<script setup>
import {computed} from "vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {useRoute} from "vue-router/composables";
import DataGrid from "@/components/DataGrid/DataGrid.vue";
import {formatDateTime} from "@/utils/filters";


const props = defineProps({
    peerId: {
        type: Number,
        required: false
    }
})
const route = useRoute()

const currencyUnit = computed(() => route.params.currency)

const getData = useGetData('getNodeActivities', {
    currency: currencyUnit.value,
    peerId: props.peerId,
}, false)

const headers = [
    {text: 'Start of activity', align: 'center', value: 'activity_start'},
    {text: 'End of activity', align: 'center', value: 'activity_end'},
    {text: 'User Agent', align: 'center', value: 'user_agent'},
    {text: 'Protocol Version', align: 'center', value: 'protocol_version'},
    {text: 'Services', align: 'center', value: 'services'},
]

const nodeActivityEndDate = (activityEndTimestamp) => activityEndTimestamp === null ? "Ongoing" : formatDateTime(activityEndTimestamp)

</script>

<template>
    <v-card class="mt-2" outlined>
        <DataGrid
            sort-desc
            :bulkOperations="false"
            :getData="getData"
            :headers="headers"
            :itemsPerPage="10"
            itemid="id"
            computed-height
        >
            <template #item="{ item }">
                <td>
                    <span>{{ item.activity_start | formatDateTime }}</span>
                </td>
                <td>
                    <span>{{ nodeActivityEndDate(item.activity_end) }}</span>
                </td>
                <td>
                    <span>{{ item.service.user_agent }}</span>
                </td>
                <td>
                    <span>{{ item.service.protocol_version }}</span>
                </td>
                <td>
                    <span>{{ item.service.services }}</span>
                </td>
            </template>
        </DataGrid>
    </v-card>
</template>

<style scoped>

</style>