var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "justify-center",
      staticStyle: { "border-radius": "50%" },
      attrs: { elevation: "1", "max-width": _vm.size },
    },
    [
      _c(
        "v-progress-circular",
        {
          attrs: {
            indeterminate: "",
            outlined: "",
            width: "5",
            size: _vm.size,
            color: _vm.color,
          },
        },
        [
          _c(
            "v-btn",
            {
              attrs: {
                rounded: "",
                fab: "",
                elevation: "0",
                outlined: "",
                small: "",
                color: "white",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel-button-event")
                },
              },
            },
            [
              _c("v-icon", { attrs: { color: _vm.color } }, [
                _vm._v("\n                mdi-close\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }