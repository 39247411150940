<template>
    <div>
        <Chart :options="pieChartOptions"/>
    </div>
</template>

<script>
import {Chart} from "highcharts-vue";
import {mapGetters} from "vuex";
import {capitalize} from "@/utils/filters";

export default {
    name: "TransactionsCountry",
    components: {
        Chart,
    },
    inject: ['entityId', 'entityType'],
    props: {
        countryData: {
            type: [Object, null],
            required: true
        }
    },
    computed:
    {
        ...mapGetters(['addressId']),
        entityIdComputed() {
            return this.entityId ?? this.addressId
        },
        pieChartOptions() {
            return {
                chart: {type: "pie"},
                credits: {enabled: false},
                title: {
                    text: `${capitalize(this.entityType)} Transactions by Country`,
                },
                subtitle: {
                    text: `<div><span class="fontMonospace">${this.entityIdComputed}</span> transactions</div>`,
                    useHTML: true
                },
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {enabled: false},
                    showInLegend: true,
                },
                series: [
                    {
                        name: "Amount",
                        colorByPoint: true,
                        data: this.countryData.highChartData,
                        dataLabels: {
                            useHTML: true,
                            formatter: function() {
                                return '<div class="mb-1 mr-2"><img class="mr-1" src="/img/flags/' + String(this.point["alpha-2"]).toLowerCase() + '.png' + '"><img>' + this.key + '</div>'
                            }
                        }
                    },
                ],
                tooltip: {
                    useHTML: true,
                    formatter: function() {
                        return '<div class="pr-4"><img class="mr-1" src="/img/flags/' + String(this.point["alpha-2"]).toLowerCase() + '.png' + '"><img>' + this.point.countryName + ': <b>' + this.y +  ' | ' + Number(this.point.percentage).toFixed(1) + '%</b></div>'
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>