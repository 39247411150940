var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    staticClass: "geo-picker v-text-field--single-line",
    attrs: {
      label: _vm.label,
      "prepend-inner-icon": _vm.prependIcon,
      dense: "",
      readonly: "",
      clearable: "",
      "clear-icon": "mdi-close-circle",
      filled: "",
      value: _vm.value,
      items: [_vm.value],
    },
    on: {
      input: function ($event) {
        return _setup.emit("input", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ item }) {
          return [
            _c(
              "div",
              { staticClass: "text-truncate mt-3" },
              [
                _c(_vm.displayComponent, {
                  tag: "Component",
                  attrs: { item: item },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }