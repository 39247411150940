<template>
    <div class="pathGraph">
        <CreateGraphFromCanvasModal ref="CreateGraphFromCanvasModal" @create-graph-event="createGraph"/>
        <GraphVisualization
            :config="config"
            :loading="transactionNetworkLoading"
            :elements="elements"
            :autoRelayout="true"
            :nameOfExportedGraph="nameOfExportedGraph"
            @graph-click-event="graphNodeClick"
            @before-destroy-event="cleanUpGraphData"
            ref="graph"
        >
            <template #actionbuttons class="graph-action-modals">
                <SaveButton :color="currency.unit" @click.stop="$refs.CreateGraphFromCanvasModal.openModal()" />
            </template>
        </GraphVisualization>
        <router-view />
    </div>
</template>

<script>
import {buildStringTemplate, createAddressOrClusterOrTransactionEndpoint} from "@/utils"
import {mapGetters} from "vuex";

import GraphVisualization from "@/components/network/GraphVisualization"
import CreateGraphFromCanvasModal from "@/components/network/CreateGraphFromCanvasModal"
import SaveButton from "@/components/common/Buttons/Fab/SaveButton"


//todo redirect from FUNDS, change to better naming
export default {
    name: "PathsNetwork",
    components: {
        GraphVisualization,
        CreateGraphFromCanvasModal,
        SaveButton
    },
    mounted() {
        if(this.$route.params.maxHops == -1) {
            this.$store.dispatch("loadFundsNetworkWithTxs", {
                source: this.$route.params.source,
                destination: this.$route.params.destination,
                currency: this.$route.params.currency,
                txs: typeof this.$route.query.txs ==="string" ? [this.$route.query.txs] : this.$route.query.txs
            });
        } else {
            this.$store.dispatch("loadFundsNetwork", {
                source: this.$route.params.source,
                destination: this.$route.params.destination,
                maxHops: this.$route.params.maxHops,
                pathFilter: this.$route.params.pathFilter,
                currency: this.$route.params.currency,
                paths: this.$route.query.paths ?? "all",
                asAddress: this.$route.query.asAddress ?? 0,
                limit: this.$route.query.limit ?? 100,
                directions: this.$route.query.directions ?? 'both',
                includeClusters: this.$route.query.includeClusters ?? true
            });
        }
    },
    computed: {
        ...mapGetters(["currency", "transactionNetworkLoading"]),
        elements() {
            return this.$store.getters.transactionNetwork;
        },
        nameOfExportedGraph() {
            return `Funds_from-${this.$route.params.source}-to${this.$route.params.destination}`
        },
        config() {
            //this prop have only nodes and edges, other data will be default, which is is set in GraphVisualization
            return this.$store.getters.transactionNetwork;
        }
    },
    methods: {
        async createGraph({ graphDetail }) {
            const graphJson = this.$refs.graph.cytoscapeInstanceFunc(i =>
                i.json()
            );
            const graph = await this.$store.dispatch('createGraph', {
                graph: {
                    ...graphDetail,
                    currency: this.currency.unit,
                    config: graphJson
                }
            })
            if(graph){
                this.$router.push({ name: "GraphSingleView", params: { graphId: graph, currency: this.currency.unit } })
            }
        },
        graphNodeClick(event) {
            if (this.$route.params.address)
            {
                const requestBaseUrl = buildStringTemplate({
                    template: "/${currency}/address/${addressId}/funds/paths/source/${source}/destination/${destination}/maxHops/${maxHops}/detail",
                    values: {
                        currency: this.$route.params.currency,
                        addressId: this.$route.params.address,
                        source: this.$route.params.source,
                        destination: this.$route.params.destination,
                        maxHops: this.$route.params.maxHops
                    }
                })
                const requestUrl = createAddressOrClusterOrTransactionEndpoint({ event, requestBaseUrl })
                this.$router.push({ path: requestUrl, query: this.$route.query })
            } else //clusterView
            {
                const requestBaseUrl = buildStringTemplate({
                    template: "/${currency}/cluster/${entity}/funds/paths/source/${source}/destination/${destination}/maxHops/${maxHops}/detail",
                    values: {
                        currency: this.$route.params.currency,
                        entity: this.$route.params.clusterentity ?? this.$route.params.detailCluster,
                        source: this.$route.params.source,
                        destination: this.$route.params.destination,
                        maxHops: this.$route.params.maxHops
                    }
                })
                const requestUrl = createAddressOrClusterOrTransactionEndpoint({ event, requestBaseUrl })
                this.$router.push({ path: requestUrl, query: this.$route.query })
            }
        },
        cleanUpGraphData() {
            this.$store.dispatch('cleanUpTransactionNetwork')
        }
    }
}
</script>

<style>

</style>