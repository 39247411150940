<script setup>
import DataGrid from "@/components/DataGrid/DataGrid.vue";
import {useGetDataStatic} from "@/components/common/Composables/useGetDataStatic";
import {computed} from "vue";
import {deepClone} from "@/utils";

const props = defineProps({
    nodeTorData: {
        required: true
    }
})

const data = computed(() => {
    let rawData = deepClone(props.nodeTorData).map(o => {
        return {
            ...o,
            inconsensus_val_after: o.inconsensus_val_after.join(" ")
        }
    })

    const sortedData = rawData.sort((a, b) => {
        if(a.inconsensus_val_after < b.inconsensus_val_after) {
            return 1
        }
        if(a.inconsensus_val_after > b.inconsensus_val_after) {
            return -1
        }

        return 0
    })

    return sortedData
})

const itemsPerPage = 10

const getData = useGetDataStatic(data, { pagination: { page: 1, itemsPerPage: itemsPerPage } })

const headers = [
    {text: 'Nickname', align: 'left', value: 'nickname', sortable: false},
    {text: 'Flags', align: 'left', value: 'flags', sortable: false},
    {text: 'TOR software', align: 'left', value: 'version', sortable: false},
    {text: 'Valid Since', align: 'center', value: 'inconsensus_val_after', sortable: false},
    {text: 'Valid Until', align: 'center', value: 'inconsensus_val_until', sortable: false},
]


</script>

<template>
    <v-card class="mt-2" outlined>
        <DataGrid
            :headers="headers"
            :get-data="getData"
            sort-desc
            :bulk-operations="false"
            :items-per-page="itemsPerPage"
            item_id="nickname"
            computed-height
        >
            <template #item="{ item }">
                <td class="fontMonospace text-left">
                    <span>{{ item.nickname }}</span>
                </td>
                <td class="fontMonospace text-left">
                    <div class="d-flex" style="gap: 4px">
                        <v-chip v-for="flag in item.flags" small :key="flag">{{ flag }}</v-chip>
                    </div>
                </td>
                <td class="fontMonospace text-left">
                    <span class="fontMonospace">{{ item.version }}</span>
                </td>
                <td class="fontMonospace">
                    <span class="fontMonospace">{{ item.inconsensus_val_after| formatDateTime }}</span>
                </td>
                <td class="fontMonospace">
                    <span class="fontMonospace">{{ item.inconsensus_val_until.join(" ") | formatDateTime }}</span>
                </td>
            </template>
        </DataGrid>
    </v-card>
</template>

<style scoped>

</style>