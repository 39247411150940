var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-autocomplete",
    _vm._g(
      _vm._b(
        {
          attrs: {
            items: _vm.addresses,
            "search-input": _vm.search,
            "hide-no-data": "",
            "item-text": "address",
            "item-value": "address",
            "prepend-icon": "search",
            "append-icon": "",
            placeholder: "Start typing to search Address",
            clearable: "",
            "no-filter": "",
          },
          on: {
            "update:searchInput": function ($event) {
              _vm.search = $event
            },
            "update:search-input": function ($event) {
              _vm.search = $event
            },
          },
        },
        "v-autocomplete",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }