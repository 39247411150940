var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                _setup.BaseDataModifyButton,
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        icon: "",
                        color: "error",
                        small: !_vm.large,
                      },
                    },
                    "BaseDataModifyButton",
                    _vm.$attrs,
                    false
                  ),
                  { ..._vm.$listeners, ...on }
                ),
                [
                  _c("v-icon", { attrs: { small: !_vm.large } }, [
                    _vm._v("delete"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.deleteButtonTooltip))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }