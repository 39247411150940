var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.transactionsCountryLoading && _vm.transactionsCountryData
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "mdi-magnify",
                      label: "Search",
                      "single-line": "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.GEOSearch,
                      callback: function ($$v) {
                        _vm.GEOSearch = $$v
                      },
                      expression: "GEOSearch",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.GEOHeaders,
                      items: _vm.transactionsCountryData.highChartData,
                      search: _vm.GEOSearch,
                      "items-per-page": 5,
                      "footer-props": {
                        "items-per-page-options": [5],
                        "disable-items-per-page": true,
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header.name",
                          fn: function ({ header }) {
                            return undefined
                          },
                        },
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c("tr", [
                                _c("td", { staticClass: "text-left" }, [
                                  _c("img", {
                                    staticClass: "mr-1",
                                    attrs: {
                                      src:
                                        "/img/flags/" +
                                        String(item[`alpha-2`]).toLowerCase() +
                                        ".png",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.countryName) +
                                      "\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-left" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.region) +
                                      "\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm._f("formatNumber")(item.y)) +
                                      "\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td"),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "footer.prepend",
                          fn: function () {
                            return [_c("v-spacer")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      280370363
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("TransactionsCountry", {
                    attrs: { countryData: _vm.transactionsCountryData },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("ChartMap", {
                    attrs: { countryData: _vm.transactionsCountryData },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("ProgressCircular"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }