var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    staticClass: "cryptoPickerCurrencyPicker",
    attrs: {
      items: _setup.currencies,
      "item-value": "id",
      filled: "",
      dense: "",
      solo: "",
      label: "Default crypto",
      "prepend-icon": "mdi-currency-sign",
      "return-object": "",
      color: "info",
      "clear-icon": "mdi-close-circle",
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "d-flex align-center justify-start" }, [
              _c("img", {
                staticStyle: { width: "20px" },
                attrs: { src: item.image, alt: "currency" },
              }),
              _vm._v(" "),
              _c("div", { class: _setup.textClass }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "d-flex align-center justify-start" }, [
              _c("img", {
                staticStyle: { width: "20px" },
                attrs: { src: item.image, alt: "currency" },
              }),
              _vm._v(" "),
              _c("div", { class: _setup.textClass }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _setup.pickedCrypto,
      callback: function ($$v) {
        _setup.pickedCrypto = $$v
      },
      expression: "pickedCrypto",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }