<script setup>
import {BlockchainCryptoBadge, CreateButton, TableDeleteModalDialog, TableEditButton} from "@/components/common";
import DataGrid from "@/components/DataGrid/DataGrid.vue";
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import GraphDetailModal from "@/components/network/GraphDetailModal.vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {computed, ref} from "vue";
import store from "@/store";
import {useRouter} from "vue-router/composables";
import {DataState} from "@/components/common/types/data";
import CreateInteractiveGraphButton from "@/components/user/UserProfileView/CreateInteractiveGraphButton.vue";

const props = defineProps({
    userId: {
        type: Number,
        required: true
    }
})

const currencies = computed(() => store.getters.currencies)

const filters = {
    filterCreatorId: props.userId
}

const getGraphs = useGetData("getUserGraphs", {filters: filters}, false, false, 1, true)

const graphsHeaders = [
    {text: "Name", align: "center", value: "name"},
    {text: "Description", align: "center", value: "description"},
    {text: "Currency", align: "center", sortable: false, value: "crypto"},
    {text: "# Configs", align: "center", value: "count"},
    {align: 'center', text: 'Visibility', value: 'is_hidden'},
    {text: "Created At", align: "center", value: "created_at"},
    {text: "Updated At", align: "center", value: "updated_at"},
    {text: "Action", value: "action", align: "center", sortable: false}
]

const router = useRouter()
const createGraph = async ({graphDetail}) => {
    const graphId = await store.dispatch('createGraph', {graph: graphDetail})
    if (graphId) {
        router.push({name: "GraphSingleView", params: {graphId: graphId, currency: graphDetail.crypto}})
    }
}

const dataGridRef = ref()

const editGraph = async ({graphDetail}) => {
    await store.dispatch("editGraph", {graph: graphDetail})
    dataGridRef.value.refreshItems(true)
}

const deleteGraph = async ({itemToDelete}) => {
    getGraphs.dataState.value = DataState.Loading
    await store.dispatch("deleteGraph", {graphId: itemToDelete.id})
    dataGridRef.value.refreshItems(true)
}

</script>

<template>
    <FancyCard class="cardShadow" dense>
        <template #header>
            <div class="d-flex align-center">
                <v-icon class="d-block mr-2 ml-1">timeline</v-icon>
                <span class="font-weight-bold">My Graphs</span>
                <v-spacer/>
                <CreateInteractiveGraphButton/>
                <GraphDetailModal
                    :currencies="currencies"
                    @save-event="createGraph"
                >
                    <template #showbutton="{ on }">
                        <CreateButton class="mr-3" text="Create graph" v-on="on"/>
                    </template>
                </GraphDetailModal>
            </div>
        </template>
        <template #body>
            <DataGrid
                ref="dataGridRef"
                :bulk-operations="false"
                :headers="graphsHeaders"
                :get-data="getGraphs"
                :items-per-page="5"
                item_id="id"
                sort-desc
            >
                <template #item="{ item }">
                    <td class="text-left dynamicTruncate" style="min-width: 150px;">
                        <router-link v-if="item.description === 'Interactive Graph'"
                                     :to="{ name: 'GraphExplorer', params: { id: item.id} }"
                                     class="link"
                        >
                            {{ item.name | stringShortener(30) }}
                        </router-link>
                        <router-link
                            v-else
                            :to="`/${item.crypto}/graph/${item.id}/config`"
                            class="link"
                        >
                            {{ item.name | stringShortener(30) }}
                        </router-link>
                    </td>
                    <v-hover v-slot="{ hover }" open-delay="200">
                        <td class="text-left dynamicTruncate" :class="{'descriptionParentHover': hover}">
                            <span
                                :class="hover && 'show-full-text-hover'"
                                class="description-styles"
                                style="margin: auto 0 0 0"
                            >{{ item.description }}</span>
                        </td>
                    </v-hover>
                    <td
                        class
                        style="text-align: center; vertical-align: middle;"
                    >
                        <BlockchainCryptoBadge
                            :crypto="item.crypto"
                            :currencies="currencies"
                            :iconWidth="24"
                        />
                    </td>
                    <td
                        class="text-right"
                        style="text-align: center;vertical-align: middle;"
                    >
                        {{ item.count | formatNumber }}
                    </td>
                    <td
                        style="text-align: center;vertical-align: middle;"
                    >
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-icon
                                    :color="item.is_hidden ? 'primary' : 'tertiary'"
                                    dark
                                    v-on="on"
                                >
                                    {{
                                        item.is_hidden
                                        ? "visibility_off"
                                        : "visibility"
                                    }}
                                </v-icon>
                            </template>
                            <span>
                                Graph is {{
                                    item.is_hidden
                                        ? "hidden"
                                        : "visible"
                                }}
                            </span>
                        </v-tooltip>
                    </td>
                    <td
                        class="text-overflow"
                        style="text-align: center;vertical-align: middle;"
                    >
                        <span>
                            {{ item.created_at | moment("YYYY-MM-DD HH:mm:ss Z") }}
                        </span>
                    </td>
                    <td
                        class="text-overflow"
                        style="text-align: center;vertical-align: middle;"
                    >
                        <span>
                            {{ item.updated_at | moment("YYYY-MM-DD HH:mm:ss Z")}}
                        </span>
                    </td>
                    <td class="text-xs-left text-overflow" style="min-width: 95px">
                        <GraphDetailModal
                            :currencies="currencies"
                            :graphDetail="item"
                            @save-event="editGraph"
                        >
                            <template #showbutton="{ on }">
                                <TableEditButton
                                    editButtonTooltip="Edit graph"
                                    v-on="on"
                                />
                            </template>
                        </GraphDetailModal>
                        <TableDeleteModalDialog
                            :description="`Do you really want to delete graph ${item.name}`"
                            :itemToDelete="item"
                            tooltip="Delete Graph"
                            @delete-event="deleteGraph"
                        />
                    </td>
                </template>
            </DataGrid>
        </template>
    </FancyCard>
</template>