var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticStyle: { "white-space": "nowrap" },
        attrs: {
          dense: "",
          headers: _vm.headers,
          items: _vm.computedData,
          "server-items-length": _vm.totalItems,
          options: _vm.filter,
          loading: _vm.loading,
          "loading-text": _vm.loadingText,
          "footer-props": _vm.footerProps,
        },
        on: {
          pagination: function ($event) {
            return _vm.debouncePagination($event)
          },
          "update:sort-by": function ($event) {
            return _vm.handleSortBy($event)
          },
          "update:sort-desc": function ($event) {
            return _vm.handleSortDesc($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item",
              fn: function (props) {
                return [_vm._t("tableBody", null, { props: props })]
              },
            },
            {
              key: "footer.page-text",
              fn: function ({ pageStart, pageStop, itemsLength }) {
                return [
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("formatNumber")(pageStart)) +
                        " - " +
                        _vm._s(_vm._f("formatNumber")(pageStop)) +
                        " of " +
                        _vm._s(_vm._f("formatNumber")(itemsLength)) +
                        "\n            "
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }