var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { "align-start": "", "fill-height": "" } },
    [
      _c("v-textarea", {
        staticClass: "ml-3 fontMonospace toolbarSearch searchArea",
        class: _setup.currency
          ? _setup.currency.unit + " darken-1"
          : "primary darken-3",
        attrs: {
          rows: _setup.textAreaRows,
          dark: "",
          "no-resize": "",
          outlined: "",
          "hide-details": "",
          placeholder: "Search",
          counter: "",
          clearable: "",
          "background-color": _setup.currency
            ? _setup.currency.unit + " darken-1"
            : "primary darken-3",
        },
        on: {
          focus: function ($event) {
            _setup.isInFocus = true
          },
          blur: function ($event) {
            _setup.isInFocus = false
          },
          "click:append": _setup.resizeEvent,
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _setup.pushToRoute.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
            },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "prepend-inner",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-center align-center",
                    staticStyle: { "min-width": "24px", "max-width": "24px" },
                  },
                  [
                    !_setup.loading
                      ? _c(
                          "v-icon",
                          {
                            attrs: {
                              color: _setup.isInFocus ? "white" : "grey",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    mdi-magnify\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _setup.loading
                      ? _c("v-progress-circular", {
                          attrs: { size: "20", width: "2", indeterminate: "" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "append",
            fn: function () {
              return [
                _c("v-hover", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ hover }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex ml-1" },
                            [
                              _c("v-divider", { attrs: { vertical: "" } }),
                              _vm._v(" "),
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: hover ? "white" : "grey" },
                                  on: { click: _setup.resizeEvent },
                                },
                                [
                                  _vm._v(
                                    "\n                        mdi-unfold-more-horizontal\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _setup.searchText,
          callback: function ($$v) {
            _setup.searchText = $$v
          },
          expression: "searchText",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }