<template>
    <v-dialog
        v-model="showModal"
        :width="width"
        :max-width="maxWidth"
        :persistent="persistent"
        scrollable
        content-class="elevation-0 mt-16 dialog-content min-width-500"
    >
        <template #activator="{ on }">
            <slot name="showbutton" v-bind:on="on"/>
        </template>
        <v-card :height="height" v-if="showModal && !loading">
            <v-card-title class="grey lighten-2">
                <div class="text-h5">{{ title }}</div>
            </v-card-title>
            <v-divider/>
            <v-card-text class="mt-3">
                <v-flex class="mt-1" :class="{disabled: disabled}">
                    <slot name="content" class="mt-6"/>
                </v-flex>
            </v-card-text>
            <v-divider/>
            <v-card-actions class="justify-space-around">
                <slot name="footer" :close="close" :setDisabled="setDisabled" :disabled="disabled"/>
            </v-card-actions>
        </v-card>
        <v-card v-else :width="loadingWidth">
            <v-card-title class="grey lighten-2">
                <h3>{{ title }}</h3>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pt-2">
                Please stand by
                <v-progress-linear indeterminate color="primary" class="mb-0"/>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "BaseModalDialog",
    props: {
        title: {
            type: String
        },
        persistent: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: "30%"
        },
        maxWidth: {
            type: String,
            default: "100%"
        },
        loading: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            required: false
        },
        loadingWidth: {
            type: String,
            required: false,
            default: "auto"
        }
    },
    data() {
        return {
            showModal: false,
            disabled: false,
        }
    },
    watch: {
        showModal(newValue) {
            if (newValue && !this.loading) {
                // in this event load state for modal dialog
                this.$emit("load-modal-state-event")
            }
        },
        loading(newValue) {
            if (!newValue && this.showModal) {
                // in this event load state for modal dialog
                this.$emit("load-modal-state-event")
            }
        }
    },
    methods: {
        open() {
            this.showModal = true
        },
        close() {
            this.showModal = false
            // in this event could be state for modal dialog destroyed
            this.$emit("destroy-modal-state-event")
        },
        setDisabled(newVal) {
            this.disabled = newVal
        }
    }
}
</script>

<style scoped>

.disabled {
    filter: blur(2px);
    pointer-events: none;
}

</style>

<style>
.dialog-content.min-width-500 {
    min-width: 500px;
}
</style>