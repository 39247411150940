var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.text
    ? _c(
        "v-snackbar",
        {
          staticStyle: { "z-index": "9999", top: "32px" },
          attrs: {
            timeout: -1,
            value: true,
            absolute: "",
            color: "blue",
            outlined: "",
            text: "",
            top: "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _vm._v("\n        " + _vm._s(_vm.text) + " "),
              _c("strong", [_vm._v("Active!")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { color: "error", outlined: "", small: "" },
                  on: {
                    click: function ($event) {
                      return _setup.emit("on-cancel-event")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("\n                mdi-cancel\n            "),
                  ]),
                  _vm._v("\n            Cancel\n        "),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "position-relative" },
            [
              _c("v-progress-linear", {
                attrs: {
                  absolute: "",
                  active: "",
                  bottom: "",
                  color: "info",
                  height: "4px",
                  indeterminate: "",
                },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }