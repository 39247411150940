var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseDeleteModalDialog",
    _vm._b(
      {
        on: {
          "delete-event": function ($event) {
            return _vm.$emit("delete-event", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "showbutton",
            fn: function ({ on: activateOn }) {
              return [
                _vm.fancy
                  ? _c(
                      "span",
                      [
                        _c("ButtonWrapperHoverTooltip", {
                          attrs: { disabled: _vm.disabled },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "active",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "TableDeleteButton",
                                      _vm._g(
                                        {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            icon: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return activateOn.click($event)
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "red" } },
                                          [
                                            _vm._v(
                                              "\n                            mdi-delete\n                        "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "tooltip",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                    Delete\n                "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "alt",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          icon: "",
                                          small: "",
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            "\n                            mdi-delete\n                        "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c(
                          "TableDeleteButton",
                          _vm._g(
                            {
                              attrs: {
                                deleteButtonTooltip: _vm.tooltip,
                                large: _vm.large,
                              },
                            },
                            activateOn
                          )
                        ),
                      ],
                      1
                    ),
              ]
            },
          },
        ]),
      },
      "BaseDeleteModalDialog",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }