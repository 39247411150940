<script setup>

import {computed} from "vue";
import store from "@/store";

const currency = computed(() => store.getters.currency)

const props = defineProps({
    presetSinceTimes: {
        type: Array,
        required: true
    },
    presetUntilTime: {
        type: Number,
        required: false,
        default: undefined
    },
    timePeriod: {
        type: String,
        required: false
    },
    buttonIndex: {
        type: Number,
        required: true
    },
    filter: {
        type: Object,
        required: true
    },
    isLast: {
        type: Boolean,
        required: false,
        default: false
    },
    buttonText: {
        type: String,
        required: true
    }
})

const emit = defineEmits(["limitSelect"])

const buttonBorderStyle = computed(() => {
    if (props.isLast) {
        return {borderRadius: "0 5px 5px 0", borderLeftWidth: 0}
    } else if (props.buttonIndex === 0) {
        return {borderRadius: "5px 0 0 5px"}
    }
    return {borderLeftWidth: 0}
})

const handleClick = () => emit('limitSelect', {timePeriod: props.timePeriod, buttonIndex: props.buttonIndex})
</script>

<template>
    <v-hover v-slot="{ hover }">
        <v-btn
            height="24px"
            style="font-size: 12px"
            :style="buttonBorderStyle"
            outlined
            tile
            :color="hover || (presetSinceTimes[buttonIndex] === filter.since && filter.until === presetUntilTime && filter.since && filter.until) ? currency.unit : 'primary'"
            @click="handleClick"
        >
            {{ buttonText }}
        </v-btn>
    </v-hover>
</template>
