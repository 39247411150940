<template>
    <v-menu
        v-if="hasPermissions"
        offset-x
    >
        <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-badge
                        color="red"
                        :content="alarms.length.toString()"
                        offset-y="13"
                        offset-x="13"
                    >
                        <v-btn
                            class="ml-2"
                            color="success"
                            elevation="0"
                            icon
                            v-on="{ ...tooltip, ...on }"
                        >
                            <v-icon>mdi-bell</v-icon>
                        </v-btn>
                    </v-badge>
                </template>
                <span>Create Alarm</span>
            </v-tooltip>
        </template>
        <v-list>
            <v-subheader style="height: 34px !important;">ALARMS</v-subheader>
            <AlarmDetailModal :currencies="currencies"
                              :alarm="{crypto: currency.unit, hash: address.address, username: this.$store.getters.user.name}"
                              new-alarm-for-address
                              @save-event="createNewAlarm"
            >
                <template #showbutton="{ on: on }">
                    <v-list-item link v-on="on">
                        <v-icon style="font-size: 18px" left color="success">add_box</v-icon>
                        <v-list-item-title style="font-weight: initial ;font-size: 16px; color: rgba(0,0,0,.7)">
                            Create New
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </AlarmDetailModal>

            <v-divider v-if="alarms.length > 0 || alarmCreating > 0"/>

            <v-list-item v-if="alarmCreating" v-for="i in alarmCreating" :key="i" style="max-height: 48px">
                <div class="text-center ml-n5 d-flex justify-start" style="max-height: 48px">
                    <v-skeleton-loader width="90px" loading type="card-heading"/>
                    <v-skeleton-loader style="margin-left: -35px" width="85px" loading type="list-item-two-line"/>
                </div>
            </v-list-item>

            <alarm-detail-modal
                v-for="(item, index) in alarms"
                :key="item.id"
                :alarm="{crypto: item.address.crypto, hash: item.address.hash, username: item.user.name, ...item}"
                :currencies="currencies"
                existing-alarm
                @save-event="editAlarm"
                @delete-event="deleteAlarm"
            >
                <template #showbutton="{ on: one }">
                    <v-list-item
                        :disabled="item.invalid"
                        v-on="one"
                        link>
                        <v-chip
                            class="mr-2 ml-n3"
                            :color="item.notifications_count > 0 ? 'warning' : 'grey'"
                            outlined
                            small
                        >
                            <v-icon small left>
                                mdi-bell-ring
                            </v-icon>
                            {{ item.notifications_count.toString() }}
                        </v-chip>
                        <div class="d-flex flex-column">

                            <div v-if="!item.invalid" style="font-size: 0.95rem;
                                                    font-weight: 500;
                                                    line-height: 1.5rem;
                                                    color: rgba(0,0,0,.7)">
                                {{ item.name | stringShortener(25) }}
                            </div>
                            <div v-else style="font-size: 0.95rem;
                                                    font-weight: 500;
                                                    line-height: 1.5rem;
                                                    color: rgba(0,0,0,.4)">
                                {{ item.name | stringShortener(25) }}
                            </div>
                            <div style="font-size: 0.8rem;
                                                line-height: 1rem; color: rgba(0,0,0,.6)">
                                {{ (item.user ? item.user.name : item.username) | stringShortener(20) }}
                            </div>
                        </div>
                    </v-list-item>
                </template>
            </alarm-detail-modal>
        </v-list>
    </v-menu>

</template>

<script>
import AlarmDetailModal from "@/components/alarm/AlarmDetailModal";

export default {
    name: "AlarmSummaryMenu",
    components: {
        AlarmDetailModal,
    },
    props: {
        alarms: {
            required: true,
            type: Array
        },
        currencies: {
            required: true,
            type: Array,
        },
        address: {
            required: true,
            type: Object
        },
        currency: {
            type: Object,
            required: true,
        },
        alarmCreating: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            selectedItem: undefined,
        }
    },
    methods: {
        createNewAlarm(alarm) {
            this.$emit("new-alarm-event", alarm)
        },
        editAlarm(alarm) {
            this.$emit("edit-alarm-event", alarm)
        },
        deleteAlarm(alarm) {
            this.$emit("delete-alarm-event", alarm)
        }
    },
    computed: {
        hasPermissions() {
            return this.$store.getters.hasPermission("alarms")
        }
    }
}
</script>

<style>

.v-skeleton-loader__heading {
    margin-top: 12px !important;
    margin-left: 8px !important;
    height: 22px !important;
    width: 50px !important;
}

.v-skeleton-loader__list-item-two-line {
    display: block !important;
    padding: 0 !important;
    margin-left: 9px !important;
    margin-top: 10px !important;
}

.delete_button {
    right: 20px;
    top: 10px
}

</style>