var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-chip",
    {
      staticStyle: { "font-weight": "500" },
      attrs: { small: "", attrs: _vm.$attrs, color: "primary" },
    },
    [_vm._v("\n    " + _vm._s(_vm.item.name) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }