var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseModalDialog, {
    ref: "baseModal",
    attrs: { title: "Error" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("div", [
                  _vm._v("\n                You need to provide "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("at least one"),
                  ]),
                  _vm._v(" of the following filters:\n                "),
                  _c("ul", [
                    _c("li", [_vm._v("Timestamp")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Input Addresses")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Output Addresses")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Input Clusters")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Output Clusters")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-center align-center" },
                  [
                    _c("v-icon", { attrs: { color: "red", large: "" } }, [
                      _vm._v(
                        "\n                    fa-solid fa-triangle-exclamation\n                "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function ({ close }) {
          return [
            _c("v-spacer"),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: { outlined: "", color: "success", text: "" },
                on: { click: close },
              },
              [_vm._v("\n            OK\n        ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }