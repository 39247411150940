<script setup>
import NameValueFlex from "@/components/common/NameValueFlex.vue";

const props = defineProps({
    hasData: {
        type: Boolean,
        required: true
    },
    loading: {
        type: Boolean,
        required: true
    },
    subject: {
        type: String,
        required: true
    }
})

</script>

<template>
    <NameValueFlex :loading="loading" loading-width="100" name-width="200">
        <template #name>
            {{ subject }} Involvement:
        </template>
        <template #value>
            <span class="fontMonospace">
                {{ hasData ? 'Yes' : 'No' }}
            </span>
        </template>
    </NameValueFlex>
</template>

<style scoped>

</style>