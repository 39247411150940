var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-4 clusterAddressMeta", attrs: { fluid: "" } },
    [
      _c("BulkMergeClustersDialog", {
        ref: "mergeDialogRef",
        attrs: {
          clusters: _vm.selectedClustersToArray,
          "currency-unit": this.filter.filterCurrency,
          redirect: "",
        },
        on: {
          "created-event": () => {
            _vm.refresh()
            _vm.removeSelection()
          },
        },
      }),
      _vm._v(" "),
      _c("BulkRemoveDialog", {
        ref: "removeDialogRef",
        attrs: { "items-to-remove": _vm.selectedClustersToArray },
        on: { "delete-event": _vm.onBulkClustersRemove },
      }),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "align-center": "" } },
        [
          _c("h1", [_vm._v("Custom Clusters Metadata")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("DeleteButton", {
            staticClass: "mx-1",
            attrs: { disabled: _vm.selectedClustersNames.length === 0 },
            on: {
              click: function ($event) {
                return _vm.$refs.removeDialogRef.open()
              },
            },
          }),
          _vm._v(" "),
          _c("MergeButton", {
            staticClass: "ml-1 mr-2",
            attrs: { disabled: _vm.selectedClustersNames.length === 0 },
            on: {
              click: function ($event) {
                return _vm.$refs.mergeDialogRef.open()
              },
            },
          }),
          _vm._v(" "),
          _c("ClusterMetadataDetailModal", {
            attrs: {
              categories: _vm.categories,
              currencies: _vm.currencies,
              isClusterMetadataDetailLoading:
                _vm.isClusterMetadataDetailLoading,
              isOwnersLoading: _vm.isOwnersLoading,
              owners: _vm.owners.data,
              "new-modal": "",
            },
            on: {
              "save-event": _vm.createClusterMetadata,
              "owner-filter-changed-event": _vm.ownerFilterChanged,
            },
            scopedSlots: _vm._u([
              {
                key: "showbutton",
                fn: function ({ on }) {
                  return [
                    _c(
                      "CreateButton",
                      _vm._g(
                        { attrs: { text: "Add new Cluster Metadata" } },
                        on
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-0 border my-2",
        attrs: {
          "footer-props": {
            "items-per-page-options": [10, 30, 50, 100],
            "show-current-page": true,
            showFirstLastPage: true,
          },
          headers: _vm.headers,
          items: _vm.clusterMetadata.data,
          loading: _vm.clusterMetadataLoading,
          options: _vm.filter,
          "server-items-length": _vm.clusterMetadata.total
            ? _vm.clusterMetadata.total
            : 0,
          dense: "",
        },
        on: {
          "update:options": function ($event) {
            _vm.filter = $event
          },
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.headers, function (h) {
              return {
                key: `header.${h.value}`,
                fn: function ({ header }) {
                  return [
                    h.value === "select"
                      ? _c("v-checkbox", {
                          key: h.value,
                          attrs: {
                            disabled:
                              _vm.clusterMetadataLoading ||
                              !_vm.userHasWritePermissions,
                            indeterminate:
                              _vm.selectedClustersNames.length !== 0 &&
                              !_vm.isWholePageSelected,
                            value: _vm.isWholePageSelected,
                          },
                          on: { change: _vm.onSelectClusters },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "entity_id"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          staticStyle: { "padding-bottom": "5px" },
                          attrs: {
                            label: header.text,
                            "append-icon": "search",
                            clearable: "",
                            "hide-details": "",
                            "single-line": "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterEntityId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.debouncedFilter,
                                "filterEntityId",
                                $$v
                              )
                            },
                            expression: "debouncedFilter.filterEntityId",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "crypto"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: {
                              "padding-top": "7px",
                              "margin-bottom": "auto",
                            },
                          },
                          [
                            _c("BlockchainCryptoPickerSlim", {
                              attrs: {
                                currencies: _vm.allCurrenciesExceptUnknown,
                                dense: "",
                              },
                              on: { input: _vm.removeSelection },
                              model: {
                                value: _vm.filter.filterCurrency,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "filterCurrency", $$v)
                                },
                                expression: "filter.filterCurrency",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "addresses_count"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: { "padding-top": "16px" },
                          },
                          [_c("h3", [_vm._v(_vm._s(h.text))])]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "categories"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: {
                              "padding-top": "20px",
                              "margin-bottom": "auto",
                            },
                          },
                          [
                            _c("CategoryPicker", {
                              staticClass: "text-field-label-style",
                              attrs: {
                                categories: _vm.categories,
                                dense: true,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value: _vm.filter.filterCategories,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "filterCategories", $$v)
                                },
                                expression: "filter.filterCategories",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "owners_count"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          staticStyle: {
                            "padding-bottom": "5px",
                            "max-width": "200px",
                          },
                          attrs: {
                            label: header.text,
                            "append-icon": "search",
                            clearable: "",
                            "hide-details": "",
                            "single-line": "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterOwner,
                            callback: function ($$v) {
                              _vm.$set(_vm.debouncedFilter, "filterOwner", $$v)
                            },
                            expression: "debouncedFilter.filterOwner",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "created_at"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: {
                              "padding-top": "6px",
                              "margin-bottom": "auto",
                            },
                          },
                          [
                            _c("DateTimePicker", {
                              attrs: { disabledTime: true, label: header.text },
                              model: {
                                value: _vm.filter.filterCreatedAt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "filterCreatedAt", $$v)
                                },
                                expression: "filter.filterCreatedAt",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "created_by"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: { "padding-top": "16px" },
                          },
                          [_c("h3", [_vm._v(_vm._s(h.text))])]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "action"
                      ? _c("div", { key: h.value }, [
                          _c("h3", [_vm._v(_vm._s(h.text))]),
                        ])
                      : _vm._e(),
                  ]
                },
              }
            }),
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _c(
                    "tr",
                    {
                      class: { pointer: _vm.userHasWritePermissions },
                      on: {
                        click: function ($event) {
                          _vm.userHasWritePermissions &&
                            _vm.onSelectCluster(item)
                        },
                      },
                    },
                    [
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              "input-value": _vm.selectedClustersNames.includes(
                                item.entity_id
                              ),
                              dense: "",
                              disabled: !_vm.userHasWritePermissions,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onSelectCluster(item)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "text-left fontMonospace dynamicTruncate",
                          staticStyle: { "min-width": "200px" },
                        },
                        [
                          _c("Copyable", {
                            attrs: {
                              "on-copy": item.entity_id,
                              justify: "start",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "text",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "text-truncate" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "link",
                                              attrs: {
                                                to:
                                                  "/" +
                                                  item.crypto +
                                                  "/cluster/" +
                                                  item.entity_id,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                _vm._g({}, on),
                                                [
                                                  _c("nobr", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(item.entity_id) +
                                                        "\n                                    "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c("BlockchainCryptoBadge", {
                            attrs: {
                              crypto: item.crypto,
                              currencies: _vm.currencies,
                              iconWidth: 24,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: { width: "130px" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("formatNumber")(item.addresses_count))
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "justify-end" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "justify-start" },
                                [
                                  _vm._l(
                                    item.categories.slice(0, 2),
                                    function (cat) {
                                      return _c("AddressCategoryIcon", {
                                        key: cat.id,
                                        attrs: { iconItem: cat },
                                      })
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "BaseButtonShowMoreItems",
                                    {
                                      attrs: {
                                        items: item.categories,
                                        reserveSpace: false,
                                        shownItemsCount: 2,
                                      },
                                    },
                                    _vm._l(item.categories, function (cat) {
                                      return _c("AddressCategoryIcon", {
                                        key: cat.id,
                                        staticClass: "mx-1",
                                        attrs: {
                                          color: cat.color,
                                          iconItem: cat,
                                          name: cat.name,
                                          medium: "",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticStyle: { width: "200px" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-start align-center",
                            staticStyle: { "max-width": "200px" },
                          },
                          [
                            _vm._l(item.owners.slice(0, 1), function (own) {
                              return _c(
                                "div",
                                {
                                  key: own.id,
                                  staticClass: "fontMonospace text-truncate",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(own.name) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "BaseButtonShowMoreItems",
                              {
                                attrs: {
                                  items: item.owners,
                                  shownItemsCount: 1,
                                },
                              },
                              _vm._l(item.owners, function (owner) {
                                return _c(
                                  "div",
                                  {
                                    key: owner.id,
                                    staticClass: "mx-1 fontMonospace",
                                    staticStyle: { "font-size": "0.8em" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(owner.name) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(
                              item.created_at,
                              "YYYY-MM-DD HH:mm:ss Z"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(item.creator.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-center d-flex justify-center",
                          staticStyle: {
                            "min-width": "92px",
                            "white-space": "nowrap",
                          },
                        },
                        [
                          _c(
                            "DataProvider",
                            {
                              attrs: {
                                data: {
                                  cluster: item,
                                  currencyUnit: item.crypto,
                                  clusterName: item.entity_id,
                                },
                                "ignore-loading": "",
                              },
                            },
                            [
                              _c("ClusterCreateCustomDialog", {
                                attrs: { icon: "" },
                                on: { "created-event": _vm.refresh },
                              }),
                              _vm._v(" "),
                              _c("ClusterMergeDialog", {
                                attrs: { currencyUnit: item.crypto, icon: "" },
                                on: {
                                  "action-finished-event": () => {
                                    _vm.refresh()
                                    _vm.removeSelection()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("ClusterMetadataDetailModal", {
                            key: item.id,
                            attrs: {
                              categories: _vm.categories,
                              clusterMetadataDetail: _vm.clusterMetadataDetail,
                              currencies: _vm.currencies,
                              isClusterMetadataDetailLoading:
                                _vm.isClusterMetadataDetailLoading,
                              isOwnersLoading: _vm.isOwnersLoading,
                              owners: _vm.owners.data,
                            },
                            on: {
                              "save-event": _vm.editClusterMetadata,
                              "owner-filter-changed-event":
                                _vm.ownerFilterChanged,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "showbutton",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "TableEditButton",
                                        _vm._g(
                                          {
                                            attrs: {
                                              editButtonTooltip:
                                                "Edit Cluster Metadata",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.loadClusterMetadataDetail(
                                                  { clusterMetadataId: item.id }
                                                )
                                              },
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c("TableDeleteModalDialog", {
                            attrs: {
                              description: `Do you really want to delete cluster ${item.entity_id} and all its metadata?`,
                              itemToDelete: item,
                              tooltip: "Delete Cluster Metadata",
                              width: "40%",
                            },
                            on: { "delete-event": _vm.deleteClusterMetadata },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "footer.page-text",
              fn: function ({ pageStart, pageStop, itemsLength }) {
                return [
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("formatNumber")(pageStart)) +
                        " - " +
                        _vm._s(_vm._f("formatNumber")(pageStop)) +
                        " of " +
                        _vm._s(_vm._f("formatNumber")(itemsLength)) +
                        "\n            "
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }