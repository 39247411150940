<script setup>

import {computed, isRef, ref, watch} from "vue";
import {usePatchManager} from "@/components/common/Composables/usePatchManager";
import {DataState} from "@/components/common/types/data";
import store from "@/store";

const props = defineProps({
    headers: {
        type: Array,
        required: true
    },
    getData: {
        type: Object,
    },
    noDataText: {
        type: String,
    },
    item_id: {
        type: String
    },
    bulkOperations: {
        type: Boolean,
        required: false,
        default: true
    },
    itemsPerPage: {
        type: Number,
        required: false,
        default: 10,
    },
    hideHeaderIfNoData: {
        type: Boolean,
        default: false,
        required: false
    },
    hideFooterIfNoData: {
        type: Boolean,
        default: false,
        required: false
    },
    hideIfNoData: {
        type: Boolean,
        required: false,
        default: false
    },
    sortDesc: {
        type: Boolean,
        required: false,
        default: false
    },
    customHeaders: {
        type: Boolean,
        required: false,
        default: false
    },
    computedHeight: {
        type: Boolean,
        required: false
    },
    footerProps: {
        type: Object,
        required: false,
        default() {
            return {
                'show-current-page': true,
                'showFirstLastPage': true,
            }
        }
    }
})

const items = computed(() => {
    const data = isRef(props.getData.data) ? props.getData.data.value?.data : props.getData.data?.data
    if (data) {
        return data
    }
    return []
})

const {patchedData: tableData, patchActions, isDataPatched} = usePatchManager(items, props.item_id)

const tableDataTotalItems = computed(() => {
    const total = isRef(props.getData.data) ? props.getData.data.value?.total : props.getData.data?.total

    if (total) {
        return total
    }

    if (isDataPatched.value) {
        return 1 //tricks table to display something
    }

    return 0
})

const loading = computed(() => {
    if (isRef(props.getData.dataState)) {
        return props.getData.dataState.value === DataState.Loading
    }
    return props.getData.dataState === DataState.Loading
})

const hideHeader = computed(() => props.hideHeaderIfNoData && tableDataTotalItems.value === 0)
const hideFooter = computed(() => props.hideFooterIfNoData && tableDataTotalItems.value === 0)
const hideTable = computed(() => props.hideIfNoData && !loading.value && tableDataTotalItems.value === 0)


const tableOptions = ref({})
watch(tableOptions, () => props.getData.getData({pagination: tableOptions.value}))

let selectedItemsMap = new Map()
const selectedItemsIds = ref([])

const isWholePageSelected = computed(() => {
    if (!tableData.value.length)
        return false

    if (selectedItemsIds.value.length < tableData.value.length)
        return false

    return tableData.value.every(x => selectedItemsIds.value.includes(x[props.item_id]))
})
const selectedItemsToArray = () => {
    //sneaky reference to selectedItemsIds
    if (selectedItemsMap.size !== selectedItemsIds.value.length) {
        console.error("DataGrid: Mismatched table selection")
    }
    return Array.from(selectedItemsMap.values())
}

const onSelectAll = () => {
    if (isWholePageSelected.value) {
        //remove all items from this page
        tableData.value.forEach(d => {
            const itemId = d[props.item_id]
            if (selectedItemsMap.delete(itemId)) {
                selectedItemsIds.value.splice(selectedItemsIds.value.indexOf(itemId), 1)
            }
        })
    } else {
        //select missing items
        tableData.value.forEach(d => {
            const itemId = d[props.item_id]
            selectedItemsMap.set(itemId, d)
            if (!selectedItemsIds.value.includes(itemId)) {
                selectedItemsIds.value.push(itemId)
            }
        })
    }
}

const onSelect = (item) => {
    const itemId = item[props.item_id]

    if (selectedItemsMap.has(itemId)) {
        selectedItemsMap.delete(itemId)
        selectedItemsIds.value.splice(selectedItemsIds.value.indexOf(itemId), 1)

    } else {
        selectedItemsIds.value.push(itemId)
        selectedItemsMap.set(itemId, item)
    }
}

const extendedHeaders = computed(() => {
    if (props.bulkOperations) {
        return [
            {
                text: '',
                value: 'patchStatus',
                align: 'center',
                width: 5,
                sortable: false,
                filterable: false,
                class: "noPadding"
            },
            {
                text: '',
                value: 'select',
                align: 'center',
                width: 42,
                sortable: false,
                filterable: false
            }, ...props.headers]
    }
    return [
        {
            text: '',
            value: 'patchStatus',
            align: 'center',
            width: 5,
            sortable: false,
            filterable: false,
            class: "noPadding"
        }, ...props.headers]
})


const refreshItems = (resetPagination = false) => {
    if (!resetPagination) {
        props.getData.getData({pagination: tableOptions.value})
        return
    }

    //triggers watcher
    tableOptions.value = {
        ...tableOptions.value,
        page: 1
    }
}

const removeSelection = (itemsToKeep = []) => {
    selectedItemsMap = new Map(itemsToKeep)
    selectedItemsIds.value = [...itemsToKeep?.map(item => item.id)]
}

const getItemsPatchStatusClass = (item) => {
    if (item.patchData?.Added) {
        return "success"
    }
    if (item.patchData?.Removed) {
        return "error"
    }
    if (item.patchData?.Updated) {
        return "warning"
    }
    return ""
}

const isAnyItemSelected = computed(() => selectedItemsIds.value.length > 0)

const tableHeight = computed(() => tableOptions.value.itemsPerPage * 32 + 32)

const userHasWritePermissions = computed(() => store.getters.hasPermission("write"))

defineExpose({refreshItems, removeSelection, selectedItemsToArray})

</script>

<template>
    <div v-show="!hideTable">
        <div class="d-flex align-end">
            <slot name="header-left"/>
            <v-spacer/>
            <slot :getItems="selectedItemsToArray"
                  :isAnyItemSelected="isAnyItemSelected"
                  :isDataPatched="isDataPatched"
                  :patchActions="patchActions"
                  :refreshItems="refreshItems"
                  :removeSelection="removeSelection"
                  name="header-right"/>
        </div>
        <v-data-table
            :footer-props="footerProps"
            :headers="extendedHeaders"
            :hide-default-footer="hideFooter"
            :hide-default-header="hideHeader"
            :items="tableData"
            :loading="loading"
            :no-data-text="props.noDataText"
            :options.sync="tableOptions"
            :server-items-length="tableDataTotalItems"
            :items-per-page="itemsPerPage"
            class="elevation-0"
            :sort-desc="sortDesc"
            :height="computedHeight ? tableHeight : undefined"
            dense
        >
            <template
                v-if="customHeaders"
                v-for="h in headers"
                v-slot:[`header.${h.value}`]="{ header }"
            >
                <slot :header="header" name="headers"></slot>
            </template>
            <template v-if="bulkOperations" #header.select>
                <v-checkbox
                    :disabled="loading || !userHasWritePermissions"
                    :indeterminate="selectedItemsIds.length !== 0 && !isWholePageSelected"
                    :value="isWholePageSelected"
                    @change="onSelectAll"
                    class="mt-0"
                    hide-details
                />
            </template>
            <template #item="{item}">
                <tr
                    :class="{'pointer': bulkOperations && userHasWritePermissions}"
                    style="position: relative; white-space: nowrap"
                    @click="userHasWritePermissions && onSelect(item)"
                >
                    <td :class="getItemsPatchStatusClass(item)" style="padding: 0; width: 4px">
                    </td>
                    <td v-if="bulkOperations" class="text-center">
                        <v-checkbox
                            :disabled="!userHasWritePermissions"
                            :input-value="selectedItemsIds.includes(item[props.item_id])"
                            dense
                            class="mt-0"
                            hide-details
                            @click.stop="onSelect(item)"/>
                    </td>
                    <slot :item="item" name="item"/>
                </tr>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                <span>
                    {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                </span>
            </template>
        </v-data-table>
    </div>
</template>

<style>

.noPadding {
    padding: 0 !important;
}

</style>