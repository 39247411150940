<template>
    <!--  v-if="corruptedData.length > 0" -->
    <BaseModalDialog title="Corrupted or missing data">
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
            <!-- <v-btn v-on="on" color="error" outlined>Show corrupted data</v-btn> -->
        </template>
        <template #content>
            <v-simple-table
                fixed-header
                fixed-footer
                class="mb-1"
                :height="corruptedData.length > 8 ?  450 :undefined"
            >
                <thead>
                    <tr>
                        <th>Csv line number</th>
                        <th>Parsed data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in corruptedData" :key="index">
                        <td>{{row.lineNumber}}</td>
                        <td>{{row.data}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </template>
        <template #footer="{close}">
            <v-btn color="error" text @click="closeModal(close)">Close</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import { BaseModalDialog } from "@/components/common";

export default {
    name: "CorruptedDataModalDialog",
    components: {
        BaseModalDialog
    },
    props: {
        corruptedData: {
            type: Array,
            validator: prop => prop.every(i => i.hasOwnProperty("lineNumber") && i.hasOwnProperty("data")),
            required: true
        }
    },
    methods: {
        closeModal(close) {
            this.$emit("close-event", close)
        }
    }
}
</script>
