var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nobr",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.data,
          "items-per-page": 50,
          "footer-props": {
            "items-per-page-options": [50],
            "disable-items-per-page": true,
            "show-current-page": true,
            showFirstLastPage: true,
          },
          "hide-default-footer": _vm.data.length < 51,
          dense: "",
          "item-key": "addr",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item, expand, isExpanded }) {
              return [
                _c("transactionOutputRow", {
                  attrs: {
                    address: _vm.address,
                    item: item,
                    expand: expand,
                    "is-expanded": isExpanded,
                    txType: _vm.txType,
                    fiats: _vm.fiats,
                  },
                }),
              ]
            },
          },
          {
            key: "expanded-item",
            fn: function ({ item: itemOuter }) {
              return _vm._l(itemOuter.inouts, function (item, index) {
                return _c("transactionOutputRow", {
                  key: index,
                  attrs: {
                    address: _vm.address,
                    item: item,
                    fiats: _vm.fiats,
                    index: index,
                    txType: _vm.txType,
                    itemOuter: itemOuter,
                  },
                })
              })
            },
          },
          {
            key: "footer.prepend",
            fn: function () {
              return [_c("v-spacer")]
            },
            proxy: true,
          },
          {
            key: "footer.page-text",
            fn: function ({ pageStart, pageStop, itemsLength }) {
              return [
                _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("formatNumber")(pageStart)) +
                      " - " +
                      _vm._s(_vm._f("formatNumber")(pageStop)) +
                      " of " +
                      _vm._s(_vm._f("formatNumber")(itemsLength)) +
                      "\n            "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }