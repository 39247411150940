var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          attrs: { "show-arrows": "" },
          model: {
            value: _setup.currentTab,
            callback: function ($$v) {
              _setup.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        [
          _vm._l(_vm.data.items, function (cryptoGroup) {
            return _c(
              "v-tab",
              { key: cryptoGroup.itemTypes },
              [
                _c("v-img", {
                  staticClass: "mr-2",
                  attrs: {
                    src: _setup.getCrypto(cryptoGroup.itemTypes).image,
                    height: "20",
                    width: "20",
                  },
                }),
                _vm._v(
                  "\n            " +
                    _vm._s(cryptoGroup.itemTypes) +
                    "\n            "
                ),
                _c("v-badge", {
                  attrs: {
                    content: _setup.formatNumber(cryptoGroup.totalMatches),
                    inline: "",
                    tile: "",
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              staticClass: "mt-1",
              model: {
                value: _setup.currentTab,
                callback: function ($$v) {
                  _setup.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            _vm._l(_vm.data.items, function (cryptoGroup) {
              return _c(
                "v-tab-item",
                { key: cryptoGroup.itemTypes },
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: [
                        { text: "Type", align: "left" },
                        { text: "Value", align: "right" },
                      ],
                      height:
                        cryptoGroup.items.length > _setup.itemsPerPage
                          ? _setup.itemsPerPage * 32 + 32
                          : undefined,
                      "hide-default-footer":
                        cryptoGroup.items.length <= _setup.itemsPerPage,
                      items: cryptoGroup.items,
                      "items-per-page": _setup.itemsPerPage,
                      dense: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c("tr", [
                                _c("td", { staticClass: "text-left" }, [
                                  _c("span", { staticClass: "fontMonospace" }, [
                                    _vm._v("Address"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-right" },
                                  [
                                    _c(_setup.RouteToAddress, {
                                      attrs: {
                                        address: item.address,
                                        currency: cryptoGroup.itemTypes,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "stringMiddleShortener"
                                                      )(item.address)
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }