<template>
    <v-menu
        :disabled="disabled"
        ref="dateMenu"
        :close-on-content-click="false"
        v-model="menuShown"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="250px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                v-bind="$attrs"
                :placeholder="placeholder"
                :rules="[rules.date]"
                :disabled="disabled"
                v-on="on"
                v-model="textFieldModel"
                :label="label"
                prepend-icon="event"
                :outlined="outlined"
                clearable
                @click:clear="clearAndEmit"
                @focus="tab=0"
                @click.stop.native
                style="min-width: 200px"
                class="text-field-label-style"
            />
        </template>
        <v-tabs
            v-model="tab"
            centered
            fixed-tabs
            height="40px"
            :color="currency && currency.unit"
        >
            <v-tab>
                <v-icon>mdi-calendar</v-icon>
            </v-tab>
            <v-tab v-if="!disabledTime" :disabled="['',undefined,null].includes(datePickerModel)">
                <v-icon>mdi-clock-outline</v-icon>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" >
            <v-tab-item>
                <v-card height="290" width="290" flat>
                    <v-date-picker v-model="datePickerModel"
                                   no-title
                                   scrollable
                                   show-current
                                   :disabled="disabled">
                    </v-date-picker>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card height="290" width="290" flat>
                    <div class="d-flex justify-center timePicker">
                        <v-time-picker
                            v-model="timePickerModel"
                            color="primary"
                            format="24hr"
                            scrollable
                            header-color="white"
                            :use-seconds="supportsSeconds"
                        />
                    </div>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-card flat>
            <div class="d-flex">
                <v-btn text color="primary"
                       @click="Cancel_click_handler">
                    Cancel
                </v-btn>
                <v-spacer/>
                <v-btn
                    text
                    color="primary"
                    @click="Ok_click_handler"
                >OK</v-btn
                >
            </div>
        </v-card>
    </v-menu>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'DateTimePicker',
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
        },
        value: {
            required: true,
        },
        outlined: {
            default: false,
            required: false,
            type: Boolean
        },
        disabledTime: {
            default: false,
            required: false,
            type: Boolean,
        },
        supportsSeconds: {
            default: false,
            required: false,
            type: Boolean
        },
        emitEagerly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            tab: 0,
            rules: {
                date: value => {
                    let pattern
                    if(this.disabledTime) {
                        pattern = /^\d\d\d\d-\d\d-\d\d$/
                    } else if(this.supportsSeconds) {
                        pattern = /^\d\d\d\d-\d\d-\d\d \d?\d:\d\d:\d\d$/
                    } else {
                        pattern = /^\d\d\d\d-\d\d-\d\d \d?\d:\d\d$/
                    }
                    return pattern.test(value) || value === "" || value === undefined || value === null || 'Invalid date.'
                },
            },
            textFieldModelDate: "",
            textFieldModelTime: "",
            textFieldModel: "",
            datePickerModel: "",
            timePickerModel: "",
            placeholder: `YYYY-MM-DD${!this.disabledTime ? " HH:MM" : (this.supportsSeconds ? " HH:MM:SS" : "")}`,
            menuShown: false,

        }
    },
    computed: {
        ...mapGetters(["currency"]),
    },
    watch: {
        textFieldModelTime: {
            handler: function (newVal, oldVal) {
                this.updateTextField()
            }
        },
        textFieldModelDate: {
            handler: function (newVal, oldVal) {
                this.updateTextField()
            }
        },
        textFieldModel: {
            handler: function (newVal, oldVal) {
                if(this.check_date_format(newVal) || !newVal){
                    if (!this.menuShown || this.emitEagerly) {
                        this.emit()
                    }
                }
            }
        },
        timePickerModel: {
            handler: function (newVal, oldVal) {
                this.textFieldModelTime = this.timePickerModel
            }
        },
        menuShown: {
            handler: function (newVal, oldVal) {
                if (newVal === false && oldVal === true && this.textFieldModel !== this.value && this.check_date_format(this.textFieldModel)){
                    this.emit()
                }
            }
        },
        value: {
            handler: function (newVal, oldVal) {
                if (this.value && !this.disabledTime && !this.supportsSeconds)
                {
                    this.textFieldModel = this.$moment.unix(this.value).format("YYYY-MM-DD HH:mm")
                } else if (this.value && !this.disabledTime && this.supportsSeconds) {
                    this.textFieldModel = this.$moment.unix(this.value).format("YYYY-MM-DD HH:mm:ss")
                } else if (this.value && this.disabledTime) {
                    this.textFieldModel = this.$moment.unix(this.value).format("YYYY-MM-DD")
                } else {
                    this.datePickerModel = ""
                    this.timePickerModel = ""
                }
            }
        },
        datePickerModel: {
            handler: function (newVal, oldVal) {
                this.textFieldModelDate = this.datePickerModel
                if (["",undefined,null].includes(this.timePickerModel) && !["",undefined,null].includes(this.datePickerModel))
                {
                    if(!this.disabledTime && !this.supportsSeconds) {
                        this.timePickerModel = "00:00"
                    } else if(this.supportsSeconds) {
                        this.timePickerModel = "00:00:00"
                    }
                }
            }
        },
    },
    mounted() {
        if (this.value && !this.disabledTime && !this.supportsSeconds){
            this.textFieldModel = this.$moment.unix(this.value).format("YYYY-MM-DD HH:mm")
        } else if (this.value && this.disabledTime && !this.supportsSeconds) {
            this.textFieldModel = this.$moment.unix(this.value).format("YYYY-MM-DD")
        } else if (this.value && this.supportsSeconds) {
            this.textFieldModel = this.$moment.unix(this.value).format("YYYY-MM-DD HH:mm:ss")
        }
        let self = this;
        window.addEventListener('keyup', function(event) {
            if (event.key === "Enter" && self.menuShown) {
                self.onEnter()
            }
        });
    },
    methods: {
        updateTextField()
        {
            if (!["",undefined,null].includes(this.timePickerModel) && !["",undefined,null].includes(this.datePickerModel))
            {
                this.textFieldModel = this.textFieldModelDate + ' ' + this.textFieldModelTime
            } else if (this.disabledTime){
                this.textFieldModel = this.textFieldModelDate
            } else {
                this.textFieldModel = ""
            }
        },
        onEnter(){
            if (this.check_date_format(this.textFieldModel)){
                this.menuShown = false
                this.emit()
            }
        },
        Ok_click_handler(){
            this.menuShown = false
            // if (this.check_date_format(this.textFieldModel)){
            //     this.emit()
            // }
        },
        Cancel_click_handler(){
            this.datePickerModel = ""
            this.timePickerModel = ""
            this.menuShown = false
        },
        check_date_format(date){
            let regex
            if(this.disabledTime) {
                regex = new RegExp(/^\d\d\d\d-\d\d-\d\d$/)
            } else if(this.supportsSeconds) {
                regex = new RegExp(/^\d\d\d\d-\d\d-\d\d \d?\d:\d\d:\d\d$/)
            } else {
                regex = new RegExp(/^\d\d\d\d-\d\d-\d\d \d?\d:\d\d$/)
            }
            return regex.test(date) || date === "" || date === null || date === undefined
        },

        emit() {
            if (this.textFieldModel === null || this.textFieldModel === undefined || this.textFieldModel === "") {
                if (this.value !== undefined) {
                    this.$emit("input", undefined)
                }
            }
            else if(this.supportsSeconds) {
                const valueInUnixTime = this.$moment(this.textFieldModel,"YYYY-MM-DD HH:mm:ss").unix()
                if(this.value !== valueInUnixTime)
                    this.$emit("input", valueInUnixTime)
            } else {
                const valueInUnixTime = this.$moment(this.textFieldModel,"YYYY-MM-DD HH:mm").unix()
                if(this.value !== valueInUnixTime)
                    this.$emit("input", valueInUnixTime)
            }
        },
        clearAndEmit() {
            this.textFieldModel = undefined
            this.emit()
        }

    }
}

</script>

<style>
.timePicker .v-picker__title {
    padding: 0 0 0 0;
}

.timePicker .v-picker__title__btn {
    height: 40px !important;
    width: 40px !important;
    font-size: 23px !important;
}

.timePicker .v-time-picker-title {
    color: dimgrey;
    display: flex;
    justify-content: center;
}

.timePicker .v-time-picker-title__time span {
    height: 40px !important;
    width: 1px !important;
    font-size: 23px !important;
}

.timePicker .v-card {
    box-shadow: none !important;
}

.timePicker .v-time-picker-clock__container {
    padding: 0 !important;
}

.timePicker .v-picker__body {
    height: 240px !important;
    width: 240px !important;
}

</style>