<template>
    <BaseModalDialog
        :title="isEditModal ? 'Edit user' : 'Create new user'"
        @load-modal-state-event="loadState"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-text-field-validateable
                    rules="required|min:2"
                    v-model="detail.name"
                    label="Name"
                    outlined
                />
                <v-text-field-validateable
                    rules="required|email"
                    v-model="detail.email"
                    label="E-mail"
                    outlined
                />
                <div v-if="isEditModal">
                    <v-row align="center" class="mx-2">
                        <v-checkbox v-model="detail.is_active" />
                        <v-chip v-if="detail.is_active" label color="success" outlined>Active</v-chip>
                        <v-chip v-else label outlined color="error">Inactive</v-chip>
                    </v-row>

                    <v-switch
                        v-model="passwordChangeIsEnabled"
                        class="mx-2"
                        color="green"
                        label="Change password"
                    />
                    <v-text-field-validateable
                        name="password-field"
                        :disabled="!passwordChangeIsEnabled"
                        :type="type"
                        rules="required|password"
                        v-model="tempPassword"
                        label="Password"
                        append="append"
                        @click:append="toggleType"
                        outlined
                    >
                        <template #append>
                            <button @click="toggleType">
                                <v-icon small>{{ type === 'password' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye' }}</v-icon>
                            </button>
                        </template>
                    </v-text-field-validateable>
                    <div v-show="passwordChangeIsEnabled">
                        <span :class="/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9\.#?!@$%^&*-]).{10,}$/.test(tempPassword) && 'text-green'">Password must:</span>
                        <ul>
                            <li :class="/[a-z]/.test(tempPassword) && 'text-green'">
                                Contain at least one lower case letter.
                                <v-icon v-show="/[a-z]/.test(tempPassword)" color="green" small>fa-solid fa-check</v-icon>
                            </li>
                            <li :class="/[A-Z]/.test(tempPassword) && 'text-green'">
                                Contain at least one upper case letter.
                                <v-icon v-show="/[A-Z]/.test(tempPassword)" color="green" small>fa-solid fa-check</v-icon>
                            </li>
                            <li :class="/[0-9\.#?!@$%^&*-]/.test(tempPassword) && 'text-green'">
                                Contain at least one number 0-9 or at least one of these .#?!@$%^&*- characters.
                                <v-icon v-show="/[0-9\.#?!@$%^&*-]/.test(tempPassword)" color="green" small>fa-solid fa-check</v-icon>
                            </li>
                            <li :class="detail && (tempPassword.length > 9 && 'text-green')">
                                Be at least 10 characters long.
                                <v-icon v-show="detail && tempPassword.length > 9" color="green" small>fa-solid fa-check</v-icon>
                            </li>
                        </ul>
                        <br>
                    </div>
                    <v-text-field-validateable
                        :disabled="!passwordChangeIsEnabled"
                        :type="type"
                        rules="required|confirmation:@password-field"
                        label="Confirm password"
                        v-model="detail.password_confirmation"
                        outlined
                    />
                </div>
                <div v-else>
                    <v-text-field-validateable
                        :type="type"
                        rules="required|password"
                        v-model="detail.password"
                        label="Password"
                        outlined
                    >
                        <template #append>
                            <button @click="toggleType">
                                <v-icon small>{{ type === 'password' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye' }}</v-icon>
                            </button>
                        </template>
                    </v-text-field-validateable>
                    <span :class="/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9\.#?!@$%^&*-]).{10,}$/.test(detail.password) && 'text-green'">Password must:</span>
                    <ul>
                        <li :class="detail.password.length > 9 && 'text-green'">
                            Be at least 10 characters long.
                            <v-icon v-show="detail.password.length > 9" color="green" small>fa-solid fa-check</v-icon>
                        </li>
                        <li :class="/[0-9\.#?!@$%^&*-]/.test(detail.password) && 'text-green'">
                            Contain at least one number 0-9 or at least one of these .#?!@$%^&*- characters.
                            <v-icon v-show="/[0-9\.#?!@$%^&*-]/.test(detail.password)" color="green" small>fa-solid fa-check</v-icon>
                        </li>
                        <li :class="/[A-Z]/.test(detail.password) && 'text-green'">
                            Contain at least one upper case letter.
                            <v-icon v-show="/[A-Z]/.test(detail.password)" color="green" small>fa-solid fa-check</v-icon>
                        </li>
                        <li :class="/[a-z]/.test(detail.password) && 'text-green'">
                            Contain at least one lower case letter.
                            <v-icon v-show="/[a-z]/.test(detail.password)" color="green" small>fa-solid fa-check</v-icon>
                        </li>
                    </ul>
                    <br>
                    <v-text-field-validateable
                        :type="type"
                        rules="required|confirmation:@Password"
                        label="Confirm password"
                        v-model="detail.password_confirmation"
                        outlined
                    />
                </div>
                <v-select-validateable
                    rules="required|roles"
                    v-model="detail.roles"
                    :items="userRoles"
                    chips
                    label="Roles"
                    multiple
                    outlined
                    item-text="name"
                    item-value="id"
                />
            </ValidationObserver>
        </template>
        <template #footer="{close, setDisabled}">
            <v-btn
                dark
                outlined
                color="error"
                :disabled="usersModalState === modalState.Pending"
                @click="closeModal(close)">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                dark
                color="success"
                :loading="usersModalState === modalState.Pending"
                outlined
                @click="save(close, setDisabled)"

            >{{ isEditModal ? "Save user" : "Create user" }}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import {deepClone} from "@/utils";
import BaseModalDialog from "@/components/common/Base/BaseModalDialog";
import {mapGetters} from "vuex";
import {modalState} from "@/components/common/types/modal";

export default {
    name: "UserDetailModal",
    components: {
        BaseModalDialog
    },
    props: {
        userDetail: {
            type: Object
        },
        userRoles: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            detail: undefined,
            passwordChangeIsEnabled: false,
            isEditModal: true,
            //detail.password wasn't reactive, using tempPassword purely for reactivity
            tempPassword: "",
            type: "password",
            closeDialog: null,
            setDisabled: null,
        }
    },
    computed: {
        ...mapGetters(['usersModalState']),
        modalState() {
            return modalState
        }
    },
    watch: {
        usersModalState(newVal) {
            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('USERS_MODAL_SET_STATE', modalState.Initial)
            } else if (newVal === modalState.Error)
            {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('USERS_MODAL_SET_STATE', modalState.Initial)
            }
        },
        passwordChangeIsEnabled: {
            handler(newValue, oldValue) {
                if (newValue) {
                    this.detail.password = ""
                    this.tempPassword = ""
                    this.detail.password_confirmation = ""
                }
                else {
                    //delete property from object (if is null, BE do not update password)
                    delete this.detail.password
                    this.tempPassword = ""
                    delete this.detail.password_confirmation
                    this.$refs.validator?.reset()
                }
            }
        },
        tempPassword: {
            handler(){
                this.detail.password = this.tempPassword
            }
        },
    },
    methods: {
        async save(close, setDisabled) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.closeDialog = close
                this.setDisabled = setDisabled
                setDisabled(true)
                this.$emit("save-event", { userDetail: this.detail })
            }
        },
        closeModal(close) {
            this.passwordChangeIsEnabled = false
            close()
        },
        loadState() {
            if (this.userDetail) {
                this.isEditModal = true
                this.detail = deepClone(this.userDetail)
                this.detail.password = ""
                //BE for editation needs only array of id`s but models from BE is {id: "", name:""}
                this.detail.roles = this.detail.roles.map(u => u.id)
            }
            else {
                this.isEditModal = false
                this.detail = {
                    name: "",
                    email: "",
                    roles: [],
                    password: "",
                    password_confirmation: ""
                }
            }
        },
        toggleType() {
            if(this.type === "password") {
                this.type = "text"
            } else {
                this.type = "password"
            }
        }
    }
}
</script>
<style>
.v-text-field__details{
    display: block !important;
}
.text-green {
    color: green;
}
</style>