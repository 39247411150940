import store from "@/store";

import {processTx} from "@/utils";


export default function ({GraphTx}) {

    let cy
    const Activate = (newCy) => {
        cy = newCy
    }

    let peelingActive = false

    const StopPeelChain = () => {
        peelingActive = false
    }

    const FollowOutputAsync = async (output, iterative = true) => {
        const initialPeelChainValue = output.value
        let lastPeelChainValue
        peelingActive = true
        const position = {x: 0, y: 0}
        while (peelingActive) {

            if (!iterative) {
                peelingActive = false
            }

            lastPeelChainValue = output.value
            const cyOutputNode = cy.$id(output.addr)
            if (cyOutputNode.isNode()) {
                position.x = cyOutputNode.position().x
                position.y = cyOutputNode.position().y
            }
            output = await getNextTxOutput(output, position)
            if (!output) {
                peelingActive = false
                continue
            }
            if (output.value < initialPeelChainValue * 0.5 || output.value > lastPeelChainValue) {
                peelingActive = false
            }
        }
    }

    const getNextTxOutput = async (output, position) => {
        if (!output.spentTxId) {
            return false
        }
        const nextTransaction = await store.dispatch('getTransaction', {
            params: {
                currencyUnit: "BTC",
                txId: output.spentTxId
            }
        })
        const processedTx = processTx(nextTransaction)
        await GraphTx.AddTxAsync(processedTx, {position: position, flags: {allOutputs: true}}, true)
        return processedTx.outputs[0]
    }

    return {Activate, FollowOutputAsync, StopPeelChain}
}