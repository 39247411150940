<template>
    <router-link
        v-if="transaction"
        class="link"
        :class="[
            monospace ? 'fontMonospace' : '',
            width100 ? 'width100' : '',
            noDecoration ? 'no-text-decoration' : '']"
        :to="{ path: targetUrl }"
    >
        <slot name="text"/>
    </router-link>

    <div v-else class="textGrey">N/A</div>
</template>

<script>
import {getRouteRelativePrefix} from "@/router/routeHelper";

export default {
    name: "RouteToTransaction",
    props: {
        transaction: {
            type: String,
            required: false,
            default: ""
        },
        monospace: {
            type: Boolean,
            required: false,
            default: false
        },
        width100: {
            type: Boolean,
            required: false,
            default: false
        },
        noDecoration: {
            type: Boolean,
            required: false,
            default: false
        },
        iconLink: {
            required: false,
            type: Boolean,
            default: false
        },
        currency: {
            type: String,
            required: false,
        }
    },
    computed: {
        targetUrl() {
            return getRouteRelativePrefix(this.$route, this.currency) + "transaction/" + this.transaction + "/"
        }
    },
}

</script>

<style scoped>
.no-text-decoration {
    text-decoration: none;
}
</style>