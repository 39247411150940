import apiClient from "@/services/apiClient";
import axios from "axios";
import Vue from "vue";
import {deepClone} from "@/utils";

const state = {
    currentInteractionsCluster: null,
    addressInteractionsLoading: {},
    addressInteractionsData: {},
    addressInteractionsFilter: {},
    addressInteractionsHistoryFallback: {},
};

const getters = {
    currentInteractionsCluster: (state) => state.currentInteractionsCluster,
    addressInteractionsLoading: (state) => state.addressInteractionsLoading,
    addressInteractionsData: (state) => state.addressInteractionsData,
    addressInteractionsFilter: (state) => state.addressInteractionsFilter,
    addressInteractionsHistoryFallback: (state) => state.addressInteractionsHistoryFallback,
};

const mutations = {
    ADDRESS_INTERACTIONS_SET_CURRENT(state, data) {
        state.currentInteractionsCluster = data;
    },
    ADDRESS_INTERACTIONS_LOADING(state, data) {
        if (state.addressInteractionsLoading.hasOwnProperty(data.entity)) {
            state.addressInteractionsLoading[data.entity].token.cancel()
        }
        Vue.set(state.addressInteractionsLoading, data.entity, {token: axios.CancelToken.source()})
    },
    ADDRESS_INTERACTIONS_SET_DATA(state, data) {
        Vue.set(state.addressInteractionsData, data.entity, data.data)
    },
    ADDRESS_INTERACTIONS_SET_FILTER(state, data) {
        Vue.set(state.addressInteractionsFilter, data.entity, data.filter)
    },
    ADDRESS_INTERACTIONS_FINISHED_LOADING(state, data) {
        Vue.delete(state.addressInteractionsLoading, data.entity)
    },
    ADDRESS_INTERACTIONS_LOADING_CANCEL(state, data) {
        if (state.addressInteractionsLoading.hasOwnProperty(data.entity)) {
            state.addressInteractionsLoading[data.entity].token.cancel()
        }
        Vue.delete(state.addressInteractionsLoading, data.entity)
    },
    ADDRESS_INTERACTIONS_FALLBACK_SET(state, data) {
        Vue.set(state.addressInteractionsHistoryFallback, data.entity, data.filter)
    },
    ADDRESS_INTERACTIONS_STATE_RESET(state) {
        state.currentInteractionsCluster = null
        state.addressInteractionsLoading = {}
        state.addressInteractionsData = {}
        state.addressInteractionsFilter = {}
        state.addressInteractionsHistoryFallback = {}
    },
    ADDRESS_INTERACTIONS_REMOVE_DATA(state, data) {
        Vue.delete(state.addressInteractionsData, data.entity)
    },
};

const actions = {
    async loadAddressInteractions(
        {commit, dispatch},
        {filter, currency, entityAddress}
    ) {
        try {

            if (!filter) {
                return
            }

            //no longer needed - todo REFACTOR
            //let serverSideFilter : AddressInteractionsFilterServerModel = MapFilterToServerModel.MapFilterToServerModel(filter)
            let serverSideFilter = filter
            commit("ADDRESS_INTERACTIONS_LOADING", {entity: entityAddress})
            commit("ADDRESS_INTERACTIONS_REMOVE_DATA", {entity: entityAddress})

            //workaround in case user sends one request twice
            //- waiting for first request to finish, otherwise the canceled "response"
            //gets cached and returned for the second request
            await new Promise(r => setTimeout(r, 0));

            const response = await apiClient.GET(
                `${currency}/cryptocluster/${entityAddress}/interactions`,
                {
                    params: {
                        remote: serverSideFilter.remote,
                        maxHops: serverSideFilter.maxHops,
                        isOutgoing: serverSideFilter.isOutgoing,
                        since: serverSideFilter.since ? serverSideFilter.since : undefined,
                        until: serverSideFilter.until ? serverSideFilter.until : undefined,
                    },
                    cancelToken: state.addressInteractionsLoading[entityAddress]?.token.token
                }
            );
            commit("ADDRESS_INTERACTIONS_SET_FILTER", {
                entity: entityAddress,
                filter: deepClone(filter)
            })
            commit("ADDRESS_INTERACTIONS_SET_DATA", {entity: entityAddress, data: response})
            commit("ADDRESS_INTERACTIONS_FINISHED_LOADING", {entity: entityAddress})
            commit("ADDRESS_INTERACTIONS_FALLBACK_SET", {entity: entityAddress, filter: filter})
            dispatch("success", response.message);
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch('error', error)
                commit("ADDRESS_INTERACTIONS_FINISHED_LOADING", {entity: entityAddress})
            }
        }
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
