var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        bottom: "",
        "content-class": "white",
        eager: "",
        "offset-y": "",
        "z-index": "999999",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                _vm._g({}, on),
                [
                  _c(_setup.FilterGeoVSelectWrapper, {
                    attrs: {
                      "display-component": _vm.displaySelectedComponent,
                      label: _vm.pickerTextSingle,
                      "prepend-icon": _vm.icon,
                    },
                    model: {
                      value: _setup.item,
                      callback: function ($$v) {
                        _setup.item = $$v
                      },
                      expression: "item",
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _setup.isMenuOpen,
        callback: function ($$v) {
          _setup.isMenuOpen = $$v
        },
        expression: "isMenuOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "body-2" },
        [
          _vm._v("\n        " + _vm._s(_vm.pickerTextMultiple) + "\n        "),
          _c(_setup.FilterGeoGenericPickerWrapper, {
            attrs: {
              "convert-item-text-to-string": _vm.convertItemTextToString,
              "display-component": _vm.displaySearchComponent,
              "get-items": _vm.getItems,
              "item-text": _vm.itemText,
              items: _vm.items,
              loading: _vm.loading,
              placeholder: `Search ${_vm.pickerTextSingle}`,
            },
            model: {
              value: _setup.item,
              callback: function ($$v) {
                _setup.item = $$v
              },
              expression: "item",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }