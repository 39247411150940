<script setup>

import {BaseModalDialog} from "@/components/common";
import {computed, ref} from "vue";
import ClusterPicker from "@/components/common/ClusterPicker.vue";
import store from "@/store";
import router from "@/router";

const props = defineProps({
    clusters: {
        type: Array,
        required: true
    },
    currencyUnit: {
        type: String,
        required: true
    },
    redirect: {
        type: Boolean,
        required: false,
        default: false
    }

})

const emit = defineEmits(['created-event'])

const targetMode = ref("pick")
const modalRef = ref(null)

const open = () => {
    modalRef.value.open()
}

defineExpose({open})

const pickedCluster = ref(null)

const newClusterName = ref(null)
const newClusterDescription = ref(null)

const addressCount = computed(() => props.clusters.reduce((a, b) => a + b.addresses_count, 0))
const localClusterCount = computed(() => pickedCluster.value?.addresses_count ?? 0)

const validator = ref(null)

const onSave = async (close, setDisabled) => {

    setDisabled(true)
    if (targetMode.value === 'create') {

        if (!await validator.value.validate()) {
            return
        }

        let wasCreateSuccess = false
        if (targetMode.value === 'create') {
            wasCreateSuccess = await store.dispatch("copyCluster", {
                currency: props.currencyUnit,
                clusterMetadata: {
                    entity_id: newClusterName.value,
                    description: newClusterDescription.value,
                    crypto: props.currencyUnit,
                    addresses: [],
                },
            })
        }

        if (!wasCreateSuccess) {
            setDisabled(false)
            emit('created-event')
            return
        }
    }

    const targetClusterToMergeTo = targetMode.value === 'create' ? newClusterName.value : pickedCluster.value.entity_id

    const wasMergeSuccess = await store.dispatch("mergeToCluster", {
        currency: props.currencyUnit,
        clusterToMergeTo: targetClusterToMergeTo,
        clustersToMergeWith: props.clusters.map(x => x.entity_id)
    })

    setDisabled(false)

    if (wasMergeSuccess && props.redirect) {
        await router.push({
            name: "clusterSummary",
            params: {clusterentity: targetClusterToMergeTo, currency: props.currencyUnit}
        })
    } else {
        emit('created-event')
    }

    if (wasMergeSuccess) {
        clearForms()
    }
}

const clearForms = () => {
    pickedCluster.value = null
    newClusterName.value = null
    newClusterDescription.value = null
}

const onClose = () => {
    clearForms()
    modalRef.value.close()
}

</script>

<template>
    <BaseModalDialog
        ref="modalRef"
        title="Merge clusters"
        width="80vh">
        <template #showbutton="{ on: dialogOn }">
        </template>
        <template #content>
            <v-row dense>
                <v-col cols="12">
                    <div>Select <strong>target</strong> cluster:</div>
                    <v-radio-group
                        v-model="targetMode"
                        row
                        @change="clearForms">
                        <v-radio
                            color="info"
                            label="Pick existing"
                            value="pick"
                        ></v-radio>
                        <v-radio
                            color="info"
                            label="Create new"
                            value="create"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col v-if="targetMode === 'pick'" cols="12">
                    <div>
                        Estimated total addresses: {{ addressCount + localClusterCount | formatNumber }}
                    </div>
                    <ClusterPicker v-model="pickedCluster" :currencyUnit="currencyUnit" :multiple="false" chips
                                   no-address-clusters no-neo-clusters/>
                </v-col>
                <v-col v-if="targetMode === 'create'" cols="12">
                    <ValidationObserver ref="validator" mode="lazy">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-text-field-validateable
                                    v-model="newClusterName"
                                    autofocus
                                    dense
                                    label="Name"
                                    rules="required|entity_id">
                                    <template #prepend>
                                        <v-icon left size="22">mdi-hub</v-icon>
                                    </template>
                                </v-text-field-validateable>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea-validateable
                                    v-model="newClusterDescription"
                                    label="Description"

                                    rules="required">
                                    <template #prepend>
                                        <v-icon>
                                            mdi-text-box-outline
                                        </v-icon>
                                    </template>
                                </v-textarea-validateable>
                            </v-col>
                        </v-row>
                    </ValidationObserver>
                </v-col>
            </v-row>
        </template>
        <template #footer="{close, setDisabled, disabled}">
            <v-btn :disabled="disabled" color="error" @click="onClose">
                Cancel
            </v-btn>
            <v-spacer/>
            <v-btn :disabled="(targetMode === 'pick' && !pickedCluster) || disabled" color="info"
                   @click="onSave(close, setDisabled)">
                Merge
            </v-btn>
        </template>
    </BaseModalDialog>
</template>

<style scoped>

</style>