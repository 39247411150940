<template>
    <div class="height100">
        <div class="d-flex justify-space-between align-center">
            <div>
                <h1>Monitoring</h1>
            </div>
            <div>
                <crypto-interceptor-new-modal :currency="currency" @monitor-new-node-event="startMonitoringPeer">
                    <template #showbutton="{ on }">
                        <CreateButton text="Add new monitored node" v-on="on"/>
                    </template>
                </crypto-interceptor-new-modal>
            </div>
        </div>
        <v-divider/>
        <v-divider/>
        <div class="my-2">
            <router-view/>
        </div>
    </div>
</template>

<script>
import CreateButton from "@/components/common/Buttons/CreateButton";
import CryptointerceptorDetailModal from "@/components/CryptoInterceptor/CryptointerceptorDetailModal";
import {mapGetters} from "vuex";
import CryptoInterceptorNewModal from "@/components/CryptoInterceptor/CryptoInterceptorNewModal";

export default {
    name: "MonitoringBase",
    components: {CryptoInterceptorNewModal, CryptointerceptorDetailModal, CreateButton},
    computed: {
        ...mapGetters(["currency"])
    },
    methods: {
        async startMonitoringPeer({ip, port}) {
            await this.$store.dispatch("setCryptoInterceptorPeerStatus", {
                currency: this.currency.unit,
                ipaddress: ip,
                action: "start",
                port
            })
            this.$store.commit("CRYPTOINTERCEPTOR_PEERS_SET_DATA", null)
        },
    }
}
</script>

<style scoped>

</style>