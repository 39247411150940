<script setup>
import {computed, nextTick, ref, watch} from "vue";

const props = defineProps({
    data: {
        required: true,
        type: Array,
    },
    headers: {
        required: true,
        type: Array
    },
    tableMaxHeight: {
        required: false,
        default: '400px',
        type: String
    },
    noDataText: {
        type: String,
        required: false
    },
    debounceInterval: {
        type: Number,
        required: false,
        default: 20
    }
})

const rowRenderingStart = ref(0)
let timeout  = null
let rowHeight = 32
const rowsPerPage = ref(25)
const headerHeight = 32

const onScroll = (e) => {
    // debounce if scrolling fast
    timeout && clearTimeout(timeout)

    timeout = setTimeout(() => {
        //scrollTop is the number of pixels needed to scroll up to the top of the table
        const {scrollTop} = e.target

        let numberOfRowsToReachTop = Math.ceil(scrollTop / rowHeight)

        rowRenderingStart.value = numberOfRowsToReachTop + rowsPerPage.value > props.data.length ?
            props.data.length - rowsPerPage.value : numberOfRowsToReachTop

        nextTick(() => {
            e.target.scrollTop = scrollTop
        })
    }, props.debounceInterval)
}

const dataLengthComputed = computed(() => props.data.length)


//reset rowRenderingStart upon data clear
watch(dataLengthComputed, (value, oldValue, onCleanup) =>{
    if(oldValue > value){
        rowRenderingStart.value = 0
    }
})

const dataLimited = computed(() => props.data.slice(rowRenderingStart.value, rowsPerPage.value + rowRenderingStart.value))
const startHeight = computed(() => rowRenderingStart.value * rowHeight - headerHeight)
const endHeight = computed(() => rowHeight * (props.data.length - rowRenderingStart.value - rowsPerPage.value))

</script>

<template>
<!--    v-if re-renders table, when data is cleared-->
    <v-data-table
        v-if="data.length > 0"
        id="virtual-scroll-table"
        v-scroll:#virtual-scroll-table="onScroll"
        dense
        class="elevation-1"
        :headers="headers"
        :items="dataLimited"
        disable-pagination
        hide-default-footer
        :style="{
            'max-height': tableMaxHeight,
            'overflow': 'auto',
        }"
    >
        <template
            v-if="rowRenderingStart > 0"
            v-slot:body.prepend
        >
            <tr>
                <td
                    :colspan="headers.length"
                    :style="'padding-top:'+startHeight+'px'"
                >
                </td>
            </tr>
        </template>
        <template #item="{item}">
            <slot name="item" v-bind="item" :item="item"/>
        </template>
        <template
            v-if="rowRenderingStart + rowsPerPage < data.length"
            v-slot:body.append
        >
            <tr>
                <td
                    :colspan="headers.length"
                    :style="'padding-top:'+endHeight+'px'"
                >
                </td>
            </tr>
        </template>
    </v-data-table>
    <v-data-table v-else :headers="headers" :no-data-text="noDataText" :items="[]" dense hide-default-footer/>
</template>

<style scoped>

</style>