import {createImageUrl} from "@/utils/filters";

export default function () {

    const styleOptions = [
        {
            selector: 'node',
            style: {
                // "z-index": 10,
                // "border-width": "4px",
            }
        },
        {
            selector: '.transaction',
            style: {
                shape: "diamond",
                width: "45px",
                height: "45px",
                "background-image": createImageUrl(
                    "transaction.png"
                ),
                "background-color": "#fafafa",
                "background-height": 20,
                "background-width": 20,
                "border-width": "4px",
                "border-color": "#424242",
                'label': 'data(inouts)',
                "text-valign": "bottom",
                "text-halign": "center",
                "font-weight": 300
            }
        },
        {
            selector: '.address',
            style: {
                "background-image": createImageUrl("address.png"),
                "border-width": "4px",
                "border-color": "#424242",
                "background-height": 20,
                "background-width": 20 * 1.13,
                "background-color": "#fafafa",
                // 'label': 'data(label)',
                // "text-valign": "bottom",
                // "text-halign": "center",
                // "font-weight": 400,
                //  "font-family": "cursive",
                // "text-margin-y": 6,
                width: "50px",
                height: "50px",
            }
        },
        {
            selector: '.merge',
            style: {
                "shape": "round-rectangle",
                "background-image": createImageUrl("group.png"),
                "border-width": "4px",
                "border-color": "#424242",
                "background-height": 22 * 1.13,
                "background-width": 22,
                "background-color": "#fafafa",
                width: "50px",
                height: "50px",
            },
        },
        {
            selector: '.note',
            style: {
                width: "20px",
                height: "20px",
                'border-opacity': 0,
                'background-opacity': 0,
            },
        },
        {
            selector: '.invisible',
            style: {
                'display': 'none',
            }
        },
        {
            selector: 'edge',
            style: {
                'curve-style': 'bezier',
                'target-arrow-shape': 'triangle-backcurve',
                'width': 6,
                'opacity': 1,
                'label': 'data(value)',
                // "text-background-opacity": 1,
                "color": "#333",
                // "text-background-color": "#fff",
                'text-outline-width': 4,
                'text-outline-color': 'white',
            }
        },
        {
            selector: '.txInEdge',
            style: {
                'target-arrow-color': '#FF6962',
                'line-color': '#FF6962',
            }
        },
        {
            selector: '.txOutEdge',
            style: {
                'target-arrow-color': '#77DD76',
                'line-color': '#77DD76',
            }
        },
        {
            selector: '.mergeInEdge',
            style: {
                'target-arrow-color': '#FF6962',
                'line-color': '#FF6962',
            }
        },
        {
            selector: '.mergeOutEdge',
            style: {
                'target-arrow-color': '#77DD76',
                'line-color': '#77DD76',
            }
        },
        {
            selector: ":selected",
            style: {
                "border-color": "#2d86ec",
                "border-width": "5px",
            },
        },
    ]

    return {styleOptions}
}