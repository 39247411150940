<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="scroll-y height100">
        <v-container>
            <v-card class="pt-2 ma-2 border" elevation="0">
                <h1 class="text-center">Advanced search</h1>
                <v-flex class="mx-auto" xs12 sm8 md6>
                    <v-text-field
                        single-line
                        hide-details
                        label="Search"
                        v-model="searchText"
                        outlined
                    />
                </v-flex>
                <div class="text-center py-2">
                    <v-btn depressed color="primary" class="mr-2" @click="search">Search</v-btn>
                    <v-btn
                        toggle
                        outlined
                        color="primary"
                        class="ml-2 pr-1"
                        @click="advanceOptionsShow = !advanceOptionsShow"
                    >
                        Options
                        <v-icon v-if="!advanceOptionsShow" class="ml-1">keyboard_arrow_down</v-icon>
                        <v-icon v-else class="ml-1">keyboard_arrow_up</v-icon>
                    </v-btn>
                </div>

                <div class="options mx-2" v-if="advanceOptionsShow">
                    <h3 class="font-weight-light">Currencies:</h3>
                    <v-divider />
                    <v-layout row wrap class="pa-3">
                        <v-flex
                            xs12
                            sm6
                            md4
                            v-for="currency in enabledCurrencies"
                            :key="currency.unit"
                        >
                            <v-checkbox
                                v-model="selectedCurrencies"
                                color="primary"
                                :value="currency.unit"
                                class="mt-0"
                            >
                                <template v-slot:label v-slot:activator="{ on }">
                                    <div>
                                        <img
                                            class="currencyImg mr-1"
                                            v-on="on"
                                            :src="currency.image"
                                        />
                                        <span>{{currency.name}}</span>
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-flex>
                    </v-layout>

                    <h3 class="font-weight-light">Search in:</h3>
                    <v-divider />
                    <v-layout row wrap class="pa-3">
                        <v-flex xs12 sm6 md4 v-for="scope in scopes" :key="scope.name">
                            <v-checkbox
                                v-model="selectedScopes"
                                color="primary"
                                :value="scope.name"
                                class="mt-0"
                                :label="scope.text"
                            />
                        </v-flex>
                    </v-layout>
                </div>
            </v-card>

            <div v-if="searching">
                <ProgressCircular class="pt-6" />
            </div>
            <v-layout align-center justify-center fill-height v-else-if="results.length === 0">
                <h2 class="font-weight-light py-3">Can not found any results for {{$route.query.q}}</h2>
            </v-layout>
            <div v-else>
                <div v-for="result in results">
                    <AdvanceSearchResult :data="result" />
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import ProgressCircular from "@/components/common/ProgressCircular";
import AdvanceSearchResult from "@/components/search/AdvanceSearchResult";

export default {
    name: "AdvanceSearchView",
    components: {
        ProgressCircular,
        AdvanceSearchResult
    },
    computed: {
        currencies() {
            return this.$store.getters.currencies;
        },
        searching() {
            return this.$store.getters.advanceSearchLoading;
        },
        results() {
            return this.$store.getters.advanceSearchResults;
        },
        enabledCurrencies() {
             return this.$store.getters.enabledCurrencies;
        }
    },
    mounted() {
        this.selectedCurrencies = this.enabledCurrencies.map(curr => curr.unit);
        this.selectedScopes = this.scopes.map(scope => scope.name);

        //todo FE hack - primary use value from Query q and if not exist use value from search store module
        this.searchText = this.$route.query.q
            ? this.$route.query.q
            : this.$store.getters.searchText;
    },
    data() {
        return {
            scopes: [
                { text: "Addresses", name: "addresses" },
                { text: "Transactions (number or ID)", name: "transactions" },
                { text: "Blocks (number or hash)", name: "blocks" },
                { text: "Identities (label or url)", name: "identities" },
                { text: "Owners (name)", name: "owners" }
            ],
            advanceOptionsShow: false,
            selectedCurrencies: [],
            selectedScopes: [],
            searchText: ""
        };
    },
    methods: {
        search() {
            this.$router.push("/advanceSearch?q=" + this.searchText);
            this.$store.dispatch("advanceSearch", {
                query: this.searchText,
                currencies: this.selectedCurrencies,
                scopes: this.selectedScopes
            });
        }
    }
};
</script>

<style scoped>
.currencyImg {
    vertical-align: middle;
}
</style>
