var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.isDebug
    ? _c(
        "div",
        [
          _c(
            "v-expand-transition",
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_setup.data,
                      expression: "!data",
                    },
                  ],
                  attrs: {
                    small: "",
                    block: "",
                    outlined: "",
                    color: "tertiary",
                    loading: _setup.loading,
                  },
                  on: { click: _setup.getBudget },
                },
                [_vm._v("\n            Get Budget\n        ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-expand-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _setup.data,
                    expression: "data",
                  },
                ],
              },
              [
                _c(_setup.HeaderLined, { attrs: { text: "Budget" } }),
                _vm._v(" "),
                _c(_setup.NameChipValueFlex, {
                  attrs: {
                    name: "Deposited",
                    "chip-color": "success",
                    unit: _setup.currentFiatUnit,
                    value: _setup.formatValue(_setup.depositedValueRaw),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "appendIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "largeRight",
                                attrs: { small: "" },
                              },
                              [
                                _vm._v(
                                  "\n                        mdi-download mdi-rotate-270\n                    "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1336148900
                  ),
                }),
                _vm._v(" "),
                _c(_setup.NameChipValueFlex, {
                  attrs: {
                    name: "Withdrawn",
                    "chip-color": "red",
                    unit: _setup.currentFiatUnit,
                    value: _setup.formatValue(_setup.withdrawnValueRaw),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "appendIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "largeRight",
                                attrs: { small: "" },
                              },
                              [
                                _vm._v(
                                  "\n                        mdi-upload mdi-rotate-90\n                    "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3718021199
                  ),
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }