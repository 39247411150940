<script setup>
import {computed} from "vue";
import {useRoute} from "vue-router/composables";
import DataProvider from "@/components/common/DataProvider.vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import CaseSummary from "@/views/Case/Components/CaseSummary.vue";
import CaseDetailAddresses from "@/views/Case/Components/Addresses/CaseDetailAddresses.vue";
import CaseDetailGraphs from "@/views/Case/Components/Graphs/CaseDetailGraphs.vue";
import CaseDetailFiles from "@/views/Case/Components/Files/CaseDetailFiles.vue";
import CaseDetailModal from "@/views/Case/Dialogs/CaseDetailModal.vue";
import EditButton from "@/components/common/Buttons/EditButton.vue";
import {DataState} from "@/components/common/types/data";

const route = useRoute()
const caseId = computed(() => route.params.caseId)

const {dataState, data: caseDetail, getData} = useGetData("loadCase", {
    caseId: caseId.value,
    loadSimpleDetail: false
}, true)

const loading = computed(() => dataState.value === DataState.Loading)

</script>

<template>
    <v-container class="px-4" fluid>
        <DataProvider :data="caseDetail" :data-state="dataState" ignore-loading name="CaseDetail">
            <div class="d-flex align-center">
                <v-skeleton-loader
                    v-if="loading"
                    type="heading"
                    height="48px"
                />
                <h1 v-else>
                    {{ caseDetail?.title }}
                </h1>
                <v-spacer/>
                <CaseDetailModal
                    :caseDetail="caseDetail"
                    :loading="loading"
                    @save-event="getData"
                >
                    <template #showbutton="{ on }">
                        <EditButton
                            text="case"
                            v-on="on"/>
                    </template>
                </CaseDetailModal>
            </div>
            <v-divider class="mb-2"/>
            <v-row no-gutters>
                <v-row>
                    <v-col cols="12">
                        <CaseSummary/>
                    </v-col>
                    <v-col cols="12">
                        <CaseDetailAddresses/>
                    </v-col>
                    <v-col cols="12">
                        <CaseDetailGraphs/>
                    </v-col>
                    <v-col cols="12">
                        <CaseDetailFiles/>
                    </v-col>
                </v-row>
            </v-row>
        </DataProvider>
    </v-container>
</template>

<style scoped>

</style>