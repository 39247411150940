<template>
    <v-layout style="height: auto;">
        <v-layout style="overflow-x: hidden; height: 200px" align-center justify-center v-if="isLoading || !transaction">
            <ProgressCircular :size="50" :width="4" class="pt-6" :color="currency.unit" />
        </v-layout>

        <v-layout style="position: absolute; width: 100%" column v-else>
                <v-app-bar style="position: sticky; z-index: 1; top: 0;" color="white" dense>
                    <EntityHeaderLabel justify="center" :label="currency.unit.toUpperCase() + ' TRANSACTION:'" :entity="transaction.txhash"/>
                    <v-spacer/>
                    <a
                        class="text-decoration-none"
                        :href="'/' + currency.unit + '/transaction/' + $route.params.detailTransaction + '/'"
                        target="_blank"
                    >
                        <v-btn color="rgba(0, 0, 0, 0.7)"
                               class="mx-1"
                               icon
                               small>
                            <v-icon style="font-size: 20px">open_in_new</v-icon>
                        </v-btn>
                    </a>
                    <v-tooltip top v-if="!isOnGraphPage">
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-btn
                                    disabled
                                    color="rgba(0, 0, 0, 0.7)"
                                    icon
                               class="mx-1"
                                    small
                                    @click="removeGraphNode"
                                >
                                    <v-icon style="font-size: 20px">delete</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <span>This view is read-only. Save this<br> configuration if you want to edit it.</span>
                    </v-tooltip>
                    <v-btn
                        v-else-if="isOnGraphPage"
                        color="rgba(0, 0, 0, 0.7)"
                        icon
                               class="mx-1"
                        small
                        @click="removeGraphNode"
                    >
                        <v-icon style="font-size: 20px">delete</v-icon>
                    </v-btn>
                    <v-btn
                        class="mx-1"
                        color="rgba(0, 0, 0, 0.7)"
                        icon
                        small
                        @click="graphCloseBottomDrawer">
                        <v-icon style="font-size: 20px">close</v-icon>
                    </v-btn>
                </v-app-bar>

            <TransactionDetail :currency="currency" :transaction="transaction" class="mt-10"/>
        </v-layout>
    </v-layout>
</template>


<script>
import ProgressCircular from "@/components/common/ProgressCircular";
import TransactionSingle from "@/components/transaction/TransactionSingle";
import TransactionDetail from "./TransactionDetail";
import EntityHeaderLabel from "@/views/Cluster/EntityHeaderLabel.vue";

//todo FE refactor this to component
export default {
    name: "TransactionBottomDrawer",
    components: {
        EntityHeaderLabel,
        TransactionDetail,
        ProgressCircular,
        TransactionSingle
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.detailTransaction !== from.params.detailTransaction) {
            this.$store.dispatch("loadTransaction", { id: to.params.detailTransaction });
        }
        next();
    },
    mounted() {
        this.$store.dispatch("loadTransaction", { id: this.$route.params.detailTransaction });
    },
    computed: {
        transaction() {
            return this.$store.getters.transactionInfo;
        },
        isLoading() {
            return this.$store.getters.transactionLoading;
        },
        currency() {
            return this.$store.getters.currency;
        },
        isOnGraphPage() {
            return (
                this.$route.matched.findIndex(
                    route => route.name === "GraphSingleView"
                ) !== -1
            );
        },
    },
    methods: {
        removeGraphNode() {
            this.$store.dispatch("removeGraphNode", {
                hash: this.transaction.txhash
            });
        },
        graphCloseBottomDrawer() {
            //todo FE this is hack and work only on TransactionNetwork View, this must be rewritten to component (not View)
            // use router push from here not from STORE
            this.$store.dispatch("graphCloseBottomDrawer", { sides: this.$route.params.sides });
        },
    }
};
</script>

<style scoped>
.mt64 {
    margin-top: 64px;
}
</style>
