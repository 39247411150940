var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseModalDialog, {
    ref: "modalRef",
    attrs: { title: _vm.title, width: "80vh" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("v-icon", { attrs: { color: "error", large: "", left: "" } }, [
              _vm._v("mdi-delete"),
            ]),
            _vm._v(" "),
            _c("b", [_vm._v(_vm._s(_vm.itemsToRemove.length))]),
            _vm._v(" items will be "),
            _c("b", [_vm._v("permanently")]),
            _vm._v(" deleted!\n    "),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function ({ close, setDisabled }) {
          return [
            _c("v-btn", { attrs: { color: "grey" }, on: { click: close } }, [
              _vm._v("\n            Cancel\n        "),
            ]),
            _vm._v(" "),
            _c("v-spacer"),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: { color: "error" },
                on: {
                  click: function ($event) {
                    return _setup.onDelete(close, setDisabled)
                  },
                },
              },
              [_vm._v("\n            Delete\n        ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }