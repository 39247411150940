var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.address === null && !_vm.isLoading
        ? [
            _c("v-container", [
              _c(
                "div",
                [
                  _c("AddressNotFoundInfo", {
                    staticClass: "my-2",
                    attrs: {
                      address: _vm.addressId,
                      message:
                        "Provided identifier does not exist in the blockchain!",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.address !== null || _vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-center addressViewCards",
              staticStyle: { "background-color": "#fff", height: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "px-4 mt-4", staticStyle: { width: "100%" } },
                [
                  !_vm.isLoading && _vm.address
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm.address.id !== null
                            ? _c("AddressMetadataDetailModal", {
                                attrs: {
                                  currencies: _vm.currencies,
                                  owners: _vm.owners.data,
                                  categories: _vm.categories,
                                  addressMetadataDetail: _vm.address,
                                  isOwnersLoading: _vm.isOwnersLoading,
                                },
                                on: {
                                  "save-event": _vm.editAddressMetadata,
                                  "owner-filter-changed-event":
                                    _vm.ownerFilterChanged,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "showbutton",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "EditButton",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  text: "Address Metadata",
                                                  outlined: "",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  713093072
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.address.id === null
                            ? _c("AddressMetadataDetailModal", {
                                attrs: {
                                  currencies: _vm.currencies,
                                  owners: _vm.owners.data,
                                  categories: _vm.categories,
                                  addressMetadataDetail: _vm.address,
                                  isOwnersLoading: _vm.isOwnersLoading,
                                },
                                on: {
                                  "save-event": _vm.createAddressMetadata,
                                  "owner-filter-changed-event":
                                    _vm.ownerFilterChanged,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "showbutton",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "CreateButton",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  text: "Add new address metadata",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  875843079
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("AlarmSummaryMenu", {
                            attrs: {
                              address: _vm.address,
                              alarms: _vm.alarms.data,
                              currencies: _vm.currencies,
                              currency: _vm.currency,
                              "alarm-creating": _vm.alarmCreating,
                            },
                            on: {
                              "new-alarm-event": _vm.createAlarm,
                              "edit-alarm-event": _vm.editAlarm,
                              "delete-alarm-event": _vm.deleteAlarm,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "6", md: "4", cols: "12" } },
                        [
                          _c("FancyCard", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-1",
                                              staticStyle: {
                                                height: "24px",
                                                width: "24px",
                                              },
                                              attrs: { left: "" },
                                            },
                                            [
                                              _vm._v(
                                                "mdi-tag-text-outline\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(_vm._s(_vm.currency.name))]
                                          ),
                                          _vm._v(
                                            " Address\n                            "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _c("NameValueFlex", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "name",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n                                    Name:\n                                "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c("Copyable", {
                                                    attrs: {
                                                      "on-copy": _vm.addressId,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "text",
                                                          fn: function ({
                                                            on,
                                                            attr,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "fontMonospace",
                                                                      staticStyle:
                                                                        {
                                                                          "white-space":
                                                                            "nowrap",
                                                                          overflow:
                                                                            "hidden",
                                                                          "text-overflow":
                                                                            "ellipsis",
                                                                        },
                                                                    },
                                                                    "div",
                                                                    attr,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "stringMiddleShortener"
                                                                        )(
                                                                          _vm.addressId,
                                                                          50
                                                                        )
                                                                      ) +
                                                                      "\n                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      530307450
                                                    ),
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1624959544
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("NameValueFlex", {
                                        attrs: {
                                          loading: _vm.isLoading,
                                          "loading-width": "150",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "name",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n                                    Label:\n                                "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _vm.address.identity
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            label: "",
                                                            outlined: "",
                                                            color:
                                                              _vm.currency.unit,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "fontMonospace",
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "middle",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.address
                                                                      .identity
                                                                      .label !==
                                                                      ""
                                                                      ? _vm
                                                                          .address
                                                                          .identity
                                                                          .label
                                                                      : "Undefined"
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-italic",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            None\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          667792289
                                        ),
                                      }),
                                      _vm._v(" "),
                                      !_vm.isLoading && _vm.address
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-1" },
                                            _vm._l(
                                              _vm.address.categories,
                                              function (cat) {
                                                return _c(
                                                  "AddressCategoryIcon",
                                                  {
                                                    key: cat.id,
                                                    staticClass: "mr-2 mb-1",
                                                    attrs: {
                                                      iconItem: cat,
                                                      medium: "",
                                                    },
                                                  }
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4282118364
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { sm: "6", md: "8", cols: "12" } },
                        [
                          _vm.address &&
                          _vm.address.confirmedTransactions > 0 &&
                          _vm.address.confirmedTransactions < 35000
                            ? _c("ChartAddressSummary", {
                                attrs: { addressInfo: _vm.address },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "pt-4", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "3", sm: "6", cols: "12" } },
                        [
                          _c("FancyCard", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-1",
                                              staticStyle: {
                                                height: "24px",
                                                width: "24px",
                                              },
                                              attrs: { left: "" },
                                            },
                                            [
                                              _vm._v(
                                                "mdi-scale-balance\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Balance")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _c("NameValueFlex", {
                                        attrs: {
                                          loading: _vm.isLoading,
                                          "loading-width": "150",
                                          "name-width": "70",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "name",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-light subtitle-1",
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        Balance:\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticStyle: {
                                                            height: "26px",
                                                          },
                                                          attrs: {
                                                            color:
                                                              _vm.currency.unit,
                                                            label: "",
                                                            "text-color":
                                                              "white",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "subtitle-1 font-weight-bold",
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "middle",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "fiat-convertor",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      _vm
                                                                        .address
                                                                        .confirmedBalance,
                                                                    fiats:
                                                                      _vm
                                                                        .address
                                                                        .fiats,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "subtitle-1 pl-1 text--white",
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "middle",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "fiat-convertor",
                                                                {
                                                                  attrs: {
                                                                    "unit-only":
                                                                      "",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "mediumRight",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            fa-solid fa-coins\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          2491587017
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("v-expand-transition", [
                                        !_vm.isLoading && _vm.address
                                          ? _c(
                                              "div",
                                              [
                                                _vm.address
                                                  .unconfirmedTransaction > 0
                                                  ? _c("NameValueFlex", {
                                                      attrs: {
                                                        loading: _vm.isLoading,
                                                        "loading-width": "150",
                                                        "name-width": "110",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "name",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                top: "",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "activator",
                                                                                    fn: function ({
                                                                                      on,
                                                                                      attrs,
                                                                                    }) {
                                                                                      return [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              _vm._g(
                                                                                                _vm._b(
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ml-n2 mr-1",
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        top: "-2px",
                                                                                                        flex: "0 1 auto !important",
                                                                                                      },
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "info",
                                                                                                      },
                                                                                                  },
                                                                                                  "v-icon",
                                                                                                  attrs,
                                                                                                  false
                                                                                                ),
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                    mdi-information-outline\n                                                                "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                false,
                                                                                2597505493
                                                                              ),
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Address has unconfirmed transactions."
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-light subtitle-1 d-flex justify-center align-center flex-column",
                                                                        staticStyle:
                                                                          {
                                                                            "max-width":
                                                                              "100px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "my-n1",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                        Unconfirmed\n                                                    "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "my-n1",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                        Balance:\n                                                    "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                          {
                                                            key: "value",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            height:
                                                                              "26px",
                                                                          },
                                                                        attrs: {
                                                                          color:
                                                                            _vm
                                                                              .currency
                                                                              .unit,
                                                                          label:
                                                                            "",
                                                                          "text-color":
                                                                            "white",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "subtitle-1 font-weight-bold",
                                                                            staticStyle:
                                                                              {
                                                                                "vertical-align":
                                                                                  "middle",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "fiat-convertor",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .address
                                                                                        .confirmedBalance +
                                                                                      _vm
                                                                                        .address
                                                                                        .unconfirmedBalance,
                                                                                    fiats:
                                                                                      _vm
                                                                                        .address
                                                                                        .fiats,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "subtitle-1 pl-1 text--white",
                                                                            staticStyle:
                                                                              {
                                                                                "vertical-align":
                                                                                  "middle",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "fiat-convertor",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "unit-only":
                                                                                      "",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mediumRight",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                    fa-solid fa-coins\n                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        3073538565
                                                      ),
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _c("NameValueFlex", {
                                        attrs: {
                                          loading: _vm.isLoading,
                                          "loading-width": "100",
                                          "name-width": "70",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "name",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-light",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        Received:\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticStyle: {
                                                            height: "24px",
                                                          },
                                                          attrs: {
                                                            color: "success",
                                                            label: "",
                                                            "text-color":
                                                              "white",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "subtitle-2 font-weight-bold",
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "middle",
                                                              },
                                                            },
                                                            [
                                                              _vm.currency
                                                                .unit === "ETH"
                                                                ? _c("div", [
                                                                    _vm._v(
                                                                      "N/A"
                                                                    ),
                                                                  ])
                                                                : _c(
                                                                    "fiat-convertor",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          _vm
                                                                            .address
                                                                            .totalReceived,
                                                                        fiats:
                                                                          _vm
                                                                            .address
                                                                            .fiats,
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "subtitle-2 pl-1 text--white font-weight-light",
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "middle",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "fiat-convertor",
                                                                {
                                                                  attrs: {
                                                                    "unit-only":
                                                                      "",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "largeRight",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            mdi-download mdi-rotate-270\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          2994848988
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("NameValueFlex", {
                                        attrs: {
                                          loading: _vm.isLoading,
                                          "loading-width": "100",
                                          "name-width": "70",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "name",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-light",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        Sent:\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticStyle: {
                                                            height: "24px",
                                                          },
                                                          attrs: {
                                                            color: "red",
                                                            label: "",
                                                            "text-color":
                                                              "white",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "subtitle-2 font-weight-bold",
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "middle",
                                                              },
                                                            },
                                                            [
                                                              _vm.currency
                                                                .typeFamily ===
                                                              "ETH"
                                                                ? _c("div", [
                                                                    _vm._v(
                                                                      "N/A"
                                                                    ),
                                                                  ])
                                                                : _c(
                                                                    "fiat-convertor",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          _vm
                                                                            .address
                                                                            .totalSent,
                                                                        fiats:
                                                                          _vm
                                                                            .address
                                                                            .fiats,
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "subtitle-2 pl-1 text--white font-weight-light",
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "middle",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "fiat-convertor",
                                                                {
                                                                  attrs: {
                                                                    "unit-only":
                                                                      "",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "largeRight",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            mdi-upload mdi-rotate-90\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1757158607
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _vm.address?.existsInGraphDb
                                        ? _c("AddressBudget", {
                                            attrs: { address: _vm.addressId },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2336585231
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "5", sm: "6", cols: "12" } },
                        [
                          _c("FancyCard", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-exchange-alt",
                                            staticStyle: {
                                              "font-size": "22px",
                                              color: "grey",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-inline-block ml-3",
                                            },
                                            [_vm._v("Transactions")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _c("NameValueFlex", {
                                        attrs: {
                                          loading: _vm.isLoading,
                                          "loading-width": "150",
                                          nameFlex: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "name",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-light subtitle-1",
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        Confirmed Transactions:\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "subtitle-1 font-weight-bold",
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "middle",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatNumber"
                                                            )(
                                                              _vm.address
                                                                .confirmedTransactions
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          2232171656
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("v-expand-transition", [
                                        !_vm.isLoading && _vm.address
                                          ? _c(
                                              "div",
                                              [
                                                _vm.address
                                                  .unconfirmedTransaction > 0
                                                  ? _c("NameValueFlex", {
                                                      attrs: {
                                                        loading: _vm.isLoading,
                                                        "loading-width": "150",
                                                        nameFlex: "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "name",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                top: "",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "activator",
                                                                                    fn: function ({
                                                                                      on,
                                                                                      attrs,
                                                                                    }) {
                                                                                      return [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              _vm._g(
                                                                                                _vm._b(
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ml-n2 mr-1",
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        top: "-2px",
                                                                                                        flex: "0 1 auto !important",
                                                                                                      },
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "info",
                                                                                                      },
                                                                                                  },
                                                                                                  "v-icon",
                                                                                                  attrs,
                                                                                                  false
                                                                                                ),
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                    mdi-information-outline\n                                                                "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                false,
                                                                                2597505493
                                                                              ),
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Address has unconfirmed transactions."
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-light subtitle-1",
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "16px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                    Unconfirmed Transactions:\n                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                          {
                                                            key: "value",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "subtitle-1 font-weight-bold",
                                                                    staticStyle:
                                                                      {
                                                                        "vertical-align":
                                                                          "middle",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .address
                                                                            .unconfirmedTransaction
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        3985056760
                                                      ),
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _c("NameValueFlex", {
                                        attrs: {
                                          loading: _vm.isLoading,
                                          "loading-width": "100",
                                          "name-width": "70",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "name",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-light",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        First tx:\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _vm.address.firstTx
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-flex text-center justify-end",
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "middle",
                                                            "max-width": "100%",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "TransactionCopyable",
                                                            {
                                                              attrs: {
                                                                text: _vm
                                                                  .address
                                                                  .firstTx
                                                                  .txhash,
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                            },
                                                            [
                                                              _c("v-divider", {
                                                                attrs: {
                                                                  vertical: "",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-flex",
                                                              staticStyle: {
                                                                "max-width":
                                                                  "170px !important",
                                                                width: "100%",
                                                                "justify-content":
                                                                  "center",
                                                                "flex-shrink":
                                                                  "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.address
                                                                  .firstTx
                                                                  .blockHeight ===
                                                                  -1
                                                                  ? "span"
                                                                  : "router-link",
                                                                {
                                                                  tag: "router-link",
                                                                  class: [
                                                                    _vm.address
                                                                      .firstTx
                                                                      .blockHeight ===
                                                                    -1
                                                                      ? "disabled"
                                                                      : "enabled",
                                                                    "d-inline-block",
                                                                    "link",
                                                                  ],
                                                                  attrs: {
                                                                    to: {
                                                                      name: "Block",
                                                                      params: {
                                                                        currency:
                                                                          _vm
                                                                            .$route
                                                                            .params
                                                                            .currency,
                                                                        block:
                                                                          _vm
                                                                            .address
                                                                            .firstTx
                                                                            .blockHeight,
                                                                      },
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "TransactionDate",
                                                                    {
                                                                      attrs: {
                                                                        date: _vm._f(
                                                                          "moment"
                                                                        )(
                                                                          _vm
                                                                            .address
                                                                            .firstTx
                                                                            .blockTime,
                                                                          "YYYY-MM-DD HH:mm:ss Z"
                                                                        ),
                                                                        unconfirmed:
                                                                          _vm
                                                                            .address
                                                                            .firstTx
                                                                            .blockHeight ===
                                                                          -1,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "template",
                                                                        {
                                                                          slot: "tooltip",
                                                                        },
                                                                        [
                                                                          _vm
                                                                            .address
                                                                            .firstTx
                                                                            .blockHeight !==
                                                                          -1
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex justify-center align-center flex-column",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Block " +
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .address
                                                                                              .firstTx
                                                                                              .blockHeight
                                                                                          )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex justify-center align-center flex-column",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Unconfirmed transaction"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-italic",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        Unconfirmed\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1720953746
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("NameValueFlex", {
                                        attrs: {
                                          loading: _vm.isLoading,
                                          "loading-width": "100",
                                          "name-width": "70",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "name",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-light",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                         Last tx:\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _vm.address.lastTx
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-flex text-center justify-end",
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "middle",
                                                            "max-width": "100%",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "TransactionCopyable",
                                                            {
                                                              attrs: {
                                                                text: _vm
                                                                  .address
                                                                  .lastTx
                                                                  .txhash,
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                            },
                                                            [
                                                              _c("v-divider", {
                                                                attrs: {
                                                                  vertical: "",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-flex",
                                                              staticStyle: {
                                                                "max-width":
                                                                  "170px !important",
                                                                width: "100%",
                                                                "justify-content":
                                                                  "center",
                                                                "flex-shrink":
                                                                  "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.address
                                                                  .lastTx
                                                                  .blockHeight ===
                                                                  -1
                                                                  ? "span"
                                                                  : "router-link",
                                                                {
                                                                  tag: "router-link",
                                                                  class: [
                                                                    _vm.address
                                                                      .lastTx
                                                                      .blockHeight ===
                                                                    -1
                                                                      ? "disabled"
                                                                      : "enabled",
                                                                    "d-inline-block",
                                                                    "link",
                                                                  ],
                                                                  attrs: {
                                                                    to: {
                                                                      name: "Block",
                                                                      params: {
                                                                        currency:
                                                                          _vm
                                                                            .$route
                                                                            .params
                                                                            .currency,
                                                                        block:
                                                                          _vm
                                                                            .address
                                                                            .lastTx
                                                                            .blockHeight,
                                                                      },
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "TransactionDate",
                                                                    {
                                                                      attrs: {
                                                                        date: _vm._f(
                                                                          "moment"
                                                                        )(
                                                                          _vm
                                                                            .address
                                                                            .lastTx
                                                                            .blockTime,
                                                                          "YYYY-MM-DD HH:mm:ss Z"
                                                                        ),
                                                                        unconfirmed:
                                                                          _vm
                                                                            .address
                                                                            .lastTx
                                                                            .blockHeight ===
                                                                          -1,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "template",
                                                                        {
                                                                          slot: "tooltip",
                                                                        },
                                                                        [
                                                                          _vm
                                                                            .address
                                                                            .lastTx
                                                                            .blockHeight !==
                                                                          -1
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex justify-center align-center flex-column",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Block " +
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .address
                                                                                              .lastTx
                                                                                              .blockHeight
                                                                                          )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex justify-center align-center flex-column",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Unconfirmed transaction"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-italic",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        Unconfirmed\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          967579090
                                        ),
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3851487069
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            md: "4",
                            sm: "6",
                            cols: "12",
                            "order-sm": "4",
                          },
                        },
                        [
                          _c("FancyCard", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              filter: "opacity(0.6)",
                                              width: "24px",
                                              "margin-right": "8px",
                                            },
                                            attrs: {
                                              src: "/img/ico/owner-icon.svg",
                                              alt: "Owner",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Owners")]),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _vm.isLoading
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-skeleton-loader", {
                                                attrs: {
                                                  height: "173",
                                                  type: "image",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _vm.computedOwners.length > 0
                                                ? _c("v-data-table", {
                                                    staticClass: "border",
                                                    attrs: {
                                                      dense: "",
                                                      headers:
                                                        _vm.headersOwners,
                                                      items: _vm.computedOwners,
                                                      "hide-default-footer": "",
                                                      "items-per-page": -1,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function (props) {
                                                            return [
                                                              _c("tr", [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-overflow fontMonospace",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                " +
                                                                        _vm._s(
                                                                          props
                                                                            .item
                                                                            .name
                                                                        ) +
                                                                        "\n                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-overflow",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "PrimarySelector",
                                                                      {
                                                                        attrs: {
                                                                          isPrimary:
                                                                            props
                                                                              .item
                                                                              .isPrimary,
                                                                          item: props.item,
                                                                          currency:
                                                                            _vm.currency,
                                                                        },
                                                                        on: {
                                                                          "set-primary-event":
                                                                            _vm.setPrimaryOwner,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      970870782
                                                    ),
                                                  })
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "title font-weight-light text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    No owners assigned.\n                                "
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              19900840
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isLoading
                        ? _c(
                            "v-col",
                            {
                              staticClass: "justify-center",
                              attrs: { cols: "12", order: "10" },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("ProgressCircular", {
                                    staticClass: "pt-10",
                                    attrs: { color: _vm.currency.unit },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isLoading && _vm.address
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", order: "4" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "cardShadow",
                                  attrs: {
                                    outlined: "",
                                    disabled: !_vm.clusters.length > 0,
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "py-2 px-4" },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticStyle: { bottom: "3px" } },
                                        [
                                          _vm._v(
                                            "\n                                mdi-hub\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "title font-weight-light ml-2",
                                        },
                                        [_vm._v("Clusters")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pt-0 pb-4 px-4" },
                                    [
                                      _vm.clusters.length > 0
                                        ? _c("v-data-table", {
                                            staticClass: "border",
                                            attrs: {
                                              headers: _vm.headersCluster,
                                              items: _vm.clusters,
                                              "hide-default-footer": "",
                                              "items-per-page": -1,
                                              dense: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (props) {
                                                    return [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow text-left",
                                                          },
                                                          [
                                                            _c("EntityLabel", {
                                                              attrs: {
                                                                entity:
                                                                  props.item
                                                                    .cluster,
                                                                entityLabel:
                                                                  props.item
                                                                    .label,
                                                                owner:
                                                                  props.item
                                                                    .owner,
                                                                color: props
                                                                  .item
                                                                  .categories[0]
                                                                  ? props.item
                                                                      .categories[0]
                                                                      .color
                                                                  : undefined,
                                                                isCopyable: true,
                                                                showExtra: false,
                                                                actAsLink: true,
                                                                currency:
                                                                  _vm.currency
                                                                    .unit,
                                                                "is-address": false,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatNumber"
                                                                  )(
                                                                    props.item
                                                                      .totalAddresses
                                                                  )
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow text-left",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  props.item
                                                                    .isCustom
                                                                    ? "custom"
                                                                    : "co-spent"
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow text-left fontMonospace",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  props.item
                                                                    .owner
                                                                    ? props.item
                                                                        .owner
                                                                        .name
                                                                    : ""
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow",
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                staticClass:
                                                                  "justify-left",
                                                              },
                                                              [
                                                                _vm._l(
                                                                  props.item.categories.slice(
                                                                    0,
                                                                    10
                                                                  ),
                                                                  function (
                                                                    cat
                                                                  ) {
                                                                    return _c(
                                                                      "AddressCategoryIcon",
                                                                      {
                                                                        key: cat.id,
                                                                        attrs: {
                                                                          iconItem:
                                                                            cat,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "BaseButtonShowMoreItems",
                                                                  {
                                                                    attrs: {
                                                                      items:
                                                                        props
                                                                          .item
                                                                          .categories,
                                                                      shownItemsCount: 10,
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    props.item
                                                                      .categories,
                                                                    function (
                                                                      cat
                                                                    ) {
                                                                      return _c(
                                                                        "AddressCategoryIcon",
                                                                        {
                                                                          key: cat.id,
                                                                          staticClass:
                                                                            "mx-1",
                                                                          attrs:
                                                                            {
                                                                              iconItem:
                                                                                cat,
                                                                              medium:
                                                                                "",
                                                                              name: cat.name,
                                                                              color:
                                                                                cat.color,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "moment"
                                                                  )(
                                                                    props.item
                                                                      .created_at,
                                                                    "YYYY-MM-DD HH:mm:ss Z"
                                                                  )
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2358769643
                                            ),
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "title font-weight-light text-center",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.address.existsInGraphDb
                                                      ? "Address does not belong to any cluster."
                                                      : "Address does not exist in the graph database."
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isLoading && _vm.address
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", order: "5" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "cardShadow",
                                  attrs: {
                                    outlined: "",
                                    disabled: !_vm.cases.length > 0,
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "py-2 px-4" },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticStyle: { bottom: "3px" } },
                                        [
                                          _vm._v(
                                            "\n                                assignment\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "title font-weight-light ml-2",
                                        },
                                        [_vm._v("Cases")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pt-0 pb-4 px-4" },
                                    [
                                      _vm.cases.length > 0
                                        ? _c("v-data-table", {
                                            staticClass: "border",
                                            attrs: {
                                              headers: _vm.headersCases,
                                              items: _vm.cases,
                                              "hide-default-footer": "",
                                              "items-per-page": -1,
                                              dense: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (props) {
                                                    return [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow text-left",
                                                          },
                                                          [
                                                            _c(
                                                              "router-link",
                                                              {
                                                                staticClass:
                                                                  "link",
                                                                attrs: {
                                                                  to: {
                                                                    name: "CaseDetail",
                                                                    params: {
                                                                      caseId:
                                                                        props
                                                                          .item
                                                                          .id,
                                                                    },
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                " +
                                                                    _vm._s(
                                                                      props.item
                                                                        .title
                                                                    ) +
                                                                    "\n                                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow text-left",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                props.item
                                                                  .description
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow text-left",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                props.item
                                                                  .created_by
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "moment"
                                                                  )(
                                                                    props.item
                                                                      .reported_at,
                                                                    "YYYY-MM-DD HH:mm:ss Z"
                                                                  )
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              433663966
                                            ),
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "title font-weight-light text-center",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Address is not assigned to any case.\n                            "
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isLoading && _vm.address
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", order: "6" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "cardShadow",
                                  attrs: {
                                    outlined: "",
                                    disabled:
                                      !_vm.computedIdentities.length > 0,
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "py-2 px-4" },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-address-card",
                                        staticStyle: {
                                          "font-size": "24px",
                                          color: "grey",
                                          bottom: "3px",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "title font-weight-light ml-2",
                                        },
                                        [_vm._v("Identities")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pt-0 pb-4 px-4" },
                                    [
                                      _vm.computedIdentities.length > 0
                                        ? _c("v-data-table", {
                                            staticClass: "border",
                                            attrs: {
                                              headers: _vm.headers,
                                              items: _vm.computedIdentities,
                                              "items-per-page":
                                                _vm.computedIdentities.length >
                                                20
                                                  ? 20
                                                  : -1,
                                              "hide-default-footer":
                                                _vm.computedIdentities.length <=
                                                20,
                                              dense: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (props) {
                                                    return [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow center",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticStyle: {
                                                                  "text-decoration":
                                                                    "none",
                                                                },
                                                                attrs: {
                                                                  href: _vm._f(
                                                                    "createMailToConvertor"
                                                                  )(props.item),
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mail_outline"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow text-left fontMonospace",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                props.item.label
                                                              ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow text-left",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                props.item
                                                                  .source
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow text-left",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "link",
                                                                attrs: {
                                                                  href: props
                                                                    .item.url,
                                                                  target:
                                                                    "_blank",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "stringShortener"
                                                                    )(
                                                                      props.item
                                                                        .url,
                                                                      64
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "moment"
                                                                  )(
                                                                    props.item
                                                                      .created_at,
                                                                    "YYYY-MM-DD HH:mm:ss Z"
                                                                  )
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-overflow",
                                                          },
                                                          [
                                                            _c(
                                                              "PrimarySelector",
                                                              {
                                                                attrs: {
                                                                  isPrimary:
                                                                    props.item
                                                                      .isPrimary,
                                                                  item: props.item,
                                                                  currency:
                                                                    _vm.currency,
                                                                },
                                                                on: {
                                                                  "set-primary-event":
                                                                    _vm.setPrimaryIdentity,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2123232363
                                            ),
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "title font-weight-light text-center",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Address is not assigned to any identity.\n                            "
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }