var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationProvider", {
    attrs: { name: _vm.$attrs.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors }) {
          return [
            _c(
              "v-autocomplete",
              _vm._g(
                _vm._b(
                  {
                    attrs: { "error-messages": errors },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.$scopedSlots, function (_, slot) {
                          return {
                            key: slot,
                            fn: function (scope) {
                              return [_vm._t(slot, null, null, scope)]
                            },
                          }
                        }),
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.innerValue,
                      callback: function ($$v) {
                        _vm.innerValue = $$v
                      },
                      expression: "innerValue",
                    },
                  },
                  "v-autocomplete",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }