<template>
    <ValidationObserver ref="validator" mode="lazy">
        <v-text-field-validateable
            :dense="dense"
            :rules="rules"
            @keyup.enter="validate"
            v-model.trim="innerValue"
            :label="label"
            append-icon="youtube_searched_for"
            outlined
            clearable
            :disabled="disabled"
        />
    </ValidationObserver>
</template>

<script>

export default {
    name: "ValidatableSearchTextField",
    props: {
        rules: {
            type: [Object, String],
            default: ""
        },
        label: {
            type: String,
            default: "Search"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        innerValue: ""
    }),
    methods: {
        async validate() {
            const isValid = await this.$refs.validator.validate();
            if (isValid && this.innerValue) {
                this.$emit("search-event", { searchQuery: this.innerValue })
                this.innerValue = ""
            }

        }
    }
}
</script>
