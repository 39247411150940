var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.outerStyle },
    [
      _c("v-hover", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ hover }) {
              return [
                _c(
                  "div",
                  { staticClass: "wrapper" },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attr }) {
                                return [
                                  _c(
                                    "RouteToTransaction",
                                    {
                                      attrs: {
                                        transaction: _vm.text,
                                        width100: "",
                                        "no-decoration": "",
                                        "icon-link": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "text" },
                                        [
                                          _c("v-hover", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    hover: hover2,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "text-ellipsis-middle position-relative",
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "div",
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "fontMonospace",
                                                                staticStyle: {
                                                                  position:
                                                                    "relative",
                                                                  left: "1px",
                                                                },
                                                              },
                                                              "div",
                                                              attr,
                                                              false
                                                            ),
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .transactionId[0]
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "fontMonospace",
                                                                staticStyle: {
                                                                  position:
                                                                    "relative",
                                                                  right: "1px",
                                                                },
                                                              },
                                                              "div",
                                                              attr,
                                                              false
                                                            ),
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .transactionId[1]
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", { staticClass: "fontMonospace" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.text) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    hover
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attr }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "clipboard",
                                                  rawName: "v-clipboard:copy",
                                                  value: _vm.text,
                                                  expression: "text",
                                                  arg: "copy",
                                                },
                                              ],
                                              staticStyle: {
                                                padding: "4px",
                                                right: "-1px",
                                                "background-color": "white",
                                              },
                                              attrs: {
                                                absolute: "",
                                                "min-width": "0px",
                                                "x-small": "",
                                                elevation: "0",
                                              },
                                              on: {
                                                click: [
                                                  function ($event) {
                                                    $event.preventDefault()
                                                  },
                                                  function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.onCopyEvent.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            "v-btn",
                                            attr,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("content_copy")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "\n                            Copy Transaction ID\n                        "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }