<template>
    <div>
        <v-data-table
            style="white-space: nowrap"
            dense
            :headers="headers"
            :items="computedData"
            :server-items-length="totalItems"
            :options="filter"
            :loading="loading"
            :loading-text="loadingText"
            :footer-props="footerProps"
            @pagination="debouncePagination($event)"
            @update:sort-by="handleSortBy($event)"
            @update:sort-desc="handleSortDesc($event)"
        >
            <template slot="item" slot-scope="props">
                <slot name="tableBody" :props="props"/>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                <span>
                    {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                </span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import RouteToAddress from "@/components/common/RouteToAddress";
import FiatConvertor from "@/components/common/FiatConvertor";
import RouteToTransaction from "@/components/common/RouteToTransaction";
import {deepClone, isObjectEmpty, timeDifference} from "@/utils";
import {mapGetters} from "vuex";
import Enumerable from "linq";
import Copyable from "@/components/common/Copyable";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import debounce from "debounce";
export default {
    name: "SyncedTable",
    mixins: [routeSyncMixin],
    components: {Copyable, RouteToTransaction, FiatConvertor, RouteToAddress},
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        loadingText: {
            type: String,
            required: false,
            default: "Loading ...",
        },
        footerProps: {
            type: Object,
            required: false,
            default: undefined
        },
        data: {
            type: Array,
            required: false,
            default: () => []
        },
        headers: {
            type: Array,
            required: true,
        },
        totalItems: {
            type: Number,
            required: true
        },
        fallback: {
        }
    },
    computed: {
        computedData() {
            //add relative-time data a sort by filters
            let addresses = Enumerable.from(this.data.map(x => {
                return {
                    ...x,
                    firstblocktimeRel: timeDifference(x.firstblocktime),
                    lastblocktimeRel: timeDifference(x.lastblocktime)
                }
            }))
            return (this.filter.sortDesc[0]
                    ? addresses.orderByDescending((i) => i[this.filter.sortBy[0]])
                    : addresses.orderBy((i) => i[this.filter.sortBy[0]])
            ).toArray();
        },
    },
    data() {
        return {
            filterInfo: {
                page: { type: "Number", default: () => 1 },
                itemsPerPage: { type: "Number", default: () => 20 },
                sortBy: { type: "ArrayOfStrings", default: () => [] },
                sortDesc: { type: "ArrayOfBooleans", default: () => [] },
            }
        }
    },
    created()
    {
        if (isObjectEmpty(this.$route.query) && this.fallback)
        {
            this.$_syncToRouteQuery(true, deepClone(this.fallback))
        }
    },
    methods: {
        handleOptions(e)
        {
            if (e.page !== this.filter.page && e.itemsPerPage !== this.filter.itemsPerPage)
            {
                this.filter = {...this.filter, page: e.page, itemsPerPage: e.itemsPerPage}
                this.$emit('fetch-event',deepClone(this.filter))
            } else if (e.page !== this.filter.page) {
                this.filter = {...this.filter, page: e.page}
                this.$emit('fetch-event',deepClone(this.filter))
            } else if (e.itemsPerPage !== this.filter.itemsPerPage) {
                this.filter = {...this.filter, itemsPerPage: e.itemsPerPage}
                this.$emit('fetch-event',deepClone(this.filter))
            }
        },
        debouncePagination: debounce(function (e) {
            this.handleOptions(e)
        }, 300),
        handleSortBy(e)
        {
            if (this.filter.sortBy[0] !== e)
                this.filter = {...this.filter, sortBy: e ? [e] : [], sortDesc: []}
        },
        handleSortDesc(e)
        {
            if (this.filter.sortDesc[0] !== e)
                this.filter = {...this.filter, sortDesc: [e]}
        },


        // -- mixin methods --
        onFilterInitial()
        {
            //Initial Filter was not default (and was read from the route)
        },
        onFilterChange()
        {
            //Filter Changed
        },
        onFilterLoaded()
        {
            this.$emit('fetch-event',deepClone(this.filter))
        }
    }
}
</script>

<style>

</style>