<script setup>

import {computed, inject, useListeners} from "vue";
import ClusterCreateCustomDialog from "@/views/Cluster/Dialogs/ClusterCreateCustomDialog.vue";
import ClusterMergeDialog from "@/views/Cluster/Dialogs/ClusterMergeDialog.vue";

const data = inject("data")
const loading = inject("Loading")

const listeners = useListeners()
const isMergeDisabled = computed(() => !data?.value?.cluster?.isCustom)

</script>

<template>
    <div>
        <ClusterCreateCustomDialog :disabled="loading" redirect/>
        <ClusterMergeDialog :currency-unit="data.currencyUnit" :disabled="isMergeDisabled || loading" v-on="listeners"/>
    </div>
</template>

<style scoped>

</style>