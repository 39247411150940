<script setup>
import {useAttrs} from "vue";

const emit = defineEmits(['click'])
const attrs = useAttrs()
</script>

<template>
    <v-btn dark class="ma-1" color="tertiary" small v-bind="attrs" @click="emit('click')">
        <v-icon left>
            <slot name="icon">
                mdi-magnify
            </slot>
        </v-icon>
        <slot name="default">
            Search
        </slot>
    </v-btn>
</template>