var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-icon", { staticClass: "ml-1", attrs: { left: "" } }, [
                  _vm._v("mdi-hub"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_setup.currency.name)),
                ]),
                _vm._v(" Cluster\n        "),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(_setup.NameValueFlex, {
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function () {
                    return [_vm._v("\n                Name:\n            ")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _c(_setup.Copyable, {
                        attrs: { "on-copy": _setup.clusterName },
                        scopedSlots: _vm._u([
                          {
                            key: "text",
                            fn: function ({ on, attr }) {
                              return [
                                _c(
                                  "div",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "fontMonospace",
                                        staticStyle: {
                                          "white-space": "nowrap",
                                          overflow: "hidden",
                                          "text-overflow": "ellipsis",
                                        },
                                      },
                                      "div",
                                      attr,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(_vm._s(_setup.clusterName))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(_setup.NameValueFlex, {
              attrs: { loading: _setup.loading, "loading-width": "150" },
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function () {
                    return [_vm._v("\n                Type:\n            ")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-italic" }, [
                        _vm._v(
                          _vm._s(
                            _setup.data.cluster.isCustom ? "Custom" : "co-spent"
                          )
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(_setup.NameValueFlex, {
              attrs: { loading: _setup.loading, "loading-width": "100" },
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function () {
                    return [
                      _vm._v("\n                # of Addresses:\n            "),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "fontMonospace" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("formatNumber")(
                                _setup.data.cluster.totalAddresses
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(_setup.NameValueFlex, {
              attrs: { loading: _setup.loading, "loading-width": "130" },
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function () {
                    return [
                      _vm._v("\n                Description:\n            "),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "d-flex" }, [
                        _setup.computedIsDescValid && !_setup.showDesc
                          ? _c(
                              "div",
                              {
                                staticClass: "fontMonospace",
                                staticStyle: {
                                  "white-space": "nowrap",
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_setup.data.cluster.description) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: {
                              "flex-grow": "0",
                              "flex-shrink": "0",
                            },
                          },
                          [
                            _setup.computedIsDescValid
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "font-italic",
                                    staticStyle: {
                                      height: "23px",
                                      width: "88px",
                                      "background-color": "white",
                                    },
                                    attrs: { elevation: "0", small: "" },
                                    on: {
                                      click: function ($event) {
                                        _setup.showDesc = !_setup.showDesc
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _setup.showDesc ? "Hide" : "Show"
                                        ) +
                                        "\n                            "
                                    ),
                                    _c("v-spacer"),
                                    _vm._v(" "),
                                    _setup.showDesc
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { right: "" } },
                                              [_vm._v("mdi-chevron-up")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "span",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { right: "" } },
                                              [_vm._v("mdi-chevron-down")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        !_setup.computedIsDescValid
                          ? _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "\n                        None\n                    "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("v-expand-transition", [
              _setup.showDesc
                ? _c(
                    "div",
                    [
                      _setup.data
                        ? _c("v-textarea", {
                            staticClass: "clusterDescriptionArea",
                            staticStyle: { "font-size": "14px" },
                            attrs: {
                              disabled: _setup.computedDisableDesc,
                              readonly: "",
                              "no-resize": "",
                              dense: "",
                              name: "Description",
                            },
                            model: {
                              value: _setup.data.cluster.description,
                              callback: function ($$v) {
                                _vm.$set(
                                  _setup.data.cluster,
                                  "description",
                                  $$v
                                )
                              },
                              expression: "data.cluster.description",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            !_setup.loading && _setup.data
              ? _c(
                  "div",
                  { staticClass: "mt-1" },
                  _vm._l(_setup.data.cluster.categories, function (cat) {
                    return _c(_setup.AddressCategoryIcon, {
                      key: cat.id,
                      staticClass: "mr-2 mb-1",
                      attrs: { iconItem: cat, medium: "" },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }