var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    !_vm.loading
      ? _c("span", { staticClass: "textGrey" }, [_vm._t("default")], 2)
      : _vm.loading
      ? _c(
          "span",
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", size: 24, color: "primary" },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }