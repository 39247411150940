var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "ma-0" },
    [
      _c(
        "v-col",
        {
          staticClass: "pa-0",
          staticStyle: { position: "absolute", left: "0" },
        },
        [
          _c("AddNewNodeModal", {
            ref: "AddNewNodeModal",
            on: { "add-node-event": _vm.addNewGraphNode },
          }),
          _vm._v(" "),
          _c("GraphConfigurationDetailModal", {
            ref: "GraphConfigurationDetailModal",
            on: { "save-graph-config-event": _vm.createGraphConfiguration },
          }),
          _vm._v(" "),
          _c("GraphVisualization", {
            ref: "graph",
            attrs: {
              config: _vm.config,
              loading: _vm.graphLoading,
              elements: _vm.elements,
              nameOfExportedGraph: _vm.nameOfExportedGraph,
            },
            on: {
              "graph-click-event": _vm.graphNodeClick,
              "before-destroy-event": _vm.cleanUpGraphData,
            },
            scopedSlots: _vm._u([
              {
                key: "actionbuttons",
                fn: function () {
                  return [
                    _c("AddNewNodeButton", {
                      attrs: { color: _vm.currency.unit },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.AddNewNodeModal.openModal()
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("SaveButton", {
                      attrs: { color: _vm.currency.unit },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.GraphConfigurationDetailModal.openModal()
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("router-view"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        {
          staticClass: "graph-panel px-0 pt-0",
          staticStyle: { position: "fixed", right: "0" },
          attrs: { cols: "2" },
        },
        [
          _c("GraphHistoryPanel", {
            attrs: {
              graphIsLoading: _vm.graphLoading,
              graphData: _vm.graphData,
            },
            on: {
              "change-graph-configuration-event": _vm.changeGraphConfiguration,
              "delete-graph-configuration-event": _vm.deleteGraphConfiguration,
              "update-graph-configuration-event": _vm.updateGraphConfiguration,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }