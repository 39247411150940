var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-y height100" },
    [
      _c(
        "v-container",
        { staticClass: "height100 px-4", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "" } },
            [
              _c("h1", [_vm._v("Alarms")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("alarm-detail-modal", {
                attrs: { currencies: _vm.currencies, "new-alarm": "" },
                on: { "save-event": _vm.createAlarm },
                scopedSlots: _vm._u([
                  {
                    key: "showbutton",
                    fn: function ({ on: on }) {
                      return [
                        _c(
                          "CreateButton",
                          _vm._g({ attrs: { text: "Create alarm" } }, on)
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-divider"),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "elevation-0 border mt-2",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.computedAlarms,
                  "server-items-length": _vm.alarms.total
                    ? _vm.alarms.total
                    : 0,
                  loading: _vm.alarmsLoading,
                  options: _vm.filter,
                  dense: "",
                  "footer-props": {
                    "items-per-page-options": [15, 30, 50, -1],
                    "show-current-page": true,
                    showFirstLastPage: true,
                  },
                },
                on: {
                  "update:options": function ($event) {
                    _vm.filter = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.headers, function (h) {
                      return {
                        key: `header.${h.value}`,
                        fn: function ({ header }) {
                          return [
                            h.value === "name"
                              ? _c("v-text-field", {
                                  key: h.value,
                                  staticClass: "text-field-label-style",
                                  attrs: {
                                    "append-icon": "search",
                                    label: header.text,
                                    "single-line": "",
                                    "hide-details": "",
                                    clearable: "",
                                  },
                                  on: { input: _vm.debounceInput },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  model: {
                                    value: _vm.debouncedFilter.filterTitle,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.debouncedFilter,
                                        "filterTitle",
                                        $$v
                                      )
                                    },
                                    expression: "debouncedFilter.filterTitle",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            h.value === "username"
                              ? _c("v-text-field", {
                                  key: h.value,
                                  staticClass: "text-field-label-style",
                                  attrs: {
                                    "append-icon": "search",
                                    label: header.text,
                                    "single-line": "",
                                    "hide-details": "",
                                    clearable: "",
                                  },
                                  on: { input: _vm.debounceInput },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  model: {
                                    value: _vm.debouncedFilter.filterUser,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.debouncedFilter,
                                        "filterUser",
                                        $$v
                                      )
                                    },
                                    expression: "debouncedFilter.filterUser",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            h.value === "notify"
                              ? _c("v-select", {
                                  key: h.value,
                                  staticClass: "text-field-label-style",
                                  attrs: {
                                    label: header.text,
                                    "single-line": "",
                                    "hide-details": "",
                                    items: _vm.notifiedByOptions,
                                    "item-value": "value",
                                    clearable: "",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  model: {
                                    value: _vm.filter.filterVia,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "filterVia", $$v)
                                    },
                                    expression: "filter.filterVia",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            h.value === "coin_id"
                              ? _c(
                                  "div",
                                  {
                                    key: h.value,
                                    staticStyle: {
                                      "margin-bottom": "auto",
                                      "padding-bottom": "auto",
                                    },
                                  },
                                  [
                                    _c("BlockchainCryptoPickerSlim", {
                                      attrs: {
                                        currencies:
                                          _vm.allCurrenciesExceptUnknown,
                                        dense: "",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.filter.filterCurrency,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "filterCurrency",
                                            $$v
                                          )
                                        },
                                        expression: "filter.filterCurrency",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            h.value === "hash"
                              ? _c("v-text-field", {
                                  key: h.value,
                                  staticClass: "text-field-label-style",
                                  attrs: {
                                    "append-icon": "search",
                                    label: header.text,
                                    "single-line": "",
                                    "hide-details": "",
                                    clearable: "",
                                  },
                                  on: { input: _vm.debounceInput },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  model: {
                                    value: _vm.debouncedFilter.filterAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.debouncedFilter,
                                        "filterAddress",
                                        $$v
                                      )
                                    },
                                    expression: "debouncedFilter.filterAddress",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            h.value === "created_at"
                              ? _c(
                                  "div",
                                  {
                                    key: h.value,
                                    staticClass: "datepicker-container",
                                  },
                                  [
                                    _c("DateTimePicker", {
                                      attrs: {
                                        label: h.text,
                                        disabledTime: true,
                                      },
                                      model: {
                                        value: _vm.filter.filterCreatedAt,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "filterCreatedAt",
                                            $$v
                                          )
                                        },
                                        expression: "filter.filterCreatedAt",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            h.value === "count"
                              ? _c("v-text-field", {
                                  key: h.value,
                                  staticClass: "text-field-label-style",
                                  staticStyle: { "min-width": "90px" },
                                  attrs: {
                                    label: header.text,
                                    "single-line": "",
                                    "hide-details": "",
                                    type: "number",
                                    min: 0,
                                    "hide-spin-buttons": "",
                                    clearable: "",
                                  },
                                  on: { input: _vm.debounceInput },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  model: {
                                    value: _vm.debouncedFilter.filterRecords,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.debouncedFilter,
                                        "filterRecords",
                                        $$v
                                      )
                                    },
                                    expression: "debouncedFilter.filterRecords",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            h.value === "tx_direction"
                              ? _c("v-select", {
                                  key: h.value,
                                  staticClass: "text-field-label-style",
                                  staticStyle: { "min-width": "90px" },
                                  attrs: {
                                    label: header.text,
                                    "single-line": "",
                                    "hide-details": "",
                                    items: _vm.directionOptions,
                                    "return-object": false,
                                    clearable: "",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  model: {
                                    value: _vm.filter.filterTxDirection,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "filterTxDirection",
                                        $$v
                                      )
                                    },
                                    expression: "filter.filterTxDirection",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            h.value === "type"
                              ? _c("v-select", {
                                  key: h.value,
                                  staticClass: "text-field-label-style",
                                  staticStyle: { "min-width": "100px" },
                                  attrs: {
                                    label: header.text,
                                    "single-line": "",
                                    pointer: "",
                                    "hide-details": "",
                                    items: _vm.alarmTypeOptions,
                                    "return-object": false,
                                    clearable: "",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  model: {
                                    value: _vm.filter.filterType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "filterType", $$v)
                                    },
                                    expression: "filter.filterType",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            h.value === "action"
                              ? _c(
                                  "div",
                                  {
                                    key: h.value,
                                    staticStyle: { width: "60px" },
                                  },
                                  [_c("h3", [_vm._v(_vm._s(h.text))])]
                                )
                              : _vm._e(),
                          ]
                        },
                      }
                    }),
                    {
                      key: "item",
                      fn: function ({ item }) {
                        return [
                          _c("tr", [
                            _c(
                              "td",
                              { staticClass: "text-left text-truncate" },
                              [
                                _c("alarm-detail-modal", {
                                  attrs: {
                                    currencies: _vm.currencies,
                                    alarm: {
                                      crypto: item.address.crypto,
                                      hash: item.address.hash,
                                      username: item.user.name,
                                      ...item,
                                    },
                                    "existing-alarm": "",
                                  },
                                  on: {
                                    "save-event": _vm.editAlarm,
                                    "delete-event": _vm.deleteAlarm,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "showbutton",
                                        fn: function ({ on: one }) {
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                {
                                                  staticClass: "link",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                },
                                                one
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(item.name) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-overflow text-left" },
                              [_vm._v(_vm._s(item.user.name))]
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-overflow" }, [
                              item.notify === "email" || item.notify === "both"
                                ? _c("span", [_c("notification-email")], 1)
                                : _vm._e(),
                              _vm._v(" "),
                              item.notify === "rest" || item.notify === "both"
                                ? _c("span", [_c("notification-rest")], 1)
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "text-overflow",
                                staticStyle: {
                                  "text-align": "center",
                                  "vertical-align": "middle",
                                },
                              },
                              [
                                _c("BlockchainCryptoBadge", {
                                  attrs: {
                                    currencies: _vm.currencies,
                                    crypto: item.address.crypto,
                                    iconWidth: 24,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("EntityLabel", {
                                  attrs: {
                                    isAddress: item.type !== "custom",
                                    currency: item.address.crypto,
                                    isCopyable: true,
                                    actAsLink: "",
                                    entity: item.address.hash,
                                    justify: "start",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-overflow" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      item.created_at,
                                      "YYYY-MM-DD HH:mm:ss Z"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "text-overflow text-right",
                                staticStyle: {
                                  "text-align": "center",
                                  "vertical-align": "middle",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("formatNumber")(
                                        item.notifications_count
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "text-overflow text-left",
                                staticStyle: {
                                  "text-align": "center",
                                  "vertical-align": "middle",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.tx_direction) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "text-overflow text-left",
                                staticStyle: {
                                  "text-align": "center",
                                  "vertical-align": "middle",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.type) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("alarm-detail-modal", {
                                  attrs: {
                                    currencies: _vm.currencies,
                                    alarm: {
                                      crypto: item.address.crypto,
                                      hash: item.address.hash,
                                      username: item.user.name,
                                      ...item,
                                    },
                                    "existing-alarm": "",
                                  },
                                  on: {
                                    "save-event": _vm.editAlarm,
                                    "delete-event": _vm.deleteAlarm,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "showbutton",
                                        fn: function ({ on: on }) {
                                          return [
                                            _c(
                                              "TableInfoButton",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    "small-icon": "",
                                                    color: "blue",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("TableDeleteModalDialog", {
                                  attrs: {
                                    description: `Do you really want to delete alarm ${item.name}?`,
                                    itemToDelete: item,
                                    tooltip: "Delete Alarm",
                                  },
                                  on: { "delete-event": _vm.deleteAlarm },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "footer.page-text",
                      fn: function ({ pageStart, pageStop, itemsLength }) {
                        return [
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm._f("formatNumber")(pageStart)) +
                                " - " +
                                _vm._s(_vm._f("formatNumber")(pageStop)) +
                                " of " +
                                _vm._s(_vm._f("formatNumber")(itemsLength)) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "150" },
              model: {
                value: _vm.showLoading,
                callback: function ($$v) {
                  _vm.showLoading = $$v
                },
                expression: "showLoading",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("ProgressCircular", {
                    staticClass: "pa-0 ma-0",
                    attrs: { color: "primary" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.alarmSingle
            ? _c("alarm-detail-modal", {
                attrs: {
                  currencies: _vm.currencies,
                  alarm: {
                    crypto: _vm.alarmSingle.address.crypto,
                    hash: _vm.alarmSingle.address.hash,
                    username: _vm.alarmSingle.user.name,
                    ..._vm.alarmSingle,
                  },
                  "existing-alarm": "",
                },
                on: {
                  "save-event": _vm.editAlarm,
                  "delete-event": _vm.deleteAlarm,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "showbutton",
                      fn: function ({ on: on }) {
                        return [
                          _c("button", {
                            ref: "openModalButton",
                            staticClass: "d-none",
                            on: {
                              click: function ($event) {
                                return on.click($event)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2437443098
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }