export enum Crypto {
    Bitcoin = "BTC",
    Litecoin = "LTC",
    Dash = "DASH",
    Zcash = "ZEC",
    BitcoinCash = "BCH",
    Digibyte = "DGB",
    BitcoinGold = "BTG",
    Qtum = "QTUM",
    Ethereum = "ETH",
    Unknown = ""
}

export interface IServerSideCurrency {
    id: number,
    unit: Crypto,
    name: string,
    regexp: string
    ws: string,
    image: string,
    imageWhite: string,
    hexColor: string,
    typeFamily: string,
}

export interface ICurrency {
    //obtained through settings
    id: number,
    unit: Crypto,
    name: string,
    regexp: string,
    ws: string,
    typeFamily: string,
    image: string
    imageWhite: string
    hexColor: string

    //enriched attributes
    isValid(value: string): boolean,
}


/*
let currencyColors = {
    BTC: "#F7931A",
    LTC: "#9c9898",
    DASH: "#008ce7",
    ZEC: "#ECB244",
    BCH: "#8dc351",
    DGB: "#006ad2",
    BTG: "#eba809",
    QTUM: "#2e9ad0",
    ETH: "#3c3c3d"
}


let currencyTypes = {
    BTC: Crypto.Bitcoin,
    LTC: Crypto.Bitcoin,
    DASH: Crypto.Bitcoin,
    ZEC: Crypto.Bitcoin,
    BCH: Crypto.Bitcoin,
    DGB: Crypto.Bitcoin,
    BTG: Crypto.Bitcoin,
    QTUM: Crypto.Bitcoin,
    ETH: Crypto.Ethereum,
}
*/

let validators = []

let addValidators = (currencies: IServerSideCurrency[] ) => {
    currencies.forEach(currency => {
        validators.push((value) => new RegExp(currency.regexp.slice(1, -1)).test(value))
    })
}

let isAddressValid = (value: string): boolean => validators.some(f => f(value))

let enrichCurrencies = (currencies: IServerSideCurrency[] ): ICurrency[] => {
    return currencies.map( currency => {
        return {...currency,
            isValid: (value: string) : boolean => new RegExp(currency.regexp.slice(1, -1)).test(value),
        }
    })
}

export default { enrichCurrencies, isAddressValid, Crypto, addValidators }