<template>
    <div class="height100 scroll-y">
        <div v-if="isLoading">
            <ProgressCircular class="pt-10" :color="currency.unit" />
        </div>

        <v-container v-if="!isLoading && !address" >
            <div v-if="address === null || !addressId">
                <AddressNotFoundInfo
                    class="my-2"
                    :address="addressId"
                    :message="'Provided identifier does not exist in the blockchain!'"
                />
            </div>
        </v-container>

        <div v-if="!isLoading && address" >
            <LimitAbleLayoutOld @reload-data="reloadData" :initLimitValue="limitValue">
                <template slot="title">
                    <h1>Balance Table in {{ displayCurrency }}</h1>
                </template>
                <template slot="body">
                    <v-row dense class="mb-0" justify="end">
                        <v-col cols="auto">
                            <ExportMenu
                                :base-url="`${currency.unit}/cryptoaddress/${addressId}/balances`"/>
                        </v-col>
                    </v-row>
                    <ProgressCircular v-if="balanceTreeData === null"/>

                    <div v-else>
                        <v-card class="border" elevation="0">
                            <v-card-title>
                                <h3>Full-Text Search:</h3>
                                <v-spacer/>
                                <v-text-field
                                    v-model="search"
                                    append-icon="search"
                                    label="search table"
                                    single-line
                                    hide-details
                                />
                            </v-card-title>

                            <v-data-table
                                dense
                                :headers="headers"
                                :search="search"
                                :items="balanceTreeData"
                                :loading="loading"
                                class="border"
                                :items-per-page="Number(limitValue) || -1"
                                hide-default-footer
                            >
                                <template
                                    v-for="h in headers"
                                    v-slot:[`header.${h.value}`]="{ header }"
                                >
                                    <v-tooltip :key="h.text" bottom>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">{{ h.text }}</span>
                                        </template>
                                        <span>{{ h.tooltip }}</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:item="props">
                                    <tr>
                                        <td>
                                            <RouteToTransaction :transaction="props.item.txid">
                                                <template slot="text">
                                                    {{(props.item.timestamp * 1000) | formatDateTime }}
                                                </template>
                                            </RouteToTransaction>
                                        </td>

                                        <td class="text-right">
                                            <fiatConvertor
                                            :value="props.item.balanceBefore"
                                            :fiats="props.item.fiats"
                                        />
                                        </td>
                                        <td class="text-right" style="color: darkgoldenrod">
                                            <nobr>{{ props.item.change > 0 ? '+' : '-'}}
                                            <fiatConvertor
                                                absoluteValue
                                                :value="props.item.change"
                                                :fiats="props.item.fiats"
                                            /></nobr>
                                        </td>
                                        <td class="text-right">
                                            <fiatConvertor
                                                :value="props.item.balanceAfter"
                                                :fiats="props.item.fiats"
                                            />
                                        </td>
                                        <td class="text-right" style="color: red">
                                            <nobr>-<fiatConvertor
                                                :value="props.item.sumOfAddrInputs"
                                                :fiats="props.item.fiats"
                                            /></nobr>
                                        </td>
                                        <td class="text-right">
                                            {{
                                                $humanize.formatNumber(
                                                    props.item.percentileOfSumInputs * 100,
                                                    0
                                                )
                                            }}
                                        </td>

                                        <td class="text-right">
                                            {{ props.item.numOfAddrInputs }}
                                        </td>
                                        <td class="text-right">{{ props.item.numOfAllInputs }}</td>
                                        <td class="text-right">
                                            {{
                                                $humanize.formatNumber(
                                                    props.item.percentileOfNumInputs * 100,
                                                    0
                                                )
                                            }}
                                        </td>

                                        <td class="text-right" style="color: green">
                                            <nobr>+<fiatConvertor
                                                :value="props.item.sumOfAddrOutputs"
                                                :fiats="props.item.fiats"
                                            /></nobr>
                                        </td>
                                        <td class="text-right">
                                            {{
                                                $humanize.formatNumber(
                                                    props.item.percentileOfSumOutputs * 100,
                                                    0
                                                )
                                            }}
                                        </td>

                                        <td class="text-right">
                                            {{ props.item.numOfAddrOutputs }}
                                        </td>
                                        <td class="text-right">
                                            {{ props.item.numOfAllOutputs }}
                                        </td>
                                        <td class="text-right">
                                            {{
                                                $humanize.formatNumber(
                                                    props.item.percentileOfNumOutputs * 100,
                                                    0
                                                )
                                            }}
                                        </td>

                                        <td class="text-right">
                                            <fiatConvertor
                                                :value="props.item.sumOfAllOutputs"
                                                :crypto-dec-places="4"
                                                :fiats="props.item.fiats"
                                            />
                                        </td>
                                        <td class="text-right">
                                            <fiatConvertor
                                            :value="props.item.fee"
                                            :crypto-dec-places="4"
                                            :fiats="props.item.fiats"
                                        />
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>

                        </v-card>
                    </div>
                </template>
            </LimitAbleLayoutOld>
        </div>
    </div>
</template>

<script>
import ProgressCircular from '@/components/common/ProgressCircular'
import {ExportButtons} from '@/components/common'
import AddressNotFoundInfo from '@/components/address/AddressNotFoundInfo'
import RouteToTransaction from "@/components/common/RouteToTransaction";
import FiatConvertor from "@/components/common/FiatConvertor";
import ExportMenu from "@/components/common/Buttons/ExportMenu.vue";
import LimitAbleLayoutOld from "@/components/common/LimitAbleLayoutOld.vue";

export default {
    name: 'BalanceTree',
    components: {
        ExportMenu,
        FiatConvertor,
        RouteToTransaction,
        ProgressCircular,
        LimitAbleLayoutOld,
        ExportButtons,
        AddressNotFoundInfo
    },
    beforeUpdate() {
        //Update visual slider (workaround)
        this.$emit("component-loaded-event");
    },
    computed: {
        addressId() {
            return this.$store.getters.addressId
        },
        address() {
            return this.$store.getters.addressInfo
        },
        isLoading() {
            return this.$store.getters.addressLoading
        },
        loading() {
            return this.$store.getters.addressBalanceTreeLoading
        },
        balanceTreeData() {
            return this.$store.getters.addressBalanceTree;
        },
        currency() {
            return this.$store.getters.currency
        },
        nonCryptoPrices() {
            return this.$store.getters.nonCryptoPrices
        },
        displayCurrency() {
            return this.$store.getters.currentFiat?.unit && this.$store.getters.fiatActive ? this.$store.getters.currentFiat.unit : this.$store.getters.currency.unit;
        },
        exportButtonsData() {
            return [
                { template: "${serverAddress}/export/${currency}/cryptoaddress/${addressId}/balances/show-htmleng", startText: "Show", endText: "HTML", imgSrc: "/img/ico/eng.svg" },
                { template: "${serverAddress}/export/${currency}/cryptoaddress/${addressId}/balances/show-htmlcze", startText: "Show", endText: "HTML", imgSrc: "/img/ico/cze.svg" },
                { template: "${serverAddress}/export/${currency}/cryptoaddress/${addressId}/balances/show-csv", startText: "Show", endText: "CSV" },
                { template: "${serverAddress}/export/${currency}/cryptoaddress/${addressId}/balances/save-htmleng", startText: "Save", endText: "HTML", imgSrc: "/img/ico/eng.svg" },
                { template: "${serverAddress}/export/${currency}/cryptoaddress/${addressId}/balances/save-htmlcze", startText: "Save", endText: "HTML", imgSrc: "/img/ico/cze.svg" },
                { template: "${serverAddress}/export/${currency}/cryptoaddress/${addressId}/balances/save-csv", startText: "Save", endText: "CSV"},
            ].map(i => ({ ...i, serverAddress: this.$store.getters.settings.server.jwtPrefix, addressId: this.addressId, currency: this.currency.unit }))
        }
    },
    data() {
        return {
            limitValue: "100",
            search: '',
            headers: [
                {text: 'Timestamp', align: 'center', value: 'timestamp', tooltip: 'Transaction timestamp'},
                {text: 'Bal. Before', align: 'right', value: 'balanceBefore', tooltip: '(in cryptounits)'},
                {
                    text: 'Change',
                    align: 'right',
                    value: 'change',
                    tooltip: '(in cryptounits) Difference between balance before and after transaction'
                },
                {text: 'Bal. After', align: 'right', value: 'balanceAfter', tooltip: '(in cryptounits)'},
                {
                    text: 'Addr Snd',
                    align: 'right',
                    value: 'sumOfAddrInputs',
                    tooltip: '(in cryptounits) Sum of inputs with a given address'
                },
                {
                    text: 'A/T Snd',
                    align: 'right',
                    value: 'percentileOfSumInputs',
                    tooltip: '(%) Ratio between sums of address inputs and all transaction inputs'
                },
                {
                    text: 'AI',
                    align: 'right',
                    value: 'numOfAddrInputs',
                    tooltip: '(#) Number of inputs with a given address'
                },
                {
                    text: 'TI',
                    align: 'right',
                    value: 'numOfAllInputs',
                    tooltip: '(#) Total number of transaction inputs'
                },
                {
                    text: 'A/T I',
                    align: 'right',
                    value: 'percentileOfNumInputs',
                    tooltip: '(%) Ratio between counts of address inputs and all inputs'
                },
                {
                    text: 'Addr Rcv',
                    align: 'right',
                    value: 'sumOfAddrOutputs',
                    tooltip: '(in cryptounits) Sum of outputs with a given address'
                },
                {
                    text: 'A/T Rcv',
                    align: 'right',
                    value: 'percentileOfSumOutputs',
                    tooltip: '(%) Ratio between sums of address outputs and all transaction outputs'
                },
                {
                    text: 'AO',
                    align: 'right',
                    value: 'numOfAddrOutputs',
                    tooltip: '(#) Number of outputs with a given address'
                },
                {
                    text: 'TO',
                    align: 'right',
                    value: 'numOfAllOutputs',
                    tooltip: '(#) Total number of transaction outputs'
                },
                {
                    text: 'A/T O',
                    align: 'right',
                    value: 'percentileOfNumOutputs',
                    tooltip: '(%) Ratio between counts of address outputs and all outputs'
                },
                {
                    text: 'Tx Rcv',
                    align: 'right',
                    value: 'sumOfAllOutputs',
                    tooltip: '(in cryptounits) Sum of all transaction outputs'
                },
                {
                    text: 'Fee',
                    align: 'right',
                    value: 'fee',
                    tooltip: '(in cryptounits) Transaction Fee'
                },
            ]
        }
    },
    methods: {
        reloadData(data) {
            this.limitValue = data
            this.$store.dispatch('loadAddressBalanceTree', { limit: data })
        }
    }
}
</script>
