<template>
    <div>
        <v-layout row class="px-2 py-1">
            <b>Block</b>
            <router-link
                :to="'/' + data.crypto + '/block/' + data.hash + '/'"
                class="text-overflow text-decoration-none"
            >
                <img
                    class="currencyImg mx-2"
                    :src="('cryptoIcons/color/' + data.crypto.toLowerCase() + '.svg') | createImageUrl"
                />
                <code class="elevation-0">{{ data.hash }}</code>
            </router-link>
            <v-spacer />
            <div class="no-wrap pl-2">{{data.time | moment('YYYY-MM-DD HH:mm:ss Z')}}</div>
        </v-layout>
        <v-divider />
        <v-layout justify-space-between row wrap class="mx-2 pb-1">
            <span>
                <b>Height:</b>
                {{data.height}}
            </span>
            <span>
                <b>Size:</b>
                {{data.size}}
            </span>
        </v-layout>
    </div>
</template>
<script>

export default {
    name: 'BlockResult',
    props: {
        data: {}
    }
}
</script>

<style scoped>
.currencyImg {
    vertical-align: middle;
    width: 20px;
}
</style>