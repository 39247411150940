export interface AlarmServerModelPOST {
    name: string,
    hash: string,
    type: string,
    tx_direction: string,
    crypto: string,
    notify: string,
    email_template: string,
}

export interface AlarmServerModel {
    id: number,
    name: string,
    hash: string,
    type: string,
    tx_direction: string,
    notify: string,
    email_template: string,
    address: {
        hash: string,
        crypto: string
    },
    user: {
        name: string,
    },
    notifications_count: number,
    created_at: number,
    updated_at: number
}

function MapToServerModelPOST(model: any) : AlarmServerModelPOST {
    let {name, hash, type, tx_direction, crypto, notify, email_template, ...restOfModel} = model


    return {name, hash, type, tx_direction, crypto, notify, email_template} as AlarmServerModelPOST
}

function MapToServerModel({id, name, notify, crypto, type, tx_direction, hash, email_template, updated_at, created_at, username, notifications_count}: any) : AlarmServerModel
{
    return { id, name, hash, type, tx_direction, notify, email_template, address: { hash, crypto}, user: { name: username}, notifications_count: notifications_count ? notifications_count : 0, created_at, updated_at } as AlarmServerModel
}


export default {MapToServerModelPOST, MapToServerModel}