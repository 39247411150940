var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { "fill-height": "" } },
    [
      _c(
        "v-layout",
        {
          staticStyle: { position: "absolute", width: "100%" },
          attrs: { column: "" },
        },
        [
          _c(
            "v-app-bar",
            {
              staticStyle: { position: "sticky", "z-index": "1", top: "0" },
              attrs: { dense: "", color: "white" },
            },
            [
              _c(
                "v-tabs",
                {
                  ref: "drawerTabs",
                  attrs: {
                    light: "",
                    "background-color": "white",
                    "slider-color": _setup.currency.unit,
                  },
                },
                _vm._l(_setup.finalRoutes, function ({ route, publicName }) {
                  return _c("v-tab", { key: route, attrs: { to: route } }, [
                    _vm._v(_vm._s(publicName) + "\n                "),
                  ])
                }),
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(_setup.EntityHeaderLabel, {
                attrs: {
                  label: _setup.currency.unit.toUpperCase() + " CLUSTER:",
                  entity: _setup.clusterCurrent,
                },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "text-decoration-none",
                  attrs: {
                    href:
                      "/" +
                      _setup.currency.unit +
                      "/cluster/" +
                      _vm.$route.params.detailCluster +
                      "/",
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      attrs: {
                        color: "rgba(0, 0, 0, 0.7)",
                        icon: "",
                        small: "",
                      },
                    },
                    [
                      _c("v-icon", { staticStyle: { "font-size": "20px" } }, [
                        _vm._v("open_in_new"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_setup.isOnGraphPage
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "div",
                                  _vm._g(_vm._b({}, "div", attrs, false), on),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          disabled: "",
                                          color: "rgba(0, 0, 0, 0.7)",
                                          icon: "",
                                          small: "",
                                        },
                                        on: { click: _setup.removeGraphNode },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "font-size": "20px",
                                            },
                                          },
                                          [_vm._v("delete")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2363347968
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("This view is read-only. Save this"),
                        _c("br"),
                        _vm._v(" configuration if you want to edit it."),
                      ]),
                    ]
                  )
                : _setup.isOnGraphPage
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      attrs: {
                        color: "rgba(0, 0, 0, 0.7)",
                        icon: "",
                        small: "",
                      },
                      on: { click: _setup.removeGraphNode },
                    },
                    [
                      _c("v-icon", { staticStyle: { "font-size": "20px" } }, [
                        _vm._v("delete"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mx-1",
                  attrs: { color: "rgba(0, 0, 0, 0.7)", icon: "", small: "" },
                  on: { click: _setup.graphCloseBottomDrawer },
                },
                [
                  _c("v-icon", { staticStyle: { "font-size": "20px" } }, [
                    _vm._v("close"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "height100", attrs: { fluid: "" } },
            [
              _c(
                _setup.DataProvider,
                { attrs: { name: "entityId", data: _setup.clusterCurrent } },
                [
                  _c("router-view", {
                    on: { "component-loaded-event": _setup.onClick },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }