var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n        Address Derivation Options\n    ")]),
      _vm._v(" "),
      _c(_setup.FancyCard, {
        staticClass: "d-flex",
        staticStyle: { "max-width": "1000px" },
        attrs: { dense: "", header: false },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-center",
                    staticStyle: { gap: "8px" },
                  },
                  [
                    _c("v-checkbox", {
                      staticStyle: { width: "220px", "padding-top": "0" },
                      attrs: {
                        dense: "",
                        label: "Use Hardened Addresses",
                        color: _vm.currency.hexColor,
                        "input-value": _vm.useHardenedAddresses,
                        "hide-details": "",
                      },
                      on: {
                        change: function ($event) {
                          _vm.$emit(
                            "update:useHardenedAddresses",
                            Boolean($event)
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        filled: "",
                        dense: "",
                        value: _vm.numberOfRowsToGenerate,
                        label: "Number of rows to generate",
                        placeholder: "Enter a whole number",
                        type: "number",
                      },
                      on: {
                        input: function ($event) {
                          _vm.$emit(
                            "update:numberOfRowsToGenerate",
                            Number($event)
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        filled: "",
                        dense: "",
                        value: _vm.startingIndex,
                        label: "Starting index",
                        placeholder: "Enter a whole number",
                        type: "number",
                        disabled: _vm.numberOfAddressesYetToGenerate > 0,
                      },
                      on: {
                        input: function ($event) {
                          _vm.$emit("update:startingIndex", Number($event))
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.currency.unit === "BCH"
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { disabled: !_setup.isSegWit, top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              _vm._b({}, "div", attrs, false),
                                              on
                                            ),
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  filled: "",
                                                  items:
                                                    _setup.bchAddressFormats,
                                                  "item-text": "value",
                                                  "item-value": "value",
                                                  value: _vm.bchAddressFormat,
                                                  label: "Address Format",
                                                  disabled: _setup.isSegWit,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$emit(
                                                      "update:bchAddressFormat",
                                                      $event
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item",
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex",
                                                              staticStyle: {
                                                                gap: "8px",
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.value
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    opacity:
                                                                      "0.6",
                                                                  },
                                                                },
                                                                [_vm._v("-")]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticStyle: {
                                                                    opacity:
                                                                      "0.6",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    '"' +
                                                                      _vm._s(
                                                                        item.prefix
                                                                      ) +
                                                                      '"'
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "selection",
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex",
                                                              staticStyle: {
                                                                gap: "8px",
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.value
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    opacity:
                                                                      "0.6",
                                                                  },
                                                                },
                                                                [_vm._v("-")]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticStyle: {
                                                                    opacity:
                                                                      "0.6",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    '"' +
                                                                      _vm._s(
                                                                        item.prefix
                                                                      ) +
                                                                      '"'
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1193639772
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("div", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "This option is available only for the Legacy address type"
                                  ),
                                  _c("br"),
                                  _vm._v("("),
                                  _c("i", [
                                    _vm._v("P2PKH, used by BIP32 / BIP44"),
                                  ]),
                                  _vm._v(")"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }