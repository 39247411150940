var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex justify-left align-center",
      staticStyle: { "max-width": "200px" },
    },
    [
      _vm._l(_vm.items.slice(0, _vm.shownItemsAmount), function (item, index) {
        return _c(
          "div",
          { key: _setup.getItemKey(item, index) },
          [_vm._t("default", null, { item: item, isInMenu: false })],
          2
        )
      }),
      _vm._v(" "),
      _c(
        _setup.BaseButtonShowMoreItems,
        { attrs: { items: _vm.items, shownItemsCount: _vm.shownItemsAmount } },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "div",
            { key: _setup.getItemKey(item, index) },
            [_vm._t("default", null, { item: item, isInMenu: true })],
            2
          )
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }