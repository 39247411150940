var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-4 allClusterMetadata", attrs: { fluid: "" } },
    [
      _c("BulkMergeClustersDialog", {
        ref: "mergeDialogRef",
        attrs: {
          clusters: _vm.selectedClustersToArray,
          "currency-unit": this.filter.filterCurrency,
          redirect: "",
        },
        on: {
          "created-event": () => {
            _vm.refresh()
            _vm.removeSelection()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "align-center": "" } },
        [
          _c("h1", [_vm._v("All Cluster Metadata")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("MergeButton", {
            staticClass: "ml-1 mr-2",
            attrs: { disabled: _vm.selectedClustersNames.length === 0 },
            on: {
              click: function ($event) {
                return _vm.$refs.mergeDialogRef.open()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "nobr",
        [
          _c("v-data-table", {
            staticClass: "elevation-0 border my-2",
            attrs: {
              "footer-props": {
                "items-per-page-options": [10, 30, 50, 100],
                "show-current-page": true,
                showFirstLastPage: true,
              },
              headers: _vm.headers,
              items: _vm.neoClusterMetadata.data,
              loading: _vm.neoClusterMetadataLoading,
              options: _vm.filter,
              "server-items-length": _vm.neoClusterMetadata.total
                ? _vm.neoClusterMetadata.total
                : 0,
              dense: "",
            },
            on: {
              "update:options": function ($event) {
                _vm.filter = $event
              },
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.headers, function (h) {
                  return {
                    key: `header.${h.value}`,
                    fn: function ({ header }) {
                      return [
                        h.value === "select"
                          ? _c("v-checkbox", {
                              key: h.value,
                              attrs: {
                                disabled:
                                  _vm.neoClusterMetadataLoading ||
                                  !_vm.userHasWritePermissions,
                                indeterminate:
                                  _vm.selectedClustersNames.length !== 0 &&
                                  !_vm.isWholePageSelected,
                                value: _vm.isWholePageSelected,
                              },
                              on: { change: _vm.onSelectClusters },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "cluster"
                          ? _c("v-text-field", {
                              key: h.value,
                              staticClass: "text-field-label-style",
                              staticStyle: { "padding-bottom": "18px" },
                              attrs: {
                                label: header.text,
                                "append-icon": "search",
                                clearable: "",
                                "hide-details": "",
                                "single-line": "",
                              },
                              on: { input: _vm.debounceInput },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value: _vm.debouncedFilter.filterEntityId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.debouncedFilter,
                                    "filterEntityId",
                                    $$v
                                  )
                                },
                                expression: "debouncedFilter.filterEntityId",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "crypto"
                          ? _c(
                              "div",
                              {
                                key: h.value,
                                staticStyle: {
                                  "padding-top": "7px",
                                  "margin-bottom": "auto",
                                },
                              },
                              [
                                _c("BlockchainCryptoPickerSlim", {
                                  attrs: {
                                    currencies: _vm.allCurrenciesExceptUnknown,
                                    isClearable: false,
                                    dense: "",
                                  },
                                  on: { input: _vm.removeSelection },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  model: {
                                    value: _vm.filter.filterCurrency,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "filterCurrency",
                                        $$v
                                      )
                                    },
                                    expression: "filter.filterCurrency",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "isCustom"
                          ? _c("div", { key: h.value }, [
                              _c("h3", [_vm._v(_vm._s(h.text))]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "totalAddresses"
                          ? _c(
                              "div",
                              {
                                key: h.value,
                                staticStyle: { "max-width": "100px" },
                              },
                              [_c("h3", [_vm._v(_vm._s(h.text))])]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "owner"
                          ? _c("v-text-field", {
                              key: h.value,
                              staticClass: "shrink text-field-label-style",
                              staticStyle: { "padding-bottom": "18px" },
                              attrs: {
                                label: header.text,
                                "append-icon": "search",
                                clearable: "",
                                "hide-details": "",
                                "single-line": "",
                              },
                              on: { input: _vm.debounceInput },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value: _vm.debouncedFilter.filterOwner,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.debouncedFilter,
                                    "filterOwner",
                                    $$v
                                  )
                                },
                                expression: "debouncedFilter.filterOwner",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "categories"
                          ? _c(
                              "div",
                              {
                                key: h.value,
                                staticStyle: { "margin-bottom": "auto" },
                              },
                              [
                                _c("CategoryPicker", {
                                  staticClass: "text-field-label-style",
                                  attrs: {
                                    categories: _vm.categories,
                                    dense: true,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  model: {
                                    value: _vm.filter.filterCategories,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "filterCategories",
                                        $$v
                                      )
                                    },
                                    expression: "filter.filterCategories",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "created_at"
                          ? _c("div", { key: h.value }, [
                              _c("h3", [_vm._v(_vm._s(h.text))]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "action"
                          ? _c("div", { key: h.value }, [
                              _c("h3", [_vm._v(_vm._s(h.text))]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  }
                }),
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "tr",
                        {
                          class: { pointer: _vm.userHasWritePermissions },
                          on: {
                            click: function ($event) {
                              _vm.userHasWritePermissions &&
                                _vm.onSelectCluster(item)
                            },
                          },
                        },
                        [
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0",
                                attrs: {
                                  "input-value":
                                    _vm.selectedClustersNames.includes(
                                      item.entity_id
                                    ),
                                  dense: "",
                                  disabled: !_vm.userHasWritePermissions,
                                  "hide-details": "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onSelectCluster(item)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-left fontMonospace" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "link",
                                  attrs: {
                                    to:
                                      "/" +
                                      _vm.filter.filterCurrency +
                                      "/cluster/" +
                                      item.cluster,
                                  },
                                },
                                [
                                  _c("Copyable", {
                                    attrs: {
                                      "on-copy": item.cluster,
                                      justify: "left",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "text",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "span",
                                                _vm._g({}, on),
                                                [
                                                  _c("nobr", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "stringMiddleShortener"
                                                          )(item.cluster, 34)
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "vertical-align": "middle",
                              },
                            },
                            [
                              _c("BlockchainCryptoBadge", {
                                attrs: {
                                  crypto: item.crypto,
                                  currencies: _vm.currencies,
                                  iconWidth: 24,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-left" }, [
                            _vm._v(
                              _vm._s(item.isCustom ? "custom" : "co-spent")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatNumber")(item.totalAddresses)
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-left" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _vm._l(
                                  item.categories.slice(0, 2),
                                  function (cat) {
                                    return _c("AddressCategoryIcon", {
                                      key: cat.id,
                                      staticClass: "mr-2",
                                      attrs: { iconItem: cat, medium: "" },
                                    })
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "BaseButtonShowMoreItems",
                                  {
                                    attrs: {
                                      items: item.categories,
                                      shownItemsCount: 2,
                                    },
                                  },
                                  _vm._l(item.categories, function (cat) {
                                    return _c("AddressCategoryIcon", {
                                      key: cat.id,
                                      staticClass: "mx-1",
                                      attrs: {
                                        color: cat.color,
                                        iconItem: cat,
                                        name: cat.name,
                                        medium: "",
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-center" }, [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-center",
                                staticStyle: { "max-width": "200px" },
                              },
                              [
                                _vm._l(item.owners.slice(0, 1), function (own) {
                                  return _c("div", { key: own.id }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(own.name) +
                                        "\n                            "
                                    ),
                                  ])
                                }),
                                _vm._v(" "),
                                _c(
                                  "BaseButtonShowMoreItems",
                                  {
                                    attrs: {
                                      items: item.owners,
                                      shownItemsCount: 1,
                                    },
                                  },
                                  _vm._l(item.owners, function (owner) {
                                    return _c(
                                      "div",
                                      {
                                        key: owner.id,
                                        staticClass: "mx-1 fontMonospace",
                                        staticStyle: { "font-size": "0.8em" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(owner.name) +
                                            "\n                                "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "vertical-align": "middle",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      item.created_at,
                                      "YYYY-MM-DD HH:mm:ss Z"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "vertical-align": "middle",
                                "min-width": "92px",
                              },
                            },
                            [
                              _c("AllClusterMetadataDetailModal", {
                                key: item.id,
                                attrs: {
                                  categories: _vm.categories,
                                  clusterMetadataDetail:
                                    _vm.neoClusterMetadataDetail,
                                  crypto: _vm.filter.filterCurrency,
                                  currencies: _vm.currencies,
                                  isOwnersLoading: _vm.isOwnersLoading,
                                  loading:
                                    _vm.isNeoClusterMetadataDetailLoading,
                                  owners: _vm.owners.data,
                                },
                                on: {
                                  "owner-filter-changed-event":
                                    _vm.ownerFilterChanged,
                                  "save-event": _vm.editClusterMetadata,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "showbutton",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "TableEditButton",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  editButtonTooltip:
                                                    "Edit All Cluster Metadata",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.loadNeoClusterMetadataDetail(
                                                      {
                                                        currency:
                                                          _vm.filter
                                                            .filterCurrency,
                                                        clusterMetadataId:
                                                          item.cluster,
                                                        pagination:
                                                          _vm.addresspagination,
                                                      }
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "footer.page-text",
                  fn: function ({ pageStart, pageStop, itemsLength }) {
                    return [
                      _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("formatNumber")(pageStart)) +
                            " - " +
                            _vm._s(_vm._f("formatNumber")(pageStop)) +
                            " of " +
                            _vm._s(_vm._f("formatNumber")(itemsLength)) +
                            "\n                "
                        ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }