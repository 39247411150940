<script setup>

import store from "@/store";
import {formatNumber} from "humanize-plus";
import RouteToAddress from "@/components/common/RouteToAddress.vue";
import {ref} from "vue";

const props = defineProps({
    data: {
        type: Object,
        required: true
    }
})

const getCrypto = (unit) => store.getters.currencyByUnit(unit)
const currentTab = ref(null)
const itemsPerPage = 10

</script>

<template>
    <div>
        <v-tabs v-model="currentTab" show-arrows>
            <v-tab
                v-for="cryptoGroup in data.items"
                :key="cryptoGroup.itemTypes">
                <v-img :src="getCrypto(cryptoGroup.itemTypes).image"
                       class="mr-2"
                       height="20"
                       width="20"/>
                {{ cryptoGroup.itemTypes }}
                <v-badge :content="formatNumber(cryptoGroup.totalMatches)"
                         inline
                         tile/>
            </v-tab>
            <v-tabs-items v-model="currentTab" class="mt-1">
                <v-tab-item v-for="cryptoGroup in data.items"
                            :key="cryptoGroup.itemTypes">
                    <v-data-table :headers="[{text:'Type', align: 'left'}, {text:'Value', align: 'right'}]"
                                  :height="cryptoGroup.items.length > itemsPerPage ? itemsPerPage * 32 + 32 : undefined"
                                  :hide-default-footer="cryptoGroup.items.length <= itemsPerPage"
                                  :items="cryptoGroup.items"
                                  :items-per-page="itemsPerPage"
                                  dense>
                        <template #item="{ item }">
                            <tr>
                                <td class="text-left">
                                    <span class="fontMonospace">Address</span>
                                </td>
                                <td class="text-right">
                                    <RouteToAddress :address="item.address" :currency="cryptoGroup.itemTypes">
                                        <template #text>
                                            {{ item.address | stringMiddleShortener }}
                                        </template>
                                    </RouteToAddress>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </div>

</template>

<style scoped>

</style>