var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: {
      title: _vm.isEditModal ? "Edit category" : "Create new category",
      loading: _vm.loading,
      width: "30%",
    },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field-validateable", {
                            attrs: {
                              rules: "required|min:3",
                              label: "Title",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.detail.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "name", $$v)
                              },
                              expression: "detail.name",
                            },
                          }),
                          _vm._v(" "),
                          _c("IconPicker", {
                            model: {
                              value: _vm.detail.icon,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "icon", $$v)
                              },
                              expression: "detail.icon",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { label: "Secondary Color (Text/Icon)" },
                              model: {
                                value: _vm.detail.secondaryColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.detail, "secondaryColor", $$v)
                                },
                                expression: "detail.secondaryColor",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Black", value: "#000000" },
                              }),
                              _vm._v(" "),
                              _c("v-radio", {
                                attrs: { label: "White", value: "#FFFFFF" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "min-width": "290px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-text-field-validateable",
                                        _vm._g(
                                          {
                                            attrs: {
                                              rules: "required",
                                              label: "Primary Color (Badge)",
                                              readonly: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.detail.color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detail,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.color",
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.colorpicker,
                                callback: function ($$v) {
                                  _vm.colorpicker = $$v
                                },
                                expression: "colorpicker",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c("v-color-picker", {
                                attrs: { dense: "" },
                                model: {
                                  value: _vm.detail.color,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "color", $$v)
                                  },
                                  expression: "detail.color",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-sheet",
                            { staticClass: "my-10" },
                            [
                              _c("h2", { staticClass: "mb-2" }, [
                                _vm._v("Large category badge"),
                              ]),
                              _vm._v(" "),
                              _c("CategoryBadge", {
                                staticClass: "ml-2",
                                attrs: { iconItem: _vm.detail, large: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-sheet",
                            [
                              _c("h2", { staticClass: "mb-2" }, [
                                _vm._v("Small category badge"),
                              ]),
                              _vm._v(" "),
                              _c("CategoryBadge", {
                                staticClass: "ml-2",
                                attrs: { iconItem: _vm.detail },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    outlined: "",
                    color: "error",
                    disabled:
                      _vm.categoriesModalState === _vm.modalState.Pending,
                  },
                  on: { click: close },
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "success",
                    loading:
                      _vm.categoriesModalState === _vm.modalState.Pending,
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save(close, setDisabled)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isEditModal ? "Save category" : "Create category"
                    )
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }