<template>
    <BaseModalDialog
        :title="isEditModal ? 'Edit address metadata' : 'Create new address metadata'"
        @load-modal-state-event="loadState"
        :loading="loading"
        width="60%"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-card outlined>
                    <v-row class="ma-2">
                        <v-col class="py-2">
                            <v-text-field-validateable
                                :disabled="isEditModal"
                                rules="required|crypto"
                                label="Address"
                                v-model="detail.address"
                                outlined
                            />
                        </v-col>
                        <v-col class="py-2">
                            <BlockchainCryptoPicker
                                :currencies="currencies"
                                v-model="detail.crypto"
                            />
                        </v-col>
                        <v-col class="py-2">
                            <v-text-field
                                :disabled="true"
                                label="Created At"
                                :value="detail.created_at | moment('YYYY-MM-DD HH:mm:ss Z')"
                                outlined
                            />
                        </v-col>
                    </v-row>

                    <v-row class="ma-2">
                        <v-col class="py-2">
                            <CategoryPicker
                                dense
                                :categories="categories"
                                v-model="detail.categoryIds"
                            />
                        </v-col>
                        <v-col class="py-2">
                            <OwnerPickerWithDisplay v-model="detail.owners"/>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card outlined class="mt-2">
                    <v-row class="ma-2">
                        <v-col cols="7">
                            <CsvImporterInput
                                :csvConfiguration="csvConfiguration"
                                @import-successful="importAddressIdentityPairs"
                                @import-failed="importAddressIdentityPairsFailed"
                            />
                        </v-col>
                        <v-col align="center">
                            <CorruptedDataModalDialog
                                v-if="corruptedData.length > 0"
                                :corruptedData="corruptedData"
                                @close-event="closeCorruptedDataDialog"
                            >
                                <template #showbutton="{ on }">
                                    <v-btn v-on="on" color="error" outlined>Show corrupted data</v-btn>
                                </template>
                            </CorruptedDataModalDialog>
                        </v-col>
                        <v-col>
                            <v-row justify="end">
                                <CreateButton text="Add new identity" @click="addNewIdentity" />
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-data-table
                        :items="detail.identities"
                        :items-per-page="5"
                        :headers="identitiesHeaders"
                        :footer-props="{
                            'items-per-page-options': [5, 15, 50, 100],
                            'show-current-page': true,
                        }"
                        dense
                    >
                        <template #item="{ item }">
                            <tr>
                                <td class="py-2" style="width: 25%">
                                    <v-text-field-validateable
                                        rules="required|max:255"
                                        v-model="item.label"
                                        label="Label"
                                        full-width
                                        outlined
                                        dense
                                        hide-details
                                    />
                                </td>
                                <td class="py-2" style="width: 20%">
                                    <v-text-field-validateable
                                        rules="required|max:255"
                                        v-model="item.source"
                                        label="Source"
                                        full-width
                                        outlined
                                        dense
                                        hide-details
                                    />
                                </td>
                                <td class="py-2">
                                    <v-text-field-validateable
                                        rules="required|max:1024"
                                        v-model="item.url"
                                        label="Url"
                                        full-width
                                        outlined
                                        dense
                                        hide-details
                                    />
                                </td>
                                <td>
                                    <TableDeleteModalDialog
                                        :itemToDelete="item"
                                        @delete-event="deleteIdentity"
                                        tooltip="Delete identity"
                                    />
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </ValidationObserver>
        </template>
        <template #footer="{close, setDisabled}">
            <v-btn
                dark
                outlined
                color="error"
                :disabled="addressMetadataModalState === modalState.Pending"
                @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                dark
                color="success"
                :loading="addressMetadataModalState === modalState.Pending"
                outlined
                @click="save(close, setDisabled)"
            >{{ isEditModal ? "Save address metadata" : "Create address metadata" }}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import {deepClone, uuidv4} from "@/utils"
import {
    BaseModalDialog,
    TableDeleteModalDialog,
    BlockchainCryptoPicker,
    CategoryPicker,
    CreateButton,
    CsvImporterInput,
    CorruptedDataModalDialog,
} from "@/components/common"
import {mapGetters} from "vuex";
import {modalState} from "@/components/common/types/modal";
import OwnerPickerWithDisplay from "@/components/ClusterMetadata/OwnerPickerWithDisplay.vue";



export default {
    name: 'AddressMetadataDetailModal',
    components: {
        OwnerPickerWithDisplay,
        BaseModalDialog,
        TableDeleteModalDialog,
        BlockchainCryptoPicker,
        CategoryPicker,
        CreateButton,
        CsvImporterInput,
        CorruptedDataModalDialog,
    },
    props: {
        owners: {
            type: Array,
            default: () => []
        },
        addressMetadataDetail: {
            type: Object
        },
        categories: {
            type: Array,
            default: () => []
        },
        currencies: {
            type: Array,
            default: () => []
        },
        isCategoriesLoading: {
            type: Boolean,
            default: false
        },
        isOwnersLoading: {
            type: Boolean,
            default: false
        },
        isAddressMetadataDetailLoading: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapGetters(['addressMetadataModalState']),
        loading() {
            return this.isCategoriesLoading || this.isAddressMetadataDetailLoading
        },
        modalState() {
            return modalState
        }
    },
    data() {
        return {
            detail: undefined,
            isEditModal: true,
            csvConfiguration: [
                { csvHeader: "Label", exportPropertyName: "label" },
                { csvHeader: "Source", exportPropertyName: "source" },
                { csvHeader: "Url", exportPropertyName: "url" }
            ],
            corruptedData: [],
            closeDialog: null,
            setDisabled: null,
            identitiesHeaders: [
                {text: "Label", sortable: false},
                {text: "Source", sortable: false},
                {text: "Url", sortable: false},
                {text: "Action", align: "center", sortable: false, width: "5%"}
            ]
        }
    },
    watch: {
        addressMetadataModalState(newVal) {
            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('ADDRESS_METADATA_MODAL_SET_STATE', modalState.Initial)
            } else if (newVal === modalState.Error)
            {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('ADDRESS_METADATA_MODAL_SET_STATE', modalState.Initial)
            }
        }
    },
    methods: {
        async save(close, setDisabled) {
            let isValid = await this.$refs.validator.validate();
            // validate all identities
            if (this.detail.identities.some(i => !i.label || !i.source || !i.url)) {
                isValid = false
                await this.$store.dispatch("warning", "All fields of all identities are required.")
            }

            if (isValid) {
                this.closeDialog = close
                this.setDisabled = setDisabled
                setDisabled(true)
                this.$emit("save-event", { adddresMetadataDetail: this.detail })
            }
        },
        loadState() {
            if (this.addressMetadataDetail) {
                this.isEditModal = true
                this.detail = deepClone(this.addressMetadataDetail)
                //this.detail.categories = this.detail.categories.map(a => a.id)
            }
            else {
                this.isEditModal = false
                this.detail = {
                    address: "",
                    crypto: "",
                    categories: [],
                    owner: null,
                    owner_id: null,
                    identities: [],

                }
            }
        },
        addNewIdentity() {
            this.detail.identities.unshift({ label: "", source: "", url: "", _id: uuidv4(), created_at: "Just now" })
        },
        deleteIdentity({ itemToDelete }) {
            if (itemToDelete.id) {
                this.detail.identities = this.detail.identities.filter(i => i.id !== itemToDelete.id)
            }
            else {
                //decorate data with _id for proper function of delete
                this.detail.identities = this.detail.identities.filter(i => i._id !== itemToDelete._id)
            }
        },
        importAddressIdentityPairs({ importedData, corruptedData }) {
            //decorate data with _id for proper function of delete
            const withIds = importedData.map((i, index) => ({ ...i, _id: uuidv4() }))
            this.detail.identities = withIds.concat(this.detail.identities)
            if (corruptedData.length > 0) {
                this.corruptedData = corruptedData
                this.$store.dispatch("warning", `Added ${withIds.length} address - identity pairs, but ${corruptedData.length} records was corrupted.`)
            }
            else {
                this.$store.dispatch("success", `Added ${withIds.length} address - identity pairs.`)
            }
        },
        importAddressIdentityPairsFailed({ message }) {
            this.$store.dispatch("error", message)
        },
        closeCorruptedDataDialog(close) {
            this.corruptedData = []
            close()
        },
        ownerFilterChanged({ filter }) {
            this.$emit("owner-filter-changed-event", { filter })
        }
    }
}
</script>