var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.BaseDataModifyButton,
    _vm._g(
      _vm._b(
        { attrs: { disabled: _vm.disabled, color: "error", small: "" } },
        "BaseDataModifyButton",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
        _vm._v("mdi-delete"),
      ]),
      _vm._v("\n    " + _vm._s(_vm.text) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }