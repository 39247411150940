var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "center" } },
        [
          _c(
            "v-col",
            {
              staticStyle: {
                "border-bottom": "1px #0000001F solid",
                height: "49px",
              },
            },
            [
              _c(
                "v-app-bar",
                { attrs: { elevation: "0", dense: "", color: "white" } },
                [
                  _c(
                    "v-app-bar",
                    {
                      staticClass: "px-4",
                      staticStyle: { margin: "0 auto", width: "160px" },
                      attrs: { dense: "", color: "white", elevation: "0" },
                    },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticStyle: { "max-width": "95% !important" },
                          attrs: {
                            "show-arrows": "",
                            "slider-size": "3",
                            grow: false,
                            light: "",
                            "background-color": "white",
                            "slider-color": _vm.currency.unit,
                          },
                        },
                        _vm._l(
                          _vm.tabs,
                          function ({ route, publicName }, index) {
                            return _c(
                              "v-tab",
                              {
                                key: route,
                                attrs: { to: _vm.finalRoutes[index].route },
                              },
                              [
                                _vm._v(
                                  _vm._s(publicName) +
                                    "\n                        "
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("EntityHeaderLabel", {
                        attrs: {
                          label: _vm.currency.unit.toUpperCase() + " CLUSTER:",
                          entity: _vm.clusterCurrent,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "px-4", attrs: { fluid: "" } },
        [
          _c(
            "DataProvider",
            { attrs: { name: "entityId", data: _vm.clusterCurrent } },
            [
              _c("router-view", {
                on: { "component-loaded-event": _vm.onClick },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }