<template>
    <BaseModalDialog :title="title" :width="width">
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>{{ description }}</template>
        <template #footer="{close}">
            <v-btn color="error" text @click="close">Cancel</v-btn>
            <v-spacer />
            <v-btn color="success" text @click="confirmDelete(close)">Delete</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import BaseModalDialog from "@/components/common/Base/BaseModalDialog";

export default {
    name: "BaseDeleteModalDialog",
    components: {
        BaseModalDialog
    },
    props: {
        title: {
            type: String,
            default: "Are you sure?"
        },
        itemToDelete: {
            type: Object,
            required: true
        },
        description: {
            type: String,
            default: "Do you really want to delete?"
        },
        width: {
            type: String,
            default: "18%"
        }
    },
    methods: {
        confirmDelete(close) {
            this.$emit("delete-event", { itemToDelete: this.itemToDelete })
            close()
        }
    }
}
</script>
