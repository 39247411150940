<template>
        <v-card class="justify-center" elevation="1" :max-width="size" style="border-radius: 50%">
            <v-progress-circular
            indeterminate
            outlined
            width="5"
            :size="size"
            :color="color"
            >
                <v-btn
                    rounded
                    fab
                    elevation="0"
                    outlined
                    small
                    color="white"
                    @click="$emit('cancel-button-event')"
                >
                    <v-icon :color="color">
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-progress-circular>
        </v-card>
</template>

<script>

export default {
    name: "CancelLoadingButton",
    props: {
        color: {
            type: String,
            default: 'primary'
        },
        size: {
            default: 50,
            type: Number
        }
    }
}

</script>

<style scoped>
.rounded {
    border-radius: 50px !important;
}
</style>