var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _setup.currencyFamilyIsEth
        ? _c(_setup.ViewHeader, { attrs: { text: "Transactions Filters" } })
        : _c(_setup.ViewHeaderWithTabs, {
            attrs: { text: "Transactions Filters", tabs: _setup.tabs },
          }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }