<template>
    <div>
        <v-layout row class="px-2 py-1">
            <b>Address</b>
            <router-link
                class="text-overflow ml-2 text-decoration-none"
                :to="'/' + data.crypto + '/address/' + data.address"
            >
                <img
                    class="currencyImg mx-2"
                    :src="('cryptoIcons/color/' + data.crypto.toLowerCase() + '.svg') | createImageUrl"
                />
                <v-tooltip top v-if="data.identities.length > 0" v-slot:activator="{ on }">
                    <code class="elevation-0" v-on="on">{{data.identities[0].label}}</code>
                    <span>{{data.address}}</span>
                </v-tooltip>
                <code v-else class="elevation-0">{{data.addr}}</code>
            </router-link>

            <addressCategory
                class="pl-1"
                v-for="cat in data.categories"
                :iconItem="cat"
                :key="cat.id"
                :name="cat.name"
                :color="cat.color"
            />
        </v-layout>
        <v-divider />

        <template v-if="data.cases.length > 0">
            <v-divider />
            <v-list-item>
                <v-list-item-action class="font-weight-bold">Cases:</v-list-item-action>
                <v-list-item-content>
                    <v-btn
                        color="secondary"
                        class="pointer"
                        :to="'/case/' + caseDetail.id"
                        outlined
                        label
                        v-for="caseDetail in data.cases"
                        :key="caseDetail.id"
                    >
                        <span class="text-initial">{{caseDetail.title}}</span>
                    </v-btn>
                </v-list-item-content>
            </v-list-item>
            <v-divider />
        </template>

        <v-layout justify-space-between row wrap class="mx-2 pb-1">
            <span>
                <b>Balance:</b>
                {{data.confirmedBalance}}
            </span>
            <span>
                <b>Transactions:</b>
                {{data.confirmedTransactions}}
            </span>
            <span>
                <b>Received:</b>
                {{data.totalReceived}}
            </span>
            <span>
                <b>Sent:</b>
                {{data.totalSent}}
            </span>
        </v-layout>
    </div>
</template>
<script>
import addressCategory from "@/components/address/AddressCategoryIcon"

export default {
    name: 'AddressResult',
    components: {
        addressCategory
    },
    props: {
        data: {}
    }
}
</script>

<style scoped>
.currencyImg {
    vertical-align: middle;
    width: 20px;
}
</style>