<template>
    <div class="height100 scroll-y">
        <div v-if="addressLoading">
            <ProgressCircular class="pt-10" :color="currency.unit"/>
        </div>

        <v-container v-if="!addressLoading && !addressInfo">
            <div v-if="addressInfo === null || !addressId">
                <AddressNotFoundInfo
                    class="my-2"
                    :address="addressId"
                    :message="'Provided identifier does not exist in the blockchain!'"
                />
            </div>
        </v-container>

        <v-container v-if="!addressLoading && addressInfo" fluid class="pa-0">
            <h1>Tracked Funds</h1>
            <v-divider/>
            <v-divider class="mb-3"/>

            <div v-if="!hasLocalClusters && !addressClustersLoading">
                <EmptyClusterInfo
                    :address="addressId"
                    :message="'This address does not form any cluster with other addresses!'"
                />
            </div>

            <div v-if="clusterNotFound">
                <AddressNotFoundInfo
                    class="my-2"
                    :address="addressId"
                    :message="'This address does not belong to the given cluster!'"
                />
            </div>

            <div class="mb-2 ml-6">
                <v-row style="max-width: 900px;" justify="center" align="center">
                    <v-col
                        class="d-flex justify-center"
                        cols="12"
                        sm="2"
                        md="1"
                        lg="1">
                        <CancelLoadingButton v-if="currentFundsLoading && !clusterNotFound" :color="currency.unit"
                                             @cancel-button-event="cancelRequest"/>
                    </v-col>
                    <v-col cols="12"
                           sm="10"
                           md="7"
                           lg="7">
                        <AddressClusterPicker
                            v-model="entityAddress"
                        />
                    </v-col>
                    <v-col cols="12"
                           sm="12"
                           md="4"
                           lg="4"
                           class="d-flex justify-center">

                        <v-divider vertical class="d-inline ml-n6 mt-n2"/>
                        <v-divider vertical class="d-inline mr-2 mt-n2"/>

                        <v-card loading="true" elevation="0" class="caption mt-n2" width="250px">
                            <v-row justify="center" no-gutters>

                                <v-col cols="6">
                                    <p class="mb-1">
                                        type:
                                    </p>
                                </v-col>
                                <v-col cols="6">
                                    <p class="mb-1">
                                        <strong> {{
                                                currentLocalCluster ? currentLocalCluster.isCustom ? "custom" : "co-spent" : "#"
                                            }} </strong>
                                    </p>
                                </v-col>
                                <v-col cols="6">
                                    <p class="ma-0 pa-0">
                                        # of Addresses:
                                    </p>
                                </v-col>
                                <v-col cols="6">
                                    <p class="ma-0">
                                        <strong> {{
                                                currentLocalCluster ? currentLocalCluster.addresses : "#"
                                            }} </strong>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <HeaderLined class="mb-1 mx-6">
                <span style="color: rgba(0, 0, 0, 0.6); font-weight: 400 ">
                    Filter
                </span>
            </HeaderLined>
            <div class="mt-2">
                <FundsFilter
                    :disabled="!entityAddress"
                    :currency="currency"
                    :categories="categories"
                    :clusterCurrent="currentCluster"
                    @search-funds-event="searchFunds"/>
            </div>
            <HeaderLined class="mb-1 mx-6">
                <span style="color: rgba(0, 0, 0, 0.6); font-weight: 400 ">
                    Result
                </span>
            </HeaderLined>
            <FundsResult
                :filter="filter"
                :currency="currency"
                :categories="categories"
                :addressFunds="currentFundsData"
                :addressFundsLoading="currentFundsLoading"
                :isLogaritmic="currentAddressFundsIsLogarithmic"
                :current-cluster="currentCluster"/>
        </v-container>
    </div>
</template>


<script>
import {mapGetters} from "vuex"

import ProgressCircular from "@/components/common/ProgressCircular"
import FundsResult from "@/components/Funds/FundsResult"
import EmptyClusterInfo from "@/components/address/EmptyClusterInfo"
import AddressNotFoundInfo from '@/components/address/AddressNotFoundInfo'
import CancelLoadingButton from "@/components/common/Buttons/CancelLoadingButton";
import AddressClusterPicker from "@/components/address/AddressClusterPicker";
import FundsFilter from "@/components/Funds/FundsFilter";
import HeaderLined from "@/components/common/HeaderLined";

export default {
    name: "AddressFunds",
    components: {
        FundsResult,
        HeaderLined,
        FundsFilter,
        AddressClusterPicker,
        CancelLoadingButton,
        ProgressCircular,
        EmptyClusterInfo,
        AddressNotFoundInfo
    },
    data() {
        return {
            entityAddress: null,
            clusterNotFound: false,
        }
    },
    computed: {
        ...mapGetters(["currency", "addressId", "addressLoading", "addressInfo", "categories", "local_clusters", "addressFundsLoading", "addressClustersLoading", "currentFundsCluster", "addressFundsFilter", "addressFundsData", "addressFundsIsLogarithmic", "addressFundsHistoryFallback"]),

        localClusters() {
            return [...this.local_clusters].map(cluster => {
                return {...cluster, isLoading: !!this.addressFundsLoading[cluster.entityid], currency: this.currency}
            })
        },
        filter()
        {
            return this.addressFundsHistoryFallback[this.currentCluster]
        },
        currentCluster() {
            return this.currentFundsCluster
        },

        currentFundsLoading() {
            return !!this.addressFundsLoading[this.currentCluster]
        },

        currentFundsData() {
            return this.addressFundsData[this.currentCluster]
        },

        hasLocalClusters() {
            return this.local_clusters.length !== 0;
        },
        currentLocalCluster() {
            return this.local_clusters.filter(x => {
                return x.entityid === this.entityAddress
            })[0]
        },
        currentAddressFundsIsLogarithmic() {
            return !!this.addressFundsIsLogarithmic[this.currentCluster]
        },
        getCurrentFundsFilter() {
            if (this.addressFundsFilter[this.currentCluster]) {
                return JSON.parse(JSON.stringify({...this.addressFundsFilter[this.currentCluster]}))
            } else {
                return undefined
            }
        },

    },
    beforeUpdate() {
        //Update visual slider (workaround)
        this.$emit("component-loaded-event");
    },
    created() {
        if (this.$route.params.entity) {
            this.entityAddress = this.$route.params.entity
        } else if (this.currentCluster !== null) {
            this.entityAddress = this.currentCluster
        }
    },
    methods: {
        cancelRequest() {
            this.$store.commit("ADDRESS_FUNDS_LOADING_CANCEL", {entity: this.entityAddress})
            const fallback = this.addressFundsHistoryFallback[this.entityAddress]
            if (fallback) {
                this.$router.push({query: {...fallback}})
            } else {
                this.entityAddress = null
            }
        },

        checkCurrentClusterForExistence() {
            let clusterFound = false
            this.local_clusters.forEach(x => {
                if (x.entityid === this.entityAddress)
                    clusterFound = true
            })
            this.clusterNotFound = !clusterFound
            if (!clusterFound) {
                this.entityAddress = null
            }
        },

        searchFunds(filter) {
            this.$store.dispatch("loadAddressFunds", {
                currency: this.currency.unit,
                addressId: this.addressId ? this.addressId : this.$route.params.detailAddress,
                filter: filter,
                entityAddress: this.currentCluster ? this.currentCluster : this.entityAddress
            })
        }

    },
    watch: {
        $route(to, from) {
            if (to.params.entity !== from.params.entity) {
                if (this.$route.params.entity) {
                    this.entityAddress = this.$route.params.entity
                    this.checkCurrentClusterForExistence()
                } else {
                    this.entityAddress = null
                }
            }
        },
        "entityAddress": function (newVal, oldVal) {
            let fundsString = "funds"
            let indexOfLastFundsOccurrence = this.$route.fullPath.lastIndexOf(fundsString)
            if (newVal) {
                this.$store.commit("ADDRESS_FUNDS_SET_CURRENT", this.entityAddress)

                let newPath = `${this.$route.fullPath.slice(0, indexOfLastFundsOccurrence + fundsString.length)}/${newVal}`
                if (this.$route.params.entity !== this.entityAddress) {
                    this.$router.push({path: newPath})
                }

                if (!this.currentFundsData && !this.currentFundsLoading && this.$route.query.filter) {
                    this.searchFunds(this.$route.query.filter)
                }
            } else {
                this.$store.commit("ADDRESS_FUNDS_SET_CURRENT", null)

                let newPath = `${this.$route.fullPath.slice(0, indexOfLastFundsOccurrence + fundsString.length)}`
                if (this.$route.params.entity !== this.entityAddress) {
                    this.$router.push({path: newPath})
                }
            }
        },
        "local_clusters": function (newVal, oldVal) {
            if (this.entityAddress) {
                this.checkCurrentClusterForExistence()
            }
        }
    },
}
</script>
