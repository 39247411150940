import { render, staticRenderFns } from "./ClusterMetadataView.vue?vue&type=template&id=0ae70925&"
import script from "./ClusterMetadataView.vue?vue&type=script&lang=js&"
export * from "./ClusterMetadataView.vue?vue&type=script&lang=js&"
import style0 from "./ClusterMetadataView.vue?vue&type=style&index=0&id=0ae70925&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VCol,VContainer,VDataTable,VDivider,VLayout,VRow,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ae70925')) {
      api.createRecord('0ae70925', component.options)
    } else {
      api.reload('0ae70925', component.options)
    }
    module.hot.accept("./ClusterMetadataView.vue?vue&type=template&id=0ae70925&", function () {
      api.rerender('0ae70925', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/ClusterMetadata/ClusterMetadataView.vue"
export default component.exports