var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "d-flex justify-center align-center",
          attrs: { rounded: "", text: "" },
          on: {
            click: function ($event) {
              _setup.modelValue = !_setup.modelValue
            },
          },
        },
        [
          _c("v-checkbox", {
            staticClass: "ma-0 pa-0",
            staticStyle: { "pointer-events": "none" },
            attrs: {
              color: "tertiary",
              dense: "",
              "hide-details": "",
              readonly: "",
            },
            model: {
              value: _setup.modelValue,
              callback: function ($$v) {
                _setup.modelValue = $$v
              },
              expression: "modelValue",
            },
          }),
          _vm._v(" "),
          _c(
            "v-icon",
            { staticClass: "ml-n1", attrs: { color: _setup.color, left: "" } },
            [_vm._v("\n            " + _vm._s(_vm.icon) + "\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "text-button",
                "textGrey",
                "ml-n1",
                _setup.color ? `${_setup.color}--text` : "",
              ],
            },
            [_vm._v("\n            " + _vm._s(_vm.text) + "\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }