<template>
    <BaseModalDialog
        :title="isEditModal ? 'Edit category' : 'Create new category'"
        @load-modal-state-event="loadState"
        :loading="loading"
        width="30%"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />

        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-row>
                    <v-col>
                        <v-text-field-validateable
                            rules="required|min:3"
                            v-model="detail.name"
                            label="Title"
                            outlined
                            dense
                        />
                        <IconPicker v-model="detail.icon"/>
                        <v-radio-group
                            label="Secondary Color (Text/Icon)"
                            v-model="detail.secondaryColor"
                        >
                            <v-radio label="Black" value="#000000" />
                            <v-radio label="White" value="#FFFFFF" />
                        </v-radio-group>
                        <v-menu
                            v-model="colorpicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field-validateable
                                    rules="required"
                                    v-model="detail.color"
                                    label="Primary Color (Badge)"
                                    readonly
                                    outlined
                                    v-on="on"
                                    dense
                                />
                            </template>
                            <v-color-picker v-model="detail.color" dense />
                        </v-menu>
                    </v-col>
                    <v-col class="text-center">
                        <v-sheet class="my-10">
                            <h2 class="mb-2">Large category badge</h2>
                            <CategoryBadge :iconItem="detail" class="ml-2" large />
                        </v-sheet>
                        <v-sheet>
                            <h2 class="mb-2">Small category badge</h2>
                            <CategoryBadge :iconItem="detail" class="ml-2" />
                        </v-sheet>
                    </v-col>
                </v-row>
            </ValidationObserver>
        </template>
        <template #footer="{close, setDisabled}">
            <v-btn
                dark
                outlined
                color="error"
                :disabled="categoriesModalState === modalState.Pending"
                @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                dark
                color="success"
                :loading="categoriesModalState === modalState.Pending"
                outlined
                @click="save(close, setDisabled)"
            >{{ isEditModal ? "Save category" : "Create category" }}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import { deepClone } from "@/utils"
import { CategoryBadge, BaseModalDialog } from "@/components/common"
import {mapGetters} from "vuex";
import {modalState} from "@/components/common/types/modal";
import IconPicker from "@/components/common/Pickers/IconPicker.vue";


export default {
    name: "CategoryDetailModal",
    components: {
        IconPicker,
        BaseModalDialog,
        CategoryBadge
    },
    props: {
        categoryDetail: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(["categoriesModalState"]),
        modalState() {
            return modalState
        }
    },
    data() {
        return {
            detail: undefined,
            isEditModal: true,
            filterIcons: "",
            colorpicker: undefined,
            closeDialog: null,
            setDisabled: null,
        }
    },
    watch: {
        categoriesModalState(newVal) {
            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('CATEGORIES_MODAL_SET_STATE', modalState.Initial)
            } else if (newVal === modalState.Error)
            {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('CATEGORIES_MODAL_SET_STATE', modalState.Initial)
            }

        }
    },
    methods: {
        async save(close, setDisabled) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.closeDialog = close
                this.setDisabled = setDisabled
                setDisabled(true)
                this.$emit("save-event", { categoryDetail: this.detail })
            }
        },
        loadState() {
            if (this.categoryDetail) {
                this.isEditModal = true
                this.detail = deepClone(this.categoryDetail)
            }
            else {
                this.isEditModal = false
                this.detail = {
                    name: "",
                    color: "#60C04C",
                    secondaryColor: "#000000",
                    icon: 'mdi-help-circle-outline',
                }
            }
        }
    }
}
</script>
