<template>
        <router-view />
</template>

<script>
export default {
    name: 'CurrencyView',
    beforeDestroy() {
        this.$store.dispatch('clearCurrency')
        this.$store.commit('TRANSACTIONS_CANCEL_AND_CLEAR')
    },
    watch: {
        $route: {
            handler(newValue, oldValue) {
                const currencyIsChanged = (oldValue && oldValue.params.currency) !== newValue.params.currency
                if (currencyIsChanged) {
                    this.$store.dispatch('selectCurrency', { currencyUnit: newValue.params.currency })
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>