<template>
    <v-container class="px-7" fluid>
        <v-row no-gutters justify="end" class="mb-2">
            <v-col cols="auto">
                <export-menu :disabled="!transaction"
                             :base-url="currency.unit + '/cryptotransaction/' + ((transaction && transaction.txhash) || '')"/>
            </v-col>
        </v-row>
        <div class="transactionContainer">
            <TransactionsHeader no-change/>
            <v-divider/>
            <div v-if="!transactionLoading && transaction" class="pt-2 ">
                <TransactionSingle expanded :transaction="transaction"/>
            </div>
            <progress-circular v-else class="mt-2" :color="currency.hexColor"/>
        </div>
    </v-container>
</template>

<script>
import TransactionSingle from './TransactionSingle'
import {ExportButtons} from '@/components/common'
import TransactionsControlPanel from "@/components/transaction/Components/TransactionsControlPanel";
import ExportMenu from "@/components/common/Buttons/ExportMenu";
import TransactionsHeader from "@/components/transaction/Components/TransactionsHeader";
import {mapGetters} from "vuex";
import ProgressCircular from "@/components/common/ProgressCircular";

export default {
    name: 'TransactionDetail',
    components: {
        ProgressCircular,
        TransactionsHeader,
        ExportMenu,
        TransactionsControlPanel,
        TransactionSingle,
        ExportButtons
    },
    computed: {
        ...mapGetters(["transactionLoading"]),
        currency() {
            return this.$store.getters.currency
        },
        transaction() {
            return this.$store.getters.transactionInfo
        },
    },
    created() {
        this.$store.dispatch('loadCategories')
    },
    data() {
        return {
            categoriespagination: {
                page: 1,
                itemsPerPage: 100,
            },
        }
    }
}
</script>
