var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseModalDialog, {
    ref: "baseModal",
    attrs: {
      title: _setup.isEditModal
        ? "Edit graph configuration"
        : "Create new graph configuration",
    },
    on: { "load-modal-state-event": _setup.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c("v-text-field-validateable", {
                    attrs: {
                      rules: "max:255",
                      label: "Description",
                      outlined: "",
                    },
                    model: {
                      value: _setup.detail.description,
                      callback: function ($$v) {
                        _vm.$set(_setup.detail, "description", $$v)
                      },
                      expression: "detail.description",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close }) {
            return [
              _c(
                "v-btn",
                { attrs: { color: "error", text: "" }, on: { click: close } },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", text: "" },
                  on: {
                    click: function ($event) {
                      return _setup.saveConfiguration(close)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _setup.isEditModal
                        ? "Save configuration"
                        : "Create configuration"
                    )
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }