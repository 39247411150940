<template>
    <v-tooltip top>
        <template #activator="{ on }">
            <v-btn 
            icon 
            small 
            v-bind="$attrs" 
            v-on="{ ...$listeners, ...on }"
            color="secondary"
            >
                <v-icon :color="color" :large="!smallIcon">info</v-icon>
            </v-btn>
        </template>
        <span>{{ infoButtonTooltip }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name:"TableInfoButton",
    props: {
        infoButtonTooltip: {
            type: String,
            default: "Info"
        },
        smallIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: "secondary"
        }

    }
}
</script>