<template>
    <tr :id="`output/${item.n}${item.value}${item.addr}`"
        :style="isSelected(item.addr) ? 'background-color: #EFEFEF' : ''" :class="!item.isAddress && 'activeGroup'"
        @click="!item.isAddress && expand(!isExpanded)">
        <td class="d-flex justify-end align-center">
            <div v-if="item.is_change">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="success" v-on="on">
                            mdi-arrow-u-left-bottom
                        </v-icon>
                    </template>
                    Spare change
                </v-tooltip>
            </div>
            <div v-else-if="item.is_lightning_network_channel_opener">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="#ffc001" v-on="on">
                            mdi-flash
                        </v-icon>
                    </template>
                    This output was used to open a lightning network channel.
                </v-tooltip>
            </div>
            <div class="fontMonospace d-flex align-center relative">
                <FiatConvertor :fiats="fiats" :value="item.value" with-unit/>
            </div>
        </td>

        <td>
            <div v-if="!itemOuter" class="d-flex justify-center align-center countingBadges relative"
                 style=" min-height: 27px; max-width: 92px; min-width: 92px; top:6px">
                <div style="min-width: 28px" class="d-flex justify-center">
                    <CasesSmartTooltip v-if="item.cases.length > 0" :cases="item.cases"/>
                </div>
                <div style="min-width: 28px" class="d-flex justify-center">
                    <OwnersSmartTooltip v-if="item.owners.length > 0" :owners="item.owners"/>
                </div>
                <div style="min-width: 36px" class="d-flex justify-center">
                    <IdentitiesSmartTooltip v-if="item.identities.length > 0" :identities="item.identities"/>
                </div>
            </div>
        </td>

        <td>
            <div class="d-flex align-center" style="position: relative">
                <div v-if="itemOuter || !item.isAddress" style="position: absolute; height: 20px">
                    <div class="d-flex justify-center align-center"
                         style="min-width: 34px; margin-left: -37px; position: absolute">
                        <v-badge
                            v-if="!item.isAddress"
                            tile
                            inline
                            overlap
                            :content="item.inouts.length"/>
                        <img v-else-if="itemOuter && index === itemOuter.inouts.length - 1"
                             src="/img/ico/list-bullet-end.svg"
                             alt=""
                             style="filter: opacity(0.6); width: 20px;"/>
                        <img v-else-if="itemOuter"
                             src="/img/ico/list-bullet-middle.svg"
                             alt=""
                             style="filter: opacity(0.6); width: 20px;"/>
                    </div>
                </div>
                <EntityLabel
                    :entity="item.addr"
                    :identity="item.identity"
                    :owner="item.owner"
                    :color="item.categories[0] ? item.categories[0].color : undefined"
                    :isCopyable="!itemOuter"
                    :showExtra="!itemOuter"
                    :actAsLink="!itemOuter"
                    :currency="currency.unit"
                />
            </div>
        </td>

        <td>
            <div class="d-flex align-center" style="min-width: 86px; max-width: 86px; position: relative">
                <v-btn
                    v-if="!item.isAddress"
                    absolute
                    style="margin-top: 1px; margin-left: -30px;"
                    class="pa-0"
                    light
                    x-small
                    icon
                    @click.stop="expand(!isExpanded)"
                >
                    <v-icon v-if="isExpanded">keyboard_arrow_up</v-icon>
                    <v-icon v-else>keyboard_arrow_down</v-icon>
                </v-btn>
                <AddressCategoryIcon
                    class="ml-1"
                    v-for="cat in item.categories.length > 2 ? item.categories.slice(0,2) : item.categories"
                    icon-size="22px"
                    :iconItem="cat"
                    :key="cat.id"
                    :name="cat.name"
                    :color="cat.color"
                    :cursor="'auto'"
                ></AddressCategoryIcon>
                <BaseButtonShowMoreItems
                    :items="item.categories"
                    :shownItemsCount="2"
                >
                    <AddressCategoryIcon
                        class="mx-1"
                        v-for="cat in item.categories"
                        :iconItem="cat"
                        :key="cat.id"
                        medium
                        :name="cat.name"
                        :color="cat.color"
                    />
                </BaseButtonShowMoreItems>
            </div>
        </td>

        <td>
            <div v-if="item.isAddress"
                 style="position: relative; height: 20px; right: 12px"
                 class="d-flex align-center"
            >
                <div style="position: absolute;">
                    <RouteToTransaction no-decoration v-if="item.spentTxId" :transaction="item.spentTxId"
                                        :iconLink="true" :currency="currency.unit">
                        <template #text>
                            <v-hover v-slot="{ hover }" style="border-radius: 50%; padding: 4px; margin: 0px">
                                <v-icon class="cursor"
                                        :style="hover && {'background-color': '#1976d2'}"
                                        small
                                        :color="hover ? 'white' : 'blue darken-2'"
                                >
                                    fa-solid fa-arrow-right
                                </v-icon>
                            </v-hover>
                        </template>
                    </RouteToTransaction>
                    <v-icon v-else-if="txType === 'BTC'" disabled small style="margin-left: 4px">
                        fa-solid fa-arrow-right
                    </v-icon>
                </div>
            </div>
        </td>

    </tr>
</template>

<script>
import FiatConvertor from "@/components/common/FiatConvertor";
import AddressCategoryIcon from "@/components/address/AddressCategoryIcon";
import RouteToTransaction from "@/components/common/RouteToTransaction";
import Copyable from "@/components/common/Copyable";
import RouteToAddress from "@/components/common/RouteToAddress";
import AddressDetailable from "@/components/common/AddressDetailable";
import OwnersSmartTooltip from "@/components/common/SmartTooltips/OwnersSmartTooltip";
import IdentitiesSmartTooltip from "@/components/common/SmartTooltips/IdentitiesSmartTooltip";
import CasesSmartTooltip from "@/components/common/SmartTooltips/CasesSmartTooltip";
import EntityLabel from "@/components/common/EntityLabel";
import BaseButtonShowMoreItems from "@/components/common/TableComponents/BaseButtonShowMoreItems";

export default {
    name: "TransactionOutputRow",
    components: {
        BaseButtonShowMoreItems,
        EntityLabel,
        CasesSmartTooltip,
        IdentitiesSmartTooltip,
        OwnersSmartTooltip,
        AddressDetailable,
        RouteToAddress,
        Copyable,
        RouteToTransaction,
        AddressCategoryIcon,
        FiatConvertor
    },
    props: {
        item: {
            type: Object
        },
        expand: {},
        isExpanded: {
            type: Boolean
        },
        fiats: {
            type: Object
        },
        itemOuter: {
            type: Object
        },
        index: {
            type: Number
        },
        address: {
            type: String
        },
        txType: {
            type: String,
        },
    },
    computed: {
        currency () {
            return this.$store.getters.currency
        }
    },
    methods: {
        isSelected(address) {
            return this.address === address
        },
    }
}
</script>

<style scoped>

</style>