var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseModalDialog, {
    ref: "baseModal",
    attrs: {
      loading: _vm.loading,
      title: "Node Info",
      width: "80%",
      height: "85vh",
    },
    on: {
      "destroy-modal-state-event": _setup.clearQueryId,
      "load-modal-state-event": _setup.onModalLoad,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "justify-center" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0 d-flex", attrs: { cols: "1" } },
                        [
                          _setup.nodeTorData
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "img",
                                              _vm._g(
                                                {
                                                  staticStyle: {
                                                    width: "36px",
                                                    "margin-bottom": "6px",
                                                  },
                                                  attrs: {
                                                    src: "/img/ico/tor-onion.svg",
                                                    alt: "Tor",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    447331939
                                  ),
                                },
                                [
                                  _vm._v(
                                    "\n                        This node was active in the Tor network.\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _setup.nodeLightningNetworkId
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    large: "",
                                                    color: "#ffc001",
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                                mdi-flash\n                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    747181470
                                  ),
                                },
                                [
                                  _vm._v(
                                    "\n                        This node propagated Lightning network transactions.\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "11" } },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "text--primary d-flex justify-center",
                              staticStyle: { gap: "8px" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "font-weight": "300" } },
                                [
                                  _vm._v(
                                    "\n                            Address:\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.peerDetail.node.host) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: 3 } },
                            [
                              _vm._l(_setup.nodeInfoRows, function (item) {
                                return _c(_setup.NameValueFlex, {
                                  key: item.name,
                                  attrs: {
                                    loading: _vm.loading,
                                    "loading-width": "150",
                                    "name-width": item.nameWidth ?? undefined,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "name",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(item.name) +
                                                ":\n                            "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "value",
                                        fn: function () {
                                          return [
                                            item.value
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "fontMonospace",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(item.value) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-italic grey--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    N/A\n                                "
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }),
                              _vm._v(" "),
                              _c(_setup.NodeInvolvement, {
                                attrs: {
                                  hasData: !!_setup.nodeLightningNetworkId,
                                  subject: "Lightning Network",
                                  loading: _setup.nodeLightningNetworkIDLoading,
                                },
                              }),
                              _vm._v(" "),
                              _c(_setup.NodeInvolvement, {
                                attrs: {
                                  hasData: !!_setup.nodeTorData,
                                  subject: "Tor Network",
                                  loading: _setup.nodeTorDataLoading,
                                },
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-center align-center",
                              attrs: { cols: 9 },
                            },
                            [
                              _vm.loading
                                ? _c(
                                    "div",
                                    [
                                      _c(_setup.ProgressCircular, {
                                        staticClass: "pt-5",
                                        attrs: { color: _setup.currency },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "justify-center" },
                                        [
                                          !_setup.isOnionAddress
                                            ? _c(_setup.MapContainer, {
                                                attrs: {
                                                  peerDetail: _vm.peerDetail,
                                                },
                                              })
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "title font-weight-light text-center",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    Not available for this address type.\n                                "
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(_setup.NodeDataTabs, {
                attrs: {
                  "node-lightning-network-id": _setup.nodeLightningNetworkId,
                  "node-lightning-network-channels":
                    _setup.lightningNetworkChannels,
                  "node-lightning-network-channels-loading":
                    _setup.lightningNetworkChannelsLoading,
                  "node-tor-data": _setup.nodeTorData,
                  "node-tor-data-loading": _setup.nodeTorDataLoading,
                  "peer-id": _vm.peerId,
                  "peer-detail": _vm.peerDetail,
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close }) {
            return [
              _c(
                "v-btn",
                { attrs: { color: "error", text: "" }, on: { click: close } },
                [_vm._v("Close")]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }