<script setup>
import GenericPicker from "@/components/common/Pickers/GenericPicker.vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {computed} from "vue";
import {DataState} from "@/components/common/types/data";
import CaseSearchDisplay from "@/components/common/Displays/CaseSearchDisplay.vue";
import CaseSelectedDisplay from "@/components/common/Displays/CaseSelectedDisplay.vue";

const props = defineProps({
    value: {
        required: true
    },
    rules: {
        required: false
    }
})

const pagination = {
    page: 1,
    itemsPerPage: 10,
    sortDesc: [false]
}

const {
    getData: casesGetData,
    dataState: casesDataState,
    data: casesData
} = useGetData("getCases", {pagination: {...pagination}}, false, false, 1, true)
const cases = computed(() => casesData.value?.data ?? [])

const loading = computed(() => casesDataState.value === DataState.Loading)

const getCases = async (searchString) => {
    casesGetData({pagination: {...pagination, filterTitle: searchString}})
}

const emitCaseId = (e) => {
    if (!e) {
        emit('input', null)
        return
    }

    emit('input', e.id)
}

const emit = defineEmits(['input'])

</script>

<template>
    <div>
        <GenericPicker
            :items="cases"
            :get-items="getCases"
            :value="value"
            @input="emitCaseId"
            :loading="loading"
            item-text="title"
            item-value="id"
            prepend-icon="mdi-clipboard-text"
            label="Case"
            hide-details
            single-input
            :rules="rules"
        >
            <template #item="{ item }">
                <CaseSearchDisplay :item="item"/>
            </template>
            <template #selection="{ item }">
                <CaseSelectedDisplay :item="item"/>
            </template>
        </GenericPicker>
    </div>
</template>

<style scoped>

</style>