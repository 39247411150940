<template>
    <div class="d-flex">
        <v-checkbox
            :error="error"
            dense
            hide-details
            class="mr-4 ma-0 pa-0"
            v-model="notification"
            :color="color"
            :label="'Notification'"
            :readonly="readonly"
        ></v-checkbox>
        <v-checkbox
            :error="error"
            dense
            hide-details
            class="ma-0 pa-0 pr-4"
            v-model="email"
            :color="color"
            :label="'Email'"
            :readonly="readonly"
        ></v-checkbox>
    </div>
</template>

<script>
export default {
    name: "NotificationPicker.vue",
    props: {
        value: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: "primary"
        }
    },
    data() {
        return {
            email: true,
            notification: true,
            error: false,
        }
    },
    watch: {
        email: {
            handler()
            {
                this.emit()
            }
        },
        notification: {
            handler()
            {
                this.emit()
            }
        },
    },
    mounted() {
        switch (this.value)
        {
            case "both":
                this.email = true;
                this.notification = true;
                break;
            case "email":
                this.email = true;
                this.notification = false;
                break
            case "rest":
                this.email = false;
                this.notification = true;
                break;
            default:
                this.email = false;
                this.notification = false;
        }
    },
    methods: {
        emit()
        {
            this.error = false;
            if (this.email && this.notification)
                this.$emit("input", "both")
            else if (this.email)
                this.$emit("input", "email")
            else if (this.notification)
                this.$emit("input", "rest")
            else
            {
                this.error = true;
                this.$emit("input", "")
            }

        }
    }
}
</script>

<style scoped>

</style>