<script setup>
import NeedleTypeAddress from "@/components/ExpansionPanel/NeedleTypes/NeedleTypeAddress.vue";
import ExpandPanel from "@/components/ExpansionPanel/ExpandPanel.vue";
import ExpandPanels from "@/components/ExpansionPanel/ExpandPanels.vue";
import {computed, ref} from "vue";

const props = defineProps({
    icon: {
        type: String,
        required: false,
        default: "mdi-invoice-list-outline"
    },
    title: {
        type: String,
        required: true
    },
    getLocationFromResource: {
        type: Function,
        required: true
    },
    resourceTypeItem: {
        type: Object,
        required: false,
        default: () => ({totalMatches: 0, items: []})
    },
})

const itemsPerPage = 20

const page = ref(1)
const totalPages = ref(Math.ceil(props.resourceTypeItem.items.length / itemsPerPage))

const items = computed(() => props.resourceTypeItem.items.slice((page.value - 1) * itemsPerPage, page.value * itemsPerPage))


</script>

<template>
    <ExpandPanels expanded>
        <ExpandPanel :icon="icon"
                     :number-of-results="props.resourceTypeItem.items.length"
                     :title="title"
                     show-as-result>
            <ExpandPanels v-for="(resource, index) in items"
                          :key="index"
                          class="searchExpandPanel">
                <ExpandPanel :number-of-results="resource.totalMatches" padding>
                    <template #header>
                        <div @click.stop>
                            <router-link :to="getLocationFromResource(resource.resource)"
                                         class="link fontMonospace"
                                         target="_blank">
                                {{ resource.resource.displayName }}
                            </router-link>
                        </div>
                    </template>
                    <div v-for="needleMatchGroup in resource.needleMatches">
                        <NeedleTypeAddress v-if="needleMatchGroup.itemTypes === 'Address'"
                                           :data="needleMatchGroup"/>
                    </div>
                </ExpandPanel>
            </ExpandPanels>
            <div v-if="totalPages > 1" class="mx-2 px-2">
                <div class="text-center py-1 position-relative">
                    <v-pagination
                        v-model="page"
                        :length="totalPages"
                        circle
                        color="tertiary"
                    ></v-pagination>
                </div>
            </div>
        </ExpandPanel>
    </ExpandPanels>
</template>

<style>
.searchExpandPanel .v-expansion-panel-header {
    padding: 0 12px !important;
    min-height: 38px !important;
}
</style>