var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.BaseModalDialog, {
        ref: "modalRef",
        attrs: { title: _vm.title, width: "80vh" },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "v-icon",
                  { attrs: { color: "error", large: "", left: "" } },
                  [_vm._v("mdi-delete")]
                ),
                _vm._v(" "),
                _c("b", [_vm._v(_vm._s(_setup.items.length))]),
                _vm._v(" items will be "),
                _c("b", [_vm._v("permanently")]),
                _vm._v(" deleted!\n        "),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function ({ close, setDisabled }) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _setup.loading,
                      small: "",
                      color: "grey",
                    },
                    on: { click: _setup.onClose },
                  },
                  [_vm._v("\n                Close\n            ")]
                ),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _setup.modalRef.disabled,
                      color: "error",
                      loading: _setup.loading,
                    },
                    on: { click: _setup.onDelete },
                  },
                  [_vm._v("\n                Delete\n            ")]
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(_setup.DataGridDeleteButton, {
        attrs: { disabled: _vm.disabled },
        on: { click: _setup.open },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }