import { render, staticRenderFns } from "./AddressDerivationOptionsCard.vue?vue&type=template&id=55256515&scoped=true&"
import script from "./AddressDerivationOptionsCard.vue?vue&type=script&setup=true&lang=js&"
export * from "./AddressDerivationOptionsCard.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55256515",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCheckbox,VSelect,VTextField,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55256515')) {
      api.createRecord('55256515', component.options)
    } else {
      api.reload('55256515', component.options)
    }
    module.hot.accept("./AddressDerivationOptionsCard.vue?vue&type=template&id=55256515&scoped=true&", function () {
      api.rerender('55256515', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/MnemonicConvertor/AddressDerivationOptionsCard.vue"
export default component.exports