<template>
    <div>
        <v-layout row class="px-2 py-1">
            <b>Transaction</b>
            <router-link
                :to="'/' + data.crypto + '/transaction/' + data.txhash + '/'"
                class="text-overflow text-decoration-none"
            >
                <img
                    class="currencyImg mx-2"
                    :src="('cryptoIcons/color/' + data.crypto.toLowerCase() + '.svg') | createImageUrl"
                />
                <code class="elevation-0">{{ data.txhash }}</code>
            </router-link>
            <v-spacer />
            <div class="no-wrap pl-2">{{data.time | moment('YYYY-MM-DD HH:mm:ss Z')}}</div>
        </v-layout>
        <v-divider />
        <v-layout justify-space-between row wrap class="mx-2 pb-1">
            <span>
                <b>Total input:</b>
                <TransactionPrice
                    :exchangePrice="data.exchangePrice"
                    :value="data.sumOfInputValues"
                    :currency="data.crypto"
                />
            </span>
            <span v-if="!data.isCoinbase">
                <b>Fee:</b>
                <TransactionPrice
                    :exchangePrice="data.exchangePrice"
                    :value="data.sumOfFeeValues"
                    :currency="data.crypto"
                />
            </span>
            <span>
                <b>Total output:</b>
                <TransactionPrice
                    :exchangePrice="data.exchangePrice"
                    :value="data.sumOfOutputValues - data.sumOfFeeValues"
                    :currency="data.crypto"
                />
            </span>
        </v-layout>
    </div>
</template>
<script>
import TransactionPrice from "@/components/transaction/TransactionPrice"

export default {
    name: 'TransactionResult',
    components: {
        TransactionPrice
    },
    props: {
        data: { type: Object }
    }
}
</script>
<style scoped>
.currencyImg {
    vertical-align: middle;
    width: 20px;
}
</style>