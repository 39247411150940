var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        dark: "",
                        fab: "",
                        small: "",
                        color: _vm.color + " darken-1",
                      },
                    },
                    "v-btn",
                    _vm.$attrs,
                    false
                  ),
                  { ...on, ..._vm.$listeners }
                ),
                [_c("v-icon", [_vm._v("add")])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", [_vm._v("Add new node")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }