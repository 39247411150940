var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SmartTooltipBase",
    {
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _vm.cases && _vm.cases.length > 0
                ? _c(
                    "v-badge",
                    {
                      attrs: {
                        content: _vm.cases.length,
                        color: "grey",
                        "offset-x": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-center align-center",
                          staticStyle: {
                            "flex-shrink": "0",
                            "flex-grow": "0",
                            width: "26px",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "orange" } }, [
                            _vm._v("assignment"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column justify-center align-center",
          staticStyle: { "max-width": "450px" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-center" },
            [
              _c("v-icon", { attrs: { color: "orange" } }, [
                _vm._v("assignment"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ml-2",
                  staticStyle: { color: "rgba(0,0,0,0.6)" },
                },
                [_vm._v("\n                CASES\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "width100 my-2" }, [_c("v-divider")], 1),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              items: _vm.cases,
              "items-per-page": _vm.cases.length > 10 ? 10 : -1,
              "hide-default-footer": _vm.cases.length <= 10,
              "footer-props": {
                "items-per-page-options": [10, 20, -1],
                "show-current-page": true,
              },
              dense: "",
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (props) {
                  return [
                    _c("tr", [
                      _c(
                        "td",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "fontMonospace link text-truncate",
                              attrs: {
                                to: {
                                  name: "CaseDetail",
                                  params: { caseId: props.item.id },
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("truncate")(props.item.title, 40)
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }