<template>
    <v-alert type="info">
        Singleton address {{ address }}
        <br />
        <strong>{{ message }}</strong>
    </v-alert>
</template>
<script>
export default {
    name: 'EmptyClusterInfo',
    props: ["address", "message"]
}
</script>
