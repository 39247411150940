var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.props.componentFilterBase, {
    ref: "filterMenu",
    tag: "component",
    attrs: {
      value: _setup.props.explicitApply
        ? _setup.filterActive
        : _setup.numericFilterValueToReadable(
            _setup.filterValue,
            _setup.selectedFilterMode,
            _setup.props.time
          ),
    },
    on: {
      "on-clear-event": function ($event) {
        _setup.filterValue = {}
      },
      "on-remove-filter-event": function ($event) {
        return _setup.emit("on-remove-filter-event", _setup.props.label)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "label",
          fn: function () {
            return [
              _vm._v("\n        " + _vm._s(_setup.props.label) + "\n    "),
            ]
          },
          proxy: true,
        },
        {
          key: "icon",
          fn: function () {
            return [
              _vm._v(
                "\n        " + _vm._s(_setup.selectedFilterMode.icon) + "\n    "
              ),
            ]
          },
          proxy: true,
        },
        _setup.props.append
          ? {
              key: "append",
              fn: function () {
                return [
                  _vm._v("\n        " + _vm._s(_setup.props.append) + "\n    "),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "menuContent",
          fn: function () {
            return [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { mandatory: "", color: "indigo" },
                      model: {
                        value: _setup.selectedFilterMode,
                        callback: function ($$v) {
                          _setup.selectedFilterMode = $$v
                        },
                        expression: "selectedFilterMode",
                      },
                    },
                    _vm._l(_setup.numericModes, function (mode, i) {
                      return _c(
                        "v-list-item",
                        { key: i, attrs: { dense: "", value: mode } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c("v-icon", {
                                    attrs: { size: "21" },
                                    domProps: {
                                      textContent: _vm._s(mode.icon),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(mode.text) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mx-2 mb-2" },
                _vm._l(
                  _setup.selectedFilterMode.fields,
                  function (fieldSetting, i) {
                    return _c("div", { key: i }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex",
                          staticStyle: { "max-width": "245px" },
                        },
                        [
                          fieldSetting.label && !_setup.props.time
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-2 mr-2 font-weight-bold text-center caption",
                                  staticStyle: {
                                    "min-width": "40px",
                                    color: "rgba(0,0,0,0.6)",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        String(fieldSetting.label).toUpperCase()
                                      ) +
                                      ":\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "txFilters width100" },
                            [
                              _setup.props.time
                                ? _c(_setup.DateTimePicker, {
                                    attrs: {
                                      label: fieldSetting.label,
                                      dense: "",
                                      outlined: "",
                                      autofocus: "",
                                      "clear-icon": "mdi-close-circle",
                                      "emit-eagerly": "",
                                    },
                                    model: {
                                      value:
                                        _setup.filterValue[fieldSetting.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.filterValue,
                                          fieldSetting.name,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filterValue[fieldSetting.name]",
                                    },
                                  })
                                : _c("v-text-field", {
                                    attrs: {
                                      placeholder: fieldSetting.placeholder,
                                      "clear-icon": "mdi-close-circle",
                                      "full-width": "",
                                      outlined: "",
                                      autofocus: "",
                                      dense: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-bold caption body-1",
                                                  staticStyle: {
                                                    color: "rgba(0,0,0,0.6)",
                                                    "margin-top": "2px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _setup.props.append
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value:
                                        _setup.filterValue[fieldSetting.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.filterValue,
                                          fieldSetting.name,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filterValue[fieldSetting.name]",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ])
                  }
                ),
                0
              ),
              _vm._v(" "),
              _setup.props.explicitApply
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      staticStyle: {
                        padding: "0 8px 8px 8px",
                        "margin-top": "-6px",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "grey", small: "" },
                          on: {
                            click: function ($event) {
                              _setup.filterValue = {}
                              _setup.emitInputValue()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", small: "" } }, [
                            _vm._v(
                              "\n                    mdi-close-circle\n                "
                            ),
                          ]),
                          _vm._v("\n                CLEAR\n            "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "indigo", small: "" },
                          on: { click: _setup.emitInputValue },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", small: "" } }, [
                            _vm._v(
                              "\n                    mdi-filter-check\n                "
                            ),
                          ]),
                          _vm._v("\n                Apply\n            "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }