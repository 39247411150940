<script setup>
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

defineProps({
    deleteButtonTooltip: {
        type: String,
        default: "Delete"
    },
    large: {
        type: Boolean,
        default: false,
        required: false
    },
    disabled: {
        type: Boolean,
        default: false,
        required: false
    }
})

</script>
<template>
    <v-tooltip top>
        <template #activator="{ on }">
            <BaseDataModifyButton
                :disabled="disabled"
                icon
                color="error"
                :small="!large"
                v-bind="$attrs"
                v-on="{...$listeners, ...on}"
            >
                <v-icon :small="!large">delete</v-icon>
            </BaseDataModifyButton>
        </template>
        <span>{{ deleteButtonTooltip }}</span>
    </v-tooltip>
</template>
