<template>
    <div class="scroll-y height100">
        <v-container class="height100 px-4" fluid>
            <v-layout>
                <h1>Roles</h1>
                <v-spacer/>
            </v-layout>
            <v-divider/>
            <v-divider/>
            <v-data-table
                :headers="headers"
                :items="roles.data"
                :loading="rolesLoading"
                :options.sync="filter"
                :server-items-length="roles.total ? roles.total : 0"
                class="elevation-0 border mt-2"
                dense
                :footer-props="{
                    'items-per-page-options': [
                        15,
                        30,
                        50,
                        { text: 'All', value: -1 },
                    ],
                    'show-current-page': true,
                    'showFirstLastPage': true,
                }"
            >
                <template #item="{ item }">
                    <tr>
                        <td class="text-left">{{ item.name }}</td>
                        <td class="text-overflow">
                            <div class="d-flex justify-center text-right">
                                <div style="width: 30px">
                                    {{ item.count | formatNumber }}
                                </div>
                            </div>
                        </td>
                        <td class="text-xs-right text-overflow">
                            {{ item.created_at | formatDateTime }}
                        </td>
                        <td class="text-xs-right text-overflow">
                            {{ item.updated_at | formatDateTime }}
                        </td>
                    </tr>
                </template>
                <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                    <span>
                        {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                    </span>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";

export default {
    name: "RolesView",
    mixins: [routeSyncMixin],
    computed: {
        ...mapGetters([
            "rolesLoading",
            "roles",
        ]),
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                sortBy: {type: "ArrayOfStrings", default: () => ['created_at']},
                sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
            },
            headers: [
                {align: "center", text: "Name", value: "name"},
                {align: "center", text: "# Users", value: "count"},
                {align: "center", text: "Created At", value: "created_at"},
                {align: "center", text: "Updated At", value: "updated_at"},
            ],
        };
    },
    methods: {
        onFilterChange() {
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        refresh() {
            this.$store.dispatch("loadRoles", this.filter);
        }
    },
};
</script>
<style>
td {
    text-align: center;
    vertical-align: middle;
}
</style>