var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    staticClass: "cardShadow",
    attrs: { dense: "" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-icon", { staticClass: "d-block mr-2 ml-1" }, [
                  _vm._v("text-account"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Activity"),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _setup.renderTable
              ? _c(_setup.DataGrid, {
                  ref: "DataGridRef",
                  attrs: {
                    "bulk-operations": false,
                    headers: _setup.hasSuperAdminRole
                      ? _setup.logsHeaders
                      : _setup.logsHeaders.slice(1),
                    "get-data": _setup.getLogs,
                    "items-per-page": 5,
                    itemId: "id",
                    "sort-desc": "",
                    "custom-headers": "",
                    "footer-props": {
                      "items-per-page-options": [5, 50, 200],
                      "show-current-page": true,
                      showFirstLastPage: true,
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "headers",
                        fn: function ({ header }) {
                          return [
                            header.value === "user" && _setup.hasSuperAdminRole
                              ? _c(_setup.UserPicker, {
                                  key: header.value,
                                  staticClass: "mb-3 text-field-label-style",
                                  staticStyle: { padding: "0" },
                                  attrs: { itemMenu: "", "single-line": "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  model: {
                                    value: _setup.filterUserId,
                                    callback: function ($$v) {
                                      _setup.filterUserId = $$v
                                    },
                                    expression: "filterUserId",
                                  },
                                })
                              : _c("span", { key: header.value }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(header.text) +
                                      "\n                "
                                  ),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function ({ item }) {
                          return [
                            _setup.hasSuperAdminRole
                              ? _c(
                                  "td",
                                  { staticClass: "text-overflow text-left" },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-overflow text-left" },
                              [_vm._v(_vm._s(item.action))]
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-overflow" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("formatDateTime")(item.created_at)
                                  ) +
                                  "\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "text-left text-overflow",
                                staticStyle: { "max-width": "300px" },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: item.url },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(item.url) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    823168275
                  ),
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }