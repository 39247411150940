var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        [
          _c(
            "div",
            { staticClass: "text-truncate d-flex" },
            [
              _vm.icon
                ? _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "tertiary" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.icon) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("header", function () {
                return [
                  _vm.title
                    ? _c(
                        "span",
                        { staticClass: "text-button tertiary--text mr-2" },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.title) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("v-spacer", { staticClass: "mx-1" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _vm.numberOfResults !== undefined
                ? _c(
                    "v-chip",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: _vm.numberOfResults > 0 ? "success" : "grey",
                        small: "",
                      },
                    },
                    [
                      _c("b", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_setup.formatNumber(_vm.numberOfResults)) +
                            "\n                "
                        ),
                      ]),
                      _vm._v("  \n                "),
                      _vm.showAsResult
                        ? _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.numberOfResults === 1
                                    ? "result"
                                    : "results"
                                ) +
                                "\n                "
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.numberOfResults === 1
                                    ? "match"
                                    : "matches"
                                ) +
                                "\n                "
                            ),
                          ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-expansion-panel-content",
        {
          class: { noPaddingExpansionPanel: !_vm.padding },
          attrs: { eager: _vm.eager },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }