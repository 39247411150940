var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center justify-center" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { right: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "img",
                    _vm._g(
                      {
                        style: { width: `${_vm.iconWidth}px` },
                        attrs: { src: _vm.currency.image },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.currency.name))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }