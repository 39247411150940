<script setup>
import {ref} from "vue";

const props = defineProps({
    expanded: {
        type: Boolean,
        required: false,
        default: false
    }
})

const expanded = ref(props.expanded ? Array.from(Array(10).keys()) : [])
</script>

<template>
    <div>
        <v-expansion-panels v-model="expanded" multiple>
            <slot/>
        </v-expansion-panels>
    </div>

</template>