var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mt-1", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                _setup.ExpandWrapper,
                {
                  scopedSlots: _vm._u([
                    {
                      key: "header.left",
                      fn: function () {
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: _setup.isFilterActive
                                  ? "info"
                                  : "primary",
                                left: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        mdi-calendar-filter\n                    "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                    Time filter\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { align: "end", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(_setup.FancySelect, {
                            attrs: {
                              disabled: _setup.customEnabled,
                              items: _setup.presetOptions,
                              "item-text": "text",
                              "item-value": "value",
                            },
                            model: {
                              value: _setup.selectedPresetTimeOption,
                              callback: function ($$v) {
                                _setup.selectedPresetTimeOption = $$v
                              },
                              expression: "selectedPresetTimeOption",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { cols: "3" },
                        },
                        [
                          _c("v-switch", {
                            staticClass: "GeoIpTransactionsFilterCustomSwitch",
                            attrs: {
                              color: "info",
                              inset: "",
                              label: "Custom",
                            },
                            model: {
                              value: _setup.customEnabled,
                              callback: function ($$v) {
                                _setup.customEnabled = $$v
                              },
                              expression: "customEnabled",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(_setup.DateTimePicker, {
                            attrs: {
                              disabled: !_setup.customEnabled,
                              label: "Since",
                            },
                            model: {
                              value: _setup.dateStart,
                              callback: function ($$v) {
                                _setup.dateStart = $$v
                              },
                              expression: "dateStart",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(_setup.DateTimePicker, {
                            attrs: {
                              disabled: !_setup.customEnabled,
                              label: "Until",
                            },
                            model: {
                              value: _setup.dateEnd,
                              callback: function ($$v) {
                                _setup.dateEnd = $$v
                              },
                              expression: "dateEnd",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center align-end",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            _setup.SearchButton,
                            {
                              on: {
                                click: function ($event) {
                                  return _setup.emit("on-filter-apply-event")
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "icon",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                                mdi-filter-check\n                            "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(
                                "\n                            Apply filter\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(_setup.TransactionsPaginatedList, {
                ref: "transactionListRef",
                attrs: {
                  "get-data": _vm.peersTxsGetDataObject,
                  "items-per-page-default": 15,
                  "tx-map-function": (tx) => _setup.processTx(tx, ""),
                  "hide-timeline": "",
                  "hide-total": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }