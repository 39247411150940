var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.hideTimeZone
      ? _c("div", [
          _vm._v("\n        " + _vm._s(_vm.item.flag) + " "),
          _c("b", [_vm._v(_vm._s(_vm.item.common_name))]),
          _vm._v(" (" + _vm._s(_vm.item.region) + ")\n    "),
        ])
      : _c("div", [
          _vm._v("\n        " + _vm._s(_vm.item.flag) + " "),
          _c("b", [_vm._v(_vm._s(_vm.item.common_name))]),
          _vm._v(" (" + _vm._s(_vm.item.region) + ", "),
          _c("i", [_vm._v(_vm._s(_vm.item.timezone))]),
          _vm._v(")\n    "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }