<template>
    <div class="width">
        <v-card class="ma-3 rounded-card" hover :to="'/' + data.unit" :disabled="data.error">
            <div class="rounded-card">
                <v-toolbar class="elevation-0" style="border-radius: 5px 5px 0 0" height="56" dark text :color="currency.hexColor">
                    <v-img class="mr-2" max-width="35" :src="currency.image"/>
                    <h3>{{ currency.name }}</h3>
                </v-toolbar>
                <v-divider />
                <div v-if="loading" style="height: 312px">
                    <div class="d-flex align-center justify-center" style="height: 80%">
                        <ProgressCircular :color="currency.unit"/>
                    </div>
                </div>
                <div v-else-if="data.error" style="height: 312px">
                    <div class="d-flex align-center justify-center" style="height: 80%; color: rgba(0,0,0,0.6)">
                        <div class="title">Failed to retrieve data!</div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="data !== null" class="d-flex flex-wrap mt-2 justify-center">
                        <StatusCircular v-for="(block,index) in cryptoStats" :key="index" :name="block.name" :value="block.value" :color="block.color" />
                    </div>
                    <v-divider class="my-2"/>
                    <div class="d-flex justify-center align-center flex-column pb-2">
                        <table>
                            <tr>
                                <td class="text-center">
                                    <div class="font-weight-bold mr-2">
                                        Headers:
                                    </div>
                                </td>
                                <td>
                                    <div class="text-left ml-2 fontMonospace">
                                        {{
                                            $humanize.formatNumber(data.status.headers)
                                        }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <div class="font-weight-bold mr-2">
                                        Connections:
                                    </div>
                                </td>
                                <td>
                                    <div class="text-left ml-2">
                                        <span class="fontMonospace">
                                        {{ data.netinfo.connections }}
                                        </span>
                                        peers
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import StatusCircular from "@/components/Dashboard/StatusCircular";
import NameValueFlex from "@/components/common/NameValueFlex";
import ProgressCircular from "@/components/common/ProgressCircular";
export default {
    name: 'CurrencyPanel',
    components: {ProgressCircular, NameValueFlex, StatusCircular},
    props: {
        data: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false,
        },
        currency: {
            type: Object,
            required: true
        }
    },
    computed: {
        cryptoStats() {
            //BLOCKCHAIN DATA
            let blockchainData = this.data ? this.data.status.blocks === this.data.status.headers : false
            if (blockchainData)
                blockchainData = 1
            else
                blockchainData = this.data.status.blocks - this.data.status.headers

            //INDEXED DATA
            let indexedData = this.data ? this.data.status.indexHeight === this.data.status.headers : false
            if (indexedData)
                indexedData = 1
            else
                indexedData = this.data.status.indexHeight - this.data.status.headers

            //INDEXED DATA
            let importedData = this.data ? this.data.cluster.blocks === this.data.status.headers : false
            if (importedData)
                importedData = 1
            else
                importedData = this.data.cluster.blocks - this.data.status.headers

            //ALARM DATA
            let minAlarmsValue = 0
            let minAlarmsValueName

            Object.entries(this.data.alarm).forEach(([key, value]) => {
                // skip 'cluster_cospent' key on Ethereum currencies, as it's always equal to 0
                if(key === "cluster_cospent" && this.currency.typeFamily === 'ETH') return

                if (minAlarmsValue === 0 || value < minAlarmsValue) {
                    minAlarmsValue = value
                    minAlarmsValueName = key
                }
            })

            let alarmData =  this.data ? minAlarmsValue === this.data.status.headers : false
            if (alarmData)
                alarmData = 1
            else
                alarmData = minAlarmsValue - this.data.status.headers

            return [
                {
                    name: "BLOCKCHAIN",
                    value: blockchainData,
                    color: blockchainData < (this.data.thresholds.blockchain.critical -  this.data.status.headers) ? 'red' : this.currency.hexColor
                },
                {
                    name: "INDEXED",
                    value: indexedData,
                    color: indexedData < (this.data.thresholds.indexed.critical -  this.data.status.headers) ? 'red' : this.currency.hexColor
                },
                {
                    name: "IMPORTED",
                    value: importedData,
                    color: importedData < (this.data.thresholds.imported.critical -  this.data.status.headers) ? 'red' : this.currency.hexColor
                },
                {
                    name: "ALARMS",
                    value: alarmData,
                    color: alarmData < (this.data.thresholds[`alarm_${minAlarmsValueName}`].critical -  this.data.status.headers) ? 'red' : this.currency.hexColor
                }
            ]
        },
    }
}
</script>

<style scoped>
.rounded-card{
    border-radius: 4px !important;
}
.width {
    width: 256px;
}

</style>
