var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", { staticClass: "d-flex justify-center" }, [
            _c(
              "div",
              { staticClass: "skeletonCryptoInterceptorPanelContainer" },
              [
                _c("v-skeleton-loader", {
                  attrs: { width: "90px", loading: "", type: "card-heading" },
                }),
              ],
              1
            ),
          ])
        : _c(
            "v-chip",
            { staticClass: "px-2", attrs: { small: _vm.small } },
            [
              _c(
                "div",
                {
                  staticClass: "not-allowed",
                  staticStyle: { height: "20px", width: "16px" },
                },
                [
                  _vm.disabled
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "not-allowed",
                              attrs: { small: "", color: "grey" },
                            },
                            [
                              _vm._v(
                                "\n                    mdi-eye\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm.active === _vm.GREEN || _vm.active === _vm.RED
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            staticClass: "not-allowed",
                                            attrs: {
                                              small: "",
                                              color:
                                                _vm.active === _vm.GREEN
                                                  ? "green"
                                                  : "red",
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                            mdi-eye\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.state.status) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("pulsating-wrapper", {
                            attrs: { "pulse-color": "orange" },
                            scopedSlots: _vm._u([
                              {
                                key: "iconName",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                        mdi-eye\n                    "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "tooltip",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.state.status) +
                                        "\n                    "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex align-center ml-2",
                  staticStyle: { height: "100%" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "60%" } },
                    [_c("v-divider", { attrs: { vertical: "" } })],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.active === _vm.RED
                ? _c("ButtonWrapperHoverTooltip", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "active",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        small: "",
                                        icon: "",
                                        disabled: _vm.disabledActionButton,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "monitoring-start-event",
                                            {
                                              ip: _vm.ip,
                                              port: _vm.portComputed,
                                            }
                                          )
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { color: "green" } }, [
                                    _vm._v(
                                      "\n                        mdi-play\n                    "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "tooltip",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                Start monitoring\n            "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "alt",
                          fn: function () {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    icon: "",
                                    disabled: _vm.disabledActionButton,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "monitoring-start-event",
                                        { ip: _vm.ip, port: _vm.portComputed }
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      "\n                        mdi-play\n                    "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1418714438
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.active === _vm.GREEN || _vm.active === _vm.ORANGE
                ? _c("ButtonWrapperHoverTooltip", {
                    attrs: { disabled: _vm.disabledActionButton },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "active",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        small: "",
                                        icon: "",
                                        disabled: _vm.disabledActionButton,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "monitoring-stop-event",
                                            {
                                              ip: _vm.ip,
                                              port: _vm.portComputed,
                                            }
                                          )
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { color: "red" } }, [
                                    _vm._v(
                                      "\n                        mdi-stop\n                    "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "tooltip",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                Stop monitoring\n            "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "alt",
                          fn: function () {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    icon: "",
                                    disabled: _vm.disabledActionButton,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "monitoring-stop-event",
                                        { ip: _vm.ip, port: _vm.portComputed }
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      "\n                        mdi-stop\n                    "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      4096086294
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-badge",
                {
                  attrs: {
                    color: "red",
                    "offset-y": "11",
                    "offset-x": "11",
                    value:
                      _vm.state.status !== "NOT_MONITORED" && _vm.showBadge,
                    dot: "",
                  },
                },
                [_vm._t("extrabutton")],
                2
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }