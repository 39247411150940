var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.GenericPicker, {
        attrs: {
          items: _setup.cases,
          "get-items": _setup.getCases,
          value: _vm.value,
          loading: _setup.loading,
          "item-text": "title",
          "item-value": "id",
          "prepend-icon": "mdi-clipboard-text",
          label: "Case",
          "hide-details": "",
          "single-input": "",
          rules: _vm.rules,
        },
        on: { input: _setup.emitCaseId },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [_c(_setup.CaseSearchDisplay, { attrs: { item: item } })]
            },
          },
          {
            key: "selection",
            fn: function ({ item }) {
              return [_c(_setup.CaseSelectedDisplay, { attrs: { item: item } })]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }