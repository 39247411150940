<script setup>

import {watch, onUnmounted, ref, nextTick, computed} from "vue";
import {useStringFilter} from "@/components/Filters/Composables/useStringFilter";

const props = defineProps({
    //Visible Label
    label: {
        type: String,
        required: false,
    },
    //Defines modelled name
    name: {
        type: String,
        required: true,
    },
    componentFilterBase: {
        type: Object,
        required: true
    },
    explicitApply: {
        type: Boolean,
        required: false,
        default: false
    },
    additionalOptions: {
        type: Array,
        required: false,
        default: undefined
    },
    value: {
        type: Object,
        required: false
    },
    filterSubType: {
        required: false,
    }
})

const emit = defineEmits(['input', 'on-remove-filter-event'])
const {
    filterValue,
    filterValueFormatted,
    adequateNoun,
    formattedNoun,
    filterDisplayValue,
    stringFilterValueConverter
} = useStringFilter(props.filterSubType)
const filterMenuRef = ref(null)
const filterActive = ref(false)

const additionalOptionsValues = ref({})

const valueWatchable = computed(() => props.value)
watch(valueWatchable, () => {
    filterValue.value = valueWatchable.value?.values ? valueWatchable.value.values.join('\n') : filterValue.value
}, { immediate: true })

const emitInputValue = () => {
    nextTick(() => {
        filterMenuRef.value.closeMenu()
        const valueToEmit = stringFilterValueConverter(filterValueFormatted, additionalOptionsValues)
        filterActive.value = !!valueToEmit
        emit('input', valueToEmit)
    })
}

if (!props.explicitApply) {
    watch(filterValueFormatted, () => emit('input', stringFilterValueConverter(filterValueFormatted, additionalOptionsValues)), {
        deep: true,
        immediate: true
    })
}

const resetValue = () => {
    filterValue.value = ''
    additionalOptionsValues.value = {}
}

const textArea = ref(null)
const onOpen = () => {
    setTimeout(function () {
        textArea.value.focus();
    }, 100);
}

onUnmounted(() => emit('input', undefined))
</script>

<template>
    <component
        ref="filterMenuRef"
        :is="props.componentFilterBase"
        @on-open-event="onOpen"
        @on-clear-event="filterValue = ''"
        @on-remove-filter-event="emit('on-remove-filter-event', props.label)"
        :value="props.explicitApply ? filterActive : filterDisplayValue"
    >
        <template #label>
            {{ props.label }}
        </template>
        <template #icon>
            mdi-tag-text-outline
        </template>
        <template #menuContent>
            <div>
                <v-list dense style="padding: 0">
                    <v-list-item-group v-for="additionalOption in props.additionalOptions"
                                       :key="additionalOption.name"
                                       color="indigo"
                                       v-model="additionalOptionsValues[additionalOption.name]">
                        <v-list-item :value="true">
                            <template v-slot:default="{ active }">
                                <v-list-item-action class="mr-3">
                                    <v-checkbox :input-value="active"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="additionalOption.label"/>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </div>
            <div class="ma-2 txFiltersStrings">
                <div class="d-flex justify-space-between mb-1 font-weight-bold caption body-1">
                    <div>
                        <span>
                            {{ filterValueFormatted.length }}
                        </span>
                        <span style="color: rgba(0,0,0,0.6)">
                            {{ formattedNoun }}
                        </span>
                    </div>
                    <div>
                        <v-tooltip top>
                            <template #activator="{on, attrs}">
                                <v-icon
                                    v-on="on"
                                    v-bind="attrs"
                                    small
                                    color="blue">
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            Enter {{ adequateNoun }} separated by <span class="fontMonospace">;,</span> or <span
                            class="fontMonospace">new_line</span>
                        </v-tooltip>
                    </div>
                </div>
                <div class="border"
                     @keydown.enter.exact.prevent="emitInputValue">
                    <textarea
                        ref="textArea"
                        autofocus
                        style="width: 100%"
                        v-model="filterValue"
                        :placeholder="`Type or paste ${adequateNoun}`"
                        rows="7"/>
                </div>
            </div>
            <div v-if="props.explicitApply" class="d-flex" style="padding: 0 8px 8px 8px; margin-top: -6px">
                <v-btn
                    dark
                    color="grey"
                    small
                    @click="resetValue(); emitInputValue()"
                >
                    <v-icon left small>
                        mdi-close-circle
                    </v-icon>
                    CLEAR
                </v-btn>
                <v-spacer/>
                <v-btn
                    dark
                    color="indigo"
                    small
                    @click="emitInputValue"
                >
                    <v-icon left small>
                        mdi-filter-check
                    </v-icon>
                    Apply
                </v-btn>
            </div>
        </template>
    </component>
</template>

<style>

.txFiltersStrings textarea {
    line-height: 20px !important;
    font-family: 'Roboto Mono', monospace !important;
    white-space: pre;
    padding: 4px;
}

.txFiltersStrings .v-text-field__slot {
    margin-right: 0;
    margin-bottom: 4px;
}
</style>