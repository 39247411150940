var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "px-4", attrs: { fluid: "" } },
    [
      _c(
        _setup.DataProvider,
        {
          attrs: {
            data: _setup.caseDetail,
            "data-state": _setup.dataState,
            "ignore-loading": "",
            name: "CaseDetail",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _setup.loading
                ? _c("v-skeleton-loader", {
                    attrs: { type: "heading", height: "48px" },
                  })
                : _c("h1", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_setup.caseDetail?.title) +
                        "\n            "
                    ),
                  ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(_setup.CaseDetailModal, {
                attrs: {
                  caseDetail: _setup.caseDetail,
                  loading: _setup.loading,
                },
                on: { "save-event": _setup.getData },
                scopedSlots: _vm._u([
                  {
                    key: "showbutton",
                    fn: function ({ on }) {
                      return [
                        _c(
                          _setup.EditButton,
                          _vm._g({ attrs: { text: "case" } }, on)
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mb-2" }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [_c(_setup.CaseSummary)],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [_c(_setup.CaseDetailAddresses)],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [_c(_setup.CaseDetailGraphs)],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [_c(_setup.CaseDetailFiles)],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }