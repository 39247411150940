<script setup>
import {ref, watch} from "vue"

const props = defineProps({
    value: {
        type: String,
        required: false
    }
})

const emit = defineEmits(['on-close-event', 'on-open-event', 'on-clear-event', 'on-remove-filter-event'])
const menuVisible = ref(false)

const closeMenu = () => {
    menuVisible.value = false
}

watch(menuVisible, (newVal, oldVal) => {
    if (newVal && !oldVal)
        emit('on-open-event')
    else if (oldVal && !newVal)
        emit('on-close-event')
})

defineExpose({
    closeMenu
})


</script>

<template>
    <div
        class="txFilters"
        style="cursor: pointer"
        @click="menuVisible = !menuVisible">
        <v-menu
            content-class="whiteBackground"
            v-model="menuVisible"
            :close-on-content-click="false"
            bottom
            offset-y>
            <template #activator="{ on }">
                <span class="txFiltersLabel">
                    <div class="d-flex justify-space-between">
                        <div class="font-weight-bold caption body-1" style="color: rgba(0,0,0,0.6)">
                            <slot name="label"/>
                        </div>
                         <div @click="emit('on-remove-filter-event')" class="caption body-2 font-weight-light underline-on-hover mr-2" style="color: rgba(0,0,0,0.6)">
                            Remove
                        </div>
                    </div>
                    <div class="d-flex align-center mr-2">
                        <slot name="textField">
                            <v-text-field
                                style="max-width: 260px"
                                clearable
                                readonly
                                outlined
                                @click:clear="emit('on-clear-event')"
                                :value="props.value"
                                clear-icon="mdi-close-circle"
                                dense>
                                <template #prepend-inner>
                                    <div  class="d-flex">
                                        <div class="mx-2" style="min-width: 22px; margin-top: 2px">
                                            <v-icon size="20">
                                                <slot name="icon"/>
                                            </v-icon>
                                        </div>
                                    </div>
                                </template>
                                <template #append>
                                    <div class="font-weight-bold caption body-1 d-flex justify-center align-center" style="color: rgba(0,0,0,0.6); margin-top: 2px">
                                        <slot name="append"/>
                                    </div>
                                </template>
                            </v-text-field>
                        </slot>
                    </div>
                </span>
            </template>
            <div>
                <slot name="menuContent"/>
            </div>
        </v-menu>
    </div>
</template>

<style>

.txFiltersLabel input {
    font-family: 'Roboto Mono', monospace !important;
    margin-bottom: 2px;
    cursor: pointer;
}

.txFiltersLabel > .v-text-field > .v-input__control > .v-input__slot {
    cursor: pointer !important;
}

.whiteBackground {
    background-color: white;
}

</style>