var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.address
    ? _c(
        "div",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  staticStyle: {
                    "border-bottom": "1px #0000001F solid",
                    height: "49px",
                  },
                },
                [
                  _c(
                    "v-app-bar",
                    { attrs: { elevation: "0", dense: "", color: "white" } },
                    [
                      _c(
                        "v-app-bar",
                        {
                          staticClass: "px-4",
                          staticStyle: { margin: "0 auto", width: "160px" },
                          attrs: { dense: "", color: "white", elevation: "0" },
                        },
                        [
                          _c(
                            "v-tabs",
                            {
                              ref: "tabsRef",
                              staticStyle: { "max-width": "95% !important" },
                              attrs: {
                                "show-arrows": "",
                                "slider-size": "3",
                                grow: false,
                                light: "",
                                "background-color": "white",
                                "slider-color": _vm.currency.unit,
                              },
                            },
                            _vm._l(
                              _vm.tabs,
                              function (
                                { route, publicName, disabled },
                                index
                              ) {
                                return _c(
                                  "v-tooltip",
                                  {
                                    key: route,
                                    attrs: {
                                      bottom: "",
                                      "open-delay": "500",
                                      disabled: !disabled,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center",
                                                    },
                                                    "div",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-tab",
                                                    {
                                                      attrs: {
                                                        to: _vm.finalRoutes[
                                                          index
                                                        ].route,
                                                        disabled: disabled,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(publicName) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(
                                      "\n                            View is disabled, because address"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " hasn't been indexed yet.\n                        "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c("EntityHeaderLabel", {
                            attrs: {
                              label:
                                _vm.currency.unit.toUpperCase() + " ADDRESS:",
                              entity: _vm.address,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "px-4", attrs: { fluid: "" } },
            [
              _c("router-view", {
                on: { "component-loaded-event": _vm.onClick },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }