var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          value: _vm.value,
          items: _vm.computedLocalClusters,
          disabled: _vm.loading,
          loading: _vm.loading,
          clearable: "",
          "item-text": "entityid",
          "item-value": "entityid",
          label: "Cluster Address",
          outlined: "",
          "hide-details": "",
        },
        on: {
          input: (newCluster) => {
            _vm.$emit("input", newCluster)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "no-data",
            fn: function () {
              return [
                _c(
                  "v-list-item",
                  [
                    _c("v-list-item-title", [
                      _c("strong", [_vm._v("No cluster available.")]),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "selection",
            fn: function ({ item }) {
              return [
                _c(
                  "span",
                  {
                    staticClass:
                      "d-flex text-truncate text--black align-center fontMonospace",
                    staticStyle: { "max-width": "99%" },
                  },
                  [
                    _c("img", {
                      staticClass: "mr-4",
                      attrs: {
                        src: _vm.currency.image,
                        alt: _vm.currency.unit,
                      },
                    }),
                    _vm._v(
                      "\n                                 " +
                        _vm._s(item.entityid) +
                        "\n                             "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c("v-list-item-action", [
                  _c("img", {
                    attrs: { src: _vm.currency.image, alt: _vm.currency.unit },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", {
                      staticClass: "fontMonospace",
                      domProps: { textContent: _vm._s(item.entityid) },
                    }),
                    _vm._v(" "),
                    _c("v-list-item-subtitle", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(item.isCustom ? "custom" : "co-spent") +
                            " |\n                    " +
                            _vm._s(_vm._f("formatNumber")(item.addresses)) +
                            " "
                        ),
                      ]),
                      _vm._v(" Addresses\n                "),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                item.isLoading
                  ? _c(
                      "v-list-item-action",
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            size: "30",
                            color: _vm.currency.unit,
                            indeterminate: "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }