import { render, staticRenderFns } from "./LatestTransactions.vue?vue&type=template&id=78031bda&scoped=true&"
import script from "./LatestTransactions.vue?vue&type=script&setup=true&lang=js&"
export * from "./LatestTransactions.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./LatestTransactions.vue?vue&type=style&index=0&id=78031bda&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78031bda",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VBtnToggle,VIcon,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('78031bda')) {
      api.createRecord('78031bda', component.options)
    } else {
      api.reload('78031bda', component.options)
    }
    module.hot.accept("./LatestTransactions.vue?vue&type=template&id=78031bda&scoped=true&", function () {
      api.rerender('78031bda', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/currency/LatestTransactions.vue"
export default component.exports