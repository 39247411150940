<script setup>

import {computed} from "vue";
import store from "@/store";
import UseGEStore from "@/views/GraphExplorer/Composables/UseGEStore";
import TransactionSingle from "@/components/transaction/TransactionSingle.vue";
import TransactionsHeader from "@/components/transaction/Components/TransactionsHeader.vue";

const {GESelectedElement} = UseGEStore()
const transaction = computed(() => GESelectedElement.value.data?.typeSpecific)


store.dispatch('selectCurrency', {currencyUnit: 'BTC'})
const currency = computed(() => store.getters.currency)

</script>

<template>
    <div>
        <v-container class="px-7 mt-0 pt-1 transactionContainer">
            <TransactionsHeader no-change/>
            <TransactionSingle :transaction="transaction" expanded/>
        </v-container>
    </div>
</template>

<style scoped>

</style>
