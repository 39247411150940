var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex align-center" }, [
              _c("img", {
                staticStyle: {
                  filter: "opacity(0.6)",
                  width: "22px",
                  "margin-right": "8px",
                },
                attrs: { src: "/img/ico/owner-icon.svg", alt: "Owner" },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("Cluster Owners")]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _setup.loading
              ? _c(
                  "div",
                  [
                    _c("v-skeleton-loader", {
                      attrs: { height: "173", type: "image" },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _setup.computedOwners.length > 0
                      ? _c("v-data-table", {
                          staticClass: "border",
                          attrs: {
                            dense: "",
                            headers: _setup.headersOwners,
                            items: _setup.computedOwners,
                            "hide-default-footer": "",
                            "items-per-page": -1,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function (props) {
                                  return [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-overflow robotoMono",
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(props.item.name) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-overflow" },
                                        [
                                          _c(_setup.PrimarySelector, {
                                            attrs: {
                                              isPrimary: props.item.isPrimary,
                                              item: props.item,
                                              currency: _setup.currency,
                                              selectable: false,
                                            },
                                            on: {
                                              "set-primary-event":
                                                _setup.setPrimaryOwner,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3254607883
                          ),
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "title font-weight-light text-center",
                          },
                          [
                            _vm._v(
                              "\n                No owners assigned.\n            "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }