<script setup>
import HeaderLined from "@/components/common/HeaderLined.vue";
import {ImportFormat} from "@/components/common/types/import";
import {computed, inject} from "vue";

const headerItemStyle = "grid-item-header"
const itemTypeStyle = "d-flex width100 fontMonospace justify-center gridItem font-weight-bold"
const exampleItemTypeStyle = "d-flex width100 fontMonospace justify-center gridItem"

const importFormat = inject("importFormat")

const isStandardImportType = computed(() => importFormat.value === ImportFormat.Standard)
const isCaseImportType = computed(() => importFormat.value === ImportFormat.Case)

</script>

<template>
    <v-expansion-panels class="fileFormatInfo">
        <v-expansion-panel>
            <v-expansion-panel-header>
                <div>
                    <v-icon color="info" left>
                        mdi-information-slab-box
                    </v-icon>
                    File format
                </div>
                <v-spacer/>
                <template v-slot:actions>
                    <v-icon color="primary">
                        $expand
                    </v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-once>
                <div
                    :class="['grid-container', isStandardImportType ? 'grid-columns-address-import' : 'grid-columns-case-import']">
                    <div :class="headerItemStyle">
                        <header-lined icon="mdi-currency-sign" text="Crypto*"/>
                    </div>
                    <div :class="headerItemStyle">
                        <header-lined icon="mdi-tag-text-outline" text="Entity*"/>
                    </div>
                    <div :class="[isStandardImportType ? 'first-row-item' : '', headerItemStyle]">
                        <header-lined :text="`Identity${isCaseImportType ? '*' : ''}`" icon="mdi-card-account-details-outline"/>
                    </div>
                    <div :class="headerItemStyle">
                        <header-lined icon="mdi-label" text="Categories"/>
                    </div>
                    <div :class="headerItemStyle">
                        <header-lined icon="mdi-account" text="Owners"/>
                    </div>
                    <div>
                        <div :class="itemTypeStyle">
                            crypto
                        </div>
                    </div>
                    <div>
                        <div :class="itemTypeStyle">
                            address
                        </div>
                    </div>
                    <div>
                        <div :class="itemTypeStyle">
                            label
                        </div>
                    </div>
                    <div v-if="isStandardImportType">
                        <div :class="itemTypeStyle">
                            source
                        </div>
                    </div>
                    <div v-if="isStandardImportType">
                        <div :class="itemTypeStyle">
                            url
                        </div>
                    </div>
                    <div>
                        <div :class="itemTypeStyle">
                            categories
                        </div>
                    </div>
                    <div>
                        <div :class="itemTypeStyle">
                            owners
                        </div>
                    </div>
                    <div>
                        <div :class="exampleItemTypeStyle">
                            BTC
                        </div>
                    </div>
                    <div>
                        <div :class="exampleItemTypeStyle">
                            1NWDhnb..
                        </div>
                    </div>
                    <div>
                        <div :class="exampleItemTypeStyle">
                            Send Bitcoin
                        </div>
                    </div>
                    <div v-if="isStandardImportType">
                        <div :class="exampleItemTypeStyle">
                            report #123
                        </div>
                    </div>
                    <div v-if="isStandardImportType">
                        <div :class="exampleItemTypeStyle">
                            www.anyurl.com
                        </div>
                    </div>
                    <div>
                        <div :class="exampleItemTypeStyle">
                            "cat1,cat2"
                        </div>
                    </div>
                    <div>
                        <div :class="exampleItemTypeStyle">
                            "John,Jane"
                        </div>
                    </div>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<style lang="scss">

.fileFormatInfo .border {
    border: 1px solid rgba(0, 0, 0, .12) !important;
    border-color: rgba(0, 0, 0, .12) !important;
}

.fileFormatInfo .grid-container {
    display: grid;
    white-space: nowrap;
}

.grid-columns-address-import {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-columns-case-import {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.fileFormatInfo .grid-item-header {
    margin-left: 8px;
    margin-right: 8px;
}

.fileFormatInfo .gridItem {
    border: 1px solid grey;
}

.fileFormatInfo .first-row-item {
    grid-column: 3 / span 3;
}

.fileFormatInfo .v-expansion-panel::before {
    @extend .border !optional;
    box-shadow: none !important;
}

.fileFormatInfo .v-expansion-panel {
    @extend .border !optional;
    box-shadow: none !important;
}

.fileFormatInfo .v-expansion-panel::after {
    @extend .border !optional;
    box-shadow: none !important;
}

</style>