<script setup>
import {computed, nextTick, onUnmounted, ref, watch} from "vue";
import store from "@/store";
import ClusterPicker from "@/components/common/ClusterPicker.vue";
import {FilterType} from "@/components/common/types/filter";

const props = defineProps({
    //Visible Label
    label: {
        type: String,
        required: false,
    },
    //Defines modelled name
    name: {
        type: String,
        required: true,
    },
    componentFilterBase: {
        type: Object,
        required: true
    },
    explicitApply: {
        type: Boolean,
        required: false,
        default: false
    },
    additionalOptions: {
        type: Array,
        required: false,
        default: undefined
    },
    value: {
        type: Object,
        required: false
    }
})

const currency = computed(() => store.getters.currency)

const emit = defineEmits(['input', 'on-remove-filter-event'])

const filterMenuRef = ref(null)

const emitInputValue = () => {
    nextTick(() => {
        filterMenuRef.value.closeMenu()
        emit('input', createValueToEmit())
    })
}

const resetValue = () => {
    selectedClusters.value = []
}

const createValueToEmit = () => {
    if (selectedClusters.value?.length > 0) {
        return {
            op: "E",
            type: FilterType.Strings,
            values: selectedClusters.value,
            opt: {}
        }
    }
    return undefined
}

const selectedClusters = ref([])
const filterActive = computed(() => selectedClusters.value?.length > 0)

const clustersToAdd = ref([])
const valueWatchable = computed(() => props.value)
watch(valueWatchable, () => {
    selectedClusters.value = valueWatchable.value?.values ? valueWatchable.value.values : selectedClusters.value
}, { immediate: true})

if (!props.explicitApply) {
    watch(selectedClusters, () => emit('input', createValueToEmit()), {
        deep: true,
        immediate: true
    })
}

watch(clustersToAdd, () => {
    if (clustersToAdd.value.length > 0) {
        if (!selectedClusters.value.includes(clustersToAdd.value[0].entity_id)) {
            selectedClusters.value.push(clustersToAdd.value[0].entity_id)
        }
        clustersToAdd.value = []
    }

})

const filterDisplayValue = computed(() => {
    return selectedClusters.value.length === 1 ? '1 Cluster' : `${selectedClusters.value.length} Clusters`
})

const removeCluster = (clusterToRemove) => {
    selectedClusters.value = selectedClusters.value.filter(cluster => cluster !== clusterToRemove)
}

const tableHeaders = [
    { text: 'Cluster ID', align: 'start', sortable: false, value: 'entity_id', width: 200 },
    {text: '', align: 'end', value: '', sortable: false, filterable: false, width: "32px"}
]

onUnmounted(() => emit('input', undefined))
</script>

<template>
    <component
        ref="filterMenuRef"
        :is="props.componentFilterBase"
        @on-clear-event="selectedClusters = []"
        @on-remove-filter-event="emit('on-remove-filter-event', props.label)"
        :value="props.explicitApply ? filterActive : filterDisplayValue"
    >
        <template #label>
            {{ props.label }}
        </template>
        <template #icon>
            mdi-hub-outline
        </template>
        <template #menuContent>
            <div>
                <v-data-table
                    :items="selectedClusters"
                    :items-per-page="5"
                    :headers="tableHeaders"
                    :footer-props="{
                        'items-per-page-options': [5],
                        'show-current-page': true,
                    }"
                    dense
                    height="192px"
                    no-data-text="No clusters selected."
                >
                    <template #item="{ item, index }">
                        <tr>
                            <td style="max-width: 220px; text-align: left" class="text-truncate">
                                {{ item }}
                            </td>
                            <td>
                                <div style="width: 32px; min-width: 32px">
                                    <v-icon @click="() => removeCluster(item)" color="error" small>
                                        mdi-delete
                                    </v-icon>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template #footer.prepend>
                        <v-spacer/>
                    </template>
                </v-data-table>
                <ClusterPicker autofocus class="pa-4" :currency-unit="currency.unit" v-model="clustersToAdd"/>
                <div v-if="props.explicitApply" class="d-flex" style="padding: 0 8px 8px 8px; margin-top: -6px">
                    <v-btn
                        dark
                        color="grey"
                        small
                        @click="resetValue(); emitInputValue()"
                    >
                        <v-icon left small>
                            mdi-close-circle
                        </v-icon>
                        CLEAR
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        dark
                        color="indigo"
                        small
                        @click="emitInputValue"
                    >
                        <v-icon left small>
                            mdi-filter-check
                        </v-icon>
                        Apply
                    </v-btn>
                </div>
            </div>
        </template>
    </component>
</template>

<style>

</style>