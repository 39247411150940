var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.props.componentFilterBase, {
    ref: "filterMenuRef",
    tag: "component",
    attrs: {
      value: _setup.props.explicitApply
        ? _setup.filterActive
        : _setup.filterDisplayValue,
    },
    on: {
      "on-open-event": _setup.onOpen,
      "on-clear-event": function ($event) {
        _setup.filterValue = ""
      },
      "on-remove-filter-event": function ($event) {
        return _setup.emit("on-remove-filter-event", _setup.props.label)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function () {
          return [_vm._v("\n        " + _vm._s(_setup.props.label) + "\n    ")]
        },
        proxy: true,
      },
      {
        key: "icon",
        fn: function () {
          return [_vm._v("\n        mdi-tag-text-outline\n    ")]
        },
        proxy: true,
      },
      {
        key: "menuContent",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(
                  "v-list",
                  { staticStyle: { padding: "0" }, attrs: { dense: "" } },
                  _vm._l(
                    _setup.props.additionalOptions,
                    function (additionalOption) {
                      return _c(
                        "v-list-item-group",
                        {
                          key: additionalOption.name,
                          attrs: { color: "indigo" },
                          model: {
                            value:
                              _setup.additionalOptionsValues[
                                additionalOption.name
                              ],
                            callback: function ($$v) {
                              _vm.$set(
                                _setup.additionalOptionsValues,
                                additionalOption.name,
                                $$v
                              )
                            },
                            expression:
                              "additionalOptionsValues[additionalOption.name]",
                          },
                        },
                        [
                          _c("v-list-item", {
                            attrs: { value: true },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ active }) {
                                    return [
                                      _c(
                                        "v-list-item-action",
                                        { staticClass: "mr-3" },
                                        [
                                          _c("v-checkbox", {
                                            attrs: { "input-value": active },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              textContent: _vm._s(
                                                additionalOption.label
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ma-2 txFiltersStrings" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-space-between mb-1 font-weight-bold caption body-1",
                },
                [
                  _c("div", [
                    _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_setup.filterValueFormatted.length) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "rgba(0,0,0,0.6)" } }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_setup.formattedNoun) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { small: "", color: "blue" } },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                                mdi-help-circle-outline\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(
                            "\n                        Enter " +
                              _vm._s(_setup.adequateNoun) +
                              " separated by "
                          ),
                          _c("span", { staticClass: "fontMonospace" }, [
                            _vm._v(";,"),
                          ]),
                          _vm._v(" or "),
                          _c("span", { staticClass: "fontMonospace" }, [
                            _vm._v("new_line"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "border",
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      )
                        return null
                      $event.preventDefault()
                      return _setup.emitInputValue.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _setup.filterValue,
                        expression: "filterValue",
                      },
                    ],
                    ref: "textArea",
                    staticStyle: { width: "100%" },
                    attrs: {
                      autofocus: "",
                      placeholder: `Type or paste ${_setup.adequateNoun}`,
                      rows: "7",
                    },
                    domProps: { value: _setup.filterValue },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _setup.filterValue = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _setup.props.explicitApply
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: {
                      padding: "0 8px 8px 8px",
                      "margin-top": "-6px",
                    },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { dark: "", color: "grey", small: "" },
                        on: {
                          click: function ($event) {
                            _setup.resetValue()
                            _setup.emitInputValue()
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "", small: "" } }, [
                          _vm._v(
                            "\n                    mdi-close-circle\n                "
                          ),
                        ]),
                        _vm._v("\n                CLEAR\n            "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: { dark: "", color: "indigo", small: "" },
                        on: { click: _setup.emitInputValue },
                      },
                      [
                        _c("v-icon", { attrs: { left: "", small: "" } }, [
                          _vm._v(
                            "\n                    mdi-filter-check\n                "
                          ),
                        ]),
                        _vm._v("\n                Apply\n            "),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }