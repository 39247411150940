import {extend, localize, validate, ValidationObserver, ValidationProvider} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import currencyConfig from "@/configurations/currency-config";
import * as bip39 from "bip39";


import VTextFieldValidateable from "@/components/common/ValidateableComponents/VTextFieldValidateable"
import VSelectValidateable from "@/components/common/ValidateableComponents/VSelectValidateable"
import VTextAreaValidateable from "@/components/common/ValidateableComponents/VTextAreaValidateable"
import VAutoCompleteValidatable from "@/components/common/ValidateableComponents/VAutoCompleteValidatable"

export function apply(Vue) {
    Object.keys(rules).forEach(rule => {
        extend(rule, rules[rule]);
    });

    //todo FE rules for password
    extend('password', {
        validate: value => {
            
            if(value.length < 10){
                return '{_field_} must be 10 characters or longer.'
            }
            var rules = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9\.#?!@$%^&*-]).{10,}$/
            return value?.match(rules)
        },
        message: '{_field_} must contain a combination of Lowercase, Uppercase Letters, and a Symbol.',
    });

    extend('confirmation', {
        params: ['target'],
        validate(value, { target }) {
            return value === target;
        },
        message: '{_field_} does not match'
    });

    extend('roles', {
        validate(value) {
            return value.length >= 1;
        },
        message: 'Selection of at least one role is mandatory'
    });

    extend('crypto', {
        validate(value) {
            return currencyConfig.isAddressValid(value)
        },
        message: 'The {_field_} field must be a valid crypto address'
    });

    extend('entity_id', {
        validate(value) {
            if(value?.length > 250) return
            const rules = /^[a-zA-Z0-9_-]*$/
            return value?.match(rules)
        },
        message: 'Allowed characters are letters a-z, A-Z, 0-9, _ and -.'
    })

    extend('port', {
        validate(value) {
            return !isNaN(Number(value)) && Number(value) > 0 && Number(value) < 65536
        },
        message: '{_field_} must be valid crypto address'
    });


    extend('ip', {
        validate(value) {
            if (value === "" || value === undefined)
                return false
            let ipv46_regex = /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$)/gm;
            return ipv46_regex.test(value)
        },
        message: '{_field_} must be valid IP address'
    });

    extend('mnemonic', {
        params: ['language'],
        validate(value, { language }) {
            return bip39.validateMnemonic(value, bip39.wordlists[language])
        }
    })

    localize('en', en);

    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('ValidationProvider', ValidationProvider);

    Vue.component('v-text-field-validateable', VTextFieldValidateable)
    Vue.component('v-select-validateable', VSelectValidateable)
    Vue.component('v-textarea-validateable', VTextAreaValidateable)
    Vue.component('v-autocomplete-validateable', VAutoCompleteValidatable)
}
