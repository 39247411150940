import {unref} from "vue";

export default () =>
{
    const validateActiveFilters = (activeFilters) => {

        activeFilters = unref(activeFilters)

        for (const [key, value] of Object.entries(activeFilters)) {

            if (["inputAddresses","outputAddresses","time", "inputTities", "outputTities"].includes(key) && value)
            {
                return true
            }
        }
        return false
    }

    return { validateActiveFilters }
}