var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("span", { staticClass: "fontMonospace" }, [
      _vm._v(_vm._s(_vm._f("numberFormatWithSpaces")(_vm.item.asn))),
    ]),
    _vm._v(", "),
    _c("b", [_vm._v(_vm._s(_vm.item.country_code))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }