var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.props.componentFilterBase, {
    ref: "filterMenuRef",
    tag: "component",
    attrs: {
      value: _setup.props.explicitApply
        ? _setup.filterActive
        : _setup.filterDisplayValue,
    },
    on: {
      "on-clear-event": function ($event) {
        _setup.selectedClusters = []
      },
      "on-remove-filter-event": function ($event) {
        return _setup.emit("on-remove-filter-event", _setup.props.label)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function () {
          return [_vm._v("\n        " + _vm._s(_setup.props.label) + "\n    ")]
        },
        proxy: true,
      },
      {
        key: "icon",
        fn: function () {
          return [_vm._v("\n        mdi-hub-outline\n    ")]
        },
        proxy: true,
      },
      {
        key: "menuContent",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("v-data-table", {
                  attrs: {
                    items: _setup.selectedClusters,
                    "items-per-page": 5,
                    headers: _setup.tableHeaders,
                    "footer-props": {
                      "items-per-page-options": [5],
                      "show-current-page": true,
                    },
                    dense: "",
                    height: "192px",
                    "no-data-text": "No clusters selected.",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function ({ item, index }) {
                        return [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-truncate",
                                staticStyle: {
                                  "max-width": "220px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "32px",
                                    "min-width": "32px",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "error", small: "" },
                                      on: {
                                        click: () => _setup.removeCluster(item),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    mdi-delete\n                                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "footer.prepend",
                      fn: function () {
                        return [_c("v-spacer")]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c(_setup.ClusterPicker, {
                  staticClass: "pa-4",
                  attrs: {
                    autofocus: "",
                    "currency-unit": _setup.currency.unit,
                  },
                  model: {
                    value: _setup.clustersToAdd,
                    callback: function ($$v) {
                      _setup.clustersToAdd = $$v
                    },
                    expression: "clustersToAdd",
                  },
                }),
                _vm._v(" "),
                _setup.props.explicitApply
                  ? _c(
                      "div",
                      {
                        staticClass: "d-flex",
                        staticStyle: {
                          padding: "0 8px 8px 8px",
                          "margin-top": "-6px",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { dark: "", color: "grey", small: "" },
                            on: {
                              click: function ($event) {
                                _setup.resetValue()
                                _setup.emitInputValue()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { left: "", small: "" } }, [
                              _vm._v(
                                "\n                        mdi-close-circle\n                    "
                              ),
                            ]),
                            _vm._v(
                              "\n                    CLEAR\n                "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: { dark: "", color: "indigo", small: "" },
                            on: { click: _setup.emitInputValue },
                          },
                          [
                            _c("v-icon", { attrs: { left: "", small: "" } }, [
                              _vm._v(
                                "\n                        mdi-filter-check\n                    "
                              ),
                            ]),
                            _vm._v(
                              "\n                    Apply\n                "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }