var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationProvider", {
    attrs: {
      disabled: _vm.$attrs.disabled,
      name: _vm.$attrs.label,
      rules: _vm.rules,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors }) {
          return [
            _c(
              "v-textarea",
              _vm._g(
                _vm._b(
                  {
                    style: _vm.styles,
                    attrs: { "error-messages": errors },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "prepend",
                          fn: function () {
                            return [_vm._t("prepend")]
                          },
                          proxy: true,
                        },
                        {
                          key: "append",
                          fn: function () {
                            return [_vm._t("append")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.innerValue,
                      callback: function ($$v) {
                        _vm.innerValue = $$v
                      },
                      expression: "innerValue",
                    },
                  },
                  "v-textarea",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }