import {computed, ref, watch} from "vue"
import FilterContentNumeric from "@/components/Filters/FilterContentNumeric.vue";
import FilterContentStrings from "@/components/Filters/FilterContentStrings.vue";
import FilterContentCategories from "@/components/Filters/FilterContentCategories.vue";
import store from '@/store'
import {deepClone} from "@/utils";
import FilterContentClusters from "@/components/Filters/FilterContentClusters.vue";
import {FilterStringSubtype, FilterType} from "@/components/common/types/filter";

const _pushToQuery = (route, router, filtersOptions, filterValues) => {
    const newQuery = route.query ? deepClone(route.query) : {}

    for (const [key, value] of Object.entries(newQuery)) {

        const filterName = key.split('_')[0]
        if (!filterName)
            continue

        if(filtersOptions.value.some(filterOption => filterOption.name === filterName)){
            delete newQuery[key]
        }
    }

    for (const [key, value] of Object.entries(filterValues)) {

        if (!key || !value)
            continue

        newQuery[`${key}_op`] = value.op
        newQuery[`${key}_values`] = value.values
    }
    router.push({query: newQuery}).catch(error => {
        if (error.name !== "NavigationDuplicated") {
            console.error(error.name)
            throw error
        }
    })
}

/**
 *
 * @param route
 * @param router
 * @return Currently selected filters, Available filter options
 */
export default
(route, router) =>
{
    //STORE
    const currency = computed(() => store.getters.currency)

    const filtersOptions = ref([
        {
            label: "Total output value",
            component: FilterContentNumeric,
            name: "outputsValue",
            append: currency.value.unit,
            filterType: FilterType.Numeric,
        },
        {
            label: "Total input value",
            component: FilterContentNumeric,
            name: "inputsValue",
            append: currency.value.unit,
            filterType: FilterType.Numeric,
        },
        {
            label: "Fee value",
            component: FilterContentNumeric,
            name: "fee",
            append: currency.value.unit,
            filterType: FilterType.Numeric,
        },
        {
            label: "Timestamp",
            component: FilterContentNumeric,
            name: "time",
            time: true,
            filterType: FilterType.Numeric,
        },
        {
            label: "Single input value",
            component: FilterContentNumeric,
            name: "inputValue",
            append: currency.value.unit,
            filterType: FilterType.Numeric,
        },
        {
            label: "Single output value",
            component: FilterContentNumeric,
            name: "outputValue",
            append: currency.value.unit,
            filterType: FilterType.Numeric,
        },
        {
            label: "Number of inputs",
            component: FilterContentNumeric,
            name: "numInputs",
            filterType: FilterType.Numeric,
        },
        {
            label: "Number of outputs",
            component: FilterContentNumeric,
            name: "numOutputs",
            filterType: FilterType.Numeric,
        },
        {
            label: "Input addresses",
            component: FilterContentStrings,
            name: "inputAddresses",
            filterType: FilterType.Strings,
            filterSubType: FilterStringSubtype.Addresses
        },
        {
            label: "Output addresses",
            component: FilterContentStrings,
            name: "outputAddresses",
            filterType: FilterType.Strings,
            filterSubType: FilterStringSubtype.Addresses
        },
        {
            label: "Categories",
            component: FilterContentCategories,
            name: "categories",
            filterType: FilterType.Categories,
        },
        {
            label: "Input clusters",
            component: FilterContentClusters,
            name: "inputTities",
            filterType: FilterType.Strings,
            filterSubType: FilterStringSubtype.Clusters
        },
        {
            label: "Output clusters",
            component: FilterContentClusters,
            name: "outputTities",
            filterType: FilterType.Strings,
            filterSubType: FilterStringSubtype.Clusters
        },
    ])

    const { newSelectedFilters, newSelectedFiltersValues } = _readActiveFiltersAndValuesFromQuery(filtersOptions.value, route)

    const selectedFilters = ref(newSelectedFilters)
    const selectedFiltersValues = ref(newSelectedFiltersValues)

    const pushToQuery = _pushToQuery.bind(this, route, router, filtersOptions)

    watch(
        () => route.query,
        async () => {
            const { newSelectedFilters, newSelectedFiltersValues } = _readActiveFiltersAndValuesFromQuery(filtersOptions.value, route)
            selectedFilters.value = newSelectedFilters
            selectedFiltersValues.value = newSelectedFiltersValues
        }
    )

    return { selectedFilters, selectedFiltersValues, filtersOptions, pushToQuery }
}

function _readActiveFiltersAndValuesFromQuery(filtersOptions, route)
{

    const categoriesFilter = filtersOptions.find(filterOption => filterOption.filterType === FilterType.Categories)
    const ensureKeyExistsInObject = (key, object, filterName) => {
        if (object.hasOwnProperty(key))
        {
            return object[key]
        }
        object[key] = { opt: {}, type: filtersOptions.find(x => filterName === x.name).filterType}
        return object[key]
    }

    const newSelectedFiltersValues = {}
    const newSelectedFilters = []
    for (const [key, value] of Object.entries(route.query)) {

        const filterName = key.split('_')[0]
        const filterKey = key.split('_')[1]

        if (!filterName || !filterKey)
            continue

        filtersOptions.forEach(filterOption => {
            if (filterOption.name === filterName)
            {
                if (!newSelectedFilters.includes(filterOption))
                {
                    newSelectedFilters.push(filterOption)
                }
            }
        })

        if (filterKey === 'op')
        {
            const filterObject = ensureKeyExistsInObject(filterName, newSelectedFiltersValues, filterName)
            filterObject[filterKey] = value
        }

        if (filterKey === 'values') {
            const filterObject = ensureKeyExistsInObject(filterName, newSelectedFiltersValues, filterName)
            if(Array.isArray(value))
            {
                filterObject[filterKey] = deepClone(value)
            } else {
                filterObject[filterKey]  = [value]
            }

            if (categoriesFilter.name === filterName) {
                filterObject[filterKey] = filterObject[filterKey].map(x => Number(x))
            }
        }
    }
    return  {newSelectedFilters, newSelectedFiltersValues}
}

