<template>
    <div class="mt-2">
        <div class="d-flex justify-space-between">
            <div>
                <h3>Nodes</h3>
            </div>
            <div>
                <router-link :to="{ name: 'MonitoringFiles' }" style="text-decoration: none">
                    <v-btn small outlined height="24" color="grey darken-1">
                        Files
                        <v-icon small right>
                            mdi-file-eye
                        </v-icon>
                    </v-btn>
                </router-link>
            </div>
        </div>

        <nobr>
            <v-data-table
                :options.sync="filter"
                :server-items-length="cryptoInterceptorPeersData ? cryptoInterceptorPeersData.total : 0"
                :headers="headers"
                :loading="cryptoInterceptorLoading"
                :loading-text="'Loading nodes ...'"
                :items="cryptoInterceptorPeersData ? cryptoInterceptorPeersData.data : []"
                class="elevation-0 border mb-2"
                :footer-props="{
                    'items-per-page-options': [30, 50, 100],
                    'show-current-page': true,
                    'showFirstLastPage': true,
                }"
                dense
            >
                <template v-slot:header.ip="{ header }">
                    <v-text-field
                        class="mb-2 mt-0 text-field-label-style"
                        append-icon="search"
                        :label="header.text"
                        single-line
                        hide-details
                        @click.native.prevent.stop.capture=""
                        v-model="filter.filterIps"
                    />
                </template>
                <template v-slot:header.cryptointerceptor="{ header }">
                    <h4>{{ header.text }}</h4>
                    <div class="d-flex justify-center">

                        <v-btn-toggle
                            v-model="filter.filterStatuses"
                            multiple
                            dense
                        >
                            <v-btn x-small :value="'RED'">
                                <v-icon small color="red">
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                            <v-btn x-small :value="'ORANGE'">
                                <v-icon small color="orange">
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                            <v-btn x-small :value="'GREEN'">
                                <v-icon small color="green">
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:item="{ item }">
                    <tr>
                        <td>
                            <Copyable :on-copy="item.ip" justify="start">
                                <template #text="{ on, attrs }">
                                    <span v-on="on" v-bind="attrs" class="fontMonospace">{{ item.ip }}</span>
                                </template>
                            </Copyable>
                        </td>
                        <td class="text-right">
                            <span>{{ item.port }}</span>
                        </td>
                        <td>
                            <span>{{ item.started_at | formatDateTime  }}</span>
                        </td>
                        <td>
                            <span>{{ item.updated_at | formatDateTime  }}</span>
                        </td>
                        <td>
                            <span v-if="['NOT_MONITORED', 'STOPPED', 'FAILED'].includes(item.status)" class="body-2 font-weight-bold" style="color: red;">{{item.status}}</span>
                            <span v-else-if="['CONNECTING','STARTING','STOPPING'].includes(item.status)" class="body-2 font-weight-bold" style="color: orange;">{{item.status}}</span>
                            <span v-else class="body-2 font-weight-bold" style="color: green;">{{item.status}}</span>
                        </td>
                        <td>
                            <cryptointerceptorDetailModal :ip="item.ip" :port="item.port" @monitoring-start-event="startMonitoringPeer" @monitoring-stop-event="stopMonitoringPeer" @peer-polling-start-event="startPeerPolling">
                                <template #showbutton="{ on: one }">
                                    <CryptoInterceptorControlPanelSmall small :ip="item.ip" :port="item.port" showBadge @monitoring-start-event="startMonitoringPeer" @monitoring-stop-event="stopMonitoringPeer" @peer-polling-start-event="startPeerPolling" @peer-polling-stop-event="stopPeerPolling">
                                        <template #extrabutton>
                                            <v-hover
                                                v-slot="{ hover }"
                                            >
                                                <div>
                                                    <div v-show="hover">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <div class="extraButton">
                                                                    <v-btn small icon v-on="on" @click="one.click($event)">
                                                                        <v-icon :color="currency.unit">
                                                                            mdi-file-search-outline
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </template>
                                                            <span>Show Details</span>
                                                        </v-tooltip>
                                                    </div>
                                                    <div v-show="!hover">
                                                        <v-btn small icon @click="one.click($event)">
                                                            <v-icon>
                                                                mdi-file-search-outline
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-hover>
                                        </template>
                                    </CryptoInterceptorControlPanelSmall>
                                </template>
                            </cryptointerceptorDetailModal>
                        </td>
                    </tr>
                </template>
                <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                    <span>
                        {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                    </span>
                </template>
            </v-data-table>
        </nobr>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import CryptoInterceptorControlPanelSmall from "@/components/CryptoInterceptor/CryptoInterceptorControlPanelSmall";
import CryptointerceptorDetailModal from "@/components/CryptoInterceptor/CryptointerceptorDetailModal";
import debounce from "debounce";
import Copyable from "@/components/common/Copyable.vue";

export default {
    name: "MonitoringNodes",
    components: {Copyable, CryptointerceptorDetailModal, CryptoInterceptorControlPanelSmall},
    data() {
        return {
            filter: {
                page: 1,
                itemsPerPage: 30,
                filterIps: undefined,
                filterStatuses: [],
            },
            headers: [
                { text: 'IP Address', align: 'center', value: 'ip', sortable: false },
                { text: 'Port', align: 'center', value: 'port' },
                { text: 'Started At', align: 'center', value: 'started_at' },
                { text: 'Updated At', align: 'center', value: 'updated_at'},
                { text: 'Status', align: 'center', value: 'status', width: 150 },
                { text: 'Control Panel', align: 'center', value: 'cryptointerceptor', sortable: false  },
            ]
        }
    },
    computed: {
        ...mapGetters(["currency","cryptoInterceptorPeersData","cryptoInterceptorLoading","cryptoInterceptorReFetchData"]),
    },
    created() {
        this.$store.commit("CRYPTOINTERCEPTOR_PEERS_SET_DATA", null)
    },
    watch: {
        filter: {
            handler()
            {
                this.debounceInput()
            },
            deep: true
        },
        cryptoInterceptorReFetchData: {
            handler()
            {
                if (this.cryptoInterceptorReFetchData === true)
                {
                    this.getCryptointerceptorData()
                    this.$store.commit("CRYPTOINTERCEPTOR_REFETCH_DATA", false)
                }
            }
        }
    },
    methods: {
        debounceInput: debounce(function () {
            this.getCryptointerceptorData()
        }, 450),
        getCryptointerceptorData()
        {
            this.$store.dispatch("getCryptoInterceptorPeersData", {currency: this.currency.unit, filter: {...this.filter, filterIps: this.filter.filterIps ? [this.filter.filterIps] : undefined, ...this.calculateStatuses()}})
        },
        startMonitoringPeer({ip, port})
        {
            this.$store.dispatch("setCryptoInterceptorPeerStatus", {currency: this.currency.unit, ipaddress: ip, action: "start", port: port})
        },
        stopMonitoringPeer({ip, port})
        {
            this.$store.dispatch("setCryptoInterceptorPeerStatus", {currency: this.currency.unit, ipaddress: ip, action: "stop", port: port})
        },
        startPeerPolling({ip, port})
        {
            this.$store.dispatch("startPollCryptoInterceptorPeerStatus", {currency: this.currency.unit, ipaddress: ip, port: port})
        },
        stopPeerPolling({ip, port})
        {
            this.$store.commit("CRYPTOINTERCEPTOR_PEER_REMOVE_POLLING", {ip, port})
        },
        calculateStatuses()
        {
            let retVal = []
            if (this.filter.filterStatuses.includes("RED"))
            {
                retVal = retVal.concat(["STOPPED","FAILED"])
            }
            if (this.filter.filterStatuses.includes("ORANGE"))
            {
                retVal = retVal.concat(["CONNECTING"])
            }
            if (this.filter.filterStatuses.includes("GREEN"))
            {
                retVal = retVal.concat(["CONNECTED"])
            }
            return {filterStatuses: retVal}
        }
    }
}
</script>

<style scoped>
td {
    text-align: center;
    vertical-align: middle;
}
</style>