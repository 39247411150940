<template>
    <BaseDeleteModalDialog v-bind="$props" @delete-event="$emit('delete-event',$event)">
        <template #showbutton="{ on: activateOn }">
            <span v-if="fancy">
                <ButtonWrapperHoverTooltip :disabled="disabled">
                    <template #active="{ on, attrs }">
                        <TableDeleteButton :disabled="disabled" icon v-on="on"
                                              @click="activateOn.click($event)">
                            <v-icon color="red">
                                mdi-delete
                            </v-icon>
                        </TableDeleteButton>
                    </template>
                    <template #tooltip>
                        Delete
                    </template>
                    <template #alt>
                        <v-btn :disabled="disabled" icon small>
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </template>
                </ButtonWrapperHoverTooltip>
            </span>
            <span v-else>
                <TableDeleteButton v-on="activateOn" :deleteButtonTooltip="tooltip" :large="large"/>
            </span>

        </template>
    </BaseDeleteModalDialog>
</template>

<script>
import BaseDeleteModalDialog from "@/components/common/Base/BaseDeleteModalDialog";
import TableDeleteButton from "@/components/common/TableComponents/TableDeleteButton";
import ButtonWrapperHoverTooltip from "@/components/common/Buttons/ButtonWrapperHoverTooltip";
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

export default {
    name: "TableDeleteModalDialog",
    components: {
        BaseDataModifyButton,
        ButtonWrapperHoverTooltip,
        BaseDeleteModalDialog,
        TableDeleteButton
    },
    props: {
        ...BaseDeleteModalDialog.props,
        tooltip: {
            type: String,
            default: "Delete"
        },
        large: {
            type: Boolean,
            default: false,
            required: false
        },
        fancy: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
}
</script>