var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        _setup.ViewHeader,
        _vm._g(
          _vm._b(
            {
              attrs: { "no-dividers": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "text",
                    fn: function () {
                      return [_vm._t("text")]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            "ViewHeader",
            _setup.attrs,
            false
          ),
          _setup.listeners
        ),
        [_vm._v(" "), _vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          staticClass: "d-flex mt-1 mb-6",
          staticStyle: { "box-shadow": "0 4px 2px -2px rgba(0, 0, 0, 0.3)" },
          attrs: { "slider-size": 4, color: "tertiary" },
        },
        _vm._l(_setup.props.tabs, function (tab, index) {
          return _c(
            "v-tab",
            { key: index, attrs: { to: { name: tab.route } } },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v(
                  "\n                " + _vm._s(tab.icon) + "\n            "
                ),
              ]),
              _vm._v("\n            " + _vm._s(tab.text) + "\n        "),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }