var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100 scroll-y" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { "grid-list-md": "", fluid: "" } },
        [
          _vm._t("title"),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2 d-flex flex-column align-center" }, [
            _c("div", { staticClass: "d-flex flex-wrap" }, [
              _c(
                "div",
                { staticClass: "mr-2", staticStyle: { width: "220px" } },
                [
                  _c("DateTimePicker", {
                    attrs: { "supports-seconds": "", label: "Since" },
                    model: {
                      value: _vm.filter.since,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "since", $$v)
                      },
                      expression: "filter.since",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-2", staticStyle: { width: "220px" } },
                [
                  _c("DateTimePicker", {
                    attrs: { "supports-seconds": "", label: "Until" },
                    model: {
                      value: _vm.filter.until,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "until", $$v)
                      },
                      expression: "filter.until",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex mx-2 mb-2 flex-wrap" },
              _vm._l(_vm.limitValues, function (item, index) {
                return _c("PresetLimitButton", {
                  key: item.text,
                  attrs: {
                    "preset-since-times": _vm.presetSinceTimes,
                    "button-index": index,
                    filter: _vm.filter,
                    "time-period": item.timePeriod,
                    "preset-until-time": _vm.presetUntilTime,
                    "is-last": index === _vm.limitValues.length - 1,
                    "button-text": item.text,
                  },
                  on: {
                    limitSelect: (e) =>
                      _vm.selectDatePreset(e.timePeriod, e.buttonIndex),
                  },
                })
              }),
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2" }, [_vm._t("body")], 2),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }