var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseModalDialog, {
    ref: "modalRef",
    attrs: { title: "Merge clusters", width: "80vh" },
    scopedSlots: _vm._u([
      {
        key: "showbutton",
        fn: function ({ on: dialogOn }) {
          return undefined
        },
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "v-row",
              { attrs: { dense: "" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("div", [
                      _vm._v("Select "),
                      _c("strong", [_vm._v("target")]),
                      _vm._v(" cluster:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-radio-group",
                      {
                        attrs: { row: "" },
                        on: { change: _setup.clearForms },
                        model: {
                          value: _setup.targetMode,
                          callback: function ($$v) {
                            _setup.targetMode = $$v
                          },
                          expression: "targetMode",
                        },
                      },
                      [
                        _c("v-radio", {
                          attrs: {
                            color: "info",
                            label: "Pick existing",
                            value: "pick",
                          },
                        }),
                        _vm._v(" "),
                        _c("v-radio", {
                          attrs: {
                            color: "info",
                            label: "Create new",
                            value: "create",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _setup.targetMode === "pick"
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("div", [
                          _vm._v(
                            "\n                    Estimated total addresses: " +
                              _vm._s(
                                _vm._f("formatNumber")(
                                  _setup.addressCount + _setup.localClusterCount
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(_setup.ClusterPicker, {
                          attrs: {
                            currencyUnit: _vm.currencyUnit,
                            multiple: false,
                            chips: "",
                            "no-address-clusters": "",
                            "no-neo-clusters": "",
                          },
                          model: {
                            value: _setup.pickedCluster,
                            callback: function ($$v) {
                              _setup.pickedCluster = $$v
                            },
                            expression: "pickedCluster",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _setup.targetMode === "create"
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "ValidationObserver",
                          { ref: "validator", attrs: { mode: "lazy" } },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-text-field-validateable", {
                                      attrs: {
                                        autofocus: "",
                                        dense: "",
                                        label: "Name",
                                        rules: "required|entity_id",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      size: "22",
                                                    },
                                                  },
                                                  [_vm._v("mdi-hub")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3762715508
                                      ),
                                      model: {
                                        value: _setup.newClusterName,
                                        callback: function ($$v) {
                                          _setup.newClusterName = $$v
                                        },
                                        expression: "newClusterName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-textarea-validateable", {
                                      attrs: {
                                        label: "Description",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend",
                                            fn: function () {
                                              return [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "\n                                        mdi-text-box-outline\n                                    "
                                                  ),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1183663335
                                      ),
                                      model: {
                                        value: _setup.newClusterDescription,
                                        callback: function ($$v) {
                                          _setup.newClusterDescription = $$v
                                        },
                                        expression: "newClusterDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function ({ close, setDisabled, disabled }) {
          return [
            _c(
              "v-btn",
              {
                attrs: { disabled: disabled, color: "error" },
                on: { click: _setup.onClose },
              },
              [_vm._v("\n            Cancel\n        ")]
            ),
            _vm._v(" "),
            _c("v-spacer"),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: {
                  disabled:
                    (_setup.targetMode === "pick" && !_setup.pickedCluster) ||
                    disabled,
                  color: "info",
                },
                on: {
                  click: function ($event) {
                    return _setup.onSave(close, setDisabled)
                  },
                },
              },
              [_vm._v("\n            Merge\n        ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }