<template>
  <span>
    <template v-if="!nonCryptoPrices">
      {{$humanize.formatNumber(value, 7)}} {{displayCurrency}}
    </template>
    <template v-else>
      {{ $humanize.formatNumber(value * exchangePrice.price, 2)}} {{exchangePrice.exchCurrency}}
    </template>
  </span>
</template>

<script>
  export default {
    name: 'transactionPrice',
    props: {
      exchangePrice: {
        type: Object,
        default: null
      },
      value: {
        type:  [String, Number],
        default: 0
      },
      currency: {
        type: String,
        default: null
      }
    },
    computed: {
      displayCurrency () {
        return this.currency || this.$store.getters.currency.unit
      },
      nonCryptoPrices () {
        return this.$store.getters.nonCryptoPrices
      },
    },
  }
</script>

<style scoped>

</style>
