<script setup>
import {useAttrs} from "vue";

const emit = defineEmits(['click'])
const attrs = useAttrs()
</script>

<template>
    <v-btn class="ma-1" color="primary" small v-bind="attrs" @click="emit('click')">
        <v-icon left>
            mdi-restore
        </v-icon>
        Reset
    </v-btn>
</template>