<template>
    <div>
        <v-select
            :value="value"
            @input="(newCluster) => { $emit('input', newCluster) }"
            :items="computedLocalClusters"
            :disabled="loading"
            :loading="loading"
            clearable
            item-text="entityid"
            item-value="entityid"
            label="Cluster Address"
            outlined
            hide-details
        >
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-title>
                        <strong>No cluster available.</strong>
                    </v-list-item-title>
                </v-list-item>
            </template>

            <template v-slot:selection="{ item }">
                                 <span class="d-flex text-truncate text--black align-center fontMonospace"
                                       style="max-width: 99%">
                                     <img class="mr-4" :src="currency.image" :alt="currency.unit"/>
                                     {{ item.entityid }}
                                 </span>
            </template>

            <template v-slot:item="{ item }">
                <v-list-item-action>
                    <img :src="currency.image" :alt="currency.unit"/>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="fontMonospace" v-text="item.entityid"></v-list-item-title>
                    <v-list-item-subtitle><strong>{{ item.isCustom ? "custom" : "co-spent" }} |
                        {{ item.addresses | formatNumber }} </strong> Addresses
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="item.isLoading">
                    <v-progress-circular size=30 :color="currency.unit" indeterminate/>
                </v-list-item-action>
            </template>
        </v-select>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: "AddressClusterPicker",
    props: {
        value: {
            type: String,
        },
    },
    computed: {
        ...mapGetters(["currency", "addressId","addressClusterLoading", "local_clusters"]),
        ...mapGetters({
            loading: "addressClustersLoading",
        }),
        computedLocalClusters() {
            return [...this.local_clusters].map(cluster => {
                return {...cluster, isLoading: !!this.addressClusterLoading[cluster.entityid]}
            })
        },
    },
    created() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.$store.dispatch('loadAddressClusters', {currency: this.currency.unit, addressId: this.addressId});
        }
    }
}
</script>

<style scoped>

</style>

