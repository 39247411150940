<script setup>
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import store from "@/store";
import {computed, inject, watch} from "vue";
import TableClusterInteractive from "@/components/common/Table/TableClusterInteractive.vue";
import TableClusterUnused from "@/components/common/Table/TableClusterUnused.vue";
import ExportMenu from "@/components/common/Buttons/ExportMenu.vue";
import {useRoute} from "vue-router/composables";
import {DataState} from "@/components/common/types/data";

const currency = computed(() => store.getters.currency)
const pagination = {
    page: 1,
    itemsPerPage: 20,
}
const route = useRoute()

const clusterCurrent = inject('entityId')
const {
    data: currentClusterAddressesData,
    dataState,
    getData
} = useGetData('getClusterAddresses', {
    currency: currency.value.unit,
    entity: clusterCurrent.value,
    pagination: pagination
}, false, false, 1, true)

const currentClusterAddressesLoading = computed(() => dataState.value === DataState.Loading)

const getAddresses = (e) => {
    getData({pagination: e, entity: clusterCurrent.value})
}

const UnusedAddressesLength = computed(() => {
    if (currentClusterAddressesLoading.value && !currentClusterAddressesData.value) {
        return -1;
    }

    if (currentClusterAddressesData.value?.unused_addresses)
        return currentClusterAddressesData.value.unused_addresses.length

    return 0;
})

watch(clusterCurrent, (newValue) => {
    getData({entity: clusterCurrent.value})
})

</script>

<template>
    <FancyCard bodyStyle="padding: 0">
        <template #header>
            <v-icon left>mdi-tag-multiple</v-icon>
            Addresses
        </template>
        <template #body>
            <div>
                <div class="mt-2 d-flex justify-end">
                    <exportMenu :base-url="currency.unit + '/cryptocluster/' + clusterCurrent +'/addresses'"
                                :disabled="currentClusterAddressesLoading"
                                class="mr-2"/>
                </div>
                <TableClusterInteractive
                    v-if="clusterCurrent !== null"
                    :cluster-total-addresses="currentClusterAddressesData ? currentClusterAddressesData.total : 0"
                    :data="currentClusterAddressesData ? currentClusterAddressesData.data : undefined"
                    :fiats="currentClusterAddressesData ? currentClusterAddressesData.fiats : {}"
                    :loading="currentClusterAddressesLoading"
                    @fetch-addresses-event="getAddresses"
                />
                <div v-if="UnusedAddressesLength > 0" class="mb-4 ml-2" style="max-width: 500px">
                    <v-expansion-panels>
                        <v-expansion-panel :disabled="UnusedAddressesLength <= 0">
                            <v-expansion-panel-header class="py-0">
                        <span v-if="UnusedAddressesLength !== -1" class="subtitle-1" style="color: rgba(0,0,0,0.6)">
                            {{ UnusedAddressesLength | formatNumber }} Unused Addresses
                        </span>
                                <template v-slot:actions>
                                    <v-icon color="primary">
                                        $expand
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <TableClusterUnused
                                    :addresses=" currentClusterAddressesData ? currentClusterAddressesData.unused_addresses : []"
                                    class="mt-4"/>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>
        </template>
    </FancyCard>
</template>

<style scoped>

</style>