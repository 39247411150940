<template>
    <div class="ring-container" :style="{ height: height + 'px', width: width + 'px' }">
        <div class="ringring" :style="`border: 2px solid ${pulseColor}`"></div>
        <div class="circle">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon class="not-allowed" small :color="pulseColor" v-on="on">
                        <slot name="iconName"/>
                    </v-icon>
                </template>
                <span>
                    <slot name="tooltip"/>
                </span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    name: "PulsatingWrapper",
    props: {
        pulseColor: {
            type: String,
            required: false,
            default: "primary",
        },
        height: {
            type: String,
            required: false,
            default: "16"
        },
        width: {
            type: String,
            required: false,
            default: "16"
        }
    }
}
</script>

<style scoped>

.ring-container {
    position: relative;
}

.circle {
    position: absolute;
}

.ringring {
    -webkit-border-radius: 30px;
    height: 22px;
    width: 22px;
    left: -3px;
    top: 0;
    position: absolute;
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0.0
}
@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

</style>