import {FilterType} from "@/components/common/types/filter";

const filterRows = (rows, filters, convertors) => {
    return rows.filter(row => {
        for (let [columnKey, columnValue] of Object.entries(row)) {
            const columnFilter = filters[columnKey]
            if (columnFilter) {
                if (convertors[columnKey])
                    columnValue = convertors[columnKey](columnValue)
                //use the correct type of filter
                switch (columnFilter.type) {
                    case FilterType.Numeric:
                        if (!filterByNumber(columnValue, columnFilter))
                            return false
                        break
                    case FilterType.Strings:
                        if (!filterByStrings(columnValue, columnFilter, row))
                            return false
                        break
                    case FilterType.Categories:
                        if (!filterByCategories(columnValue, columnFilter))
                            return false
                        break
                }
            }
        }
        return true
    })
}

const filterByNumber = (value, filter) => {
    const filteringValue = Number(filter.values[0])
    switch (filter.op) {
        case "E":
            return value === filteringValue
        case "GE":
            return value >= filteringValue
        case "LE":
            return value <= filteringValue
        case "B":
            const filteringValueSecondary = Number(filter.values[1])
            return (value >= filteringValue) && (value <= filteringValueSecondary)
    }
}


const filterSingleByStrings = (value, filter, row?) => {

    if (filter.opt?.hasLabel && row.label)
        if (row.label)
            return true

    if (row.label) {
        for (const labelEntry of filter.values) {
            if (row.label.toLowerCase().includes(labelEntry.toLowerCase()))
                return true
        }
    }

    for (const filterEntry of filter.values) {
        if (value.includes(filterEntry))
            return true
    }

    return false
}

const filterByStrings = (value, filter, row?) => {

    if (Array.isArray(value)) {
        for (const singleValue of value) {
            if (filterSingleByStrings(singleValue, filter, row))
                return true
        }
        return false
    } else {
        return filterSingleByStrings(value, filter, row)
    }
}

const filterByCategories = (value, filter) => {

    if (filter.op === 'E') {
        for (const itemCategory of value) {
            for (const filterCategoryId of filter.values) {
                if (itemCategory.id === filterCategoryId)
                    return true
            }
        }
    } else if (filter.op === 'A') {
        if (value.length > 0)
            return true
    }

    return false
}

export {
    filterRows
}