import apiClient from "@/services/apiClient";

const actions = {
    async getStatus ({commit}) {
        try {
            return await apiClient.GET('status')
        } catch (e) {
            //do nothing
        }
    }
}

export default {
    actions,
}