var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          attrs: {
            disabled: _vm.disabled || !_setup.userHasWritePermissions,
            color: _vm.color,
            icon: _vm.icon,
          },
        },
        "v-btn",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }