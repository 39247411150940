var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.BaseDataModifyButton,
    _vm._g(
      _vm._b(
        {
          attrs: {
            disabled: _vm.disabled,
            icon: _vm.icon,
            color: "info",
            small: "",
          },
        },
        "BaseDataModifyButton",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "v-icon",
        { class: { "mr-1": !_vm.icon }, attrs: { left: !_vm.icon } },
        [_vm._v("mdi-tag-multiple-outline")]
      ),
      _vm._v("\n    " + _vm._s(_vm.icon ? "" : _vm.text) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }