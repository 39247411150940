var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "router-link",
              {
                staticStyle: { "text-decoration": "none" },
                attrs: { to: { name: "MonitoringFiles" } },
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      outlined: "",
                      height: "24",
                      color: "grey darken-1",
                    },
                  },
                  [
                    _vm._v("\n                    Files\n                    "),
                    _c("v-icon", { attrs: { small: "", right: "" } }, [
                      _vm._v(
                        "\n                        mdi-file-eye\n                    "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "nobr",
        [
          _c("v-data-table", {
            staticClass: "elevation-0 border mb-2",
            attrs: {
              options: _vm.filter,
              "server-items-length": _vm.cryptoInterceptorPeersData
                ? _vm.cryptoInterceptorPeersData.total
                : 0,
              headers: _vm.headers,
              loading: _vm.cryptoInterceptorLoading,
              "loading-text": "Loading nodes ...",
              items: _vm.cryptoInterceptorPeersData
                ? _vm.cryptoInterceptorPeersData.data
                : [],
              "footer-props": {
                "items-per-page-options": [30, 50, 100],
                "show-current-page": true,
                showFirstLastPage: true,
              },
              dense: "",
            },
            on: {
              "update:options": function ($event) {
                _vm.filter = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "header.ip",
                fn: function ({ header }) {
                  return [
                    _c("v-text-field", {
                      staticClass: "mb-2 mt-0 text-field-label-style",
                      attrs: {
                        "append-icon": "search",
                        label: header.text,
                        "single-line": "",
                        "hide-details": "",
                      },
                      nativeOn: {
                        "!click": function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                        },
                      },
                      model: {
                        value: _vm.filter.filterIps,
                        callback: function ($$v) {
                          _vm.$set(_vm.filter, "filterIps", $$v)
                        },
                        expression: "filter.filterIps",
                      },
                    }),
                  ]
                },
              },
              {
                key: "header.cryptointerceptor",
                fn: function ({ header }) {
                  return [
                    _c("h4", [_vm._v(_vm._s(header.text))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center" },
                      [
                        _c(
                          "v-btn-toggle",
                          {
                            attrs: { multiple: "", dense: "" },
                            model: {
                              value: _vm.filter.filterStatuses,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "filterStatuses", $$v)
                              },
                              expression: "filter.filterStatuses",
                            },
                          },
                          [
                            _c(
                              "v-btn",
                              { attrs: { "x-small": "", value: "RED" } },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "red" } },
                                  [
                                    _vm._v(
                                      "\n                                mdi-eye\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              { attrs: { "x-small": "", value: "ORANGE" } },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "orange" } },
                                  [
                                    _vm._v(
                                      "\n                                mdi-eye\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              { attrs: { "x-small": "", value: "GREEN" } },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "green" } },
                                  [
                                    _vm._v(
                                      "\n                                mdi-eye\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c("tr", [
                      _c(
                        "td",
                        [
                          _c("Copyable", {
                            attrs: { "on-copy": item.ip, justify: "start" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "text",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          _vm._b(
                                            { staticClass: "fontMonospace" },
                                            "span",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(_vm._s(item.ip))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("span", [_vm._v(_vm._s(item.port))]),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDateTime")(item.started_at))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDateTime")(item.updated_at))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        ["NOT_MONITORED", "STOPPED", "FAILED"].includes(
                          item.status
                        )
                          ? _c(
                              "span",
                              {
                                staticClass: "body-2 font-weight-bold",
                                staticStyle: { color: "red" },
                              },
                              [_vm._v(_vm._s(item.status))]
                            )
                          : ["CONNECTING", "STARTING", "STOPPING"].includes(
                              item.status
                            )
                          ? _c(
                              "span",
                              {
                                staticClass: "body-2 font-weight-bold",
                                staticStyle: { color: "orange" },
                              },
                              [_vm._v(_vm._s(item.status))]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "body-2 font-weight-bold",
                                staticStyle: { color: "green" },
                              },
                              [_vm._v(_vm._s(item.status))]
                            ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("cryptointerceptorDetailModal", {
                            attrs: { ip: item.ip, port: item.port },
                            on: {
                              "monitoring-start-event": _vm.startMonitoringPeer,
                              "monitoring-stop-event": _vm.stopMonitoringPeer,
                              "peer-polling-start-event": _vm.startPeerPolling,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "showbutton",
                                  fn: function ({ on: one }) {
                                    return [
                                      _c("CryptoInterceptorControlPanelSmall", {
                                        attrs: {
                                          small: "",
                                          ip: item.ip,
                                          port: item.port,
                                          showBadge: "",
                                        },
                                        on: {
                                          "monitoring-start-event":
                                            _vm.startMonitoringPeer,
                                          "monitoring-stop-event":
                                            _vm.stopMonitoringPeer,
                                          "peer-polling-start-event":
                                            _vm.startPeerPolling,
                                          "peer-polling-stop-event":
                                            _vm.stopPeerPolling,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "extrabutton",
                                              fn: function () {
                                                return [
                                                  _c("v-hover", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            hover,
                                                          }) {
                                                            return [
                                                              _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            hover,
                                                                          expression:
                                                                            "hover",
                                                                        },
                                                                      ],
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          top: "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function ({
                                                                                  on,
                                                                                  attrs,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "extraButton",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          _vm._g(
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  small:
                                                                                                    "",
                                                                                                  icon: "",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return one.click(
                                                                                                      $event
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      _vm
                                                                                                        .currency
                                                                                                        .unit,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                        mdi-file-search-outline\n                                                                    "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Show Details"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            !hover,
                                                                          expression:
                                                                            "!hover",
                                                                        },
                                                                      ],
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          icon: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return one.click(
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                            mdi-file-search-outline\n                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "footer.page-text",
                fn: function ({ pageStart, pageStop, itemsLength }) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("formatNumber")(pageStart)) +
                          " - " +
                          _vm._s(_vm._f("formatNumber")(pageStop)) +
                          " of " +
                          _vm._s(_vm._f("formatNumber")(itemsLength)) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("h3", [_vm._v("Nodes")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }