var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "5", lg: "5", md: "5" } },
        [
          _c(_setup.FancyCard, {
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("v-icon", { staticClass: "d-block mr-2 ml-1" }, [
                          _vm._v("assignment"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Case Details"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function () {
                  return [
                    _c(_setup.NameValueFlex, {
                      attrs: {
                        loading: _setup.loading,
                        "loading-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "name",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        Visibility\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "value",
                          fn: function () {
                            return [
                              _c(_setup.VisibilityDisplay, {
                                attrs: { value: _setup.caseDetail.is_hidden },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(_setup.NameValueFlex, {
                      attrs: {
                        loading: _setup.loading,
                        "loading-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "name",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        Warning\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "value",
                          fn: function () {
                            return [
                              _c(_setup.WarningDisplay, {
                                attrs: { value: _setup.caseDetail.is_warning },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(_setup.NameValueFlex, {
                      attrs: {
                        loading: _setup.loading,
                        "loading-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "name",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        Reported At:\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "value",
                          fn: function () {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateTime")(
                                      _setup.caseDetail.reported_at
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "my-2" }),
                    _vm._v(" "),
                    _c(_setup.NameValueFlex, {
                      attrs: {
                        loading: _setup.loading,
                        "loading-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "name",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        Created At:\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "value",
                          fn: function () {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateTime")(
                                      _setup.caseDetail.created_at
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(_setup.NameValueFlex, {
                      attrs: {
                        loading: _setup.loading,
                        "loading-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "name",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        Created By:\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "value",
                          fn: function () {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_setup.caseDetail.creator.name)),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "my-2" }),
                    _vm._v(" "),
                    _c(_setup.NameValueFlex, {
                      attrs: {
                        loading: _setup.loading,
                        "loading-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "name",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        Updated At:\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "value",
                          fn: function () {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateTime")(
                                      _setup.caseDetail.updated_at
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(_setup.NameValueFlex, {
                      attrs: {
                        loading: _setup.loading,
                        "loading-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "name",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        Updated By:\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "value",
                          fn: function () {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_setup.caseDetail.updator.name)),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "7", lg: "7", md: "7" } },
        [
          _c(_setup.FancyCard, {
            staticClass: "descriptionContainer",
            attrs: { loading: _setup.loading },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "d-block mr-2 ml-1",
                            attrs: { width: "22" },
                          },
                          [_vm._v("description")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Case Description"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function () {
                  return [
                    _setup.caseDetail.description
                      ? _c("v-textarea", {
                          staticClass: "fontMonospace caseDescription",
                          attrs: { "no-resize": "", readonly: "" },
                          model: {
                            value: _setup.caseDetail.description,
                            callback: function ($$v) {
                              _vm.$set(_setup.caseDetail, "description", $$v)
                            },
                            expression: "caseDetail.description",
                          },
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "title font-weight-light text-center",
                          },
                          [
                            _vm._v(
                              "\n                    This case has no description.\n                "
                            ),
                          ]
                        ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }