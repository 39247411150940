var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.chips
        ? _c(
            "div",
            { staticStyle: { "min-height": "24px" } },
            _vm._l(_vm.value, function (item, index) {
              return _c(
                "v-chip",
                {
                  key: index,
                  staticClass: "ma-1",
                  attrs: {
                    close: "",
                    "close-icon": "mdi-delete",
                    label: "",
                    small: "",
                  },
                  on: {
                    "click:close": function ($event) {
                      return _setup.removeCluster(item)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "fontMonospace" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("stringShortener")(item.entity_id, 8)) +
                        "\n        "
                    ),
                  ]),
                  _vm._v("\n             \n            "),
                  _c("span", [
                    _vm._v("\n            ("),
                    _c("b", [_vm._v(_vm._s(item.type))]),
                    _vm._v(")\n        "),
                  ]),
                ]
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(_setup.ClusterPicker, {
        attrs: {
          currencyUnit: _vm.currencyUnit,
          multiple: _vm.multiple,
          value: _vm.value,
        },
        on: {
          input: function ($event) {
            return _setup.emit("input", $event)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "my-1" }, [
        _vm._v("\n        Selected clusters:\n    "),
      ]),
      _vm._v(" "),
      _vm.table
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _setup.tableHeaders,
                  items: _vm.value,
                  "items-per-page": -1,
                  dense: "",
                  height: "300px",
                  "hide-default-footer": "",
                  "no-data-text": "No clusters selected!",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function ({ item, index }) {
                        return [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ hover }) {
                                    return [
                                      _c(
                                        "tr",
                                        {
                                          staticClass: "noselect",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: () =>
                                              _setup.removeCluster(
                                                _vm.value[index]
                                              ),
                                          },
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-left fontMonospace",
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(item.entity_id) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("i", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(item.type) +
                                                  "\n                            "
                                              ),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-right fontMonospace",
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      item.addresses_count
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "32px",
                                                  "min-width": "32px",
                                                },
                                              },
                                              [
                                                hover
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "error",
                                                          small: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    mdi-delete\n                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2629926502
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }