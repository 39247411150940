var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "mt-2", attrs: { outlined: "" } },
    [
      _c(_setup.DataGrid, {
        attrs: {
          headers: _setup.headers,
          "get-data": _setup.getData,
          "sort-desc": "",
          "bulk-operations": false,
          "items-per-page": _setup.itemsPerPage,
          item_id: "nickname",
          "computed-height": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c("td", { staticClass: "fontMonospace text-left" }, [
                  _c("span", [_vm._v(_vm._s(item.nickname))]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "fontMonospace text-left" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex", staticStyle: { gap: "4px" } },
                    _vm._l(item.flags, function (flag) {
                      return _c("v-chip", { key: flag, attrs: { small: "" } }, [
                        _vm._v(_vm._s(flag)),
                      ])
                    }),
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "fontMonospace text-left" }, [
                  _c("span", { staticClass: "fontMonospace" }, [
                    _vm._v(_vm._s(item.version)),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "fontMonospace" }, [
                  _c("span", { staticClass: "fontMonospace" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatDateTime")(item.inconsensus_val_after)
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "fontMonospace" }, [
                  _c("span", { staticClass: "fontMonospace" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatDateTime")(
                          item.inconsensus_val_until.join(" ")
                        )
                      )
                    ),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }