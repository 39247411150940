var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "mt-2", attrs: { outlined: "" } },
    [
      _c(_setup.DataGrid, {
        attrs: {
          headers: _setup.headers,
          "get-data": _setup.getData,
          "sort-desc": "",
          "bulk-operations": false,
          "items-per-page": _setup.itemsPerPage,
          item_id: "channel_id",
          "computed-height": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c("td", { staticClass: "fontMonospace text-left" }, [
                  _c("span", [_vm._v(_vm._s(item.channel_id))]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "fontMonospace text-left" },
                  [
                    _c(_setup.RouteToTransaction, {
                      attrs: {
                        currency: _setup.currencyUnit,
                        transaction: item.channel_point.split(":")[0],
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "text",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(item.channel_point.split(":")[0])
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "fontMonospace text-right" }, [
                  _c("span", { staticClass: "fontMonospace" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$humanize.formatNumber(
                            item.capacity / 100000000,
                            8
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "grey--text" }, [
                    _vm._v(_vm._s(_setup.currencyUnit)),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }