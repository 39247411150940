<template>
    <div>
        <div v-if="loading" class="d-flex justify-center">
            <div class="skeletonCryptoInterceptorPanelContainer">
                <!--                <v-progress-circular indeterminate size="25" :color="currency.unit"/>-->
                <v-skeleton-loader width="90px" loading type="card-heading"/>
            </div>
        </div>
        <v-chip :small="small" class="px-2" v-else>
            <div class="not-allowed" style="height: 20px; width: 16px">

                <div v-if="disabled">
                    <v-icon small class="not-allowed" color="grey">
                        mdi-eye
                    </v-icon>
                </div>

                <div v-else-if="active === GREEN || active === RED">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small class="not-allowed" :color="active === GREEN ? 'green' : 'red'" v-on="on">
                                mdi-eye
                            </v-icon>
                        </template>
                        <span>
                            {{ state.status }}
                        </span>
                    </v-tooltip>
                </div>

                <div v-else>
                    <pulsating-wrapper pulse-color="orange">
                        <template #iconName>
                            mdi-eye
                        </template>
                        <template #tooltip>
                            {{ state.status }}
                        </template>
                    </pulsating-wrapper>
                </div>
            </div>
            <div class="d-flex align-center ml-2" style="height: 100%">
                <div style="height: 60%">
                    <v-divider vertical/>
                </div>
            </div>
            <!--IF RED, SHOW PLAY BUTTON-->
            <ButtonWrapperHoverTooltip v-if="active === RED">
                <template #active="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on" :disabled="disabledActionButton"
                           @click="$emit('monitoring-start-event',{ip: ip, port: portComputed})">
                        <v-icon color="green">
                            mdi-play
                        </v-icon>
                    </v-btn>
                </template>
                <template #tooltip>
                    Start monitoring
                </template>
                <template #alt>
                    <v-btn small icon :disabled="disabledActionButton"
                           @click="$emit('monitoring-start-event',{ip: ip, port: portComputed})">
                        <v-icon>
                            mdi-play
                        </v-icon>
                    </v-btn>
                </template>
            </ButtonWrapperHoverTooltip>
            <ButtonWrapperHoverTooltip v-if="active === GREEN || active === ORANGE" :disabled="disabledActionButton">
                <template #active="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on" :disabled="disabledActionButton"
                           @click="$emit('monitoring-stop-event',{ip: ip, port: portComputed})">
                        <v-icon color="red">
                            mdi-stop
                        </v-icon>
                    </v-btn>
                </template>
                <template #tooltip>
                    Stop monitoring
                </template>
                <template #alt>
                    <v-btn small icon :disabled="disabledActionButton"
                           @click="$emit('monitoring-stop-event',{ip: ip, port: portComputed})">
                        <v-icon>
                            mdi-stop
                        </v-icon>
                    </v-btn>
                </template>
            </ButtonWrapperHoverTooltip>
            <v-badge
                color="red"
                offset-y="11"
                offset-x="11"
                :value="state.status !== 'NOT_MONITORED' && showBadge"
                dot
            >
                <slot name="extrabutton"/>
            </v-badge>
        </v-chip>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import ButtonWrapperHoverTooltip from "@/components/common/Buttons/ButtonWrapperHoverTooltip";
import PulsatingWrapper from "@/components/common/PulsatingWrapper";

export default {
    name: "CryptoInterceptorControlPanelSmall",
    components: {PulsatingWrapper, ButtonWrapperHoverTooltip},
    props: {
        ip: {
            type: String,
            required: true,
        },
        showBadge: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean
        },
        small: {
            Boolean,
        },
        port: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            RED: 0,
            ORANGE: 1,
            GREEN: 2
        }
    },
    computed: {
        ...mapGetters(["currency", "cryptoInterceptorLoading", "cryptoInterceptorPeersData", "cryptoInterceptorPeersStatusPolling"]),
        portComputed() {
            return this.port ?? this.state.port
        },
        loading() {
            return this.cryptoInterceptorLoading
        },
        disabledActionButton() {
            return this.state.status === 'STARTING' || this.state.status === 'STOPPING' || this.disabled
        },
        state() {
            if (this.cryptoInterceptorPeersData) {
                let element = this.cryptoInterceptorPeersData.data.find(x => {
                    if (this.port) {
                        return x.ip === this.ip && x.port === this.port
                    }

                    return x.ip === this.ip
                })
                if (element)
                    return element
            }
            return {status: "NOT_MONITORED"}
        },
        active() {
            let val = 0
            if (this.state) {
                if (["CONNECTED"].includes(this.state.status)) {
                    val = this.GREEN
                } else if (["CONNECTING", "STARTING", "STOPPING"].includes(this.state.status)) {
                    val = this.ORANGE
                } else {
                    val = this.RED
                }
            }
            return val
        },

    },
    watch: {
        "state.status": function (newVal, oldVal) {
            if (newVal === "CONNECTING" && oldVal === "STARTING") {
                this.$emit("state-change-event")
            } else if (["STOPPED", "FAILED"].includes(newVal) && oldVal === "STOPPING") {
                this.$emit("state-change-event")
            }
        },
        state: {
            handler() {
                if (this.state) {
                    if (["CONNECTING"].includes(this.state.status) && !this.cryptoInterceptorPeersStatusPolling[`${this.ip}:${this.portComputed}`]) {
                        this.$emit("peer-polling-start-event", {ip: this.ip, port: this.portComputed})
                    }
                }
            },
            immediate: true,
            deep: true
        },
        "ip": function (newVal, oldVal) {
            this.$emit("peer-polling-stop-event", {ip: oldVal, port: this.portComputed})
        }
    },
    beforeDestroy() {
        this.$emit("peer-polling-stop-event", {ip: this.ip})
    },
    methods: {
        onPeerDetail() {
            this.$emit("click")
        }
    },
}
</script>

<style scoped>

.not-allowed {
    cursor: default !important;
}

.v-btn:hover:before {
    background-color: transparent;
}

.v-btn:hover:after {
    background-color: transparent;
}

</style>

<style>
.skeletonCryptoInterceptorPanelContainer .v-skeleton-loader__heading {
    margin: 0 !important;
    height: 24px !important;
    width: 97px !important;
}

.skeletonCryptoInterceptorPanelContainer .v-skeleton-loader {
    width: 100% !important;
}

.skeletonCryptoInterceptorPanelContainer .v-skeleton-loader__card-heading {
    background-color: transparent !important;
}

</style>