var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pathGraph" },
    [
      _c("CreateGraphFromCanvasModal", {
        ref: "CreateGraphFromCanvasModal",
        on: { "create-graph-event": _vm.createGraph },
      }),
      _vm._v(" "),
      _c("GraphVisualization", {
        ref: "graph",
        attrs: {
          config: _vm.config,
          loading: _vm.transactionNetworkLoading,
          elements: _vm.elements,
          autoRelayout: true,
          nameOfExportedGraph: _vm.nameOfExportedGraph,
        },
        on: {
          "graph-click-event": _vm.graphNodeClick,
          "before-destroy-event": _vm.cleanUpGraphData,
        },
        scopedSlots: _vm._u([
          {
            key: "actionbuttons",
            fn: function () {
              return [
                _c("SaveButton", {
                  attrs: { color: _vm.currency.unit },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$refs.CreateGraphFromCanvasModal.openModal()
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }