<template>
    <div class="height100">
        <h1>Peers</h1>
        <v-divider/>
        <v-divider class="mb-3" />

        <v-data-table
            :loading="currencyNeighboursLoading"
            :headers="headers"
            :items-per-page="-1"
            :items="currencyNeighbours.data"
            hide-default-footer
            class="elevation-0 border my-2"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <v-chip label>{{ item.addr }}</v-chip>
                    </td>
                    <td class="text-xs-right">{{ item.useragent + ' (' + item.protover + ')' }}</td>
                    <td class="text-xs-right">
                        {{ $humanize.fileSize(item.bytesrecv) }}
                        <br />
                        {{ item.lastrecv * 1000 | formatDateTime }}
                    </td>
                    <td class="text-xs-right">
                        {{ $humanize.fileSize(item.bytessent) }}
                        <br />
                        {{ item.lastsend * 1000 | formatDateTime }}
                    </td>
                    <td class="text-xs-right">
                        offset {{ item.timeoffset }}
                        <br />
                        {{ item.conntime * 1000 | formatDateTime }}
                    </td>
                    <td class="text-xs-right">
                        {{ item.pingtime }} s
                        <br />
                        {{ item.minping }} s
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'neighbours',
    computed: {
        ...mapGetters(["currency", "currencies", "currencyNeighbours", "currencyNeighboursLoading"]),
    },
    methods: {
        refresh() {
            this.$store.dispatch('loadCurrencyNeighbours', { currency: this.$router.currentRoute.params.currency, pagination: this.pagination })
        }
    },
    data() {
        return {
            pagination: {
                page: 1,
                itemsPerPage: 25,
            },
            headers: [
                { text: 'Remote IP address', align: 'center', value: 'addr' },
                { text: 'Version', align: 'center', value: 'useragent' },
                { text: 'Received', align: 'center', value: 'bytesrecv' },
                { text: 'Sent', align: 'center', value: 'bytessent' },
                { text: 'Time Skew / Connected At', align: 'center', value: 'timeoffset' },
                { text: 'Current / Max Ping', align: 'center', value: 'pingtime' }
            ]
        }
    },
    mounted() {
        this.refresh();
    },
    watch: {
        pagination: {
            handler() {
                this.refresh()
            },
            deep: true
        },
    },
}
</script>
