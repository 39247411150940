<script setup>
import GenericPicker from "@/components/common/Pickers/GenericPicker.vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {computed} from "vue";
import {DataState} from "@/components/common/types/data";
import CountrySearchDisplay from "@/views/Transactions/FilterGeo/Components/Displays/CountrySearchDisplay.vue";
import CountrySelectedDisplay from "@/views/Transactions/FilterGeo/Components/Displays/CountrySelectedDisplay.vue";

const props = defineProps({
    value: {
        required: true
    },
    propertyName: {
        type: String,
        required: false,
    }
})

const pagination = {
    page: 1,
    itemsPerPage: 10,
    sortDesc: [false]
}

//COUNTRY
const {
    getData: countryGetData,
    dataState: countryDataState,
    data: countryData
} = useGetData("loadGeoIpCountry", {pagination: {...pagination}}, false, false, 1, true)
const countries = computed(() => countryData.value?.data ?? [])

const loading = computed(() => countryDataState.value === DataState.Loading)

const getItemsForCountry = async (searchString) => {
    countryGetData({pagination: {...pagination, filterCountry: searchString}})
}

const emitCountryCode = (e) => {
    if (!e) {
        emit('input', null)
        return
    }

    if (props.propertyName) {
        emit('input', e[props.propertyName])
        return
    }

    emit('input', e)

}

const emit = defineEmits(['input'])

</script>

<template>
    <div>
        <GenericPicker
            :items="countries"
            :get-items="getItemsForCountry"
            :value="value"
            @input="emitCountryCode"
            :loading="loading"
            item-text="country_code"
            prepend-icon=""
            label="Country"
            hide-details
            single-input
        >
            <template #item="{item}">
                <CountrySearchDisplay :item="item"/>
            </template>
            <template #selection="{ item }">
                <CountrySelectedDisplay :item="item" hideTimeZone/>
            </template>
        </GenericPicker>
    </div>
</template>

<style scoped>

</style>