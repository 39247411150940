var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100 scroll-y" },
    [
      _vm.addressLoading
        ? _c(
            "div",
            [
              _c("ProgressCircular", {
                staticClass: "pt-10",
                attrs: { color: _vm.currency.unit },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.addressLoading && !_vm.addressInfo
        ? _c("v-container", [
            _vm.addressInfo === null || !_vm.addressId
              ? _c(
                  "div",
                  [
                    _c("AddressNotFoundInfo", {
                      staticClass: "my-2",
                      attrs: {
                        address: _vm.addressId,
                        message:
                          "Provided identifier does not exist in the blockchain!",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.addressLoading && _vm.addressInfo
        ? _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c("h1", [_vm._v("Cluster")]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mb-3" }),
              _vm._v(" "),
              !_vm.hasLocalClusters && !_vm.addressClustersLoading
                ? _c(
                    "div",
                    [
                      _c("EmptyClusterInfo", {
                        attrs: {
                          address: _vm.addressId,
                          message:
                            "This address does not form any cluster with other addresses!",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.clusterNotFound
                ? _c(
                    "div",
                    [
                      _c("AddressNotFoundInfo", {
                        staticClass: "my-2",
                        attrs: {
                          address: _vm.addressId,
                          message:
                            "This address does not belong to the given cluster!",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-2 ml-6" },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { "max-width": "900px" },
                      attrs: { justify: "center", align: "center" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { cols: "12", sm: "2", md: "1", lg: "1" },
                        },
                        [
                          _vm.currentClusterLoading && !_vm.clusterNotFound
                            ? _c("CancelLoadingButton", {
                                attrs: { color: _vm.currency.unit },
                                on: {
                                  "cancel-button-event": _vm.cancelRequest,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "10", md: "7", lg: "7" } },
                        [
                          _c("AddressClusterPicker", {
                            ref: "AddressClusterPickerRef",
                            model: {
                              value: _vm.entityAddress,
                              callback: function ($$v) {
                                _vm.entityAddress = $$v
                              },
                              expression: "entityAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { cols: "12", sm: "12", md: "4", lg: "4" },
                        },
                        [
                          _c("v-divider", {
                            staticClass: "d-inline ml-n6 mt-n2",
                            attrs: { vertical: "" },
                          }),
                          _vm._v(" "),
                          _c("v-divider", {
                            staticClass: "d-inline mr-2 mt-n2",
                            attrs: { vertical: "" },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            {
                              staticClass: "caption mt-n2",
                              attrs: {
                                loading: "true",
                                elevation: "0",
                                width: "250px",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    justify: "center",
                                    "no-gutters": "",
                                  },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("p", { staticClass: "mb-1" }, [
                                      _vm._v(
                                        "\n                                    Type:\n                                "
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _vm.addressClustersLoading
                                        ? _c("v-skeleton-loader", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              width: "40px",
                                              height: "12px",
                                              type: "image",
                                            },
                                          })
                                        : _c("p", { staticClass: "mb-1" }, [
                                            _c("strong", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.currentLocalCluster
                                                      ? _vm.currentLocalCluster
                                                          .isCustom
                                                        ? "custom"
                                                        : "co-spent"
                                                      : "#"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("p", { staticClass: "ma-0 pa-0" }, [
                                      _vm._v(
                                        "\n                                    # of Addresses:\n                                "
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _vm.addressClustersLoading
                                        ? _c("v-skeleton-loader", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              width: "40px",
                                              height: "12px",
                                              type: "image",
                                            },
                                          })
                                        : _c("p", { staticClass: "ma-0" }, [
                                            _c("strong", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.currentLocalCluster
                                                      ? _vm.currentLocalCluster
                                                          .addresses
                                                      : "#"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mb-4" }),
              _vm._v(" "),
              _vm.currentCluster
                ? _c(
                    "div",
                    [
                      _c("router-view", {
                        attrs: { currency: _vm.currency },
                        on: { "cluster-update-event": _vm.refreshClusterList },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }