<template>
    <v-row justify="center">
        <slot name="before"></slot>
        <v-btn
            class="ml-0"
            outlined
            :disabled="disabled"
            v-for="(exportButtonData, index) in buttonsData"
            v-bind:key="index"
        >
            <a :href="buildUrl(exportButtonData)" target="_blank">
                {{exportButtonData.startText}}
                <img
                    v-if="exportButtonData.imgSrc != undefined"
                    class="mx-2"
                    :src="exportButtonData.imgSrc"
                    width="24px"
                />
                {{exportButtonData.endText}}
            </a>
        </v-btn>
        <slot name="after"></slot>
    </v-row>
</template>

<script>
import { buildStringTemplate } from "@/utils"

export default {
    name: "ExportButtons",
    props: {
        buttonsData: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    methods: {
        buildUrl(exportButtonData) {
            return buildStringTemplate(
                {
                    template: exportButtonData.template,
                    values: {
                        ...exportButtonData
                    }
                }
            )
        }
    }
}
</script>
