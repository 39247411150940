var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-btn",
    _vm._b(
      {
        staticClass: "ma-1",
        attrs: { dark: "", color: "tertiary", small: "" },
        on: {
          click: function ($event) {
            return _setup.emit("click")
          },
        },
      },
      "v-btn",
      _setup.attrs,
      false
    ),
    [
      _c(
        "v-icon",
        { attrs: { left: "" } },
        [
          _vm._t("icon", function () {
            return [_vm._v("\n            mdi-magnify\n        ")]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default", function () {
        return [_vm._v("\n        Search\n    ")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }