var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: { title: _vm.isEditModal ? "Edit user" : "Create new user" },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c("v-text-field-validateable", {
                    attrs: {
                      rules: "required|min:2",
                      label: "Name",
                      outlined: "",
                    },
                    model: {
                      value: _vm.detail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "name", $$v)
                      },
                      expression: "detail.name",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-text-field-validateable", {
                    attrs: {
                      rules: "required|email",
                      label: "E-mail",
                      outlined: "",
                    },
                    model: {
                      value: _vm.detail.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "email", $$v)
                      },
                      expression: "detail.email",
                    },
                  }),
                  _vm._v(" "),
                  _vm.isEditModal
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [
                              _c("v-checkbox", {
                                model: {
                                  value: _vm.detail.is_active,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "is_active", $$v)
                                  },
                                  expression: "detail.is_active",
                                },
                              }),
                              _vm._v(" "),
                              _vm.detail.is_active
                                ? _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        label: "",
                                        color: "success",
                                        outlined: "",
                                      },
                                    },
                                    [_vm._v("Active")]
                                  )
                                : _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        label: "",
                                        outlined: "",
                                        color: "error",
                                      },
                                    },
                                    [_vm._v("Inactive")]
                                  ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-switch", {
                            staticClass: "mx-2",
                            attrs: { color: "green", label: "Change password" },
                            model: {
                              value: _vm.passwordChangeIsEnabled,
                              callback: function ($$v) {
                                _vm.passwordChangeIsEnabled = $$v
                              },
                              expression: "passwordChangeIsEnabled",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-text-field-validateable", {
                            attrs: {
                              name: "password-field",
                              disabled: !_vm.passwordChangeIsEnabled,
                              type: _vm.type,
                              rules: "required|password",
                              label: "Password",
                              append: "append",
                              outlined: "",
                            },
                            on: { "click:append": _vm.toggleType },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "button",
                                        { on: { click: _vm.toggleType } },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.type === "password"
                                                    ? "fa-solid fa-eye-slash"
                                                    : "fa-solid fa-eye"
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1215504840
                            ),
                            model: {
                              value: _vm.tempPassword,
                              callback: function ($$v) {
                                _vm.tempPassword = $$v
                              },
                              expression: "tempPassword",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.passwordChangeIsEnabled,
                                  expression: "passwordChangeIsEnabled",
                                },
                              ],
                            },
                            [
                              _c(
                                "span",
                                {
                                  class:
                                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9\.#?!@$%^&*-]).{10,}$/.test(
                                      _vm.tempPassword
                                    ) && "text-green",
                                },
                                [_vm._v("Password must:")]
                              ),
                              _vm._v(" "),
                              _c("ul", [
                                _c(
                                  "li",
                                  {
                                    class:
                                      /[a-z]/.test(_vm.tempPassword) &&
                                      "text-green",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Contain at least one lower case letter.\n                            "
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: /[a-z]/.test(
                                              _vm.tempPassword
                                            ),
                                            expression:
                                              "/[a-z]/.test(tempPassword)",
                                          },
                                        ],
                                        attrs: { color: "green", small: "" },
                                      },
                                      [_vm._v("fa-solid fa-check")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    class:
                                      /[A-Z]/.test(_vm.tempPassword) &&
                                      "text-green",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Contain at least one upper case letter.\n                            "
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: /[A-Z]/.test(
                                              _vm.tempPassword
                                            ),
                                            expression:
                                              "/[A-Z]/.test(tempPassword)",
                                          },
                                        ],
                                        attrs: { color: "green", small: "" },
                                      },
                                      [_vm._v("fa-solid fa-check")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    class:
                                      /[0-9\.#?!@$%^&*-]/.test(
                                        _vm.tempPassword
                                      ) && "text-green",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Contain at least one number 0-9 or at least one of these .#?!@$%^&*- characters.\n                            "
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: /[0-9\.#?!@$%^&*-]/.test(
                                              _vm.tempPassword
                                            ),
                                            expression:
                                              "/[0-9\\.#?!@$%^&*-]/.test(tempPassword)",
                                          },
                                        ],
                                        attrs: { color: "green", small: "" },
                                      },
                                      [_vm._v("fa-solid fa-check")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    class:
                                      _vm.detail &&
                                      _vm.tempPassword.length > 9 &&
                                      "text-green",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Be at least 10 characters long.\n                            "
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.detail &&
                                              _vm.tempPassword.length > 9,
                                            expression:
                                              "detail && tempPassword.length > 9",
                                          },
                                        ],
                                        attrs: { color: "green", small: "" },
                                      },
                                      [_vm._v("fa-solid fa-check")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-text-field-validateable", {
                            attrs: {
                              disabled: !_vm.passwordChangeIsEnabled,
                              type: _vm.type,
                              rules: "required|confirmation:@password-field",
                              label: "Confirm password",
                              outlined: "",
                            },
                            model: {
                              value: _vm.detail.password_confirmation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.detail,
                                  "password_confirmation",
                                  $$v
                                )
                              },
                              expression: "detail.password_confirmation",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("v-text-field-validateable", {
                            attrs: {
                              type: _vm.type,
                              rules: "required|password",
                              label: "Password",
                              outlined: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c(
                                      "button",
                                      { on: { click: _vm.toggleType } },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.type === "password"
                                                ? "fa-solid fa-eye-slash"
                                                : "fa-solid fa-eye"
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.detail.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "password", $$v)
                              },
                              expression: "detail.password",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              class:
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9\.#?!@$%^&*-]).{10,}$/.test(
                                  _vm.detail.password
                                ) && "text-green",
                            },
                            [_vm._v("Password must:")]
                          ),
                          _vm._v(" "),
                          _c("ul", [
                            _c(
                              "li",
                              {
                                class:
                                  _vm.detail.password.length > 9 &&
                                  "text-green",
                              },
                              [
                                _vm._v(
                                  "\n                        Be at least 10 characters long.\n                        "
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.detail.password.length > 9,
                                        expression:
                                          "detail.password.length > 9",
                                      },
                                    ],
                                    attrs: { color: "green", small: "" },
                                  },
                                  [_vm._v("fa-solid fa-check")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                class:
                                  /[0-9\.#?!@$%^&*-]/.test(
                                    _vm.detail.password
                                  ) && "text-green",
                              },
                              [
                                _vm._v(
                                  "\n                        Contain at least one number 0-9 or at least one of these .#?!@$%^&*- characters.\n                        "
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: /[0-9\.#?!@$%^&*-]/.test(
                                          _vm.detail.password
                                        ),
                                        expression:
                                          "/[0-9\\.#?!@$%^&*-]/.test(detail.password)",
                                      },
                                    ],
                                    attrs: { color: "green", small: "" },
                                  },
                                  [_vm._v("fa-solid fa-check")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                class:
                                  /[A-Z]/.test(_vm.detail.password) &&
                                  "text-green",
                              },
                              [
                                _vm._v(
                                  "\n                        Contain at least one upper case letter.\n                        "
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: /[A-Z]/.test(
                                          _vm.detail.password
                                        ),
                                        expression:
                                          "/[A-Z]/.test(detail.password)",
                                      },
                                    ],
                                    attrs: { color: "green", small: "" },
                                  },
                                  [_vm._v("fa-solid fa-check")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                class:
                                  /[a-z]/.test(_vm.detail.password) &&
                                  "text-green",
                              },
                              [
                                _vm._v(
                                  "\n                        Contain at least one lower case letter.\n                        "
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: /[a-z]/.test(
                                          _vm.detail.password
                                        ),
                                        expression:
                                          "/[a-z]/.test(detail.password)",
                                      },
                                    ],
                                    attrs: { color: "green", small: "" },
                                  },
                                  [_vm._v("fa-solid fa-check")]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("v-text-field-validateable", {
                            attrs: {
                              type: _vm.type,
                              rules: "required|confirmation:@Password",
                              label: "Confirm password",
                              outlined: "",
                            },
                            model: {
                              value: _vm.detail.password_confirmation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.detail,
                                  "password_confirmation",
                                  $$v
                                )
                              },
                              expression: "detail.password_confirmation",
                            },
                          }),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c("v-select-validateable", {
                    attrs: {
                      rules: "required|roles",
                      items: _vm.userRoles,
                      chips: "",
                      label: "Roles",
                      multiple: "",
                      outlined: "",
                      "item-text": "name",
                      "item-value": "id",
                    },
                    model: {
                      value: _vm.detail.roles,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "roles", $$v)
                      },
                      expression: "detail.roles",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    outlined: "",
                    color: "error",
                    disabled: _vm.usersModalState === _vm.modalState.Pending,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal(close)
                    },
                  },
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "success",
                    loading: _vm.usersModalState === _vm.modalState.Pending,
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save(close, setDisabled)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.isEditModal ? "Save user" : "Create user"))]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }