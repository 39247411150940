var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          staticClass: "fancyPicker textGrey",
          attrs: {
            filled: "",
            dense: "",
            solo: "",
            color: "info",
            "clear-icon": "mdi-close-circle",
          },
          scopedSlots: _vm._u(
            [
              _setup.slots.selection
                ? {
                    key: "selection",
                    fn: function ({ item }) {
                      return [_vm._t("selection", null, { item: item })]
                    },
                  }
                : null,
              _setup.slots.item
                ? {
                    key: "item",
                    fn: function ({ item }) {
                      return [_vm._t("item", null, { item: item })]
                    },
                  }
                : null,
            ],
            null,
            true
          ),
        },
        "v-select",
        _setup.attrs,
        false
      ),
      _setup.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }