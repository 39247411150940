import apiClient from "@/services/apiClient"
import axios from "axios"
import mappers from "models/LimitedPaginationModel";
import {processTx} from "@/utils";

const state = {
    // Used for page of blocks
    blocksData: null,
    blocksLoading: false,

    // Used for single block (block detail)
    blockDetail: undefined,
    blockDetailLoading: true,
    blockDetailCancelToken: null,

    blockDetailTransactions: null,
    blockDetailTransactionsLoading: false,
    blockDetailTransactionsCancelToken: undefined,
    blocksCancelToken: axios.CancelToken.source()
}

const getters = {
    blocksData: (state) => state.blocksData,
    blocksLoading: (state) => state.blocksLoading,

    blockDetail: (state) => state.blockDetail,
    blockDetailLoading: (state) => state.blockDetailLoading,
    blockDetailTransactions: (state) => state.blockDetailTransactions,
    blockDetailTransactionsLoading: (state) => state.blockDetailTransactionsLoading,
}

const mutations = {
    BLOCKS_LOADED(state, data) {
        state.blocksData = data
        state.blocksLoading = false
    },
    BLOCKS_START_LOADING(state) {
        state.blocksCancelToken.cancel()
        state.blocksCancelToken = axios.CancelToken.source()
        state.blocksLoading = true
    },

    BLOCK_DETAIL_START_LOADING(state) {
        state.blockDetailLoading = true
        if (state.blockDetailCancelToken) {
            state.blockDetailCancelToken.cancel()
        }
        state.blockDetailCancelToken= axios.CancelToken.source()
    },
    BLOCK_DETAIL_LOADED(state, data) {
        state.blockDetail = data
    },
    BLOCK_DETAIL_FINISH_LOADING(state) {
        state.blockDetailLoading = false
    },

    BLOCK_DETAIL_TRANSACTIONS_START_LOADING(state) {
        state.blockDetailTransactionsLoading = true;
        if (state.blockDetailTransactionsCancelToken) {
            state.blockDetailTransactionsCancelToken.cancel()
        }
        state.blockDetailTransactionsCancelToken = axios.CancelToken.source()
    },

    BLOCK_DETAIL_TRANSACTIONS_LOADED(state, data) {
        state.blockDetailTransactions = data
        state.blockDetailTransactions.data = data.data.map(x => processTx(x, undefined))
    },

    BLOCK_DETAIL_TRANSACTIONS_FINISH_LOADING(state) {
        state.blockDetailTransactionsLoading = false;
    }
}

const actions = {
    async loadBlocksPage({ commit, dispatch }, { currency, page, itemsPerPage }) {
        commit('BLOCKS_START_LOADING')
        try {
            const result = await apiClient.GET(`${currency}/cryptoblock`, {
                params: {
                   page,
                   itemsPerPage
                },
                cancelToken: state.blocksCancelToken.token
            })
            commit('BLOCKS_LOADED', result)
        } catch (error) {
            if(axios.isCancel(error)) {
                //request canceled, do nothing
            } else {
                dispatch("error", error.userFriendlyMessage)
                commit('BLOCKS_LOADED', [])

            }
        }
    },

    async loadBlockDetail({ commit, state, dispatch }, { blockId, currency }) {
        commit('BLOCK_DETAIL_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/cryptoblock/${blockId}`, {
                cancelToken: state.blockDetailCancelToken.token
            })

            dispatch('success', response.message)
            commit('BLOCK_DETAIL_LOADED', response)
            commit('BLOCK_DETAIL_FINISH_LOADING')
        }
        catch (error) {
            if (axios.isCancel(error))
            {
                //Error is canceled, do nothing
            } else
            {
                dispatch("error", error.userFriendlyMessage)
                commit('BLOCK_DETAIL_FINISH_LOADING')
            }
        }
    },

    async loadBlockTransactions({ commit, state, dispatch }, { blockId, currency, pagination })
    {
        commit('BLOCK_DETAIL_TRANSACTIONS_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/cryptoblock/${blockId}/transactions`,
                {
                    params: pagination,
                    cancelToken: state.blockDetailTransactionsCancelToken.token
                })
            dispatch('success', response.message)
            commit('BLOCK_DETAIL_TRANSACTIONS_LOADED', response)
            commit('BLOCK_DETAIL_TRANSACTIONS_FINISH_LOADING')
        } catch (error) {
            if (axios.isCancel(error))
            {
                //Error is canceled, do nothing
            } else
            {
                console.error(error)
                dispatch('error', error.userFriendlyMessage)
                commit('BLOCK_DETAIL_TRANSACTIONS_FINISH_LOADING')
            }
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
