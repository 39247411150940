var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.props.componentFilterBase, {
    ref: "filterMenuRef",
    tag: "component",
    attrs: {
      value: _setup.props.explicitApply
        ? _setup.filterActive
        : _setup.categoryFilterValueToReadable(
            _setup.filterValue,
            _setup.filterValueAny
          ),
    },
    on: {
      "on-clear-event": function ($event) {
        return _setup.resetValues()
      },
      "on-remove-filter-event": function ($event) {
        return _setup.emit("on-remove-filter-event", _setup.props.label)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function () {
          return [_vm._v("\n        " + _vm._s(_setup.props.label) + "\n    ")]
        },
        proxy: true,
      },
      {
        key: "icon",
        fn: function () {
          return [_vm._v("\n        mdi-format-list-bulleted-type\n    ")]
        },
        proxy: true,
      },
      {
        key: "menuContent",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(
                  "v-list",
                  { staticStyle: { padding: "0" }, attrs: { dense: "" } },
                  [
                    _c(
                      "v-list-item-group",
                      {
                        attrs: { color: "indigo" },
                        model: {
                          value: _setup.filterValueAny,
                          callback: function ($$v) {
                            _setup.filterValueAny = $$v
                          },
                          expression: "filterValueAny",
                        },
                      },
                      [
                        _c("v-list-item", {
                          attrs: { value: true },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ active }) {
                                return [
                                  _c(
                                    "v-list-item-action",
                                    { staticClass: "mr-3" },
                                    [
                                      _c("v-checkbox", {
                                        attrs: { "input-value": active },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          "\n                                    Any\n                                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mx-1 my-1",
                staticStyle: { "max-width": "250px" },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    )
                      return null
                    $event.preventDefault()
                    return _setup.emitInputValue.apply(null, arguments)
                  },
                },
              },
              [
                _c(_setup.CategoryPicker, {
                  attrs: {
                    dense: "",
                    "clear-icon": "mdi-close-circle",
                    disabled: _setup.filterValueAny,
                    placeholder: "0 selected",
                    "selection-text": "",
                    chips: "",
                    autofocus: "",
                    clearable: "",
                    dense2: "",
                    categories: _setup.allCategories,
                  },
                  model: {
                    value: _setup.filterValue,
                    callback: function ($$v) {
                      _setup.filterValue = $$v
                    },
                    expression: "filterValue",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _setup.props.explicitApply
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: {
                      padding: "4px 8px 8px 8px",
                      "margin-top": "-6px",
                    },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { dark: "", color: "grey", small: "" },
                        on: {
                          click: function ($event) {
                            _setup.resetValues()
                            _setup.emitInputValue()
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "", small: "" } }, [
                          _vm._v(
                            "\n                    mdi-close-circle\n                "
                          ),
                        ]),
                        _vm._v("\n                CLEAR\n            "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: { dark: "", color: "indigo", small: "" },
                        on: { click: _setup.emitInputValue },
                      },
                      [
                        _c("v-icon", { attrs: { left: "", small: "" } }, [
                          _vm._v(
                            "\n                    mdi-filter-check\n                "
                          ),
                        ]),
                        _vm._v("\n                Apply\n            "),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }