<template>
    <v-hover
        v-slot="{ hover }"
    >
        <div class="buttonNoHoverBackground">
            <div v-show="hover">
                <v-tooltip top :disabled="disabled">
                    <template v-slot:activator="{ on, attrs }">
                        <slot name="active" :on="on" :attrs="attrs"/>
                    </template>
                    <span>
                        <slot name="tooltip"/>
                    </span>
                </v-tooltip>
            </div>
            <div v-show="!hover">
                <slot name="alt"/>
            </div>
        </div>
    </v-hover>
</template>

<!--
<ButtonWrapperHoverTooltip>
    <template #active="{ on, attrs }">

    </template>
    <template #tooltip>

    </template>
    <template #alt>

    </template>
</ButtonWrapperHoverTooltip>
-->

<script>
export default {
    name: "ButtonWrapperHoverTooltip",
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        }
    }
}
</script>

<style>

.buttonNoHoverBackground .v-btn:before {
    background-color: transparent;
    transition: none;
}

.buttonNoHoverBackground .v-btn:after {
    background-color: transparent;
    transition: none;
}

</style>