<template>
    <div>
        <template v-if="address === null && !isLoading">
            <v-container>
                <div>
                    <AddressNotFoundInfo
                        class="my-2"
                        :address="addressId"
                        :message="'Provided identifier does not exist in the blockchain!'"
                    />
                </div>
            </v-container>
        </template>

        <div v-if="address !== null || isLoading" class="d-flex justify-center addressViewCards"
             style="background-color: #fff; height: 100%">
            <div class="px-4 mt-4" style="width: 100%">
                <v-row dense v-if="!isLoading && address">
                    <v-spacer/>
                    <AddressMetadataDetailModal
                        v-if="address.id !== null"
                        :currencies="currencies"
                        :owners="owners.data"
                        :categories="categories"
                        :addressMetadataDetail="address"
                        :isOwnersLoading="isOwnersLoading"
                        @save-event="editAddressMetadata"
                        @owner-filter-changed-event="ownerFilterChanged"
                    >
                        <template #showbutton="{ on }">
                            <EditButton text="Address Metadata" v-on="on" outlined/>
                        </template>

                    </AddressMetadataDetailModal>
                    <AddressMetadataDetailModal
                        v-if="address.id === null"
                        :currencies="currencies"
                        :owners="owners.data"
                        :categories="categories"
                        :addressMetadataDetail="address"
                        :isOwnersLoading="isOwnersLoading"
                        @save-event="createAddressMetadata"
                        @owner-filter-changed-event="ownerFilterChanged"
                    >
                        <template #showbutton="{ on }">
                            <CreateButton v-on="on" text="Add new address metadata"/>
                        </template>
                    </AddressMetadataDetailModal>
                    <AlarmSummaryMenu
                        :address="address"
                        :alarms="alarms.data"
                        :currencies="currencies"
                        :currency="currency"
                        :alarm-creating="alarmCreating"
                        @new-alarm-event="createAlarm"
                        @edit-alarm-event="editAlarm"
                        @delete-alarm-event="deleteAlarm"/>
                </v-row>

                <!-- ADDRESS INFO -->
                <v-row dense>
                    <v-col sm="6" md="4" cols="12">
                        <FancyCard>
                            <template #header>
                                <div class="d-flex align-center">
                                    <v-icon class="ml-1" left style="height: 24px; width: 24px">mdi-tag-text-outline
                                    </v-icon>
                                    <span class="font-weight-bold">{{ currency.name }}</span>&nbsp;Address
                                </div>
                            </template>
                            <template #body>
                                <NameValueFlex>
                                    <template #name>
                                        Name:
                                    </template>
                                    <template #value>
                                        <Copyable :on-copy="addressId">
                                            <template #text="{on, attr}">
                                                <div
                                                    v-on="on"
                                                    v-bind="attr"
                                                    class="fontMonospace"
                                                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                                >
                                                    {{ addressId | stringMiddleShortener(50) }}
                                                </div>
                                            </template>
                                        </Copyable>
                                    </template>
                                </nameValueFlex>
                                <NameValueFlex :loading="isLoading" loading-width="150">
                                    <template #name>
                                        Label:
                                    </template>
                                    <template #value>
                                        <v-chip
                                            v-if="address.identity"
                                            label
                                            outlined
                                            :color="currency.unit"
                                        >
                                            <span class="fontMonospace" style="vertical-align: middle"> {{
                                                    address.identity.label !== "" ? address.identity.label : "Undefined"
                                                }}</span>
                                        </v-chip>
                                        <span class="font-italic" v-else>
                                                None
                                            </span>
                                    </template>
                                </nameValueFlex>
                                <div class="mt-1" v-if="!isLoading && address">
                                    <AddressCategoryIcon
                                        class="mr-2 mb-1"
                                        v-for="cat in address.categories"
                                        v-bind:key="cat.id"
                                        :iconItem="cat"
                                        medium
                                    />
                                </div>
                            </template>
                        </FancyCard>
                    </v-col>
                    <v-col sm="6" md="8" cols="12">
                        <ChartAddressSummary
                            v-if="address && address.confirmedTransactions > 0 && address.confirmedTransactions < 35000"
                            :addressInfo="address"/>
                    </v-col>
                </v-row>

                <v-row dense class="pt-4">
                    <!-- BALANCE -->
                    <v-col md="3" sm="6" cols="12">
                        <FancyCard>
                            <template #header>
                                <div class="d-flex align-center">
                                    <v-icon class="ml-1" left style="height: 24px; width: 24px">mdi-scale-balance
                                    </v-icon>
                                    <span>Balance</span>
                                </div>
                            </template>
                            <template #body>
                                <NameValueFlex :loading="isLoading" loading-width="150" name-width="70">
                                    <template #name>
                                        <span class="font-weight-light subtitle-1" style="font-size: 16px">
                                            Balance:
                                        </span>
                                    </template>
                                    <template #value>
                                        <div>
                                            <v-chip
                                                :color="currency.unit"
                                                style="height: 26px"
                                                label
                                                text-color="white"
                                            >
                                                <span class="subtitle-1 font-weight-bold"
                                                      style="vertical-align: middle">
                                                    <fiat-convertor :value="address.confirmedBalance"
                                                                    :fiats="address.fiats"/>
                                                </span>
                                                <span class="subtitle-1 pl-1 text--white"
                                                      style="vertical-align: middle">
                                                    <fiat-convertor unit-only/>
                                                </span>
                                            </v-chip>
                                            <v-icon class="mediumRight">
                                                fa-solid fa-coins
                                            </v-icon>
                                        </div>
                                    </template>
                                </nameValueFlex>
                                <v-expand-transition>
                                    <div v-if="!isLoading && address">
                                        <NameValueFlex v-if="address.unconfirmedTransaction > 0" :loading="isLoading"
                                                       loading-width="150" name-width="110">
                                            <template #name>
                                                <div class="d-flex align-center">
                                                    <div>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <div>
                                                                    <v-icon
                                                                        class="ml-n2 mr-1"
                                                                        style="top: -2px; flex: 0 1 auto !important;"
                                                                        color="info"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        mdi-information-outline
                                                                    </v-icon>
                                                                </div>
                                                            </template>
                                                            <span>Address has unconfirmed transactions.</span>
                                                        </v-tooltip>
                                                    </div>
                                                    <div
                                                        class="font-weight-light subtitle-1 d-flex justify-center align-center flex-column"
                                                        style="max-width: 100px">
                                                        <div class="my-n1">
                                                            Unconfirmed
                                                        </div>
                                                        <div class="my-n1">
                                                            Balance:
                                                        </div>

                                                    </div>
                                                </div>
                                            </template>
                                            <template #value>
                                                <div>
                                                    <v-chip
                                                        :color="currency.unit"
                                                        style="height: 26px"
                                                        label
                                                        text-color="white"
                                                    >
                                                        <span class="subtitle-1 font-weight-bold"
                                                              style="vertical-align: middle">
                                                            <fiat-convertor
                                                                :value="address.confirmedBalance + address.unconfirmedBalance"
                                                                :fiats="address.fiats"/>
                                                        </span>
                                                        <span class="subtitle-1 pl-1 text--white"
                                                              style="vertical-align: middle">
                                                            <fiat-convertor unit-only/>
                                                        </span>
                                                    </v-chip>
                                                    <v-icon class="mediumRight">
                                                        fa-solid fa-coins
                                                    </v-icon>
                                                </div>
                                            </template>
                                        </nameValueFlex>
                                    </div>
                                </v-expand-transition>
                                <NameValueFlex :loading="isLoading" loading-width="100" name-width="70">
                                    <template #name>
                                        <span class="font-weight-light">
                                            Received:
                                        </span>
                                    </template>
                                    <template #value>
                                        <div>
                                            <v-chip
                                                color="success"
                                                style="height: 24px"
                                                label
                                                text-color="white"
                                            >
                                                <span class="subtitle-2 font-weight-bold"
                                                      style="vertical-align: middle">
                                                    <div v-if="currency.unit === 'ETH'">N/A</div>
                                                    <fiat-convertor v-else :value="address.totalReceived"
                                                                    :fiats="address.fiats"/>
                                                </span>
                                                <span class="subtitle-2 pl-1 text--white font-weight-light"
                                                      style="vertical-align: middle">
                                                    <fiat-convertor unit-only/>
                                                </span>
                                            </v-chip>
                                            <v-icon small class="largeRight">
                                                mdi-download mdi-rotate-270
                                            </v-icon>
                                        </div>
                                    </template>
                                </nameValueFlex>
                                <NameValueFlex :loading="isLoading" loading-width="100" name-width="70">
                                    <template #name>
                                        <span class="font-weight-light">
                                            Sent:
                                        </span>
                                    </template>
                                    <template #value>
                                        <div>
                                            <v-chip
                                                color="red"
                                                style="height: 24px"
                                                label
                                                text-color="white"
                                            >
                                                <span class="subtitle-2 font-weight-bold"
                                                      style="vertical-align: middle">
                                                    <div v-if="currency.typeFamily === 'ETH'">N/A</div>
                                                    <fiat-convertor v-else :value="address.totalSent"
                                                                    :fiats="address.fiats"/>
                                                </span>
                                                <span class="subtitle-2 pl-1 text--white font-weight-light"
                                                      style="vertical-align: middle">
                                                    <fiat-convertor unit-only/>
                                                </span>
                                            </v-chip>
                                            <v-icon small class="largeRight">
                                                mdi-upload mdi-rotate-90
                                            </v-icon>
                                        </div>
                                    </template>
                                </nameValueFlex>
                                <AddressBudget v-if="address?.existsInGraphDb" :address="addressId"/>
                            </template>
                        </FancyCard>
                    </v-col>

                    <!-- TRANSACTIONS -->
                    <v-col md="5" sm="6" cols="12">
                        <FancyCard>
                            <template #header>
                                <div class="d-flex align-center">
                                    <i class="fas fa-exchange-alt" style="font-size: 22px; color: grey"></i>
                                    <span class="d-inline-block ml-3">Transactions</span>
                                </div>
                            </template>
                            <template #body>
                                <NameValueFlex :loading="isLoading" loading-width="150" nameFlex>
                                    <template #name>
                                        <span class="font-weight-light subtitle-1" style="font-size: 16px">
                                            Confirmed Transactions:
                                        </span>
                                    </template>
                                    <template #value>
                                        <span class="subtitle-1 font-weight-bold" style="vertical-align: middle"> {{
                                                address.confirmedTransactions | formatNumber
                                            }}</span>
                                    </template>
                                </nameValueFlex>
                                <v-expand-transition>
                                    <div v-if="!isLoading && address">
                                        <NameValueFlex v-if="address.unconfirmedTransaction > 0" :loading="isLoading"
                                                       loading-width="150" nameFlex>
                                            <template #name>
                                                <div class="d-flex align-center">
                                                    <div>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <div>
                                                                    <v-icon
                                                                        class="ml-n2 mr-1"
                                                                        style="top: -2px; flex: 0 1 auto !important;"
                                                                        color="info"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        mdi-information-outline
                                                                    </v-icon>
                                                                </div>
                                                            </template>
                                                            <span>Address has unconfirmed transactions.</span>
                                                        </v-tooltip>
                                                    </div>
                                                    <div class="font-weight-light subtitle-1" style="font-size: 16px">
                                                        Unconfirmed Transactions:
                                                    </div>
                                                </div>
                                            </template>
                                            <template #value>
                                                <span class="subtitle-1 font-weight-bold"
                                                      style="vertical-align: middle"> {{
                                                        address.unconfirmedTransaction
                                                    }}</span>
                                            </template>
                                        </nameValueFlex>
                                    </div>
                                </v-expand-transition>
                                <NameValueFlex :loading="isLoading" loading-width="100" name-width="70">
                                    <template #name>
                                        <span class="font-weight-light">
                                            First tx:
                                        </span>
                                    </template>
                                    <template #value>
                                        <span v-if="address.firstTx" class="d-flex text-center justify-end"
                                              style="vertical-align: middle; max-width: 100%">
                                            <TransactionCopyable :text="address.firstTx.txhash"/>
                                            <span class="mx-2">
                                                <v-divider vertical/>
                                            </span>
                                            <span class="d-flex"
                                                  style="max-width: 170px !important; width: 100%; justify-content: center; flex-shrink: 2">
                                                <router-link
                                                    :is="address.firstTx.blockHeight === -1  ? 'span' : 'router-link'"
                                                    :class="[ address.firstTx.blockHeight === -1 ? 'disabled' : 'enabled' , 'd-inline-block', 'link']"
                                                    :to="{
                                                    name: 'Block',
                                                    params: {
                                                        currency: $route.params.currency,
                                                        block: address.firstTx.blockHeight
                                                    }
                                                    }">
                                               <TransactionDate
                                                   :date="address.firstTx.blockTime | moment('YYYY-MM-DD HH:mm:ss Z')"
                                                   :unconfirmed="address.firstTx.blockHeight === -1">
                                                   <template slot="tooltip">
                                                   <span v-if="address.firstTx.blockHeight !== -1"
                                                         class="d-flex justify-center align-center flex-column">
                                                        <span>Block {{ address.firstTx.blockHeight }}</span>
                                                    </span>
                                                   <span v-else class="d-flex justify-center align-center flex-column">
                                                        <span>Unconfirmed transaction</span>
                                                    </span>
                                                   </template>
                                               </TransactionDate>
                                                </router-link>
                                                </span>
                                        </span>
                                        <span v-else class="font-italic">
                                            Unconfirmed
                                        </span>
                                    </template>
                                </nameValueFlex>
                                <NameValueFlex :loading="isLoading" loading-width="100" name-width="70">
                                    <template #name>
                                        <span class="font-weight-light">
                                             Last tx:
                                        </span>
                                    </template>
                                    <template #value>
                                        <span v-if="address.lastTx" class="d-flex text-center justify-end"
                                              style="vertical-align: middle; max-width: 100%">
                                            <TransactionCopyable :text="address.lastTx.txhash"/>
                                            <span class="mx-2">
                                                <v-divider vertical/>
                                            </span>
                                            <span class="d-flex"
                                                  style="max-width: 170px !important; width: 100%; justify-content: center; flex-shrink: 2">
                                                <router-link
                                                    :is="address.lastTx.blockHeight === -1  ? 'span' : 'router-link'"
                                                    :class="[ address.lastTx.blockHeight === -1 ? 'disabled' : 'enabled' , 'd-inline-block', 'link']"
                                                    :to="{
                                                    name: 'Block',
                                                    params: {
                                                        currency: $route.params.currency,
                                                        block: address.lastTx.blockHeight
                                                    }
                                                    }">
                                                   <TransactionDate
                                                       :date="address.lastTx.blockTime | moment('YYYY-MM-DD HH:mm:ss Z')"
                                                       :unconfirmed="address.lastTx.blockHeight === -1">
                                                       <template slot="tooltip">
                                                       <span v-if="address.lastTx.blockHeight !== -1"
                                                             class="d-flex justify-center align-center flex-column">
                                                            <span>Block {{ address.lastTx.blockHeight }}</span>
                                                        </span>
                                                        <span v-else
                                                              class="d-flex justify-center align-center flex-column">
                                                            <span>Unconfirmed transaction</span>
                                                        </span>
                                                       </template>
                                                   </TransactionDate>
                                                </router-link>
                                            </span>
                                        </span>
                                        <span v-else class="font-italic">
                                            Unconfirmed
                                        </span>
                                    </template>
                                </nameValueFlex>
                            </template>
                        </FancyCard>
                    </v-col>
                    <v-col md="4" sm="6" cols="12" order-sm="4">
                        <FancyCard>
                            <template #header>
                                <div class="d-flex align-center">
                                    <img src="/img/ico/owner-icon.svg" alt="Owner"
                                         style="filter: opacity(0.6); width: 24px; margin-right: 8px"/>
                                    <span>Owners</span>
                                </div>
                            </template>
                            <template #body>
                                <div v-if="isLoading">
                                    <v-skeleton-loader
                                        height="173"
                                        type="image"
                                    ></v-skeleton-loader>
                                </div>
                                <div v-else>
                                    <v-data-table
                                        v-if="computedOwners.length > 0"
                                        dense
                                        :headers="headersOwners"
                                        :items="computedOwners"
                                        hide-default-footer
                                        class="border"
                                        :items-per-page="-1"
                                    >
                                        <template slot="item" slot-scope="props">
                                            <tr>
                                                <td class="text-overflow fontMonospace">
                                                    {{ props.item.name }}
                                                </td>
                                                <td class="text-overflow">
                                                    <PrimarySelector :isPrimary="props.item.isPrimary"
                                                                     :item="props.item"
                                                                     :currency="currency"
                                                                     @set-primary-event="setPrimaryOwner"
                                                    />
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                    <div v-else class="title font-weight-light text-center">
                                        No owners assigned.
                                    </div>
                                </div>
                            </template>
                        </FancyCard>
                    </v-col>
                    <v-col v-if="isLoading" cols="12" order="10" class="justify-center">
                        <div>
                            <ProgressCircular class="pt-10" :color="currency.unit"/>
                        </div>
                    </v-col>

                    <!-- Clusters -->
                    <v-col cols="12" order="4" v-if="!isLoading && address">
                        <v-card outlined class="cardShadow" :disabled="!clusters.length > 0">
                            <v-card-text class="py-2 px-4">
                                <v-icon style="bottom: 3px">
                                    mdi-hub
                                </v-icon>
                                <span class="title font-weight-light ml-2">Clusters</span>
                            </v-card-text>
                            <v-card-text class="pt-0 pb-4 px-4">
                                <v-data-table
                                    v-if="clusters.length > 0"
                                    :headers="headersCluster"
                                    :items="clusters"
                                    hide-default-footer
                                    class="border"
                                    :items-per-page="-1"
                                    dense
                                >
                                    <template slot="item" slot-scope="props">
                                        <tr>
                                            <td class="text-overflow text-left">
                                                <EntityLabel
                                                    :entity="props.item.cluster"
                                                    :entityLabel="props.item.label"
                                                    :owner="props.item.owner"
                                                    :color="props.item.categories[0] ? props.item.categories[0].color : undefined"
                                                    :isCopyable="true"
                                                    :showExtra="false"
                                                    :actAsLink="true"
                                                    :currency="currency.unit"
                                                    :is-address="false"
                                                />
                                            </td>
                                            <td class="text-overflow text-right">
                                                {{ props.item.totalAddresses | formatNumber }}
                                            </td>
                                            <td class="text-overflow text-left">
                                                {{ props.item.isCustom ? "custom" : "co-spent" }}
                                            </td>
                                            <td class="text-overflow text-left fontMonospace">
                                                {{ props.item.owner ? props.item.owner.name : '' }}
                                            </td>
                                            <td class="text-overflow">
                                                <v-row class="justify-left">
                                                    <AddressCategoryIcon
                                                        v-for="cat in props.item.categories.slice(0, 10)"
                                                        v-bind:key="cat.id"
                                                        :iconItem="cat"
                                                    />
                                                    <BaseButtonShowMoreItems
                                                        :items="props.item.categories"
                                                        :shownItemsCount="10"
                                                    >
                                                        <AddressCategoryIcon
                                                            class="mx-1"
                                                            v-for="cat in props.item.categories"
                                                            :iconItem="cat"
                                                            :key="cat.id"
                                                            medium
                                                            :name="cat.name"
                                                            :color="cat.color"
                                                        />
                                                    </BaseButtonShowMoreItems>
                                                </v-row>
                                            </td>
                                            <td class="text-overflow">
                                                {{ props.item.created_at | moment('YYYY-MM-DD HH:mm:ss Z') }}
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                                <div v-else class="title font-weight-light text-center">
                                    {{
                                        address.existsInGraphDb ? "Address does not belong to any cluster." : "Address does not exist in the graph database."
                                    }}
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Cases -->
                    <v-col cols="12" order="5" v-if="!isLoading && address">
                        <v-card outlined class="cardShadow" :disabled="!cases.length > 0">
                            <v-card-text class="py-2 px-4">
                                <v-icon style="bottom: 3px">
                                    assignment
                                </v-icon>
                                <span class="title font-weight-light ml-2">Cases</span>
                            </v-card-text>
                            <v-card-text class="pt-0 pb-4 px-4">
                                <v-data-table
                                    v-if="cases.length > 0"
                                    :headers="headersCases"
                                    :items="cases"
                                    hide-default-footer
                                    class="border"
                                    :items-per-page="-1"
                                    dense
                                >
                                    <template slot="item" slot-scope="props">
                                        <tr>
                                            <td class="text-overflow text-left">
                                                <router-link
                                                    class="link"
                                                    :to="{
                                            name: 'CaseDetail',
                                            params: {
                                                caseId: props.item.id
                                            }
                                        }"
                                                >
                                                    {{ props.item.title }}
                                                </router-link>
                                            </td>
                                            <td class="text-overflow text-left">{{ props.item.description }}</td>
                                            <td class="text-overflow text-left">{{ props.item.created_by }}</td>
                                            <td class="text-overflow">
                                                {{ props.item.reported_at | moment('YYYY-MM-DD HH:mm:ss Z') }}
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                                <div v-else class="title font-weight-light text-center">
                                    Address is not assigned to any case.
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Identities -->
                    <v-col cols="12" order="6" v-if="!isLoading && address">
                        <v-card outlined class="cardShadow" :disabled="!computedIdentities.length > 0">
                            <v-card-text class="py-2 px-4">
                                <i class="fas fa-address-card" style="font-size: 24px; color: grey; bottom: 3px"></i>
                                <span class="title font-weight-light ml-2">Identities</span>
                            </v-card-text>
                            <v-card-text class="pt-0 pb-4 px-4">
                                <v-data-table
                                    v-if="computedIdentities.length > 0"
                                    :headers="headers"
                                    :items="computedIdentities"
                                    class="border"
                                    :items-per-page="computedIdentities.length > 20 ? 20 : -1"
                                    :hide-default-footer="computedIdentities.length <= 20"
                                    dense
                                >
                                    <template slot="item" slot-scope="props">
                                        <tr>
                                            <td class="text-overflow center">
                                                <a :href="props.item | createMailToConvertor"
                                                   style="text-decoration: none">
                                                    <v-icon>mail_outline</v-icon>
                                                </a>
                                            </td>
                                            <td class="text-overflow text-left fontMonospace">{{
                                                    props.item.label
                                                }}
                                            </td>
                                            <td class="text-overflow text-left">{{ props.item.source }}</td>
                                            <td class="text-overflow text-left">
                                                <a class="link" :href="props.item.url" target="_blank">{{
                                                        props.item.url | stringShortener(64)
                                                    }}</a>
                                            </td>
                                            <td class="text-overflow">
                                                {{ props.item.created_at | moment('YYYY-MM-DD HH:mm:ss Z') }}
                                            </td>
                                            <td class="text-overflow">
                                                <PrimarySelector :isPrimary="props.item.isPrimary"
                                                                 :item="props.item"
                                                                 :currency="currency"
                                                                 @set-primary-event="setPrimaryIdentity"
                                                />
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                                <div v-else class="title font-weight-light text-center">
                                    Address is not assigned to any identity.
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import AddressCategoryIcon from '@/components/address/AddressCategoryIcon'
import ProgressCircular from '@/components/common/ProgressCircular'
import AddressNotFoundInfo from '@/components/address/AddressNotFoundInfo'
import AddressMetadataDetailModal from "@/components/AddressMetadata/AddressMetadataDetailModal";
import CreateButton from "@/components/common/Buttons/CreateButton";
import {mapGetters} from "vuex";
import TransactionCopyable from "@/components/common/TransactionCopyable";
import TransactionDate from "@/components/common/TransactionDate";
import PrimarySelector from "@/components/common/PrimarySelector";
import AlarmSummaryMenu from "@/components/common/AlarmSummaryMenu";
import {deepClone} from "@/utils";
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import NameValueFlex from "@/components/common/NameValueFlex.vue";
import Copyable from "@/components/common/Copyable.vue";
import ChartAddressSummary from "@/components/chart/ChartAddressSummary.vue";
import BaseButtonShowMoreItems from "@/components/common/TableComponents/BaseButtonShowMoreItems.vue";
import EntityLabel from "@/components/common/EntityLabel.vue";
import EditButton from "@/components/common/Buttons/EditButton";
import FiatConvertor from "@/components/common/FiatConvertor";
import AddressBudget from "@/components/address/AddressSummary/Components/AddressBudget.vue";


export default {
    name: 'AddressSummary',
    components: {
        FiatConvertor,
        EditButton,
        AddressBudget,
        EntityLabel,
        BaseButtonShowMoreItems,
        ChartAddressSummary,
        Copyable,
        NameValueFlex,
        FancyCard,
        AlarmSummaryMenu,
        PrimarySelector,
        TransactionCopyable,
        TransactionDate,
        AddressCategoryIcon,
        ProgressCircular,
        AddressNotFoundInfo,
        AddressMetadataDetailModal,
        CreateButton,
    },
    computed: {
        ...mapGetters(["currencies", "categories", "owners", "isOwnersLoading", "alarms", "alarmCreating"]),
        currency() {
            return this.$store.getters.currency
        },
        computedOwners() {
            return this.address.owners.map(owner => {
                return {...owner, isPrimary: owner.id === this.address.owner?.id}
            })
        },
        address() {
            return this.$store.getters.addressInfo
        },
        addressId() {
            return this.$store.getters.addressId
        },
        computedIdentities() {
            return this.address.identities.map(identity => {
                return {...identity, isPrimary: identity.id === this.address.identity?.id}
            })
        },
        clusters() {
            return this.$store.getters.addressInfo.clusters;
        },
        cases() {
            return this.$store.getters.addressInfo.cases;
        },
        isLoading() {
            return this.$store.getters.addressLoading
        },
    },
    methods: {
        async editAddressMetadata({adddresMetadataDetail}) {
            this.assignMissingAttributes({adddresMetadataDetail})
            await this.$store.dispatch("updateAddressMetadata", {
                currency: this.currency.unit,
                updatedAddressMetadata: adddresMetadataDetail
            })
            await this.$store.dispatch("loadAdress", {id: this.addressId})
        },
        createAddressMetadata({adddresMetadataDetail}) {
            this.assignMissingAttributes({adddresMetadataDetail})
            this.$store.commit("ADDRESS_LOADED", {...adddresMetadataDetail})
            this.$store.dispatch("createAddressMetadata", {
                currency: this.currency.unit,
                newAddressMetadata: adddresMetadataDetail
            })
        },

        ownerFilterChanged({filter}) {
            this.$store.dispatch('loadOwners', {filter, details: "basic"})
        },
        setPrimaryOwner(ownerId) {
            this.$store.commit("ADDRESS_SET_PRIMARY_OWNER_BY_ID", {id: ownerId})
            this.$store.dispatch("updateAddressMetadata", {
                currency: this.currency.unit,
                updatedAddressMetadata: {...this.address, owner: {id: ownerId}}
            })
        },
        setPrimaryIdentity(identityId) {
            this.$store.commit("ADDRESS_SET_PRIMARY_IDENTITY_BY_ID", {id: identityId})
            this.$store.dispatch("updateAddressMetadata", {
                currency: this.currency.unit,
                updatedAddressMetadata: {...this.address, identity: {id: identityId}}
            })
        },
        assignMissingAttributes({adddresMetadataDetail}) {
            //primary owner defaults to the first owner
            adddresMetadataDetail.owner = adddresMetadataDetail.owners[0] ? adddresMetadataDetail.owners[0] : null

            //label defaults to the first identity label
            adddresMetadataDetail.label = adddresMetadataDetail.identities[0] ? adddresMetadataDetail.identities[0].label : ""

            //propagate categoryIds into categories
            let newCategories = []
            adddresMetadataDetail.categoryIds.forEach(catId => {
                newCategories = newCategories.concat(this.categories.filter(cat => {
                    return cat.id === catId
                }))
            })
            adddresMetadataDetail.categories = newCategories
        },
        createAlarm({alarmDetail}) {
            this.$store.dispatch("createAlarm", {currency: this.currency.unit, alarm: deepClone(alarmDetail)})
        },
        editAlarm({alarmDetail}) {
            this.$store.dispatch("editAlarm", {currency: this.currency.unit, alarm: deepClone(alarmDetail)})
        },
        deleteAlarm({itemToDelete}) {
            this.$store.dispatch("deleteAlarm", {currency: this.currency.unit, alarmId: itemToDelete.id})
        }
    },
    data() {
        return {
            showMenu: false,
            headers: [
                {text: 'Archive', value: '', sortable: false, align: 'center'},
                {text: 'Label', value: 'label', align: 'left'},
                {text: 'Source', value: 'source', align: 'left'},
                {text: 'URL', value: 'url', align: 'left'},
                {text: 'Created At', value: 'created_at', align: 'center'},
                {text: 'Primary', value: 'isPrimary', align: 'center'},
            ],
            headersOwners: [
                {text: 'Name', value: 'name', align: 'center'},
                {text: 'Primary', value: 'isPrimary', align: 'center'},
            ],
            headersCluster: [
                {text: 'Cluster ID', value: 'cluster', align: 'left'},
                {text: '# Addresses', value: 'totalAddresses', align: 'right'},
                {text: 'Type', value: 'isCustom', align: 'left'},
                {text: 'Owner', value: 'owner', align: 'left'},
                {text: 'Cluster Categories', value: '', align: 'left'},
                {text: 'Created At', value: 'created_at', align: 'center'}
            ],
            headersCases: [
                {text: 'Title', value: 'title', align: 'left'},
                {text: 'Description', value: 'description', align: 'left'},
                {text: 'Reported By', value: 'created_by', align: 'left'},
                {text: 'Reported At', value: 'reported_at', align: 'center'},
            ],
        }
    },
    filters: {
        createMailToConvertor(item) {
            return 'mailto:coinomon+archive@netsearch.cz' +
                '?subject=[Archive] Requesting%20'
                + item.url +
                '&body=Please%20provide%20MAFF%20archive%28s%29%20for%20following%20identity%3A%0A%0A' +
                '%2A%20label%3A%20' + item.label + '%0A' +
                '%2A%20source%3A%20' + item.source + '%0A' +
                '%2A%20URL%3A%20' + item.url + '%0A' +
                '%2A%20timestamp%3A%20' + item.created_at;
        }
    }
}
</script>

<style scoped>

.mediumRight {
    margin-left: 7px;
    margin-right: 3px;
    font-size: 22px !important;
}

.largeRight {
    margin-left: 4px;
    font-size: 28px !important;
}


.disabled {
    opacity: 0.7;
    text-decoration: none;
}

.max1000 {
    max-width: 1000px;
}

.text-initial {
    text-transform: initial;
}

.enabled {
    text-decoration: underline;
    text-decoration-thickness: 0.05em;
}

</style>

<style>
.addressViewCards .v-text-field__slot {
    line-height: 22px !important;
    padding-top: 6px !important;
}
</style>
