<template>
    <nobr>
        <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="50"
            :footer-props="{
                'items-per-page-options':[50],
                'disable-items-per-page': true,
                'show-current-page': true,
                'showFirstLastPage': true,
                }"
            :hide-default-footer="data.length < 51"
            dense
            item-key="addr"
        >
            <template v-slot:item="{ item, expand, isExpanded }">
                <transactionOutputRow
                    :address="address"
                    :item="item"
                    :expand="expand"
                    :is-expanded="isExpanded"
                    :txType="txType"
                    :fiats="fiats"/>
            </template>
            <template v-slot:expanded-item="{ item: itemOuter }">
                <transactionOutputRow
                    :address="address"
                    v-for="(item,index) in itemOuter.inouts"
                    :key="index"
                    :item="item"
                    :fiats="fiats"
                    :index="index"
                    :txType="txType"
                    :itemOuter="itemOuter"/>
            </template>
            <template #footer.prepend>
                <v-spacer/>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                <span>
                    {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                </span>
            </template>
        </v-data-table>
    </nobr>
</template>

<script>
import AddressDetailable from "@/components/common/AddressDetailable";
import Copyable from "@/components/common/Copyable";
import addressCategory from '@/components/address/AddressCategoryIcon'
import FiatConvertor from "@/components/common/FiatConvertor";
import RouteToTransaction from "@/components/common/RouteToTransaction";
import RouteToAddress from "@/components/common/RouteToAddress";
import transactionOutputRow from "@/components/transaction/Components/TransactionOutputRow";
export default {
    name: "TransactionInputTable",
    components: {
        transactionOutputRow,
        RouteToAddress,
        RouteToTransaction,
        FiatConvertor,
        Copyable,
        AddressDetailable,
        addressCategory},
    props: {
        data: {
            type: Array,
            required: true,
        },
        fiats: {

        },
        address: {

        },
        txType: {
            type: String,
        }

    },
    data() {
        return {
            headers: [
                {
                    text: 'Value',
                    align: 'center',
                    sortable: true,
                    value: 'value'
                },
                {
                    text: 'Owners/Identities',
                    align: 'center',
                    sortable: false,
                    width: "92px",
                },
                {
                    text: 'Address',
                    align: 'center',
                    sortable: false,
                    width: '265px',
                },
                {
                    text: 'Categories',
                    align: 'center',
                    sortable: false,
                    width: '86px',
                },
                {
                    text: '',
                    align: "center",
                    sortable: false,
                    width: "0px",
                },

            ]
        }
    },
}
</script>

<style>

.countingBadges .v-badge__badge {
    inset: auto auto calc(100% - 8px) calc(100% - 11px) !important;
    font-size: 10px;
    height: 13px;
    padding: 2px 0px 1px 1px;
    min-width: 13px;
}

</style>