import {ICurrencyData} from "@/components/common/types/currency";

enum ExtendedKeyPrefix {
    xpub = '0488b21e',
    xpriv = '0488ade4',
    ypub = '049d7cb2',
    ypriv = '049d7878',
    Ypub = '0295b43f',
    Ypriv = '0295b005',
    zpub = '04b24746',
    zpriv = '04b2430c',
    Zpub = '02aa7ed3',
    Zpriv = '02aa7a99',
    tpub = '043587cf',
    tpriv = '04358394',
    upub = '044a5262',
    upriv = '044a4e28',
    Upub = '024289ef',
    Upriv = '024285b5',
    vpub = '045f1cf6',
    vpriv = '045f18bc',
    Vpub = '02575483',
    Vpriv = '02575048',
    Ltub = '019da462',
    Ltpv = '019d9cfe',
    Mtub = '01b26ef6',
    Mtpv = '01b26792',
    dgub = '02FACAFD',
    dgpv = '02FAC398'
}

enum bipSpec {
    bip32,
    bip44,
    bip49,
    bip84,
    bip141
}

enum UInt {
    UInt8,
    UInt16
}

interface IBipExtendedKeyPrefixes {
    pub: string,
    priv: string
}

interface IBip141ExtendedKeyPrefixes {
    "P2WPKH": IBipExtendedKeyPrefixes,
    "P2WPKH nested in P2SH": IBipExtendedKeyPrefixes,
    "P2WSH (1-of-1 multisig)": IBipExtendedKeyPrefixes,
    "P2WSH nested in P2SH (1-of-1 multisig)": IBipExtendedKeyPrefixes
}

interface IAlternativeExtendedKeyPrefixes {
    "32"?: IBipExtendedKeyPrefixes,
    "44"?: IBipExtendedKeyPrefixes,
    "49"?: IBipExtendedKeyPrefixes,
    "84"?: IBipExtendedKeyPrefixes,
    "141"?: IBip141ExtendedKeyPrefixes
}

interface ICurrencyObject extends ICurrencyData{
    coin: number,
    alternativeExtendedKeyPrefixes?: IAlternativeExtendedKeyPrefixes,
    typeFamily,
    network?,
    disabledBips?
}

interface IScriptSemanticSelected {
    text: string,
    disabled: boolean
}

interface IBipSpecs {
    name: string,
    path: string,
    disabled,
    extendedPrivateKey: string,
    extendedPublicKey: string,
    extendedPublicKeyFormat: string,
    extendedPrivateKeyFormat: string,
    defaultExtendedPublicKeyPrefix: string,
    defaultExtendedPrivateKeyPrefix: string,
    getExtendedKeyPrefixes,
    clientList?:string[],
    selectedClient?: string,
    customPath?: string,
    clients?,
    getPath?,
    account?: 0,
    change?: 0,
    purpose?: number,
    coin?: number,
    scriptSemanticOptions?,
    scriptSemanticSelected?: IScriptSemanticSelected,
    selectDefaultSemantic?,
    currentSemanticOptionsDisabled?,
}

export {ExtendedKeyPrefix, bipSpec, UInt, IBipExtendedKeyPrefixes, IBip141ExtendedKeyPrefixes, IAlternativeExtendedKeyPrefixes, ICurrencyObject, IBipSpecs};