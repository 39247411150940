<template>
    <highcharts :constructorType="'mapChart'" class="hc" :options="chartOptions" ref="chart"/>
</template>

<script>
import Highcharts from "highcharts";
import worldMap from '@highcharts/map-collection/custom/world.geo.json'
import Maps from "highcharts/modules/map";
import HighchartsVue from "highcharts-vue";
import Vue from "vue";
Maps(Highcharts);
Vue.use(HighchartsVue);

export default {
    name: "ChartMap",
    props: {
        countryData: {
            type: [Object, null],
            required: true
        },
    },
    computed: {
        chartOptions() {
            return {
                chart: {
                    map: worldMap,
                    height: 1000,
                },
                credits: {enabled: false},
                colorAxis: {
                    min: 0
                },
                title: {
                  floating: true,
                    text: ""
                },
                tooltip: {
                    useHTML: true,
                    formatter: function() {
                        return '<div class="pr-4"><img class="mr-1" src="/img/flags/' + String(this.point["alpha-2"]).toLowerCase() + '.png' + '"><img>' + this.point.countryName + ': <b>' + this.y +'</div>'
                    }
                },
                legend: {
                    align: 'left',
                    verticalAlign: 'center',
                    layout: 'vertical',
                    title: {
                        text: 'Number of transactions',
                    }
                },
                mapNavigation: {
                    enabled: true,
                    buttonOptions: {
                        verticalAlign: 'bottom'
                    },
                    enableMouseWheelZoom: false
                },
                series: [{
                    name: 'Random data',
                    states: {
                        hover: {
                            color: '#BADA55'
                        }
                    },
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}'
                    },
                    allAreas: true,
                    joinBy: ['iso-a2', 'name'],
                    data: this.countryData.highChartData.map(x => ({...x, value: x.y}))
                }]
            }
        },

    },
}
</script>

<style scoped>

</style>