var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        graph: _vm.isOnGraphView,
        addressClusterTransactionViewGraph: !_vm.isOnGraphView,
      },
      staticStyle: { width: "auto" },
    },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            staticStyle: { left: "0" },
            attrs: {
              absolute: "",
              color: _vm.currency.unit,
              indeterminate: true,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "toolbar-buttons-row" },
        [
          _c(
            "v-speed-dial",
            {
              staticClass: "mt",
              attrs: { direction: "right" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: _vm.currency.unit,
                            fab: "",
                          },
                          model: {
                            value: _vm.fab,
                            callback: function ($$v) {
                              _vm.fab = $$v
                            },
                            expression: "fab",
                          },
                        },
                        [
                          _vm.fab
                            ? _c("v-icon", [_vm._v("close")])
                            : _c("v-icon", [_vm._v("double_arrow")]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.fab,
                callback: function ($$v) {
                  _vm.fab = $$v
                },
                expression: "fab",
              },
            },
            [
              _vm._v(" "),
              _c("RelayoutButton", {
                attrs: { color: _vm.currency.unit },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.relayoutClick.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("ExportToPngButton", {
                attrs: { color: _vm.currency.unit },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.exportToPng.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _vm._t("actionbuttons"),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cytoscape",
        {
          ref: "cy",
          attrs: {
            preConfig: _vm.preConfig,
            afterCreated: _vm.afterCreated,
            config: _vm.internalConfig,
          },
        },
        [
          _vm._l(_vm.nodes, function (node) {
            return _c("cy-element", {
              key: `${node._id}`,
              attrs: { definition: node },
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.edges, function (edge) {
            return _c("cy-element", {
              key: `${edge._id}`,
              attrs: { definition: edge },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }