import {computed, reactive, ref} from "vue";
import {CurrenciesInfo} from "@/components/MnemonicConvertor/CurrencyObjectHelper";
import {bipSpec, ExtendedKeyPrefix, IBipSpecs} from "@/components/MnemonicConvertor/mnemonicConvertorTypes";
import {setDerivationPath} from "@/components/MnemonicConvertor/DerivationPathHelper";
import {formatExtendedKeys} from "@/components/MnemonicConvertor/ExtendedKeyHelper";
import * as bip39 from "bip39";
import BIP32Factory from "bip32";
import * as ecc from 'tiny-secp256k1';
import {delay} from "@/utils";
import RowDataGenerator from "@/components/MnemonicConvertor/RowDataGenerator";

export default (currencies) => {
    const currenciesInfo = CurrenciesInfo(currencies)
    const selectedCrypto = ref(currenciesInfo.find(c => c.unit === "BTC"))
    const currencyIsLtc = computed(() => selectedCrypto.value.unit === "LTC")
    const useAlternativeLtcExtendedKeyFormat = ref(true)

    const getExtendedKeyPrefixes = () => {
        const selectedBipSpecs = bipSpecs[selectedBip.value]

        //BIP 141
        if (selectedBip.value === 141 && selectedCrypto.value.alternativeExtendedKeyPrefixes?.[141]) {
            selectedBipSpecs.extendedPublicKeyFormat = selectedCrypto.value.alternativeExtendedKeyPrefixes[selectedBip.value][selectedBipSpecs.scriptSemanticSelected.text].pub
            selectedBipSpecs.extendedPrivateKeyFormat = selectedCrypto.value.alternativeExtendedKeyPrefixes[selectedBip.value][selectedBipSpecs.scriptSemanticSelected.text].priv

            return
        }

        // Other BIPs if currency has alternative extended key prefixes enabled
        if (selectedCrypto.value.alternativeExtendedKeyPrefixes && selectedCrypto.value.alternativeExtendedKeyPrefixes[selectedBip.value] && selectedCrypto.value.unit !== 'LTC'
            || currencyIsLtc.value && useAlternativeLtcExtendedKeyFormat.value)
        {
            selectedBipSpecs.extendedPublicKeyFormat = selectedCrypto.value.alternativeExtendedKeyPrefixes[selectedBip.value].pub
            selectedBipSpecs.extendedPrivateKeyFormat = selectedCrypto.value.alternativeExtendedKeyPrefixes[selectedBip.value].priv

            return
        }

        // Other BIPs if they use default prefixes (same as Bitcoin)
        selectedBipSpecs.extendedPublicKeyFormat = selectedBipSpecs.defaultExtendedPublicKeyPrefix
        selectedBipSpecs.extendedPrivateKeyFormat = selectedBipSpecs.defaultExtendedPrivateKeyPrefix
    }

    const bipDisabled = (bip) => {
        if (selectedCrypto.value.unit === 'LTC' && !useAlternativeLtcExtendedKeyFormat.value) {
            return selectedCrypto.value.disabledBips?.includes(bip)
        } else if (selectedCrypto.value.unit !== 'LTC') {
            return selectedCrypto.value.disabledBips?.includes(bip)
        } else {
            return false
        }
    }

    const bip44Specs : IBipSpecs = reactive({
        name: 'BIP44',
        disabled: () => bipDisabled(bipSpec.bip44),
        purpose: 44,
        coin: 0,
        account: 0,
        change: 0,
        path: '',
        getPath: () => {
            let path = "m/"
            path += bip44Specs.purpose + "'/"
            path += bip44Specs.coin + "'/"
            path += bip44Specs.account + "'/"
            path += bip44Specs.change
            return path
        },
        extendedPrivateKey: '',
        extendedPublicKey: '',
        extendedPublicKeyFormat: ExtendedKeyPrefix.xpub,
        extendedPrivateKeyFormat: ExtendedKeyPrefix.xpriv,
        defaultExtendedPublicKeyPrefix: ExtendedKeyPrefix.xpub,
        defaultExtendedPrivateKeyPrefix: ExtendedKeyPrefix.xpriv,
        getExtendedKeyPrefixes: getExtendedKeyPrefixes,
    })

    const bip32Specs : IBipSpecs = reactive({
        name: 'BIP32',
        path: "m/0'/0'",
        disabled: () => bipDisabled(bipSpec.bip32),
        extendedPrivateKey: '',
        extendedPublicKey: '',
        clients: {
            'Bitcoin Core': "m/0'/0'",
            'blockchain.info': "m/44'/0'/0'",
            'Custom Derivation Path': "m/0",
            'MultiBit HD': "m/0'/0",
            'Coinomi, Ledger': `m/44'/${bip44Specs.coin}'/0'`
        },
        customPath: 'm/0',
        clientList: ['Bitcoin Core', 'Custom Derivation Path', 'blockchain.info', 'MultiBit HD', 'Coinomi, Ledger'],
        selectedClient: 'Bitcoin Core',
        extendedPublicKeyFormat: ExtendedKeyPrefix.xpub,
        extendedPrivateKeyFormat: ExtendedKeyPrefix.xpriv,
        defaultExtendedPublicKeyPrefix: ExtendedKeyPrefix.xpub,
        defaultExtendedPrivateKeyPrefix: ExtendedKeyPrefix.xpriv,
        getExtendedKeyPrefixes: getExtendedKeyPrefixes,
    })

    const bip49Specs : IBipSpecs = reactive({
        name: 'BIP49',
        disabled: () => bipDisabled(bipSpec.bip49),
        purpose: 49,
        coin: 0,
        account: 0,
        change: 0,
        path: '',
        getPath: () => {
            let path = "m/"
            path += bip49Specs.purpose + "'/"
            path += bip49Specs.coin + "'/"
            path += bip49Specs.account + "'/"
            path += bip49Specs.change
            return path
        },
        extendedPrivateKey: '',
        extendedPublicKey: '',
        extendedPublicKeyFormat: ExtendedKeyPrefix.ypub,
        extendedPrivateKeyFormat: ExtendedKeyPrefix.ypriv,
        defaultExtendedPublicKeyPrefix: ExtendedKeyPrefix.ypub,
        defaultExtendedPrivateKeyPrefix: ExtendedKeyPrefix.ypriv,
        getExtendedKeyPrefixes: getExtendedKeyPrefixes,
    })

    const bip84Specs : IBipSpecs = reactive({
        name: 'BIP84',
        disabled: () => bipDisabled(bipSpec.bip84),
        purpose: 84,
        coin: 0,
        account: 0,
        change: 0,
        path: '',
        getPath: () => {
            let path = "m/"
            path += bip84Specs.purpose + "'/"
            path += bip84Specs.coin + "'/"
            path += bip84Specs.account + "'/"
            path += bip84Specs.change
            return path
        },
        extendedPrivateKey: '',
        extendedPublicKey: '',
        extendedPublicKeyFormat: ExtendedKeyPrefix.zpub,
        extendedPrivateKeyFormat: ExtendedKeyPrefix.zpriv,
        defaultExtendedPublicKeyPrefix: ExtendedKeyPrefix.zpub,
        defaultExtendedPrivateKeyPrefix: ExtendedKeyPrefix.zpriv,
        getExtendedKeyPrefixes: getExtendedKeyPrefixes,
    })

    const semanticOptionDisabled = computed(() => selectedCrypto.value.unit === 'BCH')

    const selectDefaultSemantic = () => {
        const selectedBipSpecs = bipSpecs[selectedBip.value]

        if (!selectedBipSpecs.scriptSemanticOptions) return

        if (selectedBipSpecs.scriptSemanticOptions.find(o => o.text === bipSpecs[selectedBip.value].scriptSemanticSelected.text).disabled) {
            selectedBipSpecs.scriptSemanticOptions.forEach(o => {
                if (!o.disabled) selectedBipSpecs.scriptSemanticSelected = o
            })
        }
        if (selectedBipSpecs.scriptSemanticSelected.disabled) console.error("This BIP tab shouldn't be accessible.")
    }

    const currentSemanticOptionsDisabled = () => {
        const selectedBipSpecs = bipSpecs[selectedBip.value]

        return selectedBipSpecs.scriptSemanticSelected.disabled
    }

    const bip141Specs : IBipSpecs = reactive({
        name: 'BIP141',
        disabled: () => bipDisabled(bipSpec.bip141),
        path: 'm/0',
        extendedPrivateKey: '',
        extendedPublicKey: '',
        scriptSemanticOptions: computed(() => [
            {text: 'P2WPKH', disabled: semanticOptionDisabled.value},
            {text: 'P2WPKH nested in P2SH', disabled: false},
            {text: 'P2WSH (1-of-1 multisig)', disabled: semanticOptionDisabled.value},
            {text: 'P2WSH nested in P2SH (1-of-1 multisig)', disabled: false}
        ]),
        scriptSemanticSelected: {text: 'P2WPKH', disabled: semanticOptionDisabled.value},
        selectDefaultSemantic,
        currentSemanticOptionsDisabled: currentSemanticOptionsDisabled,
        extendedPublicKeyFormat: ExtendedKeyPrefix.zpub,
        extendedPrivateKeyFormat: ExtendedKeyPrefix.zpriv,
        defaultExtendedPublicKeyPrefix: ExtendedKeyPrefix.zpub,
        defaultExtendedPrivateKeyPrefix: ExtendedKeyPrefix.zpriv,
        getExtendedKeyPrefixes: getExtendedKeyPrefixes,
    })

    const bipSpecs = {
        44: bip44Specs,
        49: bip49Specs,
        84: bip84Specs,
        32: bip32Specs,
        141: bip141Specs
    }

    const selectedTab = ref(0)

    const selectedBip = computed(() => {
        return [32, 44, 49, 84, 141][selectedTab.value]
    })

    let bip32RootKey

    const loadSpecificBipSpecs = () => {
        //Select available script semantic in case there isn't one already
        if (typeof bipSpecs[selectedBip.value].selectDefaultSemantic === "function" && !bipSpecs[selectedBip.value].scriptSemanticSelected) {
            bipSpecs[selectedBip.value].selectDefaultSemantic()
        }
        setDerivationPath(bipSpecs, selectedBip.value)
        formatExtendedKeys(bipSpecs, selectedBip.value, bip32RootKey, selectedCrypto)
    }

    const mnemonicLanguages = ref([
        { language: "Čeština", countryCode: 'cz'},
        { language: "English", countryCode: 'us'},
        { language: "中文(简体)", countryCode: 'cn'},
        { language: "中文(繁體)", countryCode: 'cn'},
        { language: "한국어", countryCode: 'kr'},
        { language: "Français", countryCode: 'fr'},
        { language: "italiano", countryCode: 'it'},
        { language: "Español", countryCode: 'es'},
        { language: "日本語", countryCode: 'jp'},
        { language: "Português", countryCode: 'pt'},
    ])

    const selectedLanguage = ref(mnemonicLanguages.value[1].language)

    const languages = {
        'Čeština': 'czech',
        'English': 'english',
        'Español': 'spanish',
        "中文(简体)": 'chinese_simplified',
        "中文(繁體)": 'chinese_traditional',
        "한국어": 'korean',
        "Français": 'french',
        "italiano": 'italian',
        "日本語": 'japanese',
        "Português": 'portuguese',
    }

    const selectedLanguageFormatted = computed(() => languages[selectedLanguage.value])

    const isMnemonicValid = computed(() => bip39.validateMnemonic(mnemonic.value, bip39.wordlists[selectedLanguageFormatted.value]))

    const updateLtcExtendedKeyFormat = () => {
        if(!isMnemonicValid.value || mnemonic.value === '') {
            return
        }

        if (bipSpecs[selectedBip.value].disabled()) {
            selectedTab.value = 0
        }

        bipSpecs[selectedBip.value].getExtendedKeyPrefixes()
        loadSpecificBipSpecs()
    }

    const data = ref([])

    const numberOfRowsToGenerate = ref(10)
    const startingIndex = ref(0)
    const numberOfAddressesYetToGenerate = ref(0)

    const mnemonic = ref('')

    const resetState = () => {
        numberOfAddressesYetToGenerate.value = 0
        data.value = []
        numberOfRowsToGenerate.value = 10
        startingIndex.value = 0
    }

    const clearTableGetNewKey = async () => {
        resetState()

        if(!isMnemonicValid.value || mnemonic.value === '') {
            return
        }

        const seed = bip39.mnemonicToSeedSync(mnemonic.value)
        const bip32 = BIP32Factory(ecc)
        bip32RootKey = bip32.fromSeed(seed, selectedCrypto.value.network)

        bipSpecs[selectedBip.value].getExtendedKeyPrefixes()

        loadSpecificBipSpecs()
        //wait for current cycle to finish
        await delay(0)
        await callQueueAddressesToGenerate()
    }

    const validator = ref()

    const {queueAddressesToGenerate, bchAddressFormat} = RowDataGenerator()

    const warningDialog = ref(false)
    const canContinue = ref(false)

    const dialogClose = (bool) => {
        warningDialog.value = false
        canContinue.value = bool
        if(canContinue.value) {
            callQueueAddressesToGenerate()
        }
    }

    const useHardenedAddresses = ref(true)

    const callQueueAddressesToGenerate =  async () => {
        validator.value.validate()

        if (!isMnemonicValid.value) return

        //Warn user if the requested amount of addresses to generate is high
        if (numberOfRowsToGenerate.value > 100 && !canContinue.value) {
            warningDialog.value = true
            return
        }
        //reset state, to warn user again next time
        canContinue.value = false

        const addressGenerationSettings= {
            numberOfRowsToGenerate,
            startingIndex,
            bipSpecs,
            selectedBip: selectedBip.value,
            data,
            numberOfAddressesYetToGenerate,
            selectedCrypto,
            useHardenedAddresses,
            bip32RootKey
        }

        await queueAddressesToGenerate(addressGenerationSettings)
    }

    const updateBipCoinValues = () => {
        Object.keys(bipSpecs).forEach(bip => {
            if (bipSpecs[bip].hasOwnProperty('coin')) {
                bipSpecs[bip].coin = selectedCrypto.value.coin
            }
        })
    }

    const changeCurrency = (newVal) => {
        if (newVal === selectedCrypto.value.unit) {
            return
        }

        selectedCrypto.value = currenciesInfo.find(c => c.unit === newVal)

        updateBipCoinValues()

        // change bip to default in case the current one is disabled on this currency
        if (bipSpecs[selectedBip.value].disabled()) {
            selectedTab.value = 0
        }

        // change semantics to default in case the current one is disabled on this currency
        if (bipSpecs[selectedBip.value].scriptSemanticOptions && bipSpecs[selectedBip.value].scriptSemanticOptions.find(o => o.text === bipSpecs[selectedBip.value].scriptSemanticSelected.text).disabled) {
            bipSpecs[selectedBip.value].selectDefaultSemantic()
        }

        clearTableGetNewKey()
    }

    const addressTypeChange = (newVal) => {
        if (bipSpecs[selectedBip.value].scriptSemanticOptions) {
            bipSpecs[selectedBip.value].scriptSemanticSelected = newVal
        }
        bipSpecs[selectedBip.value].getExtendedKeyPrefixes()

        clearTableGetNewKey()
    }

    const updateRefAndReset = (newVal) => {
        useHardenedAddresses.value = newVal
        clearTableGetNewKey()
    }

    return {
        selectedCrypto,
        currenciesInfo,
        useAlternativeLtcExtendedKeyFormat,
        bipSpecs,
        selectedBip,
        selectedTab,
        isMnemonicValid,
        data,
        numberOfRowsToGenerate,
        startingIndex,
        numberOfAddressesYetToGenerate,
        validator,
        bchAddressFormat,
        mnemonic,
        useHardenedAddresses,
        warningDialog,
        mnemonicLanguages,
        selectedLanguage,
        selectedLanguageFormatted,
        callQueueAddressesToGenerate,
        dialogClose,
        clearTableGetNewKey,
        updateLtcExtendedKeyFormat,
        changeCurrency,
        addressTypeChange,
        updateRefAndReset
    }
}