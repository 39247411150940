var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.onCopy && (_vm.showExtra || _vm.isCopyable || _vm.actAsLink)
    ? _c(
        "div",
        [
          _c("v-hover", {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ hover }) {
                    return [
                      _c("div", { class: `d-flex justify-${_vm.justify}` }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: {
                              "max-width": "100%",
                              position: "relative",
                            },
                            style: `width: ${_vm.containerWidth}`,
                          },
                          [
                            _vm._t("text"),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex justify-end",
                                staticStyle: {
                                  position: "absolute",
                                  width: "100%",
                                },
                              },
                              [
                                hover && _vm.isCopyable
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-row",
                                        staticStyle: { position: "absolute" },
                                        style: !_vm.aside && { right: "0px" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticStyle: {
                                              padding: "4px",
                                              "margin-top": "2px",
                                            },
                                            style: [
                                              !_vm.dark && {
                                                "background-color": "#f0eeee",
                                              },
                                            ],
                                            attrs: {
                                              "min-width": "0px",
                                              "x-small": "",
                                              elevation: "0",
                                            },
                                            on: {
                                              click: [
                                                function ($event) {
                                                  $event.preventDefault()
                                                },
                                                function ($event) {
                                                  $event.stopPropagation()
                                                  return (() =>
                                                    _vm.copy(_vm.onCopy)).apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  dark: _vm.dark,
                                                  small: "",
                                                },
                                              },
                                              [_vm._v("content_copy")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        hover && _vm.showExtra
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticStyle: {
                                                  padding: "4px",
                                                  "margin-top": "2px",
                                                },
                                                style: [
                                                  !_vm.dark && {
                                                    "background-color":
                                                      "#f0eeee",
                                                  },
                                                ],
                                                attrs: {
                                                  "min-width": "20px",
                                                  "x-small": "",
                                                  elevation: "0",
                                                },
                                                on: {
                                                  click: [
                                                    function ($event) {
                                                      $event.preventDefault()
                                                    },
                                                    function ($event) {
                                                      return _vm.$emit(
                                                        "extra-click-event",
                                                        $event
                                                      )
                                                    },
                                                    function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      dark: _vm.dark,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "fa-solid fa-ellipsis-vertical"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._t("permanent"),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }