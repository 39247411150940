<template>
    <div>
        <v-container class="px-4" fluid>
            <h1>Dashboard</h1>
            <v-divider />
            <v-row>
                <v-col cols="12">
                    <div>
                        <v-row class="mt-2" align="center" wrap>
                            <CurrencyPanel
                                v-for="(currencyStatus,index) in Object.values(dashboardData)"
                                :loading="dashboardLoading[currencyStatus.unit]"
                                :currency="getCurrencyByUnit(currencyStatus.unit)"
                                :key="index"
                                :data="currencyStatus"
                            />
                        </v-row>
                        <v-row justify="center">
                            <LatestTransactions
                                class="mx-3"
                                :showInputsOutputs="true"
                                :showFee="true"
                                :transactionIdLength="50"
                            />
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import CurrencyPanel from "@/components/currency/CurrencyPanel"
import { ProgressCircular } from "@/components/common"
import { mapGetters } from "vuex";
import LatestTransactions from "./LatestTransactions"

export default {
    name: 'CurrenciesStatus',
    components: {
        CurrencyPanel,
        ProgressCircular,
        LatestTransactions
    },
    created() {
        this.$store.dispatch('clearCurrency')
    },
    mounted() {
        //Fetch dashboard for each currency
        this.enabledCurrencies.forEach(x => {
            this.$store.dispatch("loadDashboardSingle", { currency: x.unit })
        })
    },
    computed: {
        ...mapGetters([
            'dashboardData', 'dashboardLoading','enabledCurrencies'
        ]),
        data() {
            return this.$store.getters.dashboardData
        },
    },
    methods: {
        getCurrencyByUnit(unit) {
            return this.$store.getters.currencyByCurrencyUnit({ currencyUnit: unit })
        }
    }
}
</script>
