<template>
    <v-row justify="center">
        <v-col cols="auto">
            <v-progress-circular :size="size" :width="width" :color="color" indeterminate />
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'ProgressCircular',
    props: {
        color: {
            type: String,
            default: 'primary'
        },
        size: {
            default: 50,
            type: Number
        },
        width: {
            type: Number,
            required: false
        }
    }
}
</script>

<style scoped>
</style>
