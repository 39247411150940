import { render, staticRenderFns } from "./BlockchainCryptoBadge.vue?vue&type=template&id=76cdc9c1&scoped=true&"
import script from "./BlockchainCryptoBadge.vue?vue&type=script&lang=js&"
export * from "./BlockchainCryptoBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76cdc9c1",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('76cdc9c1')) {
      api.createRecord('76cdc9c1', component.options)
    } else {
      api.reload('76cdc9c1', component.options)
    }
    module.hot.accept("./BlockchainCryptoBadge.vue?vue&type=template&id=76cdc9c1&scoped=true&", function () {
      api.rerender('76cdc9c1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/common/BlockchainCryptoBadge.vue"
export default component.exports