var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "px-2" }, [
    _vm.label
      ? _c(
          "div",
          {
            staticClass: "monospace v-label",
            staticStyle: { "font-size": "0.9em" },
          },
          [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "monospace v-input",
        staticStyle: { color: "rgba(0, 0, 0, 0.87)" },
      },
      [_vm._v("\n        " + _vm._s(_vm.text) + "\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }