<template>
    <BaseModalDialog
        :title="isEditModal ? 'Edit file' : 'Upload file'"
        @load-modal-state-event="loadState"
        :loading="loading"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <validation-provider rules="required" v-slot="{ errors, valid }">
                    <v-file-input
                        :error-messages="errors"
                        :success="valid"
                        required
                        prepend-icon="cloud_upload"
                        accept="*"
                        label="Click to browse or drop a file here"
                        v-model="detail.file"
                    />
                </validation-provider>
                <div v-if="!isEditModal" v-cloak @drop.prevent="addDropFiles" @dragover.prevent>
                </div>
                <CasePicker
                    v-model="detail.case_id"
                    class="pt-3"
                    rules="required"
                />
            </ValidationObserver>
        </template>
        <template #footer="{close, setDisabled}">
            <v-btn
                dark
                outlined
                color="error"
                :disabled="filesModalState === modalState.Pending"
                @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                dark
                color="success"
                :loading="filesModalState === modalState.Pending"
                outlined
                @click="save(close, setDisabled)"

            >{{ isEditModal ? "Save file detail" : "Upload file" }}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import {deepClone} from "@/utils";
import BaseModalDialog from "@/components/common/Base/BaseModalDialog";
import {mapGetters} from "vuex";
import {modalState} from "@/components/common/types/modal";
import CasePicker from "@/components/common/CasePicker.vue";

export default {
    name: "FileDetailModal",
    components: {
        CasePicker,
        BaseModalDialog
    },
    props: {
        fileDetail: {
            type: Object
        },
        cases: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            detail: {},
            isEditModal: true,
            closeDialog: null,
            setDisabled: null,
        }
    },
    computed: {
        ...mapGetters(['filesModalState']),
        modalState() {
            return modalState
        }
    },
    watch: {
        filesModalState(newVal) {
            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('FILES_MODAL_SET_STATE', modalState.Initial)
            } else if (newVal === modalState.Error)
            {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('FILES_MODAL_SET_STATE', modalState.Initial)
            }
        }
    },
    methods: {
        async save(close, setDisabled) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.closeDialog = close
                this.setDisabled = setDisabled
                setDisabled(true)
                this.$emit("save-event", { fileDetail: this.detail })
            }
        },
        loadState() {
            if (this.fileDetail) {
                this.isEditModal = true
                this.detail = deepClone(this.fileDetail)
            }
            else {
                this.isEditModal = false
                this.detail = {
                    case_id: undefined,
                    file: undefined // file is only for creating
                }
            }
        },
        addDropFiles(e) {
            //todo FE add possibility to load more files?
            this.detail.file = e.dataTransfer.files[0]
            // this.filesToUpload.append(e.dataTransfer.files)
        }
    }
}
</script>
