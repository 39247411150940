<script setup>

import FancyCard from "@/components/common/Cards/FancyCard.vue";
import {computed, ref} from "vue";
import store from "@/store";
import GraphFormSelectMultiple from "@/views/Graph/Forms/GraphFormSelectMultiple.vue";
import {BlockchainCryptoBadge} from "@/components/common";
import DataGrid from "@/components/DataGrid/DataGrid.vue";
import {useRoute} from "vue-router/composables";
import {useGetData} from "@/components/common/Composables/useGetData";
import RouteToGraph from "@/components/common/RouteToGraph.vue";
import DataGridBulkDelete from "@/components/DataGrid/Actions/Put/DataGridBulkDelete.vue";
import DataGridBulkAdd from "@/components/DataGrid/Actions/Put/DataGridBulkAdd.vue";

const headers = [
    {text: "Name", align: "center", value: "name", width: "20%"},
    {text: "Description", align: "center", value: "description", width: 300},
    {text: "Currency", sortable: false, value: "crypto", width: 130},
    {text: "# Configs", align: "center", value: "count", width: 110},
    {text: 'Visibility', align: 'center', value: 'is_hidden'},
    {text: "Created At", align: "center", value: "created_at"},
    {text: "Created By", align: "center", value: "created_by"},
    {text: "Updated At", align: "center", value: "updated_at"},
]

const route = useRoute()
const caseId = computed(() => route.params.caseId)

const {dataState, getData, data} = useGetData("loadCaseGraphs", {
    caseId: caseId.value,
}, false)

const gridRef = ref(null)

const deleteItems = async (itemsToDelete) =>
    await store.dispatch("deleteCaseGraphs", {
        caseId: caseId.value,
        itemsToDelete
    })

const addGraphsToCase = async (itemsToAdd) =>
    await store.dispatch("addGraphsToCase", {
        caseId: caseId.value,
        itemsToAdd
    })

const refreshItems = () => gridRef.value.refreshItems()

const onSubmit = async () => {
    let itemsToAdd = GraphFormSelectMultipleRef.value.itemsToAdd
    if (itemsToAdd.length === 0) {
        return
    }

    loading.value = true
    DataGridBulkAddModalRef.value.setDisabled(true)

    await addGraphsToCase(itemsToAdd.map(i => i.id))

    DataGridBulkAddModalRef.value.setDisabled(false)
    DataGridBulkAddModalRef.value.onClose()
    loading.value = false
    refreshItems(true)
}

const DataGridBulkAddModalRef = ref(null)
const GraphFormSelectMultipleRef = ref(null)

const loading = ref(false)

defineExpose({refreshItems})

</script>

<template>
    <FancyCard class="cardShadow" dense>
        <template #header>
            <div class="d-flex align-center">
                <v-icon class="d-block mr-2 ml-1">timeline</v-icon>
                <span class="font-weight-bold">Graphs</span>
            </div>
        </template>
        <template #body>
            <DataGrid
                ref="gridRef"
                :get-data="{ dataState, getData, data}"
                :headers="headers"
                :no-data-text="`No graphs found`"
                bulk-operations
                hide-header-if-no-data
                hide-footer-if-no-data
                item_id="id">
                <template
                    #header-right="{ getItems, refreshItems, removeSelection, patchActions, isAnyItemSelected, isDataPatched }">
                    <DataGridBulkAdd title="Add Graphs to Case" :loading="loading" modal-width="50%"
                                     save-button-text="Submit" @submit="onSubmit"
                                     :addItemsButtonDisabled="GraphFormSelectMultipleRef?.itemsToAdd.length === 0"
                                     ref="DataGridBulkAddModalRef"
                    >
                        <GraphFormSelectMultiple ref="GraphFormSelectMultipleRef"/>
                    </DataGridBulkAdd>
                    <DataGridBulkDelete :disabled="!isAnyItemSelected" :get-items="getItems"
                                        :item-callback-async="deleteItems" title="Remove Graphs"
                                        @action-finished-event="() => {refreshItems(); removeSelection()}"/>
                </template>
                <template #item="{ item }">
                    <td class="text-left dynamicTruncate">
                        <RouteToGraph :graph-object="item"/>
                    </td>
                    <td class="text-left d-flex align-center">
                        <v-hover v-slot="{ hover }" open-delay="200">
                            <span
                                :class="hover && 'show-full-text-hover'"
                                class="description-styles pa-1"
                                style="margin: auto 0 0 0"
                            >{{ item.description }}</span>
                        </v-hover>
                    </td>
                    <td style="text-align: center;vertical-align: middle;">
                        <BlockchainCryptoBadge
                            :crypto="item.crypto"
                            :iconWidth="24"
                        />
                    </td>
                    <td class="text-right"
                        style="
                                        text-align: center;
                                        vertical-align: middle;
                                    ">
                        {{ item.count | formatNumber }}
                    </td>
                    <td
                        style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                    >
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-icon
                                    :color="item.is_hidden ? 'primary' : 'tertiary'"
                                    dark
                                    v-on="on"
                                >{{
                                        item.is_hidden
                                            ? "visibility_off"
                                            : "visibility"
                                    }}
                                </v-icon
                                >
                            </template>
                            <span
                            >Graph is
                                            {{
                                    item.is_hidden
                                        ? "hidden"
                                        : "visible"
                                }}</span
                            >
                        </v-tooltip>
                    </td>
                    <td class="text-overflow" style="text-align: center;vertical-align: middle;">
                        <span>{{
                                item.created_at
                                    | moment("YYYY-MM-DD HH:mm:ss Z")
                            }}</span>
                    </td>
                    <td class="text-overflow text-left"
                        style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                    >
                        <span>{{ item.creator }}</span>
                    </td>
                    <td class="text-overflow"
                        style="
                                        text-align: center;
                                        vertical-align: middle;
                                    ">
                    <span>{{
                            item.updated_at
                                | moment("YYYY-MM-DD HH:mm:ss Z")
                        }}</span>
                    </td>
                </template>
            </DataGrid>
        </template>
    </FancyCard>
</template>