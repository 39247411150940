<template>
    <div class="scroll-y height100 width100">
        <v-layout style="border-bottom: 1px #0000001F solid; height: 49px" column fill-height>
            <v-app-bar elevation="0" color="white" dense>
                <v-tabs :slider-color="currency.unit" background-color="white" light show-arrows>
                    <v-tab :to="`/${currency.unit}/status`">Status</v-tab>
                    <v-tab v-if="currency.typeFamily === 'ETH'" :to="'/' + currency.unit + '/transactions/'">Transactions</v-tab>
                    <v-menu
                        v-else
                        bottom
                        offset-y
                        content-class="currency-hover-menu elevation-2"
                        transition="slide-y-transition"
                        open-on-hover
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-tab :to="'/' + currency.unit + '/transactions/'" v-bind="attrs" v-on="on">
                                Transactions
                                <v-icon right>
                                    mdi-chevron-down
                                </v-icon>
                            </v-tab>
                        </template>
                        <v-list dense class="mt-n2">
                            <v-list-item-group v-model="subMenu" :color="currency.unit">
                                <router-link :to="`/${currency.unit}/transactions/custom`"
                                             style="text-decoration: none">
                                    <v-list-item :value="'nodes'">
                                        <v-list-item-title
                                            style="font-weight: 500; letter-spacing: 1px; font-size: 14px">{{
                                                "CUSTOM"
                                            }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>
                                <router-link :to="`/${currency.unit}/transactions/geo/`"
                                             style="text-decoration: none">
                                    <v-list-item :value="'files'" style="border-top: solid 1px rgba(0, 0, 0, 0.1);">
                                        <v-list-item-title
                                            style="font-weight: 500; letter-spacing: 1px; font-size: 14px">{{ "GEO" }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                    <v-tab :to="`/${currency.unit}/blocks`">Blocks</v-tab>
                    <v-tab :to="`/${currency.unit}/peers`">Peers</v-tab>
                    <v-tab v-if="!isCurrencyFamilyEth" :to="`/${currency.unit}/nodes`">Nodes</v-tab>
                    <v-menu
                        v-if="currency.interceptorEnabled && $store.getters.hasPermission('monitoring')"
                        bottom
                        offset-y
                        content-class="currency-hover-menu elevation-2"
                        transition="slide-y-transition"
                        open-on-hover
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-tab :to="'/' + currency.unit + '/monitoring/'" v-bind="attrs" v-on="on">
                                Monitoring
                                <v-icon right>
                                    mdi-chevron-down
                                </v-icon>
                            </v-tab>
                        </template>
                        <v-list dense class="mt-n2">
                            <v-list-item-group v-model="subMenu" :color="currency.unit">
                                <router-link :to="`/${currency.unit}/monitoring/`" style="text-decoration: none">
                                    <v-list-item :value="'nodes'">
                                        <v-list-item-title
                                            style="font-weight: 500; letter-spacing: 1px; font-size: 14px">{{ "NODES" }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>
                                <router-link :to="`/${currency.unit}/monitoring/files/`"
                                             style="text-decoration: none">
                                    <v-list-item :value="'files'" style="border-top: solid 1px rgba(0, 0, 0, 0.1);">
                                        <v-list-item-title
                                            style="font-weight: 500; letter-spacing: 1px; font-size: 14px">{{ "FILES" }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </router-link>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </v-tabs>
            </v-app-bar>
            <div class="scroll-y height100">
                <v-container class="px-4" fluid>
                    <router-view/>
                </v-container>
            </div>
        </v-layout>
    </div>
</template>

<script>

export default {
    name: 'CurrencyDetailView',
    computed: {
        currency() {
            return this.$store.getters.currency
        },
        isCurrencyFamilyEth() {
            return this.$store.getters.isCurrencyFamilyEth
        }
    },
    watch: {
        '$route.path': {
            handler() {
                if (this.$route.path.includes(`/${this.currency.unit}/monitoring/files`)) {
                    this.subMenu = "files"
                } else if (this.$route.path.includes(`/${this.currency.unit}/monitoring`)) {
                    this.subMenu = "nodes"
                } else {
                    this.subMenu = undefined
                }
            },
            immediate: true
        },
        currency: {
            handler() {
                this.$store.commit('TRANSACTIONS_CANCEL_AND_CLEAR')
            }
        }
    },
    data() {
        return {
            subMenu: undefined
        }
    }
}
</script>

<style>
.currency-hover-menu {
    top: 114px !important;
    border-radius: 0px !important;
}
</style>
