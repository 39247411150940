<template>
    <v-menu :close-on-content-click="false" left offset-y>
        <template #activator="{ on: menu }">
            <v-tooltip top>
                <template #activator="{ on: tooltip }">
                    <v-btn
                        style="width: 100%; border-left: thin solid rgba(0, 0, 0, 0.3); border-radius: 0 4px 4px 0; min-width: 0; padding: 18px 0; height: 28px; opacity: 0.8"
                        color="#EEEEEE"
                        elevation="0"
                        @click="loadGraphs"
                        v-on="{ ...tooltip, ...menu }"
                    >
                        <v-icon color="#444444">timeline</v-icon>
                    </v-btn>
                </template>
                <span>Show transaction graph</span>
            </v-tooltip>
        </template>

        <v-list style="max-width: 400px; min-width: 400px; min-height: 200px" single-line>
            <template v-if="isOnGraphPage">
                <v-list-item>
                    <v-list-item-content>
                        <ShowOnCurrentGraphButton
                            @show-transaction-click-event="appendTransactionNetworkToExistingGraph"
                        />
                    </v-list-item-content>
                </v-list-item>
                <v-divider />
            </template>

            <v-list-item>
                <v-list-item-content>
                    <ShowOnCanvasButton
                        v-if="isOnCanvasPage"
                        @show-transaction-click-event="appendTransactionNetworkToExistingTransactionNetwork"
                    />
                    <ShowOnCanvasButton
                        v-else
                        :currency="this.currency"
                        :txhash="txhash"
                        @show-transaction-click-event="redirectToTransactionNetworkView"
                    />
                </v-list-item-content>
            </v-list-item>

            <v-divider />
            <v-subheader>Show on graph</v-subheader>

            <template v-if="!graphsLoading">
                <v-data-table
                    :items="graphs.data"
                    :items-per-page="graphs.data?.length > 10 ? 10 : -1"
                    :hide-default-footer="graphs.data?.length <= 10"
                    :footer-props="{
                        'items-per-page-options': [10, 20, -1],
                        'show-current-page': true,
                    }"
                    dense
                >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td class="text-left dynamicTruncate">
                                <route-to-graph :graph-object="props.item" :transaction-network-id-to-append="txhash"/>
                            </td>
                        </tr>
                    </template>
                    <template #footer.prepend>
                        <v-spacer/>
                    </template>
                </v-data-table>
            </template>
            <v-list-item v-else-if="! graphsLoading && graphs.data.length === 0">
                <v-list-item-content>No graphs in currency {{currency.name}}</v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
                <ProgressCircular class="pt-4" :color="currency.unit" />
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import ProgressCircular from "@/components/common/ProgressCircular";
import ShowOnCanvasButton from "./Components/ShowOnCanvasButton";
import { loadTransactionSides } from "../../store/modules/Transactions";
import ShowOnCurrentGraphButton from "@/components/transaction/Components/ShowOnCurrentGraphButton";
import RouteToGraph from "@/components/common/RouteToGraph.vue";

//todo FE rename to TransactionShowOnGraphMenu
// move all store usage to View code behind, use props, events...
// refactor isOnGraphPage, isOnCanvasPage
export default {
    name: "TransactionShowOnGraph",
    components: {RouteToGraph, ShowOnCurrentGraphButton, ProgressCircular, ShowOnCanvasButton },
    props: {
        txhash: {
            type: String
        }
    },
    computed: {
        isOnCanvasPage() {
            //todo refactor this
            return (
                this.$route.matched.findIndex(route =>
                    route.name === "TransactionNetwork" ||
                    route.name === "FundsNetwork" ||
                    route.name === "FundsNetworkCluster"
                ) !== -1
            );
        },
        isOnGraphPage() {
            return (this.$route.matched.findIndex(route => route.name === "GraphSingleView") !== -1);
        },
        graphs() {
            return this.$store.getters.graphs;
        },
        graphsLoading() {
            return this.$store.getters.graphsLoading;
        },
        currency() {
            return this.$store.getters.currency;
        }
    },

    methods: {
        loadGraphs() {
            // TODO: we need to dynamic load graphs. Now only first 50 will be in menu
            this.$store.dispatch("loadGraphs", { pagination: { itemsPerPage: 50, currency: this.currency.unit } });
        },
        redirectToTransactionNetworkView(sides) {
            const url = "/" + this.currency.unit + "/transaction/" + this.txhash + "/network/" + sides;
            this.$router.push(url);
        },
        appendTransactionNetworkToExistingTransactionNetwork(sides) {
            this.$store.dispatch("appendTransactionNetworkToExistingCanvas", { currency: this.currency.unit, transactionId: this.txhash, sides: sides });
        },
        redirectToGraphPageWithSelectedTransactionNetwork({id, description}) {
            //this.$emit("redirect-to-graph-page-with-transaction-event", graphId)
            if(description === 'Interactive Graph') {
                this.$router.push({name: 'GraphExplorer', params: { id: id }, query: { transactionNetworkIdToAppend: this.txhash }})
            } else {
                this.$router.push("/" + this.currency.unit + "/graph/" + id + "/config/?transactionNetworkIdToAppend=" + this.txhash)
            }
        },
        appendTransactionNetworkToExistingGraph(sides) {
            this.$store.dispatch("appendTransactionNetworkToExistingGraph", { currency: this.currency.unit, transactionNetworkIdToAppend: this.txhash, sides: sides });
        }
    },
    data() {
        return {
            loadTransactionSides: loadTransactionSides
        };
    }
};
</script>

<style scoped>

tr:hover {
    background-color: transparent !important;
}

</style>
