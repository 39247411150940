var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticStyle: { width: "100%" },
      style: _vm.noDecoration ? "text-decoration: none" : "",
      attrs: { to: { path: _vm.targetUrl } },
    },
    [_vm._t("text")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }