<script setup>
import {useGetData} from "@/components/common/Composables/useGetData";
import {computed} from "vue";
import store from "@/store";
import {DataState} from "@/components/common/types/data";
import HeaderLined from "@/components/common/HeaderLined.vue";
import NameChipValueFlex from "@/components/common/NameChipValueFlex.vue";
import {formatNumber} from "humanize-plus";

const props = defineProps({
    address: {
        type: String,
        required: true
    }
})

const currency = computed(() => store.getters.currency)

const {data, dataState, getData} = useGetData('getAddressBudget', {}, false)

const loading = computed(() => dataState.value === DataState.Loading)

const getBudget = () => getData({
    currency: currency.value.unit,
    address: props.address
})

const currentFiatUnit = computed(() => store.getters.currentFiat?.unit)

const isDebug = computed(() => store.getters.debug)

const depositedValueRaw = computed(() => data.value?.deposited[currentFiatUnit.value.toLowerCase()])
const withdrawnValueRaw = computed(() => data.value?.withdrawn[currentFiatUnit.value.toLowerCase()])

const isValid = (value) => (value || (!value && value === 0))

const formatValue = (value) => isValid(value) ? formatNumber(value, 2) : undefined

</script>

<template>
    <div
        v-if="isDebug">
        <v-expand-transition>
            <v-btn v-show="!data"
                   small
                   block
                   outlined
                   color="tertiary"
                   @click="getBudget"
                   :loading="loading">
                Get Budget
            </v-btn>
        </v-expand-transition>
        <v-expand-transition>
            <div v-show="data">
                <HeaderLined text="Budget"/>
                <NameChipValueFlex name="Deposited"
                                   chip-color="success"
                                   :unit="currentFiatUnit"
                                   :value="formatValue(depositedValueRaw)">
                    <template #appendIcon>
                        <v-icon small class="largeRight">
                            mdi-download mdi-rotate-270
                        </v-icon>
                    </template>
                </NameChipValueFlex>
                <NameChipValueFlex name="Withdrawn"
                                   chip-color="red"
                                   :unit="currentFiatUnit"
                                   :value="formatValue(withdrawnValueRaw)">
                    <template #appendIcon>
                        <v-icon small class="largeRight">
                            mdi-upload mdi-rotate-90
                        </v-icon>
                    </template>
                </NameChipValueFlex>
            </div>
        </v-expand-transition>
    </div>
</template>

<style scoped>
.largeRight {
    margin-left: 4px;
    font-size: 28px !important;
}
</style>