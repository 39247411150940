var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ring-container",
      style: { height: _vm.height + "px", width: _vm.width + "px" },
    },
    [
      _c("div", {
        staticClass: "ringring",
        style: `border: 2px solid ${_vm.pulseColor}`,
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "circle" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            {
                              staticClass: "not-allowed",
                              attrs: { small: "", color: _vm.pulseColor },
                            },
                            on
                          ),
                          [_vm._t("iconName")],
                          2
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [_vm._v(" "), _c("span", [_vm._t("tooltip")], 2)]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }