var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "width" },
    [
      _c(
        "v-card",
        {
          staticClass: "ma-3 rounded-card",
          attrs: {
            hover: "",
            to: "/" + _vm.data.unit,
            disabled: _vm.data.error,
          },
        },
        [
          _c(
            "div",
            { staticClass: "rounded-card" },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "elevation-0",
                  staticStyle: { "border-radius": "5px 5px 0 0" },
                  attrs: {
                    height: "56",
                    dark: "",
                    text: "",
                    color: _vm.currency.hexColor,
                  },
                },
                [
                  _c("v-img", {
                    staticClass: "mr-2",
                    attrs: { "max-width": "35", src: _vm.currency.image },
                  }),
                  _vm._v(" "),
                  _c("h3", [_vm._v(_vm._s(_vm.currency.name))]),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.loading
                ? _c("div", { staticStyle: { height: "312px" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-center justify-center",
                        staticStyle: { height: "80%" },
                      },
                      [
                        _c("ProgressCircular", {
                          attrs: { color: _vm.currency.unit },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm.data.error
                ? _c("div", { staticStyle: { height: "312px" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-center justify-center",
                        staticStyle: {
                          height: "80%",
                          color: "rgba(0,0,0,0.6)",
                        },
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("Failed to retrieve data!"),
                        ]),
                      ]
                    ),
                  ])
                : _c(
                    "div",
                    [
                      _vm.data !== null
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-wrap mt-2 justify-center",
                            },
                            _vm._l(_vm.cryptoStats, function (block, index) {
                              return _c("StatusCircular", {
                                key: index,
                                attrs: {
                                  name: block.name,
                                  value: block.value,
                                  color: block.color,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "my-2" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-center align-center flex-column pb-2",
                        },
                        [
                          _c("table", [
                            _c("tr", [
                              _c("td", { staticClass: "text-center" }, [
                                _c(
                                  "div",
                                  { staticClass: "font-weight-bold mr-2" },
                                  [
                                    _vm._v(
                                      "\n                                    Headers:\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-left ml-2 fontMonospace",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$humanize.formatNumber(
                                            _vm.data.status.headers
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "text-center" }, [
                                _c(
                                  "div",
                                  { staticClass: "font-weight-bold mr-2" },
                                  [
                                    _vm._v(
                                      "\n                                    Connections:\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("div", { staticClass: "text-left ml-2" }, [
                                  _c("span", { staticClass: "fontMonospace" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.data.netinfo.connections) +
                                        "\n                                    "
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n                                    peers\n                                "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }