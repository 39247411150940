const setDerivationPath = (bipSpecs: Object, selectedBip: number) => {
    const selectedBipSpecs = bipSpecs[selectedBip]
    if (selectedBipSpecs === undefined) {
        console.log("Unknown bip spec")
        return
    }

    if (typeof selectedBipSpecs.getPath === "function") {
        selectedBipSpecs.path = selectedBipSpecs.getPath()
    }

    if(selectedBip === 32) {
        selectedBipSpecs.path = selectedBipSpecs.clients[selectedBipSpecs.selectedClient]
    }
}

const getDerivationPath = (bipSpecs: Object, selectedBip: number) => {
    const selectedBipSpecs = bipSpecs[selectedBip]
    if (selectedBipSpecs === undefined) {
        console.error("Unknown bip spec")
        return
    }

    if(selectedBipSpecs.selectedClient === 'Custom Derivation Path'){
        return selectedBipSpecs.customPath
    }

    return selectedBipSpecs.path
}

export { setDerivationPath, getDerivationPath }