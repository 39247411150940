var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "content-class": "whiteBackground",
        "close-on-content-click": false,
        "offset-x": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(_setup.ButtonWrapperHoverTooltip, {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "active",
                      fn: function ({ on: onTooltip, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { small: "", icon: "" },
                                  on: { click: on.click },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              onTooltip
                            ),
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "blue", small: "" } },
                                [
                                  _vm._v(
                                    "\n                        mdi-filter-menu\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "tooltip",
                      fn: function () {
                        return [
                          _vm._v("\n                Edit Filter\n            "),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "alt",
                      fn: function () {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { small: "", icon: "" } },
                                "v-btn",
                                _setup.attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm.value
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "blue", small: "" } },
                                    [
                                      _vm._v(
                                        "\n                        mdi-filter-menu\n                    "
                                      ),
                                    ]
                                  )
                                : _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      "\n                        mdi-filter-menu\n                    "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ]
          },
        },
      ]),
      model: {
        value: _setup.menuVisible,
        callback: function ($$v) {
          _setup.menuVisible = $$v
        },
        expression: "menuVisible",
      },
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "background-color": "white" } },
        [_vm._t("menuContent")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }