import apiClient from "@/services/apiClient";
import axios from "axios";
import Vue from "vue";

const state = {

    cryptoInterceptorReFetchData: false,
    cryptoInterceptorReFetchFiles: false,

    cryptoInterceptorPeersData: null, //ip to data
    cryptoInterceptorPeersLoading: false, //Loading

    cryptoInterceptorFilesLoading: false,
    cryptoInterceptorFilesData: null,

    cryptoInterceptorPeerFilesLoading: {}, //ip to file loading + cancel token
    cryptoInterceptorPeerFilesData: {}, //ip to file data

    cryptoInterceptorPeerActionPending: {}, //ip to pending action
    cryptoInterceptorPeersStatusPolling: {}, //ip to cancel token

    cryptoInterceptorCancelToken: null,
    cryptoInterceptorFilesCancelToken: null
}

const getters = {
    cryptoInterceptorPeersData: (state) => state.cryptoInterceptorPeersData,
    cryptoInterceptorLoading: (state) => state.cryptoInterceptorPeersLoading,
    cryptoInterceptorPeerActionPending: (state) => state.cryptoInterceptorPeerActionPending,

    cryptoInterceptorPeerFilesLoading: (state) => state.cryptoInterceptorPeerFilesLoading,
    cryptoInterceptorPeerFilesData: (state) => state.cryptoInterceptorPeerFilesData,

    cryptoInterceptorPeersStatusPolling: (state) => state.cryptoInterceptorPeersStatusPolling,

    cryptoInterceptorFilesLoading: (state) => state.cryptoInterceptorFilesLoading,
    cryptoInterceptorFilesData: (state) => state.cryptoInterceptorFilesData,

    cryptoInterceptorReFetchData: (state) => state.cryptoInterceptorReFetchData,
    cryptoInterceptorReFetchFiles: (state) => state.cryptoInterceptorReFetchFiles,
}

const mutations = {

    //PEERS Loading
    CRYPTOINTERCEPTOR_PEERS_LOADING(state, data) {
        if(state.cryptoInterceptorCancelToken) {
            state.cryptoInterceptorCancelToken.cancel()
        }
        state.cryptoInterceptorCancelToken = axios.CancelToken.source()
        state.cryptoInterceptorPeersLoading = true;
    },
    CRYPTOINTERCEPTOR_PEERS_FINISHED_LOADING(state, data) {
        state.cryptoInterceptorPeersLoading = false;
    },

    //PEERS set data
    CRYPTOINTERCEPTOR_PEERS_SET_DATA(state,data)
    {
        state.cryptoInterceptorPeersData = data
    },
     CRYPTOINTERCEPTOR_PEER_SET_DATA(state, data) {
         let targetNode = state.cryptoInterceptorPeersData.data.find(x => x.ip === data.ip && x.port === data.port)
         let targetNodeIndex = state.cryptoInterceptorPeersData.data.indexOf(targetNode)
         if (targetNode)
         {
             let updatedNode = {...targetNode, ...data.data.data[0]}
             Vue.set(state.cryptoInterceptorPeersData.data, targetNodeIndex, updatedNode)
         }
    },

    CRYPTOINTERCEPTOR_PEER_SET_STATE(state, data) {
        if (state.cryptoInterceptorPeersData)
        {
            let targetNode = state.cryptoInterceptorPeersData.data.find(x => {
                if(data.port) {
                    return x.ip === data.ip && x.port === data.port
                }

                return x.ip === data.ip
            })
            if (targetNode)
            {
                Vue.set(targetNode,"status", data.status)
            }
        }
    },

    // ------------ FILES ------------
    //LOADING FILES
    CRYPTOINTERCEPTOR_PEER_FILES_LOADING(state, data) {
        if (state.cryptoInterceptorPeerFilesLoading.hasOwnProperty(data.ip)) {
            state.cryptoInterceptorPeerFilesLoading[data.ip].token.cancel()
        }
        Vue.set(state.cryptoInterceptorPeerFilesLoading, data.ip, {token: axios.CancelToken.source()})
    },
    CRYPTOINTERCEPTOR_PEER_FILES_FINISHED_LOADING(state, data) {
        Vue.delete(state.cryptoInterceptorPeerFilesLoading, data.ip)
    },
    //SET FILES
    CRYPTOINTERCEPTOR_PEER_FILES_SET_DATA(state, data) {
        Vue.set(state.cryptoInterceptorPeerFilesData, data.ip, data.data)
    },
    //DELETE FILES
    CRYPTOINTERCEPTOR_PEER_FILES_DELETE_FILE(state, data) {
        let file = state.cryptoInterceptorPeerFilesData[data.ip].data.find(x => x.filename === data.filename)
        let index = state.cryptoInterceptorPeerFilesData[data.ip].data.indexOf(file)
        if (index > 0)
        {
            Vue.delete(state.cryptoInterceptorPeerFilesData[data.ip].data, index)
        }
    },

    CRYPTOINTERCEPTOR_FILES_DELETE_FILE(state, data) {
        let file = state.cryptoInterceptorFilesData.data.find(x => x.filename === data.filename)
        let index = state.cryptoInterceptorFilesData.data.indexOf(file)
        if (index > 0)
        {
            Vue.delete(state.cryptoInterceptorFilesData.data, index)
        }
    },

    CRYPTOINTERCEPTOR_FILES_SET_DATA(state, data) {
        state.cryptoInterceptorFilesData = data
    },
    CRYPTOINTERCEPTOR_FILES_LOADING(state) {
        if(state.cryptoInterceptorFilesCancelToken) {
            state.cryptoInterceptorFilesCancelToken.cancel()
        }
        state.cryptoInterceptorFilesCancelToken = axios.CancelToken.source()
        state.cryptoInterceptorFilesLoading = true
    },
    CRYPTOINTERCEPTOR_FILES_FINISHED_LOADING(state) {
        state.cryptoInterceptorFilesLoading = false
    },


    //PEER POLLING
    CRYPTOINTERCEPTOR_PEER_ADD_POLLING(state, data) {
        Vue.set(state.cryptoInterceptorPeersStatusPolling, `${data.ip}:${data.port}`, {token: axios.CancelToken.source()})
    },

    CRYPTOINTERCEPTOR_PEER_REMOVE_POLLING(state, data) {
        Vue.delete(state.cryptoInterceptorPeersStatusPolling, `${data.ip}:${data.port}`)
    },

    CRYPTOINTERCEPTOR_REFETCH_DATA(state,data)
    {
        state.cryptoInterceptorReFetchData = data
    },

    CRYPTOINTERCEPTOR_REFETCH_FILES(state,data)
    {
        state.cryptoInterceptorReFetchFiles = data
    }

}

const actions = {
    async getCryptoInterceptorPeersData({commit, dispatch}, {currency, filter, pagination}) {

        commit("CRYPTOINTERCEPTOR_PEERS_LOADING")
        try {
            const result = await apiClient.GET(`${currency}/cryptointerceptor/nodes`, {
                params: {
                    ...pagination,
                    ...filter
                },
                cancelToken: state.cryptoInterceptorCancelToken.token
            })
            commit("CRYPTOINTERCEPTOR_PEERS_SET_DATA", result)
            commit("CRYPTOINTERCEPTOR_PEERS_FINISHED_LOADING",)
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch('error', error.userFriendlyMessage)
                commit("CRYPTOINTERCEPTOR_PEERS_FINISHED_LOADING")
            }
        }
    },

    async setCryptoInterceptorPeerStatus({commit, dispatch}, {currency, ipaddress, action, port}) {

        if (action === "start") {
            commit("CRYPTOINTERCEPTOR_PEER_SET_STATE", {ip: ipaddress, status: "STARTING", port: port})
        } else {
            commit("CRYPTOINTERCEPTOR_PEER_SET_STATE", {ip: ipaddress, status: "STOPPING", port: port})
        }

        const addressWithPort = `${ipaddress}:${port}`

        //cancel polling
        if (state.cryptoInterceptorPeersStatusPolling[addressWithPort])
        {
            // cancel pending request
            state.cryptoInterceptorPeersStatusPolling[addressWithPort].token.cancel();

            // remove polling altogether
            commit("CRYPTOINTERCEPTOR_PEER_REMOVE_POLLING", {ip: ipaddress, port: port})
        }

        try {
            const result = await apiClient.PUT(`${currency}/cryptointerceptor/nodes/${ipaddress}/${action}`, {
                port: port
            })

            if (action === "start") {
                commit("CRYPTOINTERCEPTOR_PEER_SET_STATE", {ip: ipaddress, port: port, status: "CONNECTING"})
            } else {
                commit("CRYPTOINTERCEPTOR_PEER_SET_STATE", {ip: ipaddress, port: port, status: "STOPPED"})
            }

        } catch (error) {
            commit("CRYPTOINTERCEPTOR_PEER_SET_STATE", {ip: ipaddress, port: port, status: "FAILED"})
            dispatch('error', error.userFriendlyMessage)
        }
    },

    async getCryptoInterceptorFiles({commit, dispatch}, {currency, filter}) {
        commit("CRYPTOINTERCEPTOR_FILES_LOADING")
        try {
            const result = await apiClient.GET(`${currency}/cryptointerceptor/pcaps`, {
                params: {
                    ...filter
                },
                cancelToken: state.cryptoInterceptorFilesCancelToken.token
            },)
            commit("CRYPTOINTERCEPTOR_FILES_SET_DATA", result)
            commit("CRYPTOINTERCEPTOR_FILES_FINISHED_LOADING")
        } catch (error)
        {
            if(axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                commit("CRYPTOINTERCEPTOR_FILES_FINISHED_LOADING")
                dispatch('error', error.userFriendlyMessage)
            }
        }
    },

    async getCryptoInterceptorPeerFiles({commit, dispatch}, {currency, filter}) {
        let ip = filter.filterIps[0]
        commit("CRYPTOINTERCEPTOR_PEER_FILES_LOADING", {ip: ip})
        try {
            const result = await apiClient.GET(`${currency}/cryptointerceptor/pcaps`, {
                params: {
                    ...filter
                },
                cancelToken: state.cryptoInterceptorPeerFilesLoading[ip].token.token
            },)
            commit("CRYPTOINTERCEPTOR_PEER_FILES_SET_DATA", {ip: ip, data: result})
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
                return
            } else
            {
                //dispatch('error', error.userFriendlyMessage)
            }

        }
        commit("CRYPTOINTERCEPTOR_PEER_FILES_FINISHED_LOADING", {ip: ip})
    },


    //POLLING
    async startPollCryptoInterceptorPeerStatus({commit, dispatch}, {currency, ipaddress, port}) {
        //If polling for an IP is not started, start it
        if (!state.cryptoInterceptorPeersStatusPolling[`${ipaddress}:${port}`]) {
            commit("CRYPTOINTERCEPTOR_PEER_ADD_POLLING", {ip: ipaddress, port: port})
            dispatch("poolCryptoInterceptorPeerStatus", {currency, ipaddress, port})
        }
    },

    async poolCryptoInterceptorPeerStatus({commit, dispatch}, {currency, ipaddress, port}) {
        const addressWithPort = `${ipaddress}:${port}`

        //If the polling is still enabled
        if (state.cryptoInterceptorPeersStatusPolling[addressWithPort]) {

            //create new cancel token
            commit("CRYPTOINTERCEPTOR_PEER_ADD_POLLING",{ip: ipaddress, port: port})
            let myToken = state.cryptoInterceptorPeersStatusPolling[addressWithPort]

            try {
                const result = await apiClient.GET(`${currency}/cryptointerceptor/nodes`, {
                    params: {
                        filterIps: [ipaddress],
                        filterPort: port,
                        itemsPerPage: -1
                    },
                    cancelToken: state.cryptoInterceptorPeersStatusPolling[addressWithPort].token.token
                })
                commit("CRYPTOINTERCEPTOR_PEER_SET_DATA", {ip: ipaddress, port: port, data: result})
            } catch (error) {
                if (axios.isCancel(error)) {
                    //request is canceled

                } else
                {
                    //dispatch('error', error.userFriendlyMessage)
                }
            }

            if (["CONNECTING"].includes(state.cryptoInterceptorPeersData?.data?.find(x => {
                if (port) {
                    return x.ip === ipaddress && x.port === port
                }

                return x.ip === ipaddress
            }).status)) {
                await new Promise(r => setTimeout(r, 2000));
                if (myToken === state.cryptoInterceptorPeersStatusPolling[addressWithPort])
                {
                    dispatch("poolCryptoInterceptorPeerStatus", {currency, ipaddress, port})
                }
            } else {
                commit("CRYPTOINTERCEPTOR_PEER_REMOVE_POLLING", {ip: ipaddress, port: port})
            }
        }
    },

    async deleteCryptoInterceptorPeersFile({commit, dispatch},{currency, filename})
    {
        try
        {
            await apiClient.DELETE(`${currency}/cryptointerceptor/pcaps?filterFilenames[]=${filename}`)
        } catch (e) {
            //error
            return
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
};
