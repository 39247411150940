<script setup>

import {useCategoriesFilter} from "@/components/Filters/Composables/useCategoriesFilter";
import {computed, nextTick, onUnmounted, ref, watch} from "vue";
import store from '@/store'
import CategoryPicker from "@/components/common/CategoryPicker.vue";

const props = defineProps({
    //Visible Label
    label: {
        type: String,
        required: false,
    },
    //Defines modelled name
    name: {
        type: String,
        required: true,
    },
    componentFilterBase: {
        type: Object,
        required: true
    },
    explicitApply: {
        type: Boolean,
        required: false,
        default: false
    },
    additionalOptions: {
        type: Array,
        required: false,
        default: undefined
    },
    value: {
        type: Object,
        required: false
    }
})

const emit = defineEmits(['input', 'on-remove-filter-event'])
const {
    filterValue,
    filterValueAny,
    categoryFilterValueToReadable,
    categoryFilterValueConverter,
    resetValues
} = useCategoriesFilter()
const filterMenuRef = ref(null)
const filterActive = ref(false)

const valueWatchable = computed(() => props.value)
watch(valueWatchable, () => {
    if(valueWatchable.value && JSON.stringify(valueWatchable.value.values) !== JSON.stringify(filterValue.value)) {
        filterValue.value = valueWatchable.value?.values
        filterValueAny.value = valueWatchable.value.op === 'A'
    }
}, { immediate: true })

store.dispatch('loadCategories')
const allCategories = computed(() => store.getters.categories)

const emitInputValue = () => {
    nextTick(() => {
        filterMenuRef.value.closeMenu()
        const valueToEmit = categoryFilterValueConverter(filterValue, filterValueAny)
        filterActive.value = !!valueToEmit
        emit('input', valueToEmit)
    })
}

if (!props.explicitApply) {
    watch([filterValue, filterValueAny], () => emit('input', categoryFilterValueConverter(filterValue, filterValueAny)), {
        deep: true,
        immediate: true
    })
}

onUnmounted(() => emit('input', undefined))
</script>

<template>
    <component
        ref="filterMenuRef"
        :is="props.componentFilterBase"
        @on-clear-event="resetValues()"
        @on-remove-filter-event="emit('on-remove-filter-event', props.label)"
        :value="props.explicitApply ? filterActive : categoryFilterValueToReadable(filterValue, filterValueAny)"
    >
        <template #label>
            {{ props.label }}
        </template>
        <template #icon>
            mdi-format-list-bulleted-type
        </template>
        <template #menuContent>
            <div>
                <v-list dense style="padding: 0">
                    <v-list-item-group
                        color="indigo"
                        v-model="filterValueAny">
                        <v-list-item :value="true">
                            <template v-slot:default="{ active }">
                                <v-list-item-action class="mr-3">
                                    <v-checkbox :input-value="active"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Any
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </div>
            <div class="mx-1 my-1" style="max-width: 250px" @keydown.enter.exact.prevent="emitInputValue">
                <categoryPicker
                    v-model="filterValue"
                    dense
                    clear-icon="mdi-close-circle"
                    :disabled="filterValueAny"
                    :placeholder="'0 selected'"
                    selection-text
                    chips
                    autofocus
                    clearable
                    dense2
                    :categories="allCategories">
                </categoryPicker>
            </div>
            <div v-if="props.explicitApply" class="d-flex" style="padding: 4px 8px 8px 8px; margin-top: -6px">
                <v-btn
                    dark
                    color="grey"
                    small
                    @click="resetValues(); emitInputValue()"
                >
                    <v-icon left small>
                        mdi-close-circle
                    </v-icon>
                    CLEAR
                </v-btn>
                <v-spacer/>
                <v-btn
                    dark
                    color="indigo"
                    small
                    @click="emitInputValue"
                >
                    <v-icon left small>
                        mdi-filter-check
                    </v-icon>
                    Apply
                </v-btn>
            </div>
        </template>
    </component>
</template>

<style>


</style>