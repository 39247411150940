<script setup>
import {useAttrs, useListeners} from "vue";
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

const emit = defineEmits(['click'])
const attrs = useAttrs()
const listeners = useListeners()

const props = defineProps({
    text: {
        type: String,
        required: false
    }
})

</script>

<template>
    <BaseDataModifyButton class="ma-1" color="info" small v-bind="attrs" v-on="listeners">
        <v-icon left>
            edit
        </v-icon>
        Edit {{ text }}
    </BaseDataModifyButton>
</template>