<template>
    <v-container class="px-4" fluid>
        <v-layout align-center>
            <h1>Owners</h1>
            <v-spacer/>
            <OwnerDetailModal @save-event="createOwner">
                <template #showbutton="{on}">
                    <CreateButton v-on="on" text="Add new Owner"/>
                </template>
            </OwnerDetailModal>
        </v-layout>
        <v-divider/>
        <v-divider/>

        <v-data-table
            :headers="headers"
            :items="computedOwners"
            :options.sync="filter"
            :loading="isOwnersLoading"
            :server-items-length="owners.total ? owners.total : 0"
            :footer-props="{
                'items-per-page-options': [10, 30, 50, 100],
                'show-current-page': true,
                'showFirstLastPage': true,
            }"
            class="elevation-0 border my-2"
            dense
        >
            <template
                v-for="h in headers"
                v-slot:[`header.${h.value}`]="{ header }"
            >
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'name'"
                    append-icon="search"
                    :label="header.text"
                    single-line
                    hide-details
                    clearable
                    @click.stop.native
                    @input="debounceInput"
                    v-model="debouncedFilter.filterName"
                    class="text-field-label-style"
                />

                <div :key="h.value" v-if="h.value ==='neo_cluster_count'">
                    <h3>{{ h.text }}</h3>
                </div>

                <div :key="h.value" v-if="h.value ==='cospent_cluster_count'">
                    <h3>{{ h.text }}</h3>
                </div>

                <div :key="h.value" v-if="h.value ==='custom_cluster_count'">
                    <h3>{{ h.text }}</h3>
                </div>

                <div :key="h.value" v-if="h.value === 'created_at'">
                    <DateTimePicker
                        v-model="filter.filterCreatedAt"
                        :label="h.text"
                        :disabledTime="true"
                    />
                </div>
                <div :key="h.value" v-if="h.value === 'action'" style="font-size: 14px">{{ h.text }}</div>
            </template>

            <template #item="{ item }">
                <tr>
                    <td class="text-left fontMonospace">
                        <span>{{ item.name }}</span>
                    </td>
                    <td>
                        <div class="d-flex justify-center text-right">
                            <div style="min-width: 80px">{{ item.neo_cluster_count | formatNumber }}</div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-center text-right">
                            <div style="min-width: 120px">{{ item.cospent_cluster_count  | formatNumber }}</div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-center text-right">
                            <div style="min-width: 110px">{{ item.custom_cluster_count  | formatNumber }}</div>
                        </div>
                    </td>
                    <td style="text-align: center;">
                        <span>{{ item.created_at | formatDateTime }}</span>
                    </td>
                    <td style="text-align: center; vertical-align: middle; min-width: 92px">
                        <OwnerDetailModal
                            editButtonTooltip="Edit Owner"
                            @save-event="editOwner"
                            :ownerDetail="item"
                        >
                            <template #showbutton="{on}">
                                <TableEditButton v-on="on" editButtonTooltip="Edit Owner"/>
                            </template>
                        </OwnerDetailModal>
                        <TableDeleteModalDialog
                            width="40%"
                            :description="`Do you really want to delete this owner?`"
                            :itemToDelete="item"
                            @delete-event="deleteOwner"
                            tooltip="Delete Owner"
                        />
                    </td>
                </tr>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                <span>
                    {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                </span>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex"
import {TableEditButton, TableDeleteModalDialog, CreateButton} from "@/components/common"
import OwnerDetailModal from "@/components/ClusterMetadata/OwnerDetailModal"
import debounce from "debounce";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import DateTimePicker from "@/components/common/DateTimePicker";

export default {
    name: 'OwnersView',
    mixins: [routeSyncMixin],
    components: {
        TableEditButton,
        TableDeleteModalDialog,
        CreateButton,
        OwnerDetailModal,
        DateTimePicker
    },
    computed: {
        ...mapGetters([
            "owners",
            "isOwnersLoading",
        ]),
        computedOwners() {
            return this.owners?.data?.map(owner => {
                return {
                    ...owner,
                    cospent_cluster_count: (owner.neo_cluster_count - owner.custom_cluster_count) >= 0 ? (owner.neo_cluster_count - owner.custom_cluster_count) : "NEEDS SYNC"
                }
            })
        }
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                sortBy: {type: "ArrayOfStrings", default: () => ['created_at']},
                sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
                filterName: {type: "String", default: () => ''},
                filterCreatedAt: {type: "Number", default: () => undefined},
            },
            debouncedFilter: {
                filterName: undefined,
            },
            headers: [
                {
                    text: 'Owner',
                    align: 'center',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: '# All Clusters',
                    align: 'center',
                    sortable: false,
                    value: 'neo_cluster_count',
                },
                {
                    text: '# Co-Spent Clusters',
                    align: 'center',
                    sortable: false,
                    value: 'cospent_cluster_count',
                },
                {
                    text: '# Custom Clusters',
                    align: 'center',
                    sortable: false,
                    value: 'custom_cluster_count',
                },
                {
                    text: 'Created',
                    align: 'center',
                    sortable: true,
                    value: 'created_at',
                    width: 230
                },
                {
                    text: 'Action',
                    value: 'action',
                    align: 'center',
                    sortable: false
                }
            ],
        }
    },
    methods: {
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),
        refresh() {
            this.$store.dispatch('loadOwners', {pagination: this.filter})
        },
        async createOwner({ownerDetail}) {
            await this.$store.dispatch("createOwner", {ownerDetail})
            this.refresh()
        },
        async editOwner({ownerDetail}) {
            await this.$store.dispatch("editOwner", {ownerDetail})
            this.refresh()
        },
        async deleteOwner({itemToDelete}) {
            await this.$store.dispatch("deleteOwner", {ownerId: itemToDelete.id})
            this.refresh()
        }
    }
}
</script>

<style>
</style>