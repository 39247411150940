import axios from "axios"
import router from '../../router'
import {safeRouterPush} from "../../utils"
import apiClient from "@/services/apiClient"

const state = {
    searchResultsQuery: '',
    searchText: '',
    results: false,
    loading: false,
    cancelToken: null
}

const getters = {
    searchText: (state) => state.searchText,
    searchResults: (state) => state.results,
    searchLoading: (state) => state.loading,
    searchResultsQuery: (state) => state.searchResultsQuery
}

const mutations = {
    START_SEARCH(state) {
        state.loading = true
        if (state.cancelToken) {
            state.cancelToken.cancel()
        }
        state.cancelToken = axios.CancelToken.source()
    },
    STORE_RESULTS(state, {results, query}) {
        state.results = results
        state.searchResultsQuery = query
        state.loading = false
    },
    SEARCH_RESULTS_LOADING_FINISHED(state) {
        state.loading = false
    },
    UPDATE_SEARCH_STRING(state, searchText) {
        state.searchText = searchText
    }
}

const actions = {
    async search({commit}, searchText) {

        if (!searchText) {
            return
        }

        if (searchText.length === 0) {
            return
        }

        //todo FE refactor this hydra
        //one day patric, one day
        commit('START_SEARCH');

        function buildUrl(currency, useMultilineSearch, searchText) {
            let requestUrl = "";
            if (currency && !useMultilineSearch) {
                requestUrl += `${currency}/`;
            }
            requestUrl += !(currency && !useMultilineSearch)
                ? "search"
                : "searchOne/" + searchText;

            return requestUrl;
        }

        const useMultilineSearch = searchText.split('\n').filter(x => x !== "").length > 1
        const {currency} = router.currentRoute.params;
        const requestUrl = buildUrl(currency, useMultilineSearch, searchText)
        const axiosRequestConfig = {
            withCredentials: true,
            cancelToken: this.state.Search.cancelToken.token,
            ...(!(currency && !useMultilineSearch) && {params: {'addrs': searchText}})
        };
        try {
            let result
            if (`${requestUrl}_${axiosRequestConfig.params?.addrs}` !== this.state.Search.searchResultsQuery) {
                result = await apiClient.GET(requestUrl, axiosRequestConfig);
            } else {
                result = this.state.Search.results
            }

            if ((typeof result?.redirect) === 'string') {
                safeRouterPush(router, result.redirect);
            } else if (result?.redirect) {
                result = null
            }

            //todo if server return redirect redirect to page, otherwise load data
            commit('STORE_RESULTS', {results: result, query: `${requestUrl}_${axiosRequestConfig.params?.addrs}`})
            commit("SEARCH_RESULTS_LOADING_FINISHED")
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                this.dispatch('error', error.userFriendlyMessage)
                console.log(error)
                commit("SEARCH_RESULTS_LOADING_FINISHED")
            }
        }
    },
    updateSearchString({commit}, str) {
        commit('UPDATE_SEARCH_STRING', str)
    },
    async advancedSearch({}, body) {
        try {
            return await apiClient.POST("search", body, {SharpApi: true})
        } catch (error) {
            if (axios.isCancel(error)) {
                // request is canceled, do nothing
            } else {
                throw error
            }
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}