import { render, staticRenderFns } from "./ClusterBottomDrawer.vue?vue&type=template&id=70f218cb&scoped=true&"
import script from "./ClusterBottomDrawer.vue?vue&type=script&setup=true&lang=js&"
export * from "./ClusterBottomDrawer.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f218cb",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAppBar,VBtn,VContainer,VIcon,VLayout,VSpacer,VTab,VTabs,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70f218cb')) {
      api.createRecord('70f218cb', component.options)
    } else {
      api.reload('70f218cb', component.options)
    }
    module.hot.accept("./ClusterBottomDrawer.vue?vue&type=template&id=70f218cb&scoped=true&", function () {
      api.rerender('70f218cb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/Cluster/BottomDrawer/ClusterBottomDrawer.vue"
export default component.exports