var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-space-between my-1",
      style: "height: " + _vm.height,
    },
    [
      _c(
        "div",
        {
          class: [_vm.nameFlex ? "nameFlex" : "noFlex", "d-flex align-center"],
          style: _vm.nameFlex ? "" : "width: " + _vm.nameWidth + "px",
        },
        [_c("div", [_vm._t("name")], 2)]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ml-2 d-flex align-center",
          staticStyle: { "min-width": "0" },
        },
        [
          _c(
            "div",
            {
              class: { textOverflowEllipsis: !_vm.allowOverflow },
              staticStyle: { color: "#000000de" },
            },
            [!_vm.loading ? _vm._t("value") : _vm._e()],
            2
          ),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "div",
                { style: ["min-width: 0"] },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "skeletonLoader",
                    attrs: {
                      "max-width": "100%",
                      width: _vm.loadingWidth,
                      "min-width": "0",
                      loading: "",
                      type: "list-item",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }