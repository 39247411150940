var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { staticStyle: { height: "auto" } },
    [
      _vm.isLoading || !_vm.transaction
        ? _c(
            "v-layout",
            {
              staticStyle: { "overflow-x": "hidden", height: "200px" },
              attrs: { "align-center": "", "justify-center": "" },
            },
            [
              _c("ProgressCircular", {
                staticClass: "pt-6",
                attrs: { size: 50, width: 4, color: _vm.currency.unit },
              }),
            ],
            1
          )
        : _c(
            "v-layout",
            {
              staticStyle: { position: "absolute", width: "100%" },
              attrs: { column: "" },
            },
            [
              _c(
                "v-app-bar",
                {
                  staticStyle: { position: "sticky", "z-index": "1", top: "0" },
                  attrs: { color: "white", dense: "" },
                },
                [
                  _c("EntityHeaderLabel", {
                    attrs: {
                      justify: "center",
                      label: _vm.currency.unit.toUpperCase() + " TRANSACTION:",
                      entity: _vm.transaction.txhash,
                    },
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "text-decoration-none",
                      attrs: {
                        href:
                          "/" +
                          _vm.currency.unit +
                          "/transaction/" +
                          _vm.$route.params.detailTransaction +
                          "/",
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            color: "rgba(0, 0, 0, 0.7)",
                            icon: "",
                            small: "",
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticStyle: { "font-size": "20px" } },
                            [_vm._v("open_in_new")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isOnGraphPage
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-1",
                                            attrs: {
                                              disabled: "",
                                              color: "rgba(0, 0, 0, 0.7)",
                                              icon: "",
                                              small: "",
                                            },
                                            on: { click: _vm.removeGraphNode },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticStyle: {
                                                  "font-size": "20px",
                                                },
                                              },
                                              [_vm._v("delete")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2363347968
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v("This view is read-only. Save this"),
                            _c("br"),
                            _vm._v(" configuration if you want to edit it."),
                          ]),
                        ]
                      )
                    : _vm.isOnGraphPage
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            color: "rgba(0, 0, 0, 0.7)",
                            icon: "",
                            small: "",
                          },
                          on: { click: _vm.removeGraphNode },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticStyle: { "font-size": "20px" } },
                            [_vm._v("delete")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      attrs: {
                        color: "rgba(0, 0, 0, 0.7)",
                        icon: "",
                        small: "",
                      },
                      on: { click: _vm.graphCloseBottomDrawer },
                    },
                    [
                      _c("v-icon", { staticStyle: { "font-size": "20px" } }, [
                        _vm._v("close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("TransactionDetail", {
                staticClass: "mt-10",
                attrs: { currency: _vm.currency, transaction: _vm.transaction },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }