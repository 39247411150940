<script setup>
import UseGEStore from "@/views/GraphExplorer/Composables/UseGEStore";

const {GESelectedElement, GETransactions} = UseGEStore()
</script>

<template>
    <div>
        <v-container class="px-7 mt-0 pt-1" fluid>
            <v-row>
                <v-col cols="6">
                    <span class="text-h5">Addresses</span>
                    <v-data-table
                        :headers="[{text: 'Address', value: 'item', align: 'center'}]"
                        :height="352"
                        :items="GESelectedElement.data.typeSpecific.addresses.map(x => ({item: x}))"
                        :items-per-page="10"
                        dense
                        fixed-header
                        no-data-text="No addresses in this group"
                    >
                        <template #item="{ item }">
                            <tr>
                                <td class="text-center fontMonospace">
                                    {{ item.item }}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="6">
                    <span class="text-h5">Transactions</span>
                    <v-data-table
                        :headers="[{text: 'Transaction ID', value: 'item', align: 'center'}]"
                        :height="352"
                        :items="GESelectedElement.data.typeSpecific.transactions.map(x => ({item: x}))"
                        :items-per-page="10"
                        dense
                        fixed-header
                        no-data-text="No addresses in this group"
                    >
                        <template #item="{ item }">
                            <tr>
                                <td class="text-center fontMonospace">
                                    {{ item.item }}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style scoped>

</style>