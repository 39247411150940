import { render, staticRenderFns } from "./CategoryDetailModal.vue?vue&type=template&id=67538fab&"
import script from "./CategoryDetailModal.vue?vue&type=script&lang=js&"
export * from "./CategoryDetailModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VColorPicker,VMenu,VRadio,VRadioGroup,VRow,VSheet,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67538fab')) {
      api.createRecord('67538fab', component.options)
    } else {
      api.reload('67538fab', component.options)
    }
    module.hot.accept("./CategoryDetailModal.vue?vue&type=template&id=67538fab&", function () {
      api.rerender('67538fab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/AddressMetadata/CategoryDetailModal.vue"
export default component.exports