<template>
    <BaseModalDialog
        :loading="loading"
        :title="isEditModal ? 'Edit case' : 'Create new case'"
        @load-modal-state-event="loadState"
        width="auto"
        loading-width="400"
    >
        <template #showbutton="{ on }">
            <slot :on="on" name="showbutton"/>
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-row class="ma-2 align-center">
                    <v-col cols="5">
                        <v-text-field-validateable
                            v-model="detail.title"
                            label="Title"
                            outlined
                            dense
                            rules="required"
                        />
                    </v-col>
                    <v-col class="d-flex align-center" cols="5">
                        <DateTimePicker
                            v-model="detail.reported_at"
                            :supportsSeconds="true"
                            label="Reported at"
                            outlined
                            dense
                        />
                    </v-col>
                    <v-col cols="2" class="d-flex flex-column align-end">
                        <IsHiddenCheckboxWithIcon v-model="detail.is_hidden"/>
                        <div class="d-flex">
                            <v-checkbox color="tertiary" dense v-model="detail.is_warning" class="mt-0 mb-n4">
                                <template #label>
                                    <v-tooltip open-delay="1000" bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                :color="detail.is_warning ? 'warning' : 'primary'"
                                                dark
                                                size="28"
                                                v-on="on"
                                            >{{ detail.is_warning ? "report" : "report_off" }}
                                            </v-icon>
                                        </template>
                                        <span>Case {{
                                                detail.is_warning ? 'warns' : 'does not warn'
                                            }} creator</span>
                                    </v-tooltip>
                                </template>
                            </v-checkbox>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="ma-2">
                    <v-col cols="12">
                        <v-textarea-validateable
                            v-model="detail.description"
                            label="Description"
                            no-resize
                            outlined
                            rows="4"
                            rules="required"
                        />
                    </v-col>
                </v-row>
            </ValidationObserver>
        </template>
        <template #footer="{close, setDisabled}">
            <v-btn
                :disabled="casesModalState === modalState.Pending"
                color="error"
                dark
                outlined
                @click="close">
                Cancel
            </v-btn>
            <v-spacer/>
            <v-btn
                :loading="casesModalState === modalState.Pending"
                color="success"
                dark
                outlined
                @click="save(close, setDisabled)"

            >{{ isEditModal ? "Save case" : "Create case" }}
            </v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import {deepClone, delay, safeRouterPush} from "@/utils"
import {
    BaseModalDialog,
} from "@/components/common"
import {mapGetters} from "vuex";
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import {modalState} from "@/components/common/types/modal";
import IsHiddenCheckboxWithIcon from "@/components/common/IsHiddenCheckboxWithIcon.vue";

export default {
    name: "CaseDetailModal",
    components: {
        IsHiddenCheckboxWithIcon,
        DateTimePicker,
        BaseModalDialog,
    },
    props: {
        caseDetail: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            detail: undefined,
            isEditModal: true,
            datepicker: false,
            closeDialog: null,
            setDisabled: null
        }
    },
    computed: {
        ...mapGetters(['casesModalState']),
        modalState() {
            return modalState
        },
    },
    watch: {
        filesToCreate(newValue, oldValue) {
            if (Array.isArray(newValue)) {
                newValue.forEach(file => {
                    if (file instanceof File) {
                        this.detail.files.push({
                            id: `${file.lastModified}${file.size}`,
                            filename: file.name,
                            isNew: true,
                            blob: file
                        })
                        this.filesToCreate = []
                    }
                })
            }
        },
        async casesModalState(newVal) {
            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.closeDialog = null
                this.setDisabled(false)
                this.setDisabled = null
                await delay(0)
                this.$store.commit('CASES_MODAL_SET_STATE', modalState.Initial)
            } else if (newVal === modalState.Error) {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                await delay(0)
                this.$store.commit('CASES_MODAL_SET_STATE', modalState.Initial)
            }
        }
    },
    methods: {
        async handleCreateCase() {
            const caseId = await this.$store.dispatch("createCase", {
                caseDetail: this.detail
            })

            if (caseId) {
                safeRouterPush(this.$router, {name: "CaseDetail", params: {caseId: caseId}})
            }
        },
        async handleUpdateCase() {
            await this.$store.dispatch("updateCase", {
                updatedCase: {
                    ...this.detail,
                    id: this.detail.id
                }
            })
        },
        async save(close, setDisabled) {
            const isValid = await this.$refs.validator.validate()
            if (isValid) {
                this.closeDialog = close
                this.setDisabled = setDisabled
                setDisabled(true)

                if (this.isEditModal) {
                    await this.handleUpdateCase()
                } else {
                    await this.handleCreateCase()
                }

                this.$emit("save-event")
            }
        },
        loadState() {
            if (this.caseDetail) {
                this.isEditModal = true
                this.detail = {
                    ...deepClone(this.caseDetail),
                    reported_at: this.$moment(this.caseDetail.reported_at).unix()
                }
            } else {
                this.isEditModal = false
                this.detail = {
                    title: "",
                    created_at: "",
                    description: "",
                    is_hidden: false,
                    is_warning: false,
                }
            }
        }
    }
}
</script>

<style scoped>
.td-container {
    margin-top: 8px;
}
</style>