<script setup>
import {computed, provide} from "vue";
import ProgressCircular from "@/components/common/ProgressCircular.vue";
import {DataState} from "@/components/common/types/data";

const props = defineProps({
    name: {
        type: String,
        required: false,
        default: 'data'
    },
    data: {
        required: false,
    },
    dataState: {
        type: Number,
        required: false,
    },
    ignoreLoading: {
        type: Boolean,
        required: false,
        default: false
    }

})

const _data = computed(() => props.data)
provide(props.name, _data)

const _loading = computed(() => props.dataState === DataState.Loading || props.dataState === DataState.Initial)
provide('Loading', _loading)

</script>

<template>
    <div v-if="dataState && !ignoreLoading">
        <progress-circular v-if="dataState === DataState.Loading"/>
        <slot v-else-if="dataState === DataState.Loaded"/>
    </div>
    <div v-else>
        <slot/>
    </div>
</template>

<style scoped>

</style>