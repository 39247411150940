<template>
    <v-row class="ma-0">
        <v-col class="pa-0" style="position: absolute; left: 0">
            <AddNewNodeModal @add-node-event="addNewGraphNode" ref="AddNewNodeModal"/>
            <GraphConfigurationDetailModal
                @save-graph-config-event="createGraphConfiguration"
                ref="GraphConfigurationDetailModal"
            />
            <GraphVisualization
                ref="graph"
                :config="config"
                :loading="graphLoading"
                :elements="elements"
                :nameOfExportedGraph="nameOfExportedGraph"
                @graph-click-event="graphNodeClick"
                @before-destroy-event="cleanUpGraphData"
            >
                <template #actionbuttons class="graph-action-modals">
                    <AddNewNodeButton @click="$refs.AddNewNodeModal.openModal()" :color="currency.unit"/>
                    <SaveButton :color="currency.unit" @click="$refs.GraphConfigurationDetailModal.openModal()"/>
                </template>
            </GraphVisualization>
            <router-view/>
        </v-col>
        <v-col cols="2" class="graph-panel px-0 pt-0" style="position: fixed; right: 0">
            <GraphHistoryPanel
                :graphIsLoading="graphLoading"
                :graphData="graphData"
                @change-graph-configuration-event="changeGraphConfiguration"
                @delete-graph-configuration-event="deleteGraphConfiguration"
                @update-graph-configuration-event="updateGraphConfiguration"
            />
        </v-col>
    </v-row>
</template>

<script>
import {createAddressOrClusterOrTransactionEndpoint, buildStringTemplate, safeRouterPush} from "@/utils"

import GraphVisualization from "@/components/network/GraphVisualization"
import GraphHistoryPanel from "@/components/network/GraphHistoryPanel"
import BottomDrawer from "@/components/network/BottomDrawer"

import AddNewNodeButton from "@/components/common/Buttons/Fab/AddNewNodeButton"
import SaveButton from "@/components/common/Buttons/Fab/SaveButton"

import AddNewNodeModal from "@/components/network/AddNewNodeModal"
import GraphConfigurationDetailModal from "@/components/network/GraphConfigurationDetailModal"

import {mapGetters} from "vuex"

export default {
    name: "GraphSingleView",
    components: {
        GraphHistoryPanel,
        GraphVisualization,
        BottomDrawer,
        AddNewNodeButton,
        SaveButton,
        GraphConfigurationDetailModal,
        AddNewNodeModal
    },
    data() {
        return {
            showModal: false,
        }
    },
    computed: {
        ...mapGetters(["currency", "selectedGraphConfiguration", "graphLoading", "graphData"]),
        nameOfExportedGraph() {
            return `Graph-${this.$store.getters.graphData?.name}`
        },
        elements() {
            return this.selectedGraphConfiguration?.json_config?.graph?.elements
        },
        config() {
            return this.selectedGraphConfiguration?.json_config?.graph
        }
    },
    watch: {
        "$route": {
            immediate: true,
            handler(to, from) {
                if (Number(from?.params?.graphId) !== Number(to.params.graphId)) {
                    this.refresh({
                        graphId: to.params.graphId,
                        graphConfigId: parseInt(to.params.graphConfigId),
                        transactionNetworkIdToAppend: to.query.transactionNetworkIdToAppend
                    })
                }

                if (this.graphData && Number(from?.params.graphConfigId) !== Number(to?.params.graphConfigId)) {
                    this.$store.dispatch("changeGraphConfig", {graphConfigId: Number(to.params.graphConfigId)})
                }
            }
        },
        selectedGraphConfiguration: {
            handler(newValue, oldValue) {
                if (newValue && newValue?.id != this.$route.params.graphConfigId) {
                    if (!oldValue) {
                        // Replaces route without graphConfigId with route that contains it.
                        this.$router.replace({params: {graphConfigId: newValue.id}})
                    } else {
                        this.$router.push({params: {graphConfigId: newValue.id}})
                    }
                }
            }
        }
    },
    methods: {
        changeGraphConfiguration({graphConfigId}) {
            if (graphConfigId !== this.$route.params.graphConfigId) {
                this.$router.push({params: {graphConfigId}})
                this.$store.dispatch("changeGraphConfig", {graphConfigId})
            }
        },
        async deleteGraphConfiguration({graphConfigId, graphId}) {
            await this.$store.dispatch("deleteGraphConfiguration", {graphId, graphConfigId})
            this.refresh({graphId})
        },
        async updateGraphConfiguration({graphConfigurationDetail}) {
            await this.$store.dispatch("updateGraphConfiguration", {graphConfigurationDetail})
            this.refresh({graphId: graphConfigurationDetail.belongs_to, graphConfigId: graphConfigurationDetail.id})
        },
        createGraphConfiguration({graphConfigurationDetail}) {
            let graphJson = this.$refs.graph.cytoscapeInstanceFunc(i => i.json())

            graphJson.elements.nodes?.forEach(x => x.classes = x.classes.replace("selected", ""))

            this.$store.dispatch("createGraphConfiguration", {
                graphId: this.graphData.id,
                graphJson,
                description: graphConfigurationDetail.description,
                is_hidden: graphConfigurationDetail.is_hidden
            })
        },
        addNewGraphNode({detail}) {
            this.$store.dispatch('addNewGraphNode', {
                graphNodeConfiguration: {
                    ...detail,
                    graphId: this.$route.params.graphId,
                    graphConfigId: this.$route.params.graphConfigId
                }
            })
        },
        graphNodeClick(event) {
            const requestBaseUrl = buildStringTemplate({
                template: "/${currency}/graph/${graphId}/config/${graphConfigId}/node/${nodeId}",
                values: {
                    currency: this.$route.params.currency,
                    graphId: this.$route.params.graphId,
                    graphConfigId: this.$route.params.graphConfigId,
                    nodeId: event.target.data('hash'),
                }
            })
            const requestUrl = createAddressOrClusterOrTransactionEndpoint({event, requestBaseUrl})
            safeRouterPush(this.$router, requestUrl)
        },
        cleanUpGraphData() {
            this.$store.dispatch('cleanUpGraph')
        },
        refresh({graphId, graphConfigId, transactionNetworkIdToAppend}) {
            this.cleanUpGraphData()
            this.$store.dispatch("loadGraph", {
                graphId,
                graphConfigId,
                transactionNetworkIdToAppend,
                currency: this.currency.unit
            })
        }
    }
}
</script>

<style scoped>
.graph-action-modals {
    z-index: 25;
}

.graph-panel {
    position: relative;
}
</style>