var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseModalDialog, {
    ref: "modalRef",
    attrs: { title: "Merge with other clusters", width: "80vh" },
    scopedSlots: _vm._u([
      {
        key: "showbutton",
        fn: function ({ on: dialogOn }) {
          return [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            _setup.MergeButton,
                            _vm._g(
                              {
                                attrs: {
                                  disabled: _vm.disabled,
                                  icon: _vm.icon,
                                  color: "info",
                                },
                              },
                              { ...dialogOn, ...on }
                            )
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.disabled
                        ? "Create a custom cluster first!"
                        : "Merge this cluster with other clusters"
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]
        },
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "v-row",
              { staticClass: "mt-2" },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("div", [
                      _vm._v(
                        "\n                    Estimated total addresses: " +
                          _vm._s(
                            _vm._f("formatNumber")(
                              _setup.addressCount + _setup.localClusterCount
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(_setup.ClusterPickerWithDisplay, {
                      attrs: { currencyUnit: _setup.currencyUnit, table: "" },
                      model: {
                        value: _setup.selectedClusters,
                        callback: function ($$v) {
                          _setup.selectedClusters = $$v
                        },
                        expression: "selectedClusters",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function ({ close, setDisabled, disabled }) {
          return [
            _c(
              "v-btn",
              {
                attrs: { disabled: disabled, color: "error" },
                on: { click: _setup.onClose },
              },
              [_vm._v("\n            Cancel\n        ")]
            ),
            _vm._v(" "),
            _c("v-spacer"),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: {
                  disabled: _setup.selectedClusters.length === 0 || disabled,
                  color: "info",
                },
                on: {
                  click: function ($event) {
                    return _setup.onSave(close, setDisabled)
                  },
                },
              },
              [_vm._v("\n            Merge\n        ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }