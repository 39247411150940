<script setup>
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

defineProps({
    color: {
        type: String,
        required: true
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
})

</script>

<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <BaseDataModifyButton
                v-bind="$attrs"
                v-on="{...on,...$listeners}"
                dark
                fab
                small
                :color="color + ' darken-1'"
            >
                <v-icon>save</v-icon>
            </BaseDataModifyButton>
        </template>
        <span>Save graph configuration</span>
    </v-tooltip>
</template>
