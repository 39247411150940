var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "autocomplete", style: { "margin-top": _vm.marginTop } },
    [
      _c("v-autocomplete", {
        attrs: {
          autofocus: _vm.autofocus,
          dense: !_vm.noDense,
          disabled: _vm.disabled,
          error: _setup.isError,
          items: _setup.foundClusters,
          label: _setup.computedLabel,
          loading: _setup.loading,
          multiple: _vm.multiple,
          outlined: _vm.outlined,
          "persistent-hint": true,
          "search-input": _setup.searchString,
          value: _vm.value,
          "no-data-text": _setup.loading
            ? "Loading..."
            : "No matching clusters found.",
          "clear-icon": "mdi-close-circle",
          clearable: "",
          hint: "Enter cluster or address",
          "item-value": "entity_id",
          "no-filter": "",
          "prepend-icon": "mdi-database-search",
          "return-object": "",
        },
        on: {
          "update:searchInput": function ($event) {
            _setup.searchString = $event
          },
          "update:search-input": function ($event) {
            _setup.searchString = $event
          },
          blur: () => {
            if (!_vm.value) {
              _setup.searchString = null
            }
          },
          input: _setup.emitValue,
          "click:clear": function ($event) {
            _setup.searchString
              ? (_setup.searchString = null)
              : _setup.search(null)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        staticClass: "fontMonospace",
                        domProps: { textContent: _vm._s(item.entity_id) },
                      }),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              item.type === "custom" ? "custom" : "co-spent"
                            ) +
                              " |\n                    " +
                              _vm._s(
                                _vm._f("formatNumber")(item.addresses_count)
                              ) +
                              " "
                          ),
                        ]),
                        _vm._v(" Addresses\n                "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            !_vm.multiple
              ? {
                  key: "selection",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-chip",
                        { attrs: { small: "" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "my-1",
                              attrs: { size: "18", left: "" },
                            },
                            [_vm._v("mdi-hub")]
                          ),
                          _vm._v(" "),
                          _c("b", [_vm._v(_vm._s(item.entity_id))]),
                          _vm._v(" ("),
                          _c("i", [_vm._v(_vm._s(item.type))]),
                          _vm._v(
                            ", " +
                              _vm._s(
                                _vm._f("formatNumber")(item.addresses_count)
                              ) +
                              "\n                Addresses)\n            "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "caption mt-n1 ml-1" }, [
      _vm._v("Search by cluster's ID "),
      _c("span", { staticClass: "font-weight-light" }, [_vm._v("or")]),
      _vm._v(" complete\n        address.\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }