/** @description Run safe router.push with catching error (router.push return Promise). If is route same do nothing
 * @param {router} router The radius of the circle.
 * @param {string} redirectToRoute route to redirect.
 * @return {void}
 */
import * as moment from "moment";
import iso3166 from "@/utils/iso3166";
import store from "@/store"

const copy = async (string: string): Promise<boolean> => {
    try {
        await navigator.clipboard.writeText(string)
    } catch (e) {
        try {
            const el = document.createElement('textarea');
            el.addEventListener('focusin', e => e.stopPropagation());
            el.value = string;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        } catch (e) {
            await store.dispatch("error", "Failed to copy.")
            return false
        }
    }

    await store.dispatch("copy", "Copied to clipboard!")
    return true
}


function safeRouterPush(router, redirectToRoute) {
    if (redirectToRoute !== router.currentRoute.fullPath) {
        router.push(redirectToRoute).catch(error => {
            if (error.name !== "NavigationDuplicated") {
                console.error(error.name)
                throw error
            }
        })
    }
}

function ArrayForEachAsync(array, fn, maxTimePerChunk, context) {
    context = context || window;
    maxTimePerChunk = maxTimePerChunk || 200;
    var index = 0;

    function now() {
        return new Date().getTime();
    }

    return new Promise((resolve, reject) => {
        function doChunk() {
            var startTime = now();
            while (index < array.length && (now() - startTime) <= maxTimePerChunk) {
                // callback called with args (value, index, array)
                fn.call(context, array[index], index, array);
                ++index;
            }
            if (index < array.length) {
                // set Timeout for async iteration
                setTimeout(doChunk, 1);
            } else {
                resolve(array.length); // Resolve the Promise when the entire array is processed
            }
        }

        doChunk();
    });
}


const delay = ms => new Promise(res => setTimeout(res, ms));

const isObject = (value) => typeof value === 'object' && value !== null

const getHashCode = (input: string): number => {
    let hash = 0,
        i, chr;
    if (input.length === 0) return hash;
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

function getCountry(code, mode) {
    switch (mode) {
        case "alpha-2":
            let country
            country = iso3166.find(x => x["alpha-2"] === code)
            if (country === undefined) {
                country = {
                    "countryName": "Unknown",
                    "alpha-2": "Unknown",
                    "alpha-3": "Unknown",
                    "country-code": "Unknown",
                    "iso_3166-2": "Unknown",
                    "region": "Unknown",
                    "sub-region": "Unknown",
                    "intermediate-region": "Unknown",
                    "region-code": "Unknown",
                    "sub-region-code": "Unknown",
                    "intermediate-region-code": "Unknown"
                }
            }
            return country
    }
}

function removeDuplicatesFromArray<T>(array: Array<T>, selector): Array<T> {
    return array.filter((thing, index, self) =>
        index === self.findIndex((t) => (
            selector(t) === selector(thing)
        )));
}

function buildStringTemplate({template, values}: { template: string, values: any }): string {
    return new Function(...Object.keys(values), `return \`${template}\`;`)(...Object.values(values))
}

//todo FE move this functionality to other reasonble place
function createAddressOrClusterOrTransactionEndpoint({requestBaseUrl, event}) {
    if (event.target.hasClass('address')) {
        return requestBaseUrl + '/address/' + event.target.data('hash');
    } else if (event.target.hasClass('cluster')) {
        return requestBaseUrl + '/cluster/' + event.target.data('hash')
    } else if (event.target.hasClass('transaction')) {
        return requestBaseUrl + '/transaction/' + event.target.data('hash')
    }
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function deepClone<T>(objectoToClone: T): T {
    return JSON.parse(JSON.stringify(objectoToClone))
}

function timeDifference(date) {
    if (typeof (date) === "number") {
        return moment.unix(date).fromNow()
    }
    return moment(date).fromNow()
}

function humanTime(timestamp) {

    // Convert to a positive integer
    let time = Math.abs(timestamp);

    // Define humanTime and units
    let humanTime, units;

    // If there are years
    if (time > (1000 * 60 * 60 * 24 * 365)) {
        humanTime = parseInt(String(time / (1000 * 60 * 60 * 24 * 365)), 10);
        units = 'year';
    }

    // If there are months
    else if (time > (1000 * 60 * 60 * 24 * 30)) {
        humanTime = parseInt(String(time / (1000 * 60 * 60 * 24 * 30)), 10);
        units = 'month';
    }

    // If there are weeks
    else if (time > (1000 * 60 * 60 * 24 * 7)) {
        humanTime = parseInt(String(time / (1000 * 60 * 60 * 24 * 7)), 10);
        units = 'week';
    }

    // If there are days
    else if (time > (1000 * 60 * 60 * 24)) {
        humanTime = parseInt(String(time / (1000 * 60 * 60 * 24)), 10);
        units = 'day';
    }

    // If there are hours
    else if (time > (1000 * 60 * 60)) {
        humanTime = parseInt(String(time / (1000 * 60 * 60)), 10);
        units = 'hour';
    }

    // If there are minutes
    else if (time > (1000 * 60)) {
        humanTime = parseInt(String(time / (1000 * 60)), 10);
        units = 'minute';
    }

    // Otherwise, use seconds
    else {
        humanTime = parseInt(String(time / (1000)), 10);
        units = 'second';
    }

    return humanTime + ' ' + (humanTime === 1 ? units : (units + 's'))
}

function getRouteFromFundsToInteractions(curRoute): String {
    let startOfFunds = curRoute.path.lastIndexOf("funds")
    let startOfFundsSelectedCluster = curRoute.path.lastIndexOf("funds/")
    if (startOfFunds === -1) {
        return ""
    }
    if (startOfFunds === startOfFundsSelectedCluster) {
        //přepisuji funds/xy (třeba na AddressView)
        return curRoute.path.slice(0, startOfFunds) + "interactions/" + curRoute.path.slice(startOfFunds + "funds/".length)
    } else {
        //přepisuji funds (třeba na ClusterView)
        return curRoute.path.slice(0, startOfFunds) + "interactions"
    }
}

function isOnSplitView(curRoute): boolean {
    return (
        curRoute.matched.findIndex(
            (route) =>
                route.name === "GraphSingleView" ||
                route.name === "TransactionNetwork" ||
                route.name === "FundsNetwork" ||
                route.name === "FundsNetworkCluster"
        ) !== -1
    );
}

// https://gist.github.com/jed/982883
// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
function uuidv4(): string {
    return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}

function orderArrayById<T extends { id: number }>(array: Array<T>): Array<T> {
    const clonnedArray = deepClone(array) as Array<T>
    clonnedArray.sort((a, b) => a.id - b.id)
    return clonnedArray;
}

function isObjectEmpty(obj) {
    for (let i in obj) return false
    return true
}

function computeQueryFromObject(obj) {
    let query = ""
    for (const [key, value] of Object.entries(obj)) {

        if (value === null)
            continue;

        if (Array.isArray(value)) {
            value.forEach(x => {
                query += `${key}[]=` + x
                query += "&"
            })
            query = query.slice(0, -1);
        } else {
            if (value === '' || value === undefined)
                continue;

            query += `${key}=${value}`
        }
        query += "&"
    }
    query = query.slice(0, -1);
    return query
}

function getCategoryOfEntities(entities) {
    // ETH inputs/outputs have a value of 0, so initial max_val has to be lower than 0
    let max_val = -1
    let cat = []

    entities.forEach(e => {
        if (e.value > max_val && e.categories.length > 0) {
            max_val = e.value
            cat = e.categories
        }
    })
    return rankCategories(cat)
}

function rankCategories(categoriesIn) {

    if (!(categoriesIn.length > 0))
        return null

    let categories = deepClone(categoriesIn)
    categories.sort((a, b) => {
        return (a.id < b.id) ? 1 : -1
    })
    return categories[0]
}

const compareInputOutput = (currentAddress, a, b) => {
    const firstEl = -1
    const secondEl = 1
    if (a.addr === currentAddress) return firstEl
    if (b.addr === currentAddress) return secondEl
    if (a.identities.length > b.identities.length) return firstEl
    if (b.identities.length > a.identities.length) return secondEl
    if (a.owners.length > b.owners.length) return firstEl
    if (b.owners.length > a.owners.length) return secondEl
    if (a.categories.length > b.categories.length) return firstEl
    if (b.categories.length > a.categories.length) return secondEl
    return b.value - a.value
}

const sortInputsOutputs = (tx, currentAddress) => {
    tx.inputs = tx.inputs.sort(compareInputOutput.bind(this, currentAddress))
    tx.outputs = tx.outputs.sort(compareInputOutput.bind(this, currentAddress))
}

function getCategoriesOfEntities(entities) {
    let categories = []
    entities.forEach(entity => {
        if (entity.categories && entity.categories.length > 0) {
            entity.categories.forEach(category => {
                let foundCategory = false
                categories.forEach(cat => {
                    if (category.id === cat.id) {
                        foundCategory = true
                    }
                })
                if (foundCategory === false) {
                    categories.push(category)
                }
            })
        }
    })
    return categories
}

function getAddressBalanceAndDirectionForTransaction(addressId, tx) {
    let asInput
    let asOutput
    let balance = 0
    tx.inputs.forEach(i => {
        if (i.addr === addressId) {
            asInput = true
            balance += i.value
        }
    })

    tx.outputs.forEach(o => {
        if (o.addr === addressId) {
            asOutput = true
            balance -= o.value
        }
    })
    return {balance, asInput, asOutput}
}

function sortCategories(categories) {
    return categories.sort((a, b) => {
        if (a.id === 3 && b.id === 3) {
            return false
        }
        if (a.id === 3) {
            return false
        }
        if (b.id === 3) {
            return true
        }
        return a.id > b.id

    })
}

function processTx(tx, currentAddress) {
    // compute address balance relative to this transaction and count of inputs and outputs

    if (tx.processed)
        return tx

    tx.inputs = tx.inputs.filter(x => x.isAddress || x.coinbase)
    tx.outputs = tx.outputs.filter(x => x.isAddress || x.coinbase)

    if (currentAddress) {
        tx.byAddress = currentAddress
        let {addressInputBalance, inputsCount} = tx.inputs.reduce((res, item) => {
            res.addressInputBalance += item.addr === currentAddress ? item.value : 0
            res.inputsCount++
            return res
        }, {addressInputBalance: 0, inputsCount: 0})

        let {addressOutputBalance, outputsCount} = tx.outputs.reduce((res, item) => {
            res.addressOutputBalance += item.addr === currentAddress ? item.value : 0
            res.outputsCount++
            return res
        }, {addressOutputBalance: 0, outputsCount: 0})

        tx.currentAddressOutputBalance = addressOutputBalance
        tx.currentAddressInputBalance = addressInputBalance
        tx.currentAddressBalance = addressInputBalance - addressOutputBalance
        tx.inputsCount = inputsCount
        tx.outputsCount = outputsCount
    } else {
        tx.inputsCount = tx.inputs.length
        tx.outputsCount = tx.outputs.length
    }

    let identityIds = new Set()
    let casesIds = new Set()
    let ownersIds = new Set()
    let categoryIds = new Set()
    //Count identities/cases/owners/categories
    tx.inputs.concat(tx.outputs).forEach(x => {
        if (x.isAddress) {
            x.identities.forEach(y => {
                identityIds.add(y.id)
            })
            x.cases.forEach(y => {
                casesIds.add(y.id)
            })
            x.owners.forEach(y => {
                ownersIds.add(y.id)
            })
            x.categories.forEach(y => {
                categoryIds.add(y.id)
            })
        }
    })

    let identityCount = identityIds.size
    let casesCount = casesIds.size
    let ownersCount = ownersIds.size
    let categoriesCount = categoryIds.size

    if (currentAddress) {
        if (!tx.inputs.some(x => x.addr === currentAddress))
            tx.category = getCategoryOfEntities(tx.inputs.filter(addr => addr.addr !== currentAddress))

        if (!tx.category && (!tx.outputs.some(x => x.addr === currentAddress)))
            tx.category = getCategoryOfEntities(tx.outputs.filter(addr => addr.addr !== currentAddress))
    }
    if (!tx.category)
        tx.category = getCategoryOfEntities(tx.inputs.concat(tx.outputs))

    tx.categories = getCategoriesOfEntities(tx.inputs.concat(tx.outputs))

    //Find change in output addresses (N^2)
    tx.outputs = tx.outputs.map(x => {
        if (x.addr) {
            let found = false
            tx.inputs.forEach(y => {
                if (x.addr === y.addr) {
                    found = true
                }
            })
            if (found)
                return {...x, is_change: true}
        }
        return x
    })

    tx.identityCount = identityCount
    tx.casesCount = casesCount
    tx.ownersCount = ownersCount
    tx.categoriesCount = categoriesCount
    //tx.category = null

    // group outputs and inputs by address
    let outputsMap = groupBy(tx.outputs, output => output.addr ? output.addr : "output" + output.n)
    let inputsMap = groupBy(tx.inputs, input => input.addr ? input.addr : "input" + input.n)

    // get actual address inputs and outputs and shift it to beginning
    let currentAddressInputs = inputsMap.get(currentAddress)
    let currentAddressOutputs = outputsMap.get(currentAddress)

    if (currentAddressOutputs !== undefined) {
        outputsMap.delete(currentAddress)
        tx.outputs = Array.from(outputsMap.values())
        tx.outputs.unshift(currentAddressOutputs)
    } else {
        tx.outputs = Array.from(outputsMap.values())
    }

    if (currentAddressInputs !== undefined) {
        inputsMap.delete(currentAddress)
        tx.inputs = Array.from(inputsMap.values())
        tx.inputs.unshift(currentAddressInputs)
    } else {
        tx.inputs = Array.from(inputsMap.values())
    }

    sortInputsOutputs(tx, currentAddress)

    tx.processed = true
    return tx
}

// group transactions output/inputs
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        // create new element in map if not exists
        if (!collection) {
            map.set(key, item);
        }
        // if there is already more inputs/outputs on the key, push another
        else if (Array.isArray(collection.inouts)) {
            collection.inouts.push(item);
            collection.value += parseFloat(item.value)
        }
        // there was only one item at this key. Make array from it!
        else {
            map.set(key, {
                value: parseFloat(item.value) + parseFloat(collection.value),
                addr: key,
                identity: item.identity,
                identities: item.identities,
                owner: item.owner,
                owners: item.owners,
                cases: item.cases,
                inouts: [collection, item],
                categories: item.categories,
                isGroup: true,
                is_change: item.is_change,
            })
        }
    });
    return map;
}

function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}

function getTruthyKeys(object) {
    return Object.keys(object).filter((key) => object[key])
}

function getWebSocketProtocol(): string {
    return window.location.protocol === "http:" ? "ws://" : "wss://"
}


export {
    copy,
    isObjectEmpty,
    safeRouterPush,
    removeDuplicatesFromArray,
    buildStringTemplate,
    createAddressOrClusterOrTransactionEndpoint,
    sleep,
    deepClone,
    uuidv4,
    orderArrayById,
    isOnSplitView,
    getRouteFromFundsToInteractions,
    timeDifference,
    computeQueryFromObject,
    getCountry,
    humanTime,
    processTx,
    delay,
    getHashCode,
    isObject,
    getAddressBalanceAndDirectionForTransaction,
    ArrayForEachAsync,
    sortCategories,
    flatten,
    getTruthyKeys,
    getWebSocketProtocol
}