import apiClient from "@/services/apiClient";
import router from "../../router";
import axios from "axios";

const state = {
    addressBalanceTree: [],
    addressBalanceTreeLoading: false,
    addressBalanceTreeCancelToken: null
};

const getters = {
    addressBalanceTreeLoading: (state) => state.addressBalanceTreeLoading,
    addressBalanceTree: (state) => state.addressBalanceTree,
};

const mutations = {
    ADDRESS_BALANCE_TREE_LOADED(state, data) {
        state.addressBalanceTree = data;
        state.addressBalanceTreeLoading = false;
    },
    ADDRESS_BALANCE_TREE_LOADING(state) {
        if(state.addressBalanceTreeCancelToken) {
            state.addressBalanceTreeCancelToken.cancel()
        }
        state.addressBalanceTreeCancelToken = axios.CancelToken.source()
        state.addressBalanceTreeLoading = true;
    },
    ADDRESS_BALANCE_TREE_LOADING_FINISHED(state) {
        state.addressBalanceTreeLoading = false;
    },
};

const actions = {
    async loadAddressBalanceTree({ commit, dispatch }, filter) {
        commit("ADDRESS_BALANCE_TREE_LOADING");
        try {
            const response = await apiClient.GET(
                `${router.currentRoute.params.currency}/cryptoaddress/${this.getters.addressId}/balances`,
                {
                    cancelToken: state.addressBalanceTreeCancelToken.token,
                    withCredentials: true,
                    params: { ...filter },
                }
            );
            commit("ADDRESS_BALANCE_TREE_LOADED", response.data);
            commit("ADDRESS_BALANCE_TREE_LOADING_FINISHED")
        } catch (error) {
            if(axios.isCancel(error)){
                //request is canceled, do nothing
            } else {
                dispatch("error", error.userFriendlyMessage)
                commit("ADDRESS_BALANCE_TREE_LOADING_FINISHED")
            }
        }
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
