var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.dataState && !_vm.ignoreLoading
    ? _c(
        "div",
        [
          _vm.dataState === _setup.DataState.Loading
            ? _c(_setup.ProgressCircular)
            : _vm.dataState === _setup.DataState.Loaded
            ? _vm._t("default")
            : _vm._e(),
        ],
        2
      )
    : _c("div", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }