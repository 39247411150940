<script setup>

import {computed, inject, ref} from "vue";
import {BaseModalDialog} from "@/components/common";
import ClusterPickerWithDisplay from "@/components/common/ClusterPickerWithDisplay.vue";
import store from "@/store";
import MergeButton from "@/components/common/Buttons/MergeButton.vue";

const data = inject("data")

const props = defineProps({
    icon: {
        type: Boolean,
        required: false,
        default: false
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    currencyUnit: {
        type: String,
        required: true
    }
})

const currencyUnit = computed(() => data.value.currencyUnit)
const cluster = computed(() => data.value.cluster)
const clusterName = computed(() => data.value.clusterName)

const emit = defineEmits(["action-finished-event"])
const selectedClusters = ref([])

const onSave = async (close, setDisabled) => {
    setDisabled(true)

    const wasMergeSuccess = await store.dispatch("mergeToCluster", {
        currency: currencyUnit.value,
        clusterToMergeTo: clusterName.value,
        clustersToMergeWith: selectedClusters.value.map(x => x.entity_id)
    })

    setDisabled(false)

    if (wasMergeSuccess) {
        emit("action-finished-event")
        selectedClusters.value = []
        close()
    }
}

const addressCount = computed(() => selectedClusters.value.reduce((a, b) => a + b.addresses_count, 0))
const localClusterCount = computed(() => (cluster.value.addresses_count ?? cluster.value.totalAddresses) ?? 0)

const modalRef = ref(null)
const onClose = () => {
    selectedClusters.value = []
    modalRef.value.close()
}
</script>

<template>
    <BaseModalDialog
        ref="modalRef"
        title="Merge with other clusters"
        width="80vh">
        <template #showbutton="{ on: dialogOn }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <MergeButton :disabled="disabled" :icon="icon" color="info" v-on="{ ...dialogOn, ...on}"/>
                </template>
                {{ disabled ? "Create a custom cluster first!" : "Merge this cluster with other clusters" }}
            </v-tooltip>
        </template>
        <template #content>
            <v-row class="mt-2">
                <v-col cols="12">
                    <div>
                        Estimated total addresses: {{ addressCount + localClusterCount | formatNumber }}
                    </div>
                    <ClusterPickerWithDisplay v-model="selectedClusters" :currencyUnit="currencyUnit" table/>
                </v-col>
            </v-row>
        </template>
        <template #footer="{close, setDisabled, disabled}">
            <v-btn :disabled="disabled" color="error" @click="onClose">
                Cancel
            </v-btn>
            <v-spacer/>
            <v-btn :disabled="selectedClusters.length === 0 || disabled" color="info"
                   @click="onSave(close, setDisabled)">
                Merge
            </v-btn>
        </template>
    </BaseModalDialog>
</template>
