var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g(
                  {
                    on: {
                      drop: function ($event) {
                        $event.preventDefault()
                        return _vm.assignFileToImportedCsvFile.apply(
                          null,
                          arguments
                        )
                      },
                      dragover: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  on
                ),
                [
                  _c("v-file-input", {
                    attrs: {
                      dense: "",
                      "prepend-icon": _vm.icon,
                      accept: ".csv",
                      label: _vm.label,
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.importedCsvFile,
                      callback: function ($$v) {
                        _vm.importedCsvFile = $$v
                      },
                      expression: "importedCsvFile",
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.tooltip))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }