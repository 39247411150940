var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-4" }, [
    _vm.addressInteractionsLoading
      ? _c(
          "div",
          [_c("progress-circular", { attrs: { color: _vm.currency.unit } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.addressInteractionsData && _vm.addressInteractionsData.transfers
      ? _c(
          "div",
          [
            _c("div", { staticClass: "d-flex justify-start" }, [
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(
                      "\n                    Cumulatively " +
                        _vm._s(_vm.filter.isOutgoing ? "Sent" : "Received") +
                        ":\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("fiat-convertor", {
                    attrs: {
                      fiats: _vm.addressInteractionsData.fiats,
                      value: Number(_vm.addressInteractionsData.totalValue),
                      monospace: "",
                      "unit-light": "",
                      "unit-small": "",
                      "with-unit": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("\n                    in\n                "),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    !_vm.isOnSplitView || _vm.$route.params.graphId
                      ? _c(
                          "span",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.createFundsLocation(
                                    {
                                      queryPaths: "all",
                                      queryType: 0,
                                      queryLimit: 100,
                                      localAddress: _vm.filter.isOutgoing
                                        ? _vm.clusterCurrent
                                        : _vm.filter.remote,
                                      remoteAddress: _vm.filter.isOutgoing
                                        ? _vm.filter.remote
                                        : _vm.clusterCurrent,
                                      directions: "both",
                                      includeClusters: true,
                                    },
                                    { maxHops: _vm.filter.maxHops }
                                  ),
                                },
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "d-inline-block mx-1 mt-n2",
                                                      staticStyle: {
                                                        "padding-left": "6px",
                                                        "padding-right": "6px",
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        outlined: "",
                                                        small: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "mr-1" },
                                                    [_vm._v("timeline")]
                                                  ),
                                                  _vm._v(
                                                    "\n                                 " +
                                                      _vm._s(
                                                        _vm
                                                          .addressInteractionsData
                                                          .transferCount
                                                      ) +
                                                      "\n                             "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1782095533
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Plot Graph")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "d-inline-block mx-1 mt-n2",
                                                  staticStyle: {
                                                    "padding-left": "6px",
                                                    "padding-right": "6px",
                                                  },
                                                  attrs: {
                                                    label: "",
                                                    outlined: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.fetchFunds({
                                                        queryPaths: "all",
                                                        queryType: 0,
                                                        queryLimit: 100,
                                                        localAddress: _vm.filter
                                                          .isOutgoing
                                                          ? _vm.clusterCurrent
                                                          : _vm.filter.remote,
                                                        remoteAddress: _vm
                                                          .filter.isOutgoing
                                                          ? _vm.filter.remote
                                                          : _vm.clusterCurrent,
                                                        maxHops:
                                                          _vm.filter.maxHops,
                                                        directions: "both",
                                                        includeClusters: true,
                                                      })
                                                    },
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [_vm._v("timeline")]
                                              ),
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.addressInteractionsData
                                                      .transferCount
                                                  ) +
                                                  "\n                "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4204995294
                                ),
                              },
                              [_vm._v(" "), _c("span", [_vm._v("Load Graph")])]
                            ),
                          ],
                          1
                        ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "title" }, [_vm._v("transfer(s)")]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "ml-7",
                attrs: { justify: "end", "no-gutters": "" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c("exportMenu", {
                      attrs: {
                        "base-url": `${_vm.currency.unit}/cryptocluster/${_vm.clusterCurrent}/interactions`,
                        query: _vm.computeQuery(),
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("fancy-card", {
                      attrs: { header: false, "no-gutters": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c("v-data-table", {
                                  staticClass: "elevation-0 mt-2",
                                  staticStyle: { "white-space": "nowrap" },
                                  attrs: {
                                    "footer-props": {
                                      "items-per-page-options": [30, 50, 200],
                                      "items-per-page-text":
                                        "Clusters per page",
                                      "show-current-page": true,
                                      showFirstLastPage: true,
                                    },
                                    headers: _vm.headers,
                                    items: _vm.computedInteractions,
                                    "items-per-page": 30,
                                    loading: _vm.addressInteractionsLoading,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm._l(_vm.headers, function (h) {
                                        return {
                                          key: `header.${h.value}`,
                                          fn: function ({ header }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: h.value,
                                                  staticClass:
                                                    "d-inline-flex align-center",
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(header.text) +
                                                        "\n                                    "
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  header.customFilter
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c("FilterIcon", {
                                                            attrs: {
                                                              additionalOptions:
                                                                header
                                                                  .customFilter
                                                                  .additionalOptions,
                                                              append:
                                                                header
                                                                  .customFilter
                                                                  .append,
                                                              componentFilterType:
                                                                header
                                                                  .customFilter
                                                                  .component,
                                                              time: header
                                                                .customFilter
                                                                .time,
                                                              name: header.value,
                                                              filterSubType:
                                                                header
                                                                  .customFilter
                                                                  .filterSubType,
                                                              "explicit-apply":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .resultsFilter[
                                                                  header
                                                                    .customFilter
                                                                    .override
                                                                    ? header
                                                                        .customFilter
                                                                        .override
                                                                    : header.value
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.resultsFilter,
                                                                    header
                                                                      .customFilter
                                                                      .override
                                                                      ? header
                                                                          .customFilter
                                                                          .override
                                                                      : header.value,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "resultsFilter[header.customFilter.override ? header.customFilter.override : header.value]",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          },
                                        }
                                      }),
                                      {
                                        key: "item",
                                        fn: function ({ item }) {
                                          return [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                _vm._l(
                                                  item.sourceAddresses,
                                                  function (address) {
                                                    return _c(
                                                      "div",
                                                      { key: address },
                                                      [
                                                        _c(
                                                          "RouteToAddress",
                                                          {
                                                            attrs: {
                                                              address: address,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "text" },
                                                              [
                                                                _c("Copyable", {
                                                                  attrs: {
                                                                    "on-copy":
                                                                      address,
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "text",
                                                                          fn: function ({
                                                                            on,
                                                                            attr,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "span",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "white-space":
                                                                                            "nowrap",
                                                                                          overflow:
                                                                                            "hidden",
                                                                                          "text-overflow":
                                                                                            "ellipsis",
                                                                                        },
                                                                                    },
                                                                                    "span",
                                                                                    attr,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "stringMiddleShortener"
                                                                                        )(
                                                                                          address
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                                             "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                _vm._l(
                                                  item.destinationAddresses,
                                                  function (address) {
                                                    return _c(
                                                      "div",
                                                      { key: address },
                                                      [
                                                        _c(
                                                          "RouteToAddress",
                                                          {
                                                            attrs: {
                                                              address: address,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "text" },
                                                              [
                                                                _c("Copyable", {
                                                                  attrs: {
                                                                    "on-copy":
                                                                      address,
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "text",
                                                                          fn: function ({
                                                                            on,
                                                                            attr,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "span",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "white-space":
                                                                                            "nowrap",
                                                                                          overflow:
                                                                                            "hidden",
                                                                                          "text-overflow":
                                                                                            "ellipsis",
                                                                                        },
                                                                                    },
                                                                                    "span",
                                                                                    attr,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "stringMiddleShortener"
                                                                                        )(
                                                                                          address
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                                             "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  _c("fiat-convertor", {
                                                    attrs: {
                                                      fiats:
                                                        _vm
                                                          .addressInteractionsData
                                                          .fiats,
                                                      value: item.value,
                                                      "with-unit": "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                !_vm.isOnSplitView ||
                                                _vm.$route.params.graphId
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            staticClass:
                                                              "text-decoration-none",
                                                            attrs: {
                                                              to: _vm.createFundsLocation(
                                                                {
                                                                  queryPaths:
                                                                    "all",
                                                                  queryType: 0,
                                                                  queryLimit: 100,
                                                                  txs: item.txs,
                                                                  localAddress:
                                                                    _vm.filter
                                                                      .isOutgoing
                                                                      ? _vm.clusterCurrent
                                                                      : _vm
                                                                          .filter
                                                                          .remote,
                                                                  remoteAddress:
                                                                    _vm.filter
                                                                      .isOutgoing
                                                                      ? _vm
                                                                          .filter
                                                                          .remote
                                                                      : _vm.clusterCurrent,
                                                                },
                                                                { maxHops: -1 }
                                                              ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  top: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "",
                                                                                        outlined:
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-1",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "timeline"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      item.hopCount
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Plot Graph"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.fetchFundsWithTxs(
                                                                                      {
                                                                                        localAddress:
                                                                                          _vm
                                                                                            .filter
                                                                                            .isOutgoing
                                                                                            ? _vm.clusterCurrent
                                                                                            : _vm
                                                                                                .filter
                                                                                                .remote,
                                                                                        remoteAddress:
                                                                                          _vm
                                                                                            .filter
                                                                                            .isOutgoing
                                                                                            ? _vm
                                                                                                .filter
                                                                                                .remote
                                                                                            : _vm.clusterCurrent,
                                                                                        txs: item.txs,
                                                                                      }
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "timeline"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                        " +
                                                                              _vm._s(
                                                                                item.hopCount
                                                                              ) +
                                                                              "\n                                                    "
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                "Load Graph"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                _vm._l(
                                                  item.txs,
                                                  function (taxes) {
                                                    return _c(
                                                      "div",
                                                      { key: taxes },
                                                      [
                                                        _c(
                                                          "RouteToTransaction",
                                                          {
                                                            attrs: {
                                                              transaction:
                                                                taxes,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "text" },
                                                              [
                                                                _c("Copyable", {
                                                                  attrs: {
                                                                    "on-copy":
                                                                      taxes,
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "text",
                                                                          fn: function ({
                                                                            on,
                                                                            attr,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "span",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "fontMonospace",
                                                                                    },
                                                                                    "span",
                                                                                    attr,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "stringMiddleShortener"
                                                                                        )(
                                                                                          taxes,
                                                                                          16
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                                            "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f("formatDateTime")(
                                                        item.firstBlocktime *
                                                          1000
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f("formatDateTime")(
                                                        item.lastBlocktime *
                                                          1000
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "footer.page-text",
                                        fn: function ({
                                          pageStart,
                                          pageStop,
                                          itemsLength,
                                        }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      pageStart
                                                    )
                                                  ) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      pageStop
                                                    )
                                                  ) +
                                                  " of " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      itemsLength
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2902210193
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }