<script setup>
import {computed} from "vue";
import EntityLabel from "@/components/common/EntityLabel.vue";
import store from "@/store";
import AddressInfo from "@/components/MnemonicConvertor/AddressInfo.vue";
import BlockchainCryptoPicker from "@/components/common/BlockchainCryptoPicker.vue";
import BipInfo from "@/components/MnemonicConvertor/BipInfo.vue";
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import DerivedAddressSummary from "@/components/MnemonicConvertor/DerivedAddressSummary.vue";
import DataTableVirtualScroll from "@/components/common/Table/DataTableVirtualScroll.vue";
import AddressDerivationOptionsCard from "@/components/MnemonicConvertor/AddressDerivationOptionsCard.vue";
import Copyable from "@/components/common/Copyable.vue";
import VTextareaValidateable from "@/components/common/ValidateableComponents/VTextAreaValidateable.vue";
import useMnemonicConvertor from "@/components/MnemonicConvertor/useMnemonicConvertor";

const currencies = computed(() => store.getters.currencies)
const {
    selectedCrypto,
    currenciesInfo,
    useAlternativeLtcExtendedKeyFormat,
    bipSpecs,
    selectedBip,
    selectedTab,
    isMnemonicValid,
    data,
    numberOfRowsToGenerate,
    startingIndex,
    numberOfAddressesYetToGenerate,
    validator,
    bchAddressFormat,
    mnemonic,
    useHardenedAddresses,
    warningDialog,
    mnemonicLanguages,
    selectedLanguage,
    selectedLanguageFormatted,
    callQueueAddressesToGenerate,
    dialogClose,
    clearTableGetNewKey,
    updateLtcExtendedKeyFormat,
    changeCurrency,
    addressTypeChange,
    updateRefAndReset
} = useMnemonicConvertor(currencies.value)

const headers = [
    { text: "Path", align: "left", value: "path" },
    { text: "Address", align: "left", value: "address" },
    { text: "Balance", align: "center", value: "balance" },
    { text: "Public Key", align: "left", value: "publicKey" },
    { text: "Private Key (WIF)", align: "left", value: "privateKey" }
]

</script>

<template>
    <v-container class="d-flex flex-column height100 px-4" style="gap: 8px" fluid>
        <div class="d-flex flex-wrap" style="gap: 8px">
            <div style="flex: 1">
                <h3 style="margin-top: 20px">Mnemonic Options</h3>
                <FancyCard style="min-width: 510px;" dense :header="false">
                    <template #body>
                        <div class="d-flex mb-1" style="gap: 8px">
                            <BlockchainCryptoPicker
                                style="max-width: 332px"
                                filled
                                dense
                                :outlined="false"
                                :currencies="currenciesInfo"
                                :value="selectedCrypto.unit"
                                @input="changeCurrency"
                            />
                            <v-select
                                filled
                                dense
                                @change="clearTableGetNewKey"
                                :items="mnemonicLanguages"
                                item-text="language"
                                v-model="selectedLanguage"
                                label="Mnemonic Language"
                            >
                                <template v-slot:selection="{ item }">
                                    <img class="mb-1 mr-1" :src="`../../../img/flags/${item.countryCode}.png`">
                                    <span>{{ item.language }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <img class="mb-1 mr-1" :src="`../../../img/flags/${item.countryCode}.png`">
                                    <span>{{ item.language }}</span>
                                </template>
                            </v-select>
                        </div>

                        <div v-if="selectedCrypto.unit === 'LTC'">
                            <v-checkbox
                                dense
                                v-model="useAlternativeLtcExtendedKeyFormat"
                                @change="updateLtcExtendedKeyFormat"
                                label="Use Ltub / Ltpv extended key format"
                                hide-details
                            />
                            <v-divider/>
                        </div>

                        <ValidationObserver ref="validator">
                            <VTextareaValidateable
                                :styles="'margin-top: 20px'"
                                label="Mnemonic Phrase"
                                rows="3"
                                dense
                                :placeholder="`Enter a mnemonic`"
                                @change="clearTableGetNewKey"
                                v-model="mnemonic"
                                :rules="`required|mnemonic:${selectedLanguageFormatted}`"
                                spellcheck="false"
                            >
                                <template #append>
                                    <v-tooltip v-if="!isMnemonicValid && mnemonic !== ''" bottom>
                                        <template #activator="{ on, attrs }">
                                            <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                color="#ff5252"
                                            >
                                                mdi-alert
                                            </v-icon>
                                        </template>
                                        <span>Invalid mnemonic phrase or language.</span>
                                    </v-tooltip>
                                </template>
                            </VTextareaValidateable>
                        </ValidationObserver>
                    </template>
                </FancyCard>
            </div>
            <div class="d-flex flex-column flex" style="max-width: 1000px">
                <v-tabs
                    light
                    :grow="false"
                    v-model="selectedTab"
                    @change="clearTableGetNewKey"
                    :color="selectedCrypto.hexColor"
                    class="flex-grow-0"
                >
                    <v-tab v-for="bip in bipSpecs" :key="bip.name" :disabled="bip.disabled()">{{ bip.name }}</v-tab>
                </v-tabs>

                <BipInfo
                    :selectedBipSpecs="bipSpecs[selectedBip]"
                    :selectedBip="selectedBip"
                    :bipSpecs="bipSpecs"
                    @parameterChange="clearTableGetNewKey"
                    @addressTypeChange="addressTypeChange"
                />
            </div>
        </div>

        <div class="d-flex flex-wrap" style="gap: 8px">
            <AddressDerivationOptionsCard
                :currency="selectedCrypto"
                v-bind:numberOfRowsToGenerate.sync="numberOfRowsToGenerate"
                v-bind:startingIndex.sync="startingIndex"
                v-bind:bchAddressFormat.sync="bchAddressFormat"
                @update:bchAddressFormat="clearTableGetNewKey"
                :useHardenedAddresses="useHardenedAddresses"
                @update:useHardenedAddresses="updateRefAndReset"
                :number-of-addresses-yet-to-generate="numberOfAddressesYetToGenerate"
                :selectedBip="selectedBip"
            />
            <DerivedAddressSummary
                :data="data"
                :selectedCrypto="selectedCrypto"
            />
        </div>

        <v-btn class="mb-1" style="max-height: 36px" block @click="callQueueAddressesToGenerate">Generate Rows</v-btn>
        <v-dialog
            v-model="warningDialog"
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Address amount
                </v-card-title>

                <v-card-text>
                    {{ `You're trying to generate ${numberOfRowsToGenerate} rows at once, are you sure you want to continue?` }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="red darken-1"
                        text
                        @click="dialogClose(false)"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogClose(true)"
                    >
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <DataTableVirtualScroll
            :data="data"
            :headers="headers"
            table-max-height="calc(100vh - 570px)"
        >
            <template #item="{ path, address, addressData, publicKey, privateKey }">
                <tr>
                    <td class="fontMonospace text-left">{{ path }}</td>
                    <td class="fontMonospace text-left">
                        <EntityLabel
                            :entity="address"
                            :isCopyable="true"
                            :actAsLink="addressData?.data?.data.confirmedTransactions > 0"
                            :currency="selectedCrypto.unit"
                        />
                    </td>
                    <td style="min-width: 220px">
                        <AddressInfo
                            :address="address"
                            :currency="selectedCrypto"
                            :addressData="addressData"
                        />
                    </td>
                    <td class="fontMonospace">
                        <Copyable :on-copy="publicKey" justify="left">
                            <template #text="{ on, attr }">
                                <span v-bind="attr" v-on="on">
                                {{ publicKey | stringMiddleShortener(30) }}
                                </span>
                            </template>
                        </Copyable>
                    </td>
                    <td class="fontMonospace">
                        <Copyable :on-copy="privateKey" justify="left">
                            <template #text="{ on, attr }">
                                <span v-bind="attr" v-on="on">
                                    {{ privateKey | stringMiddleShortener(30) }}
                                </span>
                            </template>
                        </Copyable>
                    </td>
                </tr>
            </template>
        </DataTableVirtualScroll>
    </v-container>
</template>

<style scoped>
</style>