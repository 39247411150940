<template>
    <v-navigation-drawer permanent right width="100%" style="height: calc(100vh - 68px);">
        <v-list-item-title v-if="graphIsLoading" class="title">
            <ProgressCircular class="py-12"/>
        </v-list-item-title>
        <v-list-item v-else>
            <v-list-item-content>
                <v-list-item-title class="title">
                    <div class="d-flex justify-space-between">
                        <div style="width: 100%" class="d-flex text-truncate">
                            <v-icon left>mdi-chart-timeline-variant</v-icon>
                            <div class="text-truncate">{{ graphData.name }}</div>
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon
                                        :color="graphData.is_hidden ? 'primary' : 'tertiary'"
                                        dark
                                        right
                                        v-on="on"
                                    >{{ graphData.is_hidden ? "visibility_off" : "visibility" }}
                                    </v-icon>
                                </template>
                                <span>Graph is {{
                                        graphData.is_hidden ? 'visible only to the creator.' : 'visible to all users.'
                                    }}</span>
                            </v-tooltip>
                        </div>
                        <GraphDetailModal
                            :currencies="currencies"
                            :graphDetail="graphData"
                            @save-event="editGraph"
                        >
                            <template #showbutton="{ on }">
                                <TableEditButton
                                    editButtonTooltip="Edit graph Parameters"
                                    tooltip-position="bottom"
                                    v-on="on"
                                />
                            </template>
                        </GraphDetailModal>
                    </div>
                </v-list-item-title>
                <div class="my-2">
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Description
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div style="white-space: pre-wrap">{{ graphData.description }}</div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                <v-list-item-subtitle>History</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-list v-if="!graphIsLoading">
            <template v-for="item in graphData && graphData.configs">
                <v-list-item
                    exact
                    :class="{ 'primary--text grey lighten-2': item.id === selectedGraphConfigurationId }"
                    @click="changeConfiguration({graphConfigId:item.id})"
                    :key="item.id"
                >
                    <template #default>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.description }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <span class="text--primary">{{ item.created_by.name }}</span>
                                - {{ item.created_at }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <GraphConfigurationDetailModal
                            @save-graph-config-event="editConfiguration"
                            :graphDetail="item"
                        >
                            <template #showbutton="{ on }">
                                <TableEditButton v-on="on" editButtonTooltip="Edit configuration"/>
                            </template>
                        </GraphConfigurationDetailModal>
                        <TableDeleteModalDialog
                            :description="`Do you really want to delete configuration ${item.description}`"
                            :itemToDelete="item"
                            tooltip="Delete configuration"
                            @delete-event="deleteConfiguration"
                        />
                    </template>
                </v-list-item>
                <v-divider :key="`divider-${item.id}`"/>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import ProgressCircular from "@/components/common/ProgressCircular"
import TableDeleteModalDialog from "@/components/common/TableComponents/TableDeleteModalDialog"
import TableEditButton from "@/components/common/TableComponents/TableEditButton"
import BackButton from "@/components/common/Buttons/Fab/BackButton"
import GraphConfigurationDetailModal from "@/components/network/GraphConfigurationDetailModal"
import GraphDetailModal from "@/components/network/GraphDetailModal.vue";
import {mapGetters} from "vuex";

export default {
    name: "GraphHistoryPanel",
    components: {
        GraphDetailModal,
        ProgressCircular,
        TableDeleteModalDialog,
        TableEditButton,
        GraphConfigurationDetailModal,
        BackButton
    },
    props: {
        graphData: Object,
        graphIsLoading: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters(["currencies", "currency", "selectedGraphConfiguration"]),
        selectedGraphConfigurationId() {
            return this.selectedGraphConfiguration?.id
        }
    },
    methods: {
        changeConfiguration({graphConfigId}) {
            this.$emit("change-graph-configuration-event", {graphConfigId})
        },
        deleteConfiguration({itemToDelete}) {
            this.$emit("delete-graph-configuration-event", {
                graphConfigId: itemToDelete.id,
                graphId: itemToDelete.belongs_to
            })
        },
        editConfiguration({graphConfigurationDetail}) {
            this.$emit("update-graph-configuration-event", {graphConfigurationDetail})
        },
        async editGraph({graphDetail}) {
            await this.$store.dispatch("editGraph", {graph: graphDetail})
            this.updateGraphDetail()
        },
        updateGraphDetail() {
            this.$store.dispatch("loadGraph", {graphId: this.graphData.id, currency: this.currency.unit})
        }
    }
}
</script>