var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-1 mx-3 d-flex flex-column justify-center align-center",
      staticStyle: { width: "75px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "body-2 font-weight-bold",
          style: "color: " + _vm.color,
        },
        [_vm._v("\n        " + _vm._s(_vm.name) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-1" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "", "open-delay": "500" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-progress-circular",
                        _vm._g(
                          {
                            attrs: {
                              size: 80,
                              width: 5,
                              value: "100",
                              color: _vm.color,
                            },
                          },
                          on
                        ),
                        [
                          _vm.value === 1
                            ? _c(
                                "div",
                                { staticClass: "font-weight-bold body-1" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.value * 100) +
                                      "%\n                    "
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-bold body-1 d-flex flex-column align-center justify-center",
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.value > -100000
                                            ? _vm.$humanize.formatNumber(
                                                _vm.value
                                              )
                                            : _vm.toNumeral(_vm.value)
                                        ) +
                                        "\n                        "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold body-2 mt-n1",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Blocks\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _vm.value === 1
                ? _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.name) +
                        " synchronized!\n            "
                    ),
                  ])
                : _c("span", [
                    _c("span", { staticClass: "fontMonospace" }, [
                      _vm._v(
                        _vm._s(_vm.$humanize.formatNumber(Math.abs(_vm.value)))
                      ),
                    ]),
                    _vm._v(" blocks "),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("behind"),
                    ]),
                    _vm._v(" HEADERS!\n            "),
                  ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }