<script setup>
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import DataGrid from "@/components/DataGrid/DataGrid.vue";
import {computed, inject, ref} from "vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {useRoute} from "vue-router/composables";
import DataGridBulkDelete from "@/components/DataGrid/Actions/Put/DataGridBulkDelete.vue";
import store from "@/store";
import FileBulkImport from "@/views/Case/Components/Files/FileBulkImport.vue";
import DataGridBulkAdd from "@/components/DataGrid/Actions/Put/DataGridBulkAdd.vue";
import FileNameDownloadable from "@/views/Case/Components/Files/FileNameDownloadable.vue";

const loading = inject("Loading")

const headers = [
    {text: 'Filename', value: 'filename', align: 'center'},
    {text: 'Size', value: 'size', align: 'center'},
    {text: 'MIME Type', value: 'mime', align: 'center'},
    {text: 'MD5', value: 'md5', align: 'center'},
    {text: 'SHA1', value: 'sha256', align: 'center'},
    {text: 'Created', value: 'created_at', align: 'center'}
]

const route = useRoute()
const caseId = computed(() => route.params.caseId)

const {dataState, getData, data} = useGetData("getCaseFiles", {
    pagination: {page: 1, itemsPerPage: 10},
    caseId: caseId.value
}, false)


const gridRef = ref(null)

const deleteItems = async (fileIdsArray) => {
    const failedItems = []

    const deleteFileCallbacksArray = fileIdsArray.map(async fileId => {
        const success = await store.dispatch("deleteFile", {fileId})
        if (!success) {
            failedItems.push(fileId)
        }
        return success
    })
    await Promise.all(deleteFileCallbacksArray)
    return failedItems
}

const caseDetail = inject("CaseDetail")

const uploadFiles = async (filesToUpload) => {
    const failedItems = []

    const uploadFileCallbacksArray = filesToUpload.map(file => {
        const success = store.dispatch("createFile", {
            fileMetadata: {
                case_id: caseDetail.value.id,
                file: file
            }
        })
        if (!success) {
            failedItems.push(file)
        }
        return success
    })

    await Promise.all(uploadFileCallbacksArray)
    return failedItems
}
const refreshItems = () => {
    gridRef.value.refreshItems(true)
}

const DataGridBulkAddModalRef = ref(null)
const FileBulkImportRef = ref(null)
const uploadInProgress = ref(false)

const onUpload = async () => {
    let items = FileBulkImportRef.value.itemsToAdd
    if (items.length === 0) {
        return
    }

    uploadInProgress.value = true
    DataGridBulkAddModalRef.value.setDisabled(true)

    const failedItems = await uploadFiles(items)

    FileBulkImportRef.value.itemsToAdd = failedItems
    DataGridBulkAddModalRef.value.setDisabled(false)
    if (failedItems.length === 0) {
        DataGridBulkAddModalRef.value.onClose()
    }
    uploadInProgress.value = false
    refreshItems(true)
}

defineExpose({refreshItems})
</script>

<template>
    <FancyCard class="cardShadow" dense>
        <template #header>
            <div class="d-flex align-center">
                <v-icon class="d-block mr-2 ml-1">file_copy</v-icon>
                <span class="font-weight-bold">Files</span>
            </div>
        </template>
        <template #body>
            <DataGrid
                ref="gridRef"
                bulk-operations
                :get-data="{dataState, getData, data}"
                :headers="headers"
                :no-data-text="`No files found`"
                item_id="id"
                :footer-props="{
                        'items-per-page-options': [10, 30, 50, -1],
                        'show-current-page': true,
                        'showFirstLastPage': true,
                    }"
                hide-footer-if-no-data
                hide-header-if-no-data
            >
                <template
                    #header-right="{ getItems, refreshItems, removeSelection, patchActions, isAnyItemSelected, isDataPatched }">
                    <DataGridBulkAdd title="Upload Files" :loading="uploadInProgress"
                                     save-button-text="Upload Files" @submit="onUpload"
                                     :addItemsButtonDisabled="FileBulkImportRef?.itemsToAdd.length === 0"
                                     ref="DataGridBulkAddModalRef"
                    >
                        <FileBulkImport ref="FileBulkImportRef"/>
                    </DataGridBulkAdd>
                    <DataGridBulkDelete :disabled="!isAnyItemSelected" :get-items="getItems"
                                        :item-callback-async="deleteItems"
                                        :patchActions="patchActions"
                                        @action-finished-event="(failedItems) => {refreshItems(); removeSelection(failedItems)}"/>
                </template>
                <template #item="{ item }">
                    <td class="text-left" style="max-width: 300px">
                        <FileNameDownloadable :item="item"/>
                    </td>
                    <td class="text-xs-right text-overflow">{{
                            item.size | fileSize
                        }}
                    </td>
                    <td class="text-xs-right text-overflow">{{ item.mime }}</td>
                    <td
                        class="text-xs-right text-overflow fontMonospace"
                    >{{ item.md5 | stringShortener(16) }}
                    </td>
                    <td
                        class="text-xs-right text-overflow fontMonospace"
                    >{{ item.sha256 | stringShortener(16) }}
                    </td>
                    <td
                        class="text-xs-right text-overflow"
                    >{{ item.created_at | formatDateTime }}
                    </td>
                </template>
            </DataGrid>
        </template>
    </FancyCard>
</template>