<script setup>
import DataGrid from "@/components/DataGrid/DataGrid.vue";
import {useGetDataStatic} from "@/components/common/Composables/useGetDataStatic";
import store from "@/store";
import {computed} from "vue";
import RouteToTransaction from "@/components/common/RouteToTransaction.vue";

const props = defineProps({
    data: {
        required: true
    }
})

const itemsPerPage = 10

const data = computed(() => props.data)
const getData = useGetDataStatic(data, { pagination: { page: 1, itemsPerPage: itemsPerPage } })

const headers = [
    {text: 'Channel ID', align: 'left', value: 'channel_id', sortable: false},
    {text: 'Transaction', align: 'left', value: 'channel_point', sortable: false},
    {text: 'Channel Capacity', align: 'right', value: 'capacity', sortable: false},
]

const currencyUnit = computed(() => store.getters.currency.unit)

</script>

<template>
    <v-card class="mt-2" outlined>
        <DataGrid
            :headers="headers"
            :get-data="getData"
            sort-desc
            :bulk-operations="false"
            :items-per-page="itemsPerPage"
            item_id="channel_id"
            computed-height
        >
            <template #item="{ item }">
                <td class="fontMonospace text-left">
                    <span>{{ item.channel_id }}</span>
                </td>
                <td class="fontMonospace text-left">
                    <RouteToTransaction :currency="currencyUnit" :transaction="item.channel_point.split(':')[0]">
                        <template #text>
                            <span>{{ item.channel_point.split(':')[0] }}</span>
                        </template>
                    </RouteToTransaction>
                </td>
                <td class="fontMonospace text-right">
                    <span class="fontMonospace">
                        {{ $humanize.formatNumber(item.capacity / 100000000, 8) }}
                    </span>
                    <span class="grey--text">{{ currencyUnit }}</span>
                </td>
            </template>
        </DataGrid>
    </v-card>
</template>

<style scoped>

</style>