import {openWebSocket, POST} from "@/services/apiClient";
import Vue from "vue";
import {delay} from "@/utils";

const state = {
    progressData: {},
    progressToken: null,
    progressWs: null,
    showBackgroundTasks: true
}

const getters = {
    showBackgroundTasks: (state) => state.showBackgroundTasks,
    runningTasksCount: (state) => Object.keys(state.progressData).filter(key => state.progressData[key].status === 'pending').length,
    progressData: (state) => state.progressData,
    progressWs: (state) => state.progressWs,
    emptyProgress: (state) => Object.keys(state.progressData).length === 0,
}

const mutations = {
    PROGRESS_LOADED(state, data) {
        Vue.set(state.progressData, data.endpointPath, data)
    },
    PROGRESS_REMOVE(state, key) {
        Vue.delete(state.progressData, key)
    },
    SHOW_BACKGROUND_TASKS(state, val) {
        state.showBackgroundTasks = val
    },
    SET_PROGRESS_TOKEN(state, token) {
        state.progressToken = token
    },
    SET_PROGRESS_WS(state, ws) {
        state.progressWs = ws
    },
}


const actions = {
    async connectToProgress({commit, dispatch}) {

        // Ensure the token is gotten
        await dispatch("getProgressToken")

        if (!this.state.Progress.progressToken) {
            await delay(10000)
            dispatch('connectToProgress')
            return
        }

        // Open WS, pass cb to attach hooks
        const ws = openWebSocket(`stream?auth_ott=${this.state.Progress.progressToken}`, {SharpApi: true})
        commit('SET_PROGRESS_WS', ws)

        ws.onmessage = (event) => {
            const progressUpdate = JSON.parse(event.data)
            commit('PROGRESS_LOADED', progressUpdate)
        }
        ws.onerror = () => {
            // just close ws (hopefully in can be then garbage collected)
            ws.close()
        }
        ws.onclose = async () => {
            await delay(10000)
            dispatch('connectToProgress')
        };
    },

    async getProgressToken({commit}) {
        try {
            const progressToken = (await POST('auth/ott', undefined, {SharpApi: true})).token
            commit("SET_PROGRESS_TOKEN", progressToken)
        } catch (e) {
            commit("SET_PROGRESS_TOKEN", undefined)
        }
    },
    showBackgroundTasks({commit}, val) {
        commit('SHOW_BACKGROUND_TASKS', val)
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
