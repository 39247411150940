<template>
    <div class="pt-3 white">
        <Chart v-if="!isLoading" :options="chartOptions"/>
        <ProgressCircular class="py-5" :color="currency.unit" v-else/>
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import ProgressCircular from "@/components/common/ProgressCircular"
import { mapGetters } from "vuex";
export default {
    name: "ChartDays",
    components: {
        ProgressCircular,
        Chart
    },
    props: {
        isLoading: Boolean,
        daysR: Array,
        daysS: Array
    },
    computed: {
        ...mapGetters(["currency"]),
        chartOptions() {
            return {
                chart: {
                    type: 'column',
                    height: '80%' // 16:9 ratio
                },
                credits: { enabled: false },
                title: { text: 'Daily patterns' },
                xAxis: {
                    title: { text: 'Day of week' },
                    categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                },
                yAxis: {
                    title: { text: 'Transaction count' }
                },
                plotOptions: {
                    series: { stacking: 'normal' }
                },
                tooltip: {
                    formatter: function () {
                        return `is <b>${this.series.name}</b> on <b>${this.point.category}</b><br/>${Math.abs(this.point.y)} transaction(s)`
                    }
                },
                series: [
                    {
                        name: 'Receiving',
                        color: '#4caf50',
                        data: this.daysR
                    },
                    {
                        name: 'Sending',
                        color: '#ff5252',
                        data: this.daysS
                    }]
            };
        }
    }
}
</script>