import { render, staticRenderFns } from "./StatusView.vue?vue&type=template&id=1279504a&scoped=true&"
import script from "./StatusView.vue?vue&type=script&setup=true&lang=js&"
export * from "./StatusView.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1279504a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCol,VIcon,VProgressCircular,VRow,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1279504a')) {
      api.createRecord('1279504a', component.options)
    } else {
      api.reload('1279504a', component.options)
    }
    module.hot.accept("./StatusView.vue?vue&type=template&id=1279504a&scoped=true&", function () {
      api.rerender('1279504a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/StatusView.vue"
export default component.exports