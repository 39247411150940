var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mx-auto" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-center", attrs: { cols: "12" } },
            [_c("h1", [_vm._v("Address Search")])]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center align-center",
              attrs: { cols: "12" },
            },
            [
              _c("v-text-field", {
                staticClass: "fontMonospace advSearchField",
                staticStyle: { width: "600px", "max-width": "600px" },
                attrs: {
                  readonly: _setup.isFilePicked,
                  autofocus: "",
                  "clear-icon": "mdi-close-circle",
                  clearable: "",
                  color: "tertiary",
                  label: "Search ",
                  mode: "lazy",
                  outlined: "",
                  placeholder: "Search addresses",
                  "prepend-inner-icon": "fa fa-magnifying-glass",
                  rounded: "",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _setup.onSearch.apply(null, arguments)
                  },
                  "click:clear": _setup.reset,
                },
                model: {
                  value: _setup.searchValueModel,
                  callback: function ($$v) {
                    _setup.searchValueModel = $$v
                  },
                  expression: "searchValueModel",
                },
              }),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "div",
                            _vm._g({}, on),
                            [
                              _c("v-file-input", {
                                staticClass: "ml-2 mt-n10",
                                staticStyle: { "max-width": "0" },
                                attrs: {
                                  color: "tertiary",
                                  "hide-input": "",
                                  "prepend-icon": "fa fa-file-csv",
                                },
                                on: { change: _setup.onUploadFromFileClick },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v("\n                Select from file\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-center", attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _setup.dataState === _setup.DataState.Loading,
                    color: "tertiary",
                    dark: "",
                  },
                  on: { click: _setup.onSearch },
                },
                [
                  _vm._v("\n                Search\n                "),
                  _c(
                    "v-icon",
                    { attrs: { right: "" }, on: { click: _setup.onSearch } },
                    [
                      _vm._v(
                        "\n                    mdi-magnify\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                _setup.AdvancedSearchCategory,
                {
                  attrs: {
                    "all-selected": _setup.allCryptosSelected,
                    icon: "fa fa-bitcoin-sign",
                    name: "Cryptocurrencies",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-center align-center flex-wrap",
                      staticStyle: { gap: "24px" },
                    },
                    _vm._l(_setup.currencies, function (crypto) {
                      return _c(
                        "v-btn",
                        {
                          key: crypto.unit,
                          staticClass: "d-flex justify-center align-center",
                          attrs: { rounded: "", text: "" },
                          on: {
                            click: function ($event) {
                              _setup.selectedCryptosModel[`${crypto.unit}`] =
                                !_setup.selectedCryptosModel[`${crypto.unit}`]
                            },
                          },
                        },
                        [
                          _c("v-checkbox", {
                            staticClass: "ma-0 pa-0",
                            staticStyle: { "pointer-events": "none" },
                            attrs: {
                              color: crypto.hexColor,
                              dense: "",
                              "hide-details": "",
                              readonly: "",
                            },
                            model: {
                              value:
                                _setup.selectedCryptosModel[`${crypto.unit}`],
                              callback: function ($$v) {
                                _vm.$set(
                                  _setup.selectedCryptosModel,
                                  `${crypto.unit}`,
                                  $$v
                                )
                              },
                              expression:
                                "selectedCryptosModel[`${crypto.unit}`]",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-img", {
                            staticClass: "mr-1 ml-n1",
                            attrs: {
                              src: crypto.image,
                              height: "24",
                              width: "24",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-button textGrey" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(crypto.name) +
                                "\n                        "
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                _setup.AdvancedSearchCategory,
                {
                  attrs: {
                    "all-selected": _setup.allCategoriesSelected,
                    icon: "fa fa-list",
                    name: "Categories",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-center align-center flex-wrap",
                      staticStyle: { gap: "24px" },
                    },
                    [
                      _c(_setup.AdvancedSearchCategoryItem, {
                        attrs: { icon: "mdi-hub", text: "Clusters" },
                        model: {
                          value: _setup.selectedCategoriesModel["cluster"],
                          callback: function ($$v) {
                            _vm.$set(
                              _setup.selectedCategoriesModel,
                              "cluster",
                              $$v
                            )
                          },
                          expression: "selectedCategoriesModel['cluster']",
                        },
                      }),
                      _vm._v(" "),
                      _c(_setup.AdvancedSearchCategoryItem, {
                        attrs: { icon: "assignment", text: "Cases" },
                        model: {
                          value: _setup.selectedCategoriesModel["case"],
                          callback: function ($$v) {
                            _vm.$set(
                              _setup.selectedCategoriesModel,
                              "case",
                              $$v
                            )
                          },
                          expression: "selectedCategoriesModel['case']",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(_setup.AdvancedSearchResults, {
            attrs: {
              data: _setup.searchData,
              "data-state": _setup.dataState,
              error: _setup.error,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }