<script setup>
import {computed, ref} from "vue";
import TableDeleteButton from "@/components/common/TableComponents/TableDeleteButton.vue";

const pagination = ref({page: 1, itemsPerPage: 10})
const tableHeight = computed(() => pagination.value.itemsPerPage * 32 + 32)

const filesToCreate = ref([])
const fileMap = new Map()

const createFileKey = (fileObject) => `${fileObject.name}${fileObject.lastModified}`

const appendNewFiles = (fileArray) => {
    fileArray.forEach(newFile => {
        if (!fileMap.has(createFileKey(newFile))) {
            fileMap.set(createFileKey(newFile), newFile)
        }
    })

    filesToCreate.value = Array.from(fileMap, ([_, value]) => value)

    //hack to automatically clear file input field
    fileInputKey.value++
}

const removeItem = (itemToDelete) => {
    fileMap.delete(createFileKey(itemToDelete))
    filesToCreate.value = filesToCreate.value.filter(file => file !== itemToDelete)
}

defineExpose({itemsToAdd: filesToCreate})

const headers = [{text: "Name", value: "name", align: "left", sortable: true}, {sortable: false}]

const fileInputKey = ref(0)

</script>

<template>
    <div>
        <v-row class="ma-2">
            <v-col cols="7">
                <div v-cloak @drop.prevent="appendNewFiles" @dragover.prevent>
                    <v-file-input
                        :key="fileInputKey"
                        @change="appendNewFiles"
                        accept="*"
                        hide-details
                        label="Click or drop files to upload"
                        multiple
                        prepend-icon="attach_file"
                    />
                </div>
            </v-col>
        </v-row>

        <v-data-table
            :options.sync="pagination"
            :height="tableHeight" dense :items="filesToCreate" :headers="headers"
            no-data-text="No files to add." fixed-header>
            <template #item="{ item }">
                <tr>
                    <td class="text-left" style="max-width: 330px">
                        <div v-if="item.name.includes('.')" class="d-flex">
                            <div class="text-truncate">
                                {{ item.name.split('.').shift() }}
                            </div>
                            <div>
                                {{ `.${item.name.split('.').pop()}` }}
                            </div>
                        </div>
                        <div v-else class="text-truncate">
                            {{ item.name }}
                        </div>
                    </td>
                    <td class="text-right">
                        <TableDeleteButton @click="() => removeItem(item)"/>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<style scoped>

</style>