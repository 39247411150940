<template>
    <BaseModalDialog ref="baseModal" title="Create graph from transactions" @load-modal-state-event="loadState">
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-text-field-validateable
                    v-model="detail.name"
                    label="Name"
                    rules="required|min:2"
                    outlined
                />

                <v-textarea-validateable
                    v-model="detail.description"
                    label="Description"
                    rules="max:255"
                    no-resize
                    outlined
                />
            </ValidationObserver>
            <IsHiddenCheckboxWithIcon v-model="detail.is_hidden"/>
        </template>
        <template #footer="{close}">
            <v-btn color="error" text @click="close">Cancel</v-btn>
            <v-spacer />
            <v-btn color="success" text @click="createNewGraph(close)">Create graph</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import BaseModalDialog from "@/components/common/Base/BaseModalDialog"
import IsHiddenCheckboxWithIcon from "@/components/common/IsHiddenCheckboxWithIcon.vue";

export default {
    name: "CreateGraphFromCanvasModal",
    components: {
        IsHiddenCheckboxWithIcon,
        BaseModalDialog
    },
    data() {
        return {
            detail: undefined
        }
    },
    methods: {
        openModal() {
            this.$refs.baseModal.open()
        },
        loadState() {
            this.detail = {
                name: "",
                is_hidden: false,
                description: undefined,
                crypto: this.$store.getters.currency.unit,
            }
        },
        async createNewGraph(close) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.$emit("create-graph-event", { graphDetail: this.detail })
                close()
            }
        }
    }
}
</script>
