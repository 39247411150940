var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filesView" },
    [
      _c(
        "v-container",
        { staticClass: "px-4", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "" } },
            [
              _c("h1", [_vm._v("Files")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("FileDetailModal", {
                attrs: { cases: _vm.allCases.data || [] },
                on: { "save-event": _vm.uploadFile },
                scopedSlots: _vm._u([
                  {
                    key: "showbutton",
                    fn: function ({ on: on }) {
                      return [
                        _c(
                          "UploadButton",
                          _vm._g({ attrs: { text: "Upload file" } }, on)
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-0 border mt-2",
                    attrs: {
                      "footer-props": {
                        "items-per-page-options": [15, 30, 50, -1],
                        "show-current-page": true,
                        showFirstLastPage: true,
                      },
                      headers: _vm.headers,
                      items: _vm.files.data,
                      loading: _vm.filesLoading,
                      options: _vm.filter,
                      "server-items-length": _vm.files.total
                        ? _vm.files.total
                        : 0,
                      dense: "",
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.filter = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.headers, function (h) {
                          return {
                            key: `header.${h.value}`,
                            fn: function ({ header }) {
                              return [
                                h.value === "filename"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      attrs: {
                                        label: header.text,
                                        "append-icon": "search",
                                        clearable: "",
                                        "hide-details": "",
                                        "single-line": "",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.debouncedFilter.filterFileName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterFileName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterFileName",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "size"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      staticStyle: { "min-width": "100px" },
                                      attrs: {
                                        label: header.text,
                                        "append-icon": "search",
                                        clearable: "",
                                        "hide-details": "",
                                        "single-line": "",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.debouncedFilter.filterSize,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterSize",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterSize",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "mime"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      attrs: {
                                        label: header.text,
                                        "append-icon": "search",
                                        clearable: "",
                                        "hide-details": "",
                                        "single-line": "",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.debouncedFilter.filterMimeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterMimeType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterMimeType",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "case_id"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      attrs: {
                                        label: header.text,
                                        "append-icon": "search",
                                        clearable: "",
                                        "hide-details": "",
                                        "single-line": "",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.debouncedFilter.filterCase,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterCase",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterCase",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "md5"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "sha256"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "created_at"
                                  ? _c(
                                      "div",
                                      { key: h.value },
                                      [
                                        _c("DateTimePicker", {
                                          attrs: {
                                            disabledTime: true,
                                            label: h.text,
                                          },
                                          model: {
                                            value: _vm.filter.filterCreatedAt,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filter,
                                                "filterCreatedAt",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "filter.filterCreatedAt",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "action"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: {
                                          "padding-top": "20px",
                                          "padding-bottom": "20px",
                                        },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          }
                        }),
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-left",
                                    staticStyle: { "max-width": "300px" },
                                  },
                                  [
                                    _c("FileNameDownloadable", {
                                      attrs: { item: item },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(_vm._f("fileSize")(item.size))),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-left" }, [
                                  _vm._v(_vm._s(item.mime)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-left" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "text-truncate link",
                                        attrs: {
                                          to: {
                                            name: "CaseDetail",
                                            params: {
                                              caseId: item.case_id,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.title) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "fontMonospace" },
                                  [
                                    _c("Copyable", {
                                      attrs: { "on-copy": item.md5 },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function ({ on }) {
                                              return [
                                                _c("span", _vm._g({}, on), [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "stringShortener"
                                                        )(item.md5, 16)
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "fontMonospace" },
                                  [
                                    _c("Copyable", {
                                      attrs: { "on-copy": item.sha256 },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function ({ on }) {
                                              return [
                                                _c("span", _vm._g({}, on), [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "stringShortener"
                                                        )(item.sha256, 16)
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "vertical-align": "middle",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            item.created_at,
                                            "YYYY-MM-DD HH:mm:ss Z"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("FileDetailModal", {
                                        key: item.id,
                                        attrs: {
                                          cases: _vm.allCases.data || [],
                                          fileDetail: item,
                                        },
                                        on: { "save-event": _vm.editFile },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "showbutton",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "TableEditButton",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          editButtonTooltip:
                                                            "Edit file",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("TableDeleteModalDialog", {
                                        attrs: {
                                          description: `Do you really want to delete file ${item.filename}`,
                                          itemToDelete: item,
                                          tooltip: "Delete File",
                                        },
                                        on: { "delete-event": _vm.deleteFile },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "footer.page-text",
                          fn: function ({ pageStart, pageStop, itemsLength }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm._f("formatNumber")(pageStart)) +
                                    " - " +
                                    _vm._s(_vm._f("formatNumber")(pageStop)) +
                                    " of " +
                                    _vm._s(
                                      _vm._f("formatNumber")(itemsLength)
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }