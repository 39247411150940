var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100" },
    [
      _c("h1", [_vm._v("Nodes Metadata")]),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-0 border my-2",
        attrs: {
          options: _vm.pagination,
          "server-items-length": _vm.peers.total,
          headers: _vm.headers,
          loading: _vm.peersLoading,
          items: _vm.peers.data,
          dense: "",
          "footer-props": {
            "items-per-page-options": [15, 30, 50],
            "show-current-page": true,
            showFirstLastPage: true,
          },
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.headers, function (h) {
              return {
                key: `header.${h.value}`,
                fn: function ({ header }) {
                  return [
                    h.value === "host"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          attrs: {
                            "append-icon": "search",
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                          },
                          nativeOn: {
                            "!click": function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.pagination.filterHost,
                            callback: function ($$v) {
                              _vm.$set(_vm.pagination, "filterHost", $$v)
                            },
                            expression: "pagination.filterHost",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "country_code"
                      ? _c("CountryPicker", {
                          key: h.value,
                          staticClass: "mt-1 text-field-label-style",
                          staticStyle: { padding: "14px 0 4px 0" },
                          attrs: { "property-name": "country_code" },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.pagination.filterCountry,
                            callback: function ($$v) {
                              _vm.$set(_vm.pagination, "filterCountry", $$v)
                            },
                            expression: "pagination.filterCountry",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "city"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          attrs: {
                            "append-icon": "search",
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                          },
                          nativeOn: {
                            "!click": function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.pagination.filterCity,
                            callback: function ($$v) {
                              _vm.$set(_vm.pagination, "filterCity", $$v)
                            },
                            expression: "pagination.filterCity",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "asn"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          attrs: {
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                            type: "number",
                            min: 0,
                          },
                          nativeOn: {
                            "!click": function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.pagination.filterAsn,
                            callback: function ($$v) {
                              _vm.$set(_vm.pagination, "filterAsn", $$v)
                            },
                            expression: "pagination.filterAsn",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "organization"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          attrs: {
                            "append-icon": "search",
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                          },
                          nativeOn: {
                            "!click": function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.pagination.filterOrganization,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pagination,
                                "filterOrganization",
                                $$v
                              )
                            },
                            expression: "pagination.filterOrganization",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "seen"
                      ? _c("div", { key: h.value, staticClass: "pt-1" }, [
                          _c("h3", [_vm._v(_vm._s(header.text))]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "cryptointerceptor" &&
                    _vm.currency.interceptorEnabled
                      ? _c("div", { key: h.value }, [
                          _c("h3", [_vm._v(_vm._s(header.text))]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c(
                                "v-btn-toggle",
                                {
                                  attrs: { multiple: "", dense: "" },
                                  model: {
                                    value: _vm.pagination.filterStatuses,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pagination,
                                        "filterStatuses",
                                        $$v
                                      )
                                    },
                                    expression: "pagination.filterStatuses",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: !_vm.hasMonitoringPermission,
                                        "x-small": "",
                                        value: "RED",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", color: "red" } },
                                        [
                                          _vm._v(
                                            "\n                                mdi-eye\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: !_vm.hasMonitoringPermission,
                                        "x-small": "",
                                        value: "ORANGE",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "", color: "orange" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                mdi-eye\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: !_vm.hasMonitoringPermission,
                                        "x-small": "",
                                        value: "GREEN",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "", color: "green" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                mdi-eye\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "activities_count"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: { "padding-top": "20px" },
                          },
                          [_c("h3", [_vm._v(_vm._s(header.text))])]
                        )
                      : _vm._e(),
                  ]
                },
              }
            }),
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "fontMonospace text-left dynamicTruncate",
                      },
                      [
                        _c("PeerDetailModal", {
                          key: `${item.id}${item.since}`,
                          attrs: {
                            peerDetail: _vm.peerDetail,
                            activities: item.activities_count,
                            loading: _vm.peerDetailLoading,
                            peerId: item.id,
                            currency: _vm.currency.unit,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "showbutton",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between align-center",
                                      },
                                      [
                                        _c(
                                          "span",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "link pointer text-truncate",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.pushPeerIdToQuery(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("truncate")(
                                                  item.host,
                                                  40
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "LightningNetworkInvolvementChecker",
                                          { attrs: { ipAddress: item.host } }
                                        ),
                                        _vm._v(" "),
                                        _c("TorInvolvementChecker", {
                                          attrs: { ipAddress: item.host },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [_c("span", [_vm._v(_vm._s(item.country_code))])]),
                    _vm._v(" "),
                    _c("td", [_c("span", [_vm._v(_vm._s(item.city))])]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right text-truncate" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("numberFormatWithSpaces")(item.asn))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-left text-truncate",
                        staticStyle: { "max-width": "250px" },
                      },
                      [_c("span", [_vm._v(_vm._s(item.organization))])]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-left fontMonospace",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$moment(item.since).format("YYYY-MM-DD"))
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("v-chip", { attrs: { small: "", label: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatNumber")(item.activities_count)
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.currency.interceptorEnabled
                      ? _c(
                          "td",
                          [
                            _c("cryptointerceptorDetailModal", {
                              attrs: { ip: item.host },
                              on: {
                                "monitoring-start-event":
                                  _vm.startMonitoringPeer,
                                "monitoring-stop-event": _vm.stopMonitoringPeer,
                                "peer-polling-start-event":
                                  _vm.startPeerPolling,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "showbutton",
                                    fn: function ({ on: one }) {
                                      return [
                                        _c(
                                          "CryptoInterceptorControlPanelSmall",
                                          {
                                            attrs: {
                                              ip: item.host,
                                              showBadge: "",
                                              small: "",
                                              disabled:
                                                !_vm.hasMonitoringPermission,
                                            },
                                            on: {
                                              "monitoring-start-event":
                                                _vm.startMonitoringPeer,
                                              "monitoring-stop-event":
                                                _vm.stopMonitoringPeer,
                                              "peer-polling-start-event":
                                                _vm.startPeerPolling,
                                              "peer-polling-stop-event":
                                                _vm.stopPeerPolling,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "extrabutton",
                                                  fn: function () {
                                                    return [
                                                      _c("v-hover", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                hover,
                                                              }) {
                                                                return [
                                                                  _c("div", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "show",
                                                                              rawName:
                                                                                "v-show",
                                                                              value:
                                                                                hover,
                                                                              expression:
                                                                                "hover",
                                                                            },
                                                                          ],
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                top: "",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "activator",
                                                                                    fn: function ({
                                                                                      on,
                                                                                      attrs,
                                                                                    }) {
                                                                                      return [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "extraButton",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-btn",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      disabled:
                                                                                                        !_vm.hasMonitoringPermission,
                                                                                                      small:
                                                                                                        "",
                                                                                                      icon: "",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return one.click(
                                                                                                          $event
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          _vm
                                                                                                            .currency
                                                                                                            .unit,
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                                                    mdi-file-search-outline\n                                                                "
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Show Details"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "show",
                                                                              rawName:
                                                                                "v-show",
                                                                              value:
                                                                                !hover,
                                                                              expression:
                                                                                "!hover",
                                                                            },
                                                                          ],
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !_vm.hasMonitoringPermission,
                                                                                small:
                                                                                  "",
                                                                                icon: "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return one.click(
                                                                                    $event
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                        mdi-file-search-outline\n                                                    "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              },
            },
            {
              key: "footer.page-text",
              fn: function ({ pageStart, pageStop, itemsLength }) {
                return [
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("formatNumber")(pageStart)) +
                        " - " +
                        _vm._s(_vm._f("formatNumber")(pageStop)) +
                        " of " +
                        _vm._s(_vm._f("formatNumber")(itemsLength)) +
                        "\n            "
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c("PeerDetailModal", {
        ref: "peerDetailModal",
        attrs: {
          peerDetail: _vm.peerDetail,
          loading: _vm.peerDetailLoading,
          peerId: _vm.peerId,
          currency: _vm.currency.unit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }