var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { staticClass: "px-2 py-1", attrs: { row: "" } },
        [
          _c("b", [_vm._v("Identity")]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "ml-2",
              attrs: { href: _vm.data.url, target: "_blank" },
            },
            [_vm._v(_vm._s(_vm.data.label))]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("div", { staticClass: "no-wrap pl-2" }, [
            _vm._v(
              _vm._s(
                _vm._f("moment")(_vm.data.created_at, "YYYY-MM-DD HH:mm:ss Z")
              )
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-layout",
        {
          staticClass: "mx-2 pb-1",
          attrs: { "justify-space-between": "", row: "", wrap: "" },
        },
        [
          _c(
            "span",
            [
              _c("b", [_vm._v("Address:")]),
              _vm._v(" "),
              _vm.data.address.crypto
                ? _c(
                    "router-link",
                    {
                      staticClass: "text-overflow ml-2 text-decoration-none",
                      attrs: {
                        to:
                          "/" +
                          _vm.data.address.crypto +
                          "/address/" +
                          _vm.data.address.address,
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "currencyImg mx-2",
                        attrs: {
                          src: _vm._f("createImageUrl")(
                            "cryptoIcons/color/" +
                              _vm.data.address.crypto.toLowerCase() +
                              ".svg"
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("code", { staticClass: "elevation-0" }, [
                        _vm._v(_vm._s(_vm.data.address.address)),
                      ]),
                    ]
                  )
                : _c("code", { staticClass: "elevation-0" }, [
                    _vm._v(_vm._s(_vm.data.address.address)),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", [
            _c("b", [_vm._v("Source:")]),
            _vm._v("\n            " + _vm._s(_vm.data.source) + "\n        "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }