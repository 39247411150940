var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.large
        ? _c(
            "v-chip",
            {
              attrs: {
                color: _vm.iconItem.color,
                "text-color": _vm.iconItem.secondaryColor,
              },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v(_vm._s(_vm.icon))]),
              _vm._v(" "),
              _c("span", { staticStyle: { "font-weight": "500" } }, [
                _vm._v(_vm._s(_vm.iconItem.name)),
              ]),
            ],
            1
          )
        : _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            staticClass: "pointer",
                            attrs: { left: "", color: _vm.iconItem.color },
                          },
                          on
                        ),
                        [_vm._v(_vm._s(_vm.icon))]
                      ),
                    ]
                  },
                },
              ]),
            },
            [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.iconItem.name))])]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }