<script setup>


import {computed, ref} from "vue";
import AdvancedSearchCategory from "@/views/AdvancedSearch/AdvancedSearchCategory.vue";
import store from "@/store";
import AdvancedSearchCategoryItem from "@/views/AdvancedSearch/AdvancedSearchCategoryItem.vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {formatNumber} from "humanize-plus";
import AdvancedSearchResults from "@/views/AdvancedSearch/AdvancedSearchResults.vue";
import {DataState} from "@/components/common/types/data";
import {parseSearchInputFile} from "@/views/AdvancedSearchFileParser";
import {getTruthyKeys} from "@/utils";

const {dataState, data: searchData, getData, error} = useGetData("advancedSearch", {}, false)

const onSearch = () => {

    if (!searchValueModel.value && fileDataModel.value.length === 0) {
        return // There is nothing to search for
    }

    if (dataState.value === DataState.Loading)
        return

    getData({
        cryptocurrencies: getTruthyKeys(selectedCryptosModel.value),
        needles: isFilePicked.value ? fileDataModel.value : [searchValueModel.value],
        resources: getTruthyKeys(selectedCategoriesModel.value),
    })
}

const onUploadFromFileClick = async (file) => {
    const parsedDataArray = await parseSearchInputFile(file)
    fileDataModel.value = parsedDataArray
    isFilePicked.value = true
    searchValueModel.value = `${formatNumber(parsedDataArray.length)} addresses (from file)`
}

const reset = () => {
    isFilePicked.value = false;
    searchValueModel.value = undefined
    fileDataModel.value = []
}

const fileDataModel = ref([])
const isFilePicked = ref(false)
const searchValueModel = ref()

const currencies = computed(() => store.getters.enabledCurrencies)

const selectedCategoriesModel = ref({cluster: true, case: true})
const selectedCryptosModel = ref(currencies.value.reduce((resultObject, crypto) => {
    resultObject[crypto.unit] = true;
    return resultObject;
}, {}))


const allCryptosSelected = computed(() => Object.values(selectedCryptosModel.value).every(x => x))
const allCategoriesSelected = computed(() => Object.values(selectedCategoriesModel.value).every(x => x))

</script>

<template>
    <v-container fluid>
        <v-row class="mx-auto">
            <v-col class="d-flex justify-center" cols="12">
                <h1>Address Search</h1>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-text-field
                    v-model="searchValueModel"
                    :readonly="isFilePicked"
                    autofocus
                    class="fontMonospace advSearchField"
                    clear-icon="mdi-close-circle"
                    clearable
                    color="tertiary"
                    label="Search "
                    mode="lazy"
                    outlined
                    placeholder="Search addresses"
                    prepend-inner-icon="fa fa-magnifying-glass"
                    rounded
                    style="width: 600px; max-width: 600px"
                    @keydown.enter="onSearch"
                    @click:clear="reset"
                />
                <v-tooltip top>
                    <template #activator="{on}">
                        <div v-on="on">
                            <v-file-input
                                class="ml-2 mt-n10"
                                color="tertiary"
                                hide-input
                                prepend-icon="fa fa-file-csv"
                                style="max-width: 0"
                                @change="onUploadFromFileClick"/>
                        </div>
                    </template>
                    Select from file
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn :loading="dataState === DataState.Loading"
                       color="tertiary"
                       dark
                       @click="onSearch">
                    Search
                    <v-icon right @click="onSearch">
                        mdi-magnify
                    </v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12">
                <AdvancedSearchCategory :all-selected="allCryptosSelected"
                                        icon="fa fa-bitcoin-sign"
                                        name="Cryptocurrencies">
                    <div class="d-flex justify-center align-center flex-wrap" style="gap: 24px">
                        <v-btn v-for="crypto in currencies"
                               :key="crypto.unit"
                               class="d-flex justify-center align-center"
                               rounded
                               text
                               @click="selectedCryptosModel[`${crypto.unit}`] = !selectedCryptosModel[`${crypto.unit}`]">
                            <v-checkbox v-model="selectedCryptosModel[`${crypto.unit}`]"
                                        :color="crypto.hexColor"
                                        class="ma-0 pa-0"
                                        dense
                                        hide-details
                                        readonly
                                        style="pointer-events: none"/>
                            <v-img :src="crypto.image" class="mr-1 ml-n1" height="24" width="24"/>
                            <div class="text-button textGrey">
                                {{ crypto.name }}
                            </div>
                        </v-btn>
                    </div>
                </AdvancedSearchCategory>
                <AdvancedSearchCategory :all-selected="allCategoriesSelected"
                                        icon="fa fa-list"
                                        name="Categories">
                    <div class="d-flex justify-center align-center flex-wrap" style="gap: 24px">
                        <AdvancedSearchCategoryItem v-model="selectedCategoriesModel['cluster']"
                                                    icon="mdi-hub"
                                                    text="Clusters"/>
                        <AdvancedSearchCategoryItem v-model="selectedCategoriesModel['case']"
                                                    icon="assignment"
                                                    text="Cases"/>
                    </div>
                </AdvancedSearchCategory>
            </v-col>
        </v-row>
        <div>
            <AdvancedSearchResults :data="searchData" :data-state="dataState" :error="error"/>
        </div>
    </v-container>
</template>

<style lang="scss">

.advSearchField .v-input__prepend-inner {
    margin-right: 6px !important;
}

</style>