var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationProvider", {
    attrs: { name: _vm.$attrs.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors }) {
          return [
            _c(
              "v-select",
              _vm._b(
                {
                  attrs: {
                    error: _vm.error,
                    disabled: _vm.disabled,
                    value: _vm.value,
                    "error-messages": errors,
                  },
                  on: { input: _vm.valueChange },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function (_, slot) {
                        return {
                          key: slot,
                          fn: function (scope) {
                            return [_vm._t(slot, null, null, scope)]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                },
                "v-select",
                _vm.$attrs,
                false
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }