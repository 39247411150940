<template>
    <div class="filesView">
        <v-container class="px-4" fluid>
            <v-layout align-center>
                <h1>Files</h1>
                <v-spacer/>
                <FileDetailModal
                    :cases="allCases.data || []"
                    @save-event="uploadFile"
                >
                    <template #showbutton="{ on: on }">
                        <UploadButton text="Upload file" v-on="on"/>
                    </template>
                </FileDetailModal>
            </v-layout>
            <v-divider/>
            <v-layout wrap>
                <v-flex xs12>
                    <v-divider/>
                    <v-data-table
                        :footer-props="{
                            'items-per-page-options': [15, 30, 50, -1],
                            'show-current-page': true,
                            'showFirstLastPage': true,
                        }"
                        :headers="headers"
                        :items="files.data"
                        :loading="filesLoading"
                        :options.sync="filter"
                        :server-items-length="files.total ? files.total : 0"
                        class="elevation-0 border mt-2"
                        dense
                    >
                        <template
                            v-for="h in headers"
                            v-slot:[`header.${h.value}`]="{ header }"
                        >
                            <v-text-field
                                v-if="h.value === 'filename'"
                                :key="h.value"
                                v-model="debouncedFilter.filterFileName"
                                :label="header.text"
                                append-icon="search"
                                class="text-field-label-style"
                                clearable
                                hide-details
                                single-line
                                @input="debounceInput"
                                @click.stop.native
                            />
                            <v-text-field
                                v-if="h.value === 'size'"
                                :key="h.value"
                                v-model="debouncedFilter.filterSize"
                                :label="header.text"
                                append-icon="search"
                                class="text-field-label-style"
                                clearable
                                hide-details
                                single-line
                                style="min-width: 100px"
                                @input="debounceInput"
                                @click.stop.native
                            />
                            <v-text-field
                                v-if="h.value === 'mime'"
                                :key="h.value"
                                v-model="debouncedFilter.filterMimeType"
                                :label="header.text"
                                append-icon="search"
                                class="text-field-label-style"
                                clearable
                                hide-details
                                single-line
                                @input="debounceInput"
                                @click.stop.native
                            />
                            <v-text-field
                                v-if="h.value === 'case_id'"
                                :key="h.value"
                                v-model="debouncedFilter.filterCase"
                                :label="header.text"
                                append-icon="search"
                                class="text-field-label-style"
                                clearable
                                hide-details
                                single-line
                                @input="debounceInput"
                                @click.stop.native
                            />
                            <div
                                v-if="h.value === 'md5'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'sha256'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div v-if="h.value === 'created_at'" :key="h.value">
                                <DateTimePicker
                                    v-model="filter.filterCreatedAt"
                                    :disabledTime="true"
                                    :label="h.text"
                                />
                            </div>
                            <div
                                v-if="h.value === 'action'"
                                :key="h.value"
                                style="padding-top: 20px; padding-bottom: 20px;"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                        </template>
                        <template #item="{ item }">
                            <tr>
                                <td class="text-left" style="max-width: 300px">
                                    <FileNameDownloadable :item="item"/>
                                </td>
                                <td class="text-right">{{ item.size | fileSize }}</td>
                                <td class="text-left">{{ item.mime }}</td>
                                <td class="text-left">
                                    <router-link
                                        :to="{
                                            name: 'CaseDetail',
                                            params: {
                                                caseId: item.case_id,
                                            },
                                        }"
                                        class="text-truncate link"
                                    >{{ item.title }}
                                    </router-link
                                    >
                                </td>

                                <td class="fontMonospace">
                                    <Copyable :on-copy="item.md5">
                                        <template #text="{ on }">
                                            <span v-on="on">
                                                {{ item.md5 | stringShortener(16) }}
                                            </span>
                                        </template>
                                    </Copyable>
                                </td>
                                <td class="fontMonospace">
                                    <Copyable :on-copy="item.sha256">
                                        <template #text="{ on }">
                                            <span v-on="on">
                                                {{ item.sha256 | stringShortener(16) }}
                                            </span>
                                        </template>
                                    </Copyable>
                                </td>
                                <td
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    {{
                                        item.created_at
                                            | moment("YYYY-MM-DD HH:mm:ss Z")
                                    }}
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <FileDetailModal
                                            :key="item.id"
                                            :cases="allCases.data || []"
                                            :fileDetail="item"
                                            @save-event="editFile"
                                        >
                                            <template #showbutton="{ on }">
                                                <TableEditButton
                                                    editButtonTooltip="Edit file"
                                                    v-on="on"
                                                />
                                            </template>
                                        </FileDetailModal>
                                        <TableDeleteModalDialog
                                            :description="`Do you really want to delete file ${item.filename}`"
                                            :itemToDelete="item"
                                            tooltip="Delete File"
                                            @delete-event="deleteFile"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                            <span>
                                {{ pageStart | formatNumber }} - {{
                                    pageStop | formatNumber
                                }} of {{ itemsLength | formatNumber }}
                            </span>
                        </template>
                    </v-data-table>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>

import UploadButton from "@/components/common/Buttons/UploadButton"
import TableDeleteModalDialog from "@/components/common/TableComponents/TableDeleteModalDialog"
import TableEditButton from "@/components/common/TableComponents/TableEditButton"
import FileDetailModal from "@/components/file/FileDetailModal"
import {mapGetters} from "vuex";
import debounce from "debounce";
import Copyable from "@/components/common/Copyable";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import DateTimePicker from "@/components/common/DateTimePicker";
import FileNameDownloadable from "@/views/Case/Components/Files/FileNameDownloadable.vue";

export default {
    name: 'FilesView',
    mixins: [routeSyncMixin],
    components: {
        FileNameDownloadable,
        Copyable,
        UploadButton,
        TableDeleteModalDialog,
        TableEditButton,
        FileDetailModal,
        DateTimePicker
    },
    mounted() {
        this.$store.dispatch('loadAllCases')
    },
    computed: {
        ...mapGetters(["allCases", "files", "filesLoading"])
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                sortBy: {type: "ArrayOfStrings", default: () => ['created_at']},
                sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
                filterFileName: {type: "String", default: () => ''},
                filterSize: {type: "String", default: () => ''},
                filterMimeType: {type: "String", default: () => ''},
                filterCase: {type: "String", default: () => ''},
                filterCreatedAt: {type: "Number", default: () => undefined},
            },
            debouncedFilter: {
                filterFileName: undefined,
                filterSize: undefined,
                filterMimeType: undefined,
                filterCase: undefined,
            },
            headers: [
                {align: "center", text: "Filename", value: "filename",},
                {align: "center", text: "(<,>,<=,>=) Size", value: "size"},
                {align: "center", text: "MIME Type", value: "mime"},
                {align: "center", text: "Case", value: "case_id"},
                {align: "center", text: "MD5", value: "md5"},
                {align: "center", text: "SHA1", value: "sha256"},
                {align: "center", text: "Created", value: "created_at"},
                {align: "center", text: "Action", value: "action", sortable: false}
            ]
        };
    },
    methods: {
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),
        refresh() {
            this.$store.dispatch('loadFiles', this.filter)
        },
        async deleteFile({itemToDelete}) {
            await this.$store.dispatch("deleteFile", {fileId: itemToDelete.id})
            this.refresh()
        },
        async editFile({fileDetail}) {
            await this.$store.dispatch("editFile", {fileMetadata: fileDetail})
            this.refresh()
        },
        async uploadFile({fileDetail}) {
            await this.$store.dispatch("createFile", {fileMetadata: fileDetail})
            this.refresh()
        }
    }
}
</script>

<style>
.filesView .v-text-field__details {
    position: absolute !important;
    z-index: -1 !important;
    display: none;
}

.filesView .v-input__slot {
    margin-bottom: auto;
}

</style>