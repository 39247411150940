import apiClient from "@/services/apiClient"

import { NeoClusterMetadataDetailServerModel } from "models/NeoClusterModels"
import mappers from "models/NeoClusterModels"
import axios from "axios";

const state = {
    neoClusterMetadata: {
        data: []
    },
    neoClusterMetadataLoading: false,
    neoClusterMetadataDetail: undefined,
    isNeoClusterMetadataDetailLoading: false,
    isNeoClusterDetailPaginationLoading: false,
    neoClusterMetadataCancelToken: null,
}

const getters = {
    isNeoClusterDetailPaginationLoading: (state) => state.isNeoClusterDetailPaginationLoading,
    neoClusterMetadata: (state) => state.neoClusterMetadata,
    neoClusterMetadataLoading: (state) => state.neoClusterMetadataLoading,
    neoClusterMetadataDetail: (state) => state.neoClusterMetadataDetail,
    isNeoClusterMetadataDetailLoading: (state) => state.isNeoClusterMetadataDetailLoading,
}

const mutations = {
    NEOCLUSTER_METADATA_START_LOADING(state) {
        if(state.neoClusterMetadataCancelToken) {
            state.neoClusterMetadataCancelToken.cancel()
        }
        state.neoClusterMetadataCancelToken = axios.CancelToken.source()
        state.neoClusterMetadataLoading = true
    },
    NEOCLUSTER_METADATA_LOADED(state, data) {
        state.neoClusterMetadata = data
    },
    NEOCLUSTER_METADATA_ERROR(state, data) {
        state.neoClusterMetadata = data
    },
    NEOCLUSTER_METADATA_FINISH_LOADING(state) {
        state.neoClusterMetadataLoading = false
    },
    NEOCLUSTER_METADATA_DETAIL_START_LOADING(state) {
        state.isNeoClusterMetadataDetailLoading = true
    },
    NEOCLUSTER_METADATA_DETAIL_LOADED(state, { detail }) {
        state.neoClusterMetadataDetail = detail
    },
    NEOCLUSTER_METADATA_DETAIL_FINISH_LOADING(state) {
        state.isNeoClusterMetadataDetailLoading = false
    },
    NEOCLUSTER_METADATA_DETAIL_START_PAGINATION_LOADING(state) {
        state.isNeoClusterDetailPaginationLoading = true
    },
    NEOCLUSTER_METADATA_DETAIL_FINISH_PAGINATION_LOADING(state) {
        state.isNeoClusterDetailPaginationLoading = false
    }
}

const actions = {
    async loadNeoClusterMetadata({ commit, dispatch }, { currency, pagination }) {
        commit('NEOCLUSTER_METADATA_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/neoclusters`, {
                params: { ...pagination },
                cancelToken: state.neoClusterMetadataCancelToken.token
            })
            dispatch('success', response.message)
            const clustersData = mappers.MapToViewModel(response, currency)
            commit('NEOCLUSTER_METADATA_LOADED', clustersData)
            commit('NEOCLUSTER_METADATA_FINISH_LOADING')
        }
        catch (error) {
            if(axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch('error', error.userFriendlyMessage)
                commit('NEOCLUSTER_METADATA_ERROR', {data: [], total: 0})
                commit('NEOCLUSTER_METADATA_FINISH_LOADING')
            }
        }
    },
    async updateNeoClusterMetadata({ commit }, { currency, updatedClusterMetadata }) {
        commit('NEOCLUSTER_METADATA_START_LOADING')
        const clusterMetadataDetail = mappers.MapToServerModel(updatedClusterMetadata)
        try {
            const response = await apiClient.PUT(`${currency}/neoclusters/${clusterMetadataDetail.cluster}`, clusterMetadataDetail);
            this.dispatch('success', response.message)
        }
        catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
        }
    },
    async loadNeoClusterMetadataDetail({ commit }, { currency, clusterMetadataId }) {
        commit('NEOCLUSTER_METADATA_DETAIL_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/neoclusters/${clusterMetadataId}`)
            this.dispatch('success', response.message)
            commit('NEOCLUSTER_METADATA_DETAIL_LOADED', { detail: response })
        }
        catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
        }
        finally {
            commit('NEOCLUSTER_METADATA_DETAIL_FINISH_LOADING')
        }
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}