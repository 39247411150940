import apiClient from "@/services/apiClient";
import {deepClone, orderArrayById, sortCategories} from "@/utils";
import axios from "axios";
import Vue from "vue";

const state = {
    currentFundsCluster: null,
    addressFundsLoading: {},
    addressFundsData: {},
    addressFundsFilter: {},
    addressFundsIsLogarithmic: {},
    addressFundsHistoryFallback: {},
};

const getters = {
    currentFundsCluster: (state) => state.currentFundsCluster,
    addressFundsLoading: (state) => state.addressFundsLoading,
    addressFundsData: (state) => state.addressFundsData,
    addressFundsFilter: (state) => state.addressFundsFilter,
    addressFundsIsLogarithmic: (state) => state.addressFundsIsLogarithmic,
    addressFundsHistoryFallback: (state) => state.addressFundsHistoryFallback,
};

const mutations = {
    ADDRESS_FUNDS_SET_CURRENT(state, data) {
        state.currentFundsCluster = data;
    },
    ADDRESS_FUNDS_LOADING(state, data) {
        if (state.addressFundsLoading.hasOwnProperty(data.entity)) {
            state.addressFundsLoading[data.entity].token.cancel()
        }
        Vue.set(state.addressFundsLoading, data.entity, {token: axios.CancelToken.source()})
    },
    ADDRESS_FUNDS_SET_DATA(state, data) {
        Vue.set(state.addressFundsData, data.entity, data.data)
        Vue.set(state.addressFundsIsLogarithmic, data.entity, false)

        let payload = data.data

        //order categories on entity from server by id
        for (let entity of state.addressFundsData[data.entity].entities) {
            const categories = [];
            for (const key in entity.categoriesChart) {
                categories.push(entity.categoriesChart[key]);
            }
            entity.categoriesChart = orderArrayById(categories);
        }

        for (var index = 1; index < payload.categoryAppearance.length; index++) {
            if (payload.categoryAppearance[index].name === "No category") {
                state.addressFundsIsLogarithmic[data.entity] = true;
            }
        }

        (state.addressFundsData[data.entity].highChartsData = payload.categoriesChart.map(
            (cat) => ({
                name: cat.name,
                y: Number(cat.value),
                color: cat.color,
            })
        )),
            (state.addressFundsData[data.entity].amountsChartsData = payload.amountsChart.map(
                (cat) => ({
                    name: cat.name,
                    y: cat.value,
                })
            )),
            (state.addressFundsData[data.entity].categoryAppearanceNames = payload.categoryAppearance.map(
                (cat) => ({
                    name: cat.name,
                })
            ));

        state.addressFundsData[data.entity].categoryAppearanceData = payload.categoryAppearance.map(
            (cat) => ({
                name: cat.name,
                y: cat.value,
                color: cat.color,
            })
        );

    },
    ADDRESS_FUNDS_SET_FILTER(state, data) {
        Vue.set(state.addressFundsFilter, data.entity, data.filter)
    },
    ADDRESS_FUNDS_FINISHED_LOADING(state, data) {
        Vue.delete(state.addressFundsLoading, data.entity)
    },
    ADDRESS_FUNDS_LOADING_CANCEL(state, data) {
        if (state.addressFundsLoading.hasOwnProperty(data.entity)) {
            state.addressFundsLoading[data.entity].token.cancel()
        }
        Vue.delete(state.addressFundsLoading, data.entity)
    },
    ADDRESS_FUNDS_FALLBACK_SET(state, data) {
        Vue.set(state.addressFundsHistoryFallback, data.entity, data.filter)
    },
    ADDRESS_FUNDS_STATE_RESET(state) {
        state.currentFundsCluster = null
        state.addressFundsLoading = {}
        state.addressFundsData = {}
        state.addressFundsFilter = {}
        state.addressFundsIsLogarithmic = {}
        state.addressFundsHistoryFallback = {}
    }

};
;
const actions = {
    async loadAddressFunds(
        {commit, dispatch},
        {filter, currency, entityAddress}
    ) {
        try {

            if (!filter) {
                return
            }

            //OLD způsob si ukládat k načítání ještě aktuální filter
            commit("ADDRESS_FUNDS_LOADING", {entity: entityAddress})


            //workaround in case user sends one request twice
            //- waiting for first request to finish, otherwise the canceled "response"
            //gets cached and returned for the second request
            await new Promise(r => setTimeout(r, 0));

            //Execute the API call
            const response = await apiClient.GET(
                `${currency}/cryptocluster/${entityAddress}/funds`,
                {
                    params: {
                        until: filter.until,
                        since: filter.since,
                        maxHops: filter.maxHops,
                        isOutgoing: filter.isOutgoing,
                        allClusters: filter.allClusters,
                        categories: filter.categories,
                        addresses: filter.addresses,
                        clusters: filter.clusters,
                    },
                    cancelToken: state.addressFundsLoading[entityAddress].token.token
                })
            commit("ADDRESS_FUNDS_SET_FILTER", {
                entity: entityAddress,
                filter: deepClone(filter)
            })
            commit("ADDRESS_FUNDS_FALLBACK_SET", {entity: entityAddress, filter: filter})
            commit("ADDRESS_FUNDS_SET_DATA", {entity: entityAddress,
                data: {
                    ...response,
                    entities: response.entities.map(x => ({...x, categories: sortCategories(x.categories)}))
                }
            })
            commit("ADDRESS_FUNDS_FINISHED_LOADING", {entity: entityAddress})
            dispatch("success", response.message);
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch('error', error.userFriendlyMessage)
                commit("ADDRESS_FUNDS_FINISHED_LOADING", {entity: entityAddress})
            }
        }
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
