<template>
    <div class="d-flex justify-space-between my-1" :style="'height: ' + height ">
        <div :class="[nameFlex ? 'nameFlex' : 'noFlex', 'd-flex align-center']" :style=" nameFlex ? ''  : 'width: ' + nameWidth + 'px'">
            <div>
                <slot name="name"/>
            </div>
        </div>
        <div class="ml-2 d-flex align-center" style="min-width: 0">
            <div :class="{textOverflowEllipsis: !allowOverflow}" style="color: #000000de">
                <slot v-if="!loading" name="value" />
            </div>
            <div v-if="loading" :style="['min-width: 0',  ]">
                <v-skeleton-loader class="skeletonLoader" max-width="100%" :width="loadingWidth" min-width="0" loading type="list-item"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NameValueFlex",
    props: {
        height: {
            type: String,
            required: false,
            default: "auto"
        },
        loadingWidth: {
            type: String,
            required: false,
            default: "300"
        },
        loadingHeight: {
            type: String,
            required: false,
            default: "26"
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        nameWidth: {
            type: String,
            required: false,
            default: "100"
        },
        nameFlex: {
            type: Boolean,
            required: false,
            default: false
        },
        allowOverflow: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style>

.skeletonLoader > .v-skeleton-loader__list-item
{
    height: 12px;
}

</style>

<style scoped>

.noFlex {
    flex-shrink: 0;
    flex-grow: 0;
}

.textOverflowEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

</style>