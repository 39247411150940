var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "height100 d-flex align-end",
      staticStyle: { "user-select": "none" },
    },
    [
      _vm.addressBalance.length > 0 && !_vm.addressBalanceLoading
        ? _c(
            "div",
            { staticClass: "width100" },
            [_c("HighCharts", { attrs: { options: _vm.chartOptions } })],
            1
          )
        : _vm.addressBalanceLoading
        ? _c(
            "div",
            {
              staticClass:
                "d-flex align-center justify-center height100 width100",
            },
            [
              _c("div", { staticClass: "font-weight-light" }, [
                _vm._v("\n            Loading balance chart\n        "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-2" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: _vm.currency.unit },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }