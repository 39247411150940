var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { staticClass: "px-2 py-1", attrs: { row: "" } },
        [
          _c("b", [_vm._v("Owner")]),
          _vm._v(" "),
          _c("span", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.data.name))]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("div", { staticClass: "no-wrap pl-2" }, [
            _vm._v(
              _vm._s(
                _vm._f("moment")(_vm.data.created_at, "YYYY-MM-DD HH:mm:ss Z")
              )
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mx-2 pb-1", attrs: { column: "", "align-start": "" } },
        [
          _c("b", [_vm._v("Addresses:")]),
          _vm._v(" "),
          !_vm.showMore
            ? [
                _vm._l(_vm.data.addresses.slice(0, 5), function (address) {
                  return [
                    address.crypto
                      ? _c(
                          "router-link",
                          {
                            staticClass:
                              "text-overflow ml-2 text-decoration-none",
                            attrs: {
                              to:
                                "/" +
                                address.crypto +
                                "/address/" +
                                address.address,
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "currencyImg mx-2",
                              attrs: {
                                src: _vm._f("createImageUrl")(
                                  "cryptoIcons/color/" +
                                    address.crypto.toLowerCase() +
                                    ".svg"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("code", { staticClass: "elevation-0" }, [
                              _vm._v(_vm._s(address.address)),
                            ]),
                          ]
                        )
                      : _c("code", { staticClass: "elevation-0" }, [
                          _vm._v(_vm._s(address.address)),
                        ]),
                  ]
                }),
                _vm._v(" "),
                _vm.data.addresses.length > 5
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mx-auto d-block mt-2",
                        attrs: {
                          color: "primary",
                          dark: "",
                          small: "",
                          depressed: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showMore = true
                          },
                        },
                      },
                      [
                        _vm._v("\n                Show more\n                "),
                        _c("v-icon", [_vm._v("expand_more")]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : [
                _vm._l(_vm.data.addresses, function (address) {
                  return [
                    address.crypto
                      ? _c(
                          "router-link",
                          {
                            staticClass:
                              "text-overflow ml-2 text-decoration-none",
                            attrs: {
                              to:
                                "/" +
                                address.crypto +
                                "/address/" +
                                address.address,
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "currencyImg mx-2",
                              attrs: {
                                src: _vm._f("createImageUrl")(
                                  "cryptoIcons/color/" +
                                    address.crypto.toLowerCase() +
                                    ".svg"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("code", { staticClass: "elevation-0" }, [
                              _vm._v(_vm._s(address.address)),
                            ]),
                          ]
                        )
                      : _c("code", { staticClass: "elevation-0" }, [
                          _vm._v(_vm._s(address.address)),
                        ]),
                  ]
                }),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "mx-auto d-block mt-2",
                    attrs: {
                      color: "primary",
                      dark: "",
                      small: "",
                      depressed: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showMore = false
                      },
                    },
                  },
                  [
                    _vm._v("\n                Show less\n                "),
                    _c("v-icon", [_vm._v("expand_less")]),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }