var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-expansion-panels",
    {
      model: {
        value: _setup.expandedPanels,
        callback: function ($$v) {
          _setup.expandedPanels = $$v
        },
        expression: "expandedPanels",
      },
    },
    [
      _c(
        "v-expansion-panel",
        [
          _c(
            "v-expansion-panel-header",
            {
              staticStyle: {
                padding: "8px",
                "min-height": "32px",
                "font-size": "14px",
              },
              attrs: { value: true },
              scopedSlots: _vm._u(
                [
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _vm._t("header.right", function () {
                          return [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(
                                "\n                        $expand\n                    "
                              ),
                            ]),
                          ]
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              _c(
                "div",
                [
                  _vm._t("header.left", function () {
                    return [
                      _c(
                        "v-icon",
                        { attrs: { color: _vm.iconColor, left: "" } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.icon) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.headerText) +
                          "\n                "
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c("v-expansion-panel-content", [_vm._t("default")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }