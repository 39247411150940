<script setup>
import {BaseModalDialog} from "@/components/common";
import {ref} from "vue";
import DataGridAddButton from "@/components/DataGrid/Actions/Common/Buttons/DataGridAddButton.vue";

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: "Add Items"
    },
    saveButtonText: {
        type: String,
        required: false,
        default: "Add Items"
    },
    addItemsButtonDisabled: {
        type: Boolean,
        required: false,
        default: false
    },
    loading: {
        type: Boolean,
        required: true
    },
    modalWidth: {
        type: String,
        required: false,
        default: "30%"
    }
})

const modalRef = ref(null)
const onClose = () => {
    modalRef.value.setDisabled(false)
    modalRef.value.close()
}

const emit = defineEmits(["action-finished-event", "submit"])

const setDisabled = (bool) => modalRef.value.setDisabled(bool)

defineExpose({setDisabled, onClose})
</script>

<template>
    <div>
        <BaseModalDialog
            ref="modalRef"
            :title="title"
            :width="modalWidth">
            <template #content>
                <slot/>
            </template>
            <template #footer="{close, setDisabled}">
                <v-btn :disabled="loading" small class="white--text" color="grey" @click="onClose">
                    Cancel
                </v-btn>
                <v-spacer/>
                <v-btn small :loading="loading" :disabled="addItemsButtonDisabled" color="success"
                       @click="emit('submit')">
                    {{ saveButtonText }}
                </v-btn>
            </template>
        </BaseModalDialog>
        <DataGridAddButton @click="modalRef.open()"/>
    </div>
</template>

<style scoped>

</style>