import apiClient from "@/services/apiClient";
import router from "../../router";
import axios, {Cancel} from "axios";
import mappers from "models/LimitedPaginationModel";
import Vue from "vue";

const state = {
    addressClustersLoading: false,
    local_clusters: [],

    addressClusterLoading: {},
    currentClustersCluster: null,

    addressClusterCounting: {},
    addressClusterCount: {},

    addressClusterData: {},
    addressClusterPagination: {},
    addressClusterHistoryFallback: {},
};

const getters = {
    addressClustersLoading: (state) => state.addressClustersLoading,
    local_clusters: (state) => state.local_clusters,

    addressClusterHistoryFallback: (state) => state.addressClusterHistoryFallback,
    currentClustersCluster: (state) => state.currentClustersCluster,
    addressClusterLoading: (state) => state.addressClusterLoading,
    addressClusterPagination: (state) => state.addressClusterPagination,
    addressClusterData: (state) => state.addressClusterData,
    addressClusterCounting: (state) => state.addressClusterCounting,
    addressClusterCount: (state) => state.addressClusterCount,
};

const mutations = {
    ADDRESS_CLUSTERS_SET_CURRENT(state, data) {
        state.currentClustersCluster = data;
    },
    ADDRESS_CLUSTERS_LOADING(state) {
        state.addressClustersLoading = true;
    },
    ADDRESS_CLUSTERS_SET_DATA(state, data) {
        state.local_clusters = data.local_clusters
    },
    ADDRESS_CLUSTERS_FINISHED_LOADING(state) {
        state.addressClustersLoading = false;
    },
    ADDRESS_CLUSTER_LOADING(state, data) {
        if (state.addressClusterLoading.hasOwnProperty(data.entity)) {
            state.addressClusterLoading[data.entity].token.cancel()
        }
        Vue.set(state.addressClusterLoading, data.entity, {token: axios.CancelToken.source()})
    },
    ADDRESS_CLUSTER_SET_DATA(state, data) {
        Vue.set(state.addressClusterData, data.entity, data.data)
    },
    ADDRESS_CLUSTER_SET_PAGINATION(state, data) {
        Vue.set(state.addressClusterPagination, data.entity, data.pagination)
    },
    ADDRESS_CLUSTER_SET_COUNTING(state, data) {
        if (state.addressClusterCounting.hasOwnProperty(data.entity)) {
            state.addressClusterCounting[data.entity].token.cancel()
        }
        Vue.set(state.addressClusterCounting, data.entity, {token: axios.CancelToken.source()})
    },
    ADDRESS_CLUSTER_SET_COUNT(state, data) {
        Vue.set(state.addressClusterCount, data.entity, data.data)
    },
    ADDRESS_CLUSTER_FINISHED_COUNTING(state, data) {
        Vue.delete(state.addressClusterCounting, data.entity)
    },
    ADDRESS_CLUSTER_FINISHED_LOADING(state, data) {
        Vue.delete(state.addressClusterLoading, data.entity)
    },
    ADDRESS_CLUSTER_LOADING_CANCEL(state, data) {
        if (state.addressClusterLoading.hasOwnProperty(data.entity)) {
            state.addressClusterLoading[data.entity].token.cancel()
        }
        Vue.delete(state.addressClusterLoading, data.entity)
    },
    ADDRESS_CLUSTER_COUNTING_CANCEL(state, data) {
        if (state.addressClusterCounting.hasOwnProperty(data.entity)) {
            state.addressClusterCounting[data.entity].token.cancel()
        }
        Vue.delete(state.addressClusterCounting, data.entity)
    },
    ADDRESS_CLUSTER_FALLBACK_SET(state, data) {
        Vue.set(state.addressClusterHistoryFallback, data.entity, data.pagination)
    },
    ADDRESS_CLUSTER_STATE_RESET(state) {
        //cluster, funds, Interactions
        state.addressClustersLoading = false
        state.local_clusters = []

        //cluster specific
        state.currentClustersCluster = null
        state.addressClusterLoading = {}
        state.addressClusterPagination = {}
        state.addressClusterData = {}
        state.addressClusterCounting = {}
        state.addressClusterCount = {}
        state.addressClusterHistoryFallback = {}
    }
};

const actions = {
    async loadAddressClusters({commit, dispatch}, {currency, addressId}) {
        commit("ADDRESS_CLUSTERS_LOADING");
        try {
            const result = await apiClient.GET(
                `${currency}/cryptoaddress/${addressId}/cryptoclusters`
            );
            commit("ADDRESS_CLUSTERS_SET_DATA", result);
        } catch (error) {
            dispatch("error", error.userFriendlyMessage);
            commit("ADDRESS_CLUSTERS_SET_DATA", {local_clusters: []});
        } finally {
            commit("ADDRESS_CLUSTERS_FINISHED_LOADING");
        }
    },

    async loadAddressCluster(
        {commit, dispatch},
        {currency, addressId, entityAddress, pagination}
    ) {
        let limitedPagination = mappers.MapToServerSidePagination(pagination)
        try {
            commit("ADDRESS_CLUSTER_LOADING", {entity: entityAddress})
            const result = await apiClient.GET(
                `${currency}/cryptocluster/${entityAddress}/addresses`,
                {
                    params: limitedPagination,
                    cancelToken: state.addressClusterLoading[entityAddress].token.token
                }
            );
            commit("ADDRESS_CLUSTER_SET_DATA", {entity: entityAddress, data: result})
            commit("ADDRESS_CLUSTER_FINISHED_LOADING", {entity: entityAddress})
            commit("ADDRESS_CLUSTER_FALLBACK_SET", {entity: entityAddress, pagination: pagination})
            dispatch("success", result.message);
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch('error', error.userFriendlyMessage)
                commit("ADDRESS_CLUSTER_FINISHED_LOADING", {entity: entityAddress})
            }
        }
    },

    async countCluster(
        {commit, dispatch},
        {entityAddress}
    ) {
        try {
            commit("ADDRESS_CLUSTER_SET_COUNTING", {entity: entityAddress})
            const response = await apiClient.GET(
                router.currentRoute.params.currency + `/cryptocluster/` + entityAddress  + `/addresses/count`,
                {
                    withCredentials: true,
                    cancelToken: state.addressClusterCounting[entityAddress].token.token
                },
            );
            commit("ADDRESS_CLUSTER_SET_COUNT", {entity: entityAddress, data: response})
            commit("ADDRESS_CLUSTER_FINISHED_COUNTING", {entity: entityAddress})
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                commit("ADDRESS_CLUSTER_FINISHED_COUNTING", {entity: entityAddress})
                dispatch('error', error.userFriendlyMessage)
            }
        }
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
};
