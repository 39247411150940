var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-btn",
    {
      staticClass: "mr-2",
      attrs: { small: "", color: "success", to: { name: "GraphExplorer" } },
    },
    [
      _c("v-icon", { attrs: { left: "" } }, [
        _vm._v("\n        add_box\n    "),
      ]),
      _vm._v("\n    New interactive graph\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }