var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _setup.loading
        ? _c(
            "div",
            [
              _c("v-btn", {
                attrs: {
                  link: "",
                  small: "",
                  icon: "",
                  loading: "",
                  color: _vm.currency.hexColor,
                },
              }),
            ],
            1
          )
        : _setup.props.addressData?.dataState === _setup.DataState.Error
        ? _c(
            "v-btn",
            {
              attrs: { small: "", icon: "", dark: "", color: "#EE0000" },
              on: {
                click: function ($event) {
                  return _setup.props.addressData.getData({
                    currency: _setup.props.currency.unit,
                    address: _setup.props.address,
                  })
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-reload")])],
            1
          )
        : _setup.data && _setup.data.confirmedTransactions === 0
        ? _c("div", { staticClass: "font-italic" }, [_vm._v("Never used")])
        : _setup.data
        ? _c(
            "div",
            [
              _c(
                "v-chip",
                {
                  staticStyle: { height: "26px" },
                  attrs: {
                    color: _setup.data.fiats.crypto,
                    label: "",
                    "text-color": "white",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "subtitle-1 font-weight-bold",
                      staticStyle: { "vertical-align": "middle" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_setup.data.confirmedBalance) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "subtitle-1 pl-1 text--white",
                      staticStyle: { "vertical-align": "middle" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.currency.unit) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }