var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.blockNotMined
    ? _c("div", { staticClass: "fontMonospace" }, [
        _vm._v("\n    " + _vm._s(_vm.blockNotMinedMessage) + "\n"),
      ])
    : _c(
        "router-link",
        {
          staticClass: "link fontMonospace",
          attrs: {
            to: {
              name: "Block",
              params: {
                block: _vm.blockHeight,
                currency: _setup.currencyUnit,
              },
            },
          },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm._f("formatNumber")(_vm.blockHeight)) + "\n"
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }