var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: {
      title: _vm.isEditModal ? "Edit file" : "Upload file",
      loading: _vm.loading,
    },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c("validation-provider", {
                    attrs: { rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-file-input", {
                              attrs: {
                                "error-messages": errors,
                                success: valid,
                                required: "",
                                "prepend-icon": "cloud_upload",
                                accept: "*",
                                label: "Click to browse or drop a file here",
                              },
                              model: {
                                value: _vm.detail.file,
                                callback: function ($$v) {
                                  _vm.$set(_vm.detail, "file", $$v)
                                },
                                expression: "detail.file",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  !_vm.isEditModal
                    ? _c("div", {
                        on: {
                          drop: function ($event) {
                            $event.preventDefault()
                            return _vm.addDropFiles.apply(null, arguments)
                          },
                          dragover: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("CasePicker", {
                    staticClass: "pt-3",
                    attrs: { rules: "required" },
                    model: {
                      value: _vm.detail.case_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "case_id", $$v)
                      },
                      expression: "detail.case_id",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    outlined: "",
                    color: "error",
                    disabled: _vm.filesModalState === _vm.modalState.Pending,
                  },
                  on: { click: close },
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "success",
                    loading: _vm.filesModalState === _vm.modalState.Pending,
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save(close, setDisabled)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.isEditModal ? "Save file detail" : "Upload file")
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }