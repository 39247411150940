var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.wasActiveInTor
    ? _c(
        "div",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "img",
                          _vm._g(
                            {
                              staticStyle: { width: "24px" },
                              attrs: {
                                src: "/img/ico/tor-onion.svg",
                                alt: "Tor",
                              },
                            },
                            on
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2690448116
              ),
            },
            [_vm._v("\n        This node was active in the Tor network.\n    ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }