var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn-toggle",
                {
                  staticStyle: {
                    "border-bottom-right-radius": "0",
                    "border-top-right-radius": "0",
                  },
                  attrs: {
                    "active-class": "font-weight-bold",
                    "background-color": "grey",
                    color: "grey lighten-4",
                    dense: "",
                    light: "",
                    mandatory: "",
                  },
                  model: {
                    value: _vm.currentModeModel,
                    callback: function ($$v) {
                      _vm.currentModeModel = $$v
                    },
                    expression: "currentModeModel",
                  },
                },
                [
                  _c("v-btn", { on: { click: _vm.ChangeToCryptoMode } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "rgba(0, 0, 0, 0.87) !important",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.currentCryptoName) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-btn", { on: { click: _vm.ChangeToFiatMode } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "rgba(0, 0, 0, 0.87) !important",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.currentFiatName) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "picker grey lighten-2",
                      attrs: { elevation: "0", light: "" },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", [
                    _vm._v("\n                mdi-menu-down\n            "),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        {
          staticClass: "picker elevation-0",
          attrs: { disabled: _vm.settingsLoading, dense: "" },
        },
        [
          _c(
            "div",
            [
              _c(
                "v-list-item-group",
                {
                  staticClass: "d-flex",
                  attrs: { mandatory: "" },
                  on: { change: _vm.ChangeFiat },
                  model: {
                    value: _vm.currentFiatModel,
                    callback: function ($$v) {
                      _vm.currentFiatModel = $$v
                    },
                    expression: "currentFiatModel",
                  },
                },
                _vm._l(this.FiatColumnIndexes, function (columnIndex) {
                  return _c(
                    "div",
                    { key: columnIndex },
                    _vm._l(
                      _vm.fiats.slice(
                        columnIndex * _vm.fiatsPerColumn,
                        columnIndex * _vm.fiatsPerColumn + _vm.fiatsPerColumn
                      ),
                      function (item, index) {
                        return _c(
                          "v-list-item",
                          {
                            key: item.unit,
                            attrs: {
                              "input-value":
                                index + columnIndex * _vm.fiatsPerColumn,
                              value: index + columnIndex * _vm.fiatsPerColumn,
                            },
                          },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  domProps: { textContent: _vm._s(item.unit) },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }