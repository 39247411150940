<script setup>
import {computed, inject, ref} from "vue";
import store from "@/store";
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import NameValueFlex from "@/components/common/NameValueFlex.vue";
import AddressCategoryIcon from "@/components/address/AddressCategoryIcon.vue";
import Copyable from "@/components/common/Copyable.vue";

const currency = computed(() => store.getters.currency)
const data = inject("data")
const loading = inject("Loading")
const clusterName = inject("entityId")


const showDesc = ref(false)
const computedIsDescValid = computed(() => {
    if (!data)
    {
        return false
    }
    return data.value.cluster.description !== null && data.value.cluster.description !== '';
})

const computedDisableDesc = computed(() => {
    return !loading.value && data.value && (data.value?.cluster?.description === null || data.value?.cluster?.description === '')
})

</script>

<template>
    <FancyCard>
        <template #header>
            <div class="d-flex align-center">
                <v-icon class="ml-1" left>mdi-hub</v-icon>
                <span class="font-weight-bold">{{currency.name}}</span>&nbsp;Cluster
            </div>
        </template>
        <template #body>
            <NameValueFlex>
                <template #name>
                    Name:
                </template>
                <template #value>
                    <Copyable :on-copy="clusterName">
                        <template #text="{on, attr}">
                            <div v-on="on" v-bind="attr" class="fontMonospace" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{clusterName}}</div>
                        </template>
                    </Copyable>
                </template>
            </nameValueFlex>
            <NameValueFlex :loading="loading" loading-width="150">
                <template #name>
                    Type:
                </template>
                <template #value>
                    <span class="font-italic">{{ data.cluster.isCustom ? "Custom" : "co-spent" }}</span>
                </template>
            </nameValueFlex>
            <NameValueFlex :loading="loading" loading-width="100">
                <template #name>
                    # of Addresses:
                </template>
                <template #value>
                    <span class="fontMonospace">
                        {{ data.cluster.totalAddresses | formatNumber }}
                    </span>
                </template>
            </nameValueFlex>
            <NameValueFlex :loading="loading" loading-width="130">
                <template #name>
                    Description:
                </template>
                <template #value>
                    <div class="d-flex">
                        <div v-if="computedIsDescValid && !showDesc" style="  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="fontMonospace">
                            {{data.cluster.description}}
                        </div>
                        <div class="ml-1" style="flex-grow: 0; flex-shrink: 0">
                            <v-btn class="font-italic" v-if="computedIsDescValid" elevation="0" small @click="showDesc = !showDesc" style="height: 23px; width: 88px ;background-color: white">
                                {{ showDesc ? 'Hide' : 'Show' }}
                                <v-spacer/>
                                <span v-if="showDesc">
                                    <v-icon right>mdi-chevron-up</v-icon>
                                </span>
                                <span v-else>
                                    <v-icon right>mdi-chevron-down</v-icon>
                                </span>
                            </v-btn>
                        </div>
                    </div>
                    <div>
                        <span v-if="!computedIsDescValid" class="font-weight-bold">
                            None
                        </span>
                    </div>
                </template>
            </nameValueFlex>
            <v-expand-transition>
                <div v-if="showDesc">
                    <v-textarea
                        :disabled="computedDisableDesc"
                        class="clusterDescriptionArea"
                        v-if="data"
                        style="font-size: 14px"
                        readonly
                        no-resize
                        dense
                        name="Description"
                        v-model="data.cluster.description"
                    ></v-textarea>
                </div>
            </v-expand-transition>
            <div class="mt-1" v-if="!loading && data">
                <AddressCategoryIcon
                    class="mr-2 mb-1"
                    v-for="cat in data.cluster.categories"
                    v-bind:key="cat.id"
                    :iconItem="cat"
                    medium
                />
            </div>
        </template>
    </FancyCard>
</template>

<style scoped>

.clusterDescriptionArea textarea {
    line-height: 20px !important;
    font-family: 'Roboto Mono', monospace !important;
}

</style>