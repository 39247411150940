<script setup>
import GenericPicker from "@/components/common/Pickers/GenericPicker.vue";

const props = defineProps({
    items: {
        type: Array,
    },
    value: {
        type: Object,
    },
    getItems: {
        type: Function,
    },
    loading: {
        type: Boolean,
    },
    itemText: {
        type: String
    },
    displayComponent: {
        required: true
    },
    placeholder: {
        type: String,
        required: false
    },
    readOnly: {
        type: Boolean,
        required: false,
        default: false
    },
    convertItemTextToString: {
        type: Boolean,
        required: false,
        default: false
    },
    innerClass: {
        type: String,
        required: false
    }
})

const emit = defineEmits(['input'])

</script>

<template>
    <GenericPicker :item-text="itemText"
                   :convertItemTextToString="convertItemTextToString"
                   :items="items"
                   :get-items="getItems"
                   :innerClass="innerClass"
                   :value="value"
                   @input="emit('input', $event)"
                   full-width
                   denser
                   outlined
                   :readonly="readOnly"
                   autofocus
                   prepend-inner-icon="search"
                   :placeholder="placeholder"
                   no-prepend-icon
                   no-selection-slot
                   :loading="loading">
        <template #item="{item}">
            <Component :is="displayComponent" :item="item"/>
        </template>
        <template #hint>
            Start typing to search
        </template>
    </GenericPicker>
</template>

<style scoped>

</style>