<template>
    <div v-if="onCopy && (showExtra || isCopyable || actAsLink)">
        <v-hover v-slot="{ hover }">
            <div :class="`d-flex justify-${justify}`">
                <div class="d-flex" :style="`width: ${containerWidth}`" style="max-width: 100%; position: relative;">
                    <slot name="text"/>
                    <div class="d-flex justify-end" style="position: absolute; width: 100%">
                        <div v-if="hover && isCopyable" style="position: absolute" class="d-flex flex-row" :style="!aside && {'right': '0px'}">
                            <v-btn
                                min-width="0px"
                                style="padding: 4px; margin-top: 2px;"
                                :style="[!dark && {'background-color': '#f0eeee'}]"
                                x-small
                                elevation="0"
                                @click.prevent=""
                                @click.stop="() => copy(onCopy)"
                            >
                                <v-icon :dark="dark" small>content_copy</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="hover && showExtra"
                                min-width="20px"
                                :style="[!dark && {'background-color': '#f0eeee'}]"
                                x-small
                                elevation="0"
                                style="padding: 4px; margin-top: 2px;"
                                @click.prevent=""
                                @click="$emit('extra-click-event',$event)"
                                @click.stop=""
                            >
                                <v-icon small :dark="dark">fa-solid fa-ellipsis-vertical</v-icon>
                            </v-btn>
                            <slot name="permanent"/>
                        </div>
                    </div>
                </div>
            </div>
        </v-hover>
    </div>
</template>

<script>
import {copy} from "@/utils";

export default {
    name: "Copyable",
    methods: {copy},
    props: {
        onCopy: {
            type: String,
            required: true
        },
        dark: {
            type: Boolean,
            required: false,
            default: false
        },
        justify: {
            type: String,
            required: false,
            default: "center"
        },
        aside: {
            type: Boolean,
            required: false,
            default: false,
        },
        showExtra: {
            type: Boolean,
            required: false,
            default: false,
        },
        isCopyable: {
            type: Boolean,
            required: false,
            default: true
        },
        actAsLink: {
            type: Boolean,
            required: false,
        },
        containerWidth: {
            type: String,
            required: false,
            default: "auto"
        }
    }
}
</script>

<style scoped>

</style>