var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("v-checkbox", {
        staticClass: "mr-4 ma-0 pa-0",
        attrs: {
          error: _vm.error,
          dense: "",
          "hide-details": "",
          color: _vm.color,
          label: "Notification",
          readonly: _vm.readonly,
        },
        model: {
          value: _vm.notification,
          callback: function ($$v) {
            _vm.notification = $$v
          },
          expression: "notification",
        },
      }),
      _vm._v(" "),
      _c("v-checkbox", {
        staticClass: "ma-0 pa-0 pr-4",
        attrs: {
          error: _vm.error,
          dense: "",
          "hide-details": "",
          color: _vm.color,
          label: "Email",
          readonly: _vm.readonly,
        },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }