<template>
    <BaseModalDialog
        :title="isEditModal ? 'Edit Cluster Metadata' : 'Create new Cluster Metadata'"
        @load-modal-state-event="loadState"
        :loading="loading"
        width="60%"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <fancy-card no-shadow>
                    <template #header>
                        <span class="font-weight-medium">
                            Cluster Metadata
                        </span>
                    </template>
                    <template #body>
                        <v-row class="ma-2">
                            <v-col class="py-2">
                                <v-text-field-validateable
                                    :readonly="isEditModal"
                                    rules="required|entity_id"
                                    label="Entity ID"
                                    v-model="detail.entity_id"
                                    outlined
                                />
                            </v-col>

                            <v-col class="py-2">
                                <BlockchainCryptoPicker
                                    :readonly="isEditModal"
                                    :currencies="currencies"
                                    v-model="detail.crypto"
                                    rules="required"
                                />
                            </v-col>

                            <v-col class="py-2">
                                <v-text-field
                                    :disabled="true"
                                    label="Created At"
                                    :value="detail.created_at && detail.created_at | moment('YYYY-MM-DD HH:mm:ss Z')"
                                    outlined
                                />
                            </v-col>
                        </v-row>

                        <v-row class="ma-2">
                            <v-col class="py-2">
                                <CategoryPicker
                                    :categories="categories"
                                    v-model="detail.categoryIds"
                                    dense
                                />
                            </v-col>
                            <v-col class="py-2">
                                <OwnerPickerWithDisplay v-model="detail.owners"/>
                            </v-col>
                        </v-row>

                        <v-row class="ma-2">
                            <v-col class="py-2">
                                <v-text-area-validateable
                                    :enabled="!isEditModal"
                                    rules="required"
                                    label="Description"
                                    v-model="detail.description"
                                    outlined
                                />
                            </v-col>
                        </v-row>
                    </template>
                </fancy-card>
                <fancy-card no-shadow class="mt-2" :outlined="false" :header="false" :elevation="0">
                    <template #body>
                        <v-row class="ma-2">
                            <v-col cols="7">
                                <CsvImporterInput
                                    :csvConfiguration="importCsvConfiguration"
                                    @import-successful="importAddresses"
                                    @import-failed="importAddressesFailed"
                                />
                            </v-col>
                            <v-col align="center">
                                <CorruptedDataModalDialog
                                    v-if="corruptedData.length > 0"
                                    :corruptedData="corruptedData"
                                    @close-event="closeCorruptedDataDialog"
                                >
                                    <template #showbutton="{ on }">
                                        <v-btn v-on="on" color="error" outlined>Show corrupted data</v-btn>
                                    </template>
                                </CorruptedDataModalDialog>
                            </v-col>
                            <v-col>
                                <v-row justify="end">
                                    <CreateButton text="Add new Addresses" @click="addNewAddresses" />
                                </v-row>
                            </v-col>
                        </v-row>

                        <div v-if="newAddresses.length > 0">
                            <div>
                                <v-data-table
                                    :headers="addressHeaders"
                                    :items="newAddresses"
                                    style="white-space: nowrap"
                                    :options.sync="newAddressesOptions"
                                    dense
                                    :footer-props="{
                                        'items-per-page-options': [10, 50, 200],
                                        'items-per-page-text': 'Addresses per page',
                                        'show-current-page': true,
                                        'showFirstLastPage': true,
                                        }">
                                    <template slot="item" slot-scope="props">
                                        <tr v-if="!isAddressDeleted(props.item) || newModal">
                                            <td class="pt-1">
                                                <v-text-field-validateable
                                                    style="width: 100%"
                                                    rules="required|crypto"
                                                    v-model="props.item.address"
                                                    label="Address"
                                                    full-width
                                                    outlined
                                                    dense
                                                    class="fontMonospace"/>
                                            </td>
                                            <td>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="ml-2" x-small icon color="red" v-on="on" v-bind="attrs" @click="deleteAddressFromNew(props.index)">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Delete Address</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </template>
                                    <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                                        <span>
                                            {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                                        </span>
                                    </template>
                                </v-data-table>
                            </div>

                            <v-divider class="my-1"/>
                        </div>
                    </template>
                </fancy-card>
                <div v-if="detail.total !== 0">
                    <fancy-card class="mt-2" no-shadow>
                        <template #header>
                            <span class="subtitle-1" style="color: rgba(0,0,0,0.6)">
                            <span style="font-weight: bold">
                                {{detail.total | formatNumber }}</span> Addresses total
                            </span>
                        </template>
                        <template #body>
                            <v-data-table
                                style="white-space: nowrap"
                                dense
                                :headers="addressHeaders"
                                :items="detail.addresses || []"
                                :server-items-length="detail.total"
                                :footer-props="{
                                    'items-per-page-options': [10, 50, 200],
                                    'items-per-page-text': 'Addresses per page',
                                    'show-current-page': true,
                                    'showFirstLastPage': true,
                                }"
                                :loading="addressesLoading"
                                :options.sync="options"
                                @update:page="fetchAddresses"
                                @update:items-per-page="fetchAddresses"
                            >
                                <template slot="item" slot-scope="props">
                                    <tr v-if="!isAddressDeleted(props.item) || newModal">
                                        <td>
                                        <span class="fontMonospace">
                                            {{props.item.address}}
                                        </span>
                                        </td>
                                        <td v-if="!newModal">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="ml-2" x-small icon color="red" v-on="on" v-bind="attrs" @click="deleteAddress(props.item)">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Delete Address</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                    <tr v-else style="background-color: lightgrey">
                                        <td>
                                        <span class="fontMonospace">
                                            {{props.item.address}}
                                        </span>
                                        </td>
                                        <td>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="ml-2" x-small icon color="green" v-on="on" v-bind="attrs" @click="undeleteAddress(props.item)">
                                                        <v-icon>mdi-undo</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Restore Address</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </template>
                                <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                                    <span>
                                        {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                                    </span>
                                </template>
                            </v-data-table>
                        </template>
                    </fancy-card>
                </div>
            </ValidationObserver>
        </template>
        <template #footer="{close, setDisabled}">
            <v-btn
                dark
                outlined
                color="error"
                :disabled="clusterMetadataModalState === modalState.Pending"
                @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                dark
                color="success"
                :loading="clusterMetadataModalState === modalState.Pending"
                outlined
                @click="save(close, setDisabled)"
            >{{ isEditModal ? "Save Cluster Metadata" : "Create Cluster Metadata" }}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import {deepClone, uuidv4} from "@/utils"
import {
    BaseModalDialog,
    BlockchainCryptoPicker,
    TableDeleteModalDialog,
    CreateButton,
    CsvImporterInput,
    CorruptedDataModalDialog,
    CategoryPicker
} from "@/components/common"
import {mapGetters} from "vuex";
import FancyCard from "@/components/common/Cards/FancyCard";
import VTextAreaValidateable from "@/components/common/ValidateableComponents/VTextAreaValidateable";
import {modalState} from "@/components/common/types/modal";
import OwnerPickerWithDisplay from "@/components/ClusterMetadata/OwnerPickerWithDisplay.vue";



export default {
    name: 'ClusterMetadataDetailModal',
    components: {
        OwnerPickerWithDisplay,
        VTextAreaValidateable,
        FancyCard,
        BaseModalDialog,
        BlockchainCryptoPicker,
        TableDeleteModalDialog,
        CreateButton,
        CsvImporterInput,
        CorruptedDataModalDialog,
        CategoryPicker,
    },
    props: {
        owners: {
            type: Array,
            default: () => []
        },
        clusterMetadataDetail: {
            type: Object
        },
        categories: {
            type: Array,
            default: () => []
        },
        currencies: {
            type: Array,
            default: () => []
        },
        isOwnersLoading: {
            type: Boolean,
            default: false
        },
        isClusterMetadataDetailLoading: {
            type: Boolean,
            default: false
        },
        newModal: {
          type: Boolean,
          default: false,
        },
    },
    computed: {
        ...mapGetters(["clusterMetadataModalState"]),
        loading() {
            return this.isClusterMetadataDetailLoading && this.addressesLoading === false
        },
        modalState() {
            return modalState
        }
    },
    data() {
        return {
            addressHeaders: [
                {
                    text: "Address",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Remove",
                    align: "center",
                    sortable: false,
                },
            ],

            addressesLoading: false,
            options: undefined,
            newAddressesOptions: undefined,
            newAddresses: [],
            deletedAddresses: [],

            detail: undefined,
            isEditModal: true,
            importCsvConfiguration: [
                { csvHeader: "Addresses", exportPropertyName: "address", },
            ],
            corruptedData: [],
            closeDialog: null,
            setDisabled: null,
        }
    },
    watch: {
        "detail.owner"(newValue) {
            this.detail.owner_id = newValue?.id
        },
        clusterMetadataModalState(newVal) {

            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('CLUSTER_METADATA_MODAL_SET_STATE', modalState.Initial)
            } else if (newVal === modalState.Error)
            {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('CLUSTER_METADATA_MODAL_SET_STATE', modalState.Initial)
            }
        }
    },
    methods: {
        async save(close, setDisabled) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.closeDialog = close
                this.setDisabled = setDisabled
                setDisabled(true)
                if (this.newModal)
                {
                    this.$emit("save-event", { clusterMetadataDetail: {...this.detail, addresses: this.newAddresses} })
                } else
                {
                    this.$emit("save-event", { patch: { body: this.createPatchBody(), id: this.detail?.id } })
                }
            }
        },
        async fetchAddresses()
        {
            this.addressesLoading = true
            await this.$store.dispatch("loadClusterMetadataDetail", {
                currency: this.detail.crypto,
                id: this.detail.id,
                pagination: {page: this.options.page, itemsPerPage: this.options.itemsPerPage}
            })
            this.addressesLoading = false
            this.detail.addresses =  this.clusterMetadataDetail.addresses
        },
        loadState() {
            this.addressesLoading = false
            this.options = undefined
            this.newAddresses = []
            this.deletedAddresses = []

            if (this.clusterMetadataDetail) {
                this.isEditModal = true
                this.detail = deepClone(this.clusterMetadataDetail)
            }
            else {
                this.isEditModal = false
                this.detail = {
                    entity_id: "",
                    crypto: "",
                    description: "",
                    addresses: [],
                    owner: null,
                    owner_id: null,
                    total: 0
                }
            }
        },
        isAddressDeleted(addr)
        {
            return this.deletedAddresses.some(x => x.id === addr.id)
        },

        //Operation on top of NEW addresses
        addNewAddresses() {
            this.newAddresses.push({address: ""})
        },
        deleteAddressFromNew(index) {
            index = index + (this.newAddressesOptions.page-1) * this.newAddressesOptions.itemsPerPage
            this.newAddresses = this.newAddresses.filter( (x,i) => i !== index)
        },

        //Operation on top of already existing addresses
        undeleteAddress(addressObj) {
            this.deletedAddresses = this.deletedAddresses.filter(x => x.id !== addressObj.id)
        },
        deleteAddress(addressObj) {
            this.deletedAddresses.push(addressObj)
        },

        createPatchBody()
        {
            let patch = []
            //Addresses
            patch.push({op: "add", path: "addresses", value: this.newAddresses.map(x => x.address).filter(x => x !== "" && this.deletedAddresses.every(y => x !== y.address))})
            patch.push({op: "remove", path: "addresses", value: deepClone(this.deletedAddresses.filter(x => this.newAddresses.every(y => x.address !== y.address)))})
            //description
            patch.push({op: "replace", path: "description", value: this.detail.description !== this.clusterMetadataDetail.description ? this.detail.description : "" })
            //entity_id
            patch.push({op: "replace", path: "entity_id", value: this.detail.entity_id !== this.clusterMetadataDetail.entity_id ? this.detail.entity_id : "" })
            //categories
            patch.push({op: "add", path: "categories", value: this.detail.categoryIds.filter(x => this.clusterMetadataDetail.categories.every(y => x !== y.id))})
            patch.push({op: "remove", path: "categories", value: this.clusterMetadataDetail.categories.map(x => x.id).filter(x => this.detail.categoryIds.every(y => x !== y))})
            //owners
            patch.push({op: "add", path: "owners", value: this.detail.owners.map(x => x.id).filter(x => this.clusterMetadataDetail.owners.every(y => x !== y.id))})
            patch.push({op: "remove", path: "owners", value: this.clusterMetadataDetail.owners.map(x => x.id).filter(x => this.detail.owners.every(y => x !== y.id))})
            patch = patch.filter(x => x.value.length > 0)
            return patch
        },

        importAddresses({ importedData, corruptedData }) {
            //decorate data with _id for  proper function of delete
            const withIds = importedData.map((i, index) => ({ ...i, _id: uuidv4() }))
            this.newAddresses = this.newAddresses.concat(withIds)
            if (corruptedData.length > 0) {
                this.corruptedData = corruptedData
                this.$store.dispatch("warning", `Added ${withIds.length} addresses, but ${corruptedData.length} records were corrupted.`)
            }
            else {
                this.$store.dispatch("success", `Added ${withIds.length} addresses.`)
            }
        },
        importAddressesFailed({ message }) {
            this.$store.dispatch("error", message)
        },
        closeCorruptedDataDialog(close) {
            this.corruptedData = []
            close()
        },
        ownerFilterChanged({ filter }) {
            this.$emit("owner-filter-changed-event", { filter })
        },
    }
}
</script>