<script setup>

const props = defineProps({
    text: {
        type: String
    },
    icon: {
        type: String
    }
})

</script>

<template>
    <div class="d-flex">
        <div class="d-flex align-center width100">
            <div class="width100">
                <v-divider/>
            </div>
        </div>
        <div class="flex-grow-0 flex-shrink-0 mx-2">
            <nobr class="d-flex">
                <slot name="default">
                    <v-icon small v-if="icon">
                        {{ icon }}
                    </v-icon>
                    {{ text }}
                </slot>
            </nobr>
        </div>
        <div class="d-flex align-center width100">
            <div class="width100">
                <v-divider/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderLined"
}
</script>

<style scoped>

</style>