<template>
    <v-layout row>
        <v-btn
            class="white"
            depressed
            @click="$emit('show-transaction-click-event', loadTransactionSides.BOTH)"
        >
            Show on current graph
        </v-btn>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn
                    @click="$emit('show-transaction-click-event', loadTransactionSides.LEFT)"
                    icon
                    v-on="on"
                >
                    <v-icon>subdirectory_arrow_left</v-icon>
                </v-btn>
            </template>

            <span>Show only inputs</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn
                    @click="$emit('show-transaction-click-event', loadTransactionSides.RIGHT)"
                    icon
                    v-on="on"
                >
                    <v-icon>subdirectory_arrow_right</v-icon>
                </v-btn>
            </template>

            <span>Show only outputs</span>
        </v-tooltip>
    </v-layout>
</template>

<script setup>
import { loadTransactionSides } from "../../../store/modules/Transactions";

</script>
