var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-y height100" },
    [
      _c(
        "v-container",
        { staticClass: "height100 px-4", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "" } },
            [
              _c("h1", [_vm._v("Graphs")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("CreateInteractiveGraphButton"),
              _vm._v(" "),
              _c("GraphDetailModal", {
                attrs: { currencies: _vm.currencies },
                on: { "save-event": _vm.createGraph },
                scopedSlots: _vm._u([
                  {
                    key: "showbutton",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "CreateButton",
                          _vm._g({ attrs: { text: "Create graph" } }, on)
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs15: "" } },
                [
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-0 border mt-2",
                    attrs: {
                      "footer-props": {
                        "items-per-page-options": [
                          10,
                          30,
                          50,
                          { text: "All", value: -1 },
                        ],
                        "show-current-page": true,
                        showFirstLastPage: true,
                      },
                      headers: _vm.headers,
                      items: _vm.graphs.data,
                      loading: _vm.graphsLoading,
                      options: _vm.filter,
                      "server-items-length": _vm.graphs.total
                        ? _vm.graphs.total
                        : 0,
                      dense: "",
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.filter = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.headers, function (h) {
                          return {
                            key: `header.${h.value}`,
                            fn: function ({ header }) {
                              return [
                                h.value === "name"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      attrs: {
                                        label: header.text,
                                        "append-icon": "search",
                                        clearable: "",
                                        "hide-details": "",
                                        "single-line": "",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.debouncedFilter.filterName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterName",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "description"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      attrs: {
                                        label: header.text,
                                        "append-icon": "search",
                                        clearable: "",
                                        "hide-details": "",
                                        "single-line": "",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.debouncedFilter.filterDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterDescription",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "crypto"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: {
                                          "margin-bottom": "auto",
                                          "padding-bottom": "auto",
                                        },
                                      },
                                      [
                                        _c("BlockchainCryptoPickerSlim", {
                                          attrs: {
                                            currencies:
                                              _vm.allCurrenciesExceptUnknown,
                                            dense: "",
                                            "hide-details": "",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                          model: {
                                            value: _vm.filter.filterCurrency,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filter,
                                                "filterCurrency",
                                                $$v
                                              )
                                            },
                                            expression: "filter.filterCurrency",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "count"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      attrs: {
                                        label: header.text,
                                        min: 0,
                                        clearable: "",
                                        "hide-details": "",
                                        "hide-spin-buttons": "",
                                        "single-line": "",
                                        type: "number",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.debouncedFilter.filterConfigs,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterConfigs",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterConfigs",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "is_hidden"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(h.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "created_at"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(h.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "created_by"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(h.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "updated_at"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(h.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "action"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: {
                                          "padding-top": "20px",
                                          "padding-bottom": "26px",
                                        },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(h.text))])]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          }
                        }),
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "tr",
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-left dynamicTruncate",
                                    },
                                    [
                                      _c("RouteToGraph", {
                                        attrs: { "graph-object": item },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-hover", {
                                    attrs: { "open-delay": "200" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ hover }) {
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-left dynamicTruncate",
                                                  class: {
                                                    descriptionParentHover:
                                                      hover,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "description-styles",
                                                      class:
                                                        hover &&
                                                        "show-full-text-hover",
                                                      staticStyle: {
                                                        margin: "auto 0 0 0",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.description)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [
                                      _c("BlockchainCryptoBadge", {
                                        attrs: {
                                          crypto: item.crypto,
                                          currencies: _vm.currencies,
                                          iconWidth: 24,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right",
                                      staticStyle: {
                                        "text-align": "center",
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm._f("formatNumber")(item.count)
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color:
                                                              item.is_hidden
                                                                ? "primary"
                                                                : "tertiary",
                                                            dark: "",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.is_hidden
                                                              ? "visibility_off"
                                                              : "visibility"
                                                          ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "Graph is\n                                        " +
                                                _vm._s(
                                                  item.is_hidden
                                                    ? "hidden"
                                                    : "visible"
                                                )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-overflow",
                                      staticStyle: {
                                        "text-align": "center",
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(
                                              item.created_at,
                                              "YYYY-MM-DD HH:mm:ss Z"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-overflow text-left",
                                      staticStyle: {
                                        "text-align": "center",
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [_c("span", [_vm._v(_vm._s(item.creator))])]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-overflow",
                                      staticStyle: {
                                        "text-align": "center",
                                        "vertical-align": "middle",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(
                                              item.updated_at,
                                              "YYYY-MM-DD HH:mm:ss Z"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-xs-left text-overflow",
                                      staticStyle: { "min-width": "95px" },
                                    },
                                    [
                                      _c("GraphDetailModal", {
                                        attrs: {
                                          currencies: _vm.currencies,
                                          graphDetail: item,
                                        },
                                        on: { "save-event": _vm.editGraph },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "showbutton",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "TableEditButton",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          editButtonTooltip:
                                                            "Edit graph",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("TableDeleteModalDialog", {
                                        attrs: {
                                          description: `Do you really want to delete graph ${item.name}`,
                                          itemToDelete: item,
                                          tooltip: "Delete Graph",
                                        },
                                        on: { "delete-event": _vm.deleteGraph },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "footer.page-text",
                          fn: function ({ pageStart, pageStop, itemsLength }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm._f("formatNumber")(pageStart)) +
                                    " - " +
                                    _vm._s(_vm._f("formatNumber")(pageStop)) +
                                    " of " +
                                    _vm._s(
                                      _vm._f("formatNumber")(itemsLength)
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }