<template>
    <div v-if="liveTxsEnabled" class="txContainer mt-1 my-0 pa-0">
        <FancyCard :table="true" :noGutters="true" no-header-gutters>
            <template slot="header">
                <div class="d-flex justify-space-between">
                    <div class="d-flex align-center">
                        <v-icon class="d-block mr-2 ml-1" width="22" style="filter: opacity(0.6); float: left; bottom: 2px">mdi-cash-fast</v-icon>
                        Latest Transactions
                    </div>
                    <div class="d-flex align-center">
                        <v-btn-toggle
                            :value="websockets"
                            multiple
                            borderless
                            v-if="currency"
                        >
                            <v-btn
                                :value="currency.unit"
                                @click="toggleWebsocket(currency)"
                                small
                                :disabled="!isWebsocketActive(currency.unit)"
                                style="min-width: 34px; max-width: 34px; padding: 0; border-radius: 0 3px 0 0"
                                text
                            >
                                <img :src="currency.image" style="width: 26px" />
                            </v-btn>
                        </v-btn-toggle>
                        <v-btn-toggle
                            :value="websockets"
                            multiple
                            borderless
                            v-else-if="currencies"
                        >
                            <v-btn
                                v-for="currency in currencies"
                                :key="currency.unit"
                                @click="toggleWebsocket(currency)"
                                :value="currency.unit"
                                :disabled="!isWebsocketActive(currency.unit)"
                                style="min-width: 34px; max-width: 34px; border-radius: 0"
                                small
                                text
                            >
                                <img :src="currency.image" style="width: 24px" />
                            </v-btn>
                        </v-btn-toggle>
                        <v-btn
                            :value="websockets"
                            @click="toggleAllWebsockets"
                            text
                            style="
                                width: 100px;
                                height: 28px;
                                background-color: rgb(92 160 237);
                                color: white;
                                border-radius: 0 3px 0 0;
                            "
                            v-if="!currency"
                        >
                            {{ websocketsCount > 0 ? "Stop all" : "Start all" }}
                        </v-btn>
                    </div>
                </div>
            </template>
            <template slot="body">
                <div @mouseenter="pauseWebsockets" @mouseleave="restartWebsockets">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Transaction ID
                                    </th>
                                    <th class="text-center">
                                        Crypto
                                    </th>
                                    <th v-if="showInputsOutputs" style="width: 200px" class="text-center">
                                        In : Out
                                    </th>
                                    <th class="text-center">
                                        Time
                                    </th>
                                    <th style="width: 250px" class="text-center">
                                        Amount
                                    </th>
                                    <th v-if="showFee" style="width: 200px" class="text-center">
                                        Fee
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in liveTransactions"
                                    :key="item.key"
                                    :style="rowStyle(index, liveTransactions)"
                                >
                                    <td class="text-left">
                                        <RouteToTransaction :currency="item.currency.unit" :transaction="item.data.txid" monospace>
                                            <template slot="text">
                                                {{ item.data.txid | stringMiddleShortener(transactionIdLength) }}
                                            </template>
                                        </RouteToTransaction>
                                    </td>
                                    <td class="text-center">
                                        <img :src="item.currency.image" style="width: 22px; margin-bottom: -6px;"/>
                                    </td>
                                    <td v-if="showInputsOutputs" class="text-center">
                                        <span>
                                            {{ `${item.data.vin.length} ` }}
                                        </span>
                                        <span style="opacity: 0.6">:</span>
                                        <span>
                                            {{ ` ${item.data.vout.length}` }}
                                        </span>
                                    </td>
                                    <td class="text-center" style="opacity: 0.8">
                                        {{ item.data.blockTime | moment('HH:mm:ss') }}
                                    </td>
                                    <td class="text-right fontMonospace">
                                        {{ convert(item.data.value, item.currency) | formatNumber(8) }}
                                        <span class="font-weight-light" style="font-size: 0.8em">
                                            {{ item.currency.unit }}
                                        </span>
                                    </td>
                                    <td v-if="showFee" class="text-right fontMonospace">
                                        {{ `${(item.data.fees / item.currency.decimals).toFixed(8)} ` }}
                                        <span class="font-weight-light" style=" font-size: 0.8em">
                                            {{ item.currency.unit }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </template>
        </FancyCard>
    </div>
</template>

<script setup>
import RouteToTransaction from "@/components/common/RouteToTransaction";
import FancyCard from "@/components/common/Cards/FancyCard";
import {computed, watch} from "vue"
import store from "@/store"

const props = defineProps({
    showInputsOutputs: {
        type: Boolean,
        required: false,
        default: false,
    },
    showFee: {
        type: Boolean,
        required: false,
        default: false,
    },
    transactionIdLength: {
        type: Number,
        required: false,
        default: 16,
    },
})

const isWebsocketActive = (unit) => Object.keys(store.getters.websockets).includes(unit)

const rowStyle = (index, transactions) => {
    if(transactions.length - 2 === index) {
        return { opacity: 0.7 }
    } else if (transactions.length - 1 === index) {
        return { opacity: 0.4 }
    }
}

const bigDecimal = require('js-big-decimal');

const convert = ((value, currency) => {
    let result = bigDecimal.divide(value, currency.decimals.toString(), 8)
    if(value === '0') result = "0.00000000"
    return result
})

const currencies = computed(() => store.getters.currencies)

const websockets = computed(() => store.getters.enabledWebsockets)

const websocketsCount = computed(() => {
    return Object.keys(websockets.value).length
})

const toggleWebsocket = (currency) => store.dispatch("toggleWebsocket", currency)

const toggleAllWebsockets = () => store.dispatch("toggleAllWebsockets")

const currency = computed(() => {
    return store.getters.currency
})

const endAllActiveWebsocketsAndClearTxs = () => {
    websockets.value.forEach(unit => {
        store.commit("WEBSOCKET_DISABLE", unit)
    })
    store.commit("CLEAR_LIVE_TRANSACTIONS_ARRAY")
}

endAllActiveWebsocketsAndClearTxs()
const liveTransactions = computed(() => store.getters.liveTransactions)

const liveTxsEnabled = computed(() => store.getters.settings.server.liveTxsEnabled)
// Start websocket for currency/currencies
if(currency.value && liveTxsEnabled.value) {
    store.dispatch("startWebsocket", currency.value)
} else if(currencies.value && liveTxsEnabled.value) {
    currencies.value.forEach(currency => store.dispatch("startWebsocket", currency))
}

const pauseWebsockets = () => {
    store.dispatch("pauseWebsockets")
}
const restartWebsockets = () => {
    if (currency.value) {
        store.commit("WEBSOCKET_ENABLE", currency.value.unit)
    } else if (currencies.value) {
        store.dispatch("restartWebsockets")
    }
}

watch(currency, () => {
    endAllActiveWebsocketsAndClearTxs()

    store.dispatch('startWebsocket', currency.value)
})

</script>

<style scoped>
.txContainer {
    height: 400px;
    width: 100%;
}

</style>