<template>
    <div class="pathGraph">
        <CreateGraphFromCanvasModal ref="CreateGraphFromCanvasModal" @create-graph-event="createGraph"/>
        <GraphVisualization
            :nameOfExportedGraph="nameOfExportedGraph"
            :loading="transactionNetworkLoading"
            :config="config"
            :elements="elements"
            :autoRelayout="true"
            @graph-click-event="graphNodeClick"
            @before-destroy-event="cleanUpGraphData"
            ref="graph"
        >
            <template #actionbuttons class="graph-action-modals">
                <SaveButton :color="currency.unit" @click.stop="$refs.CreateGraphFromCanvasModal.openModal()" />
            </template>
        </GraphVisualization>
        <router-view />
    </div>
</template>

<script>
import { createAddressOrClusterOrTransactionEndpoint, buildStringTemplate } from "@/utils"
import { mapGetters } from "vuex";

import GraphVisualization from "@/components/network/GraphVisualization"
import CreateGraphFromCanvasModal from "@/components/network/CreateGraphFromCanvasModal"
import SaveButton from "@/components/common/Buttons/Fab/SaveButton"

//todo FE this control is also called canvas, unify naming

export default {
    name: "TransactionNetwork",
    components: {
        GraphVisualization,
        CreateGraphFromCanvasModal,
        SaveButton
    },
    mounted() {
        this.$store.dispatch("loadTransactionNetwork", {
            transactionId: this.$route.params.transaction,
            currency: this.$route.params.currency,
            sides: this.$route.params.sides
        });
    },
    computed: {
        ...mapGetters(["transactionNetworkLoading", "currency"]),
        elements() {
            return this.$store.getters.transactionNetwork;
        },
        nameOfExportedGraph() {
            return `Transactions-${this.$route.params.transaction}`
        },
        config() {
            //todo this prop have only nodes and edges, other data will be default, which is is set in GraphVisualization
            return this.$store.getters.transactionNetwork;
        }
    },
    watch: {
        elements: {
            deep: true,
            handler() {
                if (this.$route.params.transaction && this.$route.params.detailTransaction) {
                    const fromNode = this.$route.params.transaction;
                    const toNode = this.$route.params.detailTransaction;

                    this.$refs.graph.cytoscapeInstanceFunc(cy => {
                        var dijkstra = cy.elements().dijkstra('#' + fromNode, function (v) {
                            return v.id() === toNode;
                        }, false);
                        var bfs = dijkstra.pathTo(cy.$('#' + toNode));

                        for (var i = 0; i < bfs.length; i++) {
                            if (bfs[i].isEdge()) {
                                bfs[i].addClass(this.$route.params.currency + "-edge");
                            }
                        }
                    }
                    );
                }
            }
        }
    },
    methods: {
        async createGraph({ graphDetail }) {
            const graphJson = this.$refs.graph.cytoscapeInstanceFunc(i =>
                i.json()
            );
            const graph = await this.$store.dispatch('createGraph', {
                graph: {
                    ...graphDetail,
                    currency: this.currency.unit,
                    config: graphJson
                }
            })
            if (graph) {
                this.$router.push({ name: "GraphSingleView", params: { graphId: graph, currency: this.currency.unit } })
            }
        },
        graphNodeClick(event) {
            const requestBaseUrl = buildStringTemplate({
                template: "/${currency}/transaction/${transactionId}/network/${sides}/detail",
                values: {
                    currency: this.$route.params.currency,
                    transactionId: this.$route.params.transaction,
                    sides: this.$route.params.sides,
                }
            })
            const requestUrl = createAddressOrClusterOrTransactionEndpoint({ event, requestBaseUrl })
            this.$router.push(requestUrl)
        },
        cleanUpGraphData() {
            this.$store.dispatch('cleanUpTransactionNetwork')
        }
    }
};
</script>

