var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "transactionContainer" },
    [
      _c(
        "v-row",
        { staticClass: "my-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "10", offset: "1" } },
            [
              !_vm.hideTopPagination
                ? _c("v-pagination", {
                    attrs: {
                      length: _vm.pagesTotal,
                      "total-visible": _vm.hideTotal ? 0 : 20,
                      value: _vm.value,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("input", $event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.dataLoaded
        ? _c("ProgressCircular", {
            staticClass: "mt-2",
            attrs: { color: _vm.currency.unit },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.dataLoaded ? _vm._t("content") : _vm._e(),
      _vm._v(" "),
      _vm.dataLoaded
        ? _c(
            "v-row",
            { staticClass: "my-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "10", offset: "1" } },
                [
                  _c("v-pagination", {
                    attrs: {
                      length: _vm.pagesTotal,
                      "total-visible": _vm.hideTotal ? 0 : 20,
                      value: _vm.value,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("input", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }