import apiClient from "./apiClient";

async function tryAuthenticateAsync() {
    let isAuthenticated = false;
    const response = await apiClient.GET("user/isLoggedIn", {
        validateStatus: (status) => {
            isAuthenticated = status === 200
            return status === 401 || status === 200
        }
    })
    return { isAuthenticated, user: response };
}

async function login({ credentials }) {
    return await apiClient.POST("login", credentials);
}

async function logout() {
    return await apiClient.POST("logout");
}

export default { tryAuthenticateAsync, login, logout }
