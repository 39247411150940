var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticStyle: { "margin-top": "auto" }, attrs: { "no-gutters": "" } },
    [
      _c("v-col", { staticClass: "py-0" }, [
        _c(
          "div",
          {
            staticStyle: {
              "border-bottom": "1px #0000001F solid",
              height: "49px",
            },
          },
          [
            _c(
              "v-app-bar",
              { attrs: { elevation: "0", dense: "", color: "white" } },
              [
                _c(
                  "v-row",
                  [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c("EntityHeaderLabel", {
                      attrs: {
                        justify: "center",
                        label:
                          _vm.currency.unit.toUpperCase() + " TRANSACTION:",
                        entity: _vm.$route.params.transaction,
                      },
                    }),
                    _vm._v(" "),
                    _c("v-spacer"),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("router-view"),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }