<script setup>
import {onMounted, ref} from "vue";
import GraphExplorerConfig from "@/views/GraphExplorer/GraphExplorerConfig";
import GraphExplorerDrawer from "@/views/GraphExplorer/GraphExplorerDrawer.vue";
import store from "@/store";
import UseGEStore from "@/views/GraphExplorer/Composables/UseGEStore";
import {GERequestAction} from "@/store/modules/GraphExplorer"
import UseGELayout from "@/views/GraphExplorer/Composables/UseGELayout";
import UseGEGxtMenu from "@/views/GraphExplorer/Composables/UseGEGxtMenu";
import UseGEStyles from "@/views/GraphExplorer/Composables/UseGEStyles";
import UseGEGraph from "@/views/GraphExplorer/Composables/UseGEGraph";
import UseGELogic from "@/views/GraphExplorer/Composables/UseGELogic";
import GraphExplorerNotification from "@/views/GraphExplorer/Components/GraphExplorerNotification.vue";
import UseGELayers from "@/views/GraphExplorer/Composables/UseGELayers";
import GraphExplorerLabelEditor from "@/views/GraphExplorer/Components/GraphExplorerLabelEditor.vue";
import {useRoute, useRouter} from "vue-router/composables";
import {GEEleType} from "@/views/GraphExplorer/Components/Drawer/GEEleType";
import {getCyCenter} from "@/views/GraphExplorer/GEUtils";
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

const cytoscape = require('cytoscape');

let cy
let menu

const {styleOptions} = UseGEStyles()
const {GESelectedElement, GETransactions} = UseGEStore()
const route = useRoute()
const router = useRouter()

const {
    Activate: layoutActivate,
    layoutOptions,
    reLayoutSelectedAsync,
    reLayoutNodesAsync,
    reLayoutGraph
} = UseGELayout(cytoscape)
const {Activate: gxtActivate} = UseGEGxtMenu(cytoscape)
const {Activate: graphActivate, GraphEdge, GraphTx, GraphNode} = UseGEGraph(cytoscape, reLayoutNodesAsync)
const {Activate: logicActivate, StopPeelChain, FollowOutputAsync, FollowOutputIterativelyAsync} = UseGELogic({GraphTx})
const {Activate: layersActivate, removeItemFromCacheById} = UseGELayers(cytoscape)


const loading = ref(false)
const graphName = ref(null)
const graphDesc = ref("Interactive Graph")
const graphId = ref(undefined)
store.commit('GEReset')


onMounted(async () => {
    cy = cytoscape({
        container: document.getElementById('cy'),
        ...GraphExplorerConfig,
        layout: layoutOptions,
        ready: function () {
        },
        style: styleOptions,
    });

    //setup Composables
    layersActivate(cy)
    logicActivate(cy)
    layoutActivate(cy)
    graphActivate(cy)
    gxtActivate(cy)


    if (route.params.id) {
        graphId.value = route.params.id
        loading.value = true
        const graph = await store.dispatch('loadGraphExp', {graphId: graphId.value})
        const {elements, notes, cyInfo} = graph.configs.find(config => config.description === "Interactive Graph").json_config.graph

        cy.add(elements)
        graphName.value = graph.name
        GraphNode.AddOnClick()

        notes?.forEach(x => {
            GraphNode.AddNoteNode(x.text, x.position)
        })

        cy.$(".transaction").forEach(ele => {
            store.commit("GETransactionsAdd", ele.data().typeSpecific)
        })

        cy.pan(cyInfo.pan)
        cy.zoom(cyInfo.zoom)

        loading.value = false
    }
})


const addNodeModel = ref(null)
const fetchAndNode = async () => {
    const clear = () => {
        addNodeModel.value = null
        loading.value = false
    }

    loading.value = true
    const nodeToAdd = addNodeModel.value

    const addressInfoPromise = store.dispatch('getAddressInfo', {params: {currencyUnit: "BTC", address: nodeToAdd}})
    const txInfoPromise = store.dispatch('getTransaction', {params: {currencyUnit: "BTC", txId: nodeToAdd}})

    const results = await Promise.all([addressInfoPromise, txInfoPromise])

    if (results[0]) {
        const addressInfo = await addressInfoPromise
        GraphNode.AddAddressNode(addressInfo.data.address, {
            label: addressInfo.data.label,
            position: getCyCenter(cy),
            addressData: addressInfo.data
        })
        clear()
    } else if (results[1]) {
        const txData = await txInfoPromise
        await GraphTx.AddTxAsync(txData, {
            position: getCyCenter(cy),
            undefined,
            flags: {
                allInputs: false,
                allOutputs: false
            }
        }, false)
        clear()
    } else {
        await store.dispatch("error", "Not found")
        loading.value = false
    }
}

const actionName = ref()
const cancelAction = async () => {
    actionName.value = undefined
    StopPeelChain()
}
const actionHandler = async (action, payload, invoker) => {
    switch (action) {
        case GERequestAction.TxAddition:

            const {x = 0, y = 0} = invoker.position
            const invokerAddressId = invoker.data.type === GEEleType.NodeAddress ? invoker.data.id : undefined
            await GraphTx.AddTxAsync(payload.tx, {position: {x, y}, invokerAddressId, flags: {allOutputs: true}})
            break

        case GERequestAction.TxRemoval:
            GraphTx.RemoveTx(payload.txId)
            break

        case GERequestAction.OutputFollowIteratively:
            actionName.value = "Iterative transaction follow"
            await FollowOutputAsync(payload.output, true)
            actionName.value = undefined
            break

        case GERequestAction.OutputFollow:
            actionName.value = "Transaction follow"
            await FollowOutputAsync(payload.output, false)
            actionName.value = undefined
            break

        case GERequestAction.NodeRerender:
            removeItemFromCacheById(payload)
            break

        case GERequestAction.TxExpandInputs:
            await GraphTx.AddTxAsync(payload, {flags: {allInputs: true}})
            break

        case GERequestAction.TxExpandOutputs:
            await GraphTx.AddTxAsync(payload, {flags: {allOutputs: true}})
            break

        case GERequestAction.NodesMerge:
            GraphNode.AddMergeNode(payload)
            break

        case GERequestAction.NodesUnmerge:
            GraphNode.RemoveMergeNode(payload)
            cy.$(":selected").unselect()
            break

        case GERequestAction.NodeChangeLabel:
            await editNodeLabel(payload.id, payload.text)
            break

        case GERequestAction.NoteAddition:
            await addNoteNode(payload)
            break

        case GERequestAction.NoteRemoval:
            GraphNode.RemoveNoteNode(payload)
            break

        case GERequestAction.NoteUpdate:
            const newContent = await labelEditorRef.value.Open(payload.text, true)
            if (newContent) {
                GraphNode.EditNoteNode(payload.id, newContent)
            }
            break

        case GERequestAction.AddressRemoval:
            //TODO connected transactions do not update their labels
            GraphNode.RemoveNode(payload)
            break

    }

}

store.commit('GERegisterActorCb', actionHandler)

const labelEditorRef = ref(null)
const editNodeLabel = async (id, text) => {
    const newContent = await labelEditorRef.value.Open(text)
    console.log('newContent', newContent)
    if (newContent) {
        cy.$id(id).data('customLabel', newContent)
    }
}

const addNoteNode = async (position) => {
    const newContent = await labelEditorRef.value.Open("", true)
    if (newContent) {
        GraphNode.AddNoteNode(newContent, position)
    }
}


const saveGraph = async () => {
    const elements = cy.elements().not(".note").jsons()
    const notes = []
    loading.value = true


    for (const noteNode of cy.nodes(".note").jsons()) {
        notes.push({
            position: noteNode.position,
            text: noteNode.data.text
        })
    }
    const cyInfo = {
        pan: {
            x: cy.pan().x,
            y: cy.pan().y,
        },
        zoom: cy.zoom()
    }
    const graphToSave = {
        elements: elements,
        notes: notes,
        cyInfo: cyInfo,
    }

    if (!graphName.value) {
        graphName.value = await labelEditorRef.value.Open("new graph", false, "Graph name")
    }

    if (!graphName.value) {
        loading.value = false
        return
    }

    graphId.value = await store.dispatch('saveGraph', {
        name: graphName.value,
        description: graphDesc.value,
        graphId: graphId.value,
        graph: graphToSave
    })

    if (!route.params.id) {
        router.push({params: {id: graphId.value}})
    }
    loading.value = false
}

</script>

<template>
    <div style="position: relative; height: 100%; overflow: hidden">
        <GraphExplorerDrawer/>
            <v-text-field
                v-model="graphName"
                placeholder="New graph name"
                prepend-inner-icon="edit"
                solo
                outlined
                dense
                class="fontMonospace elevation-0"
                style="width: fit-content;font-size: 14px;margin-top: 4px;margin-bottom: -10px;margin-left: 13px; z-index: 1"
            >
            </v-text-field>
            <v-text-field
                dense
                :loading="loading"
                v-model="addNodeModel"
                class="position-absolute fontMonospace"
                clear-icon="mdi-clear-circle"
                clearable
                color="info"
                label="Search"
                outlined
                hide-details
                placeholder="Search Tx/Address"
                style="min-width: 500px; max-width: 500px; top: 12px; left: 12px; background-color: #FFFFFF; z-index: 1"
                @keydown.enter="fetchAndNode"
            >
                <template #progress>
                    <v-progress-linear absolute :active="loading" color="info" indeterminate height="6px"/>
                </template>
            </v-text-field>
        <div class="d-flex flex-column" style="width: fit-content; position: relative; z-index: 1; top: 30px">
            <div
                class="d-flex flex-column justify-center align-center rounded-lg rounded-l-0 pt-1 pb-2 elevation-2 border"
                style="width: 66px; background-color: #fefefe; gap: 14px;">
                <div class="d-flex align-center justify-center flex-column">
                    <BaseDataModifyButton large icon @click="saveGraph" color="info">
                        <v-icon style="font-size: 24px">
                            save
                        </v-icon>
                    </BaseDataModifyButton>
                    <div class="caption mt-n2" style="font-size: 0.70rem !important">
                        SAVE
                    </div>
                </div>
                <v-divider class="my-n1 width100"/>
                <div class="d-flex align-center justify-center flex-column">
                    <v-btn large class="" icon @click="reLayoutGraph" color="info">
                        <v-icon style="font-size: 24px">
                            mdi-source-branch-refresh
                        </v-icon>
                    </v-btn>
                    <div class="caption mt-n2" style="font-size: 0.70rem !important">
                        LAYOUT
                    </div>
                </div>
            </div>
        </div>
        <div v-once id="cy" ref="cyRef" class="cyContainer">
        </div>
        <GraphExplorerLabelEditor ref="labelEditorRef"/>
        <GraphExplorerNotification
            :text="actionName"
            @on-cancel-event="cancelAction"/>
    </div>
</template>

<style>


.category-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    background-color: #f0f0f0;
    border-radius: 50%;
//border: solid 1px grey;
}

.cyContainer {
    width: 100%;
    height: 100%;
    display: block;
}

.noteContent {
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -webkit-box-shadow: 0px 0 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0 3px rgba(0, 0, 0, 0.25);
    background-color: #eee;
    padding: 8px;
    border: 1px solid gray;
    white-space: pre;
}

#cy {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.cxtmenu-content {
//background-color: #1f9d55;
}

/* you can set the disabled style how you like on the text/icon */
.cxtmenu-disabled {
    opacity: 0.333;
}


</style>