<script setup>
import NameValueFlex from "@/components/common/NameValueFlex.vue";

defineProps({
    name: {
        type: String,
        required: true
    },
    value: {
        type: [Number, String],
        required: false
    },
    chipColor: {
        type: String,
        required: false,
        default: "#2c2c2c"
    },
    textColor: {
        type: String,
        required: false,
        default: "white"
    },
    unit: {
        type: String,
        required: false
    }
})
</script>

<template>
    <NameValueFlex>
        <template #name>
            <span class="font-weight-light">
                {{ name }}:
            </span>
        </template>
        <template #value>
            <div>
                <v-chip
                    :color="chipColor"
                    style="height: 24px"
                    label
                    :text-color="textColor"
                >
                    <div v-if="value">
                        <span class="subtitle-2 font-weight-bold" style="vertical-align: middle">
                            {{ value }}
                        </span>
                        <span v-if="unit" class="subtitle-2 pl-1 text--white font-weight-light"
                              style="vertical-align: middle">
                            {{ unit }}
                        </span>
                    </div>
                    <span v-else>N/A</span>
                </v-chip>
                <slot name="appendIcon"/>
            </div>
        </template>
    </NameValueFlex>
</template>

<style scoped>

</style>