import {computed, ref, unref, watch} from "vue";
import {deepClone} from "@/utils";
import {FilterStringSubtype, FilterType} from "@/components/common/types/filter";

const stringFilterValueConverter = (strings, opt) => {
    strings = unref(strings)
    opt = unref(opt)
    //remove undefined options in opts
    Object.keys(opt).forEach(key => opt[key] === undefined && delete opt[key])
    if (strings.length === 0 && Object.values(opt).length === 0)
        return undefined
    return {op: "E", values: deepClone(strings), type: FilterType.Strings, opt: deepClone(opt)}
}

export const useStringFilter = (filterSubType) =>
{
    const filterValue = ref('')

    //Interpret ;, as newlines, remove unnecessary newlines
    //(DOES NOT WORK WITH V-TEXTAREA, only with raw textarea)
    watch(filterValue, () => {
        if (filterValue.value.trim().length === 0)
        {
            filterValue.value = ''
        } else
        {
            filterValue.value = filterValue.value.replace(/[\s,;]+/g,'\n')
        }
    })


    //FilterValue as Array of string
    const filterValueFormatted = computed(() => {
        if (filterValue.value.trim().length === 0)
            return []
        return filterValue.value.split(/\r?\n/)
    })

    const adequateNoun = computed(() => {
        if (filterSubType === FilterStringSubtype.Addresses) {
            return 'addresses'
        } else if (filterSubType === FilterStringSubtype.Transactions) {
            return 'transactions'
        } else {
            return 'clusters'
        }
    })

    const formattedNoun = computed(() => {
        if (filterSubType === FilterStringSubtype.Addresses) {
            return filterValueFormatted.value.length === 1 ? 'Address' : 'Addresses'
        } else if (filterSubType === FilterStringSubtype.Transactions) {
            return filterValueFormatted.value.length === 1 ? 'Transaction' : 'Transactions'
        } else {
            return filterValueFormatted.value.length === 1 ? 'Cluster' : 'Clusters'
        }
    })

    const filterDisplayValue = computed(() => `${filterValueFormatted.value.length} ${formattedNoun.value}`)

    return { filterValue, filterValueFormatted, adequateNoun, formattedNoun, filterDisplayValue, stringFilterValueConverter }
}