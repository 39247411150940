<script setup>
import {computed} from "vue";

const props = defineProps({
    icon: {
        type: String,
        required: true,
    },
    items: {
        type: Array,
        required: true,
        default() {
            return []
        }
    },
    textSingle: {
        type: String,
        required: true,
    },
    textMultiple: {
        type: String,
        required: true,
    },
    headers: {
        type: Array,
        required: true,
    }
})

const addEmptyEntry = () => {
    const createNewEntry = () => {
        const newItem = {}
        props.headers.forEach(h => {
            newItem[h.value] = ''
        })
        return newItem
    }
    props.items.unshift(createNewEntry())
}
const removeItemAtIndex = (index) => {
    props.items.splice(index, 1)
}

const computedHeaders = computed(() => [...props.headers, {text: "", align: "", value: "", width: "42px"}])

</script>


<template>
    <v-menu
        content-class="whiteBackground"
        :min-width="`${headers.length * 240 + 42}px`"
        :close-on-content-click="false"
        offset-x
        top>
        <template v-slot:activator="{ on, attrs }">
            <v-chip
                :color="items.length > 0 ? 'info' : 'primary'"
                class="addressImportedMetadataMenuChip"
                hover
                label
                outlined
                style="height: 24px"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon left>
                    {{ icon }}
                </v-icon>
                {{ items.length }} {{ items.length === 1 ? textSingle : textMultiple }}
            </v-chip>
        </template>
        <div>
            <div class="d-flex justify-end">
                <v-btn
                    class="ma-1"
                    color="success"
                    small
                    @click="addEmptyEntry">
                    <v-icon left>
                        add_box
                    </v-icon>
                    Add entry
                </v-btn>
            </div>
            <v-data-table
                :headers="computedHeaders"
                :height="32 * 5 + 52"
                :items="props.items"
                :items-per-page="-1"
                dense
                hide-default-footer
                no-data-text="No imported rows"
            >
                <template #item="{ item, index }">
                    <tr>
                        <td v-for="(header, index) in headers"
                            :key="index"
                        >
                            <v-text-field
                                v-model="item[header.value]"
                                :placeholder="header.text"
                                class="fontMonospace"
                                dense
                            />
                        </td>
                        <td>
                            <v-btn color="error" icon x-small @click="removeItemAtIndex(index)">
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </v-menu>
</template>

<style lang="scss">

.addressImportedMetadataMenuChip {
    cursor: pointer !important;

    &:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
    }
}

</style>