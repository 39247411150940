import apiClient from "@/services/apiClient"
import Vue from "vue";

import {CaseDetailServerModel, CaseDetailViewModel, ICaseAddressImport} from "models/CaseModels"
import axios from "axios";

import {modalState} from "@/components/common/types/modal";

const state = {
    cases: [],
    casesLoading: false,
    caseDetail: null,
    isCaseLoading: false,
    caseFiles: null,
    isCaseFilesLoading: false,
    caseAddresses: {}, // MAP CURRENCY to ADDRESS DATA
    caseAddressesLoading: {}, // MAP CURRENCY to BOOL
    caseCancelToken: axios.CancelToken.source(),
    casesModalState: modalState.Initial,
}

const getters = {
    cases: (state) => state.cases,
    casesLoading: (state) => state.casesLoading,
    caseDetail: (state) => state.caseDetail,
    isCaseLoading: (state) => state.isCaseLoading,
    caseAddresses: (state) => state.caseAddresses,
    caseFiles: (state) => state.caseFiles,
    caseAddressesLoading: (state) => state.caseAddressesLoading,
    caseFilesLoading: (state) => state.isCaseFilesLoading,
    casesModalState: (state) => state.casesModalState,
}

const mutations = {
    CASES_START_LOADING(state) {
        state.caseCancelToken.cancel()
        state.caseCancelToken = axios.CancelToken.source()
        state.casesLoading = true
    },
    CASES_LOADED(state, data) {
        state.cases = data
    },
    CASES_FINISH_LOADING(state) {
        state.casesLoading = false
    },
    CASE_START_LOADING(state) {
        state.isCaseLoading = true
    },
    CASE_LOADED(state, data) {
        state.caseDetail = data
    },
    CASE_FINISH_LOADING(state) {
        state.isCaseLoading = false
    },
    CASE_ADDRESSES_START_LOADING(state, data) {
        Vue.set(state.caseAddressesLoading, data.currency, true)
    },
    CASE_ADDRESSES_LOADED(state, data) {
        Vue.set(state.caseAddresses, data.currency, data.data)
    },
    CASE_ADDRESSES_FINISH_LOADING(state, data) {
        Vue.delete(state.caseAddressesLoading, data.currency)
    },
    CASE_FILES_START_LOADING(state) {
        state.isCaseFilesLoading = true
    },
    CASE_FILES_LOADED(state, data) {
        state.caseFiles = data
    },
    CASE_FILES_FINISH_LOADING(state) {
        state.isCaseFilesLoading = false
    },
    CASES_MODAL_SET_STATE(state, data) {
        state.casesModalState = data
    }
}

const actions = {
    async loadCases({commit}, {pagination}) {
        commit('CASES_START_LOADING')
        try {
            const result = await apiClient.GET("cases", {
                params: pagination,
                cancelToken: state.caseCancelToken.token
            })
            commit('CASES_LOADED', result)
            commit('CASES_FINISH_LOADING')
        } catch (error) {
            if (axios.isCancel(error)) {
                //request canceled, do nothing
            } else {
                this.dispatch("error", error.userFriendlyMessage)
                commit('CASES_FINISH_LOADING')
            }
        }
    },
    async loadCase({commit}, {caseId, loadSimpleDetail = false}) {
        commit('CASE_START_LOADING')
        try {
            const result: CaseDetailServerModel = await apiClient.GET(`cases/${caseId}`, {
                ...(loadSimpleDetail && {params: {'details': 0}})
            })
            if (!loadSimpleDetail) {
                const caseViewDetail = result
                commit('CASE_LOADED', caseViewDetail)
                commit('CASE_FINISH_LOADING')
                return caseViewDetail
            } else {
                commit('CASE_LOADED', result)
                commit('CASE_FINISH_LOADING')
                return result
            }
        } catch (error) {
            this.dispatch("error", error.userFriendlyMessage)
        }
        commit('CASE_FINISH_LOADING')
        return false
    },
    async loadCaseAddresses({commit}, {currency, pagination, caseId}) {
        commit('CASE_ADDRESSES_START_LOADING', {currency})
        try {
            const result = await apiClient.GET(`${currency}/cases/${caseId}/addresses`, {
                params: pagination
            })
            commit('CASE_ADDRESSES_LOADED', {currency, data: result})
            commit('CASE_ADDRESSES_FINISH_LOADING', {currency})
            return result
        } catch (error) {
            this.dispatch("error", error.userFriendlyMessage)
        }
        commit('CASE_ADDRESSES_FINISH_LOADING', {currency})
        return false
    },
    async deleteCase({dispatch}, {caseId}) {
        try {
            const response = await apiClient.DELETE(`cases/${caseId}`)
            dispatch('success', response.message)
        } catch (error) {
            dispatch('error', error.userFriendlyMessage)
        }
    },
    async updateCase({dispatch, commit}, {updatedCase}: {
        updatedCase: CaseDetailViewModel
    }) {
        const caseDetail = {
            title: updatedCase.title,
            description: updatedCase.description,
            reported_at: updatedCase.reported_at,
            is_warning: updatedCase.is_warning,
            is_hidden: updatedCase.is_hidden
        }

        commit('CASES_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await apiClient.PUT(`cases/${updatedCase.id}`, caseDetail)
            dispatch('success', response.message)
            commit('CASES_MODAL_SET_STATE', modalState.Success)
        } catch (error) {
            dispatch('error', error.userFriendlyMessage)
            commit('CASES_MODAL_SET_STATE', modalState.Error)
        }
    },

    async importCaseAddresses({dispatch}, {items, currencyUnit, propagateToCluster, caseId}: ICaseAddressImport) {
        try {
            return !!await apiClient.POST(`${currencyUnit}/cases/${caseId}/addresses`, {
                addresses: items,
                propagate_to_cluster: propagateToCluster
            })
        } catch (error) {
            dispatch("error", `Failed to import addresses: ${error.message}`)
        }
        return false
    },

    async deleteCaseAddresses({dispatch}, {caseId, currencyUnit, itemsToDelete}) {
        try {
            await apiClient.DELETE(`${currencyUnit}/cases/${caseId}/addresses`, {address_ids: itemsToDelete})
            return true
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
    },

    async deleteCaseGraphs({dispatch}, {caseId, itemsToDelete}) {
        try {
            await apiClient.DELETE(`cases/${caseId}/graphs`, {graph_ids: itemsToDelete})
            return true
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        return false
    },

    async addGraphsToCase({dispatch}, {caseId, itemsToAdd}) {
        try {
            await apiClient.POST(`cases/${caseId}/graphs`, {graph_ids: itemsToAdd})
            return true
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        return false
    },

    async createCase({dispatch, commit}, {caseDetail}: {
        caseDetail: CaseDetailViewModel
    }) {
        commit('CASES_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await apiClient.POST(`cases`, caseDetail)

            dispatch('success', response.message)
            commit('CASES_MODAL_SET_STATE', modalState.Success)
            return response.id
        } catch (error) {
            dispatch('error', error.userFriendlyMessage)
            commit('CASES_MODAL_SET_STATE', modalState.Error)
        }
    },
    async loadCaseGraphs({commit, dispatch}, {pagination, currency, caseId}) {
        let result
        try {
            result = await apiClient.GET(`cases/${caseId}/graphs`, {
                params: {...pagination, filterCurrency: currency}
            })
            return result
        } catch (error) {
            if (axios.isCancel(error)) {
                //request canceled, do nothing
            } else {
                dispatch("error", error.userFriendlyMessage)
            }
        }
        return false
    },
    async patchCase({commit, dispatch}, {caseId, patch}) {
        let result
        try {
            result = await apiClient.PATCH(`cases/${caseId}`, patch)
            dispatch("success", result.message)
            return true
        } catch (error) {
            if (axios.isCancel(error)) {
                //request canceled, do nothing
            } else {
                dispatch("error", error.userFriendlyMessage)
            }
        }
        return false
    },
    async getCaseFiles({commit}, {pagination, caseId}) {
        try {
            return await apiClient.GET(`cases/${caseId}/files`, {
                params: pagination
            })
        } catch (error) {
            this.dispatch("error", error.userFriendlyMessage)
        }
        return false
    },
    async getCases({commit}, {pagination, cancelToken}) {
        try {
            return await apiClient.GET("cases",
                {
                    params: pagination,
                    cancelToken: cancelToken
                })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
        return false
    },

}

export default {
    state,
    mutations,
    actions,
    getters
}
