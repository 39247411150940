var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-4", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "" } },
        [
          _c("h1", [_vm._v("Address Metadata")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("ImportAddressesDialog", {
            on: { addressesImported: _vm.refresh },
          }),
          _vm._v(" "),
          _c("AddressMetadataDetailModal", {
            attrs: {
              currencies: _vm.currencies,
              owners: _vm.owners.data,
              categories: _vm.categories,
              isOwnersLoading: _vm.isOwnersLoading,
              isCategoriesLoading: _vm.isCategoriesLoading,
              isAddressMetadataDetailLoading:
                _vm.isAddressMetadataDetailLoading,
            },
            on: {
              "save-event": _vm.createAddressMetadata,
              "owner-filter-changed-event": _vm.ownerFilterChanged,
            },
            scopedSlots: _vm._u([
              {
                key: "showbutton",
                fn: function ({ on }) {
                  return [
                    _c(
                      "CreateButton",
                      _vm._g(
                        { attrs: { text: "Add new address metadata" } },
                        on
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-0 border my-2",
        attrs: {
          headers: _vm.headers,
          items: _vm.addressMetadata.data,
          options: _vm.filter,
          "server-items-length": _vm.addressMetadata.total
            ? _vm.addressMetadata.total
            : 0,
          loading: _vm.addressMetadataLoading,
          "footer-props": {
            "items-per-page-options": [15, 30, 50, 100],
            "show-current-page": true,
          },
          dense: "",
        },
        on: {
          "update:options": function ($event) {
            _vm.filter = $event
          },
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.headers, function (h) {
              return {
                key: `header.${h.value}`,
                fn: function ({ header }) {
                  return [
                    h.value === "address"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          attrs: {
                            "append-icon": "search",
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterAddress,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.debouncedFilter,
                                "filterAddress",
                                $$v
                              )
                            },
                            expression: "debouncedFilter.filterAddress",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "crypto"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: { "margin-bottom": "auto" },
                          },
                          [
                            _c("BlockchainCryptoPickerSlim", {
                              attrs: {
                                currencies: _vm.allCurrenciesExceptUnknown,
                                dense: "",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value: _vm.filter.filterCurrency,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "filterCurrency", $$v)
                                },
                                expression: "filter.filterCurrency",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "identities_count"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          staticStyle: { width: "90px" },
                          attrs: {
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                            type: "number",
                            min: 0,
                            "hide-spin-buttons": "",
                            clearable: "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterIdentities,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.debouncedFilter,
                                "filterIdentities",
                                $$v
                              )
                            },
                            expression: "debouncedFilter.filterIdentities",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "categories_count"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: {
                              "padding-top": "20px",
                              "margin-bottom": "auto",
                            },
                          },
                          [
                            _c("CategoryPicker", {
                              staticClass: "text-field-label-style",
                              attrs: {
                                categories: _vm.categories,
                                dense: true,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value: _vm.filter.filterCategories,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "filterCategories", $$v)
                                },
                                expression: "filter.filterCategories",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "cases_count"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          attrs: {
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                            type: "string",
                            clearable: "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterCases,
                            callback: function ($$v) {
                              _vm.$set(_vm.debouncedFilter, "filterCases", $$v)
                            },
                            expression: "debouncedFilter.filterCases",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "owners_count"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          attrs: {
                            "append-icon": "search",
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterOwner,
                            callback: function ($$v) {
                              _vm.$set(_vm.debouncedFilter, "filterOwner", $$v)
                            },
                            expression: "debouncedFilter.filterOwner",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "created_at"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: { "min-width": "160px" },
                          },
                          [
                            _c("DateTimePicker", {
                              attrs: { label: h.text, disabledTime: true },
                              model: {
                                value: _vm.filter.filterCreatedAt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "filterCreatedAt", $$v)
                                },
                                expression: "filter.filterCreatedAt",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "action"
                      ? _c("div", { key: h.value }, [
                          _c("h3", [_vm._v(_vm._s(h.text))]),
                        ])
                      : _vm._e(),
                  ]
                },
              }
            }),
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "text-left" },
                      [
                        _c("EntityLabel", {
                          attrs: {
                            "act-as-link": "",
                            currency: item.crypto,
                            "is-copyable": "",
                            entity: item.address,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c("BlockchainCryptoBadge", {
                          attrs: {
                            dense: "",
                            iconWidth: 24,
                            currencies: _vm.currencies,
                            crypto: item.crypto,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-right",
                        staticStyle: {
                          "text-align": "center",
                          "vertical-align": "middle",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatNumber")(item.identities_count)
                            )
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "vertical-align": "middle",
                        },
                        attrs: { width: "20%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-left" },
                          [
                            _vm._l(item.categories.slice(0, 2), function (cat) {
                              return _c("AddressCategoryIcon", {
                                key: cat.id,
                                staticClass: "mr-2",
                                attrs: {
                                  iconItem: cat,
                                  small: "",
                                  name: cat.name,
                                  color: cat.color,
                                },
                              })
                            }),
                            _vm._v(" "),
                            _c(
                              "BaseButtonShowMoreItems",
                              {
                                attrs: {
                                  items: item.categories,
                                  shownItemsCount: 2,
                                },
                              },
                              _vm._l(item.categories, function (cat) {
                                return _c("AddressCategoryIcon", {
                                  key: cat.id,
                                  staticClass: "mx-1",
                                  attrs: {
                                    iconItem: cat,
                                    medium: "",
                                    name: cat.name,
                                    color: cat.color,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-center" }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-start align-center",
                          staticStyle: { "max-width": "200px" },
                        },
                        [
                          _vm._l(item.cases.slice(0, 1), function (item) {
                            return _c(
                              "router-link",
                              {
                                key: item.id,
                                staticClass: "link text-truncate",
                                attrs: {
                                  to: {
                                    name: "CaseDetail",
                                    params: { caseId: item.id },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.title) +
                                    "\n                        "
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "BaseButtonShowMoreItems",
                            {
                              attrs: { items: item.cases, shownItemsCount: 1 },
                            },
                            _vm._l(item.cases, function (item) {
                              return _c(
                                "router-link",
                                {
                                  key: item.id,
                                  staticClass: "link mx-1",
                                  staticStyle: { "font-size": "0.8em" },
                                  attrs: {
                                    to: {
                                      name: "CaseDetail",
                                      params: { caseId: item.id },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.title) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c("TableItemChipsWithShowMoreOption", {
                          attrs: { items: item.owners },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ item, isInMenu }) {
                                  return [
                                    _c("OwnerBadge", {
                                      class: { "mx-1 mb-1": isInMenu },
                                      attrs: { item: item },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "vertical-align": "middle",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("formatDateTime")(item.created_at)) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "vertical-align": "middle",
                          "min-width": "92px",
                        },
                        attrs: { width: "10%" },
                      },
                      [
                        _c("AddressMetadataDetailModal", {
                          key: item.id,
                          attrs: {
                            currencies: _vm.currencies,
                            owners: _vm.owners.data,
                            categories: _vm.categories,
                            isOwnersLoading: _vm.isOwnersLoading,
                            isCategoriesLoading: _vm.isCategoriesLoading,
                            isAddressMetadataDetailLoading:
                              _vm.isAddressMetadataDetailLoading,
                            addressMetadataDetail: _vm.addressMetadataDetail,
                          },
                          on: {
                            "save-event": _vm.editAddressMetadata,
                            "owner-filter-changed-event":
                              _vm.ownerFilterChanged,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "showbutton",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "TableEditButton",
                                      _vm._g(
                                        {
                                          attrs: {
                                            editButtonTooltip:
                                              "Edit address metadata",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadAddressMetadataDetail(
                                                {
                                                  addressMetadataId: item.id,
                                                }
                                              )
                                            },
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("TableDeleteModalDialog", {
                          attrs: {
                            width: "40%",
                            description: `Do you really want to delete address ${item.address} and all its metadata? Address binds ${item.identities_count} identities, ${item.categories_count} categories, ${item.owner_count} owner and ${item.cases_count} cases.`,
                            itemToDelete: item,
                            tooltip: "Delete address metadata",
                          },
                          on: { "delete-event": _vm.deleteAddressMetadata },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
            {
              key: "footer.page-text",
              fn: function ({ pageStart, pageStop, itemsLength }) {
                return [
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("formatNumber")(pageStart)) +
                        " - " +
                        _vm._s(_vm._f("formatNumber")(pageStop)) +
                        " of " +
                        _vm._s(_vm._f("formatNumber")(itemsLength)) +
                        "\n            "
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }