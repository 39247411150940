<script setup>
import BaseModalDialog from "@/components/common/Base/BaseModalDialog.vue";
import {ref, computed, onMounted} from "vue";
import store from "@/store";

const modalRef = ref(null)
const isWarningEnabled = computed(() => store.getters.settings.server.user_warning_enabled)
const warningMessage = computed(() => store.getters.settings.server.user_warning_text)
// LocalStorage is cleared as a side effect of logging out.
const warningUnderstood = localStorage.getItem("warningUnderstood")

const handleClose = (close) => {
    close()
    localStorage.setItem("warningUnderstood", true)
}

onMounted(() => {
    if (isWarningEnabled.value && !warningUnderstood) {
        modalRef.value.open()
    }
})

</script>

<template>
    <BaseModalDialog ref="modalRef" title="Warning">
        <template #content>
            <v-icon small>fa fa-triangle-exclamation</v-icon>
            <span v-html="warningMessage"/>
        </template>
        <template #footer="{close}">
            <v-btn
                color="success"
                dark
                small
                @click="() => handleClose(close)"
            >I Understand
            </v-btn>
        </template>
    </BaseModalDialog>
</template>