<template>
    <div v-if="address">
        <v-row no-gutters justify="center">
            <v-col style="border-bottom: 1px #0000001F solid; height: 49px">
                <v-app-bar elevation="0" dense color="white">
                    <v-app-bar dense color="white" class="px-4" style="margin: 0 auto; width: 160px"
                               elevation="0">
                        <v-tabs
                            show-arrows
                            slider-size="3"
                            :grow="false"
                            light
                            background-color="white"
                            :slider-color="currency.unit"
                            style="max-width: 95% !important;"
                            ref="tabsRef"
                        >
                            <v-tooltip
                                v-for="({route, publicName, disabled}, index) in tabs"
                                bottom
                                open-delay="500"
                                :disabled="!disabled"
                                :key="route"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        class="d-flex justify-center"
                                    >
                                        <v-tab
                                            :to="finalRoutes[index].route"
                                            :disabled="disabled"
                                        >
                                            {{ publicName }}
                                        </v-tab>
                                    </div>
                                </template>
                                View is disabled, because address<br/> hasn't been indexed yet.
                            </v-tooltip>
                        </v-tabs>
                        <v-spacer/>
                        <EntityHeaderLabel :label="currency.unit.toUpperCase() + ' ADDRESS:'" :entity="address"/>
                    </v-app-bar>
                </v-app-bar>
            </v-col>
        </v-row>
        <v-container class="px-4" fluid>
            <router-view @component-loaded-event="onClick"/>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {computed} from "vue";
import {DataState} from "@/components/common/types/data";
import EntityHeaderLabel from "@/views/Cluster/EntityHeaderLabel.vue";

export default {
    name: 'AddressView',
    components: {EntityHeaderLabel},
    provide() {
        return {
            entityId: computed(() => this.$route.params.entity ?? this.currentAddress)
        }
    },
    watch: {
        currency(newValue, oldValue) {
            this.$store.dispatch('loadAdress', {id: this.$route.params.address })
        },
        "$route.params.address": function (to, from) {
            if (to !== from) {
                this.currentAddress = this.$route.params.address
                this.$store.dispatch('loadAdress', {id: this.$route.params.address })
            }
        }
    },
    methods: {
        onClick(){
            //fixes out-of-sync slider
            this.$refs.tabsRef && this.$refs.tabsRef.onResize();
        },
    },
    created() {
        this.$store.dispatch('loadCategories')
        this.$store.dispatch('loadAdress', {id: this.$route.params.address})
        this.currentAddress = this.$route.params.address
    },
    mounted() {
        this.onClick()
    },
    beforeDestroy() {
        this.$store.commit('TRANSACTIONS_CANCEL_AND_CLEAR')
    },
    computed: {
        ...mapGetters(["existsInGraphDb", "addressInfo"]),
        address() {
            return this.currentAddress
        },
        isLoading() {
            return this.$store.getters.addressLoading
        },
        currency() {
            return this.$store.getters.currency
        },
        finalRoutes() {
            return this.tabs.map(x => {
                return {...x, route: '/' + this.currency.unit + '/address/' + this.address + x.route}
            })
        },
        hasAddressConfirmedTransactions() {
            return this.addressInfo?.confirmedTransactions > 0
        },
        hasAddressAnyTransactions() {
            return this.addressInfo?.confirmedTransactions > 0 || this.addressInfo?.unconfirmedTransaction > 0
        },
        tabs() {
            return [
                {route: "/summary", publicName: "Summary"},
                {route: "/transactions", publicName: "Transactions", disabled: !this.hasAddressAnyTransactions},
                {route: "/timeline", publicName: "Timelines", disabled: !this.hasAddressConfirmedTransactions},
                {route: "/patterns", publicName: "Patterns", disabled: !this.hasAddressConfirmedTransactions},
                {route: "/balance", publicName: "Balance", disabled: !this.hasAddressAnyTransactions},
                {route: "/cluster", publicName: "Cluster", disabled: !this.existsInGraphDb},
                {route: "/funds", publicName: "Funds", disabled: !this.existsInGraphDb},
                {route: "/interactions", publicName: "Interactions", disabled: !this.existsInGraphDb}
            ]
        },
    },
    data() {
        return {
            categoriespagination: {
                page: 1,
                itemsPerPage: 100,
            },
            currentAddress: "",
            exportOptions: [
                {title: 'EN HTML'},
                {title: 'CZ HTML'},
                {title: 'CSV'}
            ],
            clusterInfoState: DataState.Initial,
            clusterInfo: null,
            getClusterInfo: null,
        }
    }
}
</script>