import Vue from 'vue'
import Router from 'vue-router'
import AddressView from '../components/address/AddressView'
import TransactionView from '../components/transaction/TransactionView'
import Currency from '../components/currency/CurrencyView'
import AddressSummary from '../components/address/AddressSummary/AddressSummary.vue'
import ChartBalance from '@/components/chart/ChartBalance'
import AddressBalance from '../components/address/BalanceTree'
import AddressFunds from '../components/address/AddressFunds'
import FundsResult from '../components/Funds/FundsResult'
import AddressCluster from '../components/address/AddressCluster'
import AddressInteractions from '../components/address/AddressInteractions'
import InteractionsResult from '../components/Interactions/InteractionsResult'
import CurrenciesStatus from '../components/currency/CurrenciesStatus'
import CurrencyDetail from '../components/currency/CurrencyDetailView'
import TransactionDetail from '../components/transaction/TransactionDetail'
import TransactionNetwork from '../components/network/TransactionNetwork'
import LoginView from '../components/user/LoginView'
import SearchView from '../components/search/SearchView'
import UserProfileView from '../components/user/UserProfileView/UserProfileView.vue'

import BlockDetailView from '../components/block/BlockDetailView'
import GraphSingleView from '../components/network/GraphSingleView'
import AddressBottomDrawer from '../components/address/AddressBottomDrawer'
import ResizablePanel from '../components/ResizablePanel'
import BlocksView from '../components/block/BlocksView'
import NeighboursView from '../components/currency/NeighboursView'
import CaseDetail from '@/views/Case/CaseDetail.vue'

import PageNotFound from '../components/PageNotFound'

import TransactionBottomDrawer from '../components/transaction/TransactionBottomDrawer'
import AddressPatterns from "../components/address/AddressPatterns"
import PathsNetwork from "../components/network/PathsNetwork"
import AdvanceSearchView from "../components/search/AdvanceSearchView"
import CurrencySummary from "../components/currency/CurrencySummary"
import PeersMetadata from "../components/peers/PeersMetadata"
import MonitoringBase from "@/components/CryptoInterceptor/MonitoringBase";
import MonitoringNodes from "@/components/CryptoInterceptor/MonitoringNodes";
import MonitoringFiles from "@/components/CryptoInterceptor/MonitoringFiles";
import EntityTransactionsList from "@/components/common/EntityTransactionsList.vue";
import EntityTransactionsGeo from "@/components/common/EntityTransactionsGeo.vue";


// refactored views
import AddressMetadataView from '@/views/Addresses/AddressMetadataView'
import CategoriesView from '@/views/Addresses/CategoriesView'
import IdentitiesView from '@/views/Addresses/IdentitiesView'

import ClusterMetadataView from '@/views/ClusterMetadata/ClusterMetadataView'
import OwnersView from '@/views/ClusterMetadata/OwnersView'

import AlarmsView from '@/views/AlarmsView'
import FilesView from '@/views/FilesView'
import GraphsView from '@/views/GraphsView'
import CasesView from '@/views/Case/CasesView.vue'
import RolesView from '@/views/RolesView'
import UsersView from '@/views/UsersView'
import LogsView from '@/views/LogsView'

import guards from "./guards"
import AllClusterMetadataView from "@/views/ClusterMetadata/AllClusterMetadataView";
import ClusterView from "@/views/Cluster/ClusterView.vue";
import ClusterSummary from "@/views/Cluster/Summary/ClusterSummary.vue";
import ClusterFunds from "@/views/Cluster/ClusterFunds.vue";
import ClusterInteractions from "@/views/Cluster/ClusterInteractions.vue";
import TransactionsView from "@/views/Transactions/TransactionsView.vue";
import ClusterBottomDrawer from "@/views/Cluster/BottomDrawer/ClusterBottomDrawer.vue";
import MnemonicConvertor from "@/components/MnemonicConvertor/MnemonicConvertor.vue";
import TransactionsFilterCustom from "@/views/Transactions/FilterCustom/TransactionsFilterCustom.vue";
import TransactionsFilterGeo from "@/views/Transactions/FilterGeo/TransactionsFilterGeo.vue";
import EntityTransactions from "@/components/common/EntityTransactions.vue";
import GraphExplorer from "@/views/GraphExplorer/GraphExplorer.vue";
import AdvancedSearch from "@/views/AdvancedSearch.vue";
import StatusView from "@/views/StatusView.vue";

Vue.use(Router);

/**
 * Comments are fixing following warnings
 * [vue-router] Named Route 'ResizablePanel' has a default child route. When navigating to this named route (:to="{name: 'ResizablePanel'"), the default child route will not be rendered. Remove the name from this route and use the name of the default child route for named links instead.
 * [vue-router] Duplicate named routes definition: { name: "addressSumary", path: "/:currency/graph/:graphId/config/:graphConfigId?/node/:nodeId?/address/:address/" }
 */

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            component: CurrenciesStatus, name: 'Currences'
        },

        {
            path: '/search',
            component: SearchView, name: 'Search'
        },

        {
            path: '/advanceSearch',
            component: AdvanceSearchView, name: 'AdvanceSearch'
        },
        {
            path: '/advancedSearch',
            component: AdvancedSearch, name: 'AdvancedSearch'
        },
        {
            path: '/user',
            component: UserProfileView, name: 'UserProfile'
        },
        {
            path: '/users',
            component: UsersView, name: 'Users'
        },
        {
            path: "/categories",
            name: 'CategoriesView',
            component: CategoriesView,
        },
        {
            path: '/roles',
            component: RolesView, name: 'Roles',
        },
        {
            path: '/logs',
            component: LogsView, name: 'Logs'
        },

        {
            path: '/login',
            component: LoginView, name: 'LoginView'
        },
        {
            path: '/explorer/:id?',
            component: GraphExplorer, name: "GraphExplorer"
        },
        {
            path: '/graph',
            component: GraphsView, name: 'GraphsView'
        },
        {
            path: '/alarms',
            component: AlarmsView, name: 'Alarms',
        },
        {
            path: '/files',
            component: FilesView, name: 'FilesView'
        },
        {
            path: '/cases',
            component: CasesView, name: 'CasesView',
        },
        {
            path: '/status',
            component: StatusView, name: 'StatusView',
        },
        {
            path: '/mnemonicConvertor',
            component: MnemonicConvertor, name: 'MnemonicConvertor',
        },

        {
            path: "/addresses",
            name: 'AddressMetadataView',
            component: AddressMetadataView,
        },
        {
            path: "/identities",
            name: 'IdentitiesView',
            component: IdentitiesView,
        },
        {
            path: '/custom-clusters',
            name: 'ClusterMetadataView',
            component: ClusterMetadataView,
        },
        {
            path: '/all-clusters',
            name: 'AllClusterMetadataView',
            component: AllClusterMetadataView,
        },
        {
            path: "/owners",
            name: 'OwnersView',
            component: OwnersView,
        },

        {
            path: '/cases/:caseId',
            component: CaseDetail, name: 'CaseDetail'
        },

        {
            path: '/:currency',
            component: Currency,
            beforeEnter: guards.currencyUnitGuard,
            children: [
                {
                    path: '/',
                    component: CurrencyDetail,
                    children: [
                        {
                            path: '/',
                            redirect: {name: 'currencyStatus'}
                        },
                        {
                            path: 'status',
                            component: CurrencySummary, name: 'currencyStatus'
                        },
                        {
                            path: 'transactions',
                            component: TransactionsView,
                            name: 'transactionsView',
                            children: [
                                {
                                    path: '/',
                                    redirect: {name: 'TransactionsFilterCustom'}
                                },
                                {
                                    path: 'custom',
                                    component: TransactionsFilterCustom, name: 'TransactionsFilterCustom'
                                },
                                {
                                    path: 'geo',
                                    component: TransactionsFilterGeo, name: 'TransactionsFilterGeo'
                                },
                            ]
                        },
                        {
                            path: 'blocks',
                            component: BlocksView, name: 'currencyBlocks'
                        },
                        {
                            path: 'peers',
                            component: NeighboursView, name: 'neighbours'
                        },
                        {
                            path: 'nodes',
                            component: PeersMetadata, name: 'peersMetadata'
                        },
                        {
                            path: 'monitoring',
                            component: MonitoringBase,
                            children: [
                                {
                                    path: '/',
                                    component: MonitoringNodes, name: 'MonitoringNodes'
                                },
                                {
                                    path: 'files',
                                    component: MonitoringFiles, name: 'MonitoringFiles'
                                },
                            ]
                        }
                    ]
                },
                {
                    path: 'block/:block',
                    component: BlockDetailView, name: 'Block'
                },
                {
                    path: 'search',
                    component: SearchView, name: 'CurrencySearch'
                },
                //todo add before enter address guard
                {
                    path: 'address/:address',
                    component: AddressView,
                    children: [
                        {
                            path: '/', redirect: {name: 'AddressSummary'}
                        },
                        {
                            path: 'summary',
                            component: AddressSummary, name: 'AddressSummary'
                        },
                        {
                            path: 'transactions',
                            component: EntityTransactions,
                            children: [
                                {
                                    path: '/',
                                    component: EntityTransactionsList,
                                    name: "AddressTransactions"
                                },
                                {
                                    path: 'geo',
                                    component: EntityTransactionsGeo,
                                    name: "AddressTransactionsGeo"
                                }
                            ]
                        },
                        {
                            path: 'timeline',
                            component: ChartBalance, name: 'AddressTimeline'
                        },
                        {
                            path: 'patterns',
                            component: AddressPatterns, name: 'addressPatterns'
                        },
                        {
                            path: 'balance',
                            component: AddressBalance, name: 'addressBalance'
                        },
                        {
                            path: 'funds',
                            component: AddressFunds, name: 'addressFunds',
                            props: true,
                            children: [
                                {
                                    path: ':entity',
                                    component: FundsResult, name: 'addressFundsResult'
                                }
                            ]
                        },
                        {
                            path: 'interactions',
                            component: AddressInteractions, name: 'addressInteractions',
                            children: [
                                {
                                    path: ':entity',
                                    component: InteractionsResult, name: 'addressInteractionsResult'
                                }
                            ]
                        },
                        {
                            path: 'cluster',
                            component: AddressCluster, name: 'addressCluster',
                            children: [
                                {
                                    path: ':entity',
                                    component: ClusterSummary, name: 'addressClusterSummary'
                                }
                            ]
                        },
                        {
                            path: 'funds/paths/source/:source/destination/:destination/maxHops/:maxHops',
                            component: PathsNetwork, name: 'FundsNetwork',
                            children: [
                                {
                                    path: 'detail',
                                    component: ResizablePanel,
                                    children: [
                                        {
                                            path: 'address/:detailAddress',
                                            component: AddressBottomDrawer,
                                            children: [
                                                {
                                                    path: '/', redirect: {name: 'addressSummary2'}
                                                },
                                                {
                                                    path: 'summary',
                                                    component: AddressSummary, name: 'addressSummary2'
                                                },
                                                {
                                                    path: 'transactions',
                                                    component: EntityTransactions,
                                                    children: [
                                                        {
                                                            path: '/',
                                                            component: EntityTransactionsList,
                                                            name: "AddressTransactionsFunds"
                                                        },
                                                        {
                                                            path: 'geo',
                                                            component: EntityTransactionsGeo,
                                                            name: "AddressTransactionsFundsGeo"
                                                        }
                                                    ]

                                                },
                                                {
                                                    path: 'timeline',
                                                    component: ChartBalance, name: 'AddressFundsAddressTimeline'
                                                },
                                                {
                                                    path: 'patterns',
                                                    component: AddressPatterns, name: 'addressPatterns2'
                                                },
                                                {
                                                    path: 'balance',
                                                    component: AddressBalance, name: 'addressBalance3'
                                                },
                                                {
                                                    path: 'cluster',
                                                    component: AddressCluster, name: 'addressClusterFundsDrawer',
                                                    children: [
                                                        {
                                                            path: ':entity',
                                                            component: ClusterSummary,
                                                            name: 'addressClusterSummaryFundsDrawer'
                                                        }
                                                    ]
                                                },
                                                {
                                                    path: 'funds',
                                                    component: AddressFunds, name: 'addressFundsFundsDrawer',
                                                    props: true,
                                                    children: [
                                                        {
                                                            path: ':entity',
                                                            component: FundsResult,
                                                            name: 'addressFundsResultFundsDrawer'
                                                        }
                                                    ]
                                                },
                                                {
                                                    path: 'interactions',
                                                    component: AddressInteractions,
                                                    name: 'AddressInteractionsFundsDrawer',
                                                    props: true,
                                                    children: [
                                                        {
                                                            path: ':entity',
                                                            component: InteractionsResult,
                                                            name: 'AddressInteractionsResultFundsDrawer'
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            path: 'cluster/:detailCluster',
                                            component: ClusterBottomDrawer,
                                            children: [
                                                {
                                                    path: '/', redirect: {name: 'clusterSummaryAddressFundsDrawer'}
                                                },
                                                {
                                                    path: 'summary',
                                                    component: ClusterSummary, name: 'clusterSummaryAddressFundsDrawer',
                                                },
                                                {
                                                    path: 'transactions',
                                                    component: EntityTransactions,
                                                    children: [
                                                        {
                                                            path: '/',
                                                            component: EntityTransactionsList,
                                                            name: "EntityTransactionsListAddressFundsDrawer"
                                                        },
                                                        {
                                                            path: 'geo',
                                                            component: EntityTransactionsGeo,
                                                            name: "EntityTransactionsGeoAddressFundsDrawer"
                                                        }
                                                    ]
                                                },
                                                {
                                                    path: 'funds',
                                                    component: ClusterFunds, name: 'clusterFundsAddressFundsDrawer',
                                                },
                                                {
                                                    path: 'interactions',
                                                    component: ClusterInteractions,
                                                    name: 'clusterInteractionsAddressFundsDrawer',
                                                },
                                            ]
                                        },
                                        {
                                            path: 'transaction/:detailTransaction',
                                            component: TransactionBottomDrawer, name: 'GraphTransaction4'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'transaction/:transaction?',
                    component: TransactionView,
                    children: [
                        {
                            path: '/',
                            component: TransactionDetail, name: 'TransactionDetail'
                        },
                        {
                            path: 'network/:sides',
                            component: TransactionNetwork, name: 'TransactionNetwork',
                            children: [
                                {
                                    path: 'detail',
                                    component: ResizablePanel,
                                    children: [
                                        {
                                            path: 'cluster/:detailCluster',
                                            component: ClusterBottomDrawer,
                                            children: [
                                                {
                                                    path: '/', redirect: {name: 'clusterSummaryTransactionDrawer'}
                                                },
                                                {
                                                    path: 'summary',
                                                    component: ClusterSummary, name: 'clusterSummaryTransactionDrawer',
                                                },
                                                {
                                                    path: 'transactions',
                                                    component: EntityTransactions,
                                                    children: [
                                                        {
                                                            path: '/',
                                                            component: EntityTransactionsList,
                                                            name: "EntityTransactionsListTransactionDrawer"
                                                        },
                                                        {
                                                            path: 'geo',
                                                            component: EntityTransactionsGeo,
                                                            name: "EntityTransactionsGeoTransactionDrawer"
                                                        }
                                                    ]
                                                },
                                                {
                                                    path: 'funds',
                                                    component: ClusterFunds, name: 'clusterFundsTransactionDrawer',
                                                },
                                                {
                                                    path: 'interactions',
                                                    component: ClusterInteractions,
                                                    name: 'clusterInteractionsTransactionDrawer',
                                                },
                                            ]
                                        },
                                        {
                                            path: 'address/:detailAddress',
                                            component: AddressBottomDrawer,
                                            children: [
                                                {
                                                    path: '/', redirect: {name: 'TransactionNetworkAddressSummary'}
                                                },
                                                {
                                                    path: 'summary',
                                                    component: AddressSummary, name: 'TransactionNetworkAddressSummary'
                                                },
                                                {
                                                    path: 'transactions',
                                                    component: EntityTransactions,
                                                    children: [
                                                        {
                                                            path: '/',
                                                            component: EntityTransactionsList,
                                                            name: "AddressTransactionsTransaction"
                                                        },
                                                        {
                                                            path: 'geo',
                                                            component: EntityTransactionsGeo,
                                                            name: "AddressTransactionsTransactionGeo"
                                                        }
                                                    ]

                                                },
                                                {
                                                    path: 'timeline',
                                                    component: ChartBalance, name: 'TransactionAddressTimeline'
                                                },
                                                {
                                                    path: 'patterns',
                                                    component: AddressPatterns, name: 'addressPatterns3'
                                                },
                                                {
                                                    path: 'balance',
                                                    component: AddressBalance, name: 'addressBalance1'
                                                },
                                                {
                                                    path: 'cluster',
                                                    component: AddressCluster, name: 'addressClusterTransactionDrawer',
                                                    children: [
                                                        {
                                                            path: ':entity',
                                                            component: ClusterSummary,
                                                            name: 'addressClusterSummaryTransactionDrawer'
                                                        }
                                                    ]

                                                },
                                                {
                                                    path: 'funds',
                                                    component: AddressFunds, name: 'addressFundsTransactionDrawer',
                                                    props: true,
                                                    children: [
                                                        {
                                                            path: ':entity',
                                                            component: FundsResult,
                                                            name: 'addressFundsResultTransactionDrawer'
                                                        }
                                                    ]
                                                },
                                                {
                                                    path: 'interactions',
                                                    component: AddressInteractions,
                                                    name: 'AddressInteractionsTransactionDrawer',
                                                    props: true,
                                                    children: [
                                                        {
                                                            path: ':entity',
                                                            component: InteractionsResult,
                                                            name: 'AddressInteractionsResultTransactionDrawer'
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            path: 'transaction/:detailTransaction',
                                            component: TransactionBottomDrawer, name: 'GraphTransaction2'
                                        }
                                    ]
                                }
                            ]
                        },
                    ]
                },
                {
                    path: 'graph/:graphId/config/:graphConfigId?',
                    component: GraphSingleView, name: 'GraphSingleView',
                    children: [
                        {
                            path: 'node/:nodeId?',
                            component: ResizablePanel,
                            children: [
                                {
                                    path: 'transaction/:detailTransaction',
                                    component: TransactionBottomDrawer, name: 'GraphTransaction3'
                                },
                                {
                                    path: 'cluster/:detailCluster',
                                    component: ClusterBottomDrawer,
                                    children: [
                                        {
                                            path: '/', redirect: {name: 'clusterSummaryGraphDrawer'}
                                        },
                                        {
                                            path: 'summary',
                                            component: ClusterSummary, name: 'clusterSummaryGraphDrawer',
                                        },
                                        {
                                            path: 'transactions',
                                            component: EntityTransactions,
                                            children: [
                                                {
                                                    path: '/',
                                                    component: EntityTransactionsList,
                                                    name: "EntityTransactionsListGraphDrawer"
                                                },
                                                {
                                                    path: 'geo',
                                                    component: EntityTransactionsGeo,
                                                    name: "EntityTransactionsGeoGraphDrawer"
                                                }
                                            ]
                                        },
                                        {
                                            path: 'funds',
                                            component: ClusterFunds, name: 'clusterFundsGraphDrawer',
                                        },
                                        {
                                            path: 'interactions',
                                            component: ClusterInteractions, name: 'clusterInteractionsGraphDrawer',
                                        },
                                    ]
                                },
                                {
                                    path: 'address/:detailAddress',
                                    component: AddressBottomDrawer,
                                    children: [
                                        {
                                            path: '/', redirect: {name: 'addressSummaryGraphDrawer'}
                                        },
                                        {
                                            path: 'summary',
                                            component: AddressSummary, name: 'addressSummaryGraphDrawer'
                                        },
                                        {
                                            path: 'transactions',
                                            component: EntityTransactions,
                                            children: [
                                                {
                                                    path: '/',
                                                    component: EntityTransactionsList,
                                                    name: "AddressTransactionsGraph"
                                                },
                                                {
                                                    path: 'geo',
                                                    component: EntityTransactionsGeo,
                                                    name: "AddressTransactionsGeoGraph"
                                                }
                                            ]
                                        },

                                        {
                                            path: 'timeline',
                                            component: ChartBalance, name: 'GraphAddressTimeline'
                                        },
                                        {
                                            path: 'patterns',
                                            component: AddressPatterns, name: 'addressPatterns4'
                                        },
                                        {
                                            path: 'balance',
                                            component: AddressBalance, name: 'addressBalance2'
                                        },
                                        {
                                            path: 'cluster',
                                            component: AddressCluster, name: 'addressClusterGraphDrawer',
                                            children: [
                                                {
                                                    path: ':entity',
                                                    component: ClusterSummary, name: 'addressClusterSummaryGraphDrawer'
                                                }
                                            ]
                                        },
                                        {
                                            path: 'funds',
                                            component: AddressFunds, name: 'addressFundsGraphDrawer',
                                            props: true,
                                            children: [
                                                {
                                                    path: ':entity',
                                                    component: FundsResult, name: 'addressFundsResultGraphDrawer'
                                                }
                                            ]
                                        },
                                        {
                                            path: 'interactions',
                                            component: AddressInteractions, name: 'AddressInteractionsGraphDrawer',
                                            props: true,
                                            children: [
                                                {
                                                    path: ':entity',
                                                    component: InteractionsResult,
                                                    name: 'AddressInteractionsResultGraphDrawer'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'cluster/:clusterentity',
                    component: ClusterView,
                    children: [
                        { path: "/", redirect: { name: "clusterSummary"} },
                        {
                            path: 'summary',
                            component: ClusterSummary, name: 'clusterSummary',
                        },
                        {
                            path: 'transactions',
                            component: EntityTransactions,
                            children: [
                                {
                                    path: '/',
                                    component: EntityTransactionsList,
                                    name: "EntityTransactionsList"
                                },
                                {
                                    path: 'geo',
                                    component: EntityTransactionsGeo,
                                    name: "EntityTransactionsGeo"
                                }
                            ]
                        },
                        {
                            path: 'funds',
                            component: ClusterFunds, name: 'clusterFunds',
                        },
                        {
                            path: 'interactions',
                            component: ClusterInteractions, name: 'clusterInteractions',
                        },
                        {
                            path: 'funds/paths/source/:source/destination/:destination/maxHops/:maxHops',
                            component: PathsNetwork, name: 'FundsNetworkCluster',
                            children: [
                                {
                                    path: 'detail',
                                    component: ResizablePanel,
                                    children: [
                                        {
                                            path: 'address/:detailAddress',
                                            component: AddressBottomDrawer,
                                            children: [
                                                {
                                                    path: '/', redirect: {name: 'addressSumary3'}
                                                },
                                                {
                                                    path: 'summary',
                                                    component: AddressSummary, name: 'addressSumary3'
                                                },
                                                {
                                                    path: 'transactions',
                                                    component: EntityTransactions,
                                                    children: [
                                                        {
                                                            path: '/',
                                                            component: EntityTransactionsList,
                                                            name: "ClusterFundsTransactions"
                                                        },
                                                        {
                                                            path: 'geo',
                                                            component: EntityTransactionsGeo,
                                                            name: "ClusterFundsTransactionsGeo"
                                                        }
                                                    ]
                                                },

                                                {
                                                    path: 'timeline',
                                                    component: ChartBalance, name: 'AddressFundsAddressTimeline1'
                                                },
                                                {
                                                    path: 'patterns',
                                                    component: AddressPatterns, name: 'addressPatterns5'
                                                },
                                                {
                                                    path: 'balance',
                                                    component: AddressBalance, name: 'addressBalance4'
                                                },
                                                {
                                                    path: 'cluster',
                                                    component: AddressCluster, name: 'address1ClusterFundsDrawer',
                                                    children: [
                                                        {
                                                            path: ':entity',
                                                            component: ClusterSummary,
                                                            name: 'address1ClusterSingleFundsDrawer'
                                                        }
                                                    ]
                                                },
                                                {
                                                    path: 'funds',
                                                    component: AddressFunds, name: 'addressFundsClusterDrawer',
                                                    props: true,
                                                    children: [
                                                        {
                                                            path: ':entity',
                                                            component: FundsResult,
                                                            name: 'addressFundsResultClusterDrawer'
                                                        }
                                                    ]
                                                },
                                                {
                                                    path: 'interactions',
                                                    component: AddressInteractions,
                                                    name: 'addressInteractionsClusterDrawer',
                                                    props: true,
                                                    children: [
                                                        {
                                                            path: ':entity',
                                                            component: InteractionsResult,
                                                            name: 'addressInteractionsResultClusterDrawer'
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            path: 'transaction/:detailTransaction',
                                            component: TransactionBottomDrawer, name: 'GraphTransaction5'
                                        },
                                        {
                                            path: 'cluster/:detailCluster',
                                            component: ClusterBottomDrawer,
                                            children: [
                                                {
                                                    path: '/', redirect: {name: 'clusterSummaryFundsDrawer'}
                                                },
                                                {
                                                    path: 'summary',
                                                    component: ClusterSummary, name: 'clusterSummaryFundsDrawer',
                                                },
                                                {
                                                    path: 'transactions',
                                                    component: EntityTransactions,
                                                    children: [
                                                        {
                                                            path: '/',
                                                            component: EntityTransactionsList,
                                                            name: "EntityTransactionsListFundsDrawer"
                                                        },
                                                        {
                                                            path: 'geo',
                                                            component: EntityTransactionsGeo,
                                                            name: "EntityTransactionsGeoFundsDrawer"
                                                        }
                                                    ]
                                                },
                                                {
                                                    path: 'funds',
                                                    component: ClusterFunds, name: 'clusterFundsFundsDrawer',
                                                },
                                                {
                                                    path: 'interactions',
                                                    component: ClusterInteractions,
                                                    name: 'clusterInteractionsFundsDrawer',
                                                },
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {path: "*", component: PageNotFound, name: 'NotFound'}
    ]
})

router.beforeEach(guards.authenticationSettingsGuard)
export default router