<script setup>

import {computed} from "vue";
import {useRoute} from "vue-router/composables";
import {getRouteRelativePrefix} from "@/router/routeHelper";

const route = useRoute()

const props = defineProps({
    cluster: {
        type: String,
        required: true
    },
    noDecoration: {
        type: Boolean,
        required: false,
        default: false,
    },
    color: {
        type: String,
        required: false,
    },
    actAsLink: {
        type: Boolean,
        default: true,
        required: false
    },
    currency: {
        type: String,
        required: false
    },
    hasLabel: {
        type: Boolean,
        required: false,
        default: false
    }
})

const targetUrl = computed(() => getRouteRelativePrefix(route, props.currency) + "cluster/"  + props.cluster + "/")

</script>

<template>
    <router-link
        :is="actAsLink ? 'router-link' : 'span'"
        :style="{ textDecoration: 'none', maxWidth: '100%'}"
        class="fontMonospace"
        :to="{ path: targetUrl }"
    >
        <slot name="text"/>
    </router-link>
</template>
