var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100 scroll-y" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { "grid-list-md": "", fluid: "" } },
        [
          _vm._t("title"),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center" },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { row: "" },
                  model: {
                    value: _vm.radioButtonsValue,
                    callback: function ($$v) {
                      _vm.radioButtonsValue = $$v
                    },
                    expression: "radioButtonsValue",
                  },
                },
                _vm._l(_vm.limitValues, function (itemValue, i) {
                  return _c("v-radio", {
                    key: i,
                    attrs: {
                      color: _vm.currencyUnit,
                      label: itemValue != 0 ? " " + itemValue : "all",
                      value: itemValue,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.reloadData(itemValue)
                      },
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { attrs: { "align-center": "" } }, [_vm._t("body")], 2),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }