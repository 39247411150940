import { render, staticRenderFns } from "./TableDeleteModalDialog.vue?vue&type=template&id=6e4f2544&"
import script from "./TableDeleteModalDialog.vue?vue&type=script&lang=js&"
export * from "./TableDeleteModalDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6e4f2544')) {
      api.createRecord('6e4f2544', component.options)
    } else {
      api.reload('6e4f2544', component.options)
    }
    module.hot.accept("./TableDeleteModalDialog.vue?vue&type=template&id=6e4f2544&", function () {
      api.rerender('6e4f2544', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/common/TableComponents/TableDeleteModalDialog.vue"
export default component.exports