<script setup>
import {BaseModalDialog} from "@/components/common";
import {ref} from "vue";
import DataGridDeleteButton from "@/components/DataGrid/Actions/Common/Buttons/DataGridDeleteButton.vue";

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: "Remove items"
    },
    getItems: {
        type: Function,
        required: true
    },
    itemCallbackAsync: {
        type: Function,
        required: true
    },
    item_id: {
        type: String,
        required: false,
        default: "id"
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    currencyUnit: {
        type: String,
        required: false,
        default: undefined
    }
})

const modalRef = ref(null)
let items = ref([])

const open = () => {
    modalRef.value.open()
    items.value = props.getItems()
}
const onClose = () => {
    modalRef.value.setDisabled(false)
    modalRef.value.close()
    items.value = []
}

const emit = defineEmits(["action-finished-event"])
const loading = ref(false)

const onDelete = async () => {
    items.value = await props.getItems()
    if (items.value?.length === 0) {
        return
    }
    loading.value = true
    modalRef.value.setDisabled(true)

    const itemsToDelete = items.value.map(item => item[props.item_id])
    const failedItemIds = await props.itemCallbackAsync(itemsToDelete, props.currencyUnit)

    let failedItems = []
    if (Array.isArray(failedItemIds)) {
        failedItems = failedItemIds?.map(itemId => items.value.find(item => item.id === itemId))
    }

    items.value = []
    modalRef.value.setDisabled(false)
    modalRef.value.close()
    emit("action-finished-event", failedItems)
    loading.value = false
}

defineExpose({open, close, onDelete})

</script>

<template>
    <div>
        <BaseModalDialog
            ref="modalRef"
            :title="title"
            width="80vh">
            <template #content>
                <v-icon color="error" large left>mdi-delete</v-icon>
                <b>{{ items.length }}</b> items will be <b>permanently</b> deleted!
            </template>
            <template #footer="{close, setDisabled}">
                <v-btn :disabled="loading" small color="grey" @click="onClose">
                    Close
                </v-btn>
                <v-spacer/>
                <v-btn :disabled="modalRef.disabled" color="error" @click="onDelete" :loading="loading">
                    Delete
                </v-btn>
            </template>
        </BaseModalDialog>
        <DataGridDeleteButton @click="open" :disabled="disabled"/>
    </div>
</template>

<style scoped>

</style>