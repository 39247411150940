var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c("img", {
            staticStyle: {
              width: "48px",
              height: "48px",
              margin: "0 4px 4px 4px",
            },
            attrs: { src: _setup.currency.image },
          }),
          _vm._v(" "),
          _c("h1", [_vm._v(" " + _vm._s(_setup.currency.name) + " Status")]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-flex align-baseline",
            staticStyle: { height: "30px" },
          },
          [
            _c(
              "v-btn-toggle",
              {
                attrs: { borderless: "", mandatory: "" },
                model: {
                  value: _setup.graphUnit,
                  callback: function ($$v) {
                    _setup.graphUnit = $$v
                  },
                  expression: "graphUnit",
                },
              },
              [
                _c(
                  "v-btn",
                  { attrs: { value: "Blocks" } },
                  [
                    _c("v-icon", [
                      _vm._v(
                        "\n                        fa fa-solid fa-cube\n                    "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  { attrs: { value: "Time" } },
                  [
                    _c("v-icon", [
                      _vm._v(
                        "\n                        fa-regular fa-clock\n                    "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mb-3" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column",
          staticStyle: { padding: "0 6px" },
        },
        [
          _c(
            "v-row",
            { staticStyle: { gap: "6px" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c(_setup.FancyCard, {
                    staticClass: "mt-2",
                    staticStyle: {
                      "min-width": "250px",
                      "min-height": "205px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "d-block mr-2 ml-1",
                                    staticStyle: { filter: "opacity(0.6)" },
                                    attrs: { width: "22" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                fa-solid fa-database\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  "\n                            Blockchain Info\n                        "
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c(_setup.NameValueFlex, {
                              attrs: {
                                "loading-width": "100",
                                loading: _setup.loading,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                                Addresses:\n                            "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$humanize.formatNumber(
                                              _setup.dashboardData.cluster
                                                .addresses
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c(_setup.NameValueFlex, {
                              attrs: {
                                "loading-width": "100",
                                loading: _setup.loading,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _c("nobr", [
                                        _vm._v(
                                          "\n                                    Transactions:\n                                "
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$humanize.formatNumber(
                                              _setup.dashboardData.cluster
                                                .transactions
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c(_setup.NameValueFlex, {
                              attrs: {
                                "loading-width": "100",
                                loading: _setup.loading,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _c("nobr", [
                                        _vm._v(
                                          "\n                                    Clusters:\n                                "
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$humanize.formatNumber(
                                              _setup.dashboardData.cluster
                                                .clusters
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c(_setup.NameValueFlex, {
                              attrs: {
                                "loading-width": "100",
                                loading: _setup.loading,
                                nameWidth: "110",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                                Blockchain Size:\n                            "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _setup.dashboardData.status.blockchainSize
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$humanize.fileSize(
                                                    _setup.dashboardData.status
                                                      .blockchainSize
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ])
                                        : _c(
                                            "span",
                                            { staticClass: "textGrey" },
                                            [_vm._v("N/A")]
                                          ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c(_setup.NameValueFlex, {
                              attrs: {
                                "loading-width": "100",
                                loading: _setup.loading,
                                nameWidth: "110",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                                Index Size:\n                            "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$humanize.fileSize(
                                              _setup.dashboardData.status
                                                .indexSize
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c(_setup.FancyCard, {
                    staticClass: "mt-2",
                    staticStyle: { "min-height": "205px" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "d-block mr-2 ml-1",
                                    staticStyle: { filter: "opacity(0.6)" },
                                    attrs: { width: "22" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                mdi-magnify\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  "\n                            Crypto-Client Info\n                        "
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c(_setup.NameValueFlex, {
                              attrs: {
                                loading: _setup.loading,
                                nameWidth: "110",
                                "loading-width": "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                                User Agent:\n                            "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _setup.dashboardData.currency ===
                                              "Ethereum"
                                              ? _setup.dashboardData.status.useragent.slice(
                                                  0,
                                                  4
                                                )
                                              : _setup.dashboardData.status
                                                  .useragent
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c(_setup.NameValueFlex, {
                              attrs: {
                                "loading-width": "100",
                                loading: _setup.loading,
                                nameWidth: "110",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                                Connections:\n                            "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "link",
                                          attrs: {
                                            to:
                                              "/" +
                                              _setup.currency.unit +
                                              "/peers",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _setup.dashboardData.netinfo
                                                  .connections
                                              ) + " peers"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c(_setup.NameValueFlex, {
                              attrs: {
                                "loading-width": "100",
                                loading: _setup.loading,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                                Version:\n                            "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _setup.dashboardData.status.version
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _setup.dashboardData.status
                                                    .version
                                                ) +
                                                "\n                                "
                                            ),
                                          ])
                                        : _c(
                                            "span",
                                            { staticClass: "textGrey" },
                                            [_vm._v("N/A")]
                                          ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c(_setup.NameValueFlex, {
                              attrs: {
                                "loading-width": "100",
                                loading: _setup.loading,
                                nameWidth: "110",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                                Protocol Version:\n                            "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _setup.dashboardData.status
                                        .protocolVersion
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _setup.dashboardData.status
                                                    .protocolVersion
                                                ) +
                                                "\n                                "
                                            ),
                                          ])
                                        : _c(
                                            "span",
                                            { staticClass: "textGrey" },
                                            [_vm._v("N/A")]
                                          ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c(_setup.NameValueFlex, {
                              attrs: {
                                "loading-width": "100",
                                loading: _setup.loading,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                                Difficulty:\n                            "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      Number(
                                        _setup.dashboardData.status.difficulty
                                      )
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$humanize.formatNumber(
                                                  _setup.dashboardData.status
                                                    .difficulty,
                                                  _setup.currency.unit === "BTC"
                                                    ? 2
                                                    : 0
                                                )
                                              )
                                            ),
                                          ])
                                        : _c(
                                            "span",
                                            { staticClass: "textGrey" },
                                            [_vm._v("N/A")]
                                          ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 flex-grow-0" },
                [
                  _c(_setup.GaugeChart, {
                    attrs: {
                      loading: _setup.loading,
                      title: "Blocks",
                      value: _setup.blocksProgress,
                      thresholds: _setup.dashboardData.thresholds?.blockchain,
                      blockTime: _setup.getBlockTime(_setup.blocksProgress),
                      graphUnit: _setup.graphUnit,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "titleIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "d-block mr-2 ml-1",
                                staticStyle: {
                                  filter: "opacity(0.6)",
                                  "font-size": "20px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            fa fa-solid fa-cube\n                        "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 flex-grow-0" },
                [
                  _c(_setup.GaugeChart, {
                    attrs: {
                      loading: _setup.loading,
                      title: "Indexing",
                      value: _setup.indexProgress,
                      thresholds: _setup.dashboardData.thresholds?.indexed,
                      blockTime: _setup.getBlockTime(_setup.indexProgress),
                      graphUnit: _setup.graphUnit,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "titleIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "d-block mr-2 ml-1",
                                staticStyle: {
                                  filter: "opacity(0.6)",
                                  "font-size": "22px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            mdi-database-plus-outline\n                        "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 flex-grow-0" },
                [
                  _c(_setup.GaugeChart, {
                    attrs: {
                      loading: _setup.loading,
                      title: "Importing",
                      value: _setup.importProgress,
                      thresholds: _setup.dashboardData.thresholds?.imported,
                      blockTime: _setup.getBlockTime(_setup.importProgress),
                      graphUnit: _setup.graphUnit,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "titleIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "d-block mr-2 ml-1",
                                staticStyle: {
                                  filter: "opacity(0.6)",
                                  "font-size": "22px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            mdi mdi-database-import-outline\n                        "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _setup.clusterProgress
                ? _c(
                    "v-col",
                    { staticClass: "pa-0 flex-grow-0" },
                    [
                      _c(_setup.GaugeChart, {
                        attrs: {
                          loading: _setup.loading,
                          title: "Clustering",
                          value: _setup.clusterProgress,
                          thresholds:
                            _setup.dashboardData.thresholds?.clustered,
                          blockTime: _setup.getBlockTime(
                            _setup.clusterProgress
                          ),
                          graphUnit: _setup.graphUnit,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "titleIcon",
                              fn: function () {
                                return [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", size: "20" } },
                                    [_vm._v("mdi-hub")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4170426085
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticStyle: { gap: "6px" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("v-data-table", {
                    staticClass: "cardShadow v-sheet--outlined mt-2",
                    attrs: {
                      loading: _setup.loading,
                      headers: _setup.tableHeaders,
                      items: _setup.loading ? [] : _setup.tableData,
                      "hide-default-footer": "",
                      dense: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function ({ item }) {
                          return [
                            item.block_height
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-left" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "link",
                                          attrs: {
                                            to:
                                              "/" +
                                              _setup.currency.unit +
                                              "/block/" +
                                              item.block_height,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  item.block_height
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-truncate" }, [
                                    item.block_time
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _setup.moment
                                                  .unix(item.block_time)
                                                  .format("YYYY-MM-DD HH:mm:ss")
                                              ) +
                                              "\n                                "
                                          ),
                                        ])
                                      : _c("i", { staticClass: "textGrey" }, [
                                          _vm._v("Unavailable"),
                                        ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(_vm._s(item.progress)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    item.time_deficit
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _setup.formatTime(
                                                  item.time_deficit
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ])
                                      : _c("i", { staticClass: "textGrey" }, [
                                          _vm._v("Unavailable"),
                                        ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatNumber")(
                                          Math.abs(item.block_deficit)
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 flex-grow-0" },
                [
                  _c(_setup.GaugeChart, {
                    attrs: {
                      loading: _setup.loading,
                      title: "Alarm Addresses",
                      value: _setup.alarmProgress,
                      thresholds:
                        _setup.dashboardData.thresholds?.alarm_address,
                      blockTime: _setup.getBlockTime(_setup.alarmProgress),
                      graphUnit: _setup.graphUnit,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "titleIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "d-block mr-2 ml-1",
                                staticStyle: {
                                  filter: "opacity(0.6)",
                                  "font-size": "22px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            mdi mdi-alarm\n                        "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 flex-grow-0" },
                [
                  _c(_setup.GaugeChart, {
                    attrs: {
                      loading: _setup.loading,
                      title: "Alarm Custom Clusters",
                      value: _setup.alarmCustomClusterProgress,
                      thresholds:
                        _setup.dashboardData.thresholds?.alarm_cluster_custom,
                      blockTime: _setup.getBlockTime(
                        _setup.alarmCustomClusterProgress
                      ),
                      graphUnit: _setup.graphUnit,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "titleIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "d-block mr-2 ml-1",
                                staticStyle: {
                                  filter: "opacity(0.6)",
                                  "font-size": "22px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            mdi mdi-alarm\n                        "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _setup.alarmCospentClusterProgress
                ? _c(
                    "v-col",
                    { staticClass: "pa-0 flex-grow-0" },
                    [
                      _c(_setup.GaugeChart, {
                        attrs: {
                          loading: _setup.loading,
                          title: "Alarm Co-Spent Clusters",
                          value: _setup.alarmCospentClusterProgress,
                          thresholds:
                            _setup.dashboardData.thresholds
                              ?.alarm_cluster_cospent,
                          blockTime: _setup.getBlockTime(
                            _setup.alarmCospentClusterProgress
                          ),
                          graphUnit: _setup.graphUnit,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "titleIcon",
                              fn: function () {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "d-block mr-2 ml-1",
                                      staticStyle: {
                                        filter: "opacity(0.6)",
                                        "font-size": "22px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            mdi mdi-alarm\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          608360068
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "pa-0" },
            [
              _c(_setup.LatestTransactions, {
                attrs: {
                  showInputsOutputs: true,
                  showFee: true,
                  transactionIdLength: 50,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }