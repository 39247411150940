import apiClient from "@/services/apiClient"
import Vue from "vue";
import {deepClone, processTx} from "@/utils";

export enum GERequestAction {
    TxAddition,
    TxRemoval,
    OutputFollow,
    OutputFollowIteratively,
    NodeRerender,
    TxExpandInputs,
    TxExpandOutputs,
    NodesMerge,
    NodesUnmerge,
    NodeChangeLabel,
    NoteAddition,
    NoteRemoval,
    NoteUpdate,
    AddressRemoval,
}

const state = {
    GEAddresses: {},
    GETransactions: {},
    GESelectedElement: undefined,

    ActorCb: undefined
}

const getters = {
    GEAddresses: (state) => state.GEAddresses,
    GETransactions: (state) => state.GETransactions,
    GESelectedElement: (state) => state.GESelectedElement,
}

const mutations = {
    GESelectElement(state, element) {
        state.GESelectedElement = element
    },

    //Addresses
    GEAddressesAdd(state, address) {
        Vue.set(state.GEAddresses, address, undefined)
    },
    GEAddressesRemove(state, address) {
        Vue.delete(state.GEAddresses, address)
    },

    //Txs
    GETransactionsAdd(state, tx) {
        Vue.set(state.GETransactions, tx.txhash, tx)
    },
    GETransactionsRemove(state, txId) {
        Vue.delete(state.GETransactions, txId)
    },

    //Actor cb
    GERegisterActorCb(state, cb) {
        state.ActorCb = cb
    },

    GEReset(state) {
        state.GEAddresses = {}
        state.GETransactions = {}
        state.GESelectedElement = undefined
        state.ActorCb = undefined
    }

}

const actions = {
    async GEAddTransaction({commit, dispatch}, {tx}) {
        commit('GETransactionsAdd', deepClone(tx))
    },

    async GERemoveTransaction({commit, dispatch}, {txId}) {
        commit('GETransactionsRemove', txId)
    },

    async GERequestAction({state, commit}, {actionType, payload, invoker}) {
        state.ActorCb(actionType, payload, invoker)
    },

    async getAddressInfo({commit}, {params: {currencyUnit, address}, pagination}) {
        try {
            return await apiClient.GET(`${currencyUnit}/cryptoaddress/${address}/summary`)
        } catch (error) {
            // this.dispatch("error", error.userFriendlyMessage)
        }
        return false
    },
    async getAddressTransactions({commit}, {params: {currencyUnit, address}, pagination, filter}) {
        try {
            return await apiClient.GET(`${currencyUnit}/cryptoaddress/${address}/transactions/`, {
                params: {
                    ...pagination,
                    ...filter
                }
            })
        } catch (error) {
            // this.dispatch("error", error.userFriendlyMessage)
        }
        return false
    },
    async getTransaction({commit, dispatch}, {params: {currencyUnit, txId}}) {
        try {
            if (this.getters.categories.length === 0) {
                await dispatch('loadCategories')
            }
            const result = await apiClient.GET(`${currencyUnit}/cryptotransaction/${txId}`)

            return processTx(result, undefined)
        } catch (error) {
            // this.dispatch("error", error.userFriendlyMessage)
        }
        return false
    },
    async saveGraph({commit, dispatch}, {name, description, graphId, graph}) {
        try {
            if (graphId === undefined) {
                const postRawResponse = await apiClient.POST(`graphs`, {
                    crypto: "BTC",
                    name: name,
                    is_hidden: true,
                    description: description

                }, undefined, true)

                graphId = Number(postRawResponse.headers.location.split("/").pop())
            }

            const response = await apiClient.POST(`graphs/${graphId}/config`, {
                description: description,
                json_config: {
                    entities: {},
                    graph: deepClone(graph)
                }
            })

            if(response) {
                dispatch('success', 'Graph has been saved.')
            }
            return graphId

        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        return false
    },
    async loadGraphExp({commit, dispatch}, {graphId}) {
        try {
            if (this.getters.categories.length === 0) {
                await dispatch('loadCategories')
            }
            return await apiClient.GET(`graphs/${graphId}`)
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        return false
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
