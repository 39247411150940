var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    staticClass: "cardShadow",
    attrs: { dense: "" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-icon", { staticClass: "d-block mr-2 ml-1" }, [
                  _vm._v("file_copy"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Files"),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(_setup.DataGrid, {
              ref: "gridRef",
              attrs: {
                "bulk-operations": "",
                "get-data": {
                  dataState: _setup.dataState,
                  getData: _setup.getData,
                  data: _setup.data,
                },
                headers: _setup.headers,
                "no-data-text": `No files found`,
                item_id: "id",
                "footer-props": {
                  "items-per-page-options": [10, 30, 50, -1],
                  "show-current-page": true,
                  showFirstLastPage: true,
                },
                "hide-footer-if-no-data": "",
                "hide-header-if-no-data": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "header-right",
                  fn: function ({
                    getItems,
                    refreshItems,
                    removeSelection,
                    patchActions,
                    isAnyItemSelected,
                    isDataPatched,
                  }) {
                    return [
                      _c(
                        _setup.DataGridBulkAdd,
                        {
                          ref: "DataGridBulkAddModalRef",
                          attrs: {
                            title: "Upload Files",
                            loading: _setup.uploadInProgress,
                            "save-button-text": "Upload Files",
                            addItemsButtonDisabled:
                              _setup.FileBulkImportRef?.itemsToAdd.length === 0,
                          },
                          on: { submit: _setup.onUpload },
                        },
                        [
                          _c(_setup.FileBulkImport, {
                            ref: "FileBulkImportRef",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(_setup.DataGridBulkDelete, {
                        attrs: {
                          disabled: !isAnyItemSelected,
                          "get-items": getItems,
                          "item-callback-async": _setup.deleteItems,
                          patchActions: patchActions,
                        },
                        on: {
                          "action-finished-event": (failedItems) => {
                            refreshItems()
                            removeSelection(failedItems)
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "td",
                        {
                          staticClass: "text-left",
                          staticStyle: { "max-width": "300px" },
                        },
                        [
                          _c(_setup.FileNameDownloadable, {
                            attrs: { item: item },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-right text-overflow" }, [
                        _vm._v(
                          _vm._s(_vm._f("fileSize")(item.size)) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-right text-overflow" }, [
                        _vm._v(_vm._s(item.mime)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "text-xs-right text-overflow fontMonospace",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("stringShortener")(item.md5, 16)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "text-xs-right text-overflow fontMonospace",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("stringShortener")(item.sha256, 16)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-right text-overflow" }, [
                        _vm._v(
                          _vm._s(_vm._f("formatDateTime")(item.created_at)) +
                            "\n                "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }