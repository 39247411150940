<template>
    <div class="height100 d-flex align-end" style="user-select: none">
        <div v-if="addressBalance.length > 0 && !addressBalanceLoading" class="width100">
            <HighCharts :options="chartOptions"/>
        </div>
        <div v-else-if="addressBalanceLoading" class="d-flex align-center justify-center height100 width100">
            <div class="font-weight-light">
                Loading balance chart
            </div>
            <div class="ml-2">
                <v-progress-circular
                    indeterminate
                    :color="currency.unit"/>
            </div>
        </div>
    </div>
</template>

<script>

import {Chart} from 'highcharts-vue'
import ProgressCircular from "@/components/common/ProgressCircular"
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import {formatDateTime} from "@/utils/filters"
import {mapGetters} from "vuex";
import moment from "moment";

Highcharts.dateFormats = {
    'common': function (time) {
        return formatDateTime(time)
    }
}

export default {
    name: "ChartAddressSummary",
    components: {
        ProgressCircular,
        HighCharts: Chart,
    },
    props: {
        addressInfo: {
            type: Object,
            required: true
        }
    },
    beforeCreate() {
        exportingInit(Highcharts);
    },
    created() {
        this.reloadData()
    },
    methods: {
        reloadData() {
            this.$store.dispatch('loadAddressBalance', {
                since: moment.unix(this.addressInfo.firstTx.blockTime).subtract(1, "second").unix(),
                until: moment.unix(this.addressInfo.lastTx.blockTime).add(1, "second").unix()
            })
        }
    },
    computed: {
        ...mapGetters(["currency", "addressBalance", "addressVolume", "addressBalanceLoading"]),
        chartOptions() {
            return {
                chart: {
                    zoomType: 'x',
                    height: 150,
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: undefined
                },
                subtitle: {
                    enabled: false
                },
                xAxis: {
                    type: 'datetime',
                    minRange: 3 * 1000, // three seconds
                },
                yAxis: [
                    {
                        title: {
                            text: this.currency === false ? '' : 'Balance (' + this.currency.unit + ')'
                        },
                        min: 0,
                        floor: 0,
                        minorGridLineWidth: 0,
                        gridLineWidth: 0
                    },
                ],
                tooltip: {
                    shared: true,
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.8f}</b><br/>',
                    xDateFormat: "%common"
                },
                scrollbar: {
                    liveRedraw: false
                },
                legend: {
                    enabled: false
                },
                series: [
                    {
                        type: 'line',
                        step: 'left',
                        color: this.$vuetify.theme.currentTheme[this.currency.unit],
                        name: this.currency.unit + ' balance',
                        data: this.addressBalance
                    },
                ],
                exporting: {
                    enabled: false
                }
            }
        }
    }
}
</script>

<style scoped>

</style>