<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <div v-cloak v-on="on" @drop.prevent="assignFileToImportedCsvFile" @dragover.prevent>
                <v-file-input
                    dense
                    :prepend-icon="icon"
                    accept=".csv"
                    :label="label"
                    v-model="importedCsvFile"
                    hide-details
                />
            </div>
        </template>
        <span>{{tooltip}}</span>
    </v-tooltip>
</template>

<script>
import { parseCSVFileToObjectAsync, normalizeData, CsvParserExceptionTypes } from "@/services/fileParser"

export default {
    name: "CsvImporterInput",
    props: {
        csvConfiguration: {
            type: Array,
            validator: prop => prop.every(i => i.hasOwnProperty("csvHeader") && i.hasOwnProperty("exportPropertyName")),
            required: true
        },
        icon: {
            type: String,
            default: "library_books"
        },
        label: {
            type: String,
            default: "Drop or click to select .csv files for import"
        }
    },
    computed: {
        tooltip() {
            const headers = this.csvConfiguration.map(i => i.csvHeader).join(";")
            return `Csv file must contain following headers: '${headers}' - order of headers does not matter`
        }
    },
    data() {
        return {
            importedCsvFile: undefined,

        }
    },
    watch: {
        async importedCsvFile(newValue, oldValue) {
            if (newValue instanceof File) {
                const columns = this.csvConfiguration
                try {
                    const parsedData = await parseCSVFileToObjectAsync(newValue, columns)
                    //todo FE add logic for notification for unsuccessfull imports +2
                    const requiredProps = columns.map(i => i.exportPropertyName)
                    const { normalizedData, corruptedData } = normalizeData(parsedData, columns)
                    this.$emit("import-successful", { importedData: normalizedData, corruptedData })
                }
                catch (error) {
                    if (error.type == CsvParserExceptionTypes.MissingHeaders) {
                        this.$emit("import-failed", { message: error.message })
                    }
                    else {
                        this.$emit("import-failed", { message: "Unexpected error, file could not be parsed." })
                    }
                }
                finally {
                    this.importedCsvFile = undefined
                }
            }
        }
    },
    methods: {
        assignFileToImportedCsvFile(e) {
            this.importedCsvFile = e.dataTransfer.files[0]
        }
    }
}
</script>
