<template>
    <div class="scroll-y height100">
        <v-container class="height100 px-4" fluid>
            <v-layout align-center>
                <h1>Users</h1>
                <v-spacer/>
                <UserDetailModal
                    :userRoles="roles.data || []"
                    @save-event="createUser"
                >
                    <template #showbutton="{ on: on }">
                        <CreateButton v-on="on" text="Create user"/>
                    </template>
                </UserDetailModal>
            </v-layout>
            <v-divider/>
            <v-layout wrap>
                <v-flex xs12>
                    <v-divider/>
                    <v-data-table
                        :headers="headers"
                        :items="users.data"
                        :options.sync="filter"
                        :server-items-length="users.total ? users.total : 0"
                        :loading="isUsersLoading"
                        class="elevation-0 border mt-2"
                        dense
                        :footer-props="{
                            'items-per-page-options': [15,30,50,100],
                            'show-current-page': true,
                            'showFirstLastPage': true,
                        }"
                    >
                        <template
                            v-for="h in headers"
                            v-slot:[`header.${h.value}`]="{ header }"
                        >
                            <v-text-field
                                :key="h.value"
                                v-if="h.value === 'name'"
                                append-icon="search"
                                :label="header.text"
                                single-line
                                hide-details
                                @click.stop.native
                                clearable
                                @input="debounceInput"
                                v-model="debouncedFilter.filterName"
                                class="text-field-label-style"
                            />
                            <v-text-field
                                :key="h.value"
                                v-if="h.value === 'email'"
                                append-icon="search"
                                :label="header.text"
                                single-line
                                hide-details
                                @click.stop.native
                                clearable
                                @input="debounceInput"
                                v-model="debouncedFilter.filterEmail"
                                class="text-field-label-style"
                            />
                            <v-text-field
                                :key="h.value"
                                v-if="h.value === 'roles'"
                                append-icon="search"
                                :label="header.text"
                                single-line
                                hide-details
                                @click.stop.native
                                clearable
                                @input="debounceInput"
                                v-model="debouncedFilter.filterRole"
                                class="text-field-label-style"
                            />
                            <div
                                :key="h.value"
                                v-if="h.value === 'is_active'"
                                style="padding-top: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                :key="h.value"
                                v-if="h.value === 'created_at'"
                                style="padding-top: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                :key="h.value"
                                v-if="h.value === 'updated_at'"
                                style="padding-top: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                :key="h.value"
                                v-if="h.value === 'action'"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                                <td class="text-left">{{ item.name }}</td>
                                <td class="text-left text-overflow">
                                    {{ item.email }}
                                </td>
                                <td class="text-xs-left text-overflow">
                                    <v-layout row>
                                        <v-chip
                                            v-for="role in item.roles.slice(0, 2)"
                                            :key="role.id"
                                            small
                                            outlined
                                            label
                                        >
                                            {{ role.name }}
                                        </v-chip>
                                        <BaseButtonShowMoreItems
                                            :items="item.roles"
                                            :shownItemsCount="2"
                                        >
                                            <v-chip
                                                v-for="role in item.roles"
                                                :key="role.id"
                                                class="ma-1"
                                                small
                                                outlined
                                                label
                                            >
                                                {{ role.name }}
                                            </v-chip>
                                        </BaseButtonShowMoreItems>
                                    </v-layout>
                                </td>
                                <td
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    <v-chip
                                        v-if="item.is_active"
                                        label
                                        color="success"
                                        outlined
                                        small
                                    >
                                        Active
                                    </v-chip>
                                    <v-chip
                                        v-else
                                        label
                                        outlined
                                        small
                                        color="error"
                                    >
                                        Inactive
                                    </v-chip>
                                </td>
                                <td
                                    class="text-xs-right text-overflow"
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    {{
                                        item.created_at
                                            | formatDateTime
                                    }}
                                </td>
                                <td
                                    class="text-xs-right text-overflow"
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    {{
                                        item.updated_at
                                            | formatDateTime
                                    }}
                                </td>
                                <td style="
                                        text-align: center;
                                        vertical-align: middle;
                                        min-width: 92px;
                                    ">
                                    <UserDetailModal
                                        :userRoles="roles.data || []"
                                        :userDetail="item"
                                        @save-event="editUser"
                                    >
                                        <template #showbutton="{ on }">
                                            <TableEditButton
                                                v-on="on"
                                                editButtonTooltip="Edit user"
                                            />
                                        </template>
                                    </UserDetailModal>
                                    <TableDeleteModalDialog
                                        :description="`Do you really want to delete user ${item.name}`"
                                        :itemToDelete="item"
                                        @delete-event="deleteUser"
                                        tooltip="Delete User"
                                    />
                                </td>
                            </tr>
                        </template>
                        <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                            <span>
                                {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                            </span>
                        </template>
                    </v-data-table>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import UserDetailModal from "@/components/user/UserDetailModal"
import TableDeleteModalDialog from "@/components/common/TableComponents/TableDeleteModalDialog"
import TableEditButton from "@/components/common/TableComponents/TableEditButton"
import CreateButton from "@/components/common/Buttons/CreateButton"
import {mapGetters} from "vuex";
import debounce from "debounce";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import BaseButtonShowMoreItems from "@/components/common/TableComponents/BaseButtonShowMoreItems";

export default {
    name: "UsersView",
    mixins: [routeSyncMixin],
    components: {
        BaseButtonShowMoreItems,
        TableDeleteModalDialog,
        TableEditButton,
        UserDetailModal,
        CreateButton
    },
    mounted() {
        this.$store.dispatch('loadRoles')
    },
    computed: {
        ...mapGetters(["users", "isUsersLoading", "roles"])
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                sortBy: {type: "ArrayOfStrings", default: () => ['created_at']},
                sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
                filterName: {type: "String", default: () => ''},
                filterEmail: {type: "String", default: () => ''},
                filterRole: {type: "String", default: () => ''},
            },
            debouncedFilter: {
                filterName: undefined,
                filterEmail: undefined,
                filterRole: undefined,
            },
            headers: [
                {align: "center", text: "Name", value: "name"},
                {align: "center", text: "Email", value: "email", width: 250},
                {align: "center", text: "Roles", value: "roles", sortable: true, width: 200},
                {align: "center", text: "Active?", value: "is_active", sortable: true},
                {align: "center", text: "Created At", value: "created_at"},
                {align: "center", text: "Updated At", value: "updated_at"},
                {align: "center", text: "Action", value: "action", sortable: false}
            ]
        };
    },
    methods: {
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),
        refresh() {
            this.$store.dispatch("loadUsers", this.filter)
        },
        async deleteUser({itemToDelete}) {
            await this.$store.dispatch("deleteUser", {userId: itemToDelete.id})
            this.refresh()
        },
        async editUser({userDetail}) {
            await this.$store.dispatch("editUser", {user: userDetail})
            this.refresh()
        },
        async createUser({userDetail}) {
            await this.$store.dispatch("createUser", {user: userDetail})
            this.refresh()
        }
    }
}
</script>