var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: { title: _vm.isEditModal ? "Edit graph" : "Create new graph" },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c("v-text-field-validateable", {
                    attrs: { rules: "required", label: "Name", outlined: "" },
                    model: {
                      value: _vm.detail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "name", $$v)
                      },
                      expression: "detail.name",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-textarea-validateable", {
                    attrs: {
                      outlined: "",
                      label: "Description",
                      rules: "required|max:255",
                    },
                    model: {
                      value: _vm.detail.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "description", $$v)
                      },
                      expression: "detail.description",
                    },
                  }),
                  _vm._v(" "),
                  _c("BlockchainCryptoPicker", {
                    attrs: {
                      currencies: _vm.currencies,
                      rules: "required",
                      disabled: _vm.isEditModal,
                    },
                    model: {
                      value: _vm.detail.crypto,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "crypto", $$v)
                      },
                      expression: "detail.crypto",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0 mb-n4",
                            model: {
                              value: _vm.detail.is_hidden,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "is_hidden", $$v)
                              },
                              expression: "detail.is_hidden",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: _vm.detail.is_hidden
                                                ? "primary"
                                                : "tertiary",
                                              dark: "",
                                              large: "",
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.detail.is_hidden
                                                ? "visibility_off"
                                                : "visibility"
                                            ) + "\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "Graph is " +
                                    _vm._s(
                                      _vm.detail.is_hidden
                                        ? "visible only to the creator."
                                        : "visible to all users."
                                    )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    outlined: "",
                    color: "error",
                    disabled: _vm.graphsModalState === _vm.modalState.Pending,
                  },
                  on: { click: close },
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "success",
                    loading: _vm.graphsModalState === _vm.modalState.Pending,
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save(close, setDisabled)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.isEditModal ? "Save graph" : "Create graph")
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }