var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "content-class": "whiteBackground",
        "min-width": `${_vm.headers.length * 240 + 42}px`,
        "close-on-content-click": false,
        "offset-x": "",
        top: "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-chip",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "addressImportedMetadataMenuChip",
                      staticStyle: { height: "24px" },
                      attrs: {
                        color: _vm.items.length > 0 ? "info" : "primary",
                        hover: "",
                        label: "",
                        outlined: "",
                      },
                    },
                    "v-chip",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(
                      "\n                " + _vm._s(_vm.icon) + "\n            "
                    ),
                  ]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.items.length) +
                      " " +
                      _vm._s(
                        _vm.items.length === 1
                          ? _vm.textSingle
                          : _vm.textMultiple
                      ) +
                      "\n        "
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-1",
                  attrs: { color: "success", small: "" },
                  on: { click: _setup.addEmptyEntry },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("\n                    add_box\n                "),
                  ]),
                  _vm._v("\n                Add entry\n            "),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _setup.computedHeaders,
              height: 32 * 5 + 52,
              items: _setup.props.items,
              "items-per-page": -1,
              dense: "",
              "hide-default-footer": "",
              "no-data-text": "No imported rows",
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item, index }) {
                  return [
                    _c(
                      "tr",
                      [
                        _vm._l(_vm.headers, function (header, index) {
                          return _c(
                            "td",
                            { key: index },
                            [
                              _c("v-text-field", {
                                staticClass: "fontMonospace",
                                attrs: { placeholder: header.text, dense: "" },
                                model: {
                                  value: item[header.value],
                                  callback: function ($$v) {
                                    _vm.$set(item, header.value, $$v)
                                  },
                                  expression: "item[header.value]",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "error",
                                  icon: "",
                                  "x-small": "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _setup.removeItemAtIndex(index)
                                  },
                                },
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(
                                    "\n                                mdi-delete\n                            "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }