<script setup>
import store from "@/store";
import {computed, ref} from "vue";

const props = defineProps({
    ipAddress: {
        type: String,
        required: true
    }
})

const wasActiveInLightningNetwork = ref(false)

const currencyIsBitcoin = computed(() => store.getters.currency.unit === 'BTC')

const getActivity = async () => {
    wasActiveInLightningNetwork.value = await store.dispatch('getNodeLightningNetworkActivity', { address: props.ipAddress })
}

const lightningNetworkEnrichmentEnabled = store.getters.settings.server.lnQueryEnabled

if(currencyIsBitcoin.value && lightningNetworkEnrichmentEnabled) {
    getActivity()
}


</script>

<template>
    <div v-if="wasActiveInLightningNetwork">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon color="#ffc001" v-on="on">
                    mdi-flash
                </v-icon>
            </template>
            This node propagated Lightning network transactions.
        </v-tooltip>
    </div>
</template>

<style scoped>

</style>