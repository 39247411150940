import {
    ICurrencyToItems,
    IEntry,
    IEntryFormattedForImport,
    IFormattedAndValidatedData,
    IIdentityObject,
    ImportEmitType,
    ImportFormat,
    IParsedAddressMetadata,
    IRow
} from "@/components/common/types/import";
import store from "@/store";
import {ArrayForEachAsync} from "@/utils";
import {computed, ref, Ref} from "vue";
import {ICurrencyData} from "@/components/common/types/currency";

const mergeItems = (finalObjectReference: IEntry, newItem: IEntryFormattedForImport) => {
    const combinedIdentitiesMap: Map<string, IIdentityObject> = new Map()

    //fill map with identities that were already processed
    finalObjectReference.identities.forEach(identity => {
        const identityKey = `${identity.label}${identity.url}${identity.source}`
        combinedIdentitiesMap.set(identityKey, identity)
    })

    //add currently iterated identity unless it already exists
    newItem.identities.forEach(newItemIdentity => {
        const identityKey = `${newItemIdentity.label}${newItemIdentity.url}${newItemIdentity.source}`

        if (!combinedIdentitiesMap.has(identityKey)) {
            combinedIdentitiesMap.set(identityKey, {
                //assign only existing object properties
                ...(newItemIdentity.label && {label: newItemIdentity.label}),
                ...(newItemIdentity.url && {url: newItemIdentity.url}),
                ...(newItemIdentity.source && {source: newItemIdentity.source})
            })
        }
    })

    //override last identities array with new one
    finalObjectReference.identities = Array.from(combinedIdentitiesMap.values())
    finalObjectReference.owners = Array.from(new Set([...finalObjectReference.owners, ...newItem.owners]))
    finalObjectReference.categories = Array.from(new Set([...finalObjectReference.categories, ...newItem.categories]))
};

// defaultRowValues is an object pointer
export default ({defaultRowValues, importFormat}: { defaultRowValues: IRow, importFormat: ImportFormat }) => {
    const defaultCurrency = ref(store.getters.currencyOrDefault)
    const loading = ref(false)
    const skipIdentities = ref(false)
    const tableItems: Ref<IEntry[]> = ref([])
    let failedRows: IEntry[] = []

    const isCaseImportFormat = computed(() => importFormat === ImportFormat.Case)

    const validateIdentity = (identity: IIdentityObject) => {
        if (isCaseImportFormat.value) {
            return identity.label;
        } else {
            return !(Object.values(identity).some(prop => prop) && Object.values(identity).some(param => !param));
        }
    }

    const addEmptyEntry = () => {
        const createNewEntry = () => {
            return {
                crypto: defaultCurrency.value.unit,
                address: '',
                identities: [],
                categories: [],
                owners: [],
            }
        }
        tableItems.value.unshift(createNewEntry())
    }
    const processRows = async (rows: IRow[]) => {
        const createNewAddressMetadata = () => {
            const metadata = {}
            metadata['categories'] = new Set()
            metadata['owners'] = new Set()
            metadata['identities'] = new Map()
            return metadata
        }
        const addAndVerifyIdentity = (metadata: IParsedAddressMetadata, label: string, url: string, source: string, index: number) => {
            if (isCaseImportFormat.value) {
                label = label ? label : defaultRowValues.label

                if (!label) {
                    throw new Error(`Row number ${index} requires label`)
                }

                if (metadata['identities'].has(label)) {
                    return
                }
                metadata['identities'].set(label, {label: label})
            } else {
                label = label ? label : defaultRowValues.label
                url = url ? url : defaultRowValues.url
                source = source ? source : defaultRowValues.source

                const identityMetadata = [label, url, source]

                if (identityMetadata.every(prop => !prop)) {
                    return
                }

                if (identityMetadata.some(prop => prop) && identityMetadata.some(param => !param)) {
                    throw new Error(`Identity on row ${index} requires label, url and source`)
                }

                const identityKey = `${label}${url}${source}`
                if (metadata['identities'].has(identityKey)) {
                    return
                }
                metadata['identities'].set(identityKey, {
                    label: label,
                    url: url,
                    source: source
                })
            }

        }
        const addMaybeValueToSet = (key, destination: IParsedAddressMetadata, maybeValue) => {
            if (maybeValue) {
                const valueArray = maybeValue.split(",")
                valueArray.forEach(v => {
                    destination[key].add(v)
                })
            } else {
                if (defaultRowValues[key]) {
                    destination[key].add(defaultRowValues[key])
                }
            }
        }
        const ifUndefinedInDestinationSet = (key, destination: IParsedAddressMetadata, value) => {
            if (destination[key])
                return
            destination[key] = value
        }

        let currencyAddressToMetadataMap = new Map()
        let error = false

        await ArrayForEachAsync(rows, ((r, index) => {
            if (error)
                return

            try {
                let metadata

                const cryptoForAddress = r.crypto ? r.crypto :
                    defaultRowValues.crypto ? defaultRowValues.crypto :
                        defaultCurrency.value.unit

                const rowAddress = r.address ? r.address : defaultRowValues.address

                metadata = currencyAddressToMetadataMap.get(`${cryptoForAddress}${rowAddress}`)

                if (metadata === undefined) {
                    metadata = createNewAddressMetadata()
                    currencyAddressToMetadataMap.set(`${cryptoForAddress}${rowAddress}`, metadata)
                }

                metadata.address = rowAddress
                ifUndefinedInDestinationSet('crypto', metadata, cryptoForAddress)
                addMaybeValueToSet('categories', metadata, r.categories)
                addMaybeValueToSet('owners', metadata, r.owners)
                addAndVerifyIdentity(metadata, r.label, r.url, r.source, index)
            } catch (e) {
                error = true
                store.dispatch('error', e.message)
                loading.value = false
            }
        }), 10, undefined)

        if (error)
            return

        const newTableData: IEntry[] = []
        Array.from(currencyAddressToMetadataMap.values()).forEach(x => {
            const entry: IEntry = {
                address: x.address,
                crypto: x.crypto,
                identities: Array.from(x.identities.values()),
                categories: Array.from(x.categories).map(x => ({name: x})),
                owners: Array.from(x.owners).map(x => ({name: x}))
            }
            newTableData.push(entry)
        })
        tableItems.value = newTableData
        loading.value = false
    }

    const currencies: Ref<ICurrencyData[]> = computed(() => store.getters.currencies)

    const formatAndValidateData = async () => {
        const currencyToItems: ICurrencyToItems = new Map()
        const currencyToRegex: Map<string, RegExp> = new Map()

        currencies.value.forEach((c: ICurrencyData) => {
            currencyToItems.set(c.unit, [])
            currencyToRegex.set(c.unit, new RegExp(c.regexp.slice(1, -1)))
        })

        const filterIdentitiesCallback = isCaseImportFormat.value
            ? (identity: IIdentityObject) => identity.label
            : (identity: IIdentityObject) => (identity.label && identity.source && identity.url)

        const deduplicationMap = new Map()

        await ArrayForEachAsync(tableItems.value, (metadata): IFormattedAndValidatedData => {
            if (metadata.address === '')
                return

            const newItem: IEntryFormattedForImport = {
                address: metadata.address,
                identities: metadata.identities.filter(filterIdentitiesCallback),
                owners: metadata.owners
                    .map(o => o.name)
                    .filter(o => o),
                categories: metadata.categories
                    .map(c => c.name)
                    .filter(c => c)
            }

            // force crypto in table to uppercase
            metadata.crypto = metadata.crypto.toUpperCase()

            const currencyUnitForThisItem = metadata.crypto ?? defaultCurrency.value.unit.toUpperCase()

            const currencyAddressKey = `${currencyUnitForThisItem}${metadata.address}`
            const finalItemReference = deduplicationMap.get(currencyAddressKey)
            if (finalItemReference) {
                mergeItems(finalItemReference, newItem)
                return
            }

            if (!currencyToRegex.get(currencyUnitForThisItem)?.test(newItem.address)) {
                failedRows.push(metadata)
                return
            }

            if (isCaseImportFormat.value && metadata.identities.length === 0) {
                failedRows.push(metadata)
                return
            }

            if (metadata.identities.some((identity: IIdentityObject) => !validateIdentity(identity))) {

                failedRows.push(metadata)
                return
            }

            if (!currencyToItems.get(currencyUnitForThisItem)) {
                failedRows.push(metadata)
                return
            }

            deduplicationMap.set(currencyAddressKey, newItem)
            currencyToItems.get(currencyUnitForThisItem).push(newItem)
        }, 50, undefined)

        return currencyToItems
    }

    const propagateToClusters: Ref<boolean> = ref(true)
    const importAddressesUsingCallback = async (currencyToItems: ICurrencyToItems, emit: ImportEmitType, importRequestCallback: Function) => {
        let totalRequests = 0
        const failedRequestCurrencies: Set<string> = new Set()

        // @ts-ignore
        for (const [key, value] of currencyToItems) {
            if (value.length === 0)
                continue
            totalRequests += 1

            const success = await importRequestCallback(value, key)
            if (!success) {
                failedRequestCurrencies.add(key)
            }
        }

        const successfulRequestAmount = totalRequests - failedRequestCurrencies.size
        emit("import-finished", successfulRequestAmount)

        if (failedRows.length > 0) {
            store.dispatch("warning", "Some addresses did not match the expected format and were not imported");
        }

        if (successfulRequestAmount > 0) {
            tableItems.value = [...tableItems.value.filter(item => failedRequestCurrencies.has(item.crypto)), ...failedRows]

            if (tableItems.value.length === 0) {
                store.dispatch("success", "Address import successful")
                emit("close-modal")
            }
        }
        return !!successfulRequestAmount
    }

    const importAddresses = async (emit: ImportEmitType, importRequestCallback: Function) => {
        // Clear previously failed rows
        failedRows = []

        const currencyToItems = await formatAndValidateData()

        // Invoke callback for each crypto-address pair
        return await importAddressesUsingCallback(currencyToItems, emit, importRequestCallback)
    }

    return {
        processRows,
        importAddresses,
        addEmptyEntry,
        defaultCurrency,
        loading,
        propagateToClusters,
        skipIdentities,
        tableItems
    }
}