var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticStyle: { position: "relative", height: "100%", overflow: "hidden" },
    },
    [
      _c(_setup.GraphExplorerDrawer),
      _vm._v(" "),
      _c("v-text-field", {
        staticClass: "fontMonospace elevation-0",
        staticStyle: {
          width: "fit-content",
          "font-size": "14px",
          "margin-top": "4px",
          "margin-bottom": "-10px",
          "margin-left": "13px",
          "z-index": "1",
        },
        attrs: {
          placeholder: "New graph name",
          "prepend-inner-icon": "edit",
          solo: "",
          outlined: "",
          dense: "",
        },
        model: {
          value: _setup.graphName,
          callback: function ($$v) {
            _setup.graphName = $$v
          },
          expression: "graphName",
        },
      }),
      _vm._v(" "),
      _c("v-text-field", {
        staticClass: "position-absolute fontMonospace",
        staticStyle: {
          "min-width": "500px",
          "max-width": "500px",
          top: "12px",
          left: "12px",
          "background-color": "#FFFFFF",
          "z-index": "1",
        },
        attrs: {
          dense: "",
          loading: _setup.loading,
          "clear-icon": "mdi-clear-circle",
          clearable: "",
          color: "info",
          label: "Search",
          outlined: "",
          "hide-details": "",
          placeholder: "Search Tx/Address",
        },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _setup.fetchAndNode.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "progress",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: {
                    absolute: "",
                    active: _setup.loading,
                    color: "info",
                    indeterminate: "",
                    height: "6px",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _setup.addNodeModel,
          callback: function ($$v) {
            _setup.addNodeModel = $$v
          },
          expression: "addNodeModel",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column",
          staticStyle: {
            width: "fit-content",
            position: "relative",
            "z-index": "1",
            top: "30px",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-center align-center rounded-lg rounded-l-0 pt-1 pb-2 elevation-2 border",
              staticStyle: {
                width: "66px",
                "background-color": "#fefefe",
                gap: "14px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex align-center justify-center flex-column",
                },
                [
                  _c(
                    _setup.BaseDataModifyButton,
                    {
                      attrs: { large: "", icon: "", color: "info" },
                      on: { click: _setup.saveGraph },
                    },
                    [
                      _c("v-icon", { staticStyle: { "font-size": "24px" } }, [
                        _vm._v(
                          "\n                        save\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "caption mt-n2",
                      staticStyle: { "font-size": "0.70rem !important" },
                    },
                    [_vm._v("\n                    SAVE\n                ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-n1 width100" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex align-center justify-center flex-column",
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { large: "", icon: "", color: "info" },
                      on: { click: _setup.reLayoutGraph },
                    },
                    [
                      _c("v-icon", { staticStyle: { "font-size": "24px" } }, [
                        _vm._v(
                          "\n                        mdi-source-branch-refresh\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "caption mt-n2",
                      staticStyle: { "font-size": "0.70rem !important" },
                    },
                    [_vm._v("\n                    LAYOUT\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(_setup.GraphExplorerLabelEditor, { ref: "labelEditorRef" }),
      _vm._v(" "),
      _c(_setup.GraphExplorerNotification, {
        attrs: { text: _setup.actionName },
        on: { "on-cancel-event": _setup.cancelAction },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", {
      ref: "cyRef",
      staticClass: "cyContainer",
      attrs: { id: "cy" },
    })
  },
]
render._withStripped = true

export { render, staticRenderFns }