var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-5" },
    [
      _c("FundsFilter", {
        attrs: { currency: _vm.currency, categories: _vm.categories },
        on: { "search-funds-event": _vm.onSearch },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("HeaderLined", { staticStyle: { "max-width": "200px" } }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "rgba(0, 0, 0, 0.6)",
                        "font-weight": "400",
                      },
                    },
                    [_vm._v("\n                    Filter\n                ")]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("HeaderLined", { staticClass: "mb-1 mx-6" }, [
        _c(
          "span",
          {
            staticStyle: { color: "rgba(0, 0, 0, 0.6)", "font-weight": "400" },
          },
          [_vm._v("\n            Result\n        ")]
        ),
      ]),
      _vm._v(" "),
      _c("FundsResult", {
        attrs: {
          filter: _vm.filter,
          currency: _vm.currency,
          categories: _vm.categories,
          addressFunds: _vm.currentClusterFunds,
          addressFundsLoading: _vm.currentFundsLoading,
          "is-logaritmic": _vm.currentAddressFundsIsLogarithmic,
          "current-cluster": _vm.clusterCurrent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }