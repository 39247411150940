<template>
    <v-alert color="red" type="info">
        Wrong Address/Cluster {{ address }}
        <br />
        <strong>{{ message }}</strong>
    </v-alert>
</template>
<script>
export default {
    name: 'AddressNotFoundInfo',
    props: ["address", "message"]
}
</script>