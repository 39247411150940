var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissions
    ? _c(
        "v-menu",
        {
          attrs: { "offset-x": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on: tooltip }) {
                                return [
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        color: "red",
                                        content: _vm.alarms.length.toString(),
                                        "offset-y": "13",
                                        "offset-x": "13",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "success",
                                              elevation: "0",
                                              icon: "",
                                            },
                                          },
                                          { ...tooltip, ...on }
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-bell")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_vm._v(" "), _c("span", [_vm._v("Create Alarm")])]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            269109759
          ),
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            [
              _c(
                "v-subheader",
                { staticStyle: { height: "34px !important" } },
                [_vm._v("ALARMS")]
              ),
              _vm._v(" "),
              _c("AlarmDetailModal", {
                attrs: {
                  currencies: _vm.currencies,
                  alarm: {
                    crypto: _vm.currency.unit,
                    hash: _vm.address.address,
                    username: this.$store.getters.user.name,
                  },
                  "new-alarm-for-address": "",
                },
                on: { "save-event": _vm.createNewAlarm },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "showbutton",
                      fn: function ({ on: on }) {
                        return [
                          _c(
                            "v-list-item",
                            _vm._g({ attrs: { link: "" } }, on),
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: { "font-size": "18px" },
                                  attrs: { left: "", color: "success" },
                                },
                                [_vm._v("add_box")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-title",
                                {
                                  staticStyle: {
                                    "font-weight": "initial",
                                    "font-size": "16px",
                                    color: "rgba(0,0,0,.7)",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        Create New\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3208646140
                ),
              }),
              _vm._v(" "),
              _vm.alarms.length > 0 || _vm.alarmCreating > 0
                ? _c("v-divider")
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.alarmCreating, function (i) {
                return _vm.alarmCreating
                  ? _c(
                      "v-list-item",
                      { key: i, staticStyle: { "max-height": "48px" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center ml-n5 d-flex justify-start",
                            staticStyle: { "max-height": "48px" },
                          },
                          [
                            _c("v-skeleton-loader", {
                              attrs: {
                                width: "90px",
                                loading: "",
                                type: "card-heading",
                              },
                            }),
                            _vm._v(" "),
                            _c("v-skeleton-loader", {
                              staticStyle: { "margin-left": "-35px" },
                              attrs: {
                                width: "85px",
                                loading: "",
                                type: "list-item-two-line",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(_vm.alarms, function (item, index) {
                return _c("alarm-detail-modal", {
                  key: item.id,
                  attrs: {
                    alarm: {
                      crypto: item.address.crypto,
                      hash: item.address.hash,
                      username: item.user.name,
                      ...item,
                    },
                    currencies: _vm.currencies,
                    "existing-alarm": "",
                  },
                  on: {
                    "save-event": _vm.editAlarm,
                    "delete-event": _vm.deleteAlarm,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "showbutton",
                        fn: function ({ on: one }) {
                          return [
                            _c(
                              "v-list-item",
                              _vm._g(
                                { attrs: { disabled: item.invalid, link: "" } },
                                one
                              ),
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "mr-2 ml-n3",
                                    attrs: {
                                      color:
                                        item.notifications_count > 0
                                          ? "warning"
                                          : "grey",
                                      outlined: "",
                                      small: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { small: "", left: "" } },
                                      [
                                        _vm._v(
                                          "\n                            mdi-bell-ring\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          item.notifications_count.toString()
                                        ) +
                                        "\n                    "
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    !item.invalid
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "0.95rem",
                                              "font-weight": "500",
                                              "line-height": "1.5rem",
                                              color: "rgba(0,0,0,.7)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm._f("stringShortener")(
                                                    item.name,
                                                    25
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "0.95rem",
                                              "font-weight": "500",
                                              "line-height": "1.5rem",
                                              color: "rgba(0,0,0,.4)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm._f("stringShortener")(
                                                    item.name,
                                                    25
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "0.8rem",
                                          "line-height": "1rem",
                                          color: "rgba(0,0,0,.6)",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("stringShortener")(
                                                item.user
                                                  ? item.user.name
                                                  : item.username,
                                                20
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }