var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-expansion-panels",
    { staticClass: "fileFormatInfo" },
    [
      _c(
        "v-expansion-panel",
        [
          _c(
            "v-expansion-panel-header",
            {
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c("v-icon", { attrs: { color: "primary" } }, [
                        _vm._v(
                          "\n                    $expand\n                "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                [
                  _c("v-icon", { attrs: { color: "info", left: "" } }, [
                    _vm._v(
                      "\n                    mdi-information-slab-box\n                "
                    ),
                  ]),
                  _vm._v("\n                File format\n            "),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("v-expansion-panel-content", [
      _c(
        "div",
        {
          class: [
            "grid-container",
            _setup.isStandardImportType
              ? "grid-columns-address-import"
              : "grid-columns-case-import",
          ],
        },
        [
          _c(
            "div",
            { class: _setup.headerItemStyle },
            [
              _c(_setup.HeaderLined, {
                attrs: { icon: "mdi-currency-sign", text: "Crypto*" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _setup.headerItemStyle },
            [
              _c(_setup.HeaderLined, {
                attrs: { icon: "mdi-tag-text-outline", text: "Entity*" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _setup.isStandardImportType ? "first-row-item" : "",
                _setup.headerItemStyle,
              ],
            },
            [
              _c(_setup.HeaderLined, {
                attrs: {
                  text: `Identity${_setup.isCaseImportType ? "*" : ""}`,
                  icon: "mdi-card-account-details-outline",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _setup.headerItemStyle },
            [
              _c(_setup.HeaderLined, {
                attrs: { icon: "mdi-label", text: "Categories" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _setup.headerItemStyle },
            [
              _c(_setup.HeaderLined, {
                attrs: { icon: "mdi-account", text: "Owners" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _setup.itemTypeStyle }, [
              _vm._v("\n                        crypto\n                    "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _setup.itemTypeStyle }, [
              _vm._v("\n                        address\n                    "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _setup.itemTypeStyle }, [
              _vm._v("\n                        label\n                    "),
            ]),
          ]),
          _vm._v(" "),
          _setup.isStandardImportType
            ? _c("div", [
                _c("div", { class: _setup.itemTypeStyle }, [
                  _vm._v(
                    "\n                        source\n                    "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _setup.isStandardImportType
            ? _c("div", [
                _c("div", { class: _setup.itemTypeStyle }, [
                  _vm._v("\n                        url\n                    "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _setup.itemTypeStyle }, [
              _vm._v(
                "\n                        categories\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _setup.itemTypeStyle }, [
              _vm._v("\n                        owners\n                    "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _setup.exampleItemTypeStyle }, [
              _vm._v("\n                        BTC\n                    "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _setup.exampleItemTypeStyle }, [
              _vm._v(
                "\n                        1NWDhnb..\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _setup.exampleItemTypeStyle }, [
              _vm._v(
                "\n                        Send Bitcoin\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _setup.isStandardImportType
            ? _c("div", [
                _c("div", { class: _setup.exampleItemTypeStyle }, [
                  _vm._v(
                    "\n                        report #123\n                    "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _setup.isStandardImportType
            ? _c("div", [
                _c("div", { class: _setup.exampleItemTypeStyle }, [
                  _vm._v(
                    "\n                        www.anyurl.com\n                    "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _setup.exampleItemTypeStyle }, [
              _vm._v(
                '\n                        "cat1,cat2"\n                    '
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { class: _setup.exampleItemTypeStyle }, [
              _vm._v(
                '\n                        "John,Jane"\n                    '
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }