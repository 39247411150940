var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.GenericPicker, {
    attrs: {
      "item-text": _vm.itemText,
      convertItemTextToString: _vm.convertItemTextToString,
      items: _vm.items,
      "get-items": _vm.getItems,
      innerClass: _vm.innerClass,
      value: _vm.value,
      "full-width": "",
      denser: "",
      outlined: "",
      readonly: _vm.readOnly,
      autofocus: "",
      "prepend-inner-icon": "search",
      placeholder: _vm.placeholder,
      "no-prepend-icon": "",
      "no-selection-slot": "",
      loading: _vm.loading,
    },
    on: {
      input: function ($event) {
        return _setup.emit("input", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(_vm.displayComponent, {
              tag: "Component",
              attrs: { item: item },
            }),
          ]
        },
      },
      {
        key: "hint",
        fn: function () {
          return [_vm._v("\n        Start typing to search\n    ")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }