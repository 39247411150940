var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-end" },
        [
          _c(
            "div",
            [
              _vm._t("text", function () {
                return [
                  _c("span", { staticClass: "text-h4" }, [
                    _vm._v(_vm._s(_vm.text)),
                  ]),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("div", [_vm._t("default")], 2),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.noDividers ? _c("v-divider") : _vm._e(),
      _vm._v(" "),
      !_vm.noDividers ? _c("v-divider", { staticClass: "mb-2" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }