<template>
    <div class="height100 scroll-y">
        <v-container grid-list-md fluid class="pa-0">
            <slot name="title" />
            <v-divider />
            <v-divider />
            <div class="flex-center">
                <v-radio-group v-model="radioButtonsValue" row>
                    <v-radio
                        :color="currencyUnit"
                        v-for="(itemValue,i) in limitValues"
                        v-bind:key="i"
                        @change="reloadData(itemValue)"
                        :label=" itemValue != 0 ? ' ' + itemValue : 'all'"
                        :value="itemValue"
                    />
                </v-radio-group>
            </div>
            <div align-center>
                <slot name="body" />
            </div>
        </v-container>
    </div>
</template>

<script>

const convertToClientQuery = (limit) => limit == "0" ? "all" : limit;
const convertToServerQuery = (limit) => limit == "all" ? "0" : limit;

export default {
    name: "LimitAbleLayout",
    props: {
        initLimitValue: {
            type: String,
            default: '100'
        },
        limitValues: {
            type: Array,
            default: () => ["100", "1000", "10000", "0"]
        }
    },
    computed: {
        currencyUnit() {
            return this.$store.getters.currency.unit;
        }
    },
    data() {
        return {
            radioButtonsValue: this.initLimitValue
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler: function (to, from) {
                const limit = to.query.limit || this.initLimitValue;

                if (limit != (from && from.query.limit)) {
                    const limitServerQuery = convertToServerQuery(limit);
                    this.radioButtonsValue = limitServerQuery;
                    this.$emit('reload-data', limitServerQuery)
                }
            }
        }
    },
    methods: {
        reloadData(limit) {
            const limitClientQuery = convertToClientQuery(limit);
            if (limitClientQuery != this.$route.query.limit) {
                this.$router.push({ query: { limit: limitClientQuery } })
            }
        }
    }
}
</script>

<style scoped>
.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>