import {deepClone} from "@/utils";
import {ref, watch} from "vue";
import {useRoute, useRouter} from 'vue-router/composables'

let _route
let _router
let _type

export default (propName: string, propDefaultValueFactory, type) => {
    _route = useRoute()
    _router = useRouter()
    _type = type

    const prop = ref(_readFromQueryOrDefault(propName, propDefaultValueFactory))
    watch(prop, () => _pushToQuery(propName, prop, propDefaultValueFactory))

    return prop
}

const _readFromQueryOrDefault = (propName, propDefaultValueFactory) =>
    _route.query[propName] ? _type(_route.query[propName]) : propDefaultValueFactory()


const _pushToQuery = (propName, prop, propDefaultValueFactory) => {
    const newQuery = _route.query ? deepClone(_route.query) : {}

    //delete phase
    delete newQuery[propName]

    //insert phase
    if (prop.value !== propDefaultValueFactory())
        //insert
        newQuery[propName] = prop.value

    _router.push({query: newQuery}).catch(error => {
        if (error.name !== "NavigationDuplicated") {
            console.error(error.name)
            throw error
        }
    })
}