<script setup>
import {numberFormatCompact} from "@/utils/filters";
import PeerTransactionList from "@/components/peers/PeerTransactionList.vue";
import PeerActivitiesTable from "@/components/peers/PeerActivitiesTable.vue";
import NodeLightningNetworkDataTable from "@/components/peers/NodeLightningNetworkDataTable.vue";
import NodeTorDataTable from "@/components/peers/NodeTorDataTable.vue";


const props = defineProps({
    nodeTorData: {
        required: true
    },
    nodeTorDataLoading: {
        type: Boolean
    },
    nodeLightningNetworkId: {
        required: true
    },
    nodeLightningNetworkChannels: {
        required: true
    },
    nodeLightningNetworkChannelsLoading: {
        type: Boolean
    },
    peerId: {
        required: true
    },
    peerDetail: {
        required: true
    }
})

</script>

<template>
    <v-tabs class="mt-5" color="tertiary">
        <v-tab>
            <v-icon left size="20">date_range</v-icon>
            Activity
        </v-tab>
        <v-tab>
            <v-icon left size="18">fa fa-arrow-right-arrow-left</v-icon>
            Transactions
        </v-tab>
        <v-tab :disabled="!nodeLightningNetworkId">
            <v-icon class="tab-lightning-icon" left color="primary">mdi-flash</v-icon>
            LN Channels
            <v-skeleton-loader
                v-if="nodeLightningNetworkId && nodeLightningNetworkChannelsLoading"
                type="heading"
                class="badgeLoading"
            />
            <v-badge v-else inline :content="numberFormatCompact(nodeLightningNetworkId ? nodeLightningNetworkChannels?.length : 0)"/>
        </v-tab>
        <v-tab :disabled="!nodeTorData">
            <img
                class="mr-2 tab-onion-icon"
                src="/img/ico/tor-onion.svg"
                :style="{'filter': nodeTorData ? '' : 'invert(39%) sepia(5%) saturate(0%) hue-rotate(137deg) brightness(88%) contrast(76%)'}"
                alt="Tor Onion"
                style="width: 24px; margin-bottom: 6px"
            />
            Tor Activity
            <v-skeleton-loader
                v-if="nodeTorDataLoading"
                type="heading"
                class="badgeLoading"
            />
            <v-badge v-else inline :content="numberFormatCompact(nodeTorData?.length ?? 0)"/>
        </v-tab>
        <v-tab-item>
            <PeerActivitiesTable :peerId="peerId"/>
        </v-tab-item>
        <v-tab-item eager>
            <PeerTransactionList :peerId="peerId"/>
        </v-tab-item>
        <v-tab-item>
            <NodeLightningNetworkDataTable :data="nodeLightningNetworkChannels"/>
        </v-tab-item>
        <v-tab-item>
            <NodeTorDataTable :address="peerDetail.node.host" :node-tor-data="nodeTorData"/>
        </v-tab-item>
    </v-tabs>
</template>

<style scoped>

.tab-onion-icon {
    filter: invert(39%) sepia(5%) saturate(0%) hue-rotate(137deg) brightness(88%) contrast(76%);
}

.v-tab--active .tab-onion-icon {
    filter: none;
}

.v-tab--active .tab-lightning-icon {
    color: #ffc001 !important;
}

</style>
<style>

.badgeLoading .v-skeleton-loader__heading {
    margin-top: 0 !important;
    width: 24px !important;
}

</style>
