<script setup>

import {useAttrs} from "vue";
import BaseFilterActivatorTextField from "@/components/Filters/BaseFilterActivatorTextField.vue";

const props = defineProps({
    componentFilterType: {
        type: Object,
        required: true
    }
})

const attrs = useAttrs()
const emit = defineEmits(['input', 'on-remove-filter-event'])

</script>

<template>
    <component
        :is="props.componentFilterType"
        :componentFilterBase="BaseFilterActivatorTextField"
        v-bind="attrs"
        @on-remove-filter-event="emit('on-remove-filter-event', $event)"
        @on-clear-event="emit('input', undefined)"
        @input="emit('input', $event)"
    />
</template>
