var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100" },
    [
      _c("h1", [_vm._v("Peers")]),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mb-3" }),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-0 border my-2",
        attrs: {
          loading: _vm.currencyNeighboursLoading,
          headers: _vm.headers,
          "items-per-page": -1,
          items: _vm.currencyNeighbours.data,
          "hide-default-footer": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c("v-chip", { attrs: { label: "" } }, [
                        _vm._v(_vm._s(item.addr)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-right" }, [
                    _vm._v(_vm._s(item.useragent + " (" + item.protover + ")")),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-right" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$humanize.fileSize(item.bytesrecv)) +
                        "\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("formatDateTime")(item.lastrecv * 1000)) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-right" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$humanize.fileSize(item.bytessent)) +
                        "\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("formatDateTime")(item.lastsend * 1000)) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-right" }, [
                    _vm._v(
                      "\n                    offset " +
                        _vm._s(item.timeoffset) +
                        "\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("formatDateTime")(item.conntime * 1000)) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-right" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.pingtime) +
                        " s\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.minping) +
                        " s\n                "
                    ),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }