<template>
    <div class="scroll-y height100">
        <v-container class="height100 px-4" fluid>
            <v-layout align-center>
                <h1>Graphs</h1>
                <v-spacer/>
                <CreateInteractiveGraphButton/>
                <GraphDetailModal
                    :currencies="currencies"
                    @save-event="createGraph"
                >
                    <template #showbutton="{ on }">
                        <CreateButton text="Create graph" v-on="on"/>
                    </template>
                </GraphDetailModal>
            </v-layout>

            <v-divider/>

            <v-layout wrap>
                <v-flex xs15>
                    <v-divider/>
                    <v-data-table
                        :footer-props="{
                            'items-per-page-options': [
                                10,
                                30,
                                50,
                                { text: 'All', value: -1 },
                            ],
                            'show-current-page': true,
                            'showFirstLastPage': true,
                        }"
                        :headers="headers"
                        :items="graphs.data"
                        :loading="graphsLoading"
                        :options.sync="filter"
                        :server-items-length="graphs.total ? graphs.total : 0"
                        class="elevation-0 border mt-2"
                        dense
                    >
                        <template
                            v-for="h in headers"
                            v-slot:[`header.${h.value}`]="{ header }"
                        >
                            <v-text-field
                                v-if="h.value === 'name'"
                                :key="h.value"
                                v-model="debouncedFilter.filterName"
                                :label="header.text"
                                append-icon="search"
                                class="text-field-label-style"
                                clearable
                                hide-details
                                single-line
                                @input="debounceInput"
                                @click.stop.native
                            />
                            <v-text-field
                                v-if="h.value === 'description'"
                                :key="h.value"
                                v-model="debouncedFilter.filterDescription"
                                :label="header.text"
                                append-icon="search"
                                class="text-field-label-style"
                                clearable
                                hide-details
                                single-line
                                @input="debounceInput"
                                @click.stop.native
                            />
                            <div
                                v-if="h.value === 'crypto'"
                                :key="h.value"
                                style="
                                    margin-bottom: auto;
                                    padding-bottom: auto;
                                "
                            >
                                <BlockchainCryptoPickerSlim
                                    v-model="filter.filterCurrency"
                                    :currencies="allCurrenciesExceptUnknown"
                                    dense
                                    hide-details
                                    @click.stop.native
                                />
                            </div>
                            <v-text-field
                                v-if="h.value === 'count'"
                                :key="h.value"
                                v-model="debouncedFilter.filterConfigs"
                                :label="header.text"
                                :min="0"
                                class="text-field-label-style"
                                clearable
                                hide-details
                                hide-spin-buttons
                                single-line
                                type="number"
                                @input="debounceInput"
                                @click.stop.native
                            />
                            <div
                                v-if="h.value === 'is_hidden'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ h.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'created_at'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ h.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'created_by'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ h.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'updated_at'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ h.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'action'"
                                :key="h.value"
                                style="padding-top: 20px; padding-bottom: 26px"
                            >
                                <h3>{{ h.text }}</h3>
                            </div>
                        </template>
                        <template #item="{ item }">
                            <tr>
                                <td class="text-left dynamicTruncate">
                                    <RouteToGraph :graph-object="item"/>
                                </td>
                                <v-hover v-slot="{ hover }" open-delay="200">
                                    <td class="text-left dynamicTruncate" :class="{'descriptionParentHover': hover}">
                                        <span
                                            :class="hover && 'show-full-text-hover'"
                                            class="description-styles"
                                            style="margin: auto 0 0 0"
                                        >{{ item.description }}</span>
                                    </td>
                                </v-hover>
                                <td
                                    class
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    <BlockchainCryptoBadge
                                        :crypto="item.crypto"
                                        :currencies="currencies"
                                        :iconWidth="24"
                                    />
                                </td>
                                <td
                                    class="text-right"
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    {{ item.count | formatNumber }}
                                </td>
                                <td
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                :color="item.is_hidden ? 'primary' : 'tertiary'"
                                                dark
                                                v-on="on"
                                            >{{
                                                    item.is_hidden
                                                        ? "visibility_off"
                                                        : "visibility"
                                                }}
                                            </v-icon
                                            >
                                        </template>
                                        <span
                                        >Graph is
                                            {{
                                                item.is_hidden
                                                    ? "hidden"
                                                    : "visible"
                                            }}</span
                                        >
                                    </v-tooltip>
                                </td>
                                <td
                                    class="text-overflow"
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    <span>{{
                                            item.created_at
                                                | moment("YYYY-MM-DD HH:mm:ss Z")
                                        }}</span>
                                </td>
                                <td
                                    class="text-overflow text-left"
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    <span>{{ item.creator }}</span>
                                </td>
                                <td
                                    class="text-overflow"
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    <span>{{
                                            item.updated_at
                                                | moment("YYYY-MM-DD HH:mm:ss Z")
                                        }}</span>
                                </td>
                                <td class="text-xs-left text-overflow" style="min-width: 95px">
                                    <GraphDetailModal
                                        :currencies="currencies"
                                        :graphDetail="item"
                                        @save-event="editGraph"
                                    >
                                        <template #showbutton="{ on }">
                                            <TableEditButton
                                                editButtonTooltip="Edit graph"
                                                v-on="on"
                                            />
                                        </template>
                                    </GraphDetailModal>
                                    <TableDeleteModalDialog
                                        :description="`Do you really want to delete graph ${item.name}`"
                                        :itemToDelete="item"
                                        tooltip="Delete Graph"
                                        @delete-event="deleteGraph"
                                    />
                                </td>
                            </tr>
                        </template>
                        <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                            <span>
                                {{ pageStart | formatNumber }} - {{
                                    pageStop | formatNumber
                                }} of {{ itemsLength | formatNumber }}
                            </span>
                        </template>
                    </v-data-table>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {
    BlockchainCryptoBadge,
    BlockchainCryptoPickerSlim,
    CategoryPicker,
    CreateButton,
    TableDeleteModalDialog,
    TableEditButton
} from "@/components/common"
import GraphDetailModal from "@/components/network/GraphDetailModal"
import {mapGetters} from "vuex"
import debounce from "debounce";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import {useGetActiveCurrencyOrDefault} from "@/components/Composables/useGetActiveCurrencyOrDefault";
import RouteToGraph from "@/components/common/RouteToGraph.vue";
import CreateInteractiveGraphButton from "@/components/user/UserProfileView/CreateInteractiveGraphButton.vue";

export default {
    name: 'GraphsView',
    mixins: [routeSyncMixin],
    components: {
        CreateInteractiveGraphButton,
        RouteToGraph,
        CategoryPicker,
        TableEditButton,
        TableDeleteModalDialog,
        GraphDetailModal,
        CreateButton,
        BlockchainCryptoBadge,
        BlockchainCryptoPickerSlim
    },
    computed: {
        ...mapGetters(["graphs", "currencies", "currency", "graphsLoading", "allCurrenciesExceptUnknown",])
    },
    data: () => ({
        filterInfo: {
            page: {type: "Number", default: () => 1},
            itemsPerPage: {type: "Number", default: () => 30},
            sortBy: {type: "ArrayOfStrings", default: () => ['created_at']},
            sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
            mustSort: {type: "Boolean", default: () => false},
            multiSort: {type: "Boolean", default: () => false},
            filterName: {type: "String", default: () => ''},
            filterDescription: {type: "String", default: () => ''},
            filterConfigs: {type: "String", default: () => ''},
            filterCurrency: {type: "String", default: () => ''}
        },
        debouncedFilter: {
            filterName: undefined,
            filterDescription: undefined,
            filterConfigs: undefined,
        },
        headers: [
            {text: "Name", align: "center", value: "name"},
            {text: "Description", align: "center", value: "description"},
            {text: "Currency", sortable: false, value: "crypto", width: 130},
            {text: "Config Count", align: "center", value: "count"},
            {align: 'center', text: 'Visibility', value: 'is_hidden'},
            {text: "Created At", align: "center", value: "created_at"},
            {text: "Created By", align: "center", value: "created_by"},
            {text: "Updated At", align: "center", value: "updated_at"},
            {text: "Action", value: "action", align: "center", sortable: false}
        ],
        search: null,
    }),
    created() {
        this.$_syncToRouteQuery(true, {
            ...this.$route.query,
            filterCurrency: useGetActiveCurrencyOrDefault(this.$route)
        })
    },
    methods: {
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),
        refresh() {
            this.$store.dispatch("loadGraphs", {pagination: this.filter})
        },
        async editGraph({graphDetail}) {
            await this.$store.dispatch("editGraph", {graph: graphDetail})
            this.refresh()
        },
        async createGraph({graphDetail}) {
            const graphid = await this.$store.dispatch('createGraph', {graph: graphDetail})
            if (graphid) {
                this.$router.push({name: "GraphSingleView", params: {graphId: graphid, currency: graphDetail.crypto}})
            }
        },
        async deleteGraph({itemToDelete}) {
            await this.$store.dispatch("deleteGraph", {graphId: itemToDelete.id})
            this.refresh()
        }
    }
};
</script>
