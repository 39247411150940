import * as ethUtils from "ethereumjs-util";
import {getDerivationPath} from "@/components/MnemonicConvertor/DerivationPathHelper";
import {getBip32ExtendedKey} from "@/components/MnemonicConvertor/ExtendedKeyHelper";
import {useGetData} from "@/components/common/Composables/useGetData";
import * as bitcoinLib from "@fandaf/bitcoinjs-lib";
import {ref, Ref} from "vue";
import * as bchaddr from 'bchaddrjs';
import {delay} from "@/utils";
import {ICurrencyObject} from "@/components/MnemonicConvertor/mnemonicConvertorTypes";

const byteArrayToHex = (byteArray) => {
    return Buffer.from(byteArray).toString('hex')
}

let bip32RootKey

const currencyIsEthereumLike = (cur) => {
    return cur.unit === 'ETH' || cur.unit === 'ETC'
}

const getPublicKey = (path: string, selectedCrypto) => {
    let pubKey = byteArrayToHex(getBip32ExtendedKey(path, bip32RootKey, selectedCrypto).publicKey)
    //ETH
    if (currencyIsEthereumLike(selectedCrypto.value)) {
        return ethUtils.addHexPrefix(pubKey)
    } else {
        return pubKey
    }
}

const getPrivateKey = (keyPair, selectedCrypto) => {

    //ETH
    if (currencyIsEthereumLike(selectedCrypto.value)) {
        return ethUtils.bufferToHex(keyPair.privateKey)
    } else {
        return keyPair.toWIF()
    }
}

const isSegWit = (selectedBip: number) => {
    return selectedBip === 49 || selectedBip === 84 || selectedBip === 141
}

const p2wpkhSelected = (bipSpecs, selectedBip: number) => {
    return selectedBip === 84 ||
        selectedBip === 141 && bipSpecs[selectedBip].scriptSemanticSelected.text === 'P2WPKH'
}

const p2wpkhNestedInP2shSelected = (bipSpecs, selectedBip: number) => {
    return selectedBip === 49 ||
        selectedBip === 141 && bipSpecs[selectedBip].scriptSemanticSelected.text === 'P2WPKH nested in P2SH'
}

const p2wshSelected = (bipSpecs, selectedBip: number) => {
    return selectedBip === 141 && bipSpecs[selectedBip].scriptSemanticSelected.text === 'P2WSH (1-of-1 multisig)'
}

const p2wshInP2shSelected = (bipSpecs, selectedBip: number) => {
    return selectedBip === 141 && bipSpecs[selectedBip].scriptSemanticSelected.text === 'P2WSH nested in P2SH (1-of-1 multisig)'
}

function getAddress(node, bipSpecs, selectedBip: number) {
    let address

    if (isSegWit(selectedBip)) {
        if (p2wpkhSelected(bipSpecs, selectedBip)) {
            address = bitcoinLib.payments.p2wpkh({pubkey: node.publicKey, network: node.network})
        } else if (p2wpkhNestedInP2shSelected(bipSpecs, selectedBip)) {
            address = bitcoinLib.payments.p2sh({
                redeem: bitcoinLib.payments.p2wpkh({pubkey: node.publicKey, network: node.network})
            })
        } else if (p2wshSelected(bipSpecs, selectedBip)) {
            address = bitcoinLib.payments.p2wsh({
                redeem: bitcoinLib.payments.p2ms({m: 1, pubkeys: [node.publicKey], network: node.network})
            })
        } else if (p2wshInP2shSelected(bipSpecs, selectedBip)) {
            address = bitcoinLib.payments.p2sh({
                redeem: bitcoinLib.payments.p2wsh({
                    redeem: bitcoinLib.payments.p2ms({m: 1, pubkeys: [node.publicKey], network: node.network})
                })
            })
        }
    } else {
        address = bitcoinLib.payments.p2pkh({pubkey: node.publicKey, network: node.network})
    }

    if (node.network.unit === 'BCH' && selectedBip === 32 || node.network.unit === 'BCH' && selectedBip === 44) {
        if (bchAddressFormat.value === 'Cash') {
            return bchaddr.toCashAddress(address.address)
        } else if (bchAddressFormat.value === 'BitPay') {
            return bchaddr.toBitpayAddress(address.address)
        } else {
            // do nothing, leave address in legacy format
        }
    }

    return address.address
}

const queueAddressesToGenerate = async ({numberOfRowsToGenerate, startingIndex, bipSpecs, selectedBip, data: dataRefArray, numberOfAddressesYetToGenerate, selectedCrypto, useHardenedAddresses, bip32RootKey: bip32RootKeyArg} : {
    numberOfRowsToGenerate: Ref<number>, startingIndex: Ref<number>, bipSpecs: object, selectedBip: number, data, numberOfAddressesYetToGenerate: Ref<number>, selectedCrypto: Ref<ICurrencyObject>, useHardenedAddresses: Ref<boolean>, bip32RootKey }) => {
    bip32RootKey = bip32RootKeyArg

    let shouldStartGenerating = false

    if (numberOfAddressesYetToGenerate.value === 0) {
        shouldStartGenerating = true
    }

    numberOfAddressesYetToGenerate.value = numberOfAddressesYetToGenerate.value + numberOfRowsToGenerate.value
    const startingIndexForThisGeneration= startingIndex.value
    startingIndex.value = startingIndex.value + numberOfRowsToGenerate.value

    if (shouldStartGenerating) {
        await generateAddresses(startingIndexForThisGeneration, bipSpecs, selectedBip, dataRefArray, numberOfAddressesYetToGenerate, selectedCrypto, useHardenedAddresses)
    }
}

const generateAddresses = async (startingIndexForThisGeneration, bipSpecs, selectedBip, dataRefArray, numberOfAddressesYetToGenerate, selectedCrypto, useHardenedAddresses) => {
    for (let i = 0; i < numberOfAddressesYetToGenerate.value; i++) {
        const path = `${getDerivationPath(bipSpecs, selectedBip)}/${i + startingIndexForThisGeneration}${useHardenedAddresses.value ? "'" : ""}`
        const bip32ExtendedKey = getBip32ExtendedKey(path, bip32RootKey, selectedCrypto)

        let address = getAddress(bip32ExtendedKey, bipSpecs, selectedBip)

        if (currencyIsEthereumLike(selectedCrypto.value)) {
            const pubKeyBuffer = bip32ExtendedKey.publicKey
            const ethPubKey = ethUtils.importPublic(pubKeyBuffer)
            const addressBuffer = ethUtils.publicToAddress(ethPubKey)
            const hexAddress = addressBuffer.toString('hex')
            const checksumAddress = ethUtils.toChecksumAddress(ethUtils.addHexPrefix(hexAddress))
            address = ethUtils.addHexPrefix(checksumAddress)
        }

        const addressData = useGetData("getAddressDetail", {
            currency: selectedCrypto.value.unit,
            address: address
        }, true, true, 3)
        dataRefArray.value.unshift({
            path: path,
            address: address,
            publicKey: getPublicKey(path, selectedCrypto),
            privateKey: getPrivateKey(bip32ExtendedKey, selectedCrypto),
            addressData: addressData
        })
        await delay(0)

    }

    numberOfAddressesYetToGenerate.value = 0
}

const bchAddressFormat = ref('Cash')
export default () => {

    return { queueAddressesToGenerate, byteArrayToHex, bchAddressFormat }
}