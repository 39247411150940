var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-4", attrs: { fluid: "" } },
        [
          _c(_setup.ViewHeader, {
            staticClass: "mb-2",
            attrs: { text: "User Profile", "no-dividers": "" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column", staticStyle: { gap: "12px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [_c(_setup.UserInfoCard, { attrs: { user: _setup.user } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _setup.hasCasePermission
                ? _c(_setup.UserCasesTable, {
                    attrs: { userId: _setup.user.id },
                  })
                : _vm._e(),
              _vm._v(" "),
              _setup.hasGraphPermission
                ? _c(_setup.UserGraphsTable, {
                    attrs: { userId: _setup.user.id },
                  })
                : _vm._e(),
              _vm._v(" "),
              _setup.hasLogsPermission ? _c(_setup.UserLogsTable) : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }