import apiClient from "@/services/apiClient";
import Vue from "vue";
import axios from "axios";
import {reset} from "ol/transform";

const state = {
    dashboardData: {},
    dashboardLoading: {},

    currencyDashboardLoading: false,
    selectedCurrencyDashboard: null
};

const getters = {
    dashboardData: (state) => state.dashboardData,
    currencyDashboardData: (state) => state.selectedCurrencyDashboard,
    dashboardLoading: (state) => {
        return state.dashboardLoading
    },
    currencyDashboardLoading: (state) => state.currencyDashboardLoading,
};

const mutations = {
    DASHBOARD_LOADED(state, data) {
        state.dashboardData = data;
        state.dashboardLoading = false;
    },
    DASHBOARD_START_LOADING(state, data) {
        state.dashboardLoading = true;
    },
    CURR_DASHBOARD_START_LOADING(state, data) {
        state.currencyDashboardLoading = true;
    },
    CURRENCY_DASHBOARD_LOADED(state, data) {
        state.selectedCurrencyDashboard = data;
        state.currencyDashboardLoading = false;
    },
    CURRENCY_DASHBOARD_LOADING_FINISHED(state) {
        state.currencyDashboardLoading = false;
    },
    DASHBOARD_CURRENCY_START_LOADING(state, data) {
        Vue.set(state.dashboardData, data.currency, { unit: data.currency })
        Vue.set(state.dashboardLoading, data.currency, true)
    },
    DASHBOARD_CURRENCY_LOADED(state,data) {
        Vue.set(state.dashboardData, data.currency, {...data.response, unit: data.currency})
        Vue.delete(state.dashboardLoading, data.currency)
    }
};

const actions = {
    async loadDashboardSingle({ commit }, { currency })
    {
        commit("DASHBOARD_CURRENCY_START_LOADING", {currency: currency} )
        try {
            const response = await apiClient.GET(`${currency}/dashboard`)
            commit("DASHBOARD_CURRENCY_LOADED", {currency: currency, response: response})
        } catch (e) {
            commit("DASHBOARD_CURRENCY_LOADED", {currency: currency, response: {error: true, message: e.message}})
        }
    },
    /*async loadDashboard({ commit }) {
        commit('DASHBOARD_START_LOADING');
        const response = await apiClient.GET("dashboard")
        commit('DASHBOARD_LOADED', response);
    },*/
    async loadCurrencyDashboard({ commit, dispatch }, currency) {
        commit('CURR_DASHBOARD_START_LOADING');
        if (currency === undefined) return
        try {
            const response = await apiClient.GET(`${currency}/dashboard`)
            commit('CURRENCY_DASHBOARD_LOADED', response);
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        commit("CURRENCY_DASHBOARD_LOADING_FINISHED")
    }
};

export default {
    state,
    mutations,
    actions,
    getters
}
