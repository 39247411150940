<script setup>

import {computed} from 'vue'
import store from '@/store'


const props = defineProps({
    filterOptions: {
        type: Array,
        required: true
    },
    value: {
        type: Array,
        required: true
    }
})
const emit = defineEmits(['input'])

const currency = computed(() => store.getters.currency)
const txs = computed(() => store.getters.transactions)

</script>

<template>
    <v-menu
        :close-on-content-click="false"
        offset-x>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                small
                v-on="on"
                v-bind="attrs"
                dark
                color="tertiary">
                <v-icon left>
                    mdi-filter-plus
                </v-icon>
                add filter
            </v-btn>
        </template>
        <v-list
            subheader>
            <v-subheader>Filters</v-subheader>
            <v-list-item-group
                @change="emit('input', $event)"
                :value="props.value"
                multiple>
                <v-list-item v-for="filterOption in props.filterOptions"
                             :key="filterOption.name"
                             :value="filterOption">
                    <template v-slot:default="{ active }">
                        <v-list-item-action class="mr-3">
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title v-text="filterOption.label"/>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<style scoped>

</style>