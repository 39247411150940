var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100" },
    [
      _c("div", { staticClass: "d-flex justify-space-between align-center" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("crypto-interceptor-new-modal", {
              attrs: { currency: _vm.currency },
              on: { "monitor-new-node-event": _vm.startMonitoringPeer },
              scopedSlots: _vm._u([
                {
                  key: "showbutton",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "CreateButton",
                        _vm._g(
                          { attrs: { text: "Add new monitored node" } },
                          on
                        )
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "my-2" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("h1", [_vm._v("Monitoring")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }