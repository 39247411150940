<script setup>
import BaseModalDialog from "@/components/common/Base/BaseModalDialog.vue";
import {ref} from "vue";

const props = defineProps({
    onConfirmActionCallback: {
        type: Function,
        required: true
    },
    contentText: {
        type: String,
        required: true
    },
    modalTitle: {
        type: String,
        required: false,
        default: "Are you sure?"
    }
})

const handleConfirm = () => {
    props.onConfirmActionCallback()
    close()
}

const modalRef = ref(null)
const close = () => modalRef.value.close()
const open = () => modalRef.value.open()

defineExpose({open})
</script>

<template>
    <BaseModalDialog ref="modalRef" :title="modalTitle" width="20%" :persistent="false">
        <template #footer="{close}">
            <v-btn small @click="close">Cancel</v-btn>
            <v-spacer/>
            <v-btn color="success" small @click="handleConfirm">Confirm</v-btn>
        </template>
        <template #content>
            {{ contentText }}
        </template>
    </BaseModalDialog>
</template>
