<script setup>
import {ref} from "vue";
import {deepClone} from "@/utils";
import BaseModalDialog from "@/components/common/Base/BaseModalDialog.vue";

const props = defineProps({
    graphDetail: {
        type: Object
    }
})

const baseModal = ref()
const openModal = () => {
    baseModal.value.open()
}

const detail = ref(undefined)
const isEditModal = ref(true)

const loadState = () => {
    if (props.graphDetail) {
        isEditModal.value = true
        detail.value = deepClone(props.graphDetail)
    }
    else {
        isEditModal.value = false
        detail.value = {
            description: "",
            is_hidden: false
        }
    }
}

const emit = defineEmits(['save-graph-config-event'])
const validator = ref()

const saveConfiguration = async (close) => {
    const isValid = await validator.value.validate();
    if (isValid) {
        emit("save-graph-config-event", { graphConfigurationDetail: detail.value })
        close()
    }
}

defineExpose({ openModal })

</script>

<template>
    <BaseModalDialog
        ref="baseModal"
        :title="isEditModal ?  'Edit graph configuration' :'Create new graph configuration'"
        @load-modal-state-event="loadState"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-text-field-validateable
                    rules="max:255"
                    label="Description"
                    v-model="detail.description"
                    outlined
                />
            </ValidationObserver>
        </template>
        <template #footer="{close}">
            <v-btn color="error" text @click="close">Cancel</v-btn>
            <v-spacer />
            <v-btn
                color="success"
                text
                @click="saveConfiguration(close)"
            >{{isEditModal ? "Save configuration" : "Create configuration" }}</v-btn>
        </template>
    </BaseModalDialog>
</template>