<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-container fluid>
        <v-row align="start" justify="center">
            <v-col cols="12" md="2">
                <h3>Date</h3>
                <v-divider/>
                <DateTimePicker label="Select from" v-model="filterDateFrom"/>
                <DateTimePicker label="Select to" v-model="filterDateTo"/>
            </v-col>

            <v-col cols="12" md="2">
                <v-tooltip :top="disableValueFilter">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <h3 :style="disableValueFilter && { opacity: 0.3 }">Value</h3>
                            <v-divider></v-divider>
                            <v-text-field
                                :disabled="disableValueFilter"
                                label="Min value"
                                single-line
                                :suffix="currency.unit"
                                v-model="filterMinValue"
                                type="number"
                            ></v-text-field>
                            <v-text-field
                                :disabled="disableValueFilter"
                                label="Max value"
                                single-line
                                :suffix="currency.unit"
                                v-model="filterMaxValue"
                                type="number"
                            ></v-text-field>
                        </div>
                    </template>
                    Filter is disabled, because address<br/> hasn't been indexed yet.
                </v-tooltip>
            </v-col>
            <v-col cols="12" md="2">
                <v-tooltip :top="disableFeeFilter">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <h3 :style="disableFeeFilter && { opacity: 0.3 }">Fee Value</h3>
                            <v-divider></v-divider>
                            <v-text-field
                                :disabled="disableFeeFilter"
                                label="Min fee value"
                                single-line
                                :suffix="currency.unit"
                                v-model="filterMinFeeValue"
                                type="number"
                            ></v-text-field>
                            <v-text-field
                                :disabled="disableFeeFilter"
                                label="Max fee value"
                                single-line
                                :suffix="currency.unit"
                                v-model="filterMaxFeeValue"
                                type="number"
                            ></v-text-field>
                        </div>
                    </template>
                    Filter is disabled, because address<br/> hasn't been indexed yet.
                </v-tooltip>

            </v-col>
            <v-col cols="12" md="1" style="min-width: 140px">
                <h3>Direction</h3>
                <v-divider class="pb-2"/>
                <v-select

                    class="pt-2"
                    :items="directionOptions"
                    v-model="direction.value"
                    label="Direction"
                    outlined
                >
                    <template #item=" { item }">
                        <div class="d-flex justify-center align-center width100">
                            <span v-for="(icon, index) in item.icons"
                              :key="index">
                            <span v-if="index === 1" style="color: rgba(0,0,0,0.6)">
                                +
                            </span>
                            <v-icon>
                                {{ icon }}
                            </v-icon>
                        </span>
                        </div>
                    </template>
                    <template #selection="{ item }">
                        <div class="d-flex justify-center align-center width100">
                            <span v-for="(icon, index) in item.icons"
                                  :key="index + 3">
                            <span v-if="index === 1" style="color: rgba(0,0,0,0.6)">
                                +
                            </span>
                            <v-icon>
                                {{ icon }}
                            </v-icon>
                            </span>
                        </div>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-tooltip :top="disableCategoriesFilter">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <h3 :style="disableCategoriesFilter && { opacity: 0.3 }">Categories</h3>
                            <v-divider class="pb-4"/>
                            <category-picker :disabled="disableCategoriesFilter" :categories="categories" v-model="filterCategories"/>
                        </div>
                    </template>
                    Filter is disabled, because address<br/> hasn't been indexed yet.
                </v-tooltip>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center width100 align-self-center">
                <v-btn
                    height="32px"
                    outlined
                    class="text-left"
                    :color="currency.unit"
                    @click="onFilter(false)"
                >
                    Search
                    <v-icon class="ml-2" small>fa fa-magnifying-glass</v-icon>
                </v-btn>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            style="margin-top: -3px"
                            class="ml-2"
                            icon
                            :color="currency.unit"
                            v-on="on"
                            v-bind="attrs"
                            @click="onFilter(true)"
                        >
                            <v-icon size="28px">mdi-backup-restore</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Filter</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import TransactionSingle from '@/components/transaction/TransactionSingle'
import DateTimePicker from '@/components/common/DateTimePicker'
import {ExportButtons, PaginationWrapper, ProgressCircular} from '@/components/common'
import TransactionsCountry from "@/components/transaction/TransactionsCountry";
import {mapGetters} from "vuex";
import ChartMap from "@/components/chart/ChartMap";
import ExportMenu from "@/components/common/Buttons/ExportMenu";
import {humanTime} from "@/utils";
import TransactionsControlPanel from "@/components/transaction/Components/TransactionsControlPanel";
import CategoryPicker from "@/components/common/CategoryPicker";

export default {
    name: 'TransactionsFilter',
    components: {
        CategoryPicker,
        TransactionsControlPanel,
        ExportMenu,
        ChartMap,
        TransactionsCountry,
        TransactionSingle,
        PaginationWrapper,
        ExportButtons,
        ProgressCircular,
        DateTimePicker
    },
    props: {
        addressId: String,
        value: {
            type: Object,
        },
        disableValueFilter: {
            default: false,
            type: Boolean,
            required: false
        },
        disableFeeFilter: {
            default: false,
            type: Boolean,
            required: false
        },
        disableCategoriesFilter: {
            default: false,
            type: Boolean,
            required: false
        }
    },
    watch: {
        transactionsFilter(newValue, oldValue) {
            this.filterDateFrom = newValue.minTime
            this.filterDateTo = newValue.maxTime
            this.filterMinValue = newValue.minValue / 100000000 || ''
            this.filterMaxValue = newValue.maxValue / 100000000 || ''
            this.filterMinFeeValue = newValue.minFee / 100000000 || ''
            this.filterMaxFeeValue = newValue.maxFee / 100000000 || ''
        },
    },
    methods: {
        onFilter(reset) {
            if (reset) {
                this.setDefaultFilter()
                this.$emit("input", {
                    minTime: undefined,
                    maxTime: undefined,
                    minValue: undefined,
                    maxValue: undefined,
                    minFee: undefined,
                    maxFee: undefined,
                    direction: undefined,
                    filterCategories: undefined
                })
            } else {
                this.$emit("input", {
                    minTime: this.filterDateFrom,
                    maxTime: this.filterDateTo,
                    minValue: this.filterMinValue === '' ? undefined : this.filterMinValue,
                    maxValue: this.filterMaxValue === '' ? undefined : this.filterMaxValue,
                    minFee: this.filterMinFeeValue === '' ? undefined : this.filterMinFeeValue,
                    maxFee: this.filterMaxFeeValue === '' ? undefined : this.filterMaxFeeValue,
                    direction: (this.direction?.value === '' || this.direction.value === 'BOTH') ? undefined : this.direction.value,
                    filterCategories: this.filterCategories.length <= 0 ? undefined : this.filterCategories
                })
                this.$emit("on-filter-event")
            }
        },
        setDefaultFilter() {
                this.filterDatepickerFrom = false
                this.filterDateFrom = undefined
                this.filterDatepickerTo = false
                this.filterDateTo = undefined
                this.filterMinValue = ''
                this.filterMaxValue = ''
                this.filterMinFeeValue = ''
                this.filterMaxFeeValue = ''
                this.filterCategories = []
                this.direction = {
                    icons: ['mdi-import', 'mdi-export'],
                    value: 'BOTH'
                }
        }
    },
    computed: {
        ...mapGetters(["currency", "categories"]),

    },
    data() {
        return {
            filterDatepickerFrom: false,
            filterDateFrom: undefined,
            filterDatepickerTo: false,
            filterDateTo: undefined,
            filterMinValue: '',
            filterMaxValue: '',
            filterMinFeeValue: '',
            filterMaxFeeValue: '',
            filterCategories: [],
            direction: {
                icons: ['mdi-import', 'mdi-export'],
                value: 'BOTH'
            },
            directionOptions:
                [
                    {
                        icons: ['mdi-export'],
                        value: 'IN'
                    },
                    {
                        icons: ['mdi-import'],
                        value: 'OUT'
                    },
                    {
                        icons: ['mdi-import', 'mdi-export'],
                        value: 'BOTH'
                    }
                ],
        }
    }
}
</script>

<style scoped>
.filterBadge {
    z-index: 2;
}

</style>

<style>
.v-input__control .v-text-field__suffix {
    color: rgba(0, 0, 0, 0.58)
}
</style>