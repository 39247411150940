<template>
  <div class="cont" ref="bottomDrawer">
    <div class="handler" @mousedown="mouseDown"></div>
    <router-view></router-view>
  </div>
</template>

<script>
//todo FE why are here these events? 
  export default {
    name: 'ResizablePanel',
    mounted () {
      document.addEventListener('mouseup', this.mouseUp)
    },
    destroyed () {
      document.removeEventListener('mouseup', this.resize)
    },
    methods: {
      mouseUp (e) {
            //console.log('debug: fire mouseUp');

        document.removeEventListener('mousemove', this.resize)
      },
      mouseDown (e) {
        if (e.offsetY < 4) {
            //console.log('debug: fire mouseDown');
            
          this.mousePosition = e.Y
          document.addEventListener('mousemove', this.resize)
        }
      },
      resize (e) {
            //console.log('debug: resize');

        var height = parseInt(getComputedStyle(this.$refs.bottomDrawer, '').height)
        const dx = this.mousePosition - e.y
        this.mousePosition = e.y
        this.$refs.bottomDrawer.style.height = (height + dx) + 'px'
      }
    },
    data () {
      return {
        mousePosition: null
      }
    }
  }
</script>

<style scoped>
  .cont {
    background: white;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 45vh;
    max-height: 75vh;
    min-height: 200px;
    overflow-y: auto;
  }

  .handler {
    position: sticky;
    top: 0;
    z-index: 3;
    width: 100%;
    padding: 0;
    height: 6px;
    cursor: ns-resize;
    border-top: dashed 2px #9c9898;
    margin-bottom: -5px;
  }

</style>
