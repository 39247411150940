var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "d-flex justify-center clusterViewCards",
        staticStyle: { "background-color": "#fff", height: "100%" },
      },
      [
        _c(
          "div",
          { staticClass: "px-4", staticStyle: { width: "100%" } },
          [
            _c(
              "v-row",
              { staticClass: "pt-4", attrs: { dense: "" } },
              [
                _c(
                  "v-col",
                  { attrs: { align: "right", cols: "12" } },
                  [
                    _c(
                      _setup.DataProvider,
                      {
                        attrs: {
                          data: {
                            cluster: _setup.clusterInfo?.cluster,
                            currencyUnit: _setup.currency.unit,
                            clusterName: _setup.clusterName,
                          },
                          dataState: _setup.clusterInfoState,
                          "ignore-loading": "",
                        },
                      },
                      [
                        _c(_setup.ClusterControls, {
                          on: { "action-finished-event": _setup.refresh },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4", sm: "6" } },
                  [
                    _c(
                      _setup.DataProvider,
                      {
                        attrs: {
                          data: _setup.clusterInfo,
                          dataState: _setup.clusterInfoState,
                          "ignore-loading": "",
                        },
                      },
                      [_c(_setup.ClusterSummaryCardBasic)],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4", "order-sm": "2", sm: "6" } },
                  [_c(_setup.ClusterSummaryCardDetails)],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4", "order-sm": "4", sm: "6" } },
                  [
                    _c(
                      _setup.DataProvider,
                      {
                        attrs: {
                          data: _setup.clusterInfo,
                          dataState: _setup.clusterInfoState,
                          "ignore-loading": "",
                        },
                      },
                      [_c(_setup.ClusterSummaryCardOwners)],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { cols: "12", "order-sm": "5" } },
                  [_c(_setup.ClusterSummaryCardAddresses, { key: _setup.key })],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }