<template>
    <span>
        <span v-if="!loading"
              class="textGrey">
            <slot name="default"/>
        </span>
        <span v-else-if="loading">
            <v-progress-circular
                indeterminate
                :size="24"
                color="primary"/>
        </span>
    </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "TitleHelper",
    computed: {
        ...mapGetters(["currency"])
    },
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style scoped>

</style>