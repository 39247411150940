var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          "footer-props": { "items-per-page-options": [5, 10, 20] },
          headers: _setup.headers,
          height: 32 * _setup.pagination.itemsPerPage + 52,
          items: _setup.items,
          "server-items-length": _setup.tableDataTotalItems,
          options: _setup.pagination,
          loading: _vm.loading,
          dense: "",
          "no-data-text": "No imported rows",
        },
        on: {
          "update:options": function ($event) {
            _setup.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item, index }) {
              return [
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        staticClass: "fontMonospace",
                        attrs: {
                          placeholder: _setup.defaultCurrency?.unit,
                          "hide-details": "",
                          dense: "",
                        },
                        model: {
                          value: item.crypto,
                          callback: function ($$v) {
                            _vm.$set(item, "crypto", $$v)
                          },
                          expression: "item.crypto",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fontMonospace" },
                    [
                      _c("v-text-field", {
                        staticClass: "fontMonospace",
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          placeholder: "address",
                        },
                        model: {
                          value: item.address,
                          callback: function ($$v) {
                            _vm.$set(item, "address", $$v)
                          },
                          expression: "item.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(_setup.AddressImporterMetadataMenu, {
                        attrs: {
                          headers: _setup.identitiesHeaders,
                          items: item.identities,
                          icon: "mdi-card-account-details-outline",
                          "text-multiple": "Identities",
                          "text-single": "Identity",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(_setup.AddressImporterMetadataMenu, {
                        attrs: {
                          headers: _setup.categoriesHeaders,
                          items: item.categories,
                          icon: "mdi-label",
                          "text-multiple": "Categories",
                          "text-single": "Category",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(_setup.AddressImporterMetadataMenu, {
                        attrs: {
                          headers: _setup.ownersHeaders,
                          items: item.owners,
                          icon: "mdi-account",
                          "text-multiple": "Owners",
                          "text-single": "Owner",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error", icon: "", "x-small": "" },
                          on: {
                            click: function ($event) {
                              return _setup.removeItemAtIndex(index)
                            },
                          },
                        },
                        [
                          _c("v-icon", [
                            _vm._v(
                              "\n                            mdi-delete\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "footer.page-text",
            fn: function ({ pageStart, pageStop, itemsLength }) {
              return [
                _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("formatNumber")(pageStart)) +
                      " - " +
                      _vm._s(_vm._f("formatNumber")(pageStop)) +
                      " of " +
                      _vm._s(_vm._f("formatNumber")(itemsLength)) +
                      "\n            "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }