<script setup>

import {computed, onMounted, ref} from 'vue'
import FilterAddMenu from "@/components/Filters/FilterAddMenu.vue";
import store from '@/store'
import {useRoute, useRouter} from "vue-router/composables";
import useFiltersSelectable from "@/components/Filters/Composables/useFiltersSelectable";
import PaginationWrapper from "@/components/common/PaginationWrapper.vue";
import TransactionsHeader from "@/components/transaction/Components/TransactionsHeader.vue";
import ProgressCircular from "@/components/common/ProgressCircular.vue";
import TransactionSingle from "@/components/transaction/TransactionSingle.vue";
import ModalFilterError from "@/components/Filters/ModalFilterError.vue";
import useFiltersActiveValidation from "@/components/Filters/Composables/useFiltersActiveValidation";
import FilterTextField from "@/components/Filters/FilterTextField.vue";
import useRouteQuery from "@/components/Composables/Route/useRouteQuery";
import SearchButton from "@/components/common/Buttons/SearchButton.vue";

//STORE
const currency = computed(() => store.getters.currency)

//ROUTER
const router = useRouter()
const route = useRoute()

//COMPOSABLE
const {selectedFilters, selectedFiltersValues, filtersOptions, pushToQuery} = useFiltersSelectable(route, router)
const {validateActiveFilters} = useFiltersActiveValidation()

const page = useRouteQuery('page', () => 1, Number)
const itemsPerPage = useRouteQuery('itemsPerPage', () => 20, Number)

const searchHandler = () => {
    if (!validateActiveFilters(selectedFiltersValues)) {
        errorModal.value.openModal()
        return
    }
    store.dispatch('initTransactions')
    pushToQuery(selectedFiltersValues.value)
    getTxs()
}

const onSearchClick = () => {
    page.value = 1
    searchHandler()
}

const getTxs = () => {
    store.dispatch('loadTransactions', {
        currency: currency.value.unit,
        filter: selectedFiltersValues.value,
        pagination: {page: page.value, itemsPerPage: itemsPerPage.value}
    })
}

store.dispatch('loadCategories')

onMounted(() => {
    if (selectedFilters.value.length !== 0) {
        searchHandler()
    }
})

//TODO REFACTOR (MOVE THIS TO A SEPARATE COMPONENT)
const expanded = ref(false)
const totalPages = computed(() => store.getters.transactionsPagination?.last_page)
const totalTxs = computed(() => store.getters.transactionsPagination?.total)
const transactions = computed(() => store.getters.transactions)
const loading = computed(() => store.getters.transactionsLoading)

const handleManualPaginationChange = (e) => {
    page.value = e
    getTxs()
}

const errorModal = ref(null);

const removeFilter = (filterLabel) => {
    selectedFilters.value = selectedFilters.value.filter(filter => filter.label !== filterLabel)
}

</script>

<template>
    <div class="height100">
        <ModalFilterError ref="errorModal"/>
        <v-row dense>
            <v-col cols="auto">
                <FilterAddMenu
                    v-model="selectedFilters"
                    :filter-options="filtersOptions"/>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col
                v-for="filter in selectedFilters"
                :key="filter.name"
                cols="auto">
                <FilterTextField
                    :componentFilterType="filter.component"
                    v-model="selectedFiltersValues[filter.name]"
                    :filterSubType="filter.filterSubType"
                    :name="filter.name"
                    :time="filter.time"
                    :append="filter.append"
                    @on-remove-filter-event="(filterLabel) => removeFilter(filterLabel)"
                    :label="filter.label"/>
            </v-col>
        </v-row>
        <v-row align-content="center" justify="center">
            <v-col cols="auto" align-self="center">
                <SearchButton @click="onSearchClick"/>
            </v-col>
        </v-row>
        <v-divider class="mt-4"/>
        <div class="mt-3">
            <div class="d-flex">
                <div style="min-width: 140px">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" small :depressed="false" outlined class="mr-5"
                                   @click="expanded = !expanded">
                                {{ expanded ? "Collapse" : "Expand" }}
                                <v-icon right>
                                    {{ expanded ? "mdi-arrow-collapse-vertical" : "mdi-arrow-expand-vertical" }}
                                </v-icon>
                            </v-btn>
                        </template>
                        {{ expanded ? "Collapse" : "Expand" }} all transactions
                    </v-tooltip>
                </div>
                <v-spacer/>
                <div v-if="totalTxs">
                    <span class="NumberOfTx">{{ totalTxs | formatNumber }}</span>
                    <span class="subtitle-1" style="color: rgba(0, 0, 0, 0.6) !important">{{
                            totalPages === 1 ? 'RESULT' : 'RESULTS'
                        }}</span>
                </div>
                <v-spacer/>
                <div style="min-width: 140px">
                </div>
            </div>
            <div>

            </div>
            <PaginationWrapper
                v-if="loading || transactions.length > 0"
                :loading="loading"
                :pages-total="totalPages ? totalPages : 0"
                @input="handleManualPaginationChange"
                :value="page"
            >
                <template v-slot:content>
                    <div v-if="loading">
                        <ProgressCircular
                            :color="currency.unit"
                        />
                    </div>
                    <TransactionsHeader no-change/>
                    <v-divider/>
                    <TransactionSingle
                        class="mt-1 mb-1"
                        v-for="transaction in transactions"
                        :transaction="transaction"
                        :key="transaction.txhash"
                        :expanded="expanded"
                    />
                </template>
            </PaginationWrapper>
            <div v-else class="title mt-4 font-weight-light text-center">
                No transactions matched the selected criteria.
            </div>
        </div>
    </div>
</template>

<style>
.NumberOfTx {
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
    color: rgba(0, 0, 0, 0.6) !important
}
</style>