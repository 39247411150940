var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-3 white" },
    [
      !_vm.isLoading
        ? _c("Chart", { attrs: { options: _vm.chartOptions } })
        : _c("ProgressCircular", {
            staticClass: "py-5",
            attrs: { color: _vm.currency.unit },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }