var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select-validateable",
    _vm._b(
      {
        ref: "select",
        attrs: {
          disabled: _vm.disabled,
          label: "Blockchain",
          items: _vm.currencies,
          value: _vm.value,
          "item-text": "name",
          "item-value": "unit",
          outlined: _vm.outlined,
        },
        on: { input: _vm.change },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (data) {
              return [
                _c(
                  "v-flex",
                  { attrs: { xs2: "" } },
                  [
                    _c("v-avatar", { attrs: { size: "25px" } }, [
                      _c("img", { attrs: { src: data.item.image } }),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-flex", { staticClass: "ml-1" }, [
                  _vm._v(_vm._s(data.item.name)),
                ]),
              ]
            },
          },
          {
            key: "item",
            fn: function (data) {
              return [
                _c("v-list-item-avatar", { attrs: { size: "30" } }, [
                  _c("img", {
                    staticClass: "my-1",
                    attrs: { src: data.item.image },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", {
                      domProps: { innerHTML: _vm._s(data.item.name) },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      },
      "v-select-validateable",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }