import apiClient from "@/services/apiClient";
import router from "../../router";
import mappers from "models/AddressMetadataModels";
import Vue from "vue";
import axios from "axios";

const state = {
    addressInfo: null,
    addressLoading: true,
    addressId: "",
    addressBalance: [],
    addressTimelineFiats: [],
    addressBalanceLoading: false,
    addressGotError: false,
    addressError: null,
    addressCancelToken: null,
    addressVolume: [],
    addressVolumeLoading: false,
    timelinesCancelToken: null
};

const getters = {
    addressInfo: (state) => state.addressInfo,
    existsInGraphDb: (state) => state.addressInfo?.existsInGraphDb,
    emptyAddress: (state) => state.addressId === "",
    addressLoading: (state) => state.addressLoading,
    addressId: (state) => state.addressId,
    addressBalance: (state) => state.addressBalance,
    addressBalanceLoading: (state) => state.addressBalanceLoading,
    addressTimelineFiats: (state) => state.addressTimelineFiats,
    addressVolumeLoading: (state) => state.addressVolumeLoading,
    addressVolume: (state) => state.addressVolume,
    addressGotError: (state) => state.addressGotError,
    //todo FE redesing addressError
    addressError: (state) => {
        if (state.addressError === null) {
            return;
        }
        if (
            state.addressError !== undefined &&
            (typeof state.addressError.data === "string" ||
                state.addressError.data instanceof String)
        ) {
            return state.addressError;
        }
    },
};

const mutations = {
    ADDRESS_LOADED(state, data) {
        // data['confirmedBalance'] = Humanize.formatNumber(data['confirmedBalance'], 8) + ' ' + router.currentRoute.params.currency;
        // data['totalReceived'] = Humanize.formatNumber(data['totalReceived'], 8) + ' ' + router.currentRoute.params.currency;
        // data['totalSent'] = Humanize.formatNumber(data['totalSent'], 8) + ' ' + router.currentRoute.params.currency;
        state.addressInfo = data;
        state.addressLoading = false;
    },
    ADDRESS_INIT(state, id) {
        state.addressInfo = null;
        state.addressBalance = [];
        state.addressVolume = [];
        state.addressLoading = true;
        state.addressId = id;
        state.addressCluster = null;
        state.addressClusterLoading = false;
        state.addressClusters = null;
        state.addressClustersLoading = true;
        state.addressVolumeLoading = true;
        state.addressTxsOverallDays = [];
        state.addressTxsOverallHours = [];
        state.addressTxsOverallMonths = [];
        state.addressTxsOverallYears = [];
        state.addressTxsOverallYearsReceiving = [];
        state.addressTxsOverallYearsSending = [];
        state.addressTxsOverallLoading = true;
        state.clusterCount = null;
        state.clusterCounting = false;
        if (state.addressCancelToken) {
            state.addressCancelToken.cancel()
        }
        state.addressCancelToken = axios.CancelToken.source()
    },
    ADDRESS_START_LOADING(state) {
        state.addressGotError = false;
        state.addressLoading = true;
    },
    ADDRESS_ERROR(state, error) {
        state.addressGotError = true;
        state.addressError = error;
        state.addressLoading = false;
    },
    ADDRESS_BALANCE_LOADED(state, data) {
        state.addressBalance = data.cur;
        state.addressVolume = data.vol;
        state.addressBalanceLoading = false;
        state.addressTimelineFiats = data.fiats
    },
    ADDRESS_BALANCE_LOADING(state) {
        if(state.timelinesCancelToken) {
            state.timelinesCancelToken.cancel()
        }
        state.timelinesCancelToken = axios.CancelToken.source()
        state.addressBalance = [];
        state.addressBalanceLoading = true;
    },
    ADDRESS_BALANCE_LOADING_FINISHED(state) {
        state.addressBalanceLoading = false
    },
    ADDRESS_VOLUME_LOADED(state, data) {
        state.addressVolume = data;
        state.addressVolumeLoading = false;
    },
    ADDRESS_VOLUME_LOADING(state) {
        state.addressVolume = [];
        state.addressVolumeLoading = true;
    },
    ADDRESS_VOLUME_FINISHED_LOADING(state) {
      state.addressVolumeLoading = false;
    },
    ADDRESS_SET_PRIMARY_OWNER_BY_ID(state,data) {
        let newOwner = state.addressInfo.owners.find(owner => {
            return owner.id === data.id
        })

        Vue.set(state.addressInfo,"owner",{...newOwner})
    },
    ADDRESS_SET_PRIMARY_IDENTITY_BY_ID(state,data) {
        let newIdentity = state.addressInfo.identities.find(identity => {
            return identity.id === data.id
        })

        Vue.set(state.addressInfo,"identity",{...newIdentity})
        Vue.set(state.addressInfo,"label",newIdentity.label)
    },
};

const actions = {
    async getAddressBudget({}, { currency, address }) {
        try {
            return await apiClient.GET(`${currency}/cryptoaddress/${address}/budget`)
        } catch (e) {
            throw e
        }
    },
    async loadAdress({ commit }, { id }) {
        commit("ADDRESS_INIT", id);
        commit("ADDRESS_CLUSTER_STATE_RESET")
        commit("ADDRESS_FUNDS_STATE_RESET")
        commit("ADDRESS_INTERACTIONS_STATE_RESET")

        //workaround in case user sends one request twice
        //- waiting for first request to finish, otherwise the canceled "response"
        //gets cached and returned for the second request
        await new Promise(r => setTimeout(r, 0));

        if (this.getters.currency && id !== "") {
            let crypto = router.currentRoute.params.currency
            commit("ADDRESS_START_LOADING");
            try {
                let response = await apiClient.GET(
                    router.currentRoute.params.currency + `/cryptoaddress/${id}/summary`, {
                        cancelToken: state.addressCancelToken.token
                    }
                );
                response = mappers.MapToViewModel(response.data)
                commit("ADDRESS_LOADED", {...response, crypto: crypto});
                commit("ALARMS_PAGE_LOADED", {data: response.alarms} )
            } catch (error) {
                if (axios.isCancel(error)) {
                    //request is canceled, do nothing
                }
                else
                {
                    this.dispatch("error", error.userFriendlyMessage)
                    commit("ADDRESS_ERROR", error.response);
                }
            }
        }
    },
    async loadAddressVolume({ commit, dispatch }) {
        commit("ADDRESS_VOLUME_LOADING");
        try {
            const response = await apiClient.GET(
                router.currentRoute.params.currency +
                    `/addr/` +
                    this.getters.addressId +
                    `/graph/volume`,
                {
                    withCredentials: true,
                }
            );
            commit("ADDRESS_VOLUME_LOADED", response.data);
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        commit("ADDRESS_VOLUME_FINISHED_LOADING")
    },
    async loadAddressBalance({ commit, dispatch }, filter) {
        commit("ADDRESS_BALANCE_LOADING");
        try {
            const response = await apiClient.GET(
                router.currentRoute.params.currency +
                    `/cryptoaddress/` +
                    this.getters.addressId +
                    `/timelines`,
                {
                    params: { ...filter },
                    cancelToken: state.timelinesCancelToken.token
                }
                );
            commit("ADDRESS_BALANCE_LOADED", response);
            commit("ADDRESS_BALANCE_LOADING_FINISHED")
        } catch (error) {
            if(axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch("error", error.userFriendlyMessage)
                commit("ADDRESS_BALANCE_LOADING_FINISHED")
            }
        }
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
