var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("syncedTable", {
        attrs: {
          data: _vm.data,
          loading: _vm.loading,
          loadingText: "Loading addresses ...",
          "footer-props": {
            "items-per-page-options": [20, 50, 200],
            "items-per-page-text": "Addresses per page",
            "show-current-page": true,
            showFirstLastPage: true,
          },
          headers: _vm.headers,
          "total-items": _vm.clusterTotalAddresses,
        },
        on: { "fetch-event": _vm.onFetchEvent },
        scopedSlots: _vm._u([
          {
            key: "tableBody",
            fn: function ({ props }) {
              return [
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c("EntityLabel", {
                        attrs: {
                          entity: props.item.address,
                          identity: props.item.identity,
                          owner: props.item.owner,
                          isCopyable: true,
                          showExtra: true,
                          actAsLink: true,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-center",
                        staticStyle: {
                          "min-width": "86px",
                          "max-width": "86px",
                          position: "relative",
                        },
                      },
                      [
                        _vm._l(
                          props.item.categories.length > 3
                            ? props.item.categories.slice(0, 2)
                            : props.item.categories,
                          function (cat) {
                            return _c("AddressCategoryIcon", {
                              key: cat.id,
                              staticClass: "ml-1",
                              attrs: {
                                "icon-size": "22px",
                                iconItem: cat,
                                name: cat.name,
                                color: cat.color,
                              },
                            })
                          }
                        ),
                        _vm._v(" "),
                        props.item.categories.length > 3
                          ? _c("v-badge", {
                              attrs: { inline: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "badge",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            staticStyle: {
                                              border:
                                                "rgba(0,0,0,0.49) solid 1px",
                                            },
                                            attrs: { top: "", color: "white" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        _vm._g(
                                                          {
                                                            staticStyle: {
                                                              "margin-top":
                                                                "2px",
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "+" +
                                                              _vm._s(
                                                                props.item
                                                                  .categories
                                                                  .length - 2
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _vm._v(" "),
                                            _vm._l(
                                              props.item.categories,
                                              function (cat) {
                                                return _c(
                                                  "AddressCategoryIcon",
                                                  {
                                                    key: cat.id,
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      medium: "",
                                                      iconItem: cat,
                                                      name: cat.name,
                                                      color: cat.color,
                                                    },
                                                  }
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right font-weight-medium" },
                    [
                      _c("fiat-convertor", {
                        attrs: { value: props.item.balance, fiats: _vm.fiats },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("fiat-convertor", {
                        attrs: { value: props.item.received, fiats: _vm.fiats },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("fiat-convertor", {
                        attrs: { value: props.item.send, fiats: _vm.fiats },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(props.item.txcount) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "RouteToTransaction",
                        { attrs: { transaction: props.item.firsttxid } },
                        [
                          _c(
                            "template",
                            { slot: "text" },
                            [
                              _c("nobr", [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.relativeTimestamps,
                                        expression: "relativeTimestamps",
                                      },
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(props.item.firstblocktimeRel) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.relativeTimestamps,
                                        expression: "!relativeTimestamps",
                                      },
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            props.item.firstblocktime,
                                            "YYYY-MM-DD HH:mm:ss Z"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "RouteToTransaction",
                        { attrs: { transaction: props.item.lasttxid } },
                        [
                          _c(
                            "template",
                            { slot: "text" },
                            [
                              _c("nobr", [
                                _vm.relativeTimestamps
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(props.item.lastblocktimeRel) +
                                          "\n                                "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              props.item.lastblocktime,
                                              "YYYY-MM-DD HH:mm:ss Z"
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }