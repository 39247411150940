<template>
    <div>
        <v-menu
            bottom
            offset-y
            :disabled="disabled"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    :depressed="false"
                >
                    Export
                    <v-icon right>
                        mdi-download
                    </v-icon>
                </v-btn>
            </template>

            <v-list dense>
                <v-subheader>Export</v-subheader>
                <v-list-item-group
                    color="primary"
                >
                    <v-list-item
                        style="border-top: solid 1px rgba(0, 0, 0, 0.1);"
                        v-for="(item, i) in exportItems"
                        :key="i"
                        :href="computedUrl(item)"
                        target="_blank"
                    >
                        <div class="d-flex">
                            <div style="width: 25px" class="d-flex align-center">
                                <img v-if="item.flag" height="15" width="25" :src="item.flag"/>
                                <img v-else width="22" :src="item.icon"/>
                            </div>
                            <div class="ml-2 text-center" style="display: inherit">
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </div>
                        </div>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ExportMenu",
    props: {
        baseUrl: {
            type: String
        },
        query: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean
        }
    },
    computed: {
        ...mapGetters(["settings"]),
    },
    methods: {
        computedUrl(item)
        {
            return `${this.settings.server.jwtPrefix}/export/${this.baseUrl}/${item.url}${this.query.length > 0 ? "?" + this.query : ""}`
        }
    },
    data() {
        return {
            exportItems: [
                {flag: "/img/ico/engAlt.svg", url: "show-htmleng", text: "Show HTML"},
                {flag: "/img/ico/cze.svg", url: "show-htmlcze", text: "Show HTML"},
                {flag: "/img/ico/br.svg", url: "show-htmlpor", text: "Show HTML"},
                {icon: "/img/ico/csv.svg", url: "show-csv", text: "Show CSV"},
                {flag: "/img/ico/engAlt.svg", url: "save-htmleng", text: "Save HTML"},
                {flag: "/img/ico/cze.svg", url: "save-htmlcze", text: "Save HTML"},
                {flag: "/img/ico/br.svg", url: "save-htmlpor", text: "Save HTML"},
                {icon: "/img/ico/csv.svg", url: "save-csv", text: "Save CSV"},

            ]
        }
    }
}
</script>

<style scoped>

</style>