var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { categoryList: _vm.dense2 } },
    [
      _c(
        "v-autocomplete",
        _vm._g(
          _vm._b(
            {
              staticClass: "categoryPicker",
              attrs: {
                items: _vm.categories,
                label: this.disabled
                  ? "Category change is not allowed"
                  : "Categories",
                disabled: this.disabled,
                "item-text": "name",
                "item-value": "id",
                clearable: "",
                multiple: "",
                outlined: "",
                chips: "",
                "small-chips": "",
                dense: _vm.dense,
                value: _vm.value,
              },
              scopedSlots: _vm._u([
                {
                  key: "selection",
                  fn: function (data) {
                    return [
                      data.index < 5 && !_vm.selectionText
                        ? _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("AddressCategoryIcon", {
                                key: data.item.id,
                                attrs: { iconItem: data.item },
                              }),
                              _vm._v(" "),
                              _vm.value.length > 5 && data.index > 3
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center mt-1 font-weight-light fontMonospace",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(`+${_vm.value.length - 5}`) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selectionText && data.index === 0
                        ? _c("span", { staticClass: "fontMonospace" }, [
                            _vm._v(_vm._s(_vm.value.length) + " selected"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "no-data",
                  fn: function () {
                    return [_c("span", [_vm._v("No Match")])]
                  },
                  proxy: true,
                },
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      typeof item !== "object"
                        ? [
                            _c("v-list-item-content", {
                              domProps: { textContent: _vm._s(item) },
                            }),
                          ]
                        : !_vm.chips
                        ? [
                            _c(
                              "v-icon",
                              { attrs: { color: item.color, left: "" } },
                              [_vm._v(_vm._s(_vm._f("getIconString")(item)))]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  domProps: { innerHTML: _vm._s(item.name) },
                                }),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c("addressCategoryIcon", {
                              attrs: { "icon-item": item, medium: "" },
                            }),
                          ],
                    ]
                  },
                },
              ]),
            },
            "v-autocomplete",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }