<template>
    <div class="height100">
        <h1>Nodes Metadata</h1>
        <v-divider/>
        <v-divider/>

        <v-data-table
            :options.sync="pagination"
            :server-items-length="peers.total"
            :headers="headers"
            :loading="peersLoading"
            :items="peers.data"
            class="elevation-0 border my-2"
            dense
            :footer-props="{
                'items-per-page-options': [15, 30, 50],
                'show-current-page': true,
                'showFirstLastPage': true,
            }"
        >
            <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'host'"
                    append-icon="search"
                    :label="header.text"
                    single-line
                    hide-details
                    @click.native.prevent.stop.capture=""
                    v-model="pagination.filterHost"
                    class="text-field-label-style"
                />
                <CountryPicker
                    v-if="h.value === 'country_code'"
                    @click.stop.native
                    :key="h.value"
                    v-model="pagination.filterCountry"
                    property-name="country_code"
                    class="mt-1 text-field-label-style"
                    style="padding: 14px 0 4px 0"
                />
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'city'"
                    append-icon="search"
                    :label="header.text"
                    single-line
                    hide-details
                    @click.native.prevent.stop.capture=""
                    v-model="pagination.filterCity"
                    class="text-field-label-style"
                />
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'asn'"
                    :label="header.text"
                    single-line
                    hide-details
                    type="number"
                    :min="0"
                    @click.native.prevent.stop.capture=""
                    v-model="pagination.filterAsn"
                    class="text-field-label-style"
                />
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'organization'"
                    append-icon="search"
                    :label="header.text"
                    single-line
                    hide-details
                    @click.native.prevent.stop.capture=""
                    v-model="pagination.filterOrganization"
                    class="text-field-label-style"
                />
                <div :key="h.value" v-if="h.value === 'seen'" class="pt-1">
                    <h3>{{ header.text }}</h3>
                </div>
                <div :key="h.value" v-if="h.value === 'cryptointerceptor' && currency.interceptorEnabled">
                    <h3>{{ header.text }}</h3>
                    <div class="d-flex justify-center">

                        <v-btn-toggle
                            v-model="pagination.filterStatuses"
                            multiple
                            dense
                        >
                            <v-btn :disabled="!hasMonitoringPermission" x-small :value="'RED'">
                                <v-icon small color="red">
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                            <v-btn :disabled="!hasMonitoringPermission" x-small :value="'ORANGE'">
                                <v-icon small color="orange">
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                            <v-btn :disabled="!hasMonitoringPermission" x-small :value="'GREEN'">
                                <v-icon small color="green">
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </div>
                <div :key="h.value" v-if="h.value === 'activities_count'" style="padding-top: 20px">
                    <h3>{{ header.text }}</h3>
                </div>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td class="fontMonospace text-left dynamicTruncate">
                        <PeerDetailModal
                            :peerDetail="peerDetail"
                            :activities="item.activities_count"
                            :loading="peerDetailLoading"
                            :key="`${item.id}${item.since}`"
                            :peerId="item.id"
                            :currency="currency.unit"
                        >
                            <template #showbutton="{ on }">
                                <div class="d-flex justify-space-between align-center">
                                    <span @click="pushPeerIdToQuery(item.id)" class="link pointer text-truncate" v-on="on">{{ item.host | truncate(40) }}</span>
                                    <LightningNetworkInvolvementChecker :ipAddress="item.host"/>
                                    <TorInvolvementChecker :ipAddress="item.host"/>
                                </div>
                            </template>
                        </PeerDetailModal>
                    </td>
                    <td>
                        <span>{{ item.country_code }}</span>
                    </td>
                    <td>
                        <span>{{ item.city }}</span>
                    </td>
                    <td class="text-right text-truncate">
                        <span>{{ item.asn | numberFormatWithSpaces }}</span>
                    </td>
                    <td class="text-left text-truncate" style="max-width: 250px;">
                        <span>{{ item.organization }}</span>
                    </td>
                    <td class="text-left fontMonospace" style="white-space: nowrap">
                        <span>{{ $moment(item.since).format("YYYY-MM-DD") }}</span>
                    </td>
                    <td class="text-right">
                        <v-chip small label>{{ item.activities_count | formatNumber }}</v-chip>
                    </td>
                    <td v-if="currency.interceptorEnabled">
                        <cryptointerceptorDetailModal :ip="item.host" @monitoring-start-event="startMonitoringPeer"
                                                      @monitoring-stop-event="stopMonitoringPeer"
                                                      @peer-polling-start-event="startPeerPolling">
                            <template #showbutton="{ on: one }">
                                <CryptoInterceptorControlPanelSmall
                                    :ip="item.host" showBadge
                                    small
                                    :disabled="!hasMonitoringPermission"
                                    @monitoring-start-event="startMonitoringPeer"
                                    @monitoring-stop-event="stopMonitoringPeer"
                                    @peer-polling-start-event="startPeerPolling"
                                    @peer-polling-stop-event="stopPeerPolling">
                                    <template #extrabutton>
                                        <v-hover
                                            v-slot="{ hover }"
                                        >
                                            <div>
                                                <div v-show="hover">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div class="extraButton">
                                                                <v-btn :disabled="!hasMonitoringPermission" small icon v-on="on" @click="one.click($event)">
                                                                    <v-icon :color="currency.unit">
                                                                        mdi-file-search-outline
                                                                    </v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </template>
                                                        <span>Show Details</span>
                                                    </v-tooltip>
                                                </div>
                                                <div v-show="!hover">
                                                    <v-btn :disabled="!hasMonitoringPermission" small icon @click="one.click($event)">
                                                        <v-icon>
                                                            mdi-file-search-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </v-hover>
                                    </template>
                                </CryptoInterceptorControlPanelSmall>
                            </template>
                        </cryptointerceptorDetailModal>
                    </td>
                </tr>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                <span>
                    {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                </span>
            </template>
        </v-data-table>
        <PeerDetailModal
            :peerDetail="peerDetail"
            :loading="peerDetailLoading"
            :peerId="peerId"
            :currency="currency.unit"
            ref="peerDetailModal"
        >
        </PeerDetailModal>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import PeerDetailModal from "../peers/PeerDetailModal"
import TableInfoButton from "@/components/common/TableComponents/TableInfoButton"
import debounce from "debounce";
import CryptoInterceptorControlPanelSmall from "@/components/CryptoInterceptor/CryptoInterceptorControlPanelSmall";
import CryptointerceptorDetailModal from "@/components/CryptoInterceptor/CryptointerceptorDetailModal"
import Copyable from "@/components/common/Copyable.vue";
import CountryPicker from "@/components/common/CountryPicker.vue";
import LightningNetworkInvolvementChecker from "@/components/peers/LightningNetworkInvolvementChecker.vue";
import TorInvolvementChecker from "@/components/peers/TorInvolvementChecker.vue";

export default {
    name: 'peersMetadata',
    components: {
        TorInvolvementChecker,
        LightningNetworkInvolvementChecker,
        Copyable,
        CountryPicker,
        CryptoInterceptorControlPanelSmall,
        PeerDetailModal,
        TableInfoButton,
        CryptointerceptorDetailModal
    },
    computed: {
        ...mapGetters(["currency", "peers", "peersLoading", "peerDetail", "peerDetailLoading", "peerDetailPagination", "cryptoInterceptorLoading", "cryptoInterceptorPeersData"]),
        peerId() {
            return Number(this.$route.query.id)
        },
        hasMonitoringPermission() {
            return this.$store.getters.hasPermission('monitoring')
        },
    },
    watch: {
        pagination: {
            handler() {
                this.debounceInput()
            },
            deep: true
        },
        peers: {
            handler() {
                if(!this.hasMonitoringPermission) {
                    return
                }

                if (this.currency.interceptorEnabled) {
                    let ips = []
                    this.peers.data.forEach(x => {
                        ips.push(x.host)
                    })
                    this.$store.dispatch("getCryptoInterceptorPeersData", {
                        currency: this.currency.unit,
                        filter: {filterIps: ips},
                        pagination: {
                            page: 1,
                            itemsPerPage: -1,
                            ...this.calculateStatuses()
                        }
                    })
                }
            },
            deep: true
        },
        '$route.query.id': function (val) {
            if (val) {
                this.loadPeerDetail({peerId: this.$route.query.id, pagination:  this.pagination})
            }
        },
    },
    mounted() {
        if (this.$route.query.id) {
            this.openModal()
        }
    },
    methods: {
        pushPeerIdToQuery(peerId) {
            this.$router.push({ query: {id: peerId }})
        },
        openModal() {
            this.$refs.peerDetailModal.open()
            this.loadPeerDetail({peerId: this.$route.query.id, pagination:  this.pagination})
        },
        debounceInput: debounce(function () {
            this.refresh()
        }, 450),
        refresh() {
            this.$store.dispatch('loadPeers', {
                currency: this.$router.currentRoute.params.currency,
                pagination: {...this.pagination, ...this.calculateStatuses()}
            })
        },
        loadPeerDetail({peerId, pagination}) {
            this.$store.dispatch("loadPeerDetail", {
                currency: this.$router.currentRoute.params.currency,
                peerId: peerId,
                pagination: {...this.pagination, ...this.calculateStatuses()}
            })
        },
        startMonitoringPeer({ip, port}) {
            this.$store.dispatch("setCryptoInterceptorPeerStatus", {
                currency: this.currency.unit,
                ipaddress: ip,
                action: "start",
                port
            })
        },
        stopMonitoringPeer({ip, port}) {
            this.$store.dispatch("setCryptoInterceptorPeerStatus", {
                currency: this.currency.unit,
                ipaddress: ip,
                action: "stop",
                port
            })
        },
        startPeerPolling({ip}) {
            this.$store.dispatch("startPollCryptoInterceptorPeerStatus", {currency: this.currency.unit, ipaddress: ip})
        },
        stopPeerPolling({ip}) {
            this.$store.commit("CRYPTOINTERCEPTOR_PEER_REMOVE_POLLING", {ip})
        },

        calculateStatuses() {
            let retVal = []
            if (this.pagination.filterStatuses.includes("RED")) {
                retVal = retVal.concat(["STOPPED", "FAILED"])
            }
            if (this.pagination.filterStatuses.includes("ORANGE")) {
                retVal = retVal.concat(["CONNECTING"])
            }
            if (this.pagination.filterStatuses.includes("GREEN")) {
                retVal = retVal.concat(["CONNECTED"])
            }
            return {filterStatuses: retVal}
        }
    },
    beforeDestroy() {

    },
    data() {
        return {
            pagination: {
                page: 1,
                itemsPerPage: 30,
                filterHost: undefined,
                filterPort: undefined,
                filterCity: undefined,
                filterCountry: undefined,
                filterAsn: undefined,
                filterOrganization: undefined,
                filterStatuses: [],
                sortDesc: [true]
            },
            headers: [
                {text: 'Host', align: 'center', value: 'host'},
                {text: 'Country code', align: 'center', value: 'country_code'},
                {text: 'City', align: 'center',  value: 'city'},
                {text: 'ASN', align: 'center', value: 'asn'},
                {text: 'Organization', align: 'center', value: 'organization'},
                {text: 'First Seen', align: 'center', value: 'seen', sortable: false},
                {text: 'Activities', align: 'center', value: 'activities_count'},
                {text: 'Monitoring', align: 'center', value: 'cryptointerceptor', sortable: false},
            ]
        }
    },
}
</script>

<style scoped>
td {
    text-align: center;
    vertical-align: middle;
}

.v-btn:hover:before {
    background-color: transparent;
}

.v-btn:hover:after {
    background-color: transparent;
}

</style>