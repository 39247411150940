<script setup>

import {useGetData} from "@/components/common/Composables/useGetData";
import debounce from "debounce";
import {computed, onMounted, ref, watch} from "vue";

import {DataState} from "@/components/common/types/data";

const props = defineProps({
    value: {
        required: true
    },
    NoCustomClusters: {
        type: Boolean,
        required: false,
        default: false
    },
    NoNeoClusters: {
        type: Boolean,
        required: false,
        default: false
    },
    NoAddressClusters: {
        type: Boolean,
        required: false,
        default: false
    },
    multiple: {
        type: Boolean,
        required: false,
        default: true
    },
    currencyUnit: {
        type: String,
        required: true
    },
    autofocus: {
        type: Boolean,
        required: false,
        default: false
    },
    propertyName: {
        type: String,
        required: false,
    },
    label: {
        type: String,
        required: false
    },
    outlined: {
        type: Boolean,
        required: false,
        default: false
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    noDense: {
        type: Boolean,
        required: false,
        default: false
    },
    marginTop: {
        type: String,
        required: false,
        default: "16px"
    }
})

const emit = defineEmits(['input'])
const isError = ref(false)
const validate = async () => {
    const isValid = !!props.value
    isError.value = !isValid
    return isValid
}

const searchString = ref(null)
watch(searchString, () => {
    isError.value = false
    debouncedSearch(searchString.value)
})

const debouncedSearch = debounce(function (stringToSearch) {
    search(stringToSearch)
}, 500)


//searching through custom clusters
const {
    dataState: CustomClustersDataState,
    data: CustomClustersData,
    getData: CustomClustersGetData
} = useGetData("customClusters", {
    currency: props.currencyUnit,
    pagination: {page: 1, itemsPerPage: 10, sortBy: ["created_at"], sortDesc: [true]}
}, false, false, 1, true)
const fetchCustomClustersData = (searchValue) => {
    searchValue = searchValue ?? searchString.value
    CustomClustersGetData({
        currency: props.currencyUnit,
        pagination: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["created_at"],
            sortDesc: [true],
            filterEntityId: searchValue
        }
    })
}


//searching through neo clusters
const {dataState: ClustersDataState, data: ClustersData, getData: ClustersGetData} = useGetData("neoClusters", {
    currency: props.currencyUnit,
    pagination: {page: 1, itemsPerPage: 10, sortBy: ["created_at"], sortDesc: [true]}
}, false, false, 1, true)
const fetchClustersData = (searchValue) => {
    searchValue = searchValue ?? searchString.value
    ClustersGetData({
        currency: props.currencyUnit,
        pagination: {
            page: 1,
            itemsPerPage: 10,
            filterEntityId: searchValue
        }
    })
}

//searching through address -> clusters
const {
    dataState: AddressClustersDataState,
    data: AddressClustersData,
    getData: AddressClustersGetData
} = useGetData("clustersByAddress",
    {
        currency: props.currencyUnit,
        addressId: searchString.value
    }, false, false, 1, true)
const fetchAddressClustersData = (searchValue) => {
    searchValue = searchValue ?? searchString.value
    if (!searchValue) {
        return
    }

    AddressClustersGetData({
        currency: props.currencyUnit,
        addressId: searchValue
    })
}


const search = (stringToSearch = null) => {
    if (!props.NoNeoClusters) {
        fetchClustersData(stringToSearch)
    }

    if (!props.NoCustomClusters) {
        fetchCustomClustersData(stringToSearch)
    }

    if (!props.NoAddressClusters) {
        fetchAddressClustersData(stringToSearch)
    }
}

const lastValueObject = ref(null)
const watchableValue = computed(() => props.value)
watch(watchableValue, () => {
    if (props.propertyName) {
        //component is returning property - not object

        if (lastValueObject.value) {
            //it previously emitted value

            if (lastValueObject.value[props.propertyName] !== watchableValue.value) {
                //parent changed value manually
                search(watchableValue.value)
            }
        } else {
            //no value was yet emitted
            search(watchableValue.value)
        }
    }
}, {immediate: true, deep: true})

onMounted(() => {
    if (!props.propertyName) {
        search()
    }
})

const emitValue = (objectToEmit) => {
    lastValueObject.value = objectToEmit
    if (props.propertyName && objectToEmit) {
        emit('input', objectToEmit[props.propertyName])
    } else {
        emit('input', objectToEmit)
    }
}

//Merge of results
const foundClusters = computed(() => {
    let customClusterData = CustomClustersData?.value?.data ?? []
    let addressClustersData = AddressClustersData?.value ?? []
    let clustersData = ClustersData?.value ?? []

    const result = addressClustersData.concat(customClusterData).concat(clustersData)

    //since this component is getting only model-value string through prop, it has to get the whole
    //object manually. So if its from parents perspective modelling a value but no value was emitted,
    //parent assigned the value itself, not this component. So that means this components has never seen
    //the object its modeling.
    if (props.value && !lastValueObject.value) {
        const pickedClusterObject = result.find(c => c.entity_id === props.value)
        if (pickedClusterObject) {
            lastValueObject.value = pickedClusterObject
        }
    }

    //If some cluster is selected, add it to autocomplete's items so it shows up as selected
    if (lastValueObject.value && !props.multiple) {
        result.unshift(lastValueObject.value)
    }
    return result
})

const loading = computed(() => ClustersDataState.value === DataState.Loading
    || AddressClustersDataState.value === DataState.Loading
    || CustomClustersDataState.value === DataState.Loading)

defineExpose({validate})

const computedLabel = computed(() => {
    if (props.label) {
        return props.label
    }
    return props.multiple ? 'Clusters' : 'Cluster'
})
</script>

<template>
    <div class="autocomplete" :style="{'margin-top': marginTop}">
        <v-autocomplete
            :autofocus="autofocus"
            :dense="!noDense"
            :disabled="disabled"
            :error="isError"
            :items="foundClusters"
            :label="computedLabel"
            :loading="loading"
            :multiple="multiple"
            :outlined="outlined"
            :persistent-hint="true"
            :search-input.sync="searchString"
            :value="value"
            :no-data-text="loading ? 'Loading...' : 'No matching clusters found.'"
            clear-icon="mdi-close-circle"
            clearable
            hint="Enter cluster or address"
            item-value="entity_id"
            no-filter
            prepend-icon="mdi-database-search"
            return-object
            @blur="() => {if(!value) {searchString = null}}"
            @input="emitValue"
            @click:clear="searchString ? searchString = null : search(null)"
        >
            <template #item="{ item }">
                <v-list-item-content>
                    <v-list-item-title class="fontMonospace" v-text="item.entity_id"></v-list-item-title>
                    <v-list-item-subtitle><strong>{{ item.type === 'custom' ? "custom" : "co-spent" }} |
                        {{ item.addresses_count | formatNumber }} </strong> Addresses
                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
            <template v-if="!multiple" #selection="{ item }">
                <v-chip small>
                    <v-icon class="my-1" size="18" left>mdi-hub</v-icon>
                    <b>{{ item.entity_id }}</b>&nbsp;(<i>{{ item.type }}</i>, {{ item.addresses_count | formatNumber }}
                    Addresses)
                </v-chip>
            </template>
        </v-autocomplete>
        <div class="caption mt-n1 ml-1">Search by cluster's ID <span class="font-weight-light">or</span> complete
            address.
        </div>
    </div>

</template>

<style>
.autocomplete .v-select__selection.v-select__selection--comma {
    display: none !important;
}
</style>