interface IParsedAddressMetadata {
    categories: Set<string>,
    owners: Set<string>,
    identities: Map<string, IIdentityObject>,
    address: string
}

type ImportEmitName = "import-finished" | "import-start" | "close-modal"
type ImportEmitType = (eventName: ImportEmitName, event?: number) => void

enum ImportFormat {
    Standard,
    Case
}

interface IEntry {
    address: string,
    crypto: string,
    identities: IIdentityObject[],
    categories: object[],
    owners: object[]
}

interface IEntryFormattedForImport {
    address: string,
    identities: IIdentityObject[],
    categories: object[],
    owners: object[]
}

interface IIdentityObject {
    label: string,
    source?: string,
    url?: string
}

interface IRow {
    crypto: string,
    address: string,
    label: string,
    source?: string,
    url?: string,
    categories: string[],
    owners: string[]
}

interface IFormattedAndValidatedData {
    currencyToItems: ICurrencyToItems,
    invalidRows: IRow[]
}

type ICurrencyToItems = Map<string, IEntryFormattedForImport[]>

export {
    ImportFormat,
    IFormattedAndValidatedData,
    IIdentityObject,
    IRow,
    ICurrencyToItems,
    IEntry,
    IParsedAddressMetadata,
    ImportEmitType,
    IEntryFormattedForImport
};