export interface AddressMetadataDetailServerModel {
    ownerIds: number[],
    categoryIds: number[],
    owner_id: number,
    address: string,
    color: string,
    crypto: string,
    identities: [],
}

export interface AddressMetadataDetailViewModel {
    categoryIds: number[],
}

function MapToServerModel(model): AddressMetadataDetailServerModel {
    //Create ownerIds parameter
    let ownerIds = model.owners ? model.owners.map(item => item.id) : [];
    let identityIds = model.identities ? model.identities.map(item => item.id) : [];

    //Primary address owner
    let ownerId = null;

    if (model.owner)
        ownerId = model.owner.id
    else if (ownerIds && ownerIds[0])
        ownerId = ownerIds[0];

    //Primary address owner
    let identityId = null;

    if (model.identity)
        identityId = model.identity.id
    else if (identityIds && identityIds[0])
        identityId = identityIds[0];

    model.identities = model.identities.map(identity => {
        return {label: identity.label, source: identity.source, url: identity.url, ...(identity.id && {id: identity.id})}
    })

    //strip data
    const { address, crypto, color, identities, categoryIds, ...restOfModel } = model

    return { address, crypto, color, identities, categoryIds, owner_id: ownerId, identity_id: identityId, ownerIds } as AddressMetadataDetailServerModel
}

function MapToViewModel(model): AddressMetadataDetailViewModel {
    let categoryIds = model.categories.map(item => item.id)

    model.totalReceived = typeof(model.totalReceived) === "number" ? model.totalReceived : null
    model.totalSent = typeof(model.totalSent) === "number" ? model.totalSent : null

    return {...model, categoryIds} as AddressMetadataDetailViewModel
}

export default {    MapToServerModel, MapToViewModel }