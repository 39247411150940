<script setup>

import {BaseModalDialog} from "@/components/common";
import {computed, inject, ref} from "vue"
import store from "@/store";
import router from "@/router";
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

const data = inject("data")

const props = defineProps({
    icon: {
        type: Boolean,
        required: false,
        default: false
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    redirect: {
        type: Boolean,
        required: false,
        default: false
    }
})


const emit = defineEmits(["created-event"])

const currencyUnit = computed(() => data.value.currencyUnit)
const cluster = computed(() => data.value.cluster)
const clusterName = computed(() => data.value.clusterName)


const newClusterName = ref(null)
const validator = ref(null)

const createButtonRef = ref(null)
const baseModalDialogRef = ref(null)

const onSave = async (close, setDisabled) => {
    if (await validator.value.validate()) {
        setDisabled(true)

        const wasCopySuccess = await store.dispatch("copyCluster", {
            currency: currencyUnit.value,
            clusterMetadata: {
                ...data.value.cluster,
                entity_id: newClusterName.value,
                addresses: [],
                description: data.value.cluster.description ?? `Copy of ${clusterName.value}`,
                categoryIds: data.value.cluster.categories.map(x => x.id),
                crypto: currencyUnit.value
            },
        })

        if (!wasCopySuccess) {
            setDisabled(false)
            emit('created-event')
            return
        }

        const wasMergeSuccess = await store.dispatch("mergeToCluster", {
            currency: currencyUnit.value,
            clusterToMergeTo: newClusterName.value,
            clustersToMergeWith: [clusterName.value]
        })

        setDisabled(false)

        if (wasMergeSuccess && props.redirect) {
            await router.push({name: "clusterSummary", params: {clusterentity: newClusterName.value}})
        } else {
            emit('created-event')
        }
        newClusterName.value = null
        close()
    }
}

const onClose = () => {
    newClusterName.value = null
    baseModalDialogRef.value.close()
}

</script>

<template>
    <BaseModalDialog
        ref="baseModalDialogRef"
        title="Copy as Custom Cluster"
        width="80vh">
        <template #showbutton="{ on: dialogOn }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <BaseDataModifyButton :disabled="disabled" :icon="icon" color="info" small v-bind="attrs"
                           v-on="{ ...dialogOn, ...on}">
                        <v-icon :left="!icon">
                            mdi-content-save-move-outline
                        </v-icon>
                        {{ icon ? "" : "Create Custom" }}
                    </BaseDataModifyButton>
                </template>
                Copy as custom cluster
            </v-tooltip>
        </template>
        <template #content>
            <v-row class="mt-2">
                <v-col cols="12">
                    <ValidationObserver ref="validator" mode="lazy">
                        <v-text-field-validateable
                            v-model="newClusterName"
                            autofocus
                            dense
                            label="Name"
                            rules="required|entity_id"
                            @keydown.enter="createButtonRef.click">
                            <template #prepend>
                                <v-icon left size="22">mdi-hub</v-icon>
                            </template>
                        </v-text-field-validateable>
                    </ValidationObserver>
                </v-col>
            </v-row>
        </template>
        <template #footer="{close, setDisabled, disabled}">
            <v-btn :disabled="disabled" color="error" @click="onClose">
                Cancel
            </v-btn>
            <v-spacer/>
            <v-btn ref="createButtonRef" :disabled="disabled" color="info" @click="onSave(close, setDisabled)">
                Create
            </v-btn>
        </template>
    </BaseModalDialog>
</template>