import { render, staticRenderFns } from "./GraphExplorerLabelEditor.vue?vue&type=template&id=db5dc770&scoped=true&"
import script from "./GraphExplorerLabelEditor.vue?vue&type=script&setup=true&lang=js&"
export * from "./GraphExplorerLabelEditor.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db5dc770",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VDialog,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db5dc770')) {
      api.createRecord('db5dc770', component.options)
    } else {
      api.reload('db5dc770', component.options)
    }
    module.hot.accept("./GraphExplorerLabelEditor.vue?vue&type=template&id=db5dc770&scoped=true&", function () {
      api.rerender('db5dc770', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/GraphExplorer/Components/GraphExplorerLabelEditor.vue"
export default component.exports