var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseModalDialog, {
    attrs: { width: "80%", "max-width": "500px", title: "Monitor new node" },
    on: { "load-modal-state-event": _setup.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c(
                    "ValidationObserver",
                    { ref: "validatorIP", attrs: { mode: "aggressive" } },
                    [
                      _c("v-text-field-validateable", {
                        staticClass: "monospace",
                        staticStyle: { width: "330px" },
                        attrs: {
                          rules: "ip|required",
                          dense: "",
                          label: "IP Address",
                          outlined: "",
                          autofocus: "",
                          disabled: _setup.pending,
                        },
                        on: { keyup: _setup.validateIP },
                        model: {
                          value: _setup.ip,
                          callback: function ($$v) {
                            _setup.ip = $$v
                          },
                          expression: "ip",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-2", staticStyle: { width: "80px" } },
                [
                  _c(
                    "ValidationObserver",
                    { ref: "validatorPort", attrs: { mode: "aggressive" } },
                    [
                      _c("v-text-field-validateable", {
                        staticClass: "none_pointer_events monospace",
                        attrs: {
                          rules: "port|required",
                          dense: "",
                          label: "Port",
                          outlined: "",
                          disabled: _setup.pending,
                          type: "number",
                          "hide-spin-buttons": "",
                        },
                        on: { keyup: _setup.validatePort },
                        model: {
                          value: _setup.port,
                          callback: function ($$v) {
                            _setup.port = $$v
                          },
                          expression: "port",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _setup.pending
                ? _c(
                    "div",
                    [
                      _vm._v("\n            Starting monitoring of "),
                      _c("span", [_vm._v(_vm._s(_setup.ip))]),
                      _vm._v(" "),
                      _c("v-progress-linear", {
                        attrs: {
                          color: _vm.currency.unit,
                          indeterminate: "",
                          rounded: "",
                          height: "6",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close }) {
            return [
              _c(
                "v-btn",
                {
                  ref: "closeButton",
                  staticClass: "ml-4",
                  attrs: {
                    disabled: _setup.pending,
                    color: "error",
                    outlined: "",
                  },
                  on: { click: close },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    disabled: _setup.pending,
                    color: "success",
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _setup.save(close)
                    },
                  },
                },
                [_vm._v(_vm._s("Monitor node"))]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }