var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    _vm._b(
      {
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function ({ on }) {
              return [
                _c(
                  _setup.BaseDataModifyButton,
                  _vm._g(
                    _vm._b(
                      { attrs: { icon: "", color: "info", small: "" } },
                      "BaseDataModifyButton",
                      _vm.$attrs,
                      false
                    ),
                    { ..._vm.$listeners, ...on }
                  ),
                  [_c("v-icon", { attrs: { small: "" } }, [_vm._v("edit")])],
                  1
                ),
              ]
            },
          },
        ]),
      },
      "v-tooltip",
      _setup.tooltipPositionAttribute,
      false
    ),
    [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.editButtonTooltip))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }