<template>
    <span>
        <span v-if="!medium && !small">
            <v-chip v-if="large" :color="iconItem.color" :text-color="iconItem.secondaryColor">
                <v-avatar>
                    <v-icon>{{ icon }}</v-icon>
                </v-avatar>
                <span style="font-weight: 500">{{ iconItem.name }}</span>
            </v-chip>

            <v-tooltip top v-else>
                <template v-slot:activator="{ on }">
                    <v-icon :style="'font-size: ' + iconSize" :class="cursor" :color="iconItem.color"
                            v-on="on">{{ icon }}</v-icon>
                </template>
                <span>{{ iconItem.name }}</span>
            </v-tooltip>
        </span>
        <span v-else>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-chip :style="{ height: `${small ? 22 : 26}px` }" class="my-1" :color="iconItem.color"
                            :text-color="iconItem.secondaryColor" v-on="on">
                        <v-avatar class="ml-n2">
                            <v-icon small>{{ icon }}</v-icon>
                        </v-avatar>
                        <span style="font-weight: 500">
                            {{ iconItem.name }}
                        </span>
                    </v-chip>
                </template>
                <span>{{ iconItem.name }}</span>
            </v-tooltip>
        </span>
    </span>
</template>

<script>

export default {
    name: 'AddressCategoryIcon',
    props: {
        large: {
            type: Boolean,
            default: false
        },
        medium: {
            type: Boolean,
            default: false
        },
        iconSize: {
            type: String,
            default: "24px",
            required: false
        },
        small: {
            type: Boolean,
            default: false
        },
        iconItem: {
            required: true,
        },
        cursor: {
            default: "pointer",
            required: false,
            type: String,
        }
    },
    computed: {
        icon() {
            return this.$options.filters.getIconString(this.iconItem)
        }
    }
}
</script>
