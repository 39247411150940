var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-center d-flex align-center" },
    [
      _c(
        "v-menu",
        {
          attrs: { "close-on-content-click": false, auto: "", "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "white" } },
                        "v-icon",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v("\n                mdi-cog\n            ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-subheader",
                { staticClass: "ml-1", staticStyle: { height: "24px" } },
                [_vm._v("Options")]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-list-item-title", [
                _c("div", { staticClass: "d-flex align-center px-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between align-center ml-2",
                      staticStyle: { width: "160px" },
                    },
                    [
                      _vm._v(
                        "\n                        Labels:\n                        "
                      ),
                      _c("v-switch", {
                        staticClass: "ml-2 mt-2 mb-1",
                        staticStyle: { height: "32px" },
                        attrs: { color: "#1565C0", inset: "" },
                        model: {
                          value: _setup.showEntityState,
                          callback: function ($$v) {
                            _setup.showEntityState = $$v
                          },
                          expression: "showEntityState",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _setup.isUserSuperAdmin && _setup.isEnvironmentDevelOrStaging
                ? _c("v-list-item-title", [
                    _c("div", { staticClass: "d-flex align-center px-2" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center ml-2",
                          staticStyle: { width: "160px" },
                        },
                        [
                          _vm._v(
                            "\n                        Debug:\n                        "
                          ),
                          _c("v-switch", {
                            staticClass: "ml-2 mt-2 mb-1",
                            staticStyle: { height: "32px" },
                            attrs: { color: "#1565C0", inset: "" },
                            model: {
                              value: _setup.debugModel,
                              callback: function ($$v) {
                                _setup.debugModel = $$v
                              },
                              expression: "debugModel",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }