var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _setup.GESelectedElement,
          expression: "GESelectedElement",
        },
      ],
      ref: "drawerContainerRef",
      staticClass: "drawerContainer",
    },
    [
      _c("div", {
        staticClass: "draggable",
        on: { mousedown: _setup.mouseDown },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "background-color": "white", width: "100%" } },
        [
          _setup.GESelectedElement?.data.type === _setup.GEEleType.NodeAddress
            ? _c("div", [_c(_setup.GraphExplorerDrawerAddress)], 1)
            : _setup.GESelectedElement?.data.type ===
              _setup.GEEleType.NodeTransaction
            ? _c("div", [_c(_setup.GraphExplorerDrawerTransaction)], 1)
            : _setup.GESelectedElement?.data.type === _setup.GEEleType.NodeMerge
            ? _c("div", [_c(_setup.GraphExplorerDrawerMerge)], 1)
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }