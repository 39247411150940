var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-10 d-flex align-center justify-center flex-column" },
    [
      _setup.progress
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content align-center flex-column mt-6",
                staticStyle: { width: "400px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-start tertiary--text" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-h6 fontMonospace font-weight-medium",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_setup.progressValue.toFixed(1)) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "text-subtitle-2",
                        staticStyle: { "margin-top": "2px" },
                      },
                      [_vm._v("\n                    %\n                ")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("v-progress-linear", {
                  attrs: {
                    value: _setup.progressValue,
                    "buffer-value": "0",
                    color: "tertiary",
                    height: "10",
                    stream: "",
                    striped: "",
                  },
                }),
              ],
              1
            ),
          ])
        : _c("v-progress-circular", {
            attrs: { size: 80, width: 8, color: "tertiary", indeterminate: "" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }