var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.NameValueFlex, {
    scopedSlots: _vm._u(
      [
        {
          key: "name",
          fn: function () {
            return [
              _c("span", { staticClass: "font-weight-light" }, [
                _vm._v("\n            " + _vm._s(_vm.name) + ":\n        "),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "value",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _c(
                    "v-chip",
                    {
                      staticStyle: { height: "24px" },
                      attrs: {
                        color: _vm.chipColor,
                        label: "",
                        "text-color": _vm.textColor,
                      },
                    },
                    [
                      _vm.value
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "subtitle-2 font-weight-bold",
                                staticStyle: { "vertical-align": "middle" },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.value) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.unit
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "subtitle-2 pl-1 text--white font-weight-light",
                                    staticStyle: { "vertical-align": "middle" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.unit) +
                                        "\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _c("span", [_vm._v("N/A")]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._t("appendIcon"),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }