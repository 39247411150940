<template>
    <BaseModalDialog ref="baseModal" title="Add new node" @load-modal-state-event="loadState">
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on"/>
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-container>
                    <v-row no-gutters>
                        <v-col cols="12" class="pl-5 pr-5">
                            <p>Please use the following controls to configure a relationship between the newly added
                                node and existing ones on the graph. This modal will help you to specify what paths
                                should be plotted between nodes.</p>
                        </v-col>
                        <v-col cols="12" class="pl-5 pr-5">
                            <v-text-field-validateable
                                :rules="`required|${detail.asAddress ? 'crypto' : 'entity_id'}`"
                                :label="`Node crypto ${detail.asAddress ? 'address' : 'cluster'}`"
                                v-model="detail.address"
                                outlined
                                dense
                            />
                        </v-col>

                        <v-col cols="8" md="8" class="pl-9 pb-4 pr-4">
                            <v-switch
                                :label="`${detail.asAddress
                                    ? 'Node is an Address and search for paths between addresses'
                                    : 'Node is a Cluster and search for paths between clusters'}`"
                                v-model="detail.asAddress"
                                :true-value="1"
                                :false-value="0"
                                :color="currency.unit"
                            />
                        </v-col>
                        <v-col cols="4" sm="4" class="pr-12 pb-4">
                            <v-text-field-validateable
                                v-model.number="detail.maxHops"
                                label="Max hops in path"
                                type="number"
                                :min="1"
                                :max="currency.unit !== 'ETH'  ? 20 : 1"
                                rules="required|numeric"
                            />
                        </v-col>

                        <v-col cols="12" class="pl-5 pr-5">
                            <v-combobox
                                :items="directionsOptions"
                                v-model="detail.directions"
                                label="Direction of added node"
                                hide-selected
                                outlined
                            />
                        </v-col>
                        <v-col cols="12" class="pl-5 pr-5">
                            <v-combobox
                                @change="applyValue"
                                :items="pathsOptions"
                                v-model="detail.paths"
                                label="Search for following path(s)"
                                hide-selected
                                outlined
                            />
                        </v-col>
                        <v-col cols="8" md="8" class="pl-12">
                            <v-switch
                                :label="`${detail.includeClusters ? 'Include Clusters enroute' : 'Exclude Clusters enroute'}`"
                                v-model="detail.includeClusters"
                                :true-value="1"
                                :false-value="0"
                                :color="currency.unit"
                            />
                        </v-col>
                        <v-col cols="4" sm="4" class="pr-12">
                            <v-text-field-validateable
                                :disabled="detail.paths.value === 'shortest_single'"
                                v-model.number="detail.limit"
                                label="Limit Results"
                                type="number"
                                :min="0"
                                rules="required|numeric"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </ValidationObserver>
        </template>
        <template #footer="{close, setDisabled}">
            <v-btn
                color="error"
                text
                :disabled="newNodeModalState === modalState.Pending"
                @click="close"
            >
                Cancel
            </v-btn>
            <v-spacer/>
            <v-btn color="success"
                   text
                   :loading="newNodeModalState === modalState.Pending"
                   @click="addNewNode(close, setDisabled)"
            >
                Add new node
            </v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import BaseModalDialog from "@/components/common/Base/BaseModalDialog"
import {mapGetters} from "vuex"

import {modalState} from "@/components/common/types/modal";

export default {
    name: "AddNewNodeModal",
    components: {
        BaseModalDialog
    },
    data() {
        return {
            detail: {
                address: '',
                maxHops: 1,
                limit: 1,
                asAddress: 1,
                includeClusters: 1,
                paths: {text: 'A single shortest', value: 'shortest_single'},
                directions: {text: 'As both Source and Destination', value: 'both'},
            },
            directionsOptions:
                [
                    {
                        text: 'As both Source and Destination',
                        value: 'both'
                    },
                    {
                        text: 'As Source',
                        value: 'source'
                    },
                    {
                        text: 'As Destination',
                        value: 'destination'
                    }
                ],
            pathsOptions: [
                {
                    text: 'A single shortest',
                    value: 'shortest_single'
                },
                {
                    text: 'All (may take a long time)',
                    value: 'all'
                },
                {
                    text: 'All Shortest (experimental)',
                    value: 'shortest_all'
                },
            ],
            closeDialog: null,
            setDisabled: null,
        }
    },
    computed: {
        ...mapGetters(["currency", "newNodeModalState"]),
        modalState() {
            return modalState
        }
    },
    watch: {
        newNodeModalState(newVal) {
            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit("GRAPH_NEW_NODE_MODAL_SET_STATE", modalState.Initial)
            } else if (newVal === modalState.Error) {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit("GRAPH_NEW_NODE_MODAL_SET_STATE", modalState.Initial)
            }
        }
    },
    methods: {
        openModal() {
            this.$refs.baseModal.open()
        },
        loadState() {
            this.detail = {
                address: '',
                maxHops: 1,
                limit: 1,
                asAddress: 1,
                includeClusters: 1,
                paths: {text: 'A single shortest', value: 'shortest_single'},
                directions: {text: 'As both Source and Destination', value: 'both'}
            }
        },
        async addNewNode(close, setDisabled) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.closeDialog = close
                this.setDisabled = setDisabled
                setDisabled(true)
                this.$emit("add-node-event", {
                    detail: {
                        ...this.detail,
                        paths: this.detail.paths.value,
                        directions: this.detail.directions.value
                    }
                })
            }
        },
        applyValue() {
            if (this.detail.paths.value === 'all') {
                this.detail.limit = 100
            } else {
                this.detail.limit = 1
            }
        }
    }
}
</script>