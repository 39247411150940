var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, left: "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: menu }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: tooltip }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "border-left":
                                      "thin solid rgba(0, 0, 0, 0.3)",
                                    "border-radius": "0 4px 4px 0",
                                    "min-width": "0",
                                    padding: "18px 0",
                                    height: "28px",
                                    opacity: "0.8",
                                  },
                                  attrs: { color: "#EEEEEE", elevation: "0" },
                                  on: { click: _vm.loadGraphs },
                                },
                                { ...tooltip, ...menu }
                              ),
                              [
                                _c("v-icon", { attrs: { color: "#444444" } }, [
                                  _vm._v("timeline"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_vm._v(" "), _c("span", [_vm._v("Show transaction graph")])]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        {
          staticStyle: {
            "max-width": "400px",
            "min-width": "400px",
            "min-height": "200px",
          },
          attrs: { "single-line": "" },
        },
        [
          _vm.isOnGraphPage
            ? [
                _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("ShowOnCurrentGraphButton", {
                          on: {
                            "show-transaction-click-event":
                              _vm.appendTransactionNetworkToExistingGraph,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-divider"),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _vm.isOnCanvasPage
                    ? _c("ShowOnCanvasButton", {
                        on: {
                          "show-transaction-click-event":
                            _vm.appendTransactionNetworkToExistingTransactionNetwork,
                        },
                      })
                    : _c("ShowOnCanvasButton", {
                        attrs: { currency: this.currency, txhash: _vm.txhash },
                        on: {
                          "show-transaction-click-event":
                            _vm.redirectToTransactionNetworkView,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-subheader", [_vm._v("Show on graph")]),
          _vm._v(" "),
          !_vm.graphsLoading
            ? [
                _c("v-data-table", {
                  attrs: {
                    items: _vm.graphs.data,
                    "items-per-page": _vm.graphs.data?.length > 10 ? 10 : -1,
                    "hide-default-footer": _vm.graphs.data?.length <= 10,
                    "footer-props": {
                      "items-per-page-options": [10, 20, -1],
                      "show-current-page": true,
                    },
                    dense: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function (props) {
                          return [
                            _c("tr", [
                              _c(
                                "td",
                                { staticClass: "text-left dynamicTruncate" },
                                [
                                  _c("route-to-graph", {
                                    attrs: {
                                      "graph-object": props.item,
                                      "transaction-network-id-to-append":
                                        _vm.txhash,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "footer.prepend",
                        fn: function () {
                          return [_c("v-spacer")]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2611216244
                  ),
                }),
              ]
            : !_vm.graphsLoading && _vm.graphs.data.length === 0
            ? _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _vm._v(
                      "No graphs in currency " + _vm._s(_vm.currency.name)
                    ),
                  ]),
                ],
                1
              )
            : _c(
                "v-list-item",
                [
                  _c("ProgressCircular", {
                    staticClass: "pt-4",
                    attrs: { color: _vm.currency.unit },
                  }),
                ],
                1
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }