<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attr }">
            <div v-on="on" v-bind="attr" v-if="date">
                <span :class="['d-inline-block', 'font-weight-light', 'font-italic']">
                    {{ date }}
                </span>
            </div>
            <div v-else>
                <div class="d-inline-flex justify-center" style="max-width: 150px">
                    <span class="d-inline-block font-weight-light font-italic">
                        Unconfirmed date
                    </span>
                </div>
            </div>
        </template>
        <slot name="tooltip" />
    </v-tooltip>
</template>


<script>

export default {
    name: "TransactionDate",
    props: {
        date: {
            required: true
        },
        unconfirmed: {
            required: false,
            type: Boolean,
            default: false
        }
    },
}
</script>


<style scoped>

.underline-text-decoration {
    text-decoration: underline;
    text-decoration-thickness: 0.05em;
}

.no-text-decoration {
    text-decoration: none;
}

</style>