<template>
    <v-menu
        transition="slide-y-transition"
        rounded="lg"
        :close-on-content-click="false"
        close-delay="50"
        open-on-click
        bottom
    >
        <template v-slot:activator="{ on, attrs }">
            <span style="cursor: pointer" v-on="on">
                <slot name="activator"/>
            </span>
        </template>
        <fancy-card :header="false">
            <template #body>
                <slot name="default"/>
            </template>
        </fancy-card>
    </v-menu>
</template>

<script>
import FancyCard from "@/components/common/Cards/FancyCard";

export default {
    name: "SmartTooltipBase",
    components: {FancyCard}
}
</script>

<style scoped>

</style>