<script setup>
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

defineProps({
    text: {
        type: String,
        required: false,
        default: "Merge"
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    icon: {
        type: Boolean,
        required: false,
        default: false
    },
})
</script>

<template>
    <BaseDataModifyButton :disabled="disabled" :icon="icon" color="info" v-bind="$attrs" v-on="$listeners"
           small>
        <v-icon :class="{'mr-1': !icon}" :left="!icon">mdi-tag-multiple-outline</v-icon>
        {{ icon ? "" : text }}
    </BaseDataModifyButton>
</template>
