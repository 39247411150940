var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    staticClass: "cardShadow",
    attrs: { dense: "" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-icon", { staticClass: "d-block mr-2 ml-1" }, [
                  _vm._v("assignment"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("My Cases"),
                ]),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c(_setup.CaseDetailModal, {
                  attrs: {
                    categories: _setup.categories,
                    currencies: _setup.enabledCurrencies,
                  },
                  on: { "save-event": _setup.createCase },
                  scopedSlots: _vm._u([
                    {
                      key: "showbutton",
                      fn: function ({ on }) {
                        return [
                          _c(
                            _setup.CreateButton,
                            _vm._g(
                              {
                                staticClass: "mr-3",
                                attrs: { text: "Create case" },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(_setup.DataGrid, {
              ref: "dataGridRef",
              attrs: {
                "bulk-operations": false,
                headers: _setup.headers,
                "get-data": _setup.getData,
                "items-per-page": 5,
                item_id: "id",
                "sort-desc": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "td",
                        {
                          staticClass: "text-left dynamicTruncate",
                          staticStyle: { "min-width": "150px" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "link",
                              attrs: { to: `/cases/${item.id}` },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.title) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("formatNumber")(item.files_count)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("formatNumber")(item.addresses_count)
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: item.is_hidden
                                                  ? "primary"
                                                  : "tertiary",
                                                dark: "",
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  item.is_hidden
                                                    ? "visibility_off"
                                                    : "visibility"
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "Case is\n                            " +
                                    _vm._s(
                                      item.is_hidden ? "hidden" : "visible"
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: item.is_warning
                                                  ? "warning"
                                                  : "primary",
                                                dark: "",
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  item.is_warning
                                                    ? "report"
                                                    : "report_off"
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "Case\n                            " +
                                    _vm._s(
                                      item.is_warning
                                        ? "warns"
                                        : "does not warn"
                                    ) +
                                    "\n                            creator\n                        "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("moment")(
                                item.reported_at,
                                "YYYY-MM-DD HH:mm:ss Z"
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("moment")(
                                item.created_at,
                                "YYYY-MM-DD HH:mm:ss Z"
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("moment")(
                                item.updated_at,
                                "YYYY-MM-DD HH:mm:ss Z"
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-left" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.updator.name) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c(_setup.CaseDetailModal, {
                              key: item.id,
                              attrs: {
                                caseDetail: _setup.caseDetail,
                                categories: _setup.categories,
                                currencies: _setup.enabledCurrencies,
                                loading: _setup.isCaseLoading,
                              },
                              on: { "save-event": _setup.editCase },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "showbutton",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          _setup.TableEditButton,
                                          _vm._g(
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.$store.getters.user
                                                    .name !==
                                                    item.creator.name &&
                                                  _vm.$store.getters.user
                                                    .name !== "SuperAdmin",
                                                editButtonTooltip: "Edit case",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _setup.loadCaseDetail({
                                                    caseId: item.id,
                                                  })
                                                },
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c(_setup.TableDeleteModalDialog, {
                              attrs: {
                                description: `Do you really want to delete case ${item.title}`,
                                itemToDelete: item,
                                tooltip: "Delete case",
                              },
                              on: { "delete-event": _setup.deleteCase },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }