var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "txFilters",
      staticStyle: { cursor: "pointer" },
      on: {
        click: function ($event) {
          _setup.menuVisible = !_setup.menuVisible
        },
      },
    },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "content-class": "whiteBackground",
            "close-on-content-click": false,
            bottom: "",
            "offset-y": "",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c("span", { staticClass: "txFiltersLabel" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "font-weight-bold caption body-1",
                              staticStyle: { color: "rgba(0,0,0,0.6)" },
                            },
                            [_vm._t("label")],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "caption body-2 font-weight-light underline-on-hover mr-2",
                              staticStyle: { color: "rgba(0,0,0,0.6)" },
                              on: {
                                click: function ($event) {
                                  return _setup.emit("on-remove-filter-event")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Remove\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center mr-2" },
                        [
                          _vm._t("textField", function () {
                            return [
                              _c("v-text-field", {
                                staticStyle: { "max-width": "260px" },
                                attrs: {
                                  clearable: "",
                                  readonly: "",
                                  outlined: "",
                                  value: _setup.props.value,
                                  "clear-icon": "mdi-close-circle",
                                  dense: "",
                                },
                                on: {
                                  "click:clear": function ($event) {
                                    return _setup.emit("on-clear-event")
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-inner",
                                      fn: function () {
                                        return [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mx-2",
                                                staticStyle: {
                                                  "min-width": "22px",
                                                  "margin-top": "2px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "20" } },
                                                  [_vm._t("icon")],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "font-weight-bold caption body-1 d-flex justify-center align-center",
                                              staticStyle: {
                                                color: "rgba(0,0,0,0.6)",
                                                "margin-top": "2px",
                                              },
                                            },
                                            [_vm._t("append")],
                                            2
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _setup.menuVisible,
            callback: function ($$v) {
              _setup.menuVisible = $$v
            },
            expression: "menuVisible",
          },
        },
        [_vm._v(" "), _c("div", [_vm._t("menuContent")], 2)]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }