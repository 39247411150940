<script setup>
import ViewHeaderWithTabs from "@/components/Headers/ViewHeaderWithTabs.vue";
import ViewHeader from "@/components/Headers/ViewHeader.vue";
import store from "@/store";
import {computed} from "vue";

const tabs = [
    {
        icon: "mdi-filter",
        text: "Custom",
        route: "TransactionsFilterCustom"
    },
    {
        icon: "mdi-earth",
        text: "Geo",
        route: "TransactionsFilterGeo"
    },
]

const currencyFamilyIsEth = computed(() => store.getters.currency.typeFamily === "ETH")

</script>

<template>
    <div>
        <ViewHeader v-if="currencyFamilyIsEth" text="Transactions Filters"/>
        <ViewHeaderWithTabs v-else text="Transactions Filters" :tabs="tabs"/>
        <router-view/>
    </div>
</template>

<style scoped>

</style>