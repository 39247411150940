import apiClient from "@/services/apiClient";
import axios from "axios";
import mappers from "models/LimitedPaginationModel";
import Vue from "vue";

const state = {
    clusterCurrent: null,
    clusterTimelineAddresses: [],


    clusterInfoLoading: false,
    clusterInfo: null,

    clusterDetailsLoading: false,
    clusterDetails: null,

    clusterAddressesLoading: false,
    clusterAddressesData: null,
    clusterAddressesPagination: null,
    clusterAddressesCancelToken: null
}

const getters = {
    clusterCurrent: (state) => state.clusterCurrent,

    clusterInfoLoading: (state) => state.clusterInfoLoading,
    clusterInfo: (state) => state.clusterInfo,

    clusterDetailsLoading: (state) => state.clusterDetailsLoading,
    clusterDetails: (state) => state.clusterDetails,

    clusterAddressesLoading: (state) => state.clusterAddressesLoading,
    clusterAddressesData: (state) => state.clusterAddressesData,
    clusterAddressesPagination: (state) => state.clusterAddressesPagination,
}

const mutations = {
    CLUSTER_ADDRESSES_LOADING_START(state) {
        state.clusterAddressesLoading = true
    },
    CLUSTER_ADDRESSES_ABORT_CONTROLLER_CANCEL_PREVIOUS_CREATE_NEW(state) {
        if (state.clusterAddressesCancelToken) {
            state.clusterAddressesCancelToken.abort()
        }
        state.clusterAddressesCancelToken = new AbortController()
    },

    CLUSTER_ADDRESSES_LOADING_FINISHED(state) {
        state.clusterAddressesLoading = false
    },
    CLUSTER_ADDRESSES_ABORT_CONTROLLER_RESET_STATE(state) {
        state.clusterAddressesCancelToken = null
    },

    //CURRENT CLUSTER SETTER
    CLUSTER_SET_CURRENT(state,data)
    {
        state.clusterCurrent = data
    },

    CLUSTER_ADDRESSES_SET_DATA(state, data) {
        state.clusterAddressesData = data
    },

    CLUSTER_RESET_DATA (state) {
        state.clusterAddressesLoading = false
        state.clusterAddressesData = null
        state.clusterAddressesPagination = null
        state.clusterInfo = null
        state.clusterDetails = null
        state.clusterCurrent = null
    },

    CLUSTER_SET_PRIMARY_OWNER_BY_ID(state,data) {
        let newOwner = state.clusterInfo.cluster.owners.find(owner => {
            return owner.id === data.id
        })

        Vue.set(state.clusterInfo.cluster,"owner",{...newOwner})
    },
}

const actions = {
    async getClusterInfo({commit, dispatch}, {currency, entity}) {
        let response = null
        try {
            response = await apiClient.GET(`${currency}/cryptocluster/${entity}`)
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error
            }
            return
        }
        return response
    },

    async getClusterAddresses(
        {commit, dispatch},
        {currency, entity, pagination, cancelToken}
    )
    {
        commit('CLUSTER_ADDRESSES_LOADING_START')
        commit('CLUSTER_ADDRESSES_ABORT_CONTROLLER_CANCEL_PREVIOUS_CREATE_NEW')

        let limitedPagination = mappers.MapToServerSidePagination(pagination)
        let response
        try {
            response = await apiClient.GET(`${currency}/cryptocluster/${entity}/addresses`, {
                params: limitedPagination,
                cancelToken: cancelToken
            })

            commit("CLUSTER_ADDRESSES_SET_DATA", response)
            commit('CLUSTER_ADDRESSES_ABORT_CONTROLLER_RESET_STATE')
            commit("CLUSTER_ADDRESSES_LOADING_FINISHED")
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error
                commit('CLUSTER_ADDRESSES_ABORT_CONTROLLER_RESET_STATE')
                commit("CLUSTER_ADDRESSES_LOADING_FINISHED")
            }
        }
        return response
    },

    async getClusterDetails(
        {commit, dispatch},
        {currency, entity}
    ) {
        let response = null
        try {
            response = await apiClient.GET(
                currency + `/cryptocluster/` + entity  + `/addresses/count`,
                {
                    withCredentials: true,
                },
            );
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch('error', error.userFriendlyMessage)
            }
        }
        return response
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}