import store from "@/store";

export const useGetActiveCurrencyOrDefault = (route) => {
    if(store.getters.currency) {
        return store.getters.currency.unit
    } else if (route.query.filterCurrency) {
        return route.query.filterCurrency
    } else {
        return store.getters.currencies[0].unit
    }
}