var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "mb-0" },
    [
      _c(
        "v-tabs",
        { attrs: { "slider-color": "tertiary" } },
        [
          _vm._l(_setup.currencies, function (currency) {
            return _c(
              "v-tab",
              {
                key: currency.id,
                attrs: { disabled: _setup.noDataToDisplay(currency.unit) },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-center",
                    staticStyle: { gap: "12px" },
                  },
                  [
                    _c("v-img", {
                      staticStyle: { width: "24px" },
                      attrs: { src: currency.image, alt: "crypto icon" },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(currency.name) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _setup.currencyAddressesLoading(currency.unit)
                      ? _c(_setup.ProgressCircular, {
                          attrs: { size: 20, color: currency.color },
                        })
                      : _c("v-badge", {
                          staticClass: "fontMonospace",
                          attrs: {
                            inline: "",
                            content: _setup.numberFormatCompact(
                              _setup.totalAddressesToDisplay(currency.unit)
                            ),
                          },
                        }),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(_setup.currencies, function (currency) {
            return _c(
              "v-tab-item",
              { key: currency.id, attrs: { eager: "" } },
              [
                _c(_setup.DataGrid, {
                  ref: "gridRef",
                  refInFor: true,
                  staticClass: "mt-2",
                  attrs: {
                    "bulk-operations": "",
                    getData: _setup.currencyUseGetData[currency.unit],
                    headers: _setup.headers,
                    noDataText: _setup.caseHasAddressesInAnyCurrency
                      ? `No ${currency.name} Addresses`
                      : "No Addresses",
                    item_id: "id",
                    "footer-props": {
                      "items-per-page-options": [10, 30, 50, -1],
                      "show-current-page": true,
                      showFirstLastPage: true,
                    },
                    "hide-footer-if-no-data": "",
                    "hide-header-if-no-data": "",
                    "computed-height": _setup.caseHasAddressesInAnyCurrency,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header-right",
                        fn: function ({
                          getItems,
                          refreshItems,
                          removeSelection,
                          patchActions,
                          isAnyItemSelected,
                          isDataPatched,
                        }) {
                          return [
                            _c(_setup.DataGridBulkDelete, {
                              attrs: {
                                disabled: !isAnyItemSelected,
                                "get-items": getItems,
                                currencyUnit: currency.unit,
                                "item-callback-async": _setup.deleteItems,
                                title: "Remove Addresses",
                              },
                              on: {
                                "action-finished-event": () => {
                                  refreshItems()
                                  removeSelection()
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "td",
                              [
                                _c(_setup.EntityLabel, {
                                  attrs: {
                                    "act-as-link": "",
                                    currency: item.crypto,
                                    "is-copyable": "",
                                    entity: item.address,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "vertical-align": "middle",
                                },
                              },
                              [
                                _c(_setup.BlockchainCryptoBadge, {
                                  attrs: { crypto: item.crypto, iconWidth: 24 },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-overflow" },
                              _vm._l(item.identities, function (identity) {
                                return _c(
                                  "v-chip",
                                  { key: identity.id, attrs: { small: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(identity.label) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-overflow" },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "" } },
                                  [
                                    _vm._l(
                                      item.categories.slice(0, 2),
                                      function (cat) {
                                        return _c(_setup.AddressCategoryIcon, {
                                          key: cat.id,
                                          staticClass: "mr-2",
                                          attrs: {
                                            color: cat.color,
                                            iconItem: cat,
                                            name: cat.name,
                                            small: "",
                                          },
                                        })
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      _setup.BaseButtonShowMoreItems,
                                      {
                                        attrs: {
                                          items: item.categories,
                                          shownItemsCount: 2,
                                          buttonText: "show all",
                                        },
                                      },
                                      _vm._l(item.categories, function (cat) {
                                        return _c(_setup.AddressCategoryIcon, {
                                          key: cat.id,
                                          staticClass: "mx-1",
                                          attrs: {
                                            color: cat.color,
                                            iconItem: cat,
                                            name: cat.name,
                                            medium: "",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-overflow" },
                              [
                                _c(_setup.TableItemsExpandable, {
                                  attrs: {
                                    items: item.owners,
                                    item_id: "id",
                                    "shown-items-amount": 1,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ item, isInMenu }) {
                                          return [
                                            _c(_setup.OwnerBadge, {
                                              class: { "mx-1 mb-1": isInMenu },
                                              attrs: { item: item },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-overflow" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDateTime")(item.created_at)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }