var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tabs",
    { staticClass: "mt-5", attrs: { color: "tertiary" } },
    [
      _c(
        "v-tab",
        [
          _c("v-icon", { attrs: { left: "", size: "20" } }, [
            _vm._v("date_range"),
          ]),
          _vm._v("\n        Activity\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tab",
        [
          _c("v-icon", { attrs: { left: "", size: "18" } }, [
            _vm._v("fa fa-arrow-right-arrow-left"),
          ]),
          _vm._v("\n        Transactions\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tab",
        { attrs: { disabled: !_vm.nodeLightningNetworkId } },
        [
          _c(
            "v-icon",
            {
              staticClass: "tab-lightning-icon",
              attrs: { left: "", color: "primary" },
            },
            [_vm._v("mdi-flash")]
          ),
          _vm._v("\n        LN Channels\n        "),
          _vm.nodeLightningNetworkId && _vm.nodeLightningNetworkChannelsLoading
            ? _c("v-skeleton-loader", {
                staticClass: "badgeLoading",
                attrs: { type: "heading" },
              })
            : _c("v-badge", {
                attrs: {
                  inline: "",
                  content: _setup.numberFormatCompact(
                    _vm.nodeLightningNetworkId
                      ? _vm.nodeLightningNetworkChannels?.length
                      : 0
                  ),
                },
              }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tab",
        { attrs: { disabled: !_vm.nodeTorData } },
        [
          _c("img", {
            staticClass: "mr-2 tab-onion-icon",
            staticStyle: { width: "24px", "margin-bottom": "6px" },
            style: {
              filter: _vm.nodeTorData
                ? ""
                : "invert(39%) sepia(5%) saturate(0%) hue-rotate(137deg) brightness(88%) contrast(76%)",
            },
            attrs: { src: "/img/ico/tor-onion.svg", alt: "Tor Onion" },
          }),
          _vm._v("\n        Tor Activity\n        "),
          _vm.nodeTorDataLoading
            ? _c("v-skeleton-loader", {
                staticClass: "badgeLoading",
                attrs: { type: "heading" },
              })
            : _c("v-badge", {
                attrs: {
                  inline: "",
                  content: _setup.numberFormatCompact(
                    _vm.nodeTorData?.length ?? 0
                  ),
                },
              }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tab-item",
        [_c(_setup.PeerActivitiesTable, { attrs: { peerId: _vm.peerId } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tab-item",
        { attrs: { eager: "" } },
        [_c(_setup.PeerTransactionList, { attrs: { peerId: _vm.peerId } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tab-item",
        [
          _c(_setup.NodeLightningNetworkDataTable, {
            attrs: { data: _vm.nodeLightningNetworkChannels },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tab-item",
        [
          _c(_setup.NodeTorDataTable, {
            attrs: {
              address: _vm.peerDetail.node.host,
              "node-tor-data": _vm.nodeTorData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }