<script setup>

import {ref} from "vue";

const dialog = ref()
const textModel = ref()
const multiLine = ref(false)
const labelText = ref(false)

let promiseResolve
let promiseReject

const Open = (text, multilineMode = false, label = "Label text") => {
    textModel.value = text
    dialog.value = true
    labelText.value = label
    multiLine.value = multilineMode
    return new Promise((resolve, reject) => {
        promiseResolve = resolve
        promiseReject = reject
    });
}

const onEnterPress = () => {
    promiseResolve(textModel.value)
    dialog.value = false
}

const onEscPress = () => {
    promiseResolve(false)
    dialog.value = false
}

defineExpose({
    Open
})

</script>

<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
            style="z-index: 9999"
            overlay-opacity="0"
        >
            <v-card color="white" outlined rounded class="pa-3">
                <v-text-field
                    v-if="!multiLine"
                    v-model="textModel"
                    :label="labelText"
                    @keydown.enter="onEnterPress"
                    @keydown.esc="onEscPress"
                    autofocus
                    color="info"
                >
                </v-text-field>
                <v-textarea
                    v-if="multiLine"
                    v-model="textModel"
                    :label="labelText"
                    @keydown.enter.exact="onEnterPress"
                    @keydown.esc="onEscPress"
                    autofocus
                    color="info"
                >
                </v-textarea>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>

</style>