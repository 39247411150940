<template>
    <BaseModalDialog
        :title="isEditModal ? 'Edit graph' : 'Create new graph'"
        @load-modal-state-event="loadState"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-text-field-validateable
                    rules="required"
                    v-model="detail.name"
                    label="Name"
                    outlined
                />

                <v-textarea-validateable
                    outlined
                    label="Description"
                    v-model="detail.description"
                    rules="required|max:255"
                />
                <BlockchainCryptoPicker
                    v-model="detail.crypto"
                    :currencies="currencies"
                    rules="required"
                    :disabled="isEditModal"
                />
                <v-row>
                    <v-col cols="12" class="d-flex align-center">
                        <v-checkbox class="mt-0 mb-n4" v-model="detail.is_hidden"/>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-icon
                                    :color="detail.is_hidden ? 'primary' : 'tertiary'"
                                    dark
                                    large
                                    v-on="on"
                                >{{ detail.is_hidden ? "visibility_off" : "visibility" }}
                                </v-icon>
                            </template>
                            <span>Graph is {{
                                    detail.is_hidden ? 'visible only to the creator.' : 'visible to all users.'
                                }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </ValidationObserver>
        </template>
        <template #footer="{close, setDisabled}">
            <v-btn
                dark
                outlined
                color="error"
                :disabled="graphsModalState === modalState.Pending"
                @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                dark
                color="success"
                :loading="graphsModalState === modalState.Pending"
                outlined
                @click="save(close, setDisabled)"

            >{{ isEditModal ? "Save graph" : "Create graph" }}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import {deepClone} from "@/utils"
import {BaseModalDialog, BlockchainCryptoPicker} from "@/components/common"
import {mapGetters} from "vuex";
import {modalState} from "@/components/common/types/modal";

export default {
    name: "GraphDetailModal",
    components: {
        BaseModalDialog,
        BlockchainCryptoPicker
    },
    props: {
        graphDetail: {
            type: Object
        },
        currencies: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            detail: undefined,
            isEditModal: true,
            closeDialog: null,
            setDisabled: null,
        }
    },
    computed: {
        ...mapGetters(['graphsModalState']),
        modalState() {
            return modalState
        }

    },
    watch: {
        graphsModalState(newVal) {
            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('GRAPHS_MODAL_SET_STATE', modalState.Initial)
            } else if (newVal === modalState.Error)
            {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('GRAPHS_MODAL_SET_STATE', modalState.Initial)
            }
        }
    },
    methods: {
        async save(close, setDisabled) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.closeDialog = close
                this.setDisabled = setDisabled
                setDisabled(true)
                this.$emit("save-event", { graphDetail: this.detail })
            }

        },
        loadState() {
            if (this.graphDetail) {
                this.isEditModal = true
                this.detail = deepClone(this.graphDetail)
            }
            else {
                this.isEditModal = false
                this.detail = {
                    name: "",
                    description: "",
                    crypto: "",
                    is_hidden: true
                }
            }
        }
    }
}
</script>
