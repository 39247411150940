var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.graphObject.description === "Interactive Graph"
    ? _c(
        "router-link",
        {
          staticClass: "link",
          attrs: {
            to: { name: "GraphExplorer", params: { id: _vm.graphObject.id } },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.graphObject.name) + "\n")]
      )
    : _c(
        "router-link",
        {
          staticClass: "link fontMonospace",
          staticStyle: { "min-width": "150px" },
          attrs: {
            to: {
              name: "GraphSingleView",
              params: {
                graphId: _vm.graphObject.id,
                currency: _vm.graphObject.crypto,
              },
              query: {
                transactionNetworkIdToAppend: _vm.transactionNetworkIdToAppend,
              },
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.graphObject.name) + "\n")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }