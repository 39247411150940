<script setup>

import {computed, ref, watch} from "vue";
import store from "@/store";
import {getAddressBalanceAndDirectionForTransaction, processTx} from "@/utils";
import UseGEStore from "@/views/GraphExplorer/Composables/UseGEStore";
import {GERequestAction} from "@/store/modules/GraphExplorer";
import TransactionsPaginatedList from "@/components/transaction/Components/TransactionsPaginatedList.vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {DataState} from "@/components/common/types/data";

const {GESelectedElement, GETransactions} = UseGEStore()
const addressId = computed(() => GESelectedElement.value.data?.typeSpecific.address)
const transactions = ref([])

const getData = useGetData('getAddressTransactions', {
    params: {
        address: addressId.value,
        currencyUnit: "BTC"
    },
    pagination: {
        itemsPerPage: 15
    }
}, false)

const loading = computed(() => getData.dataState === DataState.Initial || getData.dataState === DataState.Loading)
watch(addressId, async () => {
    if (!addressId.value)
        return

    getData.getData({
            params: {
                address: addressId.value,
                currencyUnit: "BTC"
            },
            pagination: {
                itemsPerPage: 15
            }
    }, true)
}, {immediate: true})

const onCheckboxChange = (action, tx) => {
    if (action) {
        store.dispatch('GERequestAction', {
            actionType: GERequestAction.TxAddition,
            payload: {tx},
            invoker: GESelectedElement.value
        })
    } else {
        store.dispatch('GERequestAction', {
            actionType: GERequestAction.TxRemoval,
            payload: {txId: tx.txhash},
            invoker: undefined
        })
    }
}

store.dispatch('selectCurrency', {currencyUnit: 'BTC'})

</script>

<template>
    <div>

        <v-container class="px-7 mt-0 pt-1" fluid>
            <router-link
                class="text-h6 link"
                :to="{ name: 'AddressSummary', params: {currency: 'BTC', address: addressId}}"
                target="_blank"
            >
                <span class="fontMonospace">
                    {{ addressId }}
                </span>
            </router-link>
            <v-progress-linear :active="loading" height="4px" indeterminate style="width: 100%"/>
            <TransactionsPaginatedList
                :tx-map-function="(tx => {
                    const {balance} = getAddressBalanceAndDirectionForTransaction(addressId, tx)

                    return {
                        ...processTx(tx, addressId), currentAddressBalance: balance
                }})"
                :get-data="getData"
                selectable-transactions
                @selectedTransactionsChangedEvent="(event, tx) => onCheckboxChange(event, tx)"
            />
        </v-container>
    </div>
</template>

<style scoped>

</style>
