var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.ClusterCreateCustomDialog, {
        attrs: { disabled: _setup.loading, redirect: "" },
      }),
      _vm._v(" "),
      _c(
        _setup.ClusterMergeDialog,
        _vm._g(
          {
            attrs: {
              "currency-unit": _setup.data.currencyUnit,
              disabled: _setup.isMergeDisabled || _setup.loading,
            },
          },
          _setup.listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }