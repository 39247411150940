<template>
    <v-row no-gutters style="margin-top: auto">
        <v-col class="py-0">
            <div style="border-bottom: 1px #0000001F solid; height: 49px">
                <v-app-bar elevation="0" dense color="white">
                    <v-row>
                        <v-spacer />
                        <EntityHeaderLabel justify="center" :label="currency.unit.toUpperCase() + ' TRANSACTION:'" :entity="$route.params.transaction"/>
                        <v-spacer />
                    </v-row>
                </v-app-bar>
                <router-view />
            </div>
        </v-col>
    </v-row>
</template>


<script>
import EntityHeaderLabel from "@/views/Cluster/EntityHeaderLabel.vue";

export default {
    name: 'TransactionView',
    components: {EntityHeaderLabel},
    created() {
        this.$store.dispatch('loadTransaction', { id: this.$route.params.transaction, lightningNetworkEnrichmentEnabled: this.$store.getters.settings.server.lnQueryEnabled })
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.transaction !== from.params.transaction) {
            this.$store.dispatch('loadTransaction', { id: to.params.transaction })
        }
        next()
    },
    computed: {
        transaction() {
            return this.$store.getters.transactionInfo
        },
        currency() {
            return this.$store.getters.currency
        }
    },
}
</script>

