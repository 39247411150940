const getCyCenter = (cy) => ({
    x: (-cy.pan().x / cy.zoom()) + ((cy.width() / cy.zoom()) / 2),
    y: (-cy.pan().y / cy.zoom()) + ((cy.height() / cy.zoom()) / 2)
})

const createIcon = (name, size= '16px', prefix = 'fa', rotate = '0', theme = 'dark') => {
    const isFa = prefix === 'fa'

    return `<i class="${isFa ? 'fa-solid' : 'v-icon mdi'} ${prefix}-${name} ${isFa ? '' : `mdi-rotate-${rotate}`} theme--${theme}" style="font-size: ${size};"></i>`
}

export {
    getCyCenter,
    createIcon
}