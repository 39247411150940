<script setup>
import {useGetData} from "@/components/common/Composables/useGetData";
import {computed} from "vue";
import {DataState} from "@/components/common/types/data";

const props = defineProps({
    value: {
        required: true
    },
    propertyName: {
        type: String,
        required: false,
    }
})

const pagination = {
    page: 1,
    itemsPerPage: -1,
    sortDesc: [false]
}

const {
    dataState: usersDataState,
    data: usersData
} = useGetData("getUsers", {pagination: {...pagination}}, true, false, 1, true)
const users = computed(() => usersData.value?.data ?? [])

const loading = computed(() => usersDataState.value === DataState.Loading)

const value = computed(() => props.value)

const emit = defineEmits(['input'])

</script>

<template>
    <div>
        <v-autocomplete
            :items="users"
            :value="value"
            @input="emit('input', $event)"
            append-icon="search"
            item-text="name"
            item-value="id"
            label="User"
            hide-details
            single-line
            :loading="loading"
            clearable
            dense
            clear-icon="mdi-close-circle"
        />
    </div>
</template>

<style scoped>

</style>