var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "d-flex align-center width100" }, [
      _c("div", { staticClass: "width100" }, [_c("v-divider")], 1),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-grow-0 flex-shrink-0 mx-2" },
      [
        _c(
          "nobr",
          { staticClass: "d-flex" },
          [
            _vm._t("default", function () {
              return [
                _vm.icon
                  ? _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.icon) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(
                  "\n                " + _vm._s(_vm.text) + "\n            "
                ),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex align-center width100" }, [
      _c("div", { staticClass: "width100" }, [_c("v-divider")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }