var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex justify-center align-center" }, [
    _vm.isPrimary
      ? _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "disable-events my-1",
                staticStyle: { "font-size": "12px" },
                attrs: {
                  "min-width": "110px",
                  "x-small": "",
                  outlined: "",
                  elevation: "0",
                  color: _vm.currency.unit,
                },
              },
              [_vm._v("\n            Primary\n        ")]
            ),
          ],
          1
        )
      : _vm.$store.getters.hasPermission("write") && _vm.selectable
      ? _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "my-1 buttonShadow force",
                staticStyle: { "font-size": "12px", color: "#616161" },
                attrs: { "min-width": "110px", "x-small": "", outlined: "" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("set-primary-event", _vm.item.id)
                  },
                },
              },
              [_vm._v("\n            Set primary\n        ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }