var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-7 mt-0 pt-1 transactionContainer" },
        [
          _c(_setup.TransactionsHeader, { attrs: { "no-change": "" } }),
          _vm._v(" "),
          _c(_setup.TransactionSingle, {
            attrs: { transaction: _setup.transaction, expanded: "" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }