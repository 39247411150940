<template>
    <div class="jumbo secondary">
        <v-layout align-center justify-center fill-height style="background-color: #121212">
            <div>
                <div>
                    <v-card-title primary-title class="d-flex justify-center">
                        <div>
                            <div v-if="settings.app.brand_type === 'text'" style="color: white">
                                <h1 style="margin-bottom: 4px;">
                                    {{
                                        settings.app.name ?
                                        settings.app.name : "Login"
                                    }}
                                </h1>
                                <v-divider dark></v-divider>
                                <div class="img-container">
                                    <img v-show="settings.app.by_logo" src="/img/logos/by_netsearch_grey.svg" style="width: 120px;">
                                </div>
                            </div>
                            <div v-else>
                                <img :src="`/img/logos/${settings.app.logo}`" style="width: 220px">
                                <v-divider></v-divider>
                            </div>
                        </div>
                    </v-card-title>
                </div>
                <v-card-text class="text-center" style="color: grey">
                    <div>Authenticate yourself first prior to any work with this system!</div>
                    <div>All access is being authorized and accounted.</div>
                    <v-form v-model="valid" class="pt-3" ref="form">
                        <v-text-field dark autofocus v-model="email" :rules="emailRules" label="E-mail" required validate-on-blur />
                        <v-text-field dark :rules="passwordRules" v-model="password" :type="type" label="Password" required>
                            <template #append>
                                <v-icon small @click="toggleType"  tabindex="-1">{{ type === 'password' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye' }}</v-icon>
                            </template>
                        </v-text-field>
                        <v-checkbox
                            v-model="remember"
                            label="Remember me"
                            color="#1565C0"
                            dark
                            required
                        />
                        <v-layout row>
                            <div class="d-flex justify-center" style="width: 100%">
                                <v-btn
                                    dark
                                    color="#1565C0"
                                    :elevation="valid ? 1 : 0"
                                    style="width: 94%; color: white"
                                    :disabled="!valid"
                                    :loading="isUserLoading"
                                    @click="submit"
                                >Sign in</v-btn>
                            </div>
                            <v-spacer />
                        </v-layout>
                    </v-form>
                </v-card-text>
            </div>
        </v-layout>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "LoginView",
    computed: {
        ...mapGetters(["settings", "user", "isUserLoading"])
    },
    watch: {
        user(newValue) {
            //if user will be logged, redirect to page from query or to default page
            if (newValue) {
                if (this.$route.query.redirect && this.$route.path !== this.$route.query.redirect) {
                    this.$router.replace(this.$route.query.redirect)
                }
                else {
                    this.$router.push({ name: "UserProfile" })
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch("showBackgroundTasks", false);
    },
    created() {
        window.addEventListener('keypress', this.submit);
    },
    beforeDestroy() {
        window.removeEventListener('keypress', this.submit);
    },
    methods: {
        validate () {
            this.$refs.form.validate()
        },
        submit(e) {
            if (e.key)
            {
                if (e.key !== 'Enter') {
                    return
                }
            }
            this.validate()
            if (this.valid)
            {
                localStorage.setItem("remember", this.remember)
                if(this.remember) {
                    localStorage.setItem("email", this.email)
                } else localStorage.removeItem("email")
                this.$store.dispatch("userLogin", {
                    email: this.email,
                    password: this.password,
                    remember: this.remember
                });
            }
        },
        toggleType() {
            if(this.type === "password") {
                this.type = "text"
            } else {
                this.type = "password"
            }
        }
    },
    data() {
        return {
            valid: false,
            remember: window.localStorage.getItem("remember") === "true",
            password: "",
            email: window.localStorage.getItem("email") ? window.localStorage.getItem("email") : "",
            passwordRules: [v => v.length > 0 || 'Password is required'],
            emailRules: [
                v => !!v || "E-mail is required",
                v => /.+@.+/.test(v) || "E-mail must be valid"
            ],
            type: "password"
        };
    }
};
</script>

<style scoped>
.jumbo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

.img-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
}
</style>
