var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "d-flex align-center", attrs: { cols: "4" } },
        [
          _c(_setup.CryptoPicker, {
            staticClass: "my-3",
            staticStyle: { "max-width": "300px" },
            model: {
              value: _setup.defaultCurrency,
              callback: function ($$v) {
                _setup.defaultCurrency = $$v
              },
              expression: "defaultCurrency",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "d-flex align-center d-flex", attrs: { cols: "8" } },
        [
          _c("v-select", {
            staticClass: "fontMonospace",
            staticStyle: { "max-width": "300px" },
            attrs: {
              items: _setup.delimiterOptions,
              "item-value": "value",
              color: "info",
              dense: "",
              label: "Select Delimiter",
              "prepend-icon": "mdi-file-delimited",
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function ({ item }) {
                  return [
                    _c("b", [_vm._v(_vm._s(item.value))]),
                    _c("span", { staticStyle: { opacity: "0.6" } }, [
                      _vm._v(" (" + _vm._s(item.name) + ")"),
                    ]),
                  ]
                },
              },
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c("span", { staticClass: "fontMonospace" }, [
                      _c("b", [_vm._v(_vm._s(item.value))]),
                      _c("span", { staticStyle: { opacity: "0.6" } }, [
                        _vm._v(" (" + _vm._s(item.name) + ")"),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _setup.separator,
              callback: function ($$v) {
                _setup.separator = $$v
              },
              expression: "separator",
            },
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-checkbox", {
            attrs: {
              color: "info",
              dense: "",
              label: "Propagate metadata to co-spent clusters",
            },
            model: {
              value: _setup.propagateToClusters,
              callback: function ($$v) {
                _setup.propagateToClusters = $$v
              },
              expression: "propagateToClusters",
            },
          }),
          _vm._v(" "),
          !_setup.isCaseImport
            ? _c("v-checkbox", {
                staticClass: "ml-3",
                attrs: { color: "info", dense: "", label: "Skip identities" },
                model: {
                  value: _setup.skipIdentities,
                  callback: function ($$v) {
                    _setup.skipIdentities = $$v
                  },
                  expression: "skipIdentities",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-col", { attrs: { cols: "12" } }, [_c(_setup.FileFormatInfo)], 1),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                bottom: "",
                "offset-y": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mb-4",
                              attrs: {
                                disabled: _setup.tableItems.length !== 0,
                                color: "primary",
                                small: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(
                              "\n                        mdi-text-box-plus-outline\n                    "
                            ),
                          ]),
                          _vm._v(
                            "\n                    Add defaults\n                "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                _vm._l(
                  Object.keys(_vm.defaultRowValues),
                  function (keyName, index) {
                    return _c(
                      "v-list-item",
                      { key: index },
                      [
                        _c("v-text-field", {
                          staticClass: "fontMonospace",
                          attrs: {
                            placeholder: _setup.capitalize(keyName),
                            dense: "",
                            clearable: "",
                            "clear-icon": "mdi-close-circle",
                          },
                          model: {
                            value: _vm.defaultRowValues[keyName],
                            callback: function ($$v) {
                              _vm.$set(_vm.defaultRowValues, keyName, $$v)
                            },
                            expression: "defaultRowValues[keyName]",
                          },
                        }),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(_setup.CSVImporter, {
            attrs: { separator: _setup.separator },
            on: { "file-selected-event": _setup.onImportRows },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-end", staticStyle: { gap: "8px" } },
            [
              _c(_setup.ActionConfirmDialog, {
                ref: "confirmResetDialogRef",
                attrs: {
                  "on-confirm-action-callback": _setup.resetState,
                  "content-text": "You are about to reset the address table.",
                },
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _setup.tableItems.length === 0,
                    color: "error",
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      return _setup.confirmResetDialogRef.open()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("\n                    delete\n                "),
                  ]),
                  _vm._v("\n                Reset Table\n            "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", small: "" },
                  on: {
                    click: () => {
                      _setup.addEmptyEntry()
                      _setup.AddressTableEditableRef.getDataAndResetPage()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("\n                    add_box\n                "),
                  ]),
                  _vm._v("\n                Add entry\n            "),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(_setup.AddressTableEditable, {
            ref: "AddressTableEditableRef",
            attrs: {
              "table-items": _setup.tableItems,
              "identities-headers": _vm.identitiesHeaders,
              loading: _setup.loading,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }