var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex sideBarMenu" },
    [
      _c(
        "v-navigation-drawer",
        { attrs: { miniVariant: _setup.compact, permanent: "" } },
        [
          _c(
            "v-list",
            {
              staticClass: "d-flex flex-column menu-list",
              staticStyle: { height: "100%" },
              attrs: { dense: "", nav: "" },
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        "max-width": "40",
                                        "min-width": "40",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _setup.menuSizeChange.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { staticStyle: { "font-size": "20px" } },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _setup.compact
                                              ? "mdi-chevron-double-right"
                                              : "mdi-chevron-double-left"
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_setup.compact ? "Expand" : "Compact")),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_setup.menuItems, function (item) {
                return [
                  item.currencies &&
                  _vm.$store.getters.hasPermission("blockChain")
                    ? _c(
                        "v-list-group",
                        {
                          staticClass: "currenciesGroup",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          disabled: !_setup.compact,
                                          right: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-list-item-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "mt-0 mb-0",
                                                          staticStyle: {
                                                            flex: "0",
                                                            height: "32px",
                                                          },
                                                        },
                                                        "v-list-item-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "20px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-currency-sign"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-title",
                                                    _vm._g(
                                                      _vm._b(
                                                        {},
                                                        "v-list-item-title",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "Blockchain\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Blockchain")]),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _vm._l(item.currencies, function (curr, i) {
                            return _c(
                              "v-tooltip",
                              {
                                key: i + curr.unit,
                                attrs: { disabled: !_setup.compact, right: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "my-1",
                                                  staticStyle: {
                                                    flex: "0",
                                                    "min-height": "32px",
                                                    height: "32px",
                                                  },
                                                  attrs: {
                                                    to: "/" + curr.unit,
                                                    color: "tertiary",
                                                  },
                                                },
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                {
                                                  staticClass:
                                                    "d-flex justify-start",
                                                  staticStyle: {
                                                    "margin-top": "6px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "20px",
                                                      height: "20px",
                                                    },
                                                    attrs: {
                                                      alt: `${curr.name} icon`,
                                                      src: curr.image,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(_vm._s(curr.name)),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(curr.name))]),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : item.divider
                    ? _c("v-divider", { staticClass: "mb-1" })
                    : item.spacer
                    ? _c("v-spacer", { staticClass: "flex-column" })
                    : _setup.getRenderCondition(
                        item.permissionString,
                        item.isDebugFeature
                      )
                    ? _c(
                        "v-tooltip",
                        {
                          key: item.text,
                          attrs: { disabled: !_setup.compact, right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticStyle: {
                                              flex: "0",
                                              "min-height": "32px",
                                            },
                                            attrs: {
                                              href: item.href,
                                              target: item.href
                                                ? "_blank"
                                                : "_self",
                                              to: item.routeName
                                                ? { name: item.routeName }
                                                : item.route,
                                              color: "tertiary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                item.logout
                                                  ? _setup.logout()
                                                  : undefined
                                              },
                                            },
                                          },
                                          "v-list-item",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        item.imgPath
                                          ? _c(
                                              "v-list-item-icon",
                                              {
                                                staticClass:
                                                  "d-flex justify-start mt-1",
                                                staticStyle: {
                                                  "border-radius": "0",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "imgIcon",
                                                  staticStyle: {
                                                    width: "20px",
                                                  },
                                                  attrs: {
                                                    alt: item.imgAlt,
                                                    src: item.imgPath,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "v-list-item-icon",
                                              { staticClass: "mt-1" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.icon))]
                                                ),
                                              ],
                                              1
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(item.text)),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        item.appendIcon
                                          ? _c(
                                              "v-list-item-icon",
                                              {
                                                staticClass: "mt-1 justify-end",
                                                staticStyle: {
                                                  "margin-right":
                                                    "4px !important",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                      opacity: "0.6",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          item.appendIcon
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_vm._v(" "), _c("span", [_vm._v(_vm._s(item.text))])]
                      )
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex justify-center",
                  class: { "fade-in-transition": !_setup.compact },
                  staticStyle: { height: "12px" },
                },
                [
                  !_setup.compact
                    ? _c(
                        "div",
                        {
                          staticClass: "monospace d-flex text-no-wrap",
                          staticStyle: { opacity: "0.8", "font-size": "12px" },
                        },
                        [
                          _vm._v(
                            "\n                    version " +
                              _vm._s(_setup.currentAppVersion) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }