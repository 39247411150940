var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "ml-1 categories-count-container d-flex align-center",
      style: _vm.reserveSpace && { "min-width": "34px" },
    },
    [
      _c(
        "v-menu",
        {
          attrs: {
            auto: "",
            right: "",
            "offset-y": "",
            "close-on-content-click": false,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-chip",
                    _vm._g(
                      _vm._b(
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.items.length &&
                                _vm.items.length > _vm.shownItemsCount,
                              expression:
                                "items.length && items.length > shownItemsCount",
                            },
                          ],
                          staticClass: "link px-2",
                          staticStyle: { cursor: "pointer" },
                          attrs: { small: "", outlined: "" },
                        },
                        "v-chip",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.buttonText ??
                              `+${_vm.items.length - _vm.shownItemsCount}`
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            {
              staticStyle: {
                "overflow-y": "auto",
                "background-color": "#EEEEEE",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column align-left" },
                [_vm._t("default")],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }