<script setup>
import {useGetData} from "@/components/common/Composables/useGetData";
import {processTx} from "@/utils";
import {useRoute} from "vue-router/composables";
import TransactionsPaginatedList from "@/components/transaction/Components/TransactionsPaginatedList.vue";

const props = defineProps({
    peerId: {
        type: Number,
        required: false
    }
})

const route = useRoute()
const getDataObject = useGetData('getNodeTransactions', {
    currency: route.params.currency,
    peerId: props.peerId,
    pagination: {page: 1, itemsPerPage: 5}
})

const convertNumberToOrdinal = (number) => {
    if (number === 1) {
        return 'first'
    } else if (number === 2) {
        return 'second'
    } else {
        return 'third'
    }
}
const convertNumberToRanking = (order) => {
    if (order === 1) {
        return 'gold'
    } else if (order === 2) {
        return 'silver'
    } else {
        return 'bronze'
    }
}

</script>

<template>
    <div class="mt-2">
        <TransactionsPaginatedList
            :get-data="getDataObject"
            :itemsPerPageDefault="5"
            :tx-map-function="(tx => processTx(tx, ''))"
            hide-timeline
            hide-total
            hideTopPagination
        >
            <template #prependTransaction="{ transaction }">
                <v-row class="no-gutters justify-space-between">
                    <v-col class="no-gutters" cols="7">
                        <div class="d-flex">
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <div class="d-flex font-weight-bold justify-start align-end" v-on="on">
                                        <div style="margin: 0 4px -4px 0; font-size: 1.2em">
                                            {{ transaction.peerTxPropagations.order }}.
                                        </div>
                                        <v-icon>{{
                                                `mdi-podium-${convertNumberToRanking(transaction.peerTxPropagations.order)}`
                                            }}
                                        </v-icon>
                                    </div>
                                </template>
                                <span>This node was the {{
                                        convertNumberToOrdinal(transaction.peerTxPropagations.order)
                                    }} one to propagate this transaction.</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                    <v-col class="d-flex no-gutters justify-end" cols="2">
                        <v-col class="d-flex no-gutters justify-end" cols="10" style="padding: 0">
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <div class="d-flex font-weight-bold align-end text-no-wrap" v-on="on">
                                        <v-icon left>mdi-eye</v-icon>
                                        <div>{{ transaction.peerTxPropagations.received_at | formatDateTime }}</div>
                                    </div>
                                </template>
                                <span>Timestamp of when this node first saw this transaction.</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="2">
                        </v-col>
                    </v-col>
                </v-row>
            </template>
            <template #noDataText>
                This node hasn't propagated any transactions as of yet.
            </template>
        </TransactionsPaginatedList>
    </div>
</template>

<style scoped>

</style>