var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseModalDialog, {
    ref: "modalRef",
    attrs: { title: "Warning" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("v-icon", { attrs: { small: "" } }, [
              _vm._v("fa fa-triangle-exclamation"),
            ]),
            _vm._v(" "),
            _c("span", {
              domProps: { innerHTML: _vm._s(_setup.warningMessage) },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function ({ close }) {
          return [
            _c(
              "v-btn",
              {
                attrs: { color: "success", dark: "", small: "" },
                on: { click: () => _setup.handleClose(close) },
              },
              [_vm._v("I Understand\n        ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }