<template>
    <v-container class="px-4" fluid>
        <v-layout align-center>
            <h1>Categories</h1>
            <v-spacer/>
            <CategoryDetailModal @save-event="createCategory">
                <template #showbutton="{on}">
                    <CreateButton v-on="on" text="Add new category"/>
                </template>
            </CategoryDetailModal>
        </v-layout>
        <v-divider/>
        <v-divider/>
        <v-data-table
            :headers="headers"
            :items="categories"
            :options.sync="pagination"
            :server-items-length="categoriesPagination.total"
            :loading="isCategoriesLoading"
            :items-per-page="30"
            :footer-props="{
                'items-per-page-options': [30,50,100],
                'show-current-page': true,
                'showFirstLastPage': true,
            }"
            dense
            class="elevation-0 border my-2"
        >
            <template #item="{ item }">
                <tr>
                    <td class="text-left">
                        <span>{{ item.name }}</span>
                    </td>
                    <td>
                        <v-chip
                            style="height: 26px"
                            class="my-1"
                            :color="item.color"
                            :text-color="item.secondaryColor"
                        >
                            {{ item.color }}
                        </v-chip>
                    </td>
                    <td>
                        <v-chip
                            style="height: 26px"
                            class="my-1"
                            :color="item.secondaryColor"
                            :text-color="item.color"
                        >
                            {{ item.secondaryColor }}
                        </v-chip>
                    </td>
                    <td>
                        <AddressCategoryIcon medium :iconItem="item"/>
                    </td>
                    <td>
                        <CategoryBadge :iconItem="item" class="ml-2"/>
                    </td>
                    <td width="10%">
                        <CategoryDetailModal @save-event="editCategory" :categoryDetail="item">
                            <template #showbutton="{on}">
                                <TableEditButton v-on="on" editButtonTooltip="Edit category"/>
                            </template>
                        </CategoryDetailModal>
                        <TableDeleteModalDialog
                            :description="`Do you really want to delete category ${item.name}`"
                            :itemToDelete="item"
                            @delete-event="deleteCategory"
                            tooltip="Delete category"
                        />
                    </td>
                </tr>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                <span>
                    {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                </span>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex"
import {CategoryBadge, CreateButton, TableDeleteModalDialog, TableEditButton} from "@/components/common"
import CategoryDetailModal from "@/components/AddressMetadata/CategoryDetailModal"
import debounce from "debounce";
import AddressCategoryIcon from "@/components/address/AddressCategoryIcon";

export default {
    name: "CategoriesView",
    components: {
        AddressCategoryIcon,
        TableEditButton,
        TableDeleteModalDialog,
        CreateButton,
        CategoryBadge,
        CategoryDetailModal
    },
    computed: {
        ...mapGetters([
            "categories",
            "isCategoriesLoading",
            "categoriesPagination",
        ])
    },
    data() {
        return {
            pagination: {
                page: 1,
                itemsPerPage: 30,
                sortBy: ["id"],
                sortDesc: [true],
            },
            headers: [
                {align: 'left', text: 'Name', value: 'name'},
                {align: 'center', text: 'Primary Color', value: 'color'},
                {align: 'center', text: 'Secondary Color', value: 'secondaryColor'},
                {align: 'center', text: 'Badge', value: '', sortable: false},
                {align: 'center', text: 'Icon', value: '', sortable: false},
                {align: 'center', text: 'Action', value: '', sortable: false}
            ]
        }
    },
    watch: {
        pagination: {
            handler() {
                this.debouncePagination();
            },
            deep: true
        }
    },
    methods: {
        async deleteCategory({itemToDelete}) {
            await this.$store.dispatch('deleteCategory', {categoryId: itemToDelete.id})
            this.refresh()
        },
        async editCategory({categoryDetail}) {
            await this.$store.dispatch("editCategory", {categoryDetail})
            this.refresh()
        },
        async createCategory({categoryDetail}) {
            await this.$store.dispatch("createCategory", {categoryDetail})
            this.refresh()
        },
        refresh() {
            this.$store.dispatch('loadCategories', {pagination: this.pagination})
        },
        debouncePagination: debounce(function () {
            this.refresh()
        }, 450)
    }
}
</script>