<script setup>
import {computed, ref} from "vue";
import store from "@/store";
import {stringMiddleShortener} from "@/utils/filters";
import sankey from "highcharts/modules/sankey"
import {Chart} from "highcharts-vue"
import Highcharts from "highcharts"

sankey(Highcharts)

const props = defineProps({
    transactionInfo: {
        type: Object,
        required: false
    }
})

const currency = computed(() => store.getters.currency)

const showDiagram = ref()

const isDiagramViable = computed(() => {
    return !props.transactionInfo.isCoinbase
        && (Math.max(props.transactionInfo.inputs.length, props.transactionInfo.outputs.length) <= 100
            && (props.transactionInfo.inputs.length > 1 || props.transactionInfo.outputs.length > 1))
})

const chartData = computed(() => {
    const fee = {
        value: props.transactionInfo.sumOfFeeValues,
        addr: "Fee",
        color: {
            linearGradient: {x1: 0, x2: 1, y1: 0, y2: 0},
            stops: [
                [0.7, "#bdbcd7"],
                [0.9, "red"],
                [1, "red"]
            ]
        }
    }
    const inputs = props.transactionInfo.inputs
    const outputsAndFee = [...props.transactionInfo.outputs, fee]
    const allInputValuesSum = props.transactionInfo.inputs.reduce((partialSum, input) => partialSum + input.value, 0)
    //magicNumber - defining proportion between links,
    // e.g. allows the largest link to be "magicNumber" times bigger than the smallest one
    const magicNumber = 50
    const inputsArray = inputs.map((input, index) => (
        {
            from: "input" + "/" + index + 1,
            to: "Tx",
            weight: (input.value + allInputValuesSum / magicNumber) / (allInputValuesSum + inputs.length * allInputValuesSum / magicNumber),
            txIndex: index + 1,
            txValue: input.value,
            type: "Input",
            txId: stringMiddleShortener(input.addr, 20),
            id: `input/${input.n}${input.value}${input.addr}`,
            currencyUnit: currency.value.unit,
            color: input.categories.length > 0 ? {
                linearGradient: {x1: 0, x2: 1, y1: 0, y2: 0},
                stops: [
                    [0, input.categories[0].color],
                    [0.5, input.categories[0].color],
                    [1, "#bdbcd7"]
                ]
            } : "#bdbcd7"
        }
    ))
    const getColor = (output) => {
        if (output.categories && output.categories.length > 0 && output.is_change) {
            return {
                linearGradient: {x1: 0, x2: 1, y1: 0, y2: 0},
                stops: [
                    [0, "#bdbcd7"],
                    [0.5, output.categories[0].color],
                    [0.7, output.categories[0].color],
                    [0.9, "#72c25e"],
                    [1, "#72c25e"]
                ]
            }
        } else if (output.categories && output.categories.length > 0) {
            return {
                linearGradient: {x1: 0, x2: 1, y1: 0, y2: 0},
                stops: [
                    [0, "#bdbcd7"],
                    [0.5, output.categories[0].color],
                    [1, output.categories[0].color],
                ]
            }
        } else if (output.addr === "Fee") {
            return output.color
        } else if (output.is_change) {
            return {
                linearGradient: {x1: 0, x2: 1, y1: 0, y2: 0},
                stops: [
                    [0.85, "#bdbcd7"],
                    [0.95, "#72c25e"],
                    [1, "#72c25e"]
                ]
            }
        } else if (output.is_lightning_network_channel_opener) {
            return {
                linearGradient: {x1: 0, x2: 1, y1: 0, y2: 0},
                stops: [
                    [0.85, "#bdbcd7"],
                    [0.95, "#ffc001"],
                    [1, "#ffc001"]
                ]
            }
        } else {
            return "#bdbcd7"
        }
    }
    const outputsArray = outputsAndFee.map((output, index) => (
        {
            from: "Tx",
            to: output.addr === "Fee" ? `Fee` : "output" + "/" + index + 1,
            weight: (output.value + allInputValuesSum / magicNumber) / (allInputValuesSum + (outputsAndFee.length) * allInputValuesSum / magicNumber),
            txIndex: index + 1,
            txValue: output.value,
            type: "Output",
            txId: stringMiddleShortener(output.addr, 20),
            id: output.addr === "Fee" ? `${output.value}${props.transactionInfo.time}` : `output/${output.n}${output.value}${output.addr}`,
            currencyUnit: currency.value.unit,
            color: getColor(output)
        }
    ))
    return inputsArray.concat(outputsArray)
})

const sankeyChartOptions = computed(() => {
    let timeout;
    return {
        chart: {
            height: 100 + (12 * (Math.max(props.transactionInfo.inputs.length, props.transactionInfo.outputs.length)))
        },
        title: {
            text: "",
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        accessibility: {
            point: {
                valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
            }
        },
        tooltip: {
            animation: false,
        },
        series: [{
            keys: ['index', 'from', 'to', 'weight', 'outgoing'],
            title: "",
            cursor: "pointer",
            data: chartData.value,
            dataLabels: {
                enabled: false,
            },
            type: 'sankey',
            name: "",
            curveFactor: 0.5,
            nodeWidth: 0,
            nodePadding: 6,
            linkOpacity: 1,
            minLinkWidth: 0,
            colorByPoint: false,
            events: {
                click: function (event) {
                    if (event.point.to === "Fee") return
                    let row = document.getElementById(event.point.id)
                    if (!row && event.point.type === "Input") row = document.querySelectorAll('.v-data-footer')[0]
                    if (!row && event.point.type === "Output") row = document.querySelectorAll('.v-data-footer')[1]
                    row.scrollIntoView({behavior: "smooth", block: "center"})
                    row.classList.add("tempHighlight")
                    const runTimeout = () => {
                        timeout = setTimeout(() => {
                            row.classList.remove("tempHighlight")
                            timeout = null
                        }, 3000)
                    }
                    if (!timeout) {
                        runTimeout()
                    }

                }
            },
            color: "#bdbcd7",
            nodes: [{
                id: "Tx",
            }],

            tooltip: {
                followPointer: false,
                pointFormat:
                    "{point.type} <b>#{point.txIndex}</b><br/>" +
                    "{point.txValue} {point.currencyUnit}<br/>" +
                    "{point.txId}"
            },

            states: {
                hover: {
                    enabled: true,
                    color: "#bdbcd780",
                },
                inactive: {
                    enabled: false
                },
            }
        }]

    }
})

</script>

<template>
    <div>
        <div class="d-flex justify-start flex graphContainer">
            <div style="flex: 1">
                <slot name="headerLeft"></slot>
            </div>
            <v-btn
                class="ma-1 flex"
                text
                @click="showDiagram = !showDiagram"
                :disabled="!isDiagramViable"
            >
                <span v-if="!showDiagram">
                    <v-icon disabled x-small>fa-solid fa-chevron-down</v-icon>
                        Show Diagram
                    <v-icon disabled x-small>fa-solid fa-chevron-down</v-icon>
                </span>
                <span v-else>
                    <v-icon disabled x-small>fa-solid fa-chevron-up</v-icon>
                        Hide Diagram
                    <v-icon disabled x-small>fa-solid fa-chevron-up</v-icon>
                </span>
            </v-btn>
            <div class="d-flex align-center mr-4 blockHeightContainer" style="flex: 1">
                <slot name="headerRight"></slot>
            </div>
        </div>
        <div style="width: 100%">
            <v-expand-transition>
                <div v-if="showDiagram" class="d-flex flex-column" style="max-height: 1400px">
                    <Chart :options="sankeyChartOptions"/>
                </div>
            </v-expand-transition>
        </div>
    </div>
</template>

<style scoped>
.blockHeightContainer div {
    margin-left: auto;
}

</style>