<script setup>
import {ref} from "vue";

const props = defineProps({
    icon: {
        type: String,
        required: false,
    },
    name: {
        type: String,
        required: false
    },
    iconColor: {
        type: String,
        required: false
    },
    allSelected: {
        type: Boolean,
        required: false,
        default: false
    }
})

const expandedPanels = ref(0)

</script>

<template>
    <v-expansion-panels v-model="expandedPanels" class="advSearchCat" value="0">
        <v-expansion-panel class="advSearchCatPanel">
            <v-expansion-panel-header :value="true" style="padding: 8px; min-height: 32px; font-size: 14px;">
                <div>
                    <slot name="header.left">
                        <v-icon :color="iconColor" left>
                            {{ icon }}
                        </v-icon>
                        <span class="text-button">
                            {{ name }}
                        </span>
                        <v-chip v-if="allSelected" color="success" label outlined small>
                            ALL
                        </v-chip>
                    </slot>
                </div>
                <template v-slot:actions>
                    <slot name="header.right">
                        <v-icon color="primary">
                            $expand
                        </v-icon>
                    </slot>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <slot name="default"/>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<style lang="scss">

.advSearchCatPanel:hover {
    background-color: #eeeeee !important;
}

.advSearchCat .v-expansion-panel::before {
    box-shadow: none !important;
    color: blue;
}

.advSearchCat .v-expansion-panel {
    box-shadow: none !important;
}

.advSearchCat .v-expansion-panel::after {
    box-shadow: none !important;
}

</style>