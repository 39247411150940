var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-y height100" },
    [
      _c(
        "v-container",
        { staticClass: "height100 px-4", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            [_c("h1", [_vm._v("Roles")]), _vm._v(" "), _c("v-spacer")],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "elevation-0 border mt-2",
            attrs: {
              headers: _vm.headers,
              items: _vm.roles.data,
              loading: _vm.rolesLoading,
              options: _vm.filter,
              "server-items-length": _vm.roles.total ? _vm.roles.total : 0,
              dense: "",
              "footer-props": {
                "items-per-page-options": [
                  15,
                  30,
                  50,
                  { text: "All", value: -1 },
                ],
                "show-current-page": true,
                showFirstLastPage: true,
              },
            },
            on: {
              "update:options": function ($event) {
                _vm.filter = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c("tr", [
                      _c("td", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-overflow" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center text-right" },
                          [
                            _c("div", { staticStyle: { width: "30px" } }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm._f("formatNumber")(item.count)) +
                                  "\n                            "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-right text-overflow" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm._f("formatDateTime")(item.created_at)) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-right text-overflow" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm._f("formatDateTime")(item.updated_at)) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "footer.page-text",
                fn: function ({ pageStart, pageStop, itemsLength }) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("formatNumber")(pageStart)) +
                          " - " +
                          _vm._s(_vm._f("formatNumber")(pageStop)) +
                          " of " +
                          _vm._s(_vm._f("formatNumber")(itemsLength)) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }