<script setup>
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

defineProps({
    text: {
        type: String,
        default: "Upload"
    }
})
</script>

<template>
    <BaseDataModifyButton color="success" small v-bind="$attrs" v-on="$listeners">
        <v-icon left>save</v-icon>
        {{ text }}
    </BaseDataModifyButton>
</template>
