var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-icon",
                _vm._g(
                  {
                    attrs: {
                      color: _vm.value ? "primary" : "tertiary",
                      dark: "",
                    },
                  },
                  on
                ),
                [
                  _vm._v(
                    _vm._s(_vm.value ? "visibility_off" : "visibility") +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.value ? "Hidden" : "Visible"))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }