export interface ServerSidePagination {
    itemsPerPage: number;
    page: number;
}


function MapToServerSidePagination(localPagination): ServerSidePagination {
    let limitedPagination = {}

    if (localPagination.page)
        limitedPagination = {...limitedPagination, page: localPagination.page}

    if (localPagination.itemsPerPage)
        limitedPagination = {...limitedPagination, itemsPerPage: localPagination.itemsPerPage}

    return limitedPagination as ServerSidePagination
}

export default {MapToServerSidePagination}