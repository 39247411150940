<template>
    <BaseModalDialog
        width="80%"
        max-width="1200px"
        title='Monitoring Detail'
        @load-modal-state-event="loadState"
        @destroy-modal-state-event="destroyState"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>

        <template #content>
            <div>
                <div class="d-flex align-center interceptorParamsContainer">
                    <TextWithLabel label="IP Address" :text="ip" />
                    <TextWithLabel label="Port" :text="portComputed" />
                    <div  class="mx-4" style="width: 120px">
                        <div class="d-flex flex-column justify-start" style="height: 45px">
                            <div style="text-align: center;">
                                <span class="button mt-n1">Status</span>
                            </div>
                            <div style="text-align: center; margin-top: -4px;" >
                                <span v-if="['NOT_MONITORED', 'STOPPED', 'FAILED'].includes(state.status)" class="body-2 font-weight-bold" style="color: red;">{{state.status}}</span>
                                <span v-else-if="['CONNECTING','STARTING','STOPPING'].includes(state.status)" class="body-2 font-weight-bold" style="color: orange;">{{state.status}}</span>
                                <span v-else-if="['CONNECTED']" class="body-2 font-weight-bold" style="color: green;">{{state.status}}</span>
                                <span v-else class="body-2 font-weight-bold">{{state.status}}</span>
                            </div>
                            <v-progress-linear
                                v-if="['CONNECTING', 'STARTING', 'STOPPING'].includes(state.status)"
                                indeterminate
                                color="orange"
                            ></v-progress-linear>
                        </div>
                    </div >
                    <div class="mx-4 mb-2">
                        <CryptoInterceptorControlPanelSmall :ip="ip"
                                                            :port="portComputed"
                                                            @monitoring-start-event="onMonitorStartEvent"
                                                            @monitoring-stop-event="onMonitorStopEvent"
                                                            @peer-polling-start-event="onPeerStartPolling"
                                                            @state-change-event="getPeerFiles()"/>
                    </div>
                </div>
            </div>

            <v-divider/>

            <h2 class="mt-4">Captured <span class="font-italic">.pcap</span> files</h2>
            <nobr>
            <v-data-table
                :headers="headers"
                :items="peerFiles ? peerFiles.data : []"
                :server-items-length="peerFiles ? peerFiles.total : 0"
                :loading="peerFilesLoading"
                class="elevation-0 border mt-2"
                :no-data-text="'No .pcaps found'"
                dense
                :options.sync="pagination"
                @update:items-per-page="itemsPerPageUpdated"
                @update:page="pageUpdated"
                :footer-props="{
                    'items-per-page-options': [20, 50, 100]
                }">

                <template slot="item" slot-scope="props">
                    <tr>
                        <td class="text-center">
                            <div v-if="!props.item.is_complete">
                                <div class="pulsate font-weight-medium" style="color: green">Active!</div>
                            </div>
                            <div v-else>
                                Complete
                            </div>
                        </td>
                        <td class="text-overflow"> {{props.item.filename}}</td>
                        <td class="text-overflow"> {{props.item.created_at | formatDateTime }}</td>
                        <td class="text-right"> {{ (props.item.size / 1000) | formatNumber(2) }} KB</td>
                        <td class="text-oveflow">
                            <div class="d-flex justify-center">
                                <div>
                                    <ButtonWrapperHoverTooltip>
                                        <template #active="{ on, attrs }">
                                            <a style="text-decoration: none" :href="urlToFile(props.item)" target="_blank">
                                                <v-btn icon small v-on="on">
                                                    <v-icon color="blue">
                                                        mdi-download
                                                    </v-icon>
                                                </v-btn>
                                            </a>
                                        </template>
                                        <template #tooltip>
                                            Download
                                        </template>
                                        <template #alt>
                                            <v-btn icon small>
                                                <v-icon>
                                                    mdi-download
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                    </ButtonWrapperHoverTooltip>
                                </div>
                                <div>
                                    <TableDeleteModalDialog :disabled="!props.item.is_complete" fancy :item-to-delete="props.item" tooltip="Delete" @delete-event="deleteFile"/>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            </nobr>
        </template>

        <template #footer="{close}">
            <v-spacer />
            <v-btn
                class="mr-4"
                color="success"
                outlined
                @click="save(close)"
            >{{"Ok"}}</v-btn>
            <v-spacer />
        </template>

    </BaseModalDialog>
</template>

<script>
import BaseModalDialog from "@/components/common/Base/BaseModalDialog";
import CryptoInterceptorControlPanelSmall from "@/components/CryptoInterceptor/CryptoInterceptorControlPanelSmall";
import {mapGetters} from "vuex";
import ButtonWrapperHoverTooltip from "@/components/common/Buttons/ButtonWrapperHoverTooltip";
import TableDeleteModalDialog from "@/components/common/TableComponents/TableDeleteModalDialog";
import TextWithLabel from "@/components/common/TextWithLabel.vue";

export default {
    name: "CryptointerceptorDetailModal",
    components: {
        TextWithLabel,
        TableDeleteModalDialog,
        ButtonWrapperHoverTooltip,
        CryptoInterceptorControlPanelSmall,
        BaseModalDialog
    },
    props: {
        ip: {
            type: String,
            required: true
        },
        port: {
            type: Number,
            required: false
        }
    },
    data()
    {
        return {
            pagination: {page: 1, itemsPerPage: 20},
            headers: [
                { text: "Status", align: "center" ,value: "", sortable: false},
                { text: "Filename", align: "center", value: "filename"},
                { text: "Created At", align: "center" ,value: "created_at" },
                { text: "Size", align: "right" ,value: "size"},
                { text: "", align: "center" ,value: "", sortable: false},
            ],
        }
    },
    computed: {
        ...mapGetters(["currency","cryptoInterceptorPeersData","cryptoInterceptorPeerFilesLoading","cryptoInterceptorPeerFilesData"]),
        portComputed() {
            return this.port ?? this.state.port
        },
        peerFiles()
        {
            return this.cryptoInterceptorPeerFilesData[this.ip]
        },
        peerFilesLoading()
        {
            return !!this.cryptoInterceptorPeerFilesLoading[this.ip]
        },
        state()
        {
            if (this.cryptoInterceptorPeersData)
            {
                let element = this.cryptoInterceptorPeersData.data.find(x => x.ip === this.ip)
                if (element)
                    return  element
            }
            return {status: "NOT_MONITORED"}
        },
    },
    methods: {
        itemsPerPageUpdated() {
            this.getPeerFiles()
        },
        pageUpdated() {
            this.getPeerFiles()
        },
        loadState() {
            //Remove present data
            this.$store.commit("CRYPTOINTERCEPTOR_PEER_FILES_SET_DATA", {ip: this.ip, data: {}})
            //this.getPeerFiles()
        },
        async deleteFile(e)
        {
            this.$store.commit("CRYPTOINTERCEPTOR_PEER_FILES_DELETE_FILE",{ip: e.itemToDelete.ip, filename: e.itemToDelete.filename})
            await this.$store.dispatch("deleteCryptoInterceptorPeersFile", {currency: this.currency.unit, filename: e.itemToDelete.filename})
            this.getPeerFiles()
        },
        urlToFile(e)
        {
            return `${this.$store.getters.settings.server.jwtPrefix}/export/${this.currency.unit}/cryptointerceptor/pcaps/download?filterFilenames[]=${e.filename}`
        },
        getPeerFiles()
        {
            this.$store.dispatch("getCryptoInterceptorPeerFiles", {currency: this.currency.unit, filter: {...this.pagination, filterIps: [this.ip]}})
        },
        destroyState() {
        },
        save(close) {
            close()
        },
        onMonitorStartEvent(e)
        {
            this.$emit('monitoring-start-event', {ip: e.ip, port: e.port})
        },
        onMonitorStopEvent(e)
        {
            this.$emit('monitoring-stop-event', e)
        },
        onPeerStartPolling(e)
        {
            this.$emit('peer-polling-start-event', e)
        },
    },
    watch: {
        pagination: {
            handler()
            {
                this.getPeerFiles()
            },
            deep: true
        }

    }
}
</script>

<style scoped>
.v-text-field input {
    font-size: 0.5em !important;
}

.none_pointer_events {
    pointer-events: none;
}

.pulsate {
    -webkit-animation: pulsate 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0.5;
}
@-webkit-keyframes pulsate {
    0% {
        opacity: 0.7;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.7;
    }
}

.interceptorParamsContainer >>> .v-input__slot::before {
    border-style: none !important;
}

.interceptorParamsContainer >>> .v-input__slot::after {
    border-style: none !important;
}
</style>