<template>
    <div class="mt-2">
        <div class="d-flex justify-space-between">
            <div>
                <h3>Files</h3>
            </div>
            <div>
                <router-link :to="{ name: 'MonitoringNodes' }" style="text-decoration: none">
                    <v-btn color="grey darken-1" height="24" outlined small>
                        Nodes
                        <v-icon right small>
                            mdi-eye
                        </v-icon>
                    </v-btn>
                </router-link>
            </div>
        </div>

        <nobr>
            <v-data-table
                :footer-props="{
                    'items-per-page-options': [30, 50, 100],
                    'show-current-page': true,
                    'showFirstLastPage': true,
                }"
                :headers="headers"
                :items="cryptoInterceptorFilesData ? cryptoInterceptorFilesData.data : []"
                :loading="cryptoInterceptorFilesLoading"
                :loading-text="'Loading files ...'"
                :options.sync="filter"
                :server-items-length="cryptoInterceptorFilesData ? cryptoInterceptorFilesData.total : 0"
                class="elevation-0 border mb-2"
                dense>
                <template slot="item" slot-scope="props">
                    <tr>
                        <td class="text-center " style="width: 200px">
                            <div v-if="!props.item.is_complete">
                                <div class="pulsate" style="color: green;font-weight: 500">ACTIVE!</div>
                            </div>
                            <div v-else style="font-weight: 500">
                                COMPLETE
                            </div>
                        </td>
                        <td class="text-overflow text-left"> {{ props.item.filename }}</td>
                        <td class="text-overflow text-left fontMonospace"> {{ props.item.ip }}</td>
                        <td class="text-overflow"> {{ props.item.created_at | formatDateTime }}</td>
                        <td class="text-right"> {{ (props.item.size / 1000) | formatNumber(2) }} KB</td>
                        <td class="text-oveflow">
                            <div class="d-flex justify-center">
                                <div>
                                    <ButtonWrapperHoverTooltip>
                                        <template #active="{ on, attrs }">
                                            <a :href="urlToFile(props.item)" style="text-decoration: none"
                                               target="_blank">
                                                <v-btn icon small v-on="on">
                                                    <v-icon color="blue">
                                                        mdi-download
                                                    </v-icon>
                                                </v-btn>
                                            </a>
                                        </template>
                                        <template #tooltip>
                                            Download
                                        </template>
                                        <template #alt>
                                            <v-btn icon small>
                                                <v-icon>
                                                    mdi-download
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                    </ButtonWrapperHoverTooltip>
                                </div>
                                <div>
                                    <TableDeleteModalDialog :disabled="!props.item.is_complete"
                                                            :item-to-delete="props.item"
                                                            fancy tooltip="Delete"
                                                            @delete-event="deleteFile"/>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
                <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                    <span>
                        {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{
                            itemsLength | formatNumber
                        }}
                    </span>
                </template>
            </v-data-table>
        </nobr>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import CryptoInterceptorControlPanelSmall from "@/components/CryptoInterceptor/CryptoInterceptorControlPanelSmall";
import CryptointerceptorDetailModal from "@/components/CryptoInterceptor/CryptointerceptorDetailModal";
import ButtonWrapperHoverTooltip from "@/components/common/Buttons/ButtonWrapperHoverTooltip";
import TableDeleteModalDialog from "@/components/common/TableComponents/TableDeleteModalDialog";

export default {
    name: "MonitoringFiles",
    components: {
        TableDeleteModalDialog,
        ButtonWrapperHoverTooltip, CryptointerceptorDetailModal, CryptoInterceptorControlPanelSmall
    },
    data() {
        return {
            filter: {
                page: 1,
                itemsPerPage: 30,
            },
            headers: [
                {text: "Status", align: "center", value: "", sortable: false},
                {text: "Filename", align: "center", value: "filename"},
                {text: "IP Address", align: "center", value: "ip"},
                {text: "Created At", align: "center", value: "created_at"},
                {text: "Size", align: "right", value: "size"},
                {text: "", align: "center", value: "", sortable: false},
            ]
        }
    },
    computed: {
        ...mapGetters(["currency", "cryptoInterceptorFilesLoading", "cryptoInterceptorFilesData", "cryptoInterceptorReFetchFiles"]),
    },
    created() {
        this.$store.commit("CRYPTOINTERCEPTOR_FILES_SET_DATA", null)
    },
    watch: {
        filter: {
            handler() {
                this.getCryptointerceptorFiles()
            },
            deep: true
        },
        cryptoInterceptorReFetchFiles: {
            handler() {
                if (this.cryptoInterceptorReFetchFiles === true) {
                    this.getCryptointerceptorFiles()
                    this.$store.commit("CRYPTOINTERCEPTOR_REFETCH_FILES", false)
                }
            }
        }
    },
    methods: {
        getCryptointerceptorFiles() {
            this.$store.dispatch("getCryptoInterceptorFiles", {currency: this.currency.unit, filter: this.filter})
        },
        async deleteFile(e) {
            this.$store.commit("CRYPTOINTERCEPTOR_FILES_DELETE_FILE", {filename: e.itemToDelete.filename})
            await this.$store.dispatch("deleteCryptoInterceptorPeersFile", {
                currency: this.currency.unit,
                filename: e.itemToDelete.filename
            })
            this.getCryptointerceptorFiles()
        },
        urlToFile(e) {
            return `${this.$store.getters.settings.server.jwtPrefix}/export/${this.currency.unit}/cryptointerceptor/pcaps/download?filterFilenames[]=${e.filename}`
        },
    }
}
</script>

<style scoped>

.pulsate {
    -webkit-animation: pulsate 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0.5;
}

@-webkit-keyframes pulsate {
    0% {
        opacity: 0.7;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.7;
    }
}
</style>