var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    _vm._b(
      {
        class: _vm.noShadow ? "" : "cardShadow",
        attrs: { outlined: _vm.outlined },
      },
      "v-card",
      _vm.$attrs,
      false
    ),
    [
      _vm.header
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  class: _vm.noHeaderGutters ? "" : "ml-2 my-1",
                  staticStyle: {
                    color: "rgba(0, 0, 0, 0.6)",
                    "font-weight": "400",
                  },
                },
                [_vm._t("header")],
                2
              ),
              _vm._v(" "),
              _c("v-divider"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.noGutters
            ? "cardTextPadding0"
            : _vm.dense
            ? "cardTextPadding2"
            : "",
        },
        [
          _c(
            "v-card-text",
            { style: _vm.bodyStyle },
            [
              !_vm.loading
                ? _vm._t("body")
                : _c("v-skeleton-loader", { attrs: { type: "image" } }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }