<template>
    <Copyable v-if="isCopyable || showExtra || actAsLink"
              :isCopyable="isCopyable"
              :showExtra="showExtra"
              :actAsLink="actAsLink"
              :on-copy="entity"
              @extra-click-event="onMenuOpen($event)"
              :justify="justify">
        <template #text>
            <div class="d-flex align-center" :class="`justify-${justify}`" :style="fixedWidth && { width: '230px' }">
                <routeToAddress
                    v-if="isAddress"
                    :actAsLink="actAsLink"
                    :address="entity"
                    :currency="currency"
                    :hasLabel="!!label"
                    >
                    <template #text>
                        <address-detailable ref="addressInfo"
                                            :address="entity"/>
                        <div v-if="label && showEntityLabel">
                            <v-chip
                                small
                                :color="computedColor"
                                label
                                outlined
                                link
                                style="text-decoration: none !important;"
                            >
                                <span style="font-size: 14px" class="text-truncate">
                                    {{ label }}
                                </span>
                            </v-chip>
                        </div>
                        <div v-else class="fontMonospace text-truncate">
                            <nobr>
                                {{ entity | stringMiddleShortener(26) }}
                            </nobr>
                        </div>
                    </template>
                </routeToAddress>
                <RouteToCluster
                    v-else
                    :cluster="entity"
                    :currency="currency"
                    :hasLabel="!!label"
                    :actAsLink="actAsLink">
                    <template #text>
                        <div v-if="label">
                            <v-chip
                                small
                                :color="computedColor"
                                label
                                outlined
                                link
                                style="text-decoration: none !important;"
                            >
                                <span style="font-size: 14px" class="text-truncate">
                                    {{ label }}
                                </span>
                            </v-chip>
                        </div>
                        <div v-else class="fontMonospace text-truncate link">
                            <nobr>
                                {{ entity | stringMiddleShortener(26) }}
                            </nobr>
                        </div>
                    </template>
                </RouteToCluster>
            </div>
        </template>
    </Copyable>
    <div v-else class="fontMonospace text-truncate">
        <nobr>
            {{ entity | stringMiddleShortener(26) }}
        </nobr>
    </div>
</template>

<script>
import AddressDetailable from "@/components/common/AddressDetailable";
import RouteToAddress from "@/components/common/RouteToAddress";
import Copyable from "@/components/common/Copyable";
import RouteToCluster from "@/components/common/RouteToCluster.vue";
import {mapGetters} from "vuex";

export default {
    name: "EntityLabel",
    components: {
        RouteToCluster,
        AddressDetailable,
        RouteToAddress,
        Copyable,
    },
    props: {
        justify: {
            type: String,
            default: 'center',
            required: false
        },
        entity: {
            type: String,
            required: true,
        },
        identity: {
            type: Object,
        },
        owner: {
            type: Object,
        },
        color: {
            type: String,
            default: 'Primary'
        },
        isCopyable: {
            type: Boolean,
            default: false
        },
        showExtra: {
            type: Boolean,
            default: false
        },
        actAsLink: {
            type: Boolean,
            default: false
        },
        entityLabel: {
            default: false,
        },
        currency: {
            type: String,
            required: false
        },
        isAddress: {
            type: Boolean,
            required: false,
            default: true
        },
        fixedWidth: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        ...mapGetters(["showEntityLabel"]),
        label() {
            if (this.identity)
                return this.identity.label
            if (this.owner)
                return this.owner.name
            if (this.entityLabel)
                return this.entityLabel
            return undefined
        },
        computedColor() {
            return this.color ? this.color : 'Primary'
        }
    },
    methods: {
        onMenuOpen(e) {
            this.$refs['addressInfo'].openMenu(e)
        }
    }
}
</script>

<style scoped>

</style>