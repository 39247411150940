var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseModalDialog, {
    ref: "baseModalDialogRef",
    attrs: { title: "Copy as Custom Cluster", width: "80vh" },
    scopedSlots: _vm._u([
      {
        key: "showbutton",
        fn: function ({ on: dialogOn }) {
          return [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            _setup.BaseDataModifyButton,
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    icon: _vm.icon,
                                    color: "info",
                                    small: "",
                                  },
                                },
                                "BaseDataModifyButton",
                                attrs,
                                false
                              ),
                              { ...dialogOn, ...on }
                            ),
                            [
                              _c("v-icon", { attrs: { left: !_vm.icon } }, [
                                _vm._v(
                                  "\n                        mdi-content-save-move-outline\n                    "
                                ),
                              ]),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.icon ? "" : "Create Custom") +
                                  "\n                "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [_vm._v("\n            Copy as custom cluster\n        ")]
            ),
          ]
        },
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "v-row",
              { staticClass: "mt-2" },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "ValidationObserver",
                      { ref: "validator", attrs: { mode: "lazy" } },
                      [
                        _c("v-text-field-validateable", {
                          attrs: {
                            autofocus: "",
                            dense: "",
                            label: "Name",
                            rules: "required|entity_id",
                          },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _setup.createButtonRef.click.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "prepend",
                              fn: function () {
                                return [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", size: "22" } },
                                    [_vm._v("mdi-hub")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _setup.newClusterName,
                            callback: function ($$v) {
                              _setup.newClusterName = $$v
                            },
                            expression: "newClusterName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function ({ close, setDisabled, disabled }) {
          return [
            _c(
              "v-btn",
              {
                attrs: { disabled: disabled, color: "error" },
                on: { click: _setup.onClose },
              },
              [_vm._v("\n            Cancel\n        ")]
            ),
            _vm._v(" "),
            _c("v-spacer"),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                ref: "createButtonRef",
                attrs: { disabled: disabled, color: "info" },
                on: {
                  click: function ($event) {
                    return _setup.onSave(close, setDisabled)
                  },
                },
              },
              [_vm._v("\n            Create\n        ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }