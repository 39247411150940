<script setup>
import {ref, computed, inject, onBeforeUpdate, watch} from "vue";
import store from "@/store";
import {EntityType} from "@/components/common/types/entity";
import {DataState} from "@/components/common/types/data";
import {useRoute} from "vue-router/composables";
import ProgressCircular from "@/components/common/ProgressCircular.vue";
import AddressNotFoundInfo from "@/components/address/AddressNotFoundInfo.vue";
import TransactionsFilter from "@/components/transaction/TransactionsFilter.vue";
import DataProvider from "@/components/common/DataProvider.vue";
import {isOnSplitView} from "@/utils";

const entityId = inject('entityId')
const clusterInfoState = inject('clusterInfoState', undefined)
const clusterInfo = inject('clusterInfo', undefined)

const entityInfo = computed(() => clusterInfo?.value ?? store.getters.addressInfo)

store.dispatch('initTransactions')

const route = useRoute()

const isAddressView = computed(() => (route.params.address && !isOnSplitView(route)) || route.params.detailAddress)
const entityType = computed(() => isAddressView.value ? EntityType.Address : EntityType.Cluster)

const loading = computed(() => {
    if (isAddressView.value) {
        return store.getters.addressLoading
    }
    return clusterInfoState.value === DataState.Loading || clusterInfoState.value === DataState.Initial
})

const emit = defineEmits(['component-loaded-event'])

onBeforeUpdate(() => {
    //Update visual slider (workaround)
    emit("component-loaded-event")
})

const filter = ref()

const tx_pagination = computed(() => store.getters.tx_pagination)
const currency = computed(() => store.getters.currency)
const fetchTransactions = () => {
    store.dispatch('loadTransactionsByEntity', {
        entityId: entityId.value,
        currency: currency.value.unit,
        pagination: {
            page: tx_pagination.value,
            itemsPerPage: 20,
        },
        filter: { ...filter.value },
        entityTypeParam: entityType.value
    })
}

watch(tx_pagination, async () => {
    fetchTransactions()
})

const fetchGeo = () => {
    store.dispatch('loadTransactionsGeo', {
        entityId: entityId.value,
        currency: currency.value.unit,
        filter: { ...filter.value },
        entityTypeParam: entityType.value
    })
}

const geoTabActive = computed(() => route.path.slice(-3) === 'geo')
let previousGeoFilter = null
const geoFilterChanged = () => JSON.stringify(previousGeoFilter) !== JSON.stringify(filter.value)

let previousTransactionsFilter = undefined
const transactionFilterChanged = () => JSON.stringify(previousTransactionsFilter) !== JSON.stringify(filter.value)

const getDataForCurrentSubview = () => {
    if (geoTabActive.value && geoFilterChanged()) {
        fetchGeo()
        previousGeoFilter = filter.value
    } else if (transactionFilterChanged()) {
        fetchTransactions()
        previousTransactionsFilter = filter.value
    }
}

const getRelativeDestination = (dest) => {
    const base = route.fullPath.slice(0, route.fullPath.lastIndexOf('/transactions'))
    return { path: base + dest, query: route.query }
}

const tab = ref()
watch(tab, () => {
    getDataForCurrentSubview()
})

const tabValue = computed(() => {
    const path = route.path
    const fullPath = route.fullPath
    return path[path.length - 1] !== '/' ?
        `${path}/${fullPath.substring(path.length)}` :
        fullPath
})

const handleTabChange = () => {
    tab.value = tabValue.value
}

</script>

<template>
    <div class="height100 scroll-y">
        <div v-if="loading">
            <ProgressCircular class="pt-10" :color="currency.unit"/>
        </div>
        <v-container v-else-if="!entityInfo || !entityId">
            <div>
                <AddressNotFoundInfo
                    class="my-2"
                    :address="entityId"
                    :message="'Entity with the specified name does not exist!'"
                />
            </div>
        </v-container>

        <div v-else>
            <h1>Transactions</h1>
            <v-divider/>
            <v-divider class="mb-3"/>
            <TransactionsFilter
                v-model="filter"
                @on-filter-event="getDataForCurrentSubview"
            />
            <v-tabs
                v-if="currency.typeFamily !== 'ETH'"
                :value="tabValue"
                @change="handleTabChange"
                :slider-color="currency.hexColor">
                <v-tab :to="getRelativeDestination('/transactions')">
                    Transactions
                </v-tab>
                <v-tab :to="getRelativeDestination('/transactions/geo')">
                    Geolocations
                </v-tab>
            </v-tabs>
            <v-divider/>
            <div class="mt-3 width100">
                <DataProvider name="entityType" :data="entityType">
                    <router-view/>
                </DataProvider>
            </div>
        </div>
    </div>
</template>