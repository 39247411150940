<script setup>
import L, {Icon} from 'leaflet';
import {LMap, LTileLayer} from 'vue2-leaflet';
import {computed, onMounted, ref, watch} from "vue";
import {ArrayForEachAsync, delay} from "@/utils";
import HeatmapOverlay from 'leaflet-heatmap/leaflet-heatmap.js'
import DataTableVirtualScroll from "@/components/common/Table/DataTableVirtualScroll.vue";
import PeerDetailModal from "@/components/peers/PeerDetailModal.vue";
import store from "@/store";
import {DataState} from "@/components/common/types/data";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowSize: 0
});
const url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

const zoom = 1.5
const center = [30, 34]

const props = defineProps({
    country: {
        type: Object
    },
    city: {
        type: Object
    },
    as: {
        type: Object
    },
    org: {
        type: Object
    },
    getData: {
        type: Object
    }
})

const {data, getData, dataState} = props.getData

const compData = computed(() => data.value?.data ?? [])
const loadingMarkers = ref(false)

let cfg = {
    // radius should be small ONLY if scaleRadius is true (or small radius is intended)
    // if scaleRadius is false it will be the constant radius used in pixels
    "radius": 2,
    "maxOpacity": .8,
    // scales the radius based on map zoom
    "scaleRadius": true,
    // if set to false the heatmap uses the global maximum for colorization
    // if activated: uses the data maximum within the current map boundaries
    //   (there will always be a red spot with useLocalExtremas true)
    "useLocalExtrema": true,
    // which field name in your data represents the latitude - default "lat"
    latField: 'lat',
    // which field name in your data represents the longitude - default "lng"
    lngField: 'lng',
    // which field name in your data represents the data value - default "value"
    valueField: 'count'
};

const map = ref(null)
let markers
let heatmapLayer = new HeatmapOverlay(cfg);

onMounted(() => {
    map.value.mapObject.preferCanvas = true
    map.value.mapObject.addLayer(heatmapLayer)
})

const watchableCountry = computed(() => props.country)
const watchableCity = computed(() => props.city)
const watchableAS = computed(() => props.as)
const watchableOrg = computed(() => props.org)
watch([watchableCountry, watchableCity, watchableAS, watchableOrg], () => {
    if (watchableCountry.value) {
        refreshBorderLayerAndFocus()
    } else {
        removeBorderLayer()
    }
    data.value = undefined
    ResetMarkers()
    ResetHeatMap()
    resetClusterPopup()
})

let geoJSON
const removeBorderLayer = () => {
    if (geoJSON) {
        if (map.value.mapObject.hasLayer(geoJSON)) {
            map.value.mapObject.removeLayer(geoJSON)
        }
    }
}
const refreshBorderLayerAndFocus = () => {
    removeBorderLayer()
    const geoJSONRaw = JSON.parse(props.country.polygon)
    geoJSON = L.geoJson(geoJSONRaw, {
        style: function () {
            return {fill: false}
        }
    }).addTo(map.value.mapObject);
    map.value.mapObject.fitBounds(geoJSON.getBounds());
}

let heatMapData = new Map()
let heatMapMax = ref(0)
const getHeatMapDataComp = () => {
    return {
        max: heatMapMax.value,
        data: Array.from(heatMapData.values()) ?? []
    }
}

let clickedClusterPeers = []
let clickedClusterPeersIndex = 0
let clusterPeersPopup = undefined
const resetClusterPopup = () => {
    if (clusterPeersPopup) {
        clusterPeersPopup.close()
    }
    clickedClusterPeers = []
    clickedClusterPeersIndex = 0
    clusterPeersPopup = undefined
}


const createPopupForPeer = (peer, {buttons, total, index}) => {
    const createButtons = () => {
        return `<div>
        <button  onclick="(() => {
                   const event = new CustomEvent('peerPreviousClick', { bubbles: true });
                   dispatchEvent(event)
               })()" type="button" class="ma-1 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small tertiary white--text"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-left theme--light"></i></span></button>

        <button onclick="(() => {
                   const event = new CustomEvent('peerNextClick',{ bubbles: true });
                   dispatchEvent(event)
               })()" type="button" class="ma-1 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small tertiary white--text"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-right theme--light"></i></span></button>
    </div>`
    }
    const createCountText = () => {
        return `<div class="fontMonospace">
         ${index + 1} / ${total}
        </div>`
    }
    return `
    <div>
        <table class="markerPopupTable">
            <div class='d-flex justify-space-between align-center' style="white-space: nowrap">
               <div>${buttons ? createButtons() : ''}</div>
               ${buttons ? createCountText() : ''}
               <button onclick="(() => {
                   const event = new CustomEvent('peerDetailClick', { detail: ${peer.node_id}, bubbles: true });
                   dispatchEvent(event)
               })()" type="button" class="ma-1 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small tertiary white--text"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-details theme--light"></i>Detail</span>
               </button>
           </div>
           <tr>
            <td>Host</td>
            <td class="fontMonospace">${peer.host}</td>
           </tr>
           <tr>
            <td>Network type</td>
            <td class="fontMonospace">${peer.network_type}</td>
           </tr>
           <tr>
            <td>Latitude</td>
            <td>${peer.latitude}</td>
           </tr>
           <tr>
            <td>Longitude</td>
            <td>${peer.longitude}</td>
           </tr>
           <tr>
            <td>City</td>
            <td>${peer.city}</td>
           </tr>
           <tr>
            <td>Country code</td>
            <td>${peer.country_code}</td>
           </tr>
           <tr>
            <td>Autonomous system</td>
            <td class="fontMonospace">${peer.asn}</td>
           </tr>
           <tr>
            <td>Organization</td>
            <td>${peer.organization}</td>
           </tr>
           <tr>
            <td>Active since</td>
            <td>${peer.since}</td>
           </tr>
        </table>
    </div>
    `
}
const createMarker = (peer) => {
    const marker = L.marker([peer.latitude, peer.longitude])
    marker.peerData = peer
    marker.bindPopup(createPopupForPeer(peer, {}))
    return marker
}

watch(compData, async () => {
    ResetMarkers()
    ResetHeatMap()
    loadingMarkers.value = true

    await delay(100)
    await ArrayForEachAsync(compData.value, (x) => {
        //markers
        markers.addLayer(createMarker(x));

        //heatmap
        if (heatMapData.has(`${x.latitude};${x.longitude}`)) {
            const item = heatMapData.get(`${x.latitude};${x.longitude}`)
            item.count++
            if (item.count > heatMapMax) {
                heatMapMax.value = item.count
            }
        } else {
            heatMapData.set(`${x.latitude};${x.longitude}`, {lat: x.latitude, lng: x.longitude, count: 1})
        }
    }, 50)

    if (isPeerMarkersEnabled.value) {
        ShowMarkers()
    }
    if (isHeatMapEnabled.value) {
        ShowHeatMap()
    }
    loadingMarkers.value = false

})


const ShowMarkers = () => {
    if (markers && !map.value.mapObject.hasLayer(markers)) {
        map.value.mapObject.addLayer(markers)
    }
}
const HideMarkers = () => {
    if (markers && map.value.mapObject.hasLayer(markers)) {
        map.value.mapObject.removeLayer(markers)
    }
}
const ResetMarkers = () => {
    HideMarkers()
    markers = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
    })
    SetupMarkersHandlers(markers)
}


const SetupMarkersHandlers = () => {
    markers.on('clusterclick', function (a) {
        resetClusterPopup()
        if (map.value.mapObject._layersMaxZoom !== map.value.mapObject._zoom) {
            return
        }
        clickedClusterPeers = a.layer.getAllChildMarkers()
        const firstClickedMarker = clickedClusterPeers[0]
        clusterPeersPopup = L.popup({closeOnClick: false})
            .setLatLng(firstClickedMarker._latlng)
            .setContent(createPopupForPeer(firstClickedMarker.peerData, {
                buttons: true,
                total: clickedClusterPeers.length,
                index: clickedClusterPeersIndex
            }))
            .openOn(map.value.mapObject);
    });
}

const ShowHeatMap = () => {
    heatmapLayer.setData(getHeatMapDataComp());
}
const HideHeatMap = () => {
    heatmapLayer.setData({max: 0, data: []});
}
const ResetHeatMap = () => {
    HideHeatMap()
    heatMapMax.value = 0
    heatMapData = new Map()
}
const isPeerMarkersEnabled = ref(true)
const onPeerMarkerChange = (shouldEnable) => {
    if (shouldEnable) {
        ShowMarkers()
    } else {
        HideMarkers()
    }
}

const isHeatMapEnabled = ref(false)
const onHeatMapChange = (shouldEnable) => {
    if (shouldEnable) {
        ShowHeatMap()
    } else {
        HideHeatMap()
    }
}

const onPreviousPeerClick = () => {
    clickedClusterPeersIndex--
    if (clickedClusterPeersIndex < 0) {
        clickedClusterPeersIndex = clickedClusterPeers.length - 1
    }

    const peerToShow = clickedClusterPeers[clickedClusterPeersIndex].peerData
    clusterPeersPopup.setContent(createPopupForPeer(peerToShow, {
        buttons: true,
        total: clickedClusterPeers.length,
        index: clickedClusterPeersIndex
    }))
}
const onNextPeerClick = () => {
    clickedClusterPeersIndex++
    if (clickedClusterPeersIndex > clickedClusterPeers.length - 1) {
        clickedClusterPeersIndex = 0
    }

    const peerToShow = clickedClusterPeers[clickedClusterPeersIndex].peerData
    clusterPeersPopup.setContent(createPopupForPeer(peerToShow, {
        buttons: true,
        total: clickedClusterPeers.length,
        index: clickedClusterPeersIndex
    }))
}

const peerDetailModalRef = ref(null)
const peerDetailId = ref(null)
const peerDetailData = computed(() => store.getters.peerDetail)
const peerDetailLoading = computed(() => store.getters.peerDetailLoading)
const currentCurrency = computed(() => store.getters.currency)
const onOpenDetailClick = ({detail}) => {
    peerDetailId.value = detail
    store.dispatch("loadPeerDetail", {peerId: detail, currency: currentCurrency.value.unit})
    peerDetailModalRef.value.open()
}
const locatePeer = (peer) => {
    map.value.mapObject.flyTo([peer.latitude, peer.longitude], 18);
}
const peerHeaders = [
    {text: "Host", align: "left", value: "host", filterable: false, sortable: false, width: "190px"},
    {text: "Coordinates", align: "center", value: "", sortable: false, filterable: false, width: "210px"},
    {text: "Country", align: "left", value: "country_code", width: "100px"},
    {text: "City", align: "left", value: "city"},
    {text: "AS", align: "right", value: "asn", width: "130px"},
    {text: "", align: "", value: "", width: "42px"},
]

</script>


<template>
    <div class="relative peerMapContainer"
         @peerDetailClick="onOpenDetailClick"
         @peerNextClick="onNextPeerClick"
         @peerPreviousClick="onPreviousPeerClick">
        <PeerDetailModal
            ref="peerDetailModalRef"
            :currency="currentCurrency.unit"
            :loading="peerDetailLoading"
            :peer-detail="peerDetailData"
            :peer-id="peerDetailId"
        />
        <div class="d-flex justify-start mb-n4 mt-n2">
            <v-switch
                v-model="isHeatMapEnabled"
                color="tertiary"
                inset
                prepend-icon="mdi-thermometer"
                @change="onHeatMapChange"
            ></v-switch>
            <v-switch
                v-model="isPeerMarkersEnabled"
                color="tertiary"
                inset
                prepend-icon="mdi-map-marker"
                @change="onPeerMarkerChange"
            ></v-switch>
        </div>
        <div style="height: 6px">
            <v-progress-linear
                :active="dataState === DataState.Loading || loadingMarkers"
                color="tertiary"
                height="100%"
                indeterminate
            ></v-progress-linear>
        </div>
        <v-row no-gutters>
            <v-col cols="6">
                <l-map v-once ref="map" :center="center" :zoom="zoom" style="height: 800px; z-index: 0">
                    <l-tile-layer :url="url"></l-tile-layer>
                </l-map>
            </v-col>
            <v-col cols="6">
                <DataTableVirtualScroll
                    :data="compData"
                    :debounceInterval="country ? 5 : 50"
                    :headers="peerHeaders"
                    no-data-text="No peer data"
                    table-max-height="800px">
                    <template #item="{ item }">
                        <tr style="cursor: pointer" @click="onOpenDetailClick({detail: item.node_id})">
                            <td class="fontMonospace">
                                {{ item.host | truncate(16) }}
                            </td>
                            <td class="fontMonospace">
                                ({{ item.longitude }},{{ item.latitude }})
                            </td>
                            <td>
                                {{ item.country_code }}
                            </td>
                            <td>
                                {{ (item.city ? item.city : '') | truncate(16) }}
                            </td>
                            <td class="fontMonospace text-right">
                                {{ item.asn }}
                            </td>
                            <td>
                                <v-btn color="info" icon small @click.stop="locatePeer(item)">
                                    <v-icon>
                                        mdi-crosshairs-gps
                                    </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </DataTableVirtualScroll>
            </v-col>
        </v-row>
    </div>
</template>

<style>
.markerPopupTable {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    white-space: nowrap;
}

.markerPopupTable tr td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.markerPopupTable > tr:nth-child(even) {
    background-color: #dddddd;
}

.peerMapContainer .leaflet-popup-content {
    width: fit-content !important;
}

</style>
