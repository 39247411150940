<template>
    <div class="filter-expansion-panel">
        <v-row>
            <v-col
                cols="11"
                lg="2"
                md="3"
                sm="6"
            >
                <DateTimePicker v-model="filter.since" :disabled="disabled" label="Since"/>
                <DateTimePicker v-model="filter.until" :disabled="disabled" label="Until"/>
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="3"
                   sm="4">
                <v-row justify="center">
                    <v-btn-toggle
                        v-model="filter.isOutgoing"
                        :color="currency.unit"
                        class="mx-2 mb-2"
                        mandatory
                        tile
                    >
                        <v-btn
                            :disabled="disabled"
                            :value="false"
                            class="text-left"
                            height="32px"
                            outlined
                        >
                            Incoming Paths
                            <v-spacer/>
                            <v-icon class="ml-2">mdi-import</v-icon>
                        </v-btn>
                        <v-btn
                            :disabled="disabled"
                            :value="true"
                            class="text-left"
                            height="32px"
                            outlined
                            style="border-left-width: 1px; border-top-width: 0;"
                        >
                            Outgoing Paths
                            <v-spacer/>
                            <v-icon class="ml-2">mdi-export</v-icon>
                        </v-btn>
                    </v-btn-toggle>

                    <v-btn-toggle
                        v-model="filter.allClusters"
                        :color="currency.unit"
                        mandatory
                        tile
                    >
                        <v-btn
                            :disabled="disabled"
                            :value="false"
                            class="text-left"
                            height="32px"
                            outlined
                        >
                            Known Clusters
                            <v-spacer/>
                            <v-icon class="ml-2">mdi-hexagon</v-icon>
                        </v-btn>
                        <v-btn
                            :disabled="disabled"
                            :value="true"
                            class="text-left"
                            height="32px"
                            outlined
                            style="border-left-width: 1px; border-top-width: 0;"
                        >
                            All clusters
                            <v-spacer/>
                            <v-icon class="ml-2">mdi-hub</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </v-row>
            </v-col>
            <v-col cols="12"
                   lg="1"
                   md="1"
                   sm="2">
                <v-text-field
                    v-model.number="filter.maxHops"
                    :disabled="currency.typeFamily !== 'BTC' || disabled"
                    :min="1"
                    :rules="[
          (v) =>
            (v !== undefined && v !== '' && v !== null && v > 0) ||
            'Must be a positive number',
        ]"
                    label="Max Hops"
                    type="number"
                />
            </v-col>
            <v-col cols="12"
                   lg="3"
                   md="5"
                   sm="6">
                <CategoryPicker
                    v-model="filter.categories"
                    :categories="categories"
                    :disabled="disabled"
                />
            </v-col>
            <v-col cols="12"
                   lg="4"
                   md="6" offset-lg="0"
                   offset-md="3" sm="6">
                <v-row no-gutters>
                    <v-col class="mb-2 inputMonospace" cols="12">
                        <ValidatableSearchTextField
                            :disabled="disabled"
                            label="Address"
                            rules="crypto"
                            @search-event="appendToFilterAddresses"
                        />
                        <div class="caption mt-n2 ml-1">Press <span class="font-weight-light">Enter</span> to confirm.
                        </div>
                        <div class="fontMonospace font-weight-regular" style="color: rgba(0, 0, 0, 0.6);">
                            <DeleteableList v-model="filter.addresses" class="mx-3"/>
                        </div>
                    </v-col>
                    <v-col class="inputMonospace" cols="12">
                        <ClusterPicker ref="clusterPickerRef"
                                       v-model="selectedClustersInPicker"
                                       :currency-unit="currency.unit"
                                       :disabled="disabled"
                                       label="Remote Cluster"
                                       no-dense
                                       outlined
                                       @input="appendToFilterClusters"/>
                        <div class="fontMonospace font-weight-regular" style="color: rgba(0, 0, 0, 0.6);">
                            <DeleteableList v-model="filter.clusters" class="mx-3"/>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="mb-2" justify="center" no-gutters>
            <v-col cols="auto">
                <v-btn
                    :color="currency.unit"
                    :disabled="disabled"
                    class="text-left"
                    height="32px"
                    outlined
                    @click="onSearch"
                >
                    Search
                    <v-icon class="ml-2" small>fa fa-magnifying-glass</v-icon>
                </v-btn>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :color="currency.unit"
                            :disabled="isFilterDefault || disabled"
                            absolute
                            class="ml-2"
                            icon
                            style="margin-top: -3px"
                            v-bind="attrs"
                            @click="resetFilter"
                            v-on="on"
                        >
                            <v-icon size="28px">mdi-backup-restore</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Filter</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AddressCategory from "@/components/address/AddressCategoryIcon";
import EmptyClusterInfo from "@/components/address/EmptyClusterInfo";
import CategoryPicker from "@/components/common/CategoryPicker";
import DeleteableList from "@/components/common/DeleteableList";
import ValidatableSearchTextField from "@/components/common/ValidateableComponents/ValidatableSearchTextField";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import {Chart} from "highcharts-vue";
import {deepClone, isObjectEmpty,} from "@/utils";
import HeaderLined from "@/components/common/HeaderLined";
import {mapGetters} from "vuex";
import DateTimePicker from "@/components/common/DateTimePicker";
import ClusterPicker from "@/components/common/ClusterPicker.vue";

export default {
    name: "FundsFilter",
    mixins: [routeSyncMixin], //provides filter
    components: {
        ClusterPicker,
        DateTimePicker,
        HeaderLined,
        AddressCategory,
        EmptyClusterInfo,
        CategoryPicker,
        DeleteableList,
        ValidatableSearchTextField,
        Chart,
    },
    data() {
        return {
            filterInfo: {
                since: {type: "Number", default: () => undefined},
                until: {type: "Number", default: () => undefined},
                maxHops: {type: "Number", default: () => 1},
                allClusters: {type: "Boolean", default: () => false},
                isOutgoing: {type: "Boolean", default: () => false},
                categories: {type: "ArrayOfNumbers", default: () => []}, //TODO Dynamic fetch
                addresses: {type: "ArrayOfStrings", default: () => []},
                clusters: {type: "ArrayOfStrings", default: () => []},
            },
            selectedClustersInPicker: []
        }
    },
    props: {
        currency: {
            type: Object,
            required: true,
        },
        categories: {
            type: Array,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        clusterCurrent: {
            type: String,
        },
    },
    computed: {
        ...mapGetters(["addressFundsHistoryFallback"]),
        isFilterDefault() {
            return this.$_compareFilters(this.filter, this.$_getDefaultFilter())
        },
    },
    created() {
        if (isObjectEmpty(this.$route.query) && this.addressFundsHistoryFallback[this.clusterCurrent]) {
            this.$_syncToRouteQuery(true, deepClone(this.addressFundsHistoryFallback[this.clusterCurrent]))
        }
    },
    methods: {
        onSearch() {
            this.$emit("search-funds-event", deepClone(this.filter))
        },
        resetFilter() {
            this.filter = this.$_getDefaultFilter()
        },
        appendToFilterAddresses({searchQuery}) {
            this.filter.addresses.push(searchQuery);
        },
        appendToFilterClusters(clusters) {
            for (const cluster of clusters) {
                this.filter.clusters.push(cluster.entity_id)
            }
            this.selectedClustersInPicker = []
        },
        // -- mixin methods --
        onFilterInitial() {
            this.onSearch()
        },
    },
}
</script>

<style scoped>
.v-btn-toggle {
    flex-direction: column;
}

.v-expansion-panel::before {
    box-shadow: none;
}
</style>

<style>
.filter-expansion-panel .v-expansion-panel-header__icon {
    margin-left: 8px !important;
}
</style>