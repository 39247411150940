var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    staticClass: "cardShadow",
    attrs: { dense: "" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-icon", { staticClass: "d-block mr-2 ml-1" }, [
                  _vm._v("timeline"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("My Graphs"),
                ]),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c(_setup.CreateInteractiveGraphButton),
                _vm._v(" "),
                _c(_setup.GraphDetailModal, {
                  attrs: { currencies: _setup.currencies },
                  on: { "save-event": _setup.createGraph },
                  scopedSlots: _vm._u([
                    {
                      key: "showbutton",
                      fn: function ({ on }) {
                        return [
                          _c(
                            _setup.CreateButton,
                            _vm._g(
                              {
                                staticClass: "mr-3",
                                attrs: { text: "Create graph" },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(_setup.DataGrid, {
              ref: "dataGridRef",
              attrs: {
                "bulk-operations": false,
                headers: _setup.graphsHeaders,
                "get-data": _setup.getGraphs,
                "items-per-page": 5,
                item_id: "id",
                "sort-desc": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "td",
                        {
                          staticClass: "text-left dynamicTruncate",
                          staticStyle: { "min-width": "150px" },
                        },
                        [
                          item.description === "Interactive Graph"
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "link",
                                  attrs: {
                                    to: {
                                      name: "GraphExplorer",
                                      params: { id: item.id },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f("stringShortener")(item.name, 30)
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _c(
                                "router-link",
                                {
                                  staticClass: "link",
                                  attrs: {
                                    to: `/${item.crypto}/graph/${item.id}/config`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f("stringShortener")(item.name, 30)
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-hover", {
                        attrs: { "open-delay": "200" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ hover }) {
                                return [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-left dynamicTruncate",
                                      class: { descriptionParentHover: hover },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "description-styles",
                                          class:
                                            hover && "show-full-text-hover",
                                          staticStyle: { margin: "auto 0 0 0" },
                                        },
                                        [_vm._v(_vm._s(item.description))]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _c(_setup.BlockchainCryptoBadge, {
                            attrs: {
                              crypto: item.crypto,
                              currencies: _setup.currencies,
                              iconWidth: 24,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("formatNumber")(item.count)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: item.is_hidden
                                                  ? "primary"
                                                  : "tertiary",
                                                dark: "",
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  item.is_hidden
                                                    ? "visibility_off"
                                                    : "visibility"
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n                            Graph is " +
                                    _vm._s(
                                      item.is_hidden ? "hidden" : "visible"
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-overflow",
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("moment")(
                                    item.created_at,
                                    "YYYY-MM-DD HH:mm:ss Z"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-overflow",
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("moment")(
                                    item.updated_at,
                                    "YYYY-MM-DD HH:mm:ss Z"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-xs-left text-overflow",
                          staticStyle: { "min-width": "95px" },
                        },
                        [
                          _c(_setup.GraphDetailModal, {
                            attrs: {
                              currencies: _setup.currencies,
                              graphDetail: item,
                            },
                            on: { "save-event": _setup.editGraph },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "showbutton",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        _setup.TableEditButton,
                                        _vm._g(
                                          {
                                            attrs: {
                                              editButtonTooltip: "Edit graph",
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c(_setup.TableDeleteModalDialog, {
                            attrs: {
                              description: `Do you really want to delete graph ${item.name}`,
                              itemToDelete: item,
                              tooltip: "Delete Graph",
                            },
                            on: { "delete-event": _setup.deleteGraph },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }