const state = {
    notifications: [],
}

const getters = {
    notifications: (state) => state.notifications,
}

const ensureMsgIsString = (message) => {
    if (typeof message === "string") {
        return message
    }
    return message?.message ?? "Unknown Error"
}

const mutations = {
    SUCCESS(state, msg) {
        msg = ensureMsgIsString(msg)

        state.notifications.push({
            message: {
                message: msg,
                type: 'Success'
            },
            color: 'success',
            timeout: 5000,
        })
    },

    WARNING(state, msg) {
        msg = ensureMsgIsString(msg)

        state.notifications.push({
            message: {
                message: msg,
                type: 'Warning'
            },
            color: '#DAA520',
            timeout: 0,
        })
    },

    ERROR(state, msg) {
        msg = ensureMsgIsString(msg)

        //Check for duplicate errors
        if (state.notifications.some(n => n.message.message === msg)) {
            return;
        }

        state.notifications.push({
            message: {
                message: msg,
                type: 'Error'
            },
            color: 'error',
            timeout: 0,
        })
    },

    COPY(state, msg) {
        msg = ensureMsgIsString(msg)

        state.notifications.push({
            message: {
                message: msg,
                type: undefined
            },
            color: 'grey',
            timeout: 1000,
        })
    },

    PROGRESS(state, msg) {
        state.notifications.push({
            message: {
                message: msg,
                type: 'Progress'
            },
            color: 'info',
            timeout: 1000,
        })
    },

    SET_NOTIFICATIONS(state, value) {
        state.notifications = value
    }
}

const actions = {
    success({commit}, msg) {
        commit('SUCCESS', msg)
    },
    error({commit}, msg) {
        commit('ERROR', msg)
    },
    warning({commit}, msg) {
        commit('WARNING', msg)
    },
    copy({commit}, msg) {
        commit('COPY', msg)
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
