import Vue from 'vue'
import Vuetify from 'vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/dist/vuetify.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        light: true,
        themes:{
            light:{
                primary: '#616161',
                secondary: '#424242',
                tertiary: '#1565C0',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                BTC: '#F7931A',
                LTC: '#9c9898',
                DASH: '#008ce7',
                ZEC: '#ECB244',
                BCH: '#8dc351',
                DGB: '#006ad2',
                BTG: '#eba809',
                QTUM: '#2e9ad0',
                ETH: '#3c3c3d',
            }
        }
       
    }
}

export default new Vuetify(opts)