import authenticationService from "@/services/authenticationService";
import apiClient from '@/services/apiClient';
import axios from "axios";

import {modalState} from "@/components/common/types/modal";
import Vue from "vue";

const state = {
    user: false,
    token: null,
    isUserLoading: false,
    users: [],
    isUsersLoading: false,
    userActivityLogs: [],
    isUserActivityLogsLoading: true,
    userInfoCancelToken: null,
    usersCancelToken: null,
    usersModalState: modalState.Initial,
};

const getters = {
    user: (state) => state.user,
    users: (state) => state.users,
    token: (state) => state.token,
    userActivityLogs: (state) => state.userActivityLogs,
    hasPermission: (state) => (resource) => {
        if (!state.user) return false;
        //todo FE to enums 
        if (state.user.roles.find(role => role.name === "superadmin")) {
            return true
        } else if (state.user.roles.find(role => role.name === "admin")) {
            return !["users", "roles"].includes(resource);
        } else if (state.user.roles.find(role => role.name === "hiddenreporter")) {
            return !["users", "roles", "monitoring", "logs"].includes(resource);
        } else if (state.user.roles.find(role => role.name === "reporter")) {
            return !["users", "roles", "monitoring", "logs"].includes(resource);
        } else if (state.user.roles.find(role => role.name === "user")) {
            return !["users", "roles", "alarms", "write", "monitoring", "logs"].includes(resource);
        }
        return false
    },
    isUserLoading: (state) => state.isUserLoading,
    isUsersLoading: (state) => state.isUsersLoading,
    isUserActivityLogsLoading: (state) => state.isUserActivityLogsLoading,
    usersModalState: (state) => state.usersModalState
};

const mutations = {
    USER_LOGIN(state, data) {
        state.user = data
    },
    SET_TOKEN(state, token) {
        state.token = token
    },
    USER_START_LOADING(state) {
        state.isUserLoading = true
    },
    USER_FINISH_LOADING(state) {
        state.isUserLoading = false
    },
    USER_LOGOUT(state) {
        localStorage.clear();
        state.user = null
    },
    USER_ACTIVITY_LOGS_START_LOADING(state) {
        if (state.userInfoCancelToken) {
            state.userInfoCancelToken.cancel()
        }
        state.userInfoCancelToken = axios.CancelToken.source()
        state.isUserActivityLogsLoading = true
    },
    USER_ACTIVITY_LOGS_LOADED(state, data) {
        state.userActivityLogs = data
    },
    USER_ACTIVITY_LOGS_FINISH_LOADING(state) {
        state.isUserActivityLogsLoading = false
    },
    USERS_START_LOADING(state) {
        if (state.usersCancelToken) {
            state.usersCancelToken.cancel()
        }
        state.usersCancelToken = axios.CancelToken.source()
        state.isUsersLoading = true
    },
    USERS_LOADED(state, data) {
        state.users = data
    },
    USERS_FINISH_LOADING(state) {
        state.isUsersLoading = false
    },
    USERS_MODAL_SET_STATE(state, data) {
        state.usersModalState = data
    },
    USER_SET_ROLE_NAME(state, newName) {
        Vue.set(state.user, 'roles', [{name: newName}])
    }
};

const actions = {
    async loadUser({commit}, user) {
        commit('USER_LOGIN', user);
        commit('USER_FINISH_LOADING')
    },
    async deleteUser({dispatch}, {userId}) {
        try {
            const response = await apiClient.DELETE(`users/${userId}`)
            dispatch('success', response.message)
        } catch (error) {
            dispatch('error', error.userFriendlyMessage)
        }
    },
    async createUser({dispatch, commit}, {user}) {
        commit('USERS_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await apiClient.POST(`users`, user)
            dispatch('success', response.message)
            commit('USERS_MODAL_SET_STATE', modalState.Success)
        } catch (error) {
            dispatch('error', error.userFriendlyMessage)
            commit('USERS_MODAL_SET_STATE', modalState.Error)
        }
    },
    async editUser({dispatch, commit}, {user}) {
        commit('USERS_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await apiClient.PUT(`users/${user.id}`, user)
            dispatch('success', response.message)
            commit('USERS_MODAL_SET_STATE', modalState.Success)
        } catch (error) {
            dispatch('error', error.userFriendlyMessage)
            commit('USERS_MODAL_SET_STATE', modalState.Error)
        }
    },
    async userLogin({commit, dispatch}, credentials) {
        commit('USER_START_LOADING')
        const user = await authenticationService.login({credentials: credentials})
        if (user) {
            commit('USER_LOGIN', user)
            dispatch('attempt', user.token)
        }
        commit('USER_FINISH_LOADING')
    },

    async attempt({commit}, token) {
        localStorage.setItem("jwt", token);
        commit('SET_TOKEN', token)
    },
    async userLogout({commit}) {
        authenticationService.logout()
        commit('USER_LOGOUT');
        commit('USER_FINISH_LOADING')
        location.reload() //reloads page to clean state
        this.dispatch('success', 'You are logged out')
    },
    async loadUserInfo({commit, dispatch}, {pagination}) {
        commit('USER_ACTIVITY_LOGS_START_LOADING')
        try {
            const response = await apiClient.GET("logs", {
                params: pagination,
                cancelToken: state.userInfoCancelToken.token
            })
            dispatch("success", response.message)
            commit('USER_ACTIVITY_LOGS_LOADED', response)
            commit('USER_ACTIVITY_LOGS_FINISH_LOADING')
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch("error", error.userFriendlyMessage)
                commit('USER_ACTIVITY_LOGS_FINISH_LOADING')
            }
        }
    },
    async getUserLogs({commit, dispatch}, {pagination, filters, cancelToken}) {
        if (typeof (filters) === "function") {
            filters = filters()
        }
        try {
            return await apiClient.GET("logs", {
                params: {...pagination, ...filters},
                cancelToken: cancelToken
            })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
    },
    async getUserGraphs({commit, dispatch}, {pagination, filters, cancelToken}) {
        try {
            return await apiClient.GET("graphs", {
                params: {...pagination, ...filters},
                cancelToken: cancelToken
            })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
    },
    async getUserCases({commit, dispatch}, {pagination, filters, cancelToken}) {
        try {
            return await apiClient.GET("cases", {
                params: {...pagination, ...filters},
                cancelToken: cancelToken
            })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
    },
    async loadUsers({commit, dispatch}, pagination) {
        commit('USERS_START_LOADING');
        try {
            const response = await apiClient.GET("users", {
                params: pagination,
                cancelToken: state.usersCancelToken.token
            })
            dispatch("success", response.message)
            commit('USERS_LOADED', response);
            commit('USERS_FINISH_LOADING')

        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch("error", error.userFriendlyMessage)
                commit('USERS_FINISH_LOADING')
            }
        }
    },
    async getUsers({commit}, {pagination, cancelToken}) {
        try {
            return await apiClient.GET(`users`,
                {
                    params: pagination,
                    cancelToken: cancelToken
                })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
        return false
    },
    cleanUpUser({commit}) {
        commit('USER_LOGOUT');
    }
};

export default {
    state,
    mutations,
    actions,
    getters
}

