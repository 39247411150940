var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-7 mt-0 pt-1", attrs: { fluid: "" } },
        [
          _c(
            "router-link",
            {
              staticClass: "text-h6 link",
              attrs: {
                to: {
                  name: "AddressSummary",
                  params: { currency: "BTC", address: _setup.addressId },
                },
                target: "_blank",
              },
            },
            [
              _c("span", { staticClass: "fontMonospace" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_setup.addressId) +
                    "\n            "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("v-progress-linear", {
            staticStyle: { width: "100%" },
            attrs: { active: _setup.loading, height: "4px", indeterminate: "" },
          }),
          _vm._v(" "),
          _c(_setup.TransactionsPaginatedList, {
            attrs: {
              "tx-map-function": (tx) => {
                const { balance } =
                  _setup.getAddressBalanceAndDirectionForTransaction(
                    _setup.addressId,
                    tx
                  )

                return {
                  ..._setup.processTx(tx, _setup.addressId),
                  currentAddressBalance: balance,
                }
              },
              "get-data": _setup.getData,
              "selectable-transactions": "",
            },
            on: {
              selectedTransactionsChangedEvent: (event, tx) =>
                _setup.onCheckboxChange(event, tx),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }