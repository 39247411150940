<script setup>

import {ref} from "vue";
import ClusterPicker from "@/components/common/ClusterPicker.vue";

const props = defineProps({
    value: {
        type: Array,
        required: true
    },
    chips: {
        type: Boolean,
        required: false,
        default: false
    },
    table: {
        type: Boolean,
        required: false,
        default: false
    },
    multiple: {
        type: Boolean,
        required: false,
        default: true
    },
    currencyUnit: {
        type: String,
        required: true
    }
})

const emit = defineEmits(['input'])

const removeCluster = (clusterToRemove) => {
    emit('input', props.value.filter(c => c.entity_id !== clusterToRemove.entity_id))
}

const tableHeaders = ref([
    {text: 'Cluster', align: 'left', value: 'entity_id'},
    {text: 'Type', align: 'center', value: 'type'},
    {text: 'Address Count', align: 'end', value: 'addresses_count'},
    {text: '', align: 'end', value: '', sortable: false, filterable: false, width: "32px"},
])

</script>

<template>
    <div>
        <div v-if="chips" style="min-height: 24px">
            <v-chip
                v-for="(item, index) in value"
                :key="index"
                class="ma-1"
                close
                close-icon="mdi-delete"
                label
                small
                @click:close="removeCluster(item)"
            >
            <span class="fontMonospace">
                {{ item.entity_id | stringShortener(8) }}
            </span>
                &nbsp;
                <span>
                (<b>{{ item.type }}</b>)
            </span>
            </v-chip>
        </div>
        <ClusterPicker :currencyUnit="currencyUnit" :multiple="multiple" :value="value" @input="emit('input', $event)"/>
        <div class="my-1">
            Selected clusters:
        </div>
        <div v-if="table" class="mt-2">
            <v-data-table
                :headers="tableHeaders"
                :items="value"
                :items-per-page="-1"
                class="elevation-1"
                dense
                height="300px"
                hide-default-footer
                no-data-text="No clusters selected!">
                <template #item="{ item, index }">
                    <v-hover v-slot="{ hover }">
                        <tr class="noselect" style="cursor: pointer" @click="() => removeCluster(value[index])">
                            <td class="text-left fontMonospace">
                                {{ item.entity_id }}
                            </td>
                            <td>
                                <i>
                                    {{ item.type }}
                                </i>
                            </td>
                            <td class="text-right fontMonospace">
                                {{ item.addresses_count | formatNumber }}
                            </td>
                            <td>
                                <div style="width: 32px; min-width: 32px">
                                    <v-icon v-if="hover" color="error" small>
                                        mdi-delete
                                    </v-icon>
                                </div>
                            </td>
                        </tr>
                    </v-hover>
                </template>
            </v-data-table>
        </div>
    </div>

</template>

<style scoped>

</style>