<template>
    <div class="d-flex align-center justify-center">
        <i v-if="!crypto" class="textGrey">
            Unavailable
        </i>
        <v-tooltip v-else right>
            <template v-slot:activator="{ on }">
                <img :src="currency.image" :style="{ 'width': `${iconWidth}px` }" v-on="on"/>
            </template>
            <span>{{ currency.name }}</span>
        </v-tooltip>
    </div>
</template>

<script>

export default {
    name: "BlockchainCryptoBadge",
    props: {
        crypto: {
            validator: prop => typeof prop === 'string' || prop === null,
            required: true
        },
        iconWidth: {
            type: Number,
            required: false,
        }
    },
    computed: {
        currency() {
            //on BE is for Unknown currency value `null`in crypto property, on FE is for Unknown currency value `""` in crypto  
            const crypto = this.crypto ? this.crypto : ""
            return this.currencies.find(i => i.unit === crypto)
        },
        currencies() {
            return this.$store.getters.currencies
        }
    }
}
</script>

<style scoped>
</style>