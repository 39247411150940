import { render, staticRenderFns } from "./CaseAddressTables.vue?vue&type=template&id=0190d2c2&"
import script from "./CaseAddressTables.vue?vue&type=script&setup=true&lang=js&"
export * from "./CaseAddressTables.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBadge,VChip,VImg,VLayout,VRow,VTab,VTabItem,VTabs})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0190d2c2')) {
      api.createRecord('0190d2c2', component.options)
    } else {
      api.reload('0190d2c2', component.options)
    }
    module.hot.accept("./CaseAddressTables.vue?vue&type=template&id=0190d2c2&", function () {
      api.rerender('0190d2c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/Case/Components/Addresses/CaseAddressTables.vue"
export default component.exports