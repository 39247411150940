<script setup>
import {computed, ref, watch} from "vue";
import TransactionsPaginatedList from "@/components/transaction/Components/TransactionsPaginatedList.vue";
import {processTx} from "@/utils";
import ExpandWrapper from "@/components/common/ExpandWrapper.vue";
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import FancySelect from "@/components/common/FancySelect.vue";
import SearchButton from "@/components/common/Buttons/SearchButton.vue";

const props = defineProps({
    peersTxsGetDataObject: {
        type: Object,
        required: true
    },
})

const transactionListRef = ref(null)
const resetState = () => {
    transactionListRef.value.resetState()
}
const getCurrentFilter = () => {
    return {
        filterStart: dateStart.value,
        filterEnd: dateEnd.value
    }
}

defineExpose({
    resetState,
    getCurrentFilter
})
const emit = defineEmits(["on-filter-apply-event"])

const isFilterActive = computed(() => {
    if (customEnabled.value) {
        return true
    }
    return selectedPresetTimeOption.value !== presetOptions[0].value
})
const dateStart = ref(undefined)
const dateEnd = ref(undefined)

const presetOptions = [
    {
        text: "Any time",
        value: 0
    },
    {
        text: "Last day",
        value: 24 * 60 * 60
    },
    {
        text: "Last 2 days",
        value: 2 * 24 * 60 * 60
    },
    {
        text: "Last 3 days",
        value: 3 * 24 * 60 * 60
    },
    {
        text: "Last week",
        value: 7 * 24 * 60 * 60
    },
    {
        text: "Last 2 weeks",
        value: 14 * 24 * 60 * 60
    },
    {
        text: "Last month",
        value: 30 * 24 * 60 * 60 // Assuming 30 days in a month for simplicity
    },
    {
        text: "Last 2 months",
        value: 60 * 24 * 60 * 60 // Assuming 30 days in a month for simplicity
    },
    {
        text: "Last year",
        value: 365 * 24 * 60 * 60 // Assuming 365 days in a year for simplicity
    },
];
const selectedPresetTimeOption = ref(presetOptions[0].value)
const customEnabled = ref(false)
watch(customEnabled, () => {
    if (customEnabled.value === false) {
        selectRangeByCurrentlySelectedPresetTimeOption()
    }
})

const setInitialFilterValues = () => {
    const nowInMilliseconds = Date.now();
    const nowInSeconds = Math.floor(nowInMilliseconds / 1000)
    dateEnd.value = nowInSeconds - selectedPresetTimeOption.value
    dateStart.value = 1
}
setInitialFilterValues()

watch(selectedPresetTimeOption, () => {
    selectRangeByCurrentlySelectedPresetTimeOption()
})

const selectRangeByCurrentlySelectedPresetTimeOption = () => {
    const nowInMilliseconds = Date.now();
    const nowInSeconds = Math.floor(nowInMilliseconds / 1000)

    dateEnd.value = nowInSeconds
    if (selectedPresetTimeOption.value !== 0) {
        dateStart.value = nowInSeconds - selectedPresetTimeOption.value
    } else {
        dateStart.value = 1
    }
}

</script>

<template>
    <div>
        <v-row class="mt-1" dense>
            <v-col cols="12">
                <ExpandWrapper>
                    <template #header.left>
                        <v-icon :color="isFilterActive ? 'info' : 'primary'" left>
                            mdi-calendar-filter
                        </v-icon>
                        Time filter
                    </template>
                    <v-row align="end" dense>
                        <v-col cols="3">
                            <FancySelect
                                v-model="selectedPresetTimeOption"
                                :disabled="customEnabled"
                                :items="presetOptions"
                                item-text="text"
                                item-value="value">
                            </FancySelect>
                        </v-col>
                        <v-col class="d-flex justify-center" cols="3">
                            <v-switch
                                v-model="customEnabled"
                                class="GeoIpTransactionsFilterCustomSwitch"
                                color="info"
                                inset
                                label="Custom"/>
                        </v-col>
                        <v-col cols="3">
                            <DateTimePicker
                                v-model="dateStart"
                                :disabled="!customEnabled"
                                label="Since"
                            />
                        </v-col>
                        <v-col cols="3">
                            <DateTimePicker
                                v-model="dateEnd"
                                :disabled="!customEnabled"
                                label="Until"/>
                        </v-col>
                        <v-col class="d-flex justify-center align-end" cols="12">
                            <SearchButton @click="emit('on-filter-apply-event')">
                                <template #icon>
                                    mdi-filter-check
                                </template>
                                Apply filter
                            </SearchButton>
                        </v-col>
                    </v-row>
                </ExpandWrapper>
            </v-col>
            <v-col cols="12">
                <TransactionsPaginatedList
                    ref="transactionListRef"
                    :get-data="peersTxsGetDataObject"
                    :items-per-page-default="15"
                    :tx-map-function="(tx => processTx(tx, ''))"
                    hide-timeline
                    hide-total
                />
            </v-col>
        </v-row>

    </div>

</template>

<style lang="scss">
.GeoIpTransactionsFilterCustomSwitch {
    .v-messages {
        display: none;
    }
}
</style>