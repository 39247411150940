var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.props.componentFilterType,
    _vm._b(
      {
        tag: "component",
        attrs: { componentFilterBase: _setup.BaseFilterActivatorTextField },
        on: {
          "on-remove-filter-event": function ($event) {
            return _setup.emit("on-remove-filter-event", $event)
          },
          "on-clear-event": function ($event) {
            return _setup.emit("input", undefined)
          },
          input: function ($event) {
            return _setup.emit("input", $event)
          },
        },
      },
      "component",
      _setup.attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }