<script setup>

import {useAttrs, useListeners} from "vue";
import ViewHeader from "@/components/Headers/ViewHeader.vue";

const attrs = useAttrs()
const listeners = useListeners()

const props = defineProps({
    tabs: {
        type: Array,
        required: true
    }
})
</script>

<template>
    <div>
        <ViewHeader v-bind="attrs" v-on="listeners" no-dividers>
            <template #text>
                <slot name="text"/>
            </template>
            <slot name="default"/>
        </ViewHeader>
        <v-tabs
            class="d-flex mt-1 mb-6"
            style="box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);"
            :slider-size="4"
            color="tertiary">
            <v-tab
                v-for="(tab, index) in props.tabs"
                :to="{name: tab.route}"
                :key="index">
                <v-icon left>
                    {{ tab.icon }}
                </v-icon>
                {{ tab.text }}
            </v-tab>
        </v-tabs>
    </div>
</template>
