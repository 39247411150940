var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-7 mt-0 pt-1", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("span", { staticClass: "text-h5" }, [_vm._v("Addresses")]),
                  _vm._v(" "),
                  _c("v-data-table", {
                    attrs: {
                      headers: [
                        { text: "Address", value: "item", align: "center" },
                      ],
                      height: 352,
                      items:
                        _setup.GESelectedElement.data.typeSpecific.addresses.map(
                          (x) => ({ item: x })
                        ),
                      "items-per-page": 10,
                      dense: "",
                      "fixed-header": "",
                      "no-data-text": "No addresses in this group",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function ({ item }) {
                          return [
                            _c("tr", [
                              _c(
                                "td",
                                { staticClass: "text-center fontMonospace" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.item) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("span", { staticClass: "text-h5" }, [
                    _vm._v("Transactions"),
                  ]),
                  _vm._v(" "),
                  _c("v-data-table", {
                    attrs: {
                      headers: [
                        {
                          text: "Transaction ID",
                          value: "item",
                          align: "center",
                        },
                      ],
                      height: 352,
                      items:
                        _setup.GESelectedElement.data.typeSpecific.transactions.map(
                          (x) => ({ item: x })
                        ),
                      "items-per-page": 10,
                      dense: "",
                      "fixed-header": "",
                      "no-data-text": "No addresses in this group",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function ({ item }) {
                          return [
                            _c("tr", [
                              _c(
                                "td",
                                { staticClass: "text-center fontMonospace" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.item) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }