var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.BaseDataModifyButton,
    _vm._g(
      _vm._b(
        { staticClass: "ma-1", attrs: { color: "info", small: "" } },
        "BaseDataModifyButton",
        _setup.attrs,
        false
      ),
      _setup.listeners
    ),
    [
      _c("v-icon", { attrs: { left: "" } }, [_vm._v("\n        edit\n    ")]),
      _vm._v("\n    Edit " + _vm._s(_vm.text) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }