<script setup>
import {ref} from "vue";
import mdiIcons from "../Icons/mdi-7.2.96"

const selectedIcon = ref()

const icons = mdiIcons.prototype.getIconsAsArray()

const filterIcons = (item, queryText) => {
    const queryWords = queryText.split(' ')

    //Check if query words fit the tags of an icon
    for (const queryWord of queryWords) {
        if (!item.tags.some(tag => tag.includes(queryWord))) {
            return false
        }
    }
    return true
}

const emit = defineEmits('input')

const emitIconName = (e) => {
    if (e) {
        emit('input', e.icon ? e.icon : e)
    }
}

</script>

<template>
    <v-autocomplete
        :items="icons"
        return-object
        item-text="name"
        :value="selectedIcon"
        @input="emitIconName"
        clearable
        clear-icon="mdi-close-circle"
        label="Icon"
        :filter="filterIcons"
    >
        <template #selection="{ item }">
            <v-icon left>{{ item.icon }}</v-icon>
            {{ item.name }}
        </template>
        <template #item="{ item }">
            <v-icon left>{{ item.icon }}</v-icon>
            {{ item.name }}
        </template>
    </v-autocomplete>
</template>

<style scoped>

</style>