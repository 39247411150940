<template>
    <div class="mb-4">
        <div v-if="addressInteractionsLoading">
            <progress-circular :color="currency.unit"/>
        </div>

        <div v-if="addressInteractionsData && addressInteractionsData.transfers">

            <div class="d-flex justify-start">
                <div class="mt-3">
                    <span class="title">
                        Cumulatively {{ filter.isOutgoing ? "Sent" : "Received" }}:
                    </span>
                    <fiat-convertor
                        :fiats="addressInteractionsData.fiats"
                        :value="Number(addressInteractionsData.totalValue)"
                        monospace
                        unit-light
                        unit-small
                        with-unit/>
                    <span>
                        in
                    </span>
                    <span>
                <span v-if="!isOnSplitView || $route.params.graphId">
                        <router-link
                            :to="
                        createFundsLocation(
                            {
                                queryPaths: 'all',
                                queryType: 0,
                                queryLimit: 100,
                                localAddress: filter.isOutgoing
                                    ? clusterCurrent
                                    : filter.remote,
                                remoteAddress: filter.isOutgoing
                                    ? filter.remote
                                    : clusterCurrent,
                                directions: 'both',
                                includeClusters: true,
                            },
                            {maxHops: filter.maxHops}
                        )
                    "
                        >
                        <v-tooltip top>
                             <template v-slot:activator="{ on, attrs }">
                                 <v-btn class="d-inline-block mx-1 mt-n2" label outlined small
                                        style="padding-left: 6px; padding-right: 6px"
                                        v-bind="attrs"
                                        v-on="on">
                                     <v-icon class="mr-1">timeline</v-icon>
                                     {{ addressInteractionsData.transferCount }}
                                 </v-btn>
                           </template>
                           <span>Plot Graph</span>
                           </v-tooltip>
                     </router-link>
                </span>
              <span v-else>
                    <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn class="d-inline-block mx-1 mt-n2" label outlined small
                           style="padding-left: 6px; padding-right: 6px" v-bind="attrs"
                           @click="fetchFunds({
                                                         queryPaths: 'all',
                                                    queryType: 0,
                                                    queryLimit: 100,
                                                    localAddress: filter.isOutgoing
                                                        ? clusterCurrent
                                                        : filter.remote,
                                                    remoteAddress: filter.isOutgoing
                                                        ? filter.remote
                                                        : clusterCurrent,
                                                        maxHops: filter.maxHops,
                                                    directions: 'both',
                                                    includeClusters: true,
                                                    })" v-on="on">
                                            <v-icon class="mr-1">timeline</v-icon>
                                            {{ addressInteractionsData.transferCount }}
                    </v-btn>
                    </template>
                    <span>Load Graph</span>
                    </v-tooltip>
                </span>
            </span>
                    <span class="title">transfer(s)</span>
                </div>
            </div>
            <v-row class="ml-7" justify="end" no-gutters>
                <v-col cols="auto">
                    <exportMenu :base-url="`${currency.unit}/cryptocluster/${clusterCurrent}/interactions`"
                                :query="computeQuery()"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <fancy-card :header="false" no-gutters>
                        <template #body>
                            <v-data-table
                                :footer-props="{
                                    'items-per-page-options': [30, 50, 200],
                                    'items-per-page-text': 'Clusters per page',
                                    'show-current-page': true,
                                    'showFirstLastPage': true,
                                }"
                                :headers="headers"
                                :items="computedInteractions"
                                :items-per-page="30"
                                :loading="addressInteractionsLoading"
                                class="elevation-0 mt-2"
                                style="white-space: nowrap"
                            >
                                <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                                    <div :key="h.value" class="d-inline-flex align-center">
                                        <div>
                                            {{ header.text }}
                                        </div>
                                        <div v-if="header.customFilter">
                                            <FilterIcon
                                                v-model="resultsFilter[header.customFilter.override ? header.customFilter.override : header.value]"
                                                :additionalOptions="header.customFilter.additionalOptions"
                                                :append="header.customFilter.append"
                                                :componentFilterType="header.customFilter.component"
                                                :time="header.customFilter.time"
                                                :name="header.value"
                                                :filterSubType="header.customFilter.filterSubType"
                                                explicit-apply/>
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>
                                            <div v-for="address in item.sourceAddresses" :key="address">
                                                <RouteToAddress :address="address">
                                                    <template slot="text">
                                                        <Copyable :on-copy="address">
                                                            <template #text="{on, attr}">
                                                                 <span
                                                                     style="white-space: nowrap; overflow: hidden;text-overflow: ellipsis;"
                                                                     v-bind="attr"
                                                                     v-on="on">
                                                                    {{ address | stringMiddleShortener }}
                                                                 </span>
                                                            </template>
                                                        </Copyable>
                                                    </template>
                                                </RouteToAddress>
                                            </div>
                                        </td>

                                        <td>
                                            <div v-for="address in item.destinationAddresses" :key="address">
                                                <RouteToAddress :address="address">
                                                    <template slot="text">
                                                        <Copyable :on-copy="address">
                                                            <template #text="{on, attr}">
                                                                 <span
                                                                     style="white-space: nowrap; overflow: hidden;text-overflow: ellipsis;"
                                                                     v-bind="attr"
                                                                     v-on="on">
                                                                    {{ address | stringMiddleShortener }}
                                                                 </span>
                                                            </template>
                                                        </Copyable>
                                                    </template>
                                                </RouteToAddress>
                                            </div>
                                        </td>

                                        <td>
                                            <fiat-convertor :fiats="addressInteractionsData.fiats" :value="item.value"
                                                            with-unit/>
                                        </td>
                                        <td>
                                            <div v-if="!isOnSplitView || $route.params.graphId">
                                                <router-link
                                                    :to="createFundsLocation({
                                                                queryPaths: 'all',
                                                                queryType: 0,
                                                                queryLimit: 100,
                                                                txs: item.txs,
                                                                localAddress: filter.isOutgoing
                                                                    ? clusterCurrent
                                                                    : filter.remote,
                                                                remoteAddress: filter.isOutgoing
                                                                    ? filter.remote
                                                                    : clusterCurrent,
                                                            },
                                                            {maxHops: -1}
                                                        )"
                                                    class="text-decoration-none"
                                                >
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn label outlined v-bind="attrs" v-on="on">
                                                                <v-icon class="mr-1">timeline</v-icon>
                                                                {{ item.hopCount }}
                                                            </v-btn>
                                                        </template>
                                                        <span>Plot Graph</span>
                                                    </v-tooltip>
                                                </router-link>
                                            </div>
                                            <div v-else>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn label outlined v-bind="attrs" @click="fetchFundsWithTxs({
                                                                localAddress: filter.isOutgoing
                                                                    ? clusterCurrent
                                                                    : filter.remote,
                                                                remoteAddress: filter.isOutgoing
                                                                    ? filter.remote
                                                                    : clusterCurrent,
                                                                txs: item.txs
                                            })" v-on="on">
                                                            <v-icon class="mr-1">timeline</v-icon>
                                                            {{ item.hopCount }}
                                                        </v-btn>
                                                    </template>
                                                    <span>Load Graph</span>
                                                </v-tooltip>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-for="taxes in item.txs" :key="taxes">
                                                <RouteToTransaction :transaction="taxes">
                                                    <template slot="text">
                                                        <Copyable :on-copy="taxes">
                                                            <template #text="{on, attr}">
                                                                <span
                                                                    class="fontMonospace"
                                                                    v-bind="attr"
                                                                    v-on="on">
                                                                    {{ taxes | stringMiddleShortener(16) }}
                                                                </span>
                                                            </template>
                                                        </Copyable>
                                                    </template>
                                                </RouteToTransaction>
                                            </div>
                                        </td>
                                        <td>
                                            {{ (item.firstBlocktime * 1000) | formatDateTime }}
                                        </td>
                                        <td>
                                            {{ (item.lastBlocktime * 1000) | formatDateTime }}
                                        </td>
                                    </tr>
                                </template>
                                <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                                    <span>
                                        {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                                    </span>
                                </template>
                            </v-data-table>
                        </template>
                    </fancy-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import RouteToTransaction from "@/components/common/RouteToTransaction";
import FiatConvertor from "@/components/common/FiatConvertor";
import RouteToAddress from "@/components/common/RouteToAddress";
import ProgressCircular from "@/components/common/ProgressCircular";
import Copyable from "@/components/common/Copyable";
import ExportMenu from "@/components/common/Buttons/ExportMenu";
import {computeQueryFromObject, isOnSplitView} from "@/utils";
import FancyCard from "@/components/common/Cards/FancyCard";
import FilterIcon from "@/components/Filters/FilterIcon.vue";
import {filterRows} from "@/utils/tableFiltering";
import FilterContentStrings from "@/components/Filters/FilterContentStrings.vue";
import FilterContentNumeric from "@/components/Filters/FilterContentNumeric.vue";
import {FilterStringSubtype} from "@/components/common/types/filter";

export default {
    name: "InteractionsResult",
    components: {
        FilterIcon,
        FancyCard,
        ExportMenu,
        Copyable, ProgressCircular, RouteToAddress, FiatConvertor, RouteToTransaction
    },
    data() {
        return {
            resultsFilter: {},
            filterConvertors: {},
            headers: [
                {
                    text: 'Source Addresses',
                    align: 'center',
                    value: 'sourceAddresses',
                    customFilter: {
                        component: FilterContentStrings,
                        filterSubType: FilterStringSubtype.Addresses
                    },
                },
                {
                    text: 'Destination Addresses',
                    align: 'center',
                    value: 'destinationAddresses',
                    customFilter: {
                        component: FilterContentStrings,
                        filterSubType: FilterStringSubtype.Addresses
                    },
                },
                {text: 'Value', align: 'center', value: 'value', customFilter: {component: FilterContentNumeric}},
                {
                    text: 'Hop Count',
                    align: 'center',
                    value: 'hopCount',
                    customFilter: {component: FilterContentNumeric}
                },
                {
                    text: 'Transaction Path',
                    align: 'center',
                    value: 'txs',
                    customFilter: {
                        component: FilterContentStrings,
                        filterSubType: FilterStringSubtype.Transactions
                    },
                },
                {
                    text: 'First Tx',
                    align: 'center',
                    value: 'firstBlocktime',
                    customFilter: {component: FilterContentNumeric, time: true}
                },
                {
                    text: 'Last Tx',
                    align: 'center',
                    value: 'lastBlocktime',
                    customFilter: {component: FilterContentNumeric, time: true}
                },
            ]
        }
    },
    props: {
        currency: {
            type: Object,
        },
        addressInteractionsLoading: {
            type: Boolean,
        },
        addressInteractionsData: {
            type: Object
        },
        filter: {
            type: Object
        },
        clusterCurrent: {
            type: String
        },
    },
    computed: {
        computedInteractions() {
            return filterRows(this.addressInteractionsData.transfers, this.resultsFilter, this.filterConvertors)
        },
        isOnSplitView() {
            return isOnSplitView(this.$route)
        },
    },
    methods: {
        computeQuery() {
            return computeQueryFromObject(this.filter)
        },
        fetchFunds(settings) {
            this.$store.dispatch("loadFundsNetwork", {
                source: settings.localAddress,
                destination: settings.remoteAddress,
                maxHops: settings.maxHops,
                currency: this.currency.unit,
                paths: settings.queryPaths,
                asAddress: settings.queryType,
                limit: settings.queryLimit,
                includeClusters: settings.includeClusters,
                directions: settings.directions

            });
        },
        fetchFundsWithTxs(settings) {
            this.$store.dispatch("loadFundsNetworkWithTxs", {
                source: settings.localAddress,
                destination: settings.remoteAddress,
                maxHops: settings.maxHops,
                currency: this.currency.unit,
                paths: settings.queryPaths,
                asAddress: settings.queryType,
                limit: settings.queryLimit,
                txs: typeof settings.txs === "string" ? [settings.txs] : settings.txs
            });
        },
        createFundsLocation({
                                queryPaths,
                                queryType,
                                queryLimit,
                                txs,
                                localAddress,
                                remoteAddress,
                                directions,
                                includeClusters
                            }, {maxHops}) {
            const name = this.$route.params.detailCluster || this.$route.params.clusterentity ? "FundsNetworkCluster" : "FundsNetwork"
            return {
                name: name,
                params: {
                    ...this.$route.params,
                    source: localAddress,
                    destination: remoteAddress,
                    maxHops: maxHops,
                    address: !(this.$route.params.detailCluster || this.$route.params.clusterentity) && !this.$route.params.address ? this.$route.params.detailAddress : this.$route.params.address,
                    clusterentity: this.$route.params.detailCluster ?? this.$route.params.clusterentity,
                },
                query: {
                    paths: queryPaths,
                    asAddress: !queryType && !queryPaths ? undefined : (queryType ? 1 : 0),
                    limit: queryLimit,
                    txs: typeof txs === "string" ? [txs] : txs,
                    directions: directions,
                    includeClusters: includeClusters,
                }
            }

        }
    }
}
</script>

<style scoped>

.cryptoTitle {
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
}

</style>