var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_setup.hideTable,
          expression: "!hideTable",
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-end" },
        [
          _vm._t("header-left"),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm._t("header-right", null, {
            getItems: _setup.selectedItemsToArray,
            isAnyItemSelected: _setup.isAnyItemSelected,
            isDataPatched: _setup.isDataPatched,
            patchActions: _setup.patchActions,
            refreshItems: _setup.refreshItems,
            removeSelection: _setup.removeSelection,
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-0",
        attrs: {
          "footer-props": _vm.footerProps,
          headers: _setup.extendedHeaders,
          "hide-default-footer": _setup.hideFooter,
          "hide-default-header": _setup.hideHeader,
          items: _setup.tableData,
          loading: _setup.loading,
          "no-data-text": _setup.props.noDataText,
          options: _setup.tableOptions,
          "server-items-length": _setup.tableDataTotalItems,
          "items-per-page": _vm.itemsPerPage,
          "sort-desc": _vm.sortDesc,
          height: _vm.computedHeight ? _setup.tableHeight : undefined,
          dense: "",
        },
        on: {
          "update:options": function ($event) {
            _setup.tableOptions = $event
          },
        },
        scopedSlots: _vm._u(
          [
            _vm.customHeaders
              ? _vm._l(_vm.headers, function (h) {
                  return {
                    key: `header.${h.value}`,
                    fn: function ({ header }) {
                      return [_vm._t("headers", null, { header: header })]
                    },
                  }
                })
              : null,
            _vm.bulkOperations
              ? {
                  key: "header.select",
                  fn: function () {
                    return [
                      _c("v-checkbox", {
                        staticClass: "mt-0",
                        attrs: {
                          disabled:
                            _setup.loading || !_setup.userHasWritePermissions,
                          indeterminate:
                            _setup.selectedItemsIds.length !== 0 &&
                            !_setup.isWholePageSelected,
                          value: _setup.isWholePageSelected,
                          "hide-details": "",
                        },
                        on: { change: _setup.onSelectAll },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _c(
                    "tr",
                    {
                      class: {
                        pointer:
                          _vm.bulkOperations && _setup.userHasWritePermissions,
                      },
                      staticStyle: {
                        position: "relative",
                        "white-space": "nowrap",
                      },
                      on: {
                        click: function ($event) {
                          _setup.userHasWritePermissions &&
                            _setup.onSelect(item)
                        },
                      },
                    },
                    [
                      _c("td", {
                        class: _setup.getItemsPatchStatusClass(item),
                        staticStyle: { padding: "0", width: "4px" },
                      }),
                      _vm._v(" "),
                      _vm.bulkOperations
                        ? _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0",
                                attrs: {
                                  disabled: !_setup.userHasWritePermissions,
                                  "input-value":
                                    _setup.selectedItemsIds.includes(
                                      item[_setup.props.item_id]
                                    ),
                                  dense: "",
                                  "hide-details": "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _setup.onSelect(item)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("item", null, { item: item }),
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "footer.page-text",
              fn: function ({ pageStart, pageStop, itemsLength }) {
                return [
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("formatNumber")(pageStart)) +
                        " - " +
                        _vm._s(_vm._f("formatNumber")(pageStop)) +
                        " of " +
                        _vm._s(_vm._f("formatNumber")(itemsLength)) +
                        "\n            "
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }