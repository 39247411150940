<script setup>
import { useNumericFilter } from "@/components/Filters/Composables/useNumericFilter.ts"
import DateTimePicker from "@/components/common/DateTimePicker";
import {computed, onUnmounted, ref, watch} from "vue";

const props = defineProps({
    //Visible Label
    label: {
        type: String,
        required: false,
    },
    //Defines modelled name
    name: {
        type: String,
        required: true
    },
    //Defined string to append to textField
    append: {
        type: String,
        required: false,
    },
    //Switches text-fields to date-pickers
    time: {
        type: Boolean,
        required: false,
        default: false,
    },
    componentFilterBase: {
        type: Object,
        required: true
    },
    explicitApply: {
        type: Boolean,
        required: false,
        default: false
    },
    value: {
        type: Object,
        required: false
    }
})
const emit = defineEmits(['input', 'on-remove-filter-event'])

const {
    numericModes,
    numericFilterValueToReadable,
    numericFilterValueConverter
} = useNumericFilter()

const filterValue = ref({ min: undefined, max: undefined })
const selectedFilterMode = ref(numericModes.value[0])

const valueWatchable = computed(() => props.value)
watch(valueWatchable, () => {
    filterValue.value['min'] = valueWatchable.value?.values[0]
    filterValue.value['max'] = valueWatchable.value?.values[1]
    selectedFilterMode.value = numericModes.value.find(m => m.operation === valueWatchable.value?.op) ?? numericModes.value[0]
}, { immediate: true })

const filterMenu = ref(null)

const filterActive = ref(false)

const emitInputValue = () => {
    filterMenu.value.closeMenu()
    const valueToEmit = numericFilterValueConverter(selectedFilterMode, filterValue)
    filterActive.value = !!valueToEmit
    emit('input', valueToEmit)
}

if (!props.explicitApply) {
    watch([selectedFilterMode, filterValue], ([mode, value]) => emit('input', numericFilterValueConverter(mode, value)), {
        deep: true,
        immediate: true
    })
}

onUnmounted(() => emit('input', undefined))
</script>

<template>
    <component
        ref="filterMenu"
        :is="props.componentFilterBase"
        @on-clear-event="filterValue = {}"
        @on-remove-filter-event="emit('on-remove-filter-event', props.label)"
        :value="props.explicitApply ? filterActive : numericFilterValueToReadable(filterValue, selectedFilterMode, props.time)"
    >
        <template #label>
            {{ props.label }}
        </template>
        <template #icon>
            {{ selectedFilterMode.icon }}
        </template>
        <template #append v-if="props.append">
            {{ props.append }}
        </template>
        <template #menuContent>
            <v-list dense>
                <v-list-item-group
                    v-model="selectedFilterMode"
                    mandatory
                    color="indigo"
                >
                    <v-list-item
                        dense
                        v-for="(mode, i) in numericModes"
                        :key="i"
                        :value="mode"
                    >
                        <v-list-item-icon>
                            <v-row justify="center">
                                <v-icon size="21" v-text="mode.icon"></v-icon>
                            </v-row>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title v-text="mode.text"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <div class="mx-2 mb-2">
                <div
                    v-for="(fieldSetting, i) in selectedFilterMode.fields"
                    :key="i"
                >
                    <div class="d-flex" style="max-width: 245px">
                        <div v-if="fieldSetting.label && !props.time" class="mt-2 mr-2 font-weight-bold text-center caption" style="min-width: 40px; color: rgba(0,0,0,0.6)">
                            {{ String(fieldSetting.label).toUpperCase() }}:
                        </div>
                        <div class="txFilters width100">
                            <DateTimePicker
                                v-if="props.time"
                                :label="fieldSetting.label"
                                dense
                                outlined
                                autofocus
                                clear-icon="mdi-close-circle"
                                emit-eagerly
                                v-model="filterValue[fieldSetting.name]"
                            />
                            <v-text-field
                                :placeholder="fieldSetting.placeholder"
                                v-else
                                clear-icon="mdi-close-circle"
                                full-width
                                outlined
                                autofocus
                                dense
                                v-model="filterValue[fieldSetting.name]"
                            >
                                <template #append>
                                    <div class="font-weight-bold caption body-1"
                                         style="color: rgba(0,0,0,0.6); margin-top: 2px">
                                        {{ props.append }}
                                    </div>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="props.explicitApply" class="d-flex" style="padding: 0 8px 8px 8px; margin-top: -6px">
                <v-btn
                    dark
                    color="grey"
                    small
                    @click="filterValue = {}; emitInputValue()"
                >
                    <v-icon left small>
                        mdi-close-circle
                    </v-icon>
                    CLEAR
                </v-btn>
                <v-spacer/>
                <v-btn
                    dark
                    color="indigo"
                    small
                    @click="emitInputValue"
                >
                    <v-icon left small>
                        mdi-filter-check
                    </v-icon>
                    Apply
                </v-btn>
            </div>
        </template>
    </component>
</template>

<style>

.txFilters input {
    font-family: 'Roboto Mono', monospace !important;
    margin-bottom: 2px;
}

</style>