<script setup>
import DataProvider from "@/components/common/DataProvider.vue";
import {computed, provide, ref, watch} from "vue";
import {useRoute} from "vue-router/composables";
import store from "@/store";
import {useGetData} from "@/components/common/Composables/useGetData";
import EntityHeaderLabel from "@/views/Cluster/EntityHeaderLabel.vue";

const route = useRoute()

const clusterCurrent = computed(() => route.params.detailCluster)

const currency = computed(() => store.getters.currency)

store.dispatch('loadCategories')

const graphCloseBottomDrawer = () => {
    store.dispatch("graphCloseBottomDrawer", {sides: route.params.sides});
}


const drawerTabs = ref()
const onClick = () => {
    //fixes out-of-sync slider
    drawerTabs.value && drawerTabs.value.onResize();
}

const isOnCanvasPage = computed(() => {
    return (
        route.matched.findIndex(
            route => route.name === "TransactionNetwork"
        ) !== -1
    );
})

const isOnGraphPage = computed(() => {
    return (
        route.matched.findIndex(
            route => route.name === "GraphSingleView"
        ) !== -1
    );
})

const isOnFundsNetwork = computed(() => {
    return (
        route.matched.findIndex(
            route => route.name === "FundsNetwork"
        ) !== -1
    );
})

const url = computed(() => {
    if (isOnGraphPage.value) {
        //graph view
        return (
            "/" +
            currency.value.unit +
            "/graph/" +
            route.params.graphId +
            "/config/" +
            route.params.graphConfigId +
            "/node/" +
            route.params.nodeId +
            "/cluster/" +
            route.params.detailCluster
        );
    } else if (isOnCanvasPage.value) {
        //transaction view graph
        return (
            "/" +
            currency.value.unit +
            "/transaction/" +
            route.params.transaction +
            "/network/" +
            route.params.sides +
            "/detail/cluster/" +
            route.params.detailCluster
        );
    } else if (isOnFundsNetwork.value && route.params.address) {
        //address funds graph
        return (
            "/" +
            currency.value.unit +
            "/address/" +
            route.params.address +
            "/funds/paths/source/" +
            route.params.source +
            "/destination/" +
            route.params.destination +
            "/maxHops/" +
            route.params.maxHops +
            "/detail/cluster/" +
            route.params.detailCluster
        );
    } else { // Cluster funds view
        return (
            "/" +
            currency.value.unit +
            "/cluster/" +
            route.params.clusterentity +
            "/funds/paths/source/" +
            route.params.source +
            "/destination/" +
            route.params.destination +
            "/maxHops/" +
            route.params.maxHops +
            "/detail/cluster/" +
            route.params.detailCluster
        );
    }
})

const removeGraphNode = () => {
    store.dispatch("removeGraphNode", {
        hash: route.params.detailCluster
    });
}

const tabs = [
    {route: "/summary", publicName: "Summary"},
    {route: "/transactions", publicName: "Transactions"},
    {route: "/funds", publicName: "Funds"},
    {route: "/interactions", publicName: "Interactions"}
]

const finalRoutes = computed(() => {
    return tabs.map(tab => {
        return {...tab, route: url.value + tab.route}
    });
})

const { dataState, data, getData } = useGetData("getClusterInfo", {
    currency: currency.value.unit,
    entity: clusterCurrent.value
})

watch(clusterCurrent, (value, oldValue) => {
    if(value !== oldValue) {
        getData({entity: value})
    }
})

provide('clusterInfo', data)
provide('clusterInfoState', dataState)
provide('entityId', clusterCurrent.value)
provide('getClusterInfo', computed(() => getData))

</script>

<template>
    <v-layout fill-height>
        <v-layout style="position: absolute; width: 100%" column>
            <v-app-bar style="position: sticky; z-index: 1; top: 0;" dense color="white">
                <v-tabs
                    light
                    background-color="white"
                    :slider-color="currency.unit"
                    ref="drawerTabs"
                >
                    <v-tab
                        v-for="({route, publicName}) in finalRoutes"
                        :key="route"
                        :to="route"
                    >{{ publicName }}
                    </v-tab>
                </v-tabs>
                <v-spacer/>
                <EntityHeaderLabel :label="currency.unit.toUpperCase() + ' CLUSTER:'" :entity="clusterCurrent"/>
                <a
                    class="text-decoration-none"
                    :href="'/' + currency.unit + '/cluster/' + $route.params.detailCluster + '/'"
                    target="_blank"
                >
                    <v-btn
                        color="rgba(0, 0, 0, 0.7)"
                        icon
                        class="mx-1"
                        small
                    >
                        <v-icon style="font-size: 20px">open_in_new</v-icon>
                    </v-btn>
                </a>
                <v-tooltip top v-if="!isOnGraphPage">
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-btn
                                disabled
                                color="rgba(0, 0, 0, 0.7)"
                                icon
                                class="mx-1"
                                small
                                @click="removeGraphNode"
                            >
                                <v-icon style="font-size: 20px">delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <span>This view is read-only. Save this<br> configuration if you want to edit it.</span>
                </v-tooltip>
                <v-btn
                    v-else-if="isOnGraphPage"
                    color="rgba(0, 0, 0, 0.7)"
                    icon
                    class="mx-1"
                    small
                    @click="removeGraphNode"
                >
                    <v-icon style="font-size: 20px">delete</v-icon>
                </v-btn>
                <v-btn
                    class="mx-1"
                    color="rgba(0, 0, 0, 0.7)"
                    icon
                    small
                    @click="graphCloseBottomDrawer">
                    <v-icon style="font-size: 20px">close</v-icon>
                </v-btn>
            </v-app-bar>
            <v-container fluid class="height100">
                <DataProvider name="entityId" :data="clusterCurrent">
                    <router-view @component-loaded-event="onClick"/>
                </DataProvider>
            </v-container>
        </v-layout>
    </v-layout>
</template>

<style scoped>

</style>