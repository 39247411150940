<template>
    <div class="height100 scroll-y">
        <div v-if="addressLoading">
            <ProgressCircular class="pt-10" :color="currency.unit" />
        </div>

        <v-container v-if="!addressLoading && !addressInfo" >
            <div v-if="addressInfo === null || !addressId">
                <AddressNotFoundInfo
                    class="my-2"
                    :address="addressId"
                    :message="'Provided identifier does not exist in the blockchain!'"
                />
            </div>
        </v-container>

        <v-container v-if="!addressLoading && addressInfo" fluid class="pa-0 ma-0">
            <LimitAbleLayout
                :init-values="filterInitValues"
                @reload-data="reloadData">
                <template slot="title">
                    <h1>Time Patterns</h1>
                </template>
                <template slot="body">
                    <div v-if="!addressTxsOverallLoading && addressTxsOverallYears.length < 1">
                        <div class="d-flex align-center justify-center pt-10" style="height: 80%; color: rgba(0,0,0,0.6)">
                            <div class="title">No data matched provided filter!</div>
                        </div>
                    </div>
                    <v-row v-else no-gutters>
                        <v-col cols="12" sm="6" md="8">
                            <ChartHours
                                :hoursR="addressTxsOverallHours.receiving"
                                :hoursS="addressTxsOverallHours.sending"
                                :isLoading="addressTxsOverallLoading"
                            />
                        </v-col>
                        <v-col cols="6" md="4">
                            <ChartDays
                                :daysR="addressTxsOverallDays.receiving"
                                :daysS="addressTxsOverallDays.sending"
                                :isLoading="addressTxsOverallLoading"
                            />
                        </v-col>

                        <v-col cols="12" sm="6" md="8">
                            <ChartMonths
                                :monthsR="addressTxsOverallMonths.receiving"
                                :monthsS="addressTxsOverallMonths.sending"
                                :isLoading="addressTxsOverallLoading"
                            />
                        </v-col>

                        <v-col cols="6" md="4">
                            <ChartYears
                                :years="addressTxsOverallYears"
                                :yearsR="returnYearsReceiving"
                                :yearsS="returnYearsSending"
                                :isLoading="addressTxsOverallLoading"
                            />
                        </v-col>
                    </v-row>
                </template>
            </LimitAbleLayout>
        </v-container>
    </div>
</template>

<script>
import ChartHours from "../chart/ChartHours";
import ChartDays from "../chart/ChartDays";
import ChartYears from "../chart/ChartYears";
import ChartMonths from "../chart/ChartMonths";
import LimitAbleLayout from "../common/LimitAbleLayout";
import ProgressCircular from "@/components/common/ProgressCircular";
import { mapGetters } from 'vuex';
import AddressNotFoundInfo from '@/components/address/AddressNotFoundInfo'

export default {
    name: "AddressPatterns",
    components: { ChartDays, ChartHours, ChartYears, ChartMonths, LimitAbleLayout, AddressNotFoundInfo, ProgressCircular },
    beforeUpdate() {
        //Update visual slider (workaround)
        this.$emit("component-loaded-event");
    },
    computed: {
        ...mapGetters(["addressId", "addressLoading", "addressInfo", "currency", "addressTxsOverallDays", "addressTxsOverallYears",
            "addressTxsOverallHours", "addressTxsOverallMonths", "addressTxsOverallYearsReceiving", "addressTxsOverallYearsSending", "addressTxsOverallLoading"]),
        filterInitValues() {
            return {
                since: this.addressInfo.firstTx.time,
                until: this.addressInfo.lastTx.time,
                selectedButtonIndex: 3,
                offset: {
                    amount: 1,
                    unit: 'year'
                }
            }
        },
        returnYearsReceiving() {
            var result = Object.keys(this.addressTxsOverallYearsReceiving).map((key) => [this.addressTxsOverallYearsReceiving[key]]);
            return result
        },
        returnYearsSending() {
            var result = Object.keys(this.addressTxsOverallYearsSending).map((key) => [this.addressTxsOverallYearsSending[key]]);
            return result
        },

    },
    methods: {
        reloadData(data) {
            this.$store.dispatch("loadTxsOverall", { currency: this.currency.unit, addressId: this.addressId, filter: data });
        },
    }
};
</script>
