<template>
    <v-container class="px-4 allClusterMetadata" fluid>
        <BulkMergeClustersDialog
            ref="mergeDialogRef"
            :clusters="selectedClustersToArray"
            :currency-unit="this.filter.filterCurrency"
            redirect
            @created-event="() => {refresh();removeSelection()}"/>
        <v-layout align-center>
            <h1>All Cluster Metadata</h1>
            <v-spacer/>
            <MergeButton :disabled="selectedClustersNames.length === 0" class="ml-1 mr-2"
                         @click="$refs.mergeDialogRef.open()"/>
        </v-layout>
        <v-divider/>
        <v-divider/>
        <nobr>
            <v-data-table
                :footer-props="{
                    'items-per-page-options': [10,30,50,100],
                    'show-current-page': true,
                    'showFirstLastPage': true,
                }"
                :headers="headers"
                :items="neoClusterMetadata.data"
                :loading="neoClusterMetadataLoading"
                :options.sync="filter"
                :server-items-length="neoClusterMetadata.total ? neoClusterMetadata.total : 0"
                class="elevation-0 border my-2"
                dense
            >
                <template
                    v-for="h in headers"
                    v-slot:[`header.${h.value}`]="{ header }"
                >
                    <v-checkbox
                        v-if="h.value === 'select'"
                        :key="h.value"
                        :disabled="neoClusterMetadataLoading || !userHasWritePermissions"
                        :indeterminate="selectedClustersNames.length !== 0 && !isWholePageSelected"
                        :value="isWholePageSelected"
                        @change="onSelectClusters"
                    />
                    <v-text-field
                        v-if="h.value === 'cluster'"
                        :key="h.value"
                        v-model="debouncedFilter.filterEntityId"
                        :label="header.text"
                        append-icon="search"
                        class="text-field-label-style"
                        clearable
                        hide-details
                        single-line
                        style="padding-bottom: 18px;"
                        @input="debounceInput"
                        @click.stop.native
                    />

                    <div
                        v-if="h.value === 'crypto'"
                        :key="h.value"
                        style="padding-top: 7px; margin-bottom: auto; "
                    >
                        <BlockchainCryptoPickerSlim
                            v-model="filter.filterCurrency"
                            :currencies="allCurrenciesExceptUnknown"
                            :isClearable="false"
                            dense
                            @input="removeSelection"
                            @click.stop.native
                        />
                    </div>

                    <div v-if="h.value ==='isCustom'" :key="h.value">
                        <h3>{{ h.text }}</h3>
                    </div>

                    <div v-if="h.value ==='totalAddresses'" :key="h.value" style="max-width: 100px">
                        <h3>{{ h.text }}</h3>
                    </div>

                    <v-text-field
                        v-if="h.value === 'owner'"
                        :key="h.value"
                        v-model="debouncedFilter.filterOwner"
                        :label="header.text"
                        append-icon="search"
                        class="shrink text-field-label-style"
                        clearable
                        hide-details
                        single-line
                        style="padding-bottom: 18px;"
                        @input="debounceInput"
                        @click.stop.native
                    />

                    <div
                        v-if="h.value === 'categories'"
                        :key="h.value"
                        style="margin-bottom: auto;"
                    >
                        <CategoryPicker
                            v-model="filter.filterCategories"
                            :categories="categories"
                            :dense="true"
                            class="text-field-label-style"
                            @click.stop.native
                        />
                    </div>

                    <div v-if="h.value ==='created_at'" :key="h.value"><h3>{{ h.text }}</h3></div>

                    <div v-if="h.value === 'action'" :key="h.value"><h3>{{ h.text }}</h3></div>
                </template>
                <template #item="{ item }">
                    <tr :class="{'pointer': userHasWritePermissions}" @click="userHasWritePermissions && onSelectCluster(item)">
                        <td class="text-center">
                            <v-checkbox
                                :input-value="selectedClustersNames.includes(item.entity_id)"
                                class="mt-0"
                                dense
                                :disabled="!userHasWritePermissions"
                                hide-details
                                @click.stop="onSelectCluster(item)"/>
                        </td>
                        <td class="text-left fontMonospace">
                            <router-link
                                :to="'/' + filter.filterCurrency + '/cluster/' + item.cluster"
                                class="link"
                            >
                                <Copyable :on-copy="item.cluster" justify="left">
                                    <template #text="{ on }">
                                    <span v-on="on">
                                        <nobr>
                                            {{ item.cluster | stringMiddleShortener(34) }}
                                        </nobr>
                                    </span>
                                    </template>
                                </Copyable>
                            </router-link>
                        </td>
                        <td style="text-align: center; vertical-align: middle;">
                            <BlockchainCryptoBadge :crypto="item.crypto" :currencies="currencies" :iconWidth="24"/>
                        </td>
                        <td class="text-left">{{ item.isCustom ? "custom" : "co-spent" }}</td>
                        <td class="text-right">{{ item.totalAddresses | formatNumber }}</td>
                        <td class="text-left">
                            <div class="d-flex">
                                <AddressCategoryIcon
                                    v-for="cat in item.categories.slice(0, 2)"
                                    v-bind:key="cat.id"
                                    :iconItem="cat"
                                    class="mr-2"
                                    medium
                                />
                                <BaseButtonShowMoreItems
                                    :items="item.categories"
                                    :shownItemsCount="2"
                                >
                                    <AddressCategoryIcon
                                        v-for="cat in item.categories"
                                        :key="cat.id"
                                        :color="cat.color"
                                        :iconItem="cat"
                                        :name="cat.name"
                                        class="mx-1"
                                        medium
                                    />
                                </BaseButtonShowMoreItems>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="d-flex align-center" style="max-width: 200px">
                                <div v-for="own in item.owners.slice(0, 1)" v-bind:key="own.id">
                                    {{ own.name }}
                                </div>
                                <BaseButtonShowMoreItems
                                    :items="item.owners"
                                    :shownItemsCount="1"
                                >
                                    <div
                                        v-for="owner in item.owners"
                                        :key="owner.id"
                                        class="mx-1 fontMonospace"
                                        style="font-size: 0.8em"
                                    >
                                        {{ owner.name }}
                                    </div>
                                </BaseButtonShowMoreItems>
                            </div>
                        </td>
                        <td style="text-align: center; vertical-align: middle;">
                            {{ item.created_at | moment('YYYY-MM-DD HH:mm:ss Z') }}
                        </td>

                        <td style="text-align: center; vertical-align: middle; min-width: 92px">
                            <AllClusterMetadataDetailModal
                                :key="item.id"
                                :categories="categories"
                                :clusterMetadataDetail="neoClusterMetadataDetail"
                                :crypto="filter.filterCurrency"
                                :currencies="currencies"
                                :isOwnersLoading="isOwnersLoading"
                                :loading="isNeoClusterMetadataDetailLoading"
                                :owners="owners.data"
                                @owner-filter-changed-event="ownerFilterChanged"
                                @save-event="editClusterMetadata"
                            >

                                <template #showbutton="{on}">
                                    <TableEditButton
                                        editButtonTooltip="Edit All Cluster Metadata"
                                        @click="loadNeoClusterMetadataDetail({
                                        currency: filter.filterCurrency,
                                        clusterMetadataId: item.cluster,
                                        pagination: addresspagination,
                                    })"
                                        v-on="on"
                                    />
                                </template>
                            </AllClusterMetadataDetailModal>

                        </td>
                    </tr>
                </template>
                <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                    <span>
                        {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{
                            itemsLength | formatNumber
                        }}
                    </span>
                </template>
            </v-data-table>
        </nobr>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import AddressCategoryIcon from "@/components/address/AddressCategoryIcon";
import AllClusterMetadataDetailModal from '@/components/ClusterMetadata/AllClusterMetadataDetailModal'
import {BlockchainCryptoBadge, BlockchainCryptoPickerSlim, CategoryPicker, TableEditButton} from "@/components/common"
import debounce from "debounce";
import Copyable from "@/components/common/Copyable";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import BaseButtonShowMoreItems from "@/components/common/TableComponents/BaseButtonShowMoreItems";
import {useGetActiveCurrencyOrDefault} from "@/components/Composables/useGetActiveCurrencyOrDefault";
import BulkMergeClustersDialog from "@/views/ClusterMetadata/Dialogs/BulkMergeClustersDialog.vue";
import MergeButton from "@/components/common/Buttons/MergeButton.vue";

export default {
    name: 'AllClusterMetadataView',
    mixins: [routeSyncMixin],
    components: {
        MergeButton,
        CategoryPicker,
        BulkMergeClustersDialog,
        BaseButtonShowMoreItems,
        Copyable,
        AllClusterMetadataDetailModal,
        TableEditButton,
        BlockchainCryptoBadge,
        BlockchainCryptoPickerSlim,
        AddressCategoryIcon,
    },
    computed: {
        ...mapGetters([
            "neoClusterMetadata",
            "neoClusterMetadataLoading",
            "neoClusterMetadataDetail",
            "isNeoClusterMetadataDetailLoading",
            "isNeoClusterDetailPaginationLoading",
            "allCurrenciesExceptUnknown",
            "owners",
            "categories",
            "isOwnersLoading",
            "currencies",
            "currency"
        ]),
        userHasWritePermissions() {
            return this.$store.getters.hasPermission("write")
        },
        isWholePageSelected() {
            if (!this.neoClusterMetadata?.data?.length)
                return false

            if (this.selectedClustersNames.length < this.neoClusterMetadata.data.length)
                return false

            return this.neoClusterMetadata.data.every(x => this.selectedClustersNames.includes(x.entity_id))
        },
        selectedClustersToArray() {
            if (this.selectedClusters.size !== this.selectedClustersNames.length) {
                console.error("Mismatched selected clusters")
            }
            return Array.from(this.selectedClusters.values())
        }
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
                sortDesc: {type: "Boolean", default: () => false},
                filterEntityId: {type: "String", default: () => ''},
                filterOwner: {type: "String", default: () => ''},
                filterCurrency: {type: "String", default: () => ''},
                filterCategories: {type: "ArrayOfNumbers", default: () => []}
            },
            debouncedFilter: {
                filterEntityId: undefined,
                filterOwner: undefined,
            },
            categoriespagination: {
                page: 1,
                itemsPerPage: 100,
            },
            addresspagination: {
                page: 1,
                itemsPerPage: 15,
            },
            headers: [
                {text: '', value: 'select', align: 'center', width: 42, sortable: false, filterable: false},
                {text: 'Entity ID', value: 'cluster', align: 'center', sortable: false},
                {text: 'Currency', value: 'crypto', align: 'left', sortable: false, width: 130},
                {text: 'Type', value: 'isCustom', align: 'center', sortable: false},
                {text: 'Address Count', value: 'totalAddresses', align: 'center', sortable: false, width: 100},
                {text: 'Categories', value: 'categories', align: 'center', sortable: false},
                {text: 'Owners', value: 'owner', align: 'center', sortable: false},
                {text: 'Created At', value: 'created_at', align: 'center', sortable: false},
                {text: 'Action', value: 'action', align: 'center', sortable: false}
            ],
            selectedClusters: new Map(),
            selectedClustersNames: []
        }
    },
    watch: {
        '$route.query.filterCurrency': {
            handler(newValue) {
                if (!newValue) {
                    this.$_syncToRouteQuery(true, {
                        ...this.$route.query,
                        filterCurrency: useGetActiveCurrencyOrDefault(this.$route)
                    })
                }
            }
        }
    },
    created() {
        this.$_syncToRouteQuery(true, {
            ...this.$route.query,
            filterCurrency: useGetActiveCurrencyOrDefault(this.$route)
        })
        this.$store.dispatch('loadCategories')
    },
    mounted() {
        this.onClick()
    },
    methods: {
        onSelectClusters() {
            if (this.isWholePageSelected) {
                //remove all items from this page
                this.neoClusterMetadata.data.forEach(d => {
                    if (this.selectedClusters.delete(d.entity_id)) {
                        this.selectedClustersNames.splice(this.selectedClustersNames.indexOf(d.entity_id), 1)
                    }
                })
            } else {
                //select missing items
                this.neoClusterMetadata.data.forEach(d => {
                    this.selectedClusters.set(d.entity_id, d)
                    if (!this.selectedClustersNames.includes(d.entity_id)) {
                        this.selectedClustersNames.push(d.entity_id)
                    }
                })
            }
        },
        onSelectCluster(cluster) {
            if (this.selectedClusters.has(cluster.entity_id)) {
                this.selectedClusters.delete(cluster.entity_id)
                this.selectedClustersNames.splice(this.selectedClustersNames.indexOf(cluster.entity_id), 1)

            } else {
                this.selectedClustersNames.push(cluster.entity_id)
                this.selectedClusters.set(cluster.entity_id, cluster)
            }
        },
        removeSelection() {
            this.selectedClusters = new Map()
            this.selectedClustersNames = []
        },
        onClick() {
            //fixes out-of-sync slider
            this.$refs.tabs && this.$refs.tabs.onResize();
        },
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),
        refresh() {
            if (this.filter.filterCurrency) {
                this.$store.dispatch('loadNeoClusterMetadata', {
                    currency: this.filter.filterCurrency,
                    pagination: this.filter
                })
            }
        },
        async editClusterMetadata({currency, clusterMetadataDetail}) {
            await this.$store.dispatch("updateNeoClusterMetadata", {
                currency: currency,
                updatedClusterMetadata: clusterMetadataDetail
            })
            this.refresh()
        },

        loadNeoClusterMetadataDetail({currency, clusterMetadataId}) {
            this.$store.dispatch("loadNeoClusterMetadataDetail", {currency, clusterMetadataId})
        },

        ownerFilterChanged({filter}) {
            this.$store.dispatch('loadOwners', {filter, details: "basic"})
        },

    },

}
</script>

<style>
.allClusterMetadata .v-text-field__details {
    display: none !important;
}

.allClusterMetadata .v-input__slot {
    margin-bottom: auto;
}

</style>

