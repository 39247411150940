<script setup>
import store from "@/store";
import {computed} from "vue";

const props = defineProps({
    blockHeight: {
        type: Number,
        required: true
    },
    blockNotMinedMessage: {
        type: String,
        required: false,
        default: "Block has yet to be mined."
    }
})

const currencyUnit = computed(() => store.getters.currency.unit)

const blockNotMined = computed(() => props.blockHeight === -1)

</script>

<template>
    <div v-if="blockNotMined" class="fontMonospace">
        {{ blockNotMinedMessage }}
    </div>
    <router-link
        v-else
        :to="{
            name: 'Block',
            params: {
                block: blockHeight,
                currency: currencyUnit
            }
        }"
        class="link fontMonospace"
    >
        {{ blockHeight | formatNumber }}
    </router-link>
</template>

<style scoped>

</style>