var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("notification-method-base", {
    scopedSlots: _vm._u([
      {
        key: "iconName",
        fn: function ({ on }) {
          return [
            _c("v-icon", _vm._g({ attrs: { color: "green" } }, on), [
              _vm._v("\n            mdi-email-outline\n        "),
            ]),
          ]
        },
      },
      {
        key: "tooltip",
        fn: function () {
          return [_vm._v("\n        E-mail\n    ")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }