var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("v-checkbox", {
        staticClass: "mt-0 mb-n4",
        attrs: { color: "tertiary", dense: "", "input-value": _vm.value },
        on: {
          change: function ($event) {
            return _setup.emit("input", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { "open-delay": "1000", bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                {
                                  attrs: {
                                    color: _vm.value ? "primary" : "tertiary",
                                    dark: "",
                                    size: "28",
                                  },
                                },
                                on
                              ),
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.value ? "visibility_off" : "visibility"
                                  ) + "\n                    "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                    Graph is " +
                          _vm._s(
                            _vm.value
                              ? "visible only to the creator."
                              : "visible to all users."
                          ) +
                          "\n                "
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }