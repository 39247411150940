var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.transaction
    ? _c(
        "router-link",
        {
          staticClass: "link",
          class: [
            _vm.monospace ? "fontMonospace" : "",
            _vm.width100 ? "width100" : "",
            _vm.noDecoration ? "no-text-decoration" : "",
          ],
          attrs: { to: { path: _vm.targetUrl } },
        },
        [_vm._t("text")],
        2
      )
    : _c("div", { staticClass: "textGrey" }, [_vm._v("N/A")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }