var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ValidationObserver",
    { ref: "validator", attrs: { mode: "lazy" } },
    [
      _c("v-text-field-validateable", {
        attrs: {
          dense: _vm.dense,
          rules: _vm.rules,
          label: _vm.label,
          "append-icon": "youtube_searched_for",
          outlined: "",
          clearable: "",
          disabled: _vm.disabled,
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.validate.apply(null, arguments)
          },
        },
        model: {
          value: _vm.innerValue,
          callback: function ($$v) {
            _vm.innerValue = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "innerValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }