var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          dense: "",
          headers: _vm.headers,
          items: _vm.addresses,
          loading: _vm.loading,
          "items-per-page": 50,
          "loading-text": "Loading cluster addresses...",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (props) {
              return [
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ hover }) {
                          return [
                            hover
                              ? _c("tr", [
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-center fontMonospace",
                                      },
                                      [
                                        _c("copyable", {
                                          attrs: { "on-copy": props.item },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "text",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n\n                                    " +
                                                        _vm._s(props.item) +
                                                        "\n\n                                "
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ])
                              : _c("tr", [
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-center fontMonospace",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(props.item) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }