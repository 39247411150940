var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    attrs: { bodyStyle: "padding: 0" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-tag-multiple")]),
            _vm._v("\n        Addresses\n    "),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "mt-2 d-flex justify-end" },
                  [
                    _c(_setup.ExportMenu, {
                      staticClass: "mr-2",
                      attrs: {
                        "base-url":
                          _setup.currency.unit +
                          "/cryptocluster/" +
                          _setup.clusterCurrent +
                          "/addresses",
                        disabled: _setup.currentClusterAddressesLoading,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _setup.clusterCurrent !== null
                  ? _c(_setup.TableClusterInteractive, {
                      attrs: {
                        "cluster-total-addresses":
                          _setup.currentClusterAddressesData
                            ? _setup.currentClusterAddressesData.total
                            : 0,
                        data: _setup.currentClusterAddressesData
                          ? _setup.currentClusterAddressesData.data
                          : undefined,
                        fiats: _setup.currentClusterAddressesData
                          ? _setup.currentClusterAddressesData.fiats
                          : {},
                        loading: _setup.currentClusterAddressesLoading,
                      },
                      on: { "fetch-addresses-event": _setup.getAddresses },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _setup.UnusedAddressesLength > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "mb-4 ml-2",
                        staticStyle: { "max-width": "500px" },
                      },
                      [
                        _c(
                          "v-expansion-panels",
                          [
                            _c(
                              "v-expansion-panel",
                              {
                                attrs: {
                                  disabled: _setup.UnusedAddressesLength <= 0,
                                },
                              },
                              [
                                _c(
                                  "v-expansion-panel-header",
                                  {
                                    staticClass: "py-0",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "actions",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [
                                                  _vm._v(
                                                    "\n                                    $expand\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      301016648
                                    ),
                                  },
                                  [
                                    _setup.UnusedAddressesLength !== -1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "subtitle-1",
                                            staticStyle: {
                                              color: "rgba(0,0,0,0.6)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    _setup.UnusedAddressesLength
                                                  )
                                                ) +
                                                " Unused Addresses\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(_setup.TableClusterUnused, {
                                      staticClass: "mt-4",
                                      attrs: {
                                        addresses:
                                          _setup.currentClusterAddressesData
                                            ? _setup.currentClusterAddressesData
                                                .unused_addresses
                                            : [],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }