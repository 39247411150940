<script setup>
import {computed} from "vue";
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

const props = defineProps({
    editButtonTooltip: {
        type: String,
        default: "Edit"
    },
    tooltipPosition: {
        type: String,
        required: false,
        default: "top"
    }
})

const tooltipPositionAttribute = computed(() => {
    return {[props.tooltipPosition]: true}
})

</script>
<template>
    <v-tooltip v-bind="tooltipPositionAttribute">
        <template #activator="{ on }">
            <BaseDataModifyButton
                icon
                color="info"
                small
                v-bind="$attrs"
                v-on="{...$listeners, ...on}"
            >
                <v-icon small>edit</v-icon>
            </BaseDataModifyButton>
        </template>
        <span>{{ editButtonTooltip }}</span>
    </v-tooltip>
</template>
