<template>
    <BaseModalDialog
        :title="isEditModal ? 'Edit identity' : 'Create new identity'"
        @load-modal-state-event="loadState"
        :loading="loading"
        width="30%"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-row>
                    <v-col>
                        <v-text-field-validateable
                            rules="required"
                            v-model="detail.label"
                            label="Label"
                            outlined
                            dense
                        />
                        <v-text-field-validateable
                            rules="required"
                            v-model="detail.source"
                            label="Source"
                            outlined
                            dense
                        />

                        <v-text-field-validateable
                            rules="required"
                            v-model="detail.url"
                            label="Url"
                            outlined
                            dense
                        />
                        <v-text-field
                            disabled
                            label="Address"
                            v-model="detail.address.address"
                            outlined
                            dense
                        />
                        <div v-if="!isEditModal">
                        <AddressPicker
                            @address-filter-changed-event="addressFilterChanged"
                            :addresses="addresses"
                            :loading="isAddressesLoading"
                            v-model="detail.address.address"
                        />
                        </div>
                    </v-col>
                </v-row>
            </ValidationObserver>
        </template>
        <template #footer="{close, setDisabled}">
        <v-btn
            dark
            outlined
            color="error"
            :disabled="identitiesModalState === modalState.Pending"
            @click="close">
            Cancel
        </v-btn>
        <v-spacer />
        <v-btn
            dark
            color="success"
            :loading="identitiesModalState === modalState.Pending"
            outlined
            @click="save(close, setDisabled)">
            {{
                isEditModal ? "Save identity" : "Create identity"
            }}
        </v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import {deepClone} from "@/utils"
import { BaseModalDialog, AddressPicker } from "@/components/common"
import {mapGetters} from "vuex";
import {modalState} from "@/components/common/types/modal";

export default {
    name: "IdentityDetailModal",
    components: {
        BaseModalDialog,
        AddressPicker
    },
    props: {
        identityDetail: {
            type: Object
        },
        /*loading: {
            type: Boolean,
            default: false
        },*/
        addresses: {
            type: Array
        },
        isAddressesLoading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            detail: undefined,
            isEditModal: true,
            showAddress: '',
            closeDialog: null,
            setDisabled: null,
        }
    },
    computed: {
        ...mapGetters(['identitiesModalState']),
        loading() {
            return this.isCategoriesLoading || this.isAddressMetadataDetailLoading
        },
        modalState() {
            return modalState
        }
    },
    watch: {
        identitiesModalState(newVal) {
            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('IDENTITIES_MODAL_SET_STATE', modalState.Initial)
            } else if (newVal === modalState.Error)
            {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('IDENTITIES_MODAL_SET_STATE', modalState.Initial)
            }
        }
    },
    methods: {
        async save(close, setDisabled) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.closeDialog = close
                this.setDisabled = setDisabled
                setDisabled(true)
                this.$emit("save-event", { identityDetail: deepClone(this.detail) })
            }
        },
        addressFilterChanged({ filter }) {
            this.$emit("address-filter-changed-event", { filter })
        },
        loadState() {
            if (this.identityDetail)
            {
                this.isEditModal = true
                this.detail = deepClone(this.identityDetail)
            }
            else {
                this.isEditModal = false
                this.detail = {
                    label: "",
                    source: "",
                    url: "",
                    address: { address: "" }
                }
            }
        }
    }
}
</script>
