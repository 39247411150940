<template>
    <div class="width100">
        <div v-if="blockDetailLoading">
            <ProgressCircular :color="currency.unit" class="pt-12" />
        </div>
        <template v-if="blockDetail === undefined && !blockDetailLoading">
            <v-container>
                <div>
                    <BlockNotFoundInfo
                        class="my-2"
                        :block="blockId"
                        :message="'Provided identifier does not exist in the blockchain!'"
                    />
                </div>
            </v-container>
        </template>

        <v-layout v-else column>
            <div v-if="!blockDetailLoading">

            <v-app-bar dense absolute color="white">
                <v-row class="flex-nowrap" style="min-width: 0">
                    <v-btn
                        :to="'/' + currency.unit + '/block/' + blockDetail.block.previousBlockHash"
                        text
                        outlined
                    >
                        <v-icon>keyboard_arrow_left</v-icon>Previous block
                    </v-btn>
                    <v-spacer />
                    <EntityHeaderLabel justify="center" label="CURRENT BLOCK:" :entity="blockDetail.block.hash"/>
                    <v-spacer />
                    <v-btn
                        :disabled="blockDetail.block.nextBlockHash === undefined"
                        :to="'/' + currency.unit + '/block/' + blockDetail.block.nextBlockHash"
                        text
                        outlined
                    >
                        Next block
                        <v-icon>keyboard_arrow_right</v-icon>
                    </v-btn>
                </v-row>
            </v-app-bar>

            <div class="scroll-y mt-10">
                <v-container fluid>
                    <h1>{{currency.name}} block <TitleHelper>#{{blockDetail.block.height}}</TitleHelper></h1>
                    <v-divider class="mb-3" />
                    <v-card max-width="1500" class="border" elevation="0">
                        <v-layout row wrap>
                            <v-flex>
                                <v-list class="py-0 borderRight fill-height">
                                    <v-list-item>
                                        <v-list-item-content class="pr-3 font-weight-bold">Time:</v-list-item-content>
                                        <v-list-item-action>{{ blockDetail.block.time | formatDateTime }}</v-list-item-action>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item>
                                        <v-list-item-content
                                            class="pr-3 font-weight-bold"
                                        >Number of Transactions:</v-list-item-content>
                                        <v-list-item-action>{{blockDetail.block.txCount}}</v-list-item-action>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item>
                                        <v-list-item-content
                                            class="pr-3 font-weight-bold"
                                        >Confirmations:</v-list-item-content>
                                        <v-list-item-action>{{blockDetail.block.confirmations}}</v-list-item-action>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item>
                                        <v-list-item-content
                                            class="pr-3 font-weight-bold"
                                        >Merkle root:</v-list-item-content>
                                        <v-list-item-action>{{blockDetail.block.merkleRoot}}</v-list-item-action>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item>
                                        <v-list-item-content
                                            class="pr-3 font-weight-bold"
                                        >Difficulty:</v-list-item-content>
                                        <v-list-item-action>{{ blockDetail.block.difficulty | formatNumber}}</v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-flex>
                            <v-flex>
                                <v-list class="py-0 fill-height">
                                    <v-list-item>
                                        <v-list-item-content class="pr-3 font-weight-bold">Height:</v-list-item-content>
                                        <v-list-item-action>{{ blockDetail.block.height }}</v-list-item-action>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item>
                                        <v-list-item-content class="pr-3 font-weight-bold">Size:</v-list-item-content>
                                        <v-list-item-action>{{ blockDetail.block.size | fileSize }}</v-list-item-action>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item>
                                        <v-list-item-content class="pr-3 font-weight-bold">Version:</v-list-item-content>
                                        <v-list-item-action>{{ blockDetail.block.version }}</v-list-item-action>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item>
                                        <v-list-item-content class="pr-3 font-weight-bold">Nonce:</v-list-item-content>
                                        <v-list-item-action>{{ blockDetail.block.nonce }}</v-list-item-action>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item>
                                        <v-list-item-content class="pr-3 font-weight-bold">Bits:</v-list-item-content>
                                        <v-list-item-action>{{ blockDetail.block.bits }}</v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-flex>
                        </v-layout>
                    </v-card>

                    <h1 class="mt-4">Transactions</h1>
                    <v-divider class="mb-3" />
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" small :depressed="false" outlined class="mr-5" @click="expanded = !expanded">
                                {{expanded ? "Collapse" : "Expand"}}
                                <v-icon right>
                                    {{expanded ? "mdi-arrow-collapse-vertical" : "mdi-arrow-expand-vertical" }}
                                </v-icon>
                            </v-btn>
                        </template>
                        {{expanded ? "Collapse" : "Expand" }} all transactions
                    </v-tooltip>

                    <PaginationWrapper
                        :loading="blockDetailTransactionsLoading"
                        :pages-total="blockDetailTransactions ? blockDetailTransactions.last_page : 0"
                        v-model="filter.page"
                    >
                        <template v-slot:content>
                            <div v-if="blockDetailTransactionsLoading">
                                <ProgressCircular
                                    :color="currency.unit"
                                />
                            </div>
                            <TransactionsHeader no-change/>
                            <v-divider/>
                            <TransactionSingle
                                class="mt-1 mb-1"
                                v-for="transaction in blockDetailTransactions.data"
                                :transaction="transaction"
                                :key="transaction.txhash"
                                :expanded="expanded"
                            />
                        </template>
                    </PaginationWrapper>

                </v-container>
            </div>

            </div>
        </v-layout>
    </div>
</template>

<script>
import ProgressCircular from "@/components/common/ProgressCircular";
import TransactionSingle from '@/components/transaction/TransactionSingle';
import {mapGetters} from "vuex";
import BlockNotFoundInfo from "@/components/block/BlockNotFoundInfo";
import {PaginationWrapper} from "@/components/common";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import TransactionsHeader from "@/components/transaction/Components/TransactionsHeader";
import TitleHelper from "@/components/common/TitleHelper";
import EntityHeaderLabel from "@/views/Cluster/EntityHeaderLabel.vue";

export default {
    name: 'BlockDetailView',
    mixins: [routeSyncMixin],
    components: {
        EntityHeaderLabel,
        TitleHelper,
        TransactionsHeader,
        ProgressCircular,
        TransactionSingle,
        BlockNotFoundInfo,
        PaginationWrapper,
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 20}
            },
            expanded: false,
            categoriespagination: {
                page: 1,
                itemsPerPage: 100,
            },
        }
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.block !== from.params.block) {
            this.fetchBlock(to.params.block)
            this.fetchBlockTransactions(to.params.block)
        }
        next({ query: {}})
    },
    created() {
        this.fetchBlock()
        this.$store.dispatch('loadCategories')
    },
    watch: {
        currency(newValue, oldValue) {
            this.$store.dispatch('loadBlock', { blockId: this.$route.params.block, unit: this.$store.getters.currency.unit, pagination: this.pagination, page: false})
        },
    },
    computed: {
        blockId() {
            return this.$route.params.block;
        },
        ...mapGetters(["blockDetail", "blockDetailLoading", "currency", "blockDetailTransactions", "blockDetailTransactionsLoading"])
    },
    methods: {
        fetchBlock(blockId) {
            this.$store.dispatch('loadBlockDetail', {
                blockId: blockId ? blockId : this.$route.params.block,
                currency: this.$store.getters.currency.unit,
            })

        },
        fetchBlockTransactions(blockId) {
            this.$store.dispatch('loadBlockTransactions', {
                blockId: blockId ?? this.$route.params.block,
                currency: this.$store.getters.currency.unit,
                pagination: {
                    ...this.filter
                }
            })
        },
        onFilterInitial() {

        },
        onFilterChange() {
           this.fetchBlockTransactions()
        },
        onFilterLoaded() {
            this.fetchBlockTransactions()
        }
    },
}
</script>

<style scoped>
.borderRight {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
