<template>
    <ValidationProvider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
        <v-autocomplete v-model="innerValue" :error-messages="errors" v-bind="$attrs" v-on="$listeners">
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        </v-autocomplete>
    </ValidationProvider>
</template>

<script>

export default {
    name: "VAutoCompleteValidatable",
    props: {
        rules: {
            type: [Object, String],
            default: ""
        },
        value: {
            type: null
        }
    },
    data: () => ({
        innerValue: ""
    }),
    watch: {
        // Handles internal model changes.
        innerValue(newVal) {
            this.$emit("input", newVal);
        },
        // Handles external model changes.
        value(newVal) {
            this.innerValue = newVal;
        }
    },
    created() {
        if (this.value) {
            this.innerValue = this.value;
        }
    }
};
</script>
