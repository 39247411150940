var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c(_setup.TransactionsPaginatedList, {
        attrs: {
          "get-data": _setup.getDataObject,
          itemsPerPageDefault: 5,
          "tx-map-function": (tx) => _setup.processTx(tx, ""),
          "hide-timeline": "",
          "hide-total": "",
          hideTopPagination: "",
        },
        scopedSlots: _vm._u([
          {
            key: "prependTransaction",
            fn: function ({ transaction }) {
              return [
                _c(
                  "v-row",
                  { staticClass: "no-gutters justify-space-between" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "no-gutters", attrs: { cols: "7" } },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "d-flex font-weight-bold justify-start align-end",
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    margin: "0 4px -4px 0",
                                                    "font-size": "1.2em",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        transaction
                                                          .peerTxPropagations
                                                          .order
                                                      ) +
                                                      ".\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-icon", [
                                                _vm._v(
                                                  _vm._s(
                                                    `mdi-podium-${_setup.convertNumberToRanking(
                                                      transaction
                                                        .peerTxPropagations
                                                        .order
                                                    )}`
                                                  ) +
                                                    "\n                                    "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "This node was the " +
                                      _vm._s(
                                        _setup.convertNumberToOrdinal(
                                          transaction.peerTxPropagations.order
                                        )
                                      ) +
                                      " one to propagate this transaction."
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex no-gutters justify-end",
                        attrs: { cols: "2" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex no-gutters justify-end",
                            staticStyle: { padding: "0" },
                            attrs: { cols: "10" },
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "d-flex font-weight-bold align-end text-no-wrap",
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("mdi-eye")]
                                              ),
                                              _vm._v(" "),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatDateTime")(
                                                      transaction
                                                        .peerTxPropagations
                                                        .received_at
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "Timestamp of when this node first saw this transaction."
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "2" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "noDataText",
            fn: function () {
              return [
                _vm._v(
                  "\n            This node hasn't propagated any transactions as of yet.\n        "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }