var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-hover", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ hover }) {
            return [
              _c("div", { staticClass: "buttonNoHoverBackground" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: hover,
                        expression: "hover",
                      },
                    ],
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", disabled: _vm.disabled },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _vm._t("active", null, {
                                    on: on,
                                    attrs: attrs,
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_vm._v(" "), _c("span", [_vm._t("tooltip")], 2)]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !hover,
                        expression: "!hover",
                      },
                    ],
                  },
                  [_vm._t("alt")],
                  2
                ),
              ]),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }