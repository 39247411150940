<script setup>

import {useVModelInput} from "@/components/common/Composables/useVModelnput";
import {computed} from "vue";

const props = defineProps({
    icon: {
        type: String,
        required: true,
    },
    text: {
        type: String,
        required: true,
    },
    value: {
        type: Boolean,
    }
})

const emit = defineEmits(['input'])
const modelValue = useVModelInput(props, emit)

const color = computed(() => modelValue.value ? 'tertiary' : undefined)

</script>

<template>
    <div>
        <v-btn class="d-flex justify-center align-center"
               rounded
               text
               @click="modelValue = !modelValue">
            <v-checkbox v-model="modelValue" class="ma-0 pa-0" color="tertiary" dense hide-details readonly
                        style="pointer-events: none"/>
            <v-icon :color="color" class="ml-n1" left>
                {{ icon }}
            </v-icon>
            <div :class="['text-button', 'textGrey', 'ml-n1', color ? `${color}--text` : '']">
                {{ text }}
            </div>
        </v-btn>

    </div>
</template>

<style scoped>

</style>