var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  { staticClass: "d-inline-block mx-1" },
                  [_vm._t("iconName", null, { on: on })],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [_vm._v(" "), _c("span", [_vm._t("tooltip")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }