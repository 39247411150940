import {computed, ref} from "vue";
import {deepClone} from "@/utils";

export const usePatchManager = (data, item_id) => {

    const AddedItemsIds = ref([])
    const RemovedItemsIds = ref([])
    const UpdatedItemsIds = ref([])

    const PrependItems = ref([])
    const ReplaceItems = ref([])

    const addPathData = (item) => {
        let patchData = {}

        if (AddedItemsIds.value.includes(item[item_id])) {
            patchData['Added'] = true
        }

        if (RemovedItemsIds.value.includes(item[item_id])) {
            patchData['Removed'] = true
        }

        if (UpdatedItemsIds.value.includes(item[item_id])) {
            patchData['Updated'] = true
            item = ReplaceItems.value.find(x => x[item_id] === item[item_id])
        }

        return {...item, patchData}
    }

    const addItem = (itemToAdd) => {

        if (AddedItemsIds.value.includes(itemToAdd[item_id])
            || PrependItems.value.some(x => x[item_id] === itemToAdd[item_id])
            || data.value.some(x => x[item_id] === itemToAdd[item_id])) {
            return
        }

        AddedItemsIds.value.push(itemToAdd[item_id])
        PrependItems.value.unshift(itemToAdd)
    }
    const removeItem = (itemToRemove) => {
        if (RemovedItemsIds.value.includes(itemToRemove[item_id])) {
            return
        }

        if (AddedItemsIds.value.includes(itemToRemove[item_id])) {
            AddedItemsIds.value = AddedItemsIds.value.filter(x => x !== itemToRemove[item_id])
            PrependItems.value = PrependItems.value.filter(x => x[item_id] !== itemToRemove[item_id])
            return
        }

        if (UpdatedItemsIds.value.includes(itemToRemove[item_id])) {
            UpdatedItemsIds.value = UpdatedItemsIds.value.filter(x => x !== itemToRemove[item_id])
            ReplaceItems.value = ReplaceItems.value.filter(x => x[item_id] !== itemToRemove[item_id])
            return
        }

        RemovedItemsIds.value.push(itemToRemove[item_id])
    }

    const updateItem = (newItem) => {
        const itemID = newItem[item_id]

        if (RemovedItemsIds.value.includes(itemID)) {
            RemovedItemsIds.value = RemovedItemsIds.value.filter(x => x !== itemID)
        }

        if (AddedItemsIds.value.includes(itemID)) {
            return
        }

        if (UpdatedItemsIds.value.includes(itemID)) {
            const indexOfReplacedElement = ReplaceItems.value.findIndex(x => x[item_id] === itemId)
            ReplaceItems.value[indexOfReplacedElement] = newItem
            return
        }

        UpdatedItemsIds.value.push(itemID)
        ReplaceItems.value.push(newItem)
    }

    const reset = () => {
        AddedItemsIds.value = []
        RemovedItemsIds.value = []
        UpdatedItemsIds.value = []
        PrependItems.value = []
        ReplaceItems.value = []
    }

    const buildPatch = (path, item_id) => {
        const patch = []

        let itemsIdsToAdd = PrependItems.value.map(x => x[item_id])
        if (itemsIdsToAdd.length > 0) {
            patch.push({op: "add", path: path, value: itemsIdsToAdd})
        }


        let itemsIdsToRemove = patchedData.value.filter(x => x.patchData?.Removed)
            .map(x => x[item_id])

        if (itemsIdsToRemove.length > 0) {
            patch.push({op: "remove", path: path, value: itemsIdsToRemove})
        }

        return patch
    }

    // const patchedData = computed(() => PrependItems.value.concat(data.value).map(x => addPathData(x)))
    const patchedData = computed(() => {
        return PrependItems.value.concat(data.value).map(x => addPathData(x))
    })

    const debug = () => {
        console.log("AddedItems:", deepClone(AddedItemsIds.value))
        console.log("RemovedItems:", deepClone(RemovedItemsIds.value))
        console.log("UpdatedItems:", deepClone(UpdatedItemsIds.value))
        console.log("PrependItems:", deepClone(PrependItems.value))
        console.log("ReplaceItems:", deepClone(ReplaceItems.value))
    }

    const patchActions = {
        addItem,
        removeItem,
        updateItem,
        buildPatch,
        debug,
        reset
    }

    const isDataPatched = computed(() =>
        AddedItemsIds.value.length > 0 || RemovedItemsIds.value.length > 0 || UpdatedItemsIds.value.length > 0
    )

    return {patchedData, patchActions, isDataPatched}
}


