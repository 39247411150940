<template>
    <div class="scroll-y height100">
        <v-container class="height100 px-4" fluid>
            <v-layout align-center>
                <h1>Cases</h1>
                <v-spacer/>

                <CaseDetailModal
                    :categories="categories"
                    :currencies="enabledCurrencies"
                    @save-event="handleCaseSave"
                >
                    <template #showbutton="{ on }">
                        <CreateButton text="Create case" v-on="on"/>
                    </template>
                </CaseDetailModal>
            </v-layout>

            <v-divider/>

            <v-layout wrap>

                <v-flex xs12>
                    <v-divider/>
                    <v-data-table
                        :footer-props="{
                            'items-per-page-options': [15, 30, 60, -1],
                            'show-current-page': true,
                            'showFirstLastPage': true,
                        }"
                        :headers="headers"
                        :items="cases.data"
                        :loading="casesLoading"
                        :options.sync="filter"
                        :server-items-length="cases.total ? cases.total : 0"
                        class="elevation-0 border mt-2"
                        dense
                    >
                        <template
                            v-for="h in headers"
                            v-slot:[`header.${h.value}`]="{ header }"
                        >
                            <v-text-field
                                v-if="h.value === 'title'"
                                :key="h.value"
                                v-model="debouncedFilter.filterTitle"
                                :label="header.text"
                                append-icon="search"
                                class="text-field-label-style"
                                clearable
                                hide-details
                                single-line
                                style="min-width: 100px;"
                                @input="debounceInput"
                                @click.stop.native
                            />
                            <v-text-field
                                v-if="h.value === 'files_count'"
                                :key="h.value"
                                v-model="debouncedFilter.filterFiles"
                                :label="header.text"
                                :min="0"
                                class="text-field-label-style"
                                clearable
                                hide-details
                                hide-spin-buttons
                                single-line
                                type="number"
                                @input="debounceInput"
                                @click.stop.native
                            />
                            <v-text-field
                                v-if="h.value === 'addresses_count'"
                                :key="h.value"
                                v-model="debouncedFilter.filterAddresses"
                                :label="header.text"
                                :min="0"
                                class="text-field-label-style"
                                clearable
                                hide-details
                                hide-spin-buttons
                                single-line
                                style="min-width: 50px;"
                                type="number"
                                @input="debounceInput"
                                @click.stop.native
                            />
                            <div
                                v-if="h.value === 'is_hidden'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'is_warning'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'reported_at'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'created_at'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'created_by'"
                                :key="h.value"
                                style="padding-top: 20px; padding-bottom: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'updated_at'"
                                :key="h.value"
                                style="padding-top: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'updated_by'"
                                :key="h.value"
                                style="padding-top: 20px; padding-bottom: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                            <div
                                v-if="h.value === 'action'"
                                :key="h.value"
                                style="padding-top: 20px; padding-bottom: 20px"
                            >
                                <h3>{{ header.text }}</h3>
                            </div>
                        </template>
                        <template #item="{ item }">
                            <tr>
                                <td class="text-left text-truncate" style="max-width: 0;">
                                    <router-link
                                        :to="`/cases/${item.id}`"
                                        class="link"
                                        style="min-width: 100px"
                                    >
                                        {{ item.title }}
                                    </router-link>
                                </td>
                                <td
                                    class="text-right"
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    {{ item.files_count | formatNumber }}
                                </td>
                                <td
                                    class="text-right"
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    {{ item.addresses_count | formatNumber }}
                                </td>
                                <td
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                :color="item.is_hidden ? 'primary' : 'tertiary'"
                                                dark
                                                v-on="on"
                                            >{{
                                                    item.is_hidden
                                                        ? "visibility_off"
                                                        : "visibility"
                                                }}
                                            </v-icon
                                            >
                                        </template>
                                        <span
                                        >Case is
                                            {{
                                                item.is_hidden
                                                    ? "hidden"
                                                    : "visible"
                                            }}</span
                                        >
                                    </v-tooltip>
                                </td>
                                <td
                                    style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                                >
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                :color="item.is_warning ? 'warning' : 'primary'"
                                                dark
                                                v-on="on"
                                            >{{
                                                    item.is_warning
                                                        ? "report"
                                                        : "report_off"
                                                }}
                                            </v-icon
                                            >
                                        </template>
                                        <span
                                        >Case
                                            {{
                                                item.is_warning
                                                    ? "warns"
                                                    : "does not warn"
                                            }}
                                            creator</span
                                        >
                                    </v-tooltip>
                                </td>
                                <td class="text-no-wrap">
                                    {{ item.reported_at| moment("YYYY-MM-DD HH:mm:ss Z") }}
                                </td>
                                <td class="text-no-wrap">
                                    {{
                                        item.created_at
                                            | moment("YYYY-MM-DD HH:mm:ss Z")
                                    }}
                                </td>
                                <td>
                                    {{ item.creator.name }}
                                </td>
                                <td class="text-no-wrap">
                                    {{
                                        item.updated_at
                                            | moment("YYYY-MM-DD HH:mm:ss Z")
                                    }}
                                </td>
                                <td>
                                    {{ item.updator.name }}
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <CaseDetailModal
                                            :key="item.id"
                                            :caseDetail="caseDetail"
                                            :categories="categories"
                                            :currencies="enabledCurrencies"
                                            :loading="isCaseLoading"
                                            @save-event="handleCaseSave"
                                        >
                                            <template #showbutton="{ on }">
                                                <TableEditButton
                                                    editButtonTooltip="Edit case"
                                                    @click="
                                                        loadCaseDetail({
                                                            caseId: item.id,
                                                        })
                                                    "
                                                    v-on="on"
                                                />
                                            </template>
                                        </CaseDetailModal>

                                        <TableDeleteModalDialog
                                            :description="`Do you really want to delete case ${item.title}`"
                                            :itemToDelete="item"
                                            tooltip="Delete case"
                                            @delete-event="deleteCase"
                                        />

                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                            <span>
                                {{ pageStart | formatNumber }} - {{
                                    pageStop | formatNumber
                                }} of {{ itemsLength | formatNumber }}
                            </span>
                        </template>
                    </v-data-table>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import TableDeleteModalDialog from "@/components/common/TableComponents/TableDeleteModalDialog.vue"
import TableEditButton from "@/components/common/TableComponents/TableEditButton.vue"
import CreateButton from "@/components/common/Buttons/CreateButton.vue"
import CaseDetailModal from "@/views/Case/Dialogs/CaseDetailModal.vue"
import {mapGetters} from "vuex";
import debounce from "debounce";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";

export default {
    name: 'CasesView',
    mixins: [routeSyncMixin],
    components: {
        TableDeleteModalDialog,
        TableEditButton,
        CreateButton,
        CaseDetailModal
    },
    computed: {
        caseDetail() {
            return this.$store.getters.caseDetail
        },
        ...mapGetters(["isCaseLoading", "cases", "casesLoading", "enabledCurrencies", "categories"])
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                sortBy: {type: "ArrayOfStrings", default: () => ['updated_at']},
                sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
                filterTitle: {type: "String", default: () => ''},
                filterDescription: {type: "String", default: () => ''},
                filterFiles: {type: "String", default: () => ''},
                filterAddresses: {type: "String", default: () => ''},
            },
            debouncedFilter: {
                filterTitle: undefined,
                filterDescription: undefined,
                filterFiles: undefined,
                filterAddresses: undefined,
            },
            categoriespagination: {
                page: 1,
                itemsPerPage: 100,
            },
            headers: [
                {align: 'center', text: 'Title', value: 'title'},
                {align: 'center', text: 'Files', value: 'files_count'},
                {align: 'center', text: 'Addresses', value: 'addresses_count'},
                {align: 'center', text: 'Visibility', value: 'is_hidden'},
                {align: 'center', text: 'Alert', value: 'is_warning'},
                {align: 'center', text: 'Reported At', value: 'reported_at'},
                {align: 'center', text: 'Created At', value: 'created_at'},
                {align: 'center', text: 'Created By', value: 'created_by', sortable: false},
                {align: 'center', text: 'Updated At', value: 'updated_at'},
                {align: 'center', text: 'Updated By', value: 'updated_by', sortable: false},
                {align: 'center', text: 'Action', value: 'action', sortable: false}
            ]
        }
    },
    mounted() {
        this.$store.dispatch('loadCategories')
    },
    methods: {
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),
        refresh() {
            this.$store.dispatch('loadCases', {pagination: this.filter})
        },
        loadCaseDetail({caseId}) {
            this.$store.dispatch("loadCase", {caseId});
        },
        async handleCaseSave() {
            this.refresh()
        },
        async deleteCase({itemToDelete}) {
            await this.$store.dispatch("deleteCase", {caseId: itemToDelete.id})
            this.refresh()
        }
    }
}
</script>