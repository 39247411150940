var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "width100" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "end" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "auto" } },
            [
              _c("TransactionsControlPanel", {
                attrs: { expanded: _vm.expanded, timelined: _vm.timeline },
                on: {
                  "expand-event": function ($event) {
                    _vm.expanded = !_vm.expanded
                  },
                  "timeline-expand-event": function ($event) {
                    _vm.timeline = !_vm.timeline
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _vm.isAddress
                ? _c("export-menu", {
                    attrs: { disabled: _vm.loading, "base-url": _vm.baseUrl },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.loading || _vm.transactions.length > 0
        ? _c("PaginationWrapper", {
            attrs: {
              loading: _vm.loading,
              "pages-total": _vm.totalPages ? _vm.totalPages : 0,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("TransactionsHeader", {
                        attrs: { "no-change": !_vm.isAddress },
                      }),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _vm._l(_vm.transactions, function (transaction, index) {
                        return _c(
                          "div",
                          { key: transaction.id },
                          [
                            _c("v-expand-transition", [
                              _vm.timeline &&
                              index !== 0 &&
                              _vm.transactions[index - 1].time !==
                                transaction.time
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-end align",
                                      staticStyle: { "margin-right": "105px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-start align-center",
                                          staticStyle: { width: "150px" },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [
                                              _vm._v(
                                                "\n                                mdi-sort-clock-ascending-outline\n                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "font-weight-light",
                                              staticStyle: {
                                                color: "rgba(0,0,0,0.6)",
                                              },
                                            },
                                            [
                                              _c("nobr", [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(`${_vm.getHumanTime(
                                                      _vm.transactions[
                                                        index - 1
                                                      ].time * 1000,
                                                      transaction.time * 1000
                                                    )}
                                         | ${
                                           _vm.transactions[index - 1]
                                             .blockHeight -
                                           transaction.blockHeight
                                         } Blocks`) +
                                                    "\n                                    "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("TransactionSingle", {
                              staticClass: "mt-1 mb-1",
                              attrs: {
                                transaction: transaction,
                                expanded: _vm.expanded,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1368768463
            ),
            model: {
              value: _vm.filter.page,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "page", $$v)
              },
              expression: "filter.page",
            },
          })
        : _c(
            "div",
            { staticClass: "title mt-4 font-weight-light text-center" },
            [
              _vm._v(
                "\n        No transactions matched the selected criteria.\n    "
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }