<template>
    <v-menu
        z-index="9999"
        ref="menu"
        absolute
        top
        transition="slide-y-transition"
        rounded="lg"
        :close-on-content-click="false"
        v-model="isMenuVisible"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn ref="menuOpener" v-on="on" style="display: none">
            </v-btn>
        </template>
        <fancy-card v-if="isMenuVisible" :header="false" body-style="padding-top: 4px">
            <template #body>
                <div class="d-flex justify-end">
                    <router-link
                        class="text-decoration-none"
                        :to="{ name: 'AddressSummary', params: { address: address }}"
                        target="_blank"
                    >
                        <v-btn icon>
                            <v-icon>open_in_new</v-icon>
                        </v-btn>
                    </router-link>
                </div>
                <div class="d-flex align-center">
                    <v-icon class="ml-1" left style="height: 24px; width: 24px">mdi-tag-text-outline</v-icon>
                    <span class="fontMonospace">{{ address }}</span>
                </div>
                <div class="d-flex justify-center my-1">
                    <div style="width: 90%">
                        <header-lined>
                            <template #text>
                                {{currency.unit}} Address
                            </template>
                        </header-lined>
                    </div>
                </div>
                <div>
                    <NameValueFlex :loading="loading" loading-width="160" name-width="90">
                        <template #name>
                            <v-icon left class="iconLeft medium">
                                fa-solid fa-coins
                            </v-icon>
                            <span class="font-weight-light subtitle-1" style="font-size: 16px">
                                Balance:
                            </span>
                        </template>
                        <template #value>
                            <div>
                                <v-chip
                                    :color="currency.unit"
                                    style="height: 26px"
                                    label
                                    text-color="white"
                                >
                                <span class="subtitle-1 font-weight-bold" style="vertical-align: middle">
                                    <fiat-convertor :value="data.confirmedBalance" :fiats="data.fiats"/>
                                </span>
                                    <span class="subtitle-1 pl-1 text--white" style="vertical-align: middle">
                                    <fiat-convertor unit-only/>
                                </span>
                                </v-chip>
                            </div>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="110" name-width="85">
                        <template #name>
                            <v-icon class="iconLeft medium">
                                mdi-download mdi-rotate-270
                            </v-icon>
                            <span class="font-weight-light">
                                Received:
                            </span>
                        </template>
                        <template #value>
                            <div>
                                <v-chip
                                    color="success"
                                    style="height: 22px"
                                    label
                                    text-color="white"
                                >
                                    <span class="subtitle-2 font-weight-bold" style="vertical-align: middle">
                                        <div v-if="currency.unit === 'ETH'">N/A</div>
                                        <fiat-convertor v-else :value="data.totalReceived" :fiats="data.fiats"/>
                                    </span>
                                    <span class="subtitle-2 pl-1 text--white font-weight-light" style="vertical-align: middle">
                                        <fiat-convertor unit-only/>
                                    </span>
                                </v-chip>
                            </div>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="100" name-width="70">
                        <template #name>
                            <v-icon class="iconLeft medium">
                                mdi-upload mdi-rotate-90
                            </v-icon>
                            <span class="font-weight-light">
                                Sent:
                            </span>
                        </template>
                        <template #value>
                            <div>
                                <v-chip
                                    color="red"
                                    style="height: 22px"
                                    label
                                    text-color="white"
                                >
                                    <span class="subtitle-2 font-weight-bold" style="vertical-align: middle">
                                        <div v-if="currency.unit === 'ETH'">N/A</div>
                                        <fiat-convertor v-else :value="data.totalSent" :fiats="data.fiats"/>
                                    </span>
                                    <span class="subtitle-2 pl-1 text--white font-weight-light" style="vertical-align: middle">
                                        <fiat-convertor unit-only/>
                                        </span>
                                </v-chip>
                            </div>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="150" nameFlex>
                        <template #name>
                        <span class="font-weight-light subtitle-1" style="font-size: 16px">
                            Transactions:
                        </span>
                        </template>
                        <template #value>
                            <div class="d-flex justify-end align-center">
                                <div>
                                    <span class="subtitle-1 font-weight-bold" style="vertical-align: middle"> {{ data.confirmedTransactions | formatNumber }} </span>
                                </div>
                            </div>
                        </template>
                    </nameValueFlex>
                </div>
            </template>
        </fancy-card>
    </v-menu>
</template>

<script>
import FancyCard from "@/components/common/Cards/FancyCard";
import NameValueFlex from "@/components/common/NameValueFlex";
import FiatConvertor from "@/components/common/FiatConvertor";
import {mapGetters} from "vuex";
import HeaderLined from "@/components/common/HeaderLined";
import RouteToAddress from "@/components/common/RouteToAddress.vue";

export default {
    name: "AddressDetailable",
    expose: ['openMenu'],
    components: {RouteToAddress, HeaderLined, FiatConvertor, NameValueFlex, FancyCard},
    data() {
        return {
            isMenuVisible: false,
        }
    },
    props: {
        address: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters(["currency"]),
        data() {
            return this.$store.getters.menuAddressDetailPerAddress(this.address)
        },
        loading() {
            return this.$store.getters.menuAddressDetailLoadingPerAddress(this.address)
        },
        isAddressTransit() {
            return this.data.confirmedTransactions === 2 && this.data.confirmedBalance === 0 && (this.data.firstTx.inputs.length === 1 || this.data.lastTx.outputs.length === 1)
        }
    },
    watch: {
        isMenuVisible(newVal, oldVal) {
            //FALSE -> TRUE
            if (newVal && !oldVal) {
                this.getAddress(this.address)
            }
        }
    },
    methods: {
        getAddress(address)
        {
            this.$store.dispatch("loadMenuAddressDetail", {currency: this.currency.unit, address: address})
        },
        openMenu(event)
        {
            this.$refs.menuOpener.click(event)
        }
    }
}
</script>

<style scoped>

.iconLeft {
    margin-right: 3px;
    margin-top: -4px;
}

.medium {
    font-size: 18px !important;
}

</style>