import store from "@/store";
import {ref} from "vue";
import axios from "axios";
import {DataState} from "@/components/common/types/data";

const CreateCancelTokenFactory = () => {
    let cancelToken = undefined
    return () => {
        if (cancelToken) {
            cancelToken.cancel()
        }
        cancelToken = axios.CancelToken.source()
        return cancelToken.token;
    }
}


export const useGetData = (actionName, payload, immediate = true, repeatIfError = false, numberOfTries = 1, cancelable = false) => {
    const dataState = ref(DataState.Initial)
    const response = ref(null)
    const error = ref()
    let cancelTokenFactory = () => undefined
    if (cancelable) {
        cancelTokenFactory = CreateCancelTokenFactory()
    }
    const cb = fetchData.bind(this, dataState, response, error, actionName, payload, repeatIfError, numberOfTries, cancelTokenFactory)

    if (immediate) {
        setTimeout(() => cb())
    }

    //Would be nice if there was a way to cancel the request
    return {dataState: dataState, data: response, getData: cb, error}
}

const fetchData = async (dataState, data, error, actionName, payload, repeatIfError, numberOfTries, cancelTokenFactory, payloadOverride) => {
    dataState.value = DataState.Loading
    for (let i = 0; i < numberOfTries; i++) {
        try {
            const response = await store.dispatch(actionName, {
                ...payload, ...payloadOverride,
                cancelToken: cancelTokenFactory()
            })

            if (response) {
                if (dataState.value === DataState.Loading) //DataState could have been reset to Initial (fetched data are no longer relevant)
                {
                    data.value = response
                    dataState.value = DataState.Loaded
                }
                break
            }
        } catch (err) {
            if (i === numberOfTries - 1) {
                await store.dispatch('error', err.message)
                error.value = err.message
                dataState.value = DataState.Error
            }
        }
    }
}