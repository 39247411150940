import {ref, unref} from "vue";
import {deepClone} from "@/utils";
import {FilterType} from "@/components/common/types/filter";

const categoryFilterValueConverter = (categoryIds, any?) => {
    any = unref(any)

    categoryIds = unref(categoryIds)

    if (!categoryIds && !any)
        return undefined

    if (categoryIds?.length === 0 && !any)
        return undefined

    let op = 'E'
    if (any)
        op = 'A'

    return {op: op, values: deepClone(categoryIds), type: FilterType.Categories, opt: {}}
}

const categoryFilterValueToReadable = (categoryIds, any) => {

    any = unref(any)

    if (any)
        return 'Any'

    categoryIds = unref(categoryIds)


    if (!categoryIds)
        return undefined

    if (categoryIds.length === 0)
        return undefined

    return `${categoryIds.length} Categories`
}


export const useCategoriesFilter = () => {
    const filterValue = ref([])
    const filterValueAny = ref(false)

    const resetValues = () => {
        filterValue.value = []
        filterValueAny.value = false
    }

    return {filterValue, filterValueAny, categoryFilterValueToReadable, categoryFilterValueConverter, resetValues}
}

