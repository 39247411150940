var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "width100" },
    [
      _vm.blockDetailLoading
        ? _c(
            "div",
            [
              _c("ProgressCircular", {
                staticClass: "pt-12",
                attrs: { color: _vm.currency.unit },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.blockDetail === undefined && !_vm.blockDetailLoading
        ? [
            _c("v-container", [
              _c(
                "div",
                [
                  _c("BlockNotFoundInfo", {
                    staticClass: "my-2",
                    attrs: {
                      block: _vm.blockId,
                      message:
                        "Provided identifier does not exist in the blockchain!",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        : _c("v-layout", { attrs: { column: "" } }, [
            !_vm.blockDetailLoading
              ? _c(
                  "div",
                  [
                    _c(
                      "v-app-bar",
                      { attrs: { dense: "", absolute: "", color: "white" } },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "flex-nowrap",
                            staticStyle: { "min-width": "0" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  to:
                                    "/" +
                                    _vm.currency.unit +
                                    "/block/" +
                                    _vm.blockDetail.block.previousBlockHash,
                                  text: "",
                                  outlined: "",
                                },
                              },
                              [
                                _c("v-icon", [_vm._v("keyboard_arrow_left")]),
                                _vm._v("Previous block\n                "),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-spacer"),
                            _vm._v(" "),
                            _c("EntityHeaderLabel", {
                              attrs: {
                                justify: "center",
                                label: "CURRENT BLOCK:",
                                entity: _vm.blockDetail.block.hash,
                              },
                            }),
                            _vm._v(" "),
                            _c("v-spacer"),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled:
                                    _vm.blockDetail.block.nextBlockHash ===
                                    undefined,
                                  to:
                                    "/" +
                                    _vm.currency.unit +
                                    "/block/" +
                                    _vm.blockDetail.block.nextBlockHash,
                                  text: "",
                                  outlined: "",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Next block\n                    "
                                ),
                                _c("v-icon", [_vm._v("keyboard_arrow_right")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "scroll-y mt-10" },
                      [
                        _c(
                          "v-container",
                          { attrs: { fluid: "" } },
                          [
                            _c(
                              "h1",
                              [
                                _vm._v(_vm._s(_vm.currency.name) + " block "),
                                _c("TitleHelper", [
                                  _vm._v(
                                    "#" + _vm._s(_vm.blockDetail.block.height)
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-divider", { staticClass: "mb-3" }),
                            _vm._v(" "),
                            _c(
                              "v-card",
                              {
                                staticClass: "border",
                                attrs: { "max-width": "1500", elevation: "0" },
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      [
                                        _c(
                                          "v-list",
                                          {
                                            staticClass:
                                              "py-0 borderRight fill-height",
                                          },
                                          [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "pr-3 font-weight-bold",
                                                  },
                                                  [_vm._v("Time:")]
                                                ),
                                                _vm._v(" "),
                                                _c("v-list-item-action", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("formatDateTime")(
                                                        _vm.blockDetail.block
                                                          .time
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-divider"),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "pr-3 font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Number of Transactions:"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("v-list-item-action", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.blockDetail.block
                                                        .txCount
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-divider"),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "pr-3 font-weight-bold",
                                                  },
                                                  [_vm._v("Confirmations:")]
                                                ),
                                                _vm._v(" "),
                                                _c("v-list-item-action", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.blockDetail.block
                                                        .confirmations
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-divider"),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "pr-3 font-weight-bold",
                                                  },
                                                  [_vm._v("Merkle root:")]
                                                ),
                                                _vm._v(" "),
                                                _c("v-list-item-action", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.blockDetail.block
                                                        .merkleRoot
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-divider"),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "pr-3 font-weight-bold",
                                                  },
                                                  [_vm._v("Difficulty:")]
                                                ),
                                                _vm._v(" "),
                                                _c("v-list-item-action", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("formatNumber")(
                                                        _vm.blockDetail.block
                                                          .difficulty
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      [
                                        _c(
                                          "v-list",
                                          { staticClass: "py-0 fill-height" },
                                          [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "pr-3 font-weight-bold",
                                                  },
                                                  [_vm._v("Height:")]
                                                ),
                                                _vm._v(" "),
                                                _c("v-list-item-action", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.blockDetail.block
                                                        .height
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-divider"),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "pr-3 font-weight-bold",
                                                  },
                                                  [_vm._v("Size:")]
                                                ),
                                                _vm._v(" "),
                                                _c("v-list-item-action", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("fileSize")(
                                                        _vm.blockDetail.block
                                                          .size
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-divider"),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "pr-3 font-weight-bold",
                                                  },
                                                  [_vm._v("Version:")]
                                                ),
                                                _vm._v(" "),
                                                _c("v-list-item-action", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.blockDetail.block
                                                        .version
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-divider"),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "pr-3 font-weight-bold",
                                                  },
                                                  [_vm._v("Nonce:")]
                                                ),
                                                _vm._v(" "),
                                                _c("v-list-item-action", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.blockDetail.block
                                                        .nonce
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-divider"),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "pr-3 font-weight-bold",
                                                  },
                                                  [_vm._v("Bits:")]
                                                ),
                                                _vm._v(" "),
                                                _c("v-list-item-action", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.blockDetail.block.bits
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("h1", { staticClass: "mt-4" }, [
                              _vm._v("Transactions"),
                            ]),
                            _vm._v(" "),
                            _c("v-divider", { staticClass: "mb-3" }),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mr-5",
                                                attrs: {
                                                  small: "",
                                                  depressed: false,
                                                  outlined: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.expanded = !_vm.expanded
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.expanded
                                                      ? "Collapse"
                                                      : "Expand"
                                                  ) +
                                                  "\n                            "
                                              ),
                                              _c(
                                                "v-icon",
                                                { attrs: { right: "" } },
                                                [
                                                  _vm._v(
                                                    "\n                                " +
                                                      _vm._s(
                                                        _vm.expanded
                                                          ? "mdi-arrow-collapse-vertical"
                                                          : "mdi-arrow-expand-vertical"
                                                      ) +
                                                      "\n                            "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3762986807
                                ),
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.expanded ? "Collapse" : "Expand"
                                    ) +
                                    " all transactions\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("PaginationWrapper", {
                              attrs: {
                                loading: _vm.blockDetailTransactionsLoading,
                                "pages-total": _vm.blockDetailTransactions
                                  ? _vm.blockDetailTransactions.last_page
                                  : 0,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _vm.blockDetailTransactionsLoading
                                          ? _c(
                                              "div",
                                              [
                                                _c("ProgressCircular", {
                                                  attrs: {
                                                    color: _vm.currency.unit,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("TransactionsHeader", {
                                          attrs: { "no-change": "" },
                                        }),
                                        _vm._v(" "),
                                        _c("v-divider"),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.blockDetailTransactions.data,
                                          function (transaction) {
                                            return _c("TransactionSingle", {
                                              key: transaction.txhash,
                                              staticClass: "mt-1 mb-1",
                                              attrs: {
                                                transaction: transaction,
                                                expanded: _vm.expanded,
                                              },
                                            })
                                          }
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2064561303
                              ),
                              model: {
                                value: _vm.filter.page,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "page", $$v)
                                },
                                expression: "filter.page",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }