<template>
    <div>
        <v-row no-gutters justify="center">
            <v-col style="border-bottom: 1px #0000001F solid; height: 49px">
                <v-app-bar elevation="0" dense color="white">
                    <v-app-bar dense color="white" class="px-4" style="margin: 0 auto; width: 160px"
                               elevation="0">
                        <v-tabs
                            show-arrows
                            slider-size="3"
                            :grow="false"
                            light
                            background-color="white"
                            :slider-color="currency.unit"
                            style="max-width: 95% !important;"
                            ref="tabs"
                        >
                            <v-tab
                                v-for="({route, publicName}, index) in tabs"
                                :key="route"
                                :to="finalRoutes[index].route"
                            >{{ publicName }}
                            </v-tab>
                        </v-tabs>
                        <v-spacer/>

                        <EntityHeaderLabel :label="currency.unit.toUpperCase() + ' CLUSTER:'" :entity="clusterCurrent"/>

                    </v-app-bar>
                </v-app-bar>
            </v-col>
        </v-row>
        <v-container class="px-4" fluid>
            <DataProvider name="entityId" :data="clusterCurrent">
                <router-view @component-loaded-event="onClick"/>
            </DataProvider>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import DataProvider from "@/components/common/DataProvider.vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {computed} from "vue";
import {DataState} from "@/components/common/types/data";
import EntityHeaderLabel from "@/views/Cluster/EntityHeaderLabel.vue";

export default {
    name: "ClusterView",
    components: {
        EntityHeaderLabel,
        DataProvider},
    data() {
        return {
            tabs: [
                {route: "/summary", publicName: "Summary"},
                {route: "/transactions", publicName: "Transactions"},
                {route: "/funds", publicName: "Funds"},
                {route: "/interactions", publicName: "Interactions"}
            ],
            categoriespagination: {
                page: 1,
                itemsPerPage: 100,
            },
            clusterInfoState: DataState.Initial,
            clusterInfo: null,
            getClusterInfo: null,
        }
    },
    created() {
        this.resetClusterState()
    },
    mounted() {
        const { dataState, data, getData } = useGetData("getClusterInfo", {
            currency: this.currency.unit,
            entity: this.clusterCurrent
        })
        this.clusterInfoState = dataState
        this.clusterInfo = data
        this.getClusterInfo = getData
    },
    beforeDestroy() {
        this.$store.commit('TRANSACTIONS_CANCEL_AND_CLEAR')
    },
    provide() {
        return {
            clusterInfoState: computed(() => this.clusterInfoState),
            clusterInfo: computed(() => this.clusterInfo),
            getClusterInfo: computed(() => this.getClusterInfo)
        }
    },
    watch: {
        "$route.params.clusterentity": function (val, oldVal) {
            if(val !== oldVal) {
                this.getClusterInfo({entity: val})
            }
            this.resetClusterState()
        },
        currency() {
            this.resetClusterState()
        }
    },
    computed: {
        ...mapGetters(["clusterCurrent","currency","clusterDetails","addressFundsHistoryFallback"]),
        finalRoutes() {
            return this.tabs.map(x => {
                return {...x, route: '/' + this.currency.unit + '/cluster/' + this.clusterCurrent + x.route}
            })
        }
    },
    methods: {
        onClick() {
            //fixes out-of-sync slider
            this.$refs.tabs && this.$refs.tabs.onResize();
        },
        resetClusterState() {
            this.$store.commit("CLUSTER_RESET_DATA")
            this.$store.commit("CLUSTER_SET_CURRENT", this.$route.params.clusterentity)
            this.$store.dispatch('loadCategories')
        }
    }
}
</script>