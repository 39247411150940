<template>
    <div class="pt-3 white">
        <Chart v-if="!isLoading" :options="chartOptions" />
        <ProgressCircular class="py-5" :color="currency.unit" v-else />
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import ProgressCircular from "@/components/common/ProgressCircular"
import { mapGetters } from "vuex";
import { keys } from 'highcharts';
export default {
    name: "ChartYears",
    components: {
        ProgressCircular,
        Chart
    },
    props: {
        isLoading: Boolean,
        years: Array,
        yearsR: Array,
        yearsS: Array
    },
    computed: {
        ...mapGetters(["currency"]),

        chartOptions() {
            return {
                chart: {
                    type: 'column',
                    height: 500// 16:9 ratio
                },
                credits: { enabled: false },
                title: { text: 'Yearly patterns' },
                xAxis: {
                    title: { text: 'Years' },
                    categories: this.years,
                },
                yAxis: {
                    title: { text: 'Transaction count' }
                },
                plotOptions: {
                    series: { stacking: 'normal' }
                },
                tooltip: {
                    formatter: function () {
                        return `is <b>${this.series.name}</b> on <b>${this.point.category}</b><br/>${Math.abs(this.point.y)} transaction(s)`
                    }
                },
                series: [
                    {
                        name: 'Receiving',
                        color: '#4caf50',
                        data: this.yearsR
                    },
                    {
                        name: 'Sending',
                        color: '#ff5252',
                        data: this.yearsS
                    }]
            };
        }
    }
}
</script>