var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: {
      title: _vm.isEditModal ? "Edit Owner" : "Create new Owner",
      loading: _vm.loading,
      width: "30%",
    },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field-validateable", {
                            attrs: {
                              rules: "required",
                              label: "Owner",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.detail.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "name", $$v)
                              },
                              expression: "detail.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    outlined: "",
                    color: "error",
                    disabled: _vm.ownersModalState === _vm.modalState.Pending,
                  },
                  on: { click: close },
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "success",
                    loading: _vm.ownersModalState === _vm.modalState.Pending,
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save(close, setDisabled)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.isEditModal ? "Save Owner" : "Create Owner")
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }