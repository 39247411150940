import {fileSize, formatNumber, titleCase, truncate} from "humanize-plus"
import {VueConstructor} from "vue"
import * as moment from "moment"
import {ICurrency} from "@/configurations/currency-config"

function stringShortener(stringToShort: string, maxLength = 10): string {
    if (!stringToShort) {
        console.warn("String passed to stringShortener was invalid.")
        return ""
    }

    if (stringToShort.length > maxLength + 3) {
        return `${stringToShort.substring(0, maxLength)}…`
    } else {
        return stringToShort
    }
}

const getIconStringWithOrigin = icon => {
    let iconOriginSubstring = icon.substring(0,3)

    if (iconOriginSubstring.includes('mdi')) {
        return `mdi ${icon}`
    } else if (iconOriginSubstring.includes('fa-')) {
        return `fa ${icon}`
    } else {
        console.error('Unknown icon origin.')
    }
}

const getIconString = (icon) : string => {
    const iconString = icon.icon ?? icon

    if (iconString.includes('mdi-')) {
        return iconString
    }

    //fallback
    return 'mdi-help-circle-outline'

}

const getBlockTimeFromBlockTimesObject = (blockHeight: number, blockTimesObject: object) => {
    if (!blockHeight) return
    let blockTime
    Object.keys(blockTimesObject).forEach(key => {
        if (parseInt(key) === blockHeight) {
            blockTime = blockTimesObject[key]
        }
    })
    return blockTime
}

const roundTimeToHighestTimeUnit = (timeDeficitInSeconds: number) => {
    if (timeDeficitInSeconds >= 31556952) {
        return {
            value: Math.floor(timeDeficitInSeconds / 31556952),
            unit: Math.floor(timeDeficitInSeconds) > 1 ? 'years' : 'year'
        }
    } else if (timeDeficitInSeconds >= 2628000) {
        return {
            value: Math.floor(timeDeficitInSeconds / 2628000),
            unit: Math.floor(timeDeficitInSeconds) > 1 ? 'months' : 'month'
        }
    } else if (timeDeficitInSeconds >= 86400) {
        return {
            value: Math.floor(timeDeficitInSeconds / 86400),
            unit: Math.floor(timeDeficitInSeconds) > 1 ? 'days' : 'day'
        }
    } else if (timeDeficitInSeconds >= 3600) {
        return {
            value: Math.floor(timeDeficitInSeconds / 3600),
            unit: Math.floor(timeDeficitInSeconds) > 1 ? 'hours' : 'hour'
        }
    } else if (timeDeficitInSeconds >= 60) {
        return {
            value: Math.floor(timeDeficitInSeconds / 60),
            unit: Math.floor(timeDeficitInSeconds) > 1 ? 'minutes' : 'minute'
        }
    } else {
        return {
            value: Math.floor(timeDeficitInSeconds),
            unit: Math.floor(timeDeficitInSeconds) > 1 ? 'seconds' : 'second'
        }
    }
}

function stringMiddleShortener(stringToShort: string, maxLength = 16): string {
    if (stringToShort.length > maxLength + 1) {
        return `${stringToShort.substring(0, Math.floor(maxLength / 2))}…${stringToShort.substring(stringToShort.length - (Math.floor(maxLength / 2)), stringToShort.length)}`
    } else {
        return stringToShort
    }
}

function numberFormat(n) {
    let nf = Intl.NumberFormat();
    return nf.format(n);
}

function numberFormatCompact(n) {
    // noinspection TypeScriptValidateTypes
    let nf = Intl.NumberFormat(undefined, {
        maximumFractionDigits: 1,
        //@ts-ignore
        notation: "compact",
        compactDisplay: "short"
    })
    return nf.format(parseInt(n));
}

function numberFormatWithSpaces(n) {
    return String(n).replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
}


function createImageUrl(imageName: string): string {
    return '/img/' + imageName
}

function formatDateTime(dateTime: string): string {
    return moment(dateTime).format("YYYY-MM-DD HH:mm:ss Z")
}

function convertFromSathosi(number: number, currency: ICurrency, decimals = 2): string {
    const value = number / 100000000
    return `${formatNumber(value, decimals)} ${currency.unit}`
}

function toFriendlyString(value: string) {
    return titleCase(value.toLowerCase().replace("_", " "))
}

function capitalize(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
}


function apply(vue: VueConstructor) {
    vue.filter('stringShortener', stringShortener)
    vue.filter('stringMiddleShortener', stringMiddleShortener)
    vue.filter('createImageUrl', createImageUrl)
    vue.filter('truncate', truncate)
    vue.filter('fileSize', fileSize)
    vue.filter('formatDateTime', formatDateTime)
    vue.filter('formatNumber', formatNumber)
    vue.filter('convertFromSathosi', convertFromSathosi)
    vue.filter('toFriendlyString', toFriendlyString)
    vue.filter('capitalize', capitalize)
    vue.filter('numberFormat', numberFormat)
    vue.filter('roundTimeToHighestTimeUnit', roundTimeToHighestTimeUnit)
    vue.filter('getBlockTimeFromBlockTimesObject', getBlockTimeFromBlockTimesObject)
    vue.filter('numberFormatCompact', numberFormatCompact)
    vue.filter('numberFormatWithSpaces', numberFormatWithSpaces)
    vue.filter('getIconString', getIconString)
    vue.filter('getIconOriginString', getIconStringWithOrigin)
}

export { createImageUrl, formatDateTime, capitalize, numberFormatCompact, getIconStringWithOrigin, stringMiddleShortener, numberFormat }
export default { apply }