import apiClient from "@/services/apiClient"
import axios from "axios"
import mappers from "models/AddressMetadataModels"

import {modalState} from "@/components/common/types/modal";

const state = {
    addressMetadata: {
        data: []
    },
    addressMetadataLoading: false,
    isAddressMetadataDetailLoading: false,
    addressMetadataDetail: undefined,
    addressMetadataCancelToken: axios.CancelToken.source(),
    addressMetadataModalState: modalState.Initial
}

const getters = {
    addressMetadata: (state) => state.addressMetadata,
    addressMetadataLoading: (state) => state.addressMetadataLoading,
    isAddressMetadataDetailLoading: (state) => state.isAddressMetadataDetailLoading,
    addressMetadataDetail: (state) => state.addressMetadataDetail,
    addressMetadataModalState: (state) => state.addressMetadataModalState,
}

const mutations = {
    ADDRESS_METADATA_START_LOADING(state) {
        state.addressMetadataCancelToken.cancel()
        state.addressMetadataCancelToken = axios.CancelToken.source()
        state.addressMetadataLoading = true
    },
    ADDRESS_METADATA_LOADED(state, data) {
        state.addressMetadata = data
    },
    ADDRESS_METADATA_FINISH_LOADING(state) {
        state.addressMetadataLoading = false
    },

    ADDRESS_METADATA_DETAIL_START_LOADING(state, data) {
        state.isAddressMetadataDetailLoading = true
    },
    ADDRESS_METADATA_DETAIL_LOADED(state, {detail}) {
        state.addressMetadataDetail = detail
    },
    ADDRESS_METADATA_DETAIL_FINISH_LOADING(state, data) {
        state.isAddressMetadataDetailLoading = false
    },
    ADDRESS_METADATA_MODAL_SET_STATE(state, data) {
        state.addressMetadataModalState = data
    }
}

const actions = {

    async loadAddressMetadata({commit, state, dispatch}, {currency, pagination}) {
        commit('ADDRESS_METADATA_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/addresses`, {
                headers: {cache: false},
                params: {
                    ...pagination
                },
                cancelToken: state.addressMetadataCancelToken.token
            })
            this.dispatch('success', response.message)
            commit('ADDRESS_METADATA_LOADED', response)
            commit('ADDRESS_METADATA_FINISH_LOADING')
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch('error', error.message)
                commit('ADDRESS_METADATA_FINISH_LOADING')
            }
        }

    },
    async updateAddressMetadata({commit}, {currency, updatedAddressMetadata}) {
        commit('ADDRESS_METADATA_START_LOADING')
        commit('ADDRESS_METADATA_MODAL_SET_STATE', modalState.Pending)
        const addressMetadataDetail = mappers.MapToServerModel(updatedAddressMetadata)
        try {
            const response = await apiClient.PUT(`${currency}/addresses/${updatedAddressMetadata.id}`, addressMetadataDetail);
            this.dispatch('success', response.message)
            commit('ADDRESS_METADATA_MODAL_SET_STATE', modalState.Success)
        } catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
            commit('ADDRESS_METADATA_MODAL_SET_STATE', modalState.Error)
        }
    },
    async createAddressMetadata({commit}, {currency, newAddressMetadata}) {
        commit('ADDRESS_METADATA_MODAL_SET_STATE', modalState.Pending)
        const addressMetadataDetail = mappers.MapToServerModel(newAddressMetadata)
        try {
            const response = await apiClient.POST(`${currency}/addresses`, addressMetadataDetail);
            this.dispatch('success', response.message)
            commit('ADDRESS_METADATA_MODAL_SET_STATE', modalState.Success)
            return true
        } catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
            commit('ADDRESS_METADATA_MODAL_SET_STATE', modalState.Error)
            return false
        }
    },
    async deleteAddressMetadata({commit}, {currency, addressMetadataId}) {
        try {
            const response = await apiClient.DELETE(`${currency}/addresses/${addressMetadataId}`)
            this.dispatch('success', response.message)
        } catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
        }
    },
    async loadAddressMetadataDetail({commit}, {currency, addressMetadataId}) {
        commit('ADDRESS_METADATA_DETAIL_START_LOADING')
        try {
            let response = await apiClient.GET(`${currency}/addresses/${addressMetadataId}`)
            response = mappers.MapToViewModel(response)
            commit('ADDRESS_METADATA_DETAIL_LOADED', {detail: response})
        } catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
        } finally {
            commit('ADDRESS_METADATA_DETAIL_FINISH_LOADING')
        }
    },
    async importAddressMetadataBulk({commit, dispatch}, {items, propagateToCluster, skipIdentities, currencyUnit}) {
        try {
            return !!await apiClient.PUT(`${currencyUnit}/import/addresses`, {
                addresses: items,
                propagate_to_cluster: propagateToCluster,
                skip_identities: skipIdentities
            })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch("error", `Failed to import addresses: ${error.message}`)
            }
        }
        return false
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
