<script setup>
import {computed, inject, isRef, ref, watch} from "vue";
import store from "@/store";
import AddressImporterMetadataMenu from "@/views/Addresses/Dialogs/AddressImporterMetadataMenu.vue";
import {useGetDataStatic} from "@/components/common/Composables/useGetDataStatic";
import {ImportFormat} from "@/components/common/types/import";

const props = defineProps({
    tableItems: {
        type: Array,
        required: true
    },
    loading: {
        type: Boolean,
        required: true
    },
    identitiesHeaders: {
        type: Array,
        required: false,
        default() {
            return [
                {text: "Label", align: "left", value: "label"},
                {text: "URL", align: "left", value: "url"},
                {text: "Source", align: "left", value: "source"},
            ]
        }
    },
})

const importFormat = inject("importFormat")

const isCaseImportType = computed(() => importFormat.value === ImportFormat.Case)

const headers = [
    {text: "Crypto*", align: "center", value: "crypto", width: "48px", sortable: false, filterable: false},
    {text: "Address*", align: "center", value: "address", sortable: false},
    {text: `Identities${isCaseImportType.value ? "*" : ""}`, align: "center", value: "identities", width: "200px", sortable: false},
    {text: "Categories", align: "center", value: "categories", width: "200px", sortable: false},
    {text: "Owners", align: "center", value: "owners", width: "200px", sortable: false},
    {text: "", align: "", value: "", width: "42px"},
]
const identitiesHeaders = props.identitiesHeaders
const categoriesHeaders = [
    {text: "Name", align: "left", value: "name"},
]
const ownersHeaders = [
    {text: "Name", align: "left", value: "name"},
]

const defaultCurrency = computed(() => store.getters.currencyOrDefault)

const deletedItems = []
const removeItemAtIndex = (index) => {
    deletedItems.push(props.tableItems.splice(index, 1)[0])
    getData({pagination: pagination.value})
}

const pagination = ref({page: 1, itemsPerPage: 20})

const tableItemsRef = computed(() => props.tableItems ?? [])
const {data, dataState, getData} = useGetDataStatic(tableItemsRef, {pagination: pagination.value})

const items = computed(() => {
    const dataItems = isRef(data) ? data.value?.data : data?.data
    if (dataItems) {
        return dataItems
    }
    return []
})

const tableDataTotalItems = computed(() => {
    const total = isRef(data) ? data.value?.total : data?.total

    if (total) {
        return total
    }

    return 0
})

watch(pagination, () => getData({pagination: pagination.value}))

const getDataAndResetPage = () => {
    getData({pagination: pagination.value})
    pagination.value.page = 1
}

defineExpose({getDataAndResetPage})
</script>

<template>
    <div>
        <v-data-table
            :footer-props="{'items-per-page-options':[5,10,20]}"
            :headers="headers"
            :height="32 * pagination.itemsPerPage + 52"
            :items="items"
            :server-items-length="tableDataTotalItems"
            :options.sync="pagination"
            :loading="loading"
            dense
            no-data-text="No imported rows"
        >
            <template #item="{ item, index }">
                <tr>
                    <td>
                        <v-text-field
                            v-model="item.crypto"
                            :placeholder="defaultCurrency?.unit"
                            class="fontMonospace"
                            hide-details
                            dense/>
                    </td>
                    <td class="fontMonospace">
                        <v-text-field
                            v-model="item.address"
                            class="fontMonospace"
                            hide-details
                            dense
                            placeholder="address"/>
                    </td>
                    <td>
                        <AddressImporterMetadataMenu
                            :headers="identitiesHeaders"
                            :items="item.identities"
                            icon="mdi-card-account-details-outline"
                            text-multiple="Identities"
                            text-single="Identity"/>
                    </td>
                    <td>
                        <AddressImporterMetadataMenu
                            :headers="categoriesHeaders"
                            :items="item.categories"
                            icon="mdi-label"
                            text-multiple="Categories"
                            text-single="Category"/>
                    </td>
                    <td>
                        <AddressImporterMetadataMenu
                            :headers="ownersHeaders"
                            :items="item.owners"
                            icon="mdi-account"
                            text-multiple="Owners"
                            text-single="Owner"/>
                    </td>
                    <td>
                        <v-btn color="error" icon x-small @click="removeItemAtIndex(index)">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                <span>
                    {{ pageStart | formatNumber }} - {{
                        pageStop | formatNumber
                    }} of {{ itemsLength | formatNumber }}
                </span>
            </template>
        </v-data-table>
    </div>
</template>