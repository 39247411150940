export default (adapter, options) => {
    const { wildcardsToCache, logEnabled = false } = options;
    return config => {
        const { url } = config;
        config.forceUpdate = !wildcardsToCache.some(i => (new RegExp(`/${i}/`).test(url)))
        if (logEnabled) {
            console.log(url + " request is forced:" + config.forceUpdate)
        }
        return adapter(config);
    }
}