import cxtmenu from "cytoscape-cxtmenu";
import {GERequestAction} from "@/store/modules/GraphExplorer";
import store from "@/store";
import {createIcon, getCyCenter} from "@/views/GraphExplorer/GEUtils";

export default function (cytoscape) {
    cytoscape.use(cxtmenu);

    let cy

    const requestAction = (actionType, ele) => {

        if (actionType === GERequestAction.NodesMerge) {
            const selectedNodes = cy.$(":selected")
            if (selectedNodes.toArray().length < 1) {
                return
            }

            store.dispatch("GERequestAction", {
                actionType: actionType,
                payload: selectedNodes,
                invoker: undefined,
            })
            cy.$(":selected").unselect()
            return
        }

        if (actionType === GERequestAction.NodesUnmerge) {
            store.dispatch("GERequestAction", {
                actionType: actionType,
                payload: ele.id(),
                invoker: undefined,
            })
            return
        }

        if (actionType === GERequestAction.NoteAddition) {
            store.dispatch("GERequestAction", {
                actionType: actionType,
                payload: getCyCenter(cy),
                invoker: undefined,
            })
            return
        }

        if (actionType === GERequestAction.NoteRemoval) {
            store.dispatch("GERequestAction", {
                actionType: actionType,
                payload: ele.id(),
                invoker: undefined,
            })
            return
        }

        if (actionType === GERequestAction.NoteUpdate) {
            store.dispatch("GERequestAction", {
                actionType: actionType,
                payload: {id: ele.id(), text: ele.data().text},
                invoker: undefined,
            })
            return
        }

        if (actionType === GERequestAction.NodeChangeLabel) {
            store.dispatch("GERequestAction", {
                actionType: actionType,
                payload: {id: ele.id(), text: ele.data().customLabel ?? 'Custom node'},
                invoker: undefined,
            })
            return
        }

        if (actionType === GERequestAction.AddressRemoval) {
            store.dispatch("GERequestAction", {
                actionType: actionType,
                payload: ele.id(),
                invoker: undefined,
            })
            return
        }

        if (actionType === GERequestAction.TxRemoval) {
            store.dispatch("GERequestAction", {
                actionType: actionType,
                payload: {txId: ele.id()},
                invoker: undefined,
            })
            return
        }


        const eleData = ele.data()
        store.dispatch("GERequestAction", {
            actionType: actionType,
            payload: eleData.typeSpecific,
            invoker: undefined,
        })
    }

    //TODO: use createIcon to create all the icons below

    const Activate = (newCy) => {
        cy = newCy
        cy.cxtmenu({
            selector: '.txOutEdge',
            commands: [
                {
                    content: `${createIcon('magnifying-glass-dollar')}<br><span style="font-size: 12px">Follow<br>Iteratively</span>`,
                    select: requestAction.bind(this, GERequestAction.OutputFollowIteratively)
                },
                {
                    content: `${createIcon('magnifying-glass-dollar')}<br><span style="font-size: 12px">Follow</span>`,
                    select: requestAction.bind(this, GERequestAction.OutputFollow)
                },
            ]
        });
        cy.cxtmenu({
            selector: '.transaction',
            commands: [
                {
                    content: `${createIcon('download', '20px', 'mdi', '270')}<br>Expand<br>Inputs`,
                    select: requestAction.bind(this, GERequestAction.TxExpandInputs)
                },
                {
                    content: `${createIcon('upload', '20px', 'mdi', '90')}<br>Expand<br>Outputs`,
                    select: requestAction.bind(this, GERequestAction.TxExpandOutputs)
                },
                {
                    content: `<i class="fa-solid fa-trash" style="font-size: 16px;"></i><br>Remove`,
                    select: requestAction.bind(this, GERequestAction.TxRemoval)
                },
            ]
        });
        cy.cxtmenu({
            selector: '.merge',
            commands: [
                {
                    content: `<i class="fa-solid fa-pen-to-square" style="font-size: 16px;"></i><br>Rename`,
                    select: requestAction.bind(this, GERequestAction.NodeChangeLabel)
                },
                {
                    content: `<i class="fa-solid fa-trash" style="font-size: 16px;"></i><br>Ungroup`,
                    select: requestAction.bind(this, GERequestAction.NodesUnmerge)
                },
            ]
        });
        cy.cxtmenu({
            selector: '.address',
            commands: [
                {
                    content: `<i class="fa-solid fa-trash" style="font-size: 16px;"></i><br>Remove`,
                    select: requestAction.bind(this, GERequestAction.AddressRemoval)
                },
            ]
        });
        cy.cxtmenu({
            selector: '.note',
            menuRadius: function (ele) {
                return 100
            },
            atMouse: true,
            commands: [
                {
                    content: `<i class="fa-solid fa-trash" style="font-size: 16px;"></i><br>Remove`,
                    select: requestAction.bind(this, GERequestAction.NoteRemoval)
                },
                {
                    content: `<i class="fa-solid fa-pen-to-square" style="font-size: 16px;"></i><br>Edit`,
                    select: requestAction.bind(this, GERequestAction.NoteUpdate)
                },
            ]
        });
        cy.cxtmenu({
            selector: 'core',
            commands: [
                {
                    content: `<i class="v-icon mdi mdi-set-merge theme--dark" style="font-size: 30px;"></i><br>Group selected`,
                    select: requestAction.bind(this, GERequestAction.NodesMerge)
                },
                {
                    content: `${createIcon('note-sticky', '20px', 'fa')}<br>Add note`,
                    select: requestAction.bind(this, GERequestAction.NoteAddition)
                },
            ]
        });
    }

    return {Activate}
}