<script setup>
import {copy} from "@/utils";

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: "Entity",
    },
    entity: {
        type: String,
        required: true,
    },
    justify: {
        type: String,
        required: false,
        default: "end"
    }
})
</script>

<template>
    <div class="d-flex align-center mr-5" :class="`justify-${justify}`" style="width: 100%; min-width: 200px">
        <div class="mx-1">
            <nobr>
                <div class="font-weight-medium" style="font-size: 14px;">
                    {{ label }}
                </div>
            </nobr>
        </div>
        <div class="fontMonospace pl-2 d-flex align-center overflow-hidden"
             style="background-color: #EEEEEE; border-radius: 6px;">
            <div class="text-truncate">
                {{ entity }}
            </div>
            <v-btn color="rgba(0, 0, 0, 0.7)"
                   class="ml-1"
                   style="min-width: 30px; padding: 0; height: 30px"
                   small
                   text
                   @click.stop="() => copy(entity)">
                <v-icon small>content_copy</v-icon>
            </v-btn>
        </div>
    </div>
</template>