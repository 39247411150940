var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attr }) {
            return [
              _vm.date
                ? _c("div", _vm._g(_vm._b({}, "div", attr, false), on), [
                    _c(
                      "span",
                      {
                        class: [
                          "d-inline-block",
                          "font-weight-light",
                          "font-italic",
                        ],
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.date) +
                            "\n            "
                        ),
                      ]
                    ),
                  ])
                : _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "d-inline-flex justify-center",
                        staticStyle: { "max-width": "150px" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-inline-block font-weight-light font-italic",
                          },
                          [
                            _vm._v(
                              "\n                    Unconfirmed date\n                "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _vm._t("tooltip")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }