import {getDerivationPath} from "@/components/MnemonicConvertor/DerivationPathHelper";
import * as b58 from "bs58check";

const getBip32ExtendedKey = (derivationPath, bip32RootKey, selectedCrypto) => {
    // Check if there's a root key to derive from
    if (!bip32RootKey) {
        return bip32RootKey
    }

    let extendedKey = bip32RootKey
    // Derive the key from the path
    const pathBits = derivationPath.split("/")

    for (let i=0; i<pathBits.length; i++) {
        const bit = pathBits[i]
        const index = parseInt(bit)
        if (isNaN(index)) {
            continue
        }
        const hardened = bit[bit.length-1] === "'"
        const isPriv = !(extendedKey.isNeutered())
        let invalidDerivationPath = hardened && !isPriv
        if (invalidDerivationPath) {
            extendedKey = null
        }
        else if (hardened) {
            extendedKey = extendedKey.deriveHardened(index)
        }
        else {
            extendedKey = extendedKey.derive(index)
        }
    }

    return extendedKey
}

function formatExtendedKey(xpub, targetFormat) {
    let data = b58.decode(xpub)
    data = data.slice(4)
    data = Buffer.concat([Buffer.from(targetFormat.toString(16).padStart(8, 0), 'hex'), Buffer.from(data)])
    return b58.encode(data)
}

const formatExtendedKeys = (bipSpecs, selectedBip, bip32RootKey, selectedCrypto) => {
    const selectedBipSpecs = bipSpecs[selectedBip]
    let extendedPrivateKey = 'N/A'

    const extendedKey = getBip32ExtendedKey(getDerivationPath(bipSpecs, selectedBip), bip32RootKey, selectedCrypto)

    if(selectedBip === 32 && extendedKey.isNeutered()) {
        //do nothing
    } else {
        extendedPrivateKey = formatExtendedKey(extendedKey.toBase58(), selectedBipSpecs.extendedPrivateKeyFormat)
    }
    const extendedPublicKey = formatExtendedKey(extendedKey.neutered().toBase58(), selectedBipSpecs.extendedPublicKeyFormat)

    selectedBipSpecs.extendedPrivateKey = extendedPrivateKey
    selectedBipSpecs.extendedPublicKey = extendedPublicKey

}

export { getBip32ExtendedKey, formatExtendedKeys }