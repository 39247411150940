var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    staticClass: "cardShadow",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-center justify-space-between" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-icon", { staticClass: "d-block mr-2 ml-1" }, [
                      _vm._v("mdi-tag-text-outline"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Addresses"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(_setup.CaseAddressImportDialog, {
                  attrs: { "case-id": _setup.caseId },
                  on: {
                    addressesImported: () =>
                      _setup.CaseAddressTablesRef.refreshAddressTables(),
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [_c(_setup.CaseAddressTables, { ref: "CaseAddressTablesRef" })]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }