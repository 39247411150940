var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ma-4" },
    [
      _c(_setup.ViewHeader, { attrs: { text: "System Status" } }),
      _vm._v(" "),
      _setup.dataState === _setup.DataState.Loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c("VProgressCircular", {
                attrs: { color: "tertiary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _setup.dataState === _setup.DataState.Loaded
        ? _c(
            "v-row",
            _vm._l(_setup.currencies, function ({ unit: currencyUnit }) {
              return _c(
                "v-col",
                {
                  key: currencyUnit,
                  attrs: { cols: "12", xl: "2", md: "4", sm: "6" },
                },
                [
                  _c(_setup.FancyCard, {
                    attrs: { "no-gutters": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "d-flex" }, [
                                _c("img", {
                                  staticClass: "mr-2",
                                  staticStyle: { width: "22px" },
                                  attrs: {
                                    alt: currencyUnit,
                                    src: _setup.store.getters.currencyByUnit(
                                      currencyUnit
                                    ).image,
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _setup.store.getters.currencyByUnit(
                                          currencyUnit
                                        ).name
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "body",
                          fn: function () {
                            return [
                              _c("v-simple-table", { attrs: { dense: "" } }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        "\n                                Service\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                                Status\n                            "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    Object.keys(_setup.data.data[currencyUnit]),
                                    function (serviceKey) {
                                      return _c("tr", { key: serviceKey }, [
                                        _c("td", { staticClass: "text-left" }, [
                                          _vm._v(_vm._s(serviceKey)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _setup.data.data[currencyUnit][
                                              serviceKey
                                            ]
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "success" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    fas fa-circle-check\n                                "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  { attrs: { color: "error" } },
                                                  [
                                                    _vm._v(
                                                      "\n                                    fas fa-circle-xmark\n                                "
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }