/*

Mixin which takes care of syncing an object named filter into route's query!

Requires filterInfo object (defined in data of the component) that defines the structure of the filter

Each property of filterInfo represents: filter's property of the same name
                            has type which defines data type (needed to be properly read from the route)
                            has default which is a function that returns default (initial) value of the prop

calls component's methods: onFilterInitial (filter was in route when beforeMount)
                           onFilterChange (filter changed)

component can set even set initial route in created hook

--template--


    created() {
        //Set initial route (before it is read from mixin)
        //this.$_syncToRouteQuery(true, FILTER)
    },

    methods: {

        // -- mixin methods --
        onFilterInitial()
        {
            //Initial Filter was not default (and was read from the route)
        },
        onFilterChange()
        {
            //Filter Changed
        },
        onFilterLoaded()
        {
            //Filter loaded
        }
    }



*/


import {deepClone, isObjectEmpty} from "@/utils";

export default {
    data() {
      return {
          filter: undefined
      }
    },
    beforeMount() {
        if(this.filterInfo === undefined)
            console.log("filterInfo object for routeSyncMixin NOT FOUND")

        let routeFilter = this.$_getFilterFromRoute()
        this.filter = {...this.$_getDefaultFilter(), ...routeFilter}

        //if filter was set in route
        if (!isObjectEmpty(routeFilter))
        {
            this.onFilterInitial()
        }

        this.onFilterLoaded()
    },
    watch: {
        filter : {
            handler()
            {
                //check if a route is in sync with filter (route has to be read with '$_getFilterFromRoute' because arrays are removed.
                if (!this.$_compareFilters(this.filter, {...this.$_getDefaultFilter(), ...this.$_getFilterFromRoute()}))
                {
                    this.$_syncToRouteQuery()
                    this.onFilterChange()
                }
            },
            deep: true,
        },
        $route: {
            handler()
            {
                //check if a route is in sync with filter (route has to be read with '$_getFilterFromRoute' because arrays are removed).
                if (!this.$_compareFilters(this.filter, {...this.$_getDefaultFilter(), ...this.$_getFilterFromRoute()}))
                {
                    this.filter =  {...this.$_getDefaultFilter(), ...this.$_getFilterFromRoute()}
                    this.onFilterChange()
                }
            },
            deep: true,
        }
    },
    methods: {
        $_getFilterFromRoute()
        {
            let routeParams = {}
            for (const key in this.$route.query)
            {
                if (this.filterInfo[key]?.type === "Boolean")
                {
                    if (String(this.$route.query[key]) && Boolean(String(this.$route.query[key]))) {
                        routeParams[key] =
                            String(this.$route.query[key]).toLowerCase() === "true";
                    }
                }

                if (this.filterInfo[key]?.type === "String")
                {
                    if (this.$route.query[key]) {
                        routeParams[key] = String(this.$route.query[key])
                    }
                }

                if (this.filterInfo[key]?.type === "ArrayOfNumbers")
                {
                    if (Array.isArray(this.$route.query[key])) {
                        routeParams[key] = this.$route.query[key].map((i) => Number(i));
                    } else {
                        routeParams[key] = [Number(this.$route.query[key])]
                    }
                }

                if (this.filterInfo[key]?.type === "ArrayOfStrings")
                {
                    if (Array.isArray(this.$route.query[key])) {
                        routeParams[key] = deepClone(this.$route.query[key]);
                    } else {
                        routeParams[key] = [String(this.$route.query[key])]
                    }
                }

                if (this.filterInfo[key]?.type === "ArrayOfBooleans")
                {
                    if (Array.isArray(this.$route.query[key])) {
                        routeParams[key] = this.$route.query[key].map(x => String(x).toLowerCase() === "true")
                    } else {
                        routeParams[key] = [String(this.$route.query[key]).toLowerCase() === "true"]
                    }
                }


                if (this.filterInfo[key]?.type === "Number")
                {
                    routeParams[key] = Number(this.$route.query[key])
                }
            }
            return routeParams
        },
        $_syncToRouteQuery(replace, filterIn)
        {
            let filter
            if (filterIn)
            {
                filter = filterIn
            } else
            {
                filter = this.filter
            }

            let new_query = {};
            let default_filter = this.$_getDefaultFilter()
            //create new query from scratch, don't include default values
            for (const key in default_filter) {
                //Value of key is not default
                if ((JSON.stringify(filter[key]) !== JSON.stringify(default_filter[key])) && filter[key] !== null) {
                        new_query[key] = filter[key]
                }
            }
            //take current query, remove all filter properties managed by this mixin
            let originalQuery = deepClone(this.$route.query)
            for (const key in this.$_getDefaultFilter())
            {
                if (originalQuery.hasOwnProperty(key))
                {
                    delete originalQuery[key]
                }
            }
            // take originalQuery as a base, add newly computed query from scratch on top
            if (replace)
            {
                this.$router.replace({query: {...originalQuery, ...deepClone(new_query)}}).catch(error => {
                    if (error.name !== "NavigationDuplicated")
                    {
                        console.error(error.name)
                        throw error
                    }
                })
            } else
            {
                this.$router.push({query: {...originalQuery, ...deepClone(new_query)}}).catch(error => {
                    if (error.name !== "NavigationDuplicated")
                    {
                        console.error(error.name)
                        throw error
                    }
                })
            }

        },
        $_getDefaultFilter()
        {
            let result = {}
            for (const key in this.filterInfo)
            {
                result[key] = this.filterInfo[key].default()
            }

            return result
        },
        $_compareFilters(a,b) {
            for (const key in this.$_getDefaultFilter())
            {
                if (JSON.stringify(a[key]) !== JSON.stringify(b[key]))
                {
                    return false;
                }
            }
            return true
        },


        // -- mixin methods --
        onFilterInitial()
        {

        },
        onFilterChange()
        {

        },
        onFilterLoaded()
        {

        }
    }
};
