<script setup>
import NameValueFlex from "@/components/common/NameValueFlex.vue";
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import {inject} from "vue";
import VisibilityDisplay from "@/components/common/Displays/VisibilityDisplay.vue";
import WarningDisplay from "@/components/common/Displays/WarningDisplay.vue";

const caseDetail = inject("CaseDetail")
const loading = inject("Loading")


</script>

<template>
    <v-row>
        <v-col cols="5" lg="5" md="5">
            <FancyCard>
                <template #header>
                    <div class="d-flex align-center">
                        <v-icon class="d-block mr-2 ml-1">assignment</v-icon>
                        <span class="font-weight-bold">Case Details</span>
                    </div>
                </template>
                <template #body>
                    <NameValueFlex :loading="loading" loading-width="100">
                        <template #name>
                            Visibility
                        </template>
                        <template #value>
                            <VisibilityDisplay :value="caseDetail.is_hidden"/>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="100">
                        <template #name>
                            Warning
                        </template>
                        <template #value>
                            <WarningDisplay :value="caseDetail.is_warning"/>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="100">
                        <template #name>
                            Reported At:
                        </template>
                        <template #value>
                            <span>{{ caseDetail.reported_at | formatDateTime }}</span>
                        </template>
                    </nameValueFlex>
                    <v-divider class="my-2"/>
                    <NameValueFlex :loading="loading" loading-width="100">
                        <template #name>
                            Created At:
                        </template>
                        <template #value>
                            <span>{{ caseDetail.created_at | formatDateTime }}</span>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="100">
                        <template #name>
                            Created By:
                        </template>
                        <template #value>
                            <span>{{ caseDetail.creator.name }}</span>
                        </template>
                    </nameValueFlex>
                    <v-divider class="my-2"/>
                    <NameValueFlex :loading="loading" loading-width="100">
                        <template #name>
                            Updated At:
                        </template>
                        <template #value>
                            <span>{{ caseDetail.updated_at | formatDateTime }}</span>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="100">
                        <template #name>
                            Updated By:
                        </template>
                        <template #value>
                            <span>{{ caseDetail.updator.name }}</span>
                        </template>
                    </nameValueFlex>
                </template>
            </FancyCard>
        </v-col>
        <v-col cols="7" lg="7" md="7">
            <FancyCard :loading="loading" class="descriptionContainer">
                <template #header>
                    <div class="d-flex align-center">
                        <v-icon class="d-block mr-2 ml-1" width="22">description</v-icon>
                        <span class="font-weight-bold">Case Description</span>
                    </div>
                </template>
                <template #body>
                    <v-textarea
                        v-if="caseDetail.description"
                        v-model="caseDetail.description"
                        class="fontMonospace caseDescription"
                        no-resize
                        readonly

                    />
                    <div v-else class="title font-weight-light text-center">
                        This case has no description.
                    </div>
                </template>
            </FancyCard>
        </v-col>
    </v-row>
</template>

<style>
.caseDescription textarea {
    line-height: 20px !important;
}

.descriptionContainer .caseDescription {
    padding-top: 0;
    margin-top: 0;
}
</style>
