<template>
    <div class="mt-5">
        <FundsFilter
            :currency="currency"
            :categories="categories"
            @search-funds-event="onSearch">
            <template #header>
                <HeaderLined style="max-width: 200px">
                    <span style="color: rgba(0, 0, 0, 0.6); font-weight: 400 ">
                        Filter
                    </span>
                </HeaderLined>
            </template>
        </FundsFilter>
        <HeaderLined class="mb-1 mx-6">
            <span style="color: rgba(0, 0, 0, 0.6); font-weight: 400 ">
                Result
            </span>
        </HeaderLined>
        <FundsResult
            :filter="filter"
            :currency="currency"
            :categories="categories"
            :addressFunds="currentClusterFunds"
            :addressFundsLoading="currentFundsLoading"
            :is-logaritmic="currentAddressFundsIsLogarithmic"
            :current-cluster="clusterCurrent"/>
    </div>
</template>

<script>
import FundsFilter from "@/components/Funds/FundsFilter.vue";
import {mapGetters} from "vuex";
import HeaderLined from "@/components/common/HeaderLined.vue";
import FundsResult from "@/components/Funds/FundsResult.vue";
import ExportMenu from "@/components/common/Buttons/ExportMenu.vue";

export default {
    name: "ClusterFunds",
    components: {ExportMenu, FundsResult, HeaderLined, FundsFilter},
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(["currency", "categories", "clusterCurrent", "addressFundsLoading", "addressFundsData", "addressFundsIsLogarithmic", "addressFundsHistoryFallback"]),
        clusterCurrent() {
            return this.$route.params.detailCluster ?? this.$store.getters.clusterCurrent
        },
        currentFundsLoading() {
            return !!this.addressFundsLoading[this.clusterCurrent]
        },
        currentAddressFundsIsLogarithmic() {
            return !!this.addressFundsIsLogarithmic[this.clusterCurrent]
        },
        currentClusterFunds() {
            return this.addressFundsData[this.clusterCurrent]
        },
        filter() {
            return this.addressFundsHistoryFallback[this.clusterCurrent]
        }
    },
    beforeUpdate() {
        //Update visual slider (workaround)
        this.$emit("component-loaded-event");
    },
    methods: {
        onSearch(filter) {
            this.$store.dispatch("loadAddressFunds", {
                currency: this.currency.unit,
                filter: filter,
                entityAddress: this.clusterCurrent
            })
        }
    }
}
</script>

<style scoped>


</style>