import { render, staticRenderFns } from "./TransactionSankeyDiagram.vue?vue&type=template&id=0c83c177&scoped=true&"
import script from "./TransactionSankeyDiagram.vue?vue&type=script&setup=true&lang=js&"
export * from "./TransactionSankeyDiagram.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./TransactionSankeyDiagram.vue?vue&type=style&index=0&id=0c83c177&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c83c177",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VExpandTransition,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c83c177')) {
      api.createRecord('0c83c177', component.options)
    } else {
      api.reload('0c83c177', component.options)
    }
    module.hot.accept("./TransactionSankeyDiagram.vue?vue&type=template&id=0c83c177&scoped=true&", function () {
      api.rerender('0c83c177', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/transaction/Components/TransactionSankeyDiagram.vue"
export default component.exports