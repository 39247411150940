<script setup>

import {useVModelInput} from "@/components/common/Composables/useVModelnput";

const props = defineProps({
    value: {
        required: false
    },
    icon: {
        type: String,
        required: false,
        default: "mdi-information-slab-box"
    },
    headerText: {
        type: String,
        required: false,
        default: "Additional information"
    },
    iconColor: {
        type: String,
        required: false,
        default: 'info'
    }
})

const emit = defineEmits('input')
const expandedPanels = useVModelInput(props, emit)

</script>

<template>
    <v-expansion-panels v-model="expandedPanels">
        <v-expansion-panel>
            <v-expansion-panel-header :value="true" style="padding: 8px; min-height: 32px; font-size: 14px;">
                <div>
                    <slot name="header.left">
                        <v-icon :color="iconColor" left>
                            {{ icon }}
                        </v-icon>
                        {{ headerText }}
                    </slot>
                </div>
                <template v-slot:actions>
                    <slot name="header.right">
                        <v-icon color="primary">
                            $expand
                        </v-icon>
                    </slot>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <slot name="default"/>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<style scoped>

</style>