<template>
    <div>
        <v-layout row class="px-2 py-1">
            <b>Owner</b>
            <span class="ml-2">{{data.name}}</span>
            <v-spacer />
            <div class="no-wrap pl-2">{{data.created_at | moment('YYYY-MM-DD HH:mm:ss Z')}}</div>
        </v-layout>
        <v-divider />
        <v-layout column align-start class="mx-2 pb-1">
            <b>Addresses:</b>

            <template v-if="!showMore">
                <template v-for="address in data.addresses.slice(0,5)">
                    <router-link
                        v-if="address.crypto"
                        class="text-overflow ml-2 text-decoration-none"
                        :to="'/' + address.crypto + '/address/' + address.address"
                    >
                        <img
                            class="currencyImg mx-2"
                            :src="('cryptoIcons/color/' + address.crypto.toLowerCase() + '.svg') | createImageUrl"
                        />
                        <code class="elevation-0">{{address.address}}</code>
                    </router-link>
                    <code v-else class="elevation-0">{{address.address}}</code>
                </template>
                <v-btn
                    v-if="data.addresses.length > 5"
                    color="primary"
                    dark
                    small
                    class="mx-auto d-block mt-2"
                    depressed
                    @click="showMore = true"
                >
                    Show more
                    <v-icon>expand_more</v-icon>
                </v-btn>
            </template>

            <template v-else>
                <template v-for="address in data.addresses">
                    <router-link
                        v-if="address.crypto"
                        class="text-overflow ml-2 text-decoration-none"
                        :to="'/' + address.crypto + '/address/' + address.address"
                    >
                        <img
                            class="currencyImg mx-2"
                            :src="('cryptoIcons/color/' + address.crypto.toLowerCase() + '.svg') | createImageUrl"
                        />
                        <code class="elevation-0">{{address.address}}</code>
                    </router-link>
                    <code v-else class="elevation-0">{{address.address}}</code>
                </template>
                <v-btn
                    color="primary"
                    dark
                    small
                    class="mx-auto d-block mt-2"
                    depressed
                    @click="showMore = false"
                >
                    Show less
                    <v-icon>expand_less</v-icon>
                </v-btn>
            </template>
        </v-layout>
    </div>
</template>
<script>

export default {
    name: 'OwnerResult',
    props: {
        data: {}
    },
    data() {
        return {
            showMore: false
        }
    }
}
</script>
<style scoped>
.currencyImg {
    vertical-align: middle;
    width: 20px;
}
</style>