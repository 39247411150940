<template>
    <div class="height100">
        <h1>Blocks
            <TitleHelper :loading="titleTotalNumberOfBlocks ? false : loading">
                {{ titleTotalNumberOfBlocks }}
            </TitleHelper>
        </h1>
        <v-divider/>
        <v-divider class="mb-3"/>
        <nobr>
            <v-card class="border data-table-container" elevation="0">
                <v-data-table
                    :headers="headers"
                    dense
                    :items="blocksData ? blocksData.data : []"
                    :server-items-length="blocksData ? blocksData.total : 0"
                    :loading="loading"
                    loading-text="Loading blocks..."
                    :footer-props="{
                        'items-per-page-options': [30, 50, 200],
                        'items-per-page-text': 'Blocks per page',
                        'show-current-page': true,
                        'showFirstLastPage': true,
                    }"
                    class="border"
                    :options.sync="filter"
                >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td class="tabledatatd fontMonospace">{{ props.item.height | formatNumber }}</td>
                            <td class="tabledatatd fontMonospace">
                                <Copyable :on-copy="props.item.hash">
                                    <template #text="{ on }">
                                        <router-link
                                            class="link"
                                            :to="'/' + currency.unit + '/block/' + props.item.hash"
                                        >
                                            <span v-on="on">
                                                {{ props.item.hash }}
                                            </span>
                                        </router-link>
                                    </template>
                                </Copyable>
                            </td>
                            <td class="tabledatatd">{{ props.item.time * 1000 | formatDateTime }}</td>
                            <td class="text-end fontMonospace">{{ props.item.txlength | formatNumber }}</td>
                            <td class="text-end fontMonospace">{{ props.item.size | fileSize }}</td>
                        </tr>
                    </template>
                    <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                        <span>
                            {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                        </span>
                    </template>
                </v-data-table>
            </v-card>
        </nobr>
    </div>
</template>

<script>

import Copyable from "@/components/common/Copyable";
import {mapGetters} from "vuex";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import TitleHelper from "@/components/common/TitleHelper";
import {formatNumber} from "humanize-plus";

export default {
    name: 'BlocksView',
    mixins: [routeSyncMixin],
    components: {TitleHelper, Copyable},
    computed: {
        ...mapGetters(["currency", "blocksData"]),
        address() {
            return this.$store.getters.addressInfo
        },
        loading() {
            return this.$store.getters.blocksLoading
        },
        titleTotalNumberOfBlocks() {
            if (!this.blocksData?.total)
                return undefined
            return `(${formatNumber(this.blocksData.total)})`
        }
    },
    methods: {
        fetchBlocks() {
            this.$store.dispatch('loadBlocksPage', {
                currency: this.currency.unit,
                page: this.filter.page,
                itemsPerPage: this.filter.itemsPerPage
            })
        },
        onFilterChange() {
            this.fetchBlocks()
        },
        onFilterLoaded() {
            this.fetchBlocks()
        }
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30}
            },
            date: this.$moment().format('YYYY-MM-DD'),
            headers: [
                {text: 'Height', align: 'center', value: 'height', sortable: false},
                {text: 'Hash', align: 'center', value: 'hash', sortable: false},
                {text: 'Time', align: 'center', value: 'time', sortable: false},
                {text: 'Transactions', align: 'right', value: 'txlength', sortable: false},
                {text: 'Size', align: 'right', value: 'size', sortable: false},
            ]
        }
    }
}
</script>

<style>
.tabledatatd {
    text-align: center;
    vertical-align: middle;
}
</style>
