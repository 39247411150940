import apiClient from "@/services/apiClient"
import Vue from 'vue'


const state = {
    menuAddressDetail: {},
    menuAddressDetailLoading: {}
}

const getters = {
    menuAddressDetail: (state) =>  state.menuAddressDetail,
    menuAddressDetailLoading: (state) => state.menuAddressDetailLoading,
    menuAddressDetailPerAddress: (state) => (address) =>  state.menuAddressDetail[address],
    menuAddressDetailLoadingPerAddress: (state) => (address) => !!state.menuAddressDetailLoading[address],
}

const mutations = {
    MENU_ADDRESS_SET_DETAIL(state, data) {
        Vue.set(state.menuAddressDetail, data.key, data.value)
    },
    MENU_ADDRESS_DETAIL_SET_LOADING(state, data) {
        Vue.set(state.menuAddressDetailLoading, data.key, data.value)
    },
}

const actions = {
    async loadMenuAddressDetail({ commit }, {currency, address}) {
        commit('MENU_ADDRESS_DETAIL_SET_LOADING',{key: address, value: true})
        let response = await apiClient.GET(
            `${currency}/cryptoaddress/${address}/basic`,
        );
        commit('MENU_ADDRESS_SET_DETAIL', {key: address, value: response.data})
        commit('MENU_ADDRESS_DETAIL_SET_LOADING',{key: address, value: false})
    },
    async getAddressDetail({ commit, dispatch }, {currency, address}) {
        let response = null
        try {
            response = await apiClient.GET(
                `${currency}/cryptoaddress/${address}/basic`,
            )
        } catch (error) {
            throw error
        }
        return response
    },

}

export default {
    state,
    mutations,
    actions,
    getters
}
