<script setup>
import BaseModalDialog from "@/components/common/Base/BaseModalDialog";
import {ref} from "vue";

const baseModal = ref(null)

const openModal = () => {
    baseModal.value.open()
}

defineExpose({
    openModal,
})

</script>

<template>
    <BaseModalDialog
        title="Error"
        ref="baseModal">
        <template #content>
            <div class="d-flex">
                <div>
                    You need to provide <span class="font-weight-bold">at least one</span> of the following filters:
                    <ul>
                        <li>Timestamp</li>
                        <li>Input Addresses</li>
                        <li>Output Addresses</li>
                        <li>Input Clusters</li>
                        <li>Output Clusters</li>
                    </ul>
                </div>
                <v-spacer/>
                <div class="d-flex justify-center align-center">
                    <v-icon color="red" large>
                        fa-solid fa-triangle-exclamation
                    </v-icon>
                </div>
            </div>
        </template>
        <template #footer="{close}">
            <v-spacer/>
            <v-btn
                outlined
                color="success"
                text
                @click="close"
            >
                OK
            </v-btn>
        </template>

    </BaseModalDialog>
</template>

<style scoped>

</style>