<template>
    <ValidationProvider
        ref="validator"
        v-slot="{ errors }"
        :disabled="$attrs.disabled"
        :name="$attrs.name ? $attrs.name : $attrs.label"
        :rules="rules"

    >
        <v-text-field
            v-model="innerValue"
            :dense="dense"
            :error-messages="errors"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template #append>
                <slot name="append"/>
            </template>
            <template #prepend>
                <slot name="prepend"/>
            </template>
        </v-text-field>
    </ValidationProvider>
</template>

<script>
export default {
    name: "VTextFieldValidateable",
    expose: ['validate'],
    props: {
        rules: {
            type: [Object, String],
            default: ""
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        // must be included in props
        value: {
            type: null
        }
    },
    data: () => ({
        innerValue: ""
    }),
    watch: {
        // Handles internal model changes.
        innerValue(newVal) {
            this.$emit("input", newVal);
        },
        // Handles external model changes.
        value(newVal) {
            this.innerValue = newVal;
        }
    },
    created() {
        if (this.value) {
            this.innerValue = this.value;
        }
    },
    methods: {
        async validate() {
            return await this.$refs.validator.validate()
        }
    }
};
</script>
