<script setup>
import {BaseModalDialog} from "@/components/common";
import {ref} from "vue";

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: "Remove multiple items"
    },
    itemsToRemove: {
        type: Array,
        required: true
    },
})

const emit = defineEmits(['delete-event'])

const modalRef = ref(null)

let setDisabledCB = () => undefined

const open = () => {
    modalRef.value.open()
}
const close = () => {
    setDisabledCB(false)
    modalRef.value.close()
}
defineExpose({open, close})


const onDelete = (close, setDisabled) => {
    setDisabled(true)
    setDisabledCB = setDisabled
    emit('delete-event', props.itemsToRemove)
}

</script>

<template>
    <BaseModalDialog
        ref="modalRef"
        :title="title"
        width="80vh">
        <template #content>
            <v-icon color="error" large left>mdi-delete</v-icon>
            <b>{{ itemsToRemove.length }}</b> items will be <b>permanently</b> deleted!
        </template>
        <template #footer="{close, setDisabled}">
            <v-btn color="grey" @click="close">
                Cancel
            </v-btn>
            <v-spacer/>
            <v-btn color="error" @click="onDelete(close, setDisabled)">
                Delete
            </v-btn>
        </template>
    </BaseModalDialog>
</template>

<style scoped>

</style>