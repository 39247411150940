import router from "@/router"
import {buildStringTemplate, getCountry, getWebSocketProtocol, processTx, removeDuplicatesFromArray} from "@/utils"
import apiClient from "@/services/apiClient"
import {MapToServerModel} from "@/store/modules/models/TransactionsModel"
import axios from "axios";
import Vue from "vue";
import {EntityType} from "@/components/common/types/entity";


const state = {
    // Used for page of transactions
    transactions: [],
    transactionsLoading: false,
    transactionsPagination: null,
    transactionsFilter: null,
    transactionsCancelToken: null,

    // Used for single transaction (transaction detail)
    transactionInfo: null,
    transactionLoading: false,

    // Used for transaction graph
    transactionNetwork: undefined,
    transactionNetworkLoading: false,

    // Used for websocket live transactions
    websockets: {},
    liveTransactions: [],
    nonCrypto: false,
    nextKey: 0,
    transactionCount: 10,
    enabledWebsockets: [],
    pausedWebsockets: [],
    websocketsPausedByMouseover: new Set(),

    // Country data
    transactionsCountryLoading: false,
    transactionsCountryData: null,
    transactionsCountryCancelToken: null,

    // Passing data on AddressTransactionsView
    tx_filter: null,
    tx_pagination: null,
}

const getters = {
    transactionsFilter: (state) => state.transactionsFilter,
    nonCryptoPrices: (state) => state.nonCrypto,
    liveTransactions: (state) => state.liveTransactions,
    websockets: (state) => state.websockets,
    nextKey: (state) => ++state.nextKey % (state.transactionCount + 1),
    enabledWebsockets: (state) => state.enabledWebsockets,
    transactions: (state) => state.transactions,
    transactionsCountryData: (state) => state.transactionsCountryData,
    transactionsCountryLoading: (state) => state.transactionsCountryLoading,
    transactionsPagination: (state) => state.transactionsPagination,
    transactionsLoading: (state) => state.transactionsLoading,
    transactionInfo: (state) => state.transactionInfo,
    transactionLoading: (state) => state.transactionLoading,
    transactionNetwork: (state) => state.transactionNetwork,
    transactionNetworkLoading: (state) => state.transactionNetworkLoading,
    tx_filter: (state) => state.tx_filter,
    tx_pagination: (state) => state.tx_pagination,
}

const mutations = {
    TRANSACTIONS_COUNTRY_LOADED(state, data) {
        state.transactionsCountryData = {
            ...data,
            data: data.data.sort((a, b) => b.count - a.count),
            highChartData: data.data.map(x => ({
                name: x.country_code,
                y: x.count, ...getCountry(x.country_code, "alpha-2")
            }))
        }
        state.transactionsCountryLoading = false
    },
    TRANSACTIONS_COUNTRY_LOADING_FINISHED(state) {
        state.transactionsCountryLoading = false
        state.transactionsCountryCancelToken = null
    },
    TRANSACTIONS_COUNTRY_START_LOADING(state) {
        if (state.transactionsCountryCancelToken) {
            state.transactionsCountryCancelToken.abort()
        }
        state.transactionsCountryCancelToken = new AbortController()
        state.transactionsCountryLoading = true
    },
    TRANSACTIONS_LOADED(state, {response: data, entityTypeParam}) {
        let transactions = data['data']

        let currentAddress = this.getters.addressId
        for (let tx of transactions) {
            tx = processTx(tx, entityTypeParam === EntityType.Address ? currentAddress : undefined)
        }

        state.transactions = transactions
        state.transactionsPagination = data
        state.transactionsLoading = false
    },
    TRANSACTIONS_LOADING_FINISHED(state) {
        state.transactionsLoading = false
        state.transactionsCancelToken = null
    },
    TRANSACTIONS_INIT(state) {
        state.transactions = []
        state.transactionsCountryData = null
        state.transactionsLoading = false
        state.transactionsPagination = null
        state.tx_pagination = null
    },
    TRANSACTIONS_START_LOADING(state) {
        if (state.transactionsCancelToken) {
            state.transactionsCancelToken.cancel()
        }
        state.transactionsCancelToken = axios.CancelToken.source()
        state.transactionsLoading = true
    },
    TRANSACTIONS_CANCEL_AND_CLEAR(state) {
        if (state.transactionsCancelToken) {
            state.transactionsCancelToken.cancel()
        }
        if (state.transactionsCountryCancelToken) {
            state.transactionsCountryCancelToken.abort()
        }

        state.transactionsCountryCancelToken = null
        state.transactionsCountryData = null
        state.transactionsCountryLoading = false
        state.transactions = []
        state.transactionsLoading = false
        state.tx_pagination = null
        state.transactionsPagination = null
    },
    TRANSACTION_LOADED(state, data) {
        state.transactionInfo = processTx(data, undefined)
        state.transactionLoading = false
    },
    TRANSACTION_INIT(state) {
        state.transactionInfo = null
        state.transactionLoading = true
    },
    TRANSACTION_LOADING_FINISHED(state) {
        state.transactionLoading = false
    },
    TRANSACTION_NETWORK_INIT(state) {
        state.transactionNetwork = undefined;
    },
    START_TRANSACTION_NETWORK_PAGE_LOADING(state) {
        state.transactionNetworkLoading = true
    },
    FINISH_TRANSACTION_NETWORK_PAGE_LOADING(state) {
        state.transactionNetworkLoading = false
    },
    TRANSACTION_NETWORK_LOADED(state, {data}) {
        //todo FE/vlado data from server contains redundant data
        state.transactionNetwork = {
            edges: removeDuplicatesFromArray(data.edges, i => i.data.id),
            nodes: removeDuplicatesFromArray(data.nodes, i => i.data.id),
        }
    },
    //todo same as TRANSACTION_NETWORK_INIT , chceck if is TRANSACTION_NETWORK_INIT required
    TRANSACTION_NETWORK_CLEAN_UP(state) {
        state.transactionNetwork = undefined
    },
    TRANSACTION_NETWORK_ERROR(state, error) {
        this.dispatch('ERROR', error.message)
        state.transactionNetworkLoading = false
    },
    APPEND_TRANSACTION_GRAPH_DATA_TO_ACTUAL_TRANSACTION_NETWORK(state, {data}) {
        const allEdges = state.transactionNetwork.edges.concat(data.edges);
        const allNodes = state.transactionNetwork.nodes.concat(data.nodes);
        state.transactionNetwork.edges = removeDuplicatesFromArray(allEdges, i => i.data.id)
        state.transactionNetwork.nodes = removeDuplicatesFromArray(allNodes, i => i.data.id)
    },
    TRANSACTIONS_SAVE_FILTER(state, filter) {
        state.transactionsFilter = filter
    },
    TRANSACTIONS_REMOVE_FILTER(state, filter) {
        state.transactionsFilter = null
    },
    NEW_LIVE_TRANSACTION(state, socketData) {
        if (socketData && socketData.data.txid) {
            socketData.key = getters.nextKey(state)
            state.nextKey = socketData.key

            state.liveTransactions.unshift(socketData)
        }
        if (socketData) {
            if (state.liveTransactions.length > state.transactionCount) {
                state.liveTransactions.pop()
            }
        }
    },
    CREATE_STATE_FOR_NEW_WEBSOCKET(state, {socket, currency}) {
        Vue.set(state.websockets, currency.unit, {socket: socket, currency: currency})
    },
    WEBSOCKET_ENABLE(state, currency) {
        state.enabledWebsockets.push(currency)
        state.pausedWebsockets = state.pausedWebsockets.filter(wsUnit => wsUnit !== currency)
    },
    WEBSOCKET_DISABLE(state, currency) {
        state.enabledWebsockets = state.enabledWebsockets.filter(wsUnit => wsUnit !== currency)
        state.pausedWebsockets.push(currency)
    },
    WEBSOCKET_PAUSE(state, currencyUnit) {
        state.websocketsPausedByMouseover.add(currencyUnit)
        this.commit("WEBSOCKET_DISABLE", currencyUnit)
    },
    WEBSOCKET_RESTART(state, currencyUnit) {
        state.websocketsPausedByMouseover.delete(currencyUnit)
        this.commit("WEBSOCKET_ENABLE", currencyUnit)
    },
    CLEAR_LIVE_TRANSACTIONS_ARRAY(state) {
        state.liveTransactions = []
    },
    SHOW_NON_CRYPTO_PRICES(state, val) {
        state.nonCrypto = val
    },

    TX_SET_PAGINATION(state, val) {
        state.transactionsPagination = {last_page: val}
    },

    //data passing
    TX_FILTER_SET(state, filter) {
        state.tx_filter = filter
    },
    TX_PAGINATION(state, pagination) {
        state.tx_pagination = pagination
    }
}

const actions = {
    async startWebsocket({commit, dispatch}, currency) {
        if (state.websockets[currency.unit]) {
            commit("WEBSOCKET_ENABLE", currency.unit)
            return
        }
        const protocol = getWebSocketProtocol()
        const socket = new WebSocket(`${protocol}${currency.ws}`)
        socket.onopen = () => {
            socket.send(JSON.stringify({
                "id": currency.unit,
                "method": "subscribeNewTransaction",
            }))
            commit("WEBSOCKET_ENABLE", currency.unit)
            commit("CREATE_STATE_FOR_NEW_WEBSOCKET", {socket, currency})
        }

        socket.onmessage = (event) => {
            let socketData = JSON.parse(event.data)
            socketData.currency = currency
            if (state.enabledWebsockets.includes(currency.unit)) {
                commit('NEW_LIVE_TRANSACTION', socketData)
            }
        }
    },
    toggleWebsocket({commit}, currency) {
        if (!state.enabledWebsockets.includes(currency.unit)) {
            commit("WEBSOCKET_ENABLE", currency.unit)
            return
        }
        if (state.enabledWebsockets.includes(currency.unit)) {
            commit("WEBSOCKET_DISABLE", currency.unit)
        }
    },
    toggleAllWebsockets({commit}) {
        if (state.enabledWebsockets.length === 0) {
            Object.keys(state.websockets).forEach(key => {
                commit("WEBSOCKET_ENABLE", key)
            })
            return
        }
        if (state.websockets) {
            Object.keys(state.websockets).forEach(key => {
                commit("WEBSOCKET_DISABLE", key)
            })
        }
    },
    pauseWebsockets({commit}) {
        state.enabledWebsockets.forEach(unit => {
            commit("WEBSOCKET_PAUSE", unit)
        })
    },
    restartWebsockets({commit}) {
        state.websocketsPausedByMouseover.forEach(unit => {
            commit("WEBSOCKET_RESTART", unit)
        })
    },
    initTransactions({commit}) {
        commit('TRANSACTIONS_INIT')
    },

    async loadTransactionsByEntity({commit, dispatch, state}, {entityId, currency, pagination, filter, entityTypeParam}:
        { entityId: string, currency: string, pagination: object, filter: object, entityTypeParam: EntityType }) {
        commit('TRANSACTIONS_START_LOADING')
        try {
            //workaround in case user sends one request twice
            //- waiting for first request to finish, otherwise the canceled "response"
            //gets cached and returned for the second request
            await new Promise(r => setTimeout(r, 0));

            const response = await apiClient.GET(`${currency}/crypto${entityTypeParam.toLowerCase()}/${entityId}/transactions`, {
                params: {
                    ...pagination,
                    ...filter
                },
                cancelToken: state.transactionsCancelToken.token
            })

            commit('TRANSACTIONS_LOADED', {response, entityTypeParam})
            commit("TRANSACTIONS_LOADING_FINISHED")
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                console.error(error)
                dispatch("error", error.userFriendlyMessage)
                commit("TRANSACTIONS_LOADING_FINISHED")
            }
        }
    },

    clearTransactionsState({commit}) {
        commit('TRANSACTIONS_INIT')
    },

    async loadTransactions({commit, dispatch, state}, {currency, filter, pagination}) {
        filter = MapToServerModel(filter)
        commit('TRANSACTIONS_START_LOADING')
        try {
            const response = await apiClient.POST(`${currency}/cryptotransactions`,
                {
                    ...filter,
                },
                {
                    params: {
                        page: pagination.page,
                        itemsPerPage: pagination.itemsPerPage
                    },
                    cancelToken: state.transactionsCancelToken.token
                })
            commit('TRANSACTIONS_LOADED', {response})
            commit('TRANSACTIONS_LOADING_FINISHED')
        } catch (error) {
            if (axios.isCancel(error)) {
                //do nothing, request was canceled
            } else {
                console.error(error)
                dispatch("error", error.userFriendlyMessage)
                commit("TRANSACTIONS_LOADING_FINISHED")
            }
        }
    },


    async loadTransactionsGeo({commit}, {entityId, currency, filter, entityTypeParam}:
        { entityId: string, currency: string, filter: object, entityTypeParam: EntityType }) {
        commit('TRANSACTIONS_COUNTRY_START_LOADING')
        try {
            //workaround in case user sends one request twice
            //- waiting for first request to finish, otherwise the canceled "response"
            //gets cached and returned for the second request
            await new Promise(r => setTimeout(r, 0));
            const response = await apiClient.GET(`${currency}/crypto${entityTypeParam.toLowerCase()}/${entityId}/transactions/country`, {
                params: {
                    ...filter,
                },
                signal: state.transactionsCountryCancelToken.signal
            })
            commit('TRANSACTIONS_COUNTRY_LOADED', response)
            commit("TRANSACTIONS_COUNTRY_LOADING_FINISHED")
        } catch (error) {
            if (axios.isCancel(error)) {
                //do nothing, request was canceled
            } else {
                this.dispatch("error", error.userFriendlyMessage)
                commit("TRANSACTIONS_COUNTRY_LOADING_FINISHED")
            }
        }

    },

    async loadTransaction({commit}, {id, lightningNetworkEnrichmentEnabled}) {
        if (router.currentRoute.params.currency) {
            commit('TRANSACTION_INIT')
            const requestAddress = router.currentRoute.params.currency + '/cryptotransaction/' + id;
            try {
                const response = await apiClient.GET(requestAddress);

                if (lightningNetworkEnrichmentEnabled) {
                    //check whether any output is used to open a lightning network channel
                    for (const [i, output] of response.outputs.entries()) {
                        const lightningResponse = await apiClient.GET(`LN/database/channel_from_txout`, {
                            params: {channel_point: `${id}:${i}`}
                        })
                        if (lightningResponse.length > 0) {
                            output.is_lightning_network_channel_opener = true
                        }
                    }
                }
                commit('TRANSACTION_LOADED', response)
            } catch (error) {
                this.dispatch("error", error.userFriendlyMessage)
            }
            commit("TRANSACTION_LOADING_FINISHED")
        }
    },

    async loadTransactionNetwork({commit, dispatch}, {transactionId, currency, sides}) {
        commit('TRANSACTION_NETWORK_INIT')
        commit('START_TRANSACTION_NETWORK_PAGE_LOADING')
        const requestUrl = buildStringTemplate({
            template: "${currency}/cryptotransaction/${transactionId}/graph",
            values: {
                currency: currency,
                transactionId: transactionId
            }
        })
        try {
            const result = await apiClient.GET(requestUrl, {params: {sides}})
            commit('TRANSACTION_NETWORK_LOADED', {data: result.graph_data})
            commit('FINISH_TRANSACTION_NETWORK_PAGE_LOADING')
        } catch (error) {
            dispatch('error', error.message)
        }
    },

    async loadFundsNetwork({commit, dispatch}, {
        source,
        destination,
        maxHops,
        currency,
        paths,
        asAddress,
        limit,
        directions,
        includeClusters
    }) {
        commit('START_TRANSACTION_NETWORK_PAGE_LOADING')

        try {
            const result = await apiClient.GET(`${currency}/funds/paths/source/${source}`
                + `/destination/${destination}`,
                {params: {maxHops, paths, asAddress, limit, directions, includeClusters}})
            commit('TRANSACTION_NETWORK_LOADED', {data: result})
            commit('FINISH_TRANSACTION_NETWORK_PAGE_LOADING')
        } catch (error) {
            dispatch('error', error.message)
            commit('FINISH_TRANSACTION_NETWORK_PAGE_LOADING')
        }
    },

    async loadFundsNetworkWithTxs({commit, dispatch}, {source, destination, currency, txs}) {
        commit('START_TRANSACTION_NETWORK_PAGE_LOADING')
        try {
            const result = await apiClient.GET(`${currency}/graphs/funds/paths/source/${source}`
                + `/destination/${destination}`,
                {params: {txs}})
            commit('TRANSACTION_NETWORK_LOADED', {data: result})
            commit('FINISH_TRANSACTION_NETWORK_PAGE_LOADING')
        } catch (error) {
            dispatch('error', error.message)
            commit('FINISH_TRANSACTION_NETWORK_PAGE_LOADING')
        }
    },

    socketStartListening({commit}, url) {
        commit('SOCKET_START_LISTENING', url)
    },

    socketStopListening({commit}) {
        commit('SOCKET_STOP_LISTENING')
    },

    showNonCryptoPrices({commit}, val) {
        commit('SHOW_NON_CRYPTO_PRICES', val)
    },

    async appendTransactionNetworkToExistingCanvas({commit, dispatch}, {transactionId, currency, sides}) {
        commit('START_TRANSACTION_NETWORK_PAGE_LOADING')
        const requestUrl = buildStringTemplate({
            template: "${currency}/cryptotransaction/${transactionId}/graph",
            values: {
                currency: currency,
                transactionId: transactionId
            }
        })
        try {
            const result = await apiClient.GET(requestUrl, {params: {sides}})
            commit('APPEND_TRANSACTION_GRAPH_DATA_TO_ACTUAL_TRANSACTION_NETWORK', {data: result.graph_data})
            commit('FINISH_TRANSACTION_NETWORK_PAGE_LOADING')
        } catch (error) {
            dispatch('error', error.message)
        }
    },
    cleanUpTransactionNetwork({commit}) {
        commit("TRANSACTION_NETWORK_CLEAN_UP")
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}

export const loadTransactionSides = {
    LEFT: 'left',
    RIGHT: 'right',
    BOTH: 'both'
}