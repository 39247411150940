<script setup>
import NameValueFlex from "@/components/common/NameValueFlex.vue";
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import BaseButtonShowMoreItems from "@/components/common/TableComponents/BaseButtonShowMoreItems.vue";

const props = defineProps({
    user: {
        type: Object,
        required: true,
    }
})

</script>

<template>
    <FancyCard>
        <template #header>
            <div class="d-flex align-center">
                <v-icon>person</v-icon>
                <span class="font-weight-bold ml-1">User Info</span>
            </div>
        </template>
        <template #body>
            <NameValueFlex>
                <template #name>
                    <div class="grey--text">Name:</div>
                </template>
                <template #value>
                    <div class="fontMonospace" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{user.name}}</div>
                </template>
            </nameValueFlex>
            <NameValueFlex>
                <template #name>
                    <div class=grey--text>E-mail:</div>
                </template>
                <template #value>
                    <span class="font-italic">{{ user.email }}</span>
                </template>
            </nameValueFlex>
            <NameValueFlex>
                <template #name>
                    <div class="grey--text">Roles:</div>
                </template>
                <template #value>
                    <div v-if="user.roles?.length > 0" class="d-flex">
                        <v-chip class="font-weight-bold" small v-for="role in user.roles.slice(0, 3)" :key="role.name">
                            {{ role.name }}
                        </v-chip>
                        <BaseButtonShowMoreItems :reserve-space="false" :shown-items-count="3" :items="user.roles">
                            <v-chip class="font-weight-bold mx-1" small v-for="role in user.roles" :key="role.name">
                                {{ role.name }}
                            </v-chip>
                        </BaseButtonShowMoreItems>
                    </div>
                    <div v-else>
                        <span class="font-weight-bold">
                            None
                        </span>
                    </div>
                </template>
            </nameValueFlex>
        </template>
    </FancyCard>
</template>

<style scoped>

</style>