var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-y height100" },
    [
      _c(
        "v-container",
        { staticClass: "height100 px-4", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "" } },
            [
              _c("h1", [_vm._v("Cases")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("CaseDetailModal", {
                attrs: {
                  categories: _vm.categories,
                  currencies: _vm.enabledCurrencies,
                },
                on: { "save-event": _vm.handleCaseSave },
                scopedSlots: _vm._u([
                  {
                    key: "showbutton",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "CreateButton",
                          _vm._g({ attrs: { text: "Create case" } }, on)
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-0 border mt-2",
                    attrs: {
                      "footer-props": {
                        "items-per-page-options": [15, 30, 60, -1],
                        "show-current-page": true,
                        showFirstLastPage: true,
                      },
                      headers: _vm.headers,
                      items: _vm.cases.data,
                      loading: _vm.casesLoading,
                      options: _vm.filter,
                      "server-items-length": _vm.cases.total
                        ? _vm.cases.total
                        : 0,
                      dense: "",
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.filter = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.headers, function (h) {
                          return {
                            key: `header.${h.value}`,
                            fn: function ({ header }) {
                              return [
                                h.value === "title"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      staticStyle: { "min-width": "100px" },
                                      attrs: {
                                        label: header.text,
                                        "append-icon": "search",
                                        clearable: "",
                                        "hide-details": "",
                                        "single-line": "",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.debouncedFilter.filterTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterTitle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterTitle",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "files_count"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      attrs: {
                                        label: header.text,
                                        min: 0,
                                        clearable: "",
                                        "hide-details": "",
                                        "hide-spin-buttons": "",
                                        "single-line": "",
                                        type: "number",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.debouncedFilter.filterFiles,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterFiles",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterFiles",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "addresses_count"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      staticStyle: { "min-width": "50px" },
                                      attrs: {
                                        label: header.text,
                                        min: 0,
                                        clearable: "",
                                        "hide-details": "",
                                        "hide-spin-buttons": "",
                                        "single-line": "",
                                        type: "number",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.debouncedFilter.filterAddresses,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterAddresses",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterAddresses",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "is_hidden"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "is_warning"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "reported_at"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "created_at"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "created_by"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: {
                                          "padding-top": "20px",
                                          "padding-bottom": "20px",
                                        },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "updated_at"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "updated_by"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: {
                                          "padding-top": "20px",
                                          "padding-bottom": "20px",
                                        },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "action"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: {
                                          "padding-top": "20px",
                                          "padding-bottom": "20px",
                                        },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          }
                        }),
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-left text-truncate",
                                    staticStyle: { "max-width": "0" },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link",
                                        staticStyle: { "min-width": "100px" },
                                        attrs: { to: `/cases/${item.id}` },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.title) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: {
                                      "text-align": "center",
                                      "vertical-align": "middle",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("formatNumber")(
                                            item.files_count
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: {
                                      "text-align": "center",
                                      "vertical-align": "middle",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("formatNumber")(
                                            item.addresses_count
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "vertical-align": "middle",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: item.is_hidden
                                                            ? "primary"
                                                            : "tertiary",
                                                          dark: "",
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.is_hidden
                                                            ? "visibility_off"
                                                            : "visibility"
                                                        ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "Case is\n                                        " +
                                              _vm._s(
                                                item.is_hidden
                                                  ? "hidden"
                                                  : "visible"
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "vertical-align": "middle",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: item.is_warning
                                                            ? "warning"
                                                            : "primary",
                                                          dark: "",
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.is_warning
                                                            ? "report"
                                                            : "report_off"
                                                        ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "Case\n                                        " +
                                              _vm._s(
                                                item.is_warning
                                                  ? "warns"
                                                  : "does not warn"
                                              ) +
                                              "\n                                        creator"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-no-wrap" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          item.reported_at,
                                          "YYYY-MM-DD HH:mm:ss Z"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-no-wrap" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          item.created_at,
                                          "YYYY-MM-DD HH:mm:ss Z"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.creator.name) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-no-wrap" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          item.updated_at,
                                          "YYYY-MM-DD HH:mm:ss Z"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.updator.name) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("CaseDetailModal", {
                                        key: item.id,
                                        attrs: {
                                          caseDetail: _vm.caseDetail,
                                          categories: _vm.categories,
                                          currencies: _vm.enabledCurrencies,
                                          loading: _vm.isCaseLoading,
                                        },
                                        on: {
                                          "save-event": _vm.handleCaseSave,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "showbutton",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "TableEditButton",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          editButtonTooltip:
                                                            "Edit case",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.loadCaseDetail(
                                                              {
                                                                caseId: item.id,
                                                              }
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("TableDeleteModalDialog", {
                                        attrs: {
                                          description: `Do you really want to delete case ${item.title}`,
                                          itemToDelete: item,
                                          tooltip: "Delete case",
                                        },
                                        on: { "delete-event": _vm.deleteCase },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "footer.page-text",
                          fn: function ({ pageStart, pageStop, itemsLength }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm._f("formatNumber")(pageStart)) +
                                    " - " +
                                    _vm._s(_vm._f("formatNumber")(pageStop)) +
                                    " of " +
                                    _vm._s(
                                      _vm._f("formatNumber")(itemsLength)
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }