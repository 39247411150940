<script setup>
import store from "@/store";
import {computed, ref} from "vue";
import {safeRouterPush} from "@/utils";
import {useRoute, useRouter} from "vue-router/composables";
import ProgressCircular from "@/components/common/ProgressCircular.vue";
import NodeInvolvement from "@/components/peers/NodeInvolvement.vue";
import NameValueFlex from "@/components/common/NameValueFlex.vue";
import BaseModalDialog from "@/components/common/Base/BaseModalDialog.vue";
import MapContainer from "../common/maps/MapContainer"
import NodeDataTabs from "@/components/peers/NodeDataTabs.vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {DataState} from "@/components/common/types/data";


const props = defineProps({
    peerId: {
        type: Number
    },
    peerDetail: {},
    loading: {
        type: Boolean,
        required: true,
    }
})

const currency = computed(() => store.getters.currency)

const isOnionAddress = computed(() => props.peerDetail?.node.network_type === 'onion')

const baseModal = ref()
const open = () => {
    baseModal.value.open()
}

defineExpose({
    open
})

const route = useRoute()
const router = useRouter()

const emit = defineEmits(['destroy-modal-state-event'])

const clearQueryId = () => {
    const queryClearOfId = {...route.query, id: undefined}
    safeRouterPush(router, {...route, query: queryClearOfId})
    emit('destroy-modal-state-event')
}

const {
    data: nodeLightningNetworkIdData,
    dataState: nodeLightningNetworkIdState,
    getData: getNodeLightningNetworkId
} = useGetData('getNodeLightningNetworkID', {}, false)

const nodeLightningNetworkIDLoading = computed(() => nodeLightningNetworkIdState.value === DataState.Loading)
const nodeLightningNetworkId = computed(() => nodeLightningNetworkIdData.value?.length > 0 ? nodeLightningNetworkIdData.value : null)

const {
    data: lightningNetworkChannels,
    dataState: lightningNetworkChannelsState,
    getData: getLightningNetworkChannels
} = useGetData('getNodeLightningNetworkChannels', {}, false)

const lightningNetworkChannelsLoading = computed(() => lightningNetworkChannelsState.value === DataState.Loading)

const getNodeLightningNetworkData = async () => {
    if(currency.value.unit === 'BTC') {
        await getNodeLightningNetworkId({ address: props.peerDetail.node.host })
    }

    //fetch lightning network channels only if the node was a part of the lightning network
    if(nodeLightningNetworkId.value) {
        await getLightningNetworkChannels({ identityKey: nodeLightningNetworkId.value })
    }
}

const {
    data: nodeTorDataResponse,
    dataState: nodeTorDataState,
    getData: getTorData
} = useGetData('getNodeTorData', {}, false)

const nodeTorDataLoading = computed(() => nodeTorDataState.value === DataState.Loading)
const nodeTorData = computed(() => nodeTorDataResponse.value?.result.length > 0 ? nodeTorDataResponse.value.result : null)

const getNodeTorData = () => {
    getTorData({ address: props.peerDetail.node.host })
}

const lightningNetworkEnrichmentEnabled = store.getters.settings.server.lnQueryEnabled
const torNetworkEnrichmentEnabled = store.getters.settings.server.torQueryEnabled

const onModalLoad = () => {
    if (lightningNetworkEnrichmentEnabled) {
        getNodeLightningNetworkData()
    }

    if (torNetworkEnrichmentEnabled) {
        getNodeTorData()
    }
}

const headers = [
    {text: 'Start of activity', align: 'center', value: 'activity_start'},
    {text: 'End of activity', align: 'center', value: 'activity_end'},
    {text: 'User Agent', align: 'center', value: 'user_agent'},
    {text: 'Protocol Version', align: 'center', value: 'protocol_version'},
    {text: 'Services', align: 'center', value: 'services'},
]

const nodeInfoRows = computed(() => {
    if(props.peerDetail) {
        return [
            { name: 'City', value: props.peerDetail?.node.city },
            { name: 'Country code', value: props.peerDetail?.node.country_code },
            { name: 'Autonomous System Number', value: props.peerDetail?.node.asn, nameWidth: '200' },
            { name: 'Organization', value: props.peerDetail?.node.organization },
            { name: 'Last Known Latitude', value: props.peerDetail?.node.latitude, nameWidth: '200' },
            { name: 'Last Known Longitude', value: props.peerDetail?.node.longitude, nameWidth: '200' },
        ]
    }
    return []
})


</script>

<template>
    <BaseModalDialog
        ref="baseModal"
        :loading="loading"
        :title="'Node Info'"
        width="80%"
        height="85vh"
        @destroy-modal-state-event="clearQueryId"
        @load-modal-state-event="onModalLoad"
    >
        <template #showbutton="{ on }">
            <slot :on="on" name="showbutton"/>
        </template>
        <template #content>
            <v-card outlined>
                <v-card-title class="justify-center">
                    <v-col cols="1" class="py-0 d-flex">
                        <v-tooltip v-if="nodeTorData" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <img v-on="on" src="/img/ico/tor-onion.svg" alt="Tor" style="width: 36px; margin-bottom: 6px;" />
                            </template>
                            This node was active in the Tor network.
                        </v-tooltip>
                        <v-tooltip v-if="nodeLightningNetworkId" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon large color="#ffc001" v-on="on">
                                    mdi-flash
                                </v-icon>
                            </template>
                            This node propagated Lightning network transactions.
                        </v-tooltip>
                    </v-col>
                    <v-col cols="11" class="py-0">
                        <h2 class="text--primary d-flex justify-center" style="gap: 8px">
                            <div style="font-weight: 300">
                                Address:
                            </div>
                            {{ peerDetail.node.host }}
                        </h2>
                    </v-col>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-row>
                        <v-col :cols="3">
                            <NameValueFlex
                                v-for="item in nodeInfoRows"
                                :key="item.name"
                                :loading="loading"
                                loading-width="150"
                                :name-width="item.nameWidth ?? undefined"
                            >
                                <template #name>
                                    {{ item.name }}:
                                </template>
                                <template #value>
                                    <span
                                        v-if="item.value"
                                        class="fontMonospace"
                                    >
                                        {{ item.value }}
                                    </span>
                                    <span v-else class="font-italic grey--text">
                                        N/A
                                    </span>
                                </template>
                            </NameValueFlex>
                            <NodeInvolvement :hasData="!!nodeLightningNetworkId" subject="Lightning Network" :loading="nodeLightningNetworkIDLoading"/>
                            <NodeInvolvement :hasData="!!nodeTorData" subject="Tor Network" :loading="nodeTorDataLoading"/>
                        </v-col>


                        <v-col :cols="9" class="d-flex justify-center align-center">
                            <div v-if="loading">
                                <ProgressCircular :color="currency" class="pt-5"/>
                            </div>
                            <div v-else style="width: 100%">
                                <v-row class="justify-center">
                                    <MapContainer
                                        v-if="!isOnionAddress"
                                        :peerDetail="peerDetail"
                                    />
                                    <div v-else class="title font-weight-light text-center">
                                        Not available for this address type.
                                    </div>
                                </v-row>
                            </div>

                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <NodeDataTabs
                :node-lightning-network-id="nodeLightningNetworkId"
                :node-lightning-network-channels="lightningNetworkChannels"
                :node-lightning-network-channels-loading="lightningNetworkChannelsLoading"
                :node-tor-data="nodeTorData"
                :node-tor-data-loading="nodeTorDataLoading"
                :peer-id="peerId"
                :peer-detail="peerDetail"
            />
        </template>
        <template #footer="{close}">
            <v-btn color="error" text @click="close">Close</v-btn>
        </template>
    </BaseModalDialog>
</template>

<style scoped>
td {
    text-align: center;
    vertical-align: middle;
}
</style>