var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("highcharts", {
    ref: "chart",
    staticClass: "hc",
    attrs: { constructorType: "mapChart", options: _vm.chartOptions },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }