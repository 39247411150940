var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        width: _vm.width,
        "max-width": _vm.maxWidth,
        persistent: _vm.persistent,
        scrollable: "",
        "content-class": "elevation-0 mt-16 dialog-content min-width-500",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on }) {
              return [_vm._t("showbutton", null, { on: on })]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _vm._v(" "),
      _vm.showModal && !_vm.loading
        ? _c(
            "v-card",
            { attrs: { height: _vm.height } },
            [
              _c("v-card-title", { staticClass: "grey lighten-2" }, [
                _c("div", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "mt-3" },
                [
                  _c(
                    "v-flex",
                    { staticClass: "mt-1", class: { disabled: _vm.disabled } },
                    [_vm._t("content")],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "justify-space-around" },
                [
                  _vm._t("footer", null, {
                    close: _vm.close,
                    setDisabled: _vm.setDisabled,
                    disabled: _vm.disabled,
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "v-card",
            { attrs: { width: _vm.loadingWidth } },
            [
              _c("v-card-title", { staticClass: "grey lighten-2" }, [
                _c("h3", [_vm._v(_vm._s(_vm.title))]),
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pt-2" },
                [
                  _vm._v("\n            Please stand by\n            "),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "primary" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }