var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.GenericPickerWithDisplay, {
    attrs: {
      value: _setup.valueRef,
      "get-items": _setup.getItems,
      items: _setup.items,
      loading: _setup.loading,
      "item-value": "id",
      item_id: "id",
      chips: "",
      multiple: "",
      outlined: "",
      "hide-details": "",
      "no-prepend-icon": "",
      menuTop: "",
      "clear-only-search-string": "",
      label: "Search owner...",
    },
    on: { input: _setup.handleChange },
    scopedSlots: _vm._u([
      {
        key: "pickerItem",
        fn: function ({ item }) {
          return [
            _c("img", {
              staticClass: "imgIcon",
              staticStyle: {
                width: "20px",
                filter: "opacity(0.6)",
                "margin-right": "8px",
              },
              attrs: { alt: "owner icon", src: "/img/ico/owner-icon.svg" },
            }),
            _vm._v(" "),
            _c(
              "v-list-item-content",
              [
                _c("v-list-item-title", {
                  domProps: { textContent: _vm._s(item.name) },
                }),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "chipItem",
        fn: function (item) {
          return [_vm._v("\n        " + _vm._s(item.item.name) + "\n    ")]
        },
      },
      {
        key: "append",
        fn: function () {
          return [
            _vm.value?.length > 0
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      opacity: "0.8",
                      "font-size": "0.9em",
                      "margin-top": "6px",
                    },
                  },
                  [
                    _c("nobr", [
                      _vm._v(
                        "\n                Selected: " +
                          _vm._s(_setup.valueRef.length) +
                          "\n            "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }