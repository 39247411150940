var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex align-center ml-n2",
      staticStyle: { width: "100%" },
    },
    [
      _c(
        "v-list-item-content",
        { staticStyle: { padding: "4px 0 !important" } },
        [
          _c("v-list-item-title", { staticClass: "text-truncate" }, [
            _vm._v("\n            " + _vm._s(_vm.item.title) + "\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "font-size": "0.8em", opacity: "0.8" } },
            [
              _vm._v(
                "\n            Case\n            |\n            " +
                  _vm._s(_vm.item.creator.name) +
                  "\n            |\n            "
              ),
              _c("v-icon", { attrs: { "x-small": "" } }, [
                _vm._v("\n                mdi-file-multiple\n            "),
              ]),
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("numberFormatCompact")(_vm.item.files_count)) +
                  "\n            |\n            "
              ),
              _c("v-icon", { attrs: { "x-small": "" } }, [
                _vm._v(
                  "\n                mdi-chart-timeline-variant\n            "
                ),
              ]),
              _vm._v(
                "\n            " + _vm._s(_vm.item.graphs_count) + "\n        "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }