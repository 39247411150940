var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex align-center mr-5",
      class: `justify-${_vm.justify}`,
      staticStyle: { width: "100%", "min-width": "200px" },
    },
    [
      _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c("nobr", [
            _c(
              "div",
              {
                staticClass: "font-weight-medium",
                staticStyle: { "font-size": "14px" },
              },
              [
                _vm._v(
                  "\n                " + _vm._s(_vm.label) + "\n            "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fontMonospace pl-2 d-flex align-center overflow-hidden",
          staticStyle: {
            "background-color": "#EEEEEE",
            "border-radius": "6px",
          },
        },
        [
          _c("div", { staticClass: "text-truncate" }, [
            _vm._v("\n            " + _vm._s(_vm.entity) + "\n        "),
          ]),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "ml-1",
              staticStyle: {
                "min-width": "30px",
                padding: "0",
                height: "30px",
              },
              attrs: { color: "rgba(0, 0, 0, 0.7)", small: "", text: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return (() => _setup.copy(_vm.entity)).apply(null, arguments)
                },
              },
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("content_copy")])],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }