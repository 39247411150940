var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("v-content", [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "text" },
            [
              _c("p", { staticClass: "error-heading fontMonospace" }, [
                _vm._v("404"),
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("p", { staticClass: "page-not-found" }, [
                _vm._v(
                  "\n                        Page Not Found\n                    "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cog-on-fire" }, [
            _c("img", {
              staticClass: "filter-grey gear-img",
              attrs: { src: "/img/gear-on-fire.svg" },
            }),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }