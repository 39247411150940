import { GET, POST, DELETE, PUT } from "@/services/apiClient"
import axios from "axios";

import {modalState} from "@/components/common/types/modal";

const state = {
    identities: {},
    isIdentitiesLoading: true,
    isFilteredAddressesLoading: false,
    filteredAddresses: [],
    identitiesCancelationToken: axios.CancelToken.source(),
    filteredAddressesCancelToken: axios.CancelToken.source(),
    identitiesModalState: modalState.Initial,
}

state.identitiesCancelationToken.cancel()
const getters = {
    identities: (state) => state.identities,
    isIdentitiesLoading: (state) => state.isIdentitiesLoading,
    filteredAddresses: (state) => state.filteredAddresses,
    isFilteredAddressesLoading: (state) => state.isFilteredAddressesLoading,
    identitiesModalState: (state) => state.identitiesModalState,
}
const mutations = {
    IDENTITIES_START_LOADING(state) {
        state.identitiesCancelationToken.cancel()
        state.identitiesCancelationToken = axios.CancelToken.source()
        state.isIdentitiesLoading = true
    },
    IDENTITIES_PAGE_LOADED(state, identities) {
        state.identities = identities
    },
    IDENTITIES_FINISH_LOADING(state) {
        state.isIdentitiesLoading = false
    },
    ADDRESSES_START_LOADING(state) {
        state.filteredAddressesCancelToken.cancel()
        state.filteredAddressesCancelToken = axios.CancelToken.source()
        state.isFilteredAddressesLoading = true
    },
    ADDRESSES_SUGGESTIONS_LOADED(state, { addresses }) {
        state.filteredAddresses = addresses.data
    },
    ADDRESSES_FINISH_LOADING(state) {
        state.isFilteredAddressesLoading = false
    },
    IDENTITIES_MODAL_SET_STATE(state,data) {
        state.identitiesModalState = data
    }
}

const actions = {
    async loadIdentities({ commit }, { currency, pagination }) {
        commit("IDENTITIES_START_LOADING")
        try{
            const result = await GET(`${currency}/identities`, {
                headers: { cache: false },
                params: { ...pagination },
                cancelToken: state.identitiesCancelationToken.token
            })
            commit("IDENTITIES_PAGE_LOADED", result)
            commit("IDENTITIES_FINISH_LOADING")
        } catch(error) {
            if(axios.isCancel(error)) {
                //request canceled, do nothing
            } else {
                this.dispatch('error', error.userFriendlyMessage)
                commit("IDENTITIES_FINISH_LOADING")
            }
        }
    },
    async createIdentity({ commit }, { currency, identityDetail }) {
        commit('IDENTITIES_START_LOADING')
        commit('IDENTITIES_MODAL_SET_STATE',modalState.Pending)
        try {
            identityDetail.address = identityDetail.address.address;
            const response = await POST(`${currency}/identities`, identityDetail);
            this.dispatch('success', response.message)
            commit('IDENTITIES_MODAL_SET_STATE',modalState.Success)
        } catch (error) {
            this.dispatch('error', error)
            commit('IDENTITIES_MODAL_SET_STATE',modalState.Error)
        }
        finally {
            commit("IDENTITIES_FINISH_LOADING")
        }

    },
    async editIdentity({ commit }, { currency, identityDetail }) {
        commit('IDENTITIES_START_LOADING')
        commit('IDENTITIES_MODAL_SET_STATE',modalState.Pending)
        try {
            identityDetail.address = identityDetail.address.address.address;
            const response = await PUT(`${currency}/identities/${identityDetail.id}`, identityDetail);
            this.dispatch('success', response.message)
            commit('IDENTITIES_MODAL_SET_STATE',modalState.Success)
        } catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
            commit('IDENTITIES_MODAL_SET_STATE',modalState.Error)
        }
        finally {
            commit("IDENTITIES_FINISH_LOADING")
        }
    },
    async deleteIdentity({ commit }, { currency, identityId }) {
        commit('IDENTITIES_START_LOADING')
        try {
            const response = await DELETE(`${currency}/identities/${identityId}`)
            if (response) {
                this.dispatch('success', response.message)
            }
        } catch (error) {
            this.dispatch("error", error.userFriendlyMessage)
        }
        commit("IDENTITIES_FINISH_LOADING")
    },
    async loadFilteredAddresses({ commit }, { currency, pagination = { itemsPerPage: 10, sortBy: ["created_at"] }, filter }) {
        commit('ADDRESSES_START_LOADING')
        try {
            const result = await GET(`${currency}/addresses`, {
                params: { ...pagination, filterAddress: filter },
                cancelToken: state.filteredAddressesCancelToken.token
            })
            commit('ADDRESSES_SUGGESTIONS_LOADED', { addresses: result })
            commit('ADDRESSES_FINISH_LOADING')
        } catch (error) {
            if(axios.isCancel(error)) {
                //request canceled, do nothing
            } else {
                this.dispatch("error", error.userFriendlyMessage)
                commit('ADDRESSES_FINISH_LOADING')
            }
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
