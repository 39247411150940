<script setup>
import DataGrid from "@/components/DataGrid/DataGrid.vue";
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import UserPicker from "@/components/common/Pickers/UserPicker.vue";
import {computed, ref, watch} from "vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import store from "@/store";
import {delay} from "@/utils";

const filterUserId = ref(null)

const user = computed(() => store.getters.user)
const hasSuperAdminRole = computed(() => user.value.roles.some(r => r.name === 'superadmin'))

const getLogs = useGetData("getUserLogs",{ filters: () => ({ filterUserId: hasSuperAdminRole.value ? filterUserId.value : user.value.id }) }, false, false, 1, true)

const logsHeaders = [
    {text: "User", align: "center", value: "user", width: 250, sortable: false},
    {text: "Action", align: "left", value: "action"},
    {text: "Logged At", align: "center", value: "created_at", sortable: false},
    {text: "Recorded URL", align: "left", value: "url"},
]

const DataGridRef = ref()

watch(filterUserId, () => {
    DataGridRef.value.refreshItems(true)
})

//need to give other requests a head start, otherwise
//BE sometimes returns null values inside objects' properties
let renderTable = ref(false)
const renderTableDelay = async () => {
    await delay(500)
    renderTable.value = true
}
renderTableDelay()

</script>

<template>
    <FancyCard class="cardShadow" dense>
        <template #header>
            <div class="d-flex align-center">
                <v-icon class="d-block mr-2 ml-1">text-account</v-icon>
                <span class="font-weight-bold">Activity</span>
            </div>
        </template>
        <template #body>
            <DataGrid
                v-if="renderTable"
                ref="DataGridRef"
                :bulk-operations="false"
                :headers="hasSuperAdminRole ? logsHeaders : logsHeaders.slice(1)"
                :get-data="getLogs"
                :items-per-page="5"
                itemId="id"
                sort-desc
                custom-headers
                :footer-props="{
                    'items-per-page-options': [5, 50, 200],
                    'show-current-page': true,
                    'showFirstLastPage': true,
                }"
            >
                <template #headers="{ header }">
                    <UserPicker
                        v-if="header.value === 'user' && hasSuperAdminRole"
                        :key="header.value"
                        class="mb-3 text-field-label-style"
                        v-model="filterUserId"
                        style="padding: 0"
                        @click.stop.native
                        itemMenu
                        single-line
                    />
                    <span v-else :key="header.value">
                        {{ header.text }}
                    </span>
                </template>
                <template #item="{ item }">
                    <td v-if="hasSuperAdminRole" class="text-overflow text-left">{{ item.name }}</td>
                    <td class="text-overflow text-left">{{ item.action }}</td>
                    <td class="text-overflow">
                        {{ item.created_at | formatDateTime }}
                    </td>
                    <td style="max-width: 300px;" class="text-left text-overflow">
                        <a
                            :href="item.url"
                            class="link"
                        >
                            {{ item.url }}
                        </a>
                    </td>
                </template>
            </DataGrid>
        </template>
    </FancyCard>
</template>

<style scoped>

</style>