var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "height100" },
    [
      _c(_setup.ModalFilterError, { ref: "errorModal" }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(_setup.FilterAddMenu, {
                attrs: { "filter-options": _setup.filtersOptions },
                model: {
                  value: _setup.selectedFilters,
                  callback: function ($$v) {
                    _setup.selectedFilters = $$v
                  },
                  expression: "selectedFilters",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        _vm._l(_setup.selectedFilters, function (filter) {
          return _c(
            "v-col",
            { key: filter.name, attrs: { cols: "auto" } },
            [
              _c(_setup.FilterTextField, {
                attrs: {
                  componentFilterType: filter.component,
                  filterSubType: filter.filterSubType,
                  name: filter.name,
                  time: filter.time,
                  append: filter.append,
                  label: filter.label,
                },
                on: {
                  "on-remove-filter-event": (filterLabel) =>
                    _setup.removeFilter(filterLabel),
                },
                model: {
                  value: _setup.selectedFiltersValues[filter.name],
                  callback: function ($$v) {
                    _vm.$set(_setup.selectedFiltersValues, filter.name, $$v)
                  },
                  expression: "selectedFiltersValues[filter.name]",
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "align-content": "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto", "align-self": "center" } },
            [_c(_setup.SearchButton, { on: { click: _setup.onSearchClick } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mt-4" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "div",
                { staticStyle: { "min-width": "140px" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mr-5",
                                    attrs: {
                                      small: "",
                                      depressed: false,
                                      outlined: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _setup.expanded = !_setup.expanded
                                      },
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _setup.expanded ? "Collapse" : "Expand"
                                      ) +
                                      "\n                            "
                                  ),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _setup.expanded
                                            ? "mdi-arrow-collapse-vertical"
                                            : "mdi-arrow-expand-vertical"
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_setup.expanded ? "Collapse" : "Expand") +
                          " all transactions\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _setup.totalTxs
                ? _c("div", [
                    _c("span", { staticClass: "NumberOfTx" }, [
                      _vm._v(_vm._s(_vm._f("formatNumber")(_setup.totalTxs))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "subtitle-1",
                        staticStyle: { color: "rgba(0, 0, 0, 0.6) !important" },
                      },
                      [
                        _vm._v(
                          _vm._s(_setup.totalPages === 1 ? "RESULT" : "RESULTS")
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("div", { staticStyle: { "min-width": "140px" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div"),
          _vm._v(" "),
          _setup.loading || _setup.transactions.length > 0
            ? _c(_setup.PaginationWrapper, {
                attrs: {
                  loading: _setup.loading,
                  "pages-total": _setup.totalPages ? _setup.totalPages : 0,
                  value: _setup.page,
                },
                on: { input: _setup.handleManualPaginationChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _setup.loading
                            ? _c(
                                "div",
                                [
                                  _c(_setup.ProgressCircular, {
                                    attrs: { color: _setup.currency.unit },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(_setup.TransactionsHeader, {
                            attrs: { "no-change": "" },
                          }),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _vm._l(_setup.transactions, function (transaction) {
                            return _c(_setup.TransactionSingle, {
                              key: transaction.txhash,
                              staticClass: "mt-1 mb-1",
                              attrs: {
                                transaction: transaction,
                                expanded: _setup.expanded,
                              },
                            })
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  46452798
                ),
              })
            : _c(
                "div",
                { staticClass: "title mt-4 font-weight-light text-center" },
                [
                  _vm._v(
                    "\n            No transactions matched the selected criteria.\n        "
                  ),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }