var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      !_vm.nonCryptoPrices
        ? [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$humanize.formatNumber(_vm.value, 7)) +
                " " +
                _vm._s(_vm.displayCurrency) +
                "\n  "
            ),
          ]
        : [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$humanize.formatNumber(
                    _vm.value * _vm.exchangePrice.price,
                    2
                  )
                ) +
                " " +
                _vm._s(_vm.exchangePrice.exchCurrency) +
                "\n  "
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }