<template>
    <div class="filter-expansion-panel">
        <v-row class="align-center">
            <v-col
                cols="11" lg="2" md="2"
                offset="0" offset-lg="1" offset-md="0"
                offset-sm="0" order="1" order-lg="1"
                order-md="1" order-sm="1" sm="6"
            >
                <DateTimePicker v-model="filter.since" :disabled="disabled" label="Since"/>
                <DateTimePicker v-model="filter.until" :disabled="disabled" label="Until"/>
            </v-col>
            <v-col
                cols="12" lg="2" md="3"
                offset="0" offset-lg="0" offset-md="0"
                offset-sm="0" order="3" order-lg="2"
                order-md="2" order-sm="2" sm="5">
                <v-row justify="center">
                    <v-btn-toggle
                        v-model="filter.isOutgoing"
                        :color="currency.unit"
                        class="mx-2 mb-2"
                        mandatory
                        tile
                    >
                        <v-btn
                            :disabled="disabled"
                            :value="false"
                            class="text-left"
                            height="32px"
                            outlined
                        >
                            Incoming Paths
                            <v-spacer/>
                            <v-icon class="ml-2">mdi-import</v-icon>
                        </v-btn>
                        <v-btn
                            :disabled="disabled"
                            :value="true"
                            class="text-left"
                            height="32px"
                            outlined
                            style="border-left-width: 1px; border-top-width: 0;"
                        >
                            Outgoing Paths
                            <v-spacer/>
                            <v-icon class="ml-2">mdi-export</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </v-row>
            </v-col>
            <v-col
                cols="12" lg="1" md="1"
                offset="0" offset-lg="0" offset-md="0"
                offset-sm="0" order="4" order-lg="3"
                order-md="3" order-sm="4" sm="3">
                <v-text-field
                    v-model.number="filter.maxHops"
                    :disabled="currency.typeFamily !== 'BTC' || disabled"
                    :min="1"
                    :rules="[
                      (v) =>
                        (v !== undefined && v !== '' && v !== null && v > 0) ||
                        'Must be a positive number',
                    ]"
                    label="Max Hops"
                    type="number"
                />
            </v-col>
            <v-col
                cols="10" lg="5" md="5"
                offset="0" offset-lg="0" offset-md="0"
                offset-sm="0" order="5" order-lg="4"
                order-md="4" order-sm="5" sm="9">
                <ClusterPicker ref="clusterPickerRef"
                               v-model="filter.remote"
                               :currency-unit="currency.unit"
                               :disabled="disabled"
                               :multiple="false"
                               label="Remote Cluster"
                               no-dense
                               outlined
                               property-name="entity_id"/>
            </v-col>
        </v-row>
        <v-row class="mb-2" justify="center" no-gutters>
            <v-col cols="auto">
                <v-btn
                    :color="currency.unit"
                    :disabled="disabled"
                    class="text-left"
                    height="32px"
                    outlined
                    @click="onSearch"
                >
                    Search
                    <v-icon class="ml-2" small>fa fa-magnifying-glass</v-icon>
                </v-btn>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :color="currency.unit"
                            :disabled="isFilterDefault || disabled"
                            absolute
                            class="ml-2"
                            icon
                            style="margin-top: -3px"
                            v-bind="attrs"
                            @click="resetFilter"
                            v-on="on"
                        >
                            <v-icon size="28px">mdi-backup-restore</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Filter</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import DateTimePicker from "@/components/common/DateTimePicker";
import VTextFieldValidateable from "@/components/common/ValidateableComponents/VTextFieldValidateable";
import {mapGetters} from "vuex";
import {deepClone, isObjectEmpty} from "@/utils";
import ClusterPicker from "@/components/common/ClusterPicker.vue";

export default {
    name: "InteractionsFilter",
    mixins: [routeSyncMixin],
    components: {
        ClusterPicker,
        DateTimePicker,
        VTextFieldValidateable
    },
    data() {
        return {
            filterInfo: {
                since: {type: "Number", default: () => undefined},
                until: {type: "Number", default: () => undefined},
                remote: {type: "String", default: () => ''},
                maxHops: {type: "Number", default: () => 1},
                isOutgoing: {type: "Boolean", default: () => true},
            },
        }
    },
    props: {
        currency: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        clusterCurrent: {
            type: String,
        },
    },
    computed: {
        ...mapGetters(["addressInteractionsHistoryFallback"]),
        isFilterDefault() {
            return this.$_compareFilters(this.filter, this.$_getDefaultFilter())
        },
    },
    created() {
        if (isObjectEmpty(this.$route.query) && this.addressInteractionsHistoryFallback[this.clusterCurrent]) {
            this.$_syncToRouteQuery(true, deepClone(this.addressInteractionsHistoryFallback[this.clusterCurrent]))
        }
    },
    methods: {
        async onSearch() {

            let isValid = true
            if (this.$refs.clusterPickerRef) {
                isValid = await this.$refs.clusterPickerRef.validate()
            }
            if (isValid) {
                this.$emit("search-funds-event", deepClone(this.filter))
            }

        },
        resetFilter() {
            this.filter = this.$_getDefaultFilter()
        },

        // -- mixin methods --
        onFilterInitial() {
            this.onSearch()
        },
    },


}
</script>

<style scoped>
.v-btn-toggle {
    flex-direction: column;
}

.v-expansion-panel::before {
    box-shadow: none;
}
</style>