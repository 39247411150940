export enum OperationType {
    LE = "LE",
    GE = "GE",
    E = "E",
    B = "B",
    A = "A"
}


export interface TransactionsFilterModel {
    [key: string]: {
        op: OperationType,
        values: Array<string>
    }
}

export interface TransactionFilterServerModel {
    params: Array<FilterType>
}

interface FilterType {
    name: string,
    conditions: Array<Condition>
}

interface Condition {
    op: string,
    value: string,
}

export function MapToServerModel(clientModel: TransactionsFilterModel): TransactionFilterServerModel {
    let filterTypes: FilterType[] = []

    for (const [key, value] of Object.entries(clientModel)) {

        if (!value)
            continue

        let conditions: Condition[] = []
        if (value.op === OperationType.B)
        {
            conditions.push( { op: "GE", value: value.values[0] })
            conditions.push( { op: "LE", value: value.values[1]})
        }
        else if(value.op === OperationType.A) {
            conditions.push( { op: "A", value: "-1" })
        }
        else {
            value.values.forEach(singleValue => {
                conditions.push( { op: value.op, value: singleValue })
            })
        }

        filterTypes.push({ name: key, conditions: conditions })

    }

    return { params: filterTypes }
}
