import { render, staticRenderFns } from "./AddressImporterMetadataMenu.vue?vue&type=template&id=b47901c0&"
import script from "./AddressImporterMetadataMenu.vue?vue&type=script&setup=true&lang=js&"
export * from "./AddressImporterMetadataMenu.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./AddressImporterMetadataMenu.vue?vue&type=style&index=0&id=b47901c0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VChip,VDataTable,VIcon,VMenu,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b47901c0')) {
      api.createRecord('b47901c0', component.options)
    } else {
      api.reload('b47901c0', component.options)
    }
    module.hot.accept("./AddressImporterMetadataMenu.vue?vue&type=template&id=b47901c0&", function () {
      api.rerender('b47901c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/Addresses/Dialogs/AddressImporterMetadataMenu.vue"
export default component.exports