var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-4", attrs: { fluid: "" } },
        [
          _c("h1", [_vm._v("Dashboard")]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "mt-2",
                        attrs: { align: "center", wrap: "" },
                      },
                      _vm._l(
                        Object.values(_vm.dashboardData),
                        function (currencyStatus, index) {
                          return _c("CurrencyPanel", {
                            key: index,
                            attrs: {
                              loading:
                                _vm.dashboardLoading[currencyStatus.unit],
                              currency: _vm.getCurrencyByUnit(
                                currencyStatus.unit
                              ),
                              data: currencyStatus,
                            },
                          })
                        }
                      ),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { attrs: { justify: "center" } },
                      [
                        _c("LatestTransactions", {
                          staticClass: "mx-3",
                          attrs: {
                            showInputsOutputs: true,
                            showFee: true,
                            transactionIdLength: 50,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }