import {ref, unref} from "vue"
import * as moment from "moment";
import {FilterType} from "@/components/common/types/filter";

const numericModes = ref([
    {
        icon: "fa fa-less-than-equal",
        text: "Less or equal",
        operation: "LE",
        fields: [
            {
                name: "min",
                placeholder: "0"
            }]
    },
    {
        icon: "fa fa-greater-than-equal",
        text: "Greater or equal",
        operation: "GE",
        fields: [
            {
                name: "min",
                placeholder: "0"
            }]
    },
    {
        icon: "fa fa-equals",
        text: "Equal to",
        operation: "E",
        fields: [
            {
                name: "min",
                placeholder: "0"
            }]
    },
    {
        icon: "fa-solid fa-arrows-left-right-to-line",
        text: "Interval",
        operation: "B",
        fields: [
            {
                label: "From",
                name: "min",
                placeholder: "0"
            },
            {
                label: "To",
                name: "max",
                placeholder: "0"
            }
        ]
    }
])

const numericFilterValueConverter = (mode, value) => {
    mode = unref(mode)
    value = unref(value)

    if (!mode || !value)
        return undefined

    let convertedValue = {op: mode.operation, values: [], type: FilterType.Numeric}
    mode.fields.forEach(field => {
        const valueToAdd = value[field.name]

        if (valueToAdd)
            convertedValue.values.push(valueToAdd)
    })

    if (convertedValue.values.length === 0)
        return undefined

    return convertedValue
}


const _toDateString = (unixTime) => {
   return moment.unix(Number(unixTime)).format("YYYY-MM-DD HH:mm")
}

const numericFilterValueToReadable = (filterValue, filterMode, asTime) => {
    if (!filterValue || !filterMode)
        return ''

    if (filterMode.fields.length === 0)
        return ''

    if (!filterValue[filterMode.fields[0].name])
        return ''

    let returnString = asTime ? _toDateString(filterValue[filterMode.fields[0].name]) : String(filterValue[filterMode.fields[0].name])
    for (let i = 1; i < filterMode.fields.length; i++) {
        returnString += ' – '
        if (filterValue[filterMode.fields[i].name])
            returnString +=  asTime ? _toDateString(filterValue[filterMode.fields[i].name]) : String(filterValue[filterMode.fields[i].name])
    }

    return returnString
}

/**
 *
 * @param filterName Name of the filter
 * @param route Route
 *
 */
export const useNumericFilter = ()=> ({ numericModes, numericFilterValueToReadable, numericFilterValueConverter })