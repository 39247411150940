<template>
    <notification-method-base>
        <template #iconName="{ on }">
            <v-icon v-on="on" color="green">
                mdi-email-outline
            </v-icon>
        </template>
        <template #tooltip>
            E-mail
        </template>
    </notification-method-base>
</template>

<script>
import NotificationMethodBase from "@/components/alarm/NotificationMethodBase";
export default {
    name: "NotificationEmail",
    components: {NotificationMethodBase}
}
</script>

<style scoped>

</style>