<template>
    <div class="scroll-y height100">
        <v-container class="height100 px-4" fluid>
            <v-layout align-center>
                <h1>Alarms</h1>
                <v-spacer/>
                <alarm-detail-modal :currencies="currencies" new-alarm @save-event="createAlarm">
                    <template #showbutton="{ on: on }">
                        <CreateButton v-on="on" text="Create alarm"/>
                    </template>
                </alarm-detail-modal>
            </v-layout>
            <v-divider/>
            <v-flex xs12>
                <v-divider/>
                <v-data-table
                    :headers="headers"
                    :items="computedAlarms"
                    :server-items-length="alarms.total ? alarms.total : 0"
                    :loading="alarmsLoading"
                    :options.sync="filter"
                    class="elevation-0 border mt-2"
                    dense
                    :footer-props="{
                        'items-per-page-options': [15, 30, 50, -1],
                        'show-current-page': true,
                        'showFirstLastPage': true,
                    }"
                >
                    <template
                        v-for="h in headers"
                        v-slot:[`header.${h.value}`]="{ header }"
                    >
                        <v-text-field
                            :key="h.value"
                            v-if="h.value === 'name'"
                            append-icon="search"
                            :label="header.text"
                            single-line
                            hide-details
                            clearable
                            @click.stop.native
                            @input="debounceInput"
                            v-model="debouncedFilter.filterTitle"
                            class="text-field-label-style"
                        />

                        <v-text-field
                            :key="h.value"
                            v-if="h.value === 'username'"
                            append-icon="search"
                            :label="header.text"
                            single-line
                            hide-details
                            @click.stop.native
                            clearable
                            @input="debounceInput"
                            v-model="debouncedFilter.filterUser"
                            class="text-field-label-style"
                        />

                        <v-select
                            :key="h.value"
                            v-if="h.value === 'notify'"
                            :label="header.text"
                            single-line
                            hide-details
                            :items="notifiedByOptions"
                            item-value="value"
                            @click.stop.native
                            clearable
                            v-model="filter.filterVia"
                            class="text-field-label-style"
                        />

                        <div
                            :key="h.value"
                            v-if="h.value === 'coin_id'"
                            style="
                                margin-bottom: auto;
                                padding-bottom: auto;
                            "
                        >
                            <BlockchainCryptoPickerSlim
                                :currencies="allCurrenciesExceptUnknown"
                                @click.stop.native
                                v-model="filter.filterCurrency"
                                dense
                            />
                        </div>

                        <v-text-field
                            :key="h.value"
                            v-if="h.value === 'hash'"
                            append-icon="search"
                            :label="header.text"
                            single-line
                            hide-details
                            @click.stop.native
                            clearable
                            @input="debounceInput"
                            v-model="debouncedFilter.filterAddress"
                            class="text-field-label-style"
                        />

                        <div :key="h.value" v-if="h.value === 'created_at'" class="datepicker-container">
                            <DateTimePicker
                                :label="h.text"
                                v-model="filter.filterCreatedAt"
                                :disabledTime="true"
                            />
                        </div>

                        <v-text-field
                            :key="h.value"
                            v-if="h.value === 'count'"
                            :label="header.text"
                            single-line
                            hide-details
                            type="number"
                            :min="0"
                            hide-spin-buttons
                            @click.stop.native
                            clearable
                            @input="debounceInput"
                            v-model="debouncedFilter.filterRecords"
                            style="min-width: 90px;"
                            class="text-field-label-style"
                        />

                        <v-select
                            :key="h.value"
                            v-if="h.value === 'tx_direction'"
                            :label="header.text"
                            single-line
                            hide-details
                            @click.stop.native
                            :items="directionOptions"
                            :return-object="false"
                            clearable
                            v-model="filter.filterTxDirection"
                            style="min-width: 90px;"
                            class="text-field-label-style"
                        />

                        <v-select
                            :key="h.value"
                            v-if="h.value === 'type'"
                            :label="header.text"
                            single-line
                            pointer
                            hide-details
                            @click.stop.native
                            :items="alarmTypeOptions"
                            :return-object="false"
                            clearable
                            v-model="filter.filterType"
                            style="min-width: 100px;"
                            class="text-field-label-style"
                        />

                        <div
                            :key="h.value"
                            v-if="h.value === 'action'"
                            style="width: 60px"
                        >
                            <h3>{{ h.text }}</h3>
                        </div>
                    </template>

                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="text-left text-truncate">
                                <alarm-detail-modal :currencies="currencies"
                                                    :alarm="{crypto: item.address.crypto, hash: item.address.hash, username: item.user.name, ...item}"
                                                    existing-alarm
                                                    @save-event="editAlarm"
                                                    @delete-event="deleteAlarm">
                                    <template #showbutton="{ on: one }">
                                        <span
                                            v-on="one"
                                            class="link"
                                            style="cursor: pointer"
                                        >
                                            {{ item.name }}
                                        </span>
                                    </template>
                                </alarm-detail-modal>
                            </td>
                            <td class="text-overflow text-left">{{ item.user.name }}</td>

                            <td class="text-overflow">
                                <span v-if="item.notify === 'email' || item.notify === 'both'">
                                    <notification-email/>
                                </span>

                                <span v-if="item.notify === 'rest' || item.notify === 'both'">
                                    <notification-rest/>
                                </span>
                            </td>
                            <td
                                class="text-overflow"
                                style="
                                    text-align: center;
                                    vertical-align: middle;
                                "
                            >
                                <BlockchainCryptoBadge
                                    :currencies="currencies"
                                    :crypto="item.address.crypto"
                                    :iconWidth="24"
                                />
                            </td>
                            <td>
                                <EntityLabel
                                    :isAddress="item.type !== 'custom'"
                                    :currency="item.address.crypto"
                                    :isCopyable="true"
                                    actAsLink
                                    :entity="item.address.hash"
                                    justify="start"
                                />
                            </td>
                            <td class="text-overflow">
                                {{
                                    item.created_at
                                        | moment("YYYY-MM-DD HH:mm:ss Z")
                                }}
                            </td>
                            <td
                                class="text-overflow text-right"
                                style="
                                    text-align: center;
                                    vertical-align: middle;
                                "
                            >
                                {{ item.notifications_count | formatNumber }}
                            </td>
                            <td
                                class="text-overflow text-left"
                                style="
                                    text-align: center;
                                    vertical-align: middle;
                                "
                            >
                                {{ item.tx_direction }}
                            </td>
                            <td
                                class="text-overflow text-left"
                                style="
                                    text-align: center;
                                    vertical-align: middle;
                                "
                            >
                                {{ item.type }}
                            </td>
                            <td>
                                <alarm-detail-modal :currencies="currencies"
                                                    :alarm="{crypto: item.address.crypto, hash: item.address.hash, username: item.user.name, ...item}"
                                                    existing-alarm
                                                    @save-event="editAlarm"
                                                    @delete-event="deleteAlarm">
                                    <template #showbutton="{ on: on }">
                                        <TableInfoButton
                                            small-icon
                                            color="blue"
                                            v-on="on"/>
                                    </template>
                                </alarm-detail-modal>
                                <TableDeleteModalDialog
                                    :description="`Do you really want to delete alarm ${item.name}?`"
                                    :itemToDelete="item"
                                    @delete-event="deleteAlarm"
                                    tooltip="Delete Alarm"
                                />
                            </td>
                        </tr>
                    </template>
                    <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                        <span>
                            {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                        </span>
                    </template>
                </v-data-table>
            </v-flex>
            <v-dialog
                v-model="showLoading"
                persistent
                max-width="150"
            >
                <v-card>
                    <ProgressCircular class="pa-0 ma-0" color="primary"/>
                </v-card>
            </v-dialog>
            <alarm-detail-modal
                v-if="alarmSingle"
                :currencies="currencies"
                :alarm="{crypto: alarmSingle.address.crypto, hash: alarmSingle.address.hash, username: alarmSingle.user.name, ...alarmSingle}"
                existing-alarm
                @save-event="editAlarm"
                @delete-event="deleteAlarm">
                <template #showbutton="{ on: on }">
                    <button class="d-none" ref="openModalButton" @click="on.click($event)"></button>
                </template>
            </alarm-detail-modal>
        </v-container>
    </div>
</template>

<script>

import {
    BlockchainCryptoBadge,
    BlockchainCryptoPickerSlim,
    TableDeleteModalDialog,
    TableEditButton,
    CreateButton
} from "@/components/common"
import {mapGetters} from "vuex";
import debounce from "debounce";
import {deepClone, timeDifference} from "@/utils";
import AlarmDetailModal from "@/components/alarm/AlarmDetailModal";
import TableInfoButton from "@/components/common/TableComponents/TableInfoButton";
import NotificationEmail from "@/components/alarm/NotificationEmail";
import NotificationRest from "@/components/alarm/NotificationRest";
import ProgressCircular from "@/components/common/ProgressCircular";
import Copyable from "@/components/common/Copyable";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import DateTimePicker from "@/components/common/DateTimePicker";
import {useGetActiveCurrencyOrDefault} from "@/components/Composables/useGetActiveCurrencyOrDefault";
import EntityLabel from "@/components/common/EntityLabel.vue";

export default {
    name: "AlarmsView",
    mixins: [routeSyncMixin],
    components: {
        EntityLabel,
        Copyable,
        ProgressCircular,
        NotificationRest,
        NotificationEmail,
        TableInfoButton,
        AlarmDetailModal,
        BlockchainCryptoBadge,
        BlockchainCryptoPickerSlim,
        TableDeleteModalDialog,
        TableEditButton,
        CreateButton,
        DateTimePicker,
    },
    computed: {
        ...mapGetters(["currencies", "allCurrenciesExceptUnknown", "alarms", "alarmsLoading", "alarmSingle", "currency"]),
        computedAlarms() {
            return this.alarms?.data?.map(a => {
                return {...a, elapsedCreated: timeDifference(a.created_at)}
            })
        }
    },
    data() {
        return {
            showLoading: false,
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                sortBy: {type: "ArrayOfStrings", default: () => ['created_at']},
                sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
                filterTitle: {type: "String", default: () => ''},
                filterVia: {type: "String", default: () => ''},
                filterCreatedAt: {type: "Number", default: () => undefined},
                filterUser: {type: "String", default: () => ''},
                filterAddress: {type: "String", default: () => ''},
                filterRecords: {type: "Number", default: () => ''},
                filterTxDirection: {type: "String", default: () => ''},
                filterType: {type: "String", default: () => ''},
                filterCurrency: {type: "String", default: () => ''}
            },
            debouncedFilter: {
                filterTitle: undefined,
                filterUser: undefined,
                filterAddress: undefined,
                filterRecords: undefined,
            },
            headers: [
                {text: "Title", align: "center", value: "name", sortable: true},
                {text: 'User', align: "center", value: 'username', sortable: true},
                {text: "Notified By", align: "center", value: "notify", sortable: true},
                {text: "Currency", align: "center", value: "coin_id", sortable: false, width: 130},
                {text: "Entity ID", align: "center", value: "hash", sortable: true},
                {text: "Created", align: "center", value: "created_at", sortable: true},
                {text: "#Notifications", align: "center", value: "count", sortable: true},
                {text: "Direction", align: "center", value: "tx_direction", sortable: true},
                {text: "Alarm Type", align: "center", value: "type", sortable: true},
                {text: "Action", value: "action", align: "center", sortable: false, filter: null}
            ],
            directionOptions: [
                'in',
                'out',
                'inout',
            ],
            alarmTypeOptions: [
                'address',
                'cospent',
                'custom',
            ],
            notifiedByOptions: [
                {
                    text: 'Notification',
                    value: 'rest'
                },
                {
                    text: 'Email',
                    value: 'email'
                },
                {
                    text: 'Both',
                    value: 'both'
                },
                /* 'rest',
                'email',
                'both', */
            ],
        }
    },
    watch: {
        '$route.query.filterCurrency': {
            handler(newValue)
            {
                if (!newValue) {
                    this.$_syncToRouteQuery(true, {...this.$route.query, filterCurrency: useGetActiveCurrencyOrDefault(this.$route)})
                }
            }
        }
    },
    async created() {
        this.$_syncToRouteQuery(true, {...this.$route.query, filterCurrency: useGetActiveCurrencyOrDefault(this.$route)})

        this.$store.commit("ALARMS_PAGE_LOADED", {data: []})
        if (this.$route.query.alarmId) {
            this.showLoading = true
            await this.$store.dispatch("loadAlarmSingle", {currency: this.$route.query.filterCurrency, alarmId: this.$route.query.alarmId})
            this.showLoading = false
            if (this.alarmSingle) {
                this.$refs.openModalButton.click()
            }
        }
    },
    methods: {
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            //stops FE from sending BE a notifications(records) filter if the input is empty
            if (this.debouncedFilter.filterRecords === null) this.debouncedFilter.filterRecords = undefined

            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),
        refresh() {
            this.$store.dispatch("loadAlarms", {currency: this.filter.filterCurrency, pagination: this.filter})
        },
        async editAlarm({alarmDetail}) {
            await this.$store.dispatch("editAlarm", {
                currency: this.filter.filterCurrency,
                alarm: deepClone(alarmDetail)
            })
        },
        async deleteAlarm({itemToDelete}) {
            await this.$store.dispatch("deleteAlarm", {currency: this.filter.filterCurrency, alarmId: itemToDelete.id})
            this.refresh()
        },
        async createAlarm({alarmDetail}) {
            await this.$store.dispatch("createAlarm", {
                currency: this.filter.filterCurrency,
                alarm: deepClone(alarmDetail)
            })
            this.refresh()
        },
    },
}
</script>

<style>
.datepicker-container .v-input__slot {
    margin-bottom: 0px;
}
</style>