import { render, staticRenderFns } from "./GraphsView.vue?vue&type=template&id=387f7386&"
import script from "./GraphsView.vue?vue&type=script&lang=js&"
export * from "./GraphsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VContainer,VDataTable,VDivider,VFlex,VHover,VIcon,VLayout,VSpacer,VTextField,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('387f7386')) {
      api.createRecord('387f7386', component.options)
    } else {
      api.reload('387f7386', component.options)
    }
    module.hot.accept("./GraphsView.vue?vue&type=template&id=387f7386&", function () {
      api.rerender('387f7386', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/GraphsView.vue"
export default component.exports