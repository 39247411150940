<template>
    <router-link
        :style="noDecoration ? 'text-decoration: none' : ''"
        style="width: 100%"
        :to="{ path: targetUrl }">
        <slot name="text"/>
    </router-link>
</template>

<script>
import {getRouteFromFundsToInteractions} from "@/utils";

export default {
    name: "RouteToInteractions",
    props: {
        query: {
            type: String,
            required: true
        },
        noDecoration: {
          type: Boolean,
          required: false,
          default: false,
        }
    },
    computed: {
        targetUrl() {
            return getRouteFromFundsToInteractions(this.$route) + "?" + this.query
        }
    }
}

</script>