var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PeerDetailModal", {
        ref: "peerDetailModalRef",
        attrs: {
          currency: _vm.currency.unit,
          loading: _vm.peerDetailLoading,
          "peer-detail": _vm.peerDetail,
          "peer-id": _vm.peerDetailId,
        },
      }),
      _vm._v(" "),
      _vm.isLoading || !_vm.transactionInfo
        ? _c(
            "div",
            [
              _c("ProgressCircular", {
                staticClass: "pt-10",
                attrs: { color: _vm.currency.unit },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "Transaction" },
            [
              _c("FancyCard", {
                attrs: { header: false, "no-gutters": "", outlined: "" },
                scopedSlots: _vm._u([
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("nobr", [
                          _c(
                            "div",
                            {
                              staticClass: "TxHeader d-flex pl-2",
                              on: {
                                click: function ($event) {
                                  _vm.showDetail = !_vm.showDetail
                                },
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticStyle: { width: "45%" },
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-right align-center addressTitle",
                                      attrs: { cols: "12", lg: "3" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "RouteToTransaction",
                                            {
                                              attrs: {
                                                currency: _vm.currency.unit,
                                                transaction:
                                                  _vm.transactionInfo.txhash,
                                                "no-decoration": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "text" },
                                                [
                                                  _c("Copyable", {
                                                    attrs: {
                                                      "on-copy":
                                                        _vm.transactionInfo
                                                          .txhash,
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "text",
                                                        fn: function ({
                                                          on,
                                                          attr,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {},
                                                                  "div",
                                                                  attr,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "fontMonospace",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "stringMiddleShortener"
                                                                          )(
                                                                            _vm
                                                                              .transactionInfo
                                                                              .txhash,
                                                                            20
                                                                          )
                                                                        ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-space-around align-center",
                                      attrs: {
                                        cols: "12",
                                        lg: "4",
                                        offset: "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-center align-center",
                                          staticStyle: {
                                            width: "250px",
                                            height: "100%",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-center align-center",
                                              staticStyle: {
                                                "min-width": "24px",
                                                "max-width": "24px",
                                                height: "100%",
                                              },
                                            },
                                            [
                                              _vm.openedLightningNetworkChannel
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        left: "",
                                                                        color:
                                                                          "#ffc001",
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                    mdi-flash\n                                                "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1385562456
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            This transaction opened a lightning network channel.\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-center align-center",
                                              staticStyle: {
                                                "min-width": "24px",
                                                "max-width": "60px",
                                                height: "100%",
                                              },
                                            },
                                            [
                                              _vm.category
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        "open-delay": "500",
                                                        top: "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "tagContainer",
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "grid",
                                                                          filter:
                                                                            "drop-shadow(-1px 4px 4px rgba(50, 50, 0, 0.5))",
                                                                        },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "tag layer2",
                                                                      style:
                                                                        "background-color: " +
                                                                        _vm
                                                                          .category
                                                                          .color,
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "layer1",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "20px",
                                                                                "margin-left":
                                                                                  "2px",
                                                                                "margin-top":
                                                                                  "4px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .category
                                                                                    .secondaryColor,
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                            " +
                                                                                _vm._s(
                                                                                  _vm.icon
                                                                                ) +
                                                                                "\n                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        79698527
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.category.name
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "BaseButtonShowMoreItems",
                                            {
                                              attrs: {
                                                items:
                                                  _vm.categoriesForShowMoreButton,
                                                shownItemsCount: 0,
                                              },
                                            },
                                            _vm._l(
                                              _vm.categoriesForShowMoreButton,
                                              function (cat) {
                                                return _c(
                                                  "AddressCategoryIcon",
                                                  {
                                                    key: cat.id,
                                                    staticClass: "mx-1",
                                                    attrs: {
                                                      color: cat.color,
                                                      iconItem: cat,
                                                      name: cat.name,
                                                      medium: "",
                                                    },
                                                  }
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-center align-center my-1",
                                              staticStyle: { width: "38px" },
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    "open-delay": "500",
                                                    top: "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _vm.transactionInfo
                                                            .txPropagations
                                                            .length > 0
                                                            ? _c(
                                                                "div",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-center align-center",
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "\n                                                        mdi-web\n                                                    "
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Tx relayers available\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-center align-center my-1",
                                              staticStyle: { width: "50px" },
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    "open-delay": "500",
                                                    top: "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _vm.transactionInfo
                                                            .casesCount > 0
                                                            ? _c(
                                                                "div",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-center align-center",
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "4px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "assignment"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                                                    " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .transactionInfo
                                                                          .casesCount
                                                                      ) +
                                                                      "\n                                                "
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        `${
                                                          _vm.transactionInfo
                                                            .casesCount
                                                        } ${
                                                          _vm.transactionInfo
                                                            .casesCount === 1
                                                            ? "case"
                                                            : "cases"
                                                        }`
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-center align-center my-1",
                                              staticStyle: { width: "50px" },
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    "open-delay": "500",
                                                    top: "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _vm.transactionInfo
                                                            .identityCount > 0
                                                            ? _c(
                                                                "div",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-center align-center",
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("img", {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "26px",
                                                                        "margin-right":
                                                                          "4px",
                                                                        filter:
                                                                          "opacity(0.6)",
                                                                      },
                                                                    attrs: {
                                                                      alt: "Identity",
                                                                      src: "/img/ico/name-id-icon.svg",
                                                                    },
                                                                  }),
                                                                  _vm._v(
                                                                    "\n                                                    " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .transactionInfo
                                                                          .identityCount
                                                                      ) +
                                                                      "\n                                                "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        `${
                                                          _vm.transactionInfo
                                                            .identityCount
                                                        } ${
                                                          _vm.transactionInfo
                                                            .identityCount === 1
                                                            ? "identity"
                                                            : "identities"
                                                        }`
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-center align-center my-1",
                                              staticStyle: { width: "50px" },
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    "open-delay": "500",
                                                    top: "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _vm.transactionInfo
                                                            .ownersCount > 0
                                                            ? _c(
                                                                "div",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-center align-center",
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("img", {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "22px",
                                                                        "margin-right":
                                                                          "4px",
                                                                        filter:
                                                                          "opacity(0.6)",
                                                                      },
                                                                    attrs: {
                                                                      alt: "Owner",
                                                                      src: "/img/ico/owner-icon.svg",
                                                                    },
                                                                  }),
                                                                  _vm._v(
                                                                    "\n                                                    " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .transactionInfo
                                                                          .ownersCount
                                                                      ) +
                                                                      "\n                                                "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        `${
                                                          _vm.transactionInfo
                                                            .ownersCount
                                                        } ${
                                                          _vm.transactionInfo
                                                            .ownersCount === 1
                                                            ? "owner"
                                                            : "owners"
                                                        }`
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-end addressTitle font-weight-bold",
                                      attrs: {
                                        cols: "12",
                                        lg: "3",
                                        "offset-lg": "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            "open-delay": "500",
                                            top: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _vm.transaction
                                                    .currentAddressBalance
                                                    ? _c(
                                                        "div",
                                                        _vm._g(
                                                          {
                                                            class: {
                                                              fontMonospace: true,
                                                              "success--text":
                                                                _vm.transaction
                                                                  .currentAddressBalance <
                                                                0,
                                                              "error--text":
                                                                _vm.transaction
                                                                  .currentAddressBalance >
                                                                0,
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(
                                                                _vm.transaction
                                                                  .currentAddressBalance <
                                                                  0
                                                                  ? "+"
                                                                  : _vm
                                                                      .transaction
                                                                      .currentAddressBalance >
                                                                    0
                                                                  ? "-"
                                                                  : ""
                                                              ) +
                                                              "\n                                            "
                                                          ),
                                                          _c("fiat-convertor", {
                                                            attrs: {
                                                              fiats:
                                                                _vm
                                                                  .transactionInfo
                                                                  .fiats,
                                                              value:
                                                                _vm.transaction
                                                                  .currentAddressBalance,
                                                              absoluteValue: "",
                                                              "with-unit": "",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Overall balance change of the address\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center",
                                  staticStyle: {
                                    "min-width": "135px",
                                    width: "10%",
                                    filter: "opacity(0.6)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center title font-weight-bold fontMonospace d-flex",
                                      staticStyle: { width: "150px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "text-align": "end",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.transactionInfo.inputsCount
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "\n                                     : \n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            "text-align": "start",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.transactionInfo.outputsCount
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticStyle: { width: "45%" },
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-end align-center",
                                      attrs: {
                                        cols: "12",
                                        lg: "3",
                                        "offset-lg": "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            "open-delay": "500",
                                            top: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "fontMonospace addressTitle",
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("fiat-convertor", {
                                                        attrs: {
                                                          fiats:
                                                            _vm.transactionInfo
                                                              .fiats,
                                                          value:
                                                            _vm.transactionInfo
                                                              .sumOfOutputValues +
                                                            _vm.transactionInfo
                                                              .sumOfFeeValues,
                                                          "with-unit": "",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Total output value of the transaction including fee\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-end align-center",
                                      attrs: { cols: "12", lg: "3" },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            "open-delay": "500",
                                            top: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "fontMonospace addressTitle",
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("fiat-convertor", {
                                                        attrs: {
                                                          fiats:
                                                            _vm.transactionInfo
                                                              .fiats,
                                                          value:
                                                            _vm.transactionInfo
                                                              .sumOfFeeValues,
                                                          "with-unit": "",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Fee value\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-end align-center",
                                      attrs: {
                                        cols: "12",
                                        lg: "4",
                                        offset: "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                "open-delay": "500",
                                                top: "",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-col",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "d-flex align-center",
                                                            attrs: {
                                                              cols: "10",
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "moment"
                                                                )(
                                                                  _vm
                                                                    .transactionInfo
                                                                    .blockTime,
                                                                  "YYYY-MM-DD HH:mm:ss Z"
                                                                )
                                                              ) +
                                                              "\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Transaction timestamp\n                                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c("TransactionShowOnGraph", {
                                                attrs: {
                                                  txhash:
                                                    _vm.transactionInfo.txhash,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("v-expand-transition", [
                          _vm.showDetail
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between pa-1",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { width: "48%" } },
                                        [
                                          _c("TransactionInputTable", {
                                            staticClass: "my-1",
                                            attrs: {
                                              address:
                                                _vm.transaction.byAddress,
                                              data: _vm.transactionInfo.inputs,
                                              fiats: _vm.transactionInfo.fiats,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "48%" } },
                                        [
                                          _c("TransactionOutputTable", {
                                            staticClass: "my-1",
                                            attrs: {
                                              address:
                                                _vm.transaction.byAddress,
                                              data: _vm.transactionInfo.outputs,
                                              fiats: _vm.transactionInfo.fiats,
                                              txType: _vm.currency.typeFamily,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("TransactionSankeyDiagram", {
                                    attrs: {
                                      "transaction-info": _vm.transactionInfo,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "headerRight",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex",
                                                  staticStyle: { gap: "12px" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        opacity: "0.8",
                                                      },
                                                    },
                                                    [_vm._v("Block Height:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("RouteToBlock", {
                                                    attrs: {
                                                      "block-height":
                                                        _vm.transactionInfo
                                                          .blockHeight,
                                                      "block-not-mined-message":
                                                        "This transaction has not been confirmed yet.",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1361051815
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm.transactionInfo.txPropagations.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "d-flex TxFooter" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-center align-center flex-grow-0 flex-shrink-0 font-weight-bold",
                                              staticStyle: {
                                                width: "125px",
                                                color: "rgba(0,0,0,0.6)",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                RELAYERS:\n                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.transactionInfo.txPropagations
                                            .length > 0
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "rounded fontMonospace",
                                                  attrs: {
                                                    justify: "space-around",
                                                    "no-gutters": "",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.transactionInfo
                                                    .txPropagations,
                                                  function (
                                                    txPropagation,
                                                    index
                                                  ) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "d-flex justify-center align-center height100",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "d-inline-block mr-2",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "500",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  index + 1
                                                                ) +
                                                                ":\n                                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                txPropagation
                                                                  .node.id !==
                                                                Number(
                                                                  _vm.$route
                                                                    .query?.id
                                                                ),
                                                              top: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-chip",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "link",
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "20px",
                                                                                "max-width":
                                                                                  "220px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "",
                                                                                outlined:
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openPeerDialog(
                                                                                    txPropagation
                                                                                      .node
                                                                                      .id
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                " +
                                                                              _vm._s(
                                                                                txPropagation
                                                                                  .node
                                                                                  .host
                                                                              ) +
                                                                              "\n                                            "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                "You're here."
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        index !== 0
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-italic",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                         +" +
                                                                    _vm._s(
                                                                      (Date.parse(
                                                                        txPropagation.received_at
                                                                      ) -
                                                                        Date.parse(
                                                                          _vm
                                                                            .transactionInfo
                                                                            .txPropagations[0]
                                                                            .received_at
                                                                        )) /
                                                                        1000
                                                                    ) +
                                                                    "s\n                                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-italic ml-3",
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "nowrap",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                        " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "moment"
                                                                      )(
                                                                        txPropagation.received_at,
                                                                        "YYYY-MM-DD HH:mm:ss Z"
                                                                      )
                                                                    ) +
                                                                    "\n                                    "
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }