interface ColumnDefinition {
    csvHeader: string
    index?: number
    exportPropertyName: string
}

export enum CsvParserExceptionTypes {
    MissingHeaders
}
export interface CsvParserException {
    type: CsvParserExceptionTypes
    message: string
}


function parseStringToCSV<TResult>(value: string, columns: Array<ColumnDefinition>): Array<TResult> {
    const rows = value.split("\n")
    // shift remove first element from array and returns it, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/shift
    // every row must be trimmed (last \n char must be removed)
    const headers = rows.shift().trim().split(";")
    columns.forEach(column => {
        column.index = headers.indexOf(column.csvHeader)
    })

    const isAllHeadersValid = columns.every(i => i.index !== -1)
    if (!isAllHeadersValid) {
        throw {
            type: CsvParserExceptionTypes.MissingHeaders,
            message: `Required headers is "${columns.map(i => i.csvHeader).join(";")}", file contain "${headers.join(";")}"`
        } as CsvParserException
    }
    const results = [] as Array<TResult>

    rows.forEach(row => {
        const result = {} as TResult
        // every row must be trimmed (last \n char must be removed)
        row.trim().split(";").forEach((value, index) => {
            let column = columns.find(i => i.index == index)
            if (column && value) {
                result[column.exportPropertyName] = value
            }
        })
        results.push(result)
    })
    return results;
}

function readAsTextAsync(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsText(file, "UTF8");
    })
}

async function parseCSVFileToObjectAsync<TResult>(file: File, columns: Array<ColumnDefinition>): Promise<Array<TResult>> {
    const parsedText = await readAsTextAsync(file)
    return parseStringToCSV(parsedText, columns);
}

function normalizeData<TData>(data: Array<TData>, columns: Array<ColumnDefinition>) {
    const requiredProps = columns.map(i => i.exportPropertyName)
    let corruptedData = new Array<({ lineNumber: Number, data: string })>()

    const normalizedData = data.filter(function (i, index) {
        const haveEveryRequiredProps = requiredProps.every(c => i.hasOwnProperty(c))
        if (!haveEveryRequiredProps) {
            const lineNumber = index + 2 // 1. line is headers
            corruptedData.push({ lineNumber, data: JSON.stringify(i) })
        }
        return haveEveryRequiredProps
    })
    return { normalizedData, corruptedData }
}

export { parseStringToCSV, readAsTextAsync, parseCSVFileToObjectAsync, normalizeData }