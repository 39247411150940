var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "headerText", attrs: { "no-gutters": "" } },
    [
      _c(
        "v-row",
        { staticStyle: { width: "45%" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center",
              attrs: { cols: "5" },
            },
            [
              _c("div", [
                _vm._v("\n                TRANSACTION ID\n            "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center",
              attrs: { cols: "4" },
            },
            [_c("div", [_vm._v("\n                METADATA\n            ")])]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center",
              attrs: { cols: "3" },
            },
            [
              !_vm.noChange
                ? _c("div", [_vm._v("\n                CHANGE\n            ")])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { width: "10%" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center justify-center",
                  attrs: { cols: "12" },
                },
                [
                  _c("div", [
                    _vm._v("\n                    IN : OUT\n                "),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticStyle: { width: "45%" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center",
              attrs: { cols: "3", offset: "1" },
            },
            [_c("div", [_vm._v("\n                TOTAL\n            ")])]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center",
              attrs: { cols: "3" },
            },
            [_c("div", [_vm._v("\n                FEE\n            ")])]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4", offset: "1" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center justify-center",
                      attrs: { cols: "4", offset: "2" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n                        TIMESTAMP\n                    "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center justify-center",
                      attrs: { cols: "2", offset: "2" },
                    },
                    [
                      _c("div", { staticStyle: { "margin-left": "24px" } }, [
                        _vm._v(
                          "\n                        VISUALIZE\n                    "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }