<script setup>
import DataGrid from "@/components/DataGrid/DataGrid.vue";
import AddressCategoryIcon from "@/components/address/AddressCategoryIcon.vue";
import BlockchainCryptoBadge from "@/components/common/BlockchainCryptoBadge.vue";
import BaseButtonShowMoreItems from "@/components/common/TableComponents/BaseButtonShowMoreItems.vue";
import {computed, ref} from "vue";
import store from "@/store";
import {useRoute} from "vue-router/composables";
import {useGetData} from "@/components/common/Composables/useGetData";
import ProgressCircular from "@/components/common/ProgressCircular.vue";
import {DataState} from "@/components/common/types/data";
import {numberFormatCompact} from "@/utils/filters";
import DataGridBulkDelete from "@/components/DataGrid/Actions/Put/DataGridBulkDelete.vue";
import EntityLabel from "@/components/common/EntityLabel.vue";
import TableItemsExpandable from "@/views/Addresses/TableItemsExpandable.vue";
import OwnerBadge from "@/views/Addresses/OwnerBadge.vue";

const props = defineProps({
    caseId: {
        type: Number,
        required: false
    },
    inModal: {
        type: Boolean,
        required: false,
        default: false
    }
})

const currencies = computed(() => store.getters.currencies)

const route = useRoute()
const caseId = computed(() => props.caseId ?? route.params.caseId)

const headers = [
    {text: 'Address', align: 'center', value: 'address'},
    {text: 'Currency', align: 'center', value: 'crypto', sortable: false},
    {text: 'Identities', align: 'center', value: 'identities', sortable: false},
    {text: 'Categories', align: 'center', value: 'categories', sortable: false},
    {text: 'Owner', align: 'center', value: 'owner_id', sortable: false},
    {text: 'Created', align: 'center', value: 'created_at'}
]

const currencyUseGetData = computed(() => {
    const result = {}
    currencies.value.forEach(currency => {
        result[currency.unit] = createUseGetDataForCurrency(currency.unit)
    })
    return result
})

const createUseGetDataForCurrency = (currencyUnit) => {
    const {data, getData, dataState} = useGetData("loadCaseAddresses", {
        caseId: caseId.value,
        currency: currencyUnit
    }, false)
    return {data, getData, dataState}
}

const gridRef = ref(null)

const deleteItems = async (itemsToDelete, currencyUnit) => {
    return !!await store.dispatch("deleteCaseAddresses", {
        caseId: caseId.value,
        currencyUnit,
        itemsToDelete
    })
}

const currencyAddressesLoading = (currencyUnit) => currencyUseGetData.value[currencyUnit].dataState.value === DataState.Loading

const totalAddressesToDisplay = (currencyUnit) => currencyUseGetData.value[currencyUnit].data.value?.total

const noDataToDisplay = (currencyUnit) => totalAddressesToDisplay(currencyUnit) === 0

const caseHasAddressesInAnyCurrency = computed(() => !!Object.values(currencyUseGetData.value).some(itemUseGetData => itemUseGetData.data.value?.data.length > 0))

const refreshAddressTables = () => gridRef.value?.forEach(ref => ref.refreshItems())

defineExpose({
    refreshAddressTables
})
</script>

<template>
    <v-row class="mb-0">
        <v-tabs slider-color="tertiary">
            <v-tab
                v-for="currency in currencies"
                :key="currency.id"
                :disabled="noDataToDisplay(currency.unit)"
            >
                <div class="d-flex align-center" style="gap: 12px">
                    <v-img :src="currency.image" style="width: 24px" alt="crypto icon"/>
                    <div>
                        {{ currency.name }}
                    </div>
                    <progress-circular :size="20" v-if="currencyAddressesLoading(currency.unit)"
                                       :color="currency.color"/>
                    <v-badge v-else inline class="fontMonospace"
                             :content="numberFormatCompact(totalAddressesToDisplay(currency.unit))"/>
                </div>
            </v-tab>
            <v-tab-item
                v-for="currency in currencies"
                :key="currency.id"
                eager
            >
                <DataGrid
                    ref="gridRef"
                    bulk-operations
                    :getData="currencyUseGetData[currency.unit]"
                    :headers="headers"
                    :noDataText="caseHasAddressesInAnyCurrency ? `No ${currency.name} Addresses` : 'No Addresses'"
                    class="mt-2"
                    item_id="id"
                    :footer-props="{
                        'items-per-page-options': [10, 30, 50, -1],
                        'show-current-page': true,
                        'showFirstLastPage': true,
                    }"
                    hide-footer-if-no-data
                    hide-header-if-no-data
                    :computed-height="caseHasAddressesInAnyCurrency"

                >
                    <template
                        #header-right="{ getItems, refreshItems, removeSelection, patchActions, isAnyItemSelected, isDataPatched }">
                        <DataGridBulkDelete :disabled="!isAnyItemSelected" :get-items="getItems"
                                            :currencyUnit="currency.unit"
                                            :item-callback-async="deleteItems" title="Remove Addresses"
                                            @action-finished-event="() => {refreshItems(); removeSelection()}"/>
                    </template>
                    <template #item="{ item }">
                        <td>
                            <EntityLabel
                                act-as-link
                                :currency="item.crypto"
                                is-copyable
                                :entity="item.address"/>
                        </td>
                        <td style="text-align: center;vertical-align: middle;">
                            <BlockchainCryptoBadge
                                :crypto="item.crypto"
                                :iconWidth="24"
                            />
                        </td>
                        <td class="text-overflow">
                            <!-- todo FE add link to identity detail -->
                            <v-chip
                                v-for="identity in item.identities"
                                :key="identity.id"
                                small
                            >{{ identity.label }}
                            </v-chip>
                        </td>
                        <td class="text-overflow">
                            <v-layout row>
                                <AddressCategoryIcon
                                    v-for="cat in item.categories.slice(0, 2)"
                                    :key="cat.id"
                                    :color="cat.color"
                                    :iconItem="cat"
                                    :name="cat.name"
                                    class="mr-2"
                                    small
                                />
                                <BaseButtonShowMoreItems
                                    :items="item.categories"
                                    :shownItemsCount="2"
                                    buttonText="show all"
                                >
                                    <AddressCategoryIcon
                                        v-for="cat in item.categories"
                                        :key="cat.id"
                                        :color="cat.color"
                                        :iconItem="cat"
                                        :name="cat.name"
                                        class="mx-1"
                                        medium
                                    />
                                </BaseButtonShowMoreItems>
                            </v-layout>
                        </td>
                        <td class="text-overflow">
                            <TableItemsExpandable :items="item.owners" item_id="id" :shown-items-amount="1">
                                <template v-slot="{item, isInMenu}">
                                    <OwnerBadge :item="item" :class="{'mx-1 mb-1': isInMenu}"/>
                                </template>
                            </TableItemsExpandable>
                        </td>
                        <td class="text-overflow">{{ item.created_at | formatDateTime }}</td>
                    </template>
                </DataGrid>
            </v-tab-item>
        </v-tabs>
    </v-row>
</template>
