<template>
    <div class="mt-5">
        <InteractionsFilter :currency="currency" @search-funds-event="onSearch">
            <template #header>
                <HeaderLined style="max-width: 200px">
                    <span style="color: rgba(0, 0, 0, 0.6); font-weight: 400 ">
                        Filter
                    </span>
                </HeaderLined>
            </template>
        </InteractionsFilter>
        <HeaderLined class="mb-1 mx-6">
            <span style="color: rgba(0, 0, 0, 0.6); font-weight: 400 ">
                Result
            </span>
        </HeaderLined>
        <InteractionsResult :addressInteractionsData="currentInteractionsData"
                            :addressInteractionsLoading="currentInteractionsLoading"
                            :cluster-current="clusterCurrent"
                            :currency="currency"
                            :filter="filter"
        />
    </div>
</template>

<script>
import InteractionsFilter from "@/components/Interactions/InteractionsFilter.vue";
import HeaderLined from "@/components/common/HeaderLined.vue";
import {mapGetters} from "vuex";
import InteractionsResult from "@/components/Interactions/InteractionsResult.vue";

export default {
    name: "ClusterInteractions",
    components: {
        InteractionsResult, InteractionsFilter, HeaderLined,

    },
    inject: ["entityId"],
    computed: {
        ...mapGetters(["currency", "addressInteractionsData", "addressInteractionsLoading", "addressInteractionsHistoryFallback"]),
        clusterCurrent() {
            return this.entityId
        },
        currentInteractionsLoading() {
            return !!this.addressInteractionsLoading[this.clusterCurrent]
        },
        currentInteractionsData() {
            return this.addressInteractionsData[this.clusterCurrent]
        },
        filter() {
            return this.addressInteractionsHistoryFallback[this.clusterCurrent]
        },
    },
    beforeUpdate() {
        //Update visual slider (workaround)
        this.$emit("component-loaded-event");
    },
    methods: {
        onSearch(e) {
            this.$store.dispatch("loadAddressInteractions", {
                currency: this.currency.unit,
                filter: e,
                entityAddress: this.clusterCurrent
            })
        }
    }

}
</script>

<style scoped>

</style>