<script setup>

import store from "@/store";
import {computed} from "vue";
import {useVModelInput} from "@/components/common/Composables/useVModelnput";

const props = defineProps({
    value: {
        type: Object,
    }
})

const currencies = computed(() => store.getters.currencies ?? [])
const emit = defineEmits("input")

const pickedCrypto = useVModelInput(props, emit)

const textClass = "ml-3 textGrey cryptoPickerCurrencyText"
</script>

<template>
    <v-select
        v-model="pickedCrypto"
        :items="currencies"
        item-value="id"
        class="cryptoPickerCurrencyPicker"
        filled
        dense
        solo
        label="Default crypto"
        prepend-icon="mdi-currency-sign"
        return-object
        color="info"
        clear-icon="mdi-close-circle"
    >
        <template #selection="{ item }">
            <div class="d-flex align-center justify-start">
                <img :src="item.image" alt="currency" style="width: 20px;"/>
                <div :class="textClass">{{ item.name }}</div>
            </div>
        </template>
        <template #item="{ item }">
            <div class="d-flex align-center justify-start">
                <img :src="item.image" alt="currency" style="width: 20px;"/>
                <div :class="textClass">{{ item.name }}</div>
            </div>
        </template>
    </v-select>
</template>

<style lang="scss">
.border {
    border: 1px solid rgba(0, 0, 0, .12) !important;
    border-color: rgba(0, 0, 0, .12) !important;
}

.cryptoPickerCurrencyPicker {
    .v-input__slot {
        @extend .border !optional;
        box-shadow: none !important;
    }
}
</style>