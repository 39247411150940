var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      !_vm.hideExpand
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "mr-5",
                              attrs: {
                                depressed: false,
                                outlined: "",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("expand-event")
                                },
                              },
                            },
                            on
                          ),
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.expanded ? "Collapse" : "Expand") +
                                "\n                "
                            ),
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.expanded
                                      ? "mdi-arrow-collapse-vertical"
                                      : "mdi-arrow-expand-vertical"
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1154772854
              ),
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.expanded ? "Collapse" : "Expand") +
                  " all transactions\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideTimeline
        ? _c("v-switch", {
            staticClass: "mr-5 mt-0",
            attrs: { value: _vm.timelined, label: "Timeline" },
            on: {
              change: function ($event) {
                return _vm.$emit("timeline-expand-event")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }