<script setup>
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import NameValueFlex from "@/components/common/NameValueFlex.vue";
import {computed} from "vue";
import {DataState} from "@/components/common/types/data";

const props = defineProps({
    data: {
        type: Array,
        required: true
    },
    selectedCrypto: {
        required: true,
    }
})

const totalBalance = computed(() => {
    let totalBalanceValue = 0

    if(props.data) {
        props.data.forEach(address => {
            if (address.addressData.dataState === DataState.Loaded) {
                totalBalanceValue = totalBalanceValue + address.addressData.data.data.confirmedBalance
                return totalBalanceValue
            }
        })
    }
    return totalBalanceValue
})

const unusedAddresses = computed(() => {
    return props.data.filter(address => {
        if (address.addressData.dataState === DataState.Loaded && address.addressData.data.data.confirmedTransactions === 0) {
            return address
        }
    })
})

const usedAddresses = computed(() => {
    return props.data.filter(address => {
        if (address.addressData.dataState === DataState.Loaded && address.addressData.data.data.confirmedTransactions > 0) {
            return address
        }
    })
})

</script>

<template>
    <div>
        <h3>
            Address Summary
        </h3>
        <FancyCard style="min-width: 300px" dense :header="false">
            <template #body>
                <NameValueFlex name-width="150">
                    <template #name>
                        Total Balance:
                    </template>
                    <template #value>
                        <v-chip
                            :color="totalBalance > 0 ? selectedCrypto.hexColor : '#eeeeee'"
                            style="height: 26px"
                            label
                            text-color="white"
                        >
                            <span class="subtitle-1 font-weight-bold" style="vertical-align: middle">
                                {{ totalBalance }}
                            </span>
                            <span class="subtitle-1 pl-1 text--white" style="vertical-align: middle">
                                {{ selectedCrypto.unit }}
                            </span>
                        </v-chip>
                    </template>
                </NameValueFlex>
                <NameValueFlex name-width="200">
                    <template #name>
                        Number of Unused Addresses:
                    </template>
                    <template #value>
                        {{ unusedAddresses.length }}
                    </template>
                </NameValueFlex>
                <NameValueFlex name-width="200">
                    <template #name>
                        Number of Used Addresses:
                    </template>
                    <template #value>
                        <v-chip
                            :color="usedAddresses.length > 0 ? selectedCrypto.hexColor : '#eeeeee'"
                            style="height: 26px"
                            label
                            text-color="white"
                        >
                            <span class="subtitle-1 font-weight-bold" style="vertical-align: middle">
                                {{ usedAddresses.length }}
                            </span>
                        </v-chip>
                    </template>
                </NameValueFlex>
            </template>
        </FancyCard>
    </div>
</template>

<style scoped>

</style>