var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "ml-1",
                    staticStyle: { height: "24px", width: "24px" },
                    attrs: { left: "" },
                  },
                  [_vm._v("mdi-magnify")]
                ),
                _vm._v(" "),
                _c("span", [_vm._v("Cluster Details")]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("v-expand-transition", [
              _setup.computedClusterDetails === null && !_setup.loading
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ hover }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled:
                                          _setup.loading ||
                                          _setup.data !== null,
                                        small: "",
                                        outlined: "",
                                        depressed: false,
                                      },
                                      on: { click: _setup.getClusterDetails },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "d-inline-block" },
                                        [
                                          _vm._v(
                                            "\n                            Load Details\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v("mdi-plus"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          757016334
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("v-expand-transition", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _setup.data !== null || _setup.loading,
                      expression: "data !== null || loading",
                    },
                  ],
                },
                [
                  _c(_setup.NameValueFlex, {
                    attrs: {
                      loading: _setup.loading,
                      "loading-width": "150",
                      "name-width": "70",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "name",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "font-weight-light subtitle-1",
                                staticStyle: { "font-size": "16px" },
                              },
                              [
                                _vm._v(
                                  "\n                                        Balance:\n                                    "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "value",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticStyle: { height: "26px" },
                                    attrs: {
                                      color: _setup.currency.unit,
                                      label: "",
                                      "text-color": "white",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "subtitle-1 font-weight-bold",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [
                                        _setup.data
                                          ? _c(_setup.FiatConvertor, {
                                              attrs: {
                                                value: _setup.data.balance,
                                                fiats: _setup.data.fiats,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "subtitle-1 pl-1 text--white",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [
                                        _c(_setup.FiatConvertor, {
                                          attrs: { "unit-only": "" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "iconRight",
                                    attrs: { small: "" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                mdi-credit-card\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(_setup.NameValueFlex, {
                    attrs: {
                      loading: _setup.loading,
                      "loading-width": "100",
                      "name-width": "70",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "name",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-light" }, [
                              _vm._v(
                                "\n                            Received:\n                        "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "value",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticStyle: { height: "24px" },
                                    attrs: {
                                      color: "success",
                                      label: "",
                                      "text-color": "white",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "subtitle-2 font-weight-bold",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [
                                        _setup.data
                                          ? _c(_setup.FiatConvertor, {
                                              attrs: {
                                                value: _setup.data.received,
                                                fiats: _setup.data.fiats,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "subtitle-2 pl-1 text--white font-weight-light",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [
                                        _c(_setup.FiatConvertor, {
                                          attrs: { "unit-only": "" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "iconRight",
                                    attrs: { small: "" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                mdi-download mdi-rotate-270\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(_setup.NameValueFlex, {
                    attrs: {
                      loading: _setup.loading,
                      "loading-width": "100",
                      "name-width": "70",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "name",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-light" }, [
                              _vm._v(
                                "\n                            Sent:\n                        "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "value",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticStyle: { height: "24px" },
                                    attrs: {
                                      color: "red",
                                      label: "",
                                      "text-color": "white",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "subtitle-2 font-weight-bold",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [
                                        _setup.data
                                          ? _c(_setup.FiatConvertor, {
                                              attrs: {
                                                value: _setup.data.send,
                                                fiats: _setup.data.fiats,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "subtitle-2 pl-1 text--white font-weight-light",
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                      },
                                      [
                                        _c(_setup.FiatConvertor, {
                                          attrs: { "unit-only": "" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "iconRight",
                                    attrs: { small: "" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                mdi-upload mdi-rotate-90\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("v-divider", { attrs: { light: "" } }),
                  _vm._v(" "),
                  _c(_setup.NameValueFlex, {
                    attrs: { loading: _setup.loading, "loading-width": "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "name",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-light" }, [
                              _vm._v(
                                "\n                            First Deposit:\n                        "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "value",
                        fn: function () {
                          return [
                            _setup.data
                              ? _c(
                                  "span",
                                  [
                                    _c(_setup.RouteToTransaction, {
                                      attrs: {
                                        transaction:
                                          _setup.computedClusterDetails
                                            .firstDepositTransaction,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm._f("moment")(
                                                        _setup
                                                          .computedClusterDetails
                                                          .firstDepositBlocktime,
                                                        "YYYY-MM-DD HH:mm:ss Z"
                                                      )
                                                    ) +
                                                    " (" +
                                                    _vm._s(
                                                      _setup
                                                        .computedClusterDetails
                                                        .firstDepositBlocktimeRel
                                                    ) +
                                                    ")\n                                "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3836749605
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(_setup.NameValueFlex, {
                    attrs: { loading: _setup.loading, "loading-width": "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "name",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-light" }, [
                              _vm._v(
                                "\n                            Last Deposit:\n                        "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "value",
                        fn: function () {
                          return [
                            _setup.data
                              ? _c(
                                  "span",
                                  [
                                    _c(_setup.RouteToTransaction, {
                                      attrs: {
                                        transaction:
                                          _setup.computedClusterDetails
                                            .lastDepositTransaction,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm._f("moment")(
                                                        _setup
                                                          .computedClusterDetails
                                                          .lastDepositBlocktime,
                                                        "YYYY-MM-DD HH:mm:ss Z"
                                                      )
                                                    ) +
                                                    " (" +
                                                    _vm._s(
                                                      _setup
                                                        .computedClusterDetails
                                                        .lastDepositBlocktimeRel
                                                    ) +
                                                    ")\n                                "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1662235077
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(_setup.NameValueFlex, {
                    attrs: { loading: _setup.loading, "loading-width": "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "name",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-light" }, [
                              _vm._v(
                                "\n                            First Withdraw:\n                        "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "value",
                        fn: function () {
                          return [
                            _setup.data
                              ? _c(
                                  "span",
                                  [
                                    _c(_setup.RouteToTransaction, {
                                      attrs: {
                                        transaction:
                                          _setup.computedClusterDetails
                                            .firstWithdrawTransaction,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm._f("moment")(
                                                        _setup
                                                          .computedClusterDetails
                                                          .firstWithdrawBlocktime,
                                                        "YYYY-MM-DD HH:mm:ss Z"
                                                      )
                                                    ) +
                                                    " (" +
                                                    _vm._s(
                                                      _setup
                                                        .computedClusterDetails
                                                        .firstWithdrawBlocktimeRel
                                                    ) +
                                                    ")\n                                "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3261782661
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(_setup.NameValueFlex, {
                    attrs: { loading: _setup.loading, "loading-width": "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "name",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-light" }, [
                              _vm._v(
                                "\n                            Last Withdraw:\n                        "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "value",
                        fn: function () {
                          return [
                            _setup.data
                              ? _c(
                                  "span",
                                  [
                                    _c(_setup.RouteToTransaction, {
                                      attrs: {
                                        transaction:
                                          _setup.computedClusterDetails
                                            .lastWithdrawTransaction,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm._f("moment")(
                                                        _setup
                                                          .computedClusterDetails
                                                          .lastWithdrawBlocktime,
                                                        "YYYY-MM-DD HH:mm:ss Z"
                                                      )
                                                    ) +
                                                    " (" +
                                                    _vm._s(
                                                      _setup
                                                        .computedClusterDetails
                                                        .lastWithdrawBlocktimeRel
                                                    ) +
                                                    ")\n                                "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1779547557
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }