<template>
    <div class="transactionContainer">
        <v-row class="my-2">
            <v-col cols="10" offset="1">
                <v-pagination
                    v-if="!hideTopPagination"
                    :length="pagesTotal"
                    :total-visible="hideTotal ? 0 : 20"
                    :value="value"
                    @input="$emit('input', $event)"
                />
            </v-col>
        </v-row>

        <ProgressCircular v-if="!dataLoaded" :color="currency.unit" class="mt-2"/>
        <slot v-if="dataLoaded" name="content"/>

        <v-row v-if="dataLoaded" class="my-2">
            <v-col cols="10" offset="1">
                <v-pagination
                    :length="pagesTotal"
                    :total-visible="hideTotal ? 0 : 20"
                    :value="value"
                    @input="$emit('input', $event)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import ProgressCircular from "@/components/common/ProgressCircular"

export default {
    name: "PaginationWrapper",
    components: {
        ProgressCircular
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        pagesTotal: {
            type: Number,
        },
        value: {
            type: Number,
        },
        hideTopPagination: {
            type: Boolean,
            required: false,
            default: false
        },
        hideTotal: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        ...mapGetters(["currency"]),
        dataLoaded() {
            return !this.loading
        },
    },
}
</script>
