var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-4", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "" } },
        [
          _c("h1", [_vm._v("Owners")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("OwnerDetailModal", {
            on: { "save-event": _vm.createOwner },
            scopedSlots: _vm._u([
              {
                key: "showbutton",
                fn: function ({ on }) {
                  return [
                    _c(
                      "CreateButton",
                      _vm._g({ attrs: { text: "Add new Owner" } }, on)
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-0 border my-2",
        attrs: {
          headers: _vm.headers,
          items: _vm.computedOwners,
          options: _vm.filter,
          loading: _vm.isOwnersLoading,
          "server-items-length": _vm.owners.total ? _vm.owners.total : 0,
          "footer-props": {
            "items-per-page-options": [10, 30, 50, 100],
            "show-current-page": true,
            showFirstLastPage: true,
          },
          dense: "",
        },
        on: {
          "update:options": function ($event) {
            _vm.filter = $event
          },
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.headers, function (h) {
              return {
                key: `header.${h.value}`,
                fn: function ({ header }) {
                  return [
                    h.value === "name"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          attrs: {
                            "append-icon": "search",
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterName,
                            callback: function ($$v) {
                              _vm.$set(_vm.debouncedFilter, "filterName", $$v)
                            },
                            expression: "debouncedFilter.filterName",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "neo_cluster_count"
                      ? _c("div", { key: h.value }, [
                          _c("h3", [_vm._v(_vm._s(h.text))]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "cospent_cluster_count"
                      ? _c("div", { key: h.value }, [
                          _c("h3", [_vm._v(_vm._s(h.text))]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "custom_cluster_count"
                      ? _c("div", { key: h.value }, [
                          _c("h3", [_vm._v(_vm._s(h.text))]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "created_at"
                      ? _c(
                          "div",
                          { key: h.value },
                          [
                            _c("DateTimePicker", {
                              attrs: { label: h.text, disabledTime: true },
                              model: {
                                value: _vm.filter.filterCreatedAt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "filterCreatedAt", $$v)
                                },
                                expression: "filter.filterCreatedAt",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "action"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: { "font-size": "14px" },
                          },
                          [_vm._v(_vm._s(h.text))]
                        )
                      : _vm._e(),
                  ]
                },
              }
            }),
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _c("tr", [
                    _c("td", { staticClass: "text-left fontMonospace" }, [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center text-right" },
                        [
                          _c("div", { staticStyle: { "min-width": "80px" } }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatNumber")(item.neo_cluster_count)
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center text-right" },
                        [
                          _c("div", { staticStyle: { "min-width": "120px" } }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatNumber")(
                                  item.cospent_cluster_count
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center text-right" },
                        [
                          _c("div", { staticStyle: { "min-width": "110px" } }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatNumber")(
                                  item.custom_cluster_count
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticStyle: { "text-align": "center" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("formatDateTime")(item.created_at))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "vertical-align": "middle",
                          "min-width": "92px",
                        },
                      },
                      [
                        _c("OwnerDetailModal", {
                          attrs: {
                            editButtonTooltip: "Edit Owner",
                            ownerDetail: item,
                          },
                          on: { "save-event": _vm.editOwner },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "showbutton",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "TableEditButton",
                                      _vm._g(
                                        {
                                          attrs: {
                                            editButtonTooltip: "Edit Owner",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("TableDeleteModalDialog", {
                          attrs: {
                            width: "40%",
                            description: `Do you really want to delete this owner?`,
                            itemToDelete: item,
                            tooltip: "Delete Owner",
                          },
                          on: { "delete-event": _vm.deleteOwner },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
            {
              key: "footer.page-text",
              fn: function ({ pageStart, pageStop, itemsLength }) {
                return [
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("formatNumber")(pageStart)) +
                        " - " +
                        _vm._s(_vm._f("formatNumber")(pageStop)) +
                        " of " +
                        _vm._s(_vm._f("formatNumber")(itemsLength)) +
                        "\n            "
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }