<script setup>
import TransactionsHeader from "@/components/transaction/Components/TransactionsHeader.vue";
import PaginationWrapper from "@/components/common/PaginationWrapper.vue";
import ProgressCircular from "@/components/common/ProgressCircular.vue";
import TransactionSingle from "@/components/transaction/TransactionSingle.vue";
import {computed, ref} from "vue";
import store from "@/store";
import TransactionsControlPanel from "@/components/transaction/Components/TransactionsControlPanel.vue";
import {DataState} from "@/components/common/types/data";
import UseGEStore from "@/views/GraphExplorer/Composables/UseGEStore";
import {humanTime} from "@/utils";

const props = defineProps({
    pageDefault: {
        type: Number,
        required: false
    },
    itemsPerPageDefault: {
        type: Number,
        required: false
    },
    getData: {
        type: Object,
        required: true
    },
    hideTopPagination: {
        type: Boolean,
        required: false,
        default: false,
    },
    hideExpand: {
        type: Boolean,
        required: false,
        default: false,
    },
    hideTimeline: {
        type: Boolean,
        required: false,
        default: false,
    },
    txMapFunction: {
        type: Function,
        required: true
    },
    hideTotal: {
        type: Boolean,
        required: false,
        default: false
    },
    selectableTransactions: {
        type: Boolean,
        required: false
    }
})

//STORE
const currency = computed(() => store.getters.currency)


const page = ref(props.pageDefault ?? 1)
const itemsPerPage = ref(props.itemsPerPageDefault ?? 10)

store.dispatch('loadCategories')

const {dataState, data, getData} = props.getData

const loading = computed(() => dataState.value === DataState.Loading)

let foundLastPage
const maxPageVal = 999
const totalPages = computed(() => {
    if (!data.value) {
        return undefined
    }
    if (data.value.last_page) {
        return data.value.last_page
    }
    setPageAndMaybeResetFoundLastPage(data.value.current_page)
    if (foundLastPage) {
        return foundLastPage
    }
    if (data.value.data.length < itemsPerPage.value) {
        foundLastPage = data.value.data.length === 0 ? page.value - 1 : page.value
        return foundLastPage
    }
    return maxPageVal
})
const transactions = computed(() => {
    if (!data.value?.data) {
        return []
    }

    if (props.txMapFunction) {
        return data.value.data.map(props.txMapFunction)
    }

    return data.value.data
})

const setPageAndMaybeResetFoundLastPage = (pageNumber) => {
    page.value = pageNumber
    if (pageNumber === 1) {
        foundLastPage = undefined
    }
}
const getTxs = () => {
    getData({
        pagination: {page: page.value, itemsPerPage: itemsPerPage.value}
    })
}
const handleManualPaginationChange = (newPageNumber) => {
    page.value = newPageNumber
    getTxs()
}

const expanded = ref(false)
const timeline = ref(false)

const resetState = () => {
    page.value = 1
    data.value = undefined
    dataState.value = DataState.Initial
}
defineExpose({
    resetState
})

const emit = defineEmits('selectedTransactionsChangedEvent')

const {GETransactions} = UseGEStore()

const getHumanTime = (t0, t1) => {
    //swap
    if (t0 > t1)
        [t1, t0] = [t0, t1];

    return humanTime(t1 - t0)
}

</script>

<template>
    <div>
        <v-row justify="end" no-gutters>
            <v-col class="d-flex" cols="auto">
                <TransactionsControlPanel
                    :expanded="expanded"
                    :hide-expand="hideExpand"
                    :hide-timeline="hideTimeline"
                    :timelined="timeline"
                    @expand-event="expanded = !expanded"
                    @timeline-expand-event="timeline = !timeline"/>
            </v-col>
            <v-spacer/>
            <v-col cols="auto">
                <slot name="export"/>
            </v-col>
        </v-row>
        <PaginationWrapper
            v-if="loading || (transactions?.length > 0) || page !== 1"
            :hide-total="totalPages === maxPageVal || hideTotal"
            :hideTopPagination="hideTopPagination"
            :loading="loading"
            :pages-total="totalPages ? totalPages : 0"
            :value="page"
            @input="handleManualPaginationChange"
        >
            <template v-slot:content>
                <div v-if="loading">
                    <ProgressCircular
                        :color="currency.unit"
                    />
                </div>
                <TransactionsHeader no-change/>
                <v-divider/>
                <div v-for="(transaction, index) in transactions" :key="transaction.id">
                    <slot name="prependTransaction" v-bind="{ transaction }"/>
                    <v-expand-transition v-if="!hideTimeline">
                        <div v-if="timeline && index !== 0 && transactions[index-1].time !== transaction.time"
                             class="d-flex justify-end align" style="margin-right: 105px">
                            <div class="d-flex justify-start align-center" style="width: 150px">
                                <v-icon left>
                                    mdi-sort-clock-ascending-outline
                                </v-icon>
                                <span class="font-weight-light" style="color: rgba(0,0,0,0.6);">
                                    <nobr>
                                        {{
                                            `${getHumanTime(transactions[index - 1].time * 1000, transaction.time * 1000)}
                                         | ${transactions[index - 1].blockHeight - transaction.blockHeight} Blocks`
                                        }}
                                    </nobr>
                                </span>
                            </div>
                        </div>
                    </v-expand-transition>
                    <div class="d-flex align-start">
                        <v-checkbox
                            v-if="selectableTransactions"
                            @change="emit('selectedTransactionsChangedEvent', $event, transaction)"
                            dense
                            color="info"
                            style=" margin: 8px 0 0 8px; height: 26px"
                            :input-value="GETransactions[transaction.txhash]"
                        />
                        <TransactionSingle
                            :key="transaction.txhash"
                            :expanded="expanded"
                            :transaction="transaction"
                            class="mt-1 mb-1 flex-grow-1"
                        />
                    </div>
                </div>
                <div v-if="transactions?.length === 0 && !loading" class="title mt-4 font-weight-light text-center">
                    <slot name="noDataText">
                        No transactions found
                    </slot>
                </div>
            </template>
        </PaginationWrapper>
        <div v-else class="title mt-4 font-weight-light text-center">
            <slot name="noDataText">
                No transactions found
            </slot>
        </div>
    </div>
</template>

<style scoped>

</style>