import apiClient from "@/services/apiClient"
import mappers from "models/ClusterModels"
import axios from "axios";

import {modalState} from "@/components/common/types/modal";


const state = {
    clusterMetadata: {
        data: []
    },
    clusterMetadataLoading: false,
    clusterMetadataDetail: undefined,
    isClusterMetadataDetailLoading: false,
    clusterMetadataModalState: modalState.Initial,
    clusterMetadataCancelToken: null
}

const getters = {
    clusterMetadata: (state) => state.clusterMetadata,
    clusterMetadataLoading: (state) => state.clusterMetadataLoading,
    clusterMetadataDetail: (state) => state.clusterMetadataDetail,
    isClusterMetadataDetailLoading: (state) => state.isClusterMetadataDetailLoading,
    clusterMetadataModalState: (state) => state.clusterMetadataModalState,
}

const mutations = {
    CLUSTER_METADATA_START_LOADING(state) {
        if (state.clusterMetadataCancelToken) {
            state.clusterMetadataCancelToken.cancel()
        }
        state.clusterMetadataCancelToken = axios.CancelToken.source()
        state.clusterMetadataLoading = true
    },
    CLUSTER_METADATA_LOADED(state, data) {
        state.clusterMetadata = data
        state.clusterMetadataCancelToken = null
    },
    CLUSTER_METADATA_ERROR(state, data) {
        state.clusterMetadata = data
    },
    CLUSTER_METADATA_FINISH_LOADING(state) {
        state.clusterMetadataLoading = false
    },
    CLUSTER_METADATA_DETAIL_START_LOADING(state, data) {
        state.isClusterMetadataDetailLoading = true
    },
    CLUSTER_METADATA_DETAIL_LOADED(state, {detail}) {
        state.clusterMetadataDetail = detail
    },
    CLUSTER_METADATA_DETAIL_FINISH_LOADING(state, data) {
        state.isClusterMetadataDetailLoading = false
    },
    CLUSTER_METADATA_MODAL_SET_STATE(state, data) {
        state.clusterMetadataModalState = data
    }
}

const actions = {
    async loadClusterMetadata({commit}, {currency, pagination}) {
        commit('CLUSTER_METADATA_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/clusters`, {
                params: {...pagination},
                cancelToken: state.clusterMetadataCancelToken.token
            })
            this.dispatch('success', response.message)
            commit('CLUSTER_METADATA_LOADED', response)
            commit('CLUSTER_METADATA_FINISH_LOADING')
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                this.dispatch('error', error.userFriendlyMessage)
                commit('CLUSTER_METADATA_ERROR', {data: [], total: 0})
                commit('CLUSTER_METADATA_FINISH_LOADING')
            }
        }
    },
    async createClusterMetadata({commit}, {currency, newClusterMetadata}) {
        commit('CLUSTER_METADATA_START_LOADING')
        commit('CLUSTER_METADATA_MODAL_SET_STATE', modalState.Pending)
        try {
            const serverModel = mappers.MapToServerModel(newClusterMetadata)
            const response = await apiClient.POST(`${currency}/clusters`, serverModel);
            this.dispatch('success', response.message)
            commit('CLUSTER_METADATA_MODAL_SET_STATE', modalState.Success)
        } catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
            commit('CLUSTER_METADATA_MODAL_SET_STATE', modalState.Error)
        }
    },
    async updateClusterMetadata({commit}, {currency, updatedClusterMetadata}) {
        commit('CLUSTER_METADATA_START_LOADING')
        try {
            const clusterMetadataDetail = mappers.MapToServerModel(updatedClusterMetadata)
            const response = await apiClient.PUT(`${currency}/clusters/${updatedClusterMetadata.id}`, clusterMetadataDetail);
            this.dispatch('success', response.message)
        } catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
        }
    },

    //PATCH
    async patchClusterMetadata({commit}, {currency, patch}) {
        commit('CLUSTER_METADATA_START_LOADING')

        commit('CLUSTER_METADATA_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await apiClient.PATCH(`${currency}/clusters/${patch.id}`, patch.body);
            this.dispatch('success', response.message)
            commit('CLUSTER_METADATA_MODAL_SET_STATE', modalState.Success)
        } catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
            commit('CLUSTER_METADATA_MODAL_SET_STATE', modalState.Error)
        }

    },

    async deleteClusterMetadata({commit}, {currency, clusterMetadataId, silent}) {
        try {
            const response = await apiClient.DELETE(`${currency}/clusters/${clusterMetadataId}`)
            if (response && !silent) {
                this.dispatch('success', response.message)
            }
        } catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
        }

    },
    async loadClusterMetadataDetail({commit}, {currency, id, pagination}) {
        commit('CLUSTER_METADATA_DETAIL_START_LOADING')
        try {
            let response = await apiClient.GET(`${currency}/clusters/${id}`, {params: pagination})
            response = mappers.MapToViewModel(response)
            commit('CLUSTER_METADATA_DETAIL_LOADED', {detail: response})
        } catch (error) {
            this.dispatch('error', error.userFriendlyMessage)
        } finally {
            commit('CLUSTER_METADATA_DETAIL_FINISH_LOADING')
        }
    },

    async customClusters({commit}, {currency, pagination, cancelToken}) {
        let response
        try {
            response = await apiClient.GET(`${currency}/clusters`, {
                params: {...pagination},
                cancelToken: cancelToken
            })
            return response
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                console.error(error)
                this.dispatch('error', error.userFriendlyMessage)
                throw error
            }
        }

    },

    async neoClusters({commit}, {currency, pagination, cancelToken}) {
        let response
        try {
            response = await apiClient.GET(`${currency}/neoclusters`, {
                params: {...pagination},
                cancelToken: cancelToken
            })
            response = mappers.MapToCustomClusterFromNeoClusters(response.data)
            return response
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                console.error(error)
                this.dispatch('error', error.userFriendlyMessage)
                throw error
            }
        }

    },

    async clustersByAddress({commit}, {currency, addressId, cancelToken}) {
        let response
        try {
            response = await apiClient.GET(`${currency}/cryptoaddress/${addressId}/cryptoclusters`,
                {
                    cancelToken: cancelToken
                })
            response = mappers.MapToCustomClusterFromCryptoClusters(response.local_clusters)
            return response
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                return []
            }
        }
    },
    async mergeToCluster({commit}, {currency, clusterToMergeTo, clustersToMergeWith}) {
        try {
            await apiClient.PUT(`${currency}/cryptocluster/${clusterToMergeTo}/merge`, {entityIds: clustersToMergeWith});
            return true
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                this.dispatch('error', error)
                return false
            }
        }
    },
    async copyCluster({commit, dispatch}, {currency, clusterMetadata}) {
        try {
            const serverModelClusterMetadata = mappers.MapToServerModel(clusterMetadata)
            await apiClient.POST(`${currency}/clusters`, serverModelClusterMetadata,);
            return true
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                this.dispatch('error', error)
                return false
            }
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
}