var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.innerValue, function ({ id, val }) {
      return _c(
        "div",
        { key: id },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-space-between my-0" },
            [
              _c(
                "div",
                {
                  staticClass: "fontMonospace",
                  staticStyle: {
                    "white-space": "nowrap",
                    overflow: "hidden",
                    "text-overflow": "ellipsis",
                  },
                },
                [_vm._v("\n                " + _vm._s(val) + "\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", color: "red" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteItem(id)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }