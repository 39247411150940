import {getIconStringWithOrigin} from "@/utils/filters";

export default function (cytoscape) {
    //cytoscape.use(Layers);

    const nodeHtmlLabel = require('cytoscape-node-html-label');
    nodeHtmlLabel(cytoscape); // register extension
    cytoscape.use(require('cytoscape-dom-node'));

    let cy

    const eleCache = new Map()
    const removeItemFromCacheById = (itemId) => {
        eleCache.delete(itemId)
        cy.$id(itemId).addClass("dummy")
        cy.$id(itemId).removeClass("dummy")
    }

    const createCategoryIcon = (categoryObject) => `
            <div class="category-container border" style="background-color: ${categoryObject.color};">
              <i class="v-icon notranslate material-icons theme--light ${getIconStringWithOrigin(categoryObject.icon)}" style="color: ${categoryObject.secondaryColor}; font-size: 20px"/>
              </i>     
            </div>
        `
    const createAddressLabel = (addressData) => {
        if (addressData.label) {
            return addressData.label
        }
        const address = addressData.addr ?? addressData.address
        return `${address.substring(0, 4)}…${address.slice(-3)}`
    }

    const createAddressLayer = (nodeId, addressData) => {
        const base = `
            <div>
                <div class="d-flex justify-center width100 align-center mt-2">
                    <div style="width: 28px; height: 28px">
                        ${addressData.categories[0] ? createCategoryIcon(addressData.categories[0]) : ''}
                    </div>
                    <div style="width: 24px">
                    </div>
                    <div style="width: 28px; height: 28px">
                    </div>
                </div>
                <div class="text-center fontMonospace">
                    ${createAddressLabel(addressData)}
                </div>
            </div>
        `
        eleCache.set(nodeId, base)
        return base
    }

    const createTransactionLayer = (nodeId, data) => {
        const base = `
            <div class="d-flex justify-center width100 align-center mt-3 fontMonospace" style="font-size: 12px">
                <div style="width: 42px; height: 12px" class="d-flex align-center justify-end">
                   <span style="background-color: white; border-radius: 4px;">
                        ${data.inputsExpanded ? '' : '<i class="v-icon mdi mdi-plus-circle theme--light success--text" style="font-size: 12px"></i>'}                    
                        ${data.typeSpecific.inputs.length}
                    </span>
                </div>
                :
                <div style="width: 42px; height: 12px" class="d-flex align-center justify-start">
                    <span style="background-color: white; border-radius: 4px;">
                        ${data.typeSpecific.outputs.length}
                        ${data.outputsExpanded ? '' : '<i class="v-icon mdi mdi-plus-circle theme--light success--text" style="font-size: 12px"></i>'}
                    </span>
                </div>
            </div>
        `
        eleCache.set(nodeId, base)
        return base
    }


    const Activate = (cyInstance) => {
        cy = cyInstance
        cy.domNode()
        cy.nodeHtmlLabel([
                {
                    query: '.address', // cytoscape query selector
                    halign: 'center', // title vertical position. Can be 'left',''center, 'right'
                    valign: 'bottom', // title vertical position. Can be 'top',''center, 'bottom'
                    halignBox: 'center', // title vertical position. Can be 'left',''center, 'right'
                    valignBox: 'center', // title relative box vertical position. Can be 'top',''center, 'bottom'
                    cssClass: '', // any classes will be as attribute of <div> container for every title
                    tpl: function (data) {
                        const eleData = eleCache.get(data.id)
                        if (eleData) {
                            return eleData
                        }
                        return createAddressLayer(data.id, data.typeSpecific.addressData)
                    }
                },
                // {
                //     query: '.transaction', // cytoscape query selector
                //     halign: 'center', // title vertical position. Can be 'left',''center, 'right'
                //     valign: 'bottom', // title vertical position. Can be 'top',''center, 'bottom'
                //     halignBox: 'center', // title vertical position. Can be 'left',''center, 'right'
                //     valignBox: 'center', // title relative box vertical position. Can be 'top',''center, 'bottom'
                //     cssClass: '', // any classes will be as attribute of <div> container for every title
                //     tpl: function (data) {
                //         const eleData = eleCache.get(data.id)
                //         if (eleData) {
                //             return eleData
                //         }
                //         return createTransactionLayer(data.id, data)
                //     }
                // },
                {
                    query: '.merge', // cytoscape query selector
                    halign: 'center', // title vertical position. Can be 'left',''center, 'right'
                    valign: 'top', // title vertical position. Can be 'top',''center, 'bottom'
                    halignBox: 'center', // title vertical position. Can be 'left',''center, 'right'
                    valignBox: 'center', // title relative box vertical position. Can be 'top',''center, 'bottom'
                    cssClass: '', // any classes will be as attribute of <div> container for every title
                    tpl: function (data) {
                        // return `<div class="fontMonospace mt-n6">${getCustomLabelById(data.id)}</div>`
                        // return `<div class="fontMonospace mt-n6">${getCustomLabelById(data.id)}</div>`
                        return `<div class="fontMonospace mt-n6">${data.customLabel ?? 'Custom node'}</div>`
                    }
                },
                {
                    // query: '.note', // cytoscape query selector
                    // halign: 'center', // title vertical position. Can be 'left',''center, 'right'
                    // valign: 'top', // title vertical position. Can be 'top',''center, 'bottom'
                    // halignBox: 'center', // title vertical position. Can be 'left',''center, 'right'
                    // valignBox: 'center', // title relative box vertical position. Can be 'top',''center, 'bottom'
                    // cssClass: '', // any classes will be as attribute of <div> container for every title
                    // // tpl: function (data) {
                    // //     return `<div class="fontMonospace mt-n6 d-flex justify-center"><div class="noteContent" style="position: absolute;white-space: pre-wrap;bottom:6px;"
                    // //         onclick="((e) => {
                    // //               console.log('onClick',e)
                    // //               // e.stopPropagation();
                    // //               // e.preventDefault();
                    // //         })()"
                    // //         onmousedown="(() => {
                    // //               // e.stopPropagation();
                    // //               // e.preventDefault();
                    // //             const event = new CustomEvent('onMouseDownOnNote',{ detail: '${data.id}', bubbles: true });
                    // //             dispatchEvent(event)
                    // //         })()"
                    // //         onmouseup="(() => {
                    // //               // e.stopPropagation();
                    // //               // e.preventDefault();
                    // //             const event = new CustomEvent('onMouseUpOnNote',{ detail: '${data.id}', bubbles: true });
                    // //             dispatchEvent(event)
                    // //         })()">${data.text}</div></div>`
                    // // }
                    // tpl: function (data) {
                    //     return `<div class="fontMonospace mt-n6 d-flex justify-center"><div class="noteContent" style="position: absolute;white-space: pre-wrap;bottom:6px;" id="${data.id}">${data.text}</div></div>`
                    // }
                }
            ],
            {
                enablePointerEvents: true
            }
        );
    }


    // tpl: function (data) {
    //     return `<div class="fontMonospace mt-n6 d-flex justify-center"><div class="noteContent" style="position: absolute;white-space: pre-wrap;bottom:6px;"
    //                     onclick="((e) => {
    //                           console.log(e)
    //                           e.stopPropagation();
    //                           e.preventDefault();
    //                     })()"
    //                     onmousedown="(() => {
    //                           // e.stopPropagation();
    //                           // e.preventDefault();
    //                         const event = new CustomEvent('onMouseDownOnNote',{ detail: '${data.id}', bubbles: true });
    //                         dispatchEvent(event)
    //                     })()"
    //                     onmouseup="(() => {
    //                           // e.stopPropagation();
    //                           // e.preventDefault();
    //                         const event = new CustomEvent('onMouseUpOnNote',{ detail: '${data.id}', bubbles: true });
    //                         dispatchEvent(event)
    //                     })()">${data.text}</div></div>`
    // }

    return {Activate, removeItemFromCacheById}
}