<script setup>

import BaseFilterActivatorIcon from "@/components/Filters/BaseFilterActivatorIcon.vue";
import {useAttrs} from "vue";

const props = defineProps({
    componentFilterType: {
        type: Object,
        required: true
    },
})

const attrs = useAttrs()
const emit = defineEmits(['input', 'on-close-event'])

</script>

<template>
    <component
        :is="props.componentFilterType"
        :componentFilterBase="BaseFilterActivatorIcon"
        v-bind="attrs"
        @input="emit('input', $event)"
    />
</template>
