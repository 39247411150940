<template>
    <span v-if="unitOnly">
        <span v-if="!fiatActive">
            {{ currency.unit }}</span>
        <span v-else
              :class="{ 'font-weight-light': unitLight, 'unitSmallFont': unitSmall }">{{ currentFiat.unit }}</span>
    </span>
    <span v-else-if="!fiatActive">
        <span v-if="convertFromSatoshi" :class="monospace ? 'fontMonospace' : ''">
            {{ $humanize.formatNumber(computedValue / 100000000, cryptoDecPlaces) }}</span>
        <span v-else :class="monospace ? 'fontMonospace' : ''">
            {{ $humanize.formatNumber(computedValue, cryptoDecPlaces) }}</span>
        <span v-if="withUnit"
              :class="{ 'font-weight-light': unitLight, 'unitSmallFont': unitSmall }">{{ fiats.crypto }}</span>
    </span>
    <span v-else>
        <span :class="monospace ? 'fontMonospace' : ''">
            {{ $humanize.formatNumber(calculatedFiat, fiatDecPlaces) }}</span>
        <span v-if="withUnit"
              :class="{ 'font-weight-light': unitLight, 'unitSmallFont': unitSmall }">{{ currentFiat.unit }}</span>
    </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "FiatConvertor",
    props: {
        absoluteValue: {
            required: false,
            type: Boolean,
            default: false
        },
        value: {
            required: false,
            type: Number,
        },
        fiats: {
            required: false,
            type: Object
        },
        fiatDecPlaces: {
            type: Number,
            required: false,
            default: 2
        },
        cryptoDecPlaces: {
            type: Number,
            required: false,
            default: 8
        },
        withUnit: {
            type: Boolean,
            required: false,
            default: false,
        },
        unitOnly: {
          type: Boolean,
          required: false,
          default: false
        },
        convertFromSatoshi: {
            type: Boolean,
            required: false,
            default: false,
        },
        monospace: {
            type: Boolean,
            required: false,
            default: false,
        },
        unitLight: {
            type: Boolean,
            required: false,
            default: false
        },
        unitSmall: {
            type: Boolean,
            required: false,
            default: false
        }

    },
    computed: {
        ...mapGetters(["currency","currentFiat", "fiatActive"]),
        computedValue()
        {
            if (!this.absoluteValue)
            {
                return this.value
            }
            return Math.abs(this.value)

        },
        calculatedFiat() {
            if (this.convertFromSatoshi)
                return (this.computedValue / 100000000) * this.fiats[this.currentFiat.unit]
            else
                return this.computedValue * this.fiats[this.currentFiat.unit]
        }
    }
}

</script>

<style scoped>
.unitSmallFont {
    font-size: 0.8em;
}
</style>