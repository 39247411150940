var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: {
      title: _vm.isEditModal
        ? "Edit address metadata"
        : "Create new address metadata",
      loading: _vm.loading,
      width: "60%",
    },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-2" },
                            [
                              _c("v-text-field-validateable", {
                                attrs: {
                                  disabled: _vm.isEditModal,
                                  rules: "required|crypto",
                                  label: "Address",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.detail.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "address", $$v)
                                  },
                                  expression: "detail.address",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "py-2" },
                            [
                              _c("BlockchainCryptoPicker", {
                                attrs: { currencies: _vm.currencies },
                                model: {
                                  value: _vm.detail.crypto,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "crypto", $$v)
                                  },
                                  expression: "detail.crypto",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "py-2" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: true,
                                  label: "Created At",
                                  value: _vm._f("moment")(
                                    _vm.detail.created_at,
                                    "YYYY-MM-DD HH:mm:ss Z"
                                  ),
                                  outlined: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "ma-2" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-2" },
                            [
                              _c("CategoryPicker", {
                                attrs: {
                                  dense: "",
                                  categories: _vm.categories,
                                },
                                model: {
                                  value: _vm.detail.categoryIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "categoryIds", $$v)
                                  },
                                  expression: "detail.categoryIds",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "py-2" },
                            [
                              _c("OwnerPickerWithDisplay", {
                                model: {
                                  value: _vm.detail.owners,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "owners", $$v)
                                  },
                                  expression: "detail.owners",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "mt-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c("CsvImporterInput", {
                                attrs: {
                                  csvConfiguration: _vm.csvConfiguration,
                                },
                                on: {
                                  "import-successful":
                                    _vm.importAddressIdentityPairs,
                                  "import-failed":
                                    _vm.importAddressIdentityPairsFailed,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { align: "center" } },
                            [
                              _vm.corruptedData.length > 0
                                ? _c("CorruptedDataModalDialog", {
                                    attrs: { corruptedData: _vm.corruptedData },
                                    on: {
                                      "close-event":
                                        _vm.closeCorruptedDataDialog,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "showbutton",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      color: "error",
                                                      outlined: "",
                                                    },
                                                  },
                                                  on
                                                ),
                                                [_vm._v("Show corrupted data")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2621415191
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "end" } },
                                [
                                  _c("CreateButton", {
                                    attrs: { text: "Add new identity" },
                                    on: { click: _vm.addNewIdentity },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-data-table", {
                        attrs: {
                          items: _vm.detail.identities,
                          "items-per-page": 5,
                          headers: _vm.identitiesHeaders,
                          "footer-props": {
                            "items-per-page-options": [5, 15, 50, 100],
                            "show-current-page": true,
                          },
                          dense: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function ({ item }) {
                              return [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "py-2",
                                      staticStyle: { width: "25%" },
                                    },
                                    [
                                      _c("v-text-field-validateable", {
                                        attrs: {
                                          rules: "required|max:255",
                                          label: "Label",
                                          "full-width": "",
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: item.label,
                                          callback: function ($$v) {
                                            _vm.$set(item, "label", $$v)
                                          },
                                          expression: "item.label",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "py-2",
                                      staticStyle: { width: "20%" },
                                    },
                                    [
                                      _c("v-text-field-validateable", {
                                        attrs: {
                                          rules: "required|max:255",
                                          label: "Source",
                                          "full-width": "",
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: item.source,
                                          callback: function ($$v) {
                                            _vm.$set(item, "source", $$v)
                                          },
                                          expression: "item.source",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "py-2" },
                                    [
                                      _c("v-text-field-validateable", {
                                        attrs: {
                                          rules: "required|max:1024",
                                          label: "Url",
                                          "full-width": "",
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: item.url,
                                          callback: function ($$v) {
                                            _vm.$set(item, "url", $$v)
                                          },
                                          expression: "item.url",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("TableDeleteModalDialog", {
                                        attrs: {
                                          itemToDelete: item,
                                          tooltip: "Delete identity",
                                        },
                                        on: {
                                          "delete-event": _vm.deleteIdentity,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    outlined: "",
                    color: "error",
                    disabled:
                      _vm.addressMetadataModalState === _vm.modalState.Pending,
                  },
                  on: { click: close },
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "success",
                    loading:
                      _vm.addressMetadataModalState === _vm.modalState.Pending,
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save(close, setDisabled)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isEditModal
                        ? "Save address metadata"
                        : "Create address metadata"
                    )
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }