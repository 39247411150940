<template>
    <div :style="outerStyle">
        <v-hover v-slot="{ hover }">
            <div class="wrapper">
                <v-tooltip top >
                    <template v-slot:activator="{ on, attr }">
                        <RouteToTransaction :transaction="text" width100 no-decoration icon-link>
                            <template slot="text">
                                <v-hover
                                    v-slot="{ hover: hover2 }">
                                    <div class="text-ellipsis-middle position-relative" v-on="on">
                                        <div class="fontMonospace"  v-bind="attr" style="position: relative; left: 1px">{{ transactionId[0] }}</div>
                                        <div class="fontMonospace" v-bind="attr" style="position: relative; right: 1px">{{ transactionId[1] }}</div>
<!--                                        <div v-if="hover2" class="underlineProvider"/>-->
                                    </div>
                                </v-hover>
                            </template>
                        </RouteToTransaction>
                    </template>
                    <span class="fontMonospace">
                        {{text}}
                    </span>
                </v-tooltip>
                <v-tooltip v-if="hover" top>
                        <template v-slot:activator="{ on, attr }">
                            <v-btn
                                v-on="on"
                                v-bind="attr"
                                absolute
                                min-width="0px"
                                style="padding: 4px; right: -1px; background-color: white"
                                x-small
                                elevation="0"
                                @click.prevent=""
                                @click.stop="onCopyEvent"
                                v-clipboard:copy="text"
                            >
                                <v-icon small>content_copy</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            Copy Transaction ID
                        </span>
                </v-tooltip>
            </div>
        </v-hover>
    </div>
</template>
<script>
import RouteToTransaction from "@/components/common/RouteToTransaction";
export default {
    name: "TransactionCopyable",
    components: {RouteToTransaction},
    props: {
        text: {
            type: String,
            required: true
        },
        width: {
            type: String,
            required: false,
            default: "51%"
        }
    },
    computed: {
        transactionId() {
            return [this.text.slice(0, this.text.length - 6), this.text.substr(this.text.length - 6)]
        },
        outerStyle(){
            return "width: " + this.width + "; align-self: center; min-width: 0"
        }
    },
    methods: {
        onCopyEvent() {
            this.$store.dispatch("copy","Copied to clipboard!")
        }
    }
}
</script>


<style scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.underlineProvider {
    width: 100%;
    position: absolute;
    border-bottom: 1px solid #616161;
    top: 18px
}

.text-ellipsis-middle {
    display:inline-flex;
    align-items: center;
    flex-wrap:nowrap;
    max-width: 100%;
}

.text-ellipsis-middle > div:first-child {
    flex: 0 1 auto;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space:nowrap;
}

.text-ellipsis-middle > div + div {
    flex: 1 0 auto;
    white-space: nowrap;
}
</style>