var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    staticClass: "cardShadow",
    attrs: { dense: "" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-icon", { staticClass: "d-block mr-2 ml-1" }, [
                  _vm._v("timeline"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Graphs"),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(_setup.DataGrid, {
              ref: "gridRef",
              attrs: {
                "get-data": {
                  dataState: _setup.dataState,
                  getData: _setup.getData,
                  data: _setup.data,
                },
                headers: _setup.headers,
                "no-data-text": `No graphs found`,
                "bulk-operations": "",
                "hide-header-if-no-data": "",
                "hide-footer-if-no-data": "",
                item_id: "id",
              },
              scopedSlots: _vm._u([
                {
                  key: "header-right",
                  fn: function ({
                    getItems,
                    refreshItems,
                    removeSelection,
                    patchActions,
                    isAnyItemSelected,
                    isDataPatched,
                  }) {
                    return [
                      _c(
                        _setup.DataGridBulkAdd,
                        {
                          ref: "DataGridBulkAddModalRef",
                          attrs: {
                            title: "Add Graphs to Case",
                            loading: _setup.loading,
                            "modal-width": "50%",
                            "save-button-text": "Submit",
                            addItemsButtonDisabled:
                              _setup.GraphFormSelectMultipleRef?.itemsToAdd
                                .length === 0,
                          },
                          on: { submit: _setup.onSubmit },
                        },
                        [
                          _c(_setup.GraphFormSelectMultiple, {
                            ref: "GraphFormSelectMultipleRef",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(_setup.DataGridBulkDelete, {
                        attrs: {
                          disabled: !isAnyItemSelected,
                          "get-items": getItems,
                          "item-callback-async": _setup.deleteItems,
                          title: "Remove Graphs",
                        },
                        on: {
                          "action-finished-event": () => {
                            refreshItems()
                            removeSelection()
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "td",
                        { staticClass: "text-left dynamicTruncate" },
                        [
                          _c(_setup.RouteToGraph, {
                            attrs: { "graph-object": item },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-left d-flex align-center" },
                        [
                          _c("v-hover", {
                            attrs: { "open-delay": "200" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ hover }) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "description-styles pa-1",
                                          class:
                                            hover && "show-full-text-hover",
                                          staticStyle: { margin: "auto 0 0 0" },
                                        },
                                        [_vm._v(_vm._s(item.description))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _c(_setup.BlockchainCryptoBadge, {
                            attrs: { crypto: item.crypto, iconWidth: 24 },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("formatNumber")(item.count)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: item.is_hidden
                                                  ? "primary"
                                                  : "tertiary",
                                                dark: "",
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.is_hidden
                                                  ? "visibility_off"
                                                  : "visibility"
                                              ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "Graph is\n                                        " +
                                    _vm._s(
                                      item.is_hidden ? "hidden" : "visible"
                                    )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-overflow",
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  item.created_at,
                                  "YYYY-MM-DD HH:mm:ss Z"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-overflow text-left",
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(item.creator))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-overflow",
                          staticStyle: {
                            "text-align": "center",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  item.updated_at,
                                  "YYYY-MM-DD HH:mm:ss Z"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }