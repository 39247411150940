<template>
    <div class="height100 scroll-y">
        <div v-if="isLoading">
            <ProgressCircular class="pt-10" :color="currency.unit" />
        </div>

        <v-container v-if="!isLoading && !address">
            <div v-if="address === null || !addressId">
                <AddressNotFoundInfo
                    class="my-2"
                    :address="addressId"
                    :message="'Provided identifier does not exist in the blockchain!'"
                />
            </div>
        </v-container>

        <v-container v-if="!isLoading && address" fluid class="ma-0 pa-0">
            <LimitAbleLayout
                :init-values="filterInitValues"
                @reload-data="reloadData">
                <template slot="title">
                    <h1>Timelines Graphs</h1>
                </template>
                <template slot="body" class="white">
                    <div>
                        <v-container fluid class="pa-0 ma-0">
                            <ProgressCircular v-if="addressBalanceLoading" class="pt-10" :color="currency.unit"/>
                            <div v-else-if="!addressBalanceLoading && balance.length === 0">
                                <div class="d-flex align-center justify-center pt-10" style="height: 80%; color: rgba(0,0,0,0.6)">
                                    <div class="title">No data matched provided filter!</div>
                                </div>
                            </div>
                            <HighCharts v-else style="user-select: none" :options="chartOptions"/>
                        </v-container>
                    </div>
                </template>
            </LimitAbleLayout>
        </v-container>
    </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
import ProgressCircular from "@/components/common/ProgressCircular"
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import LimitAbleLayout from "@/components/common/LimitAbleLayout"
import {formatDateTime} from "@/utils/filters"
import AddressNotFoundInfo from '@/components/address/AddressNotFoundInfo'
import {mapGetters} from "vuex";

Highcharts.dateFormats = {
    'common': function (time) {
        return formatDateTime(time)
    }
}

export default {
    name: 'ChartBalance',
    components: {
        ProgressCircular,
        HighCharts: Chart,
        LimitAbleLayout,
        AddressNotFoundInfo
    },
    beforeUpdate() {
        //Update visual slider (workaround)
        this.$emit("component-loaded-event");
    },
    created() {
        Highcharts.setOptions({
            lang: {
                thousandsSep: ','
            }
        })
    },
    computed: {
        ...mapGetters(["addressBalanceLoading", "currentFiat"]),
        filterInitValues() {
            return {
                since: this.address?.firstTx?.time,
                until: this.address?.lastTx?.time,
                selectedButtonIndex: 1,
                offset: {
                    amount: 1,
                    unit: 'week'
                }
            }
        },
        currency() {
            return this.$store.getters.currency
        },
        balance() {
            return this.$store.getters.addressBalance
        },
        fiats() {
            return this.$store.getters.addressTimelineFiats
        },
        altBalance() {
            if (this.balance.length > 0 && this.fiats.length > 0) {
                const currentFiatUnit = this.currentFiat.unit
                if (this.altBalanceCache.has(`${this.currentLimitValue}${currentFiatUnit}`) && !this.filterChanged) {
                    this.filterChanged = false
                    return this.altBalanceCache.get(`${this.currentLimitValue}${currentFiatUnit}`)
                }

                let arrayOfTimestampBalanceTuples = this.fiats.map((fiat, index) => {
                    const fiatConversionRate = fiat[1][currentFiatUnit]
                    const timestamp = fiat[0]
                    const altBalance = (this.balance[index][1] * fiatConversionRate)

                    if (fiatConversionRate) {
                        return [timestamp, altBalance]
                    }
                    // returns undefined by default
                })

                if (arrayOfTimestampBalanceTuples.some(e => e === undefined)) {
                    this.$store.dispatch('warning', 'Missing conversion rates for the selected currency.')
                }

                //remove any undefined elements
                arrayOfTimestampBalanceTuples = arrayOfTimestampBalanceTuples.filter(e => e)

                this.altBalanceCache.set(`${this.currentLimitValue}${currentFiatUnit}`, arrayOfTimestampBalanceTuples)
                return arrayOfTimestampBalanceTuples
            }
        },
        addressId() {
            return this.$store.getters.addressId
        },
        address() {
            return this.$store.getters.addressInfo
        },
        isLoading() {
            return this.$store.getters.addressLoading
        },
        volume() {
            return this.$store.getters.addressVolume
        },
        altBalanceHasData() {
            return this.altBalance?.length > 0
        },
        chartSeriesArray() {
            const finalSeriesArray = [
                {
                    type: 'line',
                    lineWidth: 2,
                    color: this.$vuetify.theme.currentTheme[this.currency.unit],
                    name: this.currency.unit + ' balance',
                    data: this.balance,
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.f}</b><br/>',
                    }
                },
                {
                    type: 'column',
                    color: '#84bcff',
                    yAxis: 2,
                    name: 'Transactions',
                    data: this.volume,
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>'
                    },
                }
            ]

            if (this.altBalanceHasData) {
                finalSeriesArray.push({
                        type: 'line',
                        lineWidth: 2,
                        color: '#555555',
                        dashStyle: 'shortdot',
                        yAxis: 1,
                        name: `${this.currentFiat.unit} balance`,
                        data: this.altBalance,
                        tooltip: {
                            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f}</b><br/>',
                        }
                    })
            }

            return finalSeriesArray
        },
        chartOptions() {
            return {
                chart: {
                    zoomType: 'x',
                    height: 800,
                },

                credits: {
                    enabled: false
                },

                title: {
                    text: this.currency === false ? '' : `${this.currency.unit} address <span class="fontMonospace">${this.addressId}</span>`
                },
                subtitle: {
                    text: document.ontouchstart === undefined ? 'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
                },
                xAxis: [
                    {
                        type: 'datetime',
                        events: {
                            afterSetExtremes: this.chartSetExtremes
                        },
                        minRange: 3 * 1000, // three seconds,
                        height: '20%',
                        top: '80%',
                        lineWidth: 2,
                        lineColor: "#42424288"
                    },
                    {
                        events: {
                            afterSetExtremes: this.chartSetExtremes
                        },
                        minRange: 3 * 1000, // three seconds
                        height: '54.6%',
                        lineWidth: 2,
                        lineColor: "#42424288"
                    },
                ],
                yAxis: [
                    {
                        title: {
                            text: this.currency === false ? '' : 'Balance (' + this.currency.unit + ')'
                        },
                        min: 0,
                        floor: 0,
                        height: '60%'
                    },
                    {
                        title: {
                            text: 'Balance (' + this.currentFiat.unit + ')'
                        },
                        opposite: true,
                        min: 0,
                        floor: 0,
                        height: '60%',
                    },
                    {
                        title: {
                            text: 'Transactions'
                        },
                        offset: 0,
                        min: 0,
                        floor: 0,
                        tickInterval: 1,
                        height: '20%',
                        top: '80%',
                    },
                ],
                tooltip: {
                    shared: true,
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.8f}</b><br/>',
                    xDateFormat: "%common"
                },
                scrollbar: {
                    liveRedraw: false
                },
                legend: {
                    enabled: true
                },
                plotOptions: {
                    line: {
                        step: "left",
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, this.$vuetify.theme.currentTheme[this.currency.unit]],
                                [1, 'rgba(255,255,255,0)']
                            ]
                        },
                    },
                    series: {
                        states: {
                            inactive: {
                                opacity: 1
                            }
                        }
                    }
                },
                series: this.chartSeriesArray,
                exporting: {
                    sourceWidth: 1920,
                    sourceHeight: 1080,
                    chartOptions: {
                        subtitle: null
                    },
                    buttons: {
                        contextButton: {
                            menuItems: ['downloadPNG', 'downloadPDF'],
                        },
                    },
                }
            }
        }
    },
    beforeCreate() {
        exportingInit(Highcharts);
    },
    methods: {
        reloadData(data) {
            this.$store.dispatch('loadAddressBalance', { ...data })
            this.currentLimitValue = data
            this.filterChanged = true
        }
    },
    data() {
        return {
            currentLimitValue: undefined,
            altBalanceCache: new Map(),
            filterChanged: true
        }
    }
}
</script>

<style scoped>
</style>