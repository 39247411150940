<template>
    <div>
        <v-data-table
            dense
            :headers="headers"
            :items="addresses"
            :loading="loading"
            :items-per-page="50"
            loading-text="Loading cluster addresses..."
        >
            <template slot="item" slot-scope="props">
                <v-hover v-slot="{ hover }">
                    <tr v-if="hover">
                        <td>
                            <div class="d-flex justify-center fontMonospace">
                                <copyable :on-copy="props.item">
                                    <template #text>

                                        {{props.item}}

                                    </template>
                                </copyable>
                            </div>
                        </td>
                    </tr>
                    <tr v-else>
                        <td>
                            <div class="d-flex justify-center fontMonospace">
                                {{props.item}}
                            </div>
                        </td>
                    </tr>
                </v-hover>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Copyable from "@/components/common/Copyable";
export default {
    name: "TableClusterUnused",
    components: {Copyable},
    data() {
        return {
            headers: [
                {text: "Address", align: "center", sortable: false}
            ],
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        addresses: {
            type: Array,
            required: true,
            default: () => []
        }
    }
}
</script>

<style scoped>

</style>