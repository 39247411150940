<script setup>
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import CaseAddressTables from "@/views/Case/Components/Addresses/CaseAddressTables.vue";
import CaseAddressImportDialog from "@/views/Case/Dialogs/CaseAddressImportDialog.vue";
import {computed, inject, ref} from "vue";

const CaseAddressTablesRef = ref()

const caseDetail = inject("CaseDetail")
const caseId = computed(() => caseDetail.value?.id)
</script>

<template>
    <FancyCard class="cardShadow">
        <template #header>
            <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                    <v-icon class="d-block mr-2 ml-1">mdi-tag-text-outline</v-icon>
                    <span class="font-weight-bold">Addresses</span>
                </div>
                <CaseAddressImportDialog @addressesImported="() => CaseAddressTablesRef.refreshAddressTables()"
                                         :case-id="caseId"/>
            </div>
        </template>
        <template #body>
            <CaseAddressTables ref="CaseAddressTablesRef"/>
        </template>
    </FancyCard>
</template>