<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-btn
                v-bind="$attrs"
                v-on="{...on,...$listeners}"
                dark
                fab
                small
                :color="color + ' darken-1'"
            >
                <v-icon>reply</v-icon>
            </v-btn>
        </template>
        <span>{{tooltip}}</span>
    </v-tooltip>
</template>

<script>

export default {
    name: "BackButton",
    props: {
        color: {
            type: String,
            default: "primary"
        },
        tooltip: {
            type: String,
            default: "Go back"
        }
    }
}
</script>
