<template>
    <div class="my-1 mx-3 d-flex flex-column justify-center align-center" style="width: 75px">
        <div class="body-2 font-weight-bold"  :style="'color: ' + color ">
            {{name}}
        </div>
        <div class="mt-1">
            <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                    <v-progress-circular
                        v-on="on"
                        :size="80"
                        :width="5"
                        value="100"
                        :color="color"
                    >
                        <div v-if="value === 1" class="font-weight-bold body-1">
                            {{value*100}}%
                        </div>
                        <div v-else class="font-weight-bold body-1 d-flex flex-column align-center justify-center">
                            <div>
                                {{value > -100000 ? ($humanize.formatNumber(value)) : toNumeral(value)}}
                            </div>
                            <div class="font-weight-bold body-2 mt-n1">
                                Blocks
                            </div>
                        </div>
                    </v-progress-circular>
                </template>
                <span v-if="value === 1">
                    {{name}} synchronized!
                </span>
                <span v-else>
                    <span class="fontMonospace">{{$humanize.formatNumber(Math.abs(value))}}</span> blocks <span class="font-weight-bold">behind</span> HEADERS!
                </span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import numeral from 'numeral';

export default {
    name: "StatusCircular",
    props: {
        name: {
            type: String,
            required: true,
        },
        //EITHER 1 if 100%, otherwise negative number
        value: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true,
        }
    },
    methods: {
        toNumeral(val)
        {
            return numeral(val).format('0.0a')
        }
    }
}
</script>

<style scoped>
</style>