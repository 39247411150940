<script setup>
import {useAttrs, useListeners, useSlots} from "vue";

const listeners = useListeners()
const attrs = useAttrs()
const slots = useSlots()
</script>

<template>
    <v-select
        class="fancyPicker textGrey"
        filled
        dense
        solo
        color="info"
        clear-icon="mdi-close-circle"
        v-bind="attrs"
        v-on="listeners"
    >
        <template v-if="slots.selection" #selection="{ item }">
            <slot name="selection" :item="item"/>
        </template>
        <template v-if="slots.item" #item="{ item }">
            <slot name="item" :item="item"/>
        </template>
    </v-select>
</template>

<style lang="scss">
.border {
    border: 1px solid rgba(0, 0, 0, .12) !important;
    border-color: rgba(0, 0, 0, .12) !important;
}

.fancyPicker {
    .v-input__slot {
        @extend .border !optional;
        box-shadow: none !important;
    }
}
</style>