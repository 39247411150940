<template>
    <SmartTooltipBase>
        <template #activator>
            <v-badge
                v-if="owners && owners.length > 0"
                color="grey"
                :content="owners.length"
                offset-x="10px"
            >
                <div class="d-flex justify-center align-center" style="flex-shrink: 0; flex-grow: 0; width: 26px">
                    <img src="/img/ico/owner-icon.svg" alt="Owner" style="filter: opacity(0.6); width: 20px;"/>
                </div>
            </v-badge>
        </template>
        <div style="max-width: 450px" class="d-flex flex-column justify-center align-center">
            <div class="d-flex align-center justify-center">
                <img src="/img/ico/owner-icon.svg" alt="Owner" style="filter: opacity(0.6); width: 20px;"/>
                <div class="ml-2" style="color: rgba(0,0,0,0.6)">
                    OWNERS
                </div>
            </div>
            <div class="width100 my-2">
                <v-divider/>
            </div>
            <v-data-table
                :items="owners"
                :items-per-page="owners.length > 10 ? 10 : -1"
                :hide-default-footer="owners.length <= 10"
                :footer-props="{
                        'items-per-page-options': [10, 20, -1],
                        'show-current-page': true,
                    }"
                dense
            >
                <template slot="item" slot-scope="props">
                    <tr>
                        <td>
                            <router-link
                                class="fontMonospace link text-truncate"
                                :to="{ name: 'OwnersView', query: { filterName: props.item.name } }">
                                {{ props.item.name | truncate(40) }}
                            </router-link>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </SmartTooltipBase>
</template>

<script>
import SmartTooltipBase from "@/components/common/SmartTooltips/SmartTooltipBase";

export default {
    name: "OwnersSmartTooltip",
    components: {SmartTooltipBase},
    data() {
        return {
            headers: [
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                },
            ]
        }
    },
    props: {
        owners: {
            type: Array,
            required: true,
            default: () => []
        },
    }
}
</script>

<style scoped>

tr:hover {
    background-color: transparent !important;
}

</style>