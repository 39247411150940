var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: { title: "Corrupted or missing data" },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "v-simple-table",
                {
                  staticClass: "mb-1",
                  attrs: {
                    "fixed-header": "",
                    "fixed-footer": "",
                    height: _vm.corruptedData.length > 8 ? 450 : undefined,
                  },
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Csv line number")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Parsed data")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.corruptedData, function (row, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(row.lineNumber))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(row.data))]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: { color: "error", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal(close)
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }