<script setup>
import {computed, nextTick, onMounted, ref, useAttrs, useListeners, watch} from "vue";
import debounce from "debounce";

const props = defineProps({
    value: {
        required: true
    },
    getItems: {
        type: Function,
        required: true
    },
    items: {
        type: Array,
        required: true
    },
    loading: {
        type: Boolean,
        default: false,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    itemValue: {
        type: String,
        required: false
    },
    prependIcon: {
        type: String,
        required: false,
        default: "mdi-database-search"
    },
    appendIcon: {
        type: String,
        required: false,
    },
    noSelectionSlot: {
        type: Boolean,
        required: false,
        default: false
    },
    noPrependIcon: {
        type: Boolean,
        required: false,
        default: false
    },
    convertItemTextToString: {
        type: Boolean,
        required: false,
        default: false
    },
    itemText: {
        type: String,
        required: false,
    },
    innerClass: {
        type: String,
        required: false
    },
    singleLine: {
        type: Boolean,
        required: false,
        default: false
    },
    rules: {
        required: false
    },
    menuTop: {
        type: Boolean,
        required: false,
        default: false
    },
    label: {
        type: String,
        required: false,
        default: "",
    },
    clearOnlySearchString: {
        type: Boolean,
        required: false,
        default: false
    }
})

const emit = defineEmits(['input'])

const searchString = ref(null)
watch(searchString, () => {
    debouncedSearch()
})

const debouncedSearch = debounce(function () {
    props.getItems(searchString.value)
}, 500)

onMounted(() => {
    props.getItems(searchString.value)
})

const attrs = useAttrs()
const listeners = useListeners()


const lastValue = ref(null)
const onEmit = (e) => {
    if (Array.isArray(e)) {
        lastValue.value = e[0]
    } else {
        lastValue.value = e
    }
    emit('input', e)
}

const mappedItems = computed(() => {
    const items = [...props.items]
    if (lastValue.value && !props.multiple) {
        items.unshift(lastValue.value)
    }
    if (props.convertItemTextToString) {
        return items.map(x => {
            x[props.itemText] = String(x[props.itemText])
            return x
        })
    }
    return items
})

const {input, ...listenersWithoutInput} = listeners



//Menu position fix
const autocompleteRef = ref()
const itemsWatchable = computed(() => props.items)
watch(itemsWatchable, () => {
    nextTick(() => {
        autocompleteRef.value.updateMenuDimensions()
    })
})

</script>

<template>
    <div :class="{
        'autocomplete': true,
        'hideSelectSlotIfValue': !multiple}">
        <ValidationProvider :rules="rules" v-slot="{ errors }">
            <v-autocomplete
                :error-messages="errors"
                ref="autocompleteRef"
                :class="innerClass"
                :item-text="itemText"
                :item-value="itemValue"
                :items="mappedItems"
                :loading="loading"
                :multiple="multiple"
                :prepend-icon="noPrependIcon ? undefined : prependIcon"
                :search-input.sync="searchString"
                :value="value"
                clear-icon="mdi-close-circle"
                :clearable="!clearOnlySearchString"
                dense
                :menu-props="{top: menuTop, 'nudge-top': menuTop ? 8 : 0}"
                flat
                :label="label"
                no-filter
                :single-line="singleLine"
                return-object
                v-bind="attrs"
                @input="onEmit"
                v-on="listenersWithoutInput"
            >
                <template #item="{ item }">
                    <slot :item="item" name="item"/>
                </template>
                <template v-if="!multiple && !noSelectionSlot" #selection="{ item }">
                    <slot :item="item" name="selection"/>
                </template>
                <template #append>
                    <v-btn
                        v-if="clearOnlySearchString"
                        v-show="searchString"
                        icon
                        @click="searchString = null"
                        style="margin-top: -6px"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <slot name="append"/>
                </template>
            </v-autocomplete>
        </ValidationProvider>
        <div class="caption mt-n1 ml-1">
            <slot name="hint"/>
        </div>
    </div>
</template>

<style>

.autocomplete .v-select__selection.v-select__selection--comma {
    display: none !important;
}

.hideSelectSlotIfValue .v-select__selections {
    flex-flow: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 262px;
}

</style>