<template>
    <v-row no-gutters class="headerText">
        <v-row style="width: 45%">
            <v-col cols="5" class="d-flex align-center justify-center">
                <div>
                    TRANSACTION ID
                </div>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-center">
                <div>
                    METADATA
                </div>
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-center">
                <div v-if="!noChange">
                    CHANGE
                </div>
            </v-col>
        </v-row>
        <div style="width: 10%">
            <v-row>
                <v-col cols="12" class="d-flex align-center justify-center">
                    <div>
                        IN : OUT
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-row style="width: 45%">
            <v-col cols="3" offset="1" class="d-flex align-center justify-center">
                <div>
                    TOTAL
                </div>
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-center">
                <div>
                    FEE
                </div>
            </v-col>
            <v-col cols="4" offset="1">
                <v-row>
                    <v-col cols="4" offset="2" class="d-flex align-center justify-center">
                        <div>
                            TIMESTAMP
                        </div>
                    </v-col>
                    <v-col cols="2" offset="2" class="d-flex align-center justify-center">
                        <div style="margin-left: 24px">
                            VISUALIZE
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-row>
</template>

<script>
export default {
    name: "TransactionsHeader",
    props: {
        noChange: {
            type: Boolean,
            required: false
        }
    }
}
</script>

<style scoped>

.headerText {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 500;
}
</style>