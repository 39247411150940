<script setup>
import FancyCard from "@/components/common/Cards/FancyCard.vue";

const props = defineProps({
    selectedBip: {
        required: true,
    },
    selectedBipSpecs: {
        required: true
    },
    bipSpecs: {
        required: true
    }
})

defineEmits(['parameterChange', 'addressTypeChange'])

</script>

<template>
    <FancyCard dense :header="false">
        <template #body>
            <div class="d-flex" style="gap: 8px">
                <v-text-field
                    filled
                    dense
                    v-if="selectedBip === 44 || selectedBip === 49 || selectedBip === 84"
                    label="Purpose"
                    v-model="selectedBipSpecs.purpose"
                    disabled
                />
                <v-text-field
                    filled
                    dense
                    v-if="selectedBip === 44 || selectedBip === 49 || selectedBip === 84"
                    label="Coin"
                    v-model="selectedBipSpecs.coin"
                    disabled
                />
                <v-text-field
                    filled
                    dense
                    v-if="selectedBip === 44 || selectedBip === 49 || selectedBip === 84"
                    label="Account"
                    v-model="selectedBipSpecs.account"
                    @change="$emit('parameterChange')"
                />
                <v-text-field
                    filled
                    dense
                    v-if="selectedBip === 44 || selectedBip === 49 || selectedBip === 84"
                    label="External / Internal"
                    v-model="selectedBipSpecs.change"
                    @change="$emit('parameterChange')"
                />
                <v-select
                    filled
                    dense
                    v-if="selectedBip === 32"
                    label="Client"
                    v-model="selectedBipSpecs.selectedClient"
                    :items="selectedBipSpecs.clientList"
                    @change="$emit('parameterChange')"
                />
                <v-text-field
                    filled
                    dense
                    v-if="selectedBipSpecs.selectedClient === 'Custom Derivation Path' && selectedBip === 32"
                    label="Derivation Path"
                    @change="$emit('parameterChange')"
                    v-model="selectedBipSpecs.customPath"
                />
                <v-text-field
                    filled
                    dense
                    v-else
                    label="Derivation Path"
                    @change="$emit('parameterChange')"
                    v-model="selectedBipSpecs.path"
                    :disabled="selectedBip !== 141"
                />
                <v-select
                    filled
                    dense
                    v-if="selectedBip === 141"
                    label="Script Semantics"
                    :items="selectedBipSpecs.scriptSemanticOptions"
                    v-model="selectedBipSpecs.scriptSemanticSelected"
                    return-object
                    @change="$emit('addressTypeChange', selectedBipSpecs.scriptSemanticSelected)"
                />
            </div>
            <v-text-field
                label="bip32 Extended Private Key"
                v-model="selectedBipSpecs.extendedPrivateKey"
                disabled
            />
            <v-text-field
                label="bip32 Extended Public Key"
                v-model="selectedBipSpecs.extendedPublicKey"
                disabled
            />
        </template>
    </FancyCard>
</template>

<style scoped>

</style>