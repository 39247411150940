var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass: "link",
      attrs: {
        download: _vm.item.filename,
        href: `api/files/download/${_vm.item.id}`,
        target: "_blank",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-inline-flex", staticStyle: { "max-width": "100%" } },
        [
          _c("div", { staticClass: "text-truncate" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.item.filename.split(".").shift()) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _vm.item.filename.includes(".")
            ? _c("div", [
                _vm._v(
                  "\n            " +
                    _vm._s(`.${_vm.item.filename.split(".").pop()}`) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }