var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-alert", { attrs: { type: "info" } }, [
    _vm._v("\n    Singleton address " + _vm._s(_vm.address) + "\n    "),
    _c("br"),
    _vm._v(" "),
    _c("strong", [_vm._v(_vm._s(_vm.message))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }