<template>
    <v-card :class="noShadow ? '' : 'cardShadow'" :outlined="outlined" v-bind="$attrs">
        <div v-if="header">
            <div :class="noHeaderGutters ? '' : 'ml-2 my-1'" style="color: rgba(0, 0, 0, 0.6); font-weight: 400">
                <slot name="header"/>
            </div>
            <v-divider/>
        </div>
        <div :class=" noGutters ? 'cardTextPadding0' : dense ? 'cardTextPadding2' : ''">
            <v-card-text :style="bodyStyle">
                <slot v-if="!loading" name="body"/>
                <v-skeleton-loader v-else type="image"/>
            </v-card-text>
        </div>
    </v-card>
</template>

<script>
import NameValueFlex from "@/components/common/NameValueFlex";

export default {
    name: "FancyCard",
    components: {NameValueFlex},
    props: {
        noShadow: {
            type: Boolean,
            default: false,
            required: false,
        },
        outlined: {
            type: Boolean,
            default: true,
            required: false,
        },
        header: {
            type: Boolean,
            default: true,
            required: false,
        },
        noGutters: {
            type: Boolean,
            required: false,
            default: false,
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        bodyStyle: {
            type: String,
            required: false,
        },
        table: {
            type: Boolean,
            required: false,
            default: false
        },
        noHeaderGutters: {
            type: Boolean,
            required: false,
            default: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }

    }
}
</script>
<style>

.cardTextPadding0 > .v-card__text {
    padding: 0 !important;
}

.cardTextPadding2 > .v-card__text {
    padding: 8px !important;
}

</style>