<script setup>
import GenericPickerWithDisplay from "@/components/common/Pickers/GenericPickerWithDisplay.vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {computed, ref} from "vue";
import {DataState} from "@/components/common/types/data";

const props = defineProps({
    value: {
        type: Array,
        required: false,
        default: []
    }
})

const pagination = {
    page: 1,
    itemsPerPage: 20,
    sortDesc: [true]
}

const {dataState, getData, data} = useGetData("getOwners", {pagination, details: "basic"}, false)

const items = computed(() => data.value?.data ?? [])
const loading = computed(() => dataState.value === DataState.Loading)

const getItems = (searchString) => getData({pagination, filter: searchString})

const emit = defineEmits(["change"])

const valueRef = ref(props.value)

const handleChange = (newValue) => {
    valueRef.value = newValue
    return emit("input", valueRef);
}

</script>
<template>
    <GenericPickerWithDisplay
        :value="valueRef"
        @input="handleChange"
        :get-items="getItems"
        :items="items"
        :loading="loading"
        item-value="id"
        item_id="id"
        chips
        multiple
        outlined
        hide-details
        no-prepend-icon
        menuTop
        clear-only-search-string
        label="Search owner..."
    >
        <template #pickerItem="{ item }">
            <img alt="owner icon" src="/img/ico/owner-icon.svg" class="imgIcon" style="width: 20px; filter: opacity(0.6); margin-right: 8px"/>
            <v-list-item-content>
                <v-list-item-title v-text="item.name">
                </v-list-item-title>
            </v-list-item-content>
        </template>
        <template #chipItem="item">
            {{item.item.name}}
        </template>
        <template #append>
            <div v-if="value?.length > 0" style="opacity: 0.8; font-size: 0.9em; margin-top: 6px">
                <nobr>
                    Selected: {{valueRef.length}}
                </nobr>
            </div>
        </template>
    </GenericPickerWithDisplay>
</template>
