<template>
    <i v-if="!address" class="textGrey">
        Unavailable
    </i>
    <router-link
        v-else
        :is="actAsLink ? 'router-link' : 'span'"
        :class="actAsLink && 'link'"
        :style="{ textDecoration: 'none', maxWidth: '100%'}"
        :to="{ path: targetUrl }"
        class="fontMonospace"
    >
        <slot name="text">
            {{ address }}
        </slot>
    </router-link>
</template>

<script>
import {getRouteRelativePrefix} from "@/router/routeHelper";

export default {
    name: "RouteToAddress",
    props: {
        address: {
            type: String,
            required: true
        },
        noDecoration: {
            type: Boolean,
            required: false,
            default: false,
        },
        color: {
            type: String,
            required: false,
        },
        actAsLink: {
            type: Boolean,
            default: true,
            required: false
        },
        currency: {
            type: String,
            required: false
        },
        hasLabel: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        targetUrl() {
            return `${getRouteRelativePrefix(this.$route, this.currency)}address/${this.address}/`
        }
    }
}

</script>