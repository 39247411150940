var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-y height100 width100" },
    [
      _c(
        "v-layout",
        {
          staticStyle: {
            "border-bottom": "1px #0000001F solid",
            height: "49px",
          },
          attrs: { column: "", "fill-height": "" },
        },
        [
          _c(
            "v-app-bar",
            { attrs: { elevation: "0", color: "white", dense: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    "slider-color": _vm.currency.unit,
                    "background-color": "white",
                    light: "",
                    "show-arrows": "",
                  },
                },
                [
                  _c(
                    "v-tab",
                    { attrs: { to: `/${_vm.currency.unit}/status` } },
                    [_vm._v("Status")]
                  ),
                  _vm._v(" "),
                  _vm.currency.typeFamily === "ETH"
                    ? _c(
                        "v-tab",
                        {
                          attrs: {
                            to: "/" + _vm.currency.unit + "/transactions/",
                          },
                        },
                        [_vm._v("Transactions")]
                      )
                    : _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            "offset-y": "",
                            "content-class": "currency-hover-menu elevation-2",
                            transition: "slide-y-transition",
                            "open-on-hover": "",
                            "close-on-content-click": false,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-tab",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            to:
                                              "/" +
                                              _vm.currency.unit +
                                              "/transactions/",
                                          },
                                        },
                                        "v-tab",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                            Transactions\n                            "
                                      ),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(
                                          "\n                                mdi-chevron-down\n                            "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list",
                            { staticClass: "mt-n2", attrs: { dense: "" } },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: { color: _vm.currency.unit },
                                  model: {
                                    value: _vm.subMenu,
                                    callback: function ($$v) {
                                      _vm.subMenu = $$v
                                    },
                                    expression: "subMenu",
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticStyle: {
                                        "text-decoration": "none",
                                      },
                                      attrs: {
                                        to: `/${_vm.currency.unit}/transactions/custom`,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        { attrs: { value: "nodes" } },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                                "letter-spacing": "1px",
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s("CUSTOM") +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticStyle: {
                                        "text-decoration": "none",
                                      },
                                      attrs: {
                                        to: `/${_vm.currency.unit}/transactions/geo/`,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticStyle: {
                                            "border-top":
                                              "solid 1px rgba(0, 0, 0, 0.1)",
                                          },
                                          attrs: { value: "files" },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                                "letter-spacing": "1px",
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s("GEO") +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "v-tab",
                    { attrs: { to: `/${_vm.currency.unit}/blocks` } },
                    [_vm._v("Blocks")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab",
                    { attrs: { to: `/${_vm.currency.unit}/peers` } },
                    [_vm._v("Peers")]
                  ),
                  _vm._v(" "),
                  !_vm.isCurrencyFamilyEth
                    ? _c(
                        "v-tab",
                        { attrs: { to: `/${_vm.currency.unit}/nodes` } },
                        [_vm._v("Nodes")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currency.interceptorEnabled &&
                  _vm.$store.getters.hasPermission("monitoring")
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            "offset-y": "",
                            "content-class": "currency-hover-menu elevation-2",
                            transition: "slide-y-transition",
                            "open-on-hover": "",
                            "close-on-content-click": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-tab",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              to:
                                                "/" +
                                                _vm.currency.unit +
                                                "/monitoring/",
                                            },
                                          },
                                          "v-tab",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n                            Monitoring\n                            "
                                        ),
                                        _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v(
                                            "\n                                mdi-chevron-down\n                            "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4053200105
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list",
                            { staticClass: "mt-n2", attrs: { dense: "" } },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: { color: _vm.currency.unit },
                                  model: {
                                    value: _vm.subMenu,
                                    callback: function ($$v) {
                                      _vm.subMenu = $$v
                                    },
                                    expression: "subMenu",
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticStyle: {
                                        "text-decoration": "none",
                                      },
                                      attrs: {
                                        to: `/${_vm.currency.unit}/monitoring/`,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        { attrs: { value: "nodes" } },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                                "letter-spacing": "1px",
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s("NODES") +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticStyle: {
                                        "text-decoration": "none",
                                      },
                                      attrs: {
                                        to: `/${_vm.currency.unit}/monitoring/files/`,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticStyle: {
                                            "border-top":
                                              "solid 1px rgba(0, 0, 0, 0.1)",
                                          },
                                          attrs: { value: "files" },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                                "letter-spacing": "1px",
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s("FILES") +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "scroll-y height100" },
            [
              _c(
                "v-container",
                { staticClass: "px-4", attrs: { fluid: "" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }