var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.GenericPicker, {
        attrs: {
          items: _setup.countries,
          "get-items": _setup.getItemsForCountry,
          value: _vm.value,
          loading: _setup.loading,
          "item-text": "country_code",
          "prepend-icon": "",
          label: "Country",
          "hide-details": "",
          "single-input": "",
        },
        on: { input: _setup.emitCountryCode },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(_setup.CountrySearchDisplay, { attrs: { item: item } }),
              ]
            },
          },
          {
            key: "selection",
            fn: function ({ item }) {
              return [
                _c(_setup.CountrySelectedDisplay, {
                  attrs: { item: item, hideTimeZone: "" },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }