<template>
    <div
        :class="{
            'graph': isOnGraphView,
            'addressClusterTransactionViewGraph': !isOnGraphView
        }"
        style="width: auto"
    >
        <v-progress-linear style="left: 0" absolute :color="currency.unit" :indeterminate="true" v-if="loading"/>
        <v-row class="toolbar-buttons-row">
            <v-speed-dial class="mt" v-model="fab" direction="right">
                <template v-slot:activator>
                    <v-btn v-model="fab" dark :color="currency.unit" fab>
                        <v-icon v-if="fab">close</v-icon>
                        <v-icon v-else>double_arrow</v-icon>
                    </v-btn>
                </template>
                <RelayoutButton @click.stop="relayoutClick" :color="currency.unit"/>
                <ExportToPngButton @click.stop="exportToPng" :color="currency.unit"/>
                <slot name="actionbuttons"/>
            </v-speed-dial>
        </v-row>

        <cytoscape
            ref="cy"
            :preConfig="preConfig"
            :afterCreated="afterCreated"
            :config="internalConfig"
        >
            <cy-element
                v-for="node in nodes"
                :key="`${node._id}`"
                :definition="node"
            />
            <cy-element v-for="edge in edges" :key="`${edge._id}`" :definition="edge"/>
        </cytoscape>
    </div>
</template>

<script>
import {saveAs} from "file-saver"
import klay from "cytoscape-klay"
import {createInitConfig, graphLayoutConfig} from "@/configurations/cytoscape-config"
import {uuidv4} from "@/utils"

import RelayoutButton from "@/components/common/Buttons/Fab/RelayoutButton"
import ExportToPngButton from "@/components/common/Buttons/Fab/ExportToPngButton"

export default {
    name: "GraphVisualization",
    components: {
        RelayoutButton,
        ExportToPngButton
    },
    props: {
        nameOfExportedGraph: String,
        loading: Boolean,
        config: Object,
        elements: [Object, Array],
        autoRelayout: {
            type: Boolean,
            default: false
        },
    },
    updated: function () {
        //this exist onnly for reload purpose (cytoscape is not reactive - so after reload config you must relayout an relaad all "configs")
        this.$nextTick(function () {
            // this relayout cytoscape instance, see https://vuejs.org/v2/api/#updated for more information
            if (this.elementsUpdated && this.cytoscapeInstanceFunc && this.config) {
                this.cytoscapeInstanceFunc(cy => {
                    if (this.autoRelayout) {
                        cy.layout(graphLayoutConfig).run()
                    }

                    //vue-cytoscape does not remove click event listeners when changing configs so manage them manually
                    cy.removeAllListeners()
                    cy.on("click", `node`, ((e) => {
                        cy.elements().removeClass('selected')
                        e.target.addClass('selected')

                        this.graphNodeClick(e)
                    }))

                    cy.pan(this.config.pan)
                    cy.zoom(this.config.zoom)

                    const updateGraphBackground = () => {
                        let pan = cy.pan()
                        let zoom = cy.zoom()
                        let newZoom = 50 * zoom
                        let alpha = zoom / 5
                        let scale = zoom

                        const bgImage = document.querySelector('.__________cytoscape_container div:last-child')

                        bgImage.style.backgroundPositionX = pan.x + 'px'
                        bgImage.style.backgroundPositionY = pan.y + 'px'

                        bgImage.style.backgroundSize = `${newZoom}px ${newZoom}px`
                        bgImage.style.backgroundImage = `radial-gradient(rgba(0,0,0,${alpha}) ${scale}px, transparent 0)`
                    }
                    updateGraphBackground()

                    cy.on('pan zoom', updateGraphBackground)

                    this.elementsUpdated = false
                });
            }
        })
    },
    beforeDestroy() {
        this.$emit("before-destroy-event")
    },
    computed: {
        currency() {
            return this.$store.getters.currency;
        },
        internalConfig() {
            return this.config || createInitConfig(this.$vuetify.theme.currentTheme)
        },
        nodes() {
            return this.elements?.nodes?.map(i => ({...i, _id: uuidv4()}))
        },
        edges() {
            return this.elements?.edges?.map(i => ({...i, _id: uuidv4()}))
        },
        isOnGraphView() {
            return this.$route.matched.findIndex(route => route.name === 'GraphSingleView') !== -1
        },
    },
    data() {
        return {
            cytoscapeInstanceFunc: undefined,
            fab: true,
            elementsUpdated: true
        }
    },
    methods: {
        preConfig(cytoscape) {
            cytoscape.use(klay);
        },
        afterCreated(cy) {
            cy.layout(graphLayoutConfig).run();
            // original solution was call cy.resize(), this dont work now, this is workaround (hack)
            if (this.$refs.cy.$el.firstChild.style) {
                this.$refs.cy.$el.firstChild.style.height = "calc(100vh - 90px)";
            }
            this.cytoscapeInstanceFunc = c => c(cy);
        },
        graphNodeClick(e) {
            this.$emit("graph-click-event", e)
        },
        exportToPng(event) {
            const pngToExport = this.cytoscapeInstanceFunc(cy =>
                cy.png({bg: "#ffffff", full: true})
            );
            const exportFileName = `${this.nameOfExportedGraph}.png`
            saveAs(pngToExport, exportFileName);
        },
        relayoutClick() {
            this.cytoscapeInstanceFunc(cy => {
                cy.layout(graphLayoutConfig).run();
            })
        }
    },
    watch: {
        elements: {
            deep: true,
            handler(newValue, OldValue) {
                this.elementsUpdated = true
            }
        }
    }
};
</script>

<style>

.graph #cytoscape-div {
    height: calc(100vh - 68px) !important;
    width: auto;
}

.addressClusterTransactionViewGraph #cytoscape-div {
    height: calc(100vh - 68px - 49px) !important;
    width: auto;
}

</style>

<style scoped lang="sass">
.graph, .addressClusterTransactionViewGraph {

    position:relative;

    .toolbar-buttons-row {

        position:absolute;
        top: 50px;
        left: 60px;
        z-index: 20;
        transform: translate(-50%, -50%)

}

}
</style>

<style>
.graph .__________cytoscape_container div:last-child {
    background: #fdfdfd;
    background-size: 18px 18px;
}
</style>