<template>
    <v-layout row>
        <v-btn
            @click="showButtonClick(loadTransactionSides.BOTH)"
            class="white"
            depressed
        >Show on canvas</v-btn>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn
                    @click="showButtonClick(loadTransactionSides.LEFT)"
                  
                    icon
                    v-on="on"
                >
                    <v-icon>subdirectory_arrow_left</v-icon>
                </v-btn>
            </template>

            <span>Show only inputs</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn
                    @click="showButtonClick(loadTransactionSides.RIGHT)"
                    
                    icon
                    v-on="on"
                >
                    <v-icon>subdirectory_arrow_right</v-icon>
                </v-btn>
            </template>

            <span>Show only outputs</span>
        </v-tooltip>
    </v-layout>
</template>

<script>
import { loadTransactionSides } from "../../../store/modules/Transactions";

export default {
    name: "ShowOnCanvasButton",
    methods: {
        showButtonClick(sides) {
            this.$emit("show-transaction-click-event", sides)
        }
    },
    data() {
        return {
            loadTransactionSides: loadTransactionSides
        };
    }
}
</script>
