var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { icon: "", small: "", color: "secondary" } },
                    "v-btn",
                    _vm.$attrs,
                    false
                  ),
                  { ..._vm.$listeners, ...on }
                ),
                [
                  _c(
                    "v-icon",
                    { attrs: { color: _vm.color, large: !_vm.smallIcon } },
                    [_vm._v("info")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.infoButtonTooltip))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }