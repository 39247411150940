var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.BaseModalDialog, {
        ref: "modalRef",
        attrs: { title: _vm.title, width: _vm.modalWidth },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [_vm._t("default")]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function ({ close, setDisabled }) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        disabled: _vm.loading,
                        small: "",
                        color: "grey",
                      },
                      on: { click: _setup.onClose },
                    },
                    [_vm._v("\n                Cancel\n            ")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        loading: _vm.loading,
                        disabled: _vm.addItemsButtonDisabled,
                        color: "success",
                      },
                      on: {
                        click: function ($event) {
                          return _setup.emit("submit")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.saveButtonText) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c(_setup.DataGridAddButton, {
        on: {
          click: function ($event) {
            return _setup.modalRef.open()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }