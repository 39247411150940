var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        transition: "slide-y-transition",
        rounded: "lg",
        "close-on-content-click": false,
        "close-delay": "50",
        "open-on-click": "",
        bottom: "",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "span",
                  _vm._g({ staticStyle: { cursor: "pointer" } }, on),
                  [_vm._t("activator")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c("fancy-card", {
        attrs: { header: false },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function () {
                return [_vm._t("default")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }