<template>
    <SmartTooltipBase>
        <template #activator>
            <v-badge
                v-if="identities && identities.length > 0"
                :content="identities.length"
                color="grey"
                offset-x="6px"
            >
                <div class="d-flex justify-center align-center" style="flex-shrink: 0; flex-grow: 0; width: 26px">
                    <img alt="Identity" src="/img/ico/name-id-icon.svg" style="filter: opacity(0.6); width: 26px;"/>
                </div>
            </v-badge>
        </template>
        <div style="max-width: 450px" class="d-flex flex-column justify-center align-center">
            <div class="d-flex align-center justify-center">
                <img alt="Identity" src="/img/ico/name-id-icon.svg" style="filter: opacity(0.6); width: 26px;"/>
                <div class="ml-2" style="color: rgba(0,0,0,0.6)">
                    IDENTITIES
                </div>
            </div>
            <div class="width100 my-2">
                <v-divider/>
            </div>
            <v-data-table
                :items="identities"
                :items-per-page="identities.length > 10 ? 10 : -1"
                :hide-default-footer="identities.length <= 10"
                :footer-props="{
                        'items-per-page-options': [10, 20, -1],
                        'show-current-page': true,
                    }"
                dense
            >
                <template slot="item" slot-scope="props">
                    <tr>
                        <td>
                            <router-link :to="{ name: 'IdentitiesView', query: { filterLabel: props.item.label } }"
                                    class="fontMonospace link text-truncate">
                                {{ props.item.label | truncate(40) }}
                            </router-link>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </SmartTooltipBase>
</template>

<script>
import SmartTooltipBase from "@/components/common/SmartTooltips/SmartTooltipBase";

export default {
    name: "IdentitiesSmartTooltip",
    components: {SmartTooltipBase},
    data() {
        return {
            headers: [
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                },
            ]
        }
    },
    props: {
        identities: {
            type: Array,
            required: true,
            default: () => []
        },
    }
}
</script>

<style scoped>

tr:hover {
    background-color: transparent !important;
}

</style>