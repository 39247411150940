<template>
    <div class="mb-2">
        <div v-if="addressFundsLoading">
            <progress-circular :color="currency.unit"/>
        </div>

        <div v-else>
            <EmptyClusterInfo
                v-if="addressFunds && addressFunds.isEmptyCluster"
                :address="''"
                :currency="currency"
                :message="'Fund tracking works only with clusters and this address does not have any!'"
            />

            <div v-if="currentCluster !== null && addressFunds">

                <v-row no-gutters justify="start" align="center">
                    <v-col cols="auto" v-if="addressFunds.entities.some(x => x.categories.some(y => y.name.toLowerCase() === 'gambling' || y.name.toLowerCase() === 'mixer' ))">
                        <div class="mx-1">
                            <v-chip label color="#b22222" class="white--text">
                                <v-icon left>
                                    mdi-alert
                                </v-icon>
                                POSSIBLE MONEY LAUNDERING!
                            </v-chip>
                        </div>
                    </v-col>
                    <v-col cols="auto" v-if="addressFunds.entities.some(x => x.categories.some(y => y.name.toLowerCase() === 'scam' || y.name.toLowerCase() === 'theft' || y.name.toLowerCase() === 'ransom' )) && !filter.isOutgoing">
                        <div class="mx-1">
                            <v-chip label color="#b22222" class="white--text">
                                <v-icon left>
                                    mdi-alert
                                </v-icon>
                                POSSIBLE FRAUD!
                            </v-chip>
                        </div>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="auto">
                        <exportMenu
                            :base-url="`${currency.unit}/cryptocluster/${currentCluster}/funds`"
                            :query="computeQuery()"/>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col
                        v-if="addressFunds && addressFunds.highChartsData.length > 0"
                    >
                        <fancy-card class="d-flex justify-center" :header="false" no-gutters>
                            <template #body>
                                <Chart :options="chartOptions"/>
                            </template>
                        </fancy-card>
                    </v-col>
                    <v-col
                        v-if="
                    (addressFunds && addressFunds.amountsChartsData.length > 0) ||
                    addressFunds.amountsChartsData != null
                  "
                    >
                        <fancy-card class="d-flex justify-center" :header="false" no-gutters>
                            <template #body>
                                <Chart :options="amountsChartOptions"/>
                            </template>
                        </fancy-card>
                    </v-col>
                    <v-col
                        v-if="
                    (addressFunds &&
                      addressFunds.categoryAppearanceData.length > 0) ||
                    addressFunds.categoryAppearanceData != null
                  "
                    >
                        <div v-if="!isLogaritmic">
                            <fancy-card class="d-flex justify-center" :header="false" no-gutters>
                                <template #body>
                                    <Chart :options="categoryAppearanceOptions"/>
                                </template>
                            </fancy-card>
                        </div>
                        <div v-else>
                            <fancy-card class="d-flex justify-center" :header="false" no-gutters>
                                <template #body>
                                    <Chart :options="categoryAppearanceOptionsLogaritmic"/>
                                </template>
                            </fancy-card>
                        </div>
                    </v-col>
                </v-row>

                <div class="d-flex justify-start">
                    <div class="mt-3">
                        <span class="title">
                            Cumulatively {{ filter.isOutgoing ? "Sent" : "Received" }}:
                        </span>
                        <fiat-convertor
                            :value="Number(addressFunds.total_amount)"
                            :fiats="addressFunds.fiats"
                            convert-from-satoshi
                            with-unit
                            monospace
                            unit-small
                            unit-light/>
                    </div>
                </div>
                <v-divider class="mb-3"/>
                <fancy-card :header="false" no-gutters>
                    <template #body>
                        <v-data-table
                            dense
                            style="white-space: nowrap"
                            :loading="addressFundsLoading"
                            :headers="headers"
                            :items="computedFunds"
                            :items-per-page="30"
                            sort-by="amount"
                            sort-desc
                            class="elevation-0"
                            :footer-props="{
                                'items-per-page-options': [30, 50, 200],
                                'items-per-page-text': 'Clusters per page',
                                'show-current-page': true,
                                'showFirstLastPage': true,
                            }"
                        >
                            <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                                <div :key="h.value" class="d-inline-flex align-center">
                                    <div>
                                        {{ header.text }}
                                    </div>
                                    <div v-if="header.customFilter">
                                        <FilterIcon
                                            :componentFilterType="header.customFilter.component"
                                            :name="header.value"
                                            :append="header.customFilter.append"
                                            explicit-apply
                                            :additionalOptions="header.customFilter.additionalOptions"
                                            :filterSubType="header.customFilter.filterSubType"
                                            v-model="resultsFilter[header.customFilter.override ? header.customFilter.override : header.value]"/>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>
                                        <EntityLabel
                                            :entity="item.entityid"
                                            :entityLabel="item.label"
                                            :owner="item.owner"
                                            :color="item.categories[0] ? item.categories[0].color : undefined"
                                            :isCopyable="true"
                                            :showExtra="false"
                                            :actAsLink="true"
                                            :currency="currency.unit"
                                            :is-address="false"
                                        />
                                    </td>
                                    <td>
                                        <AddressCategory
                                            class="mr-1"
                                            v-for="cat in item.categories"
                                            :iconItem="cat"
                                            :key="cat.id"
                                            :name="cat.name"
                                            :color="cat.color"
                                        />
                                    </td>
                                    <td>
                                        <!-- IF on regular view => ROUTER PUSH -->
                                        <div class="d-flex align-center" style="margin: 2px 0">
                                            <div class="text-right align-center" style="width: 40%">
                                        <span class="fontMonospace">
                                            {{ item.transfers_count }}
                                        </span>
                                            </div>
                                            <div class="ml-2 mr-1">
                                                <div v-if="!isOnSplitView || $route.params.graphId">
                                                    <router-link
                                                        class="text-decoration-none"
                                                        :to="createFundsLocation(item.entityid)">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn v-on="on" v-bind="attrs" color="#616161" style="height: 24px" small outlined label>
                                                                    <v-icon>timeline</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Plot Graph</span>
                                                        </v-tooltip>
                                                    </router-link>
                                                </div>
                                                <div v-else>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn v-on="on" v-bind="attrs" color="#616161" style="height: 24px" small @click="fetchFunds(item.entityid)" outlined label>
                                                                <v-icon>timeline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Load Graph</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                            <div style="width: 50%; float: left; text-align: left">
                                                <RouteToInteractions
                                                    no-decoration
                                                    :query="'?remote=' +
                                             item.entityid +
                                            '&isOutgoing=' +
                                            filter.isOutgoing +
                                            '&maxHops=' +
                                            item.transfer_max_hops">
                                                    <template slot="text">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn v-on="on" v-bind="attrs" color="#616161" style="height: 24px" small outlined label class="text-decoration-none">
                                                                    <v-icon style="font-size: 22px">mdi-format-list-bulleted</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Show Interactions</span>
                                                        </v-tooltip>
                                                    </template>
                                                </RouteToInteractions>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ item.transfer_min_hops }}</td>
                                    <td>{{ item.transfer_max_hops }}</td>
                                    <td>{{ item.transfer_avg_hops | formatNumber(2) }}</td>
                                    <td>{{ item.link_factor }}</td>
                                    <td class="text-right">
                                        <fiat-convertor :value="item.amount"
                                                        :fiats="addressFunds.fiats"
                                                        with-unit
                                                        convert-from-satoshi
                                                        monospace
                                                        unit-light
                                                        unit-small/>
                                    </td>
                                </tr>
                            </template>
                            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                                <span>
                                    {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                                </span>
                            </template>
                        </v-data-table>
                    </template>
                </fancy-card>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressCircular from "@/components/common/ProgressCircular";
import EmptyClusterInfo from "@/components/address/EmptyClusterInfo";
import ExportButtons from "@/components/common/Buttons/ExportButtons";
import FiatConvertor from "@/components/common/FiatConvertor";
import AddressCategory from "@/components/address/AddressCategoryIcon";
import CategoryPicker from "@/components/common/CategoryPicker";
import DeleteableList from "@/components/common/DeleteableList";
import ValidatableSearchTextField from "@/components/common/ValidateableComponents/ValidatableSearchTextField";
import {Chart} from "highcharts-vue";
import {computeQueryFromObject, isOnSplitView} from "@/utils";
import ExportMenu from "@/components/common/Buttons/ExportMenu";
import RouteToInteractions from "@/components/common/RouteToInteractions";
import Copyable from "@/components/common/Copyable";
import FancyCard from "@/components/common/Cards/FancyCard";
import EntityLabel from "@/components/common/EntityLabel";
import FilterTableAddButton from "../Filters/BaseFilterActivatorIcon.vue";
import ButtonWrapperHoverTooltip from "../common/Buttons/ButtonWrapperHoverTooltip.vue";
import FilterIcon from "@/components/Filters/FilterIcon.vue";
import FilterContentNumeric from "@/components/Filters/FilterContentNumeric.vue";
import {filterRows} from "@/utils/tableFiltering";
import FilterContentStrings from "@/components/Filters/FilterContentStrings.vue";
import FilterContentCategories from "@/components/Filters/FilterContentCategories.vue";
import {FilterStringSubtype} from "@/components/common/types/filter";


export default {
    name: "FundsResult",
    components: {
        FilterIcon,
        EntityLabel,
        FancyCard,
        RouteToInteractions,
        Copyable,
        ExportMenu,
        FiatConvertor,
        ProgressCircular,
        AddressCategory,
        EmptyClusterInfo,
        CategoryPicker,
        DeleteableList,
        ValidatableSearchTextField,
        Chart,
        ExportButtons,
        FilterTableAddButton,
        ButtonWrapperHoverTooltip
    },
    props: {
        addressFunds: {
            type: Object
        },
        currentCluster: {
            type: String
        },
        addressFundsLoading: {
            type: Boolean,
        },
        categories: {
            type: Array,
        },
        currency: {
            type: Object,
        },
        isLogaritmic: {
            type: Boolean,
        },
        filter: {
            type: Object,
        },
        chartSubtitle: {
            type: String,
            required: false,
        }
    },
    data() {
      return {
          filterConvertors: {
              amount: (val) => {
                  return Number(val) / 100000000;
              }
          },
          resultsFilter: {},
          headers: [
              {
                  text: "Cluster",
                  align: "left",
                  value: "label",
                  customFilter: {
                      component: FilterContentStrings,
                      override: 'entityid',
                      additionalOptions: [{name: 'hasLabel', label: 'Has label'}],
                      filterSubType: FilterStringSubtype.Clusters
                  }
              },
              {
                  text: "Categories",
                  align: "center",
                  value: "categories",
                  customFilter: {component: FilterContentCategories}
              },
              {
                  text: "Paths",
                  align: "center",
                  value: "transfers_count",
                  customFilter: {component: FilterContentNumeric}
              },
              {
                  text: "Min Hops",
                  align: "center",
                  value: "transfer_min_hops",
                  customFilter: {component: FilterContentNumeric}
              },
              {
                  text: "Max Hops",
                  align: "center",
                  value: "transfer_max_hops",
                  customFilter: {component: FilterContentNumeric}
              },
              {
                  text: "Avg Hops",
                  align: "center",
                  value: "transfer_avg_hops",
                  customFilter: {component: FilterContentNumeric}
              },
              {
                  text: "Amount %",
                  align: "center",
                  value: "link_factor",
                  customFilter: {component: FilterContentNumeric, append: '%'}
              },
              {
                  text: "Amount",
                  align: "right",
                  value: "amount",
                  customFilter: {component: FilterContentNumeric, append: this.currency.unit}
              },
          ],
      }
    },
    computed: {
        computedFunds() {
            return filterRows(this.addressFunds.entities, this.resultsFilter, this.filterConvertors)
        },
        isOnSplitView() {
            return isOnSplitView(this.$route)
        },
        chartOptions() {
            return {
                chart: {type: "pie"},
                credits: {enabled: false},
                title: {
                    text: `${this.currency.unit} value distribution across cluster categories`,
                },
                subtitle: {text: this.chartSubtitle ? this.chartSubtitle : `Containing cluster ${this.currentCluster}`},
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
                },
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {enabled: false},
                    showInLegend: true,
                },
                series: [
                    {
                        name: "Amount",
                        colorByPoint: true,
                        data: this.addressFunds.highChartsData,
                    },
                ],
            };
        },
        amountsChartOptions() {
            return {
                chart: {type: "pie"},
                credits: {enabled: false},
                title: {text: `Ratio of Known and Unknown Clusters`},
                subtitle: {text: this.chartSubtitle ? this.chartSubtitle : `Containing cluster ${this.currentCluster}`},
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
                },
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {enabled: false},
                    showInLegend: true,
                },
                series: [
                    {
                        name: "Amount",
                        colorByPoint: true,
                        data: this.addressFunds.amountsChartsData,
                    },
                ],
            };
        },
        categoryAppearanceOptions() {
            return {
                chart: {type: "column"},
                credits: {enabled: false},
                title: {text: `Absolute Occurrence of Categories`},
                subtitle: {text: this.chartSubtitle ? this.chartSubtitle : `Containing cluster ${this.currentCluster}`},
                tooltip: {
                    headerFormat:
                        '<span style="font-size:16px">{point.key}</span><table>',
                    pointFormat:
                        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                },
                yAxis: {
                    title: {
                        text: undefined,
                    },
                },
                xAxis: {
                    categories: this.counterExec(),
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        showInLegend: false,
                        name: "Amount of occurences",
                        colorByPoint: true,
                        data: this.addressFunds.categoryAppearanceData,
                    },
                ],
            };
        },
        categoryAppearanceOptionsLogaritmic() {
            return {
                chart: {type: "column"},
                title: {
                    text: `Absolute occurrence of the given Category<br>within found paths`,
                },
                credits: {enabled: false},
                xAxis: {
                    tickInterval: 1,
                    categories: this.counterExec(),
                    accessibility: {
                        rangeDescription: "Range: 1 to 10",
                    },
                },

                yAxis: {
                    type: "logarithmic",
                    minorTickInterval: 1,
                    accessibility: {
                        rangeDescription: "Range: 0.1 to 1000",
                    },
                    max: 900,
                    min: 0.1,
                    title: {
                        text: undefined,
                    },
                },

                tooltip: {
                    /* headerFormat: '<b>{series.name}</b><br />',
                              pointFormat: 'x = {point.x}, y = {point.y}' */

                    headerFormat:
                        '<span style="font-size:16px">{point.key}</span><table>',
                    pointFormat:
                        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                },

                series: [
                    {
                        showInLegend: false,
                        name: "Amount of occurrences",
                        colorByPoint: true,
                        data: this.addressFunds.categoryAppearanceData,
                    },
                ],
            };
        },
    },
    methods: {
        computeQuery() {
            if (this.filter)
                return computeQueryFromObject(this.filter)
        },
        counterExec() {
            let names = [];
            let i;
            for (i = 0; i < this.addressFunds.categoryAppearanceNames.length; i++) {
                names.push(this.addressFunds.categoryAppearanceNames[i].name);
            }
            return names;
        },

        fetchFunds(entityId)
        {
            const source = this.filter.isOutgoing
                ? this.addressFunds.clusteraddr
                : entityId;
            const destination = this.filter.isOutgoing
                ? entityId
                : this.addressFunds.clusteraddr;


            const paths = "all";
            const asAddress = 0;
            const limit = 100;

            this.$store.dispatch("loadFundsNetwork", {
                source: source,
                destination: destination,
                maxHops: this.filter.maxHops,
                currency: this.currency.unit,
                paths: paths,
                asAddress: asAddress,
                limit: limit,
                directions: 'both',
                includeClusters: true,
            });
        },

        createFundsLocation(entityId) {
            const source = this.filter.isOutgoing
                ? this.addressFunds.clusteraddr
                : entityId;
            const destination = this.filter.isOutgoing
                ? entityId
                : this.addressFunds.clusteraddr;

            const paths = "all";
            const asAddress = 0;
            const limit = 100;

            //CLUSTER VIEW or ADDRESS view
            const name = this.$route.params.detailCluster || this.$route.params.clusterentity ? "FundsNetworkCluster" : "FundsNetwork"
            return {
                name: name,
                params: {
                    ...this.$route.params,
                    maxHops: this.filter.maxHops,
                    source: source,
                    destination: destination,
                    address: !(this.$route.params.detailCluster || this.$route.params.clusterentity) && !this.$route.params.address ? this.$route.params.detailAddress : this.$route.params.address,
                    clusterentity: this.$route.params.detailCluster ?? this.$route.params.clusterentity
                },
                query: {
                    paths: paths,
                    asAddress: asAddress,
                    limit: limit,
                    directions: 'both',
                    includeClusters: true,
                }
            }
        },
    }
}
</script>

<style scoped>
.headerText {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.1px
}
</style>