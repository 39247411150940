<template>
    <div id="app" style="width: 100%">
        <div id="map" style="width: 100%"></div>
        <div id="tooltip"></div>
    </div>
</template>

<script>
/* eslint-disable */
// import openlayer css for style
import "ol/ol.css";
// This is library of openlayer for handle map
import Map from "ol/Map";
import View from "ol/View";
import Feature from 'ol/Feature';
import {Fill, Stroke, Circle, Style, Text} from 'ol/style';
import Point from 'ol/geom/Point';
import { defaults as defaultControls, ScaleLine, Attribution } from "ol/control";
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import {Overlay} from "ol";
import {formatDateTime} from "@/utils/filters";
export default {
    async mounted() {
        await this.initiateMap();
    },
    props: {
        latitude: {
            type: String,
        },
        longitude: {
            type: String,
        },
        peerDetail: {
            type: Object,
        }
    },
    methods: {
        initiateMap() {
            const getLonLat = this.peerDetail ? [this.peerDetail.node.longitude, this.peerDetail.node.latitude] : [this.latitude, this.longitude]
            const projectLonLat = fromLonLat(getLonLat);
            const geosExist = this.peerDetail?.geos?.length > 0

            // Define Map Marker
            let markers
            if(geosExist) {
                markers = this.peerDetail.geos.map(item => {
                    const marker = new Feature(
                        {
                            geometry: new Point(fromLonLat([item.geo.longitude, item.geo.latitude])),
                        })
                    marker.setProperties({
                        since: item.since,
                        latitude: item.geo.latitude,
                        longitude: item.geo.longitude
                    })

                    return marker
                })

                markers.forEach((marker, index) => {
                    marker.setStyle(
                        new Style(
                            {
                                text: new Text({
                                    font: 'bold 14px monospace',
                                    text: `${index+1}`,
                                    scale: index < 9 ? 1 : 0.8,
                                    fill: new Fill({color: 'white'}),
                                }),
                                image: new Circle({
                                    radius: 10,
                                    fill: new Fill({color: 'red'}),
                                    stroke: new Stroke({
                                        color: 'white',
                                        width: 2,
                                    }),
                                }),
                            }
                        )
                    )
                })
            }


            // Define Vector Sources **Features have to be an array**
            var source = new VectorSource({
                features: markers ? [...markers] : [],
            });
            // Use the Vector Source inside of VectorLayer
            var vectorLayer = new VectorLayer({
                source: source
            });
            // Raster Layer
            var rasterLayer = new TileLayer({
                source: new OSM(),
            });
            // Hide Attributions
            var attribution = new Attribution({
                collapsible: true,
            });

            var map = new Map({
                controls: defaultControls({ attribution: false }).extend([
                    attribution,
                    new ScaleLine({
                        units: "metric",
                    }),
                ]),
                target: "map",
                layers: [rasterLayer, vectorLayer],
                view: new View({
                    projection: "EPSG:3857",
                    center: getLonLat.some(e => e) > 0 ? projectLonLat : ['0', '0'],
                    zoom: getLonLat.some(e => e) > 0 ? 11 : 0,
                }),
            })

            if (markers?.length > 1) {
                map.getView().fit(source.getExtent())
                map.getView().animate({
                    zoom: map.getView().getZoom() - 1,
                    duration: 500
                })
            }

            const tooltipElement = document.getElementById('tooltip');

            const tooltip = new Overlay({
                element: tooltipElement,
                positioning: 'bottom-center',
                stopEvent: false,
                offset: [-2, -2]
            });

            map.on('pointermove', function(event) {
                map.addOverlay(tooltip)
                const feature = map.forEachFeatureAtPixel(event.pixel,
                    function(feature) {
                        return feature
                    }
                )
                if (feature) {
                    const coordinates = this.getCoordinateFromPixel(event.pixel)
                    tooltip.setPosition(coordinates)

                    tooltip.getElement().innerHTML = `
                        <table class="pa-4">
                            <tr>
                                <td class="text-left" style="opacity: 80">Seen:</td>
                                <td class="text-right text-no-wrap font-weight-bold">${formatDateTime(feature.values_.since)}</td>
                            </tr>
                            <tr>
                                <td class="text-left" style="opacity: 80">Latitude:</td>
                                <td class="text-right font-weight-bold">${feature.values_.latitude}</td>
                            </tr>
                            <tr>
                                <td class="text-left" style="opacity: 80">Longitude:</td>
                                <td class="text-right font-weight-bold">${feature.values_.longitude}</td>
                            </tr>
                        </table>
                    `
                } else {
                    map.removeOverlay(tooltip)
                }
            })
        }
    }
}
</script>

<style>
#map {
    position: relative;
    margin: 0;
    padding: 0;
    height: 400px;
    width: 400px;
    flex: 1 1 auto;
}

#map #tooltip:hover {
    opacity: 1;
}

#tooltip {
    opacity: 0;
    position: absolute;
    background-color: rgba(72, 72, 72, 0.98);
    border: #1f1f1f;
    width: auto;
    border-radius: 6px;
    color: white;
    top: 0;
    left: 0;
}
</style>