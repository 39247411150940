<script setup>

import {computed, onUnmounted} from "vue";
import store from "@/store";

const props = defineProps({
    progressKey: {
        type: String,
        required: true
    }
})

const progress = computed(() => store.getters.progressData[props.progressKey])

const progressValue = computed(() => {
    if (progress.value) {
        return progress.value.progressPercent
    }
    return '';
})

onUnmounted(() => {
    store.commit("PROGRESS_REMOVE", props.progressKey)
})

</script>

<template>
    <div class="mt-10 d-flex align-center justify-center flex-column">
        <div v-if="progress">
            <div class="d-flex justify-content align-center flex-column mt-6" style="width: 400px">
                <div class="d-flex align-start tertiary--text">
                    <div class="text-h6 fontMonospace font-weight-medium">
                        {{ progressValue.toFixed(1) }}
                    </div>
                    <div class="text-subtitle-2" style="margin-top: 2px">
                        %
                    </div>
                </div>
                <v-progress-linear :value="progressValue" buffer-value="0" color="tertiary" height="10" stream striped/>
            </div>
        </div>
        <v-progress-circular
            v-else
            :size="80"
            :width="8"
            color="tertiary"
            indeterminate
        />
    </div>

</template>

<style scoped>

</style>