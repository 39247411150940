var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ImportAddressesDialog, {
    attrs: {
      "case-id": _vm.caseId,
      "identities-headers": _setup.identitiesHeaders,
      "default-row-values": _setup.defaultRowValues,
      "action-name": "importCaseAddresses",
      "import-format": _setup.ImportFormat.Case,
    },
    on: {
      addressesImported: function ($event) {
        return _setup.emit("addressesImported")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }