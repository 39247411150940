<template>
    <v-select-validateable
        :disabled="disabled"
        ref="select"
        label="Blockchain"
        :items="currencies"
        :value="value"
        item-text="name"
        v-bind="$attrs"
        item-value="unit"
        @input="change"
        :outlined="outlined"
    >
        <template slot="selection" slot-scope="data">
            <v-flex xs2>
                <v-avatar size="25px">
                    <img :src="data.item.image" />
                </v-avatar>
            </v-flex>
            <v-flex class="ml-1">{{ data.item.name }}</v-flex>
        </template>
        <template slot="item" slot-scope="data">
            <v-list-item-avatar size="30">
                <img class="my-1" :src="data.item.image" />
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-html="data.item.name" />
            </v-list-item-content>
        </template>
    </v-select-validateable>
</template>

<script>
export default {
    name: "BlockchainCryptoPicker",
    props: {
        value: {
            validator: prop => typeof prop === 'string' || prop === null,
            required: true
        },
        currencies: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        outlined: {
            default: true,
            required: false,
            type: Boolean
        },
    },
    methods: {
        change(newVal) {
            if(newVal !== this.value) {
                this.$emit("input", newVal)
            }
        }
    }
}
</script>
