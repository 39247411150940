var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.wasActiveInLightningNetwork
    ? _c(
        "div",
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-icon",
                          _vm._g({ attrs: { color: "#ffc001" } }, on),
                          [_vm._v("\n                mdi-flash\n            ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1800098229
              ),
            },
            [
              _vm._v(
                "\n        This node propagated Lightning network transactions.\n    "
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }