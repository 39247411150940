<script setup>
import PrimarySelector from "@/components/common/PrimarySelector.vue";
import {computed, inject} from "vue";
import store from "@/store";
import FancyCard from "@/components/common/Cards/FancyCard.vue";

const data = inject("data")
const loading = inject("Loading")

const currency = computed(() => store.getters.currency)

const computedOwners = computed(() => {
    if (!data) {
        return []
    }

    return data.value.cluster.owners.map(owner => {
        return {...owner, isPrimary: owner.id === data.value.cluster.owner?.id}
    })
})

const headersOwners = [
    { text: 'Name', value: 'name', align: 'center'},
    { text: 'Primary', value: 'isPrimary', align: 'center'  },
]

const setPrimaryOwner = (ownerId) => {
    store.commit("CLUSTER_SET_PRIMARY_OWNER_BY_ID", {id: ownerId})
}

</script>

<template>
    <FancyCard>
        <template #header>
            <div class="d-flex align-center">
                <img src="/img/ico/owner-icon.svg" alt="Owner" style="filter: opacity(0.6); width: 22px; margin-right: 8px" />
                <span>Cluster Owners</span>
            </div>
        </template>
        <template #body>
            <div v-if="loading">
                <v-skeleton-loader
                    height="173"
                    type="image"
                ></v-skeleton-loader>
            </div>
            <div v-else>
                <v-data-table
                    v-if="computedOwners.length > 0"
                    dense
                    :headers="headersOwners"
                    :items="computedOwners"
                    hide-default-footer
                    class="border"
                    :items-per-page="-1"
                >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td class="text-overflow robotoMono">
                                {{ props.item.name }}
                            </td>
                            <td class="text-overflow">
                                <PrimarySelector :isPrimary="props.item.isPrimary"
                                                 :item="props.item"
                                                 :currency="currency"
                                                 :selectable="false"
                                                 @set-primary-event="setPrimaryOwner"
                                />
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <div v-else class="title font-weight-light text-center">
                    No owners assigned.
                </div>
            </div>
        </template>
    </FancyCard>
</template>

<style scoped>

</style>