import Vue from 'vue'
import Vuex from 'vuex'

//Refactored Modules from JS to TS
import CryptoAddress from "./modules/CryptoAddress"
import BalanceTree from "./modules/BalanceTree"
import AddressTaxes from "./modules/AddressTaxes"
import AddressCluster from "./modules/AddressCluster"
import AddressFunds from "./modules/AddressFunds"
import AddressInteractions from "./modules/AddressInteractions"

import AddressMetadata from "./modules/AddressMetadata"
import ClusterMetadata from "./modules/ClusterMetadata"
import AdvanceSearch from "./modules/AdvanceSearch"
import Alarm from "./modules/Alarm"
import Block from "./modules/Block"
import Categories from "./modules/Categories"
import Currency from "./modules/Currency"
import Peers from "./modules/Peers"
import Dashboard from "./modules/Dashboard"
import File from "./modules/File"
import Graph from "./modules/Graph"
import Identity from "./modules/Identity"
import Log from "./modules/Log"
import Notification from "./modules/Notification"
import Owner from "./modules/Owner"
import Progress from "./modules/Progress"
import Case from "./modules/Case"
import Role from "./modules/Role"
import Search from "./modules/Search"
import Transactions from "./modules/Transactions"
import User from "./modules/User"
import NeoClusterMetadata from "./modules/NeoClusterMetadata";
import Settings from "./modules/Settings"
import Fiats from "@/store/modules/Fiats";
import CryptoInterceptor from "@/store/modules/CryptoInterceptor";
import Cluster from "@/store/modules/Cluster"
import MenuAddressDetail from "@/store/modules/MenuAddressDetail"
import GraphExplorer from "@/store/modules/GraphExplorer"
import TransactionsFilterGeo from "@/store/modules/TransactionsFilterGeo"
import Status from "@/store/modules/Status";

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        // ----------- Refactored modules from JS to TS
        CryptoAddress,
        BalanceTree,
        AddressTaxes,
        AddressCluster,
        AddressFunds,
        AddressInteractions,
        //------------
        AddressMetadata,
        ClusterMetadata,
        NeoClusterMetadata,
        AdvanceSearch,
        Alarm,
        Block,
        Categories,
        Currency,
        Peers,
        Dashboard,
        File,
        Graph,
        Identity,
        Log,
        Notification,
        Owner,
        Progress,
        Case,
        Role,
        Search,
        Transactions,
        User,
        Settings,
        Fiats,
        CryptoInterceptor,
        Cluster,
        MenuAddressDetail,
        TransactionsFilterGeo,
        GraphExplorer,
        Status
    },
    strict: process.env.NODE_ENV !== 'production'
})
