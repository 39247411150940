import apiClient from "@/services/apiClient"
import Vue from "vue";

const state = {
    settings: {
        server: { jwtPrefix: location.origin + "/jwt/v1"},
        app: { name: "COINØMON", brand_type: "text" },
        filesStorage: location.origin + "/storage"
    },
    settingsLoading: false,
    settingsFetched: false,
    debug: false
}

const getters = {
    settings: (state) => state.settings,
    settingsLoading: (state) => state.settingsLoading,
    settingsFetched: (state) => state.settingsFetched,
    debug: (state) => state.debug,
    isDevelEnvironment: (state) => state.settings?.app.environment === "devel",
    isStagingEnvironment: (state) => state.settings?.app.environment === "staging",
}


const mutations = {
    SETTINGS_FETCHED(state)
    {
        state.settingsFetched = true
    },
    SETTINGS_START_LOADING(state) {
        state.settingsLoading = true
    },
    SETTINGS_LOADED(state, data) {
        Vue.set(state, "settings", data);
    },
    SETTINGS_FINISHED_LOADING(state) {
        state.settingsLoading = false
    },
    SETTINGS_SET_DEBUG(state, newValue) {
        state.debug = newValue
    }
}

const actions = {
    async getSettings({commit, dispatch}) {
        commit('SETTINGS_START_LOADING')
        try {
            const response = await apiClient.GET(`settings`,
                {
                    headers: {'DisableAuthorization': true}
                })
            commit('SETTINGS_LOADED', response)
            commit('SETTINGS_FETCHED')
            commit('SET_FIATS', response.fiats)
            commit('SET_CURRENCIES', response.cryptos)

            // SharpApi prefix is loaded, connect to websocket
            dispatch("connectToProgress")
        } catch (e) {
            this.dispatch("error", e.userFriendlyMessage)
        }
        commit('SETTINGS_FINISHED_LOADING')
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}