<template>
    <div class="height100 scroll-y">
        <div v-if="addressLoading">
            <ProgressCircular class="pt-10" :color="currency.unit"/>
        </div>

        <v-container v-if="!addressLoading && !addressInfo">
            <div v-if="addressInfo === null || !addressId">
                <AddressNotFoundInfo
                    class="my-2"
                    :address="addressId"
                    :message="'Provided identifier does not exist in the blockchain!'"
                />
            </div>
        </v-container>

        <v-container v-if="!addressLoading && addressInfo" fluid class="pa-0">
            <h1>Cluster</h1>
            <v-divider/>
            <v-divider class="mb-3"/>


            <div v-if="!hasLocalClusters && !addressClustersLoading">
                <EmptyClusterInfo
                    :address="addressId"
                    :message="'This address does not form any cluster with other addresses!'"
                />
            </div>

            <div v-if="clusterNotFound">
                <AddressNotFoundInfo
                    class="my-2"
                    :address="addressId"
                    :message="'This address does not belong to the given cluster!'"
                />
            </div>

            <div class="mb-2 ml-6">
                <v-row style="max-width: 900px;" justify="center" align="center">
                    <v-col
                        class="d-flex justify-center"
                        cols="12"
                        sm="2"
                        md="1"
                        lg="1">
                        <CancelLoadingButton v-if="currentClusterLoading && !clusterNotFound"
                                             :color="currency.unit"
                                             @cancel-button-event="cancelRequest"/>
                    </v-col>
                    <v-col cols="12"
                           sm="10"
                           md="7"
                           lg="7">
                        <AddressClusterPicker
                            v-model="entityAddress"
                            ref="AddressClusterPickerRef"
                        />
                    </v-col>
                    <v-col cols="12"
                           sm="12"
                           md="4"
                           lg="4"
                           class="d-flex justify-center">

                        <v-divider vertical class="d-inline ml-n6 mt-n2"/>
                        <v-divider vertical class="d-inline mr-2 mt-n2"/>

                        <v-card loading="true" elevation="0" class="caption mt-n2" width="250px">
                            <v-row justify="center" no-gutters>

                                <v-col cols="6">
                                    <p class="mb-1">
                                        Type:
                                    </p>
                                </v-col>
                                <v-col cols="6">
                                    <v-skeleton-loader v-if="addressClustersLoading" class="mt-1" width="40px" height="12px" type="image" />
                                    <p v-else class="mb-1">
                                        <strong> {{
                                                currentLocalCluster ? currentLocalCluster.isCustom ? "custom" : "co-spent" : "#"
                                            }} </strong>
                                    </p>
                                </v-col>
                                <v-col cols="6">
                                    <p class="ma-0 pa-0">
                                        # of Addresses:
                                    </p>
                                </v-col>
                                <v-col cols="6">
                                    <v-skeleton-loader v-if="addressClustersLoading" class="mt-1" width="40px" height="12px" type="image" />
                                    <p v-else class="ma-0">
                                        <strong> {{
                                                currentLocalCluster ? currentLocalCluster.addresses : "#"
                                            }} </strong>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <v-divider class="mb-4"/>
            <div v-if="currentCluster">
                <router-view :currency="currency" @cluster-update-event="refreshClusterList"/>
            </div>
        </v-container>
    </div>
</template>
<script>

import EmptyClusterInfo from "@/components/address/EmptyClusterInfo"
import {ProgressCircular} from "@/components/common"
import {mapGetters} from 'vuex'
import AddressNotFoundInfo from '@/components/address/AddressNotFoundInfo'
import CancelLoadingButton from "@/components/common/Buttons/CancelLoadingButton";
import AddressClusterPicker from "@/components/address/AddressClusterPicker";
import ClusterSummary from "@/views/Cluster/Summary/ClusterSummary.vue";
import {computed} from "vue";
import {safeRouterPush} from "@/utils";
import {useGetData} from "@/components/common/Composables/useGetData";
import {DataState} from "@/components/common/types/data";

export default {
    name: 'AddressCluster',
    components: {
        ClusterSummary,
        AddressClusterPicker,
        CancelLoadingButton,
        EmptyClusterInfo,
        ProgressCircular,
        AddressNotFoundInfo
    },
    provide() {
        return {
            entityId: computed(() => this.currentCluster),
            clusterInfo: computed(() => this.clusterInfo),
            clusterInfoState: computed(() => this.clusterInfoState),
            getClusterInfo: computed(() => this.getClusterInfo)
        }
    },
    data() {
        return {
            entityAddress: null,
            clusterNotFound: false,
            clusterInfoState: DataState.Initial,
            clusterInfo: null,
            getClusterInfo: null,
        }
    },
    computed: {
        ...mapGetters([
            "addressClustersLoading", "currency", "addressInfo", "addressLoading", "addressId", "currentClustersCluster", "addressClustersLoading", "currentClustersCluster", "local_clusters", "addressClusterData", "addressClusterLoading", "addressClusterCount", "addressClusterCounting", "addressClusterPagination", "addressClusterHistoryFallback"]),

        currentCluster() {
            return this.currentClustersCluster
        },

        currentClusterLoading() {
            return !!this.addressClusterLoading[this.currentCluster]
        },

        hasLocalClusters() {
            return this.local_clusters.length !== 0;
        },
        currentLocalCluster() {
            return this.local_clusters.filter(x => {
                return x.entityid === this.entityAddress
            })[0]
        }

    },
    beforeUpdate() {
        //Update visual slider (workaround)
        this.$emit("component-loaded-event");
    },
    created() {
        const { dataState, data, getData } = useGetData("getClusterInfo", {
            currency: this.currency.unit,
            entity: this.currentCluster
        }, false)
        this.clusterInfoState = dataState
        this.clusterInfo = data
        this.getClusterInfo = getData
    },
    mounted() {
        if (this.$route.params.entity) {
            this.entityAddress = this.$route.params.entity
        } else if (this.currentCluster !== null) {
            this.entityAddress = this.currentCluster;
        }

        if (this.entityAddress) {
            this.getClusterInfo({entity: this.entityAddress})
        }
    },
    methods: {
        refreshClusterList() {
            this.$refs.AddressClusterPickerRef.refresh()
        },
        cancelRequest() {
            this.$store.commit("ADDRESS_CLUSTER_LOADING_CANCEL", {entity: this.entityAddress})
            const fallback = this.addressClusterHistoryFallback[this.entityAddress]
            if (fallback) {
                this.$router.push({query: {...fallback}})
            } else {
                this.entityAddress = null
            }
        },
        checkCurrentClusterForExistence() {
            let clusterFound = false
            this.local_clusters.forEach(x => {
                if (x.entityid === this.entityAddress)
                    clusterFound = true
            })
            this.clusterNotFound = !clusterFound
            if (!clusterFound) {
                this.entityAddress = null
            }
        },

    },
    watch: {
        $route(to, from) {
            if (to.params.entity !== from.params.entity) {
                if (this.$route.params.entity) {
                    this.entityAddress = this.$route.params.entity
                    this.checkCurrentClusterForExistence()
                } else {
                    this.entityAddress = null
                }
            }
        },
        "entityAddress": function (newVal, oldVal) {
            let clusterString = "/cluster"
            let indexOfLastClusterOccurrence = this.$route.fullPath.lastIndexOf(clusterString)

            if (newVal) {
                this.$store.commit("ADDRESS_CLUSTERS_SET_CURRENT", newVal)

                let newPath = `${this.$route.fullPath.slice(0, indexOfLastClusterOccurrence + clusterString.length)}/${newVal}`
                if (this.$route.params.entity !== this.entityAddress) {
                    this.$router.push({path: newPath})
                    this.getClusterInfo({entity: newVal})
                }
            } else {
                this.$store.commit("ADDRESS_CLUSTERS_SET_CURRENT", null)

                let newPath = `${this.$route.fullPath.slice(0, indexOfLastClusterOccurrence + clusterString.length)}`
                if (this.$route.params.entity !== this.entityAddress) {
                    safeRouterPush(this.$router, {path: newPath})
                }
            }
        },
        "local_clusters": function (newVal, oldVal) {
            if (this.entityAddress) {
                this.checkCurrentClusterForExistence()
            }
        }
    },
}
</script>

<style scoped>

</style>

