var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: {
      loading: _vm.loading,
      title: _vm.isEditModal ? "Edit case" : "Create new case",
      width: "auto",
      "loading-width": "400",
    },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2 align-center" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("v-text-field-validateable", {
                            attrs: {
                              label: "Title",
                              outlined: "",
                              dense: "",
                              rules: "required",
                            },
                            model: {
                              value: _vm.detail.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "title", $$v)
                              },
                              expression: "detail.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { cols: "5" },
                        },
                        [
                          _c("DateTimePicker", {
                            attrs: {
                              supportsSeconds: true,
                              label: "Reported at",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.detail.reported_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "reported_at", $$v)
                              },
                              expression: "detail.reported_at",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column align-end",
                          attrs: { cols: "2" },
                        },
                        [
                          _c("IsHiddenCheckboxWithIcon", {
                            model: {
                              value: _vm.detail.is_hidden,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "is_hidden", $$v)
                              },
                              expression: "detail.is_hidden",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0 mb-n4",
                                attrs: { color: "tertiary", dense: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              "open-delay": "1000",
                                              bottom: "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: _vm.detail
                                                              .is_warning
                                                              ? "warning"
                                                              : "primary",
                                                            dark: "",
                                                            size: "28",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.detail
                                                              .is_warning
                                                              ? "report"
                                                              : "report_off"
                                                          ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "Case " +
                                                  _vm._s(
                                                    _vm.detail.is_warning
                                                      ? "warns"
                                                      : "does not warn"
                                                  ) +
                                                  " creator"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.detail.is_warning,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "is_warning", $$v)
                                  },
                                  expression: "detail.is_warning",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "ma-2" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-textarea-validateable", {
                            attrs: {
                              label: "Description",
                              "no-resize": "",
                              outlined: "",
                              rows: "4",
                              rules: "required",
                            },
                            model: {
                              value: _vm.detail.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "description", $$v)
                              },
                              expression: "detail.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.casesModalState === _vm.modalState.Pending,
                    color: "error",
                    dark: "",
                    outlined: "",
                  },
                  on: { click: close },
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.casesModalState === _vm.modalState.Pending,
                    color: "success",
                    dark: "",
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save(close, setDisabled)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.isEditModal ? "Save case" : "Create case") +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }