<script setup>
import store from "@/store";
import {ref} from "vue";
import BaseModalDialog from "@/components/common/Base/BaseModalDialog.vue";

const props = defineProps({
    currency: {
        type: Object
    }
})

const port = ref()
const ip = ref()

const pending = ref()
const isIpValid = ref()
const isPortValid = ref()

const save = async (close) => {
    if (isIpValid.value && isPortValid.value)
    {
        pending.value = true
        await store.dispatch("setCryptoInterceptorPeerStatus", {currency: props.currency.unit, ipaddress: ip.value, action: "start", port: port.value})
        store.commit("CRYPTOINTERCEPTOR_REFETCH_DATA", true)
        store.commit("CRYPTOINTERCEPTOR_REFETCH_FILES", true)
        close()
    }
}

const validatorIP = ref()
const validateIP = async () => {
    const isValid = await validatorIP.value.validate();
    isIpValid.value = !!(isValid && ip.value);
}

const validatorPort = ref()
const validatePort = async () => {
    const isValid = await validatorPort.value.validate();
    isPortValid.value = !!(isValid && port.value);
}

const loadState = () => {
    ip.value = ""
    port.value = props.currency.interceptorDefaultConnectPort
    isIpValid.value = false
    isPortValid.value = true
    pending.value = false
}

</script>

<template>
    <BaseModalDialog
        width="80%"
        max-width="500px"
        title='Monitor new node'
        @load-modal-state-event="loadState"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>

        <template #content>
            <div class="mb-4">
                <ValidationObserver ref="validatorIP" mode="aggressive">
                    <v-text-field-validateable rules="ip|required"
                                               style="width: 330px;"
                                               dense
                                               label="IP Address"
                                               @keyup="validateIP"
                                               class="monospace"
                                               outlined
                                               autofocus
                                               :disabled="pending"
                                               v-model="ip"/>
                </ValidationObserver>
            </div>
            <div style="width: 80px;" class="mb-2">
                <ValidationObserver ref="validatorPort" mode="aggressive">
                    <v-text-field-validateable rules="port|required"
                                               dense
                                               label="Port"
                                               @keyup="validatePort"
                                               outlined
                                               class="none_pointer_events monospace"
                                               :disabled="pending"
                                               type="number"
                                               hide-spin-buttons
                                               v-model="port"/>
                </ValidationObserver>
            </div>
            <div v-if="pending">
                Starting monitoring of <span>{{ip}}</span>
                <v-progress-linear
                    :color="currency.unit"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
            </div>
        </template>

        <template #footer="{close}">
            <v-btn :disabled="pending" ref="closeButton"  class="ml-4" color="error" outlined @click="close" >Cancel</v-btn>
            <v-spacer />
            <v-btn
                :disabled="pending"
                class="mr-4"
                color="success"
                outlined
                @click="save(close)"
            >{{"Monitor node"}}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<style scoped>

</style>