<template>
    <v-menu
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn-toggle
                v-model="currentModeModel"
                active-class="font-weight-bold"
                background-color="grey"
                color="grey lighten-4"
                dense
                light
                mandatory
                style="border-bottom-right-radius: 0; border-top-right-radius: 0;">
                <v-btn @click="ChangeToCryptoMode">
                    <span style="color: rgba(0, 0, 0, 0.87) !important;">
                        {{ currentCryptoName }}
                    </span>
                </v-btn>
                <v-btn @click="ChangeToFiatMode">
                    <span style="color: rgba(0, 0, 0, 0.87) !important;">
                        {{ currentFiatName }}
                    </span>
                </v-btn>
            </v-btn-toggle>
            <v-btn
                class="picker grey lighten-2"
                elevation="0"
                light
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>
                    mdi-menu-down
                </v-icon>
            </v-btn>
        </template>
        <v-list
            :disabled="settingsLoading"
            class="picker elevation-0"
            dense
        >
            <div>
                <v-list-item-group
                    v-model="currentFiatModel"
                    @change="ChangeFiat"
                    mandatory
                    class="d-flex"
                >
                    <div v-for="columnIndex in this.FiatColumnIndexes"
                         :key="columnIndex">
                        <v-list-item
                            v-for="(item, index) in fiats.slice((columnIndex*fiatsPerColumn), (columnIndex*fiatsPerColumn)+fiatsPerColumn)"
                            :key="item.unit"
                            :input-value="index + (columnIndex*fiatsPerColumn)"
                            :value="index + (columnIndex*fiatsPerColumn)">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.unit"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>

                </v-list-item-group>
            </div>
        </v-list>
    </v-menu>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: "CurrencyPicker",
    data() {
        return {
            currentFiatModel: 0,
            currentModeModel: 0,
            fiatsPerColumn: 5,
        };
    },
    computed: {
        ...mapGetters(["currency", "settingsLoading", "fiats", "currentFiat", "fiatActive"]),
        currentCryptoName() {
            if (this.currency)
                return this.currency.unit

            return " - "
        },
        currentFiatName() {
            return this.fiats[this.currentFiatModel].unit
        },
        FiatColumnIndexes() {
            const numberOfColumns = Math.ceil(this.fiats.length / this.fiatsPerColumn);
            const columnIndexes = [];

            for (let i = 0; i < numberOfColumns; i++) {
                columnIndexes.push(i);
            }
            return columnIndexes
        },
    },
    mounted() {
        this.$store.commit("SET_CURRENT_FIAT", this.fiats[this.currentFiatModel])
    },
    methods: {
        ChangeToCryptoMode() {
            this.$store.commit('SET_FIAT_ACTIVE', false)
        },
        ChangeToFiatMode() {
            this.currentModeModel = 1
            this.$store.commit('SET_FIAT_ACTIVE', true)
        },
        ChangeFiat() {
            this.$store.commit("SET_CURRENT_FIAT", this.fiats[this.currentFiatModel])
            this.ChangeToFiatMode()
        }
    }
};
</script>

<style scoped>
.whiteBackground {
    background-color: white !important;
}

.picker {
    min-width: 20px !important;
    padding: 0 !important;
    margin: 0 !important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
</style>