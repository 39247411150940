var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cont border expand" }, [
    _c(
      "div",
      { staticClass: "overflow-y-auto width100 height100 white" },
      [
        _c(
          "v-toolbar",
          { attrs: { dense: "", text: "" } },
          [
            _c(
              "v-btn",
              {
                attrs: { icon: "" },
                on: {
                  click: function ($event) {
                    return _vm.$store.dispatch("graphCloseBottomDrawer")
                  },
                },
              },
              [_c("v-icon", [_vm._v("close")])],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("v-divider"),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "slide-y-reverse-transition" } },
          [_c("router-view")],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }