var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "relative peerMapContainer",
      on: {
        peerDetailClick: _setup.onOpenDetailClick,
        peerNextClick: _setup.onNextPeerClick,
        peerPreviousClick: _setup.onPreviousPeerClick,
      },
    },
    [
      _c(_setup.PeerDetailModal, {
        ref: "peerDetailModalRef",
        attrs: {
          currency: _setup.currentCurrency.unit,
          loading: _setup.peerDetailLoading,
          "peer-detail": _setup.peerDetailData,
          "peer-id": _setup.peerDetailId,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-start mb-n4 mt-n2" },
        [
          _c("v-switch", {
            attrs: {
              color: "tertiary",
              inset: "",
              "prepend-icon": "mdi-thermometer",
            },
            on: { change: _setup.onHeatMapChange },
            model: {
              value: _setup.isHeatMapEnabled,
              callback: function ($$v) {
                _setup.isHeatMapEnabled = $$v
              },
              expression: "isHeatMapEnabled",
            },
          }),
          _vm._v(" "),
          _c("v-switch", {
            attrs: {
              color: "tertiary",
              inset: "",
              "prepend-icon": "mdi-map-marker",
            },
            on: { change: _setup.onPeerMarkerChange },
            model: {
              value: _setup.isPeerMarkersEnabled,
              callback: function ($$v) {
                _setup.isPeerMarkersEnabled = $$v
              },
              expression: "isPeerMarkersEnabled",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "6px" } },
        [
          _c("v-progress-linear", {
            attrs: {
              active:
                _setup.dataState === _setup.DataState.Loading ||
                _setup.loadingMarkers,
              color: "tertiary",
              height: "100%",
              indeterminate: "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "6" } }, [_vm._m(0)], 1),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(_setup.DataTableVirtualScroll, {
                attrs: {
                  data: _setup.compData,
                  debounceInterval: _vm.country ? 5 : 50,
                  headers: _setup.peerHeaders,
                  "no-data-text": "No peer data",
                  "table-max-height": "800px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "tr",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _setup.onOpenDetailClick({
                                  detail: item.node_id,
                                })
                              },
                            },
                          },
                          [
                            _c("td", { staticClass: "fontMonospace" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm._f("truncate")(item.host, 16)) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "fontMonospace" }, [
                              _vm._v(
                                "\n                            (" +
                                  _vm._s(item.longitude) +
                                  "," +
                                  _vm._s(item.latitude) +
                                  ")\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.country_code) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("truncate")(
                                      item.city ? item.city : "",
                                      16
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "fontMonospace text-right" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.asn) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "info",
                                      icon: "",
                                      small: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _setup.locatePeer(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        "\n                                    mdi-crosshairs-gps\n                                "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      _setup.LMap,
      {
        ref: "map",
        staticStyle: { height: "800px", "z-index": "0" },
        attrs: { center: _setup.center, zoom: _setup.zoom },
      },
      [_c(_setup.LTileLayer, { attrs: { url: _setup.url } })],
      1
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }