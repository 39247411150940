var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-4", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "" } },
        [
          _c("h1", [_vm._v("Identities")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("IdentityDetailModal", {
            attrs: {
              addresses: _vm.filteredAddresses,
              isAddressesLoading: _vm.isFilteredAddressesLoading,
            },
            on: {
              "save-event": _vm.createIdentity,
              "address-filter-changed-event": _vm.addressFilterChanged,
            },
            scopedSlots: _vm._u([
              {
                key: "showbutton",
                fn: function ({ on }) {
                  return [
                    _c(
                      "CreateButton",
                      _vm._g({ attrs: { text: "Add new identity" } }, on)
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-0 border my-2",
        attrs: {
          headers: _vm.headers,
          items: _vm.identities.data,
          options: _vm.filter,
          "server-items-length": _vm.identities.total
            ? _vm.identities.total
            : 0,
          loading: _vm.isIdentitiesLoading,
          "footer-props": {
            "items-per-page-options": [15, 30, 50, 100],
            "show-current-page": true,
            showFirstLastPage: true,
          },
          dense: "",
        },
        on: {
          "update:options": function ($event) {
            _vm.filter = $event
          },
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.headers, function (h) {
              return {
                key: `header.${h.value}`,
                fn: function ({ header }) {
                  return [
                    h.value === "label"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          staticStyle: { "padding-bottom": "18px" },
                          attrs: {
                            "append-icon": "search",
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterLabel,
                            callback: function ($$v) {
                              _vm.$set(_vm.debouncedFilter, "filterLabel", $$v)
                            },
                            expression: "debouncedFilter.filterLabel",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "crypto"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: { "margin-bottom": "auto" },
                          },
                          [
                            _c("BlockchainCryptoPickerSlim", {
                              attrs: {
                                currencies: _vm.allCurrenciesExceptUnknown,
                                dense: "",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value: _vm.filter.filterCurrency,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "filterCurrency", $$v)
                                },
                                expression: "filter.filterCurrency",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "source"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          attrs: {
                            "append-icon": "search",
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterSource,
                            callback: function ($$v) {
                              _vm.$set(_vm.debouncedFilter, "filterSource", $$v)
                            },
                            expression: "debouncedFilter.filterSource",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "url"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          attrs: {
                            "append-icon": "search",
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.debouncedFilter, "filterUrl", $$v)
                            },
                            expression: "debouncedFilter.filterUrl",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "address"
                      ? _c("v-text-field", {
                          key: h.value,
                          staticClass: "text-field-label-style",
                          staticStyle: { "padding-bottom": "18px" },
                          attrs: {
                            "append-icon": "search",
                            label: header.text,
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                          },
                          on: { input: _vm.debounceInput },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.debouncedFilter.filterAddress,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.debouncedFilter,
                                "filterAddress",
                                $$v
                              )
                            },
                            expression: "debouncedFilter.filterAddress",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "created_at"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: {
                              "padding-top": "20px",
                              "margin-bottom": "auto",
                              "padding-bottom": "auto",
                            },
                          },
                          [_vm._v("\n                Created At\n            ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    h.value === "blank"
                      ? _c(
                          "div",
                          {
                            key: h.value,
                            staticStyle: {
                              "padding-top": "20px",
                              "margin-bottom": "auto",
                              "padding-bottom": "19px",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(h.text) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              }
            }),
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _c("tr", [
                    _c("td", { staticClass: "text-left text-truncate" }, [
                      _c("span", [_vm._v(_vm._s(item.label))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c("BlockchainCryptoBadge", {
                          attrs: {
                            iconWidth: 24,
                            currencies: _vm.currencies,
                            crypto: item.address.crypto,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-left text-truncate" }, [
                      _c("span", [_vm._v(_vm._s(item.source))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-truncate text-left",
                        staticStyle: { "max-width": "200px" },
                      },
                      [
                        _c(
                          "a",
                          { staticClass: "link", attrs: { href: item.url } },
                          [_vm._v(_vm._s(item.url))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "dynamicTruncate",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [
                        _c(
                          "RouteToAddress",
                          {
                            attrs: {
                              currency: _vm.filter.filterCurrency,
                              address: item.address.address,
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "text" },
                              [
                                _c("Copyable", {
                                  attrs: {
                                    justify: "left",
                                    "on-copy": item.address.address,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "text",
                                        fn: function ({ on }) {
                                          return [
                                            _c("div", _vm._g({}, on), [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "stringMiddleShortener"
                                                    )(item.address.address, 26)
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "vertical-align": "middle",
                          "white-space": "nowrap",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDateTime")(item.created_at))
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "min-width": "92px",
                          "text-align": "center",
                          "vertical-align": "middle",
                        },
                        attrs: { width: "10%" },
                      },
                      [
                        _c("IdentityDetailModal", {
                          attrs: {
                            identityDetail: item,
                            addresses: _vm.filteredAddresses.data,
                            isAddressesLoading: _vm.isFilteredAddressesLoading,
                          },
                          on: {
                            "save-event": _vm.editIdentity,
                            "address-filter-changed-event":
                              _vm.addressFilterChanged,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "showbutton",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "TableEditButton",
                                      _vm._g(
                                        {
                                          attrs: {
                                            editButtonTooltip: "Edit identity",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("TableDeleteModalDialog", {
                          attrs: {
                            description: `Do you really want to delete identity ${item.label}`,
                            itemToDelete: item,
                            tooltip: "Delete Identity",
                          },
                          on: { "delete-event": _vm.deleteIdentity },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
            {
              key: "footer.page-text",
              fn: function ({ pageStart, pageStop, itemsLength }) {
                return [
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("formatNumber")(pageStart)) +
                        " - " +
                        _vm._s(_vm._f("formatNumber")(pageStop)) +
                        " of " +
                        _vm._s(_vm._f("formatNumber")(itemsLength)) +
                        "\n            "
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }