<script setup>
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import {computed} from "vue";

const props = defineProps({
    numberOfAddressesYetToGenerate: {
        type: Number,
        required: true
    },
    useHardenedAddresses: {
        type: Boolean,
        required: true
    },
    numberOfRowsToGenerate: {
        type: Number,
        required: true
    },
    startingIndex: {
        type: Number,
        required: true
    },
    currency: {
        type: Object,
        required: true
    },
    bchAddressFormat: {
        type: String,
        required: true,
    },
    selectedBip: {
        type: Number,
        required: true
    }
})

const bchAddressFormats = [{value: 'Cash', prefix: 'bitcoincash:q...'}, {value: 'BitPay', prefix: 'C...'}, {value: 'Legacy', prefix: '1...'}]

const isSegWit = computed(() => {
    return props.selectedBip === 49 || props.selectedBip === 84 || props.selectedBip === 141
})

defineEmits(['parameterChange', 'update:numberOfRowsToGenerate', 'update:startingIndex', 'update:useHardenedAddresses', 'update:bchAddressFormat'])

</script>

<template>
    <div>
        <h3>
            Address Derivation Options
        </h3>
        <FancyCard style="max-width: 1000px" class="d-flex" dense :header="false">
            <template #body>
                <div class="d-flex align-center" style="gap: 8px">
                    <v-checkbox
                        dense
                        style="width: 220px; padding-top: 0"
                        label="Use Hardened Addresses"
                        :color="currency.hexColor"
                        :input-value="useHardenedAddresses"
                        @change="$emit('update:useHardenedAddresses', Boolean($event))"
                        hide-details
                    />
                    <v-text-field
                        filled
                        dense
                        :value="numberOfRowsToGenerate"
                        @input="$emit('update:numberOfRowsToGenerate', Number($event))"
                        label="Number of rows to generate"
                        placeholder="Enter a whole number"
                        type="number"
                    />
                    <v-text-field
                        filled
                        dense
                        :value="startingIndex"
                        @input="$emit('update:startingIndex', Number($event))"
                        label="Starting index"
                        placeholder="Enter a whole number"
                        type="number"
                        :disabled="numberOfAddressesYetToGenerate > 0"
                    />
                    <div v-if="currency.unit === 'BCH'">
                        <v-tooltip :disabled="!isSegWit" top>
                            <template #activator="{ on, attrs }">
                                <div v-on="on" v-bind="attrs">
                                    <v-select
                                        dense
                                        filled
                                        @change="$emit('update:bchAddressFormat', $event)"
                                        :items="bchAddressFormats"
                                        item-text="value"
                                        item-value="value"
                                        :value="bchAddressFormat"
                                        label="Address Format"
                                        :disabled="isSegWit"
                                    >
                                        <template #item="{ item }">
                                            <div class="d-flex" style="gap: 8px">
                                                <div>{{ item.value }}</div>
                                                <span style="opacity: 0.6">-</span>
                                                <i style="opacity: 0.6">"{{ item.prefix }}"</i>
                                            </div>
                                        </template>
                                        <template #selection="{ item }">
                                            <div class="d-flex" style="gap: 8px">
                                                <div>{{ item.value }}</div>
                                                <span style="opacity: 0.6">-</span>
                                                <i style="opacity: 0.6">"{{ item.prefix }}"</i>
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </template>
                            <div class="text-center">This option is available only for the Legacy address type<br>(<i>P2PKH, used by BIP32 / BIP44</i>)</div>
                        </v-tooltip>
                    </div>
                </div>
            </template>
        </FancyCard>
    </div>
</template>

<style scoped>

</style>