import { render, staticRenderFns } from "./NotificationEmail.vue?vue&type=template&id=131fa9cc&scoped=true&"
import script from "./NotificationEmail.vue?vue&type=script&lang=js&"
export * from "./NotificationEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131fa9cc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('131fa9cc')) {
      api.createRecord('131fa9cc', component.options)
    } else {
      api.reload('131fa9cc', component.options)
    }
    module.hot.accept("./NotificationEmail.vue?vue&type=template&id=131fa9cc&scoped=true&", function () {
      api.rerender('131fa9cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/alarm/NotificationEmail.vue"
export default component.exports