<template>
    <div>
        <v-layout row class="px-2 py-1">
            <b>Identity</b>
            <a :href="data.url" target="_blank" class="ml-2">{{data.label}}</a>
            <v-spacer />
            <div class="no-wrap pl-2">{{data.created_at | moment('YYYY-MM-DD HH:mm:ss Z')}}</div>
        </v-layout>
        <v-divider />
        <v-layout justify-space-between row wrap class="mx-2 pb-1">
            <span>
                <b>Address:</b>
                <router-link
                    v-if="data.address.crypto"
                    class="text-overflow ml-2 text-decoration-none"
                    :to="'/' + data.address.crypto + '/address/' + data.address.address"
                >
                    <img
                        class="currencyImg mx-2"
                        :src="('cryptoIcons/color/' + data.address.crypto.toLowerCase() + '.svg') | createImageUrl"
                    />
                    <code class="elevation-0">{{data.address.address}}</code>
                </router-link>
                <code v-else class="elevation-0">{{data.address.address}}</code>
            </span>
            <span>
                <b>Source:</b>
                {{data.source}}
            </span>
        </v-layout>
    </div>
</template>
<script>

export default {
    name: 'IdentityResult',
    props: {
        data: {}
    }
}
</script>
<style scoped>
.currencyImg {
    vertical-align: middle;
    width: 20px;
}
</style>