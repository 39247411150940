<script setup>

import {computed, ref, watch} from "vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import CountrySelectedDisplay from "@/views/Transactions/FilterGeo/Components/Displays/CountrySelectedDisplay.vue";
import CountrySearchDisplay from "@/views/Transactions/FilterGeo/Components/Displays/CountrySearchDisplay.vue";
import ASSelectedDisplay from "@/views/Transactions/FilterGeo/Components/Displays/ASSelectedDisplay.vue";
import ASSearchDisplay from "@/views/Transactions/FilterGeo/Components/Displays/ASSearchDisplay.vue";
import CitySelectedDisplay from "@/views/Transactions/FilterGeo/Components/Displays/CitySelectedDisplay.vue";
import CitySearchDisplay from "@/views/Transactions/FilterGeo/Components/Displays/CitySearchDisplay.vue";
import OrganizationSelectedDisplay
    from "@/views/Transactions/FilterGeo/Components/Displays/OrganizationSelectedDisplay.vue";
import OrganizationSearchDisplay
    from "@/views/Transactions/FilterGeo/Components/Displays/OrganizationSearchDisplay.vue";
import {GeoFilterType} from "@/views/Transactions/FilterGeo/GeoFilterTypes";
import FilterGeoPicker from "@/views/Transactions/FilterGeo/Components/Wrappers/FilterGeoPicker.vue";
import ResetButton from "@/components/common/Buttons/ResetButton.vue";
import SearchButton from "@/components/common/Buttons/SearchButton.vue";
import TransactionsFilterGeoMap from "@/views/Transactions/FilterGeo/TransactionsFilterGeoMap.vue";
import {useRoute} from "vue-router/composables";
import ExpandWrapper from "@/components/common/ExpandWrapper.vue";
import TransactionsFilterGeoTransactions from "@/views/Transactions/FilterGeo/TransactionsFilterGeoTransactions.vue";
import {DataState} from "@/components/common/types/data";

const route = useRoute()
const currencyUnit = computed(() => route.params.currency)

const pagination = {
    page: 1,
    itemsPerPage: 10,
    sortDesc: [false]
}


//COUNTRY
const country = ref(null)
const {
    getData: countryGetData,
    dataState: countryDataState,
    data: countryData
} = useGetData("loadGeoIpCountry", {pagination: {...pagination}}, false, false, 1, true)
const countries = computed(() => countryData.value?.data ?? [])
const getItemsForCountry = async (searchString) => {
    countryGetData({pagination: {...pagination, filterCountry: searchString}})
}
watch(country, () => {
    getAllWithCurrentConstrains([GeoFilterType.Country])
})


//CITY
const city = ref(null)
const {
    getData: cityGetData,
    dataState: cityDataState,
    data: cityData
} = useGetData("loadGeoIpCity", {pagination: {...pagination}}, false, false, 1, true)
const cities = computed(() => cityData.value?.data ?? [])
const getItemsForCity = (searchString) => {
    cityGetData({pagination: {...pagination, filterCity: searchString, filterCountry: country.value?.country_code}})
}
watch(city, async () => {
    if (!tryToSetCountryIfUnset(city.value)) {
        await getAllWithCurrentConstrains([GeoFilterType.City])
    }
})


//AS
const as = ref(null)
const {
    getData: asGetData,
    dataState: asDataState,
    data: asData
} = useGetData("loadGeoIpAS", {pagination: {...pagination}}, false, false, 1, true)
const ases = computed(() => asData.value?.data ?? [])
const getItemsForAS = (searchString) => {
    asGetData({pagination: {...pagination, filterAsn: searchString, filterCountry: country.value?.country_code}})
}
watch(as, async () => {
    if (!tryToSetCountryIfUnset(as.value)) {
        await getAllWithCurrentConstrains([GeoFilterType.AS])
    }
})

//ORGANIZATION
const org = ref(null)
const {
    getData: orgGetData,
    dataState: orgDataState,
    data: orgData
} = useGetData("loadGeoIpAS", {pagination: {...pagination}}, false, false, 1, true)
const orgs = computed(() => orgData.value?.data ?? [])
const getItemsForOrg = (searchString) => {
    orgGetData({pagination: {...pagination, filterAsName: searchString, filterCountry: country.value?.country_code}})
}
watch(org, async () => {
    if (!tryToSetCountryIfUnset(org.value)) {
        await getAllWithCurrentConstrains([GeoFilterType.Organization, GeoFilterType.AS])
    }
})

const getCurrentCountryConstraint = () => as.value?.country?.country_code ?? org.value?.country?.country_code ?? city.value?.country?.country_code ?? country.value?.country_code
const isTypeSelected = (type) => {
    switch (type) {
        case GeoFilterType.Country:
            return !!country.value
        case GeoFilterType.City:
            return !!city.value
        case GeoFilterType.Organization:
            return !!org.value
        case GeoFilterType.AS:
            return !!as.value
    }
}

const GetDataByType = new Map()
GetDataByType.set(GeoFilterType.Country, async () => await countryGetData({
    pagination: {
        ...pagination,
        filterCountryCode: getCurrentCountryConstraint()
    }
}))
GetDataByType.set(GeoFilterType.City, async () => await cityGetData({
    pagination: {
        ...pagination,
        filterCity: city.value?.name,
        filterCountry: getCurrentCountryConstraint()
    }
}))
GetDataByType.set(GeoFilterType.AS, async () => await asGetData({
    pagination: {
        ...pagination,
        filterAsn: as.value?.asn,
        filterCountry: getCurrentCountryConstraint()
    }
}))
GetDataByType.set(GeoFilterType.Organization, async () => await orgGetData({
    pagination: {
        ...pagination,
        filterAsName: org.value?.name,
        filterCountry: getCurrentCountryConstraint()
    }
}))

const getAllWithCurrentConstrains = async (exclude = []) => {
    for (const [key, value] of GetDataByType) {
        if (!exclude.includes(key) && !isTypeSelected(key)) {
            await value()
        }
    }
}

const resetPickedValues = () => {
    country.value = null
    city.value = null
    as.value = null
    org.value = null
}

const tryToSetCountryIfUnset = (maybeItemWithCountry) => {
    if (!maybeItemWithCountry || country.value) {
        return false
    }
    country.value = maybeItemWithCountry.country
    return true
}
const filterGeoTransactionsRef = ref(null)
const getCurrentFilterConstraintsForPeers = () => {
    return {
        filterCountry: country.value?.country_code,
        filterCity: city.value?.city,
        filterAsn: as.value?.asn ?? org.value?.asn,
    }
}

const getCurrentFilterConstraintsForTx = () => {
    return {
        filterCountry: country.value?.country_code,
        filterCity: city.value?.city,
        filterAsn: as.value?.asn ?? org.value?.asn,
        ...filterGeoTransactionsRef.value?.getCurrentFilter()
    }
}

const peersGetDataObject = useGetData("loadGeoPeers", {
        currencyUnit: currencyUnit.value,
        filter: getCurrentFilterConstraintsForPeers(),
        pagination: {page: 1, itemsPerPage: -1}
    },
    false, false, 1, true)

const peersTxsGetDataObject = useGetData("loadGeoTransactions", {
        currencyUnit: currencyUnit.value,
        filter: getCurrentFilterConstraintsForTx,
        pagination: {page: 1, itemsPerPage: 15}
    },
    false, false, 1, true)


const search = () => {
    peersGetDataObject.getData({filter: getCurrentFilterConstraintsForPeers()})
    peersTxsGetDataObject.getData()
}

const cols = {
    'cols': 12,
    'sm': 6,
    'lg': 3,
    'xl': 3
}

const isFilterActive = computed(() => country.value)
const resultTab = ref(0)
const expandedPanels = ref(0)


watch([country, city, as, org], () => {
    filterGeoTransactionsRef.value.resetState()
})


</script>

<template>
    <div class="mt-4">
        <ExpandWrapper v-model="expandedPanels">
            <template #header.left>
                <v-icon :color="isFilterActive ? 'info' : 'primary'" left>
                    mdi-filter
                </v-icon>
                Filter
            </template>
            <v-row dense>
                <v-col class="my-n1" cols="12">
                    <div class="d-flex justify-end">
                        <ResetButton @click="resetPickedValues"/>
                    </div>
                </v-col>
                <v-col v-bind="cols">
                    <FilterGeoPicker v-model="country"
                                     :display-search-component="CountrySearchDisplay"
                                     :display-selected-component="CountrySelectedDisplay"
                                     :get-items="getItemsForCountry"
                                     :items="countries"
                                     :loading="countryDataState === DataState.Loading"
                                     icon="mdi-flag"
                                     item-text="common_name"
                                     picker-text-multiple="Countries"
                                     picker-text-single="Country"/>
                </v-col>
                <v-col v-bind="cols">
                    <FilterGeoPicker v-model="city"
                                     :display-search-component="CitySearchDisplay"
                                     :display-selected-component="CitySelectedDisplay"
                                     :get-items="getItemsForCity"
                                     :items="cities"
                                     :loading="cityDataState === DataState.Loading"
                                     icon="mdi-city"
                                     item-text="city"
                                     picker-text-multiple="Cities"
                                     picker-text-single="City"/>
                </v-col>
                <v-col v-bind="cols">
                    <FilterGeoPicker v-model="as"
                                     :display-search-component="ASSearchDisplay"
                                     :display-selected-component="ASSelectedDisplay"
                                     :get-items="getItemsForAS"
                                     :items="ases"
                                     :loading="asDataState === DataState.Loading"
                                     convert-item-text-to-string
                                     icon="mdi-router"
                                     item-text="asn"
                                     picker-text-multiple="Autonomous Systems"
                                     picker-text-single="Autonomous System"/>
                </v-col>
                <v-col v-bind="cols">
                    <FilterGeoPicker v-model="org"
                                     :display-search-component="OrganizationSearchDisplay"
                                     :display-selected-component="OrganizationSelectedDisplay"
                                     :get-items="getItemsForOrg"
                                     :items="orgs"
                                     :loading="orgDataState === DataState.Loading"
                                     icon="mdi-domain"
                                     item-text="name"
                                     picker-text-multiple="Organizations"
                                     picker-text-single="Organization"/>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex justify-center">
                        <SearchButton @click="search"/>
                    </div>
                </v-col>
            </v-row>
        </ExpandWrapper>
        <v-row>
            <v-col cols="12">
                <v-tabs
                    v-model="resultTab"
                    centered
                    color="tertiary"
                    grow
                    slider-color="tertiary"
                    slider-size="4"
                >
                    <v-tab>
                        <v-icon left>
                            mdi-server
                        </v-icon>
                        Nodes
                    </v-tab>
                    <v-tab>
                        <v-icon left>
                            mdi-swap-horizontal
                        </v-icon>
                        Transactions
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="resultTab">
                    <v-tab-item eager>
                        <v-card flat>
                            <TransactionsFilterGeoMap
                                :as="as"
                                :city="city"
                                :country="country"
                                :getData="peersGetDataObject"
                                :org="org"
                            />
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat>
                            <TransactionsFilterGeoTransactions
                                ref="filterGeoTransactionsRef"
                                :peers-txs-get-data-object="peersTxsGetDataObject"
                                @on-filter-apply-event="peersTxsGetDataObject.getData()"/>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

