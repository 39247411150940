<template>
    <v-select-validateable
        ref="select"
        :clearable="isClearable"
        :disabled="disabled"
        :error="error"
        :items="currencies"
        :value="value"
        hide-details
        item-text="name"
        item-value="unit"
        label="Crypto"
        outlined
        style="margin-bottom: auto;"
        v-bind="$attrs"
        @input="change"
    >
        <template slot="selection" slot-scope="data">
            <v-flex class="ml-1 mt-2">
                <img :src="data.item.image" style="width: 26px;"/>
            </v-flex>
        </template>
        <template slot="item" slot-scope="data">
            <v-list-item-content>
                <img :src="data.item.image" class="my-1"/>
            </v-list-item-content>
        </template>
    </v-select-validateable>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "BlockchainCryptoPickerSlim",
    props: {
        value: {
            required: true
        },
        isClearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        ...mapGetters(['currencies'])
    },
    methods: {
        change(newVal) {
            if (newVal !== this.value) {
                this.$emit("input", newVal)
            }
        }
    }
}
</script>