var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100 scroll-y" },
    [
      _vm.isLoading
        ? _c(
            "div",
            [
              _c("ProgressCircular", {
                staticClass: "pt-10",
                attrs: { color: _vm.currency.unit },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !_vm.address
        ? _c("v-container", [
            _vm.address === null || !_vm.addressId
              ? _c(
                  "div",
                  [
                    _c("AddressNotFoundInfo", {
                      staticClass: "my-2",
                      attrs: {
                        address: _vm.addressId,
                        message:
                          "Provided identifier does not exist in the blockchain!",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && _vm.address
        ? _c(
            "div",
            [
              _c(
                "LimitAbleLayoutOld",
                {
                  attrs: { initLimitValue: _vm.limitValue },
                  on: { "reload-data": _vm.reloadData },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("h1", [
                      _vm._v("Balance Table in " + _vm._s(_vm.displayCurrency)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "body" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-0",
                          attrs: { dense: "", justify: "end" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("ExportMenu", {
                                attrs: {
                                  "base-url": `${_vm.currency.unit}/cryptoaddress/${_vm.addressId}/balances`,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.balanceTreeData === null
                        ? _c("ProgressCircular")
                        : _c(
                            "div",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "border",
                                  attrs: { elevation: "0" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c("h3", [_vm._v("Full-Text Search:")]),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          "append-icon": "search",
                                          label: "search table",
                                          "single-line": "",
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-data-table", {
                                    staticClass: "border",
                                    attrs: {
                                      dense: "",
                                      headers: _vm.headers,
                                      search: _vm.search,
                                      items: _vm.balanceTreeData,
                                      loading: _vm.loading,
                                      "items-per-page":
                                        Number(_vm.limitValue) || -1,
                                      "hide-default-footer": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        _vm._l(_vm.headers, function (h) {
                                          return {
                                            key: `header.${h.value}`,
                                            fn: function ({ header }) {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    key: h.text,
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      h.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(h.tooltip)),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          }
                                        }),
                                        {
                                          key: "item",
                                          fn: function (props) {
                                            return [
                                              _c("tr", [
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "RouteToTransaction",
                                                      {
                                                        attrs: {
                                                          transaction:
                                                            props.item.txid,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "text" },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatDateTime"
                                                                  )(
                                                                    props.item
                                                                      .timestamp *
                                                                      1000
                                                                  )
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _c("fiatConvertor", {
                                                      attrs: {
                                                        value:
                                                          props.item
                                                            .balanceBefore,
                                                        fiats: props.item.fiats,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-right",
                                                    staticStyle: {
                                                      color: "darkgoldenrod",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "nobr",
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item.change >
                                                              0
                                                              ? "+"
                                                              : "-"
                                                          ) +
                                                            "\n                                        "
                                                        ),
                                                        _c("fiatConvertor", {
                                                          attrs: {
                                                            absoluteValue: "",
                                                            value:
                                                              props.item.change,
                                                            fiats:
                                                              props.item.fiats,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _c("fiatConvertor", {
                                                      attrs: {
                                                        value:
                                                          props.item
                                                            .balanceAfter,
                                                        fiats: props.item.fiats,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-right",
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "nobr",
                                                      [
                                                        _vm._v("-"),
                                                        _c("fiatConvertor", {
                                                          attrs: {
                                                            value:
                                                              props.item
                                                                .sumOfAddrInputs,
                                                            fiats:
                                                              props.item.fiats,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$humanize.formatNumber(
                                                            props.item
                                                              .percentileOfSumInputs *
                                                              100,
                                                            0
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          props.item
                                                            .numOfAddrInputs
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item
                                                          .numOfAllInputs
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$humanize.formatNumber(
                                                            props.item
                                                              .percentileOfNumInputs *
                                                              100,
                                                            0
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-right",
                                                    staticStyle: {
                                                      color: "green",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "nobr",
                                                      [
                                                        _vm._v("+"),
                                                        _c("fiatConvertor", {
                                                          attrs: {
                                                            value:
                                                              props.item
                                                                .sumOfAddrOutputs,
                                                            fiats:
                                                              props.item.fiats,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$humanize.formatNumber(
                                                            props.item
                                                              .percentileOfSumOutputs *
                                                              100,
                                                            0
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          props.item
                                                            .numOfAddrOutputs
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          props.item
                                                            .numOfAllOutputs
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$humanize.formatNumber(
                                                            props.item
                                                              .percentileOfNumOutputs *
                                                              100,
                                                            0
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _c("fiatConvertor", {
                                                      attrs: {
                                                        value:
                                                          props.item
                                                            .sumOfAllOutputs,
                                                        "crypto-dec-places": 4,
                                                        fiats: props.item.fiats,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _c("fiatConvertor", {
                                                      attrs: {
                                                        value: props.item.fee,
                                                        "crypto-dec-places": 4,
                                                        fiats: props.item.fiats,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }