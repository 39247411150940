<script setup>

import {formatNumber} from "humanize-plus";

const props = defineProps({
    title: {
        type: String,
        required: false
    },
    icon: {
        type: String,
        required: false
    },
    numberOfResults: {
        type: Number,
        required: false
    },
    padding: {
        type: Boolean,
        required: false,
        default: false
    },
    eager: {
        type: Boolean,
        required: false,
        default: false
    },
    showAsResult: {
        type: Boolean,
        required: false,
        default: false
    }
})

</script>

<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="text-truncate d-flex">
                <v-icon v-if="icon" class="mr-2" color="tertiary">
                    {{ icon }}
                </v-icon>
                <slot name="header">
                <span v-if="title" class="text-button tertiary--text mr-2">
                    {{ title }}
                </span>
                </slot>
            </div>
            <v-spacer class="mx-1"/>
            <div class="d-flex justify-end">
                <v-chip v-if="numberOfResults !== undefined"
                        :color="numberOfResults > 0 ? 'success' : 'grey'"
                        class="mr-2"
                        small>
                    <b>
                        {{ formatNumber(numberOfResults) }}
                    </b> &nbsp;
                    <span v-if="showAsResult">
                        {{ numberOfResults === 1 ? 'result' : 'results' }}
                    </span>
                    <span v-else>
                        {{ numberOfResults === 1 ? 'match' : 'matches' }}
                    </span>
                </v-chip>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="{noPaddingExpansionPanel: !padding}" :eager="eager">
            <slot/>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<style>
.noPaddingExpansionPanel > .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}
</style>