<template>
    <v-autocomplete
        :items="addresses"
        :search-input.sync="search"
        hide-no-data
        item-text="address"
        item-value="address"
        prepend-icon="search"
        append-icon
        placeholder="Start typing to search Address"
        clearable
        no-filter
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
import debounce from "debounce"

export default {
    name: 'AddressPicker',
    props: {
        addresses: {
            type: Array,
            validator: prop => prop.every(i => i.hasOwnProperty("id") && i.hasOwnProperty("address")),
            required: true
        }
    },
    mounted(){
    },
    data() {
        return {
            search: "",
        }
    },
    watch: {
        search(value) {
            if (!value) {
                return
            }

            //address was not selected from hints
            if (!this.addresses?.some( address => address.address === value))
            {
                // Debounce the input and wait for a pause of 200 ms
                debounce(this.addressSearchFilterChanged, 200)(value, this)
            }
        }
    },
    methods: {
        addressSearchFilterChanged(filter) {
            this.$emit("address-filter-changed-event", { filter })
        }
    }
}
</script>