var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "mt-2", attrs: { outlined: "" } },
    [
      _c(_setup.DataGrid, {
        attrs: {
          "sort-desc": "",
          bulkOperations: false,
          getData: _setup.getData,
          headers: _setup.headers,
          itemsPerPage: 10,
          itemid: "id",
          "computed-height": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("formatDateTime")(item.activity_start))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_setup.nodeActivityEndDate(item.activity_end))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v(_vm._s(item.service.user_agent))]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v(_vm._s(item.service.protocol_version))]),
                ]),
                _vm._v(" "),
                _c("td", [_c("span", [_vm._v(_vm._s(item.service.services))])]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }