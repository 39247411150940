<script setup>
import {computed} from "vue";
import store from "@/store";

defineProps({
    text: {
        type: String,
        required: false,
        default: "Create"
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    color: {
        type: String,
        required: false,
        default: "success"
    },
    icon: {
        type: Boolean,
        required: false,
        default: false
    }
})

const userHasWritePermissions = computed(() => store.getters.hasPermission("write"))
</script>

<template>
    <v-btn :disabled="disabled || !userHasWritePermissions" :color="color" v-bind="$attrs" v-on="$listeners" :icon="icon">
        <slot/>
    </v-btn>
</template>
