<template>
    <div :class="{ 'categoryList': dense2 }">
        <v-autocomplete
            :items="categories"
            :label="this.disabled ? 'Category change is not allowed' : 'Categories'"
            :disabled="this.disabled"
            item-text="name"
            item-value="id"
            clearable
            class="categoryPicker"
            multiple
            outlined
            chips
            small-chips
            :dense="dense"
            :value="value"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template v-slot:selection="data">
                <div class="d-flex" v-if="data.index < 5 && !selectionText">
                    <AddressCategoryIcon
                        v-bind:key="data.item.id"
                        :iconItem="data.item"
                    />
                    <div v-if="value.length > 5 && data.index > 3" class="d-flex align-center mt-1 font-weight-light fontMonospace">
                        {{ `+${value.length - 5}` }}
                    </div>
                </div>
                <span v-if="selectionText && data.index === 0" class="fontMonospace">{{ value.length }} selected</span>
            </template>
            <template #no-data>
                <span>No Match</span>
            </template>
            <template v-slot:item="{ item }">
                <template v-if="typeof item !== 'object'">
                    <v-list-item-content v-text="item"></v-list-item-content>
                </template>
                <template v-else-if="!chips">
                    <v-icon :color="item.color" left>{{ item | getIconString }}</v-icon>

                    <v-list-item-content>
                        <v-list-item-title v-html="item.name"></v-list-item-title>
                    </v-list-item-content>
                </template>
                <template v-else>
                    <addressCategoryIcon :icon-item="item" medium/>
                </template>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import AddressCategoryIcon from "@/components/address/AddressCategoryIcon.vue";

export default {
    name: "CategoryPicker",
    components: {AddressCategoryIcon},
    props: {
        categories: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        chips: {
            type: Boolean,
            required: false,
            default: false
        },
        selectionText: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: Array
        },
        dense2: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        icon(iconName) {
            return this.$options.filters.getIconString(iconName)
        },
    },
}
</script>

<style>

.categoryList .v-list-item {
    min-height: 32px !important;
}

.categoryPicker .v-select__selections {
    flex-wrap: nowrap;
}

</style>