<template>
    <div class="d-flex">
        <v-checkbox
            :error="error"
            dense
            hide-details
            class="mr-4 ma-0 pa-0"
            v-model="inputs"
            :label="'Inputs'"
            :readonly="readonly"
            :color="color"
            style="padding-right: 37px !important;"
        ></v-checkbox>
        <v-checkbox
            :error="error"
            dense
            hide-details
            class="ma-0 pa-0"
            :color="color"
            v-model="outputs"
            :label="'Outputs'"
            :readonly="readonly"
        ></v-checkbox>
    </div>
</template>

<script>
export default {
    name: "TypePicker.vue",
    props: {
        value: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: "primary"
        },
    },
    data() {
        return {
            inputs: true,
            outputs: true,
            error: false,
        }
    },
    watch: {
        inputs: {
            handler()
            {
                this.emit()
            }
        },
        outputs: {
            handler()
            {
                this.emit()
            }
        },
    },
    mounted() {
        switch (this.value)
        {
            case "inout":
                this.outputs = true;
                this.inputs = true;
                break;
            case "in":
                this.inputs = true;
                this.outputs = false;
                break
            case "out":
                this.inputs = false;
                this.outputs = true;
                break;
            default:
                this.inputs = false;
                this.outputs = false;
        }
    },
    methods: {
        emit()
        {
            this.error = false;
            if (this.outputs && this.inputs)
                this.$emit("input", "inout")
            else if (this.inputs)
                this.$emit("input", "in")
            else if (this.outputs)
                this.$emit("input", "out")
            else
            {
                this.error = true;
                this.$emit("input", "")
            }

        }
    }
}
</script>

<style scoped>

</style>