<script setup>
import {useAttrs} from "vue";
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

const emit = defineEmits(['click'])
const attrs = useAttrs()
</script>

<template>
    <BaseDataModifyButton color="success" small v-bind="attrs" @click="emit('click')">
        <v-icon left>
            mdi-database-import
        </v-icon>
        Import
    </BaseDataModifyButton>
</template>