<template>
    <v-container class="px-4" fluid>
        <v-layout align-center>
            <h1>Address Metadata</h1>
            <v-spacer/>
            <ImportAddressesDialog @addressesImported="refresh"/>
            <AddressMetadataDetailModal
                :currencies="currencies"
                :owners="owners.data"
                :categories="categories"
                :isOwnersLoading="isOwnersLoading"
                :isCategoriesLoading="isCategoriesLoading"
                :isAddressMetadataDetailLoading="isAddressMetadataDetailLoading"
                @save-event="createAddressMetadata"
                @owner-filter-changed-event="ownerFilterChanged"
            >
                <template #showbutton="{ on }">
                    <CreateButton v-on="on" text="Add new address metadata"/>
                </template>
            </AddressMetadataDetailModal>
        </v-layout>

        <v-divider/>
        <v-divider/>

        <v-data-table
            :headers="headers"
            :items="addressMetadata.data"
            :options.sync="filter"
            :server-items-length="addressMetadata.total ? addressMetadata.total : 0"
            :loading="addressMetadataLoading"
            :footer-props="{
                'items-per-page-options': [15, 30, 50, 100],
                'show-current-page': true,
            }"
            class="elevation-0 border my-2"
            dense
        >
            <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'address'"
                    append-icon="search"
                    :label="header.text"
                    single-line
                    hide-details
                    @click.stop.native
                    clearable
                    @input="debounceInput"
                    v-model="debouncedFilter.filterAddress"
                    class="text-field-label-style"
                />
                <div
                    :key="h.value"
                    v-if="h.value === 'crypto'"
                    style="margin-bottom: auto"
                >
                    <BlockchainCryptoPickerSlim
                        :currencies="allCurrenciesExceptUnknown"
                        @click.stop.native
                        v-model="filter.filterCurrency"
                        dense
                    />
                </div>
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'identities_count'"
                    :label="header.text"
                    single-line
                    hide-details
                    type="number"
                    :min="0"
                    hide-spin-buttons
                    @click.stop.native
                    clearable
                    @input="debounceInput"
                    v-model="debouncedFilter.filterIdentities"
                    class="text-field-label-style"
                    style="width: 90px"
                />

                <div
                    :key="h.value"
                    v-if="h.value === 'categories_count'"
                    style="padding-top: 20px; margin-bottom: auto;"
                >
                    <CategoryPicker
                        :categories="categories"
                        @click.stop.native
                        v-model="filter.filterCategories"
                        :dense="true"
                        class="text-field-label-style"
                    />
                </div>

                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'cases_count'"
                    :label="header.text"
                    single-line
                    hide-details
                    type="string"
                    @click.stop.native
                    clearable
                    @input="debounceInput"
                    v-model="debouncedFilter.filterCases"
                    class="text-field-label-style"
                />

                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'owners_count'"
                    append-icon="search"
                    :label="header.text"
                    single-line
                    hide-details
                    @click.stop.native
                    clearable
                    @input="debounceInput"
                    v-model="debouncedFilter.filterOwner"
                    class="text-field-label-style"
                />
                <div :key="h.value" v-if="h.value === 'created_at'" style="min-width: 160px">
                    <DateTimePicker
                        :label="h.text"
                        v-model="filter.filterCreatedAt"
                        :disabledTime="true"
                    />
                </div>
                <div
                    :key="h.value"
                    v-if="h.value === 'action'"
                >
                    <h3>{{ h.text }}</h3>
                </div>
            </template>
            <template #item="{ item }">
                <tr>
                    <td class="text-left">
                        <EntityLabel
                            act-as-link
                            :currency="item.crypto"
                            is-copyable
                            :entity="item.address"/>
                    </td>
                    <td class="text-center">
                        <BlockchainCryptoBadge dense :iconWidth="24" :currencies="currencies" :crypto="item.crypto"/>
                    </td>
                    <td class="text-right" style="text-align: center; vertical-align: middle">
                        <span>{{ item.identities_count | formatNumber }}</span>
                    </td>
                    <td style="text-align: center; vertical-align: middle" width="20%">
                        <div class="d-flex justify-left">
                            <AddressCategoryIcon
                                v-for="cat in item.categories.slice(0, 2)"
                                :iconItem="cat"
                                :key="cat.id"
                                class="mr-2"
                                small
                                :name="cat.name"
                                :color="cat.color"
                            />
                            <BaseButtonShowMoreItems
                                :items="item.categories"
                                :shownItemsCount="2"
                            >
                                <AddressCategoryIcon
                                    class="mx-1"
                                    v-for="cat in item.categories"
                                    :iconItem="cat"
                                    :key="cat.id"
                                    medium
                                    :name="cat.name"
                                    :color="cat.color"
                                />
                            </BaseButtonShowMoreItems>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="d-flex justify-start align-center" style="max-width: 200px">
                            <router-link
                                v-for="item in item.cases.slice(0, 1)"
                                class="link text-truncate"
                                :key="item.id"
                                :to="{
                                name: 'CaseDetail',
                                params: { caseId: item.id }
                            }"
                            >
                                {{ item.title }}
                            </router-link>
                            <BaseButtonShowMoreItems
                                :items="item.cases"
                                :shownItemsCount="1"
                            >
                                <router-link
                                    class="link mx-1"
                                    style="font-size: 0.8em"
                                    v-for="item in item.cases"
                                    :key="item.id"
                                    :to="{
                                        name: 'CaseDetail',
                                        params: { caseId: item.id }
                                    }"
                                >
                                    {{ item.title }}
                                </router-link>
                            </BaseButtonShowMoreItems>
                        </div>
                    </td>
                    <td class="text-center">
                        <TableItemChipsWithShowMoreOption :items="item.owners">
                            <template v-slot="{item, isInMenu}">
                                <OwnerBadge :item="item" :class="{'mx-1 mb-1': isInMenu}"/>
                            </template>
                        </TableItemChipsWithShowMoreOption>
                    </td>
                    <td style="text-align: center; vertical-align: middle">
                        {{ item.created_at | formatDateTime }}
                    </td>
                    <td width="10%" style="text-align: center; vertical-align: middle; min-width: 92px;">
                        <AddressMetadataDetailModal
                            :currencies="currencies"
                            :owners="owners.data"
                            :categories="categories"
                            :isOwnersLoading="isOwnersLoading"
                            :isCategoriesLoading="isCategoriesLoading"
                            :isAddressMetadataDetailLoading="isAddressMetadataDetailLoading"
                            :addressMetadataDetail="addressMetadataDetail"
                            @save-event="editAddressMetadata"
                            @owner-filter-changed-event="ownerFilterChanged"
                            :key="item.id"
                        >
                            <template #showbutton="{ on }">
                                <TableEditButton
                                    v-on="on"
                                    @click="
                                        loadAddressMetadataDetail({
                                            addressMetadataId: item.id
                                        })
                                    "
                                    editButtonTooltip="Edit address metadata"
                                />
                            </template>
                        </AddressMetadataDetailModal>

                        <TableDeleteModalDialog
                            width="40%"
                            :description="`Do you really want to delete address ${item.address} and all its metadata? Address binds ${item.identities_count} identities, ${item.categories_count} categories, ${item.owner_count} owner and ${item.cases_count} cases.`"
                            :itemToDelete="item"
                            @delete-event="deleteAddressMetadata"
                            tooltip="Delete address metadata"
                        />
                    </td>
                </tr>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                <span>
                    {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                </span>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import AddressMetadataDetailModal from '@/components/AddressMetadata/AddressMetadataDetailModal'
import AddressCategoryIcon from '@/components/address/AddressCategoryIcon'
import {
    BlockchainCryptoBadge,
    BlockchainCryptoPickerSlim,
    CategoryPicker,
    CreateButton,
    TableDeleteModalDialog,
    TableEditButton
} from "@/components/common"
import {mapGetters} from "vuex";
import debounce from "debounce";
import EntityLabel from "@/components/common/EntityLabel";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import DateTimePicker from "@/components/common/DateTimePicker";
import BaseButtonShowMoreItems from "@/components/common/TableComponents/BaseButtonShowMoreItems";
import {useGetActiveCurrencyOrDefault} from "@/components/Composables/useGetActiveCurrencyOrDefault";
import ImportAddressesDialog from "@/views/Addresses/Dialogs/ImportAddressesDialog.vue";
import TableItemChipsWithShowMoreOption from "@/views/Addresses/TableItemsExpandable.vue";
import {formatDateTime} from "../../utils/filters";
import OwnerBadge from "@/views/Addresses/OwnerBadge.vue";

export default {
    name: 'AddressMetadataView',
    mixins: [routeSyncMixin],
    components: {
        OwnerBadge,
        TableItemChipsWithShowMoreOption,
        ImportAddressesDialog,
        BaseButtonShowMoreItems,
        AddressCategoryIcon,
        EntityLabel,
        AddressMetadataDetailModal,
        TableEditButton,
        TableDeleteModalDialog,
        CreateButton,
        BlockchainCryptoBadge,
        BlockchainCryptoPickerSlim,
        CategoryPicker,
        DateTimePicker
    },
    computed: {
        ...mapGetters([
            "addressMetadata",
            "isAddressMetadataDetailLoading",
            "addressMetadataDetail",
            "addressMetadataLoading",
            "allCurrenciesExceptUnknown",
            "categories",
            "isOwnersLoading",
            "isCategoriesLoading",
            "owners",
            "currencies",
            "currency"
        ]),
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                sortBy: {type: "ArrayOfStrings", default: () => ['created_at']},
                sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
                filterAddress: {type: "String", default: () => ''},
                filterCategories: {type: "ArrayOfNumbers", default: () => []},
                filterIdentities: {type: "String", default: () => ''},
                filterCases: {type: "String", default: () => ''},
                filterOwner: {type: "String", default: () => ''},
                filterCreatedAt: {type: "Number", default: () => undefined},
                filterCurrency: {type: "String", default: () => ''}
            },
            debouncedFilter: {
                filterAddress: undefined,
                filterIdentities: undefined,
                filterCases: undefined,
                filterOwner: undefined,
            },
            categoriespagination: {
                page: 1,
                itemsPerPage: 100,
            },

            headers: [
                {text: 'Address', align: 'center', value: 'address'},
                {text: 'Currency', align: 'center', value: 'crypto', width: 130, sortable: false},
                {text: '# Identities', align: 'center', value: 'identities_count', width: 130},
                {text: 'Categories', value: 'categories_count', align: 'center', width: 320},
                {text: 'Cases', align: 'center', value: 'cases_count', width: 130},
                {text: 'Owners', align: 'center', value: 'owners_count',},
                {text: 'Created', align: 'center', value: 'created_at',},
                {text: 'Action', value: 'action', align: 'center', sortable: false}
            ],
        }
    },
    watch: {
        '$route.query.filterCurrency': {
            handler(newValue)
            {
                if (!newValue) {
                    this.$_syncToRouteQuery(true, {...this.$route.query, filterCurrency: useGetActiveCurrencyOrDefault(this.$route)})
                }
            }
        }
    },
    created() {
        this.$_syncToRouteQuery(true, {...this.$route.query, filterCurrency: useGetActiveCurrencyOrDefault(this.$route)})
    },
    mounted() {
        this.$store.dispatch('loadCategories')
    },
    methods: {
        formatDateTime,
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),

        refresh() {
            if(this.filter.filterCurrency) {
                this.$store.dispatch('loadAddressMetadata', {currency: this.filter.filterCurrency, pagination: this.filter})
            }
        },

        async deleteAddressMetadata({itemToDelete}) {
            await this.$store.dispatch("deleteAddressMetadata", {
                currency: this.filter.filterCurrency,
                addressMetadataId: itemToDelete.id
            })
            this.refresh()
        },

        async editAddressMetadata({adddresMetadataDetail}) {
            await this.$store.dispatch("updateAddressMetadata", {
                currency: this.filter.filterCurrency,
                updatedAddressMetadata: adddresMetadataDetail
            })
            this.refresh()
        },

        async createAddressMetadata({adddresMetadataDetail}) {
            const result = await this.$store.dispatch("createAddressMetadata", {
                currency: this.filter.filterCurrency,
                newAddressMetadata: adddresMetadataDetail
            })
            if (result) {
                this.refresh()
            }
        },

        ownerFilterChanged({filter}) {
            this.$store.dispatch('loadOwners', {filter, details: "basic"})
        },

        loadAddressMetadataDetail({addressMetadataId}) {
            this.$store.dispatch("loadAddressMetadataDetail", {currency: this.filter.filterCurrency, addressMetadataId})
        }
    }
}
</script>

<style scoped>
.category-column {
    max-width: 5%;
}
</style>