<script setup>
import ImportAddressesDialog from "@/views/Addresses/Dialogs/ImportAddressesDialog.vue";
import {ImportFormat} from "@/components/common/types/import";

const props = defineProps({
    caseId: {
        type: Number,
        required: false,
        default: undefined
    }
})

const identitiesHeaders = [
    {text: "Label", align: "left", value: "label"}
]

const defaultRowValues = {
    crypto: null,
    address: null,
    label: null,
    categories: null,
    owners: null
}

const emit = defineEmits(["addressesImported"])
</script>

<template>
    <ImportAddressesDialog
        :case-id="caseId"
        :identities-headers="identitiesHeaders"
        :default-row-values="defaultRowValues"
        action-name="importCaseAddresses"
        :import-format="ImportFormat.Case"
        @addressesImported="emit('addressesImported')"
    />
</template>