<template>
    <span>
        <v-chip v-if="large" :color="iconItem.color" :text-color="iconItem.secondaryColor">
            <v-icon left>{{ icon }}</v-icon>
            <span style="font-weight: 500">{{ iconItem.name }}</span>
        </v-chip>

        <v-tooltip top v-else>
            <template v-slot:activator="{ on }">
                <v-icon left class="pointer" :color="iconItem.color" v-on="on">{{ icon }}</v-icon>
            </template>
            <span>{{ iconItem.name }}</span>
        </v-tooltip>
    </span>
</template>

<script>

export default {
    name: 'CategoryBadge',
    props: {
        large: {
            type: Boolean,
            default: false
        },
        iconItem: {
            required: true,
            validator: p => p.hasOwnProperty("color") && p.hasOwnProperty("secondaryColor") && p.hasOwnProperty("icon")
        }
    },
    computed: {
        icon() {
            return this.$options.filters.getIconString(this.iconItem)
        }
    }
}
</script>
