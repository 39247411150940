var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "end", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "auto" } },
            [
              _c(_setup.TransactionsControlPanel, {
                attrs: {
                  expanded: _setup.expanded,
                  "hide-expand": _vm.hideExpand,
                  "hide-timeline": _vm.hideTimeline,
                  timelined: _setup.timeline,
                },
                on: {
                  "expand-event": function ($event) {
                    _setup.expanded = !_setup.expanded
                  },
                  "timeline-expand-event": function ($event) {
                    _setup.timeline = !_setup.timeline
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "auto" } }, [_vm._t("export")], 2),
        ],
        1
      ),
      _vm._v(" "),
      _setup.loading || _setup.transactions?.length > 0 || _setup.page !== 1
        ? _c(_setup.PaginationWrapper, {
            attrs: {
              "hide-total":
                _setup.totalPages === _setup.maxPageVal || _vm.hideTotal,
              hideTopPagination: _vm.hideTopPagination,
              loading: _setup.loading,
              "pages-total": _setup.totalPages ? _setup.totalPages : 0,
              value: _setup.page,
            },
            on: { input: _setup.handleManualPaginationChange },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _setup.loading
                        ? _c(
                            "div",
                            [
                              _c(_setup.ProgressCircular, {
                                attrs: { color: _setup.currency.unit },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(_setup.TransactionsHeader, {
                        attrs: { "no-change": "" },
                      }),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _vm._l(
                        _setup.transactions,
                        function (transaction, index) {
                          return _c(
                            "div",
                            { key: transaction.id },
                            [
                              _vm._t("prependTransaction", null, null, {
                                transaction,
                              }),
                              _vm._v(" "),
                              !_vm.hideTimeline
                                ? _c("v-expand-transition", [
                                    _setup.timeline &&
                                    index !== 0 &&
                                    _setup.transactions[index - 1].time !==
                                      transaction.time
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-end align",
                                            staticStyle: {
                                              "margin-right": "105px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-start align-center",
                                                staticStyle: { width: "150px" },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                                mdi-sort-clock-ascending-outline\n                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-light",
                                                    staticStyle: {
                                                      color: "rgba(0,0,0,0.6)",
                                                    },
                                                  },
                                                  [
                                                    _c("nobr", [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(`${_setup.getHumanTime(
                                                            _setup.transactions[
                                                              index - 1
                                                            ].time * 1000,
                                                            transaction.time *
                                                              1000
                                                          )}
                                     | ${
                                       _setup.transactions[index - 1]
                                         .blockHeight - transaction.blockHeight
                                     } Blocks`) +
                                                          "\n                                "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex align-start" },
                                [
                                  _vm.selectableTransactions
                                    ? _c("v-checkbox", {
                                        staticStyle: {
                                          margin: "8px 0 0 8px",
                                          height: "26px",
                                        },
                                        attrs: {
                                          dense: "",
                                          color: "info",
                                          "input-value":
                                            _setup.GETransactions[
                                              transaction.txhash
                                            ],
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _setup.emit(
                                              "selectedTransactionsChangedEvent",
                                              $event,
                                              transaction
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(_setup.TransactionSingle, {
                                    key: transaction.txhash,
                                    staticClass: "mt-1 mb-1 flex-grow-1",
                                    attrs: {
                                      expanded: _setup.expanded,
                                      transaction: transaction,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        }
                      ),
                      _vm._v(" "),
                      _setup.transactions?.length === 0 && !_setup.loading
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "title mt-4 font-weight-light text-center",
                            },
                            [
                              _vm._t("noDataText", function () {
                                return [
                                  _vm._v(
                                    "\n                    No transactions found\n                "
                                  ),
                                ]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        : _c(
            "div",
            { staticClass: "title mt-4 font-weight-light text-center" },
            [
              _vm._t("noDataText", function () {
                return [_vm._v("\n            No transactions found\n        ")]
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }