var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { "max-width": "272px" } },
    [
      _c(_setup.FancyCard, {
        staticClass: "mt-2",
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _vm._t("titleIcon"),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.title) +
                          "\n            "
                      ),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _vm.loading
                    ? _c(
                        "div",
                        { style: ["min-width: 0"] },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "skeletonLoader",
                            attrs: {
                              "max-width": "100%",
                              width: "230",
                              "min-width": "0",
                              height: "138",
                              loading: "",
                              type: "image",
                            },
                          }),
                        ],
                        1
                      )
                    : _setup.gaugeOptions
                    ? _c(
                        "div",
                        [
                          _c(_setup.Chart, {
                            attrs: { options: _setup.gaugeOptions },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }