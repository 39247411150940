<script setup>
import {CreateButton, TableDeleteModalDialog, TableEditButton} from "@/components/common";
import DataGrid from "@/components/DataGrid/DataGrid.vue";
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import CaseDetailModal from "@/views/Case/Dialogs/CaseDetailModal.vue";
import store from "@/store";
import {computed, ref} from "vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import {DataState} from "@/components/common/types/data";

const props = defineProps({
    userId: {
        type: Number,
        required: true,
    }
})

const categories = computed(() => store.getters.categories)
const enabledCurrencies = computed(() => store.getters.enabledCurrencies)

const headers = [
        {align: 'center', text: 'Title', value: 'title'},
        {align: 'center', text: 'Files', value: 'files_count'},
        {align: 'center', text: 'Addresses', value: 'addresses_count'},
        {align: 'center', text: 'Visibility', value: 'is_hidden'},
        {align: 'center', text: 'Alert', value: 'is_warning'},
        {align: 'center', text: 'Reported At', value: 'reported_at'},
        {align: 'center', text: 'Created At', value: 'created_at'},
        {align: 'center', text: 'Updated At', value: 'updated_at'},
        {align: 'center', text: 'Updated By', value: 'updated_by'},
        {align: 'center', text: 'Action', value: 'action', sortable: false}
]

const filters = {
    filterCreatorId: props.userId
}

const getData = useGetData("getUserCases", { filters: filters }, false, false, 1, true)

const isCaseLoading = computed(() => store.getters.isCaseLoading)

const caseDetail = computed(() => store.getters.caseDetail)

const loadCaseDetail = ({caseId}) => {
    store.dispatch("loadCase", {caseId});
}

const dataGridRef = ref()

const editCase = async ({caseDetail}) => {
    await store.dispatch("updateCase", {updatedCase: caseDetail})
    dataGridRef.value.refreshItems(true)
}

const deleteCase = async ({itemToDelete}) => {
    getData.dataState.value = DataState.Loading
    await store.dispatch("deleteCase", {caseId: itemToDelete.id})
    dataGridRef.value.refreshItems(true)
}

const createCase = async ({caseDetail}) => {
    await store.dispatch("createCase", {caseDetail})
    dataGridRef.value.refreshItems(true)
}

</script>

<template>
    <FancyCard class="cardShadow" dense>
        <template #header>
            <div class="d-flex align-center">
                <v-icon class="d-block mr-2 ml-1">assignment</v-icon>
                <span class="font-weight-bold">My Cases</span>
                <v-spacer/>
                <CaseDetailModal
                    :categories="categories"
                    :currencies="enabledCurrencies"
                    @save-event="createCase"
                >
                    <template #showbutton="{ on }">
                        <CreateButton class="mr-3" text="Create case" v-on="on"/>
                    </template>
                </CaseDetailModal>
            </div>
        </template>
        <template #body>
            <DataGrid
                ref="dataGridRef"
                :bulk-operations="false"
                :headers="headers"
                :get-data="getData"
                :items-per-page="5"
                item_id="id"
                sort-desc
            >
                <template #item="{ item }">
                    <td class="text-left dynamicTruncate" style="min-width: 150px;">
                        <router-link
                            :to="`/cases/${item.id}`"
                            class="link"
                        >
                            {{ item.title }}
                        </router-link>
                    </td>
                    <td
                        class="text-right"
                        style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                    >
                        {{ item.files_count | formatNumber }}
                    </td>
                    <td
                        class="text-right"
                        style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                    >
                        {{ item.addresses_count | formatNumber }}
                    </td>
                    <td
                        style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                    >
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-icon
                                    :color="item.is_hidden ? 'primary' : 'tertiary'"
                                    dark
                                    v-on="on"
                                >
                                    {{
                                        item.is_hidden
                                            ? "visibility_off"
                                            : "visibility"
                                    }}
                                </v-icon>
                            </template>
                            <span>Case is
                                {{
                                    item.is_hidden
                                        ? "hidden"
                                        : "visible"
                                }}
                            </span>
                        </v-tooltip>
                    </td>
                    <td style="text-align: center;vertical-align: middle;">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-icon
                                    :color="item.is_warning ? 'warning' : 'primary'"
                                    dark
                                    v-on="on"
                                >
                                    {{
                                        item.is_warning
                                            ? "report"
                                            : "report_off"
                                    }}
                                </v-icon>
                            </template>
                            <span>Case
                                {{
                                    item.is_warning
                                        ? "warns"
                                        : "does not warn"
                                }}
                                creator
                            </span>
                        </v-tooltip>
                    </td>
                    <td>
                        {{ item.reported_at| moment("YYYY-MM-DD HH:mm:ss Z") }}
                    </td>
                    <td>
                        {{
                            item.created_at
                                | moment("YYYY-MM-DD HH:mm:ss Z")
                        }}
                    </td>
                    <td>
                        {{
                            item.updated_at
                                | moment("YYYY-MM-DD HH:mm:ss Z")
                        }}
                    </td>
                    <td class="text-left">
                        {{ item.updator.name }}
                    </td>
                    <td>
                        <div class="d-flex justify-center">
                            <CaseDetailModal
                                :key="item.id"
                                :caseDetail="caseDetail"
                                :categories="categories"
                                :currencies="enabledCurrencies"
                                :loading="isCaseLoading"
                                @save-event="editCase"
                            >
                                <template #showbutton="{ on }">
                                    <TableEditButton
                                        :disabled="($store.getters.user.name !== item.creator.name) && $store.getters.user.name !== 'SuperAdmin'"
                                        editButtonTooltip="Edit case"
                                        @click="loadCaseDetail({caseId: item.id,})"
                                        v-on="on"
                                    />
                                </template>
                            </CaseDetailModal>

                            <TableDeleteModalDialog
                                :description="`Do you really want to delete case ${item.title}`"
                                :itemToDelete="item"
                                tooltip="Delete case"
                                @delete-event="deleteCase"
                            />

                        </div>
                    </td>
                </template>
            </DataGrid>
        </template>
    </FancyCard>
</template>

<style scoped>

</style>