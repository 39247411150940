var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n        Address Summary\n    ")]),
      _vm._v(" "),
      _c(_setup.FancyCard, {
        staticStyle: { "min-width": "300px" },
        attrs: { dense: "", header: false },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(_setup.NameValueFlex, {
                  attrs: { "name-width": "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "name",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                    Total Balance:\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "value",
                      fn: function () {
                        return [
                          _c(
                            "v-chip",
                            {
                              staticStyle: { height: "26px" },
                              attrs: {
                                color:
                                  _setup.totalBalance > 0
                                    ? _vm.selectedCrypto.hexColor
                                    : "#eeeeee",
                                label: "",
                                "text-color": "white",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "subtitle-1 font-weight-bold",
                                  staticStyle: { "vertical-align": "middle" },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_setup.totalBalance) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "subtitle-1 pl-1 text--white",
                                  staticStyle: { "vertical-align": "middle" },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.selectedCrypto.unit) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c(_setup.NameValueFlex, {
                  attrs: { "name-width": "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "name",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                    Number of Unused Addresses:\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "value",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_setup.unusedAddresses.length) +
                              "\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c(_setup.NameValueFlex, {
                  attrs: { "name-width": "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "name",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                    Number of Used Addresses:\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "value",
                      fn: function () {
                        return [
                          _c(
                            "v-chip",
                            {
                              staticStyle: { height: "26px" },
                              attrs: {
                                color:
                                  _setup.usedAddresses.length > 0
                                    ? _vm.selectedCrypto.hexColor
                                    : "#eeeeee",
                                label: "",
                                "text-color": "white",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "subtitle-1 font-weight-bold",
                                  staticStyle: { "vertical-align": "middle" },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_setup.usedAddresses.length) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }