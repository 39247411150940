var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: {
      title: _vm.isEditModal ? "Edit identity" : "Create new identity",
      loading: _vm.loading,
      width: "30%",
    },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field-validateable", {
                            attrs: {
                              rules: "required",
                              label: "Label",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.detail.label,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "label", $$v)
                              },
                              expression: "detail.label",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-text-field-validateable", {
                            attrs: {
                              rules: "required",
                              label: "Source",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.detail.source,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "source", $$v)
                              },
                              expression: "detail.source",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-text-field-validateable", {
                            attrs: {
                              rules: "required",
                              label: "Url",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.detail.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "url", $$v)
                              },
                              expression: "detail.url",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              disabled: "",
                              label: "Address",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.detail.address.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail.address, "address", $$v)
                              },
                              expression: "detail.address.address",
                            },
                          }),
                          _vm._v(" "),
                          !_vm.isEditModal
                            ? _c(
                                "div",
                                [
                                  _c("AddressPicker", {
                                    attrs: {
                                      addresses: _vm.addresses,
                                      loading: _vm.isAddressesLoading,
                                    },
                                    on: {
                                      "address-filter-changed-event":
                                        _vm.addressFilterChanged,
                                    },
                                    model: {
                                      value: _vm.detail.address.address,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.detail.address,
                                          "address",
                                          $$v
                                        )
                                      },
                                      expression: "detail.address.address",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    outlined: "",
                    color: "error",
                    disabled:
                      _vm.identitiesModalState === _vm.modalState.Pending,
                  },
                  on: { click: close },
                },
                [_vm._v("\n        Cancel\n    ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "success",
                    loading:
                      _vm.identitiesModalState === _vm.modalState.Pending,
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save(close, setDisabled)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.isEditModal ? "Save identity" : "Create identity"
                      ) +
                      "\n    "
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }