import axios, {AxiosError, AxiosRequestConfig, Cancel, Method} from "axios"
import {cacheAdapterEnhancer} from 'axios-extensions'
import dataMockAdapter from "@/axios-adapters/dataMockAdapter"
import markRequestAsCacheableAdapter from "@/axios-adapters/markRequestAsCacheableAdapter"

import router from "@/router"
import store from "@/store"
import {UnexpectedError, ValidationError} from "@/services/Errors"
import {flatten, getWebSocketProtocol} from "@/utils";

const cacheAdapter = cacheAdapterEnhancer(axios.defaults.adapter);
const isDebug = process.env.APP_DEBUG;

const logEnabled = false;

const httpClient = isDebug
    ? axios.create({
        headers: {'Cache-Control': 'no-cache'},
        // cache will be enabled by default
        // mocking is enabled for dev only
        adapter: dataMockAdapter(markRequestAsCacheableAdapter(cacheAdapter, {
            wildcardsToCache: ["addr", "transaction", "cluster", "block", "logs"],
            logEnabled: logEnabled
        }))

    })
    : axios.create({
        headers: {'Cache-Control': 'no-cache'},
        // cache will be enabled by default
        adapter: markRequestAsCacheableAdapter(cacheAdapter, {
            wildcardsToCache: ["addr", "transaction", "cluster", "block", "logs"],
            logEnabled: logEnabled
        })
    })


type RequestConfigBase = {
    SharpApi?: boolean
}

type AxiosRequestConfigWithApi = AxiosRequestConfig & RequestConfigBase

// Returns appropriate prefix based on passed config
const getUrlBase = (config: RequestConfigBase) => config?.SharpApi ? store.getters.settings.server.sharpapiPrefix.slice(0, -1) : store.getters.settings.server.jwtPrefix

// hotfix: assumes input is host in case it's not a valid URL and returns it
const getUrlHost = (url: string) => new URL(url).host ?? url

export function openWebSocket(endpoint: string, config: RequestConfigBase): WebSocket {
    const baseUrl = getUrlBase(config)
    const protocol = getWebSocketProtocol()
    const serverUrlHost = getUrlHost(store.getters.settings.server.url)
    return new WebSocket(`${protocol}${serverUrlHost}${baseUrl}/${endpoint}`)
}

async function axiosRequest(request: {
    method: Method,
    endpoint: string,
    axiosRequestConfig?: AxiosRequestConfigWithApi,
    body?,
    raw?
}) {

    const requestUrl = `${getUrlBase(request.axiosRequestConfig)}/${request.endpoint}`

    try {
        let response
        if (request.axiosRequestConfig?.headers?.DisableAuthorization) {
            response = await httpClient({
                ...request.axiosRequestConfig,
                method: request.method,
                url: requestUrl,
                data: request.body,
                withCredentials: false
            })
        } else {
            response = await httpClient({
                ...request.axiosRequestConfig,
                method: request.method,
                url: requestUrl,
                data: request.body,
                // JWT Token header
                headers: {'Authorization': 'Bearer ' + localStorage.jwt},
                withCredentials: true
            })
        }

        if (!request.raw) {
            return response.data;
        }
        return response
    } catch (err) {
        const error = err as AxiosError;

        const errorMessage = err.message ?? "Undefined error message."

        if (error.response?.status === 0) {
            throw new UnexpectedError(errorMessage) // CORS
        }

        // CoinomonSharp errors
        if (request.axiosRequestConfig?.SharpApi) {
            const response = err.response?.data
            if (response.detail) {
                throw new UnexpectedError(response.detail)
            }

            if (!response.errors) {
                throw new UnexpectedError("Unknown error occurred.")
            }

            const errorValues = flatten(Object.values(response.errors))
            throw new UnexpectedError(errorValues[0] ?? "Unknown error occurred.")
        }

        if (axios.isCancel(err)) {
            // Cancel have only message property
            throw err
        }

        if (error.response.status === 400) {
            throw new UnexpectedError(err.response.data.message ?? err.response.data.error)
        } else if (error.response.status == 401) {
            //todo FE - create AuthenticationError and use typescript decorator for handling 401
            store.dispatch("cleanUpUser")
            store.dispatch('error', err.response.data.error)

            //Don't redirect on LoginView
            if (router.currentRoute.name !== 'LoginView') {
                router.push({name: 'LoginView', query: {redirect: router.currentRoute.fullPath}})
            }
        } else if (err.response.status === 422) {
            throw new ValidationError(err.response.data.message, err.response.data.errors)
        } else if (err.response.status === 413) {
            throw new UnexpectedError("Entity too large")
        } else if (err.response.status === 404) {
            throw new UnexpectedError(err.response.data.error)
        } else {
            const responseData = err.response.data;
            let message = ""
            if (typeof responseData.exception !== "undefined" && typeof responseData.message !== "undefined") {
                message = `${err.response.data.exception}: ${err.response.data.message}`;
            } else if (typeof responseData.message !== "undefined") {
                message = err.response.data.message;
            } else {
                message = `Unknown server exception occurred: ${err.response.data.exception}`;
            }
            throw new UnexpectedError(message);
        }
    }
}

async function GET(endpoint: string, axiosRequestConfig?: AxiosRequestConfigWithApi, raw?) {
    return await axiosRequest({method: "GET" as Method, endpoint, axiosRequestConfig, raw})
}

async function POST(endpoint: string, body?, axiosRequestConfig?: AxiosRequestConfigWithApi, raw?) {
    return await axiosRequest({method: "POST" as Method, endpoint, body, axiosRequestConfig, raw})
}

async function PUT(endpoint: string, body, axiosRequestConfig?: AxiosRequestConfigWithApi, raw?) {
    return await axiosRequest({method: "PUT" as Method, endpoint, body, axiosRequestConfig, raw})
}

async function DELETE(endpoint: string, body?: any, axiosRequestConfig?: AxiosRequestConfigWithApi, raw?) {
    return await axiosRequest({method: "DELETE" as Method, endpoint, body, axiosRequestConfig, raw})
}

async function PATCH(endpoint: string, body?: any, axiosRequestConfig?: AxiosRequestConfigWithApi, raw?) {
    return await axiosRequest({method: "PATCH" as Method, endpoint, body, axiosRequestConfig, raw})
}

export {GET, POST, PUT, DELETE, PATCH}
export default {GET, POST, PUT, DELETE, PATCH}
