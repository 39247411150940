var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.data.length > 0
    ? _c("v-data-table", {
        directives: [
          {
            name: "scroll",
            rawName: "v-scroll:#virtual-scroll-table",
            value: _setup.onScroll,
            expression: "onScroll",
            arg: "#virtual-scroll-table",
          },
        ],
        staticClass: "elevation-1",
        style: {
          "max-height": _vm.tableMaxHeight,
          overflow: "auto",
        },
        attrs: {
          id: "virtual-scroll-table",
          dense: "",
          headers: _vm.headers,
          items: _setup.dataLimited,
          "disable-pagination": "",
          "hide-default-footer": "",
        },
        scopedSlots: _vm._u(
          [
            _setup.rowRenderingStart > 0
              ? {
                  key: "body.prepend",
                  fn: function () {
                    return [
                      _c("tr", [
                        _c("td", {
                          style: "padding-top:" + _setup.startHeight + "px",
                          attrs: { colspan: _vm.headers.length },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "item",
              fn: function ({ item }) {
                return [_vm._t("item", null, { item: item }, item)]
              },
            },
            _setup.rowRenderingStart + _setup.rowsPerPage < _vm.data.length
              ? {
                  key: "body.append",
                  fn: function () {
                    return [
                      _c("tr", [
                        _c("td", {
                          style: "padding-top:" + _setup.endHeight + "px",
                          attrs: { colspan: _vm.headers.length },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      })
    : _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          "no-data-text": _vm.noDataText,
          items: [],
          dense: "",
          "hide-default-footer": "",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }