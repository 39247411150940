<script setup>
import NameValueFlex from "@/components/common/NameValueFlex.vue";
import RouteToTransaction from "@/components/common/RouteToTransaction.vue";
import FiatConvertor from "@/components/common/FiatConvertor.vue";
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import {computed, inject, watch} from "vue";
import store from "@/store";
import {timeDifference} from "@/utils";
import {useGetData} from "@/components/common/Composables/useGetData";
import {DataState} from "@/components/common/types/data";

const clusterName = inject("entityId")

const currency = computed(() => store.getters.currency)

const { dataState, data, getData } = useGetData("getClusterDetails", {currency: currency.value.unit, entity: clusterName.value}, false)

const loading = computed(() => dataState.value === DataState.Loading)

const computedClusterDetails = computed(() => {
    if(!data.value) return null

    return {...data.value, firstDepositBlocktimeRel: timeDifference(data.value.firstDepositBlocktime),
        lastDepositBlocktimeRel: timeDifference(data.value.lastDepositBlocktime),
        firstWithdrawBlocktimeRel: timeDifference(data.value.firstWithdrawBlocktime),
        lastWithdrawBlocktimeRel: timeDifference(data.value.lastWithdrawBlocktime)
    }
})
const getClusterDetails = () => {
    getData({ entity: clusterName.value })
}


watch(clusterName, () => {
    dataState.value = null
    data.value = null
})

</script>

<template>
    <FancyCard>
        <template #header>
            <div class="d-flex align-center">
                <v-icon class="ml-1" left style="height: 24px; width: 24px">mdi-magnify</v-icon>
                <span>Cluster Details</span>
            </div>
        </template>
        <template #body>
            <v-expand-transition>
                <div v-if="computedClusterDetails === null && !loading" class="d-flex justify-center" style="width: 100%">
                    <v-hover
                        v-slot:default="{ hover }"
                    >
                        <v-btn
                            @click="getClusterDetails"
                            :disabled="loading || data !== null"
                            small
                            outlined
                            :depressed="false"
                        >
                            <span class="d-inline-block">
                                Load Details
                            </span>
                            <v-icon right>mdi-plus</v-icon>
                        </v-btn>
                    </v-hover>
                </div>
            </v-expand-transition>
            <v-expand-transition>
                <div v-show="data !== null || loading" >
                    <NameValueFlex :loading="loading" loading-width="150" name-width="70">
                        <template #name>
                                        <span class="font-weight-light subtitle-1" style="font-size: 16px">
                                            Balance:
                                        </span>
                        </template>
                        <template #value>
                            <div>
                                <v-chip
                                    :color="currency.unit"
                                    style="height: 26px"
                                    label
                                    text-color="white"
                                >
                                    <span class="subtitle-1 font-weight-bold" style="vertical-align: middle">
                                        <fiat-convertor v-if="data" :value="data.balance" :fiats="data.fiats"/>
                                    </span>
                                    <span class="subtitle-1 pl-1 text--white" style="vertical-align: middle">
                                        <fiat-convertor unit-only/>
                                    </span>
                                </v-chip>
                                <v-icon small class="iconRight">
                                    mdi-credit-card
                                </v-icon>
                            </div>

                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="100" name-width="70">
                        <template #name>
                            <span class="font-weight-light">
                                Received:
                            </span>
                        </template>
                        <template #value>
                            <div>
                                <v-chip
                                    color="success"
                                    style="height: 24px"
                                    label
                                    text-color="white"
                                >
                                    <span class="subtitle-2 font-weight-bold" style="vertical-align: middle">
                                        <fiat-convertor v-if="data" :value="data.received" :fiats="data.fiats"/>
                                    </span>
                                    <span class="subtitle-2 pl-1 text--white font-weight-light" style="vertical-align: middle">
                                        <fiat-convertor unit-only/>
                                    </span>
                                </v-chip>
                                <v-icon small class="iconRight">
                                    mdi-download mdi-rotate-270
                                </v-icon>
                            </div>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="100" name-width="70">
                        <template #name>
                            <span class="font-weight-light">
                                Sent:
                            </span>
                        </template>
                        <template #value>
                            <div>
                                <v-chip
                                    color="red"
                                    style="height: 24px"
                                    label
                                    text-color="white"
                                >
                                    <span class="subtitle-2 font-weight-bold" style="vertical-align: middle">
                                        <fiat-convertor v-if="data" :value="data.send" :fiats="data.fiats"/>
                                    </span>
                                    <span class="subtitle-2 pl-1 text--white font-weight-light" style="vertical-align: middle">
                                        <fiat-convertor unit-only/>
                                    </span>
                                </v-chip>
                                <v-icon small class="iconRight">
                                    mdi-upload mdi-rotate-90
                                </v-icon>
                            </div>
                        </template>
                    </nameValueFlex>
                    <v-divider light />
                    <NameValueFlex :loading="loading" loading-width="200">
                        <template #name>
                            <span class="font-weight-light">
                                First Deposit:
                            </span>
                        </template>
                        <template #value>
                            <span v-if="data">
                                <route-to-transaction :transaction="computedClusterDetails.firstDepositTransaction">
                                    <template #text>
                                        {{computedClusterDetails.firstDepositBlocktime | moment('YYYY-MM-DD HH:mm:ss Z')}}&nbsp;({{computedClusterDetails.firstDepositBlocktimeRel}})
                                    </template>
                                </route-to-transaction>
                            </span>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="200">
                        <template #name>
                            <span class="font-weight-light">
                                Last Deposit:
                            </span>
                        </template>
                        <template #value>
                            <span v-if="data">
                                <route-to-transaction :transaction="computedClusterDetails.lastDepositTransaction">
                                    <template #text>
                                        {{computedClusterDetails.lastDepositBlocktime | moment('YYYY-MM-DD HH:mm:ss Z')}}&nbsp;({{computedClusterDetails.lastDepositBlocktimeRel}})
                                    </template>
                                </route-to-transaction>
                            </span>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="200">
                        <template #name>
                            <span class="font-weight-light">
                                First Withdraw:
                            </span>
                        </template>
                        <template #value>
                            <span v-if="data">
                                <route-to-transaction :transaction="computedClusterDetails.firstWithdrawTransaction">
                                    <template #text>
                                        {{computedClusterDetails.firstWithdrawBlocktime | moment('YYYY-MM-DD HH:mm:ss Z')}}&nbsp;({{computedClusterDetails.firstWithdrawBlocktimeRel}})
                                    </template>
                                </route-to-transaction>
                            </span>
                        </template>
                    </nameValueFlex>
                    <NameValueFlex :loading="loading" loading-width="200">
                        <template #name>
                            <span class="font-weight-light">
                                Last Withdraw:
                            </span>
                        </template>
                        <template #value>
                            <span v-if="data">
                                <route-to-transaction :transaction="computedClusterDetails.lastWithdrawTransaction">
                                    <template #text>
                                        {{computedClusterDetails.lastWithdrawBlocktime | moment('YYYY-MM-DD HH:mm:ss Z')}}&nbsp;({{computedClusterDetails.lastWithdrawBlocktimeRel}})
                                    </template>
                                </route-to-transaction>
                            </span>
                        </template>
                    </nameValueFlex>
                </div>
            </v-expand-transition>
        </template>
    </FancyCard>
</template>

<style scoped>

</style>