import apiClient from "@/services/apiClient"
import axios from "axios";

const state = {
    peers: [],
    peersLoading: false,
    peerDetail: [],
    peerDetailLoading: false,
    peerDetailPagination: false,
    peersCancelToken: axios.CancelToken.source(),

    nodeLightningNetworkID: '',
    nodeLightningNetworkIDLoading: false,
    nodeLightningNetworkChannels: undefined,

    nodeTorLoading: false,
    nodeTorData: null
}

const getters = {
    peers: (state) => state.peers,
    peersLoading: (state) => state.peersLoading,
    peerDetail: (state) => state.peerDetail,
    peerDetailLoading: (state) => state.peerDetailLoading,
    peerDetailPagination: (state) => state.peerDetailPagination,

    nodeLightningNetworkID: (state) => state.nodeLightningNetworkID,
    nodeLightningNetworkIDLoading: (state) => state.nodeLightningNetworkIDLoading,
    nodeLightningNetworkChannels: (state) => state.nodeLightningNetworkChannels,

    nodeTorLoading: (state) => state.nodeTorLoading,
    nodeTorData: (state) => state.nodeTorData
}

const mutations = {
    PEERS_START_LOADING(state) {
        state.peersCancelToken.cancel()
        state.peersCancelToken = axios.CancelToken.source()
        state.peersLoading = true
    },
    PEERS_FINISHED_LOADING(state) {
        state.peersLoading = false
    },
    PEERS_LOADED(state, data) {
        state.peers = data
    },
    PEER_DETAIL_START_LOADING(state){
        state.peerDetailLoading = true
    },
    PEER_DETAIL_LOADED(state, data){
        state.peerDetail = data
    },
    PEER_DETAIL_FINISHED_LOADING(state){
        state.peerDetailLoading = false
    },
    NODE_LIGHTNING_NETWORK_ID_SET_LOADING(state, loading) {
        if(loading) {
            state.nodeLightningNetworkID = ''
        }
        state.nodeLightningNetworkIDLoading = loading
    },
    NODE_LIGHTNING_NETWORK_ID_LOADED(state, Id) {
        state.nodeLightningNetworkID = Id
    },
    NODE_LIGHTNING_NETWORK_CHANNELS_LOADED(state, channels) {
        state.nodeLightningNetworkChannels = channels
    },
    NODE_TOR_DATA_SET_LOADING(state, loading) {
        state.nodeTorLoading = loading
    },
    NODE_TOR_DATA_LOADED(state, data) {
        state.nodeTorData = data
    }
}

const actions = {
    async loadPeers({ commit }, { currency, pagination }) {
        commit('PEERS_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/peers`, {
                params: pagination,
                cancelToken: state.peersCancelToken.token
            });
            commit('PEERS_LOADED', response)
            commit('PEERS_FINISHED_LOADING')
        } catch (error) {
            if(axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                this.dispatch("error", error.userFriendlyMessage)
                commit('PEERS_FINISHED_LOADING')
            }
        }
    },
    async loadPeerDetail({ commit }, { currency, peerId }){
        commit('PEER_DETAIL_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/peers/${peerId}`);
            commit('PEER_DETAIL_LOADED', response)
        } catch (error) {
            this.dispatch("error", error.userFriendlyMessage)
        }
        commit('PEER_DETAIL_FINISHED_LOADING')
    },
    async getNodeTransactions({ commit }, { currency, peerId, pagination }){
        let response = null
        try {
            response = await apiClient.GET(`${currency}/peers/${peerId}/transactions`, {
                params: pagination
            })

        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error
            }
        }
        return response
    },
    async getNodeTorData({ commit }, { address }) {
        let response
        try {
            response = await apiClient.GET(`TOR/addresses/${address}/`)
        } catch (error) {
            console.error(error)
        }
        return response
    },
    async getNodeActivities({ commit }, { currency, peerId, pagination }){
        let response = null
        try {
            response = await apiClient.GET(`${currency}/peers/${peerId}/activities`, {
                params: pagination
            })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error
            }
        }
        return response
    },
    async getNodeLightningNetworkID({commit}, { address }) {
        let response
        try {
            response = await apiClient.GET(`LN/database/address_search`, {
                params: {
                    address: address
                }
            })
        } catch (error) {
            this.dispatch("error", error)
        }
        return response
    },
    async getNodeLightningNetworkActivity({commit}, { address }) {
        let response
        try {
            response = await apiClient.GET(`LN/database/address_search`, {
                params: {
                    address: address
                }
            })
        } catch (error) {
            this.dispatch("error", error)
        }
        return response && response.length > 0
    },
    async getNodeLightningNetworkChannels ({ commit }, { identityKey }) {
        let response
        try {
            response = await apiClient.GET(`LN/database/node_advertised_channels`,
                {
                    params: {
                        identity_key: identityKey
                    },
                })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
        return response
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}