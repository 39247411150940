import { GET, POST, DELETE, PUT } from "@/services/apiClient"
import axios from "axios";

import {modalState} from "@/components/common/types/modal";

const allCategories = []

interface IState {
    categories,
    categoriesPagination,
    isCategoriesLoading: Boolean,
    categoriesCancelToken,
    categoriesModalState,
}

const state: IState = {
    categories: allCategories,
    categoriesPagination: {},
    isCategoriesLoading: false,
    categoriesCancelToken: null,
    categoriesModalState: modalState.Initial,
}

const getters = {
    categories: (state: IState) => state.categories,
    categoriesPagination: (state: IState) => state.categoriesPagination,
    isCategoriesLoading: (state: IState) => state.isCategoriesLoading,
    categoriesModalState: (state) => state.categoriesModalState,
}

const mutations = {
    CATEGORIES_START_LOADING(state: IState) {
        if(state.categoriesCancelToken) {
            state.categoriesCancelToken.cancel()
        }
        state.categoriesCancelToken = axios.CancelToken.source()
        state.isCategoriesLoading = true
    },
    CATEGORIES_PAGE_LOADED(state: IState, categories) {
        state.categories = categories.data
        state.categoriesPagination = categories
    },
    CATEGORIES_FINISH_LOADING(state: IState) {
        state.isCategoriesLoading = false
    },
    CATEGORIES_MODAL_SET_STATE(state,data) {
        state.categoriesModalState = data
    }
}

const actions = {
    async loadCategories({commit, dispatch}, pagination?) {
        commit("CATEGORIES_START_LOADING")
        try {
            const result = await GET("categories", {
                params: {page: 1, itemsPerPage: -1, ...pagination?.pagination},
                cancelToken: state.categoriesCancelToken.token
            })
            commit("CATEGORIES_PAGE_LOADED", result)
            commit("CATEGORIES_FINISH_LOADING")
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch("error", error.userFriendlyMessage)
                commit("CATEGORIES_FINISH_LOADING")
            }
        }
    },
    async createCategory({ commit, dispatch }, { categoryDetail }) {
        commit('CATEGORIES_START_LOADING')
        commit('CATEGORIES_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await POST("categories", categoryDetail);
            dispatch('success', response.message)
            commit('CATEGORIES_MODAL_SET_STATE', modalState.Success)
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
            commit('CATEGORIES_MODAL_SET_STATE', modalState.Error)
        }
        commit("CATEGORIES_FINISH_LOADING")
    },
    async editCategory({ commit, dispatch }, { categoryDetail }) {
        commit('CATEGORIES_START_LOADING')
        commit('CATEGORIES_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await PUT(`categories/${categoryDetail.id}`, categoryDetail);
            dispatch('success', response.message)
            commit('CATEGORIES_MODAL_SET_STATE', modalState.Success)
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
            commit('CATEGORIES_MODAL_SET_STATE', modalState.Error)
        }
        commit("CATEGORIES_FINISH_LOADING")
    },
    async deleteCategory({ commit, dispatch }, { categoryId }) {
        commit('CATEGORIES_START_LOADING')
        try {
            const response = await DELETE(`categories/${categoryId}`)
            if (response) {
                this.dispatch('success', response.message)
            }
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        commit("CATEGORIES_FINISH_LOADING")
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
