var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.liveTxsEnabled
    ? _c(
        "div",
        { staticClass: "txContainer mt-1 my-0 pa-0" },
        [
          _c(
            _setup.FancyCard,
            {
              attrs: { table: true, noGutters: true, "no-header-gutters": "" },
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticClass: "d-flex justify-space-between" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "d-block mr-2 ml-1",
                          staticStyle: {
                            filter: "opacity(0.6)",
                            float: "left",
                            bottom: "2px",
                          },
                          attrs: { width: "22" },
                        },
                        [_vm._v("mdi-cash-fast")]
                      ),
                      _vm._v(
                        "\n                    Latest Transactions\n                "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _setup.currency
                        ? _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                value: _setup.websockets,
                                multiple: "",
                                borderless: "",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: {
                                    "min-width": "34px",
                                    "max-width": "34px",
                                    padding: "0",
                                    "border-radius": "0 3px 0 0",
                                  },
                                  attrs: {
                                    value: _setup.currency.unit,
                                    small: "",
                                    disabled: !_setup.isWebsocketActive(
                                      _setup.currency.unit
                                    ),
                                    text: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _setup.toggleWebsocket(
                                        _setup.currency
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { width: "26px" },
                                    attrs: { src: _setup.currency.image },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _setup.currencies
                        ? _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                value: _setup.websockets,
                                multiple: "",
                                borderless: "",
                              },
                            },
                            _vm._l(_setup.currencies, function (currency) {
                              return _c(
                                "v-btn",
                                {
                                  key: currency.unit,
                                  staticStyle: {
                                    "min-width": "34px",
                                    "max-width": "34px",
                                    "border-radius": "0",
                                  },
                                  attrs: {
                                    value: currency.unit,
                                    disabled: !_setup.isWebsocketActive(
                                      currency.unit
                                    ),
                                    small: "",
                                    text: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _setup.toggleWebsocket(currency)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { width: "24px" },
                                    attrs: { src: currency.image },
                                  }),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_setup.currency
                        ? _c(
                            "v-btn",
                            {
                              staticStyle: {
                                width: "100px",
                                height: "28px",
                                "background-color": "rgb(92 160 237)",
                                color: "white",
                                "border-radius": "0 3px 0 0",
                              },
                              attrs: { value: _setup.websockets, text: "" },
                              on: { click: _setup.toggleAllWebsockets },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _setup.websocketsCount > 0
                                      ? "Stop all"
                                      : "Start all"
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("template", { slot: "body" }, [
                _c(
                  "div",
                  {
                    on: {
                      mouseenter: _setup.pauseWebsockets,
                      mouseleave: _setup.restartWebsockets,
                    },
                  },
                  [
                    _c("v-simple-table", {
                      attrs: { dense: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        "\n                                    Transaction ID\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                                    Crypto\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm.showInputsOutputs
                                      ? _c(
                                          "th",
                                          {
                                            staticClass: "text-center",
                                            staticStyle: { width: "200px" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    In : Out\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                                    Time\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: { width: "250px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Amount\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.showFee
                                      ? _c(
                                          "th",
                                          {
                                            staticClass: "text-center",
                                            staticStyle: { width: "200px" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Fee\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _setup.liveTransactions,
                                    function (item, index) {
                                      return _c(
                                        "tr",
                                        {
                                          key: item.key,
                                          style: _setup.rowStyle(
                                            index,
                                            _setup.liveTransactions
                                          ),
                                        },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "text-left" },
                                            [
                                              _c(
                                                _setup.RouteToTransaction,
                                                {
                                                  attrs: {
                                                    currency:
                                                      item.currency.unit,
                                                    transaction: item.data.txid,
                                                    monospace: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "text" },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "stringMiddleShortener"
                                                            )(
                                                              item.data.txid,
                                                              _vm.transactionIdLength
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "22px",
                                                  "margin-bottom": "-6px",
                                                },
                                                attrs: {
                                                  src: item.currency.image,
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.showInputsOutputs
                                            ? _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          `${item.data.vin.length} `
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        opacity: "0.6",
                                                      },
                                                    },
                                                    [_vm._v(":")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          ` ${item.data.vout.length}`
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-center",
                                              staticStyle: { opacity: "0.8" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      item.data.blockTime,
                                                      "HH:mm:ss"
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-right fontMonospace",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      _setup.convert(
                                                        item.data.value,
                                                        item.currency
                                                      ),
                                                      8
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-light",
                                                  staticStyle: {
                                                    "font-size": "0.8em",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        item.currency.unit
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.showFee
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-right fontMonospace",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        `${(
                                                          item.data.fees /
                                                          item.currency.decimals
                                                        ).toFixed(8)} `
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-light",
                                                      staticStyle: {
                                                        "font-size": "0.8em",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            item.currency.unit
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3684726656
                      ),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }