var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        { staticClass: "height100" },
        [
          _vm.user
            ? _c(
                "v-app-bar",
                {
                  staticClass: "topZIndex",
                  attrs: {
                    color: _vm.currency ? _vm.currency.unit : "secondary",
                    app: "",
                    dark: "",
                    fixed: "",
                    height: "68",
                    text: "",
                  },
                },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "mr-6 ml-5 mt-n1" },
                    [
                      _c(
                        "router-link",
                        { staticClass: "logo", attrs: { to: "/" } },
                        [
                          _vm.settings.app.brand_type === "logo"
                            ? _c("v-img", {
                                staticClass: "mx-4",
                                attrs: {
                                  src: "/img/logos/" + _vm.settingsApp.logo,
                                  "min-width": "140px",
                                  width: "140px",
                                },
                              })
                            : _vm.settings.app.brand_type === "text" &&
                              !_vm.settings.app.by_logo
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(_vm._s(this.settingsApp.name)),
                                ]),
                              ])
                            : _c("div", { staticStyle: { color: "white" } }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-h5 mb-n1 font-weight-bold",
                                    staticStyle: {
                                      "font-size": "1.9rem !important",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.settings.app.name) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "img-container" }, [
                                  _c("img", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      src: "/img/logos/by_netsearch.svg",
                                    },
                                  }),
                                ]),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ToolbarSearch"),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center mx-2" },
                    [
                      _vm.debug
                        ? _c("v-select", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              items: [
                                "superadmin",
                                "admin",
                                "hiddenreporter",
                                "reporter",
                                "user",
                              ],
                              dense: "",
                              "hide-details": "",
                              "hide-selected": "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.roleOverride,
                              callback: function ($$v) {
                                _vm.roleOverride = $$v
                              },
                              expression: "roleOverride",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mr-3" }, [
                    _c("div", { staticClass: "d-flex" }, [_c("FiatPicker")], 1),
                  ]),
                  _vm._v(" "),
                  _c("OptionsMenu"),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex overflow-y-hidden",
              staticStyle: { width: "100%" },
            },
            [
              _vm.user ? _c("SideBarMenu") : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "calc(100vh - 68px)",
                    width: "100%",
                    "overflow-y": "auto",
                    position: "relative",
                  },
                },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-snackbars", {
        attrs: {
          objects: _vm.notifications,
          "content-class": "text-pre-line snackbarMaxWidth",
        },
        on: {
          "update:objects": function ($event) {
            _vm.notifications = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ message }) {
              return [
                message.type
                  ? _c("h3", { staticClass: "mb-2" }, [
                      _vm._v(_vm._s(message.type)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                message?.message
                  ? _c("Copyable", {
                      attrs: {
                        justify: "start",
                        dark: "",
                        "on-copy": message.message,
                        "container-width": "100%",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "text",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "snackbar-text-container monospace",
                                  },
                                  [_vm._v(_vm._s(message.message))]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _vm.user ? _c("TestEnvironmentWarningModal") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }