<template>
    <v-container class="px-4 clusterAddressMeta" fluid>
        <BulkMergeClustersDialog
            ref="mergeDialogRef"
            :clusters="selectedClustersToArray"
            :currency-unit="this.filter.filterCurrency"
            redirect
            @created-event="() => {refresh();removeSelection()}"/>
        <BulkRemoveDialog
            ref="removeDialogRef"
            :items-to-remove="selectedClustersToArray"
            @delete-event="onBulkClustersRemove"/>
        <v-layout align-center>
            <h1>Custom Clusters Metadata</h1>
            <v-spacer/>
            <DeleteButton :disabled="selectedClustersNames.length === 0" class="mx-1"
                          @click="$refs.removeDialogRef.open()"/>
            <MergeButton :disabled="selectedClustersNames.length === 0" class="ml-1 mr-2"
                         @click="$refs.mergeDialogRef.open()"/>
            <ClusterMetadataDetailModal
                :categories="categories"
                :currencies="currencies"
                :isClusterMetadataDetailLoading="isClusterMetadataDetailLoading"
                :isOwnersLoading="isOwnersLoading"
                :owners="owners.data"
                new-modal
                @save-event="createClusterMetadata"
                @owner-filter-changed-event="ownerFilterChanged"
            >
                <template #showbutton="{on}">
                    <CreateButton text="Add new Cluster Metadata" v-on="on"/>
                </template>
            </ClusterMetadataDetailModal>
        </v-layout>

        <v-divider/>
        <v-divider/>

        <v-data-table
            :footer-props="{
                'items-per-page-options': [10,30,50,100],
                'show-current-page': true,
                'showFirstLastPage': true,
            }"
            :headers="headers"
            :items="clusterMetadata.data"
            :loading="clusterMetadataLoading"
            :options.sync="filter"
            :server-items-length="clusterMetadata.total ? clusterMetadata.total : 0"
            class="elevation-0 border my-2"
            dense
        >
            <template
                v-for="h in headers"
                v-slot:[`header.${h.value}`]="{ header }"
            >
                <v-checkbox
                    v-if="h.value === 'select'"
                    :key="h.value"
                    :disabled="clusterMetadataLoading || !userHasWritePermissions"
                    :indeterminate="selectedClustersNames.length !== 0 && !isWholePageSelected"
                    :value="isWholePageSelected"
                    @change="onSelectClusters"
                />
                <v-text-field
                    v-if="h.value === 'entity_id'"
                    :key="h.value"
                    v-model="debouncedFilter.filterEntityId"
                    :label="header.text"
                    append-icon="search"
                    class="text-field-label-style"
                    clearable
                    hide-details
                    single-line
                    style="padding-bottom: 5px;"
                    @input="debounceInput"
                    @click.native.stop
                />

                <div
                    v-if="h.value === 'crypto'"
                    :key="h.value"
                    style="padding-top: 7px; margin-bottom: auto; "
                >
                    <BlockchainCryptoPickerSlim
                        v-model="filter.filterCurrency"
                        :currencies="allCurrenciesExceptUnknown"
                        dense
                        @input="removeSelection"
                    />
                </div>

                <div v-if="h.value ==='addresses_count'" :key="h.value" style="padding-top: 16px;"><h3>{{ h.text }}</h3>
                </div>

                <div
                    v-if="h.value === 'categories'"
                    :key="h.value"
                    style="padding-top: 20px; margin-bottom: auto;"
                >
                    <CategoryPicker
                        v-model="filter.filterCategories"
                        :categories="categories"
                        :dense="true"
                        class="text-field-label-style"
                        @click.stop.native
                    />
                </div>

                <v-text-field
                    v-if="h.value === 'owners_count'"
                    :key="h.value"
                    v-model="debouncedFilter.filterOwner"
                    :label="header.text"
                    append-icon="search"
                    class="text-field-label-style"
                    clearable
                    hide-details
                    single-line
                    style="padding-bottom: 5px; max-width: 200px;"
                    @input="debounceInput"
                    @click.stop.native
                />

                <!--                <div :key="h.value" v-if="h.value ==='description'" style="padding-top: 20px;"><h3>{{ h.text }}</h3></div>-->

                <div v-if="h.value === 'created_at'" :key="h.value" style="padding-top: 6px; margin-bottom: auto;">
                    <DateTimePicker
                        v-model="filter.filterCreatedAt"
                        :disabledTime="true"
                        :label="header.text"
                    />
                </div>

                <div v-if="h.value === 'created_by'" :key="h.value" style="padding-top: 16px"><h3>{{ h.text }}</h3>
                </div>

                <div v-if="h.value === 'action'" :key="h.value"><h3>{{ h.text }}</h3></div>
            </template>
            <template #item="{ item }">
                <tr :class="{'pointer': userHasWritePermissions}" @click="userHasWritePermissions && onSelectCluster(item)">
                    <td class="text-center">
                        <v-checkbox
                            :input-value="selectedClustersNames.includes(item.entity_id)"
                            dense
                            :disabled="!userHasWritePermissions"
                            @click.stop="onSelectCluster(item)"/>
                    </td>
                    <td class="text-left fontMonospace dynamicTruncate" style="min-width: 200px">
                        <Copyable :on-copy="item.entity_id" justify="start">
                            <template #text="{ on }">
                                <div class="text-truncate">
                                    <router-link
                                        :to="'/' + item.crypto + '/cluster/' + item.entity_id"
                                        class="link"
                                    >
                                    <span v-on="on">
                                        <nobr>
                                            {{ item.entity_id }}
                                        </nobr>
                                    </span>
                                    </router-link>
                                </div>
                            </template>
                        </Copyable>
                    </td>
                    <td class="text-center">
                        <BlockchainCryptoBadge :crypto="item.crypto" :currencies="currencies" :iconWidth="24"/>
                    </td>
                    <td class="text-right" style="width: 130px">{{ item.addresses_count | formatNumber }}</td>
                    <td class="text-center">
                        <v-col class="justify-end">
                            <v-row class="justify-start">
                                <AddressCategoryIcon
                                    v-for="cat in item.categories.slice(0, 2)"
                                    v-bind:key="cat.id"
                                    :iconItem="cat"
                                />
                                <BaseButtonShowMoreItems
                                    :items="item.categories"
                                    :reserveSpace="false"
                                    :shownItemsCount="2"
                                >
                                    <AddressCategoryIcon
                                        v-for="cat in item.categories"
                                        :key="cat.id"
                                        :color="cat.color"
                                        :iconItem="cat"
                                        :name="cat.name"
                                        class="mx-1"
                                        medium
                                    />
                                </BaseButtonShowMoreItems>
                            </v-row>
                        </v-col>
                    </td>
                    <td style="width: 200px">
                        <div class="d-flex justify-start align-center" style="max-width: 200px">
                            <div
                                v-for="own in item.owners.slice(0, 1)"
                                v-bind:key="own.id"
                                class="fontMonospace text-truncate"
                            >
                                {{ own.name }}
                            </div>
                            <BaseButtonShowMoreItems
                                :items="item.owners"
                                :shownItemsCount="1"
                            >
                                <div
                                    v-for="owner in item.owners"
                                    :key="owner.id"
                                    class="mx-1 fontMonospace"
                                    style="font-size: 0.8em"
                                >
                                    {{ owner.name }}
                                </div>
                            </BaseButtonShowMoreItems>
                        </div>
                    </td>
                    <td class="text-center">{{ item.created_at | moment('YYYY-MM-DD HH:mm:ss Z') }}</td>
                    <td class="text-left">{{ item.creator.name }}</td>
                    <td class="text-center d-flex justify-center" style="min-width: 92px; white-space: nowrap">
                        <DataProvider :data="{ cluster: item, currencyUnit: item.crypto, clusterName: item.entity_id }"
                                      ignore-loading>
                            <ClusterCreateCustomDialog icon @created-event="refresh"/>
                            <ClusterMergeDialog :currencyUnit="item.crypto" icon
                                                @action-finished-event="() => {refresh();removeSelection();}"/>
                        </DataProvider>
                        <ClusterMetadataDetailModal
                            :key="item.id"
                            :categories="categories"
                            :clusterMetadataDetail="clusterMetadataDetail"
                            :currencies="currencies"
                            :isClusterMetadataDetailLoading="isClusterMetadataDetailLoading"
                            :isOwnersLoading="isOwnersLoading"
                            :owners="owners.data"
                            @save-event="editClusterMetadata"
                            @owner-filter-changed-event="ownerFilterChanged"
                        >
                            <template #showbutton="{on}">
                                <TableEditButton
                                    editButtonTooltip="Edit Cluster Metadata"
                                    @click="loadClusterMetadataDetail({ clusterMetadataId:item.id})"
                                    v-on="on"
                                />
                            </template>
                        </ClusterMetadataDetailModal>

                        <TableDeleteModalDialog
                            :description="`Do you really want to delete cluster ${item.entity_id} and all its metadata?`"
                            :itemToDelete="item"
                            tooltip="Delete Cluster Metadata"
                            width="40%"
                            @delete-event="deleteClusterMetadata"
                        />
                    </td>
                </tr>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                <span>
                    {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                </span>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import ClusterMetadataDetailModal from '@/components/ClusterMetadata/ClusterMetadataDetailModal'
import {
    BlockchainCryptoBadge,
    BlockchainCryptoPickerSlim,
    CategoryPicker,
    CreateButton,
    TableDeleteModalDialog,
    TableEditButton
} from "@/components/common"
import debounce from "debounce"
import AddressCategory from "@/components/address/AddressCategoryIcon";
import AddressCategoryIcon from "@/components/address/AddressCategoryIcon";
import Copyable from "@/components/common/Copyable";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import DateTimePicker from "@/components/common/DateTimePicker";
import BaseButtonShowMoreItems from "@/components/common/TableComponents/BaseButtonShowMoreItems";
import {useGetActiveCurrencyOrDefault} from "@/components/Composables/useGetActiveCurrencyOrDefault";
import ClusterMergeDialog from "@/views/Cluster/Dialogs/ClusterMergeDialog.vue";
import ClusterCreateCustomDialog from "@/views/Cluster/Dialogs/ClusterCreateCustomDialog.vue";
import DataProvider from "@/components/common/DataProvider.vue";
import MergeClustersDialog from "@/views/ClusterMetadata/Dialogs/BulkMergeClustersDialog.vue";
import BulkMergeClustersDialog from "@/views/ClusterMetadata/Dialogs/BulkMergeClustersDialog.vue";
import BulkRemoveDialog from "@/views/ClusterMetadata/Dialogs/BulkRemoveDialog.vue";
import DeleteButton from "@/components/common/Buttons/DeleteButton.vue";
import EditButton from "@/components/common/Buttons/EditButton.vue";
import MergeButton from "@/components/common/Buttons/MergeButton.vue";

export default {
    name: 'ClusterMetadataView',
    mixins: [routeSyncMixin],
    components: {
        MergeButton,
        EditButton,
        DeleteButton,
        CategoryPicker,
        BulkRemoveDialog,
        BulkMergeClustersDialog,
        MergeClustersDialog,
        DataProvider,
        ClusterCreateCustomDialog, ClusterMergeDialog,
        BaseButtonShowMoreItems,
        Copyable,
        ClusterMetadataDetailModal,
        TableEditButton,
        TableDeleteModalDialog,
        CreateButton,
        BlockchainCryptoBadge,
        BlockchainCryptoPickerSlim,
        AddressCategory,
        AddressCategoryIcon,
        DateTimePicker
    },
    computed: {
        ...mapGetters([
            "clusterMetadata",
            "clusterMetadataLoading",
            "clusterMetadataDetail",
            "isClusterMetadataDetailLoading",
            "allCurrenciesExceptUnknown",
            "owners",
            "categories",
            "isOwnersLoading",
            "currencies",
            "currency"
        ]),
        userHasWritePermissions() {
            return this.$store.getters.hasPermission("write")
        },
        isWholePageSelected() {
            if (!this.clusterMetadata?.data?.length)
                return false

            if (this.selectedClustersNames.length < this.clusterMetadata.data.length)
                return false

            return this.clusterMetadata.data.every(x => this.selectedClustersNames.includes(x.entity_id))
        },
        selectedClustersToArray() {
            if (this.selectedClusters.size !== this.selectedClustersNames.length) {
                console.error("Mismatched selected clusters")
            }
            return Array.from(this.selectedClusters.values())
        }
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                sortBy: {type: "ArrayOfStrings", default: () => ['created_at']},
                sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
                filterEntityId: {type: "String", default: () => ''},
                filterOwner: {type: "String", default: () => ''},
                filterCreatedAt: {type: "Number", default: () => undefined},
                filterCurrency: {type: "String", default: () => ''},
                filterCategories: {type: "ArrayOfNumbers", default: () => []}
            },
            debouncedFilter: {
                filterEntityId: undefined,
                filterOwner: undefined,
            },
            categoriespagination: {
                page: 1,
                itemsPerPage: 100,
            },
            headers: [
                {text: '', value: 'select', align: 'center', width: 42, sortable: false, filterable: false},
                {text: 'Entity ID', value: 'entity_id', align: 'center'},
                {text: 'Currency', value: 'crypto', align: 'left', sortable: false, width: 130},
                {text: 'Address Count', value: 'addresses_count', align: 'center'},
                {text: 'Categories', value: 'categories', align: 'center', width: 250},
                {text: 'Owners', value: 'owners_count', align: 'center'},
                {text: 'Created At', value: 'created_at', align: 'center'},
                {text: 'Created By', value: 'created_by', align: 'center', width: 140},
                {text: 'Action', value: 'action', align: 'center', sortable: false}
            ],
            selectedClusters: new Map(),
            selectedClustersNames: []
        }
    },
    watch: {
        '$route.query.filterCurrency': {
            handler(newValue) {
                if (!newValue) {
                    this.$_syncToRouteQuery(true, {
                        ...this.$route.query,
                        filterCurrency: useGetActiveCurrencyOrDefault(this.$route)
                    })
                }
            }
        }
    },
    created() {
        this.$_syncToRouteQuery(true, {
            ...this.$route.query,
            filterCurrency: useGetActiveCurrencyOrDefault(this.$route)
        })
    },
    mounted() {
        this.$store.dispatch('loadCategories')
    },
    methods: {
        async onBulkClustersRemove(clustersToRemove) {
            const promises = []
            clustersToRemove.forEach(x => {
                const promise = this.$store.dispatch("deleteClusterMetadata", {
                    currency: this.filter.filterCurrency,
                    clusterMetadataId: x.id,
                    silent: true
                })
                promises.push(promise)
            })
            await Promise.all(promises)
            this.$refs.removeDialogRef.close()
            this.removeSelection()
            this.refresh()
        },
        onSelectClusters() {
            if (this.isWholePageSelected) {
                //remove all items from this page
                this.clusterMetadata.data.forEach(d => {
                    if (this.selectedClusters.delete(d.entity_id)) {
                        this.selectedClustersNames.splice(this.selectedClustersNames.indexOf(d.entity_id), 1)
                    }
                })
            } else {
                //select missing items
                this.clusterMetadata.data.forEach(d => {
                    this.selectedClusters.set(d.entity_id, d)
                    if (!this.selectedClustersNames.includes(d.entity_id)) {
                        this.selectedClustersNames.push(d.entity_id)
                    }
                })
            }
        },
        onSelectCluster(cluster) {
            if (this.selectedClusters.has(cluster.entity_id)) {
                this.selectedClusters.delete(cluster.entity_id)
                this.selectedClustersNames.splice(this.selectedClustersNames.indexOf(cluster.entity_id), 1)

            } else {
                this.selectedClustersNames.push(cluster.entity_id)
                this.selectedClusters.set(cluster.entity_id, cluster)
            }
        },
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),
        removeSelection() {
            this.selectedClusters = new Map()
            this.selectedClustersNames = []
        },
        refresh() {
            if (this.filter.filterCurrency) {
                this.$store.dispatch('loadClusterMetadata', {
                    currency: this.filter.filterCurrency,
                    pagination: this.filter
                })
            }
        },
        async createClusterMetadata({clusterMetadataDetail}) {
            await this.$store.dispatch("createClusterMetadata", {
                currency: this.filter.filterCurrency,
                newClusterMetadata: clusterMetadataDetail
            })
            this.refresh()
        },
        async editClusterMetadata({patch, clusterMetadataDetail}) {
            await this.$store.dispatch("patchClusterMetadata", {currency: this.filter.filterCurrency, patch: patch})
            this.refresh()
        },
        ownerFilterChanged({filter}) {
            this.$store.dispatch('loadOwners', {filter, details: "basic"})
        },
        async deleteClusterMetadata({itemToDelete}) {
            await this.$store.dispatch("deleteClusterMetadata", {
                currency: this.filter.filterCurrency,
                clusterMetadataId: itemToDelete.id
            })
            this.refresh()
        },
        loadClusterMetadataDetail({clusterMetadataId}) {
            this.$store.dispatch("loadClusterMetadataDetail", {
                currency: this.filter.filterCurrency,
                id: clusterMetadataId,
                pagination: {page: 1, itemsPerPage: 10}
            })
        }
    },

}
</script>

<style>
.clusterAddressMeta .v-text-field__details {
    display: none !important;
}

.clusterAddressMeta .v-input__slot {
    margin-bottom: auto;
}

.clusterAddressMeta tr .v-input--checkbox .v-messages {
    display: none !important;
}

.clusterAddressMeta tr .v-input__slot {
    margin: 0 !important;
}

.clusterAddressMeta tr .v-input--checkbox {
    margin: 0 !important;
    padding: 0 !important;

}

</style>

