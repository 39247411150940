<template>
    <div>
        <syncedTable
            :data="data"
            :loading="loading"
            :loadingText="'Loading addresses ...'"
            :footer-props="{
                'items-per-page-options': [20, 50, 200],
                'items-per-page-text': 'Addresses per page',
                'show-current-page': true,
                'showFirstLastPage': true,
                }"
            :headers="headers"
            :total-items="clusterTotalAddresses"
            @fetch-event="onFetchEvent"
            >
            <template #tableBody="{props}">
                <tr>
                    <td>
                        <EntityLabel
                            :entity="props.item.address"
                            :identity="props.item.identity"
                            :owner="props.item.owner"
                            :isCopyable="true"
                            :showExtra="true"
                            :actAsLink="true"
                        />
                    </td>
                    <td>
                        <div class="d-flex align-center" style="min-width: 86px; max-width: 86px; position: relative">
                            <AddressCategoryIcon
                                class="ml-1"
                                v-for="cat in props.item.categories.length > 3 ? props.item.categories.slice(0,2) : props.item.categories"
                                icon-size="22px"
                                :iconItem="cat"
                                :key="cat.id"
                                :name="cat.name"
                                :color="cat.color"
                            ></AddressCategoryIcon>
                            <v-badge v-if="props.item.categories.length > 3" inline>
                                <template v-slot:badge>
                                    <v-tooltip top color="white" style="border: rgba(0,0,0,0.49) solid 1px">
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on" style="margin-top: 2px">+{{props.item.categories.length - 2}}</span>
                                        </template>
                                        <AddressCategoryIcon
                                            class="ml-1"
                                            v-for="cat in props.item.categories"
                                            medium
                                            :iconItem="cat"
                                            :key="cat.id"
                                            :name="cat.name"
                                            :color="cat.color"
                                        ></AddressCategoryIcon>
                                    </v-tooltip>
                                </template>
                            </v-badge>
                        </div>
                    </td>
                    <td class="text-right font-weight-medium">
                        <fiat-convertor :value="props.item.balance" :fiats="fiats"/>
                    </td>
                    <td class="text-right">
                        <fiat-convertor :value="props.item.received" :fiats="fiats"/>
                    </td>
                    <td class="text-right">
                        <fiat-convertor :value="props.item.send" :fiats="fiats"/>
                    </td>
                    <td class="text-right">
                        {{ props.item.txcount }}
                    </td>
                    <td class="text-center">
                        <RouteToTransaction
                            :transaction="props.item.firsttxid"
                        >
                            <template slot="text">
                                <nobr>
                                    <span v-show="relativeTimestamps">
                                        {{props.item.firstblocktimeRel}}
                                    </span>
                                    <span v-show="!relativeTimestamps">
                                        {{props.item.firstblocktime | moment('YYYY-MM-DD HH:mm:ss Z')}}
                                    </span>
                                </nobr>
                            </template>
                        </RouteToTransaction>
                    </td>
                    <td class="text-center">
                        <RouteToTransaction
                            :transaction="props.item.lasttxid"
                        >
                            <template slot="text">
                                <nobr>
                                    <span v-if="relativeTimestamps">
                                        {{props.item.lastblocktimeRel}}
                                    </span>
                                    <span v-else>
                                        {{props.item.lastblocktime | moment('YYYY-MM-DD HH:mm:ss Z')}}
                                    </span>
                                </nobr>
                            </template>
                        </RouteToTransaction>
                    </td>
                </tr>
            </template>
        </syncedTable>
    </div>
</template>

<script>
import RouteToAddress from "@/components/common/RouteToAddress";
import FiatConvertor from "@/components/common/FiatConvertor";
import RouteToTransaction from "@/components/common/RouteToTransaction";
import {mapGetters} from "vuex";
import Copyable from "@/components/common/Copyable";
import SyncedTable from "@/components/common/Table/SyncedTable";
import AddressDetailable from "@/components/common/AddressDetailable";
import EntityLabel from "@/components/common/EntityLabel";
import AddressCategoryIcon from "@/components/address/AddressCategoryIcon";

export default {
    name: "TableClusterInteractive",
    components: {
        AddressCategoryIcon,
        EntityLabel,
        AddressDetailable, SyncedTable, Copyable, RouteToTransaction, FiatConvertor, RouteToAddress
    },
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        data: {
            type: Array,
            required: false,
            default: () => []
        },
        fiats: {
            type: Object,
            required: true,
        },
        relativeTimestamps: {
            type: Boolean,
            required: false,
            default: false,
        },
        clusterTotalAddresses: {
            type: Number,
            required: true
        },
        value: {
            type: Object,
            required: false,
        },
    },
    computed: {
        ...mapGetters(["currency","currentFiat", "fiatActive"]),
        headers()
        {
                let unit = this.currentFiat && this.fiatActive ? this.currentFiat.unit : this.currency.unit
                return [
                {
                    text: "Address",
                    align: "center",
                    value: "address",
                    sortable: true
                },
                {
                    text: 'Categories',
                    align: 'center',
                    sortable: false,
                    width: '86px',
                },
                {
                    text: "Balance (" + unit + ")",
                    align: "right",
                    value: "balance",
                    sortable: true
                },
                {
                    text: "Received (" + unit + ")",
                    align: "right",
                    value: "received",
                    sortable: true
                },
                {
                    text: "Sent (" + unit + ")",
                    align: "right",
                    value: "send",
                    sortable: true
                },
                {
                    text: "# of Tx",
                    align: "center",
                    value: "txcount",
                    sortable: true,
                },
                {
                    text: "First Tx",
                    align: "center",
                    value: "firstblocktime",
                    sortable: true,
                },
                {
                    text: "Last Tx",
                    align: "center",
                    value: "lastblocktime",
                    sortable: true,
                },
            ]
        }
    },
    methods: {
        onFetchEvent(e)
        {
            this.$emit("fetch-addresses-event", e)
        },
        onMenuOpen(e,addr)
        {
            this.$refs[addr + 'cluster_addressMenu'].openMenu(e)
        }
    }
}
</script>

<style>


</style>