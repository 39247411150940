import { NavigationGuard } from "vue-router";
import store from '@/store/index'
import authenticationService from "@/services/authenticationService";


const currencyUnitGuard: NavigationGuard = function (to, from, next) {
    if (store.getters.enabledCurrencies.some( x => {
        return x.unit === to.params.currency
        }) || !to.params.currency) {
            next()
    }
    else
    {
        next({
            name: "NotFound",
            params: { 0: `/${to.fullPath}` },
        })

    }
}

const authenticationSettingsGuard: NavigationGuard = async function (to, from, next) {
    if (store.getters.user || to.name === 'LoginView') {
        if (!store.getters.settingsFetched && to.name === 'LoginView')
            store.dispatch("getSettings")
        else if (!store.getters.settingsFetched && store.getters.user){
            await  store.dispatch("getSettings")
        }
        next();
    }
    else {
        const [ undefined, authenticationResult ] = await Promise.all([
            store.dispatch("getSettings"),
            authenticationService.tryAuthenticateAsync(),
        ])
        if (authenticationResult.isAuthenticated) {
            store.dispatch("loadUser", authenticationResult.user)
            next();
        }
        else {
            next({ name: 'LoginView', query: { redirect: to.fullPath } })
        }
    }
}

export default { authenticationSettingsGuard, currencyUnitGuard }