<template>
    <div>
        <v-row v-if="!transactionsCountryLoading && transactionsCountryData">
            <v-col cols="6">
                <v-text-field
                    v-model="GEOSearch"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
                <v-data-table
                    :headers="GEOHeaders"
                    :items="transactionsCountryData.highChartData"
                    :search="GEOSearch"
                    :items-per-page="5"
                    :footer-props="{
                            'items-per-page-options':[5],
                            'disable-items-per-page': true,
                            }"
                >
                    <template v-slot:header.name="{ header }">
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="text-left">
                                <img class="mr-1" :src="'/img/flags/' + String(item[`alpha-2`]).toLowerCase() + '.png'"/>
                                {{item.countryName}}
                            </td>
                            <td class="text-left">
                                {{item.region}}
                            </td>
                            <td class="text-right">
                                {{item.y | formatNumber}}
                            </td>
                            <td>

                            </td>
                        </tr>
                    </template>
                    <template #footer.prepend>
                        <v-spacer/>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="6">
                <TransactionsCountry :countryData="transactionsCountryData"/>
            </v-col>
            <v-col cols="12">
                <ChartMap :countryData="transactionsCountryData"/>
            </v-col>
        </v-row>
        <ProgressCircular v-else/>
    </div>
</template>

<script>

import ChartMap from "@/components/chart/ChartMap.vue";
import TransactionsCountry from "@/components/transaction/TransactionsCountry.vue";
import {ProgressCircular} from "@/components/common/index";
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import {mapGetters} from "vuex";

export default {
    name: "EntityTransactionsGeo",
    components: {
        ChartMap,
        TransactionsCountry,
        ProgressCircular,
        DateTimePicker
    },
    inject: ['entityId'],
    data() {
        return {
            GEOSearch: '',
        }
    },
    computed: {
        ...mapGetters(['transactionsCountryLoading', 'transactionsCountryData']),
        GEOHeaders()
        {
            this.$options.filters.formatNumber()
            return [
                {
                    text: 'Country',
                    align: 'start',
                    value: 'countryName'
                },
                {
                    text: 'Region',
                    align: 'start',
                    value: 'region'
                },
                {
                    text: '# of Tx (out of ' + this.$options.filters.formatNumber(this.transactionsCountryData.total) + ')',
                    align: 'right',
                    value: 'y'
                },
                {
                    text: 'name',
                    width: 0,
                    value: 'name',
                    sortable: false
                },
            ]
        },
    },
    created() {
        this.$emit('geo-created-event')
    },
    methods: {

    }
}
</script>

<style scoped>

</style>