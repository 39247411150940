var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { row: "" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "white",
          attrs: { depressed: "" },
          on: {
            click: function ($event) {
              return _vm.$emit(
                "show-transaction-click-event",
                _setup.loadTransactionSides.BOTH
              )
            },
          },
        },
        [_vm._v("\n        Show on current graph\n    ")]
      ),
      _vm._v(" "),
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit(
                              "show-transaction-click-event",
                              _setup.loadTransactionSides.LEFT
                            )
                          },
                        },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("subdirectory_arrow_left")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v(" "), _c("span", [_vm._v("Show only inputs")])]
      ),
      _vm._v(" "),
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit(
                              "show-transaction-click-event",
                              _setup.loadTransactionSides.RIGHT
                            )
                          },
                        },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("subdirectory_arrow_right")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v(" "), _c("span", [_vm._v("Show only outputs")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }