<template>
    <v-alert color="red" type="info">
        Wrong Block {{ block }}
        <br />
        <strong>{{ message }}</strong>
    </v-alert>
</template>
<script>
export default {
    name: 'BlockNotFoundInfo',
    props: ["block", "message"]
}
</script>