<template>
    <SmartTooltipBase>
        <template #activator>
            <v-badge
                v-if="cases && cases.length > 0"
                :content="cases.length"
                color="grey"
                offset-x="10px"
            >
                <div class="d-flex justify-center align-center" style="flex-shrink: 0; flex-grow: 0; width: 26px">
                    <v-icon color="orange">assignment</v-icon>
                </div>
            </v-badge>
        </template>
        <div style="max-width: 450px" class="d-flex flex-column justify-center align-center">
            <div class="d-flex align-center justify-center">
                <v-icon color="orange">assignment</v-icon>
                <div class="ml-2" style="color: rgba(0,0,0,0.6)">
                    CASES
                </div>
            </div>
            <div class="width100 my-2">
                <v-divider/>
            </div>
            <v-data-table

                :items="cases"
                :items-per-page="cases.length > 10 ? 10 : -1"
                :hide-default-footer="cases.length <= 10"
                :footer-props="{
                        'items-per-page-options': [10, 20, -1],
                        'show-current-page': true,
                    }"
                dense
            >
                <template slot="item" slot-scope="props">
                    <tr>
                        <td>
                            <router-link :to="{ name: 'CaseDetail', params: { caseId: props.item.id } }"
                                         class="fontMonospace link text-truncate">
                                {{ props.item.title | truncate(40) }}
                            </router-link>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </SmartTooltipBase>
</template>

<script>
import SmartTooltipBase from "@/components/common/SmartTooltips/SmartTooltipBase";

export default {
    name: "CasesSmartTooltip",
    components: {SmartTooltipBase},
    props: {
        cases: {
            type: Array,
            required: true,
            default: () => []
        },
    }
}
</script>

<style scoped>
tr:hover {
    background-color: transparent !important;
}
</style>