<script setup>
import {useGetData} from "@/components/common/Composables/useGetData";
import ViewHeader from "@/components/Headers/ViewHeader.vue";
import {DataState} from "@/components/common/types/data";
import FancyCard from "@/components/common/Cards/FancyCard.vue";
import store from "@/store";

const {data, dataState, getData} = useGetData("getStatus")

const currencies = store.getters.currencies

</script>

<template>
    <div class="ma-4">
        <ViewHeader text="System Status"/>
        <div v-if="dataState === DataState.Loading" class="d-flex justify-center">
            <VProgressCircular color="tertiary" indeterminate/>
        </div>
        <v-row v-if="dataState === DataState.Loaded">
            <v-col v-for="{unit: currencyUnit} in currencies" :key="currencyUnit" cols="12" xl="2" md="4"
                   sm="6">
                <FancyCard no-gutters>
                    <template #header>
                        <div class="d-flex">
                            <img class="mr-2" style="width: 22px" :alt="currencyUnit"
                                 :src="store.getters.currencyByUnit(currencyUnit).image"/>
                            <span
                                class="font-weight-bold">{{
                                    store.getters.currencyByUnit(currencyUnit).name
                                }}</span>
                        </div>

                    </template>
                    <template #body>
                        <v-simple-table dense>
                            <thead>
                            <tr>
                                <th class="text-left">
                                    Service
                                </th>
                                <th class="text-right">
                                    Status
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="serviceKey in Object.keys(data.data[currencyUnit])"
                                :key="serviceKey"
                            >
                                <td class="text-left">{{ serviceKey }}</td>
                                <td class="text-right">
                                    <v-icon v-if="data.data[currencyUnit][serviceKey]" color="success">
                                        fas fa-circle-check
                                    </v-icon>
                                    <v-icon v-else color="error">
                                        fas fa-circle-xmark
                                    </v-icon>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </template>
                </FancyCard>
            </v-col>
        </v-row>
    </div>
</template>

<style scoped>

</style>