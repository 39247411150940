var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _vm.actAsLink ? "router-link" : "span",
    {
      tag: "router-link",
      staticClass: "fontMonospace",
      style: { textDecoration: "none", maxWidth: "100%" },
      attrs: { to: { path: _setup.targetUrl } },
    },
    [_vm._t("text")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }