var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, "offset-x": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { small: "", dark: "", color: "tertiary" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("\n                mdi-filter-plus\n            "),
                  ]),
                  _vm._v("\n            add filter\n        "),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { attrs: { subheader: "" } },
        [
          _c("v-subheader", [_vm._v("Filters")]),
          _vm._v(" "),
          _c(
            "v-list-item-group",
            {
              attrs: { value: _setup.props.value, multiple: "" },
              on: {
                change: function ($event) {
                  return _setup.emit("input", $event)
                },
              },
            },
            _vm._l(_setup.props.filterOptions, function (filterOption) {
              return _c("v-list-item", {
                key: filterOption.name,
                attrs: { value: filterOption },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ active }) {
                        return [
                          _c(
                            "v-list-item-action",
                            { staticClass: "mr-3" },
                            [
                              _c("v-checkbox", {
                                attrs: { "input-value": active },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(filterOption.label),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }