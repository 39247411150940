import {parseCSVFileToArrayAsync} from "@/services/File";
import store from "@/store";

export const parseSearchInputFile = async (file) => {

    //Parse the file
    const parsedData: any = await parseCSVFileToArrayAsync(file, {header: true, dynamicTyping: true})

    // Handle errors. Ignore delimiter error since it occurs often.
    parsedData.errors.forEach(err => {
        if (err.type !== "Delimiter") {
            store.dispatch("error", err.message)
        }
    })

    const resultDataArray = []

    // No data were parsed, take the header as data
    if (parsedData.data.length === 0) {
        resultDataArray.push(...parsedData.meta.fields)
    } else {

        // Under what column are the address entries?
        let headerName = undefined

        // Find intersection between candidate names and the actual fields
        const candidateNames = ["address", "addresses", "Address", "Addresses", "addr", "Addr", "addrs", "Addrs"]
        for (const candidateName of candidateNames) {
            if (parsedData.meta.fields.includes(candidateName)) {
                headerName = candidateName
                break;
            }
        }

        if (!headerName) {
            // No intersection found, the header must include only a single column (aka. only one column csv with no header)
            if (parsedData.meta.fields.length === 1) {
                headerName = parsedData.meta.fields[0]
                resultDataArray.push(headerName) // Add header to the result
            } else {
                await store.dispatch("error", "Ambiguous column definition.")
                return
            }
        }

        // Header is now set
        parsedData.data.forEach(row => {
            resultDataArray.push(row[headerName])
        })
    }

    return resultDataArray
}