<template>
  <v-card class="border my-2 maxWidth850 mx-auto" elevation="0">
    <template v-if="data.type === 'address'">
      <AddressResult :data="data"/>
    </template>

    <template v-if="data.type === 'transaction'">
      <TransactionResult :data="data"/>
    </template>

    <template v-if="data.type === 'block'">
      <BlockResult :data="data"/>
    </template>

    <template v-else-if="data.type === 'identity'">
      <IdentityResult :data="data"/>
    </template>

    <template v-else-if="data.type === 'owner'">
      <OwnerResult :data="data"/>
    </template>
  </v-card>
</template>

<script>
  import TransactionResult from "./AdvanceSearchResults/TransactionResult";
  import AddressResult from "./AdvanceSearchResults/AddressResult";
  import BlockResult from "./AdvanceSearchResults/BlockResult";
  import IdentityResult from "./AdvanceSearchResults/IdentityResult";
  import OwnerResult from "./AdvanceSearchResults/OwnerResult";

  export default {
    name: "AdvanceSearchResult",
    components: {OwnerResult, IdentityResult, BlockResult, AddressResult, TransactionResult},
    props: {
      data: {
        type: Object,
        default: null
      },
    },
  }
</script>

<style scoped>
  .maxWidth850 {
    max-width: 850px;
  }
</style>
