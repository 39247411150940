import {GEEleType} from "@/views/GraphExplorer/Components/Drawer/GEEleType";
import UseGEStore from "@/views/GraphExplorer/Composables/UseGEStore";
import store from "@/store";
import {GELayoutDirection} from "@/views/GraphExplorer/Composables/LayoutDirection";
import {GERequestAction} from "@/store/modules/GraphExplorer";
import {getAddressBalanceAndDirectionForTransaction} from "@/utils";
import {getCyCenter} from "@/views/GraphExplorer/GEUtils";

export default function (cytoscape, reLayoutNodesCb) {
    const reLayoutNodesAsync = reLayoutNodesCb
    let cy
    const Activate = (newCy) => {
        cy = newCy


        cy.on('click', onCyClick)
    }

    const {GESelectedElement, GETransactions} = UseGEStore()

    const onCyClick = (cyClickEvent) => {
        if (cyClickEvent.target === cy) {
            GESelectedElement.value = undefined
        }
    }


    const onNodeClick = (cyClickEvent) => {
        const elementData = cyClickEvent.target.data()
        GESelectedElement.value = {
            position: {
                x: cyClickEvent.target.position().x,
                y: cyClickEvent.target.position().y,
            },
            data: {
                id: cyClickEvent.target.id(),
                type: elementData.type,
                typeSpecific: elementData.typeSpecific
            }
        }
    }


    const AddTxNode = (tx, {position}) => {
        const txNode = cy.$id(tx.txhash)
        if (txNode.isNode()) {
            return txNode
        }

        const newNode = {
            data: {
                type: GEEleType.NodeTransaction,
                id: tx.txhash,
                typeSpecific: tx,
                inputsExpanded: false,
                outputsExpanded: false,
                inouts: `${tx.inputs.length} : ${tx.outputs.length}`
            },
            classes: ['transaction'],
            position: {
                x: position.x,
                y: position.y
            }
        }
        const cyNode = cy.add(newNode)
        cyNode.on('click', onNodeClick)
        return cyNode
    }
    const AddAddressNode = (address, {label, position, addressData}) => {
        const addressNode = cy.$id(address)
        if (addressNode.isNode()) {
            return addressNode
        }
        const newNode = {
            data: {
                type: GEEleType.NodeAddress,
                id: address,
                label: label ? label : `${address.substring(0, 4)}…${address.slice(-3)}`,
                typeSpecific: {
                    address: address,
                    addressData: addressData
                },
            },
            classes: ['address'],
            position: {
                x: position?.x,
                y: position?.y
            },
        }
        const cyNode = cy.add(newNode)
        cyNode.on('click', onNodeClick)
        return cyNode
    }

    const AddOnClick = () => {
        cy.nodes(".address").on('click', onNodeClick)
        cy.nodes(".transaction").on('click', onNodeClick)
        cy.nodes(".merge").on('click', onNodeClick)
    }

    const AddEdge = (nodeSrc, nodeDst, {value, GEEleType, typeSpecific}) => {

        let srcId
        let dstId

        if (typeof (nodeSrc) == "string") {
            srcId = nodeSrc
        } else {
            srcId = nodeSrc.id()
        }

        if (typeof (nodeDst) == "string") {
            dstId = nodeDst
        } else {
            dstId = nodeDst.id()
        }

        const edgeId = `${srcId}_${dstId}`
        let edge = cy.$id(edgeId)

        if (edge.isEdge()) {
            return edge
        }

        edge = cy.add({
            data: {
                type: GEEleType,
                typeSpecific: typeSpecific,
                source: srcId,
                target: dstId,
                value: Number(value).toFixed(5),
                valueExact: value,
                name: value,
                id: edgeId,
            },
        })

        return edge
    }
    const AddTxAsync = async (tx, {position, invokerAddressId, flags: {allInputs, allOutputs}}, reLayout = true) => {
        let txNode = cy.$id(tx.txhash)
        let elesToLayout = []

        if (!txNode.isNode()) {
            await store.dispatch('GEAddTransaction', {tx})
            txNode = AddTxNode(tx, {position})


            let txLayoutDirection = GELayoutDirection.Right
            if (invokerAddressId) {
                const {balance, asInput, asOutput} = getAddressBalanceAndDirectionForTransaction(invokerAddressId, tx)
                if (!asInput && asOutput) {
                    txLayoutDirection = GELayoutDirection.Left
                }
            }
            elesToLayout.push({node: txNode, direction: txLayoutDirection})
        }

        if (reLayout) {
            await reLayoutNodesAsync(elesToLayout)
            elesToLayout = []
        }

        let inputsFullyExpanded = true
        let txNodePosition = txNode.position()
        for (const txInput of tx.inputs) {
            let inputAddressNode = cy.$id(txInput.addr)

            if (txInput.isAddress === false) {
                continue
            }

            if (allInputs && (!inputAddressNode.isNode())) {
                inputAddressNode = AddAddressNode(
                    txInput.addr,
                    {
                        label: txInput.label,
                        position: {x: txNodePosition.x, y: txNodePosition.y},
                        addressData: txInput,
                    })
                elesToLayout.push({node: inputAddressNode, direction: GELayoutDirection.Left})
            }

            if (inputAddressNode.isNode()) {
                const edge = AddEdge(inputAddressNode, txNode,
                    {
                        value: txInput.value,
                        type: GEEleType.EdgeAddressTransactions,
                        typeSpecific: {input: txInput}
                    })
                edge.addClass('txInEdge')
            } else {
                inputsFullyExpanded = false
            }
        }


        if (inputsFullyExpanded) {
            txNode.data('inputsExpanded', true)
        }

        let outputsFullyExpanded = true
        txNodePosition = txNode.position()
        for (const txOutput of tx.outputs) {
            let outputAddressNode = cy.$id(txOutput.addr)

            if (txOutput.isAddress === false) {
                continue
            }

            if (allOutputs && (!outputAddressNode.isNode())) {
                outputAddressNode = AddAddressNode(
                    txOutput.addr,
                    {
                        label: txOutput.label,
                        position: {x: txNodePosition.x, y: txNodePosition.y},
                        addressData: txOutput,
                    })
                elesToLayout.push({node: outputAddressNode, direction: GELayoutDirection.Right})
            }

            if (outputAddressNode.isNode()) {
                const edge = AddEdge(txNode, outputAddressNode, {
                    value: txOutput.value,
                    GEEleType: GEEleType.EdgeTransactionsAddress,
                    typeSpecific: {output: txOutput}
                })
                edge.addClass('txOutEdge')
            } else {
                outputsFullyExpanded = false
            }
        }

        if (outputsFullyExpanded) {
            txNode.data('outputsExpanded', true)
        }

        if (reLayout) {
            await reLayoutNodesAsync(elesToLayout)
        }

        store.dispatch('GERequestAction', {
            actionType: GERequestAction.NodeRerender,
            payload: txNode.id(),
            invoker: undefined
        })
    }
    const RemoveTx = (txId) => {
        let txNode = cy.$id(txId)
        if (!txNode.isNode()) {
            console.error("TX NODE NOT IN THE GRAPH")
            return
        }
        const edgesToRemove = txNode.connectedEdges()
        cy.remove(edgesToRemove)
        cy.remove(txNode)
        store.dispatch('GERemoveTransaction', {txId})
    }
    const RemoveNode = (id) => {
        let node = cy.$id(id)
        if (!node.isNode()) {
            console.error("NODE NOT IN THE GRAPH")
            return
        }
        const edgesToRemove = node.connectedEdges()
        cy.remove(edgesToRemove)
        cy.remove(node)
    }
    const AddMergeNode = (eles) => {

        //only merge addresses or transactions nodes
        const internalNodes = eles.nodes(".address, .transaction")

        if (internalNodes.size() < 1) {
            return
        }

        const internalEdges = internalNodes.edgesWith(internalNodes)
        const edgesToOutside = internalNodes.neighborhood("edges").difference(internalEdges)


        const containedAddresses = []
        const containedTransactions = []

        internalNodes.forEach(ele => {
            if (ele.data().type === GEEleType.NodeAddress) {
                containedAddresses.push(ele.data().typeSpecific.address)
            }
            if (ele.data().type === GEEleType.NodeTransaction) {
                containedTransactions.push(ele.data().typeSpecific.txhash)
            }
        })

        const mergeNode = cy.add({
            data: {
                type: GEEleType.NodeMerge,
                internalEdges: internalEdges.jsons(),
                internalNodes: internalNodes.jsons(),
                externalEdges: edgesToOutside.jsons(),
                typeSpecific: {
                    addresses: containedAddresses,
                    transactions: containedTransactions,
                }
            },
            classes: ["merge"],
            position: getCyCenter(cy),
        })
        const mergeNodeId = mergeNode.id()
        mergeNode.on('click', onNodeClick)

        const edgeMap = new Map()
        const getKeyFromEdge = (edge) => {
            if (internalNodes.is(`#${edge.source().id()}`)) {
                console.log(`edge: ${edge.id()} Source is inside`)
                return `${edge.target().id()}|out|${edge.hasClass('txOutEdge')}`

            } else if (internalNodes.is(`#${edge.target().id()}`)) {
                console.log(`edge: ${edge.id()} Target is inside`)
                return `${edge.source().id()}|in|${edge.hasClass('txOutEdge')}`

            } else {
                console.error("SOURCE NOR TARGET IS INTERNAL NODE")
            }
        }

        for (const ele of edgesToOutside) {
            const keyForEdge = getKeyFromEdge(ele)
            const sameEdges = edgeMap.get(keyForEdge)
            if (sameEdges) {
                sameEdges.push(ele)
            } else {
                edgeMap.set(keyForEdge, [ele])
            }
        }

        edgeMap.forEach((value, key) => {
            const [neighbor, direction, hasOutEdgeClass] = key.split("|")
            let edge
            if (direction === 'in') {
                edge = AddEdge(neighbor, mergeNodeId,
                    {
                        value: value.reduce((accumulator, edge) => accumulator + edge.data().valueExact, 0),
                        type: GEEleType.EdgeMerge,
                        typeSpecific: {}
                    })
            }
            if (direction === 'out') {
                edge = AddEdge(mergeNodeId, neighbor,
                    {
                        value: value.reduce((accumulator, edge) => accumulator + edge.data().valueExact, 0),
                        type: GEEleType.EdgeMerge,
                        typeSpecific: {}
                    })
            }
            if (hasOutEdgeClass === "true") {
                edge.addClass('mergeOutEdge')
            } else {
                edge.addClass('mergeInEdge')
            }
        })
        internalEdges.remove()
        internalNodes.remove()
    }
    const RemoveMergeNode = (id) => {
        const nodeToRemove = cy.$id(id)
        nodeToRemove.connectedEdges().remove()
        AddOnClick(cy.add(nodeToRemove.data().internalNodes))
        cy.add(nodeToRemove.data().internalEdges)
        cy.add(nodeToRemove.data().externalEdges)
        cy.remove(nodeToRemove)
    }

    const AddNoteNode = (text, position) => {
        let div = document.createElement("div");
        div.innerHTML = text;
        div.classList.add("noteContent");
        div.classList.add("fontMonospace");
        cy.add({
            data: {
                dom: div,
                text: text,
            },
            classes: ['note'],
            position: {
                x: position.x,
                y: position.y
            }
        });
    }

    const RemoveNoteNode = (id) => {
        let node = cy.$id(id)
        if (!node.isNode()) {
            console.error("NODE NOT IN THE GRAPH")
            return
        }
        node.data().dom.remove()
        cy.remove(node)
    }

    const EditNoteNode = (id, text) => {
        cy.$id(id).data().dom.innerHTML = text
        cy.$id(id).data('text', text)
    }

    return {
        Activate,
        GraphTx: {AddTxAsync, RemoveTx},
        GraphNode: {
            AddAddressNode,
            AddMergeNode,
            RemoveMergeNode,
            AddNoteNode,
            RemoveNode,
            AddOnClick,
            EditNoteNode,
            RemoveNoteNode
        },
        GraphEdge: {AddEdge},
    }
}