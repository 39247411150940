var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "jumbo secondary" },
    [
      _c(
        "v-layout",
        {
          staticStyle: { "background-color": "#121212" },
          attrs: {
            "align-center": "",
            "justify-center": "",
            "fill-height": "",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { "primary-title": "" },
                    },
                    [
                      _c("div", [
                        _vm.settings.app.brand_type === "text"
                          ? _c(
                              "div",
                              { staticStyle: { color: "white" } },
                              [
                                _c(
                                  "h1",
                                  { staticStyle: { "margin-bottom": "4px" } },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.settings.app.name
                                            ? _vm.settings.app.name
                                            : "Login"
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("v-divider", { attrs: { dark: "" } }),
                                _vm._v(" "),
                                _c("div", { staticClass: "img-container" }, [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.settings.app.by_logo,
                                        expression: "settings.app.by_logo",
                                      },
                                    ],
                                    staticStyle: { width: "120px" },
                                    attrs: {
                                      src: "/img/logos/by_netsearch_grey.svg",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c("img", {
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    src: `/img/logos/${_vm.settings.app.logo}`,
                                  },
                                }),
                                _vm._v(" "),
                                _c("v-divider"),
                              ],
                              1
                            ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "text-center", staticStyle: { color: "grey" } },
                [
                  _c("div", [
                    _vm._v(
                      "Authenticate yourself first prior to any work with this system!"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("All access is being authorized and accounted."),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "pt-3",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dark: "",
                          autofocus: "",
                          rules: _vm.emailRules,
                          label: "E-mail",
                          required: "",
                          "validate-on-blur": "",
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          dark: "",
                          rules: _vm.passwordRules,
                          type: _vm.type,
                          label: "Password",
                          required: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "", tabindex: "-1" },
                                    on: { click: _vm.toggleType },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.type === "password"
                                          ? "fa-solid fa-eye-slash"
                                          : "fa-solid fa-eye"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                      _vm._v(" "),
                      _c("v-checkbox", {
                        attrs: {
                          label: "Remember me",
                          color: "#1565C0",
                          dark: "",
                          required: "",
                        },
                        model: {
                          value: _vm.remember,
                          callback: function ($$v) {
                            _vm.remember = $$v
                          },
                          expression: "remember",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-center",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { width: "94%", color: "white" },
                                  attrs: {
                                    dark: "",
                                    color: "#1565C0",
                                    elevation: _vm.valid ? 1 : 0,
                                    disabled: !_vm.valid,
                                    loading: _vm.isUserLoading,
                                  },
                                  on: { click: _vm.submit },
                                },
                                [_vm._v("Sign in")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }