var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select-validateable",
    _vm._b(
      {
        ref: "select",
        staticStyle: { "margin-bottom": "auto" },
        attrs: {
          clearable: _vm.isClearable,
          disabled: _vm.disabled,
          error: _vm.error,
          items: _vm.currencies,
          value: _vm.value,
          "hide-details": "",
          "item-text": "name",
          "item-value": "unit",
          label: "Crypto",
          outlined: "",
        },
        on: { input: _vm.change },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (data) {
              return [
                _c("v-flex", { staticClass: "ml-1 mt-2" }, [
                  _c("img", {
                    staticStyle: { width: "26px" },
                    attrs: { src: data.item.image },
                  }),
                ]),
              ]
            },
          },
          {
            key: "item",
            fn: function (data) {
              return [
                _c("v-list-item-content", [
                  _c("img", {
                    staticClass: "my-1",
                    attrs: { src: data.item.image },
                  }),
                ]),
              ]
            },
          },
        ]),
      },
      "v-select-validateable",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }