var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: {
        autocomplete: true,
        hideSelectSlotIfValue: !_vm.multiple,
      },
    },
    [
      _c("ValidationProvider", {
        attrs: { rules: _vm.rules },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c(
                  "v-autocomplete",
                  _vm._g(
                    _vm._b(
                      {
                        ref: "autocompleteRef",
                        class: _vm.innerClass,
                        attrs: {
                          "error-messages": errors,
                          "item-text": _vm.itemText,
                          "item-value": _vm.itemValue,
                          items: _setup.mappedItems,
                          loading: _vm.loading,
                          multiple: _vm.multiple,
                          "prepend-icon": _vm.noPrependIcon
                            ? undefined
                            : _vm.prependIcon,
                          "search-input": _setup.searchString,
                          value: _vm.value,
                          "clear-icon": "mdi-close-circle",
                          clearable: !_vm.clearOnlySearchString,
                          dense: "",
                          "menu-props": {
                            top: _vm.menuTop,
                            "nudge-top": _vm.menuTop ? 8 : 0,
                          },
                          flat: "",
                          label: _vm.label,
                          "no-filter": "",
                          "single-line": _vm.singleLine,
                          "return-object": "",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _setup.searchString = $event
                          },
                          "update:search-input": function ($event) {
                            _setup.searchString = $event
                          },
                          input: _setup.onEmit,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [_vm._t("item", null, { item: item })]
                              },
                            },
                            !_vm.multiple && !_vm.noSelectionSlot
                              ? {
                                  key: "selection",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._t("selection", null, { item: item }),
                                    ]
                                  },
                                }
                              : null,
                            {
                              key: "append",
                              fn: function () {
                                return [
                                  _vm.clearOnlySearchString
                                    ? _c(
                                        "v-btn",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _setup.searchString,
                                              expression: "searchString",
                                            },
                                          ],
                                          staticStyle: { "margin-top": "-6px" },
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              _setup.searchString = null
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-close-circle"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._t("append"),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "v-autocomplete",
                      _setup.attrs,
                      false
                    ),
                    _setup.listenersWithoutInput
                  )
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("div", { staticClass: "caption mt-n1 ml-1" }, [_vm._t("hint")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }