<template>
    <div  class="pt-3 white">
        <Chart v-if="!isLoading" :options="chartOptions"/>
        <ProgressCircular class="py-5" :color="currency.unit" v-else/>
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import ProgressCircular from "@/components/common/ProgressCircular"
import { mapGetters } from "vuex";
export default {
    name: "ChartHours",
    components: {
        ProgressCircular,
        Chart
    },

    props: {
        hoursR: Array,
        hoursS: Array,
        isLoading: Boolean,
    },
    computed: {
        ...mapGetters(["currency"]),
        chartOptions() {
            return {
                chart: {
                    type: 'column',
                    height: (9 / 16 * 70) + '%' // 16:9 ratio
                },
                credits: { enabled: false },
                title: { text: 'Hourly patterns' },
                xAxis: {
                    title: { text: 'Hour of day' },
                    categories: [
                        '00', '01', '02', '03', '04', '05',
                        '06', '07', '08', '09', '10', '11',
                        '12', '13', '14', '15', '16', '17',
                        '18', '19', '20', '21', '22', '23',
                    ],
                },
                yAxis: {
                    title: { text: 'Transaction count' }
                },
                plotOptions: {
                    series: { stacking: 'normal' }
                },
                tooltip: {
                    formatter: function () {
                        return `is <b>${this.series.name}</b> at <b>${this.point.category}:00</b><br/>${Math.abs(this.point.y)} transaction(s)`
                    }
                },
                series: [
                    {
                        name: 'Receiving',
                        color: '#4caf50',
                        data: this.hoursR
                    }, {
                        name: 'Sending',
                        color: '#ff5252',
                        data: this.hoursS
                    }]
            };
        }
    }
}
</script>