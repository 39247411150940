var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.BaseModalDialog, {
        ref: "modalRef",
        attrs: { title: "Address Importer", width: "1200px" },
        on: { "destroy-modal-state-event": _setup.resetState },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  _setup.DataProvider,
                  { attrs: { name: "importFormat", data: _vm.importFormat } },
                  [
                    _c(_setup.AddressImporter, {
                      ref: "addressImporterRef",
                      attrs: {
                        "action-name": _vm.actionName,
                        "default-row-values": _vm.defaultRowValues,
                        "identities-headers": _vm.identitiesHeaders,
                      },
                      on: {
                        "import-finished": _setup.handleImportFinish,
                        "close-modal": _setup.closeModal,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function ({ close, setDisabled }) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _setup.addressImporterRef?.loading,
                      small: "",
                      dark: "",
                      color: "primary",
                    },
                    on: { click: close },
                  },
                  [_vm._v("\n                Close\n            ")]
                ),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c(_setup.ImportButton, {
                  attrs: {
                    loading: _setup.addressImporterRef?.loading,
                    disabled:
                      _setup.addressImporterRef?.tableItems?.length === 0,
                  },
                  on: {
                    click: () => {
                      _setup.onImport()
                      setDisabled(true)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(_setup.ImportButton, {
        staticClass: "mr-1",
        on: {
          click: function ($event) {
            return _setup.modalRef.open()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }