var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "z-index": "9999",
        absolute: "",
        top: "",
        transition: "slide-y-transition",
        rounded: "lg",
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  { ref: "menuOpener", staticStyle: { display: "none" } },
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isMenuVisible,
        callback: function ($$v) {
          _vm.isMenuVisible = $$v
        },
        expression: "isMenuVisible",
      },
    },
    [
      _vm._v(" "),
      _vm.isMenuVisible
        ? _c("fancy-card", {
            attrs: { header: false, "body-style": "padding-top: 4px" },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "text-decoration-none",
                              attrs: {
                                to: {
                                  name: "AddressSummary",
                                  params: { address: _vm.address },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { icon: "" } },
                                [_c("v-icon", [_vm._v("open_in_new")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-1",
                              staticStyle: { height: "24px", width: "24px" },
                              attrs: { left: "" },
                            },
                            [_vm._v("mdi-tag-text-outline")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "fontMonospace" }, [
                            _vm._v(_vm._s(_vm.address)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex justify-center my-1" }, [
                        _c(
                          "div",
                          { staticStyle: { width: "90%" } },
                          [
                            _c("header-lined", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "text",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.currency.unit) +
                                            " Address\n                        "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                943627284
                              ),
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("NameValueFlex", {
                            attrs: {
                              loading: _vm.loading,
                              "loading-width": "160",
                              "name-width": "90",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "iconLeft medium",
                                          attrs: { left: "" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            fa-solid fa-coins\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-light subtitle-1",
                                          staticStyle: { "font-size": "16px" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Balance:\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticStyle: { height: "26px" },
                                              attrs: {
                                                color: _vm.currency.unit,
                                                label: "",
                                                "text-color": "white",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "subtitle-1 font-weight-bold",
                                                  staticStyle: {
                                                    "vertical-align": "middle",
                                                  },
                                                },
                                                [
                                                  _c("fiat-convertor", {
                                                    attrs: {
                                                      value:
                                                        _vm.data
                                                          .confirmedBalance,
                                                      fiats: _vm.data.fiats,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "subtitle-1 pl-1 text--white",
                                                  staticStyle: {
                                                    "vertical-align": "middle",
                                                  },
                                                },
                                                [
                                                  _c("fiat-convertor", {
                                                    attrs: { "unit-only": "" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3175633384
                            ),
                          }),
                          _vm._v(" "),
                          _c("NameValueFlex", {
                            attrs: {
                              loading: _vm.loading,
                              "loading-width": "110",
                              "name-width": "85",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-icon",
                                        { staticClass: "iconLeft medium" },
                                        [
                                          _vm._v(
                                            "\n                            mdi-download mdi-rotate-270\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _vm._v(
                                            "\n                            Received:\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticStyle: { height: "22px" },
                                              attrs: {
                                                color: "success",
                                                label: "",
                                                "text-color": "white",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "subtitle-2 font-weight-bold",
                                                  staticStyle: {
                                                    "vertical-align": "middle",
                                                  },
                                                },
                                                [
                                                  _vm.currency.unit === "ETH"
                                                    ? _c("div", [_vm._v("N/A")])
                                                    : _c("fiat-convertor", {
                                                        attrs: {
                                                          value:
                                                            _vm.data
                                                              .totalReceived,
                                                          fiats: _vm.data.fiats,
                                                        },
                                                      }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "subtitle-2 pl-1 text--white font-weight-light",
                                                  staticStyle: {
                                                    "vertical-align": "middle",
                                                  },
                                                },
                                                [
                                                  _c("fiat-convertor", {
                                                    attrs: { "unit-only": "" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              785742815
                            ),
                          }),
                          _vm._v(" "),
                          _c("NameValueFlex", {
                            attrs: {
                              loading: _vm.loading,
                              "loading-width": "100",
                              "name-width": "70",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-icon",
                                        { staticClass: "iconLeft medium" },
                                        [
                                          _vm._v(
                                            "\n                            mdi-upload mdi-rotate-90\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _vm._v(
                                            "\n                            Sent:\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticStyle: { height: "22px" },
                                              attrs: {
                                                color: "red",
                                                label: "",
                                                "text-color": "white",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "subtitle-2 font-weight-bold",
                                                  staticStyle: {
                                                    "vertical-align": "middle",
                                                  },
                                                },
                                                [
                                                  _vm.currency.unit === "ETH"
                                                    ? _c("div", [_vm._v("N/A")])
                                                    : _c("fiat-convertor", {
                                                        attrs: {
                                                          value:
                                                            _vm.data.totalSent,
                                                          fiats: _vm.data.fiats,
                                                        },
                                                      }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "subtitle-2 pl-1 text--white font-weight-light",
                                                  staticStyle: {
                                                    "vertical-align": "middle",
                                                  },
                                                },
                                                [
                                                  _c("fiat-convertor", {
                                                    attrs: { "unit-only": "" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              763533252
                            ),
                          }),
                          _vm._v(" "),
                          _c("NameValueFlex", {
                            attrs: {
                              loading: _vm.loading,
                              "loading-width": "150",
                              nameFlex: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-light subtitle-1",
                                          staticStyle: { "font-size": "16px" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Transactions:\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-end align-center",
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "subtitle-1 font-weight-bold",
                                                staticStyle: {
                                                  "vertical-align": "middle",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("formatNumber")(
                                                        _vm.data
                                                          .confirmedTransactions
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1969540297
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1109973133
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }