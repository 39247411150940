<script setup>
import CryptoPicker from "@/components/common/Pickers/CryptoPicker.vue";
import FileFormatInfo from "@/views/Addresses/Dialogs/FileFormatInfo.vue";
import CSVImporter from "@/components/Importer/CSV/CSVImporter.vue";
import {computed, inject, ref} from "vue";
import store from "@/store";
import {capitalize} from "humanize-plus";
import {ImportFormat} from "@/components/common/types/import";
import AddressTableEditable from "@/views/Addresses/Dialogs/AddressTableEditable.vue";
import useAddressImport from "@/components/common/CsvImport/useAddressImport";
import ActionConfirmDialog from "@/views/Addresses/Dialogs/ActionConfirmDialog.vue";

const props = defineProps({
    defaultRowValues: {
        type: Object,
        required: false,
        default() {
            return {
                crypto: null,
                address: null,
                label: null,
                source: null,
                url: null,
                categories: null,
                owners: null
            }
        }
    },
    identitiesHeaders: {
        type: Array,
        required: false,
        default() {
            return [
                {text: "Label", align: "left", value: "label"},
                {text: "URL", align: "left", value: "url"},
                {text: "Source", align: "left", value: "source"},
            ]
        }
    },
    actionName: {
        type: String,
        required: true
    }
})


const separator = ref(",")

const importFormat = inject("importFormat")

const onImportRows = async (rows) => {
    loading.value = true
    await processRows(rows)
}

const resetState = () => {
    tableItems.value = []
    AddressTableEditableRef.value.getDataAndResetPage()
}

const emit = defineEmits([
    "import-finished",
    "close-modal"
])

const modalState = computed(() => store.getters.casesModalState)
const isCaseImport = computed(() => importFormat.value === ImportFormat.Case)

const {
    processRows,
    importAddresses,
    addEmptyEntry,
    defaultCurrency,
    loading,
    propagateToClusters,
    skipIdentities,
    tableItems
} = useAddressImport({defaultRowValues: props.defaultRowValues, importFormat: importFormat.value})

const onImport = async (caseId) => {
    loading.value = true

    // Create import callback
    const importRequestCallback = async (value, key) =>
        await store.dispatch(props.actionName, {
            items: value,
            currencyUnit: key,
            propagateToCluster: propagateToClusters.value,
            skipIdentities: skipIdentities.value,
            caseId: caseId
        })

    await importAddresses(emit, importRequestCallback)

    loading.value = false
}

const delimiterOptions = [{name: 'Comma', value: ','}, {name: 'Semicolon', value: ';'}]

const confirmResetDialogRef = ref(null)

const AddressTableEditableRef = ref()

defineExpose({onImport, loading, tableItems})
</script>

<template>
    <v-row>
        <v-col class="d-flex align-center" cols="4">
            <CryptoPicker v-model="defaultCurrency" class="my-3" style="max-width: 300px;"/>
        </v-col>
        <v-col class="d-flex align-center d-flex" cols="8">
            <v-select
                v-model="separator"
                :items="delimiterOptions"
                item-value="value"
                class="fontMonospace"
                color="info"
                dense
                label="Select Delimiter"
                prepend-icon="mdi-file-delimited"
                style="max-width: 300px"
            >
                <template #selection="{item}">
                    <b>{{item.value}}</b><span style="opacity: 0.6">&nbsp;({{item.name}})</span>
                </template>
                <template #item="{item}">
                    <span class="fontMonospace">
                        <b>{{item.value}}</b><span style="opacity: 0.6">&nbsp;({{item.name}})</span>
                    </span>
                </template>
            </v-select>
            <v-spacer/>
            <v-checkbox
                v-model="propagateToClusters"
                color="info"
                dense
                label="Propagate metadata to co-spent clusters">
            </v-checkbox>
            <v-checkbox
                v-if="!isCaseImport"
                v-model="skipIdentities"
                class="ml-3"
                color="info"
                dense
                label="Skip identities">
            </v-checkbox>
        </v-col>
        <v-col cols="12">
            <FileFormatInfo/>
        </v-col>
        <v-col cols="12">
            <v-menu
                :close-on-content-click="false"
                bottom
                offset-y
            >
                <template #activator="{on, attrs}">
                    <v-btn
                        :disabled="tableItems.length !== 0"
                        class="mb-4"
                        color="primary"
                        small
                        v-bind="attrs"
                        v-on="on">
                        <v-icon left>
                            mdi-text-box-plus-outline
                        </v-icon>
                        Add defaults
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(keyName, index) in Object.keys(defaultRowValues)"
                        :key="index">
                        <v-text-field
                            v-model="defaultRowValues[keyName]"
                            :placeholder="capitalize(keyName)"
                            class="fontMonospace"
                            dense
                            clearable
                            clear-icon="mdi-close-circle"
                        />
                    </v-list-item>
                </v-list>
            </v-menu>
            <CSVImporter :separator="separator"
                         @file-selected-event="onImportRows"/>
        </v-col>
        <v-col cols="12">
            <div class="d-flex justify-end" style="gap: 8px">
                <ActionConfirmDialog ref="confirmResetDialogRef" :on-confirm-action-callback="resetState"
                                     content-text="You are about to reset the address table."/>
                <v-btn
                    :disabled="tableItems.length === 0"
                    color="error"
                    small
                    @click="confirmResetDialogRef.open()">
                    <v-icon left>
                        delete
                    </v-icon>
                    Reset Table
                </v-btn>
                <v-btn
                    color="success"
                    small
                    @click="() => {addEmptyEntry(); AddressTableEditableRef.getDataAndResetPage()}">
                    <v-icon left>
                        add_box
                    </v-icon>
                    Add entry
                </v-btn>
            </div>
            <AddressTableEditable ref="AddressTableEditableRef" :table-items="tableItems"
                                  :identities-headers="identitiesHeaders" :loading="loading"/>
        </v-col>
    </v-row>
</template>