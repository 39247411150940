var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _vm.dataState === _setup.DataState.Loading
        ? _c(_setup.NProgress, { attrs: { progressKey: "/search" } })
        : _vm.dataState === _setup.DataState.Error
        ? _c(
            "div",
            { staticClass: "d-flex align-center justify-center mt-10" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "my-n2",
                  attrs: {
                    border: "left",
                    dense: "",
                    outlined: "",
                    text: "",
                    type: "error",
                    width: "400px",
                  },
                },
                [_vm._v(_vm._s(_vm.error) + "\n        ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dataState === _setup.DataState.Loaded
        ? _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "4", md: "6", xl: "3" } },
                [
                  _c(_setup.AddressSearchResultSingle, {
                    attrs: {
                      "get-location-from-resource": (r) => `/cases/${r.id}`,
                      "resource-type-item": _vm.data.Case,
                      icon: "assignment",
                      title: "Cases",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "4", md: "6", xl: "3" } },
                [
                  _c(_setup.AddressSearchResultSingle, {
                    attrs: {
                      "get-location-from-resource": (r) =>
                        `/${r.crypto}/cluster/${r.clusterId}`,
                      "resource-type-item": _vm.data.Cluster,
                      icon: "mdi-hub",
                      title: "Clusters",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }