<script setup>
import {computed} from "vue";
import UserInfoCard from "@/components/user/UserProfileView/UserInfoCard.vue";
import ViewHeader from "@/components/Headers/ViewHeader.vue";
import store from "@/store";
import UserCasesTable from "@/components/user/UserProfileView/UserCasesTable.vue";
import UserGraphsTable from "@/components/user/UserProfileView/UserGraphsTable.vue";
import UserLogsTable from "@/components/user/UserProfileView/UserLogsTable.vue";

const user = computed(() => store.getters.user)

const hasCasePermission = computed(() => store.getters.hasPermission('case'))

const hasGraphPermission = computed(() => store.getters.hasPermission('graph'))

const hasLogsPermission = computed(() => store.getters.hasPermission('logs'))
</script>

<template>
    <div>
        <v-container class="px-4" fluid>

            <ViewHeader text="User Profile" class="mb-2" no-dividers/>

            <div class="d-flex flex-column" style="gap: 12px">
                <v-row>
                    <v-col cols="3">
                        <UserInfoCard :user="user"/>
                    </v-col>
                </v-row>

                <UserCasesTable v-if="hasCasePermission" :userId="user.id"/>

                <UserGraphsTable v-if="hasGraphPermission" :userId="user.id"/>

                <UserLogsTable v-if="hasLogsPermission"/>
            </div>
        </v-container>
    </div>
</template>

<style>

</style>