var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "d-flex flex-column height100 px-4",
      staticStyle: { gap: "8px" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-wrap", staticStyle: { gap: "8px" } },
        [
          _c(
            "div",
            { staticStyle: { flex: "1" } },
            [
              _c("h3", { staticStyle: { "margin-top": "20px" } }, [
                _vm._v("Mnemonic Options"),
              ]),
              _vm._v(" "),
              _c(_setup.FancyCard, {
                staticStyle: { "min-width": "510px" },
                attrs: { dense: "", header: false },
                scopedSlots: _vm._u([
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex mb-1",
                            staticStyle: { gap: "8px" },
                          },
                          [
                            _c(_setup.BlockchainCryptoPicker, {
                              staticStyle: { "max-width": "332px" },
                              attrs: {
                                filled: "",
                                dense: "",
                                outlined: false,
                                currencies: _setup.currenciesInfo,
                                value: _setup.selectedCrypto.unit,
                              },
                              on: { input: _setup.changeCurrency },
                            }),
                            _vm._v(" "),
                            _c("v-select", {
                              attrs: {
                                filled: "",
                                dense: "",
                                items: _setup.mnemonicLanguages,
                                "item-text": "language",
                                label: "Mnemonic Language",
                              },
                              on: { change: _setup.clearTableGetNewKey },
                              scopedSlots: _vm._u([
                                {
                                  key: "selection",
                                  fn: function ({ item }) {
                                    return [
                                      _c("img", {
                                        staticClass: "mb-1 mr-1",
                                        attrs: {
                                          src: `../../../img/flags/${item.countryCode}.png`,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(item.language)),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c("img", {
                                        staticClass: "mb-1 mr-1",
                                        attrs: {
                                          src: `../../../img/flags/${item.countryCode}.png`,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(item.language)),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _setup.selectedLanguage,
                                callback: function ($$v) {
                                  _setup.selectedLanguage = $$v
                                },
                                expression: "selectedLanguage",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _setup.selectedCrypto.unit === "LTC"
                          ? _c(
                              "div",
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    dense: "",
                                    label:
                                      "Use Ltub / Ltpv extended key format",
                                    "hide-details": "",
                                  },
                                  on: {
                                    change: _setup.updateLtcExtendedKeyFormat,
                                  },
                                  model: {
                                    value:
                                      _setup.useAlternativeLtcExtendedKeyFormat,
                                    callback: function ($$v) {
                                      _setup.useAlternativeLtcExtendedKeyFormat =
                                        $$v
                                    },
                                    expression:
                                      "useAlternativeLtcExtendedKeyFormat",
                                  },
                                }),
                                _vm._v(" "),
                                _c("v-divider"),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "ValidationObserver",
                          { ref: "validator" },
                          [
                            _c(_setup.VTextareaValidateable, {
                              attrs: {
                                styles: "margin-top: 20px",
                                label: "Mnemonic Phrase",
                                rows: "3",
                                dense: "",
                                placeholder: `Enter a mnemonic`,
                                rules: `required|mnemonic:${_setup.selectedLanguageFormatted}`,
                                spellcheck: "false",
                              },
                              on: { change: _setup.clearTableGetNewKey },
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      !_setup.isMnemonicValid &&
                                      _setup.mnemonic !== ""
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "#ff5252",
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                                            mdi-alert\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                137956484
                                              ),
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "Invalid mnemonic phrase or language."
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _setup.mnemonic,
                                callback: function ($$v) {
                                  _setup.mnemonic = $$v
                                },
                                expression: "mnemonic",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex flex-column flex",
              staticStyle: { "max-width": "1000px" },
            },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "flex-grow-0",
                  attrs: {
                    light: "",
                    grow: false,
                    color: _setup.selectedCrypto.hexColor,
                  },
                  on: { change: _setup.clearTableGetNewKey },
                  model: {
                    value: _setup.selectedTab,
                    callback: function ($$v) {
                      _setup.selectedTab = $$v
                    },
                    expression: "selectedTab",
                  },
                },
                _vm._l(_setup.bipSpecs, function (bip) {
                  return _c(
                    "v-tab",
                    { key: bip.name, attrs: { disabled: bip.disabled() } },
                    [_vm._v(_vm._s(bip.name))]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(_setup.BipInfo, {
                attrs: {
                  selectedBipSpecs: _setup.bipSpecs[_setup.selectedBip],
                  selectedBip: _setup.selectedBip,
                  bipSpecs: _setup.bipSpecs,
                },
                on: {
                  parameterChange: _setup.clearTableGetNewKey,
                  addressTypeChange: _setup.addressTypeChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap", staticStyle: { gap: "8px" } },
        [
          _c(_setup.AddressDerivationOptionsCard, {
            attrs: {
              currency: _setup.selectedCrypto,
              numberOfRowsToGenerate: _setup.numberOfRowsToGenerate,
              startingIndex: _setup.startingIndex,
              bchAddressFormat: _setup.bchAddressFormat,
              useHardenedAddresses: _setup.useHardenedAddresses,
              "number-of-addresses-yet-to-generate":
                _setup.numberOfAddressesYetToGenerate,
              selectedBip: _setup.selectedBip,
            },
            on: {
              "update:numberOfRowsToGenerate": function ($event) {
                _setup.numberOfRowsToGenerate = $event
              },
              "update:number-of-rows-to-generate": function ($event) {
                _setup.numberOfRowsToGenerate = $event
              },
              "update:startingIndex": function ($event) {
                _setup.startingIndex = $event
              },
              "update:starting-index": function ($event) {
                _setup.startingIndex = $event
              },
              "update:bchAddressFormat": [
                function ($event) {
                  _setup.bchAddressFormat = $event
                },
                _setup.clearTableGetNewKey,
              ],
              "update:bch-address-format": function ($event) {
                _setup.bchAddressFormat = $event
              },
              "update:useHardenedAddresses": _setup.updateRefAndReset,
            },
          }),
          _vm._v(" "),
          _c(_setup.DerivedAddressSummary, {
            attrs: { data: _setup.data, selectedCrypto: _setup.selectedCrypto },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mb-1",
          staticStyle: { "max-height": "36px" },
          attrs: { block: "" },
          on: { click: _setup.callQueueAddressesToGenerate },
        },
        [_vm._v("Generate Rows")]
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _setup.warningDialog,
            callback: function ($$v) {
              _setup.warningDialog = $$v
            },
            expression: "warningDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("\n                Address amount\n            "),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      `You're trying to generate ${_setup.numberOfRowsToGenerate} rows at once, are you sure you want to continue?`
                    ) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _setup.dialogClose(false)
                        },
                      },
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _setup.dialogClose(true)
                        },
                      },
                    },
                    [_vm._v("\n                    Continue\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(_setup.DataTableVirtualScroll, {
        attrs: {
          data: _setup.data,
          headers: _setup.headers,
          "table-max-height": "calc(100vh - 570px)",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({
              path,
              address,
              addressData,
              publicKey,
              privateKey,
            }) {
              return [
                _c("tr", [
                  _c("td", { staticClass: "fontMonospace text-left" }, [
                    _vm._v(_vm._s(path)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fontMonospace text-left" },
                    [
                      _c(_setup.EntityLabel, {
                        attrs: {
                          entity: address,
                          isCopyable: true,
                          actAsLink:
                            addressData?.data?.data.confirmedTransactions > 0,
                          currency: _setup.selectedCrypto.unit,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticStyle: { "min-width": "220px" } },
                    [
                      _c(_setup.AddressInfo, {
                        attrs: {
                          address: address,
                          currency: _setup.selectedCrypto,
                          addressData: addressData,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fontMonospace" },
                    [
                      _c(_setup.Copyable, {
                        attrs: { "on-copy": publicKey, justify: "left" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "text",
                              fn: function ({ on, attr }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(_vm._b({}, "span", attr, false), on),
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("stringMiddleShortener")(
                                              publicKey,
                                              30
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fontMonospace" },
                    [
                      _c(_setup.Copyable, {
                        attrs: { "on-copy": privateKey, justify: "left" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "text",
                              fn: function ({ on, attr }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(_vm._b({}, "span", attr, false), on),
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm._f("stringMiddleShortener")(
                                              privateKey,
                                              30
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }