var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: !_vm.item.isAddress && "activeGroup",
      style: _vm.isSelected(_vm.item.addr) ? "background-color: #EFEFEF" : "",
      attrs: { id: `output/${_vm.item.n}${_vm.item.value}${_vm.item.addr}` },
      on: {
        click: function ($event) {
          !_vm.item.isAddress && _vm.expand(!_vm.isExpanded)
        },
      },
    },
    [
      _c("td", { staticClass: "d-flex justify-end align-center" }, [
        _vm.item.is_change
          ? _c(
              "div",
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    staticClass: "mr-2",
                                    attrs: { color: "success" },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n                        mdi-arrow-u-left-bottom\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      95249314
                    ),
                  },
                  [_vm._v("\n                Spare change\n            ")]
                ),
              ],
              1
            )
          : _vm.item.is_lightning_network_channel_opener
          ? _c(
              "div",
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "#ffc001" },
                                },
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                        mdi-flash\n                    "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _vm._v(
                      "\n                This output was used to open a lightning network channel.\n            "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "fontMonospace d-flex align-center relative" },
          [
            _c("FiatConvertor", {
              attrs: {
                fiats: _vm.fiats,
                value: _vm.item.value,
                "with-unit": "",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("td", [
        !_vm.itemOuter
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex justify-center align-center countingBadges relative",
                staticStyle: {
                  "min-height": "27px",
                  "max-width": "92px",
                  "min-width": "92px",
                  top: "6px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-center",
                    staticStyle: { "min-width": "28px" },
                  },
                  [
                    _vm.item.cases.length > 0
                      ? _c("CasesSmartTooltip", {
                          attrs: { cases: _vm.item.cases },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-center",
                    staticStyle: { "min-width": "28px" },
                  },
                  [
                    _vm.item.owners.length > 0
                      ? _c("OwnersSmartTooltip", {
                          attrs: { owners: _vm.item.owners },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-center",
                    staticStyle: { "min-width": "36px" },
                  },
                  [
                    _vm.item.identities.length > 0
                      ? _c("IdentitiesSmartTooltip", {
                          attrs: { identities: _vm.item.identities },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("td", [
        _c(
          "div",
          {
            staticClass: "d-flex align-center",
            staticStyle: { position: "relative" },
          },
          [
            _vm.itemOuter || !_vm.item.isAddress
              ? _c(
                  "div",
                  { staticStyle: { position: "absolute", height: "20px" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-center align-center",
                        staticStyle: {
                          "min-width": "34px",
                          "margin-left": "-37px",
                          position: "absolute",
                        },
                      },
                      [
                        !_vm.item.isAddress
                          ? _c("v-badge", {
                              attrs: {
                                tile: "",
                                inline: "",
                                overlap: "",
                                content: _vm.item.inouts.length,
                              },
                            })
                          : _vm.itemOuter &&
                            _vm.index === _vm.itemOuter.inouts.length - 1
                          ? _c("img", {
                              staticStyle: {
                                filter: "opacity(0.6)",
                                width: "20px",
                              },
                              attrs: {
                                src: "/img/ico/list-bullet-end.svg",
                                alt: "",
                              },
                            })
                          : _vm.itemOuter
                          ? _c("img", {
                              staticStyle: {
                                filter: "opacity(0.6)",
                                width: "20px",
                              },
                              attrs: {
                                src: "/img/ico/list-bullet-middle.svg",
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("EntityLabel", {
              attrs: {
                entity: _vm.item.addr,
                identity: _vm.item.identity,
                owner: _vm.item.owner,
                color: _vm.item.categories[0]
                  ? _vm.item.categories[0].color
                  : undefined,
                isCopyable: !_vm.itemOuter,
                showExtra: !_vm.itemOuter,
                actAsLink: !_vm.itemOuter,
                currency: _vm.currency.unit,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("td", [
        _c(
          "div",
          {
            staticClass: "d-flex align-center",
            staticStyle: {
              "min-width": "86px",
              "max-width": "86px",
              position: "relative",
            },
          },
          [
            !_vm.item.isAddress
              ? _c(
                  "v-btn",
                  {
                    staticClass: "pa-0",
                    staticStyle: {
                      "margin-top": "1px",
                      "margin-left": "-30px",
                    },
                    attrs: { absolute: "", light: "", "x-small": "", icon: "" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.expand(!_vm.isExpanded)
                      },
                    },
                  },
                  [
                    _vm.isExpanded
                      ? _c("v-icon", [_vm._v("keyboard_arrow_up")])
                      : _c("v-icon", [_vm._v("keyboard_arrow_down")]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(
              _vm.item.categories.length > 2
                ? _vm.item.categories.slice(0, 2)
                : _vm.item.categories,
              function (cat) {
                return _c("AddressCategoryIcon", {
                  key: cat.id,
                  staticClass: "ml-1",
                  attrs: {
                    "icon-size": "22px",
                    iconItem: cat,
                    name: cat.name,
                    color: cat.color,
                    cursor: "auto",
                  },
                })
              }
            ),
            _vm._v(" "),
            _c(
              "BaseButtonShowMoreItems",
              { attrs: { items: _vm.item.categories, shownItemsCount: 2 } },
              _vm._l(_vm.item.categories, function (cat) {
                return _c("AddressCategoryIcon", {
                  key: cat.id,
                  staticClass: "mx-1",
                  attrs: {
                    iconItem: cat,
                    medium: "",
                    name: cat.name,
                    color: cat.color,
                  },
                })
              }),
              1
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("td", [
        _vm.item.isAddress
          ? _c(
              "div",
              {
                staticClass: "d-flex align-center",
                staticStyle: {
                  position: "relative",
                  height: "20px",
                  right: "12px",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { position: "absolute" } },
                  [
                    _vm.item.spentTxId
                      ? _c("RouteToTransaction", {
                          attrs: {
                            "no-decoration": "",
                            transaction: _vm.item.spentTxId,
                            iconLink: true,
                            currency: _vm.currency.unit,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "text",
                                fn: function () {
                                  return [
                                    _c("v-hover", {
                                      staticStyle: {
                                        "border-radius": "50%",
                                        padding: "4px",
                                        margin: "0px",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ hover }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "cursor",
                                                    style: hover && {
                                                      "background-color":
                                                        "#1976d2",
                                                    },
                                                    attrs: {
                                                      small: "",
                                                      color: hover
                                                        ? "white"
                                                        : "blue darken-2",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                fa-solid fa-arrow-right\n                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        995430028
                                      ),
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1225407648
                          ),
                        })
                      : _vm.txType === "BTC"
                      ? _c(
                          "v-icon",
                          {
                            staticStyle: { "margin-left": "4px" },
                            attrs: { disabled: "", small: "" },
                          },
                          [
                            _vm._v(
                              "\n                    fa-solid fa-arrow-right\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }