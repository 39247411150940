import { render, staticRenderFns } from "./NeedleTypeAddress.vue?vue&type=template&id=74e8e5da&scoped=true&"
import script from "./NeedleTypeAddress.vue?vue&type=script&setup=true&lang=js&"
export * from "./NeedleTypeAddress.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e8e5da",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBadge,VDataTable,VImg,VTab,VTabItem,VTabs,VTabsItems})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74e8e5da')) {
      api.createRecord('74e8e5da', component.options)
    } else {
      api.reload('74e8e5da', component.options)
    }
    module.hot.accept("./NeedleTypeAddress.vue?vue&type=template&id=74e8e5da&scoped=true&", function () {
      api.rerender('74e8e5da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/ExpansionPanel/NeedleTypes/NeedleTypeAddress.vue"
export default component.exports