import apiClient from "@/services/apiClient"
import axios from "axios"

const state = {
    searchText: '',
    results: false,
    loading: false,
    cancelToken: null
}

const getters = {
    advanceSearchResults: (state) => state.results,
    advanceSearchLoading: (state) => state.loading
}

const mutations = {
    START_ADVANCE_SEARCH(state) {
        state.loading = true
        if (state.cancelToken) {
            state.cancelToken.cancel()
        }
        state.cancelToken = axios.CancelToken.source()
    },
    STORE_ADVANCE_SEARCH_RESULTS(state, results) {
        state.results = results
        state.loading = false
    },
    STORE_ADVANCE_SEARCH_FINISH(state) {
        state.loading = false
    }
}

const actions = {
    async advanceSearch({ commit, state, dispatch }, params) {
        commit('START_ADVANCE_SEARCH')
        try {
            const result = await apiClient.POST("advanceSearch", params, { cancelToken: state.cancelToken.token })
            commit('STORE_ADVANCE_SEARCH_RESULTS', result)
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        commit("STORE_ADVANCE_SEARCH_FINISH")
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
