<template>
    <ValidationProvider
        v-slot="{ errors }"
        :disabled="$attrs.disabled"
        :name="$attrs.label"
        :rules="rules"
    >
        <v-textarea
            v-model="innerValue"
            :error-messages="errors"
            :style="styles"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template #prepend>
                <slot name="prepend"/>
            </template>
            <template #append>
                <slot name="append"/>
            </template>
        </v-textarea>
    </ValidationProvider>
</template>

<script>
export default {
    name: "VTextAreaValidateable",
    props: {
        rules: {
            type: [Object, String],
            default: ""
        },
        // must be included in props
        value: {
            type: null
        },
        styles: {
            type: String,
            required: false,
            default: ''
        }
    },
    data: () => ({
        innerValue: ""
    }),
    watch: {
        // Handles internal model changes.
        innerValue(newVal) {
            this.$emit("input", newVal);
        },
        // Handles external model changes.
        value(newVal) {
            this.innerValue = newVal;
        }
    },
    created() {
        if (this.value) {
            this.innerValue = this.value;
        }
    }
};
</script>