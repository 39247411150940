var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: { width: "80%", "max-width": "1200px", title: "Monitoring Detail" },
    on: {
      "load-modal-state-event": _vm.loadState,
      "destroy-modal-state-event": _vm.destroyState,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-center interceptorParamsContainer",
                  },
                  [
                    _c("TextWithLabel", {
                      attrs: { label: "IP Address", text: _vm.ip },
                    }),
                    _vm._v(" "),
                    _c("TextWithLabel", {
                      attrs: { label: "Port", text: _vm.portComputed },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mx-4", staticStyle: { width: "120px" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-column justify-start",
                            staticStyle: { height: "45px" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c("span", { staticClass: "button mt-n1" }, [
                                  _vm._v("Status"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "margin-top": "-4px",
                                },
                              },
                              [
                                ["NOT_MONITORED", "STOPPED", "FAILED"].includes(
                                  _vm.state.status
                                )
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "body-2 font-weight-bold",
                                        staticStyle: { color: "red" },
                                      },
                                      [_vm._v(_vm._s(_vm.state.status))]
                                    )
                                  : [
                                      "CONNECTING",
                                      "STARTING",
                                      "STOPPING",
                                    ].includes(_vm.state.status)
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "body-2 font-weight-bold",
                                        staticStyle: { color: "orange" },
                                      },
                                      [_vm._v(_vm._s(_vm.state.status))]
                                    )
                                  : ["CONNECTED"]
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "body-2 font-weight-bold",
                                        staticStyle: { color: "green" },
                                      },
                                      [_vm._v(_vm._s(_vm.state.status))]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "body-2 font-weight-bold",
                                      },
                                      [_vm._v(_vm._s(_vm.state.status))]
                                    ),
                              ]
                            ),
                            _vm._v(" "),
                            ["CONNECTING", "STARTING", "STOPPING"].includes(
                              _vm.state.status
                            )
                              ? _c("v-progress-linear", {
                                  attrs: { indeterminate: "", color: "orange" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mx-4 mb-2" },
                      [
                        _c("CryptoInterceptorControlPanelSmall", {
                          attrs: { ip: _vm.ip, port: _vm.portComputed },
                          on: {
                            "monitoring-start-event": _vm.onMonitorStartEvent,
                            "monitoring-stop-event": _vm.onMonitorStopEvent,
                            "peer-polling-start-event": _vm.onPeerStartPolling,
                            "state-change-event": function ($event) {
                              return _vm.getPeerFiles()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("h2", { staticClass: "mt-4" }, [
                _vm._v("Captured "),
                _c("span", { staticClass: "font-italic" }, [_vm._v(".pcap")]),
                _vm._v(" files"),
              ]),
              _vm._v(" "),
              _c(
                "nobr",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-0 border mt-2",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.peerFiles ? _vm.peerFiles.data : [],
                      "server-items-length": _vm.peerFiles
                        ? _vm.peerFiles.total
                        : 0,
                      loading: _vm.peerFilesLoading,
                      "no-data-text": "No .pcaps found",
                      dense: "",
                      options: _vm.pagination,
                      "footer-props": {
                        "items-per-page-options": [20, 50, 100],
                      },
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.pagination = $event
                      },
                      "update:items-per-page": _vm.itemsPerPageUpdated,
                      "update:page": _vm.pageUpdated,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (props) {
                          return [
                            _c("tr", [
                              _c("td", { staticClass: "text-center" }, [
                                !props.item.is_complete
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pulsate font-weight-medium",
                                          staticStyle: { color: "green" },
                                        },
                                        [_vm._v("Active!")]
                                      ),
                                    ])
                                  : _c("div", [
                                      _vm._v(
                                        "\n                            Complete\n                        "
                                      ),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-overflow" }, [
                                _vm._v(" " + _vm._s(props.item.filename)),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-overflow" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatDateTime")(
                                        props.item.created_at
                                      )
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatNumber")(
                                        props.item.size / 1000,
                                        2
                                      )
                                    ) +
                                    " KB"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-oveflow" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-center" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("ButtonWrapperHoverTooltip", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "active",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticStyle: {
                                                          "text-decoration":
                                                            "none",
                                                        },
                                                        attrs: {
                                                          href: _vm.urlToFile(
                                                            props.item
                                                          ),
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                small: "",
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: "blue",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                    mdi-download\n                                                "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "tooltip",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        Download\n                                    "
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "alt",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          small: "",
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "\n                                                mdi-download\n                                            "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("TableDeleteModalDialog", {
                                          attrs: {
                                            disabled: !props.item.is_complete,
                                            fancy: "",
                                            "item-to-delete": props.item,
                                            tooltip: "Delete",
                                          },
                                          on: {
                                            "delete-event": _vm.deleteFile,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close }) {
            return [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "success", outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.save(close)
                    },
                  },
                },
                [_vm._v(_vm._s("Ok"))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }