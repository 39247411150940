var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "height100 scroll-y" },
    [
      _setup.loading
        ? _c(
            "div",
            [
              _c(_setup.ProgressCircular, {
                staticClass: "pt-10",
                attrs: { color: _setup.currency.unit },
              }),
            ],
            1
          )
        : !_setup.entityInfo || !_setup.entityId
        ? _c("v-container", [
            _c(
              "div",
              [
                _c(_setup.AddressNotFoundInfo, {
                  staticClass: "my-2",
                  attrs: {
                    address: _setup.entityId,
                    message: "Entity with the specified name does not exist!",
                  },
                }),
              ],
              1
            ),
          ])
        : _c(
            "div",
            [
              _c("h1", [_vm._v("Transactions")]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mb-3" }),
              _vm._v(" "),
              _c(_setup.TransactionsFilter, {
                on: { "on-filter-event": _setup.getDataForCurrentSubview },
                model: {
                  value: _setup.filter,
                  callback: function ($$v) {
                    _setup.filter = $$v
                  },
                  expression: "filter",
                },
              }),
              _vm._v(" "),
              _setup.currency.typeFamily !== "ETH"
                ? _c(
                    "v-tabs",
                    {
                      attrs: {
                        value: _setup.tabValue,
                        "slider-color": _setup.currency.hexColor,
                      },
                      on: { change: _setup.handleTabChange },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          attrs: {
                            to: _setup.getRelativeDestination("/transactions"),
                          },
                        },
                        [_vm._v("\n                Transactions\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab",
                        {
                          attrs: {
                            to: _setup.getRelativeDestination(
                              "/transactions/geo"
                            ),
                          },
                        },
                        [_vm._v("\n                Geolocations\n            ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3 width100" },
                [
                  _c(
                    _setup.DataProvider,
                    { attrs: { name: "entityType", data: _setup.entityType } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }