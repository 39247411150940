<script setup>
import store from "@/store";
import {ref} from "vue";

const props = defineProps({
    ipAddress: {
        type: String,
        required: true
    }
})

const wasActiveInTor = ref(false)

const getActivity = async () => {
    const response = await store.dispatch('getNodeTorData', {address: props.ipAddress})
    wasActiveInTor.value = response?.result.length > 0
}

const torNetworkEnrichmentEnabled = store.getters.settings.server.torQueryEnabled

if (torNetworkEnrichmentEnabled) {
    getActivity()
}

</script>

<template>
    <div v-if="wasActiveInTor">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <img v-on="on" src="/img/ico/tor-onion.svg" alt="Tor" style="width: 24px" />
            </template>
            This node was active in the Tor network.
        </v-tooltip>
    </div>
</template>

<style scoped>

</style>