import { render, staticRenderFns } from "./CategoryBadge.vue?vue&type=template&id=756e0bd0&"
import script from "./CategoryBadge.vue?vue&type=script&lang=js&"
export * from "./CategoryBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VIcon,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('756e0bd0')) {
      api.createRecord('756e0bd0', component.options)
    } else {
      api.reload('756e0bd0', component.options)
    }
    module.hot.accept("./CategoryBadge.vue?vue&type=template&id=756e0bd0&", function () {
      api.rerender('756e0bd0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/common/CategoryBadge.vue"
export default component.exports