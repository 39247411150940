<template>
    <BaseModalDialog
        :title="'Edit All Cluster Metadata'"
        :loading="loading"
        width="60%"
        @load-modal-state-event="loadState"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-card elevation="0">
                    <v-row class="ma-2">
                        <v-col cols="4" class="py-2">
                            <v-text-field-validateable
                                disabled
                                rules="required|crypto"
                                label="Entity ID"
                                v-model="clusterDetail.cluster"
                                outlined
                                hide-details
                            />
                        </v-col>

                        <v-col cols="3" class="py-2">
                            <BlockchainCryptoPicker
                                disabled
                                :currencies="currencies"
                                v-model="crypto"
                                :value="crypto"
                                rules="required"
                                hide-details
                            />
                        </v-col>

                        <v-col cols="2" class="py-2">
                            <v-text-field
                                disabled
                                label="Number of Addresses"
                                :value="clusterDetail.totalAddresses | formatNumber"
                                outlined
                                hide-details
                            />
                        </v-col>

                        <v-col cols="3" class="py-2">
                            <v-text-field
                                disabled
                                label="Created At"
                                :value="clusterDetail.created_at | moment('YYYY-MM-DD HH:mm:ss Z')"
                                outlined
                                hide-details
                            />
                        </v-col>
                    </v-row>

                    <v-row class="ma-2">
                        <v-col>
                            <CategoryPicker
                                :categories="categories"
                                v-model="clusterDetail.categories"
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col>
                            <OwnerPickerWithDisplay v-model="clusterDetail.owners"/>
                        </v-col>

                    </v-row>
                </v-card>
            </ValidationObserver>
        </template>
        <template #footer="{close}">
            <v-btn color="error" text @click="close">Cancel</v-btn>
            <v-spacer />
            <v-btn
                color="success"
                text
                @click="save(close)"
            >{{ "Save Cluster Metadata" }}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import {
    BaseModalDialog,
    BlockchainCryptoPicker,
    CategoryPicker,
} from "@/components/common"
import {mapGetters} from "vuex";
import {deepClone} from "@/utils";
import OwnerPickerWithDisplay from "@/components/ClusterMetadata/OwnerPickerWithDisplay.vue";



export default {
        name: 'AllClusterMetadataDetailModal',
    components: {
        OwnerPickerWithDisplay,
        BaseModalDialog,
        BlockchainCryptoPicker,
        CategoryPicker,
    },
    props: {
        owners: {
            type: Array,
            default: () => []
        },
        crypto: {
            type: String,
            default: 'BTC',
        },
        categories: {
            type: Array,
            default: () => []
        },
        clusterMetadataDetail: {
            type: Object,
        },
        currencies: {
            type: Array,
            default: () => []
        },
        isOwnersLoading: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
        },
    },
    watch: {
        "clusterDetail.owner"(newValue) {
            this.clusterDetail.owner_id = newValue?.id
        },

    },
    computed: {
        ...mapGetters([
            "isNeoClusterDetailPaginationLoading",
        ]),

    },
    data() {
        return {
            clusterDetail: null
        }
    },
    methods: {
        loadState() {
            this.clusterDetail = deepClone(this.clusterMetadataDetail)
        },
        async save(close) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.$emit("save-event", {
                    currency: this.crypto,
                    clusterMetadataDetail: this.clusterDetail
                })
                close()
            }
        },

        ownerFilterChanged({ filter }) {
            this.$emit("owner-filter-changed-event", { filter })
        }
    }
}
</script>