<script setup>
import store from "@/store";
import {computed} from "vue";

const showEntityState = computed({
    get() {
        return store.getters.showEntityLabel
    },
    set() {
        store.commit('TOGGLE_SHOW_ENTITY_LABEL')
    }
})

const debugModel = computed({
    get() {
        return store.getters.debug
    },
    set(val) {
        store.commit('SETTINGS_SET_DEBUG', val)
    }
})

const isEnvironmentDevelOrStaging = computed(() => store.getters.isDevelEnvironment || store.getters.isStagingEnvironment)
const isUserSuperAdmin = computed(() => store.getters.user.roles.find(role => role.name.toLowerCase() === "superadmin"))
</script>

<template>
    <div class="text-center d-flex align-center">
        <v-menu :close-on-content-click="false" auto offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                >
                    mdi-cog
                </v-icon>
            </template>
            <v-list dense>
                <v-subheader class="ml-1" style="height: 24px">Options</v-subheader>
                <v-divider/>
                <v-list-item-title>
                    <div class="d-flex align-center px-2">
                        <div class="d-flex justify-space-between align-center ml-2" style="width: 160px">
                            Labels:
                            <v-switch
                                v-model="showEntityState"
                                class="ml-2 mt-2 mb-1"
                                color="#1565C0"
                                inset
                                style="height: 32px"
                            />
                        </div>
                    </div>
                </v-list-item-title>
                <v-list-item-title v-if="isUserSuperAdmin && isEnvironmentDevelOrStaging">
                    <div class="d-flex align-center px-2">
                        <div class="d-flex justify-space-between align-center ml-2" style="width: 160px">
                            Debug:
                            <v-switch
                                v-model="debugModel"
                                class="ml-2 mt-2 mb-1"
                                color="#1565C0"
                                inset
                                style="height: 32px"
                            />
                        </div>
                    </div>
                </v-list-item-title>
            </v-list>
        </v-menu>
    </div>
</template>

<style scoped>

</style>