<template>
    <v-layout style="height: auto;">
        <v-layout style="height: 200px" align-center justify-center  v-if="isLoading">
            <v-progress-circular :size="50" :width="4" :color="currency.unit" indeterminate />
        </v-layout>
        <v-layout style="position: absolute; width: 100%" column v-else>
            <v-app-bar style="position: sticky; z-index: 1; top: 0;"  dense color="white">
                <v-tabs
                    light
                    background-color="white"
                    :slider-color="currency.unit"
                    ref="drawerTabs">
                    <v-tab
                        v-for="({route, publicName}) in finalRoutes"
                        :key="route"
                        :to="route"
                    >{{ publicName }}
                    </v-tab>
                </v-tabs>
                <v-spacer/>
                <EntityHeaderLabel :label="currency.unit.toUpperCase() + ' ADDRESS:'" :entity="address.address"/>
                <router-link
                    class="text-decoration-none"
                    :to="{ name: 'AddressSummary', params: { address: $route.params.detailAddress}}"
                    target="_blank"
                >
                    <v-btn
                        color="rgba(0, 0, 0, 0.7)"
                        icon
                        class="mx-1"
                        small
                    >
                        <v-icon style="font-size: 20px">open_in_new</v-icon>
                    </v-btn>
                </router-link>
                <v-tooltip top v-if="!isOnGraphPage">
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-btn
                                disabled
                                color="rgba(0, 0, 0, 0.7)"
                                icon
                                class="mx-1"
                                small
                                @click="removeGraphNode"
                            >
                                <v-icon style="font-size: 20px">delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <span>This view is read-only. Save this<br> configuration if you want to edit it.</span>
                </v-tooltip>
                <v-btn
                    v-else-if="isOnGraphPage"
                    color="rgba(0, 0, 0, 0.7)"
                    icon
                    class="mx-1"
                    small
                    @click="removeGraphNode"
                >
                    <v-icon style="font-size: 20px">delete</v-icon>
                </v-btn>
                <v-btn
                    class="mx-1"
                    color="rgba(0, 0, 0, 0.7)"
                    icon
                    small
                    @click="graphCloseBottomDrawer">
                    <v-icon style="font-size: 20px">close</v-icon>
                </v-btn>
            </v-app-bar>
            <v-container fluid class="height100" >
                <DataProvider name="entityId" :data="address.address">
                    <router-view @component-loaded-event="onClick"/>
                </DataProvider>
            </v-container>
        </v-layout>
    </v-layout>
</template>

<script>
import DataProvider from "@/components/common/DataProvider.vue";
import EntityHeaderLabel from "@/views/Cluster/EntityHeaderLabel.vue";

export default {
    name: "AddressBottomDrawer",
    components: {EntityHeaderLabel, DataProvider},
    beforeRouteUpdate(to, from, next) {
        if (to.params.detailAddress !== from.params.detailAddress) {
            this.$store.dispatch("loadAdress", { id: to.params.detailAddress } );
        }
        next();
    },
    watch: {
        currency(newValue, oldValue) {
            this.$store.dispatch(
                "loadAdress",
                {id : this.$route.params.detailAddress}
            );
        },
        $route: function (to, from) {
            if (this.$route.params.detailAddress && to.params.detailAddress !== from.params.detailAddress) {
                this.$store.dispatch('loadAdress', {id: this.$route.params.detailAddress })
            }
        }, deep: true
    },
    created() {
        this.$store.dispatch('loadCategories')
        this.$store.dispatch("loadAdress", {id: this.$route.params.detailAddress});
    },
    mounted() {
        this.onClick();
    },
    beforeDestroy() {
        if (this.$route.params.address)
            this.$store.dispatch('loadAdress', {id: this.$route.params.address })
    },
    computed: {
        isOnCanvasPage() {
            return (
                this.$route.matched.findIndex(
                    route => route.name === "TransactionNetwork"
                ) !== -1
            );
        },
        isOnGraphPage() {
            return (
                this.$route.matched.findIndex(
                    route => route.name === "GraphSingleView"
                ) !== -1
            );
        },
        isOnFundsNetwork() {
            return (
                this.$route.matched.findIndex(
                    route => route.name === "FundsNetwork"
                ) !== -1
            );
        },
        url() {
            if (this.isOnGraphPage) {
                return (
                    "/" +
                    this.currency.unit +
                    "/graph/" +
                    this.$route.params.graphId +
                    "/config/" +
                    this.$route.params.graphConfigId +
                    "/node/" +
                    this.$route.params.nodeId +
                    "/address/" +
                    this.$route.params.detailAddress
                );
            } else if (this.isOnCanvasPage) {
                return (
                    "/" +
                    this.currency.unit +
                    "/transaction/" +
                    this.$route.params.transaction +
                    "/network/" +
                    this.$route.params.sides + 
                    "/detail/address/" +
                    this.$route.params.detailAddress
                );
            } else if (this.isOnFundsNetwork && this.$route.params.address) {
                return (
                    "/" +
                    this.currency.unit +
                    "/address/" +
                    this.$route.params.address +
                    "/funds/paths/source/" +
                    this.$route.params.source +
                    "/destination/" +
                    this.$route.params.destination +
                    "/maxHops/" +
                    this.$route.params.maxHops +
                    "/detail/address/" +
                    this.$route.params.detailAddress
                );
            } else { // Cluster funds view
                return (
                    "/" +
                    this.currency.unit +
                    "/cluster/" +
                    this.$route.params.clusterentity +
                    "/funds/paths/source/" +
                    this.$route.params.source +
                    "/destination/" +
                    this.$route.params.destination +
                    "/maxHops/" +
                    this.$route.params.maxHops +
                    "/detail/address/" +
                    this.$route.params.detailAddress
                );
            }
        },
        address() {
            return this.$store.getters.addressInfo;
        },
        error() {
            return this.$store.getters.addressError;
        },
        isLoading() {
            return this.$store.getters.addressLoading;
        },
        currency() {
            return this.$store.getters.currency;
        },
        finalRoutes() {
            return this.drawerTabs.map(x => {
                return {...x, route: this.url + x.route}
            })
        }
    },
    methods: {
        removeGraphNode() {
            this.$store.dispatch("removeGraphNode", {
                hash: this.$route.params.detailAddress
            });
        },
        graphCloseBottomDrawer() {
            this.$store.dispatch("graphCloseBottomDrawer", { sides: this.$route.params.sides });
        },
        onClick(){
            //fixes out-of-sync slider
            this.$refs.drawerTabs && this.$refs.drawerTabs.onResize();
        },
    },
    data() {
        return {
            categoriespagination: {
                page: 1,
                itemsPerPage: 100,
            },
            exportOptions: [
                { title: "EN HTML" },
                { title: "CZ HTML" },
                { title: "CSV" }
            ],
            drawerTabs: [
                {route: "/summary", publicName: "Summary"},
                {route: "/transactions", publicName: "Transactions"},
                {route: "/timeline", publicName: "Timelines"},
                {route: "/patterns", publicName: "Patterns"},
                {route: "/balance", publicName: "Balance"},
                {route: "/cluster", publicName: "Cluster"},
                {route: "/funds", publicName: "Funds"},
                {route: "/interactions", publicName: "Interactions"}
            ],
        };
    }
};
</script>

<style scoped>
.width100 {
    max-width: 700px;
}
</style>
