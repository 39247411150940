var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex justify-start flex graphContainer" },
      [
        _c("div", { staticStyle: { flex: "1" } }, [_vm._t("headerLeft")], 2),
        _vm._v(" "),
        _c(
          "v-btn",
          {
            staticClass: "ma-1 flex",
            attrs: { text: "", disabled: !_setup.isDiagramViable },
            on: {
              click: function ($event) {
                _setup.showDiagram = !_setup.showDiagram
              },
            },
          },
          [
            !_setup.showDiagram
              ? _c(
                  "span",
                  [
                    _c("v-icon", { attrs: { disabled: "", "x-small": "" } }, [
                      _vm._v("fa-solid fa-chevron-down"),
                    ]),
                    _vm._v(
                      "\n                    Show Diagram\n                "
                    ),
                    _c("v-icon", { attrs: { disabled: "", "x-small": "" } }, [
                      _vm._v("fa-solid fa-chevron-down"),
                    ]),
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    _c("v-icon", { attrs: { disabled: "", "x-small": "" } }, [
                      _vm._v("fa-solid fa-chevron-up"),
                    ]),
                    _vm._v(
                      "\n                    Hide Diagram\n                "
                    ),
                    _c("v-icon", { attrs: { disabled: "", "x-small": "" } }, [
                      _vm._v("fa-solid fa-chevron-up"),
                    ]),
                  ],
                  1
                ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-flex align-center mr-4 blockHeightContainer",
            staticStyle: { flex: "1" },
          },
          [_vm._t("headerRight")],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { width: "100%" } },
      [
        _c("v-expand-transition", [
          _setup.showDiagram
            ? _c(
                "div",
                {
                  staticClass: "d-flex flex-column",
                  staticStyle: { "max-height": "1400px" },
                },
                [
                  _c(_setup.Chart, {
                    attrs: { options: _setup.sankeyChartOptions },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }