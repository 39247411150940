<template>
        <div class="d-flex justify-center align-center">
            <div v-if="isPrimary">
                <v-btn
                    style="font-size: 12px;"
                    class="disable-events my-1"
                    min-width="110px"
                    x-small
                    outlined
                    elevation="0"
                    :color="currency.unit"
                >
                    Primary
                </v-btn>
            </div>
            <div v-else-if="$store.getters.hasPermission('write') && selectable">
                <v-btn
                    class="my-1 buttonShadow force"
                    style="font-size: 12px; color: #616161"
                    min-width="110px"
                    x-small
                    outlined
                    @click="$emit('set-primary-event', item.id )">
                    Set primary
                </v-btn>
            </div>
        </div>
</template>

<script>
export default {
    name: "PrimarySelector",
    props: {
        item: {
          type: Object,
          required: true,
          validator: function (value)
          {
                return value.id
          }
        },
        isPrimary: {
            type: Boolean,
            required: true,
        },
        currency: {
            type: Object,
            required: true,
        },
        selectable: {
            type: Boolean,
            required: false,
            default: true
        }
    }

}
</script>

<style scoped>
.disable-events {
    pointer-events: none
}
</style>
