<script setup>
import {useAttrs} from "vue";
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

defineProps({
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
})

const emit = defineEmits(['click'])

const attrs = useAttrs()
</script>

<template>
    <BaseDataModifyButton :disabled="disabled" class="ma-1" color="error" small v-bind="attrs" @click="emit('click')">
        <v-icon left>
            mdi-delete
        </v-icon>
        delete
    </BaseDataModifyButton>
</template>