<script setup>

import {ref, watch} from "vue";
import {parseCSVFileToArrayAsync} from "@/services/File";
import store from "@/store";

const props = defineProps({
    tooltip: {
        type: String,
        required: false,
        default: "Import .CSV file"
    },
    label: {
        type: String,
        required: false,
        default: "Drop or click to select .csv files for import"
    },
    icon: {
        type: String,
        required: false,
        default: "library_books"
    },
    separator: {
        type: String,
        required: false,
        default: ","
    },
})

const emit = defineEmits(["file-selected-event"])

const selectedFile = ref(null)
const assignFileFromDragAndDrop = (e) => {
    selectedFile.value = e.dataTransfer.files[0]
}
watch(selectedFile, () => {
    processFile()
})

const processFile = async () => {
    if (!selectedFile.value)
        return
    try {
        const parsedFile = await parseCSVFileToArrayAsync(selectedFile.value, {separator: props.separator})
        emit('file-selected-event', parsedFile.data)
    } catch (e) {
        await store.dispatch('error', e.message)
    }
}

</script>

<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <div v-cloak v-on="on" @drop.prevent="assignFileFromDragAndDrop" @dragover.prevent>
                <v-file-input
                    v-model="selectedFile"
                    :label="label"
                    :prepend-icon="icon"
                    accept=".csv"
                    dense
                    hide-details
                />
            </div>
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
</template>

<style scoped>

</style>