<script setup>
import FilterGeoVSelectWrapper from "@/views/Transactions/FilterGeo/Components/Wrappers/FilterGeoVSelectWrapper.vue";
import FilterGeoGenericPickerWrapper
    from "@/views/Transactions/FilterGeo/Components/Wrappers/FilterGeoGenericPickerWrapper.vue";
import {useVModelInput} from "@/components/common/Composables/useVModelnput";
import {ref, watch} from "vue";


const props = defineProps({
    pickerTextSingle: {
        type: String,
        required: true
    },
    pickerTextMultiple: {
        type: String,
        required: true
    },
    value: {
        type: Object,
    },
    getItems: {
        type: Function,
        required: true,
    },
    items: {
        type: Array,
    },
    loading: {
        type: Boolean,
        required: true,
    },
    displaySelectedComponent: {
        required: true,
    },
    displaySearchComponent: {
        required: true,
    },
    icon: {
        type: String,
        required: true
    },
    itemText: {
        type: String,
        required: true
    },
    convertItemTextToString: {
        type: Boolean,
        required: false
    }
})

const emit = defineEmits(['input'])
const item = useVModelInput(props, emit)
const isMenuOpen = ref(false)
watch(item, () => {
    if (item.value) {
        isMenuOpen.value = false
    }
})
</script>

<template>
    <v-menu
        v-model="isMenuOpen"
        :close-on-content-click="false"
        bottom
        content-class="white"
        eager
        offset-y
        z-index="999999">
        <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
                <FilterGeoVSelectWrapper
                    v-model="item"
                    :display-component="displaySelectedComponent"
                    :label="pickerTextSingle"
                    :prepend-icon="icon"/>
            </div>
        </template>
        <v-col class="body-2">
            {{ pickerTextMultiple }}
            <FilterGeoGenericPickerWrapper
                v-model="item"
                :convert-item-text-to-string="convertItemTextToString"
                :display-component="displaySearchComponent"
                :get-items="getItems"
                :item-text="itemText"
                :items="items"
                :loading="loading"
                :placeholder="`Search ${pickerTextSingle}`"
            />
        </v-col>
    </v-menu>
</template>
