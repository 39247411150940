<template>
  <div class="cont border expand">
    <div class="overflow-y-auto width100 height100 white">
      <v-toolbar dense text>
        <v-btn icon @click="$store.dispatch('graphCloseBottomDrawer')"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <transition name="slide-y-reverse-transition">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
  import TransactionSingle from '@/components/transaction/TransactionSingle'
  export default {
    name: 'BottomDrawer',
    components: {
      TransactionSingle
    },
    computed: {
      nodeSelected () {
        return this.$store.getters.graphBottomDrawer
      }
    }
  }
</script>

<style scoped>
  .cont {
    flex: 0 1 0;
    transition: .3s cubic-bezier(.25,.8,.5,1);
    background: white;
    height: 0;
    z-index: 1;
  }
  .expand {
    height: 350px;
    flex: 0 1 350px;
  }
</style>
