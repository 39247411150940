import apiClient from "@/services/apiClient";
import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async loadGeoIpCountry({commit}, {pagination, cancelToken}) {
        try {
            return await apiClient.GET(`geoip/countries`,
                {
                    params: pagination,
                    cancelToken: cancelToken
                })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
        return false
    },
    async loadGeoIpAS({commit}, {pagination, cancelToken}) {
        try {
            return await apiClient.GET(`geoip/asns`,
                {
                    params: pagination,
                    cancelToken: cancelToken
                })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
        return false
    },
    async loadGeoIpCity({commit}, {pagination, cancelToken}) {
        try {
            return await apiClient.GET(`geoip/cities`,
                {
                    params: pagination,
                    cancelToken: cancelToken
                })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
        return false
    },
    async loadGeoPeers({commit}, {pagination, filter, cancelToken, currencyUnit}) {
        try {
            return await apiClient.GET(`${currencyUnit}/geoip/peers`,
                {
                    params: {...pagination, ...filter},
                    cancelToken: cancelToken
                })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
        return false
    },
    async loadGeoTransactions({commit}, {pagination, filter, cancelToken, currencyUnit}) {
        if (typeof filter === 'function') {
            filter = filter()
        }
        try {
            return await apiClient.GET(`${currencyUnit}/geoip/transactions`,
                {
                    params: {...pagination, ...filter},
                    cancelToken: cancelToken
                })
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                throw error.userFriendlyMessage
            }
        }
        return false
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}

