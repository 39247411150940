// @ts-ignore
import coininfo from "coininfo";
import {
    bipSpec,
    ExtendedKeyPrefix,
    IAlternativeExtendedKeyPrefixes,
    ICurrencyObject,
    UInt
} from "@/components/MnemonicConvertor/mnemonicConvertorTypes";
import {ICurrencyData} from "@/components/common/types/currency";

class CurrencyObject implements ICurrencyObject {
    constructor (coin: number, currencyData: ICurrencyData, alternativeExtendedKeyPrefixes?: IAlternativeExtendedKeyPrefixes, network?, disabledBips?) {
        this.coin = coin
        this.alternativeExtendedKeyPrefixes = alternativeExtendedKeyPrefixes
        this.network = network ?? createCurrencyNetwork('BTC', UInt.UInt8)
        this.disabledBips = disabledBips

        Object.assign(this, currencyData)
    }

    coin: number;
    alternativeExtendedKeyPrefixes?: IAlternativeExtendedKeyPrefixes;
    typeFamily: any;
    network?: any;
    disabledBips?: any;
    unit: string;
    image: string;
    name: string;
    regexp: string;
}

const createCurrencyNetwork = (currencyUnit: string, UIntSize: UInt, bip32?, scriptHash?: number, bech32?: string) => {
    let network = coininfo(currencyUnit).toBitcoinJS()

    if (UIntSize === UInt.UInt8) {
        network = { ...network, bytes: 21, versionBytes: 1, versionBase: 10 }
    } else {
        return { ...network, bytes: 22, versionBytes: 2, versionBase: 16 }
    }

    if (bip32) {
        network.bip32 = bip32
    }

    if (scriptHash) {
        network.scriptHash = scriptHash
    }

    if (bech32) {
        network.bech32 = bech32
    }

    return network
}

export const CurrenciesInfo = (currencies) => {
    const getCurrencyData = (currencyUnit) => {
        return currencies.find(c => c.unit === currencyUnit.toUpperCase())
    }

    const BtcAlternativeExtendedKeyPrefixes : IAlternativeExtendedKeyPrefixes = {
        "141": {
            'P2WPKH': { pub: ExtendedKeyPrefix.zpub, priv: ExtendedKeyPrefix.zpriv },
            'P2WPKH nested in P2SH': { pub: ExtendedKeyPrefix.ypub, priv: ExtendedKeyPrefix.ypriv },
            'P2WSH (1-of-1 multisig)': { pub: ExtendedKeyPrefix.Zpub, priv: ExtendedKeyPrefix.Zpriv },
            'P2WSH nested in P2SH (1-of-1 multisig)': { pub: ExtendedKeyPrefix.Ypub, priv: ExtendedKeyPrefix.Ypriv },
        }
    }

    const BtcInfo = new CurrencyObject(0, getCurrencyData('BTC'), BtcAlternativeExtendedKeyPrefixes)

    const EthInfo = new CurrencyObject(60, getCurrencyData('ETH'))

    const LtcAlternativeExtendedKeyPrefixes : IAlternativeExtendedKeyPrefixes = {
        "32": { pub: ExtendedKeyPrefix.Ltub, priv: ExtendedKeyPrefix.Ltpv },
        "44": { pub: ExtendedKeyPrefix.Ltub, priv: ExtendedKeyPrefix.Ltpv },
        "49": { pub: ExtendedKeyPrefix.Mtub, priv: ExtendedKeyPrefix.Mtpv },
        "84": { pub: ExtendedKeyPrefix.zpub, priv: ExtendedKeyPrefix.zpriv },
        "141": {
            'P2WPKH': { pub: ExtendedKeyPrefix.zpub, priv: ExtendedKeyPrefix.zpriv },
            'P2WPKH nested in P2SH': { pub: ExtendedKeyPrefix.Mtub, priv: ExtendedKeyPrefix.Mtpv },
            'P2WSH (1-of-1 multisig)': { pub: ExtendedKeyPrefix.Ltub, priv: ExtendedKeyPrefix.Ltpv },
            'P2WSH nested in P2SH (1-of-1 multisig)': { pub: ExtendedKeyPrefix.Ltub, priv: ExtendedKeyPrefix.Ltpv }
        }
    }

    const ltcDisabledBips = [bipSpec.bip49, bipSpec.bip84, bipSpec.bip141]

    const LtcInfo = new CurrencyObject(
        2,
        getCurrencyData('LTC'),
        LtcAlternativeExtendedKeyPrefixes,
        createCurrencyNetwork('LTC', UInt.UInt8),
        ltcDisabledBips
    )

    const dashDisabledBips = [bipSpec.bip49, bipSpec.bip84, bipSpec.bip141]

    const DashInfo = new CurrencyObject(
        5,
        getCurrencyData('DASH'),
        null,
        createCurrencyNetwork('DASH', UInt.UInt8),
        dashDisabledBips
    )

    const zcashDisabledBips = [bipSpec.bip49, bipSpec.bip84, bipSpec.bip141]

    const ZcashInfo = new CurrencyObject(
        133,
        getCurrencyData('ZEC'),
        null,
        createCurrencyNetwork('ZEC', UInt.UInt16),
        zcashDisabledBips
    )

    const DgbAlternativeExtendedKeyPrefixes : IAlternativeExtendedKeyPrefixes = {
        "141": {
            'P2WPKH': { pub: ExtendedKeyPrefix.zpub, priv: ExtendedKeyPrefix.zpriv },
            'P2WPKH nested in P2SH': { pub: ExtendedKeyPrefix.ypub, priv: ExtendedKeyPrefix.ypriv },
            'P2WSH (1-of-1 multisig)': { pub: ExtendedKeyPrefix.xpub, priv: ExtendedKeyPrefix.xpriv },
            'P2WSH nested in P2SH (1-of-1 multisig)': { pub: ExtendedKeyPrefix.xpub, priv: ExtendedKeyPrefix.xpriv }
        }
    }

    const DgbInfo = new CurrencyObject(
        20,
        getCurrencyData('DGB'),
        DgbAlternativeExtendedKeyPrefixes,
        createCurrencyNetwork('DGB', UInt.UInt8, { public: 76067358, private: 76066276 }, 63)
    )

    const QtumInfo = new CurrencyObject(
        2301,
        getCurrencyData('QTUM'),
        null,
        createCurrencyNetwork('QTUM', UInt.UInt8)
    )

    const dogeDisabledBips = [bipSpec.bip84, bipSpec.bip141]

    const DogeAlternativeExtendedKeyPrefixes : IAlternativeExtendedKeyPrefixes = {
        "32": { pub: ExtendedKeyPrefix.dgub, priv: ExtendedKeyPrefix.dgpv },
        "44": { pub: ExtendedKeyPrefix.dgub, priv: ExtendedKeyPrefix.dgpv },
        "49": { pub: ExtendedKeyPrefix.dgub, priv: ExtendedKeyPrefix.dgpv },
    }

    const DogeInfo = new CurrencyObject(
        3,
        getCurrencyData('DOGE'),
        DogeAlternativeExtendedKeyPrefixes,
        createCurrencyNetwork('DOGE', UInt.UInt8),
        dogeDisabledBips
    )

    const EtcInfo = new CurrencyObject(61, getCurrencyData('ETC'))

    const BchInfo = new CurrencyObject(145, getCurrencyData('BCH'), null, createCurrencyNetwork('BCH', UInt.UInt8), [bipSpec.bip84])

    const BtgInfo = new CurrencyObject(156, getCurrencyData('BTG'), null, createCurrencyNetwork('BTG', UInt.UInt8))

    const currencyItems : CurrencyObject[] = [BtcInfo, LtcInfo, DashInfo, ZcashInfo, BchInfo, DgbInfo, QtumInfo, DogeInfo, EthInfo, EtcInfo, BtgInfo]

    const enabledCurrencyItems : CurrencyObject[] = currencyItems.filter(item => {
        return currencies.some(currency => currency.unit === item.unit)
    })

    return enabledCurrencyItems
}
