var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nobr",
    [
      _vm.data.length === 1 && _vm.data[0].coinbase
        ? _c("v-data-table", {
            attrs: {
              dense: "",
              "hide-default-footer": "",
              headers: [{ text: "", align: "center", sortable: false }],
              items: _vm.data,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function ({ item, expand, isExpanded }) {
                    return [
                      _c("tr", [
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass: "font-weight-medium",
                              staticStyle: { color: "rgba(0,0,0,0.6)" },
                            },
                            [
                              _vm._v(
                                "\n                        COINBASE INPUT\n                    "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              1732254074
            ),
          })
        : _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.data,
              "items-per-page": 50,
              dense: "",
              "item-key": "addr",
              "footer-props": {
                "items-per-page-options": [50],
                "disable-items-per-page": true,
                "show-current-page": true,
                showFirstLastPage: true,
              },
              "hide-default-footer": _vm.data.length < 51,
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item, expand, isExpanded }) {
                  return [
                    _c("transactionInputRow", {
                      attrs: {
                        address: _vm.address,
                        item: item,
                        expand: expand,
                        "is-expanded": isExpanded,
                        fiats: _vm.fiats,
                      },
                    }),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ item: itemOuter }) {
                  return _vm._l(itemOuter.inouts, function (item, index) {
                    return _c("transactionInputRow", {
                      key: item.n,
                      attrs: {
                        address: _vm.address,
                        item: item,
                        fiats: _vm.fiats,
                        index: index,
                        itemOuter: itemOuter,
                      },
                    })
                  })
                },
              },
              {
                key: "footer.prepend",
                fn: function () {
                  return [_c("v-spacer")]
                },
                proxy: true,
              },
              {
                key: "footer.page-text",
                fn: function ({ pageStart, pageStop, itemsLength }) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("formatNumber")(pageStart)) +
                          " - " +
                          _vm._s(_vm._f("formatNumber")(pageStop)) +
                          " of " +
                          _vm._s(_vm._f("formatNumber")(itemsLength)) +
                          "\n            "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }