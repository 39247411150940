<script setup>
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

defineProps({
    text: {
        type: String,
        required: false,
        default: "Delete"
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
})

</script>

<template>
    <BaseDataModifyButton :disabled="disabled" color="error" v-bind="$attrs" v-on="$listeners" small>
        <v-icon small class="mr-1">mdi-delete</v-icon>
        {{ text }}
    </BaseDataModifyButton>
</template>
