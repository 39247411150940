<script setup>
import store from '@/store'
import {computed, inject, ref} from "vue";
import DataProvider from "@/components/common/DataProvider.vue";
import ClusterSummaryCardBasic from "@/views/Cluster/Summary/ClusterSummaryCardBasic.vue";
import ClusterSummaryCardDetails from "@/views/Cluster/Summary/ClusterSummaryCardDetails.vue";
import ClusterSummaryCardOwners from "@/views/Cluster/Summary/ClusterSummaryCardOwners.vue";
import ClusterSummaryCardAddresses from "@/views/Cluster/Summary/ClusterSummaryCardAddresses.vue";
import ClusterControls from "@/views/Cluster/ClusterControls.vue";

const currency = computed(() => store.getters.currency)

const clusterInfo = inject('clusterInfo')
const clusterInfoState = inject('clusterInfoState')
const clusterName = inject('entityId')
const getClusterInfo = inject('getClusterInfo')

//Hack to force creation of new table component (as refresh with correct pagination is not properly exposed)
const key = ref(false)
const forceCreateNewTable = () => {
    key.value = !key.value
}
const refresh = () => {
    getClusterInfo.value({entity: clusterName.value})
    forceCreateNewTable()
    emit("cluster-update-event")
}

const emit = defineEmits(["cluster-update-event"])
</script>

<template>
    <div>
        <div class="d-flex justify-center clusterViewCards" style="background-color: #fff; height: 100%">
            <div class="px-4" style="width: 100%">
                <v-row class="pt-4" dense>
                    <v-col align="right" cols="12">
                        <DataProvider
                            :data="{ cluster: clusterInfo?.cluster, currencyUnit: currency.unit, clusterName: clusterName }"
                            :dataState="clusterInfoState" ignore-loading>
                            <ClusterControls @action-finished-event="refresh"/>
                        </DataProvider>
                    </v-col>

                    <v-col cols="12" md="4" sm="6">
                        <DataProvider :data="clusterInfo" :dataState="clusterInfoState" ignore-loading>
                            <ClusterSummaryCardBasic/>
                        </DataProvider>
                    </v-col>
                    <v-col cols="12" md="4" order-sm="2" sm="6">
                        <ClusterSummaryCardDetails/>
                    </v-col>
                    <v-col cols="12" md="4" order-sm="4" sm="6">
                        <DataProvider :data="clusterInfo" :dataState="clusterInfoState" ignore-loading>
                            <ClusterSummaryCardOwners/>
                        </DataProvider>
                    </v-col>
                    <v-col cols="12" order-sm="5">
                        <ClusterSummaryCardAddresses :key="key"/>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>