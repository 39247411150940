<script setup>
import {useAttrs} from "vue";
import BaseDataModifyButton from "@/components/common/Buttons/BaseDataModifyButton.vue";

const emit = defineEmits(['click'])
const attrs = useAttrs()
</script>

<template>
    <BaseDataModifyButton class="ma-1" color="success" small v-bind="attrs" @click="emit('click')">
        <v-icon left>
            add_box
        </v-icon>
        Add
    </BaseDataModifyButton>
</template>