var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-y height100" },
    [
      _c(
        "v-container",
        { staticClass: "height100 px-4", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "" } },
            [
              _c("h1", [_vm._v("Users")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("UserDetailModal", {
                attrs: { userRoles: _vm.roles.data || [] },
                on: { "save-event": _vm.createUser },
                scopedSlots: _vm._u([
                  {
                    key: "showbutton",
                    fn: function ({ on: on }) {
                      return [
                        _c(
                          "CreateButton",
                          _vm._g({ attrs: { text: "Create user" } }, on)
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-0 border mt-2",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.users.data,
                      options: _vm.filter,
                      "server-items-length": _vm.users.total
                        ? _vm.users.total
                        : 0,
                      loading: _vm.isUsersLoading,
                      dense: "",
                      "footer-props": {
                        "items-per-page-options": [15, 30, 50, 100],
                        "show-current-page": true,
                        showFirstLastPage: true,
                      },
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.filter = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.headers, function (h) {
                          return {
                            key: `header.${h.value}`,
                            fn: function ({ header }) {
                              return [
                                h.value === "name"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      attrs: {
                                        "append-icon": "search",
                                        label: header.text,
                                        "single-line": "",
                                        "hide-details": "",
                                        clearable: "",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.debouncedFilter.filterName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterName",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "email"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      attrs: {
                                        "append-icon": "search",
                                        label: header.text,
                                        "single-line": "",
                                        "hide-details": "",
                                        clearable: "",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.debouncedFilter.filterEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterEmail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterEmail",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "roles"
                                  ? _c("v-text-field", {
                                      key: h.value,
                                      staticClass: "text-field-label-style",
                                      attrs: {
                                        "append-icon": "search",
                                        label: header.text,
                                        "single-line": "",
                                        "hide-details": "",
                                        clearable: "",
                                      },
                                      on: { input: _vm.debounceInput },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.debouncedFilter.filterRole,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.debouncedFilter,
                                            "filterRole",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "debouncedFilter.filterRole",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "is_active"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "created_at"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "updated_at"
                                  ? _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticStyle: { "padding-top": "20px" },
                                      },
                                      [_c("h3", [_vm._v(_vm._s(header.text))])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                h.value === "action"
                                  ? _c("div", { key: h.value }, [
                                      _c("h3", [_vm._v(_vm._s(header.text))]),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          }
                        }),
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c("tr", [
                                _c("td", { staticClass: "text-left" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-left text-overflow" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.email) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-xs-left text-overflow" },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { row: "" } },
                                      [
                                        _vm._l(
                                          item.roles.slice(0, 2),
                                          function (role) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: role.id,
                                                attrs: {
                                                  small: "",
                                                  outlined: "",
                                                  label: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(role.name) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "BaseButtonShowMoreItems",
                                          {
                                            attrs: {
                                              items: item.roles,
                                              shownItemsCount: 2,
                                            },
                                          },
                                          _vm._l(item.roles, function (role) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: role.id,
                                                staticClass: "ma-1",
                                                attrs: {
                                                  small: "",
                                                  outlined: "",
                                                  label: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(role.name) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            )
                                          }),
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "vertical-align": "middle",
                                    },
                                  },
                                  [
                                    item.is_active
                                      ? _c(
                                          "v-chip",
                                          {
                                            attrs: {
                                              label: "",
                                              color: "success",
                                              outlined: "",
                                              small: "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Active\n                                "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "v-chip",
                                          {
                                            attrs: {
                                              label: "",
                                              outlined: "",
                                              small: "",
                                              color: "error",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Inactive\n                                "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-xs-right text-overflow",
                                    staticStyle: {
                                      "text-align": "center",
                                      "vertical-align": "middle",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("formatDateTime")(
                                            item.created_at
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-xs-right text-overflow",
                                    staticStyle: {
                                      "text-align": "center",
                                      "vertical-align": "middle",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("formatDateTime")(
                                            item.updated_at
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "vertical-align": "middle",
                                      "min-width": "92px",
                                    },
                                  },
                                  [
                                    _c("UserDetailModal", {
                                      attrs: {
                                        userRoles: _vm.roles.data || [],
                                        userDetail: item,
                                      },
                                      on: { "save-event": _vm.editUser },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "showbutton",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "TableEditButton",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        editButtonTooltip:
                                                          "Edit user",
                                                      },
                                                    },
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("TableDeleteModalDialog", {
                                      attrs: {
                                        description: `Do you really want to delete user ${item.name}`,
                                        itemToDelete: item,
                                        tooltip: "Delete User",
                                      },
                                      on: { "delete-event": _vm.deleteUser },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "footer.page-text",
                          fn: function ({ pageStart, pageStop, itemsLength }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm._f("formatNumber")(pageStart)) +
                                    " - " +
                                    _vm._s(_vm._f("formatNumber")(pageStop)) +
                                    " of " +
                                    _vm._s(
                                      _vm._f("formatNumber")(itemsLength)
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }