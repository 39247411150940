import currencyConfig from "@/configurations/currency-config"
import apiClient from "@/services/apiClient"

const state = {
    currencies: [],
    enabledCurrencies: [],
    allCurrenciesExceptUnknown: [],
    selectedCurrency: undefined,
    currencyNeighbours: [],
    currencyNeighboursLoading: false,
    showEntityLabel: true
};

const getters = {
    currency: (state) => state.selectedCurrency,
    currencyOrDefault: (state) => state.selectedCurrency ?? state.currencies[0],
    currencyByUnit: (state) => (unit) => state.currencies.find(curr => curr.unit == unit),
    isCurrencyFamilyEth: (state) => state.selectedCurrency.typeFamily === "ETH",
    currencies: (state) => state.currencies,
    allCurrenciesExceptUnknown: (state) => state.allCurrenciesExceptUnknown,
    enabledCurrencies: (state) => state.enabledCurrencies,
    currencyByCurrencyUnit: (state) => ({ currencyUnit }) => {
        return state.currencies.find(currency => currency.unit === currencyUnit)
    },
    currencyNeighbours: (state) => state.currencyNeighbours,
    currencyNeighboursLoading: (state) => state.currencyNeighboursLoading,
    showEntityLabel: (state) => state.showEntityLabel
}

const mutations = {
    SET_CURRENCIES(state, data)
    {
        currencyConfig.addValidators(data)
        state.currencies = currencyConfig.enrichCurrencies(data)
        state.enabledCurrencies = currencyConfig.enrichCurrencies(data)
        state.allCurrenciesExceptUnknown = currencyConfig.enrichCurrencies(data)
    },
    SELECT_CURRENCY(state, { currencyUnit }) {
        state.selectedCurrency = state.currencies.find(i => i.unit === currencyUnit)
    },
    CLEAR_CURRENCY(state) {
        state.selectedCurrency = undefined
    },
    NEIGHBOURS_LOADED(state, data) {
        state.currencyNeighbours = data
    },
    NEIGHBOURS_START_LOADING(state) {
        state.currencyNeighboursLoading = true
    },
    NEIGHBOURS_FINISH_LOADING(state) {
        state.currencyNeighboursLoading = false
    },
    TOGGLE_SHOW_ENTITY_LABEL(state) {
        state.showEntityLabel = !state.showEntityLabel
    }
}

const actions = {
    selectCurrency({ commit }, { currencyUnit }) {
        commit('SELECT_CURRENCY', { currencyUnit })
    },
    clearCurrency({ commit }) {
        commit('CLEAR_CURRENCY')
    },
    async loadCurrencyNeighbours({ commit, dispatch }, { currency, pagination }) {
        commit('NEIGHBOURS_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/neighbors`, {
                params: pagination
            })
            commit('NEIGHBOURS_LOADED', response)
        } catch(error) {
            dispatch("error", error.userFriendlyMessage)
        }
        commit('NEIGHBOURS_FINISH_LOADING')
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
