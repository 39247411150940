var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.GenericPicker, {
        attrs: {
          "get-items": _vm.getItems,
          "item-value": _vm.itemValue,
          items: _vm.items,
          loading: _vm.loading,
          multiple: _vm.multiple,
          value: _vm.value,
          outlined: _vm.outlined,
          "hide-details": _vm.hideDetails,
          noPrependIcon: _vm.noPrependIcon,
          "menu-top": _vm.menuTop,
          label: _vm.label,
          "clear-only-search-string": _vm.clearOnlySearchString,
        },
        on: {
          input: function ($event) {
            return _setup.emit("input", $event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item",
              fn: function ({ item }) {
                return [_vm._t("pickerItem", null, { item: item })]
              },
            },
            {
              key: "hint",
              fn: function () {
                return [_vm._t("hint")]
              },
              proxy: true,
            },
            {
              key: "selection",
              fn: function ({ item }) {
                return [_vm._t("selectionItem", null, { item: item })]
              },
            },
            {
              key: "append",
              fn: function () {
                return [_vm._t("append")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _vm.chips
        ? _c(
            "div",
            {
              staticStyle: { "min-height": "32px" },
              style: `${_vm.hideDetails ? "margin-top: 8px" : ""}`,
            },
            _vm._l(_vm.value, function (item, index) {
              return _c(
                "v-chip",
                {
                  key: index,
                  staticClass: "ma-1",
                  attrs: {
                    close: "",
                    "close-icon": "mdi-delete",
                    label: "",
                    small: "",
                  },
                  on: {
                    "click:close": function ($event) {
                      return _setup.removeItem(item)
                    },
                  },
                },
                [_vm._t("chipItem", null, { item: item })],
                2
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.table
        ? _c("div", { staticClass: "my-1" }, [
            _vm._v("\n        Selected items:\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.table
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _setup.tableHeaders,
                  items: _vm.value,
                  "items-per-page": -1,
                  dense: "",
                  height: "300px",
                  "hide-default-footer": "",
                  "no-data-text": "No clusters selected!",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function ({ item, index }) {
                        return [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ hover }) {
                                    return [
                                      _c(
                                        "tr",
                                        {
                                          staticClass: "noselect",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: () =>
                                              _setup.removeItem(
                                                _vm.value[index]
                                              ),
                                          },
                                        },
                                        [
                                          _vm._t("tableItem", null, {
                                            item: item,
                                          }),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "32px",
                                                  "min-width": "32px",
                                                },
                                              },
                                              [
                                                hover
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "error",
                                                          small: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    mdi-delete\n                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3452462857
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }