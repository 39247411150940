import { render, staticRenderFns } from "./AddressDetailable.vue?vue&type=template&id=734022dc&scoped=true&"
import script from "./AddressDetailable.vue?vue&type=script&lang=js&"
export * from "./AddressDetailable.vue?vue&type=script&lang=js&"
import style0 from "./AddressDetailable.vue?vue&type=style&index=0&id=734022dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734022dc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VChip,VIcon,VMenu})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('734022dc')) {
      api.createRecord('734022dc', component.options)
    } else {
      api.reload('734022dc', component.options)
    }
    module.hot.accept("./AddressDetailable.vue?vue&type=template&id=734022dc&scoped=true&", function () {
      api.rerender('734022dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/common/AddressDetailable.vue"
export default component.exports