<script setup>

import {computed, ref} from "vue";
import store from "@/store";
import {useRouter} from "vue-router/composables";

const enabledCurrencies = computed(() => store.getters.enabledCurrencies)

const router = useRouter()

const logout = () => {
    store.dispatch('userLogout')
    router.push({name: 'LoginView'})
}
const menuItems = [
    {text: 'Dashboard', route: '/', icon: 'mdi-view-dashboard-outline'},
    {text: 'Search', routeName: 'AdvancedSearch', icon: 'mdi-magnify'},
    {
        text: 'Blockchain',
        icon: 'mdi-currency-sign',
        permissionString: 'blockChain',
        currencies: enabledCurrencies.value
    },
    {divider: true},
    {text: 'Graphs', routeName: 'GraphsView', icon: 'timeline'},
    {text: 'Cases', routeName: 'CasesView', icon: 'assignment'},
    {text: 'Files', routeName: 'FilesView', icon: 'file_copy'},
    {text: 'Alarms', routeName: 'Alarms', icon: 'alarm', permissionString: 'alarms'},
    {
        text: 'Mnemonic',
        routeName: 'MnemonicConvertor',
        icon: 'mdi-form-textbox-password'
    },
    {divider: true},
    {
        text: 'Addresses',
        routeName: 'AddressMetadataView',
        icon: 'mdi-tag-multiple'
    },
    {
        text: 'Custom Clusters',
        routeName: 'ClusterMetadataView',
        icon: 'mdi-hub-outline'
    },
    {text: 'All Clusters', routeName: 'AllClusterMetadataView', icon: 'mdi-hub'},
    {
        text: 'Identities',
        routeName: 'IdentitiesView',
        icon: 'mdi-card-account-details'
    },
    {
        text: 'Owners',
        routeName: 'OwnersView',
        imgPath: '/img/ico/owner-icon.svg',
        imgAlt: 'Owner'
    },
    {divider: true},
    {text: 'Profile', routeName: 'UserProfile', icon: 'person'},
    {text: 'Users', routeName: 'Users', icon: 'people', permissionString: 'users'},
    {text: 'Categories', routeName: 'CategoriesView', icon: 'fa-list-alt'},
    {text: 'Roles', routeName: 'Roles', icon: 'supervised_user_circle', permissionString: 'roles'},
    {text: 'Logs', routeName: 'Logs', icon: 'date_range', permissionString: 'logs'},
    {spacer: true},
    {text: 'Status', routeName: 'StatusView', icon: 'mdi-heart-pulse', permissionString: 'status', isDebugFeature: true},
    {
        text: 'Documentation',
        href: 'http://docs.coinomon.netsearch.cz/',
        icon: 'fa-solid fa-book',
        appendIcon: 'fa-solid fa-arrow-up-right-from-square'
    },
    {text: 'Logout', logout: true, icon: 'exit_to_app'}
]

const compact = ref(JSON.parse(localStorage.getItem('menuCompact')))

const menuSizeChange = () => {
    compact.value = !compact.value
    localStorage.setItem('menuCompact', compact.value)
}

const currentAppVersion = computed(() => store.getters.settings.settings.frontend)

const debugMode = computed(() => store.getters.debug)

const getRenderCondition = (permissionString, isDebugFeature) => {
    if (store.getters.hasPermission(permissionString)) {
        if (isDebugFeature && !debugMode.value) {
            return false
        } else {
            return true
        }
    }
    return false
}
</script>

<template>
    <div
        class="d-flex sideBarMenu"
    >
        <v-navigation-drawer
            :mini-variant.camel="compact"
            permanent
        >
            <v-list class="d-flex flex-column menu-list" dense nav style="height: 100%;">
                <v-tooltip right>
                    <template #activator="{ on, attrs }">
                        <div class="d-flex justify-end">
                            <v-btn
                                max-width="40"
                                min-width="40"
                                plain
                                v-bind="attrs"
                                v-on="on"
                                @click.stop="menuSizeChange"
                            >
                                <v-icon style="font-size: 20px">
                                    {{ compact ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left' }}
                                </v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <span>{{ compact ? "Expand" : "Compact" }}</span>
                </v-tooltip>

                <template v-for="item in menuItems">

                    <!--                Blockchains-->
                    <v-list-group
                        v-if="item.currencies && $store.getters.hasPermission('blockChain')"
                        class="currenciesGroup"
                    >
                        <template #activator>
                            <v-tooltip :disabled="!compact" right>
                                <template #activator="{ on, attrs }">
                                    <v-list-item-icon class="mt-0 mb-0" style="flex: 0; height: 32px"
                                                      v-bind="attrs"
                                                      v-on="on"
                                    >
                                        <v-icon style="font-size: 20px">mdi-currency-sign</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title
                                        v-bind="attrs"
                                        v-on="on"
                                    >Blockchain
                                    </v-list-item-title>
                                </template>
                                <span>Blockchain</span>
                            </v-tooltip>


                        </template>

                        <v-divider/>
                        <v-tooltip v-for="(curr, i) in item.currencies"
                                   :key="i+curr.unit"
                                   :disabled="!compact"
                                   right
                        >
                            <template #activator="{ on, attrs }">

                                <v-list-item
                                    :to="'/'+curr.unit"

                                    class="my-1"
                                    color="tertiary"
                                    style="flex: 0; min-height: 32px; height: 32px"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-list-item-icon class="d-flex justify-start" style="margin-top: 6px">
                                        <img :alt="`${curr.name} icon`" :src="curr.image"
                                             style="width: 20px; height: 20px"/>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ curr.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <span>{{ curr.name }}</span>
                        </v-tooltip>
                    </v-list-group>

                    <!--                misc-->
                    <v-divider v-else-if="item.divider" class="mb-1"/>
                    <v-spacer v-else-if="item.spacer" class="flex-column"/>

                    <!--                Rest of Items-->
                    <v-tooltip v-else-if="getRenderCondition(item.permissionString, item.isDebugFeature)"
                               :key="item.text"
                               :disabled="!compact"
                               right
                    >
                        <template #activator="{ on, attrs }">
                            <v-list-item
                                :href="item.href"
                                :target="item.href ? '_blank' : '_self'"
                                :to="item.routeName ? { name: item.routeName } : item.route"
                                color="tertiary"
                                style="flex: 0; min-height: 32px"
                                v-bind="attrs"
                                @click="item.logout ? logout() : undefined"
                                v-on="on"
                            >
                                <v-list-item-icon v-if="item.imgPath" class="d-flex justify-start mt-1"
                                                  style="border-radius: 0;">
                                    <img :alt="item.imgAlt" :src="item.imgPath" class="imgIcon" style="width: 20px"/>
                                </v-list-item-icon>
                                <v-list-item-icon v-else class="mt-1">
                                    <v-icon style="font-size: 20px">{{ item.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-icon v-if="item.appendIcon" class="mt-1 justify-end"
                                                  style="margin-right: 4px !important;">
                                    <v-icon style="font-size: 14px; opacity: 0.6">
                                        {{ item.appendIcon }}
                                    </v-icon>
                                </v-list-item-icon>

                            </v-list-item>
                        </template>
                        <span>{{ item.text }}</span>
                    </v-tooltip>

                </template>
                <div :class="{ 'fade-in-transition': !compact}" class="d-flex justify-center" style="height: 12px">
                    <div v-if="!compact" class="monospace d-flex text-no-wrap" style="opacity: 0.8; font-size: 12px">
                        version {{ currentAppVersion }}
                    </div>
                </div>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<style scoped>

.sideBarMenu {
    height: calc(100vh - 68px);
    background-color: #fafafa;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.imgIcon {
    filter: opacity(0.6);
}

.v-list-item--active .imgIcon {
    filter: invert(24%) sepia(99%) saturate(1327%) hue-rotate(193deg) brightness(102%) contrast(90%);
}

.menu-list .v-list-item__icon {
    margin-right: 16px !important;
}

.fade-in-transition {
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

</style>

<style>

.currenciesGroup .v-list-group__header {
    min-height: 32px !important;
}

</style>