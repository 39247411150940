var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-y height100" },
    [
      _c(
        "v-container",
        { staticClass: "height100 px-4", attrs: { fluid: "" } },
        [
          _c("v-layout", { attrs: { "align-center": "" } }, [
            _c("h1", [_vm._v("Logs")]),
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "elevation-0 border mt-2",
            attrs: {
              "item-key": "id+created_at",
              headers: _vm.headers,
              items: _vm.logs.data,
              options: _vm.filter,
              "server-items-length": _vm.logs.total ? _vm.logs.total : 0,
              loading: _vm.islogsLoading,
              "footer-props": {
                "items-per-page-options": [30, 100, 500],
                "show-current-page": true,
                showFirstLastPage: true,
              },
              dense: "",
            },
            on: {
              "update:options": function ($event) {
                _vm.filter = $event
              },
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.headers, function (h) {
                  return {
                    key: `header.${h.value}`,
                    fn: function ({ header }) {
                      return [
                        h.value === "id"
                          ? _c(
                              "div",
                              {
                                key: h.value,
                                staticStyle: {
                                  "padding-top": "20px",
                                  "font-size": "1.2em",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm._f("capitalize")(h.value)) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "name"
                          ? _c("v-text-field", {
                              key: h.value,
                              staticClass: "text-field-label-style",
                              attrs: {
                                "append-icon": "search",
                                label: header.text,
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                              },
                              on: { input: _vm.debounceInput },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value: _vm.debouncedFilter.filterUserName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.debouncedFilter,
                                    "filterUserName",
                                    $$v
                                  )
                                },
                                expression: "debouncedFilter.filterUserName",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "action"
                          ? _c("v-text-field", {
                              key: h.value,
                              staticClass: "text-field-label-style",
                              attrs: {
                                "append-icon": "search",
                                label: header.text,
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                              },
                              on: { input: _vm.debounceInput },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value: _vm.debouncedFilter.filterAction,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.debouncedFilter,
                                    "filterAction",
                                    $$v
                                  )
                                },
                                expression: "debouncedFilter.filterAction",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "created_at"
                          ? _c(
                              "div",
                              {
                                key: h.value,
                                staticStyle: { "padding-bottom": "10px" },
                              },
                              [
                                _c("DateTimePicker", {
                                  attrs: { label: h.text, disabledTime: true },
                                  model: {
                                    value: _vm.filter.filterLoggedAt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "filterLoggedAt",
                                        $$v
                                      )
                                    },
                                    expression: "filter.filterLoggedAt",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "url"
                          ? _c("v-text-field", {
                              key: h.value,
                              staticClass: "text-field-label-style",
                              attrs: {
                                "append-icon": "search",
                                label: "URL",
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                              },
                              on: { input: _vm.debounceInput },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value: _vm.debouncedFilter.filterResolvedUrl,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.debouncedFilter,
                                    "filterResolvedUrl",
                                    $$v
                                  )
                                },
                                expression: "debouncedFilter.filterResolvedUrl",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "ip"
                          ? _c("v-text-field", {
                              key: h.value,
                              staticClass: "text-field-label-style",
                              staticStyle: { "max-width": "100px" },
                              attrs: {
                                "append-icon": "search",
                                label: header.text,
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                              },
                              on: { input: _vm.debounceInput },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value: _vm.debouncedFilter.filterIp,
                                callback: function ($$v) {
                                  _vm.$set(_vm.debouncedFilter, "filterIp", $$v)
                                },
                                expression: "debouncedFilter.filterIp",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        h.value === "agent"
                          ? _c(
                              "div",
                              {
                                key: h.value,
                                staticStyle: {
                                  "padding-top": "20px",
                                  "font-size": "1.2em",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm._f("capitalize")(h.value)) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  }
                }),
                {
                  key: "item",
                  fn: function (props) {
                    return [
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm._f("formatNumber")(props.item.id))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-overflow text-left" },
                          [
                            props.item.name
                              ? [
                                  _vm._v(
                                    _vm._s(props.item.name) +
                                      "\n                        "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-overflow text-left" }, [
                          _vm._v(_vm._s(props.item.action)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-overflow" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("formatDateTime")(props.item.created_at)
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "text-left text-overflow",
                            staticStyle: { "max-width": "300px" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { href: props.item.url },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(props.item.url) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "text-right text-overflow",
                            staticStyle: { width: "80px" },
                          },
                          [_vm._v(_vm._s(props.item.ip))]
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left text-overflow" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("stringShortener")(
                                  props.item.agent,
                                  40
                                ) ?? "Unknown Agent"
                              ) +
                              "\n                    "
                          ),
                        ]),
                      ]),
                    ]
                  },
                },
                {
                  key: "footer.page-text",
                  fn: function ({ pageStart, pageStop, itemsLength }) {
                    return [
                      _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("formatNumber")(pageStart)) +
                            " - " +
                            _vm._s(_vm._f("formatNumber")(pageStop)) +
                            " of " +
                            _vm._s(_vm._f("formatNumber")(itemsLength)) +
                            "\n                "
                        ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }