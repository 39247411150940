var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-icon", [_vm._v("person")]),
                _vm._v(" "),
                _c("span", { staticClass: "font-weight-bold ml-1" }, [
                  _vm._v("User Info"),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(_setup.NameValueFlex, {
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "grey--text" }, [
                        _vm._v("Name:"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "fontMonospace",
                          staticStyle: {
                            "white-space": "nowrap",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                          },
                        },
                        [_vm._v(_vm._s(_vm.user.name))]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(_setup.NameValueFlex, {
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "grey--text" }, [
                        _vm._v("E-mail:"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-italic" }, [
                        _vm._v(_vm._s(_vm.user.email)),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(_setup.NameValueFlex, {
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "grey--text" }, [
                        _vm._v("Roles:"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _vm.user.roles?.length > 0
                        ? _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _vm._l(
                                _vm.user.roles.slice(0, 3),
                                function (role) {
                                  return _c(
                                    "v-chip",
                                    {
                                      key: role.name,
                                      staticClass: "font-weight-bold",
                                      attrs: { small: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(role.name) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _c(
                                _setup.BaseButtonShowMoreItems,
                                {
                                  attrs: {
                                    "reserve-space": false,
                                    "shown-items-count": 3,
                                    items: _vm.user.roles,
                                  },
                                },
                                _vm._l(_vm.user.roles, function (role) {
                                  return _c(
                                    "v-chip",
                                    {
                                      key: role.name,
                                      staticClass: "font-weight-bold mx-1",
                                      attrs: { small: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(role.name) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            2
                          )
                        : _c("div", [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "\n                        None\n                    "
                              ),
                            ]),
                          ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }