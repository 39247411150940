import apiClient from "@/services/apiClient";
import axios from "axios";

const state = {
    rolesLoading: false,
    roles: [],
    permissions: []
};

const getters = {
    roles: (state) => state.roles,
    permissions: (state) => state.permissions,
    rolesLoading: (state) => state.rolesLoading,
};

const mutations = {
    ROLES_LOADED(state, data) {
        state.roles = data
        state.rolesLoading = false
    },
    ROLES_START_LOADING(state, data) {
        state.rolesLoading = true
    },
    ROLES_LOADING_FINISHED(state) {
        state.rolesLoading = false
    }
};

const actions = {
    async loadRoles({ commit }, pagination) {
        commit('ROLES_START_LOADING');
        try {
            const result = await apiClient.GET("roles", { params: pagination })
            commit('ROLES_LOADED', result);
            commit("ROLES_LOADING_FINISHED")
        } catch (error) {
            if(axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                this.dispatch("error", error.userFriendlyMessage)
                commit("ROLES_LOADING_FINISHED")
            }
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}


