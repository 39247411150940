<template>
    <div class="width100">
        <v-row no-gutters justify="end">
            <v-col cols="auto" class="d-flex">
                <TransactionsControlPanel
                    :expanded="expanded"
                    :timelined="timeline"
                    @expand-event="expanded = !expanded"
                    @timeline-expand-event="timeline = !timeline"/>
            </v-col>
            <v-spacer/>
            <v-col cols="auto">
                <export-menu
                    v-if="isAddress"
                    :disabled="loading"
                    :base-url="baseUrl"/>
            </v-col>
        </v-row>
        <PaginationWrapper
            v-if="loading || transactions.length > 0"
            :loading="loading"
            :pages-total="totalPages ? totalPages : 0"
            v-model="filter.page"
        >
            <template v-slot:content>
                <TransactionsHeader :no-change="!isAddress"/>
                <v-divider/>
                <div v-for="(transaction, index) in transactions" :key="transaction.id">
                    <v-expand-transition>
                        <div v-if="timeline && index !== 0 && transactions[index-1].time !== transaction.time"
                             class="d-flex justify-end align" style="margin-right: 105px">
                            <div class="d-flex justify-start align-center" style="width: 150px">
                                <v-icon left>
                                    mdi-sort-clock-ascending-outline
                                </v-icon>
                                <span class="font-weight-light" style="color: rgba(0,0,0,0.6);">
                                        <nobr>
                                            {{
                                            `${getHumanTime(transactions[index - 1].time * 1000, transaction.time * 1000)}
                                             | ${transactions[index - 1].blockHeight - transaction.blockHeight} Blocks`
                                            }}
                                        </nobr>
                                    </span>
                            </div>
                        </div>
                    </v-expand-transition>
                    <TransactionSingle
                        class="mt-1 mb-1"
                        :transaction="transaction"
                        :expanded="expanded"
                    />
                </div>
            </template>
        </PaginationWrapper>
        <div v-else class="title mt-4 font-weight-light text-center">
            No transactions matched the selected criteria.
        </div>
    </div>
</template>

<script>
import TransactionsHeader from "@/components/transaction/Components/TransactionsHeader.vue";
import TransactionsControlPanel from "@/components/transaction/Components/TransactionsControlPanel.vue";
import TransactionSingle from "@/components/transaction/TransactionSingle.vue";
import {PaginationWrapper, ProgressCircular} from "@/components/common/index";
import ExportMenu from "@/components/common/Buttons/ExportMenu.vue";
import {mapGetters} from "vuex";
import {humanTime} from "@/utils";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import {EntityType} from "@/components/common/types/entity";

export default {
    name: "EntityTransactionsList",
    mixins: [routeSyncMixin],
    components: {
        TransactionsHeader,
        TransactionsControlPanel,
        TransactionSingle,
        PaginationWrapper,
        ProgressCircular,
        ExportMenu,
    },
    inject: ['entityId', 'entityType'],
    data() {
        return {
            expanded: false,
            timeline: false,
            filterInfo: {
                page: {type: "Number", default: () => 1}
            }
        }
    },
    computed: {
        ...mapGetters(["addressId", "transactions", "currency", "tx_pagination", "transactionsPagination"]),
        ...mapGetters({
            loading: 'transactionsLoading'
        }),
        isAddress() {
            return this.entityType === EntityType.Address
        },
        totalPages() {
            return this.transactionsPagination?.last_page
        },
        baseUrl() {
            return `${this.currency.unit}${this.isAddress ? '/cryptoaddress/' : '/cryptocluster/'}${this.entityId}/transactions`
        },
    },
    watch: {
        tx_pagination: function (val, oldVal) {
            this.filter.page = val
        }
    },
    methods: {
        getHumanTime(t0, t1) {
            //swap
            if (t0 > t1)
                [t1, t0] = [t0, t1];

            return humanTime(t1 - t0)
        },
        onFilterInitial() {
            //Initial Filter was not default (and was read from the route)
            //this.$store.commit('TX_PAGINATION', this.filter.page)
        },
        onFilterChange() {
            this.$store.commit('TX_PAGINATION', this.filter.page)
            //Filter Changed
        },
        onFilterLoaded() {
            //Filter loaded
            this.$store.commit('TX_PAGINATION', this.filter.page)
        }
    }
}
</script>

<style scoped>

</style>