<script setup>

import {DataState} from "@/components/common/types/data";
import NProgress from "@/components/common/NProgress.vue";
import AddressSearchResultSingle from "@/views/AdvancedSearch/AddressSearchResultSingle.vue";

const props = defineProps({
    data: {
        type: [Array, Object],
        required: false,
        default: []
    },
    dataState: {
        type: Number,
        required: true,
    },
    error: {
        type: String,
        required: false,
    },
})


</script>

<template>
    <div class="mt-2">
        <NProgress v-if="dataState === DataState.Loading" progressKey="/search"/>
        <div v-else-if="dataState === DataState.Error"
             class="d-flex align-center justify-center mt-10">
            <v-alert
                border="left"
                class="my-n2"
                dense
                outlined
                text
                type="error"
                width="400px"
            >{{ error }}
            </v-alert>
        </div>
        <v-row v-if="dataState === DataState.Loaded" dense>
            <v-col cols="12" lg="4" md="6" xl="3">
                <AddressSearchResultSingle :get-location-from-resource="(r) => `/cases/${r.id}`"
                                           :resource-type-item="data.Case"
                                           icon="assignment"
                                           title="Cases"/>
            </v-col>
            <v-col cols="12" lg="4" md="6" xl="3">
                <AddressSearchResultSingle :get-location-from-resource="(r) => `/${r.crypto}/cluster/${r.clusterId}`"
                                           :resource-type-item="data.Cluster"
                                           icon="mdi-hub"
                                           title="Clusters"/>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss">

.searchExpandPanel .border {
    border-top: 1px solid rgba(0, 0, 0, .12) !important;
}

.searchExpandPanel .v-expansion-panel::before {
    @extend .border !optional;
    box-shadow: none !important;
}

.searchExpandPanel .v-expansion-panel {
    @extend .border !optional;
    box-shadow: none !important;
}

.searchExpandPanel .v-expansion-panel::after {
    @extend .border !optional;
    box-shadow: none !important;
}

</style>