var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "ma-2" },
        [
          _c("v-col", { attrs: { cols: "7" } }, [
            _c(
              "div",
              {
                on: {
                  drop: function ($event) {
                    $event.preventDefault()
                    return _setup.appendNewFiles.apply(null, arguments)
                  },
                  dragover: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("v-file-input", {
                  key: _setup.fileInputKey,
                  attrs: {
                    accept: "*",
                    "hide-details": "",
                    label: "Click or drop files to upload",
                    multiple: "",
                    "prepend-icon": "attach_file",
                  },
                  on: { change: _setup.appendNewFiles },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          options: _setup.pagination,
          height: _setup.tableHeight,
          dense: "",
          items: _setup.filesToCreate,
          headers: _setup.headers,
          "no-data-text": "No files to add.",
          "fixed-header": "",
        },
        on: {
          "update:options": function ($event) {
            _setup.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "text-left",
                      staticStyle: { "max-width": "330px" },
                    },
                    [
                      item.name.includes(".")
                        ? _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "text-truncate" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.name.split(".").shift()) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(`.${item.name.split(".").pop()}`) +
                                  "\n                        "
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "text-truncate" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.name) +
                                "\n                    "
                            ),
                          ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c(_setup.TableDeleteButton, {
                        on: { click: () => _setup.removeItem(item) },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }