var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-btn",
              {
                staticStyle: { "font-size": "12px" },
                style: _setup.buttonBorderStyle,
                attrs: {
                  height: "24px",
                  outlined: "",
                  tile: "",
                  color:
                    hover ||
                    (_vm.presetSinceTimes[_vm.buttonIndex] ===
                      _vm.filter.since &&
                      _vm.filter.until === _vm.presetUntilTime &&
                      _vm.filter.since &&
                      _vm.filter.until)
                      ? _setup.currency.unit
                      : "primary",
                },
                on: { click: _setup.handleClick },
              },
              [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n    ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }