var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: { title: _vm.title, width: _vm.width },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [_vm._v(_vm._s(_vm.description))]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close }) {
            return [
              _c(
                "v-btn",
                { attrs: { color: "error", text: "" }, on: { click: close } },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDelete(close)
                    },
                  },
                },
                [_vm._v("Delete")]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }