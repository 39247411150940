import {computed} from "vue";
import store from "@/store";

export default function () {

    const GESelectedElement = computed({
        get: () => store.getters.GESelectedElement,
        set: (newVal) => store.commit('GESelectElement', newVal)
    })

    const GETransactions = computed(() => store.getters.GETransactions)

    return {GESelectedElement, GETransactions}
}