<script setup>
import {GEEleType} from "@/views/GraphExplorer/Components/Drawer/GEEleType";
import GraphExplorerDrawerAddress from "@/views/GraphExplorer/Components/Drawer/GraphExplorerDrawerAddress.vue";
import {onMounted, onUnmounted, ref} from "vue";
import UseGEStore from "@/views/GraphExplorer/Composables/UseGEStore";
import GraphExplorerDrawerTransaction from "@/views/GraphExplorer/Components/Drawer/GraphExplorerDrawerTransaction.vue";
import GraphExplorerDrawerMerge from "@/views/GraphExplorer/Components/Drawer/GraphExplorerDrawerMerge.vue";

let mousePosition = undefined
const drawerContainerRef = ref(null)
const isMouseDown = ref(false)
onMounted(() => {
    document.addEventListener('mouseup', mouseUp)
})
onUnmounted(() => {
    document.removeEventListener('mouseup', resize)
})
const mouseUp = (e) => {
    document.removeEventListener('mousemove', resize)
}
const mouseDown = (e) => {
    mousePosition = e.y
    document.addEventListener('mousemove', resize)
}
const resize = (e) => {
    const height = parseInt(getComputedStyle(drawerContainerRef.value, '').height)
    const dx = mousePosition - e.y
    mousePosition = e.y
    drawerContainerRef.value.style.height = (height + dx) + 'px'
}

const {GESelectedElement} = UseGEStore()

</script>

<template>
    <div v-show="GESelectedElement" class="drawerContainer" ref="drawerContainerRef">
        <div class="draggable" @mousedown="mouseDown"></div>
        <div style="background-color: white; width: 100%">
            <div v-if="GESelectedElement?.data.type === GEEleType.NodeAddress">
                <GraphExplorerDrawerAddress/>
            </div>
            <div v-else-if="GESelectedElement?.data.type === GEEleType.NodeTransaction">
                <GraphExplorerDrawerTransaction/>
            </div>
            <div v-else-if="GESelectedElement?.data.type === GEEleType.NodeMerge">
                <GraphExplorerDrawerMerge/>
            </div>
        </div>
    </div>
</template>

<style scoped>

.drawerContainer {
    background-color: #FFFFFF;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 200px;
    max-height: 86vh;
    height: 450px;
    overflow-y: auto;
}

.draggable {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    height: 6px;
    padding: 0;
    cursor: row-resize;
    width: 100%;
    border-top: solid 2px #9c9898;
}

</style>