var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.FancyCard, {
    attrs: { dense: "", header: false },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex", staticStyle: { gap: "8px" } },
              [
                _vm.selectedBip === 44 ||
                _vm.selectedBip === 49 ||
                _vm.selectedBip === 84
                  ? _c("v-text-field", {
                      attrs: {
                        filled: "",
                        dense: "",
                        label: "Purpose",
                        disabled: "",
                      },
                      model: {
                        value: _vm.selectedBipSpecs.purpose,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedBipSpecs, "purpose", $$v)
                        },
                        expression: "selectedBipSpecs.purpose",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedBip === 44 ||
                _vm.selectedBip === 49 ||
                _vm.selectedBip === 84
                  ? _c("v-text-field", {
                      attrs: {
                        filled: "",
                        dense: "",
                        label: "Coin",
                        disabled: "",
                      },
                      model: {
                        value: _vm.selectedBipSpecs.coin,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedBipSpecs, "coin", $$v)
                        },
                        expression: "selectedBipSpecs.coin",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedBip === 44 ||
                _vm.selectedBip === 49 ||
                _vm.selectedBip === 84
                  ? _c("v-text-field", {
                      attrs: { filled: "", dense: "", label: "Account" },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("parameterChange")
                        },
                      },
                      model: {
                        value: _vm.selectedBipSpecs.account,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedBipSpecs, "account", $$v)
                        },
                        expression: "selectedBipSpecs.account",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedBip === 44 ||
                _vm.selectedBip === 49 ||
                _vm.selectedBip === 84
                  ? _c("v-text-field", {
                      attrs: {
                        filled: "",
                        dense: "",
                        label: "External / Internal",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("parameterChange")
                        },
                      },
                      model: {
                        value: _vm.selectedBipSpecs.change,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedBipSpecs, "change", $$v)
                        },
                        expression: "selectedBipSpecs.change",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedBip === 32
                  ? _c("v-select", {
                      attrs: {
                        filled: "",
                        dense: "",
                        label: "Client",
                        items: _vm.selectedBipSpecs.clientList,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("parameterChange")
                        },
                      },
                      model: {
                        value: _vm.selectedBipSpecs.selectedClient,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedBipSpecs, "selectedClient", $$v)
                        },
                        expression: "selectedBipSpecs.selectedClient",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedBipSpecs.selectedClient ===
                  "Custom Derivation Path" && _vm.selectedBip === 32
                  ? _c("v-text-field", {
                      attrs: {
                        filled: "",
                        dense: "",
                        label: "Derivation Path",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("parameterChange")
                        },
                      },
                      model: {
                        value: _vm.selectedBipSpecs.customPath,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedBipSpecs, "customPath", $$v)
                        },
                        expression: "selectedBipSpecs.customPath",
                      },
                    })
                  : _c("v-text-field", {
                      attrs: {
                        filled: "",
                        dense: "",
                        label: "Derivation Path",
                        disabled: _vm.selectedBip !== 141,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("parameterChange")
                        },
                      },
                      model: {
                        value: _vm.selectedBipSpecs.path,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedBipSpecs, "path", $$v)
                        },
                        expression: "selectedBipSpecs.path",
                      },
                    }),
                _vm._v(" "),
                _vm.selectedBip === 141
                  ? _c("v-select", {
                      attrs: {
                        filled: "",
                        dense: "",
                        label: "Script Semantics",
                        items: _vm.selectedBipSpecs.scriptSemanticOptions,
                        "return-object": "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit(
                            "addressTypeChange",
                            _vm.selectedBipSpecs.scriptSemanticSelected
                          )
                        },
                      },
                      model: {
                        value: _vm.selectedBipSpecs.scriptSemanticSelected,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.selectedBipSpecs,
                            "scriptSemanticSelected",
                            $$v
                          )
                        },
                        expression: "selectedBipSpecs.scriptSemanticSelected",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-text-field", {
              attrs: { label: "bip32 Extended Private Key", disabled: "" },
              model: {
                value: _vm.selectedBipSpecs.extendedPrivateKey,
                callback: function ($$v) {
                  _vm.$set(_vm.selectedBipSpecs, "extendedPrivateKey", $$v)
                },
                expression: "selectedBipSpecs.extendedPrivateKey",
              },
            }),
            _vm._v(" "),
            _c("v-text-field", {
              attrs: { label: "bip32 Extended Public Key", disabled: "" },
              model: {
                value: _vm.selectedBipSpecs.extendedPublicKey,
                callback: function ($$v) {
                  _vm.$set(_vm.selectedBipSpecs, "extendedPublicKey", $$v)
                },
                expression: "selectedBipSpecs.extendedPublicKey",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }