<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <div class="d-inline-block mx-1">
                <slot name="iconName" :on="on"/>
            </div>
        </template>
        <span>
            <slot name="tooltip"/>
        </span>
    </v-tooltip>
</template>

<script>
export default {
    name: "NotificationMethodBase",
    props: {
    }
}
</script>

<style scoped>

</style>