<script setup>

import {ref, useAttrs, watch} from "vue"
import ButtonWrapperHoverTooltip from "@/components/common/Buttons/ButtonWrapperHoverTooltip.vue";

const props = defineProps({
    value: {
        required: false
    }
})


const isActive = ref(false)
const attrs = useAttrs()

const emit = defineEmits(['on-close-event', 'on-open-event'])
const menuVisible = ref(false)

const closeMenu = () => {
    menuVisible.value = false
}

watch(menuVisible, (newVal, oldVal) => {
    if (newVal && !oldVal)
        emit('on-open-event')
    else if (oldVal && !newVal)
        emit('on-close-event')
})

defineExpose({
    closeMenu
})

</script>

<template>
    <v-menu
        v-model="menuVisible"
        content-class="whiteBackground"
        :close-on-content-click="false"
        offset-x>
        <template v-slot:activator="{ on }">
            <ButtonWrapperHoverTooltip>
                <template #active="{ on: onTooltip, attrs }">
                    <v-btn
                        v-bind="attrs"
                        small
                        v-on="onTooltip"
                        @click="on.click"
                        icon>
                        <v-icon
                            color="blue"
                            small>
                            mdi-filter-menu
                        </v-icon>
                    </v-btn>
                </template>
                <template #tooltip>
                    Edit Filter
                </template>
                <template #alt>
                    <v-btn
                        v-bind="attrs"
                        small
                        v-on="on"
                        icon>
                        <v-icon
                            v-if="value"
                            color="blue"
                            small>
                            mdi-filter-menu
                        </v-icon>
                        <v-icon
                            v-else
                            small>
                            mdi-filter-menu
                        </v-icon>
                    </v-btn>
                </template>
            </ButtonWrapperHoverTooltip>
        </template>
        <div style="background-color: white">
            <slot name="menuContent"/>
        </div>
    </v-menu>
</template>