<template>
    <div class="height100 scroll-y">
        <v-container grid-list-md fluid class="pa-0">
            <slot name="title"/>
            <v-divider/>
            <v-divider/>
            <div class="mt-2 d-flex flex-column align-center">
                <div class="d-flex flex-wrap">
                    <div style="width: 220px" class="mr-2">
                        <DateTimePicker supports-seconds label="Since" v-model="filter.since"/>
                    </div>
                    <div style="width: 220px" class="ml-2">
                        <DateTimePicker supports-seconds label="Until" v-model="filter.until"/>
                    </div>
                </div>
                <div class="d-flex mx-2 mb-2 flex-wrap">
                    <PresetLimitButton v-for="(item, index) in limitValues" :preset-since-times="presetSinceTimes"
                                       :button-index="index" :filter="filter" :time-period="item.timePeriod"
                                       :preset-until-time="presetUntilTime"
                                       :is-last="index === limitValues.length-1"
                                       :button-text="item.text" :key="item.text"
                                       @limitSelect="(e) => selectDatePreset(e.timePeriod, e.buttonIndex)"/>
                </div>
            </div>
            <div class="mt-2">
                <slot name="body"/>
            </div>
        </v-container>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import DateTimePicker from "@/components/common/DateTimePicker";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import moment from "moment";
import {deepClone} from "@/utils";
import PresetLimitButton from "@/components/common/PresetLimitButton.vue";

export default {
    name: "LimitAbleLayout",
    components: {PresetLimitButton, DateTimePicker},
    mixins: [routeSyncMixin],
    props: {
        initValues: {
            required: true
        }
    },
    computed: {
        ...mapGetters(["currency"])
    },
    data() {
        return {
            presetSinceTimes: [],
            presetUntilTime: undefined,
            filterInfo: {
                since: {type: "Number", default: () => undefined},
                until: {type: "Number", default: () => undefined},
            },
            limitValues: [
                {timePeriod: "day", text: "Last Day"},
                {timePeriod: "week", text: "Last Week"},
                {timePeriod: "month", text: "Last Month"},
                {timePeriod: "year", text: "Last Year"},
                {timePeriod: undefined, text: "All"}
            ],
            defaultSinceValue: moment().subtract(this.initValues.offset.amount, this.initValues.offset.unit).unix(),
            defaultUntilValue: moment().unix()
        }
    },
    created() {
        this.setInitialRouteQuery()
    },
    methods: {
        setInitialRouteQuery() {
            let routeSince = this.$route.query.since
            let routeUntil = this.$route.query.until

            if (!routeSince || !routeUntil) {
                const defaultSinceValue = this.defaultSinceValue
                const defaultUntilValue = this.defaultUntilValue

                let since = routeSince ? Number(routeSince) : defaultSinceValue
                let until = routeUntil ? Number(routeUntil) : defaultUntilValue

                this.presetSinceTimes[this.initValues.selectedButtonIndex] = since
                this.presetUntilTime = until

                //Set initial route (before it is read from mixin)
                this.$_syncToRouteQuery(true, {since: since, until: until})
            }
        },
        //handles LAST XY button click (subtracts timePeriod, store the generated values to be able to highlight the appropriate button)
        selectDatePreset(timePeriod, buttonIndex) {
            if (timePeriod) {
                this.filter.since = moment().subtract(1, timePeriod).subtract(1, "second").unix()
                this.filter.until = moment().add(1, "second").unix()
            } else {
                this.filter.since = moment.unix(this.initValues.since).subtract(1, "second").unix()
                this.filter.until = this.initValues.until ? moment.unix(this.initValues.until).add(1, "second").unix() : moment().unix()
            }
            this.presetSinceTimes[buttonIndex] = deepClone(this.filter.since)
            this.presetUntilTime = this.filter.until;
        },
        onFilterLoaded() {
            this.$emit('reload-data', {since: this.filter.since, until: this.filter.until})
        },
        onFilterChange() {
            if (!this.filter.since) {
                this.filter.since = this.defaultSinceValue
                this.presetSinceTimes[this.initValues.selectedButtonIndex] = this.filter.since
            }
            if (!this.filter.until) {
                this.filter.until = this.defaultUntilValue
                this.presetUntilTime = this.filter.until
            }

            this.$emit('reload-data', {since: this.filter.since, until: this.filter.until})
        }
    }
}
</script>