<template>
    <div class="d-flex">
        <v-tooltip v-if="!hideExpand" top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :depressed="false" class="mr-5" outlined small @click="$emit('expand-event')" v-on="on">
                    {{ expanded ? "Collapse" : "Expand" }}
                    <v-icon right>
                        {{ expanded ? "mdi-arrow-collapse-vertical" : "mdi-arrow-expand-vertical" }}
                    </v-icon>
                </v-btn>
            </template>
            {{ expanded ? "Collapse" : "Expand" }} all transactions
        </v-tooltip>
        <v-switch
            v-if="!hideTimeline"
            :value="timelined"
            class="mr-5 mt-0"
            label="Timeline"
            @change="$emit('timeline-expand-event')"
        ></v-switch>
    </div>
</template>

<script>
export default {
    name: "TransactionsControlPanel",
    props: {
        expanded: {
            type: Boolean,
            required: true
        },
        timelined: {
            type: Boolean,
            required: true
        },
        hideExpand: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideTimeline: {
            type: Boolean,
            required: false,
            default: false,
        },
    }
}
</script>

<style scoped>

</style>