var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        {
          attrs: { multiple: "" },
          model: {
            value: _setup.expanded,
            callback: function ($$v) {
              _setup.expanded = $$v
            },
            expression: "expanded",
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }