<script setup>
import store from "@/store";
import {computed, ref} from "vue";
import debounce from "debounce";
import {safeRouterPush} from "@/utils";
import {useRouter} from "vue-router/composables";

const loading = computed(() => store.getters.searchLoading)
const currency = computed(() => store.getters.currency)
const isInFocus = ref(false)
const router = useRouter()

const updateSearch = () => store.dispatch('search', searchText.value);
const debounceUpdateSearch = debounce(function () {
    updateSearch()
}, 450)
const searchText = computed({
    get: () => store.getters.searchText,
    set: (newVal) => {
        store.dispatch('updateSearchString', newVal)
        debounceUpdateSearch()
    }
})

const textAreaRows = ref(1)
const resizeEvent = () => textAreaRows.value = textAreaRows.value !== 1 ? 1 : 8;

const pushToRoute = (e) => {
    if (e.shiftKey === true) {
        if (textAreaRows.value > 1)
            searchText.value += '\n'
        return
    }

    if (!searchText.value) {
        return
    }

    const redirectToRoute = currency.value
        ? `/${currency.value.unit}/search?q=${searchText.value}`
        : `/search?q=${searchText.value.trim()}`;
    safeRouterPush(router, redirectToRoute);
}

</script>

<template>
    <v-layout align-start fill-height>
        <v-textarea
            :rows="textAreaRows"
            dark
            no-resize
            outlined
            hide-details
            class="ml-3 fontMonospace toolbarSearch searchArea"
            placeholder="Search"
            counter
            v-model="searchText"
            @focus="isInFocus = true"
            @blur="isInFocus = false"
            @click:append="resizeEvent"
            :class="(currency ? currency.unit  + ' darken-1': 'primary darken-3' )"
            clearable
            :background-color="(currency ? currency.unit  + ' darken-1': 'primary darken-3' )"
            @keydown.enter="pushToRoute"
            @keydown.enter.prevent=""
        >
            <template #prepend-inner>
                <div class="d-flex justify-center align-center" style="min-width: 24px; max-width: 24px;">
                    <v-icon v-if="!loading" :color="isInFocus ? 'white' : 'grey'">
                        mdi-magnify
                    </v-icon>
                    <v-progress-circular v-if="loading" size="20" width="2" indeterminate/>
                </div>
            </template>
            <template #append>
                <v-hover v-slot="{ hover }">
                    <div class="d-flex ml-1">
                        <v-divider vertical/>
                        <v-icon :color="hover ? 'white' : 'grey'" @click="resizeEvent">
                            mdi-unfold-more-horizontal
                        </v-icon>
                    </div>
                </v-hover>
            </template>
        </v-textarea>
    </v-layout>
</template>

<style scoped>

.toolbarSearch {
    border-radius: 8px;
}

.centered-input input {
    text-align: center;
}

</style>

<style>

.searchArea textarea {
    line-height: 24px !important;
    margin-top: 16px !important;
}

</style>
