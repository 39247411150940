var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-7", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { "no-gutters": "", justify: "end" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("export-menu", {
                attrs: {
                  disabled: !_vm.transaction,
                  "base-url":
                    _vm.currency.unit +
                    "/cryptotransaction/" +
                    ((_vm.transaction && _vm.transaction.txhash) || ""),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "transactionContainer" },
        [
          _c("TransactionsHeader", { attrs: { "no-change": "" } }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          !_vm.transactionLoading && _vm.transaction
            ? _c(
                "div",
                { staticClass: "pt-2" },
                [
                  _c("TransactionSingle", {
                    attrs: { expanded: "", transaction: _vm.transaction },
                  }),
                ],
                1
              )
            : _c("progress-circular", {
                staticClass: "mt-2",
                attrs: { color: _vm.currency.hexColor },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }