<template>
    <div class="scroll-y height100">
        <v-container class="height100 px-4" fluid>
            <v-layout align-center>
                <h1>Logs</h1>
            </v-layout>
            <v-divider/>
            <v-divider/>
            <v-data-table
                item-key="id+created_at"
                :headers="headers"
                :items="logs.data"
                :options.sync="filter"
                :server-items-length="logs.total ? logs.total : 0"
                :loading="islogsLoading"
                class="elevation-0 border mt-2"
                :footer-props="{
                    'items-per-page-options': [
                        30,
                        100,
                        500,
                    ],
                    'show-current-page': true,
                    'showFirstLastPage': true,
                }"
                dense
            >
                <template
                    v-for="h in headers"
                    v-slot:[`header.${h.value}`]="{ header }"
                >
                    <div
                        :key="h.value"
                        v-if="h.value === 'id'"
                        style="padding-top: 20px; font-size: 1.2em"
                    >
                        {{ h.value | capitalize }}
                    </div>

                    <v-text-field
                        :key="h.value"
                        v-if="h.value === 'name'"
                        append-icon="search"
                        :label="header.text"
                        single-line
                        hide-details
                        clearable
                        @click.stop.native
                        @input="debounceInput"
                        v-model="debouncedFilter.filterUserName"
                        class="text-field-label-style"
                    />

                    <v-text-field
                        :key="h.value"
                        v-if="h.value === 'action'"
                        append-icon="search"
                        :label="header.text"
                        single-line
                        hide-details
                        clearable
                        @click.stop.native
                        @input="debounceInput"
                        v-model="debouncedFilter.filterAction"
                        class="text-field-label-style"
                    />

                    <div :key="h.value" v-if="h.value === 'created_at'" style="padding-bottom: 10px">
                        <DateTimePicker
                            v-model="filter.filterLoggedAt"
                            :label="h.text"
                            :disabledTime="true"
                        />
                    </div>

                    <v-text-field
                        :key="h.value"
                        v-if="h.value === 'url'"
                        append-icon="search"
                        label="URL"
                        single-line
                        hide-details
                        clearable
                        @click.stop.native
                        @input="debounceInput"
                        v-model="debouncedFilter.filterResolvedUrl"
                        class="text-field-label-style"
                    />

                    <v-text-field
                        :key="h.value"
                        v-if="h.value === 'ip'"
                        append-icon="search"
                        :label="header.text"
                        single-line
                        hide-details
                        clearable
                        @click.stop.native
                        @input="debounceInput"
                        v-model="debouncedFilter.filterIp"
                        style="max-width: 100px;"
                        class="text-field-label-style"
                    />

                    <div
                        :key="h.value"
                        v-if="h.value === 'agent'"
                        style="padding-top: 20px; font-size: 1.2em"
                    >
                        {{ h.value | capitalize }}
                    </div>
                </template>
                <template slot="item" slot-scope="props">
                    <tr>
                        <td>{{ props.item.id | formatNumber }}</td>
                        <td class="text-overflow text-left">
                            <template v-if="props.item.name">{{
                                    props.item.name
                                }}
                            </template>
                        </td>
                        <td class="text-overflow text-left">{{ props.item.action }}</td>
                        <td class="text-overflow">
                            {{ props.item.created_at | formatDateTime }}
                        </td>
                        <td style="max-width: 300px;" class="text-left text-overflow">
                            <a
                                :href="props.item.url"
                                class="link"
                            >
                                {{ props.item.url }}
                            </a>
                        </td>
                        <td style="width: 80px;" class="text-right text-overflow">{{ props.item.ip }}</td>
                        <td class="text-left text-overflow">
                            {{ props.item.agent | stringShortener(40) ?? "Unknown Agent" }}
                        </td>
                    </tr>
                </template>
                <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                    <span>
                        {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                    </span>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import debounce from "debounce";
import DateTimePicker from "@/components/common/DateTimePicker";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";

export default {
    name: "LogsView",
    mixins: [routeSyncMixin],
    components: {
        DateTimePicker
    },
    computed: {
        ...mapGetters(["logs", "islogsLoading"])
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                sortBy: {type: "ArrayOfStrings", default: () => []},
                sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
                filterUserName: {type: "String", default: () => ''},
                filterAction: {type: "String", default: () => ''},
                filterLoggedAt: {type: "Number", default: () => undefined},
                filterResolvedUrl: {type: "String", default: () => ''},
                filterIp: {type: "String", default: () => ''},
            },
            debouncedFilter: {
                filterUserName: undefined,
                filterAction: undefined,
                filterResolvedUrl: undefined,
                filterIp: undefined,
            },
            headers: [
                {text: "Id", align: "center", value: "id"},
                {text: "User", align: "center", value: "name"},
                {text: "Action", align: "center", value: "action"},
                {text: "Logged At", align: "center", value: "created_at", sortable: false},
                {text: "Recorded URL", align: "center", value: "url"},
                {text: "IP", align: "center", value: "ip"},
                {text: "Agent", align: "center", value: "agent"}
            ],
        }
    },
    methods: {
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),
        refresh() {
            this.$store.dispatch("loadLogs", this.filter)
        },
    },
}
</script>