<template>
    <div>
        <div v-for="{id, val} in innerValue" :key="id">
            <div class="d-flex justify-space-between my-0">
                <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="fontMonospace">
                    {{val}}
                </div>
                <v-btn small icon color="red" @click="deleteItem(id)">
                    <v-icon>close</v-icon>
                </v-btn>
            </div>

            <v-divider />
        </div>
    </div>
</template>

<script>
import { uuidv4 } from "@/utils";

export default {
    name: "DeleteableList",
    props: {
        value: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        innerValue: []
    }),
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.innerValue = newVal.map(i => ({ val: i, id: uuidv4() }))
            }
        }
    },
    methods: {
        deleteItem(id) {
            const updateElements = this.innerValue.filter(i => i.id !== id).map(i => i.val)
            this.$emit("input", updateElements);
        }
    }
}
</script>
