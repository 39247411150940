import {isOnSplitView} from "@/utils";

let cache = new Map()

function getRouteRelativePrefix(curRoute, currency?): String {

    if (cache.has(curRoute.path + ' ' + currency)) {
        return cache.get(curRoute.path + ' ' + currency)
    }

    let retVal
    //early return if bottom drawer is not present,
    if (!isOnSplitView(curRoute)) {
        retVal = `/${currency ? currency : curRoute.params.currency}/`
        cache.set(`${curRoute.path} ${currency}`, retVal)
        return retVal
    }

    let addressString = "address/"
    let indexOfLastAddressOccurrence = curRoute.fullPath.lastIndexOf(addressString)

    let transactionString = "transaction/"
    let indexOfLastTransactionOccurrence = curRoute.fullPath.lastIndexOf(transactionString)

    let clusterString = "cluster/"
    let indexOfLastClusterOccurrence = curRoute.fullPath.lastIndexOf(clusterString)

    let targetIndex

    const isOnAddressBottomDrawer = () => curRoute.params.detailAddress
    const isOnClusterBottomDrawer = () => curRoute.params.detailCluster

    if(isOnAddressBottomDrawer()) {
        targetIndex = indexOfLastAddressOccurrence
    } else if (isOnClusterBottomDrawer()) {
        targetIndex = indexOfLastClusterOccurrence
    } else {
        targetIndex = Math.max(indexOfLastAddressOccurrence, indexOfLastTransactionOccurrence, indexOfLastClusterOccurrence)
    }

    retVal = curRoute.fullPath.slice(0, targetIndex)

    cache.set(`${curRoute.path} ${currency}`, retVal)

    return retVal
}

const routeToClosedDrawer = (router, options) => {
    //TODO move this logic to Views, now is not possible because o nested views
    if (router.currentRoute.matched.findIndex(route => route.name === 'TransactionNetwork') !== -1) {
        router.push('/' + router.currentRoute.params.currency + '/transaction/' + router.currentRoute.params.transaction + '/network/' + options?.sides)
    } else if (router.currentRoute.matched.findIndex(route => route.name === 'FundsNetwork') !== -1) {
        router.push('/' + router.currentRoute.params.currency + '/address/' + router.currentRoute.params.address + '/funds/paths/source/' + router.currentRoute.params.source + '/destination/' + router.currentRoute.params.destination + '/maxHops/' + router.currentRoute.params.maxHops)
    } else if (router.currentRoute.matched.findIndex(route => route.name === 'FundsNetworkCluster') !== -1) {
        router.push('/' + router.currentRoute.params.currency + '/cluster/' + router.currentRoute.params.clusterentity + '/funds/paths/source/' + router.currentRoute.params.source + '/destination/' + router.currentRoute.params.destination + '/maxHops/' + router.currentRoute.params.maxHops)
    } else {
        router.push('/' + router.currentRoute.params.currency + '/graph/' + options.graphId + '/config/' + router.currentRoute.params.graphConfigId)
    }
}

export {
    routeToClosedDrawer,
    getRouteRelativePrefix
}