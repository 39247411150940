var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex align-center ml-n2" },
    [
      _c(
        "v-list-item-content",
        { staticStyle: { padding: "4px 0 !important" } },
        [
          _c("v-list-item-title", [
            _vm._v("\n            " + _vm._s(_vm.item.name) + ", "),
            _c("b", [_vm._v(_vm._s(_vm.item.country_code))]),
          ]),
          _vm._v(" "),
          _c(
            "v-list-item-subtitle",
            [
              _vm._v(
                "\n            Autonomous System\n            |\n            " +
                  _vm._s(_vm._f("numberFormatWithSpaces")(_vm.item.asn)) +
                  "\n            |\n            "
              ),
              _c("v-icon", { attrs: { "x-small": "" } }, [
                _vm._v(
                  "\n                mdi-tag-multiple-outline\n            "
                ),
              ]),
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("numberFormatCompact")(_vm.item.addresses)) +
                  "\n        "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }