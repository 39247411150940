<script setup>
import {computed} from "vue";

import {DataState} from "@/components/common/types/data";

const props = defineProps({
    address: {
        required: true,
        type: String
    },
    currency: {
        required: true,
        type: Object
    },
    addressData: {
        required: true,
        type: Object
    },
})


const data = computed(() => props.addressData.data.data)

const loading = computed(() => props.addressData.dataState === DataState.Loading || props.addressData.dataState === DataState.Initial)
</script>

<template>
    <div>
        <div v-if="loading"> <v-btn link small icon loading :color="currency.hexColor"></v-btn> </div>
        <v-btn
            small
            icon
            dark
            color="#EE0000"
            v-else-if="props.addressData?.dataState === DataState.Error"
            @click="props.addressData.getData({ currency: props.currency.unit, address: props.address })"
        >
            <v-icon>mdi-reload</v-icon>
        </v-btn>
        <div v-else-if="data && data.confirmedTransactions === 0" class="font-italic">Never used</div>
        <div v-else-if="data">
            <v-chip
                :color="data.fiats.crypto"
                style="height: 26px"
                label
                text-color="white"
            >
                <span class="subtitle-1 font-weight-bold" style="vertical-align: middle">
                    {{ data.confirmedBalance }}
                </span>
                <span class="subtitle-1 pl-1 text--white" style="vertical-align: middle">
                    {{ currency.unit }}
                </span>
            </v-chip>
        </div>
    </div>
</template>

<style scoped>

</style>