<script setup>
import BaseButtonShowMoreItems from "@/components/common/TableComponents/BaseButtonShowMoreItems"

const props = defineProps({
    items: {
        type: Array,
        required: false,
        default() {
            return []
        }
    },
    item_id: {
        type: String,
        required: false,
        default: "id"
    },
    shownItemsAmount: {
        type: Number,
        required: false,
        default: 1
    },
    item_value: {
        type: String,
        required: false,
        default: "name"
    }
})

const getItemKey = (item, index) => props.item_id ? item[props.item_id] : index

</script>
<template>
    <div class="d-flex justify-left align-center" style="max-width: 200px">
        <div v-for="(item, index) in items.slice(0, shownItemsAmount)" :key="getItemKey(item, index)">
            <slot :item="item" :isInMenu="false"/>
        </div>
        <BaseButtonShowMoreItems
            :items="items"
            :shownItemsCount="shownItemsAmount"
        >
            <div v-for="(item, index) in items" :key="getItemKey(item, index)">
                <slot :item="item" :isInMenu="true"/>
            </div>
        </BaseButtonShowMoreItems>
    </div>
</template>
