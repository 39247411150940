var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        attrs: {
          items: _setup.users,
          value: _setup.value,
          "append-icon": "search",
          "item-text": "name",
          "item-value": "id",
          label: "User",
          "hide-details": "",
          "single-line": "",
          loading: _setup.loading,
          clearable: "",
          dense: "",
          "clear-icon": "mdi-close-circle",
        },
        on: {
          input: function ($event) {
            return _setup.emit("input", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }