var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    ref: "baseModal",
    attrs: { title: "Add new node" },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pl-5 pr-5", attrs: { cols: "12" } },
                            [
                              _c("p", [
                                _vm._v(
                                  "Please use the following controls to configure a relationship between the newly added\n                            node and existing ones on the graph. This modal will help you to specify what paths\n                            should be plotted between nodes."
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pl-5 pr-5", attrs: { cols: "12" } },
                            [
                              _c("v-text-field-validateable", {
                                attrs: {
                                  rules: `required|${
                                    _vm.detail.asAddress
                                      ? "crypto"
                                      : "entity_id"
                                  }`,
                                  label: `Node crypto ${
                                    _vm.detail.asAddress ? "address" : "cluster"
                                  }`,
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.detail.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "address", $$v)
                                  },
                                  expression: "detail.address",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-9 pb-4 pr-4",
                              attrs: { cols: "8", md: "8" },
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: `${
                                    _vm.detail.asAddress
                                      ? "Node is an Address and search for paths between addresses"
                                      : "Node is a Cluster and search for paths between clusters"
                                  }`,
                                  "true-value": 1,
                                  "false-value": 0,
                                  color: _vm.currency.unit,
                                },
                                model: {
                                  value: _vm.detail.asAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "asAddress", $$v)
                                  },
                                  expression: "detail.asAddress",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pr-12 pb-4",
                              attrs: { cols: "4", sm: "4" },
                            },
                            [
                              _c("v-text-field-validateable", {
                                attrs: {
                                  label: "Max hops in path",
                                  type: "number",
                                  min: 1,
                                  max: _vm.currency.unit !== "ETH" ? 20 : 1,
                                  rules: "required|numeric",
                                },
                                model: {
                                  value: _vm.detail.maxHops,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "maxHops", _vm._n($$v))
                                  },
                                  expression: "detail.maxHops",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pl-5 pr-5", attrs: { cols: "12" } },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  items: _vm.directionsOptions,
                                  label: "Direction of added node",
                                  "hide-selected": "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.detail.directions,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "directions", $$v)
                                  },
                                  expression: "detail.directions",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pl-5 pr-5", attrs: { cols: "12" } },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  items: _vm.pathsOptions,
                                  label: "Search for following path(s)",
                                  "hide-selected": "",
                                  outlined: "",
                                },
                                on: { change: _vm.applyValue },
                                model: {
                                  value: _vm.detail.paths,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "paths", $$v)
                                  },
                                  expression: "detail.paths",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-12",
                              attrs: { cols: "8", md: "8" },
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: `${
                                    _vm.detail.includeClusters
                                      ? "Include Clusters enroute"
                                      : "Exclude Clusters enroute"
                                  }`,
                                  "true-value": 1,
                                  "false-value": 0,
                                  color: _vm.currency.unit,
                                },
                                model: {
                                  value: _vm.detail.includeClusters,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "includeClusters", $$v)
                                  },
                                  expression: "detail.includeClusters",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pr-12",
                              attrs: { cols: "4", sm: "4" },
                            },
                            [
                              _c("v-text-field-validateable", {
                                attrs: {
                                  disabled:
                                    _vm.detail.paths.value ===
                                    "shortest_single",
                                  label: "Limit Results",
                                  type: "number",
                                  min: 0,
                                  rules: "required|numeric",
                                },
                                model: {
                                  value: _vm.detail.limit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "limit", _vm._n($$v))
                                  },
                                  expression: "detail.limit",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "error",
                    text: "",
                    disabled: _vm.newNodeModalState === _vm.modalState.Pending,
                  },
                  on: { click: close },
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    text: "",
                    loading: _vm.newNodeModalState === _vm.modalState.Pending,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addNewNode(close, setDisabled)
                    },
                  },
                },
                [_vm._v("\n            Add new node\n        ")]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }