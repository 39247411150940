var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    !_vm.medium && !_vm.small
      ? _c(
          "span",
          [
            _vm.large
              ? _c(
                  "v-chip",
                  {
                    attrs: {
                      color: _vm.iconItem.color,
                      "text-color": _vm.iconItem.secondaryColor,
                    },
                  },
                  [
                    _c(
                      "v-avatar",
                      [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "font-weight": "500" } }, [
                      _vm._v(_vm._s(_vm.iconItem.name)),
                    ]),
                  ],
                  1
                )
              : _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    class: _vm.cursor,
                                    style: "font-size: " + _vm.iconSize,
                                    attrs: { color: _vm.iconItem.color },
                                  },
                                  on
                                ),
                                [_vm._v(_vm._s(_vm.icon))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      147372456
                    ),
                  },
                  [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.iconItem.name))])]
                ),
          ],
          1
        )
      : _c(
          "span",
          [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              staticClass: "my-1",
                              style: { height: `${_vm.small ? 22 : 26}px` },
                              attrs: {
                                color: _vm.iconItem.color,
                                "text-color": _vm.iconItem.secondaryColor,
                              },
                            },
                            on
                          ),
                          [
                            _c(
                              "v-avatar",
                              { staticClass: "ml-n2" },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(_vm._s(_vm.icon)),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "500" } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.iconItem.name) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.iconItem.name))])]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }