import apiClient from '@/services/apiClient'
import mappers from "models/AlarmModel";
import axios, {Cancel} from "axios";
import Vue from "vue";

import {modalState} from "@/components/common/types/modal";

const state = {
    alarm: null,
    alarmLoading: true,
    alarmNotifications: [],
    alarmNotificationsLoading: false,
    alarms: { data: []},
    alarmSingle: null,
    alarmsLoading: false,
    alarmCreating: 0,
    alarmsCancelToken: axios.CancelToken.source(),
    alarmsModalState: modalState.Initial,
}

const getters = {
    alarm: (state) => state.alarm,
    alarmLoading: (state) => state.alarmLoading,
    alarmNotifications: (state) => state.alarmNotifications,
    alarmNotificationsLoading: (state) => state.alarmNotificationsLoading,
    alarms: (state) => state.alarms,
    alarmSingle: (state) => state.alarmSingle,
    alarmsLoading: (state) => state.alarmsLoading,
    alarmCreating: (state) => state.alarmCreating,
    alarmsModalState: (state) => state.alarmsModalState,
}

const mutations = {
    ALARM_SINGLE_LOADED(state, data) {
      state.alarmSingle =  data
    },
    ALARM_START_LOADING(state) {
        state.alarmLoading = true
    },
    ALARM_CREATING(state)
    {
        state.alarmCreating++;
    },
    ALARM_CREATED(state)
    {
        state.alarmCreating--;
    },
    ALARM_LOADED(state, data) {
        state.alarm = data
    },
    ALARM_FINISH_LOADING(state) {
        state.alarmLoading = false
    },
    ALARM_NOTIFICATIONS_START_LOADING(state) {
        state.alarmNotificationsLoading = true
    },
    ALARM_NOTIFICATIONS_PAGE_LOADED(state, data) {
        state.alarmNotifications = data
    },
    ALARM_NOTIFICATIONS_FINISH_LOADING(state) {
        state.alarmNotificationsLoading = false
    },
    ALARMS_START_LOADING(state) {
        if (state.alarmsCancelToken.token)
        {
            state.alarmsCancelToken.cancel()
        }
        state.alarmsCancelToken = axios.CancelToken.source()
        state.alarmsLoading = true
    },
    ALARMS_PAGE_LOADED(state, data) {
        state.alarms = data
    },
    ALARMS_FINISH_LOADING(state) {
        state.alarmsCancelToken = axios.CancelToken.source()
        state.alarmsLoading = false
    },
    ALARM_INVALIDATE(State, data)
    {
      let tar_id = data.id
        let result = state.alarms.data.map(alarm => {
            if (alarm.id == tar_id) {
                return {...alarm, invalid: true}
            }
            return alarm
        })

        Vue.set(state.alarms, "data", result)
    },
    ALARM_ADD_OR_UPDATE(state, data) {
        let tar_id = data.id;
        let updated = false;

        let result = state.alarms.data.map(alarm => {
            if (alarm.id == tar_id) {
                updated = true
                return data
            }
            return alarm
        })
        if (!updated)
        {
            result.unshift(data)
        }

        Vue.set(state.alarms, "data", result)
    },
    ALARM_DELETE(state, data) {
        let tar_id = data.id;

        let result = state.alarms.data.filter(alarm => {
            return alarm.id != tar_id;
        })

        Vue.set(state.alarms, "data", result)
    },
    ALARMS_MODAL_SET_STATE(state,data) {
        state.alarmsModalState = data
    }
}

const actions = {
    async loadAlarmSingle({commit, dispatch}, { currency, alarmId}) {
        try {
            const response = await apiClient.GET(`${currency}/alarms/${alarmId}`)
            commit('ALARM_SINGLE_LOADED', response)
        } catch (error) {
            dispatch('error', error.userFriendlyMessage)
        }
    },
    async loadAlarms({ commit, dispatch }, { currency, pagination }) {
        commit('ADDRESS_INIT') //cancel summary request (which could override alarms)
        commit('ALARMS_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/alarms`, {
                params: pagination,
                cancelToken: state.alarmsCancelToken.token
            })
            commit('ALARMS_PAGE_LOADED', response)
            commit('ALARMS_FINISH_LOADING')
        } catch (error) {
            if (axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch('error', error.userFriendlyMessage)
                commit('ALARMS_FINISH_LOADING')
            }
        }

    },
    async loadAlarmNotifications({ commit, dispatch }, { currency, pagination, alarmId }, ) {
        commit('ALARM_NOTIFICATIONS_START_LOADING')
        try {
            const response = await apiClient.GET(`${currency}/alarms/${alarmId}/notifications`, {
                params: pagination,
            })
            commit('ALARM_NOTIFICATIONS_PAGE_LOADED', response)

        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        commit('ALARM_NOTIFICATIONS_FINISH_LOADING')
    },
    async deleteAlarm({ dispatch, commit }, { currency, alarmId }) {
        commit("ALARM_INVALIDATE", {id: alarmId});
        try {
            const response = await apiClient.DELETE(`${currency}/alarms/${alarmId}`)
            dispatch("success", response.message)
            commit("ALARM_DELETE", {id: alarmId})
        }
        catch (error) {
            dispatch('error', error.userFriendlyMessage)
        }
    },
    async createAlarm({ dispatch, commit }, { currency, alarm }) {
        let alarmServerModel = mappers.MapToServerModelPOST(alarm)
        commit("ALARM_CREATING")
        commit('ALARMS_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await apiClient.POST(`${currency}/alarms`, alarmServerModel, undefined, true)
            dispatch("success", response.data.message)
            const resource_id = Number(response.headers.location.split("/").pop())
            commit("ALARM_ADD_OR_UPDATE", mappers.MapToServerModel({...alarm, id: resource_id}))
            commit('ALARMS_MODAL_SET_STATE', modalState.Success)
        }
        catch (error) {
            dispatch('error', error.userFriendlyMessage)
            commit('ALARMS_MODAL_SET_STATE', modalState.Error)
        }
        commit("ALARM_CREATED")
    },
    async editAlarm({ dispatch, commit }, { currency, alarm }) {
        let alarmServerModel = mappers.MapToServerModelPOST(alarm)
        commit('ALARMS_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await apiClient.PUT(`${currency}/alarms/${alarm.id}`, alarmServerModel)
            dispatch("success", response.message)
            commit("ALARM_ADD_OR_UPDATE", mappers.MapToServerModel(alarm))
            commit('ALARMS_MODAL_SET_STATE', modalState.Success)
        }
        catch (error) {
            dispatch('error', error.userFriendlyMessage)
            commit('ALARMS_MODAL_SET_STATE', modalState.Error)
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
