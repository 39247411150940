import apiClient from "@/services/apiClient"
import axios from "axios";

const state = {
    logs: [],
    islogsLoading: false,
    logsCancelToken: axios.CancelToken.source()
}

const getters = {
    logs: (state) =>  state.logs,
    islogsLoading: (state) => state.islogsLoading
}

const mutations = {
    LOGS_PAGE_LOADED(state, data) {
        state.logs = data
    },
    LOGS_START_LOADING(state) {
        state.logsCancelToken.cancel()
        state.logsCancelToken = axios.CancelToken.source()
        state.islogsLoading = true
    },
    LOGS_FINISH_LOADING(state) {
        state.islogsLoading = false
    }
}

const actions = {
    async loadLogs({ commit }, pagination) {
        commit('LOGS_START_LOADING')
        try {
            const response = await apiClient.GET("logs", {
                params: pagination,
                cancelToken: state.logsCancelToken.token
            })
            commit('LOGS_PAGE_LOADED', response)
            commit('LOGS_FINISH_LOADING')
        } catch (error) {
            if(axios.isCancel(error)) {
                //request canceled, do nothing
            } else {
                this.dispatch("error", error.userFriendlyMessage)
                commit('LOGS_FINISH_LOADING')
            }
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
