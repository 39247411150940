import Papa from "papaparse";

interface ColumnDefinition {
    name: string
    required: boolean,
}


function readAsTextAsync(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsText(file, "UTF8");
    })
}


const mapIndexToColumnDefinition = (headers: Array<string>, columns: Array<ColumnDefinition>): Map<Number, ColumnDefinition> => {
    const isHeaderInColumns = (header: string): Boolean => columns.some(c => c.name.toLowerCase() === header.toLowerCase())
    const tryRemoveFromRequiredHeaders = (header: string): void => {
        requiredHeaders = requiredHeaders.filter(rh => rh.toLowerCase() !== header.toLowerCase())
    }

    const resultMap: Map<Number, ColumnDefinition> = new Map<Number, ColumnDefinition>()
    let requiredHeaders: Array<string> = columns.filter(h => h.required).map(h => h.name.toLowerCase())
    let seenHeaders: Array<string> = []

    headers.forEach((h, index) => {
        if (!isHeaderInColumns(h)) {
            throw new Error(`File: Unknown header: ${h}`)
        }
        if (seenHeaders.includes(h)) {
            throw new Error(`File: Header ${h} defined multiple times`)
        }
        seenHeaders.push(h)
        tryRemoveFromRequiredHeaders(h)
        resultMap.set(index, columns.filter(c => c.name === h).shift())
    })

    if (requiredHeaders.length !== 0) {
        throw new Error(`File: required header(s) "${requiredHeaders}" not provided!`)
    }
    return resultMap
}

const parseCSVFileToArrayAsync = async (file, config) => {
    let resolve, reject

    const parseConfig = {
        header: true,
        skipEmptyLines: true,
        ...config,
    }

    const parse = () => {
        Papa.parse(file, {
            ...parseConfig,
            complete: (result) => {
                resolve(result)
            },
            error: (error) => {
                reject(error)
            }
        })
    }
    return new Promise((resolveFunc, rejectFunc) => {
        resolve = resolveFunc
        reject = rejectFunc
        parse()
    })
}

export {
    parseCSVFileToArrayAsync,
}