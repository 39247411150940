import apiClient from "@/services/apiClient"
import axios from "axios";

import {modalState} from "@/components/common/types/modal";

const state = {
    allCases: [], // when uploading file, we need to fetch all cases
    files: [],
    filesLoading: true,
    selectedFile: null,
    filesCancelToken: axios.CancelToken.source(),
    filesModalState: modalState.Initial,
}

const getters = {
    allCases: (state) => state.allCases,
    files: (state) => state.files,
    filesLoading: (state) => state.filesLoading,
    file: (state) => state.selectedFile,
    filesModalState: (state) => state.filesModalState,
}

const mutations = {
    FILE_CASES_LOADED(state, data) {
        state.allCases = data
    },

    FILES_START_LOADING(state) {
        state.filesCancelToken.cancel()
        state.filesCancelToken = axios.CancelToken.source()
        state.filesLoading = true
    },
    FILES_PAGE_LOADED(state, data) {
        state.files = data
    },
    FILES_FINISH_LOADING(state) {
        state.filesLoading = false
    },

    FILE_START_LOADING(state) {
        state.selectedFile = null
    },
    FILE_LOADED(state, data) {
        state.selectedFile = data
    },
    FILES_MODAL_SET_STATE(state, data) {
        state.filesModalState = data
    },
}

const actions = {
    async getFile({ commit, dispatch }, id) {
        commit('FILE_START_LOADING')
        try {
            const response = await apiClient.GET(`files/${id}`)
            commit('FILE_LOADED', response)
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        commit("FILE_FINISH_LOADING")
    },
    async loadAllCases({ commit, dispatch }) {
        try {
            const response = await apiClient.GET(`cases`, { params: { all: true } })
            commit('FILE_CASES_LOADED', response)
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
    },
    async loadFiles({ commit }, pagination) {
        commit('FILES_START_LOADING')
        try {
            const response = await apiClient.GET(`files`, {
                params: pagination,
                cancelToken: state.filesCancelToken.token
            })
            commit('FILES_PAGE_LOADED', response)
            commit('FILES_FINISH_LOADING')
        } catch (error) {
            if(axios.isCancel(error)) {
                //request canceled, do nothing
            } else {
                commit('FILES_FINISH_LOADING')
                this.dispatch("error", error.userFriendlyMessage)
            }
        }
    },
    async deleteFile({ dispatch }, { fileId }) {
        try {
            return !!await apiClient.DELETE(`files/${fileId}`)
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        return false
    },
    async createFile({ commit, dispatch }, { fileMetadata }) {
        commit('FILES_MODAL_SET_STATE', modalState.Pending)
        try {
            let formData = new FormData();
            formData.append("case_id", fileMetadata.case_id)
            formData.append("file", fileMetadata.file)
            await apiClient.POST(`files/upload`, formData)
            commit('FILES_MODAL_SET_STATE', modalState.Success)
        } catch (e) {
           if (axios.isCancel(e))
           {

           } else
           {
               dispatch("error", e.message);
               commit('FILES_MODAL_SET_STATE', modalState.Error)
           }
        }
    },
    async editFile({ commit, dispatch }, { fileMetadata }) {
        commit('FILES_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await apiClient.PUT(`files/${fileMetadata.id}`, fileMetadata)
            dispatch("success", response.message);
            commit('FILES_MODAL_SET_STATE', modalState.Success)
        } catch (e) {
            if (axios.isCancel(e))
            {

            } else
            {
                dispatch("error", e.message);
                commit('FILES_MODAL_SET_STATE', modalState.Error)
            }
        }
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
