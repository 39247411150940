<template>
    <v-container>
        <v-content>
            <div class="container">
                    <div class="text">
                        <p class="error-heading fontMonospace">404</p>
                        <v-divider></v-divider>
                        <p class="page-not-found">
                            Page Not Found
                        </p>
                    </div>
                <div class="cog-on-fire">
                    <img class="filter-grey gear-img" src="/img/gear-on-fire.svg">
                </div>
            </div>
        </v-content>
    </v-container>
</template>

<script>
export default {
    name: 'PageNotFound'
}
</script>

<style scoped>

.gear-img {
    width: 20vw;
    flex-basis: 100px;
    max-width: 200px;
}

p {
    font-size: min(3vw, 50px);
    color: #555555;
}

.error-heading {
    font-size: min(10vw, 90px);
    color: #4b4b4b;
    margin-bottom: -1vh;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10%;
}


.text {
    display: flex;
    flex-direction: column;
    text-align: center;
}

</style>