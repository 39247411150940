var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100 scroll-y" },
    [
      _vm.isLoading
        ? _c(
            "div",
            [
              _c("ProgressCircular", {
                staticClass: "pt-10",
                attrs: { color: _vm.currency.unit },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !_vm.address
        ? _c("v-container", [
            _vm.address === null || !_vm.addressId
              ? _c(
                  "div",
                  [
                    _c("AddressNotFoundInfo", {
                      staticClass: "my-2",
                      attrs: {
                        address: _vm.addressId,
                        message:
                          "Provided identifier does not exist in the blockchain!",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && _vm.address
        ? _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c(
                "LimitAbleLayout",
                {
                  attrs: { "init-values": _vm.filterInitValues },
                  on: { "reload-data": _vm.reloadData },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("h1", [_vm._v("Timelines Graphs")]),
                  ]),
                  _vm._v(" "),
                  _c("template", { staticClass: "white", slot: "body" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "v-container",
                          { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
                          [
                            _vm.addressBalanceLoading
                              ? _c("ProgressCircular", {
                                  staticClass: "pt-10",
                                  attrs: { color: _vm.currency.unit },
                                })
                              : !_vm.addressBalanceLoading &&
                                _vm.balance.length === 0
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center pt-10",
                                      staticStyle: {
                                        height: "80%",
                                        color: "rgba(0,0,0,0.6)",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "title" }, [
                                        _vm._v(
                                          "No data matched provided filter!"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _c("HighCharts", {
                                  staticStyle: { "user-select": "none" },
                                  attrs: { options: _vm.chartOptions },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }