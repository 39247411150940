var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100 scroll-y" },
    [
      _vm.addressLoading
        ? _c(
            "div",
            [
              _c("ProgressCircular", {
                staticClass: "pt-10",
                attrs: { color: _vm.currency.unit },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.addressLoading && !_vm.addressInfo
        ? _c("v-container", [
            _vm.addressInfo === null || !_vm.addressId
              ? _c(
                  "div",
                  [
                    _c("AddressNotFoundInfo", {
                      staticClass: "my-2",
                      attrs: {
                        address: _vm.addressId,
                        message:
                          "Provided identifier does not exist in the blockchain!",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.addressLoading && _vm.addressInfo
        ? _c(
            "v-container",
            { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
            [
              _c(
                "LimitAbleLayout",
                {
                  attrs: { "init-values": _vm.filterInitValues },
                  on: { "reload-data": _vm.reloadData },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("h1", [_vm._v("Time Patterns")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "body" },
                    [
                      !_vm.addressTxsOverallLoading &&
                      _vm.addressTxsOverallYears.length < 1
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center justify-center pt-10",
                                staticStyle: {
                                  height: "80%",
                                  color: "rgba(0,0,0,0.6)",
                                },
                              },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("No data matched provided filter!"),
                                ]),
                              ]
                            ),
                          ])
                        : _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "8" } },
                                [
                                  _c("ChartHours", {
                                    attrs: {
                                      hoursR:
                                        _vm.addressTxsOverallHours.receiving,
                                      hoursS:
                                        _vm.addressTxsOverallHours.sending,
                                      isLoading: _vm.addressTxsOverallLoading,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "4" } },
                                [
                                  _c("ChartDays", {
                                    attrs: {
                                      daysR:
                                        _vm.addressTxsOverallDays.receiving,
                                      daysS: _vm.addressTxsOverallDays.sending,
                                      isLoading: _vm.addressTxsOverallLoading,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "8" } },
                                [
                                  _c("ChartMonths", {
                                    attrs: {
                                      monthsR:
                                        _vm.addressTxsOverallMonths.receiving,
                                      monthsS:
                                        _vm.addressTxsOverallMonths.sending,
                                      isLoading: _vm.addressTxsOverallLoading,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "4" } },
                                [
                                  _c("ChartYears", {
                                    attrs: {
                                      years: _vm.addressTxsOverallYears,
                                      yearsR: _vm.returnYearsReceiving,
                                      yearsS: _vm.returnYearsSending,
                                      isLoading: _vm.addressTxsOverallLoading,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }