var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.address
    ? _c("i", { staticClass: "textGrey" }, [_vm._v("\n    Unavailable\n")])
    : _c(
        _vm.actAsLink ? "router-link" : "span",
        {
          tag: "router-link",
          staticClass: "fontMonospace",
          class: _vm.actAsLink && "link",
          style: { textDecoration: "none", maxWidth: "100%" },
          attrs: { to: { path: _vm.targetUrl } },
        },
        [
          _vm._t("text", function () {
            return [_vm._v("\n        " + _vm._s(_vm.address) + "\n    ")]
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }