<template>
    <ValidationProvider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
        <v-select :error="error" :disabled="disabled" :value="value" @input="valueChange" :error-messages="errors" v-bind="$attrs">
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        </v-select>
    </ValidationProvider>
</template>

<script>

export default {
    name: "VSelectValidateable",
    props: {
        rules: {
            type: [Object, String],
            default: ""
        },
        value: {
            type: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        innerValue: ""
    }),
    methods: {
        valueChange(newVal) {
            this.$emit('input', newVal)
        }
    },
    created() {
        if (this.value) {
            this.$emit('input', this.value)
        }
    }
};
</script>
