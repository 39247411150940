import {computed, isRef, ref, watch} from "vue";

import {DataState} from "@/components/common/types/data";

/*
TODO:

 - add filtering
 - add sorting

 */
export const useGetDataStatic = (data, initPagination) => {

    if (!isRef(data)) {
        console.error("useGetDataStatic: Please provide DATA as ref")
    }

    const dataState = computed(() => {
        if (!data.value) {
            return DataState.Loading
        }
        return DataState.Loaded
    })

    const fakeData = ref({})

    const fakeGetData = ({pagination: {page, itemsPerPage}}) => {
        if (!data.value) {
            fakeData.value = {
                total: 0,
                data: []
            }
            return
        }
        const starting_index = (page - 1) * itemsPerPage
        fakeData.value = {
            page: page,
            itemsPerPage: itemsPerPage,
            total: data.value.length,
            data: data.value.slice(starting_index, starting_index + itemsPerPage)
        }
    }

    watch([dataState, data], () => {
        if (dataState.value === DataState.Loaded) {
            fakeGetData(initPagination)
        }
    })

    return {dataState: dataState, data: fakeData, getData: fakeGetData}
}


// current_page	1
// data	[ {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, … ]
// first_page_url	"http://coinomon.test/jwt/v1/BTC/addresses?page=1"
// from	1
// last_page	500900
// last_page_url	"http://coinomon.test/jwt/v1/BTC/addresses?page=500900"
// links	[ {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, … ]
// next_page_url	"http://coinomon.test/jwt/v1/BTC/addresses?page=2"
// path	"http://coinomon.test/jwt/v1/BTC/addresses"
// per_page	"30"
// prev_page_url	null
// to	30
// total	15026979
// message	"Address metadata list obtained."