<template>
    <BaseModalDialog
        :title="isEditModal ? 'Edit Owner' : 'Create new Owner'"
        @load-modal-state-event="loadState"
        :loading="loading"
        width="30%"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-row>
                    <v-col>
                        <v-text-field-validateable
                            rules="required"
                            v-model="detail.name"
                            label="Owner"
                            outlined
                            dense
                        />
                    </v-col>
                </v-row>
            </ValidationObserver>
        </template>
        <template #footer="{close, setDisabled}">
            <v-btn
                dark
                outlined
                color="error"
                :disabled="ownersModalState === modalState.Pending"
                @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                dark
                color="success"
                :loading="ownersModalState === modalState.Pending"
                outlined
                @click="save(close, setDisabled)"
            >{{ isEditModal ? "Save Owner" : "Create Owner" }}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import {deepClone} from "@/utils"
import { BaseModalDialog } from "@/components/common"
import {mapGetters} from "vuex";
import {modalState} from "@/components/common/types/modal";

export default {
    name: "OwnerDetailModal",
    components: {
        BaseModalDialog,
    },
    props: {
        ownerDetail: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            detail: undefined,
            isEditModal: true,
            closeDialog: null,
            setDisabled: null,
        }
    },
    computed: {
        ...mapGetters(["ownersModalState"]),
        modalState() {
            return modalState
        }
    },
    watch: {
        ownersModalState(newVal) {
            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('OWNERS_MODAL_SET_STATE', modalState.Initial)
            } else if (newVal === modalState.Error)
            {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('OWNERS_MODAL_SET_STATE', modalState.Initial)
            }
        }
    },
    methods: {
        async save(close, setDisabled) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.$emit("save-event", { ownerDetail: this.detail })
                this.closeDialog = close
                this.setDisabled = setDisabled
                setDisabled(true)
            }
        },
        loadState() {
            if (this.ownerDetail) {
                this.isEditModal = true
                this.detail = deepClone(this.ownerDetail)
            }
            else {
                this.isEditModal = false
                this.detail = {
                    name: ""
                }
            }
        }
    }
}
</script>
