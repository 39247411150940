var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.ExpandPanels,
    { attrs: { expanded: "" } },
    [
      _c(
        _setup.ExpandPanel,
        {
          attrs: {
            icon: _vm.icon,
            "number-of-results": _setup.props.resourceTypeItem.items.length,
            title: _vm.title,
            "show-as-result": "",
          },
        },
        [
          _vm._l(_setup.items, function (resource, index) {
            return _c(
              _setup.ExpandPanels,
              { key: index, staticClass: "searchExpandPanel" },
              [
                _c(
                  _setup.ExpandPanel,
                  {
                    attrs: {
                      "number-of-results": resource.totalMatches,
                      padding: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "link fontMonospace",
                                      attrs: {
                                        to: _vm.getLocationFromResource(
                                          resource.resource
                                        ),
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            resource.resource.displayName
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(" "),
                    _vm._l(resource.needleMatches, function (needleMatchGroup) {
                      return _c(
                        "div",
                        [
                          needleMatchGroup.itemTypes === "Address"
                            ? _c(_setup.NeedleTypeAddress, {
                                attrs: { data: needleMatchGroup },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _setup.totalPages > 1
            ? _c("div", { staticClass: "mx-2 px-2" }, [
                _c(
                  "div",
                  { staticClass: "text-center py-1 position-relative" },
                  [
                    _c("v-pagination", {
                      attrs: {
                        length: _setup.totalPages,
                        circle: "",
                        color: "tertiary",
                      },
                      model: {
                        value: _setup.page,
                        callback: function ($$v) {
                          _setup.page = $$v
                        },
                        expression: "page",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }