var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("v-checkbox", {
        staticClass: "mr-4 ma-0 pa-0",
        staticStyle: { "padding-right": "37px !important" },
        attrs: {
          error: _vm.error,
          dense: "",
          "hide-details": "",
          label: "Inputs",
          readonly: _vm.readonly,
          color: _vm.color,
        },
        model: {
          value: _vm.inputs,
          callback: function ($$v) {
            _vm.inputs = $$v
          },
          expression: "inputs",
        },
      }),
      _vm._v(" "),
      _c("v-checkbox", {
        staticClass: "ma-0 pa-0",
        attrs: {
          error: _vm.error,
          dense: "",
          "hide-details": "",
          color: _vm.color,
          label: "Outputs",
          readonly: _vm.readonly,
        },
        model: {
          value: _vm.outputs,
          callback: function ($$v) {
            _vm.outputs = $$v
          },
          expression: "outputs",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }