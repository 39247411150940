const state = {
    fiats: [], // [{unit: USD}, {unit: EUR}]
    currentFiat: undefined,
    fiatActive: false
}

const getters = {
    fiats: (state) => state.fiats,
    currentFiat: (state) => state.currentFiat,
    fiatActive: (state) => state.fiatActive,
}

const mutations = {
    SET_FIATS(state, data) {
        state.fiats = data
    },
    SET_CURRENT_FIAT(state, data) {
        state.currentFiat = data
    },
    SET_FIAT_ACTIVE(state, data) {
        state.fiatActive = data
    },
}

export default {
    state,
    mutations,
    getters
}