var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isCopyable || _vm.showExtra || _vm.actAsLink
    ? _c("Copyable", {
        attrs: {
          isCopyable: _vm.isCopyable,
          showExtra: _vm.showExtra,
          actAsLink: _vm.actAsLink,
          "on-copy": _vm.entity,
          justify: _vm.justify,
        },
        on: {
          "extra-click-event": function ($event) {
            return _vm.onMenuOpen($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "text",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center",
                      class: `justify-${_vm.justify}`,
                      style: _vm.fixedWidth && { width: "230px" },
                    },
                    [
                      _vm.isAddress
                        ? _c("routeToAddress", {
                            attrs: {
                              actAsLink: _vm.actAsLink,
                              address: _vm.entity,
                              currency: _vm.currency,
                              hasLabel: !!_vm.label,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "text",
                                  fn: function () {
                                    return [
                                      _c("address-detailable", {
                                        ref: "addressInfo",
                                        attrs: { address: _vm.entity },
                                      }),
                                      _vm._v(" "),
                                      _vm.label && _vm.showEntityLabel
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticStyle: {
                                                    "text-decoration":
                                                      "none !important",
                                                  },
                                                  attrs: {
                                                    small: "",
                                                    color: _vm.computedColor,
                                                    label: "",
                                                    outlined: "",
                                                    link: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-truncate",
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                " +
                                                          _vm._s(_vm.label) +
                                                          "\n                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "fontMonospace text-truncate",
                                            },
                                            [
                                              _c("nobr", [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "stringMiddleShortener"
                                                      )(_vm.entity, 26)
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1451731323
                            ),
                          })
                        : _c("RouteToCluster", {
                            attrs: {
                              cluster: _vm.entity,
                              currency: _vm.currency,
                              hasLabel: !!_vm.label,
                              actAsLink: _vm.actAsLink,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "text",
                                  fn: function () {
                                    return [
                                      _vm.label
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticStyle: {
                                                    "text-decoration":
                                                      "none !important",
                                                  },
                                                  attrs: {
                                                    small: "",
                                                    color: _vm.computedColor,
                                                    label: "",
                                                    outlined: "",
                                                    link: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-truncate",
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                " +
                                                          _vm._s(_vm.label) +
                                                          "\n                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "fontMonospace text-truncate link",
                                            },
                                            [
                                              _c("nobr", [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "stringMiddleShortener"
                                                      )(_vm.entity, 26)
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3470808750
                            ),
                          }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3433712471
        ),
      })
    : _c(
        "div",
        { staticClass: "fontMonospace text-truncate" },
        [
          _c("nobr", [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("stringMiddleShortener")(_vm.entity, 26)) +
                "\n    "
            ),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }