<script setup>
import {BaseModalDialog} from "@/components/common";
import ImportButton from "@/components/common/Buttons/ImportButton.vue";
import AddressImporter from "@/views/Addresses/Dialogs/AddressImporter.vue";
import {ref} from "vue";
import {ImportFormat} from "@/components/common/types/import";
import DataProvider from "@/components/common/DataProvider.vue";

const props = defineProps({
    identitiesHeaders: {
        type: Array,
        required: false,
        default: undefined
    },
    caseId: {
        type: Number,
        required: false,
        default: undefined
    },
    defaultRowValues: {
        type: Object,
        required: false,
        default: undefined
    },
    actionName: {
        type: String,
        required: false,
        default: "importAddressMetadataBulk"
    },
    importFormat: {
        type: Number,
        required: false,
        default: ImportFormat.Standard
    }
})


const resetState = () => {
    addressImporterRef.value.tableItems.value = []
}

const modalRef = ref(null)
const addressImporterRef = ref(null)

const enableModal = () => modalRef.value.setDisabled(false)
const closeModal = () => modalRef.value.close()

const handleImportFinish = (successfulRequests) => {
    enableModal()

    if (successfulRequests) {
        emit("addressesImported")
    }
}

const onImport = async () => await addressImporterRef.value.onImport(props.caseId)

const emit = defineEmits(["addressesImported"])
</script>

<template>
    <div>
        <BaseModalDialog
            ref="modalRef"
            title="Address Importer"
            width="1200px"
            @destroy-modal-state-event="resetState">
            <template #content>
                <DataProvider name="importFormat" :data="importFormat">
                    <AddressImporter ref="addressImporterRef" :action-name="actionName"
                                     :default-row-values="defaultRowValues"
                                     @import-finished="handleImportFinish" @close-modal="closeModal"
                                     :identities-headers="identitiesHeaders"/>
                </DataProvider>
            </template>
            <template #footer="{close, setDisabled}">
                <v-btn :disabled="addressImporterRef?.loading" small dark color="primary" @click="close">
                    Close
                </v-btn>
                <v-spacer/>
                <ImportButton :loading="addressImporterRef?.loading" @click="() => {onImport(); setDisabled(true)}"
                              :disabled="addressImporterRef?.tableItems?.length === 0"/>
            </template>
        </BaseModalDialog>
        <ImportButton @click="modalRef.open()" class="mr-1"/>
    </div>
</template>

