<template>
    <NotificationMethodBase>
        <template #iconName="{ on }">
            <v-icon v-on="on" color="light-blue">
                mdi-message-outline
            </v-icon>
        </template>
        <template #tooltip>
            Notification
        </template>
    </NotificationMethodBase>
</template>

<script>
import NotificationMethodBase from "@/components/alarm/NotificationMethodBase";
export default {
    name: "NotificationRest",
    components: {NotificationMethodBase}
}
</script>

<style scoped>

</style>