var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "border my-2 maxWidth850 mx-auto",
      attrs: { elevation: "0" },
    },
    [
      _vm.data.type === "address"
        ? [_c("AddressResult", { attrs: { data: _vm.data } })]
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === "transaction"
        ? [_c("TransactionResult", { attrs: { data: _vm.data } })]
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === "block"
        ? [_c("BlockResult", { attrs: { data: _vm.data } })]
        : _vm.data.type === "identity"
        ? [_c("IdentityResult", { attrs: { data: _vm.data } })]
        : _vm.data.type === "owner"
        ? [_c("OwnerResult", { attrs: { data: _vm.data } })]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }