export interface Owner {
    id: number;
    name: string;
}

export interface Category {
    id: number;
    name: string;
}

export interface Address {
    id?: number;
    address: string;

}

export interface ClusterMetadataDetailServerModel {
    id: number;
    entity_id: string;
    crypto: string;
    description: string;
    addresses_count: number;
    owners: Owner[];
    ownerIds: number[];
    categoryIds: number[];
    categories: Category[];
    addresses: Address[];
}

export interface ClusterMetadataDetailViewModel {
    id: number;
    entity_id: string;
    crypto: string;
    owners: Owner[];
    categories: Category[];
    categoryIds: number[];
    description: string;
    addresses_count: number;
    addresses: Address[];
}

export interface CryptoClustersLimited {
    entityid: string;
    addresses: number;
    isCustom: boolean;
}

export interface NeoClusterLimited {
    cluster: string;
    totalAddresses: number;
    isCustom: boolean;
}

export interface CustomClusterLimited {
    entity_id: string;
    addresses_count: number;
    type: string
}

function MapToServerModel(model: ClusterMetadataDetailViewModel): ClusterMetadataDetailServerModel {
    let addresses = Array<Address>()

    //Create ownerIds parameter
    let ownerIds = model.owners ? model.owners.map(item => item.id) : [];

    //TODO: Primary owner_id parameter

    //Handling of addresses
    for (const {address} of model.addresses) {
        const addressMetadata = addresses.find(i => i.address == address)
        if (addresses.push({address})) {
            addressMetadata
        }
    }
    //strip data
    const {entity_id, description, crypto, categoryIds, ...restOfModel} = model

    return {entity_id, description, crypto, addresses, ownerIds, categoryIds} as ClusterMetadataDetailServerModel
}

function MapToViewModel(model: ClusterMetadataDetailServerModel): ClusterMetadataDetailViewModel {
    let categoryIds = model.categories.map(item => item.id)

    return {...model, categoryIds} as ClusterMetadataDetailViewModel
}

function MapToCustomClusterFromCryptoClusters(cryptoClustersLimited: CryptoClustersLimited[]): CustomClusterLimited[] {
    return cryptoClustersLimited.map(c => {
        return {
            entity_id: c.entityid,
            addresses_count: c.addresses,
            type: c.isCustom ? "custom" : "co-spent"
        }
    })
}

function MapToCustomClusterFromNeoClusters(cryptoClustersLimited: NeoClusterLimited[]): CustomClusterLimited[] {
    return cryptoClustersLimited.map(c => {
        return {
            entity_id: c.cluster,
            addresses_count: c.totalAddresses,
            type: c.isCustom ? "custom" : "co-spent"
        }
    })
}

export default {
    MapToServerModel,
    MapToViewModel,
    MapToCustomClusterFromCryptoClusters,
    MapToCustomClusterFromNeoClusters
}