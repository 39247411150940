var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: {
      title: _vm.isEditModal
        ? "Edit Cluster Metadata"
        : "Create new Cluster Metadata",
      loading: _vm.loading,
      width: "60%",
    },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c("fancy-card", {
                    attrs: { "no-shadow": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "font-weight-medium" }, [
                              _vm._v(
                                "\n                        Cluster Metadata\n                    "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c(
                              "v-row",
                              { staticClass: "ma-2" },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "py-2" },
                                  [
                                    _c("v-text-field-validateable", {
                                      attrs: {
                                        readonly: _vm.isEditModal,
                                        rules: "required|entity_id",
                                        label: "Entity ID",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.detail.entity_id,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.detail, "entity_id", $$v)
                                        },
                                        expression: "detail.entity_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { staticClass: "py-2" },
                                  [
                                    _c("BlockchainCryptoPicker", {
                                      attrs: {
                                        readonly: _vm.isEditModal,
                                        currencies: _vm.currencies,
                                        rules: "required",
                                      },
                                      model: {
                                        value: _vm.detail.crypto,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.detail, "crypto", $$v)
                                        },
                                        expression: "detail.crypto",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { staticClass: "py-2" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        disabled: true,
                                        label: "Created At",
                                        value: _vm._f("moment")(
                                          _vm.detail.created_at &&
                                            _vm.detail.created_at,
                                          "YYYY-MM-DD HH:mm:ss Z"
                                        ),
                                        outlined: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { staticClass: "ma-2" },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "py-2" },
                                  [
                                    _c("CategoryPicker", {
                                      attrs: {
                                        categories: _vm.categories,
                                        dense: "",
                                      },
                                      model: {
                                        value: _vm.detail.categoryIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detail,
                                            "categoryIds",
                                            $$v
                                          )
                                        },
                                        expression: "detail.categoryIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { staticClass: "py-2" },
                                  [
                                    _c("OwnerPickerWithDisplay", {
                                      model: {
                                        value: _vm.detail.owners,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.detail, "owners", $$v)
                                        },
                                        expression: "detail.owners",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { staticClass: "ma-2" },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "py-2" },
                                  [
                                    _c("v-text-area-validateable", {
                                      attrs: {
                                        enabled: !_vm.isEditModal,
                                        rules: "required",
                                        label: "Description",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.detail.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detail,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "detail.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("fancy-card", {
                    staticClass: "mt-2",
                    attrs: {
                      "no-shadow": "",
                      outlined: false,
                      header: false,
                      elevation: 0,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c(
                              "v-row",
                              { staticClass: "ma-2" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "7" } },
                                  [
                                    _c("CsvImporterInput", {
                                      attrs: {
                                        csvConfiguration:
                                          _vm.importCsvConfiguration,
                                      },
                                      on: {
                                        "import-successful":
                                          _vm.importAddresses,
                                        "import-failed":
                                          _vm.importAddressesFailed,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { align: "center" } },
                                  [
                                    _vm.corruptedData.length > 0
                                      ? _c("CorruptedDataModalDialog", {
                                          attrs: {
                                            corruptedData: _vm.corruptedData,
                                          },
                                          on: {
                                            "close-event":
                                              _vm.closeCorruptedDataDialog,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "showbutton",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: "error",
                                                            outlined: "",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "Show corrupted data"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2621415191
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { justify: "end" } },
                                      [
                                        _c("CreateButton", {
                                          attrs: { text: "Add new Addresses" },
                                          on: { click: _vm.addNewAddresses },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.newAddresses.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("v-data-table", {
                                          staticStyle: {
                                            "white-space": "nowrap",
                                          },
                                          attrs: {
                                            headers: _vm.addressHeaders,
                                            items: _vm.newAddresses,
                                            options: _vm.newAddressesOptions,
                                            dense: "",
                                            "footer-props": {
                                              "items-per-page-options": [
                                                10, 50, 200,
                                              ],
                                              "items-per-page-text":
                                                "Addresses per page",
                                              "show-current-page": true,
                                              showFirstLastPage: true,
                                            },
                                          },
                                          on: {
                                            "update:options": function (
                                              $event
                                            ) {
                                              _vm.newAddressesOptions = $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function (props) {
                                                  return [
                                                    !_vm.isAddressDeleted(
                                                      props.item
                                                    ) || _vm.newModal
                                                      ? _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "pt-1",
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field-validateable",
                                                                {
                                                                  staticClass:
                                                                    "fontMonospace",
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    rules:
                                                                      "required|crypto",
                                                                    label:
                                                                      "Address",
                                                                    "full-width":
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      props.item
                                                                        .address,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          props.item,
                                                                          "address",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "props.item.address",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "td",
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                            attrs,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-2",
                                                                                      attrs:
                                                                                        {
                                                                                          "x-small":
                                                                                            "",
                                                                                          icon: "",
                                                                                          color:
                                                                                            "red",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.deleteAddressFromNew(
                                                                                              props.index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-delete"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Delete Address"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "footer.page-text",
                                                fn: function ({
                                                  pageStart,
                                                  pageStop,
                                                  itemsLength,
                                                }) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatNumber"
                                                            )(pageStart)
                                                          ) +
                                                          " - " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatNumber"
                                                            )(pageStop)
                                                          ) +
                                                          " of " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatNumber"
                                                            )(itemsLength)
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4012579767
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider", { staticClass: "my-1" }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.detail.total !== 0
                    ? _c(
                        "div",
                        [
                          _c("fancy-card", {
                            staticClass: "mt-2",
                            attrs: { "no-shadow": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "subtitle-1",
                                          staticStyle: {
                                            color: "rgba(0,0,0,0.6)",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      _vm.detail.total
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            " Addresses total\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _c("v-data-table", {
                                        staticStyle: {
                                          "white-space": "nowrap",
                                        },
                                        attrs: {
                                          dense: "",
                                          headers: _vm.addressHeaders,
                                          items: _vm.detail.addresses || [],
                                          "server-items-length":
                                            _vm.detail.total,
                                          "footer-props": {
                                            "items-per-page-options": [
                                              10, 50, 200,
                                            ],
                                            "items-per-page-text":
                                              "Addresses per page",
                                            "show-current-page": true,
                                            showFirstLastPage: true,
                                          },
                                          loading: _vm.addressesLoading,
                                          options: _vm.options,
                                        },
                                        on: {
                                          "update:options": function ($event) {
                                            _vm.options = $event
                                          },
                                          "update:page": _vm.fetchAddresses,
                                          "update:items-per-page":
                                            _vm.fetchAddresses,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function (props) {
                                                return [
                                                  !_vm.isAddressDeleted(
                                                    props.item
                                                  ) || _vm.newModal
                                                    ? _c("tr", [
                                                        _c("td", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "fontMonospace",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                        " +
                                                                  _vm._s(
                                                                    props.item
                                                                      .address
                                                                  ) +
                                                                  "\n                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        !_vm.newModal
                                                          ? _c(
                                                              "td",
                                                              [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                              attrs,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "ml-2",
                                                                                        attrs:
                                                                                          {
                                                                                            "x-small":
                                                                                              "",
                                                                                            icon: "",
                                                                                            color:
                                                                                              "red",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.deleteAddress(
                                                                                                props.item
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      "v-btn",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-delete"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Delete Address"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ])
                                                    : _c(
                                                        "tr",
                                                        {
                                                          staticStyle: {
                                                            "background-color":
                                                              "lightgrey",
                                                          },
                                                        },
                                                        [
                                                          _c("td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "fontMonospace",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                        " +
                                                                    _vm._s(
                                                                      props.item
                                                                        .address
                                                                    ) +
                                                                    "\n                                    "
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "td",
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                            attrs,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-2",
                                                                                      attrs:
                                                                                        {
                                                                                          "x-small":
                                                                                            "",
                                                                                          icon: "",
                                                                                          color:
                                                                                            "green",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.undeleteAddress(
                                                                                              props.item
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-undo"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Restore Address"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "footer.page-text",
                                              fn: function ({
                                                pageStart,
                                                pageStop,
                                                itemsLength,
                                              }) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatNumber"
                                                          )(pageStart)
                                                        ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatNumber"
                                                          )(pageStop)
                                                        ) +
                                                        " of " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatNumber"
                                                          )(itemsLength)
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          541593664
                                        ),
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1079969831
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    outlined: "",
                    color: "error",
                    disabled:
                      _vm.clusterMetadataModalState === _vm.modalState.Pending,
                  },
                  on: { click: close },
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "success",
                    loading:
                      _vm.clusterMetadataModalState === _vm.modalState.Pending,
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save(close, setDisabled)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isEditModal
                        ? "Save Cluster Metadata"
                        : "Create Cluster Metadata"
                    )
                  ),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }