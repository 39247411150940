var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.unitOnly
    ? _c("span", [
        !_vm.fiatActive
          ? _c("span", [_vm._v("\n        " + _vm._s(_vm.currency.unit))])
          : _c(
              "span",
              {
                class: {
                  "font-weight-light": _vm.unitLight,
                  unitSmallFont: _vm.unitSmall,
                },
              },
              [_vm._v(_vm._s(_vm.currentFiat.unit))]
            ),
      ])
    : !_vm.fiatActive
    ? _c("span", [
        _vm.convertFromSatoshi
          ? _c("span", { class: _vm.monospace ? "fontMonospace" : "" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$humanize.formatNumber(
                      _vm.computedValue / 100000000,
                      _vm.cryptoDecPlaces
                    )
                  )
              ),
            ])
          : _c("span", { class: _vm.monospace ? "fontMonospace" : "" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$humanize.formatNumber(
                      _vm.computedValue,
                      _vm.cryptoDecPlaces
                    )
                  )
              ),
            ]),
        _vm._v(" "),
        _vm.withUnit
          ? _c(
              "span",
              {
                class: {
                  "font-weight-light": _vm.unitLight,
                  unitSmallFont: _vm.unitSmall,
                },
              },
              [_vm._v(_vm._s(_vm.fiats.crypto))]
            )
          : _vm._e(),
      ])
    : _c("span", [
        _c("span", { class: _vm.monospace ? "fontMonospace" : "" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$humanize.formatNumber(
                  _vm.calculatedFiat,
                  _vm.fiatDecPlaces
                )
              )
          ),
        ]),
        _vm._v(" "),
        _vm.withUnit
          ? _c(
              "span",
              {
                class: {
                  "font-weight-light": _vm.unitLight,
                  unitSmallFont: _vm.unitSmall,
                },
              },
              [_vm._v(_vm._s(_vm.currentFiat.unit))]
            )
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }