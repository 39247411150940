var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "router-link",
              {
                staticStyle: { "text-decoration": "none" },
                attrs: { to: { name: "MonitoringNodes" } },
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "grey darken-1",
                      height: "24",
                      outlined: "",
                      small: "",
                    },
                  },
                  [
                    _vm._v("\n                    Nodes\n                    "),
                    _c("v-icon", { attrs: { right: "", small: "" } }, [
                      _vm._v(
                        "\n                        mdi-eye\n                    "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "nobr",
        [
          _c("v-data-table", {
            staticClass: "elevation-0 border mb-2",
            attrs: {
              "footer-props": {
                "items-per-page-options": [30, 50, 100],
                "show-current-page": true,
                showFirstLastPage: true,
              },
              headers: _vm.headers,
              items: _vm.cryptoInterceptorFilesData
                ? _vm.cryptoInterceptorFilesData.data
                : [],
              loading: _vm.cryptoInterceptorFilesLoading,
              "loading-text": "Loading files ...",
              options: _vm.filter,
              "server-items-length": _vm.cryptoInterceptorFilesData
                ? _vm.cryptoInterceptorFilesData.total
                : 0,
              dense: "",
            },
            on: {
              "update:options": function ($event) {
                _vm.filter = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (props) {
                  return [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: { width: "200px" },
                        },
                        [
                          !props.item.is_complete
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pulsate",
                                    staticStyle: {
                                      color: "green",
                                      "font-weight": "500",
                                    },
                                  },
                                  [_vm._v("ACTIVE!")]
                                ),
                              ])
                            : _c(
                                "div",
                                { staticStyle: { "font-weight": "500" } },
                                [
                                  _vm._v(
                                    "\n                            COMPLETE\n                        "
                                  ),
                                ]
                              ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-overflow text-left" }, [
                        _vm._v(" " + _vm._s(props.item.filename)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-overflow text-left fontMonospace",
                        },
                        [_vm._v(" " + _vm._s(props.item.ip))]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-overflow" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDateTime")(props.item.created_at)
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatNumber")(props.item.size / 1000, 2)
                            ) +
                            " KB"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-oveflow" }, [
                        _c("div", { staticClass: "d-flex justify-center" }, [
                          _c(
                            "div",
                            [
                              _c("ButtonWrapperHoverTooltip", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "active",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "a",
                                            {
                                              staticStyle: {
                                                "text-decoration": "none",
                                              },
                                              attrs: {
                                                href: _vm.urlToFile(props.item),
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      small: "",
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "blue" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    mdi-download\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "tooltip",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n                                        Download\n                                    "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "alt",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "", small: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "\n                                                mdi-download\n                                            "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("TableDeleteModalDialog", {
                                attrs: {
                                  disabled: !props.item.is_complete,
                                  "item-to-delete": props.item,
                                  fancy: "",
                                  tooltip: "Delete",
                                },
                                on: { "delete-event": _vm.deleteFile },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "footer.page-text",
                fn: function ({ pageStart, pageStop, itemsLength }) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("formatNumber")(pageStart)) +
                          " - " +
                          _vm._s(_vm._f("formatNumber")(pageStop)) +
                          " of " +
                          _vm._s(_vm._f("formatNumber")(itemsLength)) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("h3", [_vm._v("Files")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }