var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-autocomplete", {
    attrs: {
      items: _setup.icons,
      "return-object": "",
      "item-text": "name",
      value: _setup.selectedIcon,
      clearable: "",
      "clear-icon": "mdi-close-circle",
      label: "Icon",
      filter: _setup.filterIcons,
    },
    on: { input: _setup.emitIconName },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ item }) {
          return [
            _c("v-icon", { attrs: { left: "" } }, [_vm._v(_vm._s(item.icon))]),
            _vm._v("\n        " + _vm._s(item.name) + "\n    "),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c("v-icon", { attrs: { left: "" } }, [_vm._v(_vm._s(item.icon))]),
            _vm._v("\n        " + _vm._s(item.name) + "\n    "),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }