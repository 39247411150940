export interface NeoClusterMetadataDetailServerModel {
    cluster: string;
    ownerIds: number[],
    categoryIds: number[],
}

export interface NeoClusterMetadataDetailViewModel {
    cluster: string;
    ownerIds: number[],
    categoryIds: number[],
}

function MapToServerModel(model): NeoClusterMetadataDetailServerModel {
    return {
        cluster: model.cluster,
        ownerIds: model.owners.map(item => item.id),
        categoryIds: model.categories.map(cat => typeof cat === "number" ? cat : cat.id),
    } as NeoClusterMetadataDetailServerModel
}

function MapToViewModel(model: any, crypto: string): any {
    let newData = model.data.map(x => {
        return ({...x, crypto: crypto, entity_id: x.cluster, addresses_count: x.totalAddresses})
    })
    return {...model, data: newData}
}

function MapToViewModelDetail(model: any): NeoClusterMetadataDetailViewModel {
    let categoryIds = model.categories.map(item => item.id)


    return {...model, categoryIds} as NeoClusterMetadataDetailViewModel
}

export default {MapToServerModel, MapToViewModelDetail, MapToViewModel}