var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.NameValueFlex, {
    attrs: {
      loading: _vm.loading,
      "loading-width": "100",
      "name-width": "200",
    },
    scopedSlots: _vm._u([
      {
        key: "name",
        fn: function () {
          return [
            _vm._v("\n        " + _vm._s(_vm.subject) + " Involvement:\n    "),
          ]
        },
        proxy: true,
      },
      {
        key: "value",
        fn: function () {
          return [
            _c("span", { staticClass: "fontMonospace" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.hasData ? "Yes" : "No") +
                  "\n        "
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }