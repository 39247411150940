enum FilterStringSubtype {
    Addresses,
    Transactions,
    Clusters
}

enum FilterType {
    Numeric,
    Strings,
    Categories,
}

export {FilterType, FilterStringSubtype};