var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    ref: "baseModal",
    attrs: { title: "Create graph from transactions" },
    on: { "load-modal-state-event": _vm.loadState },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c("v-text-field-validateable", {
                    attrs: {
                      label: "Name",
                      rules: "required|min:2",
                      outlined: "",
                    },
                    model: {
                      value: _vm.detail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "name", $$v)
                      },
                      expression: "detail.name",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-textarea-validateable", {
                    attrs: {
                      label: "Description",
                      rules: "max:255",
                      "no-resize": "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.detail.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "description", $$v)
                      },
                      expression: "detail.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("IsHiddenCheckboxWithIcon", {
                model: {
                  value: _vm.detail.is_hidden,
                  callback: function ($$v) {
                    _vm.$set(_vm.detail, "is_hidden", $$v)
                  },
                  expression: "detail.is_hidden",
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close }) {
            return [
              _c(
                "v-btn",
                { attrs: { color: "error", text: "" }, on: { click: close } },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.createNewGraph(close)
                    },
                  },
                },
                [_vm._v("Create graph")]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }