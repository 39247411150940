var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseModalDialog, {
    ref: "modalRef",
    attrs: { title: _vm.modalTitle, width: "20%", persistent: false },
    scopedSlots: _vm._u([
      {
        key: "footer",
        fn: function ({ close }) {
          return [
            _c("v-btn", { attrs: { small: "" }, on: { click: close } }, [
              _vm._v("Cancel"),
            ]),
            _vm._v(" "),
            _c("v-spacer"),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: { color: "success", small: "" },
                on: { click: _setup.handleConfirm },
              },
              [_vm._v("Confirm")]
            ),
          ]
        },
      },
      {
        key: "content",
        fn: function () {
          return [_vm._v("\n        " + _vm._s(_vm.contentText) + "\n    ")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }