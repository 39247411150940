import Vue from 'vue'
import store from './store'
import router from './router'
import App from './components/App'
import vuetify from './plugins/vuetify';
import * as veeValidate from './plugins/validations'

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'typeface-roboto-mono/index.css'

import axios from 'axios'
import {cacheAdapterEnhancer} from 'axios-extensions';
import markRequestAsCacheableAdapter from "./axios-adapters/markRequestAsCacheableAdapter";
import dataMockAdapter from "./axios-adapters/dataMockAdapter";

import VueClipboard from 'vue-clipboard2'
import VueCytoscape from 'vue-cytoscape'
import VueMoment from 'vue-moment'
import Humanize from 'humanize-plus'
import filters from "@/utils/filters";
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import 'leaflet-heatmap/leaflet-heatmap'
import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/css/solid.css"
import "@fortawesome/fontawesome-free/css/regular.css"

const logEnabled = false;
const isDebug = process.env.APP_DEBUG;


const cacheAdapter = cacheAdapterEnhancer(axios.defaults.adapter);
//todo FE this will be moved to apiClient.ts
// new apiClient.ts does not support this caching, use cacheAdapterEnhancer or write own
const httpClient = isDebug
    ? axios.create({
        headers: { 'Cache-Control': 'no-cache' },
        // cache will be enabled by default
        // mocking is enabled for dev only
        adapter: dataMockAdapter(markRequestAsCacheableAdapter(cacheAdapter, {
            wildcardsToCache: ["cryptoaddress", "addr", "transaction", "cluster", "block", "logs"],
            logEnabled: logEnabled
        }))

    })
    : axios.create({
        headers: { 'Cache-Control': 'no-cache' },
        // cache will be enabled by default
        adapter: markRequestAsCacheableAdapter(cacheAdapter, {
            wildcardsToCache: ["cryptoaddress", "addr", "transaction", "cluster", "block", "logs"],
            logEnabled: logEnabled
        })
    });

httpClient.CancelToken = axios.CancelToken;
httpClient.isCancel = axios.isCancel;
// todo FE - redesign usage of interceptors, if is request canceled, does not have response ("undefined")
// this coud be removed when all API call will be provided from apiClient.js
/* Vue.axios.interceptors.response.use(null, (error) => {
    //todo if is request canceled, response is undefined
    if (error.response == undefined) {
        //this.$store.dispatch('error', "request was canceled")
    } else if (error.response.status === 401) {
        store.dispatch('error', "You don't have permission to view this page.")
    }
    else {
        store.dispatch('error', error.response.data)
    }
}) */


Vue.use(VueMoment);
Vue.use(VueCytoscape);
Vue.use(VueClipboard);
veeValidate.apply(Vue)
filters.apply(Vue)

//todo FE convert humanize usage to vue filter
Vue.prototype.$humanize = Humanize;

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
    vuetify,
    el: '#app',
    router,
    store,
    components: {App},
    template: '<App/>'
});

if (Array.prototype.forEachAsync == null) {
    Array.prototype.forEachAsync = function forEachAsync(fn, thisArg, maxTimePerChunk, callback) {
        let that = this;
        let args = Array.from(arguments);

        let lastArg = args.pop();

        if (lastArg instanceof Function) {
            callback = lastArg;
            lastArg = args.pop();
        } else {
            callback = function () {
            };
        }
        if (Number(lastArg) === lastArg) {
            maxTimePerChunk = lastArg;
            lastArg = args.pop();
        } else {
            maxTimePerChunk = 200;
        }
        if (args.length === 1) {
            thisArg = lastArg;
        } else {
            thisArg = that
        }

        let index = 0;

        function now() {
            return new Date().getTime();
        }

        function doChunk() {
            let startTime = now();
            while (index < that.length && (now() - startTime) <= maxTimePerChunk) {
                // callback called with args (value, index, array)
                fn.call(thisArg, that[index], index, that);
                ++index;
            }
            if (index < that.length) {
                // set Timeout for async iteration
                setTimeout(doChunk, 1);
            } else {
                callback();
            }
        }

        doChunk();
    }
}
