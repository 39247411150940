var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "bottomDrawer", staticClass: "cont" },
    [
      _c("div", { staticClass: "handler", on: { mousedown: _vm.mouseDown } }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }