<template>
    <v-container class="px-4" fluid>
        <v-layout align-center>
            <h1>Identities</h1>
            <v-spacer/>
            <IdentityDetailModal
                @save-event="createIdentity"
                @address-filter-changed-event="addressFilterChanged"
                :addresses="filteredAddresses"
                :isAddressesLoading="isFilteredAddressesLoading"
            >
                <template #showbutton="{ on }">
                    <CreateButton v-on="on" text="Add new identity"/>
                </template>
            </IdentityDetailModal>
        </v-layout>
        <v-divider/>
        <v-divider/>
        <v-data-table
            :headers="headers"
            :items="identities.data"
            :options.sync="filter"
            :server-items-length="identities.total ? identities.total : 0"
            :loading="isIdentitiesLoading"
            :footer-props="{
                'items-per-page-options': [15, 30, 50, 100],
                'show-current-page': true,
                'showFirstLastPage': true,
            }"
            class="elevation-0 border my-2"
            dense
        >
            <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'label'"
                    append-icon="search"
                    :label="header.text"
                    single-line
                    hide-details
                    clearable
                    @click.stop.native
                    @input="debounceInput"
                    v-model="debouncedFilter.filterLabel"
                    style="padding-bottom: 18px;"
                    class="text-field-label-style"
                />
                <div
                    :key="h.value"
                    v-if="h.value === 'crypto'"
                    style="margin-bottom: auto"
                >
                    <BlockchainCryptoPickerSlim
                        :currencies="allCurrenciesExceptUnknown"
                        @click.stop.native
                        v-model="filter.filterCurrency"
                        dense
                    />
                </div>
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'source'"
                    append-icon="search"
                    :label="header.text"
                    single-line
                    hide-details
                    clearable
                    @click.stop.native
                    @input="debounceInput"
                    v-model="debouncedFilter.filterSource"
                    class="text-field-label-style"
                />
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'url'"
                    append-icon="search"
                    :label="header.text"
                    single-line
                    hide-details
                    clearable
                    @click.stop.native
                    @input="debounceInput"
                    v-model="debouncedFilter.filterUrl"
                    class="text-field-label-style"
                />
                <v-text-field
                    :key="h.value"
                    v-if="h.value === 'address'"
                    append-icon="search"
                    :label="header.text"
                    single-line
                    hide-details
                    clearable
                    @click.stop.native
                    @input="debounceInput"
                    v-model="debouncedFilter.filterAddress"
                    style="padding-bottom: 18px;"
                    class="text-field-label-style"
                />
                <div
                    :key="h.value"
                    v-if="h.value === 'created_at'"
                    style="padding-top: 20px; margin-bottom: auto; padding-bottom: auto"
                >
                    Created At
                </div>

                <div
                    :key="h.value"
                    v-if="h.value === 'blank'"
                    style="padding-top: 20px; margin-bottom: auto; padding-bottom: 19px;"
                >
                    {{ h.text }}
                </div>
            </template>
            <template #item="{ item }">
                <tr>
                    <td class="text-left text-truncate">
                        <span>{{ item.label }}</span>
                    </td>
                    <td class="text-center">
                        <BlockchainCryptoBadge :iconWidth="24" :currencies="currencies" :crypto="item.address.crypto"/>
                    </td>
                    <td class="text-left text-truncate">
                        <span>{{ item.source }}</span>
                    </td>
                    <td class="text-truncate text-left" style="max-width: 200px;">
                        <a class="link" :href="item.url">{{ item.url }}</a>
                    </td>
                    <td class="dynamicTruncate" style="white-space: nowrap">
                        <RouteToAddress :currency="filter.filterCurrency" :address="item.address.address">
                            <template slot="text">
                                <Copyable justify="left" :on-copy="item.address.address">
                                    <template #text="{ on }">
                                        <div v-on="on">
                                            {{ item.address.address | stringMiddleShortener(26) }}
                                        </div>
                                    </template>
                                </Copyable>
                            </template>
                        </RouteToAddress>
                    </td>
                    <td style="text-align: center; vertical-align: middle; white-space: nowrap">
                        <span>{{ item.created_at | formatDateTime }}</span>
                    </td>

                    <td width="10%" style="min-width: 92px; text-align: center; vertical-align: middle">
                        <IdentityDetailModal
                            @save-event="editIdentity"
                            :identityDetail="item"
                            @address-filter-changed-event="addressFilterChanged"
                            :addresses="filteredAddresses.data"
                            :isAddressesLoading="isFilteredAddressesLoading"
                        >
                            <template #showbutton="{ on }">
                                <TableEditButton v-on="on" editButtonTooltip="Edit identity"/>
                            </template>
                        </IdentityDetailModal>
                        <TableDeleteModalDialog
                            :description="`Do you really want to delete identity ${item.label}`"
                            :itemToDelete="item"
                            @delete-event="deleteIdentity"
                            tooltip="Delete Identity"
                        />
                    </td>
                </tr>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                <span>
                    {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                </span>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex"
import {BlockchainCryptoBadge, CreateButton, TableDeleteModalDialog, TableEditButton} from "@/components/common"
import IdentityDetailModal from "@/components/AddressMetadata/IdentityDetailModal"
import debounce from "debounce";
import Copyable from "@/components/common/Copyable";
import BlockchainCryptoPickerSlim from "@/components/common/BlockchainCryptoPickerSlim";
import routeSyncMixin from "@/components/Mixins/routeSyncMixin";
import RouteToAddress from "@/components/common/RouteToAddress";
import {useGetActiveCurrencyOrDefault} from "@/components/Composables/useGetActiveCurrencyOrDefault";


export default {
    name: "IdentitiesView",
    mixins: [routeSyncMixin],
    components: {
        RouteToAddress,
        Copyable,
        BlockchainCryptoPickerSlim,
        TableEditButton,
        TableDeleteModalDialog,
        CreateButton,
        IdentityDetailModal,
        BlockchainCryptoBadge,
    },
    computed: {
        ...mapGetters([
            "identities",
            "isIdentitiesLoading",
            "filteredAddresses",
            "isFilteredAddressesLoading",
            "allCurrenciesExceptUnknown",
            "currency",
            "currencies"
        ])
    },
    data() {
        return {
            filterInfo: {
                page: {type: "Number", default: () => 1},
                itemsPerPage: {type: "Number", default: () => 30},
                sortBy: {type: "ArrayOfStrings", default: () => ['created_at']},
                sortDesc: {type: "ArrayOfBooleans", default: () => [true]},
                mustSort: {type: "Boolean", default: () => false},
                multiSort: {type: "Boolean", default: () => false},
                filterLabel: {type: "String", default: () => ''},
                filterSource: {type: "String", default: () => ''},
                filterUrl: {type: "String", default: () => ''},
                filterAddress: {type: "String", default: () => ''},
                filterCurrency: {type: "String", default: () => ''}
            },
            debouncedFilter: {
                filterLabel: undefined,
                filterSource: undefined,
                filterUrl: undefined,
                filterAddress: undefined,
            },
            headers: [
                {text: 'Label', value: 'label', sortable: false},
                {text: 'Currency', align: 'center', value: 'crypto', sortable: false, width: 130},
                {text: 'Source', value: 'source', align: 'center'},
                {text: 'Url', value: 'url', align: 'center'},
                {text: 'Address', value: 'address', sortable: false},
                {text: 'Created At', value: 'created_at', align: 'center'},
                {text: 'Action', value: 'blank', sortable: false, align: 'center'}
            ]
        }
    },
    watch: {
        '$route.query.filterCurrency': {
            handler(newValue)
            {
                if (!newValue) {
                    this.$_syncToRouteQuery(true, {...this.$route.query, filterCurrency: useGetActiveCurrencyOrDefault(this.$route)})
                }
            }
        }
    },
    created() {
        this.$_syncToRouteQuery(true, {...this.$route.query, filterCurrency: useGetActiveCurrencyOrDefault(this.$route)})
    },
    methods: {
        syncFilterToDebouncedFilter() {
            Object.keys(this.debouncedFilter).forEach(key => {
                this.debouncedFilter[key] = this.filter[key]
            })
        },
        onFilterInitial() {
            this.syncFilterToDebouncedFilter()
        },
        onFilterChange() {
            this.syncFilterToDebouncedFilter()
            this.refresh()
        },
        onFilterLoaded() {
            this.refresh()
        },
        debounceInput: debounce(function () {
            this.filter = {...this.filter, ...this.debouncedFilter}
        }, 450),
        async deleteIdentity({itemToDelete}) {
            await this.$store.dispatch('deleteIdentity', {
                currency: this.filter.filterCurrency,
                identityId: itemToDelete.id
            })
            this.refresh()
        },
        async editIdentity({identityDetail}) {
            await this.$store.dispatch("editIdentity", {currency: this.filter.filterCurrency, identityDetail})
            this.refresh()
        },
        async createIdentity({identityDetail}) {
            await this.$store.dispatch("createIdentity", {currency: this.filter.filterCurrency, identityDetail})
            this.refresh()
        },
        addressFilterChanged({filter}) {
            this.$store.dispatch('loadFilteredAddresses', {currency: this.filter.filterCurrency, filter})
        },
        refresh() {
            if(this.filter.filterCurrency) {
                this.$store.dispatch('loadIdentities', {currency: this.filter.filterCurrency, pagination: this.filter})
            }
        }
    }
}
</script>