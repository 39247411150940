var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { staticClass: "px-2 py-1", attrs: { row: "" } },
        [
          _c("b", [_vm._v("Transaction")]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "text-overflow text-decoration-none",
              attrs: {
                to:
                  "/" +
                  _vm.data.crypto +
                  "/transaction/" +
                  _vm.data.txhash +
                  "/",
              },
            },
            [
              _c("img", {
                staticClass: "currencyImg mx-2",
                attrs: {
                  src: _vm._f("createImageUrl")(
                    "cryptoIcons/color/" +
                      _vm.data.crypto.toLowerCase() +
                      ".svg"
                  ),
                },
              }),
              _vm._v(" "),
              _c("code", { staticClass: "elevation-0" }, [
                _vm._v(_vm._s(_vm.data.txhash)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("div", { staticClass: "no-wrap pl-2" }, [
            _vm._v(
              _vm._s(_vm._f("moment")(_vm.data.time, "YYYY-MM-DD HH:mm:ss Z"))
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-layout",
        {
          staticClass: "mx-2 pb-1",
          attrs: { "justify-space-between": "", row: "", wrap: "" },
        },
        [
          _c(
            "span",
            [
              _c("b", [_vm._v("Total input:")]),
              _vm._v(" "),
              _c("TransactionPrice", {
                attrs: {
                  exchangePrice: _vm.data.exchangePrice,
                  value: _vm.data.sumOfInputValues,
                  currency: _vm.data.crypto,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.data.isCoinbase
            ? _c(
                "span",
                [
                  _c("b", [_vm._v("Fee:")]),
                  _vm._v(" "),
                  _c("TransactionPrice", {
                    attrs: {
                      exchangePrice: _vm.data.exchangePrice,
                      value: _vm.data.sumOfFeeValues,
                      currency: _vm.data.crypto,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            [
              _c("b", [_vm._v("Total output:")]),
              _vm._v(" "),
              _c("TransactionPrice", {
                attrs: {
                  exchangePrice: _vm.data.exchangePrice,
                  value: _vm.data.sumOfOutputValues - _vm.data.sumOfFeeValues,
                  currency: _vm.data.crypto,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }