var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100" },
    [
      _c(
        "h1",
        [
          _vm._v("Blocks\n        "),
          _c(
            "TitleHelper",
            {
              attrs: {
                loading: _vm.titleTotalNumberOfBlocks ? false : _vm.loading,
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.titleTotalNumberOfBlocks) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mb-3" }),
      _vm._v(" "),
      _c(
        "nobr",
        [
          _c(
            "v-card",
            {
              staticClass: "border data-table-container",
              attrs: { elevation: "0" },
            },
            [
              _c("v-data-table", {
                staticClass: "border",
                attrs: {
                  headers: _vm.headers,
                  dense: "",
                  items: _vm.blocksData ? _vm.blocksData.data : [],
                  "server-items-length": _vm.blocksData
                    ? _vm.blocksData.total
                    : 0,
                  loading: _vm.loading,
                  "loading-text": "Loading blocks...",
                  "footer-props": {
                    "items-per-page-options": [30, 50, 200],
                    "items-per-page-text": "Blocks per page",
                    "show-current-page": true,
                    showFirstLastPage: true,
                  },
                  options: _vm.filter,
                },
                on: {
                  "update:options": function ($event) {
                    _vm.filter = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function (props) {
                      return [
                        _c("tr", [
                          _c(
                            "td",
                            { staticClass: "tabledatatd fontMonospace" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatNumber")(props.item.height)
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "tabledatatd fontMonospace" },
                            [
                              _c("Copyable", {
                                attrs: { "on-copy": props.item.hash },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "text",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "link",
                                              attrs: {
                                                to:
                                                  "/" +
                                                  _vm.currency.unit +
                                                  "/block/" +
                                                  props.item.hash,
                                              },
                                            },
                                            [
                                              _c("span", _vm._g({}, on), [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(props.item.hash) +
                                                    "\n                                        "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", { staticClass: "tabledatatd" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatDateTime")(props.item.time * 1000)
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end fontMonospace" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatNumber")(props.item.txlength)
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end fontMonospace" }, [
                            _vm._v(_vm._s(_vm._f("fileSize")(props.item.size))),
                          ]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "footer.page-text",
                    fn: function ({ pageStart, pageStop, itemsLength }) {
                      return [
                        _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm._f("formatNumber")(pageStart)) +
                              " - " +
                              _vm._s(_vm._f("formatNumber")(pageStop)) +
                              " of " +
                              _vm._s(_vm._f("formatNumber")(itemsLength)) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }