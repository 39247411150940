<template>
    <div>
        <PeerDetailModal
            ref="peerDetailModalRef"
            :currency="currency.unit"
            :loading="peerDetailLoading"
            :peer-detail="peerDetail"
            :peer-id="peerDetailId"
        />
        <div v-if="isLoading || !transactionInfo">
            <ProgressCircular :color="currency.unit" class="pt-10"/>
        </div>
        <div v-else class="Transaction">
            <FancyCard :header="false" no-gutters outlined>
                <template #body>
                    <nobr>
                        <div class="TxHeader d-flex pl-2"
                             @click="showDetail = !showDetail">
                            <v-row no-gutters style="width: 45%">
                                <v-col class="d-flex justify-right align-center addressTitle" cols="12" lg="3">
                                    <div>
                                        <RouteToTransaction :currency="currency.unit"
                                                            :transaction="transactionInfo.txhash" no-decoration>
                                            <template slot="text">
                                                <Copyable :on-copy="transactionInfo.txhash">
                                                    <template #text="{on, attr}">
                                                        <div v-bind="attr" v-on="on">
                                                        <span class="fontMonospace">
                                                            {{ transactionInfo.txhash | stringMiddleShortener(20) }}
                                                        </span>
                                                        </div>
                                                    </template>
                                                </Copyable>
                                            </template>
                                        </RouteToTransaction>
                                    </div>
                                </v-col>
                                <v-col class="d-flex justify-space-around align-center" cols="12" lg="4" offset="1">
                                    <div class="d-flex justify-center align-center" style="width: 250px; height: 100%">
                                        <div class="d-flex justify-center align-center"
                                             style="min-width: 24px; max-width: 24px; height: 100%">
                                            <v-tooltip v-if="openedLightningNetworkChannel" top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon left color="#ffc001" v-on="on">
                                                        mdi-flash
                                                    </v-icon>
                                                </template>
                                                This transaction opened a lightning network channel.
                                            </v-tooltip>
                                        </div>
                                        <div class="d-flex justify-center align-center"
                                             style="min-width: 24px; max-width: 60px; height: 100%">
                                            <v-tooltip v-if="category" open-delay="500" top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div class="tagContainer"
                                                         style="display: grid; filter: drop-shadow(-1px 4px 4px rgba(50, 50, 0, 0.5));"
                                                         v-on="on">
                                                        <div :style="'background-color: ' + category.color"
                                                             class="tag layer2">
                                                        </div>
                                                        <div class="layer1">
                                                            <v-icon
                                                                :color="category.secondaryColor"
                                                                style="font-size: 20px; margin-left: 2px; margin-top: 4px;">
                                                                {{ icon }}
                                                            </v-icon>
                                                        </div>
                                                    </div>
                                                </template>
                                                {{ category.name }}
                                            </v-tooltip>
                                        </div>
                                        <BaseButtonShowMoreItems
                                            :items="categoriesForShowMoreButton"
                                            :shownItemsCount="0"
                                        >
                                            <AddressCategoryIcon
                                                v-for="cat in categoriesForShowMoreButton"
                                                :key="cat.id"
                                                :color="cat.color"
                                                :iconItem="cat"
                                                :name="cat.name"
                                                class="mx-1"
                                                medium
                                            />
                                        </BaseButtonShowMoreItems>
                                        <div class="d-flex justify-center align-center my-1" style="width: 38px">
                                            <v-tooltip open-delay="500" top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-if="transactionInfo.txPropagations.length > 0"
                                                         class="d-flex justify-center align-center"
                                                         v-on="on">
                                                        <v-icon>
                                                            mdi-web
                                                        </v-icon>
                                                    </div>
                                                </template>
                                                Tx relayers available
                                            </v-tooltip>
                                        </div>
                                        <div class="d-flex justify-center align-center my-1" style="width: 50px">
                                            <v-tooltip open-delay="500" top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-if="transactionInfo.casesCount > 0"
                                                         class="d-flex justify-center align-center"
                                                         v-on="on">
                                                        <v-icon style="margin-right: 4px">assignment</v-icon>
                                                        {{ transactionInfo.casesCount }}
                                                    </div>
                                                </template>
                                                {{
                                                    `${transactionInfo.casesCount} ${transactionInfo.casesCount === 1 ? "case" : "cases"}`
                                                }}
                                            </v-tooltip>
                                        </div>
                                        <div class="d-flex justify-center align-center my-1" style="width: 50px">
                                            <v-tooltip open-delay="500" top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-if="transactionInfo.identityCount > 0"
                                                         class="d-flex justify-center align-center"
                                                         v-on="on">
                                                        <img alt="Identity" src="/img/ico/name-id-icon.svg"
                                                             style="width: 26px; margin-right: 4px; filter: opacity(0.6);"/>
                                                        {{ transactionInfo.identityCount }}
                                                    </div>
                                                </template>
                                                {{
                                                    `${transactionInfo.identityCount} ${transactionInfo.identityCount === 1 ? "identity" : "identities"}`
                                                }}
                                            </v-tooltip>
                                        </div>
                                        <div class="d-flex justify-center align-center my-1" style="width: 50px">
                                            <v-tooltip open-delay="500" top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-if="transactionInfo.ownersCount > 0"
                                                         class="d-flex justify-center align-center"
                                                         v-on="on">
                                                        <img alt="Owner" src="/img/ico/owner-icon.svg"
                                                             style="width: 22px; margin-right: 4px; filter: opacity(0.6);"/>
                                                        {{ transactionInfo.ownersCount }}
                                                    </div>
                                                </template>
                                                {{
                                                    `${transactionInfo.ownersCount} ${transactionInfo.ownersCount === 1 ? "owner" : "owners"}`
                                                }}
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col class="d-flex align-center justify-end addressTitle font-weight-bold" cols="12"
                                       lg="3"
                                       offset-lg="1">
                                    <v-tooltip open-delay="500" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-if="transaction.currentAddressBalance"
                                                 :class="{ fontMonospace: true, 'success--text': transaction.currentAddressBalance < 0, 'error--text': transaction.currentAddressBalance > 0 }"
                                                 v-on="on">
                                                {{
                                                    transaction.currentAddressBalance < 0 ? '+' : transaction.currentAddressBalance > 0 ? '-' : ''
                                                }}
                                                <fiat-convertor :fiats="transactionInfo.fiats"
                                                                :value="transaction.currentAddressBalance"
                                                                absoluteValue with-unit/>
                                            </div>
                                        </template>
                                        Overall balance change of the address
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <div class="d-flex align-center justify-center"
                                 style="min-width: 135px; width: 10%; filter: opacity(0.6);">
                                <div class="text-center title font-weight-bold fontMonospace d-flex"
                                     style="width: 150px;">
                                    <div style="width: 50%; text-align: end">
                                        {{ transactionInfo.inputsCount }}
                                    </div>
                                    <div>
                                        &nbsp;:&nbsp;
                                    </div>
                                    <div style="width: 50%; text-align: start">
                                        {{ transactionInfo.outputsCount }}
                                    </div>
                                </div>
                            </div>
                            <v-row no-gutters style="width: 45%">
                                <v-col class="d-flex justify-end align-center" cols="12" lg="3" offset-lg="1">
                                    <v-tooltip open-delay="500" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="fontMonospace addressTitle" v-on="on">
                                                <fiat-convertor
                                                    :fiats="transactionInfo.fiats"
                                                    :value="transactionInfo.sumOfOutputValues + transactionInfo.sumOfFeeValues"
                                                    with-unit/>
                                            </div>
                                        </template>
                                        Total output value of the transaction including fee
                                    </v-tooltip>
                                </v-col>
                                <v-col class="d-flex justify-end align-center" cols="12" lg="3">
                                    <v-tooltip open-delay="500" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="fontMonospace addressTitle" v-on="on">
                                                <fiat-convertor :fiats="transactionInfo.fiats"
                                                                :value="transactionInfo.sumOfFeeValues" with-unit/>
                                            </div>
                                        </template>
                                        Fee value
                                    </v-tooltip>
                                </v-col>
                                <v-col class="d-flex justify-end align-center" cols="12" lg="4" offset="1">
                                    <v-row no-gutters>
                                        <v-tooltip open-delay="500" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-col class="d-flex align-center" cols="10" v-on="on">
                                                    {{ transactionInfo.blockTime | moment('YYYY-MM-DD HH:mm:ss Z') }}
                                                </v-col>
                                            </template>
                                            Transaction timestamp
                                        </v-tooltip>
                                        <v-col class="d-flex align-center" cols="2">
                                            <TransactionShowOnGraph :txhash="transactionInfo.txhash"/>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </nobr>
                    <v-expand-transition>
                        <div v-if="showDetail">
                            <div class="d-flex justify-space-between pa-1">
                                <div style="width: 48%">
                                    <TransactionInputTable :address="transaction.byAddress"
                                                           :data="transactionInfo.inputs"
                                                           :fiats="transactionInfo.fiats"
                                                           class="my-1"/>
                                </div>
                                <div style="width: 48%">
                                    <TransactionOutputTable :address="transaction.byAddress"
                                                            :data="transactionInfo.outputs"
                                                            :fiats="transactionInfo.fiats"
                                                            :txType="currency.typeFamily"
                                                            class="my-1"/>
                                </div>
                            </div>
                            <TransactionSankeyDiagram :transaction-info="transactionInfo">
                                <template #headerRight>
                                    <div class="d-flex" style="gap: 12px">
                                        <div style="opacity: 0.8">Block Height:</div>
                                        <RouteToBlock :block-height="transactionInfo.blockHeight"
                                                      block-not-mined-message="This transaction has not been confirmed yet."/>
                                    </div>
                                </template>
                            </TransactionSankeyDiagram>
                            <div v-if="transactionInfo.txPropagations.length > 0" class="d-flex TxFooter">
                                <div
                                    class="d-flex justify-center align-center flex-grow-0 flex-shrink-0 font-weight-bold"
                                    style="width: 125px; color: rgba(0,0,0,0.6)">
                                    RELAYERS:
                                </div>
                                <v-row v-if="transactionInfo.txPropagations.length > 0" class="rounded fontMonospace"
                                       justify="space-around" no-gutters>
                                    <v-col v-for="(txPropagation, index) in transactionInfo.txPropagations"
                                           :key="index"
                                           class="d-flex justify-center align-center height100"
                                           cols="4"
                                    >
                                        <span class="d-inline-block mr-2" style="font-weight: 500">
                                            {{ index + 1 }}:
                                        </span>
                                        <v-tooltip :disabled="txPropagation.node.id !== Number($route.query?.id)" top>
                                            <template #activator="{ on }">
                                                <v-chip
                                                    class="link"
                                                    label outlined
                                                    style="height: 20px; max-width: 220px;"
                                                    @click="openPeerDialog(txPropagation.node.id)"
                                                    v-on="on">
                                                    {{ txPropagation.node.host }}
                                                </v-chip>
                                            </template>
                                            <span>You're here.</span>
                                        </v-tooltip>
                                        <span v-if="index !== 0" class="font-italic">
                                            &nbsp;+{{
                                                (Date.parse(txPropagation.received_at) - Date.parse(transactionInfo.txPropagations[0].received_at)) / 1000
                                            }}s
                                        </span>
                                        <span v-else class="font-italic ml-3" style="white-space: nowrap">
                                            {{ txPropagation.received_at | moment('YYYY-MM-DD HH:mm:ss Z') }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-expand-transition>
                </template>
            </FancyCard>
        </div>
    </div>
</template>

<script>
import TransactionPrice from "@/components/transaction/TransactionPrice";
import TransactionShowOnGraph from "@/components/transaction/TransactionShowOnGraph";
import ProgressCircular from "@/components/common/ProgressCircular";
import RouteToTransaction from "@/components/common/RouteToTransaction";
import FiatConvertor from "@/components/common/FiatConvertor";
import Copyable from "@/components/common/Copyable";
import FancyCard from "@/components/common/Cards/FancyCard";
import TransactionOutputTable from "@/components/transaction/Components/TransactionOutputTable";
import {mapGetters} from "vuex";
import AddressCategoryIcon from "@/components/address/AddressCategoryIcon";
import TransactionInputTable from "@/components/transaction/Components/TransactionInputTable";
import BaseButtonShowMoreItems from "@/components/common/TableComponents/BaseButtonShowMoreItems";
import PeerDetailModal from "@/components/peers/PeerDetailModal.vue";
import TransactionSankeyDiagram from "@/components/transaction/Components/TransactionSankeyDiagram.vue";
import RouteToBlock from "@/components/common/RouteToBlock.vue";

export default {
    name: "TransactionSingle",
    props: {
        transaction: {
            type: Object,
            default: null
        },
        expanded: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    components: {
        RouteToBlock,
        TransactionSankeyDiagram,
        PeerDetailModal,
        BaseButtonShowMoreItems,
        TransactionInputTable,
        AddressCategoryIcon,
        TransactionOutputTable,
        FancyCard,
        Copyable,
        FiatConvertor,
        RouteToTransaction,
        TransactionPrice,
        TransactionShowOnGraph,
        ProgressCircular
    },
    mounted() {
        if (this.$route.params.txhash !== undefined) {
            this.$store.dispatch("loadTransaction", {id: this.$route.params.txhash});
        }
    },
    computed: {
        ...mapGetters(["categories", "currency", "peerDetailLoading", "peerDetail"]),
        openedLightningNetworkChannel() {
            return this.transactionInfo.outputs.some(o => o.is_lightning_network_channel_opener)
        },
        categoriesForShowMoreButton() {
            return this.transactionInfo.categories.filter(cat => cat.id !== this.category?.id)
        },
        icon() {
            return this.$options.filters.getIconString(this.category)
        },
        category() {
            if (!this.transactionInfo)
                return undefined

            let cat = undefined
            this.categories.forEach(x => {
                if (x.id === this.transactionInfo?.category?.id)
                    cat = x
            })
            return cat
        },
        isLoading() {
            return this.$store.getters.transactionLoading;
        },
        currency() {
            return this.$store.getters.currency;
        },
        transactionInfo() {
            return this.transaction || this.$store.getters.transactionInfo;
        }
    },
    methods: {
        openPeerDialog(peerId) {
            this.$router.push({query: {id: peerId}})
            this.peerDetailId = peerId
            this.$store.dispatch("loadPeerDetail", {peerId: peerId, currency: this.currency.unit})
            this.$refs.peerDetailModalRef.open()
        }
    },
    data() {
        return {
            showDetail: false,
            showMoreInputs: false,
            showMoreOutputs: false,
            txPropHeaders: [
                {
                    text: 'Order',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'IP',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Received at',
                    align: 'center',
                    sortable: false,
                },
            ],
            peerDetailId: undefined,
        };
    },
    watch: {
        expanded: {
            handler(newVal) {
                this.showDetail = newVal
            },
            immediate: true
        }
    }
};
</script>

<style scoped>
.tagContainer {
    width: 24px;
    height: 100%;
}

.layer1, .layer2 {
    grid-column: 1;
    grid-row: 1;
}

.tag {
    height: 100%;
    width: 100%;
    clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%, 50% 80%);
}

.TxHeader {
    border-radius: 4px;
    cursor: pointer;
    height: 36px;
    background-color: #EEEEEE;
    color: #454545;
    transition: background-color 0.3s ease;
}

.TxHeader:hover {
    background-color: #F8F8F8;
    transition: background-color 0.3s ease;
}

@media (max-width: 1264px) {
    .TxHeader {
        height: 72px;
    }
}

.TxFooter {
    background-color: rgb(240, 240, 240);
    height: 28px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.addressTitle {
    font-size: 16px;
}

.graphContainer .v-icon {
    bottom: 2px;
    margin: 6px 0px
}

</style>

<style>
.Transaction .theme--light.v-data-table {
    background-color: initial;
}

.categories-count-container .v-chip:before {
    background-color: #ffffff00;
}

.Transaction > .v-card {
    border: thin solid rgba(0, 0, 0, 0.3) !important;
}

/*changes background color temporarily, used when clicking a transaction in sankey diagram*/
@-o-keyframes fadeIt {
    0% {
        background-color: #bdbcd780;
    }
    50% {
        background-color: #FFFFFF00;
    }
    100% {
        background-color: #FFFFFF00;
    }
}

@keyframes fadeIt {
    0% {
        background-color: #bdbcd780;
    }
    50% {
        background-color: #FFFFFF00;
    }
    100% {
        background-color: #FFFFFF00;
    }
}

/*is added dynamically to inputs or outputs via a interaction with the sankey diagram */
.tempHighlight {
    background-image: none !important;
    -o-animation: fadeIt 5s ease-out;
    animation: fadeIt 5s ease-out;
}


</style>