import apiClient from "@/services/apiClient";
import axios from "axios";

const state = {
    addressTxsOverallDays: [],
    addressTxsOverallHours: [],
    addressTxsOverallMonths: [],
    addressTxsOverallYears: [],
    addressTxsOverallYearsReceiving: [],
    addressTxsOverallYearsSending: [],
    addressTxsOverallLoading: false,
    addressTxsCancelToken: null
};

const getters = {
    addressTxsOverallDays: (state) => state.addressTxsOverallDays,
    addressTxsOverallHours: (state) => state.addressTxsOverallHours,
    addressTxsOverallMonths: (state) => state.addressTxsOverallMonths,
    addressTxsOverallYears: (state) => state.addressTxsOverallYears,
    addressTxsOverallYearsReceiving: (state) =>
        state.addressTxsOverallYearsReceiving,
    addressTxsOverallYearsSending: (state) =>
        state.addressTxsOverallYearsSending,
    addressTxsOverallLoading: (state) => state.addressTxsOverallLoading,
};

const mutations = {
    ADDRESS_TXS_OVERALL_LOADED(state, data) {
        (state.addressTxsOverallDays = data.days),
            (state.addressTxsOverallHours = data.hours),
            (state.addressTxsOverallMonths = data.months),
            (state.addressTxsOverallYears = Object.keys(data.years.receiving)),
            (state.addressTxsOverallYearsReceiving = data.years.receiving),
            (state.addressTxsOverallYearsSending = data.years.sending);
    },
    ADDRESS_TXS_OVERALL_LOADING(state) {
        if(state.addressTxsCancelToken) {
            state.addressTxsCancelToken.cancel()
        }
        state.addressTxsCancelToken = axios.CancelToken.source()
        state.addressTxsOverallDays = [];
        state.addressTxsOverallHours = [];
        state.addressTxsOverallMonths = [];
        state.addressTxsOverallYears = [];
        state.addressTxsOverallYearsReceiving = [];
        state.addressTxsOverallYearsSending = [];
        state.addressTxsOverallLoading = true;
    },
    ADDRESS_TXS_OVERALL_LOADING_FINISHED(state) {
        state.addressTxsOverallLoading = false
    }
};

const actions = {
    async loadTxsOverall({ commit, dispatch }, { currency, addressId, filter }) {
        commit("ADDRESS_TXS_OVERALL_LOADING");
        try {
            const result = await apiClient.GET(
                `${currency}/cryptoaddress/${addressId}/patterns`,
                {
                    params: {
                        ...filter,
                    },
                    cancelToken: state.addressTxsCancelToken.token
                }
            );
            commit("ADDRESS_TXS_OVERALL_LOADED", result);
            commit("ADDRESS_TXS_OVERALL_LOADING_FINISHED")
        } catch (error) {
            if(axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                this.dispatch("error", error.userFriendlyMessage)
                commit("ADDRESS_TXS_OVERALL_LOADING_FINISHED")
            }
        }
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
