var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { staticClass: "px-2 py-1", attrs: { row: "" } },
        [
          _c("b", [_vm._v("Address")]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "text-overflow ml-2 text-decoration-none",
              attrs: {
                to: "/" + _vm.data.crypto + "/address/" + _vm.data.address,
              },
            },
            [
              _c("img", {
                staticClass: "currencyImg mx-2",
                attrs: {
                  src: _vm._f("createImageUrl")(
                    "cryptoIcons/color/" +
                      _vm.data.crypto.toLowerCase() +
                      ".svg"
                  ),
                },
              }),
              _vm._v(" "),
              _vm.data.identities.length > 0
                ? _c("v-tooltip", {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "code",
                                _vm._g({ staticClass: "elevation-0" }, on),
                                [_vm._v(_vm._s(_vm.data.identities[0].label))]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.data.address))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3315534758
                    ),
                  })
                : _c("code", { staticClass: "elevation-0" }, [
                    _vm._v(_vm._s(_vm.data.addr)),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.data.categories, function (cat) {
            return _c("addressCategory", {
              key: cat.id,
              staticClass: "pl-1",
              attrs: { iconItem: cat, name: cat.name, color: cat.color },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _vm.data.cases.length > 0
        ? [
            _c("v-divider"),
            _vm._v(" "),
            _c(
              "v-list-item",
              [
                _c("v-list-item-action", { staticClass: "font-weight-bold" }, [
                  _vm._v("Cases:"),
                ]),
                _vm._v(" "),
                _c(
                  "v-list-item-content",
                  _vm._l(_vm.data.cases, function (caseDetail) {
                    return _c(
                      "v-btn",
                      {
                        key: caseDetail.id,
                        staticClass: "pointer",
                        attrs: {
                          color: "secondary",
                          to: "/case/" + caseDetail.id,
                          outlined: "",
                          label: "",
                        },
                      },
                      [
                        _c("span", { staticClass: "text-initial" }, [
                          _vm._v(_vm._s(caseDetail.title)),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-divider"),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-layout",
        {
          staticClass: "mx-2 pb-1",
          attrs: { "justify-space-between": "", row: "", wrap: "" },
        },
        [
          _c("span", [
            _c("b", [_vm._v("Balance:")]),
            _vm._v(
              "\n            " +
                _vm._s(_vm.data.confirmedBalance) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _c("b", [_vm._v("Transactions:")]),
            _vm._v(
              "\n            " +
                _vm._s(_vm.data.confirmedTransactions) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _c("b", [_vm._v("Received:")]),
            _vm._v(
              "\n            " + _vm._s(_vm.data.totalReceived) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _c("b", [_vm._v("Sent:")]),
            _vm._v(
              "\n            " + _vm._s(_vm.data.totalSent) + "\n        "
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }