var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticStyle: { height: "calc(100vh - 68px)" },
      attrs: { permanent: "", right: "", width: "100%" },
    },
    [
      _vm.graphIsLoading
        ? _c(
            "v-list-item-title",
            { staticClass: "title" },
            [_c("ProgressCircular", { staticClass: "py-12" })],
            1
          )
        : _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "title" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-space-between" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex text-truncate",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("mdi-chart-timeline-variant"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-truncate" }, [
                              _vm._v(_vm._s(_vm.graphData.name)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: _vm.graphData.is_hidden
                                                  ? "primary"
                                                  : "tertiary",
                                                dark: "",
                                                right: "",
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.graphData.is_hidden
                                                  ? "visibility_off"
                                                  : "visibility"
                                              ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "Graph is " +
                                      _vm._s(
                                        _vm.graphData.is_hidden
                                          ? "visible only to the creator."
                                          : "visible to all users."
                                      )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("GraphDetailModal", {
                          attrs: {
                            currencies: _vm.currencies,
                            graphDetail: _vm.graphData,
                          },
                          on: { "save-event": _vm.editGraph },
                          scopedSlots: _vm._u([
                            {
                              key: "showbutton",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "TableEditButton",
                                    _vm._g(
                                      {
                                        attrs: {
                                          editButtonTooltip:
                                            "Edit graph Parameters",
                                          "tooltip-position": "bottom",
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-2" },
                    [
                      _c(
                        "v-expansion-panels",
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _vm._v(
                                  "\n                            Description\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-expansion-panel-content", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "white-space": "pre-wrap" },
                                  },
                                  [_vm._v(_vm._s(_vm.graphData.description))]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-subtitle", [_vm._v("History")]),
                ],
                1
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      !_vm.graphIsLoading
        ? _c(
            "v-list",
            [
              _vm._l(_vm.graphData && _vm.graphData.configs, function (item) {
                return [
                  _c("v-list-item", {
                    key: item.id,
                    class: {
                      "primary--text grey lighten-2":
                        item.id === _vm.selectedGraphConfigurationId,
                    },
                    attrs: { exact: "" },
                    on: {
                      click: function ($event) {
                        return _vm.changeConfiguration({
                          graphConfigId: item.id,
                        })
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.description)),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-list-item-subtitle", [
                                    _c(
                                      "span",
                                      { staticClass: "text--primary" },
                                      [_vm._v(_vm._s(item.created_by.name))]
                                    ),
                                    _vm._v(
                                      "\n                            - " +
                                        _vm._s(item.created_at) +
                                        "\n                        "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("GraphConfigurationDetailModal", {
                                attrs: { graphDetail: item },
                                on: {
                                  "save-graph-config-event":
                                    _vm.editConfiguration,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "showbutton",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "TableEditButton",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  editButtonTooltip:
                                                    "Edit configuration",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("TableDeleteModalDialog", {
                                attrs: {
                                  description: `Do you really want to delete configuration ${item.description}`,
                                  itemToDelete: item,
                                  tooltip: "Delete configuration",
                                },
                                on: { "delete-event": _vm.deleteConfiguration },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _c("v-divider", { key: `divider-${item.id}` }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }