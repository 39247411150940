var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    ref: "BaseModalDialogRef",
    attrs: {
      width: "80%",
      "max-width": "1200px",
      title: _vm.newAlarmForAddress ? "Create New Alarm" : "Alarm Detail",
    },
    on: {
      "load-modal-state-event": _vm.loadState,
      "destroy-modal-state-event": _vm.destroyState,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center align-end" },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { width: "100%", "max-width": "1150px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-end",
                            },
                            [
                              _c("v-text-field", {
                                staticStyle: { "max-width": "400px" },
                                attrs: {
                                  rules: _vm.nameRules,
                                  placeholder: "Enter name for new alarm!",
                                  label: "Alarm name",
                                  outlined: "",
                                  clearable: _vm.detailsEditable,
                                  readonly: !_vm.detailsEditable,
                                  autofocus: "",
                                  shaped: "",
                                  "validate-on-blur": "",
                                },
                                model: {
                                  value: _vm.alarmModel.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.alarmModel, "name", $$v)
                                  },
                                  expression: "alarmModel.name",
                                },
                              }),
                              _vm._v(" "),
                              _vm.existingAlarm
                                ? _c(
                                    "div",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              _vm.detailsEditable,
                                                            color: "info",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.detailsEditable = true
                                                            },
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("edit"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            808621050
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Edit Alarm")]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("TableDeleteModalDialog", {
                                        attrs: {
                                          description: `Do you really want to delete alarm ${_vm.alarmModel.name} ?`,
                                          itemToDelete: _vm.alarmModel,
                                          tooltip: "Delete Alarm",
                                          large: "",
                                        },
                                        on: { "delete-event": _vm.deleteAlarm },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            {
                              staticStyle: { width: "1150px" },
                              attrs: { elevation: "0" },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-list",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "mx-2 mt-2",
                                                    },
                                                    [
                                                      _c(
                                                        "blockchain-crypto-picker",
                                                        {
                                                          attrs: {
                                                            rules: "required",
                                                            "is-clearable": false,
                                                            currencies:
                                                              _vm.currencies,
                                                            disabled:
                                                              !_vm.headerEditable,
                                                            error:
                                                              _vm.currencyNotSet,
                                                            dense: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.alarmModel
                                                                .crypto,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.alarmModel,
                                                                "crypto",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "alarmModel.crypto",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !!_vm.alarmModel
                                                                .crypto,
                                                            top: "",
                                                            "open-delay": "200",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    _vm._g(
                                                                      {},
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          staticClass:
                                                                            "ma-2",
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                !_vm
                                                                                  .alarmModel
                                                                                  .crypto,
                                                                              items:
                                                                                _vm.entityTypes,
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              label:
                                                                                "Alarm Type",
                                                                              "menu-props":
                                                                                {
                                                                                  bottom: true,
                                                                                  offsetY: true,
                                                                                },
                                                                              readonly:
                                                                                !_vm.headerEditable,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.handleEntityTypeChange,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm.entityType,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.entityType =
                                                                                    $$v
                                                                                },
                                                                              expression:
                                                                                "entityType",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Choose crypto first."
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "mx-2" },
                                                    [
                                                      _vm.headerEditable
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _vm.entityType ===
                                                              "Address"
                                                                ? _c(
                                                                    "v-text-field-validateable",
                                                                    {
                                                                      staticClass:
                                                                        "mt-4 mb-n2",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "350px",
                                                                        },
                                                                      attrs: {
                                                                        rules:
                                                                          "required|crypto",
                                                                        outlined:
                                                                          "",
                                                                        label:
                                                                          "Address",
                                                                        clearable:
                                                                          "",
                                                                        "prepend-inner-icon":
                                                                          "mdi-magnify",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .alarmModel
                                                                            .hash,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.alarmModel,
                                                                              "hash",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "alarmModel.hash",
                                                                      },
                                                                    }
                                                                  )
                                                                : _c(
                                                                    "ClusterPicker",
                                                                    {
                                                                      ref: "ClusterPickerRef",
                                                                      attrs: {
                                                                        "margin-top":
                                                                          "4px",
                                                                        multiple: false,
                                                                        outlined:
                                                                          "",
                                                                        disabled:
                                                                          !_vm
                                                                            .alarmModel
                                                                            .crypto,
                                                                        "currency-unit":
                                                                          _vm
                                                                            .alarmModel
                                                                            .crypto,
                                                                        value:
                                                                          _vm.pickedCluster,
                                                                      },
                                                                      on: {
                                                                        input:
                                                                          _vm.handleClusterChange,
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          !_vm.headerEditable
                                            ? _c(
                                                "v-list-item",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "v-list-item-action",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          `${
                                                            _vm.isAlarmTypeAddress
                                                              ? "Address"
                                                              : "Cluster"
                                                          } being observed: `
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("EntityLabel", {
                                                        attrs: {
                                                          entity:
                                                            _vm.alarmModel.hash,
                                                          "is-address":
                                                            _vm.isAlarmTypeAddress,
                                                          currency:
                                                            _vm.alarmModel
                                                              .crypto,
                                                          justify: "end",
                                                          "fixed-width": false,
                                                          "act-as-link": "",
                                                          "is-copyable": "",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-list-item-action",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [_vm._v("Created:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-content",
                                                {
                                                  staticClass:
                                                    "text-right grey--text",
                                                },
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("moment")(
                                                          _vm.alarmModel
                                                            .created_at,
                                                          "YYYY-MM-DD HH:mm:ss Z"
                                                        )
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.createdElapsed
                                                        )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass: "mt-n4",
                                              attrs: { dense: "" },
                                            },
                                            [
                                              _c(
                                                "v-list-item-action",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [_vm._v("Updated:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-content",
                                                {
                                                  staticClass:
                                                    "text-right grey--text",
                                                },
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("moment")(
                                                          _vm.alarmModel
                                                            .updated_at,
                                                          "YYYY-MM-DD HH:mm:ss Z"
                                                        )
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.updatedElapsed
                                                        )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-list",
                                        { staticClass: "pt-6" },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass: "d-flex",
                                              staticStyle: { bottom: "12px" },
                                            },
                                            [
                                              _c(
                                                "v-list-item-action",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [_vm._v("Tx direction:")]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("TypePicker", {
                                                attrs: {
                                                  readonly:
                                                    !_vm.detailsEditable,
                                                  color: _vm.detailsEditable
                                                    ? "blue"
                                                    : "primary",
                                                },
                                                model: {
                                                  value:
                                                    _vm.alarmModel.tx_direction,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.alarmModel,
                                                      "tx_direction",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "alarmModel.tx_direction",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item",
                                            { staticClass: "d-flex mb-4" },
                                            [
                                              _c(
                                                "v-list-item-action",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [_vm._v("Notify:")]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("NotificationPicker", {
                                                attrs: {
                                                  readonly:
                                                    !_vm.detailsEditable,
                                                  color: _vm.detailsEditable
                                                    ? "blue"
                                                    : "primary",
                                                },
                                                model: {
                                                  value: _vm.alarmModel.notify,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.alarmModel,
                                                      "notify",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "alarmModel.notify",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider"),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-action",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [_vm._v("Owner:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.alarmModel.username
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex justify-center" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "1150px" } },
                      [
                        _c("v-textarea", {
                          staticClass: "mb-0 ma-1 mt-3",
                          attrs: {
                            "hide-details": "",
                            outlined: "",
                            label: "Additional notes",
                            readonly: !_vm.detailsEditable,
                          },
                          model: {
                            value: _vm.alarmModel.email_template,
                            callback: function ($$v) {
                              _vm.$set(_vm.alarmModel, "email_template", $$v)
                            },
                            expression: "alarmModel.email_template",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.existingAlarm
                    ? _c("div", [
                        _c("h1", { staticClass: "mt-4" }, [
                          _vm._v(
                            "Alarm notifications (" +
                              _vm._s(_vm.alarm.notifications_count) +
                              " in total)"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex justify-center" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "max-width": "1150px",
                                width: "100%",
                              },
                            },
                            [
                              _c("v-data-table", {
                                staticClass: "elevation-0 border mt-2",
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.computedNotificationsData,
                                  options: _vm.pagination,
                                  "server-items-length":
                                    _vm.notifications.total,
                                  loading: _vm.notificationsLoading,
                                  dense: "",
                                  "footer-props": {
                                    "show-current-page": true,
                                    showFirstLastPage: true,
                                  },
                                },
                                on: {
                                  "update:options": function ($event) {
                                    _vm.pagination = $event
                                  },
                                  "update:items-per-page":
                                    _vm.itemsPerPageUpdated,
                                  "update:page": _vm.pageUpdated,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (props) {
                                        return [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "text-overflow" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      props.item.created_at,
                                                      "YYYY-MM-DD HH:mm:ss Z"
                                                    )
                                                  ) +
                                                    " (" +
                                                    _vm._s(
                                                      props.item.elapsedCreated
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "link",
                                                    attrs: {
                                                      to: {
                                                        name: "TransactionDetail",
                                                        params: {
                                                          currency:
                                                            _vm.alarmModel
                                                              .crypto,
                                                          transaction:
                                                            props.item.tx_hash,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "fontMonospace",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              props.item.tx_hash
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-overflow" },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "text-overflow link",
                                                    attrs: {
                                                      to:
                                                        "/" +
                                                        _vm.alarm.crypto +
                                                        "/block/" +
                                                        props.item.hash,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          props.item.blockheight
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: "footer.page-text",
                                      fn: function ({
                                        pageStart,
                                        pageStop,
                                        itemsLength,
                                      }) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    pageStart
                                                  )
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    pageStop
                                                  )
                                                ) +
                                                " of " +
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    itemsLength
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3754494394
                                ),
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close, setDisabled }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    outlined: "",
                    color: "error",
                    disabled: _vm.alarmsModalState === _vm.modalState.Pending,
                  },
                  on: { click: close },
                },
                [_vm._v("\n            Cancel\n        ")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "success",
                    loading: _vm.alarmsModalState === _vm.modalState.Pending,
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save(close, setDisabled)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.detailsEditable ? "Save" : "Ok"))]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }