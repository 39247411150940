var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-4", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "" } },
        [
          _c("h1", [_vm._v("Categories")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("CategoryDetailModal", {
            on: { "save-event": _vm.createCategory },
            scopedSlots: _vm._u([
              {
                key: "showbutton",
                fn: function ({ on }) {
                  return [
                    _c(
                      "CreateButton",
                      _vm._g({ attrs: { text: "Add new category" } }, on)
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-0 border my-2",
        attrs: {
          headers: _vm.headers,
          items: _vm.categories,
          options: _vm.pagination,
          "server-items-length": _vm.categoriesPagination.total,
          loading: _vm.isCategoriesLoading,
          "items-per-page": 30,
          "footer-props": {
            "items-per-page-options": [30, 50, 100],
            "show-current-page": true,
            showFirstLastPage: true,
          },
          dense: "",
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c("tr", [
                  _c("td", { staticClass: "text-left" }, [
                    _c("span", [_vm._v(_vm._s(item.name))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "my-1",
                          staticStyle: { height: "26px" },
                          attrs: {
                            color: item.color,
                            "text-color": item.secondaryColor,
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.color) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "my-1",
                          staticStyle: { height: "26px" },
                          attrs: {
                            color: item.secondaryColor,
                            "text-color": item.color,
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.secondaryColor) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("AddressCategoryIcon", {
                        attrs: { medium: "", iconItem: item },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("CategoryBadge", {
                        staticClass: "ml-2",
                        attrs: { iconItem: item },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { attrs: { width: "10%" } },
                    [
                      _c("CategoryDetailModal", {
                        attrs: { categoryDetail: item },
                        on: { "save-event": _vm.editCategory },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "showbutton",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "TableEditButton",
                                    _vm._g(
                                      {
                                        attrs: {
                                          editButtonTooltip: "Edit category",
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("TableDeleteModalDialog", {
                        attrs: {
                          description: `Do you really want to delete category ${item.name}`,
                          itemToDelete: item,
                          tooltip: "Delete category",
                        },
                        on: { "delete-event": _vm.deleteCategory },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "footer.page-text",
            fn: function ({ pageStart, pageStop, itemsLength }) {
              return [
                _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("formatNumber")(pageStart)) +
                      " - " +
                      _vm._s(_vm._f("formatNumber")(pageStop)) +
                      " of " +
                      _vm._s(_vm._f("formatNumber")(itemsLength)) +
                      "\n            "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }